import { Injectable, Injector, NgZone } from '@angular/core';
import { DisplayableItemsViewModel } from '../../../../../shared/components/displayable-content/displayable-item-list/displayable-items-view-model';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { PrintMenusContainerViewModel } from '../print-menus-container/print-menus-container-view-model';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortUtils } from '../../../../../../utils/sort-utils';
import { DisplayableType } from '../../../../../../models/enum/shared/displayableType.enum';

@Injectable()
export class PrintCardsViewModel extends DisplayableItemsViewModel {

  constructor(
    protected menuDomainModel: MenuDomainModel,
    // printMenusContainerViewModel provided by Logged In Scope - need to get access to which tag is selected,
    // only way to pass this data to child is via a shared model, because child component is being created
    // by tab bar component (inflated in non conventional way), which means I can't pass in inputs
    protected printMenusContainerViewModel: PrintMenusContainerViewModel,
    navigationService: NavigationService,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
  ) {
    super(printMenusContainerViewModel, navigationService, ngZone, ngbModal, injector);
    this.setupFilter();
  }

  protected defaultEmptyStateTitle = 'No Print Cards';

  protected override defaultEmptyStateDescription = 'Create a card stack by using the button above.';

  protected itemsToFilter$ = combineLatest([
    this.menuDomainModel.currentLocationPrintCards$,
    this.menuDomainModel.currentLocationShelfTalkerMenus$
  ]).pipe(
    map(([cardStacks, shelfTalkerStacks]) => {
      if (!cardStacks || !shelfTalkerStacks) return null;
      return [...cardStacks, ...shelfTalkerStacks]?.sort(SortUtils.menusByNameAsc);
    })
  );

  protected getEmptyStateTitleCopy(menuTypeFilter: string, menuFormatFilter: DisplayableType): string {
    return this.genericEmptyStateTitleCopyForMenu(
      menuTypeFilter,
      menuFormatFilter,
      'print cards',
      'templated print cards'
    );
  }

  public createPrintCardStack(): void {
    this.createMenu(MenuType.PrintCardMenu);
  }

}
