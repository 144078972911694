import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, exhaustMap, iif, merge, NEVER, Observable, of, Subject, throwError, timer } from 'rxjs';
import { SignInRequest } from '../models/account/requests/sign-in-request';
import { AccountAPI } from '../api/account-api';
import { catchError, delay, distinctUntilChanged, map, shareReplay, switchMap, take, tap, throttleTime } from 'rxjs/operators';
import { HydratedUser } from '../models/account/dto/hydrated-user';
import { ForgotPasswordRequest } from '../models/account/requests/forgot-password-request';
import { CodeDeliveryDetails } from '../models/account/dto/code-delivery-details';
import { ResetPasswordRequest } from '../models/account/requests/reset-password-request';
import { SignInNewPasswordRequest } from '../models/account/requests/sign-in-new-password-request';
import { ChangePasswordRequest } from '../models/account/requests/change-password-request';
import { CacheService } from '../services/cache-service';
import { DefaultCacheKey } from '../models/enum/shared/default-cache-key.enum';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { ConfirmCodeRequest } from '../models/account/requests/confirm-code-request';
import { ImageAPI } from '../api/image-api';
import { GenerateUploadUrlRequest } from '../models/image/requests/generate-upload-url-request';
import { BudsenseFile } from '../models/shared/budsense-file';
import { UploadFilePath } from '../models/enum/dto/upload-file.path';
import { CachePolicy } from '../models/enum/shared/cachable-image-policy.enum';
import { StringUtils } from '../utils/string-utils';
import { ToastService } from '../services/toast-service';
import { SendCompanyReferral } from '../models/account/dto/send-company-referral';
import { NavigationService } from '../services/navigation.service';
import { RefreshSessionRequest } from '../models/account/requests/refresh-session-request';
import { SignOutRequest } from '../models/account/requests/sign-out-request';
import { Asset } from '../models/image/dto/asset';
import { InventoryProvider } from '../models/enum/dto/inventory-provider';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { exists } from '../functions/exists';
import { DistinctUtils } from '../utils/distinct-utils';
import { Session } from '../models/account/dto/session';
import type { UserProfilePicture } from '../models/image/dto/user-profile-picture';
import { AuthInterceptorService } from '../services/interceptors/auth-interceptor.service';

@Injectable({ providedIn: 'root' })
export class UserDomainModel extends BaseDomainModel {

  constructor(
    private accountAPI: AccountAPI,
    private imageAPI: ImageAPI,
    private cacheService: CacheService,
    private navigationService: NavigationService,
    private toastService: ToastService,
    private ngbModal: NgbModal,
    private authInterceptorService: AuthInterceptorService
  ) {
    super();
    this.subroutineConnectToAuthInterceptor();
    this.subroutineDestroySession();
    this.subroutineReceived403SoSetSessionTokenToRefreshing();
    this.subroutineRefreshSession();
    this.subroutineCheckIfSessionNeedsToBeRefreshed();
  }

  private readonly _destroySession: Subject<void> = new Subject<void>();
  public readonly destroySession$ = this._destroySession as Observable<void>;

  private _user = new BehaviorSubject<HydratedUser | null>(null);
  public user$ = this._user.pipe(
    map(user => {
      if (!user) user = this.getCachedUser();
      return user;
    }),
    distinctUntilChanged(DistinctUtils.distinctNulls),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public setUser(u: HydratedUser, rememberMe?: boolean): void {
    this.user$.once(user => {
      if (exists(u)) {
        u.rememberMe = (rememberMe !== undefined ? rememberMe : user?.rememberMe) ?? false;
      }
      if (u?.rememberMe) {
        this.cacheService.cacheObject(DefaultCacheKey.SessionUser, u, true);
      }
      if (exists(u)) {
        this.cacheService.cacheObject(DefaultCacheKey.SessionUser, u);
      } else {
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser, CachePolicy.Persistent);
      }
      this._user.next(u);
    });
  }

  public userId$ = this.user$.pipe(map(user => user?.userId), distinctUntilChanged());
  public userEmail$ = this.user$.pipe(map(user => user?.email), distinctUntilChanged());
  public authToken$ = this.user$.pipe(map(user => user?.session?.accessToken), distinctUntilChanged());
  public refreshToken$ = this.user$.pipe(map(user => user?.session?.refreshToken), distinctUntilChanged());
  public challengeToken$ = this.user$.pipe(map(user => user?.session?.getChallengeAuthToken()), distinctUntilChanged());
  public isCompanyAdmin$ = this.user$.pipe(map(u => u?.isCompanyAdmin), distinctUntilChanged());
  public readonly isTemplateAdmin$ = this.user$.pipe(map(u => u?.isTemplateAdmin ?? false), distinctUntilChanged());
  public userDefaultLocationId$ = this.user$.pipe(map(user => user?.defaultLocationId || null), distinctUntilChanged());
  public companyFeatures$ = this.user$.notNull().pipe(map(user => user?.companyFeatures), distinctUntilChanged());
  public canUsePrintCards$ = this.companyFeatures$.pipe(map(cf => cf?.supportsPrintCards), distinctUntilChanged());
  public canUseTemplates$ = this.companyFeatures$.pipe(map(cf => cf?.supportsTemplates), distinctUntilChanged());

  /* **************************************** SESSION **************************************** */

  private _err403SoUpdateSessionTokenToRefreshing = new Subject<void>();
  protected err403SoUpdateSessionTokenToRefreshing$ = this._err403SoUpdateSessionTokenToRefreshing as Observable<void>;
  received403SoUpdateSessionTokenToRefreshing(): void {
    this._err403SoUpdateSessionTokenToRefreshing.next();
  }

  public userSession$ = this.user$.pipe(
    map(user => user?.session ?? null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public validSession$ = this.userSession$.pipe(
    map(session => session?.validSession()),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private calculateValidSessionToken$ = this.userSession$.pipe(
    map(session => session?.validSession() ? session?.accessToken : null),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * validSessionToken$ emits a valid session token, Session.REFRESHING_AUTH_TOKEN_FROM_403, or null.
   * If the session is valid, it emits the access token.
   * If the session is in the middle of refresh, because of 403 error, then emit Session.REFRESHING_AUTH_TOKEN_FROM_403.
   * If the session is invalid, it emits null.
   *
   * Immediately switch output to Session.REFRESHING_AUTH_TOKEN_FROM_403 if a 403 error is received.
   */
  public validSessionToken$ = merge(
    this.calculateValidSessionToken$,
    this.err403SoUpdateSessionTokenToRefreshing$.pipe(map(() => Session.REFRESHING_AUTH_TOKEN_FROM_403))
  ).pipe(
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _refreshSession = new Subject<void>();
  public readonly refreshSession$ = this._refreshSession as Observable<void>;

  /**
   * This is to decouple the auth interceptor from the user domain model.
   */
  private subroutineConnectToAuthInterceptor(): void {
    this.destroySession$.subscribeWhileAlive({
      owner: this,
      next: () => this.authInterceptorService.connectToDestroySession()
    });
    this.validSessionToken$.subscribeWhileAlive({
      owner: this,
      next: (token) => this.authInterceptorService.connectToValidSessionToken(token)
    });
    this.user$.subscribeWhileAlive({
      owner: this,
      next: (user) => this.authInterceptorService.connectToUser(user)
    });
    this.authInterceptorService.err403SoUpdateSessionTokenToRefreshing$.subscribeWhileAlive({
      owner: this,
      next: () => this.received403SoUpdateSessionTokenToRefreshing()
    });
  }

  private subroutineDestroySession(): void {
    this.destroySession$.subscribeWhileAlive({
      owner: this,
      next: () => {
        this.cacheService.clearSessionCache();
        this.cacheService.clearPersistentCache();
        this._user.next(null);
        this.ngbModal.dismissAll();
        this.navigationService.signIn();
      }
    });
  }

  /**
   * A single mechanism for refreshing user sessions. This mechanism forces a single refresh request at a time.
   *
   * api/refresh-session with an invalid refresh token can come back without an error. In this scenario, the
   * session object properties are null, which means the session did not refresh, and is invalid. When this
   * occurs, we sign out the user.
   */
  private subroutineRefreshSession(): void {
    this.refreshSession$.pipe(
      // exhaustMap ensures that only one refresh session request is in flight at a time
      exhaustMap(() => {
        return this.user$.pipe(
          take(1),
          switchMap(user => this.getRefreshSessionReq(user)),
          take(1),
          switchMap(req => iif(() => !req, of(null), this.accountAPI.RefreshSession(req)))
        );
      })
    ).subscribeWhileAlive({
      owner: this,
      next: (user) => {
        user?.session?.validSession()
          ? this.sessionRefreshed(user)
          : this.sessionDidNotRefreshSoSignOut();
      },
      error: () => this.sessionDidNotRefreshSoSignOut()
    });
  }

  private sessionRefreshed(refreshedUser: HydratedUser): void {
    this.setUser(refreshedUser, refreshedUser?.rememberMe);
  }

  /**
   * signOut() internally calls:
   *   this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser, CachePolicy.Persistent);
   *   this.cacheService.clearAllCaches();
   *   this._destroySession.next();
   * so we don't need to manually destroy the session here.
   */
  private sessionDidNotRefreshSoSignOut(): void {
    const forceSignOutCompleted = () => {
      this.toastService.publishErrorMessage(
        'Your session has expired. Please log in again.',
        'You have been signed out.'
      );
    };
    this.signOut().once({
      // post requests that return nothing auto complete the observable and do not emit next
      complete: () => forceSignOutCompleted(),
      error: () => forceSignOutCompleted()
    });
  }

  /**
   * If err403SoUpdateSessionTokenToRefreshing$ emits, then we want to update the users session token
   * to be in the "Session.REFRESHING_AUTH_TOKEN_FROM_403" state. The session token is invalid on the backend,
   * so we queue API calls and prevent them from going out the door, but we don't want the access token to be null,
   * because null signifies an invalid session on the frontend and will log the user out. Therefore, setting the
   * access token to "Session.REFRESHING_AUTH_TOKEN_FROM_403" means that the session is still valid, so the user
   * can still navigate around the app without triggering router guards.
   *
   * Throttle time is added, because multiple 403 errors can come in at once, and we only want to clear the
   * session token once from this burst of errors. Therefore, we wait 5 seconds before accepting new
   * err403SoUpdateSessionTokenToRefreshing$ emissions.
   */
  private subroutineReceived403SoSetSessionTokenToRefreshing(): void {
    this.err403SoUpdateSessionTokenToRefreshing$.pipe(
      throttleTime(5000),
      switchMap(() => this.user$.pipe(take(1)))
    ).subscribeWhileAlive({
      owner: this,
      next: (user) => {
        if (exists(user.session)) {
          user.session.setAccessTokenToRefreshing();
          this.setUser(user);
        }
      }
    });
  }

  /**
   * When a user session is set, a timer starts and triggers when the session is one minute from expiring.
   * When the timer fires, it emits a refresh session event. The one-minute buffer prevents 403 errors on
   * API calls due to an expired session token.
   */
  private subroutineCheckIfSessionNeedsToBeRefreshed(): void {
    this.userSession$.pipe(
      switchMap(session => {
        switch (true) {
          case exists(session) && session.hasValidSessionAndNotRefreshingBecauseOf403(): {
            // timer is only alive while the session is valid, and it will refresh the session 1min before it expires
            const expiresIn = session?.expiresInNMilliSecondsMinus(60);
            return timer(expiresIn);
          }
          case exists(session) && session?.refreshingBecauseOf403():
          case exists(session) && !session?.hasChallenge() && !session.validSession(): {
            return of(true);
          }
          default: {
            return NEVER;
          }
        }
      })
    ).subscribeWhileAlive({
      owner: this,
      next: () => this._refreshSession.next()
    });
  }

  /* ****************************************************************************************** */

  public signOut(): Observable<any> {
    return this.getSignOutReq().pipe(
      take(1),
      switchMap(req => this.accountAPI.SignOut(req)),
      map((r) => {
        // Clear the user session from the persistent cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser, CachePolicy.Persistent);
        this.cacheService.clearAllCaches();
        this._destroySession.next();
        return r;
      }),
      catchError(err => {
        // If sign out request fails, we want to kill all caches any destroy session
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser, CachePolicy.Persistent);
        this.cacheService.clearAllCaches();
        this._destroySession.next();
        return throwError(() => err);
      })
    );
  }

  // Auth Methods

  public signIn(req: SignInRequest): Observable<HydratedUser> {
    return this.accountAPI.SignIn(req).pipe(
      map((user) => {
        // Clear the existing user from the cache
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionUser, CachePolicy.Persistent);
        this.setUser(user, req?.rememberMe);
        return user;
      })
    );
  }

  public forgotPassword(req: ForgotPasswordRequest): Observable<CodeDeliveryDetails> {
    return this.accountAPI.GetForgotPasswordCode(req.email).pipe(
      map((deliveryDetails) => {
        this.setDeliveryDetails(deliveryDetails);
        return deliveryDetails;
      })
    );
  }

  public resetPassword(req: ResetPasswordRequest): Observable<HydratedUser> {
    return this.accountAPI.ResetForgottenPassword(req).pipe(
      map((user) => {
        this.setUser(user);
        return user;
      })
    );
  }

  public signInNewPassword(req: SignInNewPasswordRequest): Observable<HydratedUser> {
    // Populate request from current session
    return combineLatest([this.userId$, this.challengeToken$]).pipe(
      take(1),
      switchMap(([userId, challengeToken]) => {
        req.userId = userId;
        req.session = challengeToken;
        return this.accountAPI.SignInNewPassword(req);
      }),
      tap((user) => this.setUser(user))
    );
  }

  public changePassword(req: ChangePasswordRequest): Observable<HydratedUser> {
    return this.accountAPI.ChangePassword(req).pipe(
      map((user) => {
        this.setUser(user);
        return user;
      })
    );
  }

  public updateUser(req: HydratedUser): Observable<HydratedUser> {
    return this.accountAPI.UpdateUser(req).pipe(
      map((user) => {
        this.setUser(user);
        return user;
      })
    );
  }

  public getUser(): Observable<HydratedUser> {
    return this.accountAPI.GetUser();
  }

  public confirmEmail(req: ConfirmCodeRequest): Observable<HydratedUser> {
    return this.accountAPI.ConfirmCode(req).pipe(
      map((user) => {
        this.setUser(user, true);
        return user;
      })
    );
  }

  public resendEmailConfirmationCode(email: string): Observable<CodeDeliveryDetails> {
    return this.authToken$.pipe(
      take(1),
      switchMap((token) => this.accountAPI.ResendCode(email, token))
    );
  }

  public uploadProfilePicture(f: BudsenseFile): Observable<Asset> {
    return this.user$.pipe(
      take(1),
      switchMap((user) => {
        this.cacheService.removeCachedAsset(user?.profilePicture);
        const req = new GenerateUploadUrlRequest();
        req.fileName = StringUtils.normalizeCharacters(f.name);
        req.mediaClass = UploadFilePath.ProfilePicturePath;
        req.mediaType = f.getMediaType();
        req.metadata = new Map<string, string>();
        req.metadata.set('CompanyId', user?.companyId?.toString());
        req.metadata.set('UserId', user?.userId);
        return this.uploadProfilePictureHelper(req, f);
      })
    );
  }

  public deleteProfilePicture(): Observable<any | never> {
    return this.user$.pipe(
      take(1),
      switchMap(user => {
        const existingProfilePicture = user?.profilePicture;
        if (!existingProfilePicture) {
          return EMPTY;
        }
        this.cacheService.removeCachedAsset(user?.profilePicture);
        return this.imageAPI.DeleteAsset(existingProfilePicture.id, existingProfilePicture.md5Hash).pipe(
          delay(2000),
          tap(() => this._refreshSession.next())
        );
      }),
    );
  }

  private uploadProfilePictureHelper(req: GenerateUploadUrlRequest, f: BudsenseFile): Observable<Asset> {
    return this.imageAPI.GenerateUploadUrl(req).pipe(
      switchMap((signedUploadUrl) => {
        return this.imageAPI.PutImageUploadUrl(signedUploadUrl.url, f.url.toString(), req.fileName).pipe(
          // provide delay based on file size
          delay(f.getUploadDelay()),
          switchMap((_) => {
            return this.pollForUploadedAssets(f, 10, 0);
          })
        );
      })
    );
  }

  private pollForUploadedAssets(file: BudsenseFile, numOfRetries: number, delayTime: number): Observable<Asset> {
    return this.getUser().pipe(
      delay(delayTime),
      switchMap(u => {
        if (u?.profilePicture?.fileName?.toLowerCase() === file.name.toLowerCase()) {
          numOfRetries = 0;
        }
        if (numOfRetries > 0) {
          return this.pollForUploadedAssets(file, numOfRetries - 1, 3500);
        } else {
          numOfRetries = 0;
          const profilePicture = u?.profilePicture;
          this.setUserProfilePicture(profilePicture);
          return of(profilePicture);
        }
      })
    );
  }

  public sendCompanyReferral(req: SendCompanyReferral): Observable<SendCompanyReferral> {
    return this.accountAPI.SendCompanyReferral(req);
  }

  public getRefreshSessionReq(user?: HydratedUser): Observable<RefreshSessionRequest | null> {
    return combineLatest([this.userId$, this.refreshToken$]).pipe(
      take(1),
      map(([userId, refreshToken]) => {
        if (user) {
          userId = user?.userId;
          refreshToken = user?.session?.refreshToken;
        }
        const req = new RefreshSessionRequest(userId, refreshToken);
        return (req?.refreshToken && req?.userId) ? req : null;
      })
    );
  }

  public getSignOutReq(): Observable<SignOutRequest | null> {
    return combineLatest([this.userId$, this.authToken$]).pipe(
      take(1),
      map(([userId, authToken]) => {
        const req = new SignOutRequest(userId, authToken);
        return (req?.accessToken && req?.userId) ? req : null;
      })
    );
  }

  public getCachedUser(): HydratedUser {
    return this.cacheService.getCachedObject(HydratedUser, DefaultCacheKey.SessionUser)
        ?? this.cacheService.getCachedObject(HydratedUser, DefaultCacheKey.SessionUser, true);
  }

  public setInventoryProvider(provider: InventoryProvider): void {
    this.user$.once(user => {
      const userCopy = window?.injector?.Deserialize?.instanceOf(HydratedUser, user);
      userCopy.inventoryProvider = provider;
      this.setUser(userCopy);
    });
  }

  public setUserProfilePicture(profilePicture: UserProfilePicture): void {
    this.user$.once(user => {
      const userCopy = window?.injector?.Deserialize?.instanceOf(HydratedUser, user);
      userCopy.profilePicture = profilePicture;
      this.setUser(userCopy);
    });
  }

  private setDeliveryDetails(deliveryDetails: CodeDeliveryDetails): void {
    const spoofedUser = new HydratedUser();
    spoofedUser.codeDeliveryDetails = [deliveryDetails];
    this.setUser(spoofedUser);
  }

}
