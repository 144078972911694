import { EditMarketingMenuViewModel } from '../../../viewModels/edit-marketing-menu-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { ToastService } from '../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Size } from '../../../../../models/shared/size';
import { takeUntil } from 'rxjs/operators';
import { Component, Injector, NgZone, ViewChild } from '@angular/core';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';

@Component({ selector: 'app-edit-marketing-menu', template: '' })
export abstract class EditMarketingMenuComponent extends BaseComponent {

  constructor(
    public viewModel: EditMarketingMenuViewModel,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected toastService: ToastService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  // View References
  @ViewChild('formCapture') formCapture: ReactiveFormMergeGroupsComponent;

  override setupBindings() {
    const resetTag = () => {
      this.viewModel.menu$.once(menu => {
        menu.tag = '';
        this.viewModel.showNewTagInput = !this.viewModel.showNewTagInput;
      });
    };
    this.viewModel.newTagClicked.subscribeWhileAlive({
      owner: this,
      next: resetTag
    });
    this.viewModel.useExistingTagClicked.subscribeWhileAlive({
      owner: this,
      next: resetTag
    });
    this.formCapture?.canSubmit$
      .pipe(takeUntil(this.onDestroy))
      .subscribeWhileAlive({
        owner: this,
        next: canSubmit => this.viewModel.canSubmitForm.next(canSubmit)
      });
    this.viewModel.triggerAutoFormSubmission
      .pipe(takeUntil(this.onDestroy))
      .subscribe(_ => this.formCapture?.submitForms(true));
  }

  liveViewClicked() {
    this.viewModel.canSubmitForm.once(canSubmitForm => {
      if (canSubmitForm && this.viewModel.unsavedChanges) {
        // Add delay for updated menu to be set since the height and width of displaySize will
        // update on backend based on size default
        this.formCapture.formSubmittedWithKeyedOutput$.once(_ => {
          this.viewModel.backgroundSaveMenuWithCompletion()
            .subscribe((updatedMenu) => this.openLiveViewModal(updatedMenu.displaySize));
        });
        this.formCapture.submitFormsWithKeyedOutput('liveViewClicked');
      } else {
        this.openLiveViewModal();
      }
    });
  }

  canDeactivate(): boolean | Promise<any> {
    return this.viewModel.canDeactivate();
  }

  private openLiveViewModal(sizeOverride?: Size) {
    this.viewModel.openLiveViewModal(sizeOverride);
  }

}
