import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModel } from '../base-smart-filter-form-view-model';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../domainModels/cannabinoids-and-terpenes-domain-model';
import { PrimaryCannabinoid } from '../../../../../models/enum/shared/primary-cannabinoid.enum';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CannabinoidAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModel {

  constructor(
    public cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel
  ) {
    super();
  }

  public primaryCannabinoids$ = of(Object.values(PrimaryCannabinoid));
  public enabledSecondaryCannabinoids$ = this.cannabinoidsAndTerpenesDomainModel.enabledSecondaryCannabinoidNames$;

  public secondaryCannabinoidDropdowns$ = this.cannabinoidsAndTerpenesDomainModel.enabledSecondaryCannabinoids$;

  public secondaryCannabinoidPillBackgroundColors$ = combineLatest([
    this.enabledSecondaryCannabinoids$,
    window.types.secondaryCannabinoids$
  ]).pipe(
    map(([
      enabledCannabinoids,
      cannabinoids
    ]) => {
      const cannabinoidNames = cannabinoids?.map(c => c?.getSelectionTitle());
      return cannabinoidNames.reduce((pillBackgroundColors, cannabinoidName) => {
        pillBackgroundColors[cannabinoidName] = enabledCannabinoids?.includes(cannabinoidName) ? '#222222' : '#FFD74B';
        return pillBackgroundColors;
      }, {});
    })
  );

  public secondaryCannabinoidPillTooltip$ = combineLatest([
    this.enabledSecondaryCannabinoids$,
    window.types.secondaryCannabinoids$
  ]).pipe(
    map(([
      enabledCannabinoids,
      cannabinoids
    ]) => {
      const cannabinoidNames = cannabinoids?.map(c => c?.getSelectionTitle());
      return cannabinoidNames.reduce((pillTooltips, cannabinoidName) => {
        const tooltip = enabledCannabinoids?.includes(cannabinoidName)
          ? ''
          : `${cannabinoidName} is disabled in company cannabinoids. `
            + 'This will not be considered on smart filter matches.';
        return { ...pillTooltips, [cannabinoidName]: tooltip};
      }, {});
    })
  );

}
