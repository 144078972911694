import { Component, Injector, NgZone, OnInit, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { EditLabelViewModel } from './edit-label-view-model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from '../../../../../services/navigation.service';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { LabelPreviewComponent } from '../../../../../modules/product-labels/label-preview/label-preview.component';
import { ModalConfirmation } from '../../../../../modals/modal-confirmation';

@Component({
  selector: 'app-edit-label-modal',
  templateUrl: './edit-label-modal.component.html',
  styleUrls: ['./edit-label-modal.component.scss'],
  providers: [
    EditLabelViewModel
  ]
})
export class EditLabelModalComponent extends BaseModalComponent implements OnInit {

  constructor(
    public viewModel: EditLabelViewModel,
    protected activeModal: NgbActiveModal,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected navigationService: NavigationService
  ) {
    super(activeModal);
  }

  @ViewChild('labelPreviewComponent') labelPreviewComponent: LabelPreviewComponent;

  override setupBindings() {
    this.viewModel.dismissModalSubject.subscribeWhileAlive({
      owner: this,
      next: _ => this.activeModal.close(null)
    });
    this.labelPreviewComponent?.appliedSortedSystemLabels$.subscribeWhileAlive({
      owner: this,
      next: systemLabels => {
        this.viewModel.connectToAppliedSystemLabels(systemLabels);
      }
    });
  }

  editLabels() {
    this.navigationService.manageLabels();
  }

  promptForSaveLabelOverride() {
    combineLatest([
      this.viewModel?.variant$,
      this.viewModel?.menu$,
      this.viewModel?.section$,
      this.viewModel?.allLabels$,
      this.viewModel?.templateMode$
    ]).pipe(
      take(1)
    ).subscribe(([variant, menu, section, labels, templateMode]) => {
      const variantId = variant?.id;
      const customLabelExists = !!section.customLabelMap.get(variantId);
      const existingLabel = customLabelExists || menu.variantFeature?.variantIds.contains(variantId);
      const existingDisplayAttributeLabels = !!variant?.displayAttributes?.defaultLabel
        || !!variant?.displayAttributes?.inheritedDisplayAttribute?.defaultLabel;
      const overridingDisplayAttribute = !existingLabel && existingDisplayAttributeLabels;
      if (overridingDisplayAttribute) {
        const opts = new ConfirmationOptions();
        opts.title = 'Override Label';
        const inheritedLocationLabelText = labels?.find(l => {
          if (templateMode) {
            return l.id === variant.displayAttributes?.defaultLabel;
          }
          return l.id === variant.displayAttributes?.defaultLabel && l.locationId === menu.locationId;
        })?.text;
        const inheritedCompanyLabelText = labels?.find(l => {
          const labelMatches = l.id === variant?.displayAttributes?.inheritedDisplayAttribute?.defaultLabel;
          const locationMatchesCompany = l.locationId === menu?.companyId;
          return labelMatches && locationMatchesCompany;
        })?.text;
        const inheritedLabelText = inheritedLocationLabelText || inheritedCompanyLabelText;
        opts.bodyText = `The '${inheritedLabelText}' label is being inherited from the product's display attributes. `
          + `Are you sure you want to override this label on the current menu?`;
        opts.cancelText = 'Cancel';
        opts.continueText = 'Override';
        const confirmation = (cont: boolean) => (cont ? this.viewModel.save() : this.activeModal.close(null));
        ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
      } else {
        this.viewModel.save();
      }
    });
  }

}
