import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, NgZone, Output, QueryList, ViewChildren } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { EditSmartPlaylistMenuViewModel } from '../edit-smart-playlist-menu-view-model';
import { Section } from '../../../../../../../models/menu/dto/section';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { ModalReorderSections } from '../../../../../../../modals/modal-reorder-sections';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HydratedSection } from '../../../../../../../models/menu/dto/hydrated-section';
import { EditSmartPlaylistMenuContentViewModel } from './edit-smart-playlist-menu-content-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { ProductGroupingComponent } from '../../../../shared/product-grouping/product-grouping.component';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-edit-smart-playlist-menu-content',
  templateUrl: './edit-smart-playlist-menu-content.component.html',
  styleUrls: ['./edit-smart-playlist-menu-content.component.scss'],
  providers: [EditSmartPlaylistMenuContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSmartPlaylistMenuContentComponent extends BaseComponent {

  constructor(
    public viewModel: EditSmartPlaylistMenuContentViewModel,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  @ViewChildren(ProductGroupingComponent) productGroupings: QueryList<ProductGroupingComponent>;
  @Input() templateMode: boolean;
  @Input() sharedVM: EditSmartPlaylistMenuViewModel;
  @Input() mergeKey: string;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Output() replacingImage = new EventEmitter<boolean>(true);
  @Output() submitForms = new EventEmitter<boolean>(true);

  private readonly _productGroupings = new BehaviorSubject<ProductGroupingComponent[]|null>(null);
  private readonly replacingImage$ = this._productGroupings.pipe(
    switchMap(groupings => {
      const signals$ = groupings?.map(g => g.replacingImage$) || [of(false)];
      return combineLatest(signals$);
    }),
    map(signals => signals?.includes(true))
  );

  override setupBindings(): void {
    this._productGroupings.next(this.productGroupings?.toArray());
    this.productGroupings?.changes.subscribeWhileAlive({
      owner: this,
      next: groupings => this._productGroupings.next(groupings?.toArray())
    });
    this.replacingImage$.subscribeWhileAlive({
      owner: this,
      next: replacing => this.replacingImage.emit(replacing)
    });
  }

  addProductGroupingClicked() {
    if (this.formPristine) {
      this.sharedVM?.addProductGroupingClicked();
    } else {
      this.viewModel.formAutoSubmitted$.once(_ => this.sharedVM?.addProductGroupingClicked());
      this.submitForms.emit(true);
    }
  }

  reorderGroupingsClicked() {
    const reorderOp = (ordered: Section[]): Observable<boolean> => this.sharedVM?.saveMenuSectionOrder(ordered);
    const open = (sections: Section[]) => {
      ModalReorderSections.open(this.ngZone, this.ngbModal, this.injector, sections, reorderOp);
    };
    this.sharedVM?.menuSections$.once(open);
  }

  duplicateGrouping(section: HydratedSection) {
    if (this.formPristine) {
      this.sharedVM?.duplicateSection(section);
    } else {
      this.viewModel.formAutoSubmitted$.once(_ => this.sharedVM?.duplicateSection(section));
      this.submitForms.emit(true);
    }
  }

}
