<div
  class="arrow-button-container"
  [class.selected]="selected"
  [style.padding-left]="paddingLeft"
  [style.padding-right]="paddingRight">
  <div class="arrow-button-content" fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex [style.padding-left.rem]="0.25">
      <div class="font-title" [style.font-size]="titleFontSize" [style.font-weight]="titleFontWeight">
        {{ title | titlecase }}
        <span *ngIf="infoText">
          <img
            class="arrow-button-info-icon"
            [style.height]="titleFontSize"
            [src]="'assets/icons/dark/outline/information-circle.svg'"
            alt=""
            [popper]="popperContent"
            [popperShowOnStart]="false"
            [popperTrigger]="ClientTypes.NgxPopperjsTriggers.hover"
            [popperModifiers]="popperModifier"
            [popperHideOnClickOutside]="true"
            [popperHideOnScroll]="true"
            [popperPreventOverflow]="false"
            [popperPositionFixed]="false"
            [popperApplyClass]="'missing-variant-info-popper'"
            [popperStyles]="popperStyles"
            [popperPlacement]="ClientTypes.NgxPopperjsPlacements.TOP"
            (click)="rippleOverlay?.click()" />
          <popper-content #popperContent>
            <span class="arrow-button-info-text">{{ infoText }}</span>
          </popper-content>
        </span>
      </div>
      <div class="font-sub-title" [style.font-size]="subtitleFontSize" [style.font-weight]="subtitleFontWeight">
        {{ subtitle }}
      </div>
    </div>
    <div class="arrow" fxFlex="none">
      <img [src]="'assets/icons/dark/solid/chevron-right.svg'" alt="" />
    </div>
  </div>
  <hr class="no-margin" />
  <div
    #rippleOverlay
    matRipple
    class="ripple-overlay"
    appThrottleClick
    (throttleClick)="ifSelectedPreventClickEvents ? !selected && clicked.emit(true) : clicked.emit(true)"></div>
</div>
