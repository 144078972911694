<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel?.loadingOpts$ | async"> </app-loading>
<app-search-with-look-ahead
  style="display: block"
  class="bg-white pt-16px pb-8px"
  libStickyAutoPosition
  [stickyCollectionKey]="stickyCollectionKey"
  [stickyZ]="9"
  [stickyOrder]="1"
  [disablePopper]="true"
  [placeHolder]="searchBarPlaceholder"
  [nLookAheadItems]="searchLookAheadItems"
  [lookAheadItems]="viewModel.itemsToSearch$ | async"
  [searchTheseProperties]="['title', 'body', 'groupingName']"
  (searchText)="viewModel.connectToSearchString($event)"
  (lookAheadHits)="viewModel.connectToSearchHits($event)">
</app-search-with-look-ahead>
<app-single-selection-grouped-list
  *ngIf="!(viewModel.showNoItems$ | async) && !(viewModel.showNoResultGroups$ | async)"
  [groupedItems]="viewModel.groupedHits$ | async"
  (itemSelected)="selectedItem.emit($event)">
</app-single-selection-grouped-list>

<app-single-selection-list
  *ngIf="!(viewModel.showNoItems$ | async) && !(viewModel.showNoResults$ | async)"
  [items]="viewModel.individualHits$ | async"
  (itemSelected)="selectedItem.emit($event)">
</app-single-selection-list>

<div *ngIf="viewModel.showNoItems$ | async" class="mt-1" fxLayout="column" fxLayoutGap="0.5rem">
  <div class="bs-section-list-title">{{ noItemsTitle }}</div>
  <div class="bs-edit-section-description">
    {{ noItemsBody }}
  </div>
</div>
<div *ngIf="viewModel.showNoResults$ | async" class="mt-1" fxLayout="column" fxLayoutGap="0.5rem">
  <div class="bs-section-list-title">No Results</div>
  <div class="bs-edit-section-description">We couldn't find any items matching your search criteria.</div>
</div>
