import { Injectable } from '@angular/core';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { BulkPrintJob } from '../../../../../models/automation/bulk-print-job';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { HasChildIds } from '../../../../../models/protocols/has-child-ids';
import { Menu } from '../../../../../models/menu/dto/menu';
import { StackPrintType } from '../../../../../models/automation/enum/card-stack-print-type.enum';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { exists } from '../../../../../functions/exists';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { SmartPrintUtils } from '../../../../../utils/smart-print-utils';

@Injectable()
export class PrintJobSelectStacksViewModel extends BaseViewModel {

  constructor(private menuDomainModel: MenuDomainModel) {
    super();
  }

  override _loadingOpts = new BehaviorSubject(LoadingOptions.defaultWhiteBackground());

  public readonly currentLocationCardStackMenus$ = this.menuDomainModel.currentLocationPrintCards$;
  public readonly currentLocationLabelStackMenus$ = this.menuDomainModel.currentLocationPrintLabels$;
  public readonly currentLocationShelfTalkerMenus$ = this.menuDomainModel.currentLocationShelfTalkerMenus$;

  private readonly _stackPrintType = new BehaviorSubject<StackPrintType>(null);
  public readonly stackPrintType$ = this._stackPrintType as Observable<StackPrintType>;
  connectToStackPrintType = (stackPrintType: StackPrintType) => this._stackPrintType.next(stackPrintType);

  public readonly menuStacksToSelectFrom$ = this.stackPrintType$.notNull().pipe(
    switchMap(stackPrintType => {
      const compareWith = stackPrintType?.toLowerCase();
      switch (true) {
        case SmartPrintUtils.isLabelJob(compareWith):
          return this.currentLocationLabelStackMenus$.pipe(map(menus => ['Label', menus] as [string, Menu[]]));
        case SmartPrintUtils.isShelfTalkerJob(compareWith):
          return this.currentLocationShelfTalkerMenus$.pipe(map(menus => ['Shelf Talker', menus] as [string, Menu[]]));
        default:
          return this.currentLocationCardStackMenus$.pipe(map(menus => ['Card', menus] as [string, Menu[]]));
      }
    }),
    tap(([noun, stacks]) => {
      const lm = `Loading ${noun} Stacks`;
      if (!stacks && !this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.addRequest(lm);
      } else if (exists(stacks)) {
        this._loadingOpts.removeRequest(lm);
      }
    }),
    map(([_, stacks]) => stacks),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => {
    this._job.next(job);
    this._selectedStackIds.next(job?.menuIds ?? null);
  };

  private _searchedMenus = new BehaviorSubject<Menu[]>([]);
  public searchedMenus$ = this._searchedMenus as Observable<Menu[]>;
  connectToSearchedMenus = (searchedMenus: Menu[]) => this._searchedMenus.next(searchedMenus);

  private _selectedStackIds = new BehaviorSubject<string[]>([]);
  public selectedStackIds$ = this._selectedStackIds as Observable<string[]>;
  connectToSelectedStackIds = (ids: string[]) => this._selectedStackIds.next(ids);

  private _mergeKey = new BehaviorSubject<string>('');
  public mergeKey$ = this._mergeKey as Observable<string>;
  connectToMergeKey = (mergeKey: string) => this._mergeKey.next(mergeKey);

  private _viewOnly = new BehaviorSubject<boolean>(false);
  public viewOnly$ = this._viewOnly as Observable<boolean>;
  connectToViewOnly = (viewOnly: boolean) => this._viewOnly.next(viewOnly);

  public menusAsSelectionItem$ = this.searchedMenus$.pipe(
    map(menus => {
      return new (class implements HasChildIds {

        getId = (): string => '';
        getChildIds = (): string[] => menus?.map(menu => menu?.getId()) ?? [];

      })();
    })
  );

}
