declare global {
  interface String {
    spaceOutCamelString(): string;
    stripWhiteSpace(): string;
    stripWhiteSpaceAndLowerCase(): string;
    ellipsis(maxLength: number): string;
    pluralizer(): Pluralizer;
  }
}

String.prototype.spaceOutCamelString = function() {
  return this.replace(/([A-Z])/g, ' $1').trim();
};

String.prototype.stripWhiteSpace = function() {
  return this.trim().replace(/\s/g, '');
};

String.prototype.stripWhiteSpaceAndLowerCase = function() {
  return this.trim().replace(/\s/g, '').toLowerCase();
};

String.prototype.ellipsis = function(maxLength: number) {
  return this.length > maxLength ? this.substring(0, maxLength) + '…' : this;
};

type PluralizerRule = { isPlural?: boolean; listConnection: any[]; word: string; useApostrophe: boolean };
type Pluralizer = { addRule: (rule: PluralizerRule) => Pluralizer; pluralize: () => string };
String.prototype.pluralizer = function(): Pluralizer {
  const rules: PluralizerRule[] = [];
  let pluralized: string = this;
  const addRule = (rule: PluralizerRule) => {
    rules.push(rule);
    return { addRule, pluralize };
  };
  const pluralize = (): string => {
    for (const rule of rules) {
      if (rule?.isPlural || (rule?.listConnection?.length > 1)) {
        pluralized = pluralized.replace(rule.word, rule.word + (rule.useApostrophe ? '\'' : '') + 's');
      }
    }
    return pluralized;
  };
  return { addRule, pluralize };
};

export class StringUtils {

  static lowercaseFirstCharacter(s: string): string {
    return (s?.length > 1) ? s.charAt(0).toLowerCase() + s.slice(1) : s;
  }

  static capitalize(s: string): string {
    return (s?.length > 1) ? s.charAt(0).toUpperCase() + s.slice(1) : s;
  }

  static sentenceCase(s: string): string {
    return StringUtils.capitalize(s?.toLowerCase());
  }

  static capitalizeFirstLetterOfEachWord(s: string): string {
    return s?.split(' ')?.map(word => StringUtils.capitalize(word))?.join(' ');
  }

  static normalizeCharacters(s: string): string {
    // First replace all non-ASCII characters, then replace all duplicate spaces with a single space
    return s.normalize('NFD')
      .replace(/[^a-zA-Z0-9\-_().\s]/g, '')
      .replace(/\s\s+/g, ' ');
  }

  static toTitleCase(s: string): string {
    return s.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  }

  static camelize(str): string {
    return str
      ?.replace(/^\w|[A-Z]|\b\w/g, (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase())
      ?.replace(/\s+/g, '');
  }

  static getWordsFromCamelCase(s: string): string[] {
    return s?.split(/(?=[A-Z])/) || [];
  }

  static splitPascalCaseIntoSentence(s: string) {
    return s?.split(/(?=[A-Z])/)?.join(' ');
  }

  /**
   * Pascal case, or PascalCase, is a variable naming convention in programming in which the first letter of each word
   * is uppercase and the remaining letters are lowercase, with no spaces or other separators between words.
   */
  static toPascalCase(s: string, separator: string = ' ') {
    return s?.split(separator)?.map(word => StringUtils.capitalize(word))?.join('');
  }

  /**
   * Camel case, or camelCase, is a variable naming convention in programming in which the first letter of the first
   * word is lowercase and the first letter of each subsequent word is uppercase, with no spaces or other separators
   * between words.
   */
  static toCamelCase(s: string, separator: string = ' ') {
    return StringUtils.lowercaseFirstCharacter(StringUtils.toPascalCase(s, separator));
  }

  static splitPascalCase(s: string): string[] {
    return s?.split(/(?=[A-Z])/);
  }

  static getStringMode(items: string[]): string {
    const modeCounts = {};
    items?.forEach(item => modeCounts[item] = (modeCounts[item] || 0) + 1);
    const sortedModeCounts = Object.keys(modeCounts)?.sort((a, b) => modeCounts[b] - modeCounts[a]);
    return sortedModeCounts?.[0] || null;
  }

  static ellipse(s: string, maxLength: number) {
    return (s?.length > maxLength) ? (s.substring(0, maxLength) + '…') : s;
  }

  static removeWhiteSpace(s: string): string {
    return s?.replace(/\s/g, '');
  }

  static gridColumnComparisonString(unitString: string): string {
    return StringUtils.removeWhiteSpace(unitString?.toLowerCase());
  }

  static replaceMenuWithTemplate(input: string, isTemplate: boolean = true): string {
    if (!input || !isTemplate) return input;
    if (input?.includes('Menus')) input = input?.replace('Menus', 'Templates');
    if (input?.includes('menus')) input = input?.replace('menus', 'templates');
    if (input?.includes('Menu'))  input = input?.replace('Menu', 'Template');
    if (input?.includes('menu'))  input = input?.replace('menu', 'template');
    return input;
  }

  static replaceMenuWithCard(input: string, isCard: boolean = true): string {
    if (!input || !isCard) return input;
    if (input?.includes('Menus')) input = input?.replace('Menus', 'Cards');
    if (input?.includes('menus')) input = input?.replace('menus', 'cards');
    if (input?.includes('Menu'))  input = input?.replace('Menu', 'Card');
    if (input?.includes('menu'))  input = input?.replace('menu', 'card');
    return input;
  }

  static replaceMenuWithLabel(input: string, isLabel: boolean = true): string {
    if (!input || !isLabel) return input;
    if (input?.includes('Menus')) input = input?.replace('Menus', 'Labels');
    if (input?.includes('menus')) input = input?.replace('menus', 'labels');
    if (input?.includes('Menu'))  input = input?.replace('Menu', 'Label');
    if (input?.includes('menu'))  input = input?.replace('menu', 'label');
    return input;
  }

  static replaceMenuWithStack(input: string, isCard: boolean = true): string {
    if (!input || !isCard) return input;
    if (input?.includes('Menus')) input = input?.replace('Menus', 'Stacks');
    if (input?.includes('menus')) input = input?.replace('menus', 'stacks');
    if (input?.includes('Menu'))  input = input?.replace('Menu', 'Stack');
    if (input?.includes('menu'))  input = input?.replace('menu', 'stack');
    return input;
  }

  static replaceMenuWithCardStack(input: string, isCard: boolean = true): string {
    if (!input || !isCard) return input;
    if (input?.includes('Menus')) input = input?.replace('Menus', 'Card Stacks');
    if (input?.includes('menus')) input = input?.replace('menus', 'card stacks');
    if (input?.includes('Menu'))  input = input?.replace('Menu', 'Card Stack');
    if (input?.includes('menu'))  input = input?.replace('menu', 'card stack');
    return input;
  }

  static replaceMenuWithLabelStack(input: string, isLabel: boolean = true): string {
    if (!input || !isLabel) return input;
    if (input?.includes('Menus')) input = input?.replace('Menus', 'Label Stacks');
    if (input?.includes('menus')) input = input?.replace('menus', 'label stacks');
    if (input?.includes('Menu'))  input = input?.replace('Menu', 'Label Stack');
    if (input?.includes('menu'))  input = input?.replace('menu', 'label stack');
    return input;
  }

  static replaceDisplayWithCollection(input: string): string {
    if (!input) return input;
    if (input.includes('Displays')) input = input.replace('Displays', 'Collections');
    if (input.includes('displays')) input = input.replace('displays', 'collections');
    if (input.includes('Display'))  input = input.replace('Display', 'Collection');
    if (input.includes('display'))  input = input.replace('display', 'collection');
    return input;
  }

  static replaceDisplayWithTemplateCollection(input: string, isTemplateCollection = true): string {
    if (!input || !isTemplateCollection) return input;
    if (input.includes('Displays')) input = input.replace('Displays', 'Template Collections');
    if (input.includes('displays')) input = input.replace('displays', 'template collections');
    if (input.includes('Display'))  input = input.replace('Display', 'Template Collection');
    if (input.includes('display'))  input = input.replace('display', 'template collection');
    return input;
  }

}
