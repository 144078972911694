import { Injectable } from '@angular/core';
import { EditTemplateMenuSectionCombinedLoadingOptsViewModel } from '../../../../edit-template-menu-section/edit-template-combined-loading-opts-view-model';
import { EditTemplateShelfTalkerBrandsSectionViewModel } from './edit-template-shelf-talker-brands-section-view-model';
import { EditShelfTalkerBrandsSectionViewModel } from '../../../../../../menu/components/edit-menu/edit-shelf-talker-menu/edit-shelf-talker-brands-menu/edit-shelf-talker-brands-section/edit-shelf-talker-brands-section-view-model';

@Injectable()
export class EditTemplateShelfTalkerBrandsSectionCombinedLoadingOptsViewModel
  extends EditTemplateMenuSectionCombinedLoadingOptsViewModel {

  constructor(
    public editTemplateShelfTalkerBrandsSectionViewModel: EditTemplateShelfTalkerBrandsSectionViewModel,
    public editShelfTalkerBrandsSectionViewModel: EditShelfTalkerBrandsSectionViewModel,
  ) {
    super(editTemplateShelfTalkerBrandsSectionViewModel, editShelfTalkerBrandsSectionViewModel);
  }

}
