import { TypeDefinition } from './type-definition';
import { SectionSortOption } from '../../enum/dto/section-sort-option';

export class SectionSortType extends TypeDefinition {

  override value: SectionSortOption;

  nameWithoutDashes(): string {
    return this.name?.replace(/ - /g, ' ');
  }

}
