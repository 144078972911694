import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import type { Section } from '../../../../../../../../../models/menu/dto/section';

@Component({
  selector: 'app-card-stack-print-job-select-individual-section',
  templateUrl: './card-stack-print-job-select-individual-section.component.html',
  styleUrls: ['./card-stack-print-job-select-individual-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardStackPrintJobSelectIndividualSectionComponent {

  @Input() selectedIds: string[];
  @Input() section: Section;
  @Input() viewOnly: boolean = false;
  @Output() addSectionClicked = new EventEmitter<string>();
  @Output() removeSectionClicked = new EventEmitter<string>();
  @Output() containerSectionClicked = new EventEmitter<string>();

}
