import { TypeDefinition } from './type-definition';

export enum SectionLayout {
  Grid = 'SectionLayoutType_Grid',
  List = 'SectionLayoutType_List',
  PricingTierGrid = 'SectionLayoutType_PricingTierGrid',
  ChildVariantList = 'SectionLayoutType_ChildVariantList',
  ClassicFlowerGrid = 'SectionLayoutType_ClassicFlowerGrid',
}

export class SectionLayoutType extends TypeDefinition {

  override value: SectionLayout;

  public lineItemHasMultipleColumnsForSizeOrPrice(): boolean {
    switch (this.value) {
      case SectionLayout.Grid:
      case SectionLayout.PricingTierGrid:
      case SectionLayout.ClassicFlowerGrid:
        return true;
      default:
        return false;
    }
  }

  public isList(): boolean {
    return this.value === SectionLayout.List;
  }

  public isGrid(): boolean {
    return this.value === SectionLayout.Grid;
  }

  public isPricingTierGrid(): boolean {
    return this.value === SectionLayout.PricingTierGrid;
  }

  public isClassicFlowerGrid(): boolean {
    return this.value === SectionLayout.ClassicFlowerGrid;
  }

  public isChildVariantList(): boolean {
    return this.value === SectionLayout.ChildVariantList;
  }

}
