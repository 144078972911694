import { SectionColumnConfig } from './section-column-config';
import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { DefaultSectionColumnConfigProperties } from '../../protocols/default-section-column-config-properties';
import { SectionColumnConfigKey } from '../../enum/dto/section-column-config-key';

export class DefaultSectionColumnConfig
  implements Deserializable, UniquelyIdentifiable, DefaultSectionColumnConfigProperties {

  public id: string;
  public name: string = '';
  public columnConfig: Map<SectionColumnConfigKey, SectionColumnConfig>;

  onDeserialize() {
    if (!this.columnConfig) {
      this.columnConfig = new Map<SectionColumnConfigKey, SectionColumnConfig>();
    } else if (!(this.columnConfig instanceof Map)) {
      this.columnConfig = window?.injector?.Deserialize?.mapOf(SectionColumnConfig, this.columnConfig);
    } else {
      this.columnConfig = this.columnConfig.deepCopy();
    }
    this.name = this.name || '';
  }

  getId(): string {
    return this.id;
  }

  getUniqueIdentifier(): string {
    const columnConfigMapIds: string[] = [];
    this.columnConfig?.forEach((config, key) => {
      const configId = config?.getUniqueIdentifier();
      columnConfigMapIds.push(`${key}-${configId}`);
    });
    const columnConfigId = columnConfigMapIds?.join('-');
    return `
      -${this.id}
      -${this.name}
      -${columnConfigId}
    `;
  }

}
