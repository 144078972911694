import { Pipe, PipeTransform } from '@angular/core';
import type { SortFunctions } from '../all-products-data-table/products-table/all-products-table-header/all-products-table-header.component';
import type { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getVariantSortingFunctionForCannabinoidOrTerpene'
})
export class GetVariantSortingFunctionForCannabinoidOrTerpenePipe implements PipeTransform {

  transform(
    cannabinoidOrTerpene: string,
    sortFuncMap: Map<string, SortFunctions>
  ): { property: string, sortBy: (a: Variant, b: Variant) => number } {
    return sortFuncMap?.get(cannabinoidOrTerpene)?.variantSort;
  }

}
