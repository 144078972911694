// Don't use this unless you know exactly how it works. If used wrong, can cause weird problems, since the cache lives
// with the life cycle of the object. I need to update this to live with the life cycle of edits, meaning if any
// data changes on the object, then I need to clear the cached method data.

/**
 * A decorator that caches the result of a function after its first execution.
 * Subsequent calls to the function will return the cached value instead of executing the function again.
 *
 * If the function takes in inputs, then consider using the `memoize` decorator instead.
 *
 * @param target - The prototype of the class.
 * @param propertyKey - The name of the method being decorated.
 * @param descriptor - The descriptor of the method (contains metadata about the method).
 *
 * @returns The updated method descriptor with caching behavior.
 */
export function cacheResult(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;
  // Replace the original method with a new function that adds caching
  descriptor.value = function(...args: any[]) {
    if (this._cache === undefined) this._cache = {};
    if (this._cache[propertyKey] !== undefined) return this._cache[propertyKey];
    const result = originalMethod.apply(this, args);
    this._cache[propertyKey] = result;
    return result;
  };
  // Return the updated method descriptor with the memoization behavior
  return descriptor;
}
