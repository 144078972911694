import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { VariantType } from '../../utils/dto/variant-type-definition';
import { UnitOfMeasure } from '../../utils/dto/unit-of-measure-type';
import { CannabisUnitOfMeasure } from '../../utils/dto/cannabis-unit-of-measure-type';
import { StrainClassification } from '../../enum/dto/strain-classification';
import { ProductType } from '../../enum/dto/product-type';

export class UniversalVariant implements Deserializable, UniquelyIdentifiable {

  public barcode: string;
  public confirmed: boolean;
  public displayAttributeGroupId: string;
  public assetGroupId: string;
  public name: string;
  public lastModified: number;
  public dateCreated: number;
  public brand: string;
  public manufacturer: string;
  public description: string;
  public terpenes: string;
  public classification: StrainClassification;
  public unitSize: number;
  public unitOfMeasure: UnitOfMeasure;
  public cannabisUnitOfMeasure: CannabisUnitOfMeasure;
  public packagedQuantity: number;
  public productType: ProductType;
  public variantType: VariantType;
  public strain: string;
  public THC: string;
  public minTHC: string;
  public maxTHC: string;
  public CBD: string;
  public minCBD: string;
  public maxCBD: string;
  public cultivatorVerified: boolean;

  getUniqueIdentifier(...opts: any): string {
    return `${this.barcode}
      -${this.name}
      -${this.confirmed}
      -${this.lastModified}
      -${this.dateCreated}
      -${this.brand}
      -${this.manufacturer}
      -${this.classification}
      -${this.unitSize}
      -${this.unitOfMeasure}
      -${this.cannabisUnitOfMeasure}
      -${this.packagedQuantity}
      -${this.productType}
      -${this.variantType}
      -${this.strain}
      -${this.THC}
      -${this.minTHC}
      -${this.maxTHC}
      -${this.CBD}
      -${this.minCBD}
      -${this.maxCBD}`;
  }

  onDeserialize() {
  }

}
