import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import type { StackType } from '../../../../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';

@Component({
  selector: 'app-stack-smart-bulk-print-job-selected-stacks',
  templateUrl: './stack-smart-bulk-print-job-selected-stacks.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackSmartBulkPrintJobSelectedStacksComponent {

  @Input() stackMenus: Menu[];
  @Input() stackType: StackType;

}
