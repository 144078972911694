import { Component, Injector, NgZone } from '@angular/core';
import { EditMenuSectionComponent } from '../../../../edit-menu-section/edit-menu-section.component';
import { EditSmartPlaylistMenuSectionViewModel } from './edit-smart-playlist-menu-section-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditMenuSectionViewModel } from '../../../../../viewModels/edit-menu-section-view-model';

@Component({
  selector: 'app-edit-smart-playlist-menu-section',
  templateUrl: './edit-smart-playlist-menu-section.component.html',
  providers: [
    EditSmartPlaylistMenuSectionViewModel,
    {
      provide: EditMenuSectionViewModel,
      useExisting: EditSmartPlaylistMenuSectionViewModel
    }
  ]
})
export class EditSmartPlaylistMenuSectionComponent extends EditMenuSectionComponent {

  constructor(
    public override viewModel: EditSmartPlaylistMenuSectionViewModel,
    activatedRoute: ActivatedRoute,
    router: Router,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
  ) {
    super(viewModel, activatedRoute, router, ngZone, ngbModal, injector);
  }

}
