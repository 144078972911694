import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { CannabinoidsFormViewModel } from './cannabinoids-form-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { ParserUtils } from '../../../../../../../../utils/parser-utils';

@Component({
  selector: 'app-cannabinoids-form',
  templateUrl: './cannabinoids-form.component.html',
  styleUrls: [
    '../edit-variant-cannabinoids.component.scss',
    './cannabinoids-form.component.scss',
  ],
  providers: [CannabinoidsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CannabinoidsFormComponent extends BaseComponent {

  constructor(
    public viewModel: CannabinoidsFormViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToCannabinoid') cannabinoid: string;
  @Input() mergeKey: string;

  parseNumberAsString(val: string) {
     return ParserUtils.parseFloatAsString(val);
  }

}
