<div class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">{{ title }}</p>
    <p class="bs-edit-section-description">{{ description }}</p>
  </div>
  <div class="col-sm-8 col-md-8 col-lg-8">
    <app-edit-shelf-talker-brands-section-form
      [mergeKey]="mergeKey"
      [menu]="viewModel.menu$ | push"
      [section]="viewModel.section$ | push"
      [isTemplatedSection]="viewModel.isTemplatedSection$ | push"
      [companyConfig]="viewModel.companyConfig$ | push"
      [locationConfig]="viewModel.locationConfig$ | push"
      [bindTo]="viewModel.section$ | push"
      [autoUpdateGridColumns]="autoUpdateGridColumns"
      (showZeroStockChanged)="viewModel.showZeroStockChanged($event)"
      (formAutoSubmitted)="viewModel.saveSection(true)"
      (disableSubmit)="viewModel.setAutoSaveTimer($event)"
      (primarySortChanged)="viewModel.primarySortChanged($event)"
      (secondarySortChanged)="viewModel.secondarySortChanged($event)"
      (selectedSectionLayoutType)="selectedSectionLayoutType.emit($event)">
    </app-edit-shelf-talker-brands-section-form>
  </div>
</div>

<hr class="mt-24px" />
