<div class="card-table">
  <!-- Filter Options -->
  <div class="card-table-filters pt-8px pb-8px">
    <!-- Search Input -->
    <div *ngIf="tableOptions.enableSearch" class="card-table-search">
      <form>
        <label class="bs-textfield-label" for="searchQueryString">
          {{ tableOptions.searchLabelText }}
        </label>
        <div class="card-table-search-bar">
          <input
            (ngModelChange)="onQueryStringChange($event)"
            [(ngModel)]="searchQueryString"
            class="bs-textfield search-textfield custom-right-padding"
            [style.padding-right.rem]="3"
            id="searchQueryString"
            name="searchQueryString"
            placeholder="{{ tableOptions.searchPlaceholderText }}"
            type="text" />
          <button (click)="clearSearch()" class="clear-card-table-search-bar" type="button">CLEAR</button>
        </div>
        <span class="bs-textfield-error"></span>
      </form>
    </div>
    <!--   Checkbox Filter     -->
    <div *ngIf="tableOptions.checkBox" class="card-table-switches">
      <app-toggle-switch
        [state]="this.tableOptions.checkBox.checked"
        [label]="'Hide out of stock products'"
        (toggled)="filterChanged($event)">
      </app-toggle-switch>
    </div>
  </div>

  <div class="card-table-grid">
    <app-incomplete-product-card
      *ngFor="let card of displayedData; let cardIndex = index"
      (click)="cardPressed(card)"
      [cardClass]="getCardClass(cardIndex)"
      [card]="card">
    </app-incomplete-product-card>
  </div>

  <div [hidden]="displayedData.length > 0 || tableData.length === 0" class="no-results-container">
    <div class="no-results-title">
      {{ getNoResultsTitle() }}
    </div>
    <div class="no-results-body">
      {{ getNoResultsBody() }}
    </div>
  </div>

  <!--   Footer   -->
  <div [hidden]="tableOptions.hideFooter" class="card-table-grid-footer">
    <!--   Results Position   -->
    <div class="card-table-footer-results" [hidden]="filteredData.length === 0">
      <div class="position-text">
        Showing
        <span class="position-text-bold">{{ beginAmount() + 1 }}</span>
        to
        <span class="position-text-bold">{{
          endAmount() < filteredData.length ? endAmount() : filteredData.length
        }}</span>
        of
        <span class="position-text-bold">{{ filteredData.length }}</span>
        results
      </div>
    </div>
    <!--   Pagination   -->
    <div [hidden]="filteredData.length === 0">
      <div class="card-table-footer-pagination">
        <ul class="pagination dt_pagination">
          <li [class.disabled]="previousDisabled()" class="paginate_button page-item previous">
            <a (click)="previousClicked()" class="page-link" tabindex="0">
              <img alt="" class="pagination-arrow" src="assets/icons/dark/outline/arrow-left.svg" />
            </a>
          </li>

          <li
            *ngFor="let page of this.pages()"
            [class.active]="isActivePage(page)"
            class="paginate_button page-item page-item-number">
            <a (click)="pageClicked(page)" class="page-link">
              {{ page }}
            </a>
          </li>

          <li [class.disabled]="nextDisabled()" class="paginate_button page-item next">
            <a (click)="nextClicked()" class="page-link" data-dt-idx="5" tabindex="0">
              <img alt="" class="pagination-arrow" src="assets/icons/dark/outline/arrow-right.svg" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
