import { Pipe, PipeTransform } from '@angular/core';
import { TypeDefinition } from '../../../models/utils/dto/type-definition';

@Pipe({
  name: 'typeDefinitionValueComparison'
})
export class TypeDefinitionValueComparisonPipe implements PipeTransform {

  transform(
    typeDefinition: TypeDefinition,
    comparisonValue: any
  ): boolean {
    return typeDefinition?.getSelectionValue() === comparisonValue;
  }

}
