import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { concatMap, debounceTime, distinctUntilChanged, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ProductAPI } from '../api/product-api';
import { ToastService } from '../services/toast-service';
import { CacheService } from '../services/cache-service';
import { BehaviorSubject, combineLatest, defer, interval, Observable, of } from 'rxjs';
import { SyncDataJob } from '../models/settings/sync-data-job';
import { DistinctUtils } from '../utils/distinct-utils';
import { DateUtils } from '../utils/date-utils';
import { SyncJobStatus } from '../models/utils/dto/sync-job-status-type';
import { CompanyDomainModel } from './company-domain-model';
import { LocationDomainModel } from './location-domain-model';
import { DisplayAttributesDomainModel } from './display-attributes-domain-model';
import { LabelDomainModel } from './label-domain-model';
import { SyncType } from '../models/enum/dto/sync-type';
import { ProviderUtils } from '../utils/provider-utils';
import { ProductDomainModel } from './product-domain-model';

@Injectable()
export class SyncDomainModel extends BaseDomainModel {

  constructor(
    public companyDomainModel: CompanyDomainModel,
    private displayAttributesDomainModel: DisplayAttributesDomainModel,
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private productDomainModel: ProductDomainModel,
    private productAPI: ProductAPI,
    private toastService: ToastService,
    private cacheService: CacheService,
  ) {
    super();
  }

  // Sync Job polling
  public readonly POLLING_TIME = 10 * 1000; // 10 seconds
  public pollingTimer$ = interval(this.POLLING_TIME);
  private _lastPolledTime = new BehaviorSubject<number>(0);
  public lastPolledTime$ = this._lastPolledTime.asObservable();

  private _fetchingSyncJobs = new BehaviorSubject<boolean>(false);
  public fetchingSyncJobs$ = this._fetchingSyncJobs as Observable<boolean>;

  private companyId$ = this.companyDomainModel.companyId$;
  private inventoryProvider$ = this.companyDomainModel.inventoryProvider$;

  public locationId$ = this.locationDomainModel.locationId$;

  private _companySyncJobs = new BehaviorSubject<SyncDataJob[]>(null);
  public companySyncJobs$ = combineLatest([
    this._companySyncJobs,
    this.inventoryProvider$
  ]).pipe(
    map(([jobs, inventoryProvider]) => {
      jobs?.forEach(job => job?.autoAddPromotionsToSyncTypesIfNeeded(inventoryProvider));
      return jobs;
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({bufferSize: 1, refCount: true}),
    debounceTime(100) // delay pipe to not spam table. Spamming the table breaks it
  );

  private getSyncJobs = this.companyDomainModel.inventoryProviderSupportsLocationSpecificSync$.pipe(
    distinctUntilChanged(),
    switchMap(ipSupportsLocationSpecificSync => {
      const companyId$ = this.companyId$.notNull().pipe(distinctUntilChanged());
      const locationId$ = defer(() => this.locationId$.notNull().pipe(distinctUntilChanged()));
      return combineLatest([companyId$, ...(ipSupportsLocationSpecificSync ? [locationId$] : [])]);
    }),
    debounceTime(100)
  ).subscribeWhileAlive({
    owner: this,
    next: () => this.fetchCompanySyncJobs()
  });

  private fetchCompanySyncJobs(): void {
    this._fetchingSyncJobs.next(true);
    combineLatest([this.locationId$, this.companyDomainModel.inventoryProviderSupportsLocationSpecificSync$]).once(([
      locationId,
      ipSupportsLocationSpecificSync
    ]) => {
      this.productAPI.GetSyncJobs(null, ipSupportsLocationSpecificSync ? locationId : null).subscribe({
        next: (syncJobs) => {
          this._companySyncJobs.next(syncJobs);
          this._fetchingSyncJobs.next(false);
        },
        error: () => {
          this._fetchingSyncJobs.next(false);
        }
      });
    });
  }

  public pollingInputJobs$ = this.companySyncJobs$.pipe(
    map(jobs => {
      return jobs?.filter(j => {
        // Continue to poll for jobs that are queued or processing
        const queued = SyncJobStatus[j.jobStatus] === SyncJobStatus.SyncJobStatus_Queued;
        const processing = SyncJobStatus[j.jobStatus] === SyncJobStatus.SyncJobStatus_Processing;
        const continuePolling = j.dateCreated + DateUtils.unixOneMinute() * 10 > DateUtils.currentTimestamp();
        return (queued || processing) && continuePolling;
      });
    })
  );

  private poller = combineLatest([
    this.pollingInputJobs$,
    this.pollingTimer$,
    this.lastPolledTime$
  ]).pipe(
    concatMap(([jobs, _, lastPolledTime]) => {
      if (jobs?.length > 0 && (Date.now() - lastPolledTime > this.POLLING_TIME)) {
        this._lastPolledTime.next(Date.now());
        return this.getUpdatedSyncJobStatus(jobs);
      } else {
        return of([]);
      }
    }),
    takeUntil(this.onDestroy)
  ).subscribe();

  private getUpdatedSyncJobStatus(jobs: SyncDataJob[]): Observable<SyncDataJob[]> {
    return this.productAPI.GetSyncJobs(jobs.map(j => j.id).unique()).pipe(
      tap((updatedJobs) => {
        updatedJobs.forEach(j => {
          if (j.jobStatus === SyncJobStatus.SyncJobStatus_Success) {
            this.locationDomainModel.fetchLocationConfig();
            this.companyDomainModel.fetchCompanyConfig();
            this.loadCompanyDisplayAttributesIfNeeded(j);
            this.loadLocationDisplayAttributesIfNeeded(j);
            this.loadLabelsIfNeeded(j);
            this.loadCompanyIfNeeded(j);
            this.loadInventoryRoomsIfNeeded(j);
            this.loadLocationPromotionsIfNeeded(j);
          }
          this.updateCurrentSyncJobs(j);
        });
      }),
    );
  }

  private loadCompanyDisplayAttributesIfNeeded(j: SyncDataJob): void {
    // POS labels are set at the company level
    if (j.results.defaultLabelUpdated > 0) this.displayAttributesDomainModel.loadCompanyDisplayAttributes();
  }

  private loadLocationDisplayAttributesIfNeeded(j: SyncDataJob): void {
    if (j.results.displayAttributeUpdated > 0 || j.results.lotInfoUpdated > 0) {
      // Display Attributes updated via product-sync is always location specific (ie location cannabinoids)
      // Lot Info updated via inventory-sync is always location specific (ie location lot info / lab results)
      this.displayAttributesDomainModel.loadLocationDisplayAttributes();
    }
  }

  private loadLabelsIfNeeded(j: SyncDataJob): void {
    if (j.results.posLabelsChanged()) {
      this.locationId$.once(locationId => {
        this.labelDomainModel.loadLabels(locationId);
      });
    }
  }

  private loadCompanyIfNeeded(j: SyncDataJob): void {
    if (j.syncTypes.includes(SyncType.Location)) {
      this.companyDomainModel.loadCompany(true);
    }
  }

  private loadInventoryRoomsIfNeeded(j: SyncDataJob): void {
    this.inventoryProvider$.once(ip => {
      if (ProviderUtils.supportsInventoryRooms(ip) && j.syncTypes.includes(SyncType.Location)) {
        this.companyDomainModel.loadInventoryProvider();
      }
    });
  }

  private loadLocationPromotionsIfNeeded(j: SyncDataJob): void {
    const promotionsCreated = j?.results?.promotionsCreated > 0;
    const promotionsDeleted = j?.results?.promotionsDeleted > 0;
    const promotionsUpdated = j?.results?.promotionsUpdated > 0;
    if (promotionsCreated || promotionsDeleted || promotionsUpdated) {
      this.productDomainModel.loadLocationPromotions();
    }
  }

  private updateCurrentSyncJobs(job: SyncDataJob) {
    this._companySyncJobs.once(jobs => {
      const updatedJobs = jobs.shallowCopy();
      const existingIndex = updatedJobs.findIndex(j => j.id === job.id);
      if (existingIndex > -1) {
        updatedJobs.splice(existingIndex, 1);
      }
      updatedJobs.push(job);
      this._companySyncJobs.next(updatedJobs);
    });
  }

  public createSyncJob(job: SyncDataJob): Observable<SyncDataJob> {
    return this.productAPI.CreateSyncJob(job).pipe(
      tap(newJob => this.updateCurrentSyncJobs(newJob))
    );
  }

}
