import { VariantType } from '../models/utils/dto/variant-type-definition';
import { ProductType } from '../models/enum/dto/product-type';

export class AssetUtils {

  static readonly productTypeUrls = {
    [ProductType.Accessories]: 'assets/img/product-category/accessories.png',
    [ProductType.Beverage]: 'assets/img/product-category/beverage.png',
    [ProductType.Concentrate]: 'assets/img/product-category/concentrate.png',
    [ProductType.Edible]: 'assets/img/product-category/edible.png',
    [ProductType.Flower]: 'assets/img/product-category/flower.png',
    [ProductType.Oil]: 'assets/img/product-category/oil.png',
    [ProductType.Other]: 'assets/img/product-category/other.png',
    [ProductType.Seed]: 'assets/img/product-category/seed.png',
    [ProductType.Vape]: 'assets/img/product-category/vape.png',
    [ProductType.Wellness]: 'assets/img/product-category/wellness.png'
  };

  static budSenseProductTypeUrls(productType: ProductType, variantType: VariantType): string | null {
    switch (productType) {
      case ProductType.Accessories:
        return AssetUtils.budSenseAccessoriesVariantTypeUrls(variantType);
      case ProductType.Beverage:
        return AssetUtils.budSenseBeverageVariantTypeUrls(variantType);
      case ProductType.Concentrate:
        return AssetUtils.budSenseConcentrateVariantTypeUrls(variantType);
      case ProductType.Edible:
        return AssetUtils.budSenseEdibleVariantTypeUrls(variantType);
      case ProductType.Flower:
        return AssetUtils.budSenseFlowerVariantTypeUrls(variantType);
      case ProductType.Oil:
        return AssetUtils.budSenseOilVariantTypeUrls(variantType);
      case ProductType.Other:
        return AssetUtils.budSenseOtherVariantTypeUrls(variantType);
      case ProductType.Seed:
        return AssetUtils.budSenseSeedVariantTypeUrls(variantType);
      case ProductType.Vape:
        return AssetUtils.budSenseVapeVariantTypeUrls(variantType);
      case ProductType.Wellness:
        return AssetUtils.budSenseWellnessVariantTypeUrls(variantType);
      default:
        return 'assets/svg/budsense/defaultHemp.svg';
    }
  }

  private static budSenseAccessoriesVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.Battery:
        return 'assets/img/budsense/battery.png';
      case VariantType.Bong:
        return 'assets/img/budsense/bong.png';
      case VariantType.Book:
        return 'assets/img/budsense/book.png';
      case VariantType.Cleaning:
        return 'assets/img/budsense/cleaning.png';
      case VariantType.Grinder:
        return 'assets/img/budsense/grinder.png';
      case VariantType.Kitchenware:
        return 'assets/img/budsense/kitchenware.png';
      case VariantType.Lighter:
        return 'assets/img/budsense/lighter.png';
      case VariantType.Pipe:
        return 'assets/img/budsense/pipe.png';
      case VariantType.Storage:
        return 'assets/img/budsense/storage.png';
      case VariantType.Tray:
        return 'assets/img/budsense/tray.png';
      default:
        return 'assets/img/budsense/accessories.png';
    }
  }

  private static budSenseBeverageVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.Dissolvable:
        return 'assets/img/budsense/dissolvable.png';
      case VariantType.Distilled:
        return 'assets/img/budsense/distilled.png';
      case VariantType.Tea:
        return 'assets/img/budsense/tea.png';
      default:
        return 'assets/img/budsense/drinks.png';
    }
  }

  private static budSenseConcentrateVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      default:
        return 'assets/img/budsense/concentrates.png';
    }
  }

  private static budSenseEdibleVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.HardCandy:
        return 'assets/img/budsense/candy.png';
      default:
        return 'assets/img/budsense/edibles.png';
    }
  }

  private static budSenseFlowerVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.BulkFlower:
        return 'assets/img/budsense/big-bags.png';
      case VariantType.PreRoll:
      case VariantType.InfusedPreRoll:
      case VariantType.InfusedBlunt:
      case VariantType.Blunt:
        return 'assets/img/budsense/pre-rolls.png';
      default:
        return 'assets/img/budsense/flower.png';
    }
  }

  private static budSenseOilVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.Capsule:
        return 'assets/img/budsense/capsules.png';
      default:
        return 'assets/img/budsense/oil.png';
    }
  }

  private static budSenseOtherVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.Apparel:
        return 'assets/img/budsense/apparel.png';
      case VariantType.Bundle:
        return 'assets/img/budsense/bundle.png';
      case VariantType.GiftCard:
        return 'assets/img/budsense/gift-card.png';
      case VariantType.Merch:
        return 'assets/img/budsense/merch.png';
      case VariantType.Pet:
        return 'assets/img/budsense/pet.png';
      case VariantType.Sample:
        return 'assets/img/budsense/sample.png';
      default:
        return 'assets/img/budsense/other.png';
    }
  }

  private static budSenseSeedVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      default:
        return 'assets/img/budsense/seed.png';
    }
  }

  private static budSenseVapeVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      default:
        return 'assets/img/budsense/vapes.png';
    }
  }

  private static budSenseWellnessVariantTypeUrls(variantType: VariantType): string {
    switch (variantType) {
      case VariantType.Bath:
        return 'assets/img/budsense/bath.png';
      case VariantType.CreamAndLotion:
        return 'assets/img/budsense/cream.png';
      case VariantType.Lubricant:
        return 'assets/img/budsense/lubricant.png';
      case VariantType.RollOn:
        return 'assets/img/budsense/roll-on.png';
      case VariantType.SkinCare:
        return 'assets/img/budsense/skincare.png';
      case VariantType.Suppository:
        return 'assets/img/budsense/suppository.png';
      case VariantType.Transdermal:
        return 'assets/img/budsense/transdermal.png';
      case VariantType.Hemp:
        return 'assets/img/budsense/hemp.png';
      default:
        return 'assets/img/budsense/wellness.png';
    }
  }

}
