import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { DisplayableContentContainerViewModel } from '../displayable-content-container/displayable-content-container-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { ReactiveSearchBarComponent } from '@mobilefirstdev/reactive-search-and-pagination';
import { ReactiveFormDropDownComponent } from '@mobilefirstdev/reactive-form';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-displayable-content-filter-form',
  templateUrl: './displayable-content-filter-form.component.html',
  styleUrls: ['./displayable-content-filter-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayableContentFilterFormComponent extends BaseComponent {

  constructor(public viewModel: DisplayableContentContainerViewModel) {
    super();
  }

  @ViewChild('search') search: ReactiveSearchBarComponent;
  @ViewChild('inactiveStateFilter') inactiveStateFilter: ReactiveFormDropDownComponent;
  @ViewChild('menuFormatFilter') menuFormatFilter: ReactiveFormDropDownComponent;
  @ViewChild('menuTypeFilter') menuTypeFilter: ReactiveFormDropDownComponent;

  override setupBindings(): void {
    this.viewModel.selectedTab$.pipe(distinctUntilChanged()).subscribeWhileAlive({
      owner: this,
      next: () => {
        this.resetForm();
      }
    });
  }

  private resetForm(): void {
    this.search?.clear();
    this.resetInactiveStateFilter();
    this.resetMenuFormatFilter();
    this.resetMenuTypeFilter();
  }

  private resetInactiveStateFilter(): void {
    this.viewModel.isInitialInactiveStateSelection$.next(true);
    this.viewModel.selectFilterByInactiveState$.once(filter => {
      this.inactiveStateFilter?.getSelfAsFormItem()?.patchValue(filter);
    });
  }

  private resetMenuFormatFilter(): void {
    this.viewModel.selectFilterByMenuFormat$.once(filter => {
      this.menuFormatFilter?.getSelfAsFormItem()?.patchValue(filter);
    });
  }

  private resetMenuTypeFilter(): void {
    this.viewModel.selectFilterByMenuType$.once(filter => {
      this.menuTypeFilter?.getSelfAsFormItem()?.patchValue(filter);
    });
  }

}
