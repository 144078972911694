import { Component, Injector, NgZone, ViewChild } from '@angular/core';
import { EditProductMenuComponent } from '../../edit-product-menu/edit-product-menu.component';
import { ComponentCanDeactivate } from '../../../../../../models/protocols/component-can-deactivate';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../../../../../../services/toast-service';
import { EditStaticSectionViewModel } from './edit-static-section-view-model';
import { EditStaticSectionMenuViewModel } from './edit-static-section-menu-view-model';
import { CombinedLoadingOptsViewModel } from '../../../../../shared/components/auto-save/combined-loading-opts-view-model';

@Component({template: ''})
export abstract class EditStaticSectionMenuComponent
  extends EditProductMenuComponent
  implements ComponentCanDeactivate {

  @ViewChild(ReactiveFormMergeGroupsComponent) override formCapture: ReactiveFormMergeGroupsComponent;

  protected constructor(
    public override viewModel: EditStaticSectionMenuViewModel,
    public sectionViewModel: EditStaticSectionViewModel,
    public sharedLoadingState: CombinedLoadingOptsViewModel,
    activatedRoute: ActivatedRoute,
    router: Router,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    sanitizer: DomSanitizer,
    toastService: ToastService,
    activeRoute: ActivatedRoute,
  ) {
    super(viewModel, activatedRoute, router, ngZone, ngbModal, injector, sanitizer, toastService, activeRoute);
  }

  override canDeactivate(): boolean | Promise<any> {
    return this.viewModel.canDeactivate() && this.sectionViewModel.canDeactivate();
  }

}
