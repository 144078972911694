import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../../../utils/string-utils';

@Pipe({
  name: 'GetSortFunctionForCannabinoidOrTerpene'
})
export class GetSortFunctionForCannabinoidOrTerpenePipe implements PipeTransform {

  transform(
    cannabinoidOrTerpene: string,
    minOrMaxSort: boolean,
    sortFuncMap: Map<string, (a: any, b: any) => number>,
  ): (a: any, b: any) => number {
    const key = minOrMaxSort
      ? StringUtils.toPascalCase(cannabinoidOrTerpene)
      : StringUtils.camelize(cannabinoidOrTerpene);
    return sortFuncMap.get(key);
  }

}
