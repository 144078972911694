import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditMenuSectionBodyComponent } from '../../../../../edit-menu-section/edit-menu-section-body/edit-menu-section-body.component';
import { EditShelfTalkerBrandsSectionViewModel } from '../edit-shelf-talker-brands-section-view-model';

@Component({
  selector: 'app-edit-shelf-talker-brands-section-body',
  templateUrl: './edit-shelf-talker-brands-section-body.component.html',
  styleUrls: ['./edit-shelf-talker-brands-section-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EditShelfTalkerBrandsSectionBodyComponent extends EditMenuSectionBodyComponent {

  constructor(
    override viewModel: EditShelfTalkerBrandsSectionViewModel
  ) {
    super(viewModel);
  }

}
