import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { DisplayableContentContainerViewModel } from '../displayable-content-container/displayable-content-container-view-model';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, defer, Observable, of } from 'rxjs';
import { SortUtils } from '../../../../../utils/sort-utils';
import { DisplayableItem } from '../displayable-item-container/displayable-item-preview/displayable-item';
import { DisplayableType } from '../../../../../models/enum/shared/displayableType.enum';
import { DisplayableItemFilterByActive } from '../../../../../models/enum/shared/displayable-item-filter-by.active';
import { DropDownItem } from '../../../../../models/shared/stylesheet/drop-down-item';
import { NavigationService } from '../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { Menu } from '../../../../../models/menu/dto/menu';
import { ModalCreateMenu } from '../../../../../modals/modal-create-menu';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { exists } from '../../../../../functions/exists';

type DataForEmptyStateHelper = [string, string, DisplayableItemFilterByActive, DisplayableType, string];

export abstract class DisplayableItemsViewModel extends BaseViewModel {

  public readonly ITEMS_PER_PAGE = 15;
  public readonly MAX_PAGES = 10;

  protected constructor(
    protected containerViewModel: DisplayableContentContainerViewModel,
    protected navigationService: NavigationService,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
    this.generateMenuFormatOptions();
    this.generateInactiveStateFilterOptions();
    this.listenForEmptyState();
  }

  protected abstract defaultEmptyStateTitle: string;

  protected abstract itemsToFilter$: Observable<DisplayableItem[]>;

  protected abstract getEmptyStateTitleCopy(menuTypeFilter: string, menuFormatFilter?: DisplayableType): string;

  protected defaultEmptyStateDescription: string = 'Create a menu by using the button above.';

  protected genericEmptyStateTitleCopyForMenu(
    menuTypeFilter: string,
    menuFormatFilter: DisplayableType,
    nonTemplatedMenuName: string,
    templatedMenuName: string,
    allStartWith: string = 'Searching for '
  ): string {
    let title: string;
    switch (menuFormatFilter) {
      case DisplayableType.All:
        title = menuTypeFilter
          ? this.getMenuTypeFilterDescriptionStringForAllMenuFormat(menuTypeFilter, allStartWith)
          : `${allStartWith}${nonTemplatedMenuName}`;
        break;
      case DisplayableType.NonTemplatedMenu:
        title = menuTypeFilter
          ? this.getMenuTypeFilterDescriptionStringForNonTemplatedMenu(menuTypeFilter, allStartWith)
          : `${allStartWith}${nonTemplatedMenuName}`;
        break;
      case DisplayableType.TemplatedMenu:
        title = menuTypeFilter
          ? this.getMenuTypeFilterDescriptionStringForTemplatedMenu(menuTypeFilter, allStartWith)
          : `${allStartWith}${templatedMenuName}`;
        break;
    }
    return title;
  }

  protected menuTypeFilterEndsWith(
    menuTypeFilter: string,
    endsWith: string[]
  ): boolean {
    return endsWith?.some(x => menuTypeFilter?.endsWith(x));
  }

  protected pluralizeMenuTypeFilter(
    menuTypeFilter: string,
    pluralizeFor: string[]
  ): string {
    const plural = menuTypeFilter?.pluralizer();
    for (const word of pluralizeFor) {
      plural?.addRule({ isPlural: true, listConnection: null, word, useApostrophe: false });
    }
    return plural?.pluralize();
  }

  protected singularizeMenuTypeFilter(
    menuTypeFilter: string,
    singularizeFor: string[]
  ): string {
    if (singularizeFor?.some(x => menuTypeFilter?.endsWith(x))) {
      return menuTypeFilter?.slice(0, -1);
    }
    return menuTypeFilter;
  }

  protected pluralizeFor = ['Menu', 'Card', 'Label'];
  protected singularizeFor = ['Brands'];

  protected getMenuTypeFilterDescriptionStringForAllMenuFormat(
    menuTypeFilter: string,
    allStartWith: string,
    pluralizeFor: string[] = this.pluralizeFor,
    singularizeFor: string[] = this.singularizeFor
  ): string {
    return this.menuTypeFilterEndsWith(menuTypeFilter, pluralizeFor)
      ? `${allStartWith}"${this.pluralizeMenuTypeFilter(menuTypeFilter, pluralizeFor)}"`
      : `${allStartWith}"${this.singularizeMenuTypeFilter(menuTypeFilter, singularizeFor)}" menus`;
  }

  protected getMenuTypeFilterDescriptionStringForNonTemplatedMenu(
    menuTypeFilter: string,
    allStartWith: string,
    pluralizeFor: string[] = this.pluralizeFor,
    singularizeFor: string[] = this.singularizeFor
  ): string {
    return this.menuTypeFilterEndsWith(menuTypeFilter, pluralizeFor)
      ? `${allStartWith}"${this.pluralizeMenuTypeFilter(menuTypeFilter, pluralizeFor)}"`
      : `${allStartWith}"${this.singularizeMenuTypeFilter(menuTypeFilter, singularizeFor)}" menus`;
  }

  protected getMenuTypeFilterDescriptionStringForTemplatedMenu(
    menuTypeFilter: string,
    allStartWith: string,
    pluralizeFor: string[] = this.pluralizeFor,
    singularizeFor: string[] = this.singularizeFor
  ): string {
    return this.menuTypeFilterEndsWith(menuTypeFilter, pluralizeFor)
      ? `${allStartWith}templated "${this.pluralizeMenuTypeFilter(menuTypeFilter, pluralizeFor)}"`
      : `${allStartWith}templated "${this.singularizeMenuTypeFilter(menuTypeFilter, singularizeFor)}" menus`;
  }

  protected genericEmptyStateTitleCopyForTemplates(
    menuTypeFilter: string,
    templatesName: string,
    allStartWith: string = 'Searching for '
  ): string {
    return menuTypeFilter
      ? this.getMenuTypeFilterDescriptionStringForTemplates(menuTypeFilter, allStartWith)
      : `${allStartWith}${templatesName}`;
  }

  protected getMenuTypeFilterDescriptionStringForTemplates(
    menuTypeFilter: string,
    allStartWith: string,
    pluralizeFor: string[] = this.pluralizeFor,
    singularizeFor: string[] = this.singularizeFor
  ): string {
    return `${allStartWith}"${this.singularizeMenuTypeFilter(menuTypeFilter, singularizeFor)}" templates`;
  }

  protected getEmptyStateDescriptionCopy(
    hasInactiveItems: boolean,
    hasTemplatedItems: boolean,
    hasTag: boolean,
    searchText: boolean
  ): string {
    let description: string;
    switch (true) {
      case searchText && !hasTag:
        description = 'Try a different search query to find the menu that you\'re looking for.';
        break;
      case searchText && hasTag:
        description = 'Try a different search query or tag to find the menu that you\'re looking for.';
        break;
      case hasTag && hasTemplatedItems && hasInactiveItems:
        description = 'Try to filter by a different menu type, menu format or active status.';
        break;
      case hasTag && hasTemplatedItems:
        description = 'Try to filter by a different menu type or menu format.';
        break;
      case hasTag && !hasTemplatedItems:
        description = 'Try to filter by a different menu type or active status.';
        break;
      case !hasTemplatedItems:
        description = 'Try to filter by a different active status.';
        break;
      case hasInactiveItems:
        description = 'Try to filter by a different menu format or active status.';
        break;
      default:
        description = 'Try to filter by a different menu format.';
        break;
    }
    return description;
  }

  protected setEmptyState(
    searchText: string,
    tag: string,
    filterDisplayableItemBy: DisplayableItemFilterByActive,
    menuFormatFilter: DisplayableType,
    menuTypeFilter: string,
    hasInactiveItems: boolean,
    hasTemplatedItems: boolean,
    hasItemsToFilter: boolean
  ) {
    const title = this.getEmptyStateTitleCopy(menuTypeFilter, menuFormatFilter);
    this.updateEmptyStateHelper(
      [searchText, tag, filterDisplayableItemBy, menuFormatFilter, menuTypeFilter],
      {
        noSearchTextNoTag: () => {
          const description = this.getEmptyStateDescriptionCopy(hasInactiveItems, hasTemplatedItems, false, false);
          this.updateEmptyStateInfo(hasItemsToFilter, title, description);
        },
        noSearchTextWithTag: () => {
          const description = this.getEmptyStateDescriptionCopy(hasInactiveItems, hasTemplatedItems, true, false);
          const withThatTag = ` with the tag \"${tag.toUpperCase()}\".`;
          this.updateEmptyStateInfo(hasItemsToFilter, title + withThatTag, description);
        },
        searchTextNoTag: () => {
          const description = this.getEmptyStateDescriptionCopy(hasInactiveItems, hasTemplatedItems, false, true);
          const couldNotBeFound = ` that include \"${searchText}\".`;
          this.updateEmptyStateInfo(hasItemsToFilter, title + couldNotBeFound, description);
        },
        searchTextWithTag: () => {
          const description = this.getEmptyStateDescriptionCopy(hasInactiveItems, hasTemplatedItems, true, true);
          const withThatText = ` that include \"${searchText}\"`;
          const withThatTag = ` with the tag \"${tag.toUpperCase()}\".`;
          this.updateEmptyStateInfo(hasItemsToFilter, title + withThatText + withThatTag, description);
        }
      }
    );
  }

  public readonly loading$ = defer(() => {
    const opts = LoadingOptions.defaultWhiteBackground();
    opts.addRequest('');
    return of(opts);
  });

  public filterByTag$ = this.containerViewModel.activeTagString$;

  protected inactiveItemsFilter$ = this.containerViewModel.selectFilterByInactiveState$;

  protected _emptyStateTitle = new BehaviorSubject<string>('');
  public emptyStateTitle$ = this._emptyStateTitle as Observable<string>;

  protected _emptyStateDesc = new BehaviorSubject<string>('');
  public emptyStateDesc$ = this._emptyStateDesc as Observable<string>;

  private _filteredDisplayableItems = new BehaviorSubject<DisplayableItem[]>(null);
  public filteredDisplayableItems$ = this._filteredDisplayableItems as Observable<DisplayableItem[]>;
  connectToFilteredDisplayableItems = (items: DisplayableItem[]) => this._filteredDisplayableItems.next(items);

  private _paginatedItems = new BehaviorSubject<DisplayableItem[]>(null);
  public paginatedItems$ = this._paginatedItems as Observable<DisplayableItem[]>;
  public connectToPaginatedItems = (paginatedItems: DisplayableItem[]) => this._paginatedItems.next(paginatedItems);

  public readonly menuSubTypeAssociatedToThemeId$ = this.containerViewModel.menuSubTypeAssociatedToThemeId$;
  public selectedDisplayableType$ = this.containerViewModel.selectedDisplayableType$;
  public menuTypeFilter$ = this.containerViewModel.menuTypeFilter$;

  public numberOfItems$ = this.filteredDisplayableItems$.pipe(
    distinctUntilChanged(),
    map(m => m?.length),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public loadingItems$ = this.filteredDisplayableItems$.pipe(
    map(m => !m),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public noItems$ = this.filteredDisplayableItems$.pipe(
    distinctUntilChanged(),
    map(m => m?.length === 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly hidePagination$ = combineLatest([
    this.loadingItems$,
    this.numberOfItems$
  ]).pipe(
    map(([loading, numberOfItems]) => loading || numberOfItems <= this.ITEMS_PER_PAGE),
    debounceTime(5),
    startWith(true)
  );

  protected setNothingToSearchThroughState(): void {
    this._emptyStateTitle.next(this.defaultEmptyStateTitle);
    this._emptyStateDesc.next(this.defaultEmptyStateDescription);
  }

  private generateMenuFormatOptions() {
    const dropdownOptions: DropDownItem[] = [];
    const all = new DropDownItem('All', DisplayableType.All);
    const menus = new DropDownItem('Menus', DisplayableType.NonTemplatedMenu);
    const templates = new DropDownItem('Templates', DisplayableType.TemplatedMenu);
    dropdownOptions.push(all, menus, templates);
    this.containerViewModel.connectToMenuFormatFilterOptions(dropdownOptions);
  }

  protected generateInactiveStateFilterOptions() {
    const dropdownOptions: DropDownItem[] = [];
    const all = new DropDownItem('All', DisplayableItemFilterByActive.All);
    const active = new DropDownItem('Active', DisplayableItemFilterByActive.Active);
    const inactive = new DropDownItem('Inactive', DisplayableItemFilterByActive.Inactive);
    dropdownOptions.push(all, active, inactive);
    this.containerViewModel.connectToInactiveStateFilterOptions(dropdownOptions);
  }

  public listenForEmptyState(): void {
    combineLatest([
      this.containerViewModel.searchText$,
      this.containerViewModel.activeTagString$,
      this.inactiveItemsFilter$,
      this.containerViewModel.selectedDisplayableType$,
      this.containerViewModel.menuTypeFilter$,
      this.containerViewModel.hasInactiveItems$,
      this.containerViewModel.hasTemplatedItems$,
      this.containerViewModel.itemsToFilter$,
      this.containerViewModel.menuSubTypes$
    ]).subscribeWhileAlive({
      owner: this,
      next: ([
        searchText,
        tag,
        filterInactiveMenus,
        menuFormatFilter,
        menuTypeFilter,
        hasInactiveItems,
        hasTemplatedItems,
        itemsToFilter,
        menuSubTypes
      ]) => {
        const menuTypeName = menuSubTypes.find(sub => sub.value === menuTypeFilter)?.name;
        this.setEmptyState(
          searchText,
          tag,
          filterInactiveMenus,
          menuFormatFilter,
          menuTypeName,
          hasInactiveItems,
          hasTemplatedItems,
          itemsToFilter?.length > 0
        );
      }
    });
  }

  // Call from within child, else itemsToFilter$ will be undefined
  protected setupFilter(): void {
    this.startFiltering();
    this.itemsToFilter$.subscribeWhileAlive({
      owner: this,
      next: (items: DisplayableItem[]) => {
        this.containerViewModel.connectToItemsToFilter(items);
      }
    });
  }

  private startFiltering(): void {
    combineLatest([
      this.containerViewModel.searchedItems$,
      this.filterByTag$,
      this.inactiveItemsFilter$,
      this.selectedDisplayableType$,
      combineLatest([
        this.menuTypeFilter$,
        this.menuSubTypeAssociatedToThemeId$
      ])
    ]).pipe(debounceTime(1)).subscribeWhileAlive({
        owner: this,
        next: ([displayableItems, tagString, inactiveFilter, displayableType, [menuTypeFilter, menuTypeMapping]]) => {
          let filteredItems: DisplayableItem[] = displayableItems;
          if (exists(menuTypeFilter)) {
            filteredItems = filteredItems
              ?.filter(item => menuTypeFilter === menuTypeMapping?.get((item as any)?.theme));
          }
          if (exists(tagString)) {
            filteredItems = filteredItems
              ?.filter(item => item?.tags?.some(x => x?.toUpperCase() === tagString?.toUpperCase()));
          }
          switch (displayableType) {
            case DisplayableType.NonTemplatedMenu:
              filteredItems = filteredItems?.filter(item => !item.displayableItemIsTemplatedMenu());
              break;
            case DisplayableType.TemplatedMenu:
              filteredItems = filteredItems?.filter(item => item.displayableItemIsTemplatedMenu());
              break;
          }
          switch (inactiveFilter) {
            case DisplayableItemFilterByActive.Active:
              filteredItems = filteredItems?.filter(item => item?.displayableItemIsActive());
              break;
            case DisplayableItemFilterByActive.Inactive:
              filteredItems = filteredItems?.filter(item => !item?.displayableItemIsActive());
              break;
          }
          filteredItems = filteredItems?.sort(SortUtils.menusByNameAsc);
          this.connectToFilteredDisplayableItems(filteredItems);
        }
      });
  }

  protected updateEmptyStateHelper(
    [searchText, tag, filterInactiveMenus, menuFormatFilter, menuTypeFilter]: DataForEmptyStateHelper,
    emptyStateLambdas: {
      noSearchTextNoTag: () => void,
      noSearchTextWithTag: () => void,
      searchTextNoTag: () => void,
      searchTextWithTag: () => void
    }
  ): void {
    switch (true) {
      case !searchText && !tag:
        emptyStateLambdas?.noSearchTextNoTag?.();
        break;
      case !searchText && tag?.length > 0:
        emptyStateLambdas?.noSearchTextWithTag?.();
        break;
      case searchText?.length > 0 && !tag:
        emptyStateLambdas?.searchTextNoTag?.();
        break;
      case searchText?.length > 0 && tag?.length > 0:
        emptyStateLambdas?.searchTextWithTag?.();
        break;
    }
  }

  protected updateEmptyStateInfo(
    hasItemsToFilter: boolean,
    emptyStateTitle: string,
    emptyStateDesc: string
  ) {
    if (hasItemsToFilter) {
      this._emptyStateTitle.next(emptyStateTitle);
      this._emptyStateDesc.next(emptyStateDesc);
    } else {
      this.setNothingToSearchThroughState();
    }
  }

  protected createMenu(mt: MenuType = MenuType.DisplayMenu): void {
    const onClose = (menu: Menu) => {
      if (menu) {
        this.navigationService.navigateToEditMenuOrTemplate(menu);
      }
    };
    ModalCreateMenu.open(this.ngZone, this.ngbModal, this.injector, mt, onClose);
  }

}
