import { SectionLayout } from '../../utils/dto/section-layout-type';
import { SectionBlueprint } from '../../template/dto/section-blueprint';
import { Deserializable } from '../../protocols/deserializable';
import { SectionType } from '../../enum/dto/section-type';
import type { SectionSortOption } from '../../enum/dto/section-sort-option';

export class NewMenuSectionRequest implements Deserializable {

  public configurationId: string;
  public sectionBlueprintId: string;
  public title: string;
  public priority: number;
  public sectionType: SectionType;
  public layoutType: string;
  public defaultColumnConfigId: string;
  public sorting: SectionSortOption;
  public secondarySorting: SectionSortOption;

  constructor(
    configId: string = null,
    title: string = '',
    priority: number = null,
    sectionType: SectionType = null,
    layoutType: string = SectionLayout.List,
    defaultColumnConfigId: string = null
  ) {
    this.configurationId = configId;
    this.title = title;
    this.priority = priority;
    this.sectionType = sectionType;
    this.layoutType = layoutType;
    this.defaultColumnConfigId = defaultColumnConfigId;
  }

  addConfigurationId(configId: string) {
    this.configurationId = configId;
  }

  useDataFrom(sectionBlueprint: SectionBlueprint) {
    this.sectionBlueprintId = sectionBlueprint.id;
    this.title = sectionBlueprint.title;
    this.sectionType = sectionBlueprint.sectionType;
    this.layoutType = sectionBlueprint.layoutType;
    this.sorting = sectionBlueprint.sorting;
    this.secondarySorting = sectionBlueprint.secondarySorting;
  }

  onDeserialize = () => {};

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-template-service/blob/dev/models/NewSectionRequest.go
  onSerialize() {
    const dto = Object.create(NewMenuSectionRequest.prototype);
    dto.configurationId = this.configurationId;
    dto.sectionBlueprintId = this.sectionBlueprintId;
    dto.title = this.title;
    dto.priority = this.priority;
    dto.sorting = this.sorting;
    dto.secondarySorting = this.secondarySorting;
    dto.sectionType = this.sectionType;
    dto.layoutType = this.layoutType;
    dto.defaultColumnConfigId = this.defaultColumnConfigId;
    return dto;
  }

}
