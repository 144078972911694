import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '../../../utils/string-utils';

@Pipe({
  name: 'replaceMenuWithStack'
})
export class ReplaceMenuWithStackPipe implements PipeTransform {

  transform(
    input: string,
    isCard: boolean,
    isLabel: boolean,
    isShelfTalker: boolean
  ): string {
    if (isCard) input = StringUtils.replaceMenuWithStack(input);
    if (isLabel) input = StringUtils.replaceMenuWithLabelStack(input);
    if (isShelfTalker) input = StringUtils.replaceMenuWithShelfTalkerStack(input);
    return input;
  }

}
