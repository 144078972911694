import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { Variant } from '../../../../../../models/product/dto/variant';
import { MenuStyle } from '../../../../../../models/menu/dto/menu-style';
import { BsError } from '../../../../../../models/shared/bs-error';
import { Theme } from '../../../../../../models/menu/dto/theme';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { MenuSectionProductPreviewViewModel } from './menu-section-product-preview-view-model';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { StringUtils } from '../../../../../../utils/string-utils';
import { map } from 'rxjs/operators';
import { VariantBadge } from '../../../../../../models/product/dto/variant-badge';

@Component({
  selector: 'app-menu-section-product-preview',
  templateUrl: './menu-section-product-preview.component.html',
  styleUrls: ['./menu-section-product-preview.component.scss'],
  providers: [MenuSectionProductPreviewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSectionProductPreviewComponent extends BaseComponent implements OnChanges {

  constructor(
    public viewModel: MenuSectionProductPreviewViewModel
  ) {
    super();
  }

  @Input() gridMode: boolean = false;
  @Input() menu: Menu;
  @Input() section: HydratedSection;
  @Input() variant: Variant;
  @Input() sectionStyles: MenuStyle[];
  @Input() variantStyle: MenuStyle;
  @Input() theme: Theme;
  @Input() invalidFormState: boolean = false;
  @Input() autoSaving: boolean;
  @Input() removingVariants: boolean = false;
  @Input() templateMode: boolean = false;
  @Input() isTemplatedSection: boolean = false;
  @Input() allowBadgeOverride: boolean = true;
  @Input() allowLabelOverride: boolean = true;
  @Input() allowStyleOverride: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() saveSection: (background: boolean, badgeMap?: Map<string, string[]>) => void;
  @Input() removeVariantFromSection: (v: Variant) => Observable<string[]>;
  @Input() redBorder: boolean = true;
  @Output() showLiveView = new EventEmitter<Variant>(true);

  private _loadingOpts = new BehaviorSubject(LoadingOptions.defaultWhiteBackground());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this.loadingOpts$.pipe(map(opts => opts?.isLoading));

  override setupViews() {
    // connect to internal circuit
    this.viewModel.connectToMenu(this.menu);
    this.viewModel.connectToTheme(this.theme);
    this.viewModel.connectToSection(this.section);
    this.viewModel.connectToVariant(this.variant);
    this.viewModel.connectToSectionStyles(this.sectionStyles);
    this.viewModel.connectToTemplateMode(this.templateMode);
    this.viewModel.connectToAllowBadgeOverride(this.allowBadgeOverride);
    this.viewModel.connectToAllowLabelOverride(this.allowLabelOverride);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
    if (changes.theme) this.viewModel.connectToTheme(this.theme);
    if (changes.section) this.viewModel.connectToSection(this.section);
    if (changes.variant) this.viewModel.connectToVariant(this.variant);
    if (changes.sectionStyles) this.viewModel.connectToSectionStyles(this.sectionStyles);
    if (changes.templateMode) this.viewModel.connectToTemplateMode(this.templateMode);
    if (changes.allowBadgeOverride) this.viewModel.connectToAllowBadgeOverride(this.allowBadgeOverride);
    if (changes.allowLabelOverride) this.viewModel.connectToAllowLabelOverride(this.allowLabelOverride);
  }

  handleRemoveVariant() {
    const lm = `Removing ${StringUtils.ellipse(this.variant?.getDisplayName(), 25)}`;
    this.removeVariantFromSection(this.variant)?.subscribeWhileAlive({
      owner: this,
      next: (removingTheseIds) => {
        if (removingTheseIds?.includes(this.variant.id)) {
          if (!this._loadingOpts.containsRequest(lm)) this._loadingOpts.addRequest(lm);
        } else {
          this._loadingOpts.removeRequest(lm);
        }
      },
      error: (err: BsError) => {
        this._loadingOpts.removeRequest(lm);
        throwError(err);
      }
    });
  }

  trackByBadgeId(index: number, badge: VariantBadge) {
    return badge?.id;
  }

}
