import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { EditVariantTerpenesViewModel } from './edit-variant-terpenes-view-model';
import { NavigationService } from '../../../../../../../services/navigation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TerpenesPreviewViewModel } from './terpenes-preview/terpenes-preview-view-model';

@Component({
  selector: 'app-edit-variant-terpenes',
  templateUrl: './edit-variant-terpenes.component.html',
  styleUrls: ['./edit-variant-terpenes.component.scss'],
  providers: [EditVariantTerpenesViewModel, TerpenesPreviewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditVariantTerpenesComponent extends BaseComponent implements Tabbable {

  constructor(
    public viewModel: EditVariantTerpenesViewModel,
    public navigationService: NavigationService,
    private activeModal: NgbActiveModal
  ) {
    super();
  }

  tabActivated: BehaviorSubject<boolean>;

  editProductSettings() {
    this.navigationService.manageProducts();
    this.activeModal.close();
  }

}
