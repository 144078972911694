// noinspection JSUnusedLocalSymbols

import { Injectable } from '@angular/core';
import { ProductAPI } from '../api/product-api';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { BehaviorSubject, combineLatest, defer, forkJoin, iif, merge, Observable, of, Subject } from 'rxjs';
import { BsError } from '../models/shared/bs-error';
import { ToastService } from '../services/toast-service';
import { concatMap, debounceTime, delay, distinctUntilChanged, filter, map, pairwise, reduce, shareReplay, skipUntil, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { DisplayAttributesDomainModel, ProductPipeDisplayAttrsChanges } from './display-attributes-domain-model';
import { CompanyDomainModel } from './company-domain-model';
import { LocationDomainModel } from './location-domain-model';
import { SortUtils } from '../utils/sort-utils';
import { ProductMix } from '../models/utils/dto/product-mix-type';
import { LocationChangedUtils } from '../utils/location-changed-utils';
import { LabelDomainModel } from './label-domain-model';
import { SystemLabel } from '../models/shared/labels/system-label';
import { exists } from '../functions/exists';
import { TaxGroup } from '../models/product/dto/tax-group';
import { TaxesAPI } from '../api/taxes-api';
import { iiif, iiifOnce } from '../utils/observable.extensions';
import { PriceFormat } from '../models/enum/dto/price-format';
import { UsePurpose } from '../models/enum/dto/use-purpose';
import type { CompanyConfiguration } from '../models/company/dto/company-configuration';
import type { DisplayAttribute } from '../models/display/dto/display-attribute';
import type { HydratedSection } from '../models/menu/dto/hydrated-section';
import type { Label } from '../models/shared/label';
import type { LocationConfiguration } from '../models/company/dto/location-configuration';
import type { OverrideProductGroup } from '../models/product/dto/override-product-group';
import type { Product } from '../models/product/dto/product';
import type { Promotion } from '../models/product/dto/promotion';
import type { TaxRate } from '../models/product/dto/tax-rate';
import type { UniversalVariant } from '../models/product/dto/universal-variant';
import type { Variant } from '../models/product/dto/variant';
import type { VariantPricing } from '../models/product/dto/variant-pricing';
import { DistinctUtils } from '../utils/distinct-utils';
import { VariantInventory } from '../models/product/dto/variant-inventory';

// Provided by Logged In Scope
@Injectable()
export class ProductDomainModel extends BaseDomainModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private productAPI: ProductAPI,
    private taxesAPI: TaxesAPI,
    private toastService: ToastService,
    private locationDomainModel: LocationDomainModel,
    private displayAttributeDomainModel: DisplayAttributesDomainModel,
    private labelDomainModel: LabelDomainModel,
  ) {
    super();
     // Don't remove these, they start up the internal shareReplay(1) subscription for each buildProducts pipeline
    this.currentLocationActiveProducts$.subscribe().unsubscribe();
    this.currentLocationDiscontinuedProducts$.subscribe().unsubscribe();
    this.overridedProducts$.subscribe().unsubscribe();
  }

  private readonly locationId$ = this.locationDomainModel.locationId$;
  private readonly locationUsesBudSenseTaxes$ = this.locationDomainModel.locationConfig$.pipe(
    map(locConfig => locConfig?.useBudSenseTaxes)
  );

  private readonly fillProductPipelineHopperWithInitialCompanyDisplayAttrs$
    = this.displayAttributeDomainModel.fillProductPipelineHopperWithInitialCompanyDisplayAttrs$;
  private readonly fillProductPipelineHopperWithInitialLocationDisplayAttrs$
    = this.displayAttributeDomainModel.fillProductPipelineHopperWithInitialLocationDisplayAttrs$;
  private readonly displayAttributes$ = combineLatest([
    this.fillProductPipelineHopperWithInitialLocationDisplayAttrs$,
    this.fillProductPipelineHopperWithInitialCompanyDisplayAttrs$
  ]);

  private readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  private readonly companyConfig$ = this.companyDomainModel.companyConfiguration$;
  private readonly configurations$ = combineLatest([
    this.locationConfig$,
    this.companyConfig$
  ]);

  private readonly allLabels$ = this.labelDomainModel.allLabels$;
  private readonly systemLabels$ = this.labelDomainModel.systemLabels$;
  private readonly companyPOSLabels$ = this.labelDomainModel.companyPOSLabels$;
  private readonly locationSystemLabels$ = this.labelDomainModel.locationSystemLabels$;
  private readonly labels$ = combineLatest([
    this.allLabels$,
    this.systemLabels$,
    this.companyPOSLabels$
  ]);

  private readonly _updatedVariants = new Subject<Variant[]>();
  public readonly updatedVariants$ = this._updatedVariants.pipe(filter(variants => variants?.length > 0));

  private readonly _removeProducts = new Subject<Product[]>();
  public readonly removeProducts$ = this._removeProducts.pipe(filter(products => products?.length > 0));

  private readonly _addProducts = new Subject<Product[]>();
  public readonly addProducts$ = this._addProducts.pipe(
    filter(products => products?.length > 0),
    map(products => {
      return products?.flatMap(p => p?.splitIntoActiveAndDiscontinued())?.filterNulls();
    })
  );

  public readonly addActiveProducts$ = this.addProducts$.pipe(
    map(products => products?.filter(p => p?.isActive())),
    filter(products => products?.length > 0),
  );

  public readonly addDiscontinuedProducts$ = this.addProducts$.pipe(
    map(products => products?.filter(p => !p?.isActive())),
    filter(products => products?.length > 0),
  );

  /* ******************************* Override Product Groups ******************************* */

  private readonly _currentLocationOverrideProductGroups = new BehaviorSubject<OverrideProductGroup[]|null>(null);
  public readonly currentLocationOverrideProductGroups$ = this._currentLocationOverrideProductGroups.pipe(
    map(groups => groups?.sort(SortUtils.nameAscending)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * BudSense created products. These are generated from OverrideProductGroup's. It's a way to squish products together
   * into a single product.
   */
  public readonly consolidatedProducts$ = this.currentLocationOverrideProductGroups$.pipe(
    map(groups => groups?.map(group => group?.consolidatedProduct))
  );

  public readonly consolidatedProductIds$ = this.consolidatedProducts$.pipe(
    map(products => products?.map(p => p?.id))
  );

  public createOverrideProductGroup(grouping: OverrideProductGroup): Observable<OverrideProductGroup> {
    return combineLatest([
      this.locationId$,
      this.currentLocationDiscontinuedProducts$.pipe(map(products => products?.length > 0))
    ]).pipe(
      take(1),
      switchMap(([locationId, showingDiscontinued]) => {
        return this.productAPI.CreateOverrideProductGroup(locationId, grouping, showingDiscontinued);
      }),
      switchMap(createdGrouping => {
        return this.getAffectedOverrideProductGroupIds(createdGrouping).pipe(
          tap(affectedGroupIds => {
            affectedGroupIds?.length > 1
              ? this.dealWithAffectedProductGroups(createdGrouping, affectedGroupIds)
              : this.productGroupCreatedSideEffects(createdGrouping);
          }),
          map(() => createdGrouping)
        );
      }),
      take(1)
    );
  }

  private productGroupCreatedSideEffects(createdGrouping: OverrideProductGroup): void {
    this.currentLocationOverrideProductGroups$.once(overrideProductGroups => {
      this._currentLocationOverrideProductGroups.next([...(overrideProductGroups || []), createdGrouping]);
    });
    this._removeProducts.next(createdGrouping?.products);
    this._addProducts.next([createdGrouping?.consolidatedProduct]?.filterNulls());
  }

  public updateOverrideProductGroup(updatedGroup: OverrideProductGroup): Observable<OverrideProductGroup> {
    const productGroupDeleted = !updatedGroup?.productIds?.length;
    const delete$ = defer(() => this.deleteOverrideProductGroup(updatedGroup));
    const update$ = defer(() => this.updateOverrideProductGroupHelper(updatedGroup));
    return iif(() => productGroupDeleted, delete$, update$);
  }

  private updateOverrideProductGroupHelper(updatedGroup: OverrideProductGroup): Observable<OverrideProductGroup> {
    return combineLatest([
      this.locationId$,
      this.showingDiscontinuedProducts$
    ]).pipe(
      take(1),
      switchMap(([locationId, showingDiscontinued]) => {
        return this.productAPI.UpdateOverrideProductGroup(locationId, updatedGroup, showingDiscontinued);
      }),
      switchMap(updatedGrouping => {
        return this.getAffectedOverrideProductGroupIds(updatedGrouping).pipe(
          tap(affectedGroupIds => {
            affectedGroupIds?.length > 1
              ? this.dealWithAffectedProductGroups(updatedGrouping, affectedGroupIds)
              : this.productGroupUpdatedSideEffects(updatedGrouping);
          }),
          map(() => updatedGrouping)
        );
      }),
      take(1)
    );
  }

  private productGroupUpdatedSideEffects(updatedGrouping: OverrideProductGroup): void {
    this.currentLocationOverrideProductGroups$.once(overrideProductGroups => {
      const updatedGroups = overrideProductGroups?.shallowCopy() || [];
      const index = updatedGroups?.findIndex(g => g?.id === updatedGrouping?.id);
      index > -1
        ? updatedGroups.splice(index, 1, updatedGrouping)
        : updatedGroups.push(updatedGrouping);
      this._currentLocationOverrideProductGroups.next(updatedGroups);
    });
    this._removeProducts.next(updatedGrouping?.products);
    const add = [updatedGrouping?.consolidatedProduct, ...(updatedGrouping?.removedProducts || [])]?.filterNulls();
    this._addProducts.next(add);
  }

  public deleteOverrideProductGroup(group: OverrideProductGroup): Observable<OverrideProductGroup> {
    return this.locationId$.pipe(
      take(1),
      switchMap(locationId => this.productAPI.DeleteOverrideProductGroup(locationId, group)),
      tap(() => this.productGroupDeletedSideEffects(group)),
      map(() => group),
      take(1)
    );
  }

  private productGroupDeletedSideEffects(deletedGrouping: OverrideProductGroup): void {
    this.currentLocationOverrideProductGroups$.once(overrideProductGroups => {
      const updatedGroups = overrideProductGroups?.shallowCopy();
      const index = updatedGroups?.findIndex(g => g?.id === deletedGrouping?.id);
      if (index > -1) updatedGroups.splice(index, 1);
      this._currentLocationOverrideProductGroups.next(updatedGroups);
    });
    deletedGrouping?.products?.forEach(product => product?.removeOverrideGroupName());
    this._addProducts.next(deletedGrouping?.products);
    this._removeProducts.next([deletedGrouping?.consolidatedProduct]?.filterNulls());
  }

  /**
   * returns a list of all override product group ids that are affected by the create/update operation.
   */
  private getAffectedOverrideProductGroupIds(changed: OverrideProductGroup): Observable<string[]> {
    return this.currentLocationOverrideProductGroups$.pipe(
      take(1),
      map(groups => {
        const affectedGroups = groups
          ?.filter(group => group?.productIds?.intersection(changed?.productIds)?.length)
          ?.map(group => group?.id);
        return [changed?.id, ...(affectedGroups || [])]?.unique();
      })
    );
  }

  public fixAffectedOverrideProductGroupings(
    groupThatCausedProblems: OverrideProductGroup,
    idsToFetch: string[]
  ): Observable<OverrideProductGroup[]> {
    const getSpecificGroupings = (locationId: number)  => {
      return this.productAPI.GetOverrideProductGroups(locationId, idsToFetch).pipe(
        withLatestFrom(this.currentLocationOverrideProductGroups$)
      );
    };
    return this.locationId$.pipe(
      take(1),
      switchMap(getSpecificGroupings),
      map(([fetchedGroupings, overrideProductGroups]) => {
        const affectedGroupings = [...(fetchedGroupings || []), groupThatCausedProblems];
        const updatedGroups = overrideProductGroups?.shallowCopy() || [];
        let productsWithinGroups: Product[] = [];
        let productsRemovedFromGroups: Product[] = [];
        const deletedConsolidatedProducts: Product[] = [];
        const updatedConsolidatedProducts: Product[] = [];
        const affectedGroupIds = [...(idsToFetch || []), groupThatCausedProblems?.id];
        affectedGroupIds?.forEach(affectedId => {
          const index = updatedGroups?.findIndex(g => g?.id === affectedId);
          const affectedGroupFromAPI = affectedGroupings?.find(g => g?.id === affectedId);
          const deletedFromBackend = !affectedGroupFromAPI;
          if (deletedFromBackend) {
            if (index > -1) {
              const deletedGroup = updatedGroups?.find(g => g?.id === affectedId);
              updatedGroups.splice(index, 1);
              productsRemovedFromGroups.push(...(deletedGroup?.products || []));
              deletedConsolidatedProducts.push(deletedGroup?.consolidatedProduct);
            }
          } else {
            if (index > -1) {
              updatedGroups.splice(index, 1, affectedGroupFromAPI);
              productsWithinGroups.push(...(affectedGroupFromAPI?.products || []));
              productsRemovedFromGroups.push(...(affectedGroupFromAPI?.removedProducts || []));
              updatedConsolidatedProducts.push(affectedGroupFromAPI?.consolidatedProduct);
            } else {
              updatedGroups.push(affectedGroupFromAPI);
              productsWithinGroups.push(...(affectedGroupFromAPI?.products || []));
              productsRemovedFromGroups.push(...(affectedGroupFromAPI?.removedProducts || []));
              updatedConsolidatedProducts.push(affectedGroupFromAPI?.consolidatedProduct);
            }
          }
        });
        productsWithinGroups = productsWithinGroups?.uniqueByProperty('id');
        productsRemovedFromGroups = productsRemovedFromGroups?.uniqueByProperty('id');
        const swappedGroups = (without: Product) => !productsWithinGroups?.find(within => within?.id === without?.id);
        productsRemovedFromGroups = productsRemovedFromGroups?.filter(swappedGroups);
        this._removeProducts.next([...deletedConsolidatedProducts, ...productsWithinGroups]);
        this._addProducts.next([...updatedConsolidatedProducts, ...productsRemovedFromGroups]);
        this._currentLocationOverrideProductGroups.next(updatedGroups);
        return affectedGroupings;
      }),
      take(1)
    );
  }

  /**
   * The user can add a product from one override product group to another. This will cause the product to be removed
   * from the first group, and added to the second group. Therefore, we need to keep track of all groups that are
   * affected by create/update operations, and then update the affected groups accordingly. This can also cause a group
   * to be deleted, if the user removes the last product from a group. A group is considered deleted if you try to fetch
   * it from the backend and it doesn't exist.
   */
  private dealWithAffectedProductGroups(groupThatCausedProblems: OverrideProductGroup, affectedIds: string[]): void {
    if (!affectedIds?.length) return;
    this.fixAffectedOverrideProductGroupings(groupThatCausedProblems, affectedIds).subscribe({
      error: (error: BsError) => this.toastService.publishError(error)
    });
  }

  /* ****************************************************************************************** */

  private dataForComputingProductImplicitData$ = combineLatest([
    this.displayAttributes$,
    this.configurations$,
    this.labels$
  ]);

  private readonly _fetchingCurrentLocationActiveProducts = new BehaviorSubject<boolean>(false);
  public readonly fetchingCurrentLocationActiveProducts$ = this._fetchingCurrentLocationActiveProducts.pipe(
    distinctUntilChanged()
  );

  private readonly _buildingCurrentLocationActiveProducts = new BehaviorSubject<boolean>(true);
  public readonly buildingCurrentLocationActiveProducts$ = this._buildingCurrentLocationActiveProducts.pipe(
    distinctUntilChanged()
  );

  private readonly _currentLocationActiveProducts = new BehaviorSubject<Product[]>(null);
  public readonly currentLocationActiveProducts$ = this.rebuildProductPipelineOnLocationChange(
    this._currentLocationActiveProducts,
    this._buildingCurrentLocationActiveProducts,
    this.addActiveProducts$,
    this.removeProducts$,
    0,
    true,
    true
  );

  public readonly loadingCurrentLocationActiveProducts$ = combineLatest([
    this.currentLocationActiveProducts$,
    this.fetchingCurrentLocationActiveProducts$
  ]).pipe(
    switchMap(([currentLocationProducts, fetching]) => {
      return iif(
        () => !currentLocationProducts || fetching,
        of(true),
        this.buildingCurrentLocationDiscontinuedProducts$.pipe(
          debounceTime(100),
          startWith(true)
        )
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _fetchingCurrentLocationDiscontinuedProducts = new BehaviorSubject<boolean>(false);
  public readonly fetchingCurrentLocationDiscontinuedProducts$ = this._fetchingCurrentLocationDiscontinuedProducts.pipe(
    distinctUntilChanged()
  );

  private readonly _buildingCurrentLocationDiscontinuedProducts = new BehaviorSubject<boolean>(false);
  public readonly buildingCurrentLocationDiscontinuedProducts$ = this._buildingCurrentLocationDiscontinuedProducts.pipe(
    distinctUntilChanged()
  );

  private readonly _currentLocationDiscontinuedProducts = new BehaviorSubject<Product[]>(null);
  public currentLocationDiscontinuedProducts$ = this.rebuildProductPipelineOnLocationChange(
    this._currentLocationDiscontinuedProducts,
    this._buildingCurrentLocationDiscontinuedProducts,
    this.addDiscontinuedProducts$,
    this.removeProducts$,
    1,
    false,
    true
  );

  public readonly loadingCurrentLocationDiscontinuedProducts$ = this.fetchingCurrentLocationDiscontinuedProducts$.pipe(
    switchMap(fetching => {
      return iif(
        () => fetching,
        of(true),
        this.buildingCurrentLocationDiscontinuedProducts$.pipe(
          debounceTime(100),
          startWith(true)
        )
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly showingDiscontinuedProducts$ = this.currentLocationDiscontinuedProducts$.pipe(
    map(products => products?.length > 0)
  );

  private readonly _buildingCurrentLocationProductGroups = new BehaviorSubject<boolean>(true);
  public readonly buildingCurrentLocationProductGroups$ = this._buildingCurrentLocationProductGroups.pipe(
    distinctUntilChanged()
  );

  /**
   * All products that have been binned into an override product group.
   */
  public readonly overridedProducts$ = this.rebuildProductPipelineOnLocationChange(
    this.currentLocationOverrideProductGroups$.pipe(map(groups => groups?.flatMap(g => g?.products) || [])),
    this._buildingCurrentLocationProductGroups,
    of(null),
    of(null),
    2,
    false,
    false
  );

  private readonly productPipelineDisplayAttrsAdjustments$
    = this.displayAttributeDomainModel.productPipelineDisplayAttrsAdjustments$;

  public readonly buildingCurrentLocationProducts$ = combineLatest([
    this.buildingCurrentLocationActiveProducts$,
    this.buildingCurrentLocationDiscontinuedProducts$
  ]).pipe(
    map(([active, discontinued]) => active || discontinued),
    distinctUntilChanged()
  );

  public readonly loadingCurrentLocationProducts$ = combineLatest([
    this.loadingCurrentLocationActiveProducts$,
    this.loadingCurrentLocationDiscontinuedProducts$
  ]).pipe(
    map(([loadingActive, loadingDiscontinued]) => loadingActive || loadingDiscontinued),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * Shallow copy of products that include active and discontinued variants within variants property.
   */
  public readonly currentLocationProducts$ = this.loadingCurrentLocationProducts$.pipe(
    switchMap(loading => {
      if (loading) return of(null);
      return combineLatest([
        this.currentLocationActiveProducts$,
        this.currentLocationDiscontinuedProducts$,
      ]).pipe(
        map(([active, discontinued]) => {
          return (!active ? null : this.consolidateProducts(active, discontinued));
        })
      );
    }),
    startWith<Product[]>(null),
    distinctUntilChanged(DistinctUtils.distinctNulls),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly currentLocationProductsUsePurpose$ = this.currentLocationProducts$.notNull().pipe(
    map(products => {
      const containsRecProducts = products.some(p => p?.variants?.some(v => !v?.isMedical));
      const containsMedProducts = products.some(p => p?.variants?.some(v => v?.isMedical));
      if (containsRecProducts && containsMedProducts) return UsePurpose.ALL;
      else if (containsRecProducts) return UsePurpose.RECREATION;
      else if (containsMedProducts) return UsePurpose.MEDICAL;
      else return null;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /**
   * Do not deep copy the products. Shallow copying and pointer manipulation is 100x faster.
   */
  private consolidateProducts(active: Product[], discontinued: Product[]): Product[] {
    const allProducts = [...(active ?? []), ...(discontinued ?? [])];
    const productMap = new Map<string, Product>();
    allProducts.forEach(product => {
      if (productMap.has(product.id)) {
        const existingProduct = productMap.get(product.id);
        productMap.set(product.id, existingProduct.shallowCopyAndMergeVariants(product));
      } else {
        productMap.set(product.id, product);
      }
    });
    return Array.from(productMap.values());
  }

  /**
   * Null/undefined is used as a loading state for this data pipeline.
   */
  public readonly currentLocationActiveVariants$ = this.currentLocationActiveProducts$.pipe(
    map(products => products?.flatMap(it => it?.variants)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /**
   * Null/undefined is used as a loading state for this data pipeline.
   */
  public readonly currentLocationDiscontinuedVariants$ = this.currentLocationDiscontinuedProducts$.pipe(
    map(products => products?.flatMap(it => it?.variants)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public currentLocationVariants$: Observable<Variant[]> = combineLatest([
    this.currentLocationActiveVariants$,
    this.currentLocationDiscontinuedVariants$
  ]).pipe(
    map(([active, discontinued]) => {
      if (!active) return null;
      return [...active, ...(discontinued || [])];
    }),
    tap(variants => variants?.forEach(variant => variant.variantTitle = variant?.getVariantTitle())),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private _universalVariantMap = new BehaviorSubject<Map<string, UniversalVariant>>(new Map());
  public universalVariantMap$ = this._universalVariantMap as Observable<Map<string, UniversalVariant>>;

  private listenForLocationFirstSet = this.locationId$.pipe(filter(id => !!id), take(1)).subscribe(locationId => {
    this.loadProductsForCurrentLocation();
    this.loadLocationPromotions();
  });

  private listenForLocationChange = LocationChangedUtils.onLocationChange(this, this.locationId$, () => {
    this._currentLocationActiveProducts.next(null);
    this._currentLocationDiscontinuedProducts.next(null);
    this._currentLocationOverrideProductGroups.next(null);
    this._smartFilterDataTableProducts.next(null);
    this._locationPromotions.next(null);
    this.loadProductsForCurrentLocation();
    this.loadLocationPromotions();
  });

  // This connects smart filters with all products datatable
  private _selectedSmartFilterIds = new BehaviorSubject<string[]>(null);
  public selectedSmartFilterIds$ = this._selectedSmartFilterIds as Observable<string[]>;
  private _smartFilterDataTableProducts = new BehaviorSubject<Product[]>(null);
  public smartFilterDataTableProducts$ = this._smartFilterDataTableProducts
    .preventConsecutiveNulls()
    .pipe(startWith<Product[], Product[]>(null));

  // Incomplete Products
  public inStockIncompleteVariants$ = combineLatest([
    this.currentLocationProducts$,
    this.companyDomainModel.rangeCannabinoidsAtCompanyLevel$,
  ]).pipe(
    map(([prods, useRange]) => this.getOutOfStockVariants(prods, useRange, true)),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public allIncompleteVariants$ = combineLatest([
    this.currentLocationProducts$,
    this.companyDomainModel.rangeCannabinoidsAtCompanyLevel$,
  ]).pipe(
    map(([prods, useRange]) => this.getOutOfStockVariants(prods, useRange, false)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _locationPromotions = new BehaviorSubject<Promotion[]>(null);
  public locationPromotions$ = this._locationPromotions as Observable<Promotion[]>;

  public loadingProductsAndDAs$ = combineLatest([
    this.displayAttributeDomainModel.isDALoading$,
    this.loadingCurrentLocationProducts$
  ]).pipe(
    map(([isDALoading, isReloadingProducts]) => (isDALoading || isReloadingProducts))
  );

  private listenForLocationSyncChanges = this.locationDomainModel.locationConfig$.pipe(pairwise())
    .stopWhenInactive()
    .subscribeWhileAlive({
      owner: this,
      next: ([prev, curr]) => {
        const smartDASyncChanged = prev?.lastSmartDisplayAttributeSync !== curr?.lastSmartDisplayAttributeSync;
        const sameLocationIds = prev?.locationId === curr?.locationId;
        if (sameLocationIds && smartDASyncChanged) {
          this.loadProductsForCurrentLocation();
        }
      }
    });

  private rebuildProductPipelineOnLocationChange(
    prods$: Observable<Product[]>,
    _building: BehaviorSubject<boolean>,
    addProds$: Observable<Product[]>,
    removeProds$: Observable<Product[]>,
    workerId: number,
    sortByTitle: boolean,
    waitForGroups: boolean
  ): Observable<Product[]> {
    return this.locationId$.pipe(
      startWith(null),
      pairwise(),
      switchMap(([prev, curr]) => {
        const initialLoad$ = defer(() => of(null));
        const locationChanged$ = defer(() => of(prev !== curr).pipe(debounceTime(1000), filter(x => x)));
        return iif(() => !prev, initialLoad$, locationChanged$);
      }),
      switchMap(() => {
        return this.buildProducts(prods$, _building, addProds$, removeProds$, workerId, sortByTitle, waitForGroups);
      }),
      // Don't use shareReplay(1) unless you understand the implications of it. It will cause the pipeline to
      // leak if you don't properly unsubscribe from it.
      shareReplay(1),
      takeUntil(this.onDestroy)
    );
  }

  /**
   * DO NOT CHANGE THIS PIPE WITHOUT KEVIN'S PERMISSION.
   * Look at all the data that we stitch together in order to build product objects correctly. Don't mess
   * with this pipe unless you know what you're doing, or else you will break all product flows in the app.
   *
   * God Help Me.
   */
  private buildProducts(
    products$: Observable<Product[]>,
    _buildingProducts: BehaviorSubject<boolean>,
    addProducts$: Observable<Product[]>,
    removeProducts$: Observable<Product[]>,
    webWorkerNumber: number,
    sortByTitle: boolean = false,
    waitForProductGroups: boolean
  ): Observable<Product[]> {
    const productMapping = new Map<string, Product>();
    const buildProducts$ = combineLatest([
      products$.pipe(tap(products => _buildingProducts.next(products?.length > 0))),
      this.dataForComputingProductImplicitData$
    ]).pipe(
      skipUntil(products$.notNull()),
      skipUntil(this.locationConfig$.notNull()),
      skipUntil(this.companyConfig$.notNull()),
      skipUntil(this.fillProductPipelineHopperWithInitialLocationDisplayAttrs$.notNull()),
      skipUntil(this.fillProductPipelineHopperWithInitialCompanyDisplayAttrs$.notNull()),
      skipUntil(this.allLabels$.notNull()),
      skipUntil(this.systemLabels$.notNull()),
      skipUntil(this.companyPOSLabels$.notNull())
    ).pipe(
      debounceTime(100),
      switchMap(([prods, [[_, __], [locConfig, companyConfig], [allLabels, systemLabels, ____]]]) => {
        const hasSecondaryPriceGroupId = exists(locConfig?.secondaryPriceGroupId);
        /* *************************** Pipeline Pieces *************************** */
        const calculateStaticData = (products: Product[]|null): Product[]|null => {
          // In normal circumstances, try not to peek into pipes like this, but this is a special case, since this
          // pipeline operates a lot like C, where I manipulate memory pointers to speed up the pipeline.
          const locDAs = this.displayAttributeDomainModel.peekIntoLocationDisplayAttrPipe();
          const companyDAs = this.displayAttributeDomainModel.peekIntoCompanyDisplayAttrPipe();
          this.setProductDisplayAttributes(locDAs, companyDAs, products);
          products?.forEach(prod => {
            // order matters, some of the methods below rely on computeRangedPropertiesFromCompanyConfig being first
            prod.computeRangedPropertiesFromCompanyConfig(companyConfig);
            prod.computeLabelPropertyForProductTable(locConfig, allLabels, systemLabels);
            if (hasSecondaryPriceGroupId) {
              prod.computeAndSetSecondaryCompanyPrice(locConfig?.secondaryPriceGroupId);
            }
            prod.computeSmartFilterHydrationProperties(
              locConfig?.locationId,
              companyConfig?.companyId,
              locConfig?.priceFormat,
              systemLabels
            );
            prod.setSortingProperties(
              locConfig?.locationId,
              companyConfig?.companyId,
              locConfig?.priceFormat,
              systemLabels,
              false,
              companyConfig?.enabledCannabinoids,
              companyConfig?.enabledTerpenes
            );
          });
          return products;
        };
        const updateProductVariants$: Observable<Product[]> = this.updatedVariants$.pipe(
          map(variants => {
            const updateProduct = (variant: Variant) => {
              const product = productMapping?.get(variant?.productId);
              product?.replaceVariantAndShallowCopyListIfVariantExists(variant);
              return product;
            };
            return calculateStaticData(variants?.map(updateProduct)?.filterNulls());
          })
        );
        const addProductsWrapper$ = addProducts$.notNull().pipe(
          map(products => calculateStaticData(products))
        );
        const constructiveProductUpdate$ = (mainProducts: Product[]): Observable<Product[]> => {
          return merge(of(mainProducts), updateProductVariants$, addProductsWrapper$).notNull().pipe(
            map(products => {
              products?.forEach(product => {
                // Edit prods (main product input) memory location with updated data
                // IN NORMAL CIRCUMSTANCES, DON'T DO THIS. THIS IS A HACK TO SPEED UP THE PRODUCT PIPELINE.
                const index = prods?.findIndex(p => p?.id === product?.id);
                index > -1 ? prods?.splice(index, 1, product) : prods?.push(product);
                productMapping?.set(product?.id, product);
              });
              return products;
            })
          );
        };
        const destructiveProductUpdate$ = removeProducts$.notNull().pipe(
          map(products => {
            products?.forEach(product => {
              // Edit prods (main product input) memory location with updated data
              // IN NORMAL CIRCUMSTANCES, DON'T DO THIS. THIS IS A HACK TO SPEED UP THE PRODUCT PIPELINE.
              const index = prods?.findIndex(p => p?.id === product?.id);
              (index > 1) && prods?.splice(index, 1);
              productMapping?.delete(product?.id);
            });
            return products;
          })
        );
        const fastDisplayAttributeUpdate$ = (products: Product[]): Observable<Product[]> => {
          const displayAttrAdjustments$ = this.productPipelineDisplayAttrsAdjustments$.pipe(
            map(adjustments => {
              return this.displayAttrAdjustments(
                [products, productMapping, locConfig?.priceFormat],
                [adjustments, locConfig, companyConfig],
                [allLabels, systemLabels]
              );
            })
          );
          return merge(of(products), displayAttrAdjustments$).notNull();
        };
        /* ***************************** Build Pipeline ***************************** */
        return !prods
          ? of(null)
          // Parse products over time to prevent blocking the main thread. It's better to process a small amount of
          // products in quick bursts, than to process a large amount of products all at once. This is because
          // the small bursts allow the event loop to schedule other tasks in between, like rendering, which prevents
          // the app from freezing.
          : of(...(prods?.chunkedList(3) || [])).pipe(
            concatMap(chunk => of(calculateStaticData(chunk)).pipe(delay(1))),
            reduce((acc, curr) => { acc.push(...curr); return acc; }, [] as Product[]),
            tap(products => {
              productMapping?.clear();
              products?.map(product => productMapping?.set(product?.id, product));
            }),
            switchMap(products => merge(destructiveProductUpdate$, constructiveProductUpdate$(products)).notNull()),
            switchMap(() => {
              const sortByTitle$ = SortUtils.productNameSortWorker(productMapping, webWorkerNumber).pipe(
                map(sortedIds => sortedIds?.map(id => productMapping?.get(id))),
                take(1),
              );
              return iif(() => sortByTitle, sortByTitle$, of([...(productMapping?.values() || [])]));
            }),
            switchMap(products => fastDisplayAttributeUpdate$(products)),
            map(products => products?.shallowCopy() ?? null)
          );
      }),
      tap(() => _buildingProducts.next(false)),
      startWith<Product[]>(null)
    );
    const waitingForProductGroups$ = defer(() => {
      return this.currentLocationOverrideProductGroups$.pipe(map(groups => !groups), distinctUntilChanged());
    });
    return waitForProductGroups
      ? iiif(waitingForProductGroups$, of(null), buildProducts$)
      : buildProducts$;
  }

  private listenForCompanyAndLocationConfigChanges = combineLatest([
    this.companyDomainModel.companyConfiguration$,
    this.locationDomainModel.locationConfig$
  ]).pipe(debounceTime(100), pairwise())
    .stopWhenInactive()
    .pipe(
      switchMap((([[prevCompConfig, prevLocConfig], [currCompConfig, currLocConfig]]) => {
        return combineLatest([
          prevCompConfig?.changesRequireProductRefresh$(currCompConfig) || of(false),
          prevLocConfig?.changesRequireProductRefresh$(currLocConfig) || of(false)
        ]);
      })),
      debounceTime(100),
    ).subscribeWhileAlive({
      owner: this,
      next: (requiresRefresh) => {
        if (requiresRefresh?.includes(true)) {
          this.loadProductsForCurrentLocation();
        }
      }
    });

  /**
   * Lives at the bottom of the buildProducts pipeline. This allows for fast updates to display attribute data.
   */
  private displayAttrAdjustments = (
    [products, productMapping, priceFormat]: [Product[], Map<string, Product>, PriceFormat],
    [adjustments, locConfig, compConfig]: [ProductPipeDisplayAttrsChanges, LocationConfiguration, CompanyConfiguration],
    [labels, systemLabels]: [Label[], SystemLabel[]],
  ): Product[] => {
    const updates = adjustments?.attrsToUpdate || [];
    const removals = adjustments?.attrsToRemove || [];
    const idsThatNeedUpdating = (DAs: DisplayAttribute[]) => DAs?.map(attr => attr?.objectId)?.unique();
    const idsThatNeedChanges = idsThatNeedUpdating([...updates, ...removals]);
    const productsThatNeedChanges = products?.filter(product => {
      return idsThatNeedChanges?.includes(product?.id)
          || product?.variants?.some(variant => idsThatNeedChanges?.includes(variant?.id));
    });
    const locId = locConfig?.locationId;
    // make deep copy of changed products so memoized pipelines fire when display attributes change
    const updateTheseProducts = productsThatNeedChanges?.deepCopy();
    updateTheseProducts?.forEach(product => {
      product?.updateDisplayAttributes(updates, removals);
      product?.computeLabelPropertyForProductTable(locConfig, labels, systemLabels);
      product?.computeSmartFilterHydrationProperties(locId, compConfig?.companyId, priceFormat, systemLabels);
      product?.setSortingProperties(
        locId,
        compConfig?.companyId,
        priceFormat,
        systemLabels,
        false,
        compConfig?.enabledCannabinoids,
        compConfig?.enabledTerpenes
      );
      const replacementIndex = products?.findIndex(p => p?.id === product?.id);
      if (replacementIndex > -1) products?.splice(replacementIndex, 1, product);
      productMapping?.set(product?.id, product);
    });
    return products;
  };

  private getOutOfStockVariants(prods: Product[], useRange: boolean, excludeIfOutOfStock: boolean): Variant[] {
    const incompleteVariants: Variant[] = [];
    prods?.forEach((prod) => {
      prod?.variants.forEach((variant) => {
        if (variant.isIncomplete(useRange, excludeIfOutOfStock)) {
          incompleteVariants.push(variant);
        }
      });
    });
    return incompleteVariants;
  }

  /**
   * Updates a section's products list with the current location products list,
   * and the inventory value is taken from the latest modified data set between the two.
   * This is because the section product doesn't contain display attributes,
   * but the current location product list does.
   * This function is lexically scoped.
   */
  applyProductDetailsToHydratedSection = <T extends HydratedSection>(section: T): Observable<T> => {
    return this.currentLocationProducts$.pipe(
      map(currentLocationProducts => {
        return section?.replaceProductsWithDeepCopyFromPoolButKeepLatestInventory(currentLocationProducts) as T;
      })
    );
  };

  setSmartFilterDataTableProducts(selectedSmartFilterIds: string[], products: Product[]) {
    this._selectedSmartFilterIds.next(selectedSmartFilterIds);
    this._smartFilterDataTableProducts.next(products);
  }

  loadProductsForCurrentLocation() {
    this.loadActiveProductsForCurrentLocation();
    this.loadOverrideProductGroupsForCurrentLocation();
  }

  private loadActiveProductsForCurrentLocation() {
    this._fetchingCurrentLocationActiveProducts.next(true);
    this.locationId$.pipe(
      switchMap(locationId => this.productAPI.GetLocationProducts(locationId)),
      take(1)
    ).subscribe({
      next: prods => {
        this._currentLocationActiveProducts.next(prods);
        this._fetchingCurrentLocationActiveProducts.next(false);
      },
      error: (error: BsError) => {
        this._fetchingCurrentLocationActiveProducts.next(false);
        this.toastService.publishError(error);
      }
    });
  }

  public loadDiscontinuedProductsForCurrentLocation() {
    this._fetchingCurrentLocationDiscontinuedProducts.next(true);
    this.locationId$.pipe(
      switchMap(locationId => this.productAPI.GetLocationProducts(locationId, ProductMix.Discontinued)),
      take(1)
    ).subscribe({
      next: prods => {
        this._currentLocationDiscontinuedProducts.next(prods);
        this._fetchingCurrentLocationDiscontinuedProducts.next(false);
      },
      error: (error: BsError) => {
        this.toastService.publishError(error);
        this._fetchingCurrentLocationDiscontinuedProducts.next(false);
      }
    });
  }

  private loadOverrideProductGroupsForCurrentLocation() {
    this.locationId$.pipe(
      take(1),
      switchMap(locationId => this.productAPI.GetOverrideProductGroups(locationId))
    ).subscribe({
      next: overrideProductGroups => this._currentLocationOverrideProductGroups.next(overrideProductGroups),
      error: (error: BsError) => this.toastService.publishError(error)
    });
  }

  reloadSpecificProducts(products: Product[]) {
    this.locationId$.pipe(
      take(1),
      switchMap(locationId => this.productAPI.GetUpdatedLocationProducts(locationId, products))
    ).subscribe({
      next: updatedProducts => {
        this._removeProducts.next(updatedProducts);
        this._addProducts.next(updatedProducts);
      },
      error: (error: BsError) => this.toastService.publishError(error)
    });
  }

  setProductDisplayAttributes(
    locationDAs: DisplayAttribute[],
    companyDAs: DisplayAttribute[],
    locationProducts: Product[]
  ) {
    locationProducts?.forEach(product => product?.replaceDisplayAttributesUsingGlobalPool(locationDAs, companyDAs));
  }

  /**
   * The adding of display attributes and calculation of label happens within the buildProducts pipeline!
   *
   * Locations using BudSense managed taxes must update the product type / variant type first, then update the variant
   * pricing objects. This will ensure the proper tax group is selected when hydrating the pricing objects on the API.
   */
  updateVariants(variants: Variant[]): Observable<Variant[]> {
    return iiifOnce(
      this.locationUsesBudSenseTaxes$,
      this.synchronouslyUpdateVariantData(variants),
      this.asynchronouslyUpdateVariantData(variants)
    );
  }

  updateVariantInventories(inventories: VariantInventory[]): Observable<VariantInventory[]> {
    return this.productAPI.UpdateVariantInventory(inventories);
  }

  /**
   * Note, if this pipeline changes, then synchronouslyUpdateVariantData most likely needs to be updated as well.
   */
  private asynchronouslyUpdateVariantData(variants: Variant[]): Observable<Variant[]> {
    return defer(() => {
      const variantPricing = variants?.flatMap(v => v.locationPricing)?.filterNulls() || [];
      return forkJoin([
        this.locationId$.pipe(take(1), switchMap(locId => this.productAPI.UpdateVariants(locId, variants))),
        this.locationId$.pipe(take(1), switchMap(locId => this.productAPI.UpdateVariantPricing(locId, variantPricing))),
        this.currentLocationVariants$.pipe(take(1))
      ]);
    }).pipe(
      map(([updatedVariants, updatedVariantPricing, currentVariants]) => {
        return this.updateLocationPricingOnUpdatedVariants(currentVariants, updatedVariants, updatedVariantPricing);
      }),
      tap(updatedVariants => this._updatedVariants.next(updatedVariants))
    );
  }

  /**
   * Note, if this pipeline changes, then asynchronouslyUpdateVariantData most likely needs to be updated as well.
   */
  private synchronouslyUpdateVariantData(variants: Variant[]): Observable<Variant[]> {
    return defer(() => {
      const variantPricing = variants?.flatMap(v => v.locationPricing)?.filterNulls() || [];
      return combineLatest([
        this.currentLocationVariants$,
        this.locationId$
      ]).pipe(
        take(1),
        switchMap(([currentVariants, locId]) => {
          return this.productAPI.UpdateVariants(locId, variants).pipe(
            switchMap(updatedVariants => {
              return this.productAPI.UpdateVariantPricing(locId, variantPricing).pipe(
                map(updatedVariantPricing => [updatedVariants, updatedVariantPricing] as [Variant[], VariantPricing[]])
              );
            }),
            map(([updatedVariants, updatedVarPricing]) => {
              return this.updateLocationPricingOnUpdatedVariants(currentVariants, updatedVariants, updatedVarPricing);
            }),
            tap(updatedVariants => this._updatedVariants.next(updatedVariants))
          );
        })
      );
    });
  }

  private updateLocationPricingOnUpdatedVariants(
    currentVariants: Variant[],
    updatedVariants: Variant[],
    updatedVariantPricing: VariantPricing[]
  ): Variant[] {
    updatedVariants?.forEach(updatedVariant => {
      const oldVariant = currentVariants?.find(v => v.id === updatedVariant.id);
      const updatedLocPricingForVariant = updatedVariantPricing?.filter(vp => vp.variantId === oldVariant.id);
      updatedVariant.locationPricing = updatedLocPricingForVariant || oldVariant.locationPricing;
      updatedVariant.applyNonUpdatableProperties(oldVariant);
    });
    return updatedVariants;
  }

  public getUniversalVariant(v: Variant): Observable<UniversalVariant> {
    return this.universalVariantMap$.pipe(
      take(1),
      switchMap(variantMap => {
        return iif(
          () => variantMap?.has(v?.id),
          of(variantMap?.get(v?.id)),
          this.fetchUniversalVariant(v?.id)
        );
      })
    );
  }

  private fetchUniversalVariant(variantId: string): Observable<UniversalVariant> {
    return this.productAPI.GetUniversalVariants([variantId]).pipe(
      map(updatedVariantMap => {
        this.updateUniversalVariantMap(updatedVariantMap);
        return updatedVariantMap?.get(variantId);
      })
    );
  }

  private updateUniversalVariantMap(updatedVariantMap: Map<string, UniversalVariant>): void {
    this.universalVariantMap$.once(variantMap => {
      updatedVariantMap?.forEach((value, key) => variantMap?.set(key, value));
      this._universalVariantMap.next(variantMap);
    });
  }

  public loadLocationPromotions(): void {
    this.locationId$.pipe(
      switchMap(locationId => {
        return this.productAPI.GetLocationPromotions(locationId);
      })
    ).once(locationPromotions => this._locationPromotions.next(locationPromotions));
  }

  /* ******************************* Tax Rates And Groups ******************************* */

  private readonly _taxRates = new BehaviorSubject<TaxRate[]>(null);
  public readonly taxRates$ = combineLatest([
    this.locationId$,
    this._taxRates,
  ]).pipe(
    map(([locationId, taxRates]) => {
      return taxRates?.filter(rate => (rate?.locationId === locationId) || rate?.isCompanyRate);
    })
  );
  connectToTaxRates = (taxRates: TaxRate[]): void => this._taxRates.next(taxRates?.sort(SortUtils.nameAscending));

  public readonly fetchProductsAfterChangingTaxesFlag$ = new BehaviorSubject<boolean>(null);
  public setFetchProductsAfterChangingTaxesFlag = () => this.fetchProductsAfterChangingTaxesFlag$.next(true);

  /* ****** Rates ****** */

  public createTaxRate(createTaxRate: TaxRate): Observable<TaxRate> {
    return combineLatest([this.locationId$, this.companyDomainModel.companyId$]).pipe(
      take(1),
      switchMap(([locationId, companyId]) => {
        const req = createTaxRate;
        req.locationId = createTaxRate.isCompanyRate ? companyId : locationId;
        return this.taxesAPI.CreateTaxRate(locationId, req);
      }),
      withLatestFrom(this.taxRates$),
      map(([newTaxRate, currentTaxRates]) => {
        this.connectToTaxRates([...(currentTaxRates || []), newTaxRate]);
        return newTaxRate;
      })
    );
  }

  public updateTaxRate(updateTaxRate: TaxRate): Observable<TaxRate> {
    return combineLatest([this.locationId$, this.companyDomainModel.companyId$]).pipe(
      take(1),
      switchMap(([locationId, companyId]) => {
        const req = updateTaxRate;
        req.locationId = updateTaxRate.isCompanyRate ? companyId : locationId;
        return this.taxesAPI.UpdateTaxRate(locationId, req);
      }),
      withLatestFrom(this.taxRates$),
      map(([updatedTaxRate, currentTaxRates]) => {
        const updatedTaxRates = currentTaxRates?.shallowCopy() || [];
        const i = updatedTaxRates?.findIndex(taxRate => taxRate?.id === updatedTaxRate?.id);
        if (i > -1) updatedTaxRates.splice(i, 1, updatedTaxRate);
        this.connectToTaxRates(updatedTaxRates);
        this.updateTaxRateWithinTaxGroups(updatedTaxRate);
        this.setFetchProductsAfterChangingTaxesFlag();
        return updatedTaxRate;
      })
    );
  }

  public deleteTaxRate(deleteTaxRate: TaxRate): Observable<string> {
    return this.locationId$.pipe(
      take(1),
      switchMap((locationId: number) => this.taxesAPI.DeleteTaxRate(locationId, deleteTaxRate)),
      withLatestFrom(this.taxRates$),
      map(([deleteString, taxRates]) => {
        const updatedTaxRates = taxRates?.shallowCopy() || [];
        const i = updatedTaxRates.findIndex(taxRate => taxRate?.id === deleteTaxRate?.id);
        if (i > -1) {
          updatedTaxRates.splice(i, 1);
          this.connectToTaxRates(updatedTaxRates);
          this.deleteTaxRateFromTaxGroups(deleteTaxRate);
          this.setFetchProductsAfterChangingTaxesFlag();
        }
        return deleteString;
      })
    );
  }

  private updateTaxRateWithinTaxGroups(updatedTaxRate: TaxRate): void {
    this.taxGroups$.once(taxGroups => {
      const updatedTaxGroups = taxGroups?.shallowCopy();
      updatedTaxGroups?.forEach((taxGroup, index) => {
        const indexOfTaxRate = taxGroup?.taxRates?.findIndex(rate => rate?.id === updatedTaxRate?.id);
        if (indexOfTaxRate > -1) {
          const deepCopyOfTaxGroup = window?.injector?.Deserialize.instanceOf(TaxGroup, taxGroup);
          const updatedTaxRates = deepCopyOfTaxGroup?.taxRates?.shallowCopy();
          updatedTaxRates?.splice(indexOfTaxRate, 1, updatedTaxRate);
          deepCopyOfTaxGroup.taxRates = updatedTaxRates;
          updatedTaxGroups.splice(index, 1, deepCopyOfTaxGroup);
        }
      });
      this.connectToTaxGroups(updatedTaxGroups);
    });
  }

  private deleteTaxRateFromTaxGroups(deleteTaxRate: TaxRate): void {
    this.taxGroups$.once(taxGroups => {
      const updatedTaxGroups = taxGroups?.shallowCopy();
      updatedTaxGroups?.forEach((taxGroup, index) => {
        const indexOfTaxRate = taxGroup?.taxRates?.findIndex(rate => rate?.id === deleteTaxRate?.id);
        if (indexOfTaxRate > -1) {
          const deepCopyOfTaxGroup = window?.injector?.Deserialize.instanceOf(TaxGroup, taxGroup);
          const updatedTaxRates = deepCopyOfTaxGroup?.taxRates?.shallowCopy();
          updatedTaxRates?.splice(indexOfTaxRate, 1);
          deepCopyOfTaxGroup.taxRates = updatedTaxRates;
          updatedTaxGroups.splice(index, 1, deepCopyOfTaxGroup);
        }
      });
      this.connectToTaxGroups(updatedTaxGroups);
    });
  }

  /* ****** Groups ****** */

  private readonly _taxGroups = new BehaviorSubject<TaxGroup[]>(null);
  public readonly taxGroups$ = this._taxGroups as Observable<TaxGroup[]>;
  connectToTaxGroups = (taxGroups: TaxGroup[]): void => this._taxGroups.next(taxGroups?.sort(SortUtils.nameAscending));

  public createTaxGroup(request: TaxGroup): Observable<TaxGroup> {
    return this.taxesAPI.CreateTaxGroup(request).pipe(
      withLatestFrom(this.taxGroups$),
      map(([newTaxGroup, currentTaxGroup]) => {
        this.connectToTaxGroups([...(currentTaxGroup || []), newTaxGroup]);
        return newTaxGroup;
      }),
      tap(() => this.fetchTaxRates())
    );
  }

  public updateTaxGroup(request: TaxGroup): Observable<TaxGroup> {
    return this.taxesAPI.UpdateTaxGroup(request).pipe(
      withLatestFrom(this.taxGroups$),
      map(([updatedTaxGroup, currentTaxGroups]) => {
        const i = currentTaxGroups?.findIndex(g => g?.id === updatedTaxGroup?.id);
        if (i > -1) {
          const updatedTaxGroups = currentTaxGroups?.shallowCopy();
          updatedTaxGroups.splice(i, 1, updatedTaxGroup);
          this.connectToTaxGroups(updatedTaxGroups);
          this.setFetchProductsAfterChangingTaxesFlag();
        }
        return updatedTaxGroup;
      }),
      tap(() => this.fetchTaxRates())
    );
  }

  public deleteTaxGroup(deleteTaxRate: TaxGroup): Observable<string> {
    return this.locationId$.pipe(
      take(1),
      switchMap((locationId: number) => {
        const req = deleteTaxRate;
        req.locationId = locationId;
        return this.taxesAPI.DeleteTaxGroup(locationId, req);
      }),
      withLatestFrom(this.taxGroups$),
      map(([deleteString, taxGroups]) => {
        const updatedTaxGroups = taxGroups?.shallowCopy();
        const i = updatedTaxGroups.findIndex(taxGroup => taxGroup?.id === deleteTaxRate?.id);
        if (i > -1) {
          updatedTaxGroups.splice(i, 1);
          this.connectToTaxGroups(updatedTaxGroups);
          this.setFetchProductsAfterChangingTaxesFlag();
        }
        return deleteString;
      }),
      tap(() => this.fetchTaxRates())
    );
  }

  private readonly getTaxRates$ = this.locationDomainModel.locationId$.pipe(
    switchMap(locationId => this.taxesAPI.GetTaxRates(locationId)),
    tap((taxRate) => this.connectToTaxRates(taxRate)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly getTaxGroups$ = this.locationDomainModel.locationId$.pipe(
    switchMap(locationId => this.taxesAPI.GetTaxGroups(locationId)),
    tap((taxGroups) => this.connectToTaxGroups(taxGroups)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly fetchTaxesBasedOnLocationId$ = combineLatest([this.getTaxRates$, this.getTaxGroups$]);

  fetchTaxRates(): void {
    this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap(locationId => this.taxesAPI.GetTaxRates(locationId)),
    ).once(taxRates => {
      this.connectToTaxRates(taxRates);
    });
  }

}
