<div [class.border-expanded]="productExpanded">
  <div
    class="card-stack-product-selection-item"
    [class.separator-border]="productExpanded"
    (click)="productClicked.emit(product.id)">
    <div class="f12px bs-medium">{{ product | getProductTitle }}</div>
    <div [hidden]="!(groupSelection.nChildrenSelected$ | push)" class="checked-count">
      ({{ groupSelection.nChildrenSelected$ | push }})
    </div>
    <app-group-selection
      #groupSelection
      [selectedIds]="selectedIds"
      [selection]="product | cardStackProductAsSelectionItem : cardStackVariantIds"
      (bulkAdd)="bulkAddClicked.emit($event)"
      (bulkRemove)="bulkRemoveClicked.emit($event)">
    </app-group-selection>
  </div>

  <div [@expandCollapse]="productExpanded ? 'expanded' : 'collapsed'">
    <div
      *rxFor="let variant of viewModel?.variants$ | push | variantsInCardStack : cardStackVariantIds; trackBy: 'id'"
      class="card-stack-product-selection-item variant-selection-item"
      [style.cursor]="viewOnly ? 'not-allowed' : 'pointer'"
      (click)="variantClicked(variant.id)">
      <div class="flex-container bs-medium gap-8px">
        <img
          *ngIf="variant?.isMedical"
          class="medical-symbol"
          alt=""
          [src]="'/assets/icons/green/outline/medicinal.svg'" />
        <span class="f12px">{{ variant | getVariantTitle }}</span>
        <span class="f11px">{{ variant | variantFormattedSizing : true }}</span>
      </div>
      <app-selectable
        [selectedIds]="selectedIds"
        [selection]="variant"
        (add)="addClicked.emit($event)"
        (remove)="removeClicked.emit($event)">
      </app-selectable>
    </div>
  </div>
</div>
