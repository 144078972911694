<div class="row">
  <div class="col-md-12 col-lg-4">
    <p class="bs-bold f18px">Print Section</p>
    <p class="bs-regular f12px">{{ sectionDescription }}</p>
    <div *rxIf="viewModel.cardStackHasPrintJobs$">
      <lib-button-primary
        [disabled]="viewModel.disableNewPrintJobButton$ | push"
        [disabledTooltip]="viewModel.disabledNewPrintJobTooltip$ | push"
        [iconSrc]="'assets/icons/light/outline/plus.svg'"
        (buttonClicked)="viewModel.openNewPrintJobModal()">
        New Print Job
      </lib-button-primary>
    </div>
  </div>
  <div class="col-md-12 col-lg-8">
    <p class="bs-bold f18px">{{ viewModel.cardStackTitle$ | push }}</p>
    <div>
      <!-- If native change detection isn't used, then ngbModal.open breaks, and doesn't open immediately -->
      <app-bulk-print-jobs-data-table
        *rxIf="viewModel.cardStackHasPrintJobs$"
        [bulkPrintJobs]="viewModel.mostRecentBulkPrintJobs$ | push : 'native'"
        [nItemsToDisplay]="viewModel.recentJobsCount$ | push : 'native'"
        [editPrintCardMode]="editPrintCardMode"
        [editPrintLabelMode]="editPrintLabelMode"
        [editPrintShelfTalkerMode]="editPrintShelfTalkerMode"
        [showPagination]="false">
      </app-bulk-print-jobs-data-table>
      <div *rxIf="!(viewModel.cardStackHasPrintJobs$ | push)" class="pb-24px">
        <lib-button-primary
          [disabled]="viewModel.disableNewPrintJobButton$ | push"
          [disabledTooltip]="viewModel.disabledNewPrintJobTooltip$ | push"
          [iconSrc]="'assets/icons/light/outline/plus.svg'"
          (buttonClicked)="viewModel.openNewPrintJobModal()">
          New Print Job
        </lib-button-primary>
      </div>
    </div>
    <div *rxIf="viewModel.cardStackHasPrintJobs$" class="pt-16px pb-24px">
      <lib-button-neutral
        [iconSrc]="'assets/icons/dark/outline/eye.svg'"
        (buttonClicked)="viewModel.viewAllPrintJobs()">
        View All Print Jobs
      </lib-button-neutral>
    </div>
  </div>
  <hr />
</div>
