import { Injectable, Injector, NgZone } from '@angular/core';
import { EditMarketingMenuViewModel } from '../../../../viewModels/edit-marketing-menu-view-model';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, merge, throwError } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Section } from '../../../../../../models/menu/dto/section';
import { BsError } from '../../../../../../models/shared/bs-error';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { map } from 'rxjs/operators';
import { CardType } from '../../../../../../models/utils/dto/card-type-definition';
import { SyncSmartFilterService } from '../../../../../../services/smart-filter-sync.service';
import { ModalPromptForSectionDelete } from '../../../../../../modals/modal-prompt-for-section-delete';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationService } from '../../../../../../services/navigation.service';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';
import { PrintPDFService } from '../../../../../../services/print-pdf.service';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';
import { ModalNewFeaturedCategorySection } from '../../../../../../modals/modal-new-featured-category-section';
import { DisplayDomainModel } from '../../../../../../domainModels/display-domain-model';
import { TemplateCollectionDomainModel } from '../../../../../../domainModels/template-collection-domain-model';
import { BulkPrintJobDomainModel } from '../../../../../../domainModels/bulk-print-job-domain-model';

@Injectable()
export class EditFeaturedCategoryMenuViewModel extends EditMarketingMenuViewModel {

  constructor(
    protected syncSmartFilterService: SyncSmartFilterService,
    bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    displayDomainModel: DisplayDomainModel,
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    productDomainModel: ProductDomainModel,
    templateDomainModel: TemplateDomainModel,
    templateCollectionDomainModel: TemplateCollectionDomainModel,
    userDomainModel: UserDomainModel,
    menuPreviewService: MenuPreviewService,
    printPDFService: PrintPDFService,
    navigationService: NavigationService,
    toastService: ToastService,
    router: Router,
    ngZone: NgZone,
    ngbModal: NgbModal,
    sanitizer: DomSanitizer,
    activatedRoute: ActivatedRoute,
    injector: Injector
  ) {
    super(
      bulkPrintJobDomainModel,
      displayDomainModel,
      locationDomainModel,
      menuDomainModel,
      productDomainModel,
      templateDomainModel,
      templateCollectionDomainModel,
      userDomainModel,
      menuPreviewService,
      printPDFService,
      navigationService,
      toastService,
      router,
      ngZone,
      ngbModal,
      sanitizer,
      activatedRoute,
      injector
    );
  }

  // Card
  private _cardStartAt = new BehaviorSubject<string | null>(null);
  public cardStartAt$ = merge(this._cardStartAt.notNull(), this.menu$.pipe(map(m => m?.metadata?.cardStartAt)));

  addFeaturedCategoryCard() {
    this.menu$.once(menu => {
      const onClose = (section: Section) => {
        const url = section?.getEditSectionUrl(menu);
        if (url) this.router.navigate([url]).then();
      };
      ModalNewFeaturedCategorySection.open(this.ngZone, this.ngbModal, this.injector, menu, null, onClose);
    });
  }

  navigateToMenuRoot() {
    this.router.navigate([`menus/edit-marketing-menu`], {relativeTo: this.activatedRoute}).then();
  }

  syncSectionSmartFilters(menu: Menu, section: Section) {
    this.syncSmartFilterService.syncSectionSmartFilters(menu, section).subscribe(syncedSection => {
      if (!!syncedSection) {
        this.toastService.publishBannerSuccess(section.whatChangedString(syncedSection));
      }
    }, (error: BsError) => {
      this.toastService.publishBannerFailed(error?.message);
      throwError(error);
    });
  }

  override duplicateSection(section: HydratedSection) {
    combineLatest([
      this.menu$,
      this.allowAutoSaving$
    ]).once(([menu, allowAutoSaving]) => {
      if (allowAutoSaving) this.saveMenu(true);
      const onClose = (newSection: Section) => {
        const url = newSection?.getEditSectionUrl(menu);
        if (url) this.router.navigate([url]).then();
      };
      ModalNewFeaturedCategorySection.open(this.ngZone, this.ngbModal, this.injector, menu, section, onClose);
    });
  }

  promptForDeleteSection(section: Section) {
    const confirmation = (cont) => { if (cont) { this.deleteSection(section); } };
    ModalPromptForSectionDelete.open(this.ngZone, this.ngbModal, this.injector, section, confirmation);
  }

  updateCardType(type: CardType) {
    this.menu$.once(menu => menu.metadata.cardType = type);
  }

  public updateCardStartAt(startAt: string) {
    this._cardStartAt.next(startAt);
  }

}
