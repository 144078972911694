import { DefaultStackedSizeType } from './default-stacked-size-type';
import { DefaultShelfTalkerSize } from '../../enum/dto/default-shelf-talker-size';

export class DefaultShelfTalkerSizeType extends DefaultStackedSizeType {

  override value: DefaultShelfTalkerSize;

  public override getImageSrcFromSize(): string {
    switch (this.value) {
      case DefaultShelfTalkerSize.ShelfTalkerSize_PostCard:  return 'assets/img/preview/Shelf-Talker.svg';
      case DefaultShelfTalkerSize.ShelfTalkerSize_Custom5x7: return 'assets/img/preview/Shelf-Talker.svg';
    }
  }

  public override getNameFromSize(): string {
    switch (this.value) {
      case DefaultShelfTalkerSize.ShelfTalkerSize_PostCard:  return 'Post Card';
      case DefaultShelfTalkerSize.ShelfTalkerSize_Custom5x7: return 'Custom Card';
    }
  }

  public override getDimensionString(): string {
    switch (this.value) {
      case DefaultShelfTalkerSize.ShelfTalkerSize_PostCard:  return '4” x 6”';
      case DefaultShelfTalkerSize.ShelfTalkerSize_Custom5x7: return '5” x 7”';
    }
  }

}
