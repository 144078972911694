import { ChangeDetectionStrategy, Component, Injector, Input, NgZone, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MenuAdditionalOptionsFormComponent } from '../../../../../forms/menu-additional-options/additional-option-forms/menu-additional-options-form';
import { ModalThemeDetails } from '../../../../../../../../modals/modal-theme-details';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormDropDownComponent } from '@mobilefirstdev/reactive-form';
import { EditShelfTalkerBrandsMenuDetailsFormViewModel } from './edit-shelf-talker-brands-menu-details-view-model';

@Component({
  selector: 'app-edit-shelf-talker-brands-menu-details-form',
  templateUrl: './edit-shelf-talker-brands-menu-details-form.component.html',
  styleUrls: ['./edit-shelf-talker-brands-menu-details-form.component.scss'],
  providers:[EditShelfTalkerBrandsMenuDetailsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShelfTalkerBrandsMenuDetailsFormComponent extends MenuAdditionalOptionsFormComponent
  implements OnChanges {

  constructor(
    public viewModel: EditShelfTalkerBrandsMenuDetailsFormViewModel,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @ViewChild('sectionOrientation') protected sectionOrientation: ReactiveFormDropDownComponent;

  openThemeDetails() {
    ModalThemeDetails.open(
      this.ngZone,
      this.ngbModal,
      this.injector,
      this.menu.hydratedTheme,
      this.menu.type,
      this.sectionOrientation?.getMyValue()
    );
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.menu) this.viewModel.setMenu(this.menu);
  }

}
