import { InformationItem } from '../information-item';
import { Menu } from '../../menu/dto/menu';
import { MarketingMenuType } from '../../enum/dto/marketing-menu-type.enum';
import { ThemeUtils } from '../../../utils/theme-utils';

export class RemovedFromMenuInfo {

  static getInformationItems(menu: Menu): InformationItem[] {
    switch (true) {
      case menu?.getSubType() === MarketingMenuType.Category:
        return [
          RemovedFromMenuInfo.getNCardsInfoItem(),
          RemovedFromMenuInfo.getOutOfStockInfoItem()
        ];
      case menu?.isShelfTalkerMenu():
        return [
          RemovedFromMenuInfo.getOutOfStockShelfTalkerInfoItem(),
          ...menu?.isBrandShelfTalkerMenu() ? [RemovedFromMenuInfo.brandShelfTalkerVariantsDoNotHaveABrand()] : [],
          ...menu?.isBrandShelfTalkerMenu() ? [RemovedFromMenuInfo.brandShelfTalkerNBrandsPerCardInfoItem(menu)] : []
        ];
      case menu?.containsStackedContent():
        return [
          RemovedFromMenuInfo.variantGridSizeNotSelected()
        ];
      default:
        return [
          RemovedFromMenuInfo.getNProductInfoItem(menu?.isSpotlightMenu()),
          RemovedFromMenuInfo.getOutOfStockInfoItem()
        ];
    }
  }

  static getOutOfStockInfoItem(): InformationItem {
    return new InformationItem(
      'Out of Stock',
      'If the ‘Show Out of Stock Products’ setting is not enabled on your menu, '
      + 'products with an inventory count of 0 will not appear on the menu.'
    );
  }

  static getNProductInfoItem(isSpotlight = false): InformationItem {
    const initialText = isSpotlight ? 'Spotlight menus are designed to feature a defined-number of products. ' : '';
    return new InformationItem(
      'Product Count',
      `${initialText}`
      + 'You can adjust the amount of products that are shown by changing the ‘Product Row’ '
      + `count in the Section Details portion of the Edit Menu${isSpotlight ? '' : ' Section'} screen. `
      + 'Any excess products will not be displayed on the menu.'
    );
  }

  static getNCardsInfoItem(): InformationItem {
    return new InformationItem(
      'Product Count',
      'Category cards are designed to display a defined-number of products. '
      + 'Any excess products past this number will not be shown.'
    );
  }

  static variantGridSizeNotSelected(): InformationItem {
    return new InformationItem(
      'Variant Grid Column Not Selected',
      'You must select the grid column corresponding with the variant size in order for the variant to be displayed.'
    );
  }

  static getOutOfStockShelfTalkerInfoItem(): InformationItem {
    return new InformationItem(
      'Out of Stock',
      'If the ‘Show Out of Stock Products’ setting is not enabled on your card, '
      + 'products with an inventory count of 0 will not appear on the card.'
    );
  }

  static brandShelfTalkerVariantsDoNotHaveABrand(): InformationItem {
    return new InformationItem(
      'No Brand',
      'Variants need a brand to be displayed on brand shelf talker cards.'
    );
  }

  static brandShelfTalkerNBrandsPerCardInfoItem(menu: Menu): InformationItem {
    const nItems = ThemeUtils.getMaxNumberOfProductsOnShelfTalkerCard(menu);
    const description = Number.isFinite(nItems)
      ? `This shelf talker card was designed to display a maximum of ${nItems} brands. `
        + 'Any excess brands past this number will not be shown.'
      : 'Brand shelf talker cards can only display a limited number of brands. Any excess brands will not be shown.';
    return new InformationItem('Brand Count', description);
  }

}
