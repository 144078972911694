import { SortOrderPosition, WorkerSortUtils } from '../worker/worker-sorter';
import { Observable, of } from 'rxjs';
import { SectionSortProductInfo } from '../models/enum/dto/section-sort-product-info';
import { SectionSortSecondaryCannabinoids } from '../models/enum/dto/section-sort-secondary-cannabinoids';
import { SectionSortTerpenes } from '../models/enum/dto/section-sort-terpenes';
import { StringUtils } from './string-utils';
import { SectionSortOption } from '../models/enum/dto/section-sort-option';
import { map } from 'rxjs/operators';
import { SectionColumnConfigSecondaryPricingData } from '../models/enum/dto/section-column-config-secondary-pricing-data';
import { PriceFormat } from '../models/enum/dto/price-format';
import { SectionColumnConfigKey, SectionColumnConfigProductInfoKey } from '../models/enum/dto/section-column-config-key';
import { PrimaryCannabinoid } from '../models/enum/shared/primary-cannabinoid.enum';
import { SectionColumnConfigKeyType } from '../models/utils/dto/section-column-config-key-type';
import type { Section } from '../models/menu/dto/section';
import type { Variant } from '../models/product/dto/variant';
import type { HydratedSection } from '../models/menu/dto/hydrated-section';
import type { Menu } from '../models/menu/dto/menu';
import type { SystemLabel } from '../models/shared/labels/system-label';
import type { BackgroundSortable } from '../models/protocols/background-sortable';

export class SortUtils extends WorkerSortUtils {

  /**
   * If you run into a bug while sorting, then this may be your culprit. Sorting use to rely on Typescript objects,
   * but now it relies on plain javascript objects, because we don't have access to many of our Typescript objects
   * in web workers. These functions were converted to work with web workers, so prepareForSorting needs to be called
   * on some objects before sorting.
   */
  static prepareForSorting<T extends BackgroundSortable>(
    items: T[],
    locationId?: number,
    companyId?: number,
    priceFormat?: PriceFormat,
    saleLabels?: SystemLabel[],
    hideSale?: boolean,
    enabledCannabinoids?: string[],
    enabledTerpenes?: string[]
  ): void {
    items?.forEach((item) => item.setSortingProperties(
      locationId,
      companyId,
      priceFormat,
      saleLabels,
      hideSale,
      enabledCannabinoids,
      enabledTerpenes
    ));
  }

  static columnOptionKeySortAsc = (a: SectionColumnConfigKeyType, b: SectionColumnConfigKeyType): number => {
    const priority = (key: SectionColumnConfigKey) => {
      return SectionColumnConfigKeyType.getSortOrder()?.findIndex(k => k === key);
    };
    const aOrderNumber = priority(a?.value);
    const bOrderNumber = priority(b?.value);
    return SortUtils.numberAscNullsLast(aOrderNumber, bOrderNumber);
  };
  static columnOptionKeySortDesc = (a: SectionColumnConfigKeyType, b: SectionColumnConfigKeyType): number => {
    const priority = (key: SectionColumnConfigKey) => {
      return SectionColumnConfigKeyType.getSortOrder()?.findIndex(k => k === key);
    };
    const aOrderNumber = priority(a?.value);
    const bOrderNumber = priority(b?.value);
    return SortUtils.numberDescNullsLast(aOrderNumber, bOrderNumber);
  };

  /* *******************************************************************************************
   *                                Represent Sorting in UI                                    *
   * *******************************************************************************************/

  static getVariantPreviewSortByValue(
    sortPosition: SortOrderPosition,
    getCannabinoidText$: (cannabinoid: string) => Observable<string>,
    getTACText$: () => Observable<string>,
    getTerpeneText$: (terpene: string) => Observable<string>,
    getTopTerpeneText$: () => Observable<string>,
    getTotalTerpenesText$: () => Observable<string>,
    [section, variant]: [Section, Variant],
    [quantityValue, typeText, regularPrice, secondaryPrice]: [string, string, any, string],
  ): Observable<string> {
    const sortOption = SortUtils.getSectionSortOptionFromPosition(sortPosition, section);
    const getProductInfoValue = (): Observable<string> => {
      switch (sortOption) {
        case SectionSortProductInfo.BrandAsc:
        case SectionSortProductInfo.BrandDesc:
          return of(variant?.brand);
        case SectionSortProductInfo.CBDAsc:
        case SectionSortProductInfo.CBDDesc:
          return getCannabinoidText$('CBD');
        case SectionSortProductInfo.ClassificationAsc:
        case SectionSortProductInfo.ClassificationDesc:
          return of(variant?.getFormattedClassification());
        case SectionSortProductInfo.ManufacturerAsc:
        case SectionSortProductInfo.ManufacturerDesc:
          return of(variant?.manufacturer);
        case SectionSortProductInfo.PackagedQuantityAsc:
        case SectionSortProductInfo.PackagedQuantityDesc:
          return of(variant?.packagedQuantity.toString());
        case SectionSortProductInfo.PriceAsc:
        case SectionSortProductInfo.PriceDesc:
          return of(regularPrice);
        case SectionSortProductInfo.ProductTypeAsc:
        case SectionSortProductInfo.ProductTypeDesc:
          return of(typeText);
        case SectionSortProductInfo.SecondaryPriceAsc:
        case SectionSortProductInfo.SecondaryPriceDesc:
          return of(secondaryPrice);
        case SectionSortProductInfo.StockAsc:
        case SectionSortProductInfo.StockDesc:
          return of(quantityValue);
        case SectionSortProductInfo.TitleAsc:
        case SectionSortProductInfo.TitleDesc:
          return of(variant?.getVariantTitle());
        case SectionSortProductInfo.TACAsc:
        case SectionSortProductInfo.TACDesc:
          return getTACText$();
        case SectionSortProductInfo.THCAsc:
        case SectionSortProductInfo.THCDesc:
          return getCannabinoidText$('THC');
        case SectionSortProductInfo.TopTerpeneAsc:
        case SectionSortProductInfo.TopTerpeneDesc:
          return getTopTerpeneText$();
        case SectionSortProductInfo.TotalTerpenesAsc:
        case SectionSortProductInfo.TotalTerpenesDesc:
          return getTotalTerpenesText$();
        case SectionSortProductInfo.UnitSizeAsc:
        case SectionSortProductInfo.UnitSizeDesc:
          return of(variant?.getFormattedUnitSize(false));
        case SectionSortProductInfo.VariantTypeAsc:
        case SectionSortProductInfo.VariantTypeDesc:
          return of(variant?.variantType);
        default:
          return of(null);
      }
    };
    const getSecondaryCannabinoidValue = (): Observable<string> => {
      return getCannabinoidText$(sortOption.split('_')?.firstOrNull());
    };
    const getTerpenesValue = (): Observable<string> => {
      const camelizeTerpene = sortOption
        .replace(/_(ASC|DESC)$/, '')
        .split('_')
        .map((it, index) => index === 0 ? it.toLowerCase() : StringUtils.sentenceCase(it))
        .join('');
      return getTerpeneText$(camelizeTerpene);
    };
    switch (true) {
      case Object.values(SectionSortProductInfo).includes(sortOption as SectionSortProductInfo):
        return getProductInfoValue();
      case Object.values(SectionSortSecondaryCannabinoids).includes(sortOption as SectionSortSecondaryCannabinoids):
        return getSecondaryCannabinoidValue();
      case Object.values(SectionSortTerpenes).includes(sortOption as SectionSortTerpenes):
        return getTerpenesValue();
      default:
        return of(null);
    }
  }

  static getVariantPreviewSortSubtext(
    sortPosition: SortOrderPosition,
    menu: Menu,
    sec: HydratedSection,
    variant: Variant,
    priceFormat: PriceFormat
  ): string {
    const option = SortUtils.getSectionSortOptionFromPosition(sortPosition, sec);
    switch (true) {
      case Object.values(SectionSortProductInfo).includes(option as SectionSortProductInfo):
        return SortUtils.getSectionSortProductInfo(option as SectionSortProductInfo, menu, sec, variant, priceFormat);
      case Object.values(SectionSortSecondaryCannabinoids).includes(option as SectionSortSecondaryCannabinoids):
        return SortUtils.getSectionSortCannabinoidsInfo(option as SectionSortSecondaryCannabinoids);
      case Object.values(SectionSortTerpenes).includes(option as SectionSortTerpenes):
        return SortUtils.getSectionSortTerpenesInfo(option as SectionSortTerpenes);
    }
  }

  static getSectionSortProductInfo(
    sortOption: SectionSortProductInfo,
    menu: Menu,
    section: HydratedSection,
    variant: Variant,
    priceFormat: PriceFormat
  ): string {
    switch (true) {
      case sortOption === SectionSortProductInfo.SecondaryPriceAsc:
      case sortOption === SectionSortProductInfo.SecondaryPriceDesc:
        return SortUtils.getSectionSortSecondaryPriceInfo(menu, section, variant, priceFormat);
      default: {
        const splitResult = sortOption.split('_');
        const productInfo = splitResult.length < 3 ? splitResult[0] : `${splitResult[0]} ${splitResult[1]}`;
        return Object.values(PrimaryCannabinoid).includes(productInfo as PrimaryCannabinoid)
          ? productInfo
          : StringUtils.toTitleCase(productInfo);
      }
    }
  }

  static getSectionSortSecondaryPriceInfo(
    menu: Menu,
    section: HydratedSection,
    variant: Variant,
    priceFormat: PriceFormat
  ): string {
    const secondaryPriceColumnConfig = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice);
    switch (secondaryPriceColumnConfig?.dataValue) {
      case SectionColumnConfigSecondaryPricingData.PricePerUOM:
        return `Price per ${variant?.unitOfMeasure}`;
      case SectionColumnConfigSecondaryPricingData.OriginalPrice:
        return `Original Price`;
      case SectionColumnConfigSecondaryPricingData.SaleOriginalPrice:
        return `Sale Original Price`;
      case SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice:
        return `Original and Sale Price`;
      case SectionColumnConfigSecondaryPricingData.TaxesInPrice:
        return `Taxes In Price`;
      case SectionColumnConfigSecondaryPricingData.TaxesInRoundedPrice:
        return `Taxes In (Rounded) Price`;
      case SectionColumnConfigSecondaryPricingData.PreTaxPrice:
        return `Pre Tax Price`;
    }
    if (variant?.hasLocationOrCompanySecondaryPricing()) {
      if (variant?.isLocationPrice(menu?.locationId, menu?.companyId, priceFormat)) {
        return `Location Secondary Price`;
      } else {
        return `Company Secondary Price`;
      }
    } else {
      return 'Secondary Price';
    }
  }

  static getSectionSortCannabinoidsInfo(sortOption: SectionSortSecondaryCannabinoids): string {
    return sortOption.split('_')?.firstOrNull();
  }

  static getSectionSortTerpenesInfo(sortOption: SectionSortTerpenes): string {
    const toTitleCase = (s: string): string => s.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
    const splitResult = sortOption.split('_');
    const productInfo = splitResult.length < 3 ? splitResult[0] : `${splitResult[0]} ${splitResult[1]}`;
    return toTitleCase(productInfo);
  }

  static decodeSectionSortingIntoReadableString(section: Section): Observable<string|null> {
    return window.types.sectionSortOptions$.pipe(
      map((sortOptions) => {
        const primarySort = sortOptions?.find(option => option?.value === section?.sorting);
        const secondarySort = sortOptions?.find(option => option?.value === section?.secondarySorting);
        switch (true) {
          case !secondarySort && !primarySort: // binary - 00
            return null;
          case !secondarySort && !!primarySort: // binary - 01
          case !!secondarySort && !primarySort: // binary - 10
            return primarySort?.nameWithoutDashes() || secondarySort?.nameWithoutDashes();
          case !!secondarySort && !!primarySort: // binary - 11
            return `${primarySort?.nameWithoutDashes()} then by ${secondarySort?.nameWithoutDashes()}`;
        }
      })
    );
  }

  static getSectionSortOptionFromPosition(sortPosition: SortOrderPosition, section: Section): SectionSortOption {
    switch (sortPosition) {
      case SortOrderPosition.Primary:
        return section?.sorting;
      case SortOrderPosition.Secondary:
        return section?.secondarySorting;
      case SortOrderPosition.Tertiary:
        return WorkerSortUtils.DEFAULT_SECTION_TERTIARY_SORT;
    }
  }

}
