import { ApiClient } from './api-client';
import { CompanyConfiguration } from '../models/company/dto/company-configuration';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { VariantBadge } from '../models/product/dto/variant-badge';
import { catchError, map } from 'rxjs/operators';
import { Product } from '../models/product/dto/product';
import { VariantInventory } from '../models/product/dto/variant-inventory';
import { Variant } from '../models/product/dto/variant';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';
import { HydratedVariantBadge } from '../models/product/dto/hydrated-variant-badge';
import { Promotion } from '../models/product/dto/promotion';
import { VariantPricing } from '../models/product/dto/variant-pricing';
import { UniversalVariant } from '../models/product/dto/universal-variant';
import { ApiPagination } from '../models/shared/api-pagination';
import { ProductMix } from '../models/utils/dto/product-mix-type';
import { SyncDataJob } from '../models/settings/sync-data-job';
import { OverrideProductGroup } from '../models/product/dto/override-product-group';
import { WebSocketRequestProducts } from '../models/web-sockets/messages/send/web-socket-request-products';
import { WebSocketService } from '../services/web-socket.service';

export const DEFAULT_PRODUCT_PAGINATION_COUNT = 2250;
export const DEFAULT_PRODUCT_GROUP_PAGINATION_COUNT = 250;

@Injectable() // Provided by Logged In Scope
export class ProductAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
    private webSocketService: WebSocketService,
  ) {
  }

  // Variables

  public serviceName = 'Product';

  // Product

  public WriteVariantBadge(badge: VariantBadge): Observable<VariantBadge> {
    const url = Endpoints.WriteVariantBadge();
    return this.apiClient.postObj(VariantBadge, url, badge).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'WriteVariantBadge', err));
        return throwError(() => err);
      })
    );
  }

  public DeleteVariantBadge(badge: VariantBadge): Observable<VariantBadge> {
    const url = Endpoints.DeleteVariantBadge();
    return this.apiClient.deleteStr(url, badge).pipe(
      map(_ => {
        return badge;
      }),
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteVariantBadge', err));
        return throwError(() => err);
      })
    );
  }

  public UpdateVariantBadge(badge: VariantBadge): Observable<HydratedVariantBadge> {
    const url = Endpoints.UpdateVariantBadge();
    return this.apiClient.postObj(HydratedVariantBadge, url, badge).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateVariantBadge', err));
        return throwError(() => err);
      })
    );
  }

  public GetCuratedBadges(): Observable<HydratedVariantBadge[]> {
    const url = Endpoints.GetCuratedBadges();
    return this.apiClient.getArr<HydratedVariantBadge>(HydratedVariantBadge, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCuratedBadges', err));
        return throwError(() => err);
      })
    );
  }

  public GetVariantBadges(): Observable<HydratedVariantBadge[]> {
    const url = Endpoints.GetVariantBadges();
    return this.apiClient.getArr<HydratedVariantBadge>(HydratedVariantBadge, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetVariantBadges', err));
        return throwError(() => err);
      })
    );
  }

  public GetVariantBadge(id: string): Observable<HydratedVariantBadge> {
    const url = Endpoints.GetVariantBadges(id);
    return this.apiClient.getArr<HydratedVariantBadge>(HydratedVariantBadge, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetVariantBadge', err));
        return throwError(() => err);
      }),
      map(badges => {
        if (badges.length > 0) {
          return badges[0];
        } else {
          const err = new BsError();
          err.title = 'Variant Badge Error';
          err.message = `Could not get Variant Badge with id: ${id}`;
          throwError(() => err);
        }
      })
    );
  }

  public getLocationProducts$(
    userId: string,
    companyId: number,
    locationId: number,
    productMix = ProductMix.Active
  ): Observable<Product[]> {
    return this.websocketGetLocationProducts$(userId, companyId, locationId, productMix);
  }

  protected websocketGetLocationProducts$(
    userId: string,
    companyId: number,
    locationId: number,
    productMix = ProductMix.Active
  ): Observable<Product[]> {
    const msg = new WebSocketRequestProducts(userId, companyId, locationId, productMix);
    const webSocketRequest$ = this.webSocketService.requestArray$(msg, Product).pipe(
      catchError(error => {
        const err = new BsError(error, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetLocationProductsFromWebSocket', err));
        return this.restfulGetLocationProducts$(locationId, productMix);
      })
    );
    return this.transformProducts$(webSocketRequest$, productMix);
  }

  protected restfulGetLocationProducts$(
    locationId: number,
    productMix: ProductMix,
  ): Observable<Product[]> {
    const url = Endpoints.GetCompanyProducts(locationId, productMix);
    const productsPagination = new ApiPagination(DEFAULT_PRODUCT_PAGINATION_COUNT);
    const restfulRequest$ = this.apiClient.recursiveGet<Product>(Product, url, null, productsPagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetLocationProducts', err));
        return throwError(() => err);
      })
    );
    return this.transformProducts$(restfulRequest$, productMix);
  }

  protected transformProducts$(input$: Observable<Product[]>, productMix: ProductMix): Observable<Product[]> {
    return input$.pipe(
      map(products => {
        if (productMix === ProductMix.All) products?.forEach(p => p?.forceToActive());
        return products;
      })
    );
  }

  public GetUpdatedLocationProducts(
    locationId: number,
    products: Product[],
  ): Observable<Product[]> {
    const url = Endpoints.GetCompanyProducts(
      locationId,
      ProductMix.Active,
      products?.map(p => p?.id) ?? []
    );
    return this.apiClient.getArr<Product>(Product, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetUpdatedLocationProducts', err));
        return throwError(() => err);
      })
    );
  }

  public GetLocationPromotions(locationId: number): Observable<Promotion[]> {
    const url = Endpoints.GetLocationPromotions(locationId);
    return this.apiClient.getArr<Promotion>(Promotion, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetLocationPromotions', err));
        return throwError(() => err);
      })
    );
  }

  public GetLocationInventory(locationId: number, forceSync: boolean): Observable<VariantInventory[]> {
    const url = Endpoints.GetLocationInventory(locationId, forceSync);
    return this.apiClient.getArr<VariantInventory>(VariantInventory, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetLocationInventory', err));
        return throwError(() => err);
      })
    );
  }

  public GetAllLocationInventory(variantId: string): Observable<VariantInventory[]> {
    const url = Endpoints.GetAllLocationInventory(variantId);
    return this.apiClient.getArr<VariantInventory>(VariantInventory, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetAllLocationInventory', err));
        return throwError(() => err);
      })
    );
  }

  public GetUniversalVariants(variantIds: string[]): Observable<Map<string, UniversalVariant>> {
    const url = Endpoints.GetUniversalVariants(variantIds);
    return this.apiClient.getTypedStringMap<UniversalVariant>(UniversalVariant, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetUniversalVariants', err));
        return throwError(() => err);
      })
    );
  }

  public UpdateVariants(locationId: number, variants: Variant[]): Observable<Variant[]> {
    const additionalHeaders = { LocationId: locationId };
    const url = Endpoints.UpdateVariants();
    return this.apiClient.postArr(Variant, url, variants, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateVariants', err));
        return throwError(() => err);
      })
    );
  }

  public UpdateVariantPricing(locationId: number, locationPricing: VariantPricing[]): Observable<VariantPricing[]> {
    const additionalHeaders = { LocationId: locationId };
    const url = Endpoints.UpdateVariantPricing();
    return this.apiClient.postArr(VariantPricing, url, locationPricing, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateVariantPricing', err));
        return throwError(() => err);
      })
    );
  }

  public UpdateVariantInventory(
    variantInventories: VariantInventory[],
    markAsActive: boolean = true
  ): Observable<VariantInventory[]> {
    const url = Endpoints.UpdateVariantInventory(markAsActive);
    return this.apiClient.postArr(VariantInventory, url, variantInventories).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'updateVariantPricing', err));
        return throwError(() => err);
      })
    );
  }

  public SyncFullProductInfo(): Observable<CompanyConfiguration> {
    const url = Endpoints.SyncFullProductInfo();
    return this.apiClient.getObj<CompanyConfiguration>(CompanyConfiguration, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SyncFullProductInfo', err));
        return throwError(() => err);
      })
    );
  }

  public SyncDisplayNames(): Observable<CompanyConfiguration> {
    const url = Endpoints.SyncDisplayNames();
    return this.apiClient.getObj<CompanyConfiguration>(CompanyConfiguration, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'SyncDisplayNames', err));
        return throwError(() => err);
      })
    );
  }

  // Sync Jobs

  public CreateSyncJob(job: SyncDataJob): Observable<SyncDataJob> {
    const url = Endpoints.CreateSyncJob();
    return this.apiClient.postObj(SyncDataJob, url, job).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateSyncJob', err));
        return throwError(() => err);
      })
    );
  }

  public GetSyncJobs(jobIds: string[] = null, locationId: number = null): Observable<SyncDataJob[]> {
    const additionalHeaders = {...(locationId ? {locationId} : {})};
    const url = Endpoints.GetSyncJobs(jobIds);
    return this.apiClient.getArr<SyncDataJob>(SyncDataJob, url, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetSyncJobs', err));
        return throwError(() => err);
      })
    );
  }

  public GetOverrideProductGroups(locationId: number, ids?: string[]): Observable<OverrideProductGroup[]> {
    const url = Endpoints.GetOverrideProductGroups(ids);
    const pagination = new ApiPagination(DEFAULT_PRODUCT_GROUP_PAGINATION_COUNT);
    const headers = { locationId };
    return this.apiClient.recursiveGet<OverrideProductGroup>(OverrideProductGroup, url, headers, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetOverrideProductGroups', err));
        return throwError(() => err);
      })
    );
  }

  public CreateOverrideProductGroup(
    locationId: number,
    group: OverrideProductGroup,
    showingDiscontinuedProducts: boolean
  ): Observable<OverrideProductGroup> {
    const url = Endpoints.CreateOverrideProductGroup();
    const additionalHeaders = { locationId };
    return this.apiClient.postObj(OverrideProductGroup, url, group, additionalHeaders).pipe(
      map(overrideProductGroup => {
        if (showingDiscontinuedProducts) overrideProductGroup?.forceToActive();
        return overrideProductGroup;
      }),
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'CreateOverrideProductGroup', err));
        return throwError(() => err);
      })
    );
  }

  public UpdateOverrideProductGroup(
    locationId: number,
    group: OverrideProductGroup,
    showingDiscontinuedProducts: boolean
  ): Observable<OverrideProductGroup> {
    const url = Endpoints.UpdateOverrideProductGroup();
    const additionalHeaders = { locationId };
    return this.apiClient.postObj(OverrideProductGroup, url, group, additionalHeaders).pipe(
      map(overrideProductGroup => {
        if (showingDiscontinuedProducts) overrideProductGroup?.forceToActive();
        return overrideProductGroup;
      }),
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateOverrideProductGroup', err));
        return throwError(() => err);
      })
    );
  }

  public DeleteOverrideProductGroup(locationId: number, group: OverrideProductGroup): Observable<OverrideProductGroup> {
    const url = Endpoints.DeleteOverrideProductGroup();
    const additionalHeaders = { locationId };
    return this.apiClient.deleteStr(url, group, additionalHeaders).pipe(
      map(_ => group),
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteOverrideProductGroup', err));
        return throwError(() => err);
      })
    );
  }

}
