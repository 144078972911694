import { Injectable } from '@angular/core';
import { PreviewService } from './preview-service';
import { CacheService } from './cache-service';
import { ImageAPI } from '../api/image-api';
import { LocationDomainModel } from '../domainModels/location-domain-model';
import { MenuDomainModel } from '../domainModels/menu-domain-model';
import { Menu } from '../models/menu/dto/menu';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { defer, Observable, of } from 'rxjs';
import { delay, map, switchMap, take, tap } from 'rxjs/operators';
import { iiif } from '../utils/observable.extensions';
import { PreviewOf } from '../models/enum/shared/preview-of';
import { ChangesRequiredForPreviewService } from './changes-required-for-preview.service';
import { CardPreview } from '../models/menu/shared/card-preview';

@Injectable()
export class PrintCardPreviewService extends PreviewService {

  constructor(
    private cacheService: CacheService,
    private imageAPI: ImageAPI,
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    changesRequiredForPreviewService: ChangesRequiredForPreviewService
  ) {
    super(locationDomainModel, menuDomainModel, changesRequiredForPreviewService);
  }

  static getPrintCardPreviewKey(
    menuId: string,
    locId: number,
    itemIds: string[],
    previewType: PreviewOf = PreviewOf.CardImage
  ): string {
    const locAndPreviewType = PreviewService.getLocationPreviewPartialKey(locId, previewType);
    return `${menuId}-${locAndPreviewType}-${itemIds?.sort()?.join(',')}`;
  }

  /* ******************************* Get Print Card Preview ******************************* */

  protected override waitForFetch(key: string): Observable<CardPreview> {
    return super.waitForFetch(key).pipe(map(preview => preview as CardPreview));
  }

  public getPrintCardPreview = (
    menu: Menu | MenuTemplate,
    variantIds: string[],
    returnLastSaved: boolean,
    cacheImageDelay: number,
    skipCacheWrite: boolean = false,
    forceUpdate: boolean = false,
    previewOnly?: boolean
  ): Observable<CardPreview> => {
    return this.locationId$.pipe(
      take(1),
      switchMap(locId => {
        const key = PrintCardPreviewService.getPrintCardPreviewKey(menu?.id, locId, variantIds);
        const alreadyFetching$ = defer(() => this.addRequestToQueue(key));
        const waitForFetch$ = defer(() => this.waitForFetch(key));
        const goFetch$ = defer(() => {
          const fetch = this.fetchPrintCardPreview;
          return fetch(menu, variantIds, returnLastSaved, cacheImageDelay, skipCacheWrite, forceUpdate, previewOnly);
        });
        this.setLoadingStateFor(key, true);
        return iiif(alreadyFetching$, waitForFetch$, goFetch$);
      })
    );
  };

  private fetchPrintCardPreview = (
    menu: Menu | MenuTemplate,
    variantIds: string[],
    returnLastSaved: boolean,
    cacheImageDelay: number,
    skipCacheWrite: boolean = false,
    forceUpdate: boolean = false,
    previewOnly?: boolean
  ): Observable<CardPreview> => {
    return this.locationId$.pipe(
      take(1),
      switchMap(locId => {
        const key = PrintCardPreviewService.getPrintCardPreviewKey(menu?.id, locId, variantIds);
        const cachedPreview = this.cacheService.getCachedObject<CardPreview>(CardPreview, key);
        if (cachedPreview && returnLastSaved && !forceUpdate) {
          this.storeActivePreview(key, cachedPreview);
          this.setLoadingStateFor(key, false);
          this.removeRequestFromQueue(key);
          return of(cachedPreview);
        }
        const templatePreview = this.imageAPI.GetPrintCardTemplatePreview;
        const regularPreview = this.imageAPI.GetPrintCardPreview;
        const previewReq$ = menu instanceof MenuTemplate
          ? templatePreview(locId, menu, variantIds, returnLastSaved, forceUpdate, previewOnly)
          : regularPreview(locId, menu, variantIds, returnLastSaved, forceUpdate, previewOnly);
        return previewReq$.pipe(
          delay(cacheImageDelay),
          map(data => {
            const Deserializer = window.injector.Deserialize;
            const preview = Deserializer.instanceOf(CardPreview, new CardPreview(menu.id, data, locId));
            preview.itemIds = variantIds;
            return preview;
          }),
          tap(preview => {
            if (!skipCacheWrite) this.cacheService.cacheObject<CardPreview>(key, preview);
            this.storeActivePreview(key, preview);
            this.setLoadingStateFor(key, false);
            this.removeRequestFromQueue(key);
          })
        );
      })
    );
  };

  public getShelfTalkerPreview = (
    menu: Menu | MenuTemplate,
    sectionId: string[],
    returnLastSaved: boolean,
    cacheImageDelay: number,
    skipCacheWrite: boolean = false,
    forceUpdate: boolean = false,
    previewOnly?: boolean
  ): Observable<CardPreview> => {
    return this.locationId$.pipe(
      take(1),
      switchMap(locId => {
        const key = PrintCardPreviewService.getPrintCardPreviewKey(menu?.id, locId, sectionId);
        const alreadyFetching$ = defer(() => this.addRequestToQueue(key));
        const waitForFetch$ = defer(() => this.waitForFetch(key));
        const goFetch$ = defer(() => {
          const fetch = this.fetchShelfTalkerPreview;
          return fetch(menu, sectionId, returnLastSaved, cacheImageDelay, skipCacheWrite, forceUpdate, previewOnly);
        });
        this.setLoadingStateFor(key, true);
        return iiif(alreadyFetching$, waitForFetch$, goFetch$);
      })
    );
  };

  private fetchShelfTalkerPreview = (
    menu: Menu | MenuTemplate,
    sectionId: string[],
    returnLastSaved: boolean,
    cacheImageDelay: number,
    skipCacheWrite: boolean = false,
    forceUpdate: boolean = false,
    previewOnly?: boolean
  ): Observable<CardPreview> => {
    return this.locationId$.pipe(
      take(1),
      switchMap(locId => {
        const key = PrintCardPreviewService.getPrintCardPreviewKey(menu?.id, locId, sectionId);
        const cachedPreview = this.cacheService.getCachedObject<CardPreview>(CardPreview, key);
        if (cachedPreview && returnLastSaved && !forceUpdate) {
          this.storeActivePreview(key, cachedPreview);
          this.setLoadingStateFor(key, false);
          this.removeRequestFromQueue(key);
          return of(cachedPreview);
        }
        const templatePreview = this.imageAPI.GetShelfTalkerCardTemplatePreview;
        const regularPreview = this.imageAPI.GetShelfTalkerCardPreview;
        const previewReq$ = menu instanceof MenuTemplate
          ? templatePreview(locId, menu, sectionId?.firstOrNull(), returnLastSaved, forceUpdate, previewOnly)
          : regularPreview(locId, menu, sectionId?.firstOrNull(), returnLastSaved, forceUpdate, previewOnly);
        return previewReq$.pipe(
          delay(cacheImageDelay),
          map(data => {
            const Deserializer = window.injector.Deserialize;
            const preview = Deserializer.instanceOf(CardPreview, new CardPreview(menu.id, data, locId));
            preview.itemIds = sectionId;
            return preview;
          }),
          tap(preview => {
            if (!skipCacheWrite) this.cacheService.cacheObject<CardPreview>(key, preview);
            this.storeActivePreview(key, preview);
            this.setLoadingStateFor(key, false);
            this.removeRequestFromQueue(key);
          })
        );
      })
    );
  };

}
