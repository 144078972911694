import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { EditVariantGeneralViewModel } from './edit-variant-general-view-model';
import { ReactiveFormDropDownComponent, ReactiveFormGroupComponent } from '@mobilefirstdev/reactive-form';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-edit-variant-general',
  templateUrl: './edit-variant-general.component.html',
  styleUrls: ['./edit-variant-general.component.scss'],
  providers: [EditVariantGeneralViewModel]
})
export class EditVariantGeneralComponent extends BaseComponent implements Tabbable, AfterViewInit {

  @ViewChild('editVariantForm') editVariantForm!: ReactiveFormGroupComponent;
  @ViewChild('productTypeDropdown') productTypeDropdown!: ReactiveFormDropDownComponent;
  @ViewChild('variantTypeDropdown') variantTypeDropdown!: ReactiveFormDropDownComponent;

  constructor(
    public viewModel: EditVariantGeneralViewModel
  ) {
    super();
  }

  tabActivated: BehaviorSubject<boolean>;

  override setupBindings(): void {
    this.viewModel.variantTypeOptions$.subscribeWhileAlive({
      owner: this,
      next: (variantTypeDefs) => {
        if (variantTypeDefs?.length === 1) {
          const variantType = variantTypeDefs[0];
          this.variantTypeDropdown?.getSelfAsFormItem().setValue(variantType?.value);
        } else {
          this.variantTypeDropdown?.clear();
        }
      }
    });
  }

  scrollDownToSeeInfo(panel: MatExpansionPanel): void {
    // eslint-disable-next-line no-underscore-dangle
    panel._body.nativeElement.scrollIntoView({behavior:'smooth'});
  }

}
