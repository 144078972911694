import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Label } from '../models/shared/label';
import { LabelStyle } from '../models/enum/shared/label-style.enum';
import { LabelPriorityEditorModalComponent } from '../views/settings/components/settings-customization/labels/shared-label-components/label-priority-editor-modal/label-priority-editor-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector, NgZone } from '@angular/core';
import { SaleLabelFormat } from '../models/utils/dto/sale-label-format-type';

export class ModalEditLabelPriority {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    labelsToEdit: Label[],
    allowUnsetPriority: boolean,
    activeLabelStyle: LabelStyle,
    activeSaleLabelFormat: SaleLabelFormat,
    onClose: (labels: Label[]) => void
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(
        LabelPriorityEditorModalComponent,
        ModalUtils.defaultModalOptions(injector)
      );
      const compInstance = modalRef.componentInstance as LabelPriorityEditorModalComponent;
      compInstance.viewModel.connectToLabels(labelsToEdit);
      compInstance.viewModel.connectToAllowUnsetPriority(allowUnsetPriority);
      compInstance.viewModel.connectToActiveLabelStyle(activeLabelStyle);
      compInstance.viewModel.connectToActiveSaleLabelFormat(activeSaleLabelFormat);
      modalRef.result.then((ls) => !!ls && onClose(ls)).catch(() => {});
    });
  }

}
