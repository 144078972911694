<app-info-banner *rxIf="viewModel.selectedSortOptionIsStrainClassification$" class="mb-16px">
  {{ viewModel.strainClassificationSortExplanation$ | push }}
</app-info-banner>
<div class="d-flex gap-3">
  <div class="flex-take-up-remain-space">
    <lib-reactive-form-group
      [mergeKey]="mergeKey"
      [bindTo]="menu"
      [globalDisabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip">
      <lib-reactive-form-text
        [label]="'Stack Name (Internal)'"
        [required]="true"
        [bindingProperty]="'name'"
        [inputName]="'name'"
        [maxLength]="128"
        [placeholder]="'Enter an internal name for the stack.'"
        [disabled]="isTemplatedMenu">
      </lib-reactive-form-text>
    </lib-reactive-form-group>
  </div>
  <div class="flex-take-up-remain-space">
    <lib-reactive-form-group
      [mergeKey]="mergeKey"
      [bindTo]="section"
      [globalDisabledTooltip]="isTemplatedSection | templatedMenuDisabledFormControlTooltip">
      <lib-reactive-form-drop-down
        [inputName]="'sectionType'"
        [label]="'Section Type'"
        [placeholder]="'Select the type of section.'"
        [inlineLabel]="false"
        [required]="true"
        [dropdowns]="viewModel.sectionTypes$ | push"
        [bindingProperty]="'sectionType'"
        [disabled]="true">
      </lib-reactive-form-drop-down>
    </lib-reactive-form-group>
  </div>
</div>

<lib-reactive-form-group
  [mergeKey]="mergeKey"
  [bindTo]="section"
  [globalDisabledTooltip]="isTemplatedSection | templatedMenuDisabledFormControlTooltip">
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-drop-down
      [inputName]="'sorting'"
      [label]="'Primary Sort'"
      [placeholder]="'Select how variants should be sorted.'"
      [inlineLabel]="false"
      [required]="true"
      [dropdowns]="viewModel.primarySortingTypes$ | push"
      [bindingProperty]="'sorting'"
      [disabled]="isTemplatedSection"
      [reinitializeWhenListChanges]="false"
      (valueChanged)="viewModel.connectToSelectedPrimarySortOption($event)">
    </lib-reactive-form-drop-down>
    <!-- not required, because old menu sections will not have a secondary set -->
    <lib-reactive-form-drop-down
      [inputName]="'secondarySorting'"
      [label]="'Secondary Sort'"
      [disabled]="(viewModel.secondarySortDisabled$ | push) || isTemplatedSection"
      [placeholder]="viewModel.secondarySortPlaceholder$ | push"
      [tooltip]="viewModel.secondarySortTooltip$ | push"
      [inlineLabel]="false"
      [required]="false"
      [dropdowns]="viewModel.secondarySortingTypes$ | push"
      [bindingProperty]="'secondarySorting'"
      [reinitializeWhenListChanges]="false"
      [programmaticallyChangeValue]="viewModel.programmaticallyChangeSecondarySort$ | push"
      (valueChanged)="viewModel.connectToSelectedSecondarySortOption($event)">
    </lib-reactive-form-drop-down>
    <lib-reactive-form-drop-down
      [inputName]="'layoutType'"
      [label]="'Layout'"
      [placeholder]="'Select how content should be laid out'"
      [inlineLabel]="false"
      [required]="true"
      [disabled]="(viewModel.disableLayoutTypeSelection$ | push) || isTemplatedSection"
      [disabledTooltip]="viewModel.layoutTypeDisabledTooltip$ | push"
      [dropdowns]="viewModel.layoutTypes$ | push"
      [bindingProperty]="'layoutType'"
      [emitInitializationValuesAsValueChanged]="true"
      (valueChanged)="viewModel.connectToSectionLayoutType($event)">
    </lib-reactive-form-drop-down>
    <lib-reactive-form-drop-down
      [inputName]="'saleLabelFormat'"
      [bindingProperty]="'saleLabelFormat'"
      [label]="'Sale Label Format'"
      [required]="false"
      [placeholder]="viewModel.saleFormatPlaceholder$ | push"
      [clearable]="true"
      [tooltip]="'The sale label format can be overridden at the section level.'"
      [dropdowns]="viewModel.saleFormatDropdowns$ | push"
      [disabled]="isTemplatedSection">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>
  <lib-reactive-form-row *ngIf="viewModel.showGridColumnsOptions$ | async">
    <lib-reactive-form-checkboxes
      [customValueParser]="viewModel.parseSectionGridColumns"
      (valueChanged)="viewModel.connectToGridColumns($event)"
      [disabled]="
        (viewModel.autoUpdateGridColumns$ | push) ||
        (viewModel.disableGridColumnCheckboxes$ | push) ||
        isTemplatedSection
      "
      [disabledTooltip]="viewModel.selectAllGridColumnsDisabledTooltip$ | push"
      [disabledTooltipDelayMs]="75"
      [bindingProperty]="'metadata.gridColumnNames'"
      [programmaticallyChangeValue]="viewModel.programmaticallyChangeGridOptionCheckboxes$ | push"
      [requireMinimumSelection]="1"
      [inputName]="'gridColumnNames'"
      [label]="'Grid Columns'"
      [checkAllText]="'Select All Grid Columns'"
      [inlineOptions]="true">
      <lib-reactive-form-checkbox
        *rxFor="let colName of viewModel.sectionLayoutGridColumns$"
        [inputName]="colName | replaceDotWithDash"
        [disabled]="(viewModel.section$ | push)?.autoUpdateGridColumns || isTemplatedSection"
        [selectedValue]="colName"
        [notSelectedValue]="null">
        {{ colName | flowerGramsToOunces : viewModel.layoutType$ | push }}
      </lib-reactive-form-checkbox>
    </lib-reactive-form-checkboxes>
  </lib-reactive-form-row>
</lib-reactive-form-group>

<app-edit-menu-warning-banner [warningMessage]="viewModel.sectionBannerWarningMessage$ | push">
</app-edit-menu-warning-banner>
