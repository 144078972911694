import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { MenuType } from '../../../../models/utils/dto/menu-type-definition';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TypeDefinition } from '../../../../models/utils/dto/type-definition';
import { MenuCreationFlowType } from '../../../../models/utils/dto/menu-creation-flow-type';
import { MenuSubtype } from '../../../../models/enum/dto/menu-subtype';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { MenuDomainModel } from '../../../../domainModels/menu-domain-model';
import type { Theme } from '../../../../models/menu/dto/theme';
import { exists } from '../../../../functions/exists';
import { DistinctUtils } from '../../../../utils/distinct-utils';

@Injectable()
export class MenuTypePickerViewModel extends BaseViewModel {

  constructor(
    private companyDomain: CompanyDomainModel,
    private menuDomainModel: MenuDomainModel
  ) {
    super();
  }

  private readonly companyId$ = this.companyDomain.companyId$;
  private readonly themes$ = this.menuDomainModel.menuThemes$;

  private _menuType = new BehaviorSubject<MenuType>(null);
  public menuType$ = this._menuType as Observable<MenuType>;
  public connectToMenuType = (mt: MenuType) => this._menuType.next(mt);

  private _readableSubtype = new BehaviorSubject<string>(null);
  public readableSubtype$ = this._readableSubtype as Observable<string>;

  private _selectedSubtypes = new BehaviorSubject<MenuSubtype[]>(null);
  public selectedSubtypes$ = this._selectedSubtypes as Observable<MenuSubtype[]>;

  private _selectedMenuType = new BehaviorSubject<MenuCreationFlowType>(null);
  public selectedMenuType$ = this._selectedMenuType as Observable<MenuCreationFlowType>;

  /**
   * Shelf talkers and print card menus both have "Print Card Stack" and "Shelf Talker" as options,
   * because regardless of which menu type is selected, the user needs to be able to choose between
   * "Print Card Stack" and "Shelf Talker".
   */
  private menuCreationFlowMap$ = combineLatest([
    this.menuType$,
    window?.types?.menuCreationFlowMap$,
  ]).pipe(
    map(([menuType, createMap]) => createMap?.get(menuType))
  );

  public menuCreationSelections$: Observable<TypeDefinition[]> = combineLatest([
    this.menuCreationFlowMap$,
    window?.types?.menuCreationFlows$,
    this.companyId$,
    this.themes$
  ]).pipe(
    map(([creationMap, createTypes, companyId, themes]) => {
      return this.getMenuCreationSelections(creationMap, createTypes, companyId, themes);
    }),
    distinctUntilChanged(DistinctUtils.distinctJSON)
  );

  private getMenuCreationSelections(
    creationMap: Map<MenuCreationFlowType, string[]>,
    createTypes: TypeDefinition[],
    companyId: number,
    themes: Theme[]
  ): TypeDefinition[] {
    return ([...(creationMap?.entries() || [])])
      ?.map(([menuCreationFlowType]) => {
        const flow = createTypes?.find(t => t.value === menuCreationFlowType);
        switch (flow?.value) {
          case MenuCreationFlowType.PrintShelfTalkerMenu: {
            const hasShelfTalkerThemes = themes?.some(theme => theme?.isUsableShelfTalker(companyId));
            return hasShelfTalkerThemes ? flow : null;
          }
          default: {
            return flow;
          }
        }
      })
      ?.filter(exists);
  }

  public menuTypeSelected(flowType: MenuCreationFlowType): void {
    combineLatest([
      window.types.menuCreationFlows$,
      this.menuCreationFlowMap$
    ]).once(([flows, m]) => {
      const menuSubtypes = (m?.get(flowType)) as MenuSubtype[];
      this._selectedMenuType.next(flowType);
      this._readableSubtype.next(flows.find(f => f.value === flowType).name);
      this._selectedSubtypes.next(menuSubtypes);
    });
  }

}
