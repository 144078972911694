<thead class="header-container">
  <tr>
    <ng-container
      *rxFor="
        let header of viewModel.productTableColumnHeaders$ | push : 'userBlocking';
        trackBy: viewModel.trackByName
      ">
      <ng-container
        [ngSwitch]="
          header
            | ProductTableColumnKeyToData
              : (viewModel.enabledCannabinoids$ | push : 'userBlocking')
              : (viewModel.enabledTerpenes$ | push : 'userBlocking')
        ">
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Name" resizable class="fixed-column">
          <div class="header-title" (click)="nameSort.clicked()">Name</div>
          <lib-reactive-table-asc-desc-sort
            #nameSort
            fxFlex
            [ascSort]="nameAsc"
            [descSort]="nameDesc"></lib-reactive-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Brand" resizable>
          <div class="header-title" (click)="brandSort.clicked()">Brand</div>
          <app-table-asc-desc-sort
            #brandSort
            fxFlex
            [ascSort]="brandAsc"
            [descSort]="brandDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.ProductType" resizable>
          <div class="header-title" (click)="typeSort.clicked()">Type</div>
          <app-table-asc-desc-sort #typeSort fxFlex [ascSort]="typeAsc" [descSort]="typeDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.StrainType" resizable>
          <div class="header-title" (click)="strainSort.clicked()">Strain Type</div>
          <app-table-asc-desc-sort
            #strainSort
            fxFlex
            [ascSort]="strainAsc"
            [descSort]="strainDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Quantity" resizable>
          <div class="header-title" (click)="qtySort.clicked()">Quantity</div>
          <app-table-asc-desc-sort #qtySort fxFlex [ascSort]="qtyAsc" [descSort]="qtyDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Price" resizable>
          <div class="header-title" (click)="priceSort.clicked()">Price</div>
          <app-table-asc-desc-sort
            #priceSort
            fxFlex
            [ascSort]="priceAsc"
            [descSort]="priceDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.SecondaryPrice" resizable>
          <div class="header-title" (click)="secondaryPriceSort.clicked()">Secondary Price</div>
          <app-table-asc-desc-sort
            #secondaryPriceSort
            fxFlex
            [ascSort]="secondaryPriceAsc"
            [descSort]="secondaryPriceDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Label" resizable>
          <div class="header-title" (click)="labelSort.clicked()">Label</div>
          <app-table-asc-desc-sort
            #labelSort
            fxFlex
            [ascSort]="labelAsc"
            [descSort]="labelDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Badge" resizable>
          <div class="header-title" (click)="badgeSort.clicked()">Badge</div>
          <app-table-asc-desc-sort
            #badgeSort
            fxFlex
            [ascSort]="badgeAsc"
            [descSort]="badgeDesc"></app-table-asc-desc-sort>
        </th>
        <th *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.TopTerpene" resizable>
          <div class="header-title">Top Terpene</div>
          <app-table-asc-desc-sort
            #topTerpeneSort
            fxFlex
            [ascSort]="topTerpeneAsc"
            [descSort]="topTerpeneDesc"></app-table-asc-desc-sort>
        </th>
        <ng-container *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Cannabinoid">
          <th *rxIf="viewModel.displayCannabinoidsInRanges$ | push : 'userBlocking'" resizable>
            <div class="header-title" (click)="minCannabinoidSort.clicked()">Min {{ header }}</div>
            <app-table-asc-desc-sort
              #minCannabinoidSort
              fxFlex
              [ascSort]="
                header
                  | GetSortFunctionForCannabinoidOrTerpene
                    : true
                    : (minCannabinoidAndTerpeneAscSortFuncMap$ | push : 'userBlocking')
              "
              [descSort]="
                header
                  | GetSortFunctionForCannabinoidOrTerpene
                    : true
                    : (minCannabinoidAndTerpeneDescSortFuncMap$ | push : 'userBlocking')
              "></app-table-asc-desc-sort>
          </th>

          <th *rxIf="viewModel.displayCannabinoidsInRanges$ | push : 'userBlocking'" resizable>
            <div class="header-title" (click)="maxCannabinoidSort.clicked()">Max {{ header }}</div>
            <app-table-asc-desc-sort
              #maxCannabinoidSort
              fxFlex
              [ascSort]="
                header
                  | GetSortFunctionForCannabinoidOrTerpene
                    : true
                    : (maxCannabinoidAndTerpeneAscSortFuncMap$ | push : 'userBlocking')
              "
              [descSort]="
                header
                  | GetSortFunctionForCannabinoidOrTerpene
                    : true
                    : (maxCannabinoidAndTerpeneDescSortFuncMap$ | push : 'userBlocking')
              "></app-table-asc-desc-sort>
          </th>

          <th *rxIf="!(viewModel.displayCannabinoidsInRanges$ | push : 'userBlocking')" resizable>
            <div class="header-title" (click)="cannabinoidSort.clicked()">{{ header }}</div>
            <app-table-asc-desc-sort
              #cannabinoidSort
              fxFlex
              [ascSort]="
                header
                  | GetSortFunctionForCannabinoidOrTerpene
                    : false
                    : (cannabinoidAndTerpeneAscSortFuncMap$ | push : 'userBlocking')
              "
              [descSort]="
                header
                  | GetSortFunctionForCannabinoidOrTerpene
                    : false
                    : (cannabinoidAndTerpeneDescSortFuncMap$ | push : 'userBlocking')
              "></app-table-asc-desc-sort>
          </th>
        </ng-container>

        <ng-container *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Terpene">
          <th *rxIf="viewModel.displayTerpenesInRanges$ | push : 'userBlocking'" resizable>
            <div class="header-title" (click)="minTerpeneSort.clicked()">Min {{ header }}</div>
            <app-table-asc-desc-sort
              #minTerpeneSort
              fxFlex
              [ascSort]="
                header
                  | GetSortFunctionForCannabinoidOrTerpene
                    : true
                    : (minCannabinoidAndTerpeneAscSortFuncMap$ | push : 'userBlocking')
              "
              [descSort]="
                header
                  | GetSortFunctionForCannabinoidOrTerpene
                    : true
                    : (minCannabinoidAndTerpeneDescSortFuncMap$ | push : 'userBlocking')
              "></app-table-asc-desc-sort>
          </th>

          <th *rxIf="viewModel.displayTerpenesInRanges$ | push : 'userBlocking'" resizable>
            <div class="header-title" (click)="maxTerpeneSort.clicked()">Max {{ header }}</div>
            <app-table-asc-desc-sort
              #maxTerpeneSort
              fxFlex
              [ascSort]="
                header
                  | GetSortFunctionForCannabinoidOrTerpene
                    : true
                    : (maxCannabinoidAndTerpeneAscSortFuncMap$ | push : 'userBlocking')
              "
              [descSort]="
                header
                  | GetSortFunctionForCannabinoidOrTerpene
                    : true
                    : (maxCannabinoidAndTerpeneDescSortFuncMap$ | push : 'userBlocking')
              "></app-table-asc-desc-sort>
          </th>

          <th *rxIf="!(viewModel.displayTerpenesInRanges$ | push : 'userBlocking')" resizable>
            <div class="header-title" (click)="terpeneSort.clicked()">{{ header }}</div>
            <app-table-asc-desc-sort
              #terpeneSort
              fxFlex
              [ascSort]="
                header
                  | GetSortFunctionForCannabinoidOrTerpene
                    : false
                    : (cannabinoidAndTerpeneAscSortFuncMap$ | push : 'userBlocking')
              "
              [descSort]="
                header
                  | GetSortFunctionForCannabinoidOrTerpene
                    : false
                    : (cannabinoidAndTerpeneDescSortFuncMap$ | push : 'userBlocking')
              "></app-table-asc-desc-sort>
          </th>
        </ng-container>
      </ng-container>
    </ng-container>
  </tr>
</thead>
