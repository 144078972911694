import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Card } from '../../../../models/shared/stylesheet/card';
import { CardStyle } from '../../../../models/shared/stylesheet/card-style.enum';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-card-deck',
  templateUrl: './card-deck.component.html',
  styleUrls: ['./card-deck.component.scss'],
})
export class CardDeckComponent extends BaseComponent implements AfterViewInit, OnChanges {

  @Input() cards: Card[] = [];
  @Input() horizontalScroller = true;
  @Input() maxHeightRem: number = 18;
  @Input() columnGap: string = '1rem';
  @Input() rowGap: string = '1rem';
  @Input() scrollToCardId: string;

  @Output() cardPressed: EventEmitter<Card> = new EventEmitter<Card>();
  @Output() cardButtonPressed: EventEmitter<Card> = new EventEmitter<Card>();

  // View
  public cardStyles = CardStyle;

  constructor() {
    super();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.scrollToCard(this.scrollToCardId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scrollToCardId) this.scrollToCard(this.scrollToCardId);
  }

  scrollToCard(cardId: string) {
    if (cardId) {
      document.getElementById(cardId)?.scrollIntoView();
      document.getElementById(cardId)?.focus();
    }
  }

  handleCardPress(card: Card) {
    this.cardPressed.emit(card);
  }

}
