import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditCardStackPrintSectionComponent } from '../../../../edit-card-stack-menu/edit-card-stack-print-section/edit-card-stack-print-section.component';
import { BulkPrintJobPollingService } from '../../../../../menus/print/services/bulk-print-job-polling.service';
import { EditShelfTalkerBrandsMenuPrintSectionViewModel } from './edit-shelf-talker-brands-menu-print-section-view-model';

@Component({
  selector: 'app-edit-shelf-talker-brands-menu-print-section',
  templateUrl: '../../../../../edit-menu/edit-card-stack-menu/edit-card-stack-print-section/edit-card-stack-print-section.component.html',
  providers: [EditShelfTalkerBrandsMenuPrintSectionViewModel, BulkPrintJobPollingService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShelfTalkerBrandsMenuPrintSectionComponent extends EditCardStackPrintSectionComponent {

  constructor(
    public override viewModel: EditShelfTalkerBrandsMenuPrintSectionViewModel,
    protected override bulkPrintJobPollingService: BulkPrintJobPollingService
  ) {
    super(viewModel, bulkPrintJobPollingService);
  }

  @Input() override sectionDescription: string = 'View and manage the print jobs for your shelf talker.';
  @Input() override editPrintCardMode: boolean = false;
  @Input() override editPrintLabelMode: boolean = false;
  @Input() override editPrintShelfTalkerMode: boolean = true;

}
