import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { catchError, delay, distinctUntilChanged, map, shareReplay, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ASSET_DELETE_DELAY, ASSET_RETRY_COUNT, ASSET_RETRY_DELAY, MediaUtils } from '../../../../../../utils/media-utils';
import { BsError } from '../../../../../../models/shared/bs-error';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { BudsenseFile } from '../../../../../../models/shared/budsense-file';
import { AssetPreviewOptions } from '../../../../../../models/shared/stylesheet/asset-preview-options';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { CacheService } from '../../../../../../services/cache-service';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';
import { Asset } from '../../../../../../models/image/dto/asset';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { exists } from '../../../../../../functions/exists';
import { SectionAdjustableAssetViewModel } from '../../../shared/section-adjustable-asset/section-adjustable-asset-view-model';

@Injectable()
export class MenuSectionBackgroundViewModel extends SectionAdjustableAssetViewModel {

  constructor(
    protected templateDomainModel: TemplateDomainModel,
    protected menuDomainModel: MenuDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected toastService: ToastService,
    private cacheService: CacheService,
    private menuPreviewService: MenuPreviewService
  ) {
    super();
    this.setupBindings();
  }

  private readonly locationId$ = this.locationDomainModel.locationId$;

  private _templateMode = new BehaviorSubject<boolean>(false);
  public templateMode$ = this._templateMode as Observable<boolean>;
  connectToTemplateMode = (templateMode: boolean) => this._templateMode.next(templateMode);

  public menu$ = this.templateMode$.pipe(
    switchMap(templateMode => {
      return templateMode
        ? this.templateDomainModel.activeMenuTemplate$
        : this.menuDomainModel.activeHydratedMenu$;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public isTemplatedMenu$ = this.menu$.pipe(
    map(menu => menu?.isTemplatedMenu())
  );

  // Background Asset Loading
  protected backgroundAssetLoadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.getAssetLoadingOpts());
  public backgroundAssetLoadingOpts$ = this.backgroundAssetLoadingOpts.asObservable();
  public backgroundAssetLoading$ = this.backgroundAssetLoadingOpts$.pipe(map(it => it.isLoading));

  // Background Image
  private _menuBackgroundAsset = new BehaviorSubject<Asset>(null);
  public menuBackgroundAsset$ = this._menuBackgroundAsset as Observable<Asset>;
  public hasBackgroundAsset$ = this.menuBackgroundAsset$.pipe(map(it => !!it));
  private _backgroundPreviewOptions = new BehaviorSubject<AssetPreviewOptions>(this.getBackgroundPreviewOpts());
  public backgroundPreviewOptions$ = this._backgroundPreviewOptions.asObservable();
  public replacingBackground: boolean = false;
  public showBackgroundAsset$ = combineLatest([
    this.menu$,
    this.menuDomainModel.menuThemes$
  ]).pipe(
    map(([menu, themes]) => themes?.find(th => th.id === menu?.theme)?.themeFeatures?.backgroundMedia),
    distinctUntilChanged()
  );

  setupBindings() {
    // Subscribe to activeHydratedMenu
    const activeMenuSub = this.menu$.notNull().subscribe((m) => {
      const duplicatedMenuWithAsset = (m instanceof MenuTemplate)
        ? this.templateDomainModel.duplicatedMenuTemplatesWithAssets.get(m?.id)
        : this.menuDomainModel.duplicatedMenuIdsWithAsset.get(m?.id);
      if (!!duplicatedMenuWithAsset) {
        this.getRefreshedBackgroundAsset(duplicatedMenuWithAsset, ASSET_RETRY_COUNT);
      }
      this._menuBackgroundAsset.next(m?.backgroundImage);
    });
    this.pushSub(activeMenuSub);
  }

  getBackgroundPreviewOpts(): AssetPreviewOptions {
    const opts = new AssetPreviewOptions();
    opts.primaryButtonText = 'Remove Media';
    opts.primaryButtonDestructive = true;
    opts.secondaryButtonText = 'Change Media';
    return opts;
  }

  public deleteBackgroundAsset(onBackgroundDeleted: EventEmitter<boolean>) {
    this.menu$.once(menu => {
      const loadingOpts = this.backgroundAssetLoadingOpts;
      const lm = 'Deleting Background Asset';
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        this.menuDomainModel
          .deleteAsset(menu?.backgroundImage)
          .pipe(delay(ASSET_DELETE_DELAY * 1000))
          .subscribe({
            complete: () => {
              loadingOpts.removeRequest(lm);
              this.toastService.publishSuccessMessage('Background asset deleted.', 'Asset Deleted');
              menu.backgroundImage = null;
              this._menuBackgroundAsset.next(null);
              onBackgroundDeleted.emit(true);
            },
            error: (error: BsError) => {
              loadingOpts.removeRequest(lm);
              this.toastService.publishError(error);
              throwError(error);
            }
          });
      }
    });
  }

  public uploadBackgroundAsset(f: BudsenseFile): Observable<string[]> {
    return this.menu$.pipe(
      take(1),
      switchMap(menu => {
        const loadingOpts = this.backgroundAssetLoadingOpts;
        const lm = 'Uploading Background Asset';
        if (!loadingOpts.containsRequest(lm)) {
          loadingOpts.addRequest(lm);
          return this.menuDomainModel.uploadMenuBackgroundAsset(f, menu).pipe(
            map((_) => {
              loadingOpts.removeRequest(lm);
              if (f.isVideo()) f.replaceWithWebm();
              return [f.name];
            }),
            catchError((err: BsError) => {
              loadingOpts.removeRequest(lm);
              return throwError(err);
            })
          );
        }
      })
    );
  }

  public manualRefreshBackgroundAsset() {
    const loadingOpts = this.backgroundAssetLoadingOpts;
    const lm = 'Refreshing Asset';
    combineLatest([this.locationId$, this.menu$]).pipe(
      take(1),
      switchMap(([locationId, menu]) => {
        if (!loadingOpts.containsRequest(lm)) loadingOpts.addRequest(lm);
        const getHydratedMenu$ = (menu instanceof MenuTemplate)
          ? this.templateDomainModel.getHydratedMenuTemplate(locationId, menu?.id)
          : this.menuDomainModel.getHydratedMenu(menu?.id);
        return getHydratedMenu$.pipe(delay(ASSET_RETRY_DELAY * 1000));
      })
    ).subscribe(_ => loadingOpts.removeRequest(lm));
  }

  public getRefreshedBackgroundAsset(newFileNames: string[], remainingRetries: number) {
    const loadingOpts = this.backgroundAssetLoadingOpts;
    const lm = MediaUtils.getRefreshAssetLoadingMessage(remainingRetries);
    combineLatest([this.locationId$, this.menu$]).pipe(
      take(1),
      switchMap(([locationId, menu]) => {
        if (!loadingOpts.containsRequest(lm)) {
          loadingOpts.addRequest(lm);
          if (!exists(menu)) return of([]);
          const getHydratedMenu$ = (menu instanceof MenuTemplate)
            ? this.templateDomainModel.getHydratedMenuTemplate(locationId, menu?.id)
            : this.menuDomainModel.getHydratedMenu(menu?.id);
          return getHydratedMenu$.pipe(
            delay(ASSET_RETRY_DELAY * 1000),
            withLatestFrom(this.locationId$),
          );
        }
      }),
      take(1),
      takeUntil(this.onDestroy)
    ).subscribe(([menu, locId]) => {
      loadingOpts.removeRequest(lm);
      const noImageOrNewImage = !menu?.backgroundImage || !newFileNames.includes(menu?.backgroundImage.fileName);
      if (noImageOrNewImage && (remainingRetries > 0)) {
        this.getRefreshedBackgroundAsset(newFileNames, remainingRetries - 1);
      } else { // image is done uploading and is confirmed on the menu
        (menu instanceof MenuTemplate)
          ? this.templateDomainModel.duplicatedMenuTemplatesWithAssets.delete(menu?.id)
          : this.menuDomainModel.duplicatedMenuIdsWithAsset.delete(menu?.id);
        this.refreshMenuPreview(menu, locId);
      }
    });
  }

  refreshMenuPreview(m: Menu | MenuTemplate, locId: number) {
    const mKey = MenuPreviewService.getMenuPreviewKey(m?.id, locId);
    this.cacheService.removeCachedObject(mKey);
    this.menuPreviewService.getMenuPreview(m, false, 10000, false, true).pipe(take(1)).subscribe();
  }

  afterBackgroundAssetChanged = (uploadedFileNames: string[]) => {
    this.getRefreshedBackgroundAsset(uploadedFileNames, ASSET_RETRY_COUNT);
  };

}
