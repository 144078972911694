import { BaseViewModel } from '../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { LabelDomainModel } from '../../../domainModels/label-domain-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, defer, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { MenuType } from '../../../models/utils/dto/menu-type-definition';
import { StringifyUtils } from '../../../utils/stringify-utils';
import { DisplayAttributesDomainModel } from '../../../domainModels/display-attributes-domain-model';

@Injectable()
export abstract class LiveViewViewModel extends BaseViewModel {

  constructor(
    protected companyDomainModel: CompanyDomainModel,
    protected displayAttributesDomainModel: DisplayAttributesDomainModel,
    protected labelDomainModel: LabelDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected sanitizer: DomSanitizer
  ) {
    super();
  }

  abstract scaleToParentView$: Observable<number>;
  abstract url$: Observable<SafeResourceUrl>;
  protected abstract generateLiveViewURL(menuType: MenuType): SafeResourceUrl;

  private sendCompanyConfigSub: Subscription|null = null;
  private sendLabelsSub: Subscription|null = null;
  private sendLocationConfigSub: Subscription|null = null;

  public readonly locationDisplayAttributes$ = this.displayAttributesDomainModel.locationDisplayAttributes$;
  public readonly companyDisplayAttributes$ = this.displayAttributesDomainModel.companyDisplayAttributes$;
  public readonly companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  public readonly labels$ = this.labelDomainModel.allLabels$;
  public readonly iFrameName$ = of(Date.now()?.toString(10));

  private readonly _parentContainerWidth = new BehaviorSubject<number|null>(null);
  public readonly parentContainerWidth$ = this._parentContainerWidth.pipe(distinctUntilChanged());
  connectToParentContainerWidth = (width: number|null) => this._parentContainerWidth.next(width);

  private readonly _parentContainerHeight = new BehaviorSubject<number|null>(null);
  public readonly parentContainerHeight$ = this._parentContainerHeight.pipe(distinctUntilChanged());
  connectToParentContainerHeight = (height: number|null) => this._parentContainerHeight.next(height);

  private readonly _userSetScale = new BehaviorSubject<number|null>(null);
  public readonly userSetScale$ = this._userSetScale.pipe(distinctUntilChanged());
  connectToUserSetScale = (scale: number|null) => this._userSetScale.next(scale);

  public readonly scale$ = combineLatest([
    this.userSetScale$,
    defer(() => this.scaleToParentView$)
  ]).pipe(
    map(([userSetScale, scaleToParentView]) => userSetScale || scaleToParentView)
  );

  private sendCompanyConfig(iFrame: HTMLIFrameElement): void {
    this.sendCompanyConfigSub?.unsubscribe();
    this.sendCompanyConfigSub = this.companyConfig$.subscribeWhileAlive({
      owner: this,
      next: (companyConfig) => {
        const msg = { companyConfig: JSON.parse(JSON.stringify(companyConfig, StringifyUtils.displayAppReplacer)) };
        iFrame?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  private sendLocationConfig(iFrame: HTMLIFrameElement): void {
    this.sendLocationConfigSub?.unsubscribe();
    this.sendLocationConfigSub = this.locationConfig$.subscribeWhileAlive({
      owner: this,
      next: (locationConfig) => {
        const msg = { locationConfig: JSON.parse(JSON.stringify(locationConfig, StringifyUtils.displayAppReplacer)) };
        iFrame?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  private sendLabels(iFrame: HTMLIFrameElement): void {
    this.sendLabelsSub?.unsubscribe();
    this.sendLabelsSub = this.labels$.subscribeWhileAlive({
      owner: this,
      next: (labels) => {
        const msg = { labels: JSON.parse(JSON.stringify(labels, StringifyUtils.displayAppReplacer)) };
        iFrame?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  public sendDataToIFrame(iFrame: HTMLIFrameElement): void {
    this.sendCompanyConfig(iFrame);
    this.sendLocationConfig(iFrame);
    this.sendLabels(iFrame);
  }

}
