import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { DefaultSectionColumnConfig } from '../../../../models/menu/dto/default-section-column-config';
import { ThemeSectionColumnConfig } from '../../../../models/menu/dto/theme-section-column-config';
import { PopperUtils } from '../../../../utils/popper-utils';
import { SectionColumnConfigKey } from '../../../../models/enum/dto/section-column-config-key';

@Component({
  selector: 'app-reset-column-options',
  templateUrl: './reset-column-options.component.html',
  styleUrls: ['./reset-column-options.component.scss']
})
export class ResetColumnOptionsComponent extends BaseComponent {

  @Input() themeDefault: Map<SectionColumnConfigKey, ThemeSectionColumnConfig>;
  @Output() resetClicked: EventEmitter<DefaultSectionColumnConfig> = new EventEmitter<DefaultSectionColumnConfig>();

  // Popper
  public popperModifier = [PopperUtils.flipModifier()];
  public popperStyles = { 'background-color': '#FFFFFF' };

}
