import type { CustomVariantInput } from '../views/product/components/modals/edit-variant-modal/edit-variant-container';
import { environment } from '../../environments/environment';

const FireAndFlowerCompanyId = 68510706;
const StiiizyCompanyId = 43194004;

export class CompanyFeatureUtils {

  static getCompanyDisabledSettingDefaultTooltip(): string {
    return 'This setting is disabled for your company';
  }

  static companyCanEditShowDiscontinuedProducts(companyId: number): boolean {
    switch (companyId) {
      case FireAndFlowerCompanyId:
        return false;
      default:
        return true;
    }
  }

  static companyCanSetSyncCannabinoidFromPOS(companyId: number): boolean {
    switch (companyId) {
      case FireAndFlowerCompanyId:
        return false;
      default:
        return true;
    }
  }

  /**
   * The Variant.ts object has custom1, custom2, and custom3 as addition string properties. These properties are
   * used to store additional information about the variant. This method will return the supported custom
   * variant inputs for the given company. The order in which they are returned is the order that they are displayed
   * in the UI.
   */
  static getSupportedCustomVariantInputs(companyId: number): CustomVariantInput[] {
    switch (true) {
      case environment.production && companyId === StiiizyCompanyId:
        return [{ label: 'FlowHub Variant Type', bindToVariantProperty: 'custom1', disabled: true }];
      default:
        // If the company does not return specific configs, return null and use the default configs
        return null;
    }
  }

}
