import { BaseDomainModel } from '../models/base/base-domain-model';
import { LocationDomainModel } from './location-domain-model';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { BulkPrintJob } from '../models/automation/bulk-print-job';
import { MenuAPI } from '../api/menu-api';
import { Injectable } from '@angular/core';
import { DistinctUtils } from '../utils/distinct-utils';
import { MenuDomainModel } from './menu-domain-model';
import { TemplateDomainModel } from './template-domain-model';
import { iiif } from '../utils/observable.extensions';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { SortUtils } from '../utils/sort-utils';
import { MenuPreviewJobStatus, MenuPreviewJobStatusType } from '../models/utils/dto/menu-preview-job-status-type';

// Provided by Logged In Scope
@Injectable()
export class BulkPrintJobDomainModel extends BaseDomainModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    private menuAPI: MenuAPI,
    private menuDomainModel: MenuDomainModel,
    private templateDomainModel: TemplateDomainModel
  ) {
    super();
    this.getBulkPrintJobsForCurrentLocation();
    this.initializeActiveMenuBulkPrintJobs();
  }

  public readonly locationId$ = this.locationDomainModel.locationId$;

  /* ***************************** Active Menu Bulk Print Jobs ***************************** */

  private readonly _activeMenuBulkPrintJobs = new BehaviorSubject<BulkPrintJob[] | null>([]);
  public readonly activeMenuBulkPrintJobs$ = this._activeMenuBulkPrintJobs.pipe(
    map(jobs => jobs?.sort(SortUtils.sortBulkPrintJobsByMostRecent)),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public readonly activeSuccessfulMenuBulkPrintJobs$ = this.activeMenuBulkPrintJobs$.pipe(
    map(jobs => jobs?.filter(j => MenuPreviewJobStatusType.jobIsSuccessfulOrSent(MenuPreviewJobStatus[j?.jobStatus])))
  );

  public updateActiveBulkPrintJob(job: BulkPrintJob) {
    this.activeMenuBulkPrintJobs$.once(jobs => {
      const updatedJobs = jobs?.shallowCopy() ?? [];
      const existingIndex = updatedJobs.findIndex(j => j.id === job.id);
      if (existingIndex > -1) updatedJobs.splice(existingIndex, 1);
      updatedJobs.push(job);
      this._activeMenuBulkPrintJobs.next(updatedJobs);
    });
  }

  private readonly _templateMode = new BehaviorSubject<boolean>(false);
  public readonly templateMode$ = this._templateMode.pipe(distinctUntilChanged());
  connectToTemplateMode = (templateMode: boolean) => this._templateMode.next(templateMode);

  public readonly activeMenu$ = iiif(
    this.templateMode$,
    this.templateDomainModel.activeMenuTemplate$,
    this.menuDomainModel.activeHydratedMenu$
  );

  public readonly activeMenuId$ = this.activeMenu$.pipe(
    map(menu => menu?.id),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /* ***************************** Current Location Bulk Print Jobs ***************************** */

  private _currentLocationBulkPrintJobs = new BehaviorSubject<BulkPrintJob[]>(null);
  public currentLocationBulkPrintJobs$ = this._currentLocationBulkPrintJobs.pipe(
    map(jobs => jobs?.sort(SortUtils.sortBulkPrintJobsByMostRecent)),
    debounceTime(1),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public currentSuccessfulLocationBulkPrintJobs$ = this.currentLocationBulkPrintJobs$.pipe(
    map(jobs => jobs?.filter(j => MenuPreviewJobStatusType.jobIsSuccessfulOrSent(MenuPreviewJobStatus[j?.jobStatus]))),
    distinctUntilChanged(DistinctUtils.distinctBulkPrintJobsById)
  );

  private updateCurrentLocationBulkPrintJobs(jobsToUpdate: BulkPrintJob[]) {
    this._currentLocationBulkPrintJobs.once(existingJobs => {
      const jobIdsToUpdate = jobsToUpdate?.map(j => j?.id) ?? [];
      const filteredJobs = existingJobs?.filter(j => !jobIdsToUpdate?.includes(j?.id)) ?? [];
      const updatedJobs = filteredJobs?.concat(jobsToUpdate) ?? [];
      this._currentLocationBulkPrintJobs.next(updatedJobs);
    });
  }

  public getBulkPrintJobs(locationId: string, jobIds?: string[], startKey?: string) {
    this._currentLocationBulkPrintJobs.next(null);
    return this.menuAPI.GetBulkPrintJobs(locationId, jobIds).pipe(
      tap(jobs => this.updateCurrentLocationBulkPrintJobs(jobs)),
    );
  }

  public bulkPrintJobContainsStackedContentAtMenuLevel(req: BulkPrintJob): Observable<boolean> {
    if (!req?.menuIds?.length) return of(false);
    return this.menuDomainModel.currentLocationStackedMenus$.pipe(
      map(menus => menus?.some(m => req?.menuIds?.includes(m.id))),
      take(1)
    );
  }

  public createBulkPrintJob(req: BulkPrintJob): Observable<BulkPrintJob> {
    return this.menuAPI.CreateBulkPrintJob(req).pipe(
      tap(job => this.updateCurrentLocationBulkPrintJobs([job]))
    );
  }

  public retryBulkPrintJob(req: BulkPrintJob): Observable<BulkPrintJob> {
    const newReq = window?.injector?.Deserialize?.instanceOf(BulkPrintJob, req);
    newReq.setDataAsRetry();
    return this.menuAPI.CreateBulkPrintJob(newReq).pipe(
      tap(job => this.updateCurrentLocationBulkPrintJobs([job]))
    );
  }

  public cancelBulkPrintJob(req: BulkPrintJob): Observable<BulkPrintJob> {
    return this.menuAPI.CancelBulkPrintJob(req).pipe(
      tap(job => this.updateCurrentLocationBulkPrintJobs([job]))
    );
  }

  public getUpdatedBulkPrintJobStatus(jobs: BulkPrintJob[]): Observable<BulkPrintJob[]> {
    return this.locationId$.pipe(
      take(1),
      switchMap(locationId => {
        return this.menuAPI.GetBulkPrintJobs(locationId.toString(), jobs.map(j => j.id)).pipe(
          tap(updatedJobs => this.updateCurrentLocationBulkPrintJobs(updatedJobs)),
        );
      })
    );
  }

  public getActiveMenuBulkPrintJobs(): void {
    combineLatest([this.activeMenu$, this.locationId$.notNull()]).pipe(
      take(1),
      switchMap(([activeMenu, locationId]) => {
        return activeMenu instanceof MenuTemplate
          ? this.menuAPI.GetMenuTemplateBulkPrintJobs(activeMenu?.id, String(locationId))
          : this.menuAPI.GetMenuBulkPrintJobs(activeMenu?.id, String(locationId));
      })
    ).subscribe({
      next: jobs => {
        jobs = jobs ?? [];
        this._activeMenuBulkPrintJobs.next(jobs);
        this.updateCurrentLocationBulkPrintJobs(jobs);
      }
    });
  }

  /* *************************** Local Threads of Execution *************************** */

  private getBulkPrintJobsForCurrentLocation(): void {
    this.locationId$
      .notNull()
      .pipe(distinctUntilChanged())
      .subscribeWhileAlive({
        owner: this,
        next: locationId => this.getBulkPrintJobs(String(locationId)).subscribe()
      });
  }

  private initializeActiveMenuBulkPrintJobs(): void {
    combineLatest([
      this.activeMenu$.pipe(distinctUntilChanged(DistinctUtils.distinctByMenuId)),
      this.locationId$.notNull().pipe(distinctUntilChanged())
    ]).subscribeWhileAlive({
      owner: this,
      next: ([menu]) => {
        this._activeMenuBulkPrintJobs.next([]);
        if (menu?.containsStackedContent()) {
          this.getActiveMenuBulkPrintJobs();
        }
      }
    });
  }

}
