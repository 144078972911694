import { Injectable } from '@angular/core';
import { BaseService } from '@mobilefirstdev/base-angular';
import { WebSocketReceiveMessage } from '../models/web-sockets/messages/receive/web-socket-receive-message';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { StringifyUtils } from '../utils/stringify-utils';
import { catchError, distinctUntilChanged, filter, finalize, retry, shareReplay, switchMap, take, tap, timeout, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, interval, merge, NEVER, Observable, of, throwError, timer } from 'rxjs';
import { ToastService } from './toast-service';
import { environment } from '../../environments/environment';
import { UserDomainModel } from '../domainModels/user-domain-model';
import { DistinctUtils } from '../utils/distinct-utils';
import { LocationDomainModel } from '../domainModels/location-domain-model';
import { HydratedUser } from '../models/account/dto/hydrated-user';
import { WebSocketMessageContext } from '../models/web-sockets/contexts/web-socket-message-context';
import { exists } from '../functions/exists';
import { WebSocketSendMessage } from '../models/web-sockets/messages/send/web-socket-send-message';
import { WebSocketConnectionEstablished } from '../models/web-sockets/messages/receive/web-socket-connection-established';
import { WebSocketMessageContextConnectionManager } from '../models/web-sockets/contexts/web-socket-message-context-connection-manager';
import { WebSocketRespondToHealthCheck } from '../models/web-sockets/messages/send/web-socket-respond-to-health-check';
import { WebSocketPong } from '../models/web-sockets/messages/receive/web-socket-pong';
import { WebSocketPing } from '../models/web-sockets/messages/send/web-socket-ping';
import { WebSocketConnectionState } from '../models/web-sockets/web-socket-connection-state';
import { ConnectionStatus } from '../models/web-sockets/enums/connection-status';

/**
 * Provided by Logged In Scope. This means the WebSocketService will be created when a user logs in,
 * and destroyed when the user logs out. The WebSocket API is protected by authentication, so the user must be
 * logged in to use it.
 *
 * If a domain model uses getRelevantMessages, make sure it's created as soon as the user logs in, within
 * the DashboardComponent (life cycle is scoped to the users authenticated state), so that it doesn't miss any
 * messages coming in from the WebSocket.
 */
@Injectable()
export class WebSocketService extends BaseService {

  constructor(
    private toastService: ToastService,
    private locationDomainModel: LocationDomainModel,
    private userDomainModel: UserDomainModel,
  ) {
    super();
    this.respondToHealthChecks();
    this.consoleLogUnaccountedForMessageContexts();
  }

  /**
   * The number of times the WebSocket connection has been retried.
   * Resets to 0 when the connection is successfully established.
   */
  private retryConnectionCount = 0;

  /**
   * When a WebSocket is opened, the server sends a "Connection Established" message to the client.
   * If the message is not received within the specified time, the connection is considered dead.
   */
  private waitNSecondsForConnectionEstablishedMessage = 5;

  /**
   * The client pings the server every n seconds to check if the connection is still alive.
   */
  private pingServerEveryNSeconds = 60;

  /**
   * The client waits x seconds for the server to respond to a ping with a pong. If the server takes longer than
   * n seconds to respond, the ping is considered a failure.
   */
  private waitNSecondsForPongResponse = 5;

  /**
   * If the server fails to respond with n consecutive pongs, the connection is considered dead.
   */
  private numberOfFailedConsecutivePingsBeforeConnectionIsConsideredDead = 3;

  /**
   * An enum representation of the WebSocket connection status.
   * The connection status can be: Connecting, Connected, Disconnected.
   */
  private readonly _socketConnectionStatus = new BehaviorSubject<ConnectionStatus>(ConnectionStatus.Connecting);
  public readonly socketConnectionStatus$ = this._socketConnectionStatus.pipe(distinctUntilChanged());

  /**
   * WebSocketMessageContextConnectionManager.ConnectionEstablished is accounted for by default within socket$.
   * WebSocketMessageContextConnectionManager.Pong is accounted for by default within socket$.
   */
  private accountedForMessageContexts: WebSocketMessageContext[] = [
    WebSocketMessageContextConnectionManager.ConnectionEstablished,
    WebSocketMessageContextConnectionManager.Pong
  ];

  /**
   * Calling .complete() on the WebSocketSubject will close the underlying WebSocket connection gracefully by
   * sending a Close Frame to the server. It also completes the observable sequence, so any subscribers to the
   * WebSocketSubject will receive a complete notification.
   */
  private _socket: WebSocketSubject<WebSocketSendMessage|WebSocketReceiveMessage>;

  /**
   * The WebSocket is authenticated with the user's access token. Therefore, we only emit new user objects
   * if the user's access token changes.
   */
  private readonly userForWebSocket$ = this.userDomainModel.user$.pipe(
    filter(user => exists(user)),
    distinctUntilChanged((a, b) => DistinctUtils.distinctByUserIdAndAccessToken(a, b))
  );

  /**
   * WebSockets take in an entity id, which is the location id for the client dashboard.
   */
  private readonly locationIdForWebSocket$ = this.locationDomainModel.locationId$.pipe(
    filter(locationId => Number.isFinite(locationId))
  );

  /**
   * The web socket connection with automatic reconnection.
   * "Connection Established" and "Pong" messages are handled inside the socket$ observable.
   *
   * export interface WebSocketSubjectConfig<T> {
   *   url: string;
   *   // The protocol to use to connect
   *   protocol?: string | Array<string>;
   *   // @deprecated Will be removed in v8. Use {@link deserializer} instead.
   *   resultSelector?: (e: MessageEvent) => T;
   *   // A serializer used to create messages from passed values before the
   *   // messages are sent to the server. Defaults to JSON.stringify.
   *   serializer?: (value: T) => WebSocketMessage;
   *   // A deserializer used for messages arriving on the socket from the server. Defaults to JSON.parse.
   *   deserializer?: (e: MessageEvent) => T;
   *   // An Observer that watches when open events occur on the underlying web socket.
   *   openObserver?: NextObserver<Event>;
   *   // An Observer that watches when close events occur on the underlying web socket
   *   closeObserver?: NextObserver<CloseEvent>;
   *   // An Observer that watches when a close is about to occur due to unsubscription.
   *   closingObserver?: NextObserver<void>;
   *   // A WebSocket constructor to use. This is useful for situations like using a
   *   // WebSocket impl in Node (WebSocket is a DOM API), or for mocking a WebSocket for testing purposes
   *   WebSocketCtor?: { new (url: string, protocols?: string | string[]): WebSocket };
   *   // Sets the `binaryType` property of the underlying WebSocket.
   *   binaryType?: 'blob' | 'arraybuffer';
   * }
   */
  private readonly socket$ = combineLatest([
    this.userForWebSocket$,
    this.locationIdForWebSocket$
  ]).pipe(
    switchMap(([user, locationId]) => {
      this._socketConnectionStatus.next(ConnectionStatus.Connecting);
      this._socket?.complete();
      this._socket = webSocket({
        url: this.buildSocketUrl(user, locationId),
        deserializer: this.deserializer,
        serializer: this.serializer,
        openObserver: this.webSocketAPIConnectionOpened(),
        closeObserver: this.webSocketAPIConnectionClosed()
      });
      // Having this is here is weird, but it simplifies the retry connection logic. If an error is thrown, then the
      // retry connection logic will automatically kick in.
      const throwErrorIfServerDoesNotEstablishConnection$  = this._socket.pipe(
        filter((msg): msg is WebSocketConnectionEstablished => msg instanceof WebSocketConnectionEstablished),
        take(1),
        // timeout will throw an error if the server doesn't respond with a connection established message
        timeout(this.waitNSecondsForConnectionEstablishedMessage * 1000),
        switchMap(connectionEstablished => {
          return connectionEstablished?.payload?.connectionState === WebSocketConnectionState.Connected
            ? of(this._socket)
            : throwError(() => 'WebSocket established, but has no backend connection.');
        }),
        catchError(() => throwError(() => 'Server failed to establish socket connection')),
        // prevent this observable from emitting, we only want to throw errors to trigger retry connection logic
        filter(() => false)
      );
      // Having this is here is weird, but it simplifies the retry connection logic. If an error is thrown, then the
      // retry connection logic will automatically kick in.
      const throwErrorIfServerDoesNotRespondToPings$ = interval(this.pingServerEveryNSeconds * 1000).pipe(
        withLatestFrom(this.locationIdForWebSocket$),
        switchMap(([_, locationId]) => {
          return of(true).pipe(
            tap(() => this.sendMessage(new WebSocketPing(locationId))),
            switchMap(() => this._socket),
            filter((msg): msg is WebSocketPong => msg instanceof WebSocketPong),
            take(1),
            // timeout will throw an error if the server doesn't respond with a pong
            timeout(this.waitNSecondsForPongResponse * 1000),
            // error will be caught N times before error is thrown up the chain
            retry(this.numberOfFailedConsecutivePingsBeforeConnectionIsConsideredDead - 1),
            switchMap(() => of(this._socket)),
          );
        }),
        // prevent this observable from emitting, we only want to throw errors to trigger retry connection logic
        filter(() => false),
      );
      return merge(
        of(this._socket),
        throwErrorIfServerDoesNotEstablishConnection$,
        throwErrorIfServerDoesNotRespondToPings$
      );
    })
  ).pipe(
    retry(this.failedToConnectTryAgain()),
    catchError((error) => {
      console.warn('Failed to connect to WebSocket:', error);
      return of(NEVER);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /**
   * EntityId can be locationId or companyId, but since we always know the locationId for the users session, we use
   * the location level for better granularity, which allows the other end of the connections to send location specific
   * messages.
   *
   * Header params are not supported by the browser WebSocket API, so we have to pass them as query parameters.
   */
  private buildSocketUrl(user: HydratedUser, locationId: number): string {
    return `${environment.webSocketUrl}`
      + `?ClientType=WebsocketClientType_User`
      + `&ClientId=${user?.userId}`
      + `&ClientSecret=${environment?.cognitoClientSecret}`
      + `&Token=${user?.session?.accessToken}`
      + `&EntityId=${locationId}`
      + `&source=Dashboard`;
  }

  private deserializer =  (e: MessageEvent<string>): WebSocketReceiveMessage => {
    return window?.injector?.Deserialize?.instanceOf(WebSocketReceiveMessage, JSON.parse(e?.data));
  };

  private serializer = (msg: any): any => JSON.stringify(msg?.onSerialize?.() ?? msg, StringifyUtils.replacer);

  private webSocketAPIConnectionOpened(): { next: () => void } {
    return {
      next: () => {
        // eslint-disable-next-line no-console
        console.log('WebSocket API connection opened');
        this.retryConnectionCount = 0;
        this._socketConnectionStatus.next(ConnectionStatus.Connected);
      }
    };
  }

  private webSocketAPIConnectionClosed(): { next: () => void } {
    return {
      next: () => {
        // eslint-disable-next-line no-console
        console.log('WebSocket API connection closed');
      }
    };
  }

  /**
   * @param retryAttempts is the number of reconnection attempts
   * @param reconnectIntervalInMilliSeconds is the delay between retries
   */
  private failedToConnectTryAgain(
    retryAttempts: number = 10,
    reconnectIntervalInMilliSeconds: number = 5000
  ): { delay: (error: any) => any } {
    return {
      delay: (error) => {
        if (this.retryConnectionCount >= retryAttempts) {
          this.toastService.publishErrorMessage('Could not connect to server', 'WebSocket Error');
          console.error('WebSocket Error: Maximum retry limit reached');
          this._socketConnectionStatus.next(ConnectionStatus.Disconnected);
          throw error;
        }
        this.retryConnectionCount++;
        console.warn(`WebSocket error, attempting to reconnect (#${this.retryConnectionCount}):`, error);
        this._socketConnectionStatus.next(ConnectionStatus.Connecting);
        return timer(reconnectIntervalInMilliSeconds); // delay between retries
      },
    };
  }

  private consoleLogUnaccountedForMessageContexts(): void {
    this.socket$.pipe(
      switchMap(socket => socket),
      filter((message): message is WebSocketReceiveMessage => message instanceof WebSocketReceiveMessage),
      filter(message => exists(message) && !this.accountedForMessageContexts?.includes(message?.messageContext)),
    ).subscribeWhileAlive({
      owner: this,
      next: (message) => console.warn('Unaccounted for WebSocket message context: ', message?.messageContext)
    });
  }

  private respondToHealthChecks(): void {
    this.getRelevantMessages([WebSocketMessageContextConnectionManager.HealthCheck]).pipe(
      withLatestFrom(this.locationIdForWebSocket$)
    ).subscribeWhileAlive({
      owner: this,
      next: ([msg, locationId]) => this.sendMessage(new WebSocketRespondToHealthCheck(locationId))
    });
  }

  /**
   * @param relevantMessageContexts is required, if you pass in null or an empty array, the observable will never emit.
   */
  public getRelevantMessages(
    relevantMessageContexts: WebSocketMessageContext[]
  ): Observable<WebSocketReceiveMessage> {
    if (!relevantMessageContexts?.length) return NEVER;
    relevantMessageContexts?.forEach(context => this.accountedForMessageContexts.push(context));
    return this.socket$.pipe(
      switchMap(socket => socket),
      filter((message): message is WebSocketReceiveMessage => message instanceof WebSocketReceiveMessage),
      filter(message => exists(message) && relevantMessageContexts?.includes(message?.messageContext)),
      finalize(() => relevantMessageContexts?.forEach(context => this.accountedForMessageContexts.remove(context))),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  sendMessage(message: WebSocketSendMessage): void {
    if (!this._socket) {
      console.warn('WebSocket connection is not established, message did not send:', message);
      return;
    }
    this._socket.next(message);
  }

  override destroy() {
    super.destroy();
    this._socket?.complete();
  }

}
