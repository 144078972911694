import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { ModalUtils } from '../utils/modal-utils';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { TemplatePublishComponent } from '../views/template/components/shared/template-publish/template-publish.component';
import { LoadingOptions } from '../models/shared/loading-options';
import { Observable } from 'rxjs';

export class ModalPublishTemplate {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    menuTemplate$: Observable<MenuTemplate>,
    parentLoadingOpts$?: Observable<LoadingOptions>,
    onClose?: (menuTemplate: MenuTemplate) => void
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(TemplatePublishComponent, ModalUtils.defaultModalOptions(injector));
      const compInstance = modalRef.componentInstance as TemplatePublishComponent;
      compInstance.viewModel.connectToMenuTemplate(menuTemplate$);
      compInstance.viewModel.connectToParentLoadingOpts(parentLoadingOpts$);
      modalRef.result.then(updatedTemplate => onClose?.(updatedTemplate)).catch(() => {});
    });
  }

}
