import { PriceUtils } from './price-utils';
import { PriceFormat } from '../models/enum/dto/price-format';

export { };
declare global {
  export interface Number {
    applyPriceFormatRounding(format: PriceFormat): number | null;
  }
}

Number.prototype.applyPriceFormatRounding = function(format: PriceFormat): number | null {
  switch (format) {
    case PriceFormat.TaxesInRounded:
      return PriceUtils.roundToNearest5CentsOrNull(this);
    default:
      return this;
  }
};
