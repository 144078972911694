import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { Injectable, Injector, NgZone } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BulkPrintJob } from '../../../../../../models/automation/bulk-print-job';
import { BulkPrintJobDomainModel } from '../../../../../../domainModels/bulk-print-job-domain-model';
import { BulkJobSource } from '../../../../../../models/utils/dto/bulk-job-source-type';
import { MenuType, MenuTypeDefinition } from '../../../../../../models/utils/dto/menu-type-definition';
import { ModalCreateBulkPrintJob } from '../../../../../../modals/modal-create-bulk-print-job';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class BulkPrintJobsListViewModel extends BaseViewModel {

  constructor(
    protected bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  public menuTypeFilterOptions$ = MenuTypeDefinition.getPrintMenuTypeOptions().pipe(
    map(menuTypes => menuTypes?.filter(mt => mt?.value !== MenuType.PrintReportMenu))
  );

  // Only jobs created from bulk job flow should appear
  public currentLocationBulkPrintJobs$ = this.bulkPrintJobDomainModel.currentLocationBulkPrintJobs$.pipe(
    map(jobs => jobs?.filter(j => j?.jobSource === BulkJobSource.BulkJobSource_BulkPrint))
  );

  private _searchText = new BehaviorSubject<string>(null);
  public searchText$ = this._searchText as Observable<string>;
  public connectToSearchText = (t: string) => this._searchText.next(t);

  private _menuTypeFilter = new BehaviorSubject<MenuType>(null);
  public menuTypeFilter$ = this._menuTypeFilter as Observable<MenuType>;
  connectToMenuTypeFilter = (menuType: MenuType) => this._menuTypeFilter.next(menuType);

  private _searchedItems = new BehaviorSubject<BulkPrintJob[]>(null);
  public searchedItems$ = this._searchedItems.pipe(debounceTime(1));
  public connectToSearchedItems = (items: BulkPrintJob[]) => this._searchedItems.next(items);

  public filteredSearchedItem$ = combineLatest([
    this.searchedItems$,
    this.menuTypeFilter$
  ]).pipe(
    map(([searchedItems, menuTypeFilter]) => {
      return !!menuTypeFilter
        ? searchedItems?.filter(item => item?.menuType === menuTypeFilter)
        : searchedItems;
    })
  );

  public noResultsTitle$ = combineLatest([
    this.searchText$,
    this.menuTypeFilter$
  ]).pipe(
    map(([searchText, menuTypeFilter]) => {
      return searchText?.length > 1 || !!menuTypeFilter
        ? `No Results`
        : `No Bulk Print Jobs`;
    })
  );

  public noResultsText$ = combineLatest([
    this.searchText$,
    this.menuTypeFilter$
  ]).pipe(
    map(([searchText, menuTypeFilter]) => {
      return searchText?.length > 1 || !!menuTypeFilter
        ? `There are no results that match your search in Bulk Prints.`
        : `Any bulk print jobs that have been created at this location will show up here.`;
    })
  );

  public createBulkPrintJob(): void {
    ModalCreateBulkPrintJob.open(this.ngZone, this.ngbModal, this.injector);
  }

}
