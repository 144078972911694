import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { Variant } from '../../../../../../../../models/product/dto/variant';
import { StackPrintJobAppliedProductsViewModel } from '../../../../../../../shared/modals/create-view-bulk-print-job/stack-bulk-print-job/bulk-card-stack-print-job-applied-products/stack-print-job-applied-products-view-model';
import type { StackType } from '../create-view-stack-print-job.component';

@Injectable()
export class SingleStackPrintJobAppliedProductsViewModel extends StackPrintJobAppliedProductsViewModel {

  public readonly variantCountMap$ = this.job$.pipe(
    map(job => job?.getCardStackPrintConfigFor(job?.getTargetedMenuId())?.variantCardCountMap),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _stackType = new BehaviorSubject<StackType>('card');
  public readonly stackType$ = this._stackType.pipe(distinctUntilChanged());
  connectToStackType = (type: StackType) => this._stackType.next(type);

  private _searchText = new BehaviorSubject<string>(null);
  public searchText$ = this._searchText as Observable<string>;
  connectToSearchText = (x: string) => this._searchText.next(x);

  public isSearching$ = this.searchText$.pipe(
    map(searchText => searchText?.length >= 2),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly noVariantsForSearchPlaceholder$ = this.searchText$.pipe(
    map(searchText => `No variants found for "${searchText}"`),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private search = (haystack: string, needle: string) => {
    return haystack?.toLowerCase()?.trim()?.includes(needle?.toLowerCase()?.trim());
  };

  public variants$: Observable<Variant[]> = combineLatest([
    this.job$,
    this.productDomainModel.currentLocationVariants$,
    this.searchText$
  ]).pipe(
    map(([job, allVariants, searchText]) => {
      const previewJob = job?.previewJobs?.firstOrNull();
      const appliedVariantIds = previewJob?.cardStackPrintConfig?.variantIds;
      const appliedVariants: Variant[] = allVariants?.filter(v => appliedVariantIds?.includes(v.id));
      if (searchText?.length >= 2) {
        return appliedVariants?.filter(v => this.search(v?.getVariantTitle(), searchText));
      }
      return appliedVariants;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
