import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, Injector, Input, OnInit, ViewChild, ViewRef } from '@angular/core';
import { ReactiveTableRowBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { Variant } from 'src/app/models/product/dto/variant';
import { Product } from 'src/app/models/product/dto/product';
import { AllProductsDataTableViewModel } from '../../all-products-data-table-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { StringUtils } from '../../../../../utils/string-utils';
import { LabelUtils } from '../../../../../modules/product-labels/utils/label-utils';
import { LabelInflatorComponent } from '../../../../../modules/product-labels/label-inflator/label-inflator.component';
import { DisplayLabelInterface } from '../../../../../modules/product-labels/label/display-label-interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalEditVariant } from '../../../../../modals/modal-edit-variant';
import { TableBadgeUtils } from '../../../utils/table-badge-utils';
import { ClientTypeUtils } from '../../../../../utils/client-type-utils';
import { PrimaryCannabinoid } from '../../../../../models/enum/shared/primary-cannabinoid.enum';

@Component({
  selector: 'app-all-products-table-item',
  templateUrl: './all-products-table-item.component.html',
  styleUrls: ['./all-products-table-item.component.scss'],
  providers: [
    {
      provide: ReactiveTableRowBluePrintComponent,
      useExisting: forwardRef(() => AllProductsTableItemComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllProductsTableItemComponent extends ReactiveTableRowBluePrintComponent implements OnInit {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    changeDetector: ChangeDetectorRef,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    public viewModel: AllProductsDataTableViewModel // global - provided by root
  ) {
    super(viewRef, changeDetector);
  }

  @Input() override rowData: Product;
  @ViewChild('productLabel') productLabelInflator: LabelInflatorComponent;

  private _rowData = new BehaviorSubject<Product|null>(this.rowData);
  public rowData$ = this._rowData as Observable<Product|null>;
  public readonly labelZoom$ = new BehaviorSubject(0.8) as Observable<number>;

  public priceNoDollarSigns$ = combineLatest([
    this.rowData$,
    this.viewModel.locationId$,
    this.viewModel.priceFormat$
  ]).pipe(
    map(([product, locationId, priceStream]) => product?.getPriceNoDollarSigns(locationId, priceStream, false))
  );

  public secondaryPriceNoDollarSigns$ = combineLatest([
    this.rowData$,
    this.viewModel.locationId$,
    this.viewModel.companyId$
  ]).pipe(
    map(([product, locationId, companyId]) => product?.getSecondaryPriceNoDollarSigns(locationId, companyId))
  );

  public locationId$ = this.viewModel.locationId$;
  public companyId$ = this.viewModel.companyId$;
  public priceFormat$ = this.viewModel.priceFormat$;
  public inventoryProvider$ = this.viewModel.inventoryProvider$;

  public manufacturer$ = this.rowData$.pipe(
    map(rowData => StringUtils.getStringMode(rowData?.variants?.filter(v => !!v.manufacturer).map(v => v.manufacturer)))
  );
  public brand$ = this.rowData$.pipe(
    map(rowData => StringUtils.getStringMode(rowData?.variants?.filter(v => !!v.brand).map(v => v.brand)))
  );

  public expanded$ = combineLatest([this.rowData$, this.viewModel.selectedProductId$]).pipe(
    map(([rowData, selectedProductId]) => rowData?.id === selectedProductId)
  );

  public labels$ = this.rowData$.pipe(map(label => label?.computedLabelsForProductTable));

  public badges$ = this.rowData$.pipe(
    map((rowData) => TableBadgeUtils.getAllBadgesForVariants(rowData?.variantsFilteredByTable))
  );

  public rowDataVariants$ = this.rowData$.pipe(
    map(rowData => rowData?.variantsFilteredByTable?.shallowCopy()),
  );

  public readonly dataForLabelInterface$ = combineLatest([
    this.rowData$,
    this.viewModel.locationConfig$,
    this.viewModel.companyConfig$
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly productLabelInterface$: Observable<DisplayLabelInterface> = this.dataForLabelInterface$.pipe(
    map(([product, locationConfig, companyConfig]) => {
      return LabelUtils.getDisplayLabelInterfaceForProductInProductTable(product, locationConfig, companyConfig);
    })
  );

  ngOnInit() {
    this._rowData.next(this.rowData);
  }

  rowDataChanged(newRowData: any): void {
    this._rowData.next(newRowData);
  }

  public openEditVariantModal(variant: Variant): void {
    ModalEditVariant.open(this.ngbModal, this.injector, variant);
  }

  protected readonly ClientTypeUtils = ClientTypeUtils;

  protected readonly PrimaryCannabinoid = PrimaryCannabinoid;

}
