/* eslint-disable prefer-const,padded-blocks */
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, defer, iif, Observable, of } from 'rxjs';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { Variant } from '../../../../../../models/product/dto/variant';
import { VariantGroup } from '../../../../../../models/product/shared/variant-group';
import { debounceTime, distinctUntilChanged, map, pairwise, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../../utils/distinct-utils';
import { SortUtils } from '../../../../../../utils/sort-utils';
import { Section } from '../../../../../../models/menu/dto/section';
import { CompanyDomainModel } from '../../../../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { Selectable } from '../../../../../../models/protocols/selectable';
import { SearchUtils } from '../../../../../../utils/search-utils';
import { PaginationUtils } from '../../../../../../utils/pagination-utils';
import { PrintCardMenuUtils } from '../../../../../../utils/print-card-menu-utils';
import { MenuTypeDefinition } from '../../../../../../models/utils/dto/menu-type-definition';
import { exists } from '../../../../../../functions/exists';
import { PriceFormat } from '../../../../../../models/enum/dto/price-format';
import { SectionType } from '../../../../../../models/enum/dto/section-type';
import type { SectionSortOption } from '../../../../../../models/enum/dto/section-sort-option';
import { LabelDomainModel } from '../../../../../../domainModels/label-domain-model';
import { PrintCardTheme } from '../../../../../../models/enum/dto/theme.enum';
import { ProductThatRepresentsVariantsGroupedByBrand } from '../../../../../../models/product/shared/product-that-represents-variants-grouped-by-brand';
import { ThemeUtils } from '../../../../../../utils/theme-utils';

type VisibilityOption = 'Visible' | 'Hidden' | null;
type SearchedVariantGroup = { productId: string, variantIds: string[] };

@Injectable()
export class MenuSectionVariantGroupsViewModel extends BaseViewModel {

  constructor(
    protected companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel,
    private labelDomainModel: LabelDomainModel,
  ) {
    super();
  }

  protected override _loadingOpts = new BehaviorSubject<LoadingOptions>(
    LoadingOptions.defaultOpaqueWhiteBackground(0.75)
  );

  public displaysUnitsInRanges$ = this.companyDomainModel.rangeCannabinoidsAtCompanyLevel$;

  /* ********************* Internal RX Circuit  *************************/

  // Internal RX Circuit - Inputs
  private _menu = new BehaviorSubject<Menu>(null);
  private _section = new BehaviorSubject<HydratedSection>(null);
  private _showZeroStockItems = new BehaviorSubject<boolean>(false);
  private _sortedSectionVariants = new BehaviorSubject<Variant[]>([]);
  private _sortedVisibleSectionGroupedVariants = new BehaviorSubject<VariantGroup[]>([]);
  private _sortedHiddenSectionGroupedVariants = new BehaviorSubject<VariantGroup[]>([]);
  private _showWillNotAppearOnMenuSection = new BehaviorSubject<boolean>(false);
  private _removingVariants = new BehaviorSubject<boolean>(false);
  private _allowAddingProducts = new BehaviorSubject<boolean>(true);
  private _primarySortOption = new BehaviorSubject<SectionSortOption>(null);
  private _secondarySortOption = new BehaviorSubject<SectionSortOption>(null);

  // Internal RX Circuit - Outputs
  public readonly locationId$ = this.locationDomainModel.locationId$;
  public readonly companyId$ = this.companyDomainModel.companyId$;
  public readonly priceFormat$ = this.locationDomainModel.priceFormat$;
  public menu$: Observable<Menu> = this._menu.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable));
  public hideFilterByVisibility$ = this.menu$.pipe(map(m => m?.containsStackedContent() || false));
  public hideSalePricing$ = this.menu$.pipe(map(m => m?.menuOptions?.hideSale ?? false));
  public showWillNotAppearOnMenuSection$ = this._showWillNotAppearOnMenuSection.pipe(distinctUntilChanged());
  public menuOptions$ = this._menu.pipe(map(menu => menu?.menuOptions));
  public currentMenuTheme$ = this._menu.pipe(map(menu => menu?.hydratedTheme));
  public section$: Observable<HydratedSection> = this._section.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    shareReplay({bufferSize: 1, refCount: true})
  );
  public showZeroStockItems$ = this._showZeroStockItems.asObservable();
  public sectionHasProductIds$ = this.section$.pipe(
    map(section => !!section?.productIds && section?.productIds?.length > 0)
  );
  public sectionHasSmartFilters$ = this.section$.pipe(map(section => section?.hasSmartFilters()));
  public removingVariants$ = this._removingVariants as Observable<boolean>;
  public emptyProductSectionText$ = this.sectionHasSmartFilters$.pipe(
    map(hasSmartFilters => {
      if (hasSmartFilters) {
        return 'Products will appear in a list here. Smart filters will add and remove products for you.';
      } else {
        return 'Products will appear in a list here. You can add, edit, and remove products from here.';
      }
    })
  );
  public sectionProductIdsAreEmpty$ = this.section$.pipe(
    map(section => !section?.productIds || section?.productIds?.length === 0)
  );
  public sectionStyles$ = this._menu.pipe(map(menu => menu?.styling));
  public variantsAreGrouped$ = this.section$.pipe(map(section => section?.shouldGroupProductVariantsTogether()));
  public groupingModeInfoText$ = this.section$.pipe(
    map(section => {
      return section?.isShelfTalker()
        ? `This menu groups products based on their brand.
           Variants from the same brand will be grouped below, and appear on the menu in this order.`
        : `This menu uses Grid layout. Variants from the same parent product will be grouped below,
           because this is how they will appear on the menu.`;
    })
  );
  public allowAddingProducts$ = this._allowAddingProducts as Observable<boolean>;
  private primarySortOption$ = this._primarySortOption.asObservable().pipe(distinctUntilChanged());
  private secondarySortOption$ = this._secondarySortOption.asObservable().pipe(distinctUntilChanged());
  public sectionSortTypes$ = combineLatest([
    this.primarySortOption$,
    this.secondarySortOption$,
  ]).pipe(
    switchMap(([pSort, sSort]) => {
      return window.types.sectionSortOptions$.pipe(
        map((sortOptions) => {
          const primarySort = sortOptions?.find(option => option?.value === pSort);
          const secondarySort = sortOptions?.find(option => option?.value === sSort);
          return [primarySort, secondarySort]?.filterNulls();
        })
      );
    })
  );
  public sectionWithRealtimeSortingApplied$ = combineLatest([
    // All inputs to this pipe are distinct to avoid unnecessary re-evaluations
    this.section$.notNull(),
    this.primarySortOption$,
    this.secondarySortOption$
  ]).pipe(
    map(([section, primarySortOption, secondarySortOption]) => {
      const shallowCopy = window?.injector?.Deserialize?.shallowCopyOf(section);
      shallowCopy.sorting = primarySortOption;
      shallowCopy.secondarySorting = secondarySortOption;
      return shallowCopy;
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public sortedSectionVariants$ = this._sortedSectionVariants.notNull().pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({bufferSize: 1, refCount: true})
  );
  public sortedVisibleSectionGroupedVariants$ = this._sortedVisibleSectionGroupedVariants.asObservable();
  public sortedHiddenSectionGroupedVariants$ = this._sortedHiddenSectionGroupedVariants.asObservable();
  public sortedVisibleAndHiddenSectionGroupedVariants$ = combineLatest([
    this.sortedVisibleSectionGroupedVariants$,
    this.sortedHiddenSectionGroupedVariants$
  ]).pipe(
    map(([visible, hidden]) => {
      if (!visible && !hidden) return null;
      return [...(visible ?? []), ...(hidden ?? [])];
    })
  );
  public readonly sortedVariantIds$ = this.sortedVisibleAndHiddenSectionGroupedVariants$.pipe(
    map(groups => groups?.flatMap(group => group?.variants?.map(v => v?.id))?.unique(true))
  );
  public sortedSectionVariantsLessThanTwo$ = this.sortedSectionVariants$.pipe(map(v => v?.length < 2));
  public showWillNotAppearSection$ = combineLatest([
    this.showWillNotAppearOnMenuSection$,
    this.sortedHiddenSectionGroupedVariants$.pipe(map(willNotAppearGroups => willNotAppearGroups?.length > 0))
  ]).pipe(
    map(([show, hasWillNotAppearVariants]) => show && hasWillNotAppearVariants),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public sectionHasProductsThatCanBeRemoved$ = combineLatest([
    this.section$,
    this.allowAddingProducts$
  ]).pipe(
    map(([section, allowAddingProducts]) => {
      return allowAddingProducts && (section?.getNonTemplatedVariantIds()?.length > 0);
    })
  );

  private primarySortChanged$ = this.primarySortOption$.notNull().pipe(
    startWith(false),
    distinctUntilChanged(),
    pairwise(),
    map(([prev, curr]) => {
      return prev !== curr;
    })
  );
  private secondarySortChanged$ = this.secondarySortOption$.notNull().pipe(
    startWith(false),
    distinctUntilChanged(),
    pairwise(),
    map(([prev, curr]) => {
      return prev !== curr;
    })
  );
  private sectionResortSub = combineLatest([
    this.primarySortChanged$,
    this.secondarySortChanged$,
  ]).subscribeWhileAlive({
    owner: this,
    next: ([pSortChanged, sSortChanged]) => {
      if (pSortChanged || sSortChanged) {
        // Values will get recalculated by calculateSortedSectionGroupedVariants
        this._sortedVisibleSectionGroupedVariants.next([]);
        this._sortedHiddenSectionGroupedVariants.next([]);
      }
    }
  });

  public disableAddProductsButton$ = combineLatest([
    this.sectionHasSmartFilters$,
    this.removingVariants$,
    this.allowAddingProducts$
  ]).pipe(
    map(([sectionHasSmartFilters, removingVariants, allowAddingProducts]) => {
      return sectionHasSmartFilters || removingVariants || !allowAddingProducts;
    })
  );

  public showDisabledTooltip$ = this.allowAddingProducts$.pipe(map(allow => !allow));

  // Calculate section variants
  private calculateSortedSectionVariants = combineLatest([
    this.menu$,
    this.section$,
    this.priceFormat$
  ]).pipe(takeUntil(this.onDestroy)).subscribe(([menu, section, priceStream]) => {
    if (!!menu && !!section) {
      const enabledVariants: Variant[] = [];
      section?.products?.forEach((prod) => {
        prod.variants?.forEach((variant) => {
          if (section.enabledVariantIds.contains(variant.id)) {
            enabledVariants.push(variant);
          }
        });
      });
      if (enabledVariants.length > 0) {
        const sorted = SortUtils.sortVariantsBySectionSortOptions(enabledVariants, section);
        this._sortedSectionVariants.next(sorted);
      } else {
        this._sortedSectionVariants.next([]);
      }
    }
  });

  // Calculate variant groups circuit
  private calculateSortedSectionGroupedVariants = combineLatest([
    this.showWillNotAppearOnMenuSection$,
    combineLatest([
      this.sectionWithRealtimeSortingApplied$.notNull(),
      this.showZeroStockItems$,
      this.sortedSectionVariants$
    ]),
    this.menu$.notNull(),
    combineLatest([
      this.locationId$,
      this.companyId$,
      this.priceFormat$,
      this.labelDomainModel.systemLabels$,
    ])
  ]).pipe(
    debounceTime(250),
    takeUntil(this.onDestroy)
  ).subscribe(([
    notAppearSection,
    [sec, showZeroStock, variants],
    menu,
    [locationId, companyId, priceStream, systemLabels]
  ]) => {
    // Filter Variants by in stock logic determined by section
    type filterVariantTypes = [Section, Variant[], boolean, boolean];
    const filterVariantParams = [sec, variants, notAppearSection, showZeroStock] as filterVariantTypes;
    const [filteredVariants, willNotAppearVariants] = this.filterVariantsByInStock(...filterVariantParams);
    // Build variant groups based on parent products
    let maxVariantsPerCards: number = null;
    if (MenuTypeDefinition.containsStackedContent(menu?.type)) {
      const menuCardSize = menu?.metadata?.printCardSize;
      maxVariantsPerCards = menu?.hydratedTheme?.printConfig?.gridCountMap?.get(menuCardSize) ?? 1;
    }
    let [variantGroups, willNotAppearGroups] = this.buildVariantGroupings(
      menu,
      sec,
      priceStream,
      filteredVariants,
      willNotAppearVariants,
      maxVariantsPerCards
    );
    // Sorting - apply sort before truncating based on item count.
    const hideSale = menu?.menuOptions?.hideSale;
    SortUtils.prepareForSorting(variantGroups, locationId, companyId, priceStream, systemLabels, hideSale);
    const sortedGroups = SortUtils.variantGroupsBySortOptions(variantGroups, sec);
    const sortedHiddenGroups = SortUtils.variantGroupsBySortOptions(willNotAppearGroups, sec);
    // Filter Groups by item count (truncate anything over the max)
    let filteredVariantGroups: VariantGroup[];
    type filterGroupTypes = [Menu, HydratedSection, VariantGroup[], VariantGroup[], boolean];
    const filterGroupParams = [menu, sec, sortedGroups, sortedHiddenGroups, notAppearSection] as filterGroupTypes;
    [filteredVariantGroups, willNotAppearGroups] = this.filterGroupsByItemCount(...filterGroupParams);
    filteredVariantGroups?.forEach(group => group.includedOnMenu = true);
    willNotAppearGroups?.forEach(group => group.includedOnMenu = false);
    this._sortedVisibleSectionGroupedVariants.next(filteredVariantGroups);
    this._sortedHiddenSectionGroupedVariants.next(willNotAppearGroups);
  });

  public readonly removeAllProductButtonText$ = this.section$.pipe(
    map(section => {
      return section?.isTemplatedSection()
        ? 'Remove All Additional Products'
        : 'Remove All Products';
    })
  );

  filterByVisibilityOptions$ = of(
    ['Visible', 'Hidden'].map(option => {
      return new class implements Selectable {
        getSelectionTitle = (): string => option;
        getSelectionUniqueIdentifier = (): string => option;
        getSelectionValue = (): any => option;
        getSelectionIsDisabled = (): boolean => false;
      }();
    })
  );

  public nResultsPerPageOptions$ = of(PaginationUtils.constructResultsPerPageOptions([10, 25, 50, 100]));

  private _nResultsPerPage = new BehaviorSubject<number>(25);
  public nResultsPerPage$ = this._nResultsPerPage as Observable<number>;
  connectToNResultsPerPage = (n: number) => this._nResultsPerPage.next(n);

  private readonly _filterByVisibility = new BehaviorSubject<VisibilityOption>(null);
  public readonly filterByVisibility$ = this._filterByVisibility as Observable<VisibilityOption>;
  connectToFilterByVisibility = (option: VisibilityOption) => this._filterByVisibility.next(option);

  /**
   * Search through the products using fuse on a background thread.
   *
   * @returns the filtered products as a list of SearchedVariantGroup's.
   */
  private variantGroupSearcher(
    [variantGroupData, productOptions, variantOptions, searchText]: [any[], any[], any[], string]
  ): Observable<SearchedVariantGroup[]> {
    return new Observable(subscriber => {
      const myWorker = new Worker(
        new URL('./../../../../../../worker/variant-group-searcher.worker', import.meta.url),
        { type: 'module', name: 'variant-group-searcher' }
      );
      myWorker.onmessage = result => {
        subscriber.next(result?.data);
        subscriber.complete();
        myWorker.terminate();
      };
      myWorker.postMessage({ variantGroupData, productOptions, variantOptions, searchText });
    });
  }

  private getVariantGroupSearchPipeline(
    [variantGroups, range, pOpts, vOpts, searchText]: [VariantGroup[], boolean, any[], any[], string],
    [locId, compId, priceFormat, hideSalePricing]: [number, number, PriceFormat, boolean]
  ): Observable<VariantGroup[]> {
    return defer(() => {
      const searchableGrouping = variantGroups?.map(vg => {
        return SearchUtils.getSearchableVariantGroup(vg, range, locId, compId, priceFormat, hideSalePricing);
      });
      return this.variantGroupSearcher([searchableGrouping, pOpts, vOpts, searchText]).pipe(
        map((hits: SearchedVariantGroup[]) => {
          return hits
            ?.map(hit => {
              const preSearchedGroup = variantGroups.find(vg => {
                const sameProduct = vg.product?.id === hit?.productId;
                const sameVariants = hit?.variantIds?.every(hitVarId => vg?.variants?.some(v => v.id === hitVarId));
                return sameProduct && sameVariants;
              });
              return new VariantGroup(
                preSearchedGroup?.product,
                preSearchedGroup?.variants?.filter(v => hit?.variantIds?.includes(v.id)),
                preSearchedGroup?.includedOnMenu
              );
            })
            ?.filter(group => group?.variants?.length > 0);
        })
      );
    });
  }

  private _searchText = new BehaviorSubject<string>(null);
  public searchText$ = this._searchText as Observable<string>;
  connectToSearchText = (searchText: string) => this._searchText.next(searchText);

  /**
   * Product and variant search is too complicated to abstract away within the generic search capabilities
   * of the lib-reactive-search-bar. Therefore, we will use a custom search implementation, which will
   * search through the products and variants using fuse on a background thread. The search bar
   * will only be used for string capture, and this pipe will be used to perform the search.
   */
  public searchedGroupings$ = combineLatest([
    combineLatest([
      this.filterByVisibility$,
      this.sortedVisibleSectionGroupedVariants$,
      this.sortedHiddenSectionGroupedVariants$,
    ]),
    combineLatest([
      this.searchText$.pipe(map(s => s?.stripWhiteSpaceAndLowerCase()), distinctUntilChanged()),
      this.displaysUnitsInRanges$
    ]),
    combineLatest([
      this.displaysUnitsInRanges$.pipe(map(range => SearchUtils.getFuseOptionsForEditSectionProduct())),
      this.displaysUnitsInRanges$.pipe(map(range => SearchUtils.getFuseOptionsForEditSectionVariants(range)))
    ]),
    combineLatest([
      this.locationId$,
      this.companyId$,
      this.priceFormat$,
      this.hideSalePricing$
    ])
  ]).pipe(
    debounceTime(100),
    switchMap(([
      [visibilityFilter, visibleGroups, hiddenGroups],
      [text, range],
      [productOpts, variantOpts],
      [locId, compId, priceFormat, hideSalePricing]
    ]) => {
      const getSearchData = (): VariantGroup[] => {
        switch (visibilityFilter) {
          case 'Visible': return visibleGroups;
          case 'Hidden':  return hiddenGroups;
          default:        return [...(visibleGroups ?? []), ...(hiddenGroups ?? [])];
        }
      };
      const searchThrough = getSearchData();
      const search$ = defer(() => {
        return this.getVariantGroupSearchPipeline(
          [searchThrough, range, productOpts, variantOpts, text],
          [locId, compId, priceFormat, hideSalePricing]
        );
      });
      return iif(() => text?.length > 1, search$, of(searchThrough));
    }),
    startWith(null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public emptySearch$ = combineLatest([
    this.searchText$.pipe(map(searchText => searchText?.length > 1)),
    this.searchedGroupings$.pipe(map(searchedGroupings => searchedGroupings?.length === 0))
  ]).pipe(
    map(([hasSearchText, isEmpty]) => hasSearchText && isEmpty),
  );

  public readonly noVariantsWithThatVisibility$ = combineLatest([
    this.sortedVisibleAndHiddenSectionGroupedVariants$,
    this.filterByVisibility$,
    this.searchedGroupings$,
  ]).pipe(
    map(([groupings, visibilityFilter, searchedGroupings]) => {
      switch (true) {
        case !groupings?.length:             return false;
        case visibilityFilter === 'Hidden':
        case visibilityFilter === 'Visible': return searchedGroupings?.length === 0;
        default:                             return false;
      }
    })
  );

  public connectToMenu(menu: Menu) {
    this._menu.next(menu);
  }

  public connectToSection(section: HydratedSection) {
    this._section.next(section);
    this.connectToShowZeroStockItems(section?.showZeroStockItems);
    this.connectToPrimarySortOption(section?.sorting);
    this.connectToSecondarySortOption(section?.secondarySorting);
  }

  public connectToShowZeroStockItems(showZeroStockItems: boolean) {
     this._showZeroStockItems.next(showZeroStockItems);
  }

  public connectToPrimarySortOption(sort: SectionSortOption) {
     this._primarySortOption.next(sort);
  }

  public connectToSecondarySortOption(sort: SectionSortOption) {
     this._secondarySortOption.next(sort);
  }

  public connectToShowWillNotAppearOnMenuSection(showWillNotAppearOnMenuSection: boolean) {
     this._showWillNotAppearOnMenuSection.next(showWillNotAppearOnMenuSection);
  }

  public connectToRemovingVariants(removingVariants: boolean) {
     this._removingVariants.next(removingVariants);
  }

  public connectToAllowAddingProducts(allowAddingProducts: boolean) {
    this._allowAddingProducts.next(allowAddingProducts);
  }

  /* *******************************************************************/

  /**
   * @return [filteredVariants, willNotAppearVariants]
   */
  protected filterVariantsByInStock(
    section: Section,
    variants: Variant[],
    showWillNotAppearOnMenuSection: boolean,
    showZeroStockItems: boolean
  ): [Variant[], Variant[]] {
    // Variants
    let filteredVariants: Variant[] = variants;
    let willNotAppearVariants: Variant[] = [];
    if (showWillNotAppearOnMenuSection) {
      if (section?.shouldGroupProductVariantsTogether()) {
        if (!showZeroStockItems) {
          section?.productIds?.forEach(productId => {
            const productVariants = variants?.filter(v => v.productId === productId);
            if (productVariants?.every(v => !v.inStock())) {
              willNotAppearVariants.push(...productVariants);
            }
          });
          filteredVariants = variants?.filter(variant => !willNotAppearVariants.includes(variant));
        }
      } else { // Line item mode
        if (!showZeroStockItems) {
          filteredVariants = variants?.filter(variant => variant.inStock());
          willNotAppearVariants = variants?.filter(x => !filteredVariants.includes(x));
        }
      }
    }
    return [filteredVariants, willNotAppearVariants];
  }

  /**
   * @return [filteredVariantGroups, willNotAppearVariantGroups]
   */
  protected buildVariantGroupings(
    menu: Menu,
    section: HydratedSection,
    priceFormat: PriceFormat,
    filteredVars: Variant[],
    willNotAppearVars: Variant[],
    maxVariantsPerCard?: number
  ): [VariantGroup[], VariantGroup[]] {
    switch (true) {
      case section?.shouldGroupProductVariantsTogether(): {
        switch (section?.sectionType) {
          case SectionType.ShelfTalker:
            return this.getShelfTalkerBrandGroupings(filteredVars, willNotAppearVars);
          case SectionType.CardStack:
            return this.getMultiVariantCardStackGroupings(
              menu,
              section,
              priceFormat,
              filteredVars,
              willNotAppearVars,
              maxVariantsPerCard
            );
          default:
            return this.getGridMenuGroupings(section, filteredVars, willNotAppearVars);
        }
      }
    }
    return this.getSingleVariantGroupings(section, filteredVars, willNotAppearVars);
  }

  /**
   * If the card stack is in grid mode:
   * - account for checked off grid columns by filtering out variants that are not part of the grid
   * - spread large grids across multiple cards using PrintCardMenuUtils.spreadLargePrintCardGridAcrossMultipleCards
   * If the card stack is in child variant list mode:
   * - do not spread the variants across multiple cards
   *
   * @return [filteredVariantGroups, willNotAppearVariantGroups]
   */
  protected getMultiVariantCardStackGroupings(
    menu: Menu,
    section: HydratedSection,
    priceFormat: PriceFormat,
    filteredVariants: Variant[],
    willNotAppearVariants: Variant[],
    maxVariantsPerCard?: number
  ): [VariantGroup[], VariantGroup[]] {
    const gridColumns = section?.getActiveColumnsAsGridColumnComparisonStrings();
    const addToWillNotAppear = section?.isGridMode()
      ? filteredVariants?.filter(v => !gridColumns?.includes(v?.getGridNameAsColumnComparisonString())) || []
      : [];
    const filtered = section?.isGridMode()
      ? filteredVariants?.filter(v => gridColumns?.includes(v?.getGridNameAsColumnComparisonString())) || []
      : filteredVariants;
    const willNotAppear = [...willNotAppearVariants, ...addToWillNotAppear];
    const filteredProductIds = filtered?.map(v => v.productId).unique();
    const willNotAppearProductIds = willNotAppear?.map(v => v.productId).unique();
    const filteredVariantChunks: Variant[][] = [];
    const spreadByPrice = PrintCardMenuUtils.spreadByPrice;
    const spreadAcrossMultipleCards = PrintCardMenuUtils.spreadLargePrintCardGridAcrossMultipleCards;
    const theme = menu?.theme;
    const companyId = menu?.companyId;
    const locationId = menu?.locationId;
    const hideSale = menu?.menuOptions?.hideSale;
    filteredProductIds?.forEach(pId => {
      const product = section?.products?.find(p => p?.id === pId);
      const variantsToChunk = product?.variants?.filter(v => {
        return exists(filtered?.find(variant => variant?.id === v?.id));
      });
      if (section?.isChildVariantList()) {
        if (menu?.theme === PrintCardTheme.FikaEdibles) {
          const chunkedForProduct = spreadByPrice(variantsToChunk, theme, locationId, companyId, priceFormat, hideSale);
          filteredVariantChunks.push(...chunkedForProduct);
        } else {
          filteredVariantChunks.push(variantsToChunk);
        }
      } else if (maxVariantsPerCard > 1) { // prevents error when duplicating grid cards to a smaller non-grid card
        const chunkedVariantsForProduct = spreadAcrossMultipleCards(variantsToChunk, maxVariantsPerCard);
        filteredVariantChunks.push(...chunkedVariantsForProduct);
      }
    });
    const filteredGroups = filteredVariantChunks?.map(variantChunk => {
      const productId = variantChunk?.firstOrNull()?.productId;
      const product = section?.products?.find(p => p?.id === productId);
      return new VariantGroup(product, variantChunk, true);
    });
    const willNotAppearChunkedVariants: Variant[][]  = [];
    willNotAppearProductIds?.forEach(pId => {
      const product = section?.products?.find(p => p?.id === pId);
      const variantsToChunk = product?.variants?.filter(v => {
        return exists(willNotAppear?.find(variant => variant?.id === v?.id));
      });
      if (section?.isChildVariantList()) {
        if (menu?.theme === PrintCardTheme.FikaEdibles) {
          const chunkedForProduct = spreadByPrice(variantsToChunk, theme, locationId, companyId, priceFormat, hideSale);
          willNotAppearChunkedVariants.push(...chunkedForProduct);
        } else {
          willNotAppearChunkedVariants.push(variantsToChunk);
        }
      } else if (maxVariantsPerCard > 1) { // prevents error when duplicating grid cards to a smaller non-grid card
        const chunkedVariantsForProduct = spreadAcrossMultipleCards(variantsToChunk, maxVariantsPerCard);
        willNotAppearChunkedVariants.push(...chunkedVariantsForProduct);
      }
    });
    const willNotAppearGroups = willNotAppearChunkedVariants.map(variantChunk => {
      const productId = variantChunk?.firstOrNull()?.productId;
      const product = section?.products?.find(p => p?.id === productId);
      return new VariantGroup(product, variantChunk, false);
    });
    return [filteredGroups, willNotAppearGroups];
  }

  /**
   * @return [filteredVariantGroups, willNotAppearVariantGroups]
   */
  protected getGridMenuGroupings(
    section: HydratedSection,
    filteredVariants: Variant[],
    willNotAppearVariants: Variant[]
  ): [VariantGroup[], VariantGroup[]] {
    const filteredProductIds = filteredVariants?.map(v => v.productId).unique();
    const willNotAppearProductIds = willNotAppearVariants?.map(v => v.productId).unique();
    const filteredGroups = filteredProductIds?.map(productId => {
      const product = section?.products?.find(p => p.id === productId);
      const variantGroup = filteredVariants?.filter(v => v.productId === productId) ?? [];
      return new VariantGroup(product, variantGroup, true);
    }) ?? [];
    const willNotAppearGroups = willNotAppearProductIds?.map(productId => {
      const product = section?.products?.find(p => p.id === productId);
      const variantGroup = willNotAppearVariants?.filter(v => v.productId === productId) ?? [];
      return new VariantGroup(product, variantGroup, false);
    }) ?? [];
    return [filteredGroups, willNotAppearGroups];
  }

  protected getShelfTalkerBrandGroupings(
    filteredVariants: Variant[],
    willNotAppearVariants: Variant[]
  ): [VariantGroup[], VariantGroup[]] {
    const brandForComparison = (brand: string) => brand?.toLowerCase()?.trim();
    const filteredProductBrands = filteredVariants?.map(v => brandForComparison(v?.brand))?.unique();
    const variantsWithoutABrand = filteredVariants?.filter(v => !brandForComparison(v?.brand));
    const willNotAppearProductBrands = willNotAppearVariants?.map(v => brandForComparison(v?.brand))?.unique();
    const filteredGroups = filteredProductBrands?.map(comparatorBrand => {
      const variantsInGroup = filteredVariants?.filter(v => brandForComparison(v?.brand) === comparatorBrand) ?? [];
      const product = new ProductThatRepresentsVariantsGroupedByBrand(variantsInGroup);
      return new VariantGroup(product, variantsInGroup, true);
    }) ?? [];
    const willNotAppearGroups = willNotAppearProductBrands?.map(comparatorBrand => {
      const variantGroup = willNotAppearVariants?.filter(v => brandForComparison(v?.brand) === comparatorBrand) ?? [];
      const product = new ProductThatRepresentsVariantsGroupedByBrand(variantGroup);
      return new VariantGroup(product, variantGroup, false);
    }) ?? [];
    // these were supposed to be visible, but they don't have a brand, so put them with the will not appear groups
    if (variantsWithoutABrand?.length > 0) {
      const product = new ProductThatRepresentsVariantsGroupedByBrand(variantsWithoutABrand);
      willNotAppearGroups.push(new VariantGroup(product, variantsWithoutABrand, false));
    }
    return [filteredGroups, willNotAppearGroups];
  }

  /**
   * @return [filteredVariantGroups, willNotAppearVariantGroups]
   */
  protected getSingleVariantGroupings(
    section: HydratedSection,
    filteredVariants: Variant[],
    willNotAppearVariants: Variant[]
  ): [VariantGroup[], VariantGroup[]] {
    const filteredGroups = filteredVariants?.map(v => {
      const product = section?.products?.find(p => p.id === v.productId);
      return new VariantGroup(product, [v], true);
    }) ?? [];
    const willNotAppearGroups = willNotAppearVariants?.map(v => {
      const product = section?.products?.find(p => p.id === v.productId);
      return new VariantGroup(product, [v], false);
    });
    return [filteredGroups, willNotAppearGroups];
  }

  /**
   * @return [filteredVariantGroups, willNotAppearVariantGroups]
   */
  protected filterGroupsByItemCount(
    menu: Menu,
    section: Section,
    variantGroups: VariantGroup[],
    willNotAppearGroups: VariantGroup[],
    showWillNotAppearOnMenuSection: boolean
  ): [VariantGroup[], VariantGroup[]] {
    let filteredVariantGroups: VariantGroup[] = variantGroups;
    let willNotAppearVariantGroups: VariantGroup[] = willNotAppearGroups;
    if (showWillNotAppearOnMenuSection) {
      const takeNGroups = section?.rowCount ?? ThemeUtils.getMaxNumberOfProductsOnShelfTalkerCard(menu) ?? 0;
      const isSectionLevelOverflow = menu?.isProductMenuWithSectionLevelOverflow();
      const hasSpecificGroupSize = takeNGroups > 0;
      const maxGroups = menu?.hydratedTheme?.themeFeatures?.sectionProductMaxCount ?? 0;
      const hasMaxGroups = maxGroups > 0;
      const shouldTakeNItems = !isSectionLevelOverflow && hasSpecificGroupSize;
      if (shouldTakeNItems || hasMaxGroups) {
        if (hasSpecificGroupSize) {
          filteredVariantGroups = filteredVariantGroups.take(takeNGroups);
        }
        if (hasMaxGroups) {
          filteredVariantGroups = filteredVariantGroups.take(maxGroups);
        }
        const difference = variantGroups.filter(x => !filteredVariantGroups.includes(x));
        willNotAppearVariantGroups = [...difference, ...willNotAppearGroups];
      }
    }
    return [filteredVariantGroups, willNotAppearVariantGroups];
  }

}
