<app-shelf-talker-details-section [templateMode]="templateMode" [menu]="viewModel.menu$ | push">
  <lib-reactive-form-group [mergeKey]="mergeKey" [bindTo]="viewModel.menu$ | async">
    <lib-reactive-form-column-layout [nColumns]="2" [reversed]="false">
      <lib-reactive-form-text
        [inputName]="'menuName'"
        [label]="'Menu Name (Internal)' | replaceMenuWithTemplate : templateMode"
        [placeholder]="'Enter an internal name for the menu.' | replaceMenuWithTemplate : templateMode"
        [inlineLabel]="false"
        [required]="true"
        [minLength]="1"
        [maxLength]="128"
        [bindingProperty]="'name'">
      </lib-reactive-form-text>
      <lib-reactive-form-drop-down
        [inputName]="'menuType'"
        [label]="'Menu Type'"
        [placeholder]="'Choose menu type'"
        [inlineLabel]="false"
        [disabled]="true"
        [required]="true"
        [dropdowns]="viewModel.menuTypes$ | async"
        [bindingProperty]="'type'">
      </lib-reactive-form-drop-down>
    </lib-reactive-form-column-layout>
  </lib-reactive-form-group>
  <app-edit-shelf-talker-brands-menu-additional-options
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | push">
  </app-edit-shelf-talker-brands-menu-additional-options>
</app-shelf-talker-details-section>
<app-edit-shelf-talker-brands-menu-print-section [templateMode]="templateMode" [unsavedChanges]="unsavedChanges">
</app-edit-shelf-talker-brands-menu-print-section>
<app-edit-product-menu-sections
  [templateMode]="templateMode"
  [formHasErrors]="formHasErrors"
  [formPristine]="formPristine"
  [formAutoSubmitted]="formAutoSubmitted"
  (submitForms)="submitForms.emit($event)">
  Display Sections Added To Product Menu
</app-edit-product-menu-sections>
