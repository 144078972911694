import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { PendingDisplay } from '../../../../../models/template/dto/pending-display';
import { TemplateCollection } from '../../../../../models/template/dto/template-collection';
import { map } from 'rxjs/operators';

export enum RequiredDisplaysEditStep {
  ChooseEditMethod = 0,
  ApplyToExisting = 1,
  NewDisplayDetails = 2,
  SelectLocations = 3,
}

export enum RequiredDisplayEditMethod {
  Existing = 1,
  New = 2
}

@Injectable()
export class CollectionDisplaysModalContainerViewModel extends BaseViewModel {

  public get requiredDisplaysStep(): typeof RequiredDisplaysEditStep {
    return RequiredDisplaysEditStep;
  }

  public displaySizeOptions$ = window?.types?.digitalSizeTypes$;
  public orientationOptions$ = window?.types?.orientations$;

  private _collection = new BehaviorSubject<TemplateCollection>(null);
  public collection$ = this._collection as Observable<TemplateCollection>;
  public connectToCollection = (c: TemplateCollection) => this._collection.next(c);

  private _pendingDisplay = new BehaviorSubject<PendingDisplay>(new PendingDisplay());
  public pendingDisplay$ = this._pendingDisplay as Observable<PendingDisplay>;
  public connectToPendingDisplay = (pd: PendingDisplay) => {
    // Pending displays will have orientation and displayName set, but they won't have height, width, and unit set.
    // This messes with distinctUntilChanged, because width = 0 and height = 0 is the first state (which is wrong),
    // and the unit property isn't even added to the underlying data. The following method fixes this.
    pd?.addAdditionalInfoNeededForDistinctUntilChanged();
    this._pendingDisplay.next(pd);
  };

  private _requiredDisplayIds = new BehaviorSubject<string[]>(null);
  public requiredDisplayIds$ = this._requiredDisplayIds as Observable<string[]>;
  public connectToRequiredDisplayIds = (ids: string[]) => this._requiredDisplayIds.next(ids);

  private _editStep = new BehaviorSubject<number>(0);
  public editStep$ = this._editStep as Observable<number>;
  public connectToEditStep = (n: number) => this._editStep.next(n);

  private _startingAtStepZero = new BehaviorSubject<boolean>(true);
  public startingAtStepZero$ = this._startingAtStepZero as Observable<boolean>;
  public connectToStartingAtStepZero = (start: boolean) => this._startingAtStepZero.next(start);

  public displaySize$ = combineLatest([this.collection$, this.pendingDisplay$]).pipe(
    map(([c, pendingDisplay]) => pendingDisplay?.displaySize?.name || c?.displaySize?.name)
  );
  public orientation$ = combineLatest([this.collection$, this.pendingDisplay$]).pipe(
    map(([c, pendingDisplay]) => pendingDisplay?.displaySize?.orientation || c?.displaySize?.orientation)
  );

  public goBack(): void {
    this.editStep$.once(step => {
      step === RequiredDisplaysEditStep.NewDisplayDetails
        ? this._editStep.next(RequiredDisplaysEditStep.ChooseEditMethod)
        : this._editStep.next(--step);
    });
  }

  public incrementEditStep = () => this.editStep$.once((step) => this._editStep.next(++step));

}
