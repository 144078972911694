import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { Injectable, Injector, NgZone } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { BsError } from '../../../../../../models/shared/bs-error';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { distinctUntilChanged, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../../utils/distinct-utils';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { HydratedSmartFilter } from '../../../../../../models/automation/hydrated-smart-filter';
import { AddEditSmartFilterModalOpenedFrom } from '../../../../../../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { SyncSmartFilterService } from '../../../../../../services/smart-filter-sync.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSelectSmartFilter } from '../../../../../../modals/modal-select-smart-filter';
import { ModalCreateSmartFilter } from '../../../../../../modals/modal-create-smart-filter';
import { SectionTemplate } from '../../../../../../models/template/dto/section-template';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import type { SectionLayoutType } from '../../../../../../models/utils/dto/section-layout-type';

@Injectable()
export class MenuSectionSmartFiltersViewModel extends BaseViewModel {

  constructor(
    protected menuDomainModel: MenuDomainModel,
    protected templateDomainModel: TemplateDomainModel,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected toastService: ToastService,
    private syncSmartFilterService: SyncSmartFilterService
  ) {
    super();
  }

  protected override _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.defaultWhiteBackground());

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu.asObservable();

  private _section = new BehaviorSubject<HydratedSection>(null);
  public section$ = this._section.asObservable();

  public hasSmartFilters$ = this.section$.pipe(map(section => section?.hasSmartFilters()));
  public addedSmartFilterIds$ = this.section$.pipe(
    map(section => section?.smartFilterIds),
    distinctUntilChanged(DistinctUtils.distinctSortedStrings),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private isTemplatedSection = new BehaviorSubject<boolean>(false);
  public isTemplatedSection$ = this.isTemplatedSection as Observable<boolean>;

  public isPrintReportMenu$ = this.menu$.pipe(map(menu => menu?.isPrintReportMenu()));

  private _autoUpdateGridColumns = new BehaviorSubject<boolean>(false);
  public autoUpdateGridColumns$ = this._autoUpdateGridColumns as Observable<boolean>;
  connectToAutoUpdateGridColumns = (autoUpdateGridColumns: boolean) => {
    this._autoUpdateGridColumns.next(autoUpdateGridColumns);
  };

  private _selectedSectionLayoutType = new BehaviorSubject<SectionLayoutType>(null);
  public selectedSectionLayoutType$ = this._selectedSectionLayoutType as Observable<SectionLayoutType>;
  connectToSelectedSectionLayoutType = (sectionLayoutType: SectionLayoutType) => {
    this._selectedSectionLayoutType.next(sectionLayoutType);
  };

  public isLayoutGridMode$ = this.selectedSectionLayoutType$.pipe(
    map(sectionLayoutType => sectionLayoutType?.lineItemHasMultipleColumnsForSizeOrPrice())
  );

  connectToMenu(menu: Menu) {
    this._menu.next(menu);
  }

  connectToSection(section: HydratedSection) {
    this._section.next(section);
  }

  connectToIsTemplatedSection(isTemplatedSection: boolean) {
    this.isTemplatedSection.next(isTemplatedSection);
  }

  syncSmartFilters() {
    combineLatest([this.menu$, this.section$]).pipe(
      take(1),
      switchMap(([menu, sectionBeforeSync]) => {
        return this.syncSmartFilterService.syncSectionSmartFilters(menu, sectionBeforeSync).pipe(
          tap(sectionAfterSync => {
            if (!!sectionAfterSync) {
              this.toastService.publishBannerSuccess(sectionBeforeSync.whatChangedString(sectionAfterSync));
            }
          })
        );
      })
    ).subscribe(_ => {
    }, (error: BsError) => {
      this.toastService.publishBannerFailed(error?.message);
      throwError(error);
    });
  }

  addSmartFilters() {
    combineLatest([this.addedSmartFilterIds$, this.section$]).once(([previouslyAddedIds, section]) => {
      const forTemplateSection = section instanceof SectionTemplate;
      ModalSelectSmartFilter.open(
        this.ngZone,
        this.ngbModal,
        this.injector,
        previouslyAddedIds,
        this.addSmartFilterHelper.bind(this),
        this.createSmartFilter.bind(this),
        null,
        forTemplateSection
      );
    });
  }

  private addSmartFilterHelper(ids: string[]) {
    if (ids?.length > 0) {
      const lm = 'Updating Smart Filters';
      this._loadingOpts.addRequest(lm);
      combineLatest([
        this.menu$.notNull(),
        this.section$.notNull(),
        this.menuDomainModel.currentLocationMenus$,
      ]).pipe(
        take(1),
        switchMap(([menu, section, locationMenus]) => {
          const sectionCopy = window?.injector?.Deserialize?.instanceOf(HydratedSection, section);
          sectionCopy?.addSmartFilters(ids);
          if (sectionCopy instanceof SectionTemplate) {
            return this.templateDomainModel.updateMenuSectionTemplate(sectionCopy).pipe(
              tap((ts) => {
                this.menuDomainModel.getTemplatedMenuSortedVariantIdsAfterDelay(menu, locationMenus, 5000);
              }),
              map((updated) => [sectionCopy, updated])
            );
          }
          return this.menuDomainModel.updateMenuSection(sectionCopy).pipe(
            switchMap(updateSection => this.menuDomainModel.syncSectionSmartFilters(menu, updateSection)),
            map(updated => [sectionCopy, updated])
          );
        }),
      ).subscribe({
        next: ([old, updated]) => {
          this._loadingOpts.removeRequest(lm);
          updated instanceof SectionTemplate
            ? this.toastService.publishSuccessMessage('Template successfully updated', 'Update Template')
            : this.toastService.publishBannerSuccess(old.whatChangedString(updated));
        },
        error: (error: BsError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishBannerFailed(error?.message);
          throwError(() => error);
        }
      });
    }
  }

  createSmartFilter() {
    this.section$.once((s) => {
      const openedFrom = s instanceof SectionTemplate
        ? AddEditSmartFilterModalOpenedFrom.TemplateSectionCreate
        : AddEditSmartFilterModalOpenedFrom.SectionCreate;
      ModalCreateSmartFilter.open(
        this.ngZone,
        this.ngbModal,
        this.injector,
        openedFrom,
        this.createSmartFilterHelper.bind(this)
      );
    });
  }

  createSmartFilterHelper(smartFilter?: HydratedSmartFilter) {
    const smartFilterId = smartFilter?.id;
    if (!!smartFilterId) this.addSmartFilterHelper([smartFilterId]);
  }

}
