import { Variant } from '../dto/variant';
import { PrioritySortableVariantProperties } from '../../protocols/priority-sortable-variant-properties';

export class PrioritySortableVariant implements PrioritySortableVariantProperties {

  public priority: number;
  public variant: Variant;

  constructor(p: number, v: Variant) {
    this.priority = p;
    this.variant = v;
  }

}
