import { PrimaryCannabinoid } from '../../enum/shared/primary-cannabinoid.enum';
import { StringUtils } from '../../../utils/string-utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Deserializable } from '../../protocols/deserializable';
import { SecondaryCannabinoid } from '../../enum/dto/secondary-cannabinoid';
import { EnumUtils } from '../../../utils/enum-utils';

export class ProductTableColumnConfig implements Deserializable, UniquelyIdentifiable {

  public name: boolean;
  public brand:  boolean;
  public productType:  boolean;
  public strainType:  boolean;
  public quantity:  boolean;
  public price:  boolean;
  public secondaryPrice:  boolean;
  public label:  boolean;
  public badge:  boolean;
  public THC:  boolean;
  public CBD:  boolean;
  public CBDA:  boolean;
  public CBG:  boolean;
  public CBGA:  boolean;
  public CBL:  boolean;
  public CBLA:  boolean;
  public CBN:  boolean;
  public CBNA:  boolean;
  public CBT:  boolean;
  public CBC:  boolean;
  public CBCA:  boolean;
  public CBCV:  boolean;
  public CBDV:  boolean;
  public THC8:  boolean;
  public THC9:  boolean;
  public THCA:  boolean;
  public THCV:  boolean;
  public TAC:  boolean;
  public totalTerpene:  boolean;
  public topTerpene:  boolean;
  public alphaBisabolol: boolean;
  public alphaCaryophyllene: boolean;
  public alphaCedrene: boolean;
  public alphaHumulene: boolean;
  public alphaMyrcene: boolean;
  public alphaPinene: boolean;
  public alphaPhellandrene: boolean;
  public alphaSantalene: boolean;
  public alphaTerpinene: boolean;
  public alphaTerpineol: boolean;
  public betaCaryophyllene: boolean;
  public betaEudesmol: boolean;
  public betaMyrcene: boolean;
  public betaOcimene: boolean;
  public betaPinene: boolean;
  public bergamotene: boolean;
  public bisabolol: boolean;
  public borneol: boolean;
  public cadinene: boolean;
  public camphene: boolean;
  public camphor: boolean;
  public carene: boolean;
  public caryophylleneOxide: boolean;
  public carvacrol: boolean;
  public carvone: boolean;
  public caryophyllene: boolean;
  public cedrene: boolean;
  public cedrol: boolean;
  public cisNerolidol: boolean;
  public cisOcimene: boolean;
  public citral: boolean;
  public citronellol: boolean;
  public cymene: boolean;
  public cymenene: boolean;
  public delta3Carene: boolean;
  public deltaLimonene: boolean;
  public endoFenchyl: boolean;
  public eucalyptol: boolean;
  public eudesmols: boolean;
  public eugenol: boolean;
  public farnesene: boolean;
  public farnesol: boolean;
  public fenchol: boolean;
  public fenchone: boolean;
  public fenchyl: boolean;
  public geraniol: boolean;
  public geranylAcetate: boolean;
  public germacrene: boolean;
  public guaiol: boolean;
  public humulene: boolean;
  public isopulegol: boolean;
  public linalool: boolean;
  public limonene: boolean;
  public myrcene: boolean;
  public nerol: boolean;
  public nerolidol: boolean;
  public ocimene: boolean;
  public paraCymenene: boolean;
  public phellandrene: boolean;
  public phytol: boolean;
  public pinene: boolean;
  public pulegone: boolean;
  public sabinene: boolean;
  public santalene: boolean;
  public threeCarene: boolean;
  public terpinene: boolean;
  public terpineol: boolean;
  public terpinolene: boolean;
  public transNerolidol: boolean;
  public transCaryophyllene: boolean;
  public valencene: boolean;

  constructor() {
    this.initializeFields();
  }

  static formatColumnName(key: string): string {
    const upperKey = key.toUpperCase();
    const secondaryCannabinoids = EnumUtils.getSecondaryCannabinoids().includes(upperKey as SecondaryCannabinoid);
    const cannabinoid = EnumUtils.getPrimaryCannabinoids().includes(upperKey as PrimaryCannabinoid);
    return secondaryCannabinoids || cannabinoid
      ? upperKey
      : StringUtils.splitPascalCasePreserveAcronyms(StringUtils.toPascalCase(key));
  }

  onDeserialize() {
    this.initializeFields();
  }

  getUniqueIdentifier(): string {
    return JSON.stringify(this);
  }

  protected initializeFields(): void {
    this.name = this.name ?? false;
    this.brand = this.brand ?? false;
    this.productType = this.productType ?? false;
    this.strainType = this.strainType ?? false;
    this.quantity = this.quantity ?? false;
    this.price = this.price ?? false;
    this.secondaryPrice = this.secondaryPrice ?? false;
    this.label = this.label ?? false;
    this.badge = this.badge ?? false;
    EnumUtils.getPrimaryCannabinoids()?.forEach(cannabinoid => {
      const accessor = cannabinoid.toLowerCase();
      this[accessor] = this[accessor] ?? false;
    });
    EnumUtils.getSecondaryCannabinoids()?.forEach(cannabinoid => {
      const accessor = cannabinoid.toLowerCase();
      this[accessor] = this[accessor] ?? false;
    });
    this.topTerpene = this.topTerpene ?? false;
    this.totalTerpene = this.totalTerpene ?? false;
    EnumUtils.getTerpenes()?.forEach(terpene => {
      const accessor = StringUtils.toCamelCase(terpene);
      this[accessor] = this[accessor] ?? false;
    });
  }

}
