import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditTemplateMenuSectionComponent } from '../../../../edit-template-menu-section/edit-template-menu-section.component';
import { EditTemplateShelfTalkerBrandsSectionViewModel } from './edit-template-shelf-talker-brands-section-view-model';
import { EditShelfTalkerBrandsSectionViewModel } from '../../../../../../menu/components/edit-menu/edit-shelf-talker-menu/edit-shelf-talker-brands-menu/edit-shelf-talker-brands-section/edit-shelf-talker-brands-section-view-model';
import { EditTemplateShelfTalkerBrandsSectionCombinedLoadingOptsViewModel } from './edit-template-shelf-talker-brands-section-combined-loading-opts-view-model';
import { EditMenuSectionViewModel } from '../../../../../../menu/viewModels/edit-menu-section-view-model';

@Component({
  selector: 'app-edit-template-shelf-talker-brands-section',
  templateUrl: './edit-template-shelf-talker-brands-section.component.html',
  styleUrls: [
    './edit-template-shelf-talker-brands-section.component.scss',
    '../../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [
    EditTemplateShelfTalkerBrandsSectionViewModel,
    EditShelfTalkerBrandsSectionViewModel,
    EditTemplateShelfTalkerBrandsSectionCombinedLoadingOptsViewModel,
    {
      provide: EditMenuSectionViewModel,
      useExisting: EditShelfTalkerBrandsSectionViewModel
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateShelfTalkerBrandsSectionComponent extends EditTemplateMenuSectionComponent {

  constructor(
    public override viewModel: EditTemplateShelfTalkerBrandsSectionViewModel,
    public override combinedLoadingOptsViewModel: EditTemplateShelfTalkerBrandsSectionCombinedLoadingOptsViewModel
  ) {
    super(viewModel, combinedLoadingOptsViewModel);
  }

}
