import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, iif, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { exists } from '../../../../../../../../functions/exists';
import { ProviderUtils } from '../../../../../../../../utils/provider-utils';
import { CannabinoidUtils } from '../../../../../../../../utils/cannabinoid-utils';
import { EditVariantCannabinoidsViewModel } from '../edit-variant-cannabinoids-view-model';
import { PrimaryCannabinoid } from '../../../../../../../../models/enum/shared/primary-cannabinoid.enum';
import { TACProperty } from '../../../../../../../../models/enum/shared/tac-property-enum';

@Injectable()
export class CannabinoidsFormViewModel extends BaseViewModel {

  constructor(
    private editCannabinoidViewModel: EditVariantCannabinoidsViewModel,
  ) {
    super();
  }

  public variant$ = this.editCannabinoidViewModel.container.variant$;
  public companyDA$ = this.editCannabinoidViewModel.container.variantCompanyDisplayAttribute$;
  public updatedCompanyDA$ = this.editCannabinoidViewModel.updatedCompanyDA$;
  public locationDA$ = this.editCannabinoidViewModel.container.variantLocationDisplayAttribute$;
  public updatedLocationDA$ = this.editCannabinoidViewModel.updatedLocationDA$;
  public useRange$ = this.editCannabinoidViewModel.container.useCannabinoidRange$;
  public disableTACCalculation$ = this.editCannabinoidViewModel.disableTACCalculation$;
  public selectedCUOM$ = this.editCannabinoidViewModel.selectedCUOM$;
  public enabledSecondaryCannabinoidNames$ = this.editCannabinoidViewModel.enabledSecondaryCannabinoidNames$;
  public isCompanyAdmin$ = this.editCannabinoidViewModel.container.isCompanyAdmin$;
  public syncPOSCannabinoid$ = this.editCannabinoidViewModel.container.syncCannabinoidsFromPOS$;
  public inventoryProvider$ = this.editCannabinoidViewModel.container.inventoryProvider$;
  public companyName$ = this.editCannabinoidViewModel.container.companyName$;
  public locationName$ = this.editCannabinoidViewModel.container.currentLocationName$;
  public posSupportedCannabinoids$ = this.editCannabinoidViewModel.posSupportedSecondaryCannabinoids$.pipe(
    map(posSupportedSecondaryCannabinoids => [
      PrimaryCannabinoid.THC,
      PrimaryCannabinoid.CBD,
      ...(posSupportedSecondaryCannabinoids || [])
    ])
  );

  private _cannabinoid = new BehaviorSubject<string>('');
  public cannabinoid$ = this._cannabinoid as Observable<string>;
  connectToCannabinoid = (cannabinoid: string) => this._cannabinoid.next(cannabinoid);

  public disableCannabinoidInputs$ = combineLatest([
    this.editCannabinoidViewModel.disableCannabinoidInputs$,
    this.cannabinoid$,
    this.posSupportedCannabinoids$,
  ]).pipe(
    map(([disableCannabinoidInputs, cannabinoid, posSupportedCannabinoids]) => {
      return disableCannabinoidInputs && posSupportedCannabinoids?.includes(cannabinoid);
    })
  );

  public disableCannabinoidOrTAC$: Observable<boolean> = this.cannabinoid$.pipe(
    map((cannabinoid) => Object.values(TACProperty).includes(cannabinoid as TACProperty)),
    switchMap((isTACCannabinoid) => {
      return iif(() => isTACCannabinoid, this.disableTACCalculation$, this.disableCannabinoidInputs$);
    })
  );

  public companyTACPlaceholder$: Observable<string|null> = combineLatest([
    this.variant$,
    this.updatedCompanyDA$,
    this.enabledSecondaryCannabinoidNames$,
    this.disableTACCalculation$,
    this.selectedCUOM$
  ]).pipe(
    map(([variant, companyDA, enabledSecondaryCannabinoid, disableTACCalculation, selectedCUOM]) => {
      if (!disableTACCalculation && selectedCUOM) {
        return variant
          ?.getAutoCalculatedTAC(enabledSecondaryCannabinoid, null, [companyDA])
          ?.toString();
      }
      return null;
    })
  );

  public companyMinTACPlaceholder$: Observable<string|null> = combineLatest([
    this.variant$,
    this.updatedCompanyDA$,
    this.enabledSecondaryCannabinoidNames$,
    this.disableTACCalculation$,
    this.selectedCUOM$
  ]).pipe(
    map(([variant, companyDA, enabledSecondaryCannabinoid, disableTACCalculation, selectedCUOM]) => {
      if (!disableTACCalculation && selectedCUOM) {
        return variant
          ?.getAutoCalculatedTAC(enabledSecondaryCannabinoid, 'min', [companyDA])
          ?.toString();
      }
      return null;
    })
  );

  public companyMaxTACPlaceholder$: Observable<string|null> = combineLatest([
    this.variant$,
    this.updatedCompanyDA$,
    this.enabledSecondaryCannabinoidNames$,
    this.disableTACCalculation$,
    this.selectedCUOM$
  ]).pipe(
    map(([variant, companyDA, enabledSecondaryCannabinoid, disableTACCalculation, selectedCUOM]) => {
      if (!disableTACCalculation && selectedCUOM) {
        return variant
          ?.getAutoCalculatedTAC(enabledSecondaryCannabinoid, 'max', [companyDA])
          ?.toString();
      }
      return null;
    })
  );

  public locationTACPlaceholder$: Observable<string|null> = combineLatest([
    this.variant$,
    this.updatedLocationDA$,
    this.enabledSecondaryCannabinoidNames$,
    this.disableTACCalculation$,
    this.selectedCUOM$
  ]).pipe(
    map(([variant, locationDA, enabledSecondaryCannabinoid, disableTACCalculation, selectedCUOM]) => {
      if (!disableTACCalculation && selectedCUOM) {
        return variant
          ?.getAutoCalculatedTAC(enabledSecondaryCannabinoid, null, [locationDA])
          ?.toString();
      }
      return null;
    })
  );

  public locationMinTACPlaceholder$: Observable<string|null> = combineLatest([
    this.variant$,
    this.updatedLocationDA$,
    this.enabledSecondaryCannabinoidNames$,
    this.disableTACCalculation$,
    this.selectedCUOM$
  ]).pipe(
    map(([variant, locationDA, enabledSecondaryCannabinoid, disableTACCalculation, selectedCUOM]) => {
      if (!disableTACCalculation && selectedCUOM) {
        return variant
          ?.getAutoCalculatedTAC(enabledSecondaryCannabinoid, 'min', [locationDA])
          ?.toString();
      }
      return null;
    })
  );

  public locationMaxTACPlaceholder$: Observable<string|null> = combineLatest([
    this.variant$,
    this.updatedLocationDA$,
    this.enabledSecondaryCannabinoidNames$,
    this.disableTACCalculation$,
    this.selectedCUOM$
  ]).pipe(
    map(([variant, locationDA, enabledSecondaryCannabinoid, disableTACCalculation, selectedCUOM]) => {
      if (!disableTACCalculation && selectedCUOM) {
        return variant
          ?.getAutoCalculatedTAC(enabledSecondaryCannabinoid, 'max', [locationDA])
          ?.toString();
      }
      return null;
    })
  );

  public readonly defaultMaxCannabinoid$ = combineLatest([
    this.variant$,
    this.cannabinoid$
  ]).pipe(
    map(([variant, cannabinoid]) => variant?.['max' + cannabinoid])
  );

  public companyCannabinoidInputName$ = this.cannabinoid$.pipe(
    map(cannabinoid => 'company' + cannabinoid)
  );

  private labelTextPipe$ = combineLatest([
    this.cannabinoid$,
    this.isCompanyAdmin$,
    this.companyName$
  ]).pipe(shareReplay({bufferSize: 1, refCount: true}));

  public companyCannabinoidLabel$: Observable<string> = this.labelTextPipe$.pipe(
    map(([cannabinoid, isAdmin, name]) => {
      return isAdmin ? `${cannabinoid} - ${name} (Company)` : `${cannabinoid} - ${name} (Company)     Admin Only`;
    })
  );

  public companyCannabinoidPlaceholder$ = combineLatest([
    this.variant$,
    this.cannabinoid$,
    this.companyTACPlaceholder$
  ]).pipe(
    map(([variant, cannabinoid, companyTAC]) => {
      const defaultCannabinoid = variant?.[cannabinoid];
      if (exists(defaultCannabinoid)) {
        return `${defaultCannabinoid} (Variant Default)`;
      }
      if (exists(companyTAC) && cannabinoid === PrimaryCannabinoid.TAC) {
        return `${companyTAC} (Auto-calculated) Enter value to override`;
      }
      return `Enter the default ${cannabinoid} content`;
    })
  );

  public companyCannabinoidTooltip$ = this.cannabinoid$.pipe(
    map(cannabinoid => {
      return `This is the override ${cannabinoid} value that will appear on all menus across `
        + `your company. This value will not be modified on the product or in your POS.`;
    })
  );

  public locationCannabinoidLabel$ = combineLatest([
    this.cannabinoid$,
    this.locationName$
  ]).pipe(
    map(([cannabinoid, locationName]) => `${cannabinoid} - ${locationName} (Location)`)
  );

  public locationCannabinoidPlaceholder$ = combineLatest([
    this.updatedCompanyDA$,
    this.cannabinoid$,
    this.syncPOSCannabinoid$,
    this.inventoryProvider$,
    this.locationTACPlaceholder$,
    this.variant$
  ]).pipe(
    map(([companyDA, cannabinoid, syncPOSCannabinoid, inventoryProvider, locationTAC, variant]) => {
      return ProviderUtils.getDisplayAttributeCannabinoidPlaceholder(
        inventoryProvider,
        syncPOSCannabinoid,
        companyDA,
        companyDA?.[cannabinoid],
        `${cannabinoid}`,
        locationTAC,
        variant
      );
    })
  );

  public locationCannabinoidTooltip$ = this.cannabinoid$.pipe(
    map(cannabinoid => {
      return `This is the override ${cannabinoid} value that will appear on all menus for `
        + `this location. This value will not be modified on the product or in your POS.`;
    })
  );

  public companyMinCannabinoidInputName$ = this.cannabinoid$.pipe(
    map(cannabinoid => 'companyMin' + cannabinoid)
  );

  public companyMinCannabinoidPlaceholder$ = combineLatest([
    this.variant$,
    this.cannabinoid$,
    this.companyMinTACPlaceholder$
  ]).pipe(
    map(([variant, cannabinoid, companyMinTAC]) => {
      const defaultCannabinoid = variant?.['min' + cannabinoid];
      if (exists(defaultCannabinoid)) {
        return `${defaultCannabinoid} (Variant Default)`;
      }
      if (exists(companyMinTAC) && cannabinoid === PrimaryCannabinoid.TAC) {
        return `${companyMinTAC} (Auto-calculated) Enter minimum value to override`;
      }
      return `Enter the default minimum ${cannabinoid} content`;
    })
  );

  public companyMinCannabinoidLabel$: Observable<string> = this.labelTextPipe$.pipe(
    map(([cannabinoid, isAdmin, name]) => {
      return isAdmin
        ? `Minimum ${cannabinoid} - ${name} (Company)`
        : `Minimum ${cannabinoid} - ${name} (Company)     Admin Only`;
    })
  );

  public companyMinCannabinoidTooltip$ = this.cannabinoid$.pipe(
    map(cannabinoid => {
      return `This is the override minimum ${cannabinoid} value that will appear on all menus `
        + `across your company. This value will not be modified on the product or in your POS.`;
    })
  );

  public companyMaxCannabinoidInputName$ = this.cannabinoid$.pipe(
    map(cannabinoid => 'companyMax' + cannabinoid)
  );

  public companyMaxCannabinoidLabel$: Observable<string> = this.labelTextPipe$.pipe(
    map(([cannabinoid, isAdmin, name]) => {
      return isAdmin
        ? `Maximum ${cannabinoid} - ${name} (Company)`
        : `Maximum ${cannabinoid} - ${name} (Company)     Admin Only`;
    })
  );

  public companyMaxCannabinoidPlaceholder$ = combineLatest([
    this.variant$,
    this.cannabinoid$,
    this.companyMaxTACPlaceholder$
  ]).pipe(
    map(([variant, cannabinoid, companyMaxTAC]) => {
      const defaultCannabinoid = variant?.['max' + cannabinoid];
      if (exists(defaultCannabinoid)) {
        return `${defaultCannabinoid} (Variant Default)`;
      }
      if (exists(companyMaxTAC) && cannabinoid === PrimaryCannabinoid.TAC) {
        return `${companyMaxTAC} (Auto-calculated) Enter maximum value to override`;
      }
      return `Enter the default maximum ${cannabinoid} content`;
    })
  );

  public companyMaxCannabinoidTooltip$ = this.cannabinoid$.pipe(
    map(cannabinoid => {
      return `This is the override maximum ${cannabinoid} value that will appear on all menus `
        + `across your company. This value will not be modified on the product or in your POS.`;
    })
  );

  public locationMinCannabinoidLabel$ = combineLatest([
    this.cannabinoid$,
    this.locationName$
  ]).pipe(
    map(([cannabinoid, locationName]) => `Minimum ${cannabinoid} - ${locationName} (Location)`)
  );

  public locationMinCannabinoidPlaceholder$ = combineLatest([
    this.updatedCompanyDA$,
    this.cannabinoid$,
    this.syncPOSCannabinoid$,
    this.inventoryProvider$,
    this.locationMinTACPlaceholder$,
    this.variant$
  ]).pipe(
    map(([companyDA, cannabinoid, syncPOSCannabinoid, inventoryProvider, locationMinTAC, variant]) => {
      return ProviderUtils.getDisplayAttributeCannabinoidPlaceholder(
        inventoryProvider,
        syncPOSCannabinoid,
        companyDA,
        companyDA?.['min' + cannabinoid],
        `Min ${cannabinoid}`,
        locationMinTAC,
        variant
      );
    })
  );

  public locationMinCannabinoidTooltip$ = this.cannabinoid$.pipe(
    map(cannabinoid => {
      return `This is the override minimum ${cannabinoid} value that will appear on all menus for `
        + `this location. This value will not be modified on the product or in your POS.`;
    })
  );

  public locationMaxCannabinoidLabel$ = combineLatest([
    this.cannabinoid$,
    this.locationName$
  ]).pipe(
    map(([cannabinoid, locationName]) => `Maximum ${cannabinoid} - ${locationName} (Location)`)
  );

  public locationMaxCannabinoidPlaceholder$ = combineLatest([
    this.updatedCompanyDA$,
    this.cannabinoid$,
    this.syncPOSCannabinoid$,
    this.inventoryProvider$,
    this.locationMaxTACPlaceholder$,
    this.variant$
  ]).pipe(
    map(([companyDA, cannabinoid, syncPOSCannabinoid, inventoryProvider, locationMaxTAC, variant]) => {
      return ProviderUtils.getDisplayAttributeCannabinoidPlaceholder(
        inventoryProvider,
        syncPOSCannabinoid,
        companyDA,
        companyDA?.['max' + cannabinoid],
        `Max ${cannabinoid}`,
        locationMaxTAC,
        variant
      );
    })
  );

  public locationMaxCannabinoidTooltip$ = this.cannabinoid$.pipe(
    map(cannabinoid => {
      return `This is the override maximum ${cannabinoid} value that will appear on all menus for `
        + `this location. This value will not be modified on the product or in your POS.`;
    })
  );

  public locationMaxCannabinoidLimit$ = combineLatest([
    this.cannabinoid$,
    this.variant$,
    this.updatedCompanyDA$,
    this.updatedLocationDA$
  ]).pipe(
    map(([cannabinoid, variant, companyDA, locationDA]) => {
      const cannabinoidAccessor = 'max' + cannabinoid;
      const defaultCannabinoid = variant?.[cannabinoidAccessor];
      const companyDACannabinoid = companyDA?.[cannabinoidAccessor];
      const locationDACannabinoid = locationDA?.[cannabinoidAccessor];
      return locationDACannabinoid ?? companyDACannabinoid ?? defaultCannabinoid ?? '';
    })
  );

  public companyMaxCannabinoidLimit$ = combineLatest([
    this.cannabinoid$,
    this.updatedCompanyDA$,
    this.defaultMaxCannabinoid$
  ]).pipe(
    map(([cannabinoid, companyDA, defaultMaxCannabinoid]) => {
      const companyMaxCannabinoid = companyDA?.['max' + cannabinoid];
      return exists(companyMaxCannabinoid) ? companyMaxCannabinoid : defaultMaxCannabinoid;
    })
  );

  public hasCompanyMinCannabinoid$ = combineLatest([
    this.cannabinoid$,
    this.updatedCompanyDA$
  ]).pipe(
    map(([cannabinoid, companyDA]) => exists(companyDA?.['min' + cannabinoid]))
  );

  public hasCompanyMaxCannabinoid$ = combineLatest([
    this.cannabinoid$,
    this.updatedCompanyDA$
  ]).pipe(
    map(([cannabinoid, companyDA]) => exists(companyDA?.['max' + cannabinoid]))
  );

  public hasLocationMinCannabinoid$ = combineLatest([
    this.cannabinoid$,
    this.updatedLocationDA$
  ]).pipe(
    map(([cannabinoid, locationDA]) => exists(locationDA?.['min' + cannabinoid]))
  );

  public hasLocationMaxCannabinoid$ = combineLatest([
    this.cannabinoid$,
    this.updatedLocationDA$
  ]).pipe(
    map(([cannabinoid, locationDA]) => exists(locationDA?.['max' + cannabinoid]))
  );

  public locationMaxCannabinoidLowerBoundValue$ = this.getLowerBoundForMaxValue(this.locationMaxCannabinoidLimit$);
  public companyMaxCannabinoidLowerBoundValue$ = this.getLowerBoundForMaxValue(this.companyMaxCannabinoidLimit$);

  private getLowerBoundForMaxValue(cannabinoidMaxValue$: Observable<string>): Observable<number> {
    return cannabinoidMaxValue$.pipe(
      map(maxValue => {
        if (!maxValue) {
          return CannabinoidUtils.MAX_CANNABINOID_NUMBER;
        } else {
          const maxValueAsNumber = Number.parseFloat(maxValue);
          return Number.isFinite(maxValueAsNumber) ? maxValueAsNumber : CannabinoidUtils.MAX_CANNABINOID_NUMBER;
        }
      })
    );
  }

  companyCannabinoidUpdated(value: string) {
    this.cannabinoid$.once(cannabinoid => {
      this.editCannabinoidViewModel.companyDAUpdated(value, cannabinoid);
    });
  }

  companyMinCannabinoidUpdated(value: string) {
    this.cannabinoid$.once(cannabinoid => {
      this.editCannabinoidViewModel.companyDAUpdated(value, 'min' + cannabinoid);
    });
  }

  companyMaxCannabinoidUpdated(value: string) {
    this.cannabinoid$.once(cannabinoid => {
      this.editCannabinoidViewModel.companyDAUpdated(value, 'max' + cannabinoid);
    });
  }

  locationCannabinoidUpdated(value: string) {
    this.cannabinoid$.once(cannabinoid => {
      this.editCannabinoidViewModel.locationDAUpdated(value, cannabinoid);
    });
  }

  locationMinCannabinoidUpdated(value: string) {
    this.cannabinoid$.once(cannabinoid => {
      this.editCannabinoidViewModel.locationDAUpdated(value, 'min' + cannabinoid);
    });
  }

  locationMaxCannabinoidUpdated(value: string) {
    this.cannabinoid$.once(cannabinoid => {
      this.editCannabinoidViewModel.locationDAUpdated(value, 'max' + cannabinoid);
    });
  }

}
