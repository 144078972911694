// noinspection JSUnusedLocalSymbols

import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { EditVariantContainer } from '../../edit-variant-container';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../../../utils/distinct-utils';
import { CannabisUnitOfMeasure } from '../../../../../../../models/utils/dto/cannabis-unit-of-measure-type';
import { DisplayAttribute } from '../../../../../../../models/display/dto/display-attribute';

@Injectable()
export class EditVariantCannabinoidsViewModel extends BaseViewModel {

  constructor(
    public container: EditVariantContainer
  ) {
    super();
  }

  public cannabisUnitOfMeasureOptions$ = window.types.cannabisUnitOfMeasures$;

  public enabledSecondaryCannabinoidNames$ = this.container.enabledSecondaryCannabinoidNames$;
  public hasEnabledSecondaryCannabinoids$ = this.container.hasEnabledSecondaryCannabinoids$;

  private _selectedCUOM = new BehaviorSubject<CannabisUnitOfMeasure>(CannabisUnitOfMeasure.UNKNOWN);
  public selectedCUOM$ = this._selectedCUOM.pipe(distinctUntilChanged());

  private _updatedCompanyDA = new BehaviorSubject<DisplayAttribute>(null);
  public updatedCompanyDA$ = this._updatedCompanyDA.pipe(distinctUntilChanged());

  private _updatedLocationDA = new BehaviorSubject<DisplayAttribute>(null);
  public updatedLocationDA$ = this._updatedLocationDA.pipe(distinctUntilChanged());

  private distinctById = distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable);
  private initial = combineLatest([
    this.container.variant$.pipe(this.distinctById),
    this.container.variantLocationDisplayAttribute$.pipe(this.distinctById),
    this.container.variantCompanyDisplayAttribute$.pipe(this.distinctById)
  ]).subscribeWhileAlive({
    owner: this,
    next: ([variant, locationDA, companyDA]) => {
      this._selectedCUOM.next(variant?.cannabisUnitOfMeasure || CannabisUnitOfMeasure.UNKNOWN);
      this._updatedCompanyDA.next(companyDA);
      this._updatedLocationDA.next(locationDA);
    }
  });

  public disableCannabinoidInputs$: Observable<boolean> = combineLatest([
    this.container.showCannabinoidPOSSyncBanner$,
    this.selectedCUOM$
  ]).pipe(
    map(([showPOSSyncBanner, selectedCUOM]) => {
      return showPOSSyncBanner || selectedCUOM === CannabisUnitOfMeasure.NA;
    })
  );

  public CUOMTooltip$ = this.container.syncCannabinoidsFromPOS$.pipe(
    map(isSync => {
      if (isSync) {
        return 'Editing the Cannabis Unit of Measure is disabled when Sync THC/CBD from POS is enabled. '
          + 'This value will automatically be set based on the Default Cannabis Unit of Measure. '
          + '(See Settings > Product > General)';
      } else {
        return 'The units of measure for cannabinoids such as THC and CBD within the product.';
      }
    })
  );

  public cuomSelectionChanged = (cuom: CannabisUnitOfMeasure) => {
    this._selectedCUOM.next(cuom);
  };

  public companyDAUpdated(value: string, accessor: string) {
    this.updatedCompanyDA$.once(companyDA => {
      const companyDACopy = Object.assign(new DisplayAttribute(), companyDA);
      companyDACopy[accessor] = value;
      this._updatedCompanyDA.next(companyDACopy);
    });
  }

  public locationDAUpdated(value: string, accessor: string) {
    this.updatedLocationDA$.once(locationDA => {
      const locationDACopy = Object.assign(new DisplayAttribute(), locationDA);
      locationDACopy[accessor] = value;
      this._updatedLocationDA.next(locationDACopy);
    });
  }

}
