import { InformationModalComponent } from '../views/shared/modals/information-modal/information-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { InformationItem } from '@mobilefirstdev/reactive-form/lib/components/information-modal/information-item';

export class ModalInformation {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    title: string,
    informationItems: InformationItem[]
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(InformationModalComponent, ModalUtils.informationModalOptions(injector));
      const compInstance = modalRef.componentInstance as InformationModalComponent;
      compInstance.title = title;
      compInstance.informationItems = informationItems;
    });
  }

}
