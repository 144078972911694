<div class="product-picker-datatable-container">
  <!-- Filter Options -->
  <div
    #filterContainer
    class="datatable-filters-container sticky-modal-header"
    [style.top.px]="headerHeight$ | push : 'userBlocking'">
    <!-- do not delete #filterFormComponent -->
    <app-product-picker-filter-form
      #filterFormComponent
      [includeBottomBorder]="false"
      (resetPageNumber)="productsTableComponent?.resetPageNumber()">
    </app-product-picker-filter-form>
  </div>

  <!-- do not delete #productsTableComponent -->
  <app-product-picker-table
    #productsTableComponent
    [products]="viewModel.productsFilteredByProductTypeVariantTypeStrainType$ | push : 'userBlocking'"
    [nItemsToDisplay]="viewModel.numberOfProductsPerPage$ | push : 'userBlocking'"
    [filterHeight]="(filterDivHeight$ | push : 'userBlocking') + (headerHeight$ | push : 'userBlocking')"
    (dataBeingDisplayed)="viewModel.setProductsBeingDisplayed($event)">
  </app-product-picker-table>
</div>
