import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { PopperUtils } from '../../../../utils/popper-utils';

@Component({
  selector: 'app-arrow-button',
  templateUrl: './arrow-button.component.html',
  styleUrls: ['./arrow-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrowButtonComponent extends BaseComponent {

  constructor(
    public elementRef: ElementRef<HTMLDivElement>
  ) {
    super();
  }

  @Input() id: string;
  @Input() title: string;
  @Input() subtitle;
  @Input() titleFontSize: string;
  @Input() subtitleFontSize: string;
  @Input() titleFontWeight: string;
  @Input() subtitleFontWeight: string;
  @Input() paddingLeft: string;
  @Input() paddingRight: string;
  @Input() selected: boolean;
  @Input() ifSelectedPreventClickEvents: boolean = false;
  @Input() infoText: string;
  @Output() clicked = new EventEmitter<boolean>();

  // Popper
  public popperModifier = [PopperUtils.flipModifier(['bottom', 'top'])];
  public popperStyles = {
    'background-color': 'black',
    'z-index': 99
  };

}
