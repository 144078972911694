import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'variantSecondaryPrice'
})
export class VariantSecondaryPricePipe implements PipeTransform {

  transform(
    locationId: number,
    companyId: number,
    variant: Variant
  ): number|null {
    if (locationId > -1) {
      return variant?.getSecondaryPrice(locationId, companyId);
    } else {
      return null;
    }
  }

}
