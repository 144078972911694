import { Component } from '@angular/core';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { AuthViewModel } from '../../../viewModels/auth-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../../services/toast-service';
import { BsError } from '../../../../../models/shared/bs-error';
import { SignInNewPasswordRequest } from '../../../../../models/account/requests/sign-in-new-password-request';
import { BaseComponent } from '../../../../../models/base/base-component';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { NavigationService } from '../../../../../services/navigation.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: [
    './new-password.component.scss',
    '../auth.component.scss'
  ]
})
export class NewPasswordComponent extends BaseComponent {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private toastService: ToastService,
    public viewModel: AuthViewModel
  ) {
    super();
  }

  // View
  public _loadingOpts = new BehaviorSubject(LoadingOptions.default());
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this._loadingOpts.asObservable().pipe(map(opts => opts.isLoading));
  // Variables
  public req: SignInNewPasswordRequest = new SignInNewPasswordRequest();

  override setupBindings() {
    this.viewModel.user$.pipe(debounceTime(1)).subscribeWhileAlive({
      owner: this,
      next: user => {
        const userId = user?.userId;
        const sessionToken = user?.session?.getChallengeAuthToken();
        const validSession = user?.session?.validSession();
        if ((!userId || !sessionToken) && !validSession) {
          this.navigationService.signIn();
        }
      }
    });
  }

  formSubmitted(req: SignInNewPasswordRequest) {
    const loadingMess = 'Updating Password';
    if (!this._loadingOpts.containsRequest(loadingMess)) {
      this._loadingOpts.addRequest(loadingMess);
      this.viewModel.signInNewPassword(req).subscribe({
        next: () => this._loadingOpts.removeRequest(loadingMess),
        error: (error: BsError) => {
          this._loadingOpts.removeRequest(loadingMess);
          this.toastService.publishError(error);
          throwError(() => error);
        }
      });
    }
  }

}
