import { DateUtils } from '../../../utils/date-utils';
import { DistinctUtils } from '../../../utils/distinct-utils';
import type { Variant } from '../../product/dto/variant';
import { SaleLabelFormat } from '../../utils/dto/sale-label-format-type';
import { CardType } from '../../utils/dto/card-type-definition';
import type { SectionTemplate } from '../../template/dto/section-template';
import type { Menu } from './menu';
import { HydratedVariantFeature } from '../../product/dto/hydrated-variant-feature';
import { BaseSection } from './base-section';
import { PolymorphicDeserializationKey } from '../../enum/shared/polymorphic-deserialization-key.enum';
import { Breadcrumb } from '../../shared/stylesheet/breadcrumb';
import { exists } from '../../../functions/exists';
import { SectionMetadata } from './section-metadata';
import type { Product } from '../../product/dto/product';
import { SortUtils } from '../../../utils/sort-utils';
import { VariantGroup } from '../../product/shared/variant-group';
import { PriceFormat } from '../../enum/dto/price-format';
import { SectionType } from '../../enum/dto/section-type';
import type { SectionSortOption } from '../../enum/dto/section-sort-option';

export class Section extends BaseSection {

  public configurationId: string;
  public productIds: string[];
  public enabledVariantIds: string[];
  public masterSectionId: string;
  public variantBadgeIdsMap: Map<string, string[]>;
  public customLabelMap: Map<string, string>;
  public lastSmartFilterSync: number;
  public saleLabelFormat: SaleLabelFormat;
  public cardType: CardType;
  public sortedVariantIds: string[][];
  // Template
  public templateSectionId?: string;
  public templateSection?: SectionTemplate;
  // Cache
  public cachedTime: number;

  static buildCacheKey(id: string): string {
    return `Section-${id}`;
  }

  getPolymorphicDeserializationKey(): PolymorphicDeserializationKey {
    return PolymorphicDeserializationKey.Section;
  }

  /**
   * Don't use methods or types in here. The pointers are added before the data has been completely deserialized,
   * so the data is in a raw JavaScript object format.
   *
   * Section.TemplateSection is scrubbed away by the server to prevent sending the template data structure
   * multiple times within a single data structure tree. This method adds the template section pointers back
   * upon deserialization.
   */
  static setTemplatePointers(menu: any): void {
    menu?.sections?.forEach(section => {
      const templateSec = menu?.template?.templateSections?.find(ts => ts?.id === section?.templateSectionId);
      if (exists(templateSec)) {
        section.templateSection = templateSec;
      }
    });
  }

  /**
   * The following is done to prevent circular import warnings:
   * this.templateSection = window?.injector?.Deserialize.instanceOf(Section, this.templateSection) as SectionTemplate;
   * Section has been added to the polymorphic deserializer, so if the data represents a SectionTemplate, then
   * it will get deserialized as a SectionTemplate, and not as a Section.
   */
  public override onDeserialize() {
    super.onDeserialize();
    this.productIds = Array.from(this.productIds || []);
    this.enabledVariantIds = Array.from(this.enabledVariantIds || []);
    this.templateSection = window?.injector?.Deserialize.instanceOf(Section, this.templateSection) as SectionTemplate;
    this.deserializeVariantBadgeIds();
    this.deserializeCustomLabelMap();
    if (this.isTemplatedSection() && !!this.templateSection) {
      this.hydrateFromTemplate();
    }
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/SectionDTO.go
  public override onSerialize(): any {
    const dto = Object.assign(new Section(), super.onSerialize());
    dto.configurationId = this.configurationId;
    dto.id = this.id;
    dto.customLabelMap = this.customLabelMap;
    dto.enabledVariantIds = this.enabledVariantIds;
    dto.lastSmartFilterSync = this.lastSmartFilterSync;
    dto.productIds = this.productIds?.unique();
    dto.saleLabelFormat = this.saleLabelFormat;
    dto.templateSectionId = this.templateSectionId;
    dto.variantBadgeIdsMap = this.variantBadgeIdsMap;
    return dto;
  }

  public setTemplateSection(ts: SectionTemplate): void {
    this.templateSection = ts;
    this.hydrateFromTemplate();
  }

  private deserializeVariantBadgeIds() {
    this.variantBadgeIdsMap = !!this.variantBadgeIdsMap
      ? window?.injector?.Deserialize?.genericArrayMap(this.variantBadgeIdsMap)
      : new Map<string, string[]>();
  }

  private deserializeCustomLabelMap() {
    if (!this.customLabelMap) {
      this.customLabelMap = new Map<string, string>();
    } else if (!(this.customLabelMap instanceof Map)) {
      this.customLabelMap = window?.injector?.Deserialize.genericMap(this.customLabelMap);
    } else {
      this.customLabelMap = new Map<string, string>(this.customLabelMap);
    }
  }

  override translateIntoDTO(): this {
    super.translateIntoDTO();
    this.dehydrateTemplatedSection();
    return this;
  }

  protected hydrateFromTemplate(): void {
    this.title = this.templateSection?.title;
    this.subTitle = this.templateSection?.subTitle;
    this.sectionType = this.templateSection?.sectionType;
    this.sorting = this.templateSection?.sorting;
    this.secondarySorting = this.templateSection?.secondarySorting;
    this.layoutType = this.templateSection?.layoutType;
    this.priority = this.templateSection?.priority;
    this.rowCount = this.templateSection?.rowCount;
    this.showZeroStockItems = this.templateSection?.showZeroStockItems;
    this.saleLabelFormat = this.templateSection?.saleLabelFormat;
    this.cardType = this.templateSection?.cardType;
    const metadataToUse = this.templateSection?.metadata;
    if (this.templateSection?.autoUpdateGridColumns && !!this.metadata?.gridColumnNames) {
      metadataToUse.gridColumnNames = this.metadata?.gridColumnNames;
    }
    this.metadata = metadataToUse;
    this.autoUpdateGridColumns = this.templateSection?.autoUpdateGridColumns;
    this.productIds = [...(this.productIds ?? []), ...(this.templateSection?.productIds ?? [])]?.unique();
    this.smartFilterIds = [
      ...(this.smartFilterIds ?? []),
      ...(this.templateSection?.smartFilterIds ?? [])
    ]?.unique();
    this.lastSmartFilterSync = Math.max(this.lastSmartFilterSync, this.templateSection?.lastSmartFilterSync, 0);
    this.enabledVariantIds = [
      ...(this.enabledVariantIds ?? []),
      ...(this.templateSection?.enabledVariantIds ?? [])
    ]?.unique();
    this.templateSection?.variantBadgeIdsMap?.forEach((badgeIds, variantId) => {
      if (!this.variantBadgeIdsMap?.get(variantId)) {
        this.variantBadgeIdsMap?.set(variantId, badgeIds);
      } else {
        const combinedBadgeIds = [...(this.variantBadgeIdsMap?.get(variantId) ?? []), ...(badgeIds ?? [])].unique();
        this.variantBadgeIdsMap?.set(variantId, combinedBadgeIds);
      }
    });
    this.templateSection?.customLabelMap?.forEach((labelId, variantId) => {
      if (!this.customLabelMap?.get(variantId)) {
        this.customLabelMap?.set(variantId, labelId);
      }
    });
  }

  public dehydrateTemplatedSection(): void {
    if (!this.isTemplatedSection()) return;
    this.title = '';
    this.subTitle = '';
    this.sorting = '' as SectionSortOption;
    this.secondarySorting = '' as SectionSortOption;
    this.layoutType = '';
    this.priority = -1;
    this.rowCount = -1;
    this.showZeroStockItems = false;
    this.saleLabelFormat = '' as SaleLabelFormat;
    this.cardType = '' as CardType;
    this.metadata = null;
    this.autoUpdateGridColumns = null;
    this.productIds = this.productIds?.filter(productId => !this.templateSection?.productIds?.includes(productId));
    this.smartFilterIds = this.getNonTemplatedSmartFilterIds();
    this.enabledVariantIds = this.getNonTemplatedVariantIds();
    this.templateSection?.variantBadgeIdsMap?.forEach((badgeIds, variantId) => {
      const currentBadgeIds = this.variantBadgeIdsMap?.get(variantId);
      if (!!currentBadgeIds) {
        const sectionVariantBadgeIds = currentBadgeIds?.filter(bid => !badgeIds?.includes(bid));
        if (!sectionVariantBadgeIds?.length) {
          this.variantBadgeIdsMap?.delete(variantId);
        } else {
          this.variantBadgeIdsMap?.set(variantId, sectionVariantBadgeIds);
        }
      }
    });
    this.templateSection?.customLabelMap?.forEach((labelId, variantId) => {
      const currentLabelId = this.customLabelMap?.get(variantId);
      if (currentLabelId === labelId) {
        this.customLabelMap?.delete(variantId);
      }
    });
  }

  combineWithTemplatedData(sectionTemplate: Section): void {
    const shortCircuitedProperties = this.getCombineWithTemplateDataShortCircuitedProperties();
    shortCircuitedProperties?.forEach(key => this[key] = this[key] || sectionTemplate?.[key]);
    this.mergeAdvancedDataPropertiesFromTemplateSection(sectionTemplate);
  }

  protected getCombineWithTemplateDataShortCircuitedProperties(): string[] {
    const ignore = [
      'templatedSectionId', 'templatedSection',
      'uniqueIdentifier', 'cachedTime',
      'pageIndex', 'firstOnPage', 'lastOnPage', 'lastSection',
      'metadata'
    ];
    return Object.keys(this)?.filter(key => !ignore.includes(key)) || [];
  }

  protected mergeAdvancedDataPropertiesFromTemplateSection(sectionTemplate: Section): void {
    // base implementation is section template data, then override with any section data
    this.combineCustomLabelMapWithTemplate(sectionTemplate);
    this.combineSectionMetadataWithTemplate(sectionTemplate);
  }

  protected combineCustomLabelMapWithTemplate(sectionTemplate: Section): void {
    const updatedMap = window.injector.Deserialize.genericMap(sectionTemplate?.customLabelMap) ?? new Map();
    this.customLabelMap?.forEach((customLabel, key) => updatedMap?.set(key, customLabel));
    this.customLabelMap = updatedMap;
  }

  protected combineSectionMetadataWithTemplate(sectionTemplate: Section): void {
    const templateDataTakesPrecedenceFor = [
      'width', 'alignment', 'imageAlignment',
      'repeat', 'objectFit',
      'hidePrices', 'cardOpacity'
    ];
    if (!this.autoUpdateGridColumns) {
      // If section uses auto-update grid columns, then use the value from the templated menu (not the template)
      templateDataTakesPrecedenceFor.push('gridColumnNames');
    }
    this.metadata = this.metadata || {} as SectionMetadata;
    Object.keys(sectionTemplate?.metadata || {})?.forEach(property => {
      templateDataTakesPrecedenceFor?.includes(property)
        ? this.metadata[property] = sectionTemplate?.metadata[property] || this.metadata[property]
        : this.metadata[property] = this.metadata[property] || sectionTemplate?.metadata[property];
    });
  }

  public override getSectionTitle(): string {
    switch (this.sectionType) {
      case SectionType.PageBreak:
        return 'Page Break';
      default:
        return this.title;
    }
  }

  shouldGroupProductVariantsTogether(): boolean {
    // Group together product variants
    // This is ignored for Pricing Tier Grid since each line item is still a discrete variant
    return this.isGridMode() || this.isChildVariantList();
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(): string {
    return Section.buildCacheKey(this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  isTemplatedSection(): boolean {
    return !!this.templateSectionId;
  }

  allowedToOverrideTemplateStyling(): boolean {
    return this.isTemplatedSection()
      ? this.templateSection?.allowStyleOverride
      : true;
  }

  whatChangedString(updatedSection: Section): string {
    let changesString;
    let sectionTitle;
    if (updatedSection.title !== '') {
      sectionTitle = `${updatedSection?.title} section.`;
      changesString = `\'${sectionTitle}\' Smart Filter sync completed. Nothing changed.`;
    } else {
      sectionTitle = 'menu.';
      changesString = `Smart Filter sync completed. Nothing changed.`;
    }
    let nProductsGained = 0;
    let nProductsRemoved = 0;
    const oldProductIds = this.productIds;
    const newProductIds = updatedSection?.productIds || [];

    if (!DistinctUtils.distinctSortedStrings(oldProductIds, newProductIds)) {
      const whichProductsWereLost = oldProductIds?.filter(oldId => !newProductIds?.contains(oldId));
      nProductsRemoved = whichProductsWereLost?.length ?? 0;
      const whichProductsWereGained = newProductIds?.filter(newId => !oldProductIds?.contains(newId));
      nProductsGained = whichProductsWereGained?.length ?? 0;
    }

    if (nProductsGained > 0 && nProductsRemoved > 0) {
      changesString = `${nProductsGained} product${nProductsGained > 1 ? 's' : ''} `
        + `${nProductsGained > 1 ? 'were' : 'was'} added and `
        + `${nProductsRemoved} product${nProductsRemoved > 1 ? 's' : ''} `
        + `${nProductsRemoved > 1 ? 'were' : 'was'} removed from your ${sectionTitle}`;
    } else if (nProductsGained > 0) {
      changesString = `${nProductsGained} product${nProductsGained > 1 ? 's' : ''} `
        + `${nProductsGained > 1 ? 'were' : 'was'} added to your ${sectionTitle}`;
    } else if (nProductsRemoved > 0) {
      changesString = `${nProductsRemoved} product${nProductsRemoved > 1 ? 's' : ''} `
        + `${nProductsRemoved > 1 ? 'were' : 'was'} removed from your ${sectionTitle}`;
    }
    return changesString;
  }

  /**
   * "Scoped visible" means that the variants returned are being displayed on the menu.
   */
  public getScopedVisibleVariants(
    productsForGridMode: Product[] | null,
    variants: Variant[],
    menu: Menu,
    priceFormat: PriceFormat,
    sectionLevelOverflow: boolean
  ): Variant[] {
    switch (true) {
      case this.isChildVariantList(): {
        const forChildVariantList = this.getScopedVisibleVariantsForChildVariantList.bind(this);
        return forChildVariantList(productsForGridMode, variants, menu, priceFormat, sectionLevelOverflow).flatten();
      }
      case this.isGridMode(): {
        const forGridMode = this.getScopedVisibleVariantsForGridMode.bind(this);
        return forGridMode(productsForGridMode, variants, menu, priceFormat, sectionLevelOverflow).flatten();
      }
      default: {
        const forLineItemMode = this.getScopedVisibleVariantsForLineItemMode.bind(this);
        return forLineItemMode(variants, menu, priceFormat, sectionLevelOverflow);
      }
    }
  }

  /**
   * Don't use this unless absolutely necessary. Use getScopedVisibleVariants() instead.
   */
  public getScopedVisibleVariantsWithoutFlattening(
    productsForGridMode: Product[] | null,
    variants: Variant[],
    menu: Menu,
    priceFormat: PriceFormat,
    sectionLevelOverflow: boolean
  ): Variant[] | Variant[][] {
    switch (true) {
      case this.isChildVariantList(): {
        const forChildVariantList = this.getScopedVisibleVariantsForChildVariantList.bind(this);
        return forChildVariantList(productsForGridMode, variants, menu, priceFormat, sectionLevelOverflow);
      }
      case this.isGridMode(): {
        const forGridMode = this.getScopedVisibleVariantsForGridMode.bind(this);
        return forGridMode(productsForGridMode, variants, menu, priceFormat, sectionLevelOverflow);
      }
      default: {
        const forLineItemMode = this.getScopedVisibleVariantsForLineItemMode.bind(this);
        return forLineItemMode(variants, menu, priceFormat, sectionLevelOverflow);
      }
    }
  }

  /**
   * "Scoped visible" means that the variants returned are being displayed on the menu.
   */
  getScopedVisibleLineItemCount(
    products: Product[],
    variants: Variant[],
    menu: Menu,
    priceFormat: PriceFormat,
    sectionLevelOverflow: boolean
  ): number {
    switch (true) {
      case this.isChildVariantList(): {
        const forChildVariantList = this.getScopedVisibleVariantsForChildVariantList.bind(this);
        return forChildVariantList(products, variants, menu, priceFormat, sectionLevelOverflow)?.length || 0;
      }
      case this.isGridMode(): {
        const forGridMode = this.getScopedVisibleVariantsForGridMode.bind(this);
        return forGridMode(products, variants, menu, priceFormat, sectionLevelOverflow)?.length || 0;
      }
      default: {
        const forLineItemMode = this.getScopedVisibleVariantsForLineItemMode.bind(this);
        return forLineItemMode(variants, menu, priceFormat, sectionLevelOverflow)?.length || 0;
      }
    }
  }

  getScopedVisibleVariantsForLineItemMode(
    variants: Variant[],
    menu: Menu,
    locationPriceStream: PriceFormat,
    sectionLevelOverflow: boolean
  ): Variant[] {
    const showZeroStockItems = menu?.containsStackedContent() || this.showZeroStockItems;
    const sectionVariants = variants?.filter(v => this.enabledVariantIds?.find(x => x === v?.id));
    const scoped = sectionVariants?.filter(variant => showZeroStockItems || variant?.inventory?.inStock()) || [];
    const sorted = SortUtils.sortVariantsBySectionSortOptions(scoped, this);
    return (this.rowCount && !sectionLevelOverflow) ? sorted?.take(this.rowCount) : sorted;
  }

  getScopedVisibleVariantsForChildVariantList(
    products: Product[],
    variants: Variant[],
    menu?: Menu,
    priceFormat?: PriceFormat,
    sectionLevelOverflow?: boolean
  ): Variant[][] {
    const enabledVariants = variants?.filter(v => this.enabledVariantIds?.find(x => x === v?.id));
    const enabledProductIds = enabledVariants?.map(v => v?.productId)?.unique();
    const showZeroStockItems = menu?.containsStackedContent() || this.showZeroStockItems;
    const scoped = enabledProductIds?.map(productId => {
      return enabledVariants?.filter(v => v?.productId === productId && (showZeroStockItems || v?.inStock()));
    });
    const variantGroups = scoped?.map(variantsInGroup => {
      const product = products?.find(p => p?.id === variantsInGroup?.firstOrNull()?.productId);
      return new VariantGroup(product, variantsInGroup, true);
    });
    const locationId = menu?.locationId;
    const companyId = menu?.companyId;
    const hideSale = menu?.menuOptions?.hideSale;
    SortUtils.prepareForSorting(variantGroups, locationId, companyId, priceFormat, null, hideSale);
    const sort = SortUtils.variantGroupsBySortOptions(variantGroups, this);
    const sorted = sort?.map(it => it?.variants);
    return (this.rowCount && !sectionLevelOverflow) ? sorted?.take(this.rowCount) : sorted;
  }

  /**
   * This got retro fitted to work with getScopedVisibleVariants a while ago. HydratedSection has an override
   * of this method that contains the correct logic. So for the most part, you can ignore that this method even
   * exists, because we are only interested in the override method. This method looks to be incorrect, but I'm worried
   * about changing it, because I don't understand why it's like this. If it's wrong and causes a bug, then someone
   * can delete it later.
   */
  getScopedVisibleVariantsForGridMode(
    products: Product[],
    variants: Variant[],
    menu?: Menu,
    priceFormat?: PriceFormat,
    sectionLevelOverflow?: boolean
  ): Variant[][] {
    let rowVariants = variants?.filter(v => this.enabledVariantIds?.find(x => x === v?.id));
    if (this.isStackedContent()) {
      rowVariants = rowVariants?.filter(v => {
        return this.getActiveColumnsAsGridColumnComparisonStrings()?.includes(v?.getGridNameAsColumnComparisonString());
      }) || [];
    }
    const groupingNames = Array.from(new Set<string>(rowVariants?.map(v => v?.getFormattedUnitSize())));
    return groupingNames?.map(groupName => rowVariants?.filter(v => v?.getFormattedUnitSize() === groupName));
  }

  /**
   * Used to calculate the amount of time a specific section level overflow will take.
   *
   * @returns [number of visible line items, row count, section overflow duration]
   */
  getScopedVisibleLineItemDisplayDurationCalculationData(
    products: Product[],
    variants: Variant[],
    menu: Menu,
    priceFormat: PriceFormat,
    sectionLevelOverflow: boolean,
    sectionOverflowDuration: number
  ): [number, number, number] {
    return [
      this.getScopedVisibleLineItemCount(products, variants, menu, priceFormat, sectionLevelOverflow),
      this.rowCount,
      sectionOverflowDuration
    ];
  }

  parseFeaturedVariants(menu: Menu): HydratedVariantFeature {
    const sectionFeaturedVariants = new Map<string, boolean>();
    this.enabledVariantIds?.forEach((vid) => {
      const isFeat = menu?.variantFeature?.variantIds?.contains(vid);
      sectionFeaturedVariants.set(vid, isFeat);
    });
    const variantFeature = window?.injector?.Deserialize?.instanceOf(HydratedVariantFeature, menu?.variantFeature);
    const existingFeaturedVariantIds = menu?.variantFeature?.variantIds?.shallowCopy() ?? [];
    const sectionFeaturedVariantIds = [];
    sectionFeaturedVariants?.forEach((enabled, vid) => {
      if (enabled) {
        sectionFeaturedVariantIds.push(vid);
      } else {
        const removeIndex = existingFeaturedVariantIds.indexOf(vid);
        if (removeIndex > -1) existingFeaturedVariantIds.splice(removeIndex, 1);
      }
    });
    if (variantFeature) {
      variantFeature.variantIds = existingFeaturedVariantIds.concat(sectionFeaturedVariantIds).unique(true).sort();
    }
    return variantFeature;
  }

  getEditSectionName(): string {
    switch (this.sectionType) {
      case SectionType.Product:
      case SectionType.NewProducts:
      case SectionType.RestockedProducts: return 'Edit Product Section';
      case SectionType.Spotlight:         return 'Edit Spotlight Section';
      case SectionType.Title:             return 'Edit Title Section';
      case SectionType.Media:             return 'Edit Media Section';
      case SectionType.PageBreak:         return 'Edit Page Break Section';
      case SectionType.CategoryCard:      return 'Edit Category Card';
      case SectionType.ProductGroup:      return 'Edit Product Grouping';
      default:                            return '';
    }
  }

  getEditSectionUrl(menu: Menu): string {
    if (this.sectionType === SectionType.PageBreak) return null;
    return menu?.getEditNavigationUrl() + `/section/${this.id}`;
  }

  getEditBreadcrumb(menu: Menu, active: boolean): Breadcrumb {
    const name = this.getEditSectionName();
    const url = this.getEditSectionUrl(menu);
    const fragment = undefined;
    return new Breadcrumb(name, url, fragment, active);
  }

  getNonTemplatedSmartFilterIds(): string[] {
    return this.smartFilterIds
      ?.filter(smartFilterId => !this.templateSection?.smartFilterIds?.includes(smartFilterId))
      ?? [];
  }

  getNonTemplatedVariantIds(): string[] {
    return this.enabledVariantIds
      ?.filter(enabledVariantId => !this.templateSection?.enabledVariantIds?.includes(enabledVariantId))
      ?? [];
  }

  permissionToRemoveVariantFromSection(variant: Variant): boolean {
    const variantOnTemplate = this.templateSection?.enabledVariantIds?.contains(variant?.id);
    let permissionToRemove: boolean;
    switch (true) {
      case this.isTemplatedSection(): {
        permissionToRemove = this.templateSection?.allowAddProducts ?? false;
        break;
      }
      default: permissionToRemove = true;
    }
    const removableVariantNotOnTemplate = !variantOnTemplate && permissionToRemove;
    return !this.hasSmartFilters() && removableVariantNotOnTemplate;
  }

  isVariantAddedByTemplate(variant: Variant): boolean {
    return this.templateSection?.enabledVariantIds?.contains(variant?.id);
  }

  public isProductSectionWithVisibleContent(menuVariants: Variant[]): boolean {
    const sectionHasInStockVariants = menuVariants?.some(v => {
      return this.enabledVariantIds?.find(vId => vId === v?.id) && v?.inStock();
    });
    return (this.showZeroStockItems || sectionHasInStockVariants) && !this.hideSection;
  }

  isCardStack(): boolean {
    return this.sectionType === SectionType.CardStack;
  }

  isLabelStack(): boolean {
    return this.sectionType === SectionType.LabelStack;
  }

  isStackedContent(): boolean {
    return this.isCardStack() || this.isLabelStack();
  }

  override getUniqueIdentifier(): string {
    const id = this.id;
    const priorityId = this.priority?.toString() ?? '0';
    const masterSectionId = this.masterSectionId ?? '';
    const variantBadgeIds: string[] = [];
    this.variantBadgeIdsMap?.forEach((val, key) => {
      const vbId = val.sort().join(',') ?? '';
      variantBadgeIds.push(`${key}-${vbId}`);
    });
    const customLabelMap: string[] = [];
    this.customLabelMap.forEach((val, key) => {
      customLabelMap.push(`${key}-${val}`);
    });
    const variantBadgesId = variantBadgeIds.sort().join(',') ?? '';
    const lastFilterSync = `${this.lastSmartFilterSync}`;
    return super.getUniqueIdentifier() + `${id}
      -${priorityId}
      -${customLabelMap.sort().join(',')}
      -${masterSectionId}
      -${variantBadgesId}
      -${lastFilterSync}`;
  }

}
