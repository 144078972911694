import { ChangeDetectionStrategy, Component, Injector, NgZone } from '@angular/core';
import { EditMenuSectionComponent } from '../../../../edit-menu-section/edit-menu-section.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditShelfTalkerBrandsSectionViewModel } from './edit-shelf-talker-brands-section-view-model';

@Component({
  selector: 'app-edit-shelf-talker-brands-section',
  templateUrl: './edit-shelf-talker-brands-section.component.html',
  styleUrls: ['./edit-shelf-talker-brands-section.component.scss'],
  providers:[EditShelfTalkerBrandsSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShelfTalkerBrandsSectionComponent extends EditMenuSectionComponent {

  constructor(
    public override viewModel: EditShelfTalkerBrandsSectionViewModel,
    activatedRoute: ActivatedRoute,
    router: Router,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
  ) {
    super(viewModel, activatedRoute, router, ngZone, ngbModal, injector);
  }

}
