import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { SectionAdjustableAssetViewModel } from './section-adjustable-asset-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';
import { ReactiveFormDropDownComponent, ReactiveFormNumberComponent } from '@mobilefirstdev/reactive-form';
import { SectionTemplate } from '../../../../../models/template/dto/section-template';
import { HydratedSection } from '../../../../../models/menu/dto/hydrated-section';
import { ImageAlignment } from '../../../../../models/enum/shared/image-alignment';

@Component({ template: '' })
export abstract class SectionAdjustableAssetComponent extends BaseComponent implements OnChanges {

  constructor(
    public viewModel: SectionAdjustableAssetViewModel
  ) {
    super();
  }

  @ViewChild('imageAlignment') imageAlignmentDropdown: ReactiveFormDropDownComponent;
  @ViewChild('imageWidth') imageWidth: ReactiveFormNumberComponent;
  @Input() mergeKey = 'editSection';
  @Input() section: SectionTemplate|HydratedSection;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.section) this.viewModel.connectToSection(this.section);
  }

  override setupBindings() {
    super.setupBindings();
    this.viewModel.imageWidthDisabled$.subscribeWhileAlive({
      owner: this,
      next: imageWidthDisabled => {
        if (imageWidthDisabled) this.imageWidth?.getSelfAsFormItem()?.setValue(100);
      }
    });
    this.viewModel.imageAlignmentDisabled$.subscribeWhileAlive({
      owner: this,
      next: imageAlignmentDisabled => {
        if (imageAlignmentDisabled) this.imageAlignmentDropdown?.getSelfAsFormItem()?.setValue(ImageAlignment.Center);
      }
    });
  }

}
