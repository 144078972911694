import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { TemplateCollectionDomainModel } from '../../../../../domainModels/template-collection-domain-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';

@Injectable()
export class EditCollectionDetailsViewModel extends BaseViewModel {

  constructor(
    private collectionDomainModel: TemplateCollectionDomainModel,
    private locationDomainModel: LocationDomainModel,
  ) {
    super();
  }

  public displaySizeDropDowns$ = window?.types?.digitalSizeTypes$;
  public orientationDropDowns$ = window?.types?.orientations$;

  public tagsDelimited$: Observable<string> = this.collectionDomainModel.existingCollectionTags$.pipe(
    map(x => x?.map(x => x.title)?.filterFalsies()?.join(','))
  );

  private _creatingNewTag = new BehaviorSubject<boolean>(false);
  public creatingNewTag$ = this._creatingNewTag as Observable<boolean>;

  public bannerMessage$ = this.locationDomainModel.locationName$.pipe(
    map((locationName) => {
      return `The live view of this template collection will show products based on ${locationName} location.`;
    })
  );

  public toggleNewTagInput(): void {
    this.creatingNewTag$.once(t => this._creatingNewTag.next(!t));
  }

}
