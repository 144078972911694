<div class="item-container">
  <!-- We want loading container to take shape of display preview card -->
  <app-loading
    *rxIf="viewModel.isLoading$; strategy: 'immediate'"
    [options]="viewModel.loadingOpts$ | push : 'immediate'" />

  <!-- Image preview -->
  <div
    class="item-preview-container preview"
    [ngClass]="{
      'item-landscape-preview': viewModel.useLandscapeAspectRatio$ | push,
      'item-portrait-preview': viewModel.usePortraitAspectRatio$ | push
    }">
    <swiper-container
      [hidden]="!(viewModel?.hasContentIds$ | push)"
      (click)="viewModel.openEditItem()"
      class="swiper-container"
      #swiper
      initial-slide="0"
      slides-per-view="1"
      loop="false">
      <ng-container *rxFor="let contentId of viewModel.contentIds$; trackBy: viewModel.trackByContentId">
        <swiper-slide lazy="true">
          <!--  Top Right corner loading spinner for refresh  -->
          <ng-container
            *rxIf="viewModel.refreshLoadingBundle$ | displayItemGetLoadingFromBundle : contentId as refreshOptions$">
            <div
              [hidden]="!(refreshOptions$ | push)?.isLoading"
              [ngbTooltip]="'Refreshing Preview'"
              [container]="'body'"
              class="refreshing-preview-container"
              placement="right">
              <app-loading *rxIf="(refreshOptions$ | push)?.isLoading" [options]="refreshOptions$ | push">
              </app-loading>
            </div>
          </ng-container>
          <div class="swiper-image-content">
            <ng-container
              *rxLet="viewModel.previewLoadingBundle$ | displayItemGetLoadingFromBundle : contentId as loadingOptions$">
              <app-loading *rxIf="(loadingOptions$ | push)?.isLoading" [options]="loadingOptions$ | push"></app-loading>
            </ng-container>
            <app-asset
              *rxIf="viewModel.itemPreviews$ | loadPreview : contentId | push as previewAsset"
              class="item-preview-img"
              [styleOverrides]="viewModel.imgStyleOverrides$ | push"
              [borderRadius]="'0.625rem'"
              [scaleFit]="imageFitContain"
              [asset]="previewAsset"
              [size]="ClientTypes.AssetSize.Large">
            </app-asset>
          </div>
        </swiper-slide>
      </ng-container>
      <swiper-slide lazy="true" *ngIf="viewModel?.showSeeMoreCard$ | push">
        <div class="see-more-swiper-card">
          {{ viewModel?.seeMoreCardsText$ | push }}
        </div>
      </swiper-slide>
    </swiper-container>
    <img
      *rxIf="!(viewModel.hasContentIds$ | push)"
      class="empty-preview"
      (click)="viewModel.openEditItem()"
      [src]="viewModel.previewPlaceholderSrc$ | push" />
    <div *rxIf="viewModel.showCarouselButtons$" class="carousel-button-container" (click)="viewModel.openEditItem()">
      <div (click)="$event.stopPropagation()">
        <app-carousel-button
          [disabled]="viewModel.firstSlide$ | push"
          [icon]="'assets/icons/light/outline/chevron-left.svg'"
          (clicked)="previousSlide()">
        </app-carousel-button>
      </div>
      <div (click)="$event.stopPropagation()">
        <app-carousel-button
          [disabled]="viewModel.lastSlide$ | push"
          [icon]="'assets/icons/light/outline/chevron-right.svg'"
          (clicked)="nextSlide()">
        </app-carousel-button>
      </div>
    </div>
    <div class="options-button-container">
      <app-drop-down-menu
        (output)="viewModel.handleDropdownSelection($event)"
        [buttonClass]="'options-button-color rounded-icon-button'"
        [dropdownTopMargin]="'1.2rem'"
        [dropdownLeftMargin]="'0.4rem'"
        [iconSrc]="'assets/icons/dark/outline/dots-horizontal.svg'"
        [openLeft]="true"
        [sections]="viewModel.dropDownMenuSections$ | push">
      </app-drop-down-menu>
    </div>
    <div
      *rxIf="viewModel.showLiveViewButton$"
      (click)="$event.stopPropagation()"
      [ngbTooltip]="'Live View'"
      [triggers]="'hover'"
      class="options-button-container me-5">
      <button (click)="viewModel.openLiveViewModal()" class="bs-button options-button-color rounded-icon-button">
        <img [src]="'assets/icons/dark/outline/eye.svg'" alt="" class="icon-button-icon" />
      </button>
    </div>
    <div class="badge-container">
      <!--      Badge Container uses row-reverse flex direction to right align, so elements are in reverse order-->
      <app-active *rxIf="viewModel.showActiveBadge$" [displayableItem]="displayableItem"></app-active>
      <app-template-deployed-count-indicator
        *rxIf="viewModel.showDeployedCountIndicator$"
        [displayableItem]="displayableItem">
      </app-template-deployed-count-indicator>
      <app-smart-menu-indicator
        *rxIf="viewModel.itemContainsSmartFilters$"
        [tooltipText]="viewModel.smartFilterIndicatorTooltip$ | push">
      </app-smart-menu-indicator>
      <app-template-collection-indicator
        *rxIf="viewModel.templateCollectionIndicatorTooltip$"
        [collectionNames]="viewModel.templateCollectionIndicatorTooltip$ | push">
      </app-template-collection-indicator>
    </div>
  </div>
  <div class="item-info-container">
    <div class="item-info-name-container">
      <img *rxIf="viewModel.showTemplateIcon$" class="me-2" src="assets/icons/dark/solid/template.svg" alt="" />
      <div
        class="item-info-name text-truncate d-block"
        #container
        [ngbTooltip]="viewModel.itemName$ | push"
        triggers="hover"
        openDelay="300"
        [disableTooltip]="!(container.offsetWidth < container.scrollWidth)">
        {{ viewModel.itemName$ | push }}
      </div>
      <div class="item-time-container">
        <img
          [hidden]="!(viewModel.previewTime$ | push)"
          [ngbTooltip]="viewModel.previewTime$ | push"
          [container]="'body'"
          [placement]="'left'"
          alt=""
          class="preview-date-icon"
          placement="top"
          src="/assets/icons/dark/outline/calendar.svg" />
      </div>
    </div>
    <div *rxIf="(viewModel.itemSubItems$ | push)?.length > 0">
      <div class="menu-info-subheading ellipsis-nowrap-text">
        {{ viewModel.itemSubItemTitle$ | push }}
      </div>
      <ng-container *rxFor="let subItem of viewModel.itemSubItems$; trackBy: viewModel.trackBySubItemId">
        <div fxLayout="row" fxLayoutAlign=" center" [style.gap.rem]="0.3">
          <img *rxIf="subItem.iconSrc" [src]="subItem.iconSrc" [style.height.rem]="1" [style.width.rem]="1" />
          <div class="item-info-menus ellipsis-nowrap-text">
            {{ subItem.name }}
          </div>
        </div>
      </ng-container>
    </div>
    <div
      *rxIf="(displayableItem | isDisplay) && !(viewModel.itemSubItems$ | push)?.length"
      class="menu-info-subheading">
      There is nothing assigned to this display.
    </div>
  </div>
</div>
