import { Deserializable } from '../protocols/deserializable';
import { JobStatus, MenuPreviewJobStatus } from '../utils/dto/menu-preview-job-status-type';
import { MenuType } from '../utils/dto/menu-type-definition';
import { BulkJobSource } from '../utils/dto/bulk-job-source-type';
import { CardStackPrintConfig } from './card-stack-print-config';
import { MenuPreviewJob } from './menu-preview-job';
import { Selectable } from '../protocols/selectable';
import { Pagable } from '../protocols/pagable';
import { BulkPrintJobType } from './enum/bulk-print-job-type';
import { StackPrintType } from './enum/card-stack-print-type.enum';
import { DateUtils } from '../../utils/date-utils';
import { BulkPrintJobProperties } from '../protocols/bulk-print-job-properties';
import { SmartPrintUtils } from '../../utils/smart-print-utils';
import type { StackType } from '../../views/menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';

export class BulkPrintJob implements Deserializable, Selectable, Pagable, BulkPrintJobProperties {

  public locationId: number = null;
  public id: string = null;
  public name: string = '';
  public recipientIds: string[] = [];
  public menuIds: string[] = [];
  public templateIds: string[] = [];
  public deletedMenuNames: string[] = [];
  public companyId: number = null;
  public dateCreated: number = null;
  public dateStarted: number = null;
  public dateCompleted: number = null;
  public jobStatus: JobStatus = null;
  public menuType: MenuType = null;
  public cardStackPrintConfigMap: Map<string, CardStackPrintConfig> = new Map();
  public jobSource: BulkJobSource = null;
  public completedMenuIds: string[] = [];
  public completedTemplateIds: string[] = [];
  public downloadUrl: string = '';
  public previewJobs: MenuPreviewJob[] = [];

  onDeserialize() {
    this.recipientIds = Array.from(this.recipientIds || []);
    this.menuIds = Array.from(this.menuIds || []);
    this.completedMenuIds = Array.from(this.completedMenuIds || []);
    this.completedTemplateIds = Array.from(this.completedTemplateIds || []);
    this.deletedMenuNames = Array.from(this.deletedMenuNames || []);
    const Deserialize = window?.injector?.Deserialize;
    this.cardStackPrintConfigMap = Deserialize?.mapOf(CardStackPrintConfig, this.cardStackPrintConfigMap) || new Map();
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/MenuBulkJobDTO.go
  onSerialize() {
    const dto = Object.create(BulkPrintJob.prototype);
    dto.locationId = this.locationId;
    dto.id = this.id;
    dto.name = this.name;
    dto.recipientIds = this.recipientIds;
    dto.menuIds = this.menuIds;
    dto.templateIds = this.templateIds;
    dto.deletedMenuNames = this.deletedMenuNames;
    dto.companyId = this.companyId;
    dto.dateCreated = this.dateCreated;
    dto.dateStarted = this.dateStarted;
    dto.dateCompleted = this.dateCompleted;
    dto.jobStatus = this.jobStatus;
    dto.menuType = this.menuType;
    dto.cardStackPrintConfigMap = this.cardStackPrintConfigMap;
    dto.jobSource = this.jobSource;
    return dto;
  }

  setDataAsRetry(): void {
    this.id = null;
    this.name = this.name + ' (Retry)';
    this.downloadUrl = '';
    this.jobStatus = undefined;
    this.completedMenuIds = [];
    this.completedTemplateIds = [];
    this.dateCreated = undefined;
    this.dateStarted = undefined;
    this.dateCompleted = undefined;
  }

  /**
   * Only works when the print job represents a single menu or template.
   * Ex. When within Edit Card Stack Menu, the print jobs are filtered to the current card stack,
   * so this method will return the card stack menu id (handles regular menu or template).
   *
   * If used in the wrong context, then this method has a fail-safe to return null.
   * Meaning, if the print job represents multiple menus or templates, then this method will return null.
   */
  getTargetedMenuId(): string | null {
    const nMenus = this.menuIds?.length ?? 0;
    const nTemplates = this.templateIds?.length ?? 0;
    const representsSingleMenu = (nMenus + nTemplates) === 1;
    return representsSingleMenu
      ? (this.menuIds?.firstOrNull() || this.templateIds?.firstOrNull() || null)
      : null;
  }

  getBulkPrintJobType(): BulkPrintJobType {
    if (this.cardStackPrintConfigMap?.size > 0) {
      const config: CardStackPrintConfig = this.cardStackPrintConfigMap?.entries()?.next()?.value?.[1];
      const compareWith = config?.printType?.toLowerCase();
      switch (true) {
        case SmartPrintUtils.isLabelJob(compareWith):
          return BulkPrintJobType.BulkPrintJobType_Label;
        case SmartPrintUtils.isCardJob(compareWith):
          return BulkPrintJobType.BulkPrintJobType_Card;
        case SmartPrintUtils.isShelfTalkerJob(compareWith):
          return BulkPrintJobType.BulkPrintJobType_ShelfTalker;
      }
    }
    return BulkPrintJobType.BulkPrintJobType_Menu;
  }

  getCardStackPrintConfigFor(menuId: string): CardStackPrintConfig | null {
    const previewCardStackPrintConfig = this.previewJobs?.find(p => p.menuId === menuId)?.cardStackPrintConfig;
    const cardStackPrintConfigFromMap = this.cardStackPrintConfigMap?.get(menuId);
    return previewCardStackPrintConfig || cardStackPrintConfigFromMap || null;
  }

  getSelectionTitle(): string {
    return DateUtils.formatToDateTimeStringIfUnixStringElseOriginal(this.name);
  }

  getSelectionUniqueIdentifier(): string {
    return this.id;
  }

  getSelectionValue(): number {
    return this.dateCreated;
  }

  getStartKey(): string {
    return this.id;
  }

  getStatusToDisplay(): MenuPreviewJobStatus {
    let statusToDisplay = MenuPreviewJobStatus[this.jobStatus];
    const success = statusToDisplay === MenuPreviewJobStatus.MenuPreviewJobStatus_Success;
    const successButNoUrl = success && !this.downloadUrl;
    if (successButNoUrl) {
      // If downloadUrl is not set yet for successful job, keep the status as processing
      statusToDisplay = MenuPreviewJobStatus.MenuPreviewJobStatus_Processing;
    }
    return statusToDisplay;
  }

  getStackPrintType(): StackPrintType {
    const previewType = this.previewJobs?.firstOrNull()?.cardStackPrintConfig?.printType;
    const creationDataType = [...(this.cardStackPrintConfigMap?.values() || [])]?.firstOrNull()?.printType;
    return previewType || creationDataType;
  }

  whatTypeOfStackIsThis(): StackType {
    const compareWith = this.getStackPrintType()?.toLowerCase();
    switch (true) {
      case SmartPrintUtils.isShelfTalkerJob(compareWith): return 'shelf talker';
      case SmartPrintUtils.isLabelJob(compareWith):       return 'label';
      default:                                            return 'card';
    }
  }

  getStackPrintConfigMap(): Map<string, CardStackPrintConfig> {
    if (!this.previewJobs?.length) return this.cardStackPrintConfigMap;
    const cardStackPrintConfigMap = new Map();
    this.previewJobs?.forEach(p => cardStackPrintConfigMap.set(p.menuId, p.cardStackPrintConfig));
    return cardStackPrintConfigMap;
  }

  menuIncludedInPrintJob(menuId: string): boolean {
    return this.menuIds?.includes(menuId) || this.cardStackPrintConfigMap?.has(menuId);
  }

}
