import { Injectable, Injector, NgZone } from '@angular/core';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TemplateDomainModel } from '../../../../../../../domainModels/template-domain-model';
import { MenuDomainModel } from '../../../../../../../domainModels/menu-domain-model';
import { ProductDomainModel } from '../../../../../../../domainModels/product-domain-model';
import { CompanyDomainModel } from '../../../../../../../domainModels/company-domain-model';
import { LabelDomainModel } from '../../../../../../../domainModels/label-domain-model';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { DisplayAttributesDomainModel } from '../../../../../../../domainModels/display-attributes-domain-model';
import { UserDomainModel } from '../../../../../../../domainModels/user-domain-model';
import { SmartFiltersDomainModel } from '../../../../../../../domainModels/smart-filters-domain-model';
import { ToastService } from '../../../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditMenuSectionViewModel } from '../../../../../viewModels/edit-menu-section-view-model';
import { ModalShelfTalkerLiveView } from '../../../../../../../modals/modal-shelf-talker-live-view';
import { ModalEditStackStyling } from '../../../../../../../modals/modal-edit-stack-styling';
import type { Size } from '../../../../../../../models/shared/size';

@Injectable()
export class EditShelfTalkerBrandsSectionViewModel extends EditMenuSectionViewModel {

  constructor(
    templateDomainModel: TemplateDomainModel,
    menuDomainModel: MenuDomainModel,
    productDomainModel: ProductDomainModel,
    companyDomainModel: CompanyDomainModel,
    labelDomainModel: LabelDomainModel,
    locationDomainModel: LocationDomainModel,
    displayAttributeDomainModel: DisplayAttributesDomainModel,
    userDomainModel: UserDomainModel,
    smartFiltersDomainModel: SmartFiltersDomainModel,
    toastService: ToastService,
    router: Router,
    route: ActivatedRoute,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(
      templateDomainModel,
      menuDomainModel,
      productDomainModel,
      companyDomainModel,
      labelDomainModel,
      locationDomainModel,
      displayAttributeDomainModel,
      userDomainModel,
      smartFiltersDomainModel,
      toastService,
      router,
      route,
      ngZone,
      ngbModal,
      injector
    );
  }

  public override openLiveViewModal(sizeOverride?: Size) {
    combineLatest([
      this.menu$,
      this.section$
    ]).once(([menu, section]) => {
      ModalShelfTalkerLiveView.open(this.ngZone, this.ngbModal, this.injector, menu, section?.id);
    });
  }

  public supportsStackStyling$ = this.menu$.pipe(
    map(menu => {
      const themeFeatures = menu?.hydratedTheme?.themeFeatures;
      const themeSupportsCardOpacity = themeFeatures?.backgroundOpacity;
      const themeSupportsAccentColor = themeFeatures?.sectionHeaderBackgroundColor;
      const themeSupportsCardColor = themeFeatures?.sectionBodyBackgroundColor;
      const themeSupportsHeaderTextColor = themeFeatures?.sectionHeaderTextColor;
      const themeSupportsBodyTextColor = themeFeatures?.sectionBodyTextColor;
      return themeSupportsCardOpacity
          || themeSupportsAccentColor
          || themeSupportsCardColor
          || themeSupportsHeaderTextColor
          || themeSupportsBodyTextColor;
    })
  );

  public viewStackStyling(): void {
    combineLatest([
      this.menu$,
      this.section$
    ]).once(([menu, section]) => {
      ModalEditStackStyling.open(this.ngZone, this.ngbModal, this.injector, menu, section?.id);
    });
  }

  public showSectionImageAsset$ = combineLatest([
    this.menu$,
    this.menuThemes$
  ]).pipe(
    map(([menu, themes]) => themes?.find(th => th.id === menu?.theme)?.themeFeatures?.sectionImage),
    distinctUntilChanged()
  );

}
