import 'src/app/utils/array.extensions';
import 'src/app/utils/map.extensions';
import 'src/app/utils/number.extensions';
import 'src/app/utils/behavior-subject.extensions';
import 'src/app/utils/replay-subject.extensions';
import 'src/app/utils/observable.extensions';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './views/dashboard/components/dashboard/dashboard.component';
import { AuthModule } from './views/auth/auth.module';
import { SidebarNavComponent } from './views/dashboard/components/sidebar-nav/sidebar-nav.component';
import { TemplateModule } from './views/template/template.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AuthInterceptor } from './services/interceptors/auth.interceptor';
import { SharedModule } from './views/shared/shared.module';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from './services/strategy/cache-route-reuse.strategy';
import { SidebarSubItemListComponent } from './views/dashboard/components/sidebar-nav/sidebar-sub-item-list/sidebar-sub-item-list.component';
import { NavSubItemComponent } from './views/dashboard/components/sidebar-nav/nav-sub-item/nav-sub-item.component';
import { NavItemComponent } from './views/dashboard/components/sidebar-nav/nav-item/nav-item.component';
import { CurrentLocationNavItemComponent } from './views/dashboard/components/sidebar-nav/nav-item/current-location-nav-item/current-location-nav-item.component';
import { ProductsNavItemComponent } from './views/dashboard/components/sidebar-nav/nav-item/products-nav-item/products-nav-item.component';
import { SignOutNavItemComponent } from './views/dashboard/components/sidebar-nav/nav-item/sign-out-nav-item/sign-out-nav-item.component';
import { BannerEventsModule } from './modules/banner-events/banner-events.module';
import player from 'lottie-web';
import { LocationsSubNavItemComponent } from './views/dashboard/components/sidebar-nav/nav-sub-item/locations-sub-nav-item/locations-sub-nav-item.component';
import { IsProductsNavItemPipe } from './views/dashboard/components/sidebar-nav/nav-item/pipes/is-products-nav-item.pipe';
import { IsSignOutNavItemPipe } from './views/dashboard/components/sidebar-nav/nav-item/pipes/is-sign-out-nav-item.pipe';
import { IsDefaultNavItemPipe } from './views/dashboard/components/sidebar-nav/nav-item/pipes/is-base-nav-item.pipe';
import { IsLocationNavItemPipe } from './views/dashboard/components/sidebar-nav/nav-item/pipes/is-location-nav-item.pipe';
import { IsLocationsNavSubItemPipe } from './views/dashboard/components/sidebar-nav/nav-sub-item/pipes/is-locations-nav-sub-item.pipe';
import { IsDefaultNavSubItemPipe } from './views/dashboard/components/sidebar-nav/nav-sub-item/pipes/is-default-nav-sub-item.pipe';
import { IncompleteSubNavItemComponent } from './views/dashboard/components/sidebar-nav/nav-sub-item/incomplete-sub-nav-item/incomplete-sub-nav-item.component';
import { IsIncompleteNavSubItemPipe } from './views/dashboard/components/sidebar-nav/nav-sub-item/pipes/is-incomplete-nav-sub-item.pipe';
import { ProfileNavItemComponent } from './views/dashboard/components/sidebar-nav/nav-item/profile-nav-item/profile-nav-item.component';
import { IsProfileNavItemPipe } from './views/dashboard/components/sidebar-nav/nav-item/pipes/is-profile-nav-item.pip';
import { ApplicationSourceInterceptor } from './services/interceptors/application-source-interceptor';
import { ConfirmationModule } from './modules/confirmation/confirmation.module';
import { SmartLabelsModule } from './modules/product-labels/smart-labels/smart-labels.module';
import { CollectionModule } from './views/collection/collection.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { HelpNavItemComponent } from './views/dashboard/components/sidebar-nav/nav-item/help-nav-item/help-nav-item.component';
import { IsHelpNavItemPipe } from './views/dashboard/components/sidebar-nav/nav-item/pipes/is-help-nav-item.pip';
import { LiveViewModule } from './views/live-view/live-view.module';
import { RxIf } from '@rx-angular/template/if';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { LottieModule } from 'ngx-lottie';
import { PageBannerComponent } from './views/dashboard/components/page-banner/page-banner.component';
import { RxPush } from '@rx-angular/template/push';
import { RxLet } from '@rx-angular/template/let';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SidebarNavComponent,
    SidebarSubItemListComponent,
    NavSubItemComponent,
    NavItemComponent,
    CurrentLocationNavItemComponent,
    ProductsNavItemComponent,
    ProfileNavItemComponent,
    IsProductsNavItemPipe,
    IsLocationNavItemPipe,
    IsDefaultNavItemPipe,
    SignOutNavItemComponent,
    HelpNavItemComponent,
    IsSignOutNavItemPipe,
    LocationsSubNavItemComponent,
    IsLocationsNavSubItemPipe,
    IsDefaultNavSubItemPipe,
    IncompleteSubNavItemComponent,
    IsIncompleteNavSubItemPipe,
    IsProfileNavItemPipe,
    IsHelpNavItemPipe,
    PageBannerComponent
  ],
  imports: [
    // Do not change the order of AppRoutingModule. If you do, it will mess up the base route url redirect!
    AppRoutingModule,
    AuthModule,
    BannerEventsModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
    NgxSpinnerModule,
    ConfirmationModule,
    FlexLayoutModule,
    HttpClientModule,
    LottieModule.forRoot({player: playerFactory}),
    NgxPopperjsModule,
    SmartLabelsModule,
    TemplateModule,
    CollectionModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      maxOpened: 5,
      toastClass: 'bs-toast ngx-toastr'
    }),
    SharedModule,
    LiveViewModule,
    RxIf,
    RxPush,
    RxLet
  ],
  providers: [
    NgbActiveModal,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationSourceInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
