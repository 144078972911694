import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModel } from '../base-smart-filter-form-view-model';
import { map } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { combineLatest } from 'rxjs';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../domainModels/cannabinoids-and-terpenes-domain-model';

@Injectable()
export class TerpeneAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModel {

  constructor(
    public companyDomainModel: CompanyDomainModel,
    public cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel
  ) {
    super();
  }

  public enabledTerpenes$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpeneNames$;

  public terpeneDropdowns$ = combineLatest([
    this.enabledTerpenes$,
    window.types.terpenes$
  ]).pipe(
    map(([enabledTerpenes, terpenes]) => {
      return terpenes?.filter(t => enabledTerpenes?.includes(t?.getSelectionValue()));
    })
  );

  public terpenes$ = this.terpeneDropdowns$.pipe(
    map(terpeneTypes => terpeneTypes?.map(t => {
      const typeText = t?.getSelectionValue();
      const typeKey = typeText.toString()?.stripWhiteSpace();
      return {
        key: typeKey,
        text: typeText,
      };
    })),
  );

}
