import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { Injectable, Injector, NgZone } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import { BehaviorSubject, combineLatest, defer, Observable, of, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { LoadingOptions } from '../../../../../../models/shared/loading-options';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { LoadingSpinnerSize } from '../../../../../../models/enum/shared/loading-spinner-size.enum';
import { DateUtils } from '../../../../../../utils/date-utils';
import { DistinctUtils } from '../../../../../../utils/distinct-utils';
import { DropDownMenuSection } from '../../../../../../models/shared/stylesheet/drop-down-menu-section';
import { DisplayableAction } from '../../../../../../models/menu/enum/menu-action.enum';
import { Size } from '../../../../../../models/shared/size';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DISPLAYABLE_ITEM_PREVIEW_COUNT, DisplayableItem } from './displayable-item';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { Preview } from '../../../../../../models/shared/preview';
import { LocationChangedUtils } from '../../../../../../utils/location-changed-utils';
import { DisplayableSubItem } from '../../../../../../models/shared/displayable-subitem';

export const REFRESH_MENU_PREVIEW_THRESHOLD_MINUTES = environment.menuPreviewRefreshThreshold;
export const REFRESH_DISPLAY_PREVIEW_THRESHOLD_MINUTES = environment.displayRefreshThreshold;

type LoadingBundle = Map<string, BehaviorSubject<LoadingOptions>>;

@Injectable()
export abstract class DisplayableItemPreviewViewModel extends BaseViewModel {

  protected constructor(
    protected menuDomainModel: MenuDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected toastService: ToastService,
    protected navigationService: NavigationService,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector
  ) {
    super();
    this.clearPreviewsOnLocationChange();
    this.fetchItemPreviews();
    this.listenForPreviewChanges();
  }

  protected override _loadingOpts = new BehaviorSubject(this.getLoadingOpts());

  protected _previewLoadingBundle = new BehaviorSubject<LoadingBundle>(new Map());
  public previewLoadingBundle$ = defer(() => this._previewLoadingBundle);

  protected _refreshLoadingBundle = new BehaviorSubject<LoadingBundle>(new Map());
  public refreshLoadingBundle$ = defer(() => this._refreshLoadingBundle);

  public abstract dropDownMenuSections$: Observable<DropDownMenuSection[]>;

  protected readonly locationId$ = this.locationDomainModel.locationId$;

  protected _displayableItem = new BehaviorSubject<DisplayableItem>(null);
  public displayableItem$ = defer(() => this._displayableItem);
  public connectToDisplayableItem = (item: DisplayableItem) => this._displayableItem.next(item);

  public contentIds$ = combineLatest([
    this.locationId$,
    this.displayableItem$
  ]).pipe(
    map(([locationId, displayableItem]) => displayableItem?.displayableItemPreviewContentIds(locationId)),
    distinctUntilChanged(DistinctUtils.distinctJSON),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public hasContentIds$ = this.contentIds$.pipe(map(contentIds => contentIds?.length > 0));

  // See More Cards
  public showSeeMoreCard$ = this.displayableItem$.pipe(
    map(displayableItem => displayableItem?.displayableItemShouldShowSeeMoreCard())
  );
  public seeMoreItemCount$ = this.displayableItem$.pipe(
    map(displayableItem => displayableItem?.displayableItemTotalCount() - DISPLAYABLE_ITEM_PREVIEW_COUNT)
  );
  public seeMoreCardsText$ = this.seeMoreItemCount$.pipe(
    map(seeMoreItemCount => `+${seeMoreItemCount} More Product Card${seeMoreItemCount > 1 ? 's' : ''}`)
  );

  protected _itemPreviews: BehaviorSubject<Preview[]|null> = new BehaviorSubject<Preview[]|null>(null);
  public itemPreviews$: Observable<Preview[]|null> = this._itemPreviews.pipe(
    // Ensure the itemPreviews are unique (including locationId for templates)
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
  );

  protected _previewPlaceholderSrc = new BehaviorSubject<string>('');
  public previewPlaceholderSrc$ = this._previewPlaceholderSrc as Observable<string>;

  protected _activeSlideIndex = new BehaviorSubject<number>(0);
  public activeSlideIndex$ = this._activeSlideIndex as Observable<number>;
  public connectToActiveSlideIndex = (index: number) => this._activeSlideIndex.next(index);

  public readonly firstSlide$ = this.activeSlideIndex$.pipe(map(activeSlideIndex => activeSlideIndex === 0));

  public readonly lastSlide$ = combineLatest([
    this.activeSlideIndex$,
    this.contentIds$,
    this.showSeeMoreCard$,
  ]).pipe(
    map(([activeSlideIndex, contentIds, showSeeMoreCard]) => {
      const contentLength = contentIds?.length ?? 0;
      const lastIndex = (showSeeMoreCard ? contentLength + 1 : contentLength) - 1;
      return activeSlideIndex === lastIndex;
    }),
  );

  public showCarouselButtons$ = this.contentIds$.pipe(map(mps => mps?.length > 1));
  public showLiveViewButton$ = of(false);

  public abstract useLandscapeAspectRatio$: Observable<boolean>;
  public abstract usePortraitAspectRatio$: Observable<boolean>;

  public isRefreshing: boolean = false;
  public imgStyleOverrides$ = this.displayableItem$.pipe(
    map(displayItem => {
      return displayItem?.displayableItemContainsStackedContent()
        ? { 'object-position': 'center' }
        : { 'object-position': 'top' };
      })
  );

  private clearPreviewsOnLocationChange(): void {
    const locationChanged = () => {
      this._itemPreviews.next(null);
      this.connectToActiveSlideIndex(0);
    };
    LocationChangedUtils.onLocationChange(this, this.locationId$, locationChanged);
  }

  protected fetchItemPreviews(): void {
    combineLatest([
      this.displayableItem$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)),
      this.locationId$.notNull()
    ]).subscribeWhileAlive({
      owner: this,
      next: ([itemRef, locId]) => {
        combineLatest([
          this.previewLoadingBundle$,
          this.refreshLoadingBundle$
        ]).once(([previewBundle, refreshBundle]) => {
          const contentIds = itemRef?.displayableItemPreviewContentIds(locId);
          this.initializePreviewLoadingOptions(previewBundle, contentIds);
          this.initializeRefreshPreviewLoadingOptions(refreshBundle, contentIds);
          this.loadItemPreviews(itemRef, locId);
        });
      }
    });
  }

  public previewTime$ = this.itemPreviews$.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    map(p => {
      const firstPreview = p?.firstOrNull()?.preview;
      return (!!firstPreview ? `Preview from ${DateUtils.formatUnixToDateTime(firstPreview?.timestamp)}` : null);
    })
  );

  protected dPipe = <T>(op: OperatorFunction<DisplayableItem, T>) => this.displayableItem$.pipe(op);

  public itemName$ = this.dPipe(map(item => item?.name));
  public showTemplateIcon$: Observable<boolean> = of(false);

  // Badges

  public smartFilterIndicatorTooltip$ = this.dPipe(map(i => i?.displayableItemSmartFilterIndicatorTooltip()));
  public showDeployedCountIndicator$ = this.dPipe(map(i => i?.displayableItemShowDeployedCountIndicator()));
  public itemContainsSmartFilters$ = this.dPipe(map(i => i?.displayableItemHasSmartFilters()));
  public showActiveBadge$ = this.dPipe(map(i => i?.displayableItemShowActiveBadge()));
  public templateCollectionIndicatorTooltip$: Observable<string> = of(null);

  // Sub-Items

  public itemSubItemTitle$ =  this.dPipe(map(item => item?.displayableItemSubtitle()));
  public itemSubItems$ = this.dPipe(map(item => item?.displayableItemSubItemList()));

  private getLoadingOpts(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.showLoadingText = false;
    opts.isLoading = false;
    opts.cornerRadiusRem = 0.625;
    return opts;
  }

  protected getPreviewImageLoadingOpts(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.showLoadingText = false;
    opts.isLoading = false;
    opts.cornerRadiusRem = 0.625;
    return opts;
  }

  private getRefreshPreviewLoadingOpts(): LoadingOptions {
    const opts = LoadingOptions.default();
    opts.showLoadingText = false;
    opts.isLoading = false;
    opts.cornerRadiusRem = 2;
    opts.spinnerSize = LoadingSpinnerSize.Small;
    return opts;
  }

  protected initializePreviewLoadingOptions(
    loadingBundle: Map<string, BehaviorSubject<LoadingOptions>>,
    ids: (string | string[])[],
  ) {
    const updatedLoadingOptions = loadingBundle?.shallowCopy() ?? new Map<string, BehaviorSubject<LoadingOptions>>();
    ids?.forEach(idBundle => {
      const key = Array.isArray(idBundle) ? idBundle?.firstOrNull() : idBundle;
      if (!updatedLoadingOptions?.has(key)) {
        updatedLoadingOptions.set(key, new BehaviorSubject(this.getPreviewImageLoadingOpts()));
      }
    });
    this._previewLoadingBundle.next(updatedLoadingOptions);
  }

  protected initializeRefreshPreviewLoadingOptions(
    loadingBundle: Map<string, BehaviorSubject<LoadingOptions>>,
    ids: (string | string[])[],
  ) {
    const updatedLoadingOptions = loadingBundle?.shallowCopy() ?? new Map<string, BehaviorSubject<LoadingOptions>>();
    ids?.forEach(idBundle => {
      const key = Array.isArray(idBundle) ? idBundle?.firstOrNull() : idBundle;
      if (!updatedLoadingOptions?.has(key)) {
        updatedLoadingOptions.set(key, new BehaviorSubject(this.getRefreshPreviewLoadingOpts()));
      }
    });
    this._refreshLoadingBundle.next(updatedLoadingOptions);
  }

  protected abstract loadItemPreviews(item: DisplayableItem, locId: number);

  protected abstract listenForPreviewChanges(): void;

  protected abstract refreshItemPreviewInBackground(item: DisplayableItem);

  protected abstract deleteItem(item: DisplayableItem): void;

  public abstract openEditItem(): void;

  // Handle Actions

  handleDropdownSelection(ma: DisplayableAction) {
    switch (ma) {
      case DisplayableAction.Edit:
        this.openEditItem();
        break;
      case DisplayableAction.LiveView:
        this.openLiveViewModal();
        break;
      case DisplayableAction.Duplicate:
        this.openDuplicateItemModal();
        break;
      case DisplayableAction.Delete:
        this.openDeleteItemModal();
        break;
      case DisplayableAction.CopyURL:
        this.copyItemURL();
        break;
    }
  }

  public abstract openDeleteItemModal();

  public abstract openDuplicateItemModal();

  abstract openLiveViewModal(sizeOverride?: Size);

  public abstract copyItemURL();

  public trackByContentId(index: number, id: string): string {
    return id;
  }

  public trackBySubItemId(index: number, subItem: DisplayableSubItem): string {
    return subItem?.id;
  }

}
