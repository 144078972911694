import { Size } from '../../shared/size';
import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Orientation } from '../../utils/dto/orientation-type';
import { DefaultDigitalSize } from '../../utils/dto/default-digital-size-type';
import { SizeUnit } from '../../utils/dto/size-unit-type';

export class PendingDisplay implements Deserializable, UniquelyIdentifiable {

  public displaySize: Size;
  public locationIds: number[];
  public name: string;

  constructor() {
    this.name = '';
    this.locationIds = [];
    this.displaySize = new Size();
  }

  onDeserialize() {
    this.displaySize = window?.injector?.Deserialize?.instanceOf(Size, this.displaySize);
    this.locationIds = Array.from(this.locationIds || []);
  }

  public addAdditionalInfoNeededForDistinctUntilChanged(): void {
    this.addHeightAndWidthBasedOnOrientationAndName();
    this.addUnitSize();
  }

  private addHeightAndWidthBasedOnOrientationAndName(): void {
    if (!this.displaySize) return;
    const { height, width } = this.getHeightAndWidthFromOrientationAndSize(this);
    this.displaySize.height = height;
    this.displaySize.width = width;
  }

  private getHeightAndWidthFromOrientationAndSize(pendingDisplay: PendingDisplay): { height: number, width: number } {
    switch (pendingDisplay?.displaySize?.orientation) {
      case Orientation.Portrait:
      case Orientation.ReversePortrait:
        return this.getPortraitSizesFromName(pendingDisplay);
      case Orientation.Landscape:
        return this.getLandscapeSizesFromName(pendingDisplay);
      default:
        return { height: 0, width: 0 };
    }
  }

  private getPortraitSizesFromName(pendingDisplay: PendingDisplay): { height: number, width: number } {
    switch (pendingDisplay?.displaySize?.name) {
      case DefaultDigitalSize.Digital720p:
        return { height: 1280, width: 720 };
      case DefaultDigitalSize.Digital1080p:
        return { height: 1920, width: 1080 };
      case DefaultDigitalSize.Digital4k:
        return { height: 3840, width: 2160 };
      case DefaultDigitalSize.Digital8k:
        return { height: 7680, width: 4320 };
      default:
        return { height: 0, width: 0 };
    }
  }

  private getLandscapeSizesFromName(pendingDisplay: PendingDisplay): { height: number, width: number } {
    switch (pendingDisplay?.displaySize?.name) {
      case DefaultDigitalSize.Digital720p:
        return { height: 720, width: 1280 };
      case DefaultDigitalSize.Digital1080p:
        return { height: 1080, width: 1920 };
      case DefaultDigitalSize.Digital4k:
        return { height: 2160, width: 3840 };
      case DefaultDigitalSize.Digital8k:
        return { height: 4320, width: 7680 };
      default:
        return { height: 0, width: 0 };
    }
  }

  private addUnitSize(): void {
    if (!this.displaySize) return;
    this.displaySize.unit = SizeUnit.Digital;
  }

  getUniqueIdentifier(): string {
    return `
      ${this.displaySize?.getUniqueIdentifier()}-
      ${this.locationIds.join(',')}-
      ${this.name}
    `;
  }

}
