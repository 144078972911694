<hr class="no-margin mb-16px" />
<div class="all-products-table-container">
  <app-horizontal-scrolling-reactive-table
    [data]="products"
    [nItemsToDisplay]="nItemsToDisplay"
    [nPagesInsidePaginationIndicator]="7"
    [resetPageOnNewData]="resetPageOnNewData"
    [resetPageOnSortingChange]="resetPageOnSortingChange">
    <app-all-products-table-header></app-all-products-table-header>
    <app-all-products-table-item></app-all-products-table-item>
    <app-all-products-table-empty-state></app-all-products-table-empty-state>
    <app-table-loading></app-table-loading>
    <lib-reactive-table-paginator></lib-reactive-table-paginator>
  </app-horizontal-scrolling-reactive-table>
</div>
