import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, NgZone, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SectionMediaViewModel } from './section-media-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../services/toast-service';
import { HydratedSection } from '../../../../../models/menu/dto/hydrated-section';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Asset } from '../../../../../models/image/dto/asset';
import { Menu } from '../../../../../models/menu/dto/menu';
import { EditMarketingMenuViewModel } from '../../../viewModels/edit-marketing-menu-view-model';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../../../modals/modal-confirmation';
import { combineLatest } from 'rxjs';
import { UploadImageInterface } from '../../../../shared/components/upload-asset/upload-image-interface';
import { BudsenseFile } from '../../../../../models/shared/budsense-file';

@Component({
  selector: 'app-section-media',
  templateUrl: './section-media.component.html',
  styleUrls: ['./section-media.component.scss'],
  providers: [SectionMediaViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionMediaComponent extends BaseComponent implements OnChanges, UploadImageInterface {

  constructor(
    public viewModel: SectionMediaViewModel,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected toastService: ToastService,
  ) {
    super();
  }

  @Input() sharedViewModel: EditMarketingMenuViewModel;
  @Input() mergeKey: string;
  @Input() isDisabledByTemplates: boolean = false;
  @Input() file: Asset;
  @Input() menu: Menu;
  @Input() section: HydratedSection;
  @Input() index: number;
  @Input() bottomDivider: boolean = true;
  @Input() destructiveButtonText: string;
  @Input() formPristine: boolean = true;
  @Output() replaceMedia: EventEmitter<BudsenseFile[]> = new EventEmitter<BudsenseFile[]>();
  @Output() deleteMedia: EventEmitter<Asset> = new EventEmitter<Asset>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sharedViewModel) this.viewModel.initSharedViewModel(this.sharedViewModel);
    if (changes.isDisabledByTemplates) this.viewModel.connectToIsDisabledByTemplates(this.isDisabledByTemplates);
    if (changes.file) this.viewModel.connectToFile(this.file);
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
    if (changes.section) this.viewModel.connectToSection(this.section);
  }

  refreshMedia() {
    this.viewModel.refreshImage();
  }

  openRemoveMediaDialog() {
    combineLatest([
      this.viewModel.section$,
      this.viewModel.file$
    ]).once(([section, file]) => {
      const opts = new ConfirmationOptions();
      opts.title = 'Delete Media';
      opts.bodyText = `Are you sure you want to remove ${file?.fileName} from product group ${section?.title}?`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Remove Media';
      const confirmation = (cont) => {
        if (cont) {
          this.deleteMedia.next(this.file);
        }
      };
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
    });
  }

  listenForDuration(dur: number) {
    this.viewModel.setMediaDuration(dur);
  }

  fileList(f: BudsenseFile[], id?: number) {
    if (!!f && f?.length === 1) {
      this.replaceMedia.emit(f);
    } else if (f.length > 1) {
      this.toastService.publishErrorMessage('Only 1 file can be uploaded for each product group.', 'Too many files!');
    }
  }

}
