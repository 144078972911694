import { WebSocketReceiveMessage } from './web-socket-receive-message';

export class WebSocketReceiveData extends WebSocketReceiveMessage {

  /**
   * Only used on request messages. This is the ID of the request that was sent to the server.
   */
  requestId: string;

  getRequestId(): string {
    return this.requestId;
  }

}
