import { Component } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { EditVariantCannabinoidsViewModel } from './edit-variant-cannabinoids-view-model';
import { NavigationService } from '../../../../../../../services/navigation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CannabinoidsPreviewViewModel } from './cannabinoids-preview/cannabinoids-preview-view-model';
import { PrimaryCannabinoid } from '../../../../../../../models/enum/shared/primary-cannabinoid.enum';

@Component({
  selector: 'app-edit-variant-cannabinoids',
  templateUrl: './edit-variant-cannabinoids.component.html',
  styleUrls: ['./edit-variant-cannabinoids.component.scss'],
  providers: [EditVariantCannabinoidsViewModel, CannabinoidsPreviewViewModel]
})
export class EditVariantCannabinoidsComponent extends BaseComponent implements Tabbable {

  constructor(
    public viewModel: EditVariantCannabinoidsViewModel,
    public navigationService: NavigationService,
    private activeModal: NgbActiveModal
  ) {
    super();
  }

  tabActivated: BehaviorSubject<boolean>;

  editProductSettings() {
    this.navigationService.manageProducts();
    this.activeModal.close();
  }

  protected readonly PrimaryCannabinoid = PrimaryCannabinoid;

}
