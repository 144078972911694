import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReferAFriendModalComponent } from '../views/shared/components/refer-a-friend-widget/refer-a-friend-modal/refer-a-friend-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector, NgZone } from '@angular/core';

export class ModalReferAFriend {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
  ): void {
    ngZone.run(() => {
      ngbModal.open(ReferAFriendModalComponent, ModalUtils.defaultModalOptions(injector));
    });
  }

}
