<div class="left-pane">
  <app-print-job-name-form
    [style.top]="0"
    [style.position]="'sticky'"
    [style.z-index]="2"
    [dispersedKey]="viewModel.mergeKey$ | push"
    [mergeKey]="viewModel.mergeKey$ | push"
    [job]="viewModel.job$ | push"
    [label]="'Stack Print Name'"
    [placeholder]="viewModel.placeholder$ | push"
    [viewOnly]="viewModel.viewOnly$ | push">
  </app-print-job-name-form>
  <hr class="mt-0 mb-24px" />
  <app-print-job-employee-form
    [job]="viewModel.job$ | push"
    [mergeKey]="viewModel.mergeKey$ | push"
    [viewOnly]="viewModel.viewOnly$ | push">
  </app-print-job-employee-form>
  <app-stack-manual-bulk-print-job-menu-list
    [style.margin-top.rem]="1.5"
    [type]="viewModel.typeOfStack$ | push"
    [stackMenus]="viewModel.selectedStackMenus$ | push"
    [selectedItemsMap]="viewModel.selectedItemsMap$ | push"
    (idSelected)="viewModel.connectToSelectedStackMenuId($event)">
  </app-stack-manual-bulk-print-job-menu-list>
</div>
