import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { exists } from '../../../functions/exists';
import { BaseComponent } from '../../../models/base/base-component';
import { CardLiveViewViewModel } from './card-live-view-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({ template: '' })
export abstract class CardLiveViewComponent extends BaseComponent {

  constructor(
    public viewModel: CardLiveViewViewModel
  ) {
    super();
  }

  @Input() fitIntoContainer: HTMLElement;
  @Input() @ViewModelConnector('connectToUserSetScale') userSetScale: number;
  @Output() cardHeight = new EventEmitter<number>(true);
  @Output() cardWidth = new EventEmitter<number>(true);
  @Output() smallestScaleValue = new EventEmitter<number>(true);
  @Output() scale = new EventEmitter<number>(true);
  @Output() iFrameLoaded = new EventEmitter<boolean>(true);
  @ViewChild('iFrame') iFrame: ElementRef<HTMLIFrameElement>;
  private parentContainerResizeObserver: ResizeObserver;

  override setupViews(): void {
    this.sendCardHeightOutside();
    this.sendCardWidthOutside();
    this.sendScaleToOutside();
    this.sendSmallestScaleValueOutside();
    this.waitToStartTalking();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (exists(changes.fitIntoContainer)) {
      this.listenToParentContainer();
    }
  }

  private sendCardHeightOutside(): void {
    this.viewModel.cardHeight$.subscribeWhileAlive({
      owner: this,
      next: (cardHeight) => this.cardHeight.emit(cardHeight)
    });
  }

  private sendCardWidthOutside(): void {
    this.viewModel.cardWidth$.subscribeWhileAlive({
      owner: this,
      next: (cardWidth) => this.cardWidth.emit(cardWidth)
    });
  }

  private sendScaleToOutside(): void {
    this.viewModel.scale$.subscribeWhileAlive({
      owner: this,
      next: (scale) => this.scale.emit(scale)
    });
  }

  private sendSmallestScaleValueOutside(): void {
    this.viewModel.scaleToParentView$.subscribeWhileAlive({
      owner: this,
      next: (smallestScaleValue) => this.smallestScaleValue.emit(smallestScaleValue)
    });
  }

  private listenToParentContainer(): void {
    this.parentContainerResizeObserver?.disconnect();
    if (!this.fitIntoContainer) return;
    this.parentContainerResizeObserver = new ResizeObserver(() => {
      this.viewModel.connectToParentContainerWidth(this.fitIntoContainer?.clientWidth);
      this.viewModel.connectToParentContainerHeight(this.fitIntoContainer?.clientHeight);
    });
    this.parentContainerResizeObserver.observe(this.fitIntoContainer);
  }

  private waitToStartTalking(): void {
    this.viewModel.startTalking$.subscribeWhileAlive({
      owner: this,
      next: () => this.loaded()
    });
  }

  private loaded(): void {
    this.viewModel.sendDataToIFrame(this.iFrame.nativeElement);
    this.iFrameLoaded.emit(true);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.parentContainerResizeObserver?.disconnect();
  }

}
