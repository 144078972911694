import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { exists } from '../../../functions/exists';
import { TaxRateProperties } from '../../protocols/tax-rate-properties';

export enum TaxAmountType {
  Percent = 'percent',
  FlatRate = 'flatRate'
}

export class TaxRate implements Deserializable, UniquelyIdentifiable, TaxRateProperties {

  constructor(
    name?: string,
    percent?: number,
    flatRateAmount?: number,
    isCompanyRate?: boolean,
    taxAmount?: number,
    taxTypeSelected?: TaxAmountType
  ) {
    if (exists(name)) this.name = name;
    if (Number.isFinite(percent)) this.percent = percent;
    if (Number.isFinite(flatRateAmount)) this.flatRateAmount = flatRateAmount;
    if (exists(isCompanyRate)) this.isCompanyRate = isCompanyRate;
    if (Number.isFinite(taxAmount)) this.taxAmount = taxAmount;
    if (exists(taxTypeSelected)) this.taxTypeSelected = taxTypeSelected;
  }

  public locationId: number;
  public id: string;
  public name: string;
  public percent: number;
  public flatRateAmount: number;
  public layer: number;
  public appliedTo: string = 'SoldAtPrice';
  public isCustomTax: boolean = true;
  public isCompanyRate: boolean; // frontend only when creating tax rates, does not exist in the backend
  public taxTypeSelected: TaxAmountType;
  public taxAmount: number;
  public activeLocationIds: number[];

  onDeserialize() {
  }

  isCompanyTaxRate(companyId: number): boolean {
    return this.locationId === companyId;
  }

  getUniqueIdentifier(): string {
    return JSON.stringify(this);
  }

}
