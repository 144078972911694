import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { DatatableProductFilter } from '../models/product/table/datatable-product-filter';
import { ModalUtils } from '../utils/modal-utils';
import { ProductPickerModalOptions } from '../models/product/shared/product-picker-modal-options';
import { ProductPickerModalComponent } from '../views/product/components/modals/product-picker-modal/product-picker-modal.component';
import { OverrideProductGroup } from '../models/product/dto/override-product-group';

export class ModalAddProductsToProductGrouping {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    productGrouping: OverrideProductGroup,
    showSuccessToast: boolean,
    continueOperation: (productIds: string[]) => Observable<boolean>,
    resultOperation?: (customFilter: DatatableProductFilter) => void,
  ) {
    ngZone.run(() => {
      const modalRef = ngbModal.open(ProductPickerModalComponent, ModalUtils.productPickerModalOptions(injector));
      const compInstance = modalRef.componentInstance as ProductPickerModalComponent;
      compInstance.viewModel.initAsIgnoreVariants.next(false);
      compInstance.options = ProductPickerModalOptions.productGroupingPickerModalOptions(showSuccessToast);
      compInstance.setupModalForProductGrouping(productGrouping);
      compInstance.setupModalForHideOutOfStockProducts(false);
      compInstance.continueOperation = continueOperation;
      modalRef.result.then((customFilter: DatatableProductFilter) => resultOperation?.(customFilter)).catch(() => {});
    });
  }

}
