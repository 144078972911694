<lib-reactive-form-column-layout nColumns="2">
  <lib-reactive-form-number
    (valueChanged)="viewModel.connectToMinTerpeneValue($event)"
    [inputName]="'min' + terpene.key"
    [label]="'Minimum ' + terpene.text"
    [placeholder]="'Minimum ' + terpene.text"
    [inlineLabel]="false"
    [required]="false"
    [disabled]="isViewOnly"
    [integersOnly]="false"
    [step]="0.01"
    [tooltip]="
      'The smart filter will include products with ' + terpene.text + ' values greater than or equal to this value.'
    "
    [minValue]="0"
    [maxValue]="viewModel.minTerpeneMaxValue$ | push"
    [addToAtLeastNItemsFilledInPool]="true"
    [bindingProperty]="'min' + terpene.key"
    [dispersedKey]="dispersedKey">
  </lib-reactive-form-number>

  <lib-reactive-form-number
    (valueChanged)="viewModel.connectToMaxTerpeneValue($event)"
    [inputName]="'max' + terpene.key"
    [label]="'Maximum ' + terpene.text"
    [placeholder]="'Maximum ' + terpene.text"
    [inlineLabel]="false"
    [required]="false"
    [disabled]="isViewOnly"
    [integersOnly]="false"
    [step]="0.01"
    [tooltip]="
      'The smart filter will include products with ' + terpene.text + ' values less than or equal to this value.'
    "
    [minValue]="viewModel.maxTerpeneMinValue$ | push"
    [addToAtLeastNItemsFilledInPool]="true"
    [bindingProperty]="'max' + terpene.key"
    [dispersedKey]="dispersedKey">
  </lib-reactive-form-number>
</lib-reactive-form-column-layout>
