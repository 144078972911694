import * as uuid from 'uuid';
import { WebSocketSendMessage } from './web-socket-send-message';
import { StringUtils } from '../../../../utils/string-utils';
import { WebSocketPayloadActionData } from '../../actions/web-socket-payload-action-data';

export class WebSocketSendRequestDataMessage extends WebSocketSendMessage {

  constructor(entityId: number) {
    super();
    const requestId = uuid.v4();
    this.entityId = entityId;
    this.action = WebSocketPayloadActionData.REQUEST;
    this.payload = { requestId };
  }

  getPayloadRequestId(): string {
    return this.payload.requestId;
  }

  whatIsThisRequestFor(): string {
    const type = this.payload?.dataType?.split('_')?.last();
    const stripped = StringUtils.splitPascalCasePreserveAcronyms(type)?.replace('Get', '');
    return StringUtils.lowercaseFirstLetterOfEachWord(stripped);
  }

}
