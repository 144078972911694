import { MenuPreview } from './menu-preview';

export class CardPreview extends MenuPreview {

  itemIds: string[];

  public override onDeserialize() {
    super.onDeserialize();
    this.itemIds = Array.from(this.itemIds || []);
  }

}
