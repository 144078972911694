import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { TemplateCollection } from '../models/template/dto/template-collection';
import { ConfirmationOptions } from '../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from './modal-confirmation';

export class ModalPromptForCollectionDelete {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    collection: TemplateCollection,
    confirmation: (cont: boolean) => void
  ): void {
    ngZone.run(() => {
      const opts = new ConfirmationOptions();
      opts.title = 'Delete Template Collection';
      opts.bodyText = `Are you sure you want to delete the following template collection: \'${collection?.name}\'? `
        + `This action cannot be undone.\n\nDeleting a template collection will automatically remove it, and all `
        + 'associated templates from all displays at all locations. This action will affect what customers see on the '
        + 'TVs in your stores.';
      opts.cancelText = 'Cancel';
      opts.continueText = 'Delete';
      ModalConfirmation.open(ngbModal, injector, opts, confirmation);
    });
  }

}
