import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';
import { PriceFormat } from '../../../models/enum/dto/price-format';

@Pipe({
  name: 'variantPrice'
})
export class VariantPricePipe implements PipeTransform {

  transform(
    locationId: number,
    variant: Variant,
    priceFormat: PriceFormat,
    hideSale: boolean
  ): number|null {
    if (locationId > -1) {
      return variant?.getVisiblePrice(locationId, null, priceFormat, hideSale);
    } else {
      return null;
    }
  }

}
