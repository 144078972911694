import { Deserializable } from '../../protocols/deserializable';
import { Cachable } from '../../protocols/cachable';
import { PromotionPeriod } from '../../utils/dto/promotion-period-type';
import { TimeDuration } from '../../shared/time-duration';
import { LocalizedDateRange } from '../../shared/localized-date-range';
import { PromotionCondition } from '../../utils/dto/promotion-condition-type';
import { PromotionDiscount } from '../../utils/dto/promotion-discount-type';
import { DateUtils } from '../../../utils/date-utils';
import { exists } from '../../../functions/exists';
import { PriceUtils } from '../../../utils/price-utils';
import * as moment from 'moment/moment';
import { LocationPromotionProperties } from '../../protocols/location-promotion-properties';

export class LocationPromotion implements Deserializable, Cachable, LocationPromotionProperties {

  // DTO
  public locationId: number;
  public id: string;
  public discountId: string;
  // isCompanyPromo is used as a flag to determine if a promotion in a Cova account has been applied to the
  // entire company, hence inheriting to every location. This functionality is just a shortcut within the POS
  // to apply a promotion to all locations. Therefore, a promotion with "isCompanyPromo === true" is still applied
  // at the location level.
  public isCompanyPromo: boolean;
  public name: string;
  public period: PromotionPeriod;
  public recurringDuration: TimeDuration;
  public effectiveDateRange: LocalizedDateRange;
  public dateRanges: LocalizedDateRange[];
  public condition: PromotionCondition;
  public discount: PromotionDiscount;
  public dollarDiscount: number;
  public percentageDiscount: number;
  public fixedPriceDiscount: number;
  // Cache
  public cachedTime: number;

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.recurringDuration = Deserialize?.instanceOf(TimeDuration, this.recurringDuration);
    this.effectiveDateRange = Deserialize?.instanceOf(LocalizedDateRange, this.effectiveDateRange);
    this.dateRanges = Deserialize?.arrayOf(LocalizedDateRange, this.dateRanges);
  }

  static buildArrayCacheKey(locationId: number): string {
    return `Promotions-${locationId}`;
  }

  static buildCacheKey(id: string): string {
    return `Promotion-${id}`;
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(): string {
    return LocationPromotion.buildCacheKey(this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  private startInfinite(): boolean {
    return this.effectiveDateRange?.startDate === 0;
  }

  private endInfinite(): boolean {
    return this.effectiveDateRange?.endDate === 9999999999;
  }

  public getDateRanges(): LocalizedDateRange[] {
    if (exists(this.dateRanges) && this.dateRanges.length > 0) {
      return this.dateRanges;
    } else if (!this.endInfinite() && !this.startInfinite()) {
      return [this.effectiveDateRange];
    } else {
      return [];
    }
  }

  protected hasDateRanges(): boolean {
    return exists(this.dateRanges?.length);
  }

  protected isWithinDateRanges(now: number): boolean {
    return this.dateRanges?.some(dr => now >= (dr?.startDate ?? 0) && now <= (dr?.endDate ?? 0)) || false;
  }

  isActive(): boolean {
    const validDiscountAmount = [this.dollarDiscount, this.percentageDiscount, this.fixedPriceDiscount].some(exists);
    if (!validDiscountAmount) return false;
    if (this.period === PromotionPeriod.Definite) {
      return this.hasDateRanges()
        ? this.isWithinDateRanges(DateUtils.nowInUnixSeconds())
        : this.effectiveDateRange?.isWithinUnixDateTimeWindow(DateUtils.nowInUnixSeconds());
    } else {
      const validDate = this.effectiveDateRange?.isWithinUnixDateTimeWindow(DateUtils.nowInUnixSeconds());
      const validDuration = this.recurringDuration?.isActive();
      return validDate && validDuration;
    }
  }

  isContinuousDates(): boolean {
    if (this.period !== PromotionPeriod.Definite) {
      return false;
    }
    if (exists(this.dateRanges) && this.dateRanges.length > 0) {
      return this.dateRanges?.some(dateRange => {
        const startDate = moment.unix(dateRange.startDate).local();
        const endDate = moment.unix(dateRange.endDate).local();
        return !startDate.isSame(endDate, 'day');
      });
    } else {
      // No date ranges specified, so it's continuous
      return true;
    }
  }

  getPromotionPeriodString(): string {
    if (this.period === PromotionPeriod.Recurrent) {
      return `Recurring`;
    } else if (this.isContinuousDates()) {
      if (this.startInfinite() && this.endInfinite()) {
        return 'Ongoing';
      } else {
        return 'Continuous Dates';
      }
    } else {
      return 'Specific Dates';
    }
  }

  getPromotionDateDescriptionLines(): string[] {
    if (this.period === PromotionPeriod.Recurrent) {
      const daysOfWeek = this.recurringDuration.days?.map(wd => DateUtils.weekDayToShorthandString(wd));
      const dateRange = this.getEffectiveDateRangeString();
      const recurringPeriodComponents = [];
      if (!!dateRange) {
        recurringPeriodComponents.push(dateRange);
      }
      if (!!daysOfWeek && daysOfWeek.length > 0) {
        recurringPeriodComponents.push(`Occurs on ${daysOfWeek}`);
      }
      return ['Recurring:', ...recurringPeriodComponents];
    } else if (this.isContinuousDates() && exists(this.dateRanges) && this.dateRanges.length > 0) {
      const dateRanges = this.dateRanges?.map(dr => {
        return DateUtils.formatUnixRangeToShorthandDateWithYear(dr.startDate, dr.endDate);
      });
      return ['Continuous Dates:', ...dateRanges];
    } else if (exists(this.dateRanges) && this.dateRanges.length > 0) {
      const dates = this.dateRanges?.map(dr => {
        return DateUtils.formatUnixRangeToShorthandDateWithYear(dr.startDate, dr.endDate);
      });
      return ['Specific Dates:', ...dates];
    } else {
      // Recurrent promotion with effective date range, but not subsets of date ranges
      return [this.getEffectiveDateRangeString()];
    }
  }

  getEffectiveDateRangeString(): string {
    if (!this.effectiveDateRange && !this.effectiveDateRange?.startDate && !this.effectiveDateRange?.endDate) {
      return null;
    }
    if (this.startInfinite() && this.endInfinite()) {
      return `Ongoing`;
    } else if (!this.effectiveDateRange.startDate || this.startInfinite()) {
      return `Until ${DateUtils.formatUnixToShorthandDateWithYear(this.effectiveDateRange.endDate)}`;
    } else if (!this.effectiveDateRange.endDate || this.endInfinite()) {
      return `From ${DateUtils.formatUnixToShorthandDateWithYear(this.effectiveDateRange.startDate)}`;
    } else {
      return DateUtils.formatUnixRangeToShorthandDateWithYear(
        this.effectiveDateRange.startDate,
        this.effectiveDateRange.endDate
      );
    }
  }

  getDiscountTypeString(): string {
    switch (this.discount) {
      case PromotionDiscount.Dollar:
        return 'Dollar Amount Off';
      case PromotionDiscount.Percentage:
        return 'Percentage Amount Off';
      case PromotionDiscount.FixedPrice:
        return 'Price To Amount'; // consistent with POS terminology
    }
  }

  getDiscountAmountString(): string {
    switch (this.discount) {
      case PromotionDiscount.Dollar:
        return PriceUtils.formatPrice(this.dollarDiscount);
      case PromotionDiscount.Percentage:
        return `${this.percentageDiscount}%`;
      case PromotionDiscount.FixedPrice:
        return `Fixed Price: ${PriceUtils.formatPrice(this.fixedPriceDiscount)}`;
    }
  }

  /**
   * Returns the discount amount for the given price, else null
   */
  applyPromotionDiscountOrNull(priceBeforeDiscount: number): number | null {
    if (!this.isActive() || !priceBeforeDiscount) return null;
    const round = PriceUtils.roundPrice;
    switch (true) {
      case exists(this.dollarDiscount):     return round(Math.max(priceBeforeDiscount - this.dollarDiscount, 0));
      case exists(this.percentageDiscount): return round(priceBeforeDiscount * ((100 - this.percentageDiscount) / 100));
      case exists(this.fixedPriceDiscount): return round(Math.max(this.fixedPriceDiscount, 0));
      default:                              return null;
    }
  }

}
