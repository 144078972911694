<div class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">Section Colors</p>
    <p class="bs-edit-section-description"></p>
  </div>

  <div class="col-sm-8 col-md-8 col-lg-8">
    <lib-reactive-form-group
      [mergeKey]="mergeKey"
      [bindTo]="section"
      [globalDisabledTooltip]="isTemplatedSection | templatedMenuDisabledFormControlTooltip">
      <lib-reactive-form-row>
        <lib-reactive-form-color-square
          [hidden]="!sectionSupportsTitleSectionBackgroundColor"
          [disabled]="!sectionSupportsTitleSectionBackgroundColor || isTemplatedSection"
          [bindOnSubmit]="sectionSupportsTitleSectionBackgroundColor"
          [presetColors]="viewModel.colorPalette$ | async"
          [inputName]="'titleSectionBackgroundColor'"
          [label]="'Title Section Background Color'"
          [inlineLabel]="false"
          [hideErrorBlock]="true"
          [tooltip]="'This will change the background color for this title section.'"
          [bindingProperty]="'metadata.titleSectionBackgroundColor'">
        </lib-reactive-form-color-square>
      </lib-reactive-form-row>
      <hr
        *ngIf="
          sectionSupportsTitleSectionBackgroundColor &&
          (sectionSupportsHeaderBackgroundColor ||
            sectionSupportsHeaderTextColor ||
            sectionSupportsProductContainerBackgroundColor ||
            sectionSupportsBodyTextColor)
        " />
      <lib-reactive-form-row>
        <lib-reactive-form-color-square
          [hidden]="!sectionSupportsHeaderBackgroundColor"
          [disabled]="!sectionSupportsHeaderBackgroundColor || isTemplatedSection"
          [bindOnSubmit]="sectionSupportsHeaderBackgroundColor"
          [presetColors]="viewModel.colorPalette$ | async"
          [inputName]="'headerBackgroundColor'"
          [label]="'Header Background Color'"
          [inlineLabel]="false"
          [hideErrorBlock]="true"
          [tooltip]="'This will change the background color for this sections header.'"
          [bindingProperty]="'metadata.productsHeaderBackgroundColor'">
        </lib-reactive-form-color-square>
        <lib-reactive-form-color-square
          [hidden]="!sectionSupportsHeaderTextColor"
          [disabled]="!sectionSupportsHeaderTextColor || isTemplatedSection"
          [bindOnSubmit]="sectionSupportsHeaderTextColor"
          [presetColors]="viewModel.colorPalette$ | async"
          [inputName]="'headerTextColor'"
          [label]="'Header Text Color'"
          [inlineLabel]="false"
          [hideErrorBlock]="true"
          [tooltip]="'This will change the text color for this sections header.'"
          [bindingProperty]="'metadata.sectionHeaderTextColor'">
        </lib-reactive-form-color-square>
      </lib-reactive-form-row>
      <hr
        *ngIf="
          (sectionSupportsHeaderBackgroundColor || sectionSupportsHeaderTextColor) &&
          (sectionSupportsProductContainerBackgroundColor || sectionSupportsBodyTextColor)
        " />
      <lib-reactive-form-row>
        <lib-reactive-form-color-square
          [hidden]="!sectionSupportsProductContainerBackgroundColor"
          [disabled]="!sectionSupportsProductContainerBackgroundColor || isTemplatedSection"
          [bindOnSubmit]="sectionSupportsProductContainerBackgroundColor"
          [presetColors]="viewModel.colorPalette$ | async"
          [inputName]="'sectionBackgroundColor'"
          [label]="'Section Background Color'"
          [inlineLabel]="false"
          [hideErrorBlock]="true"
          [tooltip]="'This will change the background color for this section.'"
          [bindingProperty]="'metadata.productsContainerBackgroundColor'">
        </lib-reactive-form-color-square>
        <lib-reactive-form-color-square
          [hidden]="!sectionSupportsBodyTextColor"
          [disabled]="!sectionSupportsBodyTextColor || isTemplatedSection"
          [bindOnSubmit]="sectionSupportsBodyTextColor"
          [presetColors]="viewModel.colorPalette$ | async"
          [inputName]="'sectionBodyTextColor'"
          [label]="'Section Body Text Color'"
          [inlineLabel]="false"
          [hideErrorBlock]="true"
          [tooltip]="'This will change the text color for products within this section.'"
          [bindingProperty]="'metadata.sectionBodyTextColor'">
        </lib-reactive-form-color-square>
      </lib-reactive-form-row>
    </lib-reactive-form-group>
  </div>
</div>

<hr class="mt-24px" />
