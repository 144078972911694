import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import type { StackType } from '../create-view-stack-print-job.component';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { iiif } from '../../../../../../../../utils/observable.extensions';
import { TemplateDomainModel } from '../../../../../../../../domainModels/template-domain-model';
import { Section } from '../../../../../../../../models/menu/dto/section';
import { MenuPreviewJobStatus } from '../../../../../../../../models/utils/dto/menu-preview-job-status-type';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';
import { MenuDomainModel } from '../../../../../../../../domainModels/menu-domain-model';

@Injectable()
export class SingleStackPrintJobAppliedSectionsViewModel {

  constructor(
    protected templateDomainModel: TemplateDomainModel,
    protected menuDomainModel: MenuDomainModel
  ) {
  }

  protected _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => this._job.next(job);

  protected readonly jobStatus$ = this.job$.pipe(
    map(job => job?.getStatusToDisplay()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _templateMode = new BehaviorSubject<boolean>(false);
  public readonly templateMode$ = this._templateMode as Observable<boolean>;
  connectToTemplateMode = (templateMode: boolean) => this._templateMode.next(templateMode);

  private _searchText = new BehaviorSubject<string>(null);
  public searchText$ = this._searchText as Observable<string>;
  connectToSearchText = (x: string) => this._searchText.next(x);

  private _stackType = new BehaviorSubject<StackType>('shelf talker');
  public readonly stackType$ = this._stackType.pipe(distinctUntilChanged());
  connectToStackType = (type: StackType) => this._stackType.next(type);

  public readonly itemCountMap$ = this.job$.pipe(
    // this might seem strange, but we reuse the variantCardCountMap for sectionCardCountMapping
    map(job => job?.getCardStackPrintConfigFor(job?.getTargetedMenuId())?.variantCardCountMap),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public isSearching$ = this.searchText$.pipe(
    map(searchText => searchText?.length >= 2),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly noSectionsForSearchPlaceholder$ = this.searchText$.pipe(
    map(searchText => `"${searchText}" does not exist`),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public noSectionsPlaceholder$ = this.jobStatus$.pipe(
    map(status => {
      const processing = status === MenuPreviewJobStatus.MenuPreviewJobStatus_Processing;
      const queued = status === MenuPreviewJobStatus.MenuPreviewJobStatus_Queued;
      return (processing || queued) ? 'Job is processing' : 'No sections applied';
    })
  );

  private menu$ = iiif(
    this.templateMode$,
    this.templateDomainModel.activeMenuTemplate$,
    this.menuDomainModel.activeHydratedMenu$
  );

  private sections$ = this.menu$.pipe(map(cardStack => cardStack?.getSectionsBasedOnMenuType()));

  public sectionsApplied$: Observable<Section[]>  = combineLatest([this.job$, this.sections$, this.searchText$]).pipe(
    map(([job, sections, searchText]) => {
      const previewJob = job?.previewJobs?.firstOrNull();
      // In variantIds property we also store section ids
      const appliedSectionIds = previewJob?.cardStackPrintConfig?.variantIds;
      const appliedSections: Section[] = sections?.filter(s => appliedSectionIds?.includes(s.id));

      if (searchText?.length >= 2) {
        return appliedSections?.filter(s => this.search(s?.title, searchText));
      }
      return appliedSections;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private search = (haystack: string, needle: string) => {
    return haystack?.toLowerCase()?.trim()?.includes(needle?.toLowerCase()?.trim());
  };

}
