import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Theme } from '../models/menu/dto/theme';
import { Section } from '../models/menu/dto/section';
import { NewMenuSectionModalComponent } from '../views/menu/components/modals/new-menu-section-modal/new-menu-section-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector, NgZone } from '@angular/core';
import { Menu } from '../models/menu/dto/menu';

export class ModalAddSection {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    menu: Menu,
    menuTheme$: Observable<Theme>,
    onClose: (section: Section) => void
  ): void {
    menuTheme$.once(theme => {
      ngZone.run(() => {
        const modalRef = ngbModal.open(NewMenuSectionModalComponent, ModalUtils.newMenuSectionModalOptions(injector));
        const modalInstance = modalRef.componentInstance as NewMenuSectionModalComponent;
        modalInstance.setMenuToAddSectionTo(menu);
        modalInstance.setTheme(theme);
        modalRef.result.then((section) => onClose?.(section)).catch(() => {});
      });
    });
  }

}
