import { Orientation } from '../models/utils/dto/orientation-type';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';

/**
 * Pass in ngbModal so that it retains the context of where it's being opened from.
 * Without this, the logged in scope does not work, and modals will throw errors
 * about dependency injection missing.
 */
export class ModalUtils {

  static defaultModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static displayModalOptions(injector: Injector): NgbModalOptions {
    return this.largeStatic(injector);
  }

  static reorderModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static selectVariantsModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static templateViewLocationsModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStaticScrollable(injector);
  }

  static templateAddEditLocationsModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStaticScrollable(injector);
  }

  static editProductModalOptions(injector: Injector): NgbModalOptions {
    return this.largeStatic(injector);
  }

  static editLabelModalOptions(injector: Injector): NgbModalOptions {
    return this.largeStatic(injector);
  }

  static viewProductModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static confirmationModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static informationModalOptions(injector: Injector): NgbModalOptions {
    return this.largeStatic(injector);
  }

  static createMarketingModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static newMenuModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStaticScrollable(injector);
  }

  static createTemplateModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStaticScrollable(injector);
  }

  static themeDetailsModalOptions(injector: Injector): NgbModalOptions {
    return this.largeStatic(injector);
  }

  static newMenuSectionModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStaticScrollable(injector);
  }

  static menuOptionsModalOptions(injector: Injector): NgbModalOptions {
    return this.largeStatic(injector);
  }

  static duplicateMenuModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static sectionVariantBadgeModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static editMenuStyleModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static setupDeviceModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static userPickerModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static selectSmartFiltersModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static addEditSmartFiltersModalOptions(injector: Injector): NgbModalOptions {
    return this.extraLargeStatic(injector);
  }

  static editBulkPrintModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static newSyncJobModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static reviewSyncJobModalOptions(injector: Injector): NgbModalOptions {
    return ModalUtils.defaultModalOptions(injector);
  }

  static overflowInfoModalOptions(injector: Injector): NgbModalOptions {
    return this.doubleExtraLargeStatic(injector);
  }

  static editLabelStyleModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static menuTemplateSaveConfirmationOptions(injector: Injector): NgbModalOptions {
    return this.mediumStatic(injector);
  }

  static templateCollectionDisplayModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStaticScrollable(injector);
  }

  static templateCollectionViewLocationsModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStaticScrollable(injector);
  }

  static addMenuOrCollectionModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStaticScrollable(injector);
  }

  static createOverrideProductGroupingModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStaticScrollable(injector);
  }

  static renameProductGroupingModalOptions(injector: Injector): NgbModalOptions {
    return this.mediumStaticScrollable(injector);
  }

  static columnOptionsModalOptions(injector: Injector): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true,
      injector
    };
  }

  static allCardStackPrintJobsOptions(injector: Injector): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      injector
    };
  }

  static changeMediaModalOptions(
    injector: Injector,
    allowColors: boolean
  ): NgbModalOptions {
    if (allowColors) {
      return this.largeStatic(injector);
    } else {
      return this.mediumStatic(injector);
    }
  }

  private static mediumStatic(injector: Injector): NgbModalOptions {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
      injector
    };
  }

  private static mediumStaticScrollable(injector: Injector): NgbModalOptions {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
      scrollable: true,
      injector
    };
  }

  private static largeStatic(injector: Injector): NgbModalOptions {
    return {
      size: 'lg',
      backdrop: 'static',
      centered: true,
      injector
    };
  }

  private static extraLargeStatic(injector: Injector): NgbModalOptions {
    return {
      size: 'bs-xl',
      backdrop: 'static',
      centered: true,
      injector
    };
  }

  private static doubleExtraLargeStatic(injector: Injector): NgbModalOptions {
    return {
      size: 'bs-xxl',
      backdrop: 'static',
      centered: true,
      injector
    };
  }

  static editVariantModalOptions(injector: Injector): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'edit-variant-modal',
      injector
    };
  }

  static liveViewOptions(injector: Injector, o: Orientation): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      windowClass: (o === Orientation.Landscape) ? 'live-view-modal-landscape' : 'live-view-modal-portrait',
      injector
    };
  }

  static printCardLiveViewOptions(injector: Injector, o: Orientation): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      windowClass: 'live-view-modal-print-card',
      injector
    };
  }

  static printLabelLiveViewOptions(injector: Injector, o: Orientation): NgbModalOptions {
    return ModalUtils.printCardLiveViewOptions(injector, o);
  }

  static printMenuLiveViewOptions(injector: Injector, o: Orientation): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      windowClass: 'live-view-modal-print-menu',
      injector
    };
  }

  static productPickerModalOptions(injector: Injector): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      windowClass: 'product-picker-modal',
      injector
    };
  }

  static customVisibleColumnsModalOptions(injector: Injector): NgbModalOptions {
    return ModalUtils.defaultModalOptions(injector);
  }

  static addNewLabelModalOptions(injector: Injector): NgbModalOptions {
    return {
      ...ModalUtils.defaultModalOptions(injector),
      modalDialogClass: 'add-new-label-dialog',
    };
  }

  static manageLabelSmartFiltersModalOptions(injector: Injector): NgbModalOptions {
    return ModalUtils.defaultModalOptions(injector);
  }

}
