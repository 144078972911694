import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/models/base/base-component';
import { PopperUtils } from '../../../../utils/popper-utils';
import { ProductTableColumnConfigsIdValue } from '../../../../models/utils/dto/product-table-column-configs-type';

@Component({
  selector: 'app-custom-visible-columns',
  templateUrl: './custom-visible-columns.component.html',
  styleUrls: ['./custom-visible-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomVisibleColumnsComponent extends BaseComponent {

  @Input() currentProductColumnConfigsIdValue: ProductTableColumnConfigsIdValue;
  @Output() productTableColumnConfigSelected: EventEmitter<ProductTableColumnConfigsIdValue>
    = new EventEmitter<ProductTableColumnConfigsIdValue>();

  // Popper
  public popperModifier = [PopperUtils.flipModifier()];
  public popperStyles = { 'background-color': '#FFFFFF' };

}
