import { Injectable, Injector, NgZone } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { SegmentedControlOption } from '../../../../models/shared/stylesheet/segmented-control-option';
import { Card } from '../../../../models/shared/stylesheet/card';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Orientation } from '../../../../models/utils/dto/orientation-type';
import { MenuType, MenuTypeDefinition } from '../../../../models/utils/dto/menu-type-definition';
import { ModalThemeDetails } from '../../../../modals/modal-theme-details';
import { Theme } from '../../../../models/menu/dto/theme';
import { SortUtils } from '../../../../utils/sort-utils';
import { CardStyle } from '../../../../models/shared/stylesheet/card-style.enum';
import { Asset } from '../../../../models/image/dto/asset';
import { debounceTime, distinctUntilChanged, map, pairwise, shareReplay, startWith, withLatestFrom } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DefaultPrintStackSize } from '../../../../models/enum/dto/default-print-stack-size';

@Injectable()
export class ThemePreviewViewModel extends BaseViewModel {

  constructor(
    private injector: Injector,
    private ngZone: NgZone,
    private ngbModal: NgbModal,
  ) {
    super();
    this.setupSegments();
    this.selectFirstThemeIfNoThemeIsSelected();
    this.updateSelectedThemeIdIfStackSizeChanges();
  }

  private readonly _selectedThemeId = new BehaviorSubject<string>(null);
  public readonly selectedThemeId$ = this._selectedThemeId.pipe(distinctUntilChanged());
  connectToSelectedThemeId = (x: string) => this._selectedThemeId.next(x);

  private readonly _orientationControlOptions = new BehaviorSubject<SegmentedControlOption[]>([]);
  public readonly orientationControlOptions$ = this._orientationControlOptions as Observable<SegmentedControlOption[]>;
  connectToOrientationControlOptions = (x: SegmentedControlOption[]) => this._orientationControlOptions.next(x);

  private readonly _selectedOrientationControl = new BehaviorSubject<SegmentedControlOption>(null);
  public readonly selectedOrientationControl$ = this._selectedOrientationControl as Observable<SegmentedControlOption>;
  connectToSelectedOrientationControl = (x: SegmentedControlOption) => this._selectedOrientationControl.next(x);

  private readonly _selectedCardStackSize = new BehaviorSubject<DefaultPrintStackSize>(null);
  public readonly selectedCardStackSize$ = this._selectedCardStackSize.pipe(distinctUntilChanged());
  connectToSelectedCardStackSize = (x: DefaultPrintStackSize) => this._selectedCardStackSize.next(x);

  private readonly _themes = new BehaviorSubject<Theme[]>([]);
  public readonly themes$ = this._themes.pipe(
    map(themes => {
      const shallow = themes?.shallowCopy();
      SortUtils.prepareForSorting(shallow);
      return shallow?.sort((a, b) => SortUtils.sortThemes(a, b)) || [];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToThemes = (x: Theme[]) => this._themes.next(x);

  private readonly _menuType = new BehaviorSubject<MenuType>(null);
  public readonly menuType$ = this._menuType.pipe(distinctUntilChanged());
  connectToMenuType = (x: MenuType) => this._menuType.next(x);

  private readonly _selectedCardId = new BehaviorSubject<string>(null);
  public readonly selectedCardId$ = this._selectedCardId.pipe(distinctUntilChanged());
  connectToSelectedCardId = (x: string) => this._selectedCardId.next(x);

  private orientationMap = new Map<string, Orientation>();

  public readonly scrollToCardId$ = this.selectedCardId$.pipe(debounceTime(50));

  public readonly maxHeightRem$ = this.selectedOrientationControl$.pipe(
    map(orientation => (orientation.value === Orientation.Portrait) ? 26 : 16)
  );

  public readonly hideOrientationSegmentedControl$ = this.menuType$.pipe(
    map(menuType => {
      return menuType === MenuType.WebMenu
          || menuType === MenuType.PrintMenu
          || menuType === MenuType.PrintReportMenu;
    })
  );

  public readonly themeSelected$ = combineLatest([
    this.selectedThemeId$,
    this.themes$
  ]).pipe(
    map(([id, themes]) => themes?.find(t => t?.id === id))
  );

  public readonly themeCards$ = combineLatest([
    this.themes$,
    this.selectedOrientationControl$,
    this.selectedCardId$,
    this.selectedCardStackSize$,
    this.menuType$
  ]).pipe(
    debounceTime(1),
    map(([themes, orientation, selectedCardId, selectedCardStackSize, menuType]) => {
      if (!themes?.length) return [];
      return themes?.map(theme => {
        const previewImages = this.setStackPreviewImages(theme, menuType, orientation, selectedCardStackSize);
        const card = new Card(theme.description, theme.name);
        card.id = this.buildCardId(theme.id, selectedCardStackSize);
        card.cardStyle = CardStyle.ThemePreview;
        card.data = theme;
        card.customClass = MenuTypeDefinition.containsStackedContent(menuType)
          ? this.getPrintStackCustomClass(theme.id, this.orientationMap, selectedCardStackSize)
          : this.getCardCustomClass(menuType, orientation);
        card.selected = card.id === selectedCardId;
        if (previewImages?.length > 0) {
          card.asset = previewImages?.sort((a, b) => b.timestamp - a.timestamp)?.firstOrNull();
        }
        return card;
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  orientationSegmentedControlOptionSelected(updatedOpts: SegmentedControlOption[]) {
    this.connectToSelectedOrientationControl(updatedOpts?.firstOrNull());
    combineLatest([
      this.themeCards$,
      this.themes$,
    ]).once(([previewCards, themes]) => {
      this.connectToSelectedCardId(previewCards.find(c => c.selected)?.id);
      this.connectToSelectedThemeId(themes?.firstOrNull()?.id);
    });
  }

  themeCardSelected(card: Card) {
    combineLatest([
      this.themeCards$,
      this.themes$,
    ]).once(([previewCards, themes]) => {
      previewCards?.forEach(c => c.selected = (c.id === card.id));
      this.connectToSelectedThemeId(themes?.find(theme => card?.id?.includes(theme?.id))?.id);
      this.connectToSelectedCardId(card.id);
    });
  }

  openCardDetails(card: Card) {
    combineLatest([
      this.menuType$,
      this.selectedOrientationControl$
    ]).once(([menuType, orientation]) => {
      ModalThemeDetails.open(
        this.ngZone,
        this.ngbModal,
        this.injector,
        card.data as Theme,
        menuType,
        orientation.value
      );
    });
  }

  private setStackPreviewImages(
    theme: Theme,
    menuType: MenuType,
    selectedOrientationControl: SegmentedControlOption,
    selectedCardStackSize: DefaultPrintStackSize
  ): Asset[] {
    if (MenuTypeDefinition.containsStackedContent(menuType)) {
      return this.setPrintStackPreviewImages(theme, selectedCardStackSize);
    }
    return selectedOrientationControl?.value === Orientation.Portrait
      ? theme?.portraitPreviewImages
      : theme?.landscapePreviewImages;
  }

  private setPrintStackPreviewImages(theme: Theme, selectedCardStackSize: DefaultPrintStackSize): Asset[] {
    const cardOrientation = theme.printConfig.cardOrientationMap.get(selectedCardStackSize);
    this.orientationMap.set(theme.id, cardOrientation);
    const previewHashes = theme.printConfig.previewCardMap.get(selectedCardStackSize);
    return theme?.getStackPreviewImages()?.filter(asset => previewHashes?.includes(asset.md5Hash));
  }

  private getCardCustomClass(menuType: MenuType, selectedOrientationControl: SegmentedControlOption): string {
    switch (menuType) {
      case MenuType.DisplayMenu:
      case MenuType.MarketingMenu:
        return (selectedOrientationControl?.value === Orientation.Portrait)
          ? 'portrait-preview'
          : 'landscape-preview';
      case MenuType.WebMenu:
        return 'web-preview';
      case MenuType.PrintMenu:
      case MenuType.PrintReportMenu:
        return 'portrait-print-preview';
    }
  }

  private getPrintStackCustomClass(
    themeId: string,
    orientationMap: Map<string, Orientation>,
    selectedCardStackSize: DefaultPrintStackSize
  ): string {
    const orientation = orientationMap?.get(themeId);
    const cardSize = selectedCardStackSize?.toLowerCase();
    return `${orientation}-${cardSize}`;
  }

  private setupSegments(): void {
    const p = new SegmentedControlOption('Portrait', Orientation.Portrait, true);
    const l = new SegmentedControlOption('Landscape', Orientation.Landscape);
    this.connectToOrientationControlOptions([p, l]);
    this.connectToSelectedOrientationControl(p);
  }

  private selectFirstThemeIfNoThemeIsSelected(): void {
    combineLatest([
      this.themes$,
      this.selectedThemeId$,
      this.selectedCardStackSize$
    ]).once(([themes, selectedThemeId, selectedCardStackSize]) => {
      if (themes?.length > 0 && !selectedThemeId) {
        this.selectTheme(themes?.firstOrNull()?.id, selectedCardStackSize);
      }
    });
  }

  private updateSelectedThemeIdIfStackSizeChanges(): void {
    this.themes$.pipe(
      startWith(null),
      pairwise(),
      withLatestFrom(this.selectedCardStackSize$)
    ).subscribeWhileAlive({
      owner: this,
      next: ([[prevThemes, currentThemes], selectedCardStackSize]) => {
        if (prevThemes && prevThemes?.map(it => it?.id)?.sort() !== currentThemes?.map(it => it?.id)?.sort()) {
          this.selectTheme(currentThemes?.firstOrNull()?.id, selectedCardStackSize);
        }
      }
    });
  }

  private buildCardId(themeId: string, selectedCardStackSize: DefaultPrintStackSize): string {
    return `${themeId}-${selectedCardStackSize}`;
  }

  private selectTheme(themeId: string, selectedCardStackSize: DefaultPrintStackSize): void {
    this.connectToSelectedThemeId(themeId);
    this.connectToSelectedCardId(this.buildCardId(themeId, selectedCardStackSize));
  }

}
