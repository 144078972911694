import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DisplayableItem } from '../../displayable-item-preview/displayable-item';
import { ActiveViewModel } from './active-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-active',
  templateUrl: './active.component.html',
  styleUrls: ['./active.component.scss'],
  providers: [ActiveViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveComponent {

  constructor(public viewModel: ActiveViewModel) {}
  @Input() @ViewModelConnector('connectToDisplayableItem') displayableItem: DisplayableItem;

}
