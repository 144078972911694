<app-loading *ngIf="viewModel.isLoading$ | push" [options]="viewModel?.loadingOpts$ | push"> </app-loading>

<div fxLayout="column" fxLayoutGap="1.5rem">
  <!-- Info banner explain variant grouping when section is in Grid Mode -->
  <div *rxIf="viewModel.variantsAreGrouped$" class="mt-8px">
    <div fxLayout="row">
      <div class="info-grouping-bar"></div>
      <app-info-banner fxFlex>
        {{ viewModel.groupingModeInfoText$ | push }}
      </app-info-banner>
    </div>
    <hr class="mt-24px mb-0" />
  </div>
  <div class="search-and-filter-container">
    <!-- search algorithm is too complicated for internal search bar logic -->
    <!-- bypass the search bar altogether and write search logic in view model -->
    <lib-reactive-search-bar class="search-bar" (searchText)="viewModel.connectToSearchText($event)">
    </lib-reactive-search-bar>
    <lib-reactive-form-group>
      <div class="d-flex gap-4">
        <lib-reactive-form-drop-down
          *ngIf="!(viewModel?.hideFilterByVisibility$ | push)"
          class="filter-by"
          [inputName]="'filterByVisibility'"
          [label]="'Filter By Visibility'"
          [placeholder]="'All'"
          [inlineLabel]="false"
          [clearable]="true"
          [hideErrorBlock]="true"
          (valueChanged)="viewModel.connectToFilterByVisibility($event)"
          [dropdowns]="viewModel.filterByVisibilityOptions$ | push">
        </lib-reactive-form-drop-down>
        <lib-reactive-form-drop-down
          class="number-of-results"
          [inputName]="'numberOfResults'"
          [label]="'Number of Results'"
          [placeholder]="'Number of Results'"
          [hideErrorBlock]="true"
          [dropdowns]="viewModel.nResultsPerPageOptions$ | push"
          [programmaticallyChangeValue]="viewModel.nResultsPerPage$ | push"
          (valueChanged)="viewModel.connectToNResultsPerPage($event)">
        </lib-reactive-form-drop-down>
      </div>
    </lib-reactive-form-group>
  </div>
  <!-- List Variants -->
  <div
    [hidden]="viewModel.sortedSectionVariantsLessThanTwo$ | push"
    [style.marginTop.rem]="!(viewModel.variantsAreGrouped$ | push) ? 0.5 : 0"
    class="variant-sort-header"
    [innerHtml]="viewModel.sectionSortTypes$ | push | decodeSectionSortingIntoReadableString"></div>
  <!----------------------- Variant groupings --------------------------->
  <ng-container
    *rxFor="
      let group of pagination.currentPageData | asArrayOf : ClientTypes.VariantGroup as groups;
      trackBy: trackByShortGroupingId;
      strategy: 'immediate';
      let i = index
    ">
    <app-text-line-divider
      *ngIf="groups | showWillNotAppearDivider : i"
      [barColor]="'#fa5555'"
      (openInformationModal)="openInformationModal()">
      {{ productBelowLineDesc }}
    </app-text-line-divider>
    <app-menu-section-variant-grouping
      #grouping
      [menu]="viewModel.menu$ | push"
      [menuOptions]="viewModel.menuOptions$ | push"
      [section]="viewModel.sectionWithRealtimeSortingApplied$ | push"
      [product]="group.product"
      [sortedVariantIds]="viewModel.sortedVariantIds$ | push"
      [variants]="group.variants"
      [sectionStyles]="viewModel.sectionStyles$ | push"
      [invalidFormState]="invalidFormState"
      [formPristine]="formPristine"
      [autoSaving]="autoSaving"
      [removingVariants]="removingVariants"
      [templateMode]="templateMode"
      [isTemplatedSection]="isTemplatedSection"
      [allowBadgeOverride]="allowBadgeOverride"
      [allowLabelOverride]="allowLabelOverride"
      [allowStyleOverride]="allowStyleOverride"
      [saveSection]="saveSection"
      [removeVariantFromSection]="removeVariantFromSection"
      [theme]="viewModel.currentMenuTheme$ | push"
      [includedOnMenu]="group.includedOnMenu">
    </app-menu-section-variant-grouping>
  </ng-container>
  <app-menu-section-variant-groups-empty-state
    [emptySearch]="viewModel.emptySearch$ | push"
    [noVariantsWithThatVisibility]="viewModel.noVariantsWithThatVisibility$ | push">
  </app-menu-section-variant-groups-empty-state>
  <div [hidden]="viewModel.sectionHasProductIds$ | push" class="no-product-placeholder">
    <p class="bs-edit-section-title">
      {{ viewModel.emptyProductSectionText$ | push }}
    </p>
    <lib-button-primary
      (buttonClicked)="addProductsClicked.emit(true)"
      [iconSrc]="'assets/icons/light/outline/plus.svg'"
      [disabled]="(viewModel.disableAddProductsButton$ | push) || !formPristine"
      [disabledTooltip]="
        (viewModel.showDisabledTooltip$ | push | templatedMenuDisabledButtonTooltip) ||
        (formPristine | saveChangesTooltip)
      ">
      Add Products
    </lib-button-primary>
  </div>
  <lib-reactive-pagination
    #pagination
    [hidden]="!(viewModel.sectionHasProductIds$ | push)"
    class="reactive-pagination-fix-reboot-changes"
    [itemsPerPage]="viewModel.nResultsPerPage$ | push"
    [showSkipToBeginningAndEnd]="true"
    [externalData]="viewModel.searchedGroupings$ | push">
  </lib-reactive-pagination>
  <!--------------------------------------------------------------------->
</div>
<hr [hidden]="hideBottomDivider" class="mt-24px" />
