import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Terpene } from '../../../../../../../../../models/enum/dto/terpene';
import { TerpenesPreviewViewModel } from '../terpenes-preview-view-model';

@Component({
  selector: 'app-terpene-preview-chunk',
  templateUrl: './terpene-preview-chunk.component.html',
  styleUrls: ['./terpene-preview-chunk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerpenePreviewChunkComponent {

  constructor(
    public viewModel: TerpenesPreviewViewModel // provided by parent
  ) {
  }

  @Input() terpene: Terpene | string;

}
