import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../../models/product/dto/variant';
import { Product } from '../../../../models/product/dto/product';
import { Menu } from '../../../../models/menu/dto/menu';
import { PrintCardTheme } from '../../../../models/enum/dto/theme.enum';

@Pipe({
  name: 'getPrintCardOptionTitle'
})
export class GetPrintCardOptionTitlePipe implements PipeTransform {

  transform(item: Product | Variant, menu: Menu | null): string | null {
    if (item instanceof Product) {
      const getProductTitle = () => item?.variants?.length === 1
        ? item?.variants?.firstOrNull()?.getDisplayName()
        : item?.getProductTitle();
      const isFikaEdibles = menu?.theme === PrintCardTheme.FikaEdibles;
      const isChildVariantList = menu?.getSectionsBasedOnMenuType()?.firstOrNull()?.isChildVariantList();
      const multipleFlavours = item?.variants?.length > 1;
      if (isFikaEdibles && isChildVariantList && multipleFlavours) {
        return getProductTitle() + ` (${item?.variants?.length} Flavours)`;
      } else {
        return getProductTitle();
      }
    }
    if (item instanceof Variant) return item?.getVariantTitle();
    return null;
  }

}
