import { Pipe, PipeTransform } from '@angular/core';
import { Section } from '../../../models/menu/dto/section';
import { SectionType } from '../../../models/enum/dto/section-type';

@Pipe({
  name: 'isProductGroupSection'
})
export class IsProductGroupSectionPipe implements PipeTransform {

  transform(value: Section): boolean {
    return value?.sectionType === SectionType.ProductGroup;
  }

}
