import { Component, Injector, Input, NgZone, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { UploadImageInterface } from '../../../../../../shared/components/upload-asset/upload-image-interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BudsenseFile } from '../../../../../../../models/shared/budsense-file';
import { OrderableMenuAsset } from '../../../../../../../models/menu/shared/orderable-menu-asset';
import { SortUtils } from '../../../../../../../utils/sort-utils';
import { combineLatest, Observable } from 'rxjs';
import { UploadAssetComponent } from '../../../../../../shared/components/upload-asset/upload-asset.component';
import { ToastService } from '../../../../../../../services/toast-service';
import { switchMap } from 'rxjs/operators';
import { EditPlaylistMenuViewModel } from '../edit-playlist-menu-view-model';
import { EditPlaylistViewModel } from './edit-playlist-view-model';
import { ModalReorderProductMenuAssets } from '../../../../../../../modals/modal-reorder-product-menu-assets';

@Component({
  selector: 'app-edit-playlist',
  templateUrl: './edit-playlist.component.html',
  styleUrls: ['./edit-playlist.component.scss'],
  providers: [EditPlaylistViewModel]
})
export class EditPlaylistComponent extends BaseComponent implements UploadImageInterface {

  @ViewChild('mediaUpload') mediaUpload: UploadAssetComponent;
  @Input() sharedVM: EditPlaylistMenuViewModel;
  @Input() mergeKey: string;
  @Input() formPristine: boolean = true;
  @Input() formHasErrors: boolean = true;

  constructor(
    public viewModel: EditPlaylistViewModel,
    private ngZone: NgZone,
    private ngbModal: NgbModal,
    private injector: Injector,
    private toastService: ToastService,
  ) {
    super();
  }

  addNewMediaClicked(el: HTMLElement) {
    this.mediaUpload.animate(750);
    el.scrollIntoView();
  }

  fileList(files: BudsenseFile[], id?: number) {
    if (files && files.length > 0) {
      const nFiles = files.length === 1 ? '1 file' : `${files.length} files`;
      const lm = `Uploading ${nFiles}`;
      if (!this.sharedVM.fileUploadLoadingOpts.containsRequest(lm)) {
        this.sharedVM.fileUploadLoadingOpts.addRequest(lm);
        this.viewModel.sharedViewModel.uploadFiles(files).pipe(
          // After upload, update the default priority for newly added files
          switchMap(_ => this.viewModel.setDefaultOptionsForNewFiles(files))
        ).subscribe(_ => {
          this.sharedVM.fileUploadLoadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage(`Successfully uploaded ${nFiles}`, 'Media Uploaded');
          this.mediaUpload.clear();
          // spoof temporary files into orderedMedia so they can show loading in UI
          this.viewModel.addTempUploadedFiles(files);
        }, error => {
          this.sharedVM.fileUploadLoadingOpts.removeRequest(lm);
          this.toastService.publishError(error);
        });
      }
      this.mediaUpload.clear();
    }
  }

  reorderMediaClicked() {
    combineLatest([
      this.viewModel.sharedViewModel.menu$,
      this.viewModel.sharedViewModel.orderedMedia$
    ]).once(([menu, orderedMedia]) => {
      const opts = this.sharedVM.getReorderOptions(menu);
      orderedMedia.sort(SortUtils.menuAssets);
      const reorderOperation = (ordered: OrderableMenuAsset[]): Observable<boolean> => {
        return this.viewModel.sharedViewModel.updateMenuAssetOrder(ordered);
      };
      ModalReorderProductMenuAssets.open(
        this.ngZone,
        this.ngbModal,
        this.injector,
        opts,
        orderedMedia,
        reorderOperation
      );
    });
  }

}
