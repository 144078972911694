import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { Display } from '../models/display/dto/display';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';
import { ApiPagination } from '../models/shared/api-pagination';
import { WebSocketService } from '../services/web-socket.service';
import { WebSocketRequestDisplays } from '../models/web-sockets/messages/send/web-socket-request-displays';

export const DEFAULT_DISPLAY_PAGINATION_COUNT = 2;

@Injectable() // Provided by Logged In Scope
export class DisplayAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
    private webSocketService: WebSocketService
  ) {
  }

  // Variables

  public serviceName = 'Display';

  // Display

  public WriteDisplay(display: Display): Observable<Display> {
    const url = Endpoints.WriteDisplay();
    return this.apiClient.postObj(Display, url, display).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'WriteDisplay', err));
        return throwError(err);
      })
    );
  }

  public UpdateDisplay(display: Display): Observable<Display> {
    const updateDataUrl = Endpoints.UpdateDisplay();
    const getDataUrl = Endpoints.GetDisplay(display.id, false);
    return this.apiClient.postRecursiveObj<Display, Display>(Display, updateDataUrl, getDataUrl, display).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateDisplay', err));
        return throwError(err);
      })
    );
  }

  public DeleteDisplay(display: Display): Observable<string> {
    const url = Endpoints.DeleteDisplay();
    return this.apiClient.deleteStr(url, display).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteDisplay', err));
        return throwError(err);
      })
    );
  }

  public getDisplay$(
    userId: string,
    companyId: number,
    locationId: number,
    displayId: string,
    ignoreLastSession: boolean,
    lastModified?: number
  ): Observable<Display> {
    return this.webSocketGetDisplay$(userId, companyId, locationId, displayId, ignoreLastSession, lastModified);
  }

  protected webSocketGetDisplay$(
    userId: string,
    companyId: number,
    locationId: number,
    displayId: string,
    ignoreLastSession: boolean,
    lastModified?: number
  ): Observable<Display> {
    const msg = new WebSocketRequestDisplays(userId, companyId, locationId, displayId, ignoreLastSession, lastModified);
    return this.webSocketService.requestObject$(msg, Display).pipe(
      catchError(error => {
        const err = new BsError(error, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetDisplayFromWebSocket', err));
        return this.restfulGetDisplay$(displayId, ignoreLastSession, lastModified);
      })
    );
  }

  protected restfulGetDisplay$(
    displayId: string,
    ignoreLastSession: boolean,
    lastModified?: number
  ): Observable<Display> {
    const url = Endpoints.GetDisplay(displayId, ignoreLastSession, lastModified);
    return this.apiClient.recursiveGetObject<Display>(Display, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetDisplay', err));
        return throwError(() => err);
      })
    );
  }

  public getLocationDisplays$(
    userId: string,
    companyId: number,
    locationId: number
  ): Observable<Display[]> {
    return this.webSocketGetLocationDisplays$(userId, companyId, locationId);
  }

  protected webSocketGetLocationDisplays$(
    userId: string,
    companyId: number,
    locationId: number
  ): Observable<Display[]> {
    const msg = new WebSocketRequestDisplays(userId, companyId, locationId);
    return this.webSocketService.requestArray$(msg, Display).pipe(
      catchError(error => {
        const err = new BsError(error, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetLocationDisplaysFromWebSocket', err));
        return this.restfulGetLocationDisplays$(locationId);
      })
    );
  }

  protected restfulGetLocationDisplays$(locationId: number): Observable<Display[]> {
    const url = Endpoints.GetLocationDisplays(locationId);
    const pagination = new ApiPagination(DEFAULT_DISPLAY_PAGINATION_COUNT);
    return this.apiClient.recursiveGet<Display>(Display, url, null, pagination).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetLocationDisplays', err));
        return throwError(() => err);
      })
    );
  }

  public GetCompanyDisplays(): Observable<Display[]> {
    const url = Endpoints.GetCompanyDisplays();
    return this.apiClient.getArr(Display, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyDisplays', err));
        return throwError(err);
      })
    );
  }

  public UpdateDisplayPriorities(displays: Display[]): Observable<Display[]> {
    const url = Endpoints.UpdateDisplayPriorities();
    return this.apiClient.postArr(Display, url, displays).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'UpdateDisplayPriorities', err));
        return throwError(err);
      })
    );
  }

}
