import { Deserializable } from '../../protocols/deserializable';
import { Variant } from './variant';
import { DisplayAttribute } from '../../display/dto/display-attribute';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { Pagable } from '../../protocols/pagable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { SortUtils } from '../../../utils/sort-utils';
import { HasChildIds } from '../../protocols/has-child-ids';
import { VariantInventory } from './variant-inventory';
import { StringUtils } from '../../../utils/string-utils';
import { Label } from '../../shared/label';
import { SystemLabel } from '../../shared/labels/system-label';
import { CannabisUnitOfMeasure } from '../../utils/dto/cannabis-unit-of-measure-type';
import { ProviderUtils } from '../../../utils/provider-utils';
import { exists } from '../../../functions/exists';
import type { CompanyConfiguration } from '../../company/dto/company-configuration';
import type { LocationConfiguration } from '../../company/dto/location-configuration';
import type { Menu } from '../../menu/dto/menu';
import type { Section } from '../../menu/dto/section';
import type { VariantType } from '../../utils/dto/variant-type-definition';
import { ProductProperties } from '../../protocols/product-properties';
import { BackgroundSortable } from '../../protocols/background-sortable';
import { PriceFormat } from '../../enum/dto/price-format';
import { ProductType } from '../../enum/dto/product-type';
import { StrainClassification } from '../../enum/dto/strain-classification';
import { InventoryProvider } from '../../enum/dto/inventory-provider';
import { TerpeneUnitOfMeasure } from '../../utils/dto/terpene-unit-of-measure-type';

export class Product implements Deserializable, Cachable, HasChildIds, Pagable, UniquelyIdentifiable,
  ProductProperties, BackgroundSortable {

  // DTO Properties
  public companyId: number;
  public id: string;
  public provider: InventoryProvider;
  public name: string;
  public isArchived: boolean;
  public variants: Variant[];
  /**
   * Display attributes aren't stored in an array, but rather they are stored like a linked list.
   * Combinations that linked list can be in:
   * 1. null
   * 2. Location DA, points to ->, null
   * 3. Company DA, points to ->, null (Company DA will always point to null)
   * 4. Location DA, points to ->, Company DA, points to ->, null (Company DA will always point to null)
   */
  public displayAttributes: DisplayAttribute;
  // Cache
  public cachedTime: number;
  // Below properties aren't returned from API. Only hydrated if setSortingProperties is called prior
  title: string;

  /**
   * FOR ORDER RECEIVING
   *
   * When picking "Show New Inventory From the Last X Days/Hours", this is the chain link that gets set.
   * It's initialized from the main product pool, and determines which variants cascade downwards into
   * variantsFilteredByMedAndRec.
   *
   * variants -> variantsFilteredByLastNDays -> variantsFilteredByMedAndRec -> etc
   *
   * Note: using array pointers is an order of magnitude faster than making deep copied lists throughout the app to
   * keep track of this information.
   */
  public variantsFilteredByLastNDays: Variant[];

  /**
   * FOR PRODUCT TABLES
   *
   * "All Product Table" uses variantsThatMeetSmartFilterSearch and variantsFilteredByMedAndRec. It cascades downwards.
   * That means variantsFilteredByMedAndRec is initialized with variantsThatMeetSmartFilterSearch.
   *
   * "Product Picker Table" only uses variantsFilteredByMedAndRec.
   *
   * Note: using array pointers is an order of magnitude faster than making deep copied lists throughout the app to
   * keep track of this information.
   */
  public variantsThatMeetSmartFilterSearch: Variant[];

  /**
   * A link in the product sorting chain.
   *
   * It's a bin for storing variants sorted by medical, recreational, or both.
   *
   * Note: using array pointers is an order of magnitude faster than making deep copied lists throughout the app to
   * keep track of this information.
   */
  public variantsFilteredByMedAndRec: Variant[];

  /**
   * This is the end of the chain that begins with variantsThatMeetSmartFilterSearch or variantsFilteredByMedAndRec.
   * It's initialized from variantsFilteredByMedAndRec and determines which variants to show according to the filter
   * criteria set by user.
   *
   * Note: using array pointers is an order of magnitude faster than making deep copied lists throughout the app to
   * keep track of this information
   */
  public variantsFilteredByTable: Variant[];

  /**
   * Not returned from API. Set by products pipeline inside product domain model.
   * This gets set at the highest level in the waterfall of data, so any pipeline listening
   * to the product domain model will have access to this computed label.
   */
  public computedLabelsForProductTable: Label[];

  /**
   * Need this property for Fuse.js to include labels in searches.
   */
  public computedLabelTextForProductSearch: string;

  /**
   * Computed within edit menu section.
   */
  public computedLabelsForMenuSection: Label[];

  /**
   * Only set for products within override product groups. Set on override product group deserialization.
   */
  public overrideGroupName: string;

  static buildArrayCacheKey(companyId, locationId: number): string {
    return `Products-${companyId}-${locationId}`;
  }

  static buildCacheKey(companyId, locationId: number, productId: string): string {
    return `Product-${companyId}-${locationId}-${productId}`;
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.variants = Deserialize?.arrayOf(Variant, this.variants) ?? [];
    this.displayAttributes = Deserialize?.instanceOf(DisplayAttribute, this.displayAttributes);
    this.variantsFilteredByLastNDays = Deserialize?.arrayOf(Variant, this.variantsFilteredByLastNDays);
    this.variantsThatMeetSmartFilterSearch = Deserialize?.arrayOf(Variant, this.variantsThatMeetSmartFilterSearch);
    this.variantsFilteredByMedAndRec = Deserialize?.arrayOf(Variant, this.variantsFilteredByMedAndRec);
    this.variantsFilteredByTable = Deserialize?.arrayOf(Variant, this.variantsFilteredByTable);
    this.computedLabelsForProductTable = Deserialize?.arrayOf(Label, this.computedLabelsForProductTable) ?? [];
    this.computedLabelsForMenuSection = Deserialize?.arrayOf(Label, this.computedLabelsForMenuSection) ?? [];
    // Set variant properties for parent product
    if (this.variants) {
      this.variants.forEach(v => {
        v.productId = this.id;
        v.productName = this.name;
        return v;
      });
    }
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(companyId, locationId: number): string {
    return Product.buildCacheKey(companyId, locationId, this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  resetVariantsThatMeetSmartFilterSearch(): void {
    this.variantsThatMeetSmartFilterSearch = [];
  }

  computeLabelPropertyForProductTable(
    locationConfig: LocationConfiguration,
    labels: Label[],
    systemLabels: SystemLabel[]
  ): void {
    const locationId = locationConfig?.locationId;
    const priceStream = locationConfig?.priceFormat;
    this.variants?.forEach(v => v?.computeLabelPropertyForProductTable(locationId, priceStream, labels, systemLabels));
    this.computedLabelsForProductTable = this.variants
      ?.map(v => v?.computedLabelForProductTable)
      ?.uniqueByProperty('id')
      ?.sort(SortUtils.sortLabelsByPriority);
    this.computedLabelTextForProductSearch = this.computedLabelsForProductTable?.firstOrNull()?.text;
  }

  computeLabelPropertyForMenuContext(
    [menu, section]: [Menu, Section],
    [locId, companyId, priceFormat]: [number, number, PriceFormat],
    [labels, systemLabels]: [Label[], SystemLabel[]],
  ): void {
    this.variants?.forEach((v: Variant) => {
      v?.computedLabelPropertyForMenuContext([menu, section], [labels, systemLabels], [locId, companyId, priceFormat]);
    });
    this.computedLabelsForMenuSection = this.variants
      ?.map(v => v?.computedLabelForMenuSection)
      ?.sort(SortUtils.sortLabelsByPriority);
  }

  computeAndSetSecondaryCompanyPrice(secondaryPriceGroupId: string): void {
    this.variants?.forEach(v => {
      const companyPricing = v?.locationPricing?.find(lp => lp.locationId === v.companyId);
      if (exists(companyPricing)) {
        companyPricing.secondaryPrice = companyPricing?.pricingGroupPrices?.[secondaryPriceGroupId] || null;
      }
    });
  }

  /**
   * These properties are computed for the smart filter variant matcher worker. Web worker don't have
   * access to full objects, so computing them inside the worker won't work, which means we need to
   * compute them as part of the products pipeline.
   */
  computeSmartFilterHydrationProperties(
    locId: number,
    companyId: number,
    priceFormat: PriceFormat,
    systemLabels: SystemLabel[],
  ): void {
    this.variants?.forEach(v => v?.setSmartFilterHydrationProperties(locId, companyId, priceFormat, systemLabels));
  }

  getId(): string {
    return this.id;
  }

  getChildIds(): string[] {
    return this.variantsFilteredByTable?.map(v => v.id) ?? [];
  }

  hasVariantById(variantId: string): boolean {
    return this.variants?.some(v => v?.id === variantId);
  }

  getProductTitle(): string {
    const hasDisplayAttributeName = !!this.displayAttributes && !!this.displayAttributes?.displayName;
    const hasInheritedDisplayAttributeName = !!this.displayAttributes?.inheritedDisplayAttribute
      && !!this.displayAttributes?.inheritedDisplayAttribute?.displayName;
    if (hasDisplayAttributeName) {
      return this.displayAttributes.displayName;
    }
    if (hasInheritedDisplayAttributeName) {
      return this.displayAttributes.inheritedDisplayAttribute.displayName;
    }
    return this.name;
  }

  getDisplayName(): string {
    return this.displayAttributes?.getDisplayName() || this.name;
  }

  getStartKey(): string {
    return this.id;
  }

  getProductTypes(): ProductType[] {
    return this.variants.map(v => v.productType).unique();
  }

  getProductTypeString(): ProductType|VariantType|string {
    const uniqueVariantTypes = this.variantsFilteredByTable?.map(v => v.variantType)?.uniqueInstance();
    if (!!uniqueVariantTypes) {
      // If all variantTypes are the same, then show that
      return uniqueVariantTypes;
    } else {
      const productTypes = this?.variants?.map(v => v.productType);
      let firstProdType: ProductType;
      if (productTypes?.length > 0) {
        firstProdType = productTypes.reduce((accu, curr) => accu || curr);
      }
      const varTypeCount = this.variantsFilteredByTable?.map(v => v.variantType)?.unique()?.length ?? 0;
      return (varTypeCount > 1) ? `${firstProdType} (${varTypeCount})` : firstProdType;
    }
  }

  getVariantTypes(): VariantType[]|null {
    return this.variants?.map(v => v.variantType)?.unique();
  }

  getStrainClassifications(): StrainClassification[]|null {
    return this.variants?.map(v => v.classification)?.unique();
  }

  getStrainClassification(): StrainClassification {
    const classifications = this?.variants?.map(v => v.classification);
    if (classifications?.length > 0) {
      return classifications.reduce((accu, curr) => accu || curr);
    }
    return StrainClassification.Unknown;
  }

  getStrains(): string[] {
    return this.variants?.map(v => v?.strain?.trim())?.filterFalsies()?.unique();
  }

  getUniqueIdentifier(): string {
    const variantsIds = this.variants.map(v => v?.getUniqueIdentifier()).sort().join(',');
    const productTableLabels = this.computedLabelsForProductTable?.map(label => label?.getUniqueIdentifier());
    const menuLabels = this.computedLabelsForMenuSection?.map(label => label?.getUniqueIdentifier());
    return `${this.id}
      -${this.name}
      -${variantsIds}
      -${productTableLabels}
      -${menuLabels}
      -${this.displayAttributes?.getUniqueIdentifier()}`;
  }

  getQuantityInStockString(): string {
    const quantitiesInStock = this?.variants?.map(v => v.inventory?.quantityInStock ?? 0);
    if (quantitiesInStock?.length > 0) {
      return quantitiesInStock.reduce((a, b) => a + b, 0).toString();
    }
    return '';
  }

  /**
   * This is needed because some of the POS APIs don't return the true value of stock for a variant.
   * Example: The Trees API will cap quantity at 30, even if the stock for the given variant is higher than 30.
   */
  presentQuantityInStockToUser(inventoryProvider: InventoryProvider): string {
    const stockString = this.getQuantityInStockString();
    if (!stockString) {
      return '';
    } else {
      return ProviderUtils.applyVariantInventoryDecorator(inventoryProvider, stockString);
    }
  }

  getProductCannabisUnitOfMeasure(): CannabisUnitOfMeasure {
    const cannabisUnits = this?.variants?.map(v => v.cannabisUnitOfMeasure);
    let cuom: CannabisUnitOfMeasure = CannabisUnitOfMeasure.UNKNOWN;
    if (cannabisUnits?.length > 0) {
      cuom = cannabisUnits.reduceRight((accu, curr) => accu || curr) || CannabisUnitOfMeasure.UNKNOWN;
    }
    return cuom;
  }

  getProductTerpeneUnitOfMeasure(): TerpeneUnitOfMeasure {
    const terpeneUnits = this.variants?.map(v => v?.terpeneUnitOfMeasure);
    let tuom: TerpeneUnitOfMeasure = TerpeneUnitOfMeasure.UNKNOWN;
    if (terpeneUnits?.length > 0) {
      tuom = terpeneUnits.reduceRight((accu, curr) => accu || curr) || TerpeneUnitOfMeasure.UNKNOWN;
    }
    return tuom;
  }

  getMinCannabinoidWithUnits(cannabinoid: string): string {
    const cuom = this.getProductCannabisUnitOfMeasure();
    let val: string;
    const displayAttributeMinCannabinoid = this?.displayAttributes?.getMinCannabinoidOrTerpene(cannabinoid);
    if (!!displayAttributeMinCannabinoid) {
      val = displayAttributeMinCannabinoid;
    } else {
      const minCannabinoids = this?.variants
        ?.map(v => v.getNumericMinCannabinoidOrTerpene(cannabinoid))
        ?.filterNulls()
        ?.filter(v => v !== Variant.invalidParsedCannabinoidOrTerpene)
        ?.sort(SortUtils.numberAscNullsLast);
      if (minCannabinoids?.length > 0) {
        val = `${minCannabinoids.reduce((accu, curr) =>  accu ?? curr)}`;
      }
    }
    return (!!val && (cuom !== CannabisUnitOfMeasure.NA)) ? val + cuom : '';
  }

  getMinCannabinoidWithoutDisplayAttributes(cannabinoid: string): string {
    const cuom = this.getProductCannabisUnitOfMeasure();
    const minCannabinoids = this?.variants
      ?.map(v => v[`min${cannabinoid}`])
      ?.filterNulls()
      ?.sort(SortUtils.numericStringAsc);
    let val: string;
    if (minCannabinoids?.length > 0) {
      val = minCannabinoids.reduce((accu, curr) => accu ?? curr);
    }
    return (!!val && (cuom !== CannabisUnitOfMeasure.NA)) ? val + cuom : '';
  }

  getMaxCannabinoidWithUnits(cannabinoid: string): string {
    const cuom = this.getProductCannabisUnitOfMeasure();
    let val: string;
    const displayAttributeMaxCannabinoid = this?.displayAttributes?.getMaxCannabinoidOrTerpene(cannabinoid);
    if (!!displayAttributeMaxCannabinoid) {
      val = displayAttributeMaxCannabinoid;
    } else {
      const maxCannabinoids = this?.variants
        ?.map(v => v.getNumericMaxCannabinoidOrTerpene(cannabinoid))
        ?.filterNulls()
        ?.filter(v => v !== Variant.invalidParsedCannabinoidOrTerpene)
        ?.sort(SortUtils.numberDescNullsLast);
      if (maxCannabinoids?.length > 0) {
        val = `${maxCannabinoids.reduce((accu, curr) => accu ?? curr)}`;
      }
    }
    return (!!val && (cuom !== CannabisUnitOfMeasure.NA)) ? val + cuom : '';
  }

  getMaxCannabinoidWithoutDisplayAttributes(cannabinoid: string): string {
    const cuom = this.getProductCannabisUnitOfMeasure();
    const maxCannabinoids = this?.variants
      ?.map(v => v[`max${cannabinoid}`])
      ?.filterNulls()
      ?.sort(SortUtils.numericStringDesc);
    let val: string;
    if (maxCannabinoids?.length > 0) {
      val = maxCannabinoids.reduce((accu, curr) => accu ?? curr);
    }
    return (!!val && (cuom !== CannabisUnitOfMeasure.NA)) ? val + cuom : '';
  }

  getCannabiniodWithUnits(cannabinoid: string): string {
    const cuom = this.getProductCannabisUnitOfMeasure();
    let val: string = '';
    const cannabinoids = this.variants
      ?.flatMap(v => {
        return v.useCannabinoidRange
          ? [v.getNumericMinCannabinoidOrTerpene(cannabinoid), v.getNumericMaxCannabinoidOrTerpene(cannabinoid)]
          : [v.getNumericCannabinoidOrTerpene(cannabinoid)];
      })
      ?.filterNulls()
      ?.filter(v => v !== Variant.invalidParsedCannabinoidOrTerpene)
      ?.sort(SortUtils.numberAscNullsLast)
      ?.unique(true);
    if (cannabinoids.length > 1) {
      // If at least two unique values exist, show as range
      val = `${cannabinoids?.firstOrNull()} - ${cannabinoids?.last()}`;
    } else if (cannabinoids.length === 1) {
      val = `${cannabinoids?.firstOrNull()}`;
    }
    return (!!val && (cuom !== CannabisUnitOfMeasure.NA)) ? val + cuom : '';
  }

  getCannabinoidWithoutDisplayAttributes(cannabinoid: string): string {
    const cuom = this.getProductCannabisUnitOfMeasure();
    const cannabinoids = this?.variants
      ?.map(v => v[cannabinoid])
      ?.filterNulls()
      ?.sort(SortUtils.numericStringDesc);
    let val: string;
    if (cannabinoids?.length > 0) {
      val = cannabinoids.reduce((accu, curr) => accu ?? curr);
    }
    return (!!val && (cuom !== CannabisUnitOfMeasure.NA)) ? val + cuom : '';
  }

  getTopTerpeneString(): string {
    const uniqueTopTerpenes = this.variantsFilteredByTable?.map(v => v?.getVariantTopTerpene())?.uniqueInstance();
    if (!!uniqueTopTerpenes) {
      // If all Top Terpenes are the same, then show that
      return uniqueTopTerpenes;
    } else {
      const topTerpenes = this.variants?.map(v => v?.getVariantTopTerpene());

      let firstTopTerpene: string;
      if (topTerpenes?.length > 0) {
        firstTopTerpene = topTerpenes.reduce((accu, curr) => accu || curr);
      }
      const topTerpeneCount = this.variantsFilteredByTable?.map(v => v?.getVariantTopTerpene())?.unique()?.length ?? 0;
      return (topTerpeneCount > 1) ? `${firstTopTerpene} (${topTerpeneCount})` : firstTopTerpene;
    }
  }

  getTotalTerpenesWithUnits(): string {
    const tuom = this.getProductTerpeneUnitOfMeasure();
    let val: string = '';
    const totalTerpenes = this?.variants
      ?.map(v => v?.getTotalTerpenes())
      ?.filterNulls()
      ?.sort(SortUtils.numericStringAsc)
      ?.unique(true);
    if (totalTerpenes.length > 1) {
      val = `${totalTerpenes?.firstOrNull()} - ${totalTerpenes?.last()}`;
    } else if (totalTerpenes.length === 1) {
      val = `${totalTerpenes.firstOrNull()}`;
    }
    return (!!val && (tuom !== TerpeneUnitOfMeasure.NA)) ? val + tuom : '';
  }

  getTerpeneWithUnits(terpene: string): string {
    const tuom = this.getProductTerpeneUnitOfMeasure();
    let val: string = '';
    const terpenes = this.variants
      ?.flatMap(v => {
        return v.useCannabinoidRange
          ? [
            v.getNumericMinCannabinoidOrTerpene(terpene),
            v.getNumericMaxCannabinoidOrTerpene(terpene)
          ]
          : [v.getNumericCannabinoidOrTerpene(terpene)];
      })
      ?.filterNulls()
      ?.filter(v => v !== Variant.invalidParsedCannabinoidOrTerpene)
      ?.sort(SortUtils.numberAscNullsLast)
      ?.unique(true);
    if (terpenes.length > 1) {
      // If at least two unique values exist, show as range
      val = `${terpenes?.firstOrNull()} - ${terpenes?.last()}`;
    } else if (terpenes.length === 1) {
      val = `${terpenes?.firstOrNull()}`;
    }
    return (!!val && (tuom !== TerpeneUnitOfMeasure.NA)) ? val + tuom : '';
  }

  getMinTerpeneWithUnits(terpene: string): string {
    const tuom = this.getProductTerpeneUnitOfMeasure();
    let val: string;
    const minTerpenes = this?.variants
        ?.map(v => v.getNumericMinCannabinoidOrTerpene(terpene))
        ?.filterNulls()
        ?.filter(v => v !== Variant.invalidParsedCannabinoidOrTerpene)
        ?.sort(SortUtils.numberAscNullsLast);
      if (minTerpenes?.length > 0) {
        val = `${minTerpenes.reduce((accu, curr) =>  accu ?? curr)}`;
      }
    return (!!val && (tuom !== TerpeneUnitOfMeasure.NA)) ? val + tuom : '';
  }

  getMaxTerpeneWithUnits(terpene: string): string {
    const tuom = this.getProductTerpeneUnitOfMeasure();
    let val: string;
    const maxTerpenes = this?.variants
      ?.map(v => v.getNumericMaxCannabinoidOrTerpene(terpene))
      ?.filterNulls()
      ?.filter(v => v !== Variant.invalidParsedCannabinoidOrTerpene)
      ?.sort(SortUtils.numberDescNullsLast);
    if (maxTerpenes?.length > 0) {
      val = `${maxTerpenes.reduce((accu, curr) => accu ?? curr)}`;
    }
    return (!!val && (tuom !== TerpeneUnitOfMeasure.NA)) ? val + tuom : '';
  }

  getSwitchEnabled(): boolean {
    return false;
  }

  getLowestPrice(
    locationId: number,
    companyId: number,
    priceFormat: PriceFormat,
    ignoreSalePrice: boolean = false
  ): number {
    const prices = this.variants
      ?.map(v => v.getVisiblePrice(locationId, companyId, priceFormat, ignoreSalePrice))
      ?.filterFalsies()
      ?.sort(SortUtils.numberAscNullsLast);
    const nPrices = prices.length ?? 0;
    if (nPrices === 0 || nPrices === null || nPrices === undefined) {
      return null;
    } else {
      return prices.firstOrNull();
    }
  }

  getHighestPrice(
    locationId: number,
    priceFormat: PriceFormat,
    ignoreSalePrice: boolean = false,
  ): number {
    const prices = this.variants
      ?.map(v => v.getVisiblePrice(locationId, null, priceFormat, ignoreSalePrice))
      ?.filterFalsies()
      ?.sort(SortUtils.numberDescNullsLast);
    const nPrices = prices.length ?? 0;
    if (nPrices === 0 || nPrices === null || nPrices === undefined) {
      return null;
    } else {
      return prices.firstOrNull();
    }
  }

  getPriceNoDollarSigns(
    locationId: number,
    priceFormat: PriceFormat,
    ignoreSalePrice: boolean = false
  ): string|null {
    const prices = this.variants
      ?.map(v => v.getVisiblePrice(locationId, null, priceFormat, ignoreSalePrice))
      ?.filterFalsies()
      ?.sort(SortUtils.numberAscNullsLast);
    const nPrices = prices?.length ?? 0;
    if (nPrices === 0 || nPrices === null || nPrices === undefined) {
      return null;
    } else if (nPrices === 1 || prices?.firstOrNull() === prices?.last()) {
      return `${prices?.firstOrNull()}`;
    } else {
      return `${prices?.firstOrNull()} - ${prices?.last()}`;
    }
  }

  getSecondaryPriceNoDollarSigns(
    locationId: number,
    companyId: number
  ): string|null {
    const secondaryPrices = this.variants
      ?.map(v => v?.getSecondaryPrice(locationId, companyId))
      ?.filterFalsies()
      ?.sort(SortUtils.numberAscNullsLast);
    const nSecondaryPrices = secondaryPrices?.length ?? 0;
    if (nSecondaryPrices === 0 || nSecondaryPrices === null || nSecondaryPrices === undefined) {
      return null;
    } else if (nSecondaryPrices === 1 || secondaryPrices?.firstOrNull() === secondaryPrices?.last()) {
      return `${secondaryPrices?.firstOrNull()}`;
    } else {
      return `${secondaryPrices?.firstOrNull()} - ${secondaryPrices?.last()}`;
    }
  }

  deepCopyAndReplaceInventory(updatedInventory: Product): Product {
    const deepCopy = window?.injector?.Deserialize?.instanceOf(Product, this);
    deepCopy.takeLatestInventory(updatedInventory);
    return deepCopy;
  }

  private takeLatestInventory(updatedProduct: Product): void {
    this.variants?.forEach(v => {
      const updatedVariant = updatedProduct?.variants?.find(updated => updated.id === v.id);
      const updatedLastModified = updatedVariant?.inventory?.lastModified ?? 0;
      const currentLastModified = v?.inventory?.lastModified ?? 0;
      if (updatedLastModified > currentLastModified) {
        v.inventory = window?.injector?.Deserialize?.instanceOf(VariantInventory, updatedVariant.inventory);
      }
    });
  }

  public getBrand(): string {
    return StringUtils.getStringMode(this.variants?.filter(v => !!v.brand).map(v => v.brand))?.trim();
  }

  public getManufacturer(): string {
    return StringUtils.getStringMode(this.variants?.filter(v => !!v.manufacturer).map(v => v.manufacturer));
  }

  public shallowCopyAndMergeVariants(sameProduct: Product): Product {
    const shallowCopy = Object.assign(new Product(), this);
    shallowCopy.variants = [...(this.variants || []), ...(sameProduct?.variants || [])].uniqueByProperty('id');
    return shallowCopy;
  }

  public shallowCopyAndReplaceVariants(newVariants: Variant[]): Product {
    const shallowCopy = Object.assign(new Product(), this);
    shallowCopy.variants = newVariants;
    return shallowCopy;
  }

  public replaceVariantAndShallowCopyListIfVariantExists(variant: Variant): void {
    const updatedVariants = this.variants?.shallowCopy() || [];
    const index = updatedVariants?.findIndex(v => v.id === variant.id);
    if (index > -1) {
      updatedVariants[index] = variant;
      this.variants = updatedVariants;
    }
  }

  splitIntoActiveAndDiscontinuedIfToggled(companyConfig: CompanyConfiguration): Product[] {
    const discontinuedVariants = this.variants?.filter(v => v?.isDiscontinued);
    if (companyConfig?.showDiscontinuedProducts || !discontinuedVariants?.length) {
      return [this];
    }
    const activeVariants = this.variants?.filter(v => !v?.isDiscontinued);
    const activeProduct = this.shallowCopyAndReplaceVariants(activeVariants);
    const discontinuedProduct = this.shallowCopyAndReplaceVariants(discontinuedVariants);
    return [activeProduct, discontinuedProduct];
  }

  isActive(): boolean {
    return this.variants?.every(v => !v.isDiscontinued);
  }

  forceToActive(): void {
    this.variants?.forEach(v => v.isDiscontinued = false);
  }

  removeOverrideGroupName(): void {
    this.overrideGroupName = null;
  }

  /* ******************************* Managing Display Attributes ******************************* */

  public replaceDisplayAttributesUsingGlobalPool(
    locationDisplayAttributes: DisplayAttribute[],
    companyDisplayAttributes: DisplayAttribute[],
  ): void {
    this.displayAttributes = null;
    const locationProductDA = locationDisplayAttributes?.find(da => da.isForProduct() && da.objectId === this.id);
    const companyProductDA = companyDisplayAttributes?.find(da => da.isForProduct() && da.objectId === this.id);
    if (exists(locationProductDA)) {
      this.displayAttributes = locationProductDA;
    }
    exists(this.displayAttributes)
      ? this.displayAttributes.setInheritedDisplayAttribute(companyProductDA || null)
      : this.displayAttributes = companyProductDA || null;
    this.variants?.forEach(v => {
      v.displayAttributes = null;
      const locationVariantDA = locationDisplayAttributes?.find(da => da.isForVariant() && da.objectId === v.id);
      const companyVariantDA = companyDisplayAttributes?.find(da => da.isForVariant() && da.objectId === v.id);
      if (exists(locationVariantDA)) {
        v.displayAttributes = locationVariantDA;
      }
      exists(v.displayAttributes)
        ? v.displayAttributes.setInheritedDisplayAttribute(companyVariantDA || null)
        : v.displayAttributes = companyVariantDA || null;
    });
  }

  updateDisplayAttributes(
    attrsToUpdate: DisplayAttribute[],
    attrsToRemove: DisplayAttribute[]
  ): void {
    const forProduct = da => da.isForProduct() && da.objectId === this.id;
    const productDAsToRemove = attrsToRemove?.filter(forProduct);
    const productDAsToUpdate = attrsToUpdate?.filter(forProduct);
    productDAsToRemove?.forEach(da => this.removeProductDisplayAttribute(da));
    productDAsToUpdate?.forEach(da => this.updateProductDisplayAttribute(da));
    const forVariant = da => da.isForVariant() && this.variants?.some(v => v.id === da.objectId);
    const variantDAsToRemove = attrsToRemove?.filter(forVariant);
    const variantDAsToUpdate = attrsToUpdate?.filter(forVariant);
    variantDAsToRemove?.forEach(da => this.removeVariantDisplayAttribute(da));
    variantDAsToUpdate?.forEach(da => this.updateVariantDisplayAttribute(da));
  }

  private removeProductDisplayAttribute(toRemove: DisplayAttribute): void {
    DisplayAttribute.removeDisplayAttributeFrom(this, toRemove);
  }

  private removeVariantDisplayAttribute(variantDisplayAttrToRemove: DisplayAttribute): void {
    this.variants
      ?.find(v => v?.id === variantDisplayAttrToRemove?.objectId)
      ?.removeVariantDisplayAttribute(variantDisplayAttrToRemove);
  }

  private updateProductDisplayAttribute(toUpdate: DisplayAttribute): void {
    DisplayAttribute.updateDisplayAttributeWithin(this, toUpdate);
  }

  private updateVariantDisplayAttribute(variantDisplayAttrToUpdate: DisplayAttribute): void {
    this.variants
      ?.find(v => v?.id === variantDisplayAttrToUpdate?.objectId)
      ?.updateVariantDisplayAttribute(variantDisplayAttrToUpdate);
  }

  setSortingProperties(
    locationId: number,
    companyId: number,
    priceFormat: PriceFormat,
    systemLabels: SystemLabel[],
    hideSale: boolean,
  ): void {
    this.title = this.getProductTitle();
    this.variants?.forEach(v => v?.setSortingProperties(locationId, companyId, priceFormat, systemLabels, hideSale));
  }

  /* ******************************************************************************************* */

}
