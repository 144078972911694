import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, defer, iif, Observable, of, throwError } from 'rxjs';
import { map, shareReplay, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { SectionCreationType } from '../../../../../models/enum/shared/section-creation-type.enum';
import { Section } from '../../../../../models/menu/dto/section';
import { Theme } from '../../../../../models/menu/dto/theme';
import { NewMenuSectionRequest } from '../../../../../models/menu/dto/new-menu-section-request';
import { SelectableItem } from '../../../../../models/shared/selectable-item';
import { SectionBlueprintsDomainModel } from '../../../../../domainModels/section-blueprints-domain-model';
import { SingleSelectionItem } from '../../../../../models/shared/stylesheet/single-selection-item';
import { SingleSelectionItemGrouping } from '../../../../../models/shared/stylesheet/single-selection-item-grouping';
import { SectionBlueprint } from '../../../../../models/template/dto/section-blueprint';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';
import { Menu } from '../../../../../models/menu/dto/menu';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { SectionTemplate } from '../../../../../models/template/dto/section-template';
import { DuplicateTemplateSectionRequest } from '../../../../../models/menu/shared/duplicate-template-section-request';
import { DuplicateMenuSectionRequest } from '../../../../../models/menu/shared/duplicate-menu-section-request';
import { BsError } from '../../../../../models/shared/bs-error';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { MarketingTheme } from '../../../../../models/enum/dto/theme.enum';
import { SectionType } from '../../../../../models/enum/dto/section-type';

export enum SectionCreationStep {
  HowToCreate,
  SelectBlueprint,
  NewSection
}

@Injectable()
export class NewMenuSectionContainer extends BaseModalViewModel {

  constructor(
    private domainModel: SectionBlueprintsDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private menuDomainModel: MenuDomainModel,
    private templateDomainModel: TemplateDomainModel,
    private toastService: ToastService,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  private _newSectionRequest = new BehaviorSubject<NewMenuSectionRequest>(new NewMenuSectionRequest());
  public newSectionRequest$ = this._newSectionRequest as Observable<NewMenuSectionRequest>;
  connectToNewSectionRequest = (sectionRequest: NewMenuSectionRequest) => this._newSectionRequest.next(sectionRequest);
  public newSectionType$ = this.newSectionRequest$.pipe(map(newSection => newSection?.sectionType));

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu as Observable<Menu>;
  connectToMenu = (menu: Menu) => this._menu.next(menu);

  public dispersedKey$ = of('createMenuSection');

  private _creationType = new BehaviorSubject<SectionCreationType>(null);
  public creationType$ = this._creationType as Observable<SectionCreationType>;
  connectToCreationType = (type: SectionCreationType) => this._creationType.next(type);

  private _creationStep = new BehaviorSubject<SectionCreationStep>(SectionCreationStep.HowToCreate);
  public creationStep$ = this._creationStep as Observable<SectionCreationStep>;

  private setCreationStepFromCreationType = this.creationType$.pipe(
    withLatestFrom(this.menu$)
  ).subscribeWhileAlive({
    owner: this,
    next: ([creationType, menu]) => {
      if (!!creationType) {
        switch (creationType) {
          case SectionCreationType.FromBlueprint:
            this._creationStep.next(SectionCreationStep.SelectBlueprint);
            const sectionType = menu?.theme === MarketingTheme.SmartPlaylist
              ? SectionType.ProductGroup
              : SectionType.Product;
            this._newSectionRequest.next(new NewMenuSectionRequest(null, '', null, sectionType));
            break;
          case SectionCreationType.New:
            this._creationStep.next(SectionCreationStep.NewSection);
            break;
        }
      } else {
        this._creationStep.next(SectionCreationStep.HowToCreate);
      }
    }
  });

  private _isDuplicating = new BehaviorSubject<boolean>(false);
  public isDuplicating$ = this._isDuplicating as Observable<boolean>;
  connectToIsDuplicating = (isDuplicating: boolean) => this._isDuplicating.next(isDuplicating);

  private _sectionToDuplicate = new BehaviorSubject<Section>(null);
  public sectionToDuplicate$ = this._sectionToDuplicate as Observable<Section>;
  connectToSectionToDuplicate = (section: Section) => this._sectionToDuplicate.next(section);

  private _menuTheme = new BehaviorSubject<Theme>(null);
  public menuTheme$ = this._menuTheme as Observable<Theme>;
  connectToMenuTheme = (menuTheme: Theme) => this._menuTheme.next(menuTheme);

  private _formIsValid = new BehaviorSubject<boolean>(false);
  public formIsValid$ = this._formIsValid as Observable<boolean>;
  connectToFormIsValid = (formIsValid: boolean) => this._formIsValid.next(formIsValid);

  private _formHasErrors = new BehaviorSubject<boolean>(false);
  public formHasErrors$ = this._formHasErrors as Observable<boolean>;
  connectToFormHasErrors = (formHasErrors: boolean) => this._formHasErrors.next(formHasErrors);

  private _blueprintSelected = new BehaviorSubject<SectionBlueprint>(null);
  public blueprintSelected$ = this._blueprintSelected as Observable<SectionBlueprint>;
  connectToBlueprintSelected = (blueprintSelected: SectionBlueprint) => this._blueprintSelected.next(blueprintSelected);

  public canSubmitForm$ = combineLatest([
    this.formIsValid$,
    this.blueprintSelected$,
    this.isDuplicating$,
    this.formHasErrors$
  ]).pipe(
    map(([formIsValid, blueprintSelected, isDuplicating, formHasErrors]) => {
      if (isDuplicating) return !formHasErrors;
      return formIsValid || !!blueprintSelected;
    })
  );

  public modalTitle$ = combineLatest([
    this.isDuplicating$,
    this.creationStep$,
    this.newSectionType$
  ]).pipe(
    map(([isDuplicating, creationStep, newSectionType]) => {
      const noun = this.getSectionNoun(newSectionType);
      if (isDuplicating) {
        return `Duplicate ${noun}`;
      }
      if (creationStep === SectionCreationStep.SelectBlueprint) {
        return 'Select Blueprint Section';
      }
      return `Add New ${noun}`;
    })
  );

  private getSectionNoun(sectionType: SectionType): string {
    switch (sectionType) {
      case SectionType.ProductGroup:
        return 'Product Grouping';
      case SectionType.CategoryCard:
        return 'Featured Category';
      default:
        return 'Section';
    }
  }

  public creationStepIsHowToCreate$ = this.creationStep$.pipe(
    map(step => step === SectionCreationStep.HowToCreate)
  );

  public creationStepIsSelectBlueprint$ = this.creationStep$.pipe(
    map(step => step === SectionCreationStep.SelectBlueprint)
  );

  public creationStepIsNewSection$ = this.creationStep$.pipe(
    map(step => step === SectionCreationStep.NewSection)
  );

  public hideGoBack$ = combineLatest([
    this.isDuplicating$,
    this.creationStepIsHowToCreate$,
    this.newSectionType$
  ]).pipe(
    map(([isDuplicating, creationStepIsHowToCreate, newSectionType]) => {
      const creatingCategoryCard = newSectionType === SectionType.CategoryCard;
      return isDuplicating || creationStepIsHowToCreate || creatingCategoryCard;
    })
  );

  public sectionTypes$ = this.menu$.pipe(
    switchMap(menu => !!menu ? window.types.getSectionTypes(menu) : of(null))
  );

  public columnOptions$ = this.companyDomainModel.companyConfiguration$.pipe(
    map(config => config?.defaultColumnConfigs),
    map(defaultSectionColumnConfig => {
      const createSelectableItem = cc => new SelectableItem(cc.name, cc.id, cc.name + cc.id + cc.columnConfig);
      return defaultSectionColumnConfig.map(createSelectableItem);
    }),
    startWith([]),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public productBlueprintsGroupedByCategory$ = combineLatest([
    this.newSectionType$,
    this.domainModel.sectionBlueprints$,
    this.domainModel.sectionBlueprintCategories$
  ]).pipe(
    map(([sectionType, blueprints, categories]) => {
      let selectableBlueprints = blueprints;
      if (sectionType === SectionType.ProductGroup) {
        selectableBlueprints = blueprints?.filter(bp => bp?.sectionType === SectionType.ProductGroup && !!bp?.asset);
      } else {
        selectableBlueprints = blueprints?.filter(bp => bp?.sectionType === SectionType.Product);
      }
      const groupedSelectableItems = categories
        ?.filter(c => c?.allowedSectionTypes?.includes(sectionType))
        ?.map(c => {
          const blueprintsForCategory = selectableBlueprints?.filter(bp => bp?.categoryId === c?.id);
          const selectableItems = blueprintsForCategory?.map(bp => {
            return new SingleSelectionItem(
              bp?.title,
              NewMenuSectionContainer.makeBlueprintBody(bp),
              bp,
              c?.id,
              c?.name
            );
          });
        return new SingleSelectionItemGrouping(c?.name, selectableItems, c?.id);
      }) || [];
      const ungrouped = selectableBlueprints?.filter(bp => !bp?.categoryId);
      const ungroupedItems = ungrouped?.map(bp => {
        return new SingleSelectionItem(bp?.title, NewMenuSectionContainer.makeBlueprintBody(bp), bp, '-1');
      });
      groupedSelectableItems.push(new SingleSelectionItemGrouping('Uncategorized', ungroupedItems, '-1'));
      return groupedSelectableItems.filter(i => i.items?.length > 0);
    })
  );

  private static makeBlueprintBody(bp: SectionBlueprint): string {
    return `${bp?.smartFilterIds?.length || 0} Smart Filter`
      .pluralizer()
      .addRule({
        listConnection: bp?.smartFilterIds,
        useApostrophe: false,
        word: 'Filter',
      })
      .pluralize();
  }

  setSelectedBlueprint(selectedItem: SingleSelectionItem) {
    const blueprint: SectionBlueprint = selectedItem?.value;
    this._blueprintSelected.next(blueprint);
  }

  addSection() {
    combineLatest([
      combineLatest([this.menu$, this.isDuplicating$, this.creationStep$]),
      combineLatest([this.sectionToDuplicate$, this.blueprintSelected$, this.newSectionRequest$])
    ]).once(([
      [menu, isDuplicating, step],
      [sectionToDup, blueprint, newSectionReq]
    ]) => {
      const loadingOpts = this._loadingOpts;
      const noun = this.getSectionNoun(sectionToDup?.sectionType);
      const lm = isDuplicating ? `Duplicating ${noun}` : `Creating ${noun}`;
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        const request$ = this.getSectionRequest(menu, isDuplicating, sectionToDup, newSectionReq, step, blueprint);
        request$.subscribe({
          next: (createdSection) => {
            this.toastService.publishSuccessMessage(
              `Section ${isDuplicating ? 'duplicated' : 'created'} successfully.`,
              `Section ${isDuplicating ? 'Duplicated' : 'Created'}`
            );
            loadingOpts.removeRequest(lm);
            this.activeModal.close(createdSection);
          },
          error: (err: BsError) => {
            loadingOpts.removeRequest(lm);
            this.toastService.publishError(err);
            throwError(() => err);
          }
        });
      }
    });
  }

  protected getSectionRequest(
    menu: Menu,
    isDuplicating: boolean,
    sectionToDuplicate: Section,
    newSectionReq: NewMenuSectionRequest,
    step: SectionCreationStep,
    blueprint: SectionBlueprint
  ): Observable<Section> {
    const create$ = defer(() => {
      newSectionReq.addConfigurationId(menu?.id);
      if (step === SectionCreationStep.SelectBlueprint) {
        newSectionReq.useDataFrom(blueprint);
      }
      newSectionReq.priority = menu?.getNextSectionPriority();
      return menu instanceof MenuTemplate
        ? this.templateDomainModel.createSectionTemplate(menu?.id, newSectionReq)
        : this.menuDomainModel.createMenuSection(menu?.id, newSectionReq);
    });
    const dup$ = defer(() => {
      sectionToDuplicate.title = newSectionReq.title;
      const duplicateSectionReq = sectionToDuplicate instanceof SectionTemplate
        ? new DuplicateTemplateSectionRequest(sectionToDuplicate)
        : new DuplicateMenuSectionRequest(sectionToDuplicate);
      return duplicateSectionReq instanceof DuplicateTemplateSectionRequest
        ? this.templateDomainModel.duplicateSectionTemplate(duplicateSectionReq)
        : this.menuDomainModel.duplicateMenuSection(duplicateSectionReq);
    });
    return iif(() => isDuplicating, dup$, create$);
  }

}
