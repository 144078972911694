import { Pipe, PipeTransform } from '@angular/core';
import type { TerpenePreviewInfo } from '../terpenes-preview-view-model';
import type { Terpene } from '../../../../../../../../../models/enum/dto/terpene';

@Pipe({
  name: 'getTerpenePreviewInfoFor'
})
export class GetTerpenePreviewInfoForPipe implements PipeTransform {

  /**
   * @param previewInfoList list of all preview info
   * @param terpene can be min${Terpene} and max${Terpene}, totalTerpene, topTerpene
   */
  transform(
    previewInfoList: TerpenePreviewInfo[],
    terpene: Terpene | string
  ): TerpenePreviewInfo | null {
    return previewInfoList?.find(it => terpene === it?.terpene) ?? null;
  }

}
