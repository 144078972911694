import { Deserializable } from '../../protocols/deserializable';
import { SectionType } from '../../enum/dto/section-type';

export class SectionBlueprintCategory implements Deserializable {

  public companyId: number;
  public id: string;
  public name: string;
  public allowedSectionTypes: SectionType[];

  onDeserialize() {
    this.allowedSectionTypes = Array.from(this.allowedSectionTypes || []);
  }

}
