import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';
import { PriceFormat } from '../../../models/enum/dto/price-format';

@Pipe({
  name: 'isVariantOnSale'
})
export class IsVariantOnSalePipe implements PipeTransform {

  transform(locationId: number, variant: Variant, priceFormat: PriceFormat): boolean {
    if (locationId > -1) {
      return variant?.onSale(locationId, undefined, priceFormat);
    } else {
      return false;
    }
  }

}
