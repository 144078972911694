import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintCardLiveViewComponent } from './print-cards/print-card-live-view/print-card-live-view.component';
import { RxPush } from '@rx-angular/template/push';
import { PrintCardLiveViewModalComponent } from './print-cards/print-card-live-view-modal/print-card-live-view-modal.component';
import { SharedModule } from '../shared/shared.module';
import { ReactiveButtonsModule } from '@mobilefirstdev/reactive-buttons';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { GetPrintCardOptionTitlePipe } from './print-cards/print-card-live-view-modal/get-print-card-option-title.pipe';
import { PrintMenuLiveViewComponent } from './print-menus/print-menu-live-view/print-menu-live-view.component';
import { PrintMenuLiveViewModalComponent } from './print-menus/print-menu-live-view-modal/print-menu-live-view-modal.component';
import { IsArrowButtonSelectedPipe } from './shared/is-arrow-button-selected.pipe';
import { PrintLabelLiveViewModalComponent } from './print-labels/print-label-live-view-modal/print-label-live-view-modal.component';
import { PrintShelfTalkerLiveViewModalComponent } from './shelf-talkers/print-shelf-talker-live-view-modal/print-shelf-talker-live-view-modal.component';
import { PrintShelfTalkerLiveViewComponent } from './shelf-talkers/print-shelf-talker-live-view/print-shelf-talker-live-view.component';
import { GetMissingPropertiesForVariantPipe } from './get-missing-properties-for-variant.pipe';

@NgModule({
  declarations: [
    PrintCardLiveViewComponent,
    PrintCardLiveViewModalComponent,
    PrintLabelLiveViewModalComponent,
    GetPrintCardOptionTitlePipe,
    PrintMenuLiveViewComponent,
    PrintMenuLiveViewModalComponent,
    IsArrowButtonSelectedPipe,
    PrintShelfTalkerLiveViewModalComponent,
    PrintShelfTalkerLiveViewComponent,
    GetMissingPropertiesForVariantPipe
  ],
  imports: [
    CommonModule,
    RxPush,
    SharedModule,
    ReactiveButtonsModule,
    RxFor,
    RxIf
  ],
  exports: [
    PrintCardLiveViewComponent
  ]
})
export class LiveViewModule {
}
