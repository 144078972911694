// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.staging.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'dev',
  webSocketUrl: 'wss://cgnadyrnq4.execute-api.us-west-2.amazonaws.com/dev',
  mainServiceUrl: 'https://1atnrjljz3.execute-api.us-west-2.amazonaws.com/dev',
  accountServiceUrl: 'https://os24szc8tc.execute-api.us-west-2.amazonaws.com/dev',
  automationServiceUrl: 'https://rz8bk0stv4.execute-api.us-west-2.amazonaws.com/dev',
  imageServiceUrl: 'https://i6tg826c0a.execute-api.us-west-2.amazonaws.com/dev',
  productServiceUrl: 'https://abjr2ew223.execute-api.us-west-2.amazonaws.com/dev',
  menuServiceUrl: 'https://qko0xxzck8.execute-api.us-west-2.amazonaws.com/dev',
  deviceServiceUrl: 'https://2dv3i6a9u3.execute-api.us-west-2.amazonaws.com/dev',
  templateServiceUrl: 'https://pj2w8xiiue.execute-api.us-west-2.amazonaws.com/dev',
  cognitoClientSecret: '9sp64sinb3chskqarp88i9aqgbssd6er98vu9or7n7i4o80ilqa',
  displayRefreshThreshold: 120,
  menuPreviewRefreshThreshold: 300,
  firebaseConfig: {
    apiKey: 'AIzaSyA6m9TAKWK3SeVdizi0mA33Yu_8197no0s',
    authDomain: 'budsense-staging.firebaseapp.com',
    projectId: 'budsense-staging',
    storageBucket: 'budsense-staging.appspot.com',
    messagingSenderId: '3775232139',
    appId: '1:3775232139:web:6e6425a53682e19dcdbc8e',
    measurementId: 'G-BRNK4R3284'
  }
};
