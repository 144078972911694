import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { ModalUtils } from '../utils/modal-utils';
import { MakeVariantActiveModalComponent } from '../views/product/components/modals/make-variant-active-modal/make-variant-active-modal.component';
import { VariantInventory } from '../models/product/dto/variant-inventory';

export class ModalMakeVariantActive {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    inventories: VariantInventory[],
    onClose?: (reactivated: boolean) => void
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(
        MakeVariantActiveModalComponent,
        ModalUtils.makeVariantActiveModalOptions(injector)
      );
      const compInstance = modalRef.componentInstance as MakeVariantActiveModalComponent;
      compInstance.setInventories(inventories);
      modalRef.result.then(reactivated => onClose?.(reactivated)).catch(() => {});
    });
  }

}

