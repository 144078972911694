import { Size } from '../../shared/size';
import { DisplayOptions } from '../../shared/display-options';
import type { Menu } from '../../menu/dto/menu';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Orderable } from '../../protocols/orderable';
import { Selectable } from '../../protocols/selectable';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { HasId } from '../../protocols/has-id';
import { environment } from '../../../../environments/environment';
import { Pagable } from '../../protocols/pagable';
import { BaseDisplayProperties } from '../../protocols/base-display-properties';

/**
 * Useful information:
 * - options.rotationInterval for marketing playlist menus
 *   - not the total time in seconds
 *   - it's the number of times to loop through marketing content
 */
export abstract class BaseDisplay
  implements Orderable, UniquelyIdentifiable, Selectable, LookAheadItem, HasId, Pagable, BaseDisplayProperties {

  id: string;
  companyId: number;
  displaySize: Size;
  name: string;
  options: DisplayOptions;
  priority: number;
  lastModified: number;

  abstract getMenus(): Menu[];

  onDeserialize() {
    this.displaySize = window?.injector?.Deserialize?.instanceOf(Size, this.displaySize);
    this.options = window?.injector?.Deserialize?.instanceOf(DisplayOptions, this.options);
  }

  // Expected go model
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/BaseDisplayDTO.go
  onSerialize(): BaseDisplay {
    const dto: BaseDisplay = Object.create(BaseDisplay.prototype);
    dto.id = this.id;
    dto.companyId = this.companyId;
    dto.displaySize = this.displaySize;
    dto.name = this.name;
    dto.options = this.options?.onSerialize();
    dto.priority = this.priority;
    return dto;
  }

  getId(): string {
    return this.id;
  }

  getOrderValue(): number {
    return this.priority;
  }

  getOrderableTitle(): string {
    return this.name;
  }

  getOrderableUniqueId(): string {
    return this.id;
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): string {
    return this.id;
  }

  getSelectionValue(): any {
    return this;
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  setOrderableValue(val: number) {
    this.priority = val;
  }

  public generateDisplayUrl(locationId: number, hideDownloadToast: boolean = true): string {
    const cacheBuster = '&cacheBuster=' + Date.now() + Math.floor(Math.random() * 1000000);
    const stage = (environment.production && environment.environmentName !== 'rc')
      ? ''
      : `${environment.environmentName}.`;
    let url = `https://${stage}display.mybudsense.com/#/display/${this.id}/?IgnoreLastSession=true${cacheBuster}`;
    if (hideDownloadToast) url += '&hideDownloadToast=true';
    return url;
  }

  getUniqueIdentifier() {
    return `
      ${this.id}-
      ${this.companyId}-
      ${this.name}-
      ${this.priority}-
      ${this.displaySize?.getUniqueIdentifier()}-
      ${this.options?.getUniqueIdentifier()}-
      ${this.lastModified}
    `;
  }

  getStartKey(): string {
    return this.id;
  }

}
