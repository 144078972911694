import { Injectable } from '@angular/core';
import { BaseService } from '@mobilefirstdev/base-angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import type { HydratedUser } from '../../models/account/dto/hydrated-user';

/**
 * Do not inject the UserDomainModel into here, or the interceptor will have cyclic dependency injection errors.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService extends BaseService {

  constructor() {
    super();
  }

  private readonly _destroySession = new Subject<void>();
  public readonly destroySession$ = this._destroySession as Observable<void>;
  connectToDestroySession(): void {
    this._destroySession.next();
  }

  private readonly _validSessionToken = new BehaviorSubject<string>(null);
  public readonly validSessionToken$ = this._validSessionToken as Observable<string>;
  connectToValidSessionToken(token: string): void {
    this._validSessionToken.next(token);
  }

  private readonly _user = new BehaviorSubject<HydratedUser>(null);
  public readonly user$ = this._user as Observable<HydratedUser>;
  connectToUser(user: HydratedUser): void {
    this._user.next(user);
  }

  private _err403SoUpdateSessionTokenToRefreshing = new Subject<void>();
  public err403SoUpdateSessionTokenToRefreshing$ = this._err403SoUpdateSessionTokenToRefreshing as Observable<void>;
  received403SoUpdateSessionTokenToRefreshing(): void {
    this._err403SoUpdateSessionTokenToRefreshing.next();
  }

}
