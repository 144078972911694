import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { Asset } from '../../../../../models/image/dto/asset';
import { AssetPreviewOptions } from '../../../../../models/shared/stylesheet/asset-preview-options';
import { UploadImageInterface } from '../../../../shared/components/upload-asset/upload-image-interface';
import { SectionAdjustableAssetComponent } from '../../shared/section-adjustable-asset/section-adjustable-asset.component';
import { EditMenuSectionPrimaryAssetViewModel } from './edit-menu-section-primary-asset-view-model';

@Component({
  selector: 'app-edit-menu-section-primary-asset',
  templateUrl: './edit-menu-section-primary-asset.component.html',
  providers: [EditMenuSectionPrimaryAssetViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuSectionPrimaryAssetComponent extends SectionAdjustableAssetComponent {

  constructor(
    public override viewModel: EditMenuSectionPrimaryAssetViewModel
  ) {
    super(viewModel);
  }

  @Input() primaryAssetTitle: string;
  @Input() primaryAssetDesc: string;
  @Input() hideLoading: boolean;
  @Input() loadingOpts: LoadingOptions;
  @Input() templateMode: boolean;
  @Input() isTemplatedSection: boolean;
  @Input() sectionAsset: Asset;
  @Input() parentHandler: UploadImageInterface;
  @Input() sectionAssetPreview: AssetPreviewOptions;
  @Input() allowImage: boolean = true;
  @Input() allowVideo: boolean = false;
  @Input() formPristine: boolean = true;
  @Output() deleteSectionAssetClicked = new EventEmitter();
  @Output() changeSectionAssetClicked = new EventEmitter();
  @Output() refreshSectionAssetClicked = new EventEmitter<string>();

}
