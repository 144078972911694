import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuAdditionalOptionsComponent } from '../../../../../forms/menu-additional-options/menu-additional-options.component';
import { EditShelfTalkerBrandsMenuAdditionOptionsViewModel } from './edit-shelf-talker-brands-menu-additional-options-view-model';

@Component({
  selector: 'app-edit-shelf-talker-brands-menu-additional-options',
  templateUrl: './edit-shelf-talker-brands-menu-additional-options.component.html',
  styleUrls: ['./edit-shelf-talker-brands-menu-additional-options.component.scss'],
  providers: [EditShelfTalkerBrandsMenuAdditionOptionsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShelfTalkerBrandsMenuAdditionalOptionsComponent extends MenuAdditionalOptionsComponent {

  constructor(viewModel: EditShelfTalkerBrandsMenuAdditionOptionsViewModel) {
    super(viewModel);
  }

}
