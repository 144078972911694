<div class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-bold f18px">Display Details</p>
  </div>

  <div class="col-sm-8 col-md-8 col-lg-8">
    <!-- Edit display form -->
    <app-edit-display-main-section-form [mergeKey]="mergeKey" [bindTo]="display"> </app-edit-display-main-section-form>

    <hr class="mt-none" />

    <div class="url-container">
      <lib-reactive-form-group [style.width]="'100%'" [mergeKey]="mergeKey" [bindTo]="display">
        <lib-reactive-form-text
          [inputName]="'url'"
          [disabled]="true"
          [label]="'Display URL'"
          [placeholder]="'URL of the Display'"
          [bindingProperty]="'url'">
        </lib-reactive-form-text>
      </lib-reactive-form-group>
      <div [style.cursor]="'pointer'" (click)="copyDisplayUrl.emit()">
        <img [src]="'assets/icons/dark/outline/duplicate.svg'" alt="" />
      </div>
    </div>
  </div>
</div>

<hr class="mt-24px" />
