import { ChangeDetectionStrategy, Component, Injector, NgZone, ViewChild } from '@angular/core';
import { EditCardStackMenuComponent } from '../edit-card-stack-menu/edit-card-stack-menu.component';
import { EditLabelStackMenuViewModel } from './edit-label-stack-menu-view-model';
import { EditLabelStackSectionViewModel } from './edit-label-stack-section-view-model';
import { EditLabelStackMenuCombinedLoadingOptsViewModel } from './edit-label-stack-menu-combined-loading-opts-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../../../../../services/toast-service';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';

@Component({
  selector: 'app-edit-label-stack-menu',
  templateUrl: './edit-label-stack-menu.component.html',
  styleUrls: [
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [
    EditLabelStackMenuViewModel,
    EditLabelStackSectionViewModel,
    EditLabelStackMenuCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditLabelStackMenuComponent extends EditCardStackMenuComponent {

  constructor(
    public override viewModel: EditLabelStackMenuViewModel,
    public override sectionViewModel: EditLabelStackSectionViewModel,
    public override sharedLoadingState: EditLabelStackMenuCombinedLoadingOptsViewModel,
    activatedRoute: ActivatedRoute,
    router: Router,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    sanitizer: DomSanitizer,
    toastService: ToastService,
    activeRoute: ActivatedRoute,
  ) {
    super(
      viewModel,
      sectionViewModel,
      sharedLoadingState,
      activatedRoute,
      router,
      ngZone,
      ngbModal,
      injector,
      sanitizer,
      toastService,
      activeRoute
    );
  }

  @ViewChild(ReactiveFormMergeGroupsComponent) override formCapture: ReactiveFormMergeGroupsComponent;

}
