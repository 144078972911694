import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, iif, Observable } from 'rxjs';
import { CuratedVariantBadgeSection } from '../../../../models/product/dto/curated-variant-badge-section';
import { HydratedVariantBadge } from 'src/app/models/product/dto/hydrated-variant-badge';
import { BadgeDomainModel } from '../../../../domainModels/badge-domain-model';

@Injectable()
export class BadgePickerViewModel extends BaseViewModel {

  constructor(
    private badgeDomainModel: BadgeDomainModel,
  ) {
    super();
  }

  private _curatedBadgesOnly = new BehaviorSubject<boolean>(false);
  public curatedBadgesOnly$ = this._curatedBadgesOnly as Observable<boolean>;
  connectToCuratedBadgesOnly = (curatedBadgesOnly: boolean) => this._curatedBadgesOnly.next(curatedBadgesOnly);

  private _removeSmartBadgesFromPicker = new BehaviorSubject<boolean>(true);
  public removeSmartBadgesFromPicker$ = this._removeSmartBadgesFromPicker as Observable<boolean>;
  connectToRemoveSmartBadgesFromPicker = (remove: boolean) => this._removeSmartBadgesFromPicker.next(remove);

  private _searchBadges = new BehaviorSubject<[string, HydratedVariantBadge[]]>([null, null]);
  private searchBadges$ = this._searchBadges as Observable<[string, HydratedVariantBadge[]]>;

  private _searchText = new BehaviorSubject<string>(null);
  public searchText$ = this._searchText as Observable<string>;
  connectToSearchBadges = (data: [string, HydratedVariantBadge[]]) => {
    this._searchText.next(data[0]);
    this._searchBadges.next(data);
  };

  public curatedBadgeSections$ = this.badgeDomainModel.curatedBadgeSections$;
  public theirsAndCuratedBadgeSections$ = this.badgeDomainModel.theirsAndCuratedBadgeSections$;

  private badgeSections$ = this.curatedBadgesOnly$.pipe(
    switchMap(curatedOnly => {
      const curatedBadgeSections$ = this.curatedBadgeSections$;
      const theirsAndCuratedBadgeSections$ = this.theirsAndCuratedBadgeSections$;
      return iif(() => curatedOnly, curatedBadgeSections$, theirsAndCuratedBadgeSections$);
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public readonly allBadges$ = this.badgeDomainModel.allBadges$;

  badgeSectionList$ = combineLatest([
    this.badgeSections$,
    this.removeSmartBadgesFromPicker$
  ]).pipe(
    map(([sections, removeSmartBadgesFromPicker]) => {
      if (!removeSmartBadgesFromPicker) return sections || null;
      // don't manipulate the data that's stored in the domain model, manipulate a copy instead
      const copy = window?.injector?.Deserialize?.arrayOf(CuratedVariantBadgeSection, sections);
      const removeSmartFilterBadges = (section: CuratedVariantBadgeSection) => {
        section.badges = section.badges.filter((b) => !b?.smartFilterIds?.length);
      };
      copy?.forEach(removeSmartFilterBadges);
      return sections || null;
    })
  );

  public searchedBadgeSectionList$: Observable<CuratedVariantBadgeSection[]> = this.searchBadges$.pipe(
    switchMap(([searchText, searchedBadges]) => {
      if (!searchText) return this.badgeSectionList$;
      const badgeIdsWithSearchHit = searchedBadges?.map(s => s.id);
      return this.badgeSectionList$.pipe(
        map(sections => {
          return sections
            ?.map(section => section.deepCopyAndFilterSectionBySearchHits(badgeIdsWithSearchHit))
            ?.filter(section => !section.isEmpty()) || null;
        })
      );
    })
  );

  badgeSectionTrackBy = (index: number, section: CuratedVariantBadgeSection) => section?.title;
  badgeTrackBy = (index: number, badge: HydratedVariantBadge) => badge?.id;

}
