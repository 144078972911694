import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MenuType } from '../../../../models/utils/dto/menu-type-definition';
import { BaseComponent } from '../../../../models/base/base-component';
import { MenuTypePickerViewModel } from './menu-type-picker-view-model';
import { MenuSubtype } from '../../../../models/enum/dto/menu-subtype';
import { MenuCreationFlowType } from '../../../../models/utils/dto/menu-creation-flow-type';

@Component({
  selector: 'app-menu-type-picker',
  templateUrl: './menu-type-picker.component.html',
  styleUrls: ['./menu-type-picker.component.scss'],
  providers: [MenuTypePickerViewModel]
})
export class MenuTypePickerComponent extends BaseComponent implements OnChanges {

  @Input() paddingLeft = '1.5rem';
  @Input() paddingRight: string = '1.5rem';
  @Input() titleFontSize: string = '0.75rem';
  @Input() subtitleFontSize: string = '0.75rem';
  @Input() menuType: MenuType;
  @Output() selectedSubTypes = new EventEmitter<MenuSubtype[]>();
  @Output() readableSubtype = new EventEmitter<string>();
  @Output() selectedMenuType = new EventEmitter<MenuCreationFlowType>();

  constructor(
    public viewModel: MenuTypePickerViewModel
  ) {
    super();
  }

  private listenToSelectedSubType =
    this.viewModel.selectedSubtypes$.notNull().subscribeWhileAlive({
      owner: this,
      next: st => this.selectedSubTypes.emit(st)
    });

  private listenToReadableSubType =
    this.viewModel.readableSubtype$.notNull().subscribeWhileAlive({
      owner: this,
      next: rst => this.readableSubtype.emit(rst)
    });

  private listenToSelectedMenuType$ =
    this.viewModel.selectedMenuType$.notNull().subscribeWhileAlive({
      owner: this,
      next: mt => this.selectedMenuType.emit(mt)
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuType) this.viewModel.connectToMenuType(this.menuType);
  }

}
