import { EditMenuSectionViewModel } from '../../../../../viewModels/edit-menu-section-view-model';
import { MenuDomainModel } from '../../../../../../../domainModels/menu-domain-model';
import { ProductDomainModel } from '../../../../../../../domainModels/product-domain-model';
import { ToastService } from '../../../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable, Injector, NgZone } from '@angular/core';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { CompanyDomainModel } from '../../../../../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { DisplayAttributesDomainModel } from '../../../../../../../domainModels/display-attributes-domain-model';
import { CardType } from '../../../../../../../models/utils/dto/card-type-definition';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateDomainModel } from '../../../../../../../domainModels/template-domain-model';
import { UserDomainModel } from '../../../../../../../domainModels/user-domain-model';
import { LabelDomainModel } from '../../../../../../../domainModels/label-domain-model';
import { SmartFiltersDomainModel } from '../../../../../../../domainModels/smart-filters-domain-model';

@Injectable()
export class EditFeaturedCategorySectionViewModel extends EditMenuSectionViewModel {

  constructor(
    templateDomainModel: TemplateDomainModel,
    menuDomainModel: MenuDomainModel,
    productDomainModel: ProductDomainModel,
    companyDomainModel: CompanyDomainModel,
    labelDomainModel: LabelDomainModel,
    locationDomainModel: LocationDomainModel,
    displayAttributeDomainModel: DisplayAttributesDomainModel,
    userDomainModel: UserDomainModel,
    smartFiltersDomainModel: SmartFiltersDomainModel,
    toastService: ToastService,
    router: Router,
    route: ActivatedRoute,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(
      templateDomainModel,
      menuDomainModel,
      productDomainModel,
      companyDomainModel,
      labelDomainModel,
      locationDomainModel,
      displayAttributeDomainModel,
      userDomainModel,
      smartFiltersDomainModel,
      toastService,
      router,
      route,
      ngZone,
      ngbModal,
      injector
    );
  }

  public productsUnderLineDesc$ = combineLatest([
    this.menu$.notNull(),
    this.section$.notNull(),
  ]).pipe(
    map(([menu, sec]) => {
      if (sec.rowCount === 0) {
        return `Products below line will not appear on menu.`;
      } else {
        const cardType = menu.getFeaturedCategoryCardType();
        return `Anything below this line will not appear on the menu.
        ${cardType.name} cards only supports ${sec.rowCount} products per card.`;
      }
    })
  );

  public hideImageOptions$ = this.menu$.pipe(
    map(menu => menu?.metadata?.cardType === CardType.ProductDealCarousel)
  );

}
