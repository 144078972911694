import { Injectable, Injector, NgZone } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from '../../../../../services/navigation.service';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { CompactMenu } from '../../../../../models/menu/dto/compact-menu';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take } from 'rxjs/operators';
import { ConfirmationOptions } from '../../../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../../../modals/modal-confirmation';
import { ModalOverflowInformation } from '../../../../../modals/modal-overflow-information';
import { OverflowStateInfoType } from '../../../../../models/utils/dto/overflow-state-type';
import { EditDisplayViewModel } from '../../../viewModels/edit-display-view-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { ProductMenuType } from '../../../../../models/enum/dto/product-menu-type.enum';
import { MarketingMenuType } from '../../../../../models/enum/dto/marketing-menu-type.enum';

@Injectable()
export class DisplayMenuSectionViewModel extends BaseViewModel {

  constructor(
    protected editDisplayViewModel: EditDisplayViewModel,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected navigationService: NavigationService,
    protected menuDomainModel: MenuDomainModel,
    protected templateDomainModel: TemplateDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected userDomainModel: UserDomainModel,
    protected companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  public allTemplates$ = this.templateDomainModel.menuTemplates$;
  public locationId$ = this.locationDomainModel.locationId$;
  public isUserTemplateAdmin$ = this.userDomainModel.isTemplateAdmin$;
  public companySupportsTemplates$ = this.userDomainModel.canUseTemplates$;

  protected override _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.defaultWhiteBackground());
  public enabledMenuOverrides$ = this.editDisplayViewModel.enabledMenuOverrides$;

  protected _menu = new BehaviorSubject<CompactMenu>(null);
  public menu$ = this._menu as Observable<CompactMenu>;
  connectToMenu = (menu: CompactMenu) => this._menu.next(menu);

  public _allDisplaysAreScheduled = new BehaviorSubject<boolean>(false);
  private allDisplaysAreScheduled$ = this._allDisplaysAreScheduled as Observable<boolean>;
  connectToAllDisplaysAreScheduled = (allScheduled: boolean) => this._allDisplaysAreScheduled.next(allScheduled);

  public _nMenusOnDisplay = new BehaviorSubject<number>(null);
  nMenusOnDisplay$ = this._nMenusOnDisplay as Observable<number>;
  connectToNMenusOnDisplay = (n: number) => this._nMenusOnDisplay.next(n);

  private _containerId = new BehaviorSubject<string>(null);
  public containerId$ = this._containerId as Observable<string>;
  connectToContainerId = (id: string) => this._containerId.next(id);

  private _withinCollectionAssignedToDisplay = new BehaviorSubject<boolean>(false);
  public withinCollectionAssignedToDisplay$ = this._withinCollectionAssignedToDisplay as Observable<boolean>;
  connectToWithinCollectionAssignedToDisplay = (val: boolean) => this._withinCollectionAssignedToDisplay.next(val);

  public menuId$ = this.menu$.pipe(map(menu => menu?.id), distinctUntilChanged());
  public showDateTimeOverride$ = combineLatest([
    this.menuId$,
    this.containerId$,
    this.enabledMenuOverrides$
  ]).pipe(
    map(([menuId, containerId, overrides]) => {
      return overrides?.get(containerId)?.get(menuId) || false;
    }),
    distinctUntilChanged(),
    startWith(false)
  );

  public productOverflowTitle$ = this.menu$.pipe(
    switchMap(menu => {
      return window.types.allMenuOverflowStateTypes$.pipe(
        map(types => types?.find(type => type.getSelectionValue() === menu?.overflowState)),
        map(typeDefinition => typeDefinition?.name)
      );
    })
  );

  public menuWarningTooltip$ = combineLatest([
    this.menu$,
    this.withinCollectionAssignedToDisplay$,
    this.allTemplates$,
    this.locationId$
  ]).pipe(
    debounceTime(100),
    map(([menu, withinCollectionAssignedToDisplay, templates, locationId]) => {
      switch (true) {
        case withinCollectionAssignedToDisplay && menu?.isTemplate && !menu?.isTemplateImportedAtMyLocation: {
          return `The template ${menu?.name} is included in the template collection, but does not exist `
               + `at this location. This templated menu will not appear on the display until it is added at `
               + `the current location.`;
        }
        case menu?.isFeaturedMenuWithoutVisibleFeaturedVariants(): {
          return `This featured product menu doesn\'t have enabled products that are in stock, so it will `
               + `not be displayed.`;
        }
        case menu?.hasNoEnabledMarketingContent(): {
          return this.createNoEnabledContentWarning(menu);
        }
        case (menu?.usesIntervalSystemAndNoContentEnabled()): {
          return this.createNoIntervalContentMessage(menu);
        }
        case menu?.menuUsesProductsAndDoesntHaveVisibleContent(): {
          return this.createNoContentWarning(menu);
        }
        case menu?.hasNoValidUrl(): {
          return 'This url playlist doesn\'t have an attached url, so it will not be displayed.';
        }
      }
      return undefined;
    })
  );

  public contentWillLoopForever$ = combineLatest([
    this.menu$,
    this.allDisplaysAreScheduled$,
    this.nMenusOnDisplay$,
    this.editDisplayViewModel.collectionMode$,
    this.editDisplayViewModel.display$
  ]).pipe(
    map(([menu, allDisplaysAreScheduled, nMenusOnDisplay, collectionMode, display]) => {
      if (collectionMode) return false;
      const isSingleMarketingMenuWithContent = nMenusOnDisplay === 1
        && menu?.isLoopingMarketingContent
        && menu?.calculateMarketingLoopDuration >= 1;
      const isSingleNonMarketingLoopingContentMenu = (nMenusOnDisplay === 1) && (!menu?.isLoopingMarketingContent);
      const scheduled = display?.options?.overrides?.get(menu?.id)?.hasSchedule() || false;
      const willLoopForever = (isSingleMarketingMenuWithContent && !scheduled)
        || (isSingleNonMarketingLoopingContentMenu && !scheduled);
      return !allDisplaysAreScheduled && willLoopForever;
    })
  );

  private createNoContentWarning(menu: CompactMenu): string {
    let menuTypeName: string;
    let appearanceText = 'appear blank in the display';
    switch (menu?.menuSubType) {
      case ProductMenuType.ProductMenu:
        menuTypeName = 'product menu';
        break;
      case ProductMenuType.SpotlightMenu:
        menuTypeName = 'spotlight menu';
        break;
      case MarketingMenuType.Category:
        menuTypeName = 'featured category menu';
        break;
      case MarketingMenuType.SmartPlaylist:
        menuTypeName = 'smart playlist';
        appearanceText = 'not be displayed';
        break;
      default:
        menuTypeName = 'menu';
        break;
    }
    return `This ${menuTypeName} has no content and will ${appearanceText}.`;
  }

  private createNoEnabledContentWarning(menu: CompactMenu): string {
    let menuTypeName: string;
    switch (menu?.menuSubType) {
      case MarketingMenuType.DriveThru:
        menuTypeName = 'drive thru menu';
        break;
      case MarketingMenuType.Featured:
        menuTypeName = 'featured product menu';
        break;
      case MarketingMenuType.SmartPlaylist:
        menuTypeName = 'smart playlist';
        break;
      default:
        menuTypeName = 'menu';
        break;
    }
    return `This ${menuTypeName} doesn\'t have enabled products, so it will not be displayed.`;
  }

  private createNoIntervalContentMessage(menu: CompactMenu): string {
    let menuTypeName: string;
    switch (menu?.menuSubType) {
      case MarketingMenuType.Playlist:
        menuTypeName = 'playlist';
        break;
      case MarketingMenuType.Featured:
        menuTypeName = 'featured product menu';
        break;
      case MarketingMenuType.SmartPlaylist:
        menuTypeName = 'smart playlist';
        break;
      default:
        menuTypeName = 'menu';
        break;
    }
    return `This ${menuTypeName} doesn\'t have enabled media, so it will not be displayed.`;
  }

  openEditMenu(compactMenu: CompactMenu) {
    combineLatest([
      this.menuDomainModel.currentLocationMenus$,
      this.templateDomainModel.menuTemplates$
    ]).once(([menus, templates]) => {
      const item = compactMenu?.isTemplate
        ? templates?.find((m => m?.id === compactMenu?.id))
        : menus?.find(m => m?.id === compactMenu?.id);
      if (!!item) this.navigationService.navigateToEditMenuOrTemplate(item);
    });
  }

  removeMenuFromDisplay(m: CompactMenu) {
    const options = this.setConfirmationOptions(m);
    const confirmation = (cont) => {
      if (cont) {
        this.editDisplayViewModel.autoSaveLoadingOpts$
          .pipe(take(1), map(opts => opts?.isLoading))
          .subscribe(isLoading => {
            if (!isLoading) this.editDisplayViewModel.removeMenu(this._loadingOpts, m);
          });
      }
    };
    ModalConfirmation.open(this.ngbModal, this.injector, options, confirmation);
  }

  private setConfirmationOptions(m: CompactMenu): ConfirmationOptions {
    const options = new ConfirmationOptions();
    options.cancelText = 'Cancel';
    options.continueText = 'Remove';
    this.editDisplayViewModel.collectionMode$.once((collectionMode) => {
      options.title = collectionMode ? 'Remove Template' : 'Remove Menu';
      options.bodyText = `Are you sure you want to delete the following ${collectionMode ? 'template' : 'menu'} `
        + `from this ${collectionMode ? 'template collection' : 'display'}: '${m?.name}'?`;
    });
    return options;
  }

  openInformationModal() {
    ModalOverflowInformation.open(this.ngZone, this.ngbModal, this.injector, OverflowStateInfoType.Default);
  }

}
