<div class="mt-16px f16px bs-bold">Cards</div>

<ng-container *rxIf="(viewModel.sectionsApplied$ | push)?.length; else emptyState">
  <app-stack-applied-section-list-item
    *rxFor="let section of viewModel.sectionsApplied$ | push; trackBy: 'id'"
    [itemCountMap]="viewModel.itemCountMap$ | push"
    [item]="section"
    [stackType]="viewModel.stackType$ | push">
  </app-stack-applied-section-list-item>
</ng-container>

<ng-template #emptyState>
  <ng-container *rxIf="viewModel.isSearching$; else noSections">
    <div class="d-flex justify-content-center mt-16px f14px bs-medium">
      <div>{{ viewModel.noSectionsForSearchPlaceholder$ | push }}</div>
    </div>
  </ng-container>
  <ng-template #noSections>
    <div class="d-flex justify-content-center mt-16px f14px bs-medium">
      <div>{{ viewModel.noSectionsPlaceholder$ | push }}</div>
    </div>
  </ng-template>
</ng-template>
