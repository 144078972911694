import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ProductTableColumnKeyToData'
})
export class ProductTableColumnKeyToDataPipe implements PipeTransform {

  transform(key: string, cannabinoids: string[], terpenes: string[]): any {
    const comparisonKey = key?.stripWhiteSpaceAndLowerCase();
    if (cannabinoids?.includes(comparisonKey)) return 'cannabinoid';
    if (terpenes?.includes(comparisonKey) || comparisonKey === 'totalterpene') return 'terpene';
    return comparisonKey;
  }

}
