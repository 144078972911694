import { ChangeDetectionStrategy, Component, Injector, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditShelfTalkerBrandsSectionViewModel } from '../edit-shelf-talker-brands-section-view-model';
import { EditMenuSectionProductsComponent } from '../../../../../edit-menu-section/edit-menu-section-products/edit-menu-section-products.component';

@Component({
  selector: 'app-edit-shelf-talker-brands-menu-products',
  templateUrl: '/edit-shelf-talker-brands-menu-products.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShelfTalkerBrandsMenuProductsComponent extends EditMenuSectionProductsComponent {

  constructor(
    public override viewModel: EditShelfTalkerBrandsSectionViewModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(viewModel, ngZone, ngbModal, injector);
  }

}
