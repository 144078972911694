import { ChangeDetectionStrategy, Component, Injector, Input, NgZone, QueryList, ViewChildren } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { combineLatest, Observable } from 'rxjs';
import { DatatableProductFilter } from '../../../../../../../models/product/table/datatable-product-filter';
import { EditDriveThruProductsViewModel } from './edit-drive-thru-products-view-model';
import { OrderableMenuAsset } from '../../../../../../../models/menu/shared/orderable-menu-asset';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { EditDriveThruMenuViewModel } from '../edit-drive-thru-menu-view-model';
import { DriveThruProductComponent } from '../../../../shared/drive-thru-product/drive-thru-product.component';
import { CardType } from '../../../../../../../models/utils/dto/card-type-definition';
import { ModalAddProducts } from '../../../../../../../modals/modal-add-products';
import { ModalReorderProductMenuAssets } from '../../../../../../../modals/modal-reorder-product-menu-assets';
import { Menu } from '../../../../../../../models/menu/dto/menu';

@Component({
  selector: 'app-edit-drive-thru-products',
  templateUrl: './edit-drive-thru-products.component.html',
  styleUrls: ['./edit-drive-thru-products.component.scss'],
  providers: [EditDriveThruProductsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDriveThruProductsComponent extends BaseComponent {

  @ViewChildren(DriveThruProductComponent) products: QueryList<DriveThruProductComponent>;
  @Input() sharedVM: EditDriveThruMenuViewModel;
  @Input() mergeKey: string;
  @Input() formPristine: boolean = true;
  @Input() formHasErrors: boolean = true;

  constructor(
    public viewModel: EditDriveThruProductsViewModel,
    protected ngZone: NgZone,
    public ngbModal: NgbModal,
    private injector: Injector,
  ) {
    super();
  }

  override setupBindings() {
    this.sharedVM?.theme$.pipe(takeUntil(this.onDestroy)).notNull()?.subscribe(theme => {
      this.viewModel.connectToTheme(theme);
    });
  }

  public updateCardType(type: CardType) {
    this.sharedVM.updateCardType(type);
  }

  public updateCardStartAt(startAt: string) {
    this.sharedVM.updateCardStartAt(startAt);
  }

  public previewClicked(i: number) {
    this.products.forEach(component => {
      component.previewSelectionChanged(i);
    });
  }

  public addFeatureProductClicked() {
    const contOp = (variantIds: string[]): Observable<boolean> => this.sharedVM?.addNewFeaturedVariants(variantIds);
    const resultOp = (customFilter: DatatableProductFilter) => {};
    const open = (menu: Menu) => ModalAddProducts.open(
      this.ngZone,
      this.ngbModal,
      this.injector,
      menu,
      null,
      contOp,
      resultOp
    );
    this.sharedVM?.menu$?.once(open);
  }

  reorderProductsClicked() {
    const reorderOp = (ordered: OrderableMenuAsset[]) => this.sharedVM.updateMenuAssetOrder(ordered);
    combineLatest([this.sharedVM.menu$, this.sharedVM.orderedMedia$]).once(([menu, orderedMedia]) => {
      const options = this.sharedVM?.getReorderOptions(menu);
      ModalReorderProductMenuAssets.open(this.ngZone, this.ngbModal, this.injector, options, orderedMedia, reorderOp);
    });
  }

}
