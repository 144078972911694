import { Deserializable } from '../../protocols/deserializable';
import { TypeDefinition } from './type-definition';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { SectionLayoutType } from './section-layout-type';
import { FeaturedCategoryMenuCardType } from './featured-category-menu-card-type';
import { OverflowStateType } from './overflow-state-type';
import { DefaultPrintSizeType } from './default-print-size-type';
import { DefaultDigitalSizeType } from './default-digital-size-type';
import { CardTypeDefinition } from './card-type-definition';
import { SaleLabelFormatType } from './sale-label-format-type';
import { StrainClassificationType } from './strain-classification-type';
import { VariantTypeDefinition } from './variant-type-definition';
import { UsePurposeType } from './use-purpose-type';
import { ProductTypeDefinition } from './product-type-definition';
import { CannabinoidDisplayTypeDefinition } from './cannabinoid-display-type-definition';
import { CannabisUnitOfMeasureType } from './cannabis-unit-of-measure-type';
import { UnitOfMeasureType } from './unit-of-measure-type';
import { DayOfWeekType } from './day-of-week-type';
import { PrintFooterLayoutTypeDefinition } from './print-footer-layout-type-definition';
import { PrintHeaderLayoutTypeDefinition } from './print-header-layout-type-definition';
import { FontStyleType } from './font-style-type';
import { OptionScaleType } from './option-scale-type';
import { InventoryProviderType } from './inventory-provider-type';
import { MenuPreviewJobStatusType } from './menu-preview-job-status-type';
import { MenuLabelType } from './menu-label-type';
import { MenuStyleObjectType } from './menu-style-object-type';
import { OrientationType } from './orientation-type';
import { PriceFormatType } from './price-format-type';
import { PromotionConditionType } from './promotion-condition-type';
import { PromotionDiscountType } from './promotion-discount-type';
import { PromotionPeriodType } from './promotion-period-type';
import { SectionColumnConfigKeyType } from './section-column-config-key-type';
import { SectionColumnConfigDataValueType } from './section-column-config-data-value-type';
import { SectionColumnConfigDefaultStateType } from './section-column-config-default-state-type';
import { SectionTypeDefinition } from './section-type-definition';
import { SectionSortType } from './section-sort-type';
import { SizeUnitType } from './size-unit-type';
import { MenuType, MenuTypeDefinition } from './menu-type-definition';
import { ProductMixType } from './product-mix-type';
import { MenuCreationFlowType } from './menu-creation-flow-type';
import { ThemeSubtypeType } from './theme-subtype-type';
import { SyncJobStatusType } from './sync-job-status-type';
import { SyncTypeType } from './sync-type-type';
import { ProvinceTypeDefinition } from './province-type';
import { CountryTypeDefinition } from './country-type';
import { DefaultPrintCardSizeType } from './default-print-card-size-type';
import { DefaultPrintCardPaperSizeType } from './default-print-card-paper-size-type';
import { BulkJobSourceType } from './bulk-job-source-type';
import { DefaultPrintLabelPaperSizeType } from './default-print-label-paper-size-type';
import { DefaultPrintLabelSizeType } from './default-print-label-size-type';
import { SecondaryCannabinoidsTypeDefinition } from './secondary-cannabinoids-type-definition';
import { TerpeneTypeDefinition } from './terpene-type-definition';
import { TerpeneDisplayTypeDefinition } from './terpene-display-type-definition';
import { TerpeneUnitOfMeasureType } from './terpene-unit-of-measure-type';
import { ProductTableColumnConfigsType } from './product-table-column-configs-type';
import { VariantPropertyType } from './variant-property-type';
import { DefaultShelfTalkerSizeType } from './default-shelf-talker-size-type';
import { DefaultShelfTalkerPaperSizeType } from './default-shelf-talker-paper-size-type';
import { MenuSubTypeDefinition } from './menu-sub-type-definition';

export class EnumTypes implements Deserializable, Cachable {

  public assetLibraryType: TypeDefinition[];
  public bulkJobSourceType: BulkJobSourceType[];
  public cannabinoidDisplayType: CannabinoidDisplayTypeDefinition[];
  public terpeneDisplayType: TerpeneDisplayTypeDefinition[];
  public cannabisUnitOfMeasure: CannabisUnitOfMeasureType[];
  public terpeneUnitOfMeasure: TerpeneUnitOfMeasureType[];
  public comboMenuCardType: CardTypeDefinition[];
  public companyRole: TypeDefinition[];
  public country: CountryTypeDefinition[];
  public daysOfWeek: DayOfWeekType[];
  public defaultPrintCardSizes: DefaultPrintCardSizeType[];
  public defaultPrintLabelSizes: DefaultPrintLabelSizeType[];
  public defaultPrintCardPaperSizes: DefaultPrintCardPaperSizeType[];
  public defaultPrintLabelPaperSizes: DefaultPrintLabelPaperSizeType[];
  public defaultPrintSizes: DefaultPrintSizeType[];
  public defaultDigitalSizes: DefaultDigitalSizeType[];
  public defaultShelfTalkerCardSizes: DefaultShelfTalkerSizeType[];
  public defaultShelfTalkerPaperSizes: DefaultShelfTalkerPaperSizeType[];
  public productTableColumnConfigs: ProductTableColumnConfigsType[];
  public featuredCategoryMenuCardType: FeaturedCategoryMenuCardType[];
  public fontStyle: FontStyleType[];
  public inventoryProvider: InventoryProviderType[];
  public menuCreationFlowMap: Map<MenuType, Map<MenuCreationFlowType, string[]>>;
  public menuCreationFlows: TypeDefinition[];
  public menuLabel: MenuLabelType[];
  public menuPreviewJobStatus: MenuPreviewJobStatusType[];
  public menuStyleObject: MenuStyleObjectType[];
  public menuType: MenuTypeDefinition[];
  public menuSubType: MenuSubTypeDefinition[];
  public optionScale: OptionScaleType[];
  public orientation: OrientationType[];
  public overflowState: OverflowStateType[];
  public priceFormat: PriceFormatType[];
  public printFooterLayout: PrintFooterLayoutTypeDefinition[];
  public printHeaderLayout: PrintHeaderLayoutTypeDefinition[];
  public productMix: ProductMixType[];
  public productType: ProductTypeDefinition[];
  public promotionCondition: PromotionConditionType[];
  public promotionDiscount: PromotionDiscountType[];
  public promotionPeriod: PromotionPeriodType[];
  public province: Map<string, ProvinceTypeDefinition[]>;
  public saleLabelFormat: SaleLabelFormatType[];
  public secondaryCannabinoids: SecondaryCannabinoidsTypeDefinition[];
  public sectionColumnConfigDataValue: SectionColumnConfigDataValueType[];
  public sectionColumnConfigDefaultState: SectionColumnConfigDefaultStateType[];
  public sectionColumnConfigKey: SectionColumnConfigKeyType[];
  public sectionLayoutType: SectionLayoutType[];
  public sectionType: SectionTypeDefinition[];
  public sectionSortOption: SectionSortType[];
  public sizeUnit: SizeUnitType[];
  public strainType: StrainClassificationType[];
  public syncType: SyncTypeType[];
  public syncJobStatus: SyncJobStatusType[];
  public terpenes: TerpeneTypeDefinition[];
  public themeSubType: ThemeSubtypeType[];
  public timezone: Map<string, TypeDefinition[]>;
  public unitOfMeasure: UnitOfMeasureType[];
  public usePurpose: UsePurposeType[];
  public variantLookupType: TypeDefinition[];
  public variantProperty: VariantPropertyType[];
  public variantType: Map<string, VariantTypeDefinition[]>;

  // Cache
  public cachedTime: number;
  // @ts-ignore
  public versionString = require('package.json')?.version;

  static getCacheKey(): string {
    return new EnumTypes().cacheKey();
  }

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.assetLibraryType = Deserialize?.arrayOf(TypeDefinition, this.assetLibraryType);
    this.bulkJobSourceType = Deserialize?.arrayOf(BulkJobSourceType, this.bulkJobSourceType);
    this.cannabinoidDisplayType = Deserialize?.arrayOf(CannabinoidDisplayTypeDefinition, this.cannabinoidDisplayType);
    this.terpeneDisplayType = Deserialize?.arrayOf(TerpeneDisplayTypeDefinition, this.terpeneDisplayType);
    this.cannabisUnitOfMeasure = Deserialize?.arrayOf(CannabisUnitOfMeasureType, this.cannabisUnitOfMeasure);
    this.terpeneUnitOfMeasure = Deserialize?.arrayOf(TerpeneUnitOfMeasureType, this.terpeneUnitOfMeasure);
    this.comboMenuCardType = Deserialize?.arrayOf(CardTypeDefinition, this.comboMenuCardType);
    this.companyRole = Deserialize?.arrayOf(TypeDefinition, this.companyRole);
    this.country = Deserialize?.arrayOf(CountryTypeDefinition, this.country);
    this.daysOfWeek = Deserialize?.arrayOf(DayOfWeekType, this.daysOfWeek);
    this.defaultPrintCardPaperSizes = Deserialize
      ?.arrayOf(DefaultPrintCardPaperSizeType, this.defaultPrintCardPaperSizes);
    this.defaultPrintLabelPaperSizes = Deserialize
      ?.arrayOf(DefaultPrintLabelPaperSizeType, this.defaultPrintLabelPaperSizes);
    this.defaultPrintCardSizes = Deserialize?.arrayOf(DefaultPrintCardSizeType, this.defaultPrintCardSizes);
    this.defaultPrintLabelSizes = Deserialize?.arrayOf(DefaultPrintLabelSizeType, this.defaultPrintLabelSizes);
    this.defaultPrintSizes = Deserialize?.arrayOf(DefaultPrintSizeType, this.defaultPrintSizes);
    this.defaultDigitalSizes = Deserialize?.arrayOf(DefaultDigitalSizeType, this.defaultDigitalSizes);
    this.defaultShelfTalkerCardSizes = Deserialize
      ?.arrayOf(DefaultShelfTalkerSizeType, this.defaultShelfTalkerCardSizes);
    this.defaultShelfTalkerPaperSizes = Deserialize
      ?.arrayOf(DefaultShelfTalkerPaperSizeType, this.defaultShelfTalkerPaperSizes);
    this.productTableColumnConfigs = Deserialize
      ?.arrayOf(ProductTableColumnConfigsType, this.productTableColumnConfigs);
    this.featuredCategoryMenuCardType = Deserialize
      ?.arrayOf(FeaturedCategoryMenuCardType, this.featuredCategoryMenuCardType);
    this.fontStyle = Deserialize?.arrayOf(FontStyleType, this.fontStyle);
    this.inventoryProvider = Deserialize?.arrayOf(InventoryProviderType, this.inventoryProvider);
    this.menuCreationFlowMap = Deserialize.genericNestedArrayMap(this.menuCreationFlowMap);
    this.menuCreationFlows = Deserialize.arrayOf(TypeDefinition, this.menuCreationFlows);
    this.menuLabel = Deserialize?.arrayOf(MenuLabelType, this.menuLabel);
    this.menuPreviewJobStatus = Deserialize?.arrayOf(MenuPreviewJobStatusType, this.menuPreviewJobStatus);
    this.menuStyleObject = Deserialize?.arrayOf(MenuStyleObjectType, this.menuStyleObject);
    this.menuType = Deserialize?.arrayOf(MenuTypeDefinition, this.menuType);
    this.optionScale = Deserialize?.arrayOf(OptionScaleType, this.optionScale);
    this.orientation = Deserialize?.arrayOf(OrientationType, this.orientation);
    this.overflowState = Deserialize?.arrayOf(OverflowStateType, this.overflowState);
    this.priceFormat = Deserialize?.arrayOf(PriceFormatType, this.priceFormat);
    this.printFooterLayout = Deserialize?.arrayOf(PrintFooterLayoutTypeDefinition, this.printFooterLayout);
    this.printHeaderLayout = Deserialize?.arrayOf(PrintHeaderLayoutTypeDefinition, this.printHeaderLayout);
    this.productMix = Deserialize?.arrayOf(TypeDefinition, this.productMix);
    this.productType = Deserialize?.arrayOf(ProductTypeDefinition, this.productType);
    this.promotionCondition = Deserialize?.arrayOf(PromotionConditionType, this.promotionCondition);
    this.promotionDiscount = Deserialize?.arrayOf(PromotionDiscountType, this.promotionDiscount);
    this.promotionPeriod = Deserialize?.arrayOf(PromotionPeriodType, this.promotionPeriod);
    this.province = Deserialize?.typedArrayMapOf(ProvinceTypeDefinition, this.province);
    this.saleLabelFormat = Deserialize?.arrayOf(SaleLabelFormatType, this.saleLabelFormat);
    this.secondaryCannabinoids = Deserialize?.arrayOf(SecondaryCannabinoidsTypeDefinition, this.secondaryCannabinoids);
    this.sectionColumnConfigDataValue = Deserialize
      ?.arrayOf(SectionColumnConfigDataValueType, this.sectionColumnConfigDataValue);
    this.sectionColumnConfigDefaultState = Deserialize
      ?.arrayOf(SectionColumnConfigDefaultStateType, this.sectionColumnConfigDefaultState);
    this.sectionColumnConfigKey = Deserialize?.arrayOf(SectionColumnConfigKeyType, this.sectionColumnConfigKey);
    this.sectionLayoutType = Deserialize?.arrayOf(SectionLayoutType, this.sectionLayoutType);
    this.sectionType = Deserialize?.arrayOf(SectionTypeDefinition, this.sectionType);
    this.sectionSortOption = Deserialize?.arrayOf(SectionSortType, this.sectionSortOption);
    this.sizeUnit = Deserialize?.arrayOf(SizeUnitType, this.sizeUnit);
    this.strainType = Deserialize?.arrayOf(StrainClassificationType, this.strainType);
    this.syncJobStatus = Deserialize?.arrayOf(SyncJobStatusType, this.syncJobStatus);
    this.syncType = Deserialize?.arrayOf(SyncTypeType, this.syncType);
    this.terpenes = Deserialize?.arrayOf(TerpeneTypeDefinition, this.terpenes);
    this.terpeneUnitOfMeasure = Deserialize.arrayOf(TerpeneUnitOfMeasureType, this.terpeneUnitOfMeasure);
    this.themeSubType = Deserialize?.arrayOf(ThemeSubtypeType, this.themeSubType);
    this.timezone = Deserialize?.typedArrayMapOf(TypeDefinition, this.timezone);
    this.unitOfMeasure = Deserialize?.arrayOf(UnitOfMeasureType, this.unitOfMeasure);
    this.usePurpose = Deserialize?.arrayOf(UsePurposeType, this.usePurpose);
    this.variantLookupType = Deserialize?.arrayOf(TypeDefinition, this.variantLookupType);
    this.variantProperty = Deserialize?.arrayOf(TypeDefinition, this.variantProperty);
    this.variantType = Deserialize?.typedArrayMapOf(VariantTypeDefinition, this.variantType);
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneWeek();
  }

  cacheKey(): string {
    return `BudSense-Types-${this.versionString}`;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

}
