import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditShelfTalkerBrandsSectionFormViewModel } from './edit-shelf-talker-brands-section-form-view-model';
import { EditSectionForm } from '../../../../../edit-menu-section/edit-menu-section-form/edit-section-form/edit-section-form';

@Component({
  selector: 'app-edit-shelf-talker-brands-section-form',
  templateUrl: './edit-shelf-talker-brands-section-form.component.html',
  styleUrls: ['./edit-shelf-talker-brands-section-form.component.scss'],
  providers: [EditShelfTalkerBrandsSectionFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShelfTalkerBrandsSectionFormComponent extends EditSectionForm {

  constructor(
    public override viewModel: EditShelfTalkerBrandsSectionFormViewModel
  ) {
    super(viewModel);
  }

}
