import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { AnimatorService } from '../../../../../../services/animator/animator.service';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { TemplateCollectionDomainModel } from '../../../../../../domainModels/template-collection-domain-model';
import { TemplatesContentContainerViewModel } from '../../../../../shared/components/displayable-content/displayable-content-container/templates-content-container-view-model';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';

// Provided by Logged In Scope
@Injectable()
export class PrintTemplatesContainerViewModel extends TemplatesContentContainerViewModel {

  constructor(
    templateCollectionDomainModel: TemplateCollectionDomainModel,
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    templateDomainModel: TemplateDomainModel,
    userDomainModel: UserDomainModel,
    animatorService: AnimatorService,
    activeRoute: ActivatedRoute,
  ) {
    super(
      templateCollectionDomainModel,
      locationDomainModel,
      menuDomainModel,
      templateDomainModel,
      userDomainModel,
      animatorService,
      activeRoute
    );
  }

  getMenuTypeFromSelectedTab(selectedTab: number): MenuType[] {
    switch (selectedTab) {
      case 0: return [MenuType.PrintMenu];
      case 1: return [MenuType.PrintReportMenu];
      case 2: return [MenuType.PrintCardMenu];
      case 3: return [MenuType.PrintLabelMenu];
    }
  }

}
