import { Pipe, PipeTransform } from '@angular/core';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';

@Pipe({
  name: 'isShelfTalkerMenuTemplate'
})
export class IsShelfTalkerMenuTemplatePipe implements PipeTransform {

  transform(value: any): MenuTemplate | null {
    return (value instanceof MenuTemplate && (value?.isShelfTalkerMenu?.() ?? false)) ? value : null;
  }

}
