<lib-reactive-form-column-layout nColumns="2">
  <lib-reactive-form-number
    (valueChanged)="viewModel.connectToMinCannabinoidValue($event)"
    [inputName]="'min' + cannabinoid"
    [label]="'Minimum ' + cannabinoid"
    [placeholder]="'Minimum ' + cannabinoid + ' content of products'"
    [inlineLabel]="false"
    [required]="false"
    [disabled]="isViewOnly"
    [integersOnly]="false"
    [step]="0.01"
    [tooltip]="
      'The smart filter will include products with ' + cannabinoid + ' values greater than or equal to this value.'
    "
    [minValue]="0"
    [maxValue]="viewModel.minCannabinoidMaxValue$ | push"
    [addToAtLeastNItemsFilledInPool]="true"
    [bindingProperty]="'min' + cannabinoid"
    [dispersedKey]="dispersedKey">
  </lib-reactive-form-number>

  <lib-reactive-form-number
    (valueChanged)="viewModel.connectToMaxCannabinoidValue($event)"
    [inputName]="'max' + cannabinoid"
    [label]="'Maximum ' + cannabinoid"
    [placeholder]="'Maximum ' + cannabinoid + ' content of products'"
    [inlineLabel]="false"
    [required]="false"
    [disabled]="isViewOnly"
    [integersOnly]="false"
    [step]="0.01"
    [tooltip]="
      'The smart filter will include products with ' + cannabinoid + ' values less than or equal to this value.'
    "
    [minValue]="viewModel.maxCannabinoidMinValue$ | push"
    [addToAtLeastNItemsFilledInPool]="true"
    [bindingProperty]="'max' + cannabinoid"
    [dispersedKey]="dispersedKey">
  </lib-reactive-form-number>
</lib-reactive-form-column-layout>
