import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OverflowStateInfoType } from '../models/utils/dto/overflow-state-type';
import { OverflowInfoModalComponent } from '../views/shared/modals/overflow-info-modal/overflow-info-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector, NgZone } from '@angular/core';

export class ModalOverflowInformation {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    overflowStateInfoType: OverflowStateInfoType
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(OverflowInfoModalComponent, ModalUtils.overflowInfoModalOptions(injector));
      const compInstance = modalRef.componentInstance as OverflowInfoModalComponent;
      compInstance.title = 'Product Overflow Information';
      compInstance.overflowStateInfoType = overflowStateInfoType;
    });
  }

}
