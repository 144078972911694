import { ChangeDetectionStrategy, Component, Injector, NgZone } from '@angular/core';
import { EditFeaturedCategoryMenuViewModel } from './edit-featured-category-menu-view-model';
import { EditMarketingMenuComponent } from '../edit-marketing-menu.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-edit-featured-category-menu',
  templateUrl: './edit-featured-category-menu.component.html',
  styleUrls: [
    './edit-featured-category-menu.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditFeaturedCategoryMenuViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditFeaturedCategoryMenuComponent extends EditMarketingMenuComponent {

  constructor(
    public override viewModel: EditFeaturedCategoryMenuViewModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    toastService: ToastService,
    router: Router,
    activatedRoute: ActivatedRoute,
  ) {
    super(viewModel, ngZone, ngbModal, injector, toastService, router, activatedRoute);
  }

}
