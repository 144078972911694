import { ProductMix } from '../../../utils/dto/product-mix-type';
import { WebSocketSendRequestDataMessage } from './web-socket-send-request-data-message';

/**
 * Example:
 * {
 *     "action": "DATA_REQUEST",
 *     "entityId": 420420,
 *     "payload": {
 *         "companyId": 170479,
 *         "locationId": 420420,
 *         "requestId": "asd123",
 *         "userId": "8ffbfd7a-29e1-484f-8584-73ecdd8d2402",
 *         "dataType": "WebsocketDataRequestType_Products",
 *         "metadata": {
 *             "ProductMix": "ALL",
 *             "ProductIds": "10089714,10090153"
 *         }
 *     }
 * }
 */
export class WebSocketRequestProducts extends WebSocketSendRequestDataMessage {

  constructor(
    userId: string,
    companyId: number,
    locationId: number,
    productMix: ProductMix,
    productIds?: string[]
  ) {
    super(locationId);
    this.payload.companyId = companyId;
    this.payload.locationId = locationId;
    this.payload.userId = userId;
    this.payload.dataType = 'WebsocketDataRequestType_Products';
    this.payload.metadata = {
      ProductMix: productMix
    };
    if (productIds?.length > 0) {
      this.payload.metadata.ProductIds = productIds;
    }
  }

}
