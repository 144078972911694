<div class="mt-4">
  <div class="my-3" *rxIf="viewModel?.showDisabledInGridModeMessage$ | push">
    <app-message-banner [message]="viewModel?.disabledInGridModeMessage$ | push"> </app-message-banner>
  </div>

  <app-column-options-form
    [formColumnConfig]="viewModel.formColumnConfig$ | push"
    [columnSectionKey]="viewModel.container.columnSectionKey$ | push"
    [formMergeKey]="viewModel.container.mergeKey"
    [sectionLayoutType]="viewModel.container.layoutType$ | push"
    [themeDefaults]="viewModel.themeDefaults$ | push"
    [managingDefault]="viewModel.container.managingDefault$ | push"
    (columnChanges)="handleColumnChanges($event)"></app-column-options-form>
</div>
