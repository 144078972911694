import { Deserializable } from '../../protocols/deserializable';
import { Asset } from '../../image/dto/asset';
import { Product } from '../../product/dto/product';
import { Section } from './section';
import { Cachable } from '../../protocols/cachable';
import { HydratedVariantBadge } from '../../product/dto/hydrated-variant-badge';
import { SectionLayoutType } from '../../utils/dto/section-layout-type';
import { SortUtils } from '../../../utils/sort-utils';
import { SmartFilter } from '../../automation/smart-filter';
import { VariantGroup } from '../../product/shared/variant-group';
import type { Variant } from '../../product/dto/variant';
import type { Menu } from './menu';
import { PriceFormat } from '../../enum/dto/price-format';

export class HydratedSection extends Section implements Deserializable, Cachable {

  public image: Asset;
  public secondaryImage: Asset;
  public products: Product[];
  public variants: Variant[] = []; // Not included from api
  public smartFilters: SmartFilter[];
  public variantBadgeMap: Map<string, HydratedVariantBadge[]>;

  public override onDeserialize() {
    super.onDeserialize();
    const Deserialize = window?.injector?.Deserialize;
    this.image = Deserialize?.instanceOf(Asset, this.image);
    this.secondaryImage = Deserialize?.instanceOf(Asset, this.secondaryImage);
    this.products = Deserialize?.arrayOf(Product, this.products);
    this.smartFilters = Deserialize?.arrayOf(SmartFilter, this.smartFilters);
    this.deserializeVariantBadgeMap();
    this.setVariants();
    if (this.isTemplatedSection() && !! this.templateSection) {
      this.hydrateFromTemplate();
    }
  }

  public override onSerialize(): any {
    return Object.assign(new HydratedSection(), super.onSerialize());
  }

  private deserializeVariantBadgeMap(): void {
    this.variantBadgeMap = !!this.variantBadgeMap
      ? window?.injector?.Deserialize?.typedArrayMapOf(HydratedVariantBadge, this.variantBadgeMap)
      : new Map<string, HydratedVariantBadge[]>();
  }

  protected override hydrateFromTemplate() {
    super.hydrateFromTemplate();
    this.image = this.templateSection?.image;
    this.secondaryImage = this.templateSection?.secondaryImage;
    this.products = [...(this.products ?? []), ...(this.templateSection?.products ?? [])]?.uniqueByProperty('id');
    this.variants = [...(this.variants ?? []), ...(this.templateSection?.variants ?? [])]?.uniqueByProperty('id');
    if (this.variantBadgeMap?.size >= 0) {
      this.templateSection?.variantBadgeMap?.forEach((badges, variantId) => {
        if (!this.variantBadgeMap?.get(variantId)) {
          this.variantBadgeMap?.set(variantId, badges);
        } else {
        const combinedBadges = [
          ...(this.variantBadgeMap?.get(variantId) ?? []),
          ...(badges ?? [])
        ]?.uniqueByProperty('id');
        this.variantBadgeMap?.set(variantId, combinedBadges);
        }
      });
    }
  }

  public override dehydrateTemplatedSection() {
    super.dehydrateTemplatedSection();
    this.image = null;
    this.secondaryImage = null;
    this.products = this.products?.filter(product => {
      return this.templateSection?.products?.findIndex(p => p.id === product.id) < 0;
    });
    this.variants = this.variants?.filter(variant => {
      return this.templateSection?.variants?.findIndex(v => v.id === variant.id) < 0;
    });
    this.templateSection?.variantBadgeMap?.forEach((badges, variantId) => {
      const currentBadges = this.variantBadgeMap?.get(variantId);
      if (!!currentBadges) {
        const filteredBadgesFromTemplate = currentBadges?.filter(b => {
          return badges?.findIndex(badge => badge?.id === b?.id) < 0;
        });
        if (filteredBadgesFromTemplate?.length === 0) {
          this.variantBadgeMap?.delete(variantId);
        } else {
          this.variantBadgeMap?.set(variantId, filteredBadgesFromTemplate);
        }
      }
    });
  }

  override getScopedVisibleVariantsForGridMode(
    products: Product[],
    variants: Variant[],
    menu: Menu,
    priceFormat: PriceFormat,
    sectionLevelOverflow: boolean
  ): Variant[][] {
    let enabledVariants = variants?.filter(v => this.enabledVariantIds?.find(x => x === v?.id));
    if (this.isStackedContent()) {
      enabledVariants = enabledVariants?.filter(v => {
        return this.getActiveColumnsAsGridColumnComparisonStrings()?.includes(v?.getGridNameAsColumnComparisonString());
      }) || [];
    }
    const enabledProductIds = enabledVariants?.map(v => v?.productId)?.unique();
    const showZeroStockItems = menu?.containsStackedContent() || this.showZeroStockItems;
    const scoped = enabledProductIds?.map(productId => {
      const rowVariants = enabledVariants?.filter(v => v?.productId === productId);
      return this.getActiveColumnsAsGridColumnComparisonStrings()
        ?.map(groupName => rowVariants?.filter(v => v?.withinGridColumnAndInStock(groupName, showZeroStockItems)))
        ?.filter(group => group?.length > 0)
        ?.flatten<Variant[]>() || [];
    });
    const variantGroups = scoped?.map(variantsInGroup => {
      const product = products?.find(p => p?.id === variantsInGroup?.firstOrNull()?.productId);
      return new VariantGroup(product, variantsInGroup, true);
    });
    const locationId = menu?.locationId;
    const companyId = menu?.companyId;
    const hideSale = menu?.menuOptions?.hideSale;
    SortUtils.prepareForSorting(variantGroups, locationId, companyId, priceFormat, null, hideSale, [], []);
    const sort = SortUtils.variantGroupsBySortOptions(variantGroups, this);
    const sorted = sort?.map(it => it?.variants);
    return (this.rowCount && !sectionLevelOverflow) ? sorted?.take(this.rowCount) : sorted;
  }

  public getVariantSiblingIdsInSection(variant: Variant): string[] {
    const prodMatch = this?.products?.find(p => p.id === variant.productId);
    if (prodMatch) {
      return prodMatch?.variants?.map(v => v.id).intersection(this?.enabledVariantIds);
    }
    return [];
  }

  public getDABadgeCountForInStockVariants(): number {
    const daBadges = this.products
      ?.flatMap(p => p?.variants)
      ?.filter(v => v.inStock())
      ?.flatMap(v => v?.displayAttributes?.getBadges());
    return daBadges.length;
  }

  public getBadgesFor(variantId: string): HydratedVariantBadge[] {
    let badges: HydratedVariantBadge[] = [];
    const overrideBadges = this.variantBadgeMap?.get(variantId);
    const daBadges = this.products.map(p => {
      return p.variants.find(v => v.id === variantId);
    }).uniqueInstanceByProperty('id')?.displayAttributes?.getBadges();
    if (!!overrideBadges && overrideBadges.length > 0) {
      badges = overrideBadges;
    } else if (!!daBadges && daBadges.length > 0) {
      badges = daBadges;
    }
    return badges;
  }

  public getGridColumns(layoutType: SectionLayoutType, locationId: number): string[] {
    return this.products?.flatMap(p => p.variants)
      .filter(v => this.enabledVariantIds?.contains(v.id))
      .flatMap((variant) => variant?.getGridNames(layoutType, locationId))
      .filterFalsies()
      .unique()
      .sort(SortUtils.compareNumerically) ?? [];
  }

  private setVariants() {
    this.variants = this?.products?.map(it => (it?.variants || [])).flatten();
  }

  replaceProductsWithDeepCopyFromPoolButKeepLatestInventory(currentLocationProducts: Product[]): HydratedSection {
    const hydratedSectionProducts: Product[] = [];
    this.products?.forEach((prod) => {
      const prodMatch = currentLocationProducts?.find(p => p.id === prod.id);
      if (!!prodMatch) {
        // Create a copy of the product so that if it's modified at the section level and not saved,
        // then it doesn't affect the original
        hydratedSectionProducts.push(prodMatch.deepCopyAndReplaceInventory(prod));
      } else {
        hydratedSectionProducts.push(prod);
      }
    });
    this.products = hydratedSectionProducts;
    this.setVariants();
    return this;
  }

  hasPricingTier(): boolean {
    return this.variants
      ?.filter(v => v?.locationPricing?.some(p => p?.pricingTiers?.length > 0))
      ?.length > 0;
  }

  override getUniqueIdentifier(): string {
    const og = super.getUniqueIdentifier();
    const imageId = this.image?.getUniqueIdentifier() ?? '';
    const secondaryImageId = this.secondaryImage?.getUniqueIdentifier() ?? '';
    const badges: string[] = [];
    this.variantBadgeMap?.forEach((v, key) => {
      badges.push(v?.map(it => `${key}-${it?.getUniqueIdentifier()}`)?.join(','));
    });
    const variants = this.variants?.map(v => v?.getUniqueIdentifier())?.join(',');
    const enabledVariantIds = this.enabledVariantIds?.join(',');
    const products = this.products?.map(p => p?.getUniqueIdentifier())?.join(',');
    return `${og}
    -${imageId}
    -${secondaryImageId}
    -${badges?.join(',')}
    -${variants}
    -${enabledVariantIds}
    -${products}`;
  }

}
