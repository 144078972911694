import { Injectable } from '@angular/core';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProviderUtils } from '../../../../../utils/provider-utils';
import { UsePurpose } from '../../../../../models/enum/dto/use-purpose';
import { UsePurposeType } from '../../../../../models/utils/dto/use-purpose-type';
import { BaseSmartFilterFormViewModel } from '../base-smart-filter-form-view-model';
import { AddEditSmartFilterModalOpenedFrom } from '../../../../../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { SmartFilterUtils } from '../../../../../utils/smart-filter-utils';
import { ProductDomainModel } from '../../../../../domainModels/product-domain-model';

@Injectable()
export class BasicAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel,
    private productDomainModel: ProductDomainModel
  ) {
    super();
  }

  public locationId$ = this.locationDomainModel.locationId$;
  public companyId$ = this.companyDomainModel.companyId$;

  private _itemCount = new BehaviorSubject<number>(null);
  public itemCount$ = this._itemCount as Observable<number>;
  public setItemCount = (ic: number) => this._itemCount.next(ic);

  private selectedPrimarySortOption = new BehaviorSubject<string>(null);
  public disableSecondarySort$ = combineLatest([
    this.selectedPrimarySortOption,
    this.isViewOnly$
  ]).pipe(
    map(([selectedPrimarySort, viewOnly]) => {
      return !selectedPrimarySort || viewOnly;
    })
  );

  public disableCompanySmartFilterSwitch$ = combineLatest([
    this.isEditing$,
    this.smartFilter$,
    this.companyId$,
    this.isViewOnly$,
    this.modalOpenedFrom$
  ]).pipe(
    map(([isEditing, SF, companyId, isViewOnly, modalOpenedFrom]) => {
      return (isEditing && SF?.locationId === companyId)
          || isViewOnly
          || SmartFilterUtils.contextRequiresCompanySmartFilter(modalOpenedFrom);
    })
  );

  public primarySortDropdownOptions$ = window?.types?.sectionSortOptions$;

  public secondarySortDropdownOptions$ = combineLatest([
    this.primarySortDropdownOptions$,
    this.selectedPrimarySortOption
  ]).pipe(
    map(([allDropdowns, primarySort]) => {
      // If primary sort is CBD_ASC, secondary cannot be either CBD_ASC or CBD_DESC
      if (!primarySort) {
        return null;
      }
      const primaryKey = primarySort?.substr(0, primarySort?.indexOf('_'));
      return allDropdowns.filter(dropdowns => !dropdowns.value?.includes(primaryKey));
    })
  );

  public inventoryProvider$ = this.companyDomainModel.inventoryProvider$;
  public supportsMedRecProducts$ = this.inventoryProvider$.pipe(
    map(inventoryProvider => ProviderUtils.supportsMedRecProducts(inventoryProvider))
  );
  public inventoryProviderConfigs$ = this.companyDomainModel.inventoryProviderConfigs$;

  public displayAllRecMedControl$ = combineLatest([
    this.supportsMedRecProducts$,
    this.locationDomainModel.location$,
    this.smartFilter$,
    this.productDomainModel.currentLocationProductsUsePurpose$,
  ]).pipe(
    map(([supportsRecMedAPIKeys, location, smartFilter, currentLocationProductsUsePurpose]) => {
      const posAndLocationSupportsAll = supportsRecMedAPIKeys && location?.usePurpose === UsePurpose.ALL;
      const smartFilterMedialUsePurpose = smartFilter.usePurpose === UsePurpose.MEDICAL;
      const smartFilterRecreationalUsePurpose = smartFilter.usePurpose === UsePurpose.RECREATION;
      const smartFilterHasUsePurpose = smartFilterMedialUsePurpose || smartFilterRecreationalUsePurpose;
      const currentLocationProductsSupportsAll = currentLocationProductsUsePurpose === UsePurpose.ALL;
      return posAndLocationSupportsAll || smartFilterHasUsePurpose || currentLocationProductsSupportsAll;
    })
  );

  public readonly usePurposeOptions$ = combineLatest([
    window?.types?.usePurposes$,
    this.smartFilter$,
    this.isViewOnly$
  ]).pipe(
    map(([ups, sf, isViewOnly]) => {
      const upsCopy = window?.injector?.Deserialize.arrayOf(UsePurposeType, ups);
      if (!!sf?.usePurpose) {
        const i = upsCopy?.findIndex(up => up.value === sf?.usePurpose);
        upsCopy[i].selected = true;
      } else {
        upsCopy[0].selected = true;
      }
      if (isViewOnly) {
        upsCopy.forEach(up => up.disabled = true);
      }
      return upsCopy;
    }),
  );

  public readonly disableSortDropdowns$ = combineLatest([
    this.isViewOnly$,
    this.itemCount$
  ]).pipe(
    map(([viewOnly, itemCount]) => {
      return viewOnly || !itemCount;
    })
  );

  public readonly primarySortTooltip$ = combineLatest([
    this.isViewOnly$,
    this.itemCount$
  ]).pipe(
    map(([viewOnly, ic]) => {
      if (!ic && !viewOnly) {
        return 'Item Count required for sorting';
      }
      return 'The items pulled in from the smart filter will be sorted using this criteria before the'
        + ' item count is applied';
    })
  );

  public readonly secondarySortTooltip$ = combineLatest([
    this.isViewOnly$,
    this.itemCount$
  ]).pipe(
    map(([viewOnly, ic]) => {
      if (!ic && !viewOnly) {
        return 'Item Count required for sorting';
      }
      return 'This sorting option will be applied to any items that are in the same position based on '
        + 'the primary sort';
    })
  );

  public readonly viewingCuratedSmartFilter$ = combineLatest([
    this.isViewOnly$,
    this.smartFilter$
  ]).pipe(
    map(([viewOnly, sf]) => viewOnly && sf?.isCurated())
  );

  public readonly companySmartFilterSwitchTooltip$ = this.modalOpenedFrom$.pipe(
    map(openedFrom => {
      const templateSectionCreate = openedFrom === AddEditSmartFilterModalOpenedFrom.TemplateSectionCreate;
      const templatedSectionEdit = openedFrom === AddEditSmartFilterModalOpenedFrom.TemplatedSectionEdit;
      if (templateSectionCreate || templatedSectionEdit) {
        return 'Company smart filters will be available at all locations. '
             + 'Only curated and company smart filters can be used when building templates.';
      }
      return 'Company smart filters will be available at all locations and can be '
           + 'used for smart labels & smart badges. Location smart filters can '
           + 'be converted to company smart filters, but once set they can not '
           + 'be reverted back to location smart filters.';
    })
  );

  public primarySortDropdownChanged(sortOption: string) {
    this.selectedPrimarySortOption.next(sortOption);
  }

}
