import { Deserializable } from '../../protocols/deserializable';
import { CodeDeliveryDetails } from './code-delivery-details';
import { Session } from './session';
import { User } from './user';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { DefaultCacheKey } from '../../enum/shared/default-cache-key.enum';
import { CompanyFeatures } from '../../company/dto/company-features';
import { InventoryProvider } from '../../enum/dto/inventory-provider';
import { UserProfilePicture } from '../../image/dto/user-profile-picture';

export class HydratedUser extends User implements Cachable, Deserializable {

  public profilePicture: UserProfilePicture;
  public codeDeliveryDetails: CodeDeliveryDetails[];
  public session: Session;
  public companyFeatures: CompanyFeatures;

  rememberMe: boolean = false;
  inventoryProvider: InventoryProvider;
  cachedTime: number;

  public override onDeserialize() {
    super.onDeserialize();
    const Deserialize = window?.injector?.Deserialize;
    this.profilePicture = Deserialize?.instanceOf(UserProfilePicture, this.profilePicture);
    this.codeDeliveryDetails = Deserialize?.arrayOf(CodeDeliveryDetails, this.codeDeliveryDetails);
    this.session = Deserialize?.instanceOf(Session, this.session);
    this.companyFeatures = Deserialize?.instanceOf(CompanyFeatures, this.companyFeatures);
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/clientModels/User.go
  public override onSerialize(): any {
    const dto = Object.assign(new HydratedUser(), super.onSerialize());
    dto.profilePicture = this.profilePicture;
    dto.codeDeliveryDetails = this.codeDeliveryDetails;
    dto.session = this.session;
    return dto;
  }

  public user(): User {
    return window?.injector?.Deserialize?.instanceOf(User, this);
  }

  public profilePictureExists(): boolean {
    return this?.hasProfilePicture && !this?.profilePicture.isEmpty();
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneWeek();
  }

  cacheKey(): string {
    return DefaultCacheKey.SessionUser;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

}
