export enum ProductTableColumnId {
  Name = 'name',
  Brand = 'brand',
  ProductType = 'producttype',
  StrainType = 'straintype',
  Quantity = 'quantity',
  Price = 'price',
  SecondaryPrice = 'secondaryprice',
  Label = 'label',
  Badge = 'badge',
  TopTerpene = 'topterpene',
  Cannabinoid = 'cannabinoid',
  Terpene = 'terpene',
}
