import { Deserializable } from './deserializable';
import { Type } from '@angular/core';
import { WebSocketReceiveMessage } from '../web-sockets/messages/receive/web-socket-receive-message';
import { WebSocketMessageContextEvent } from '../web-sockets/contexts/web-socket-message-context-event';
import { WebSocketSyncJobUpdate } from '../web-sockets/messages/receive/web-socket-sync-job-update';
import { WebSocketMessageContextConnectionManager } from '../web-sockets/contexts/web-socket-message-context-connection-manager';
import { WebSocketConnectionEstablished } from '../web-sockets/messages/receive/web-socket-connection-established';
import { WebSocketPong } from '../web-sockets/messages/receive/web-socket-pong';
import { WebSocketHealthCheck } from '../web-sockets/messages/receive/web-socket-health-check';
import { WebSocketReceiveData } from '../web-sockets/messages/receive/web-socket-receive-data';
import { WebSocketMessageContextResponse } from '../web-sockets/contexts/web-socket-message-context-response';

export class DeserializeWebSocketMessageHelper {

  /**
   * If a message has explicit data types coming back, then the WebSocketMessage object needs to be extended and
   * the specific override for the payload property needs to be implemented (with its own deserialization logic).
   */
  static getPolymorphicMessageObject<T extends Deserializable>(message: any): T {
    const createPolymorphicObject = (ObjectType: Type<any>): T => {
      return Object.assign(Object.create(ObjectType.prototype), message);
    };
    switch (message?.messageContext) {
      case WebSocketMessageContextConnectionManager.ConnectionEstablished:
        return createPolymorphicObject(WebSocketConnectionEstablished);
      case WebSocketMessageContextConnectionManager.Pong:
        return createPolymorphicObject(WebSocketPong);
      case WebSocketMessageContextConnectionManager.HealthCheck:
        return createPolymorphicObject(WebSocketHealthCheck);
      case WebSocketMessageContextEvent.SyncJobUpdate:
        return createPolymorphicObject(WebSocketSyncJobUpdate);
      case WebSocketMessageContextResponse.RequestResponse:
        return createPolymorphicObject(WebSocketReceiveData);
    }
    return createPolymorphicObject(WebSocketReceiveMessage);
  }

}
