import { Asset } from './asset';

export class UserProfilePicture extends Asset {

  /**
   * Do nothing. If you try to prefetch the image, then an injector circular dependency error will be thrown by
   * Angular, due to needing the UserDomainModel within the auth interceptor.
   */
  override loadAssetOnDeserialize(): void {
    // Do nothing
  }

}
