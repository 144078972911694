import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditLabelStackMenuViewModel } from '../edit-label-stack-menu-view-model';
import { EditLabelStackMenuCombinedLoadingOptsViewModel } from '../edit-label-stack-menu-combined-loading-opts-view-model';
import { EditCardStackMenuFooterComponent } from '../../edit-card-stack-menu/edit-card-stack-menu-footer/edit-card-stack-menu-footer.component';

@Component({
  selector: 'app-edit-label-stack-menu-footer',
  templateUrl: '../../shared/edit-menu-and-section-combined-together-footer/edit-menu-with-menu-and-section-combined-together-footer.component.html',
  styleUrls: ['../../../../../shared/styles/edit-page-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditLabelStackMenuFooterComponent extends EditCardStackMenuFooterComponent {

  constructor(
    public override viewModel: EditLabelStackMenuViewModel,
    public override sharedLoadingState: EditLabelStackMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel, sharedLoadingState);
  }

}
