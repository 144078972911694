import { LiveViewModalComponent } from '../views/display/components/modals/live-view-modal/live-view-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { BaseDisplay } from '../models/display/dto/base-display';
import { Size } from '../models/shared/size';

export class ModalDisplayLiveView {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    display: BaseDisplay,
    locationId: number
  ): void {
    ngZone.run(() => {
      // BuzzTV always renders the content as 720p, regardless of screen size or operating settings.
      const always720pBuzzTV = Size.sevenTwentyP(display?.displaySize?.orientation);
      const modalRef = ngbModal.open(
        LiveViewModalComponent,
        ModalUtils.liveViewOptions(injector, always720pBuzzTV?.orientation)
      );
      const compInstance = modalRef.componentInstance as LiveViewModalComponent;
      compInstance.setupDisplayLiveView(display, always720pBuzzTV, locationId);
    });
  }

}
