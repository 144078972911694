<lib-reactive-form-group
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
  (hasErrors$)="setHasError($event)">
  <div class="show-hide-switches-container">
    <lib-reactive-form-column-layout [nColumns]="2">
      <lib-reactive-form-switch
        [label]="'Hide Label'"
        [tooltip]="'Hides all labels (ie: Low Stock, Sale, etc).'"
        [bindingProperty]="'menuOptions.hideLabel'"
        [inputName]="'hideLabel'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        [label]="'Hide Sale'"
        [tooltip]="'Hides sale prices/labels.'"
        [bindingProperty]="'menuOptions.hideSale'"
        [inputName]="'hideSale'"
        [disabled]="isTemplatedMenu || hideSaleDisabled"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        *ngIf="!(menu | isFeaturedProductMenu)"
        [label]="'Hide Inventory Labels'"
        [tooltip]="'Hides restock and low stock labels.'"
        [bindingProperty]="'menuOptions.hideInventoryLabels'"
        [inputName]="'hideInventoryLabels'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>

      <lib-reactive-form-switch
        *ngIf="!(menu | isFeaturedProductMenu)"
        [label]="'Hide Price Decimal'"
        [tooltip]="'By default the price decimal values are shown, but they can be hidden (without rounding).'"
        [bindingProperty]="'menuOptions.hidePriceDecimal'"
        [inputName]="'hidePriceDecimal'"
        [disabled]="isTemplatedMenu"
        [inlineLabel]="true"
        [inlinePercentage]="50"
        [labelOnRight]="true">
      </lib-reactive-form-switch>
    </lib-reactive-form-column-layout>
  </div>
</lib-reactive-form-group>
