import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BulkPrintJob } from '../models/automation/bulk-print-job';
import { CreateViewBulkPrintJobComponent } from '../views/shared/modals/create-view-bulk-print-job/create-view-bulk-print-job.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector, NgZone } from '@angular/core';

export class ModalViewBulkPrintJob {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    bulkPrintJob: BulkPrintJob
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(CreateViewBulkPrintJobComponent, ModalUtils.editBulkPrintModalOptions(injector));
      const compInstance = modalRef.componentInstance as CreateViewBulkPrintJobComponent;
      compInstance.viewModel.connectToViewOnly(true);
      compInstance.viewModel.connectToBulkPrintJob(bulkPrintJob);
    });
  }

}
