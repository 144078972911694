<div class="right-pane">
  <lib-reactive-search-bar
    #searchBar
    class="search-bar"
    [style.top]="0"
    [style.position]="'sticky'"
    [style.z-index]="2"
    [searchTheseProperties]="['name']"
    [placeHolder]="'Search variants by name'"
    [searchThrough]="viewModel.searchThrough$ | push : 'immediate'"
    (searchTextAndHits)="viewModel.connectToSearchTextAndHits($event)">
  </lib-reactive-search-bar>
  <ng-container
    #cardStackSelection
    *rxIf="viewModel.isCardOrShelfTalkerStack$; strategy: 'immediate'; else: labelStackSelection">
    <ng-container *rxIf="viewModel.isShelfTalkerCardStack$; strategy: 'immediate'; else: cardStackSelectAll">
      <app-card-stack-manual-bulk-print-job-select-all-sections
        *rxIf="
          !(viewModel.viewOnly$ | async) && (viewModel.hasVisibleSections$ | async);
          else emptyState;
          strategy: 'immediate'
        ">
      </app-card-stack-manual-bulk-print-job-select-all-sections>
    </ng-container>
    <ng-template #cardStackSelectAll>
      <app-card-stack-manual-bulk-print-job-select-all-variants
        *rxIf="
          !(viewModel.viewOnly$ | async) && (viewModel.hasVisibleVariants$ | async);
          else emptyState;
          strategy: 'immediate'
        ">
      </app-card-stack-manual-bulk-print-job-select-all-variants>
    </ng-template>
    <ng-template #emptyState>
      <div class="empty-state-container">
        <div class="empty-state-text">{{ viewModel.emptySearchText$ | push }}</div>
      </div>
    </ng-template>
    <ng-container
      *rxIf="viewModel.isShelfTalkerCardStack$ | push; strategy: 'immediate'; else: cardStackSelectVariants">
      <app-card-stack-manual-bulk-print-job-selectable-section
        *rxFor="let section of viewModel.visibleSectionsInList$; trackBy: 'id'"
        [section]="section">
      </app-card-stack-manual-bulk-print-job-selectable-section>
    </ng-container>
    <ng-template #cardStackSelectVariants>
      <app-card-stack-manual-bulk-print-job-selectable-variant
        *rxFor="let variant of viewModel.visibleVariantsInList$; trackBy: 'id'"
        [variant]="variant">
      </app-card-stack-manual-bulk-print-job-selectable-variant>
    </ng-template>
  </ng-container>
  <ng-template #labelStackSelection>
    <app-label-stack-manual-bulk-print-job-select-all-variants
      *rxIf="
        !(viewModel.viewOnly$ | async) && (viewModel.hasVisibleVariants$ | async);
        strategy: 'immediate';
        else: emptyState
      "
      [selection]="viewModel.visibleVariantsInListAsSelection$ | push"
      [selectionMap]="viewModel.selectedMenuVariantLabelCountMap$ | push"
      (bulkAddClicked)="viewModel.bulkAddVariantsToVariantLabelCountMap($event)"
      (bulkRemoveClicked)="viewModel.bulkRemoveVariantsFromVariantLabelCountMap($event)">
    </app-label-stack-manual-bulk-print-job-select-all-variants>
    <ng-template #emptyState>
      <div class="empty-state-container">
        <div class="empty-state-text">No variants found</div>
      </div>
    </ng-template>
    <!-- DON'T USE TRACKBY HERE - I NEED THE COMPONENTS TO GET DESTROYED AND RECREATED WHEN THE MENU SWITCHES -->
    <app-label-stack-manual-bulk-print-job-selectable-variant
      *rxFor="let variant of viewModel.visibleVariantsInList$"
      [variant]="variant"
      [variantCountMap]="viewModel.selectedMenuVariantLabelCountMap$ | async"
      [viewOnly]="viewModel.viewOnly$ | async"
      (updateVariantCountMap)="viewModel.updateVariantCountMap([variant?.id, $event])">
    </app-label-stack-manual-bulk-print-job-selectable-variant>
  </ng-template>
  <app-hidden-array-form-input
    [disabled]="viewModel.viewOnly$ | async"
    [dispersedKey]="viewModel.mergeKey$ | async"
    [inputName]="'variantSelections'"
    [required]="true"
    [inputArr]="viewModel.entireJobSelectedVariantIds$ | async">
  </app-hidden-array-form-input>
</div>
