import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationPickerComponent } from '../views/shared/modals/location-picker/location-picker.component';
import { ModalUtils } from '../utils/modal-utils';
import { Location } from '../models/company/dto/location';
import { Injector, NgZone } from '@angular/core';

export class ModalLocationPicker {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    onClose: ([activeLocation, remember]: [Location, boolean]) => void,
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(LocationPickerComponent, ModalUtils.defaultModalOptions(injector));
      modalRef.result.then(onClose, (err) => console.error(`LocationPickerComponent err: ${err}`));
    });
  }

}
