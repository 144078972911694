import { Injectable, Injector, NgZone } from '@angular/core';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { SortUtils } from '../../../../../../utils/sort-utils';
import { ModalCreateStackPrintJob } from '../../../../../../modals/modal-create-stack-print-job';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAllCardStackPrintJobs } from '../../../../../../modals/modal-all-card-stack-print-jobs';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { BulkPrintJobDomainModel } from '../../../../../../domainModels/bulk-print-job-domain-model';
import { EditProductMenuViewModel } from '../../../../viewModels/edit-product-menu-view-model';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';
import type { StackType } from '../modals/create-view-stack-print-job/create-view-stack-print-job.component';

@Injectable()
export class EditCardStackPrintSectionViewModel extends BaseViewModel {

  constructor(
    protected editProductMenuViewModel: EditProductMenuViewModel,
    protected bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    protected injector: Injector,
    protected menuDomainModel: MenuDomainModel,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected templateDomainModel: TemplateDomainModel
  ) {
    super();
  }

  private _templateMode = new BehaviorSubject<boolean>(false);
  public templateMode$ = this._templateMode.pipe(distinctUntilChanged());
  connectToTemplateMode = (templateMode: boolean) => {
    this._templateMode.next(templateMode);
    this.bulkPrintJobDomainModel.connectToTemplateMode(templateMode);
  };

  private _unsavedChanges = new BehaviorSubject<boolean>(false);
  public unsavedChanges$ = this._unsavedChanges.pipe(distinctUntilChanged());
  connectToUnsavedChanges = (unsavedChanges: boolean) => this._unsavedChanges.next(unsavedChanges);

  private _recentJobsCount = new BehaviorSubject<number>(3);
  public recentJobsCount$ = this._recentJobsCount as BehaviorSubject<number>;

  private cardStackMenu$ = this.editProductMenuViewModel.menu$;
  private cardStackBulkPrintJobs$ = this.bulkPrintJobDomainModel.activeMenuBulkPrintJobs$;

  /**
   * There is a bug in the reactive table library where sending in data too quickly will break
   * all table functionality. Debouncing is a workaround until the bug is fixed.
   */
  public mostRecentBulkPrintJobs$ = combineLatest([this.recentJobsCount$, this.cardStackBulkPrintJobs$]).pipe(
    map(([recentJobsCount, jobs]) => {
      const sortedJobs = jobs?.slice()?.sort(SortUtils.sortBulkPrintJobsByMostRecent);
      return sortedJobs?.slice(0, recentJobsCount);
    }),
    debounceTime(100)
  );

  public cardStackHasProducts$ = this.cardStackMenu$.pipe(
    map(cardStack => cardStack?.getSectionsBasedOnMenuType()?.firstOrNull()?.productIds?.length > 0),
  );

  public readonly disableNewPrintJobButton$ = combineLatest([
    this.cardStackHasProducts$,
    this.unsavedChanges$
  ]).pipe(
    map(([hasProducts, unsavedChanges]) => !hasProducts || unsavedChanges)
  );

  public readonly disabledNewPrintJobTooltip$ = combineLatest([
    this.cardStackHasProducts$,
    this.unsavedChanges$
  ]).pipe(
    map(([hasProducts, unsavedChanges]) => {
      switch (true) {
        case !hasProducts:
          return 'Please add products before creating a print job.';
        case unsavedChanges:
          return 'Please save your changes before creating a print job.';
        default:
          return '';
      }
    })
  );

  public cardStackHasPrintJobs$ = this.cardStackBulkPrintJobs$.pipe(
    map(jobs => jobs?.length > 0),
  );

  public cardStackTitle$ = this.cardStackHasPrintJobs$.pipe(
    map(hasJobs => {
      return hasJobs
        ? 'Most Recent Print Jobs'
        : 'Recent print jobs for this card stack will show up here.';
    })
  );

  public openNewPrintJobModal(): void {
    combineLatest([
      this.templateMode$,
      this.cardStackMenu$
    ]).once(([templateMode, stackMenu]) => {
      const stackMenuType = this.getCardStackMenuType(stackMenu?.type);
      ModalCreateStackPrintJob.open(this.ngZone, this.ngbModal, this.injector, stackMenuType, templateMode);
    });
  }

  public viewAllPrintJobs(): void {
    this.templateMode$.once(templateMode => {
      ModalAllCardStackPrintJobs.open(this.ngZone, this.ngbModal, this.injector, templateMode);
    });
  }

  private getCardStackMenuType(menuType: MenuType): StackType {
    switch (menuType) {
      case MenuType.PrintCardMenu:        return 'card';
      case MenuType.PrintShelfTalkerMenu: return 'shelf talker';
      case MenuType.PrintLabelMenu:       return 'label';
    }
  }

}
