import { WebSocketSendMessage } from './web-socket-send-message';
import { WebSocketPayloadActionConnectionManagement } from '../../actions/web-socket-payload-action-connection-management';

export class WebSocketRespondToHealthCheck extends WebSocketSendMessage {

  constructor(
    entityId: number
  ) {
    super();
    this.entityId = entityId;
    this.action = WebSocketPayloadActionConnectionManagement.WELLNESS_RESPONSE;
  }

}
