import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DisplayableItemListComponent } from '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component';
import { DigitalProductTemplatesViewModel } from '../digital-product-templates-view-model';

@Component({
  selector: 'app-digital-product-template-list',
  templateUrl: '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.html',
  styleUrls: [
    '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.scss',
    './digital-product-template-list.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DigitalProductTemplateListComponent extends DisplayableItemListComponent {

  constructor(
    digitalProductTemplatesViewModel: DigitalProductTemplatesViewModel // Provided by parent component
  ) {
    super(digitalProductTemplatesViewModel);
  }

}
