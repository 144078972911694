<lib-reactive-form-group [mergeKey]="mergeKey" [bindTo]="bindTo" (hasErrors$)="setHasError($event)">
  <lib-reactive-form-column-layout [nColumns]="2">
    <div [style.display]="'flex'" [style.gap.rem]="0.5" [style.align-items]="'center'">
      <lib-reactive-form-drop-down
        #sectionType
        [style.width]="'-webkit-fill-available'"
        [inputName]="'menuTheme'"
        [label]="'Card Theme'"
        [placeholder]="'Choose card theme'"
        [inlineLabel]="false"
        [disabled]="true"
        [required]="true"
        [dropdowns]="viewModel.themes$ | async"
        [bindingProperty]="'theme'">
      </lib-reactive-form-drop-down>

      <lib-button-neutral (buttonClicked)="openThemeDetails()"> Details </lib-button-neutral>
    </div>

    <div [style.display]="'flex'" [style.align-items]="'center'">
      <lib-reactive-form-drop-down
        fxFlex
        [hidden]="viewModel.creatingNewTag$ | async"
        [bindOnSubmit]="!(viewModel.creatingNewTag$ | async)"
        [inputName]="'tags'"
        [label]="'Tag'"
        [clearable]="true"
        [placeholder]="'Select a tag for this menu.'"
        [inlineLabel]="false"
        [disabled]="(viewModel.creatingNewTag$ | async) || isTemplatedMenu"
        [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
        [required]="false"
        [dropdowns]="viewModel.tags$ | async"
        [bindingProperty]="'tag'">
      </lib-reactive-form-drop-down>

      <lib-reactive-form-text
        fxFlex
        [hidden]="!(viewModel.creatingNewTag$ | async)"
        [bindOnSubmit]="viewModel.creatingNewTag$ | async"
        [inputName]="'newTag'"
        [label]="'New Tag'"
        [placeholder]="'Enter tag name.'"
        [inlineLabel]="false"
        [required]="false"
        [minLength]="1"
        [maxLength]="128"
        [disabled]="!(viewModel.creatingNewTag$ | async)"
        [bindingProperty]="'tag'">
      </lib-reactive-form-text>

      <lib-button-primary
        [style.margin-left.rem]="0.5"
        [disabled]="isTemplatedMenu"
        [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
        (buttonClicked)="viewModel.toggleNewTagInput()">
        {{ (viewModel.creatingNewTag$ | async) ? 'Existing' : 'New' }}
      </lib-button-primary>
    </div>

    <lib-reactive-form-drop-down
      [inputName]="'menuPreviewSize'"
      [label]="'Menu Printout Size'"
      [placeholder]="'Select the size of this shelf talker menu.'"
      [inlineLabel]="false"
      [disabled]="isTemplatedMenu || (viewModel.shelfTalkerPaperSizes$ | push)?.length === 1"
      [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
      [required]="true"
      [tooltip]="viewModel.previewSizeToolTip$ | async"
      [dropdowns]="viewModel.shelfTalkerPaperSizes$ | async"
      [bindingProperty]="'displaySize.name'">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>
</lib-reactive-form-group>
