import { Component, Injector, NgZone } from '@angular/core';
import { EditMenuSectionComponent } from '../../../../edit-menu-section/edit-menu-section.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditFeaturedCategorySectionViewModel } from './edit-featured-category-section-view-model';

@Component({
  selector: 'app-edit-featured-category-section',
  templateUrl: './edit-featured-category-section.component.html',
  styleUrls: [
    './edit-featured-category-section.component.scss',
    '../../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditFeaturedCategorySectionViewModel]
})
export class EditFeaturedCategorySectionComponent extends EditMenuSectionComponent {

  constructor(
    public override viewModel: EditFeaturedCategorySectionViewModel,
    activatedRoute: ActivatedRoute,
    router: Router,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
  ) {
    super(viewModel, activatedRoute, router, ngZone, ngbModal, injector);
  }

}
