import { Component, ViewChild } from '@angular/core';
import { ComponentCanDeactivate } from '../../../../../models/protocols/component-can-deactivate';
import { ChangePasswordViewModel } from '../../../viewModels/change-password-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { ReactiveFormGroupComponent } from '@mobilefirstdev/reactive-form';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseModalComponent implements ComponentCanDeactivate {

  @ViewChild(ReactiveFormGroupComponent) passwordForm: ReactiveFormGroupComponent;

  constructor(
    public viewModel: ChangePasswordViewModel,
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  override setupBindings() {
    this.viewModel.dismissModalSubject.subscribeWhileAlive({
      owner: this,
      next: (close) => {
        if (close) this.cancel();
      }
    });
  }

  canDeactivate(): boolean | Promise<any> {
    return !this.viewModel.unsavedChanges;
  }

}
