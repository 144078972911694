// noinspection JSUnusedLocalSymbols

import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { EditVariantContainer } from '../../edit-variant-container';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../../../utils/distinct-utils';
import { CannabisUnitOfMeasure } from '../../../../../../../models/utils/dto/cannabis-unit-of-measure-type';
import { DisplayAttribute } from '../../../../../../../models/display/dto/display-attribute';
import { PrimaryCannabinoid } from '../../../../../../../models/enum/shared/primary-cannabinoid.enum';
import { ProviderUtils } from '../../../../../../../utils/provider-utils';
import { exists } from '../../../../../../../functions/exists';

@Injectable()
export class EditVariantCannabinoidsViewModel extends BaseViewModel {

  constructor(
    public container: EditVariantContainer
  ) {
    super();
  }

  public cannabisUnitOfMeasureOptions$ = window.types.cannabisUnitOfMeasures$;

  public enabledSecondaryCannabinoids$ = this.container.enabledSecondaryCannabinoids$;
  public enabledSecondaryCannabinoidNames$ = this.container.enabledSecondaryCannabinoidNames$;
  public hasEnabledSecondaryCannabinoids$ = this.container.hasEnabledSecondaryCannabinoids$;
  public posSupportedSecondaryCannabinoids$ = this.container.posSupportedSecondaryCannabinoids$;
  public hasPosSupportedSecondaryCannabinoids$ = this.container.hasPosSupportedSecondaryCannabinoids$;
  public otherEnabledSecondaryCannabinoids$ = this.container.otherEnabledSecondaryCannabinoids$;
  public hasOtherEnabledSecondaryCannabinoids$ = this.container.hasOtherEnabledSecondaryCannabinoids$;
  public readonly companyUsesCannabinoidRange$ = this.container.companyUsesCannabinoidRange$;

  private _selectedCUOM = new BehaviorSubject<CannabisUnitOfMeasure>(CannabisUnitOfMeasure.UNKNOWN);
  public selectedCUOM$ = this._selectedCUOM.pipe(distinctUntilChanged());

  private _updatedCompanyDA = new BehaviorSubject<DisplayAttribute>(null);
  public updatedCompanyDA$ = this._updatedCompanyDA.pipe(distinctUntilChanged());

  private _updatedLocationDA = new BehaviorSubject<DisplayAttribute>(null);
  public updatedLocationDA$ = this._updatedLocationDA.pipe(distinctUntilChanged());

  public primaryCannabinoids$ = of(Object.values(PrimaryCannabinoid));
  public showCannabinoidPOSSyncBanner$ = this.container.showCannabinoidPOSSyncBanner$;

  public readonly primaryCannabinoidsWithoutTAC$: Observable<PrimaryCannabinoid[]> = of(
    Object.values(PrimaryCannabinoid)?.filter(it => it !== PrimaryCannabinoid.TAC)
  );

  private distinctById = distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable);
  private initial = combineLatest([
    this.container.variant$.pipe(this.distinctById),
    this.container.variantLocationDisplayAttribute$.pipe(this.distinctById),
    this.container.variantCompanyDisplayAttribute$.pipe(this.distinctById)
  ]).subscribeWhileAlive({
    owner: this,
    next: ([variant, locationDA, companyDA]) => {
      this._selectedCUOM.next(variant?.cannabisUnitOfMeasure || CannabisUnitOfMeasure.UNKNOWN);
      this._updatedCompanyDA.next(companyDA);
      this._updatedLocationDA.next(locationDA);
    }
  });

  public disableCannabinoidInputs$: Observable<boolean> = combineLatest([
    this.showCannabinoidPOSSyncBanner$,
    this.selectedCUOM$
  ]).pipe(
    map(([showPOSSyncBanner, selectedCUOM]) => {
      return showPOSSyncBanner || selectedCUOM === CannabisUnitOfMeasure.NA;
    })
  );

  public disablePresentCannabinoidInput$ = combineLatest([
    this.disableCannabinoidInputs$,
    this.container.posSupportsPresentSecondaryCannabinoids$
  ]).pipe(
    map(([disableCannabinoidInputs, posSupportsPresentSecondaryCannabinoids]) => {
      // this signal is here for future "Present Secondary Cannabinoids" disabled logic (if needed)
      return false;
    }),
    startWith(false),
    distinctUntilChanged()
  );

  public disableTACCalculation$: Observable<boolean> = combineLatest([
    this.showCannabinoidPOSSyncBanner$,
    this.selectedCUOM$,
    this.container.inventoryProvider$
  ]).pipe(
    map(([showPOSSyncBanner, selectedCUOM, inventoryProvider]) => {
      return (showPOSSyncBanner && ProviderUtils.supportsTotalActiveCannabinoids(inventoryProvider))
        || selectedCUOM === CannabisUnitOfMeasure.NA;
    }),
  );

  public CUOMTooltip$ = this.container.syncCannabinoidsFromPOS$.pipe(
    map(isSync => {
      if (isSync) {
        return 'Editing the Cannabis Unit of Measure is disabled when Sync THC/CBD from POS is enabled. '
          + 'This value will automatically be set based on the Default Cannabis Unit of Measure. '
          + '(See Settings > Product > General)';
      } else {
        return 'The units of measure for cannabinoids such as THC and CBD within the product.';
      }
    })
  );

  public companyPresentSecondaryCannabinoidPillPlaceholders$ = combineLatest([
    this.container.variant$,
    this.companyUsesCannabinoidRange$,
    this.updatedCompanyDA$,
    this.enabledSecondaryCannabinoidNames$
  ]).pipe(
    map(([variant, companyUsesCannabinoidRange, companyDA, enabledSecondaryCannabinoidNames]) => {
      const ranged = variant?.useCannabinoidRange || companyUsesCannabinoidRange;
      return this.getNonInheritedPresentSecondaryCannabinoidsFromDisplayAttribute(
        ranged,
        companyDA,
        enabledSecondaryCannabinoidNames
      );
    })
  );

  public companyPresentSecondaryCannabinoidTextPlaceholder$: Observable<string> = combineLatest([
    this.updatedCompanyDA$,
    this.companyPresentSecondaryCannabinoidPillPlaceholders$
  ]).pipe(
    map(([companyDA, presentSecondaryCannabinoidPillPlaceholders]) => {
      const hasCompanySetPresentCannabinoids = companyDA?.presentSecondaryCannabinoids?.length > 0;
      const hasPillPlaceholders = presentSecondaryCannabinoidPillPlaceholders?.length > 0;
      switch (true) {
        case hasCompanySetPresentCannabinoids:
          return '(Manual Override)';
        case hasPillPlaceholders:
          return '(Auto-calculated)';
        default:
          return 'Select present secondary cannabinoids';
      }
    })
  );

  public locationPresentSecondaryCannabinoidsFromDisplayAttributes$ = combineLatest([
    this.container.variant$,
    this.companyUsesCannabinoidRange$,
    this.updatedLocationDA$,
    this.enabledSecondaryCannabinoidNames$
  ]).pipe(
    map(([variant, companyUsesCannabinoidRange, locationDA, enabledSecondaryCannabinoidNames]) => {
      const ranged = variant?.useCannabinoidRange || companyUsesCannabinoidRange;
      return this.getNonInheritedPresentSecondaryCannabinoidsFromDisplayAttribute(
        ranged,
        locationDA,
        enabledSecondaryCannabinoidNames
      );
    })
  );

  public locationPresentSecondaryCannabinoidPillPlaceholders$ = combineLatest([
    this.updatedCompanyDA$,
    this.enabledSecondaryCannabinoidNames$,
    this.locationPresentSecondaryCannabinoidsFromDisplayAttributes$,
    this.companyPresentSecondaryCannabinoidPillPlaceholders$
  ]).pipe(
    map(([
      companyDA,
      enabledSecondaryCannabinoids,
      locationPresentSecondaryCannabinoidsFromDA,
      companyPresentSecondaryCannabinoidsFromDA
    ]) => {
      const hasCompanySetPresentSecondaryCannabinoids = companyDA?.presentSecondaryCannabinoids?.length > 0;
      const hasCompanyAutoCalculatedPlaceholders = companyPresentSecondaryCannabinoidsFromDA?.length > 0;
      const companySetSecondaryCannabinoids = companyDA?.presentSecondaryCannabinoids?.map(cannabinoid => {
        return enabledSecondaryCannabinoids?.find(sc => sc === cannabinoid);
      });
      switch (true) {
        case hasCompanySetPresentSecondaryCannabinoids:
          return companySetSecondaryCannabinoids;
        case hasCompanyAutoCalculatedPlaceholders:
        case locationPresentSecondaryCannabinoidsFromDA?.length > 0:
          return locationPresentSecondaryCannabinoidsFromDA;
        default:
          return null;
      }
    })
  );

  public locationPresentSecondaryCannabinoidTextPlaceholder$: Observable<string> = combineLatest([
    this.updatedLocationDA$,
    this.updatedCompanyDA$,
    this.locationPresentSecondaryCannabinoidPillPlaceholders$
  ]).pipe(
    map(([locationDA, companyDA, locationPresentSecondaryCannabinoidPillPlaceholders]) => {
      const hasLocationSetPresentSecondaryCannabinoids = locationDA?.presentSecondaryCannabinoids?.length > 0;
      const hasCompanySetPresentSecondaryCannabinoids = companyDA?.presentSecondaryCannabinoids?.length > 0;
      const hasLocationPillPlaceholders = locationPresentSecondaryCannabinoidPillPlaceholders?.length > 0;
      switch (true) {
        case hasLocationSetPresentSecondaryCannabinoids:
          return '(Manual Override)';
        case hasLocationPillPlaceholders && hasCompanySetPresentSecondaryCannabinoids:
          return '(Company Default)';
        case hasLocationPillPlaceholders:
          return '(Auto-calculated)';
        default:
          return 'Select present secondary cannabinoids';
      }
    })
  );

  public cuomSelectionChanged = (cuom: CannabisUnitOfMeasure) => {
    this._selectedCUOM.next(cuom);
  };

  public companyDAUpdated(value: string, accessor: string) {
    this.updatedCompanyDA$.once(companyDA => {
      const companyDACopy = Object.assign(new DisplayAttribute(), companyDA);
      companyDACopy[accessor] = value;
      this._updatedCompanyDA.next(companyDACopy);
    });
  }

  public locationDAUpdated(value: string, accessor: string) {
    this.updatedLocationDA$.once(locationDA => {
      const locationDACopy = Object.assign(new DisplayAttribute(), locationDA);
      locationDACopy[accessor] = value;
      this._updatedLocationDA.next(locationDACopy);
    });
  }

  /**
   * Only look at the da passed in, and NOT it's inherited value.
   * Calling da?.getCannabinoidOrTerpene, da?.getMinCannabinoidOrTerpene, and da?.getMaxCannabinoidOrTerpene in here
   * is wrong, because it will return an inherited value from the companyDA (which we don't want).
   */
  getNonInheritedPresentSecondaryCannabinoidsFromDisplayAttribute(
    useRange: boolean,
    da: DisplayAttribute,
    enabledSecondaryCannabinoids: string[]
  ): string[] {
    return enabledSecondaryCannabinoids?.filter(cannabinoid => {
      if (useRange) {
        return this.hasNumericValueGreaterThanZero(da?.[`min${cannabinoid}`])
            || this.hasNumericValueGreaterThanZero(da?.[`max${cannabinoid}`]);
      } else {
        return this.hasNumericValueGreaterThanZero(da?.[cannabinoid]);
      }
    });
  }

  private hasNumericValueGreaterThanZero(cannabinoidVal: string): boolean {
    return exists(cannabinoidVal) && parseFloat(cannabinoidVal) > 0;
  }

}
