<div *rxIf="!(viewModel.useRange$ | push); else rangeTerpenes" class="input-and-then-preview">
  <lib-reactive-form-column-layout nColumns="2" rowGap="'2rem'" class="flex-fill">
    <lib-reactive-form-group [bindTo]="viewModel.companyDA$ | push" [mergeKey]="mergeKey" [style.display]="'contents'">
      <lib-reactive-form-number
        (valueChanged)="viewModel.companyTerpeneUpdated($event)"
        [inputName]="'company' | randomString"
        [label]="viewModel.companyTerpeneLabel$ | push"
        [placeholder]="viewModel.companyTerpenePlaceholder$ | push"
        [bindingProperty]="viewModel.terpeneAccessor$ | push"
        [tooltip]="viewModel.companyTerpeneTooltip$ | push"
        [disabled]="!(viewModel.isCompanyAdmin$ | push)"
        [minValue]="0"
        [maxValue]="ClientTypes.CannabinoidUtils.MAX_CANNABINOID_NUMBER"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
    </lib-reactive-form-group>
    <lib-reactive-form-group [bindTo]="viewModel.locationDA$ | push" [mergeKey]="mergeKey" [style.display]="'contents'">
      <lib-reactive-form-number
        (valueChanged)="viewModel.locationTerpeneUpdated($event)"
        [inputName]="'location' | randomString"
        [label]="viewModel.locationTerpeneLabel$ | push"
        [placeholder]="viewModel.locationTerpenePlaceholder$ | push"
        [bindingProperty]="viewModel.terpeneAccessor$ | push"
        [tooltip]="viewModel.locationTerpeneTooltip$ | push"
        [disabled]="viewModel.disableTerpeneInputs$ | push"
        [minValue]="0"
        [maxValue]="ClientTypes.CannabinoidUtils.MAX_CANNABINOID_NUMBER"
        [showErrorMessagesOnInitialization]="true"
        [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
    </lib-reactive-form-group>
  </lib-reactive-form-column-layout>
  <app-terpene-preview-chunk [terpene]="terpeneCamelCased"></app-terpene-preview-chunk>
</div>

<ng-template #rangeTerpenes>
  <!-- Min Terpenes -->
  <div class="input-and-then-preview">
    <lib-reactive-form-column-layout nColumns="2" rowGap="'2rem'" class="flex-fill">
      <lib-reactive-form-group
        [bindTo]="viewModel.companyDA$ | push"
        [mergeKey]="mergeKey"
        [style.display]="'contents'">
        <lib-reactive-form-number
          class="terpenes-number-form"
          (valueChanged)="viewModel.companyMinTerpeneUpdated($event)"
          [inputName]="'companyMin' | randomString"
          [label]="viewModel.companyMinTerpeneLabel$ | push"
          [placeholder]="viewModel.companyMinTerpenePlaceholder$ | push"
          [bindingProperty]="viewModel.terpeneMinAccessor$ | push"
          [tooltip]="viewModel.companyMinTerpeneTooltip$ | push"
          [required]="viewModel.hasCompanyMaxTerpene$ | push"
          [disabled]="!(viewModel.isCompanyAdmin$ | push)"
          [minValue]="0"
          [maxValue]="viewModel.companyMaxTerpeneLowerBoundValue$ | push"
          [showErrorMessagesOnInitialization]="true"
          [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
      </lib-reactive-form-group>
      <lib-reactive-form-group
        [bindTo]="viewModel.locationDA$ | push"
        [mergeKey]="mergeKey"
        [style.display]="'contents'">
        <lib-reactive-form-number
          class="terpenes-number-form"
          (valueChanged)="viewModel.locationMinTerpeneUpdated($event)"
          [inputName]="'locationMin' | randomString"
          [label]="viewModel.locationMinTerpeneLabel$ | push"
          [placeholder]="viewModel.locationMinTerpenePlaceholder$ | push"
          [bindingProperty]="viewModel.terpeneMinAccessor$ | push"
          [tooltip]="viewModel.locationMinTerpeneTooltip$ | push"
          [required]="viewModel.hasLocationMaxTerpene$ | push"
          [disabled]="viewModel.disableTerpeneInputs$ | push"
          [minValue]="0"
          [maxValue]="viewModel.locationMaxTerpeneLowerBoundValue$ | push"
          [showErrorMessagesOnInitialization]="true"
          [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
      </lib-reactive-form-group>
    </lib-reactive-form-column-layout>
    <app-terpene-preview-chunk [terpene]="'min' + terpenePascalCased"></app-terpene-preview-chunk>
  </div>
  <!-- Max Terpenes -->
  <div class="input-and-then-preview">
    <lib-reactive-form-column-layout nColumns="2" rowGap="'2rem'" class="flex-fill">
      <lib-reactive-form-group
        [bindTo]="viewModel.companyDA$ | push"
        [mergeKey]="mergeKey"
        [style.display]="'contents'">
        <lib-reactive-form-number
          class="terpenes-number-form"
          (valueChanged)="viewModel.companyMaxTerpeneUpdated($event)"
          [inputName]="'companyMax' | randomString"
          [label]="viewModel.companyMaxTerpeneLabel$ | push"
          [placeholder]="viewModel.companyMaxTerpenePlaceholder$ | push"
          [bindingProperty]="viewModel.terpeneMaxAccessor$ | push"
          [tooltip]="viewModel.companyMaxTerpeneTooltip$ | push"
          [required]="viewModel.hasCompanyMinTerpene$ | push"
          [disabled]="!(viewModel.isCompanyAdmin$ | push)"
          [minValue]="0"
          [maxValue]="ClientTypes.CannabinoidUtils.MAX_CANNABINOID_NUMBER"
          [showErrorMessagesOnInitialization]="true"
          [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
      </lib-reactive-form-group>
      <lib-reactive-form-group
        [bindTo]="viewModel.locationDA$ | push"
        [mergeKey]="mergeKey"
        [style.display]="'contents'">
        <lib-reactive-form-number
          class="terpenes-number-form"
          (valueChanged)="viewModel.locationMaxTerpeneUpdated($event)"
          [inputName]="'locationMax' | randomString"
          [label]="viewModel.locationMaxTerpeneLabel$ | push"
          [placeholder]="viewModel.locationMaxTerpenePlaceholder$ | push"
          [bindingProperty]="viewModel.terpeneMaxAccessor$ | push"
          [tooltip]="viewModel.locationMaxTerpeneTooltip$ | push"
          [required]="viewModel.hasLocationMinTerpene$ | push"
          [disabled]="viewModel.disableTerpeneInputs$ | push"
          [minValue]="0"
          [maxValue]="ClientTypes.CannabinoidUtils.MAX_CANNABINOID_NUMBER"
          [showErrorMessagesOnInitialization]="true"
          [customValueParser]="parseNumberAsString"></lib-reactive-form-number>
      </lib-reactive-form-group>
    </lib-reactive-form-column-layout>
    <app-terpene-preview-chunk [terpene]="'max' + terpenePascalCased"></app-terpene-preview-chunk>
  </div>
</ng-template>
