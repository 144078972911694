<div class="stack-list-container">
  <div class="title-and-desc-container">
    <div class="card-stack-list-title">{{ type | titlecase }} Stacks</div>
    <div class="card-stack-list-desc">Choose what to include for each {{ type | lowercase }} stack.</div>
  </div>
  <app-stack-manual-bulk-print-job-menu-list-item
    *rxFor="let cardStackMenu of viewModel.stackMenus$"
    [stackMenu]="cardStackMenu"
    [locationConfig]="viewModel.locationConfig$ | push"
    [nSelectedItems]="viewModel | nSelectedVariants : cardStackMenu | push"
    [selectedId]="viewModel.selectedCardStackMenuId$ | push"
    (idSelected)="viewModel.connectToSelectedCardStackMenuId($event); idSelected.emit($event)">
  </app-stack-manual-bulk-print-job-menu-list-item>
</div>
