import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { ModalUtils } from '../utils/modal-utils';
import { EditCollectionSaveConfirmationComponent } from '../views/collection/components/modals/edit-collection-save-confirmation/edit-collection-save-confirmation.component';
import { PendingDisplay } from '../models/template/dto/pending-display';

export class ModalCollectionSaveConfirmation {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    originalRequiredDisplayIds: string[],
    updatedRequiredDisplayIds: string[],
    pendingDisplay: PendingDisplay,
    onConfirmation: (result: any) => void
  ) {
    ngZone.run(() => {
      const modalRef = ngbModal.open(EditCollectionSaveConfirmationComponent, ModalUtils.defaultModalOptions(injector));
      const compInst = modalRef.componentInstance as EditCollectionSaveConfirmationComponent;
      compInst.viewModel.connectToOriginalRequiredDisplayIds(originalRequiredDisplayIds);
      compInst.viewModel.connectToUpdatedRequiredDisplayIds(updatedRequiredDisplayIds);
      compInst.viewModel.connectToPendingDisplay(pendingDisplay);
      modalRef.result.then((result) => onConfirmation(result)).catch(() => {});
    });
  }

}
