import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { StackPrintType } from '../../../../../models/automation/enum/card-stack-print-type.enum';
import { ChooseStackPrintTypeViewModel } from './choose-stack-print-type-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import type { StackType } from '../../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';

@Component({
  selector: 'app-choose-card-stack-print-type',
  templateUrl: './choose-stack-print-type.component.html',
  providers: [ChooseStackPrintTypeViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseStackPrintTypeComponent {

  constructor(public viewModel: ChooseStackPrintTypeViewModel) {
  }

  @Input() @ViewModelConnector('connectToStackType') stackType: StackType;
  @Output() stackPrintTypeSelected = new EventEmitter<StackPrintType>();

}
