import { Injector, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Menu } from '../models/menu/dto/menu';
import { ModalUtils } from '../utils/modal-utils';
import { PrintShelfTalkerLiveViewModalComponent } from '../views/live-view/shelf-talkers/print-shelf-talker-live-view-modal/print-shelf-talker-live-view-modal.component';
import { exists } from '../functions/exists';

export class ModalShelfTalkerLiveView {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    shelfTalkerMenu: Menu,
    sectionId?: string
  ) {
    ngZone.run(() => {
      const modalRef = ngbModal.open(
        PrintShelfTalkerLiveViewModalComponent,
        ModalUtils.shelfTalkerLiveViewOptions(injector, shelfTalkerMenu?.displaySize?.orientation)
      );
      const ref = (modalRef.componentInstance as PrintShelfTalkerLiveViewModalComponent);
      ref.viewModel.connectToShelfTalkerMenu(shelfTalkerMenu);
      if (exists(sectionId)) ref.viewModel.connectToSelectedSectionId(sectionId);
    });
  }

}
