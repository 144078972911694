import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { DateUtils } from '../../../utils/date-utils';

export class BannerMessage implements Deserializable, UniquelyIdentifiable {

  message: string;
  clearAfter: number;

  onDeserialize() {}

  getUniqueIdentifier(): string {
    return `
      -${this.message}
      -${this.clearAfter}
    `;
  }

  isWithinTimeWindow(): boolean {
    return DateUtils.nowInUnixSeconds() < this.clearAfter;
  }

}
