import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import type { BulkPrintJob } from '../../../../../../models/automation/bulk-print-job';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { BulkCardStackPrintJobAppliedSectionsViewModel } from './bulk-card-stack-print-job-applied-sections-view-model';

@Component({
  selector: 'app-bulk-card-stack-print-job-applied-sections',
  templateUrl: './bulk-card-stack-print-job-applied-sections.component.html',
  providers: [BulkCardStackPrintJobAppliedSectionsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkCardStackPrintJobAppliedSectionsComponent extends BaseComponent {

  constructor(
    public viewModel: BulkCardStackPrintJobAppliedSectionsViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() title = 'Cards';

}
