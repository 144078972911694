import { Pipe, PipeTransform } from '@angular/core';
import { SelectableSmartFilter } from '../../../../../models/automation/protocols/selectable-smart-filter';
import { Asset } from '../../../../../models/image/dto/asset';

@Pipe({
  name: 'getGroupingImage'
})
export class GetGroupingImagePipe implements PipeTransform {

  transform(selectableSmartFilter: SelectableSmartFilter): Asset {
    const asset = selectableSmartFilter?.getGroupingImage();
    return asset instanceof Asset ? asset : null;
  }

}
