import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ProductTableColumnConfig } from '../../../../models/menu/dto/product-table-column-config';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../domainModels/cannabinoids-and-terpenes-domain-model';
import { map } from 'rxjs/operators';
import { StringUtils } from '../../../../utils/string-utils';

@Injectable()
export class CustomVisibleColumnsModalViewModel extends BaseViewModel {

  constructor(
    private cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel
  ) {
    super();
  }

  private _customProductTableColumnConfig = new BehaviorSubject<ProductTableColumnConfig>(null);
  public customProductTableColumnConfig$ = this._customProductTableColumnConfig as Observable<ProductTableColumnConfig>;
  setCustomProductTableColumnConfig = (customProductTableColumnConfig: ProductTableColumnConfig) => {
    this._customProductTableColumnConfig.next(customProductTableColumnConfig);
  };
  public customConfigHasAtLeast1ColumnEnabled$ = this.customProductTableColumnConfig$.pipe(
    map(customProductTableColumnConfig => Object.values(customProductTableColumnConfig).some(col => col))
  );

  public enabledCannabinoids$ = this.cannabinoidsAndTerpenesDomainModel.enabledSecondaryCannabinoidNames$;
  public enabledTerpenes$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpeneNames$.pipe(
    map(terpeneNames => terpeneNames?.map(terpene => {
      return {
        name: terpene,
        accessor: StringUtils.camelize(terpene)
      };
    }))
  );

  private _changedConfigHasAtLeast1ColumnEnabled = new BehaviorSubject<boolean>(false);
  public changedConfigHasAtLeast1ColumnEnabled$ = this._changedConfigHasAtLeast1ColumnEnabled as Observable<boolean>;

  public canSubmit$ = combineLatest([
    this.customConfigHasAtLeast1ColumnEnabled$,
    this.changedConfigHasAtLeast1ColumnEnabled$
  ]).pipe(
    map(([customConfigHasAtLeast1ColumnEnabled, changedConfigHasAtLeast1ColumnEnabled]) => {
      return customConfigHasAtLeast1ColumnEnabled || changedConfigHasAtLeast1ColumnEnabled;
    })
  );

  formChanged(changes: any) {
    this._changedConfigHasAtLeast1ColumnEnabled.next(Object.values(changes).some(change => change));
  }

}
