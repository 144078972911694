import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Variant } from '../../../models/product/dto/variant';
import { InventoryProvider } from '../../../models/enum/dto/inventory-provider';

@Pipe({
  name: 'presentVariantQuantityInStockToUser'
})
export class PresentVariantQuantityInStockToUserPipe implements PipeTransform {

  transform(variant: Variant, inventoryProvider$: Observable<InventoryProvider>): Observable<string> {
    return inventoryProvider$.pipe(
      map(inventoryProvider => variant?.presentQuantityInStockToUser(inventoryProvider))
    );
  }

}
