import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeMediaModalComponent } from '../views/menu/components/modals/change-media-modal/change-media-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { BudsenseFile } from '../models/shared/budsense-file';
import { Observable } from 'rxjs';
import { ChangeMediaOptions } from '../models/shared/stylesheet/change-media-options';
import { Injector, NgZone } from '@angular/core';

export class ModalChangeMedia {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    allowColors: boolean,
    changeMediaOptions: ChangeMediaOptions,
    changeMediaOperation: (files: BudsenseFile[]) => Observable<string[]>,
    onClose: (uploadedFileNames: string[]) => void
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(
        ChangeMediaModalComponent,
        ModalUtils.changeMediaModalOptions(injector, allowColors)
      );
      const inst = modalRef.componentInstance as ChangeMediaModalComponent;
      inst.options = changeMediaOptions;
      inst.changeMediaOperation = changeMediaOperation;
      modalRef.result.then(onClose).catch(() => {});
    });
  }

}
