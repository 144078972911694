import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringIncludes'
})
export class StringIncludesPipe implements PipeTransform {

  transform(str: string, value: string): boolean {
    return str?.includes(value);
  }

}
