import { Selectable } from '../models/protocols/selectable';

export class ReactiveFormUtils {

  public static buildDivider = (title: string): Selectable => new class implements Selectable {

    getSelectionTitle = (): string => title;
    getSelectionUniqueIdentifier = (): string => '-1';
    getSelectionValue = (): any => undefined;
    getSelectionIsDisabled = (): boolean => true;

  }();

}
