<div class="sticky-header-container">
  <div class="modal-header d-flex align-items-center">
    <div class="modal-title">Live View: {{ viewModel.title$ | push }}</div>
  </div>
</div>

<div class="modal-body">
  <div class="content-container">
    <div class="left-pane">
      <lib-reactive-search-bar
        #searchBar
        [searchTheseProperties]="['name']"
        [placeHolder]="viewModel.searchPlaceholder$ | push"
        [searchThrough]="viewModel.searchableItems$ | push"
        (searchTextAndHits)="viewModel.connectToSearchTextAndHits($event)">
      </lib-reactive-search-bar>
      <app-arrow-button
        *rxFor="let item of viewModel.sortedVisibleItems$; trackBy: 'id'; strategy: 'native'"
        [id]="item?.id"
        [title]="item | getPrintCardOptionTitle : (viewModel.printCardMenu$ | push)"
        [selected]="viewModel | isArrowButtonSelected : item | push"
        [ifSelectedPreventClickEvents]="true"
        [infoText]="
          item
            | getMissingPropertiesForVariant
              : viewModel.printCardMenu$
              : viewModel.locationConfig$
              : viewModel.visibleProperties$
            | push
        "
        (clicked)="viewModel.itemClicked(item)">
      </app-arrow-button>
      <div *rxIf="viewModel.noVisibleItems$" class="empty-state-container">
        <div class="empty-state-text">{{ viewModel.emptyText$ | push }}</div>
      </div>
    </div>
    <div #rightPane class="right-pane">
      <div
        class="right-pane-scaling"
        [style.bottom]="viewModel.verticalCenteringOffset$ | push"
        [style.left]="viewModel.horizontalCenteringOffset$ | push"
        [style.scale]="liveView.scale | push">
        <app-segmented-control
          *ngIf="viewModel.segmentedControls$ | async as options"
          [options]="options"
          (optionSelected)="viewModel.connectToSelectedSegmentValue($event)">
        </app-segmented-control>
        <app-print-card-live-view
          #liveView
          [printCardMenu]="viewModel.printCardMenu$ | push"
          [variantIds]="viewModel.selectedVariantIds$ | push"
          [fitIntoContainer]="rightPane"
          [userSetScale]="viewModel.userSetScale$ | push"
          [metadata]="viewModel.selectedSegmentValue$ | push"
          (cardHeight)="viewModel.connectToCardHeight($event)"
          (cardWidth)="viewModel.connectToCardWidth($event)"
          (smallestScaleValue)="viewModel.connectToSmallestScaleValue($event)"
          (iFrameLoaded)="viewModel.connectToIFrameLoaded($event)">
        </app-print-card-live-view>
      </div>
    </div>
  </div>
</div>

<div class="footer-container bg-white">
  <button
    *ngIf="viewModel.userHasSetScale$ | push"
    (click)="viewModel.resetScale()"
    class="bs-button dark-neutral-button rounded-icon-button reset-scale no-margin">
    <img [src]="'assets/icons/light/outline/refresh.svg'" alt="" class="icon-button-icon" />
  </button>
  <app-range-slider
    [options]="viewModel.scaleRangeOptions$ | push"
    (valueChanged)="viewModel.connectToUserSetScale($event)">
  </app-range-slider>
  <lib-button-primary (buttonClicked)="close()">Done</lib-button-primary>
</div>
