import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AllProductsDataTableViewModel } from './all-products-data-table-view-model';
import { BaseComponent } from 'src/app/models/base/base-component';
import { Product } from 'src/app/models/product/dto/product';
import { ProductsTableComponent } from './products-table/products-table.component';
import { FilterProductsFormComponent } from '../../shared/components/filter-products-form/filter-products-form.component';

@Component({
  selector: 'app-all-products-data-table',
  templateUrl: './all-products-data-table.component.html',
  styleUrls: ['./all-products-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllProductsDataTableComponent extends BaseComponent implements OnChanges {

  @ViewChild('productsTableComponent') protected productsTableComponent!: ProductsTableComponent;
  @ViewChild('filterFormComponent') protected filterFormComponent!: FilterProductsFormComponent;

  @Input() products: Product[];
  @Output() hasFilters: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Output() hasSearchText: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Output() clearExternalFilters: EventEmitter<void> = new EventEmitter<void>(true);
  @Output() hideOutOfStock: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(
    public viewModel: AllProductsDataTableViewModel, // global (provided by root)
  ) {
    super();
  }

  override setupViews() {
    this.viewModel.setProducts(this.products);
  }

  override setupBindings() {
    this.viewModel.tellOutsideComponentsToClearFilters$.subscribeWhileAlive({
      owner: this,
      next: () => this.clearExternalFilters.emit()
    });
  }

  public toggleChangeDetection() {
    this.productsTableComponent?.toggleChangeDetection();
  }

  public clearSearchAndFilters(): void {
    this.filterFormComponent?.clearSearchAndFilters();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.products) this.viewModel.setProducts(this.products);
  }

  public resetPageNumber(): void {
    this.productsTableComponent?.resetPageNumber();
  }

}
