<div class="modal-padding">
  <div class="title-row my-4">
    <div class="company-switch-container">
      <lib-reactive-form-switch
        *ngIf="!(viewModel.viewingCuratedSmartFilter$ | async)"
        [inputName]="'companySmartFilter'"
        [selectedValue]="viewModel?.companyId$ | async"
        [notSelectedValue]="viewModel?.locationId$ | async"
        [bindingProperty]="'locationId'"
        [dispersedKey]="dispersedKey"
        [tooltip]="viewModel.companySmartFilterSwitchTooltip$ | async"
        [labelOnRight]="false"
        [disabled]="viewModel.disableCompanySmartFilterSwitch$ | async">
        Company Smart Filter
      </lib-reactive-form-switch>
    </div>
  </div>

  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-text
      [inputName]="'smartFilterName'"
      [label]="'Smart Filter Name'"
      [placeholder]="'My Smart Filter'"
      [inlineLabel]="false"
      [required]="true"
      [maxLength]="128"
      [disabled]="viewModel.isViewOnly$ | async"
      [bindingProperty]="'name'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-text>

    <lib-reactive-form-number
      [inputName]="'itemCount'"
      [label]="'Item Count'"
      [placeholder]="'Enter an item count'"
      [inlineLabel]="false"
      [minValue]="1"
      [integersOnly]="true"
      [disabled]="viewModel.isViewOnly$ | async"
      [bindingProperty]="'itemCount'"
      [dispersedKey]="dispersedKey"
      (valueChanged)="viewModel.setItemCount($event)">
    </lib-reactive-form-number>

    <lib-reactive-form-drop-down
      #primarySort
      [inputName]="'primarySort'"
      [label]="'Primary Sort'"
      [placeholder]="'Select a primary sort'"
      [inlineLabel]="false"
      [required]="false"
      [clearable]="!(viewModel.isViewOnly$ | async)"
      [useClearableButton]="false"
      [disabled]="viewModel.disableSortDropdowns$ | async"
      [dropdowns]="viewModel.primarySortDropdownOptions$ | async"
      [bindingProperty]="'primarySort'"
      [dispersedKey]="dispersedKey"
      [tooltip]="viewModel.primarySortTooltip$ | async"
      (valueChanged)="primarySortDropdownChanged($event)">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      #secondarySort
      [inputName]="'secondarySort'"
      [label]="'Secondary Sort'"
      [placeholder]="'Select a secondary sort'"
      [inlineLabel]="false"
      [required]="false"
      [clearable]="!(viewModel.disableSecondarySort$ | async)"
      [useClearableButton]="false"
      [disabled]="viewModel.disableSortDropdowns$ | async"
      [dropdowns]="viewModel.secondarySortDropdownOptions$ | async"
      [tooltip]="viewModel.secondarySortTooltip$ | async"
      [bindingProperty]="'secondarySort'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>

  <app-smart-filter-applied-on-pills *ngIf="viewModel.isEditing$ | async" [smartFilter]="bindTo" [isClickable]="false">
  </app-smart-filter-applied-on-pills>

  <div class="title-row">
    <app-segmented-control-for-form
      *ngIf="viewModel.displayAllRecMedControl$ | async"
      [inputName]="'usePurpose'"
      [bindingProperty]="'usePurpose'"
      [dispersedKey]="dispersedKey"
      [options]="viewModel.usePurposeOptions$ | async">
    </app-segmented-control-for-form>
  </div>
  <hr />
</div>
