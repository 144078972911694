<div class="reactive-checkbox-container no-select" [style.z-index]="zIndex">
  <input
    #input
    type="checkbox"
    class="reactive-checkbox"
    [id]="cid"
    [checked]="checked"
    [class.indeterminate]="indeterminate"
    (change)="handleInputChange()"
    [attr.disabled]="disabled ? true : null" />
  <span class="reactive-checkmark" (click)="!disabled ? itemClicked() : null; $event.stopPropagation()"></span>
</div>
