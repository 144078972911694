import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { DisplayAttribute } from '../../../../../models/display/dto/display-attribute';
import { EditVariantModalTabId } from '../../../../../models/enum/shared/edit-variant-modal-tab-id.enum';
import { map } from 'rxjs/operators';
import { ProviderUtils } from '../../../../../utils/provider-utils';
import { InventoryProvider } from '../../../../../models/enum/dto/inventory-provider';
import { Injectable } from '@angular/core';

@Injectable()
export class LotInfoBannerViewModel extends BaseViewModel {

  private _inventoryProvider: BehaviorSubject<InventoryProvider> = new BehaviorSubject(null);
  public readonly inventoryProvider$ = this._inventoryProvider as Observable<InventoryProvider>;
  connectToInventoryProvider = (ip: InventoryProvider) => this._inventoryProvider.next(ip);

  private _displayAttribute: BehaviorSubject<DisplayAttribute> = new BehaviorSubject(null);
  public readonly displayAttribute$ = this._displayAttribute as Observable<DisplayAttribute>;
  connectToDisplayAttribute = (da: DisplayAttribute) => this._displayAttribute.next(da);

  private _selectedTabId: BehaviorSubject<EditVariantModalTabId> = new BehaviorSubject(null);
  public readonly selectedTabId$ = this._selectedTabId as Observable<EditVariantModalTabId>;
  connectToSelectedTabId = (tabId: EditVariantModalTabId) => this._selectedTabId.next(tabId);

  public readonly lotInfoBannerText$ = combineLatest([
    this.inventoryProvider$,
    this.displayAttribute$,
    this.selectedTabId$
  ]).pipe(
    map(([ip, da, tabId]) => ProviderUtils.getDisplayAttributeLotInfoBannerText(ip, da, tabId))
  );

  public readonly showLotInfoBannerText$ = this.inventoryProvider$.pipe(
    map((ip) => ProviderUtils.supportsPOSSync(ip))
  );

  public readonly lotInfo$ = this.displayAttribute$.pipe(map(da => da?.lotInfo));
  public readonly hasLotInfo$ = this.lotInfo$.pipe(map(lotInfo => !!lotInfo));
  public readonly batchId$ = this.lotInfo$.pipe(map(da => da?.batchId || '-'));
  public readonly supplierName$ = this.lotInfo$.pipe(map(da => da?.supplierName || '-'));
  public readonly harvestDate$ = this.lotInfo$.pipe(map(da => da?.harvestDate));
  public readonly packagedDate$ = this.lotInfo$.pipe(map(da => da?.packagedDate));
  public readonly receivingDate$ = this.lotInfo$.pipe(map(da => da?.receivingDate));

}
