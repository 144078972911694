import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Menu } from '../../../../../../../models/menu/dto/menu';

@Component({
  selector: 'app-bulk-card-stack-print-job-applied-sections-accordion',
  templateUrl: './bulk-card-stack-print-job-applied-sections-accordion.component.html',
  styleUrls: ['./bulk-card-stack-print-job-applied-sections-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkCardStackPrintJobAppliedSectionsAccordionComponent {

  @Input() cardStackMenu: Menu;
  @Input() itemCount: number = 0;

}
