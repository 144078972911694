/**
 * These properties live inside `printConfig.visiblePropertyMap`, which is a `Map<PrintCardSize, PrintCardProperty[]>`.
 * These are determined in the admin dashboard app when creating card/label/talker/etc, and are set within the
 * "Visible Properties" modal for each size.
 */
export enum PrintCardProperty {
  // product info
  Name = 'Name',
  Brand = 'Brand',
  Manufacturer = 'Manufacturer',
  SKU = 'SKU',
  Barcode = 'Barcode',
  Description = 'Description',
  StrainType = 'StrainType',
  UnitSize = 'UnitSize',
  PackageQuantity = 'PackagedQuantity',
  ProductTypeVariantType = 'ProductTypeVariantType',
  Strain = 'Strain',
  IsMedical = 'IsMedical',
  Price = 'Price',
  SecondaryPrice = 'SecondaryPrice',
  Label = 'Label',
  Badges = 'Badges',
  CompanyLogo = 'CompanyLogo',
  // cannabinoids
  THC = 'THC',
  CBD = 'CBD',
  TAC = 'TAC',
  EnabledSecondaryCannabinoids = 'EnabledSecondaryCannabinoids',
  // terpenes
  TopTerpene = 'TopTerpene',
  TotalTerpene = 'TotalTerpene',
  PresentTerpenes = 'PresentTerpenes',
  EnabledTerpenes = 'EnabledTerpenes',
}
