<div class="modal-tab-component" [style.height.px]="viewModel.container.tabComponentHeight$ | push">
  <ng-container *rxIf="viewModel.container.isCompanyAdmin$">
    <div class="form-section-title">Display Name - {{ viewModel.container.companyName$ | push }} (Company)</div>
    <lib-reactive-form-column-layout nColumns="2">
      <lib-reactive-form-group
        [bindTo]="viewModel.container.variantCompanyDisplayAttribute$ | push"
        [mergeKey]="'editVariantFormCapture'">
        <lib-reactive-form-text
          [inputName]="'variantName'"
          [label]="'Variant Name'"
          [placeholder]="viewModel.container.companyVariantNamePlaceholder$ | push"
          [bindingProperty]="'displayName'"
          [maxLength]="128"
          (valueChanged)="viewModel?.connectToQueuedCompanyVariantDisplayName($event)"></lib-reactive-form-text>
      </lib-reactive-form-group>

      <lib-reactive-form-group
        [bindTo]="viewModel.container.productCompanyDisplayAttribute$ | push"
        [mergeKey]="'editVariantFormCapture'">
        <lib-reactive-form-text
          [inputName]="'parentProductName'"
          [label]="'Parent Product Name'"
          [placeholder]="viewModel.container.companyProductNamePlaceholder$ | push"
          [bindingProperty]="'displayName'"
          [maxLength]="128"
          [tooltip]="
            'This is the name that will be displayed on menus with Grid Mode enabled, as well as at the top of ' +
            'an expanded product in the products table. Location name overrides Company name.'
          "
          (valueChanged)="viewModel?.connectToQueuedCompanyProductDisplayName($event)"></lib-reactive-form-text>
      </lib-reactive-form-group>
    </lib-reactive-form-column-layout>
  </ng-container>

  <div class="form-section-title">{{ viewModel.locationDisplayNamesTitle$ | push }}</div>
  <div class="form-section-subtext" *rxIf="viewModel.container.isCompanyAdmin$">
    If a location level name is set, it will override the company display name.
  </div>
  <lib-reactive-form-column-layout nColumns="2">
    <lib-reactive-form-group
      [bindTo]="viewModel.container.variantLocationDisplayAttribute$ | push"
      [mergeKey]="'editVariantFormCapture'">
      <lib-reactive-form-text
        [inputName]="'variantName'"
        [label]="'Variant Name'"
        [placeholder]="viewModel.variantDisplayNamePlaceholder$ | push"
        [maxLength]="128"
        [bindingProperty]="'displayName'"></lib-reactive-form-text>
    </lib-reactive-form-group>

    <lib-reactive-form-group
      [bindTo]="viewModel.container.productLocationDisplayAttribute$ | push"
      [mergeKey]="'editVariantFormCapture'">
      <lib-reactive-form-text
        [inputName]="'parentProductName'"
        [label]="'Parent Product Name'"
        [placeholder]="viewModel.productDisplayNamePlaceholder$ | push"
        [bindingProperty]="'displayName'"
        [maxLength]="128"
        [tooltip]="
          'This is the name that will be displayed on Menus with Grid Mode enabled, as well as at the top of ' +
          'an expanded product in the products table. Location name overrides Company name.'
        "></lib-reactive-form-text>
    </lib-reactive-form-group>
  </lib-reactive-form-column-layout>

  <lib-reactive-form-group
    #editVariantForm
    [bindTo]="viewModel.container.variant$ | push"
    [mergeKey]="'editVariantFormCapture'">
    <div class="form-section-title">General Info</div>
    <lib-reactive-form-column-layout [nColumns]="2">
      <lib-reactive-form-pill-drop-down
        [bindingProperty]="'brand'"
        [inputName]="'brand'"
        [label]="'Brand'"
        [placeholder]="'Enter the brand'"
        [inlineLabel]="false"
        [addToAtLeastNItemsFilledInPool]="true"
        [showRequiredAstrix]="true"
        [maxCharactersInSearch]="100"
        [limitNumberOfSelectedItemsTo]="1"
        [userCanAddNewDelimitedItems]="true"
        [submitAsDelimitedString]="true"
        [delimitedDropDownOptions]="viewModel?.container?.brandsDelimited$ | push">
      </lib-reactive-form-pill-drop-down>
      <lib-reactive-form-text
        [inputName]="'manufacturer'"
        [label]="'Manufacturer'"
        [placeholder]="'Enter the manufacturer'"
        [minLength]="1"
        [maxLength]="100"
        [bindingProperty]="'manufacturer'">
      </lib-reactive-form-text>
      <lib-reactive-form-drop-down
        #productTypeDropdown
        [inputName]="'filterByProductType'"
        [label]="'Product Type'"
        [showRequiredAstrix]="true"
        [placeholder]="'Choose a product type'"
        [dropdowns]="viewModel.productTypeOptions$ | push"
        [bindingProperty]="'productType'"
        (valueChanged)="viewModel.connectToSelectedProductType($event)">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-drop-down
        #variantTypeDropdown
        [inputName]="'variantType'"
        [label]="'Variant Type'"
        [showRequiredAstrix]="true"
        [placeholder]="'Choose a variant type'"
        [bindingProperty]="'variantType'"
        [disabled]="viewModel.variantTypeDisabled$ | push"
        [dropdowns]="viewModel.variantTypeOptions$ | push"
        (valueChanged)="viewModel.connectToSelectedVariantType($event)">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-drop-down
        [hidden]="viewModel.isNonCannabinoid$ | push"
        [inputName]="'classification'"
        [label]="'Strain Type'"
        [showRequiredAstrix]="true"
        [placeholder]="'Choose a strain type'"
        [dropdowns]="viewModel.strainTypeOptions$ | push"
        [bindOnSubmit]="!(viewModel.isNonCannabinoid$ | push)"
        [bindingProperty]="'classification'">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-text
        [hidden]="viewModel.isNonCannabinoid$ | push"
        [inputName]="'strain'"
        [label]="'Strain'"
        [placeholder]="'Enter the strain'"
        [minLength]="1"
        [maxLength]="100"
        [bindOnSubmit]="!(viewModel.isNonCannabinoid$ | push)"
        [bindingProperty]="'strain'">
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>

    <lib-reactive-form-rich-text-area
      [inputName]="'variantRichTextDescription'"
      [label]="'Description'"
      [placeholder]="'Add Description'"
      [bindingProperty]="'richTextDescription'"
      [maxLength]="4096"
      [canCreateHyperlink]="false"
      [canInsertImage]="false"
      [canSetTextColor]="false"
      [canSetBackgroundColor]="false"
      [canCreateBlockQuote]="false"
      [canCreateCodeBlock]="false"
      [canCreateHeaders]="false"></lib-reactive-form-rich-text-area>

    <lib-reactive-form-text-area
      [inputName]="'variantShortDescription'"
      [label]="'Short Description'"
      [placeholder]="'Add Short Description'"
      [bindingProperty]="'shortDescription'"
      [maxLength]="2048"></lib-reactive-form-text-area>

    <ng-container>
      <hr />
      <div class="form-section-title">Packaging Info</div>
      <lib-reactive-form-column-layout [nColumns]="2">
        <lib-reactive-form-text
          [inputName]="'barcode'"
          [label]="'Barcode'"
          [placeholder]="'Variant Barcode'"
          [disabled]="true"
          [disabledTooltip]="viewModel?.container?.barcodeDisabledPlaceholder$ | push"
          [tooltip]="'Barcode of the Product Variant'"
          [bindingProperty]="'barcode'"></lib-reactive-form-text>
        <lib-reactive-form-text
          [inputName]="'sku'"
          [label]="'SKU'"
          [placeholder]="'sku'"
          [disabled]="true"
          [tooltip]="'SKU of the Product Variant'"
          [bindingProperty]="'catalogSKU'"></lib-reactive-form-text>
        <lib-reactive-form-number
          [hidden]="viewModel.isNonCannabinoid$ | push"
          [inputName]="'unitSize'"
          [label]="'Unit Size'"
          [showRequiredAstrix]="true"
          [placeholder]="'Enter the unit size'"
          [maxValue]="1000"
          [tooltip]="viewModel.unitSizeTooltip$ | push"
          [bindOnSubmit]="!(viewModel.isNonCannabinoid$ | push)"
          [bindingProperty]="'unitSize'">
        </lib-reactive-form-number>
        <lib-reactive-form-drop-down
          [hidden]="viewModel.isNonCannabinoid$ | push"
          [inputName]="'unitOfMeasure'"
          [label]="'Unit Of Measure'"
          [showRequiredAstrix]="true"
          [placeholder]="'Select product size unit of measure'"
          [dropdowns]="viewModel.unitOfMeasureOptions$ | push"
          [bindOnSubmit]="!(viewModel.isNonCannabinoid$ | push)"
          [bindingProperty]="'unitOfMeasure'">
        </lib-reactive-form-drop-down>
        <lib-reactive-form-number
          [hidden]="viewModel.isNonCannabinoid$ | push"
          [inputName]="'packagedQuantity'"
          [label]="'Packaged Quantity'"
          [showRequiredAstrix]="true"
          [placeholder]="'Enter the packaged quantity'"
          [maxValue]="1000"
          [integersOnly]="true"
          [tooltip]="'This value is the number of units that are packaged in the variant.'"
          [bindOnSubmit]="!(viewModel.isNonCannabinoid$ | push)"
          [bindingProperty]="'packagedQuantity'">
        </lib-reactive-form-number>
      </lib-reactive-form-column-layout>
    </ng-container>

    <ng-container *rxIf="viewModel.container.hasCustomVariantInputs$">
      <hr />
      <div class="form-section-title">Custom Properties</div>
      <lib-reactive-form-column-layout
        *rxFor="let customVariantInput of viewModel.container.supportedCustomVariantTextInputs$"
        [nColumns]="2">
        <lib-reactive-form-text
          [disabled]="customVariantInput?.disabled"
          [inputName]="customVariantInput.label"
          [label]="customVariantInput.label"
          [placeholder]="customVariantInput?.placeholder || 'Enter a value for ' + customVariantInput.label"
          [minLength]="1"
          [maxLength]="256"
          [required]="customVariantInput?.required || false"
          [disabledTooltip]="customVariantInput?.disabledTooltip"
          [bindingProperty]="customVariantInput.bindToVariantProperty">
        </lib-reactive-form-text>
      </lib-reactive-form-column-layout>
    </ng-container>
  </lib-reactive-form-group>

  <mat-accordion
    *rxIf="(viewModel.container.universalVariant$ | push)?.cultivatorVerified"
    class="cultivator-verified-options-accordion"
    [displayMode]="'flat'">
    <mat-expansion-panel
      #infoPanel
      class="mat-elevation-z0 column-style-options-container"
      (afterExpand)="scrollDownToSeeInfo(infoPanel)">
      <mat-expansion-panel-header>
        <mat-panel-title class="bs-medium f14px ps-4">
          <img
            class="cultivator-verified-badge"
            [container]="'body'"
            [ngbTooltip]="'Product information is verified by the cultivator'"
            alt=""
            src="assets/icons/dark/solid/badge-check.svg" />
          This product is Cultivator verified. We've worked with the cultivator to ensure that product information is
          accurate and available within BudSense. See all verified information for the product below.
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-ubv-info-panel [variant]="viewModel?.container?.universalVariant$ | push"></app-ubv-info-panel>
    </mat-expansion-panel>
  </mat-accordion>
</div>
