import { Injectable, Injector, NgZone } from '@angular/core';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalUserPicker } from '../../../../../modals/modal-user-picker';
import { BulkPrintJob } from '../../../../../models/automation/bulk-print-job';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../../../models/account/dto/user';

@Injectable()
export class PrintJobEmployeeFormViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private injector: Injector,
    private ngZone: NgZone,
    private ngbModal: NgbModal,
  ) {
  }

  private _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => {
    this._job.next(job);
    this._recipientIds.next(job?.recipientIds ?? null);
  };

  private _viewOnly = new BehaviorSubject<boolean>(false);
  public viewOnly$ = this._viewOnly as Observable<boolean>;
  connectToViewOnly = (viewOnly: boolean) => this._viewOnly.next(viewOnly);

  private _mergeKey = new BehaviorSubject<string>('');
  public mergeKey$ = this._mergeKey as Observable<string>;
  connectToMergeKey = (mergeKey: string) => this._mergeKey.next(mergeKey);

  private _recipientIds = new BehaviorSubject<string[] | null>(null);
  public recipientIds$ = this._recipientIds as Observable<string[] | null>;

  public recipients$ = combineLatest([this.recipientIds$, this.companyDomainModel.employees$]).pipe(
    map(([ids, employees]) => employees?.filter(e => ids?.includes(e.userId)))
  );

  public isViewOnlyWithRecipients$ = combineLatest([
    this.viewOnly$, this.job$, this.companyDomainModel.employees$
  ])
    .pipe(
      map(([viewOnly, job, employees]) => {
        const employeesOnJob = employees?.filter(e => job?.recipientIds?.includes(e.userId));
        const hasEmployees = employeesOnJob && employeesOnJob.length > 0;

        return !viewOnly || hasEmployees;
      })
  );

  public description$ = this.viewOnly$.pipe(
    map(viewOnly => {
      return viewOnly
        ? 'View employees that this print job was shared with.'
        : 'Send to the following employees.';
    })
  );

  private addRecipientIds = (users: User[]) => {
    const ids = users.map(u => u.userId);
    this.recipientIds$.once(prevSelectedIds => {
      const newIds = prevSelectedIds?.concat(ids) ?? ids;
      this._recipientIds.next(newIds);
    });
  };

  public openEmployeeSelectionModal(): void {
    combineLatest([this.companyDomainModel.employees$, this.recipients$]).once(([allEmployees, recipients]) => {
      ModalUserPicker.open(
        this.ngZone,
        this.ngbModal,
        this.injector,
        'Add Employee',
        allEmployees,
        recipients,
        this.addRecipientIds
      );
    });
  }

  public removeRecipient(recipient: User): void {
    this.recipientIds$.once(prevSelectedIds => {
      const newIds = prevSelectedIds?.filter(id => id !== recipient.userId) ?? [];
      this._recipientIds.next(newIds);
    });
  }

}
