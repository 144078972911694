import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WebProductMenusViewModel } from '../web-product-menus-view-model';
import { DisplayableItemListComponent } from '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component';

@Component({
  selector: 'app-web-product-menu-list',
  templateUrl: '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.html',
  styleUrls: [
    '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.scss',
    './web-product-menu-list.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebProductMenuListComponent extends DisplayableItemListComponent {

  constructor(
    public webProductMenusViewModel: WebProductMenusViewModel, // Provided by parent component
  ) {
    super(webProductMenusViewModel);
  }

}
