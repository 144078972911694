import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { HiddenFormInputComponent } from '../../../../../shared/forms/hidden-form-input/hidden-form-input.component';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@mobilefirstdev/reactive-form';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PendingDisplay } from '../../../../../../models/template/dto/pending-display';

@Component({
  selector: 'app-edit-collection-pending-display-form-item',
  templateUrl: '../../../../../shared/forms/hidden-form-input/hidden-form-input.component.html',
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => EditCollectionPendingDisplayFormItemComponent)
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditCollectionPendingDisplayFormItemComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCollectionPendingDisplayFormItemComponent extends HiddenFormInputComponent implements OnChanges {

  constructor(
    elementRef: ElementRef,
    dispersedFormGroupService: DispersedFormGroupService
  ) {
    super(elementRef, dispersedFormGroupService);
  }

  @Input() pendingDisplay: PendingDisplay;
  @Output() pendingDisplayChanged = new EventEmitter<PendingDisplay>();

  /**
   * Keep track of the current pending display, and if the new pending display is different, update the form item.
   * If we don't do this, then the form will be marked as dirty when pendingDisplay is set to the same value.
   */
  private currentPendingDisplay: PendingDisplay;

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    const different = this.currentPendingDisplay?.getUniqueIdentifier() !== this.pendingDisplay?.getUniqueIdentifier();
    if (changes.pendingDisplay && !!this.pendingDisplay && different) {
      this.currentPendingDisplay = window?.injector?.Deserialize?.instanceOf(PendingDisplay, this.pendingDisplay);
      this.handleUniquePendingDisplayChange(changes.pendingDisplay);
    }
  }

  /**
   * Initial empty state is undefined, so if the previous value was undefined, and the new value is not, then the
   * form is being initialized with a value, and we should not mark it as dirty.
   */
  handleUniquePendingDisplayChange(simpleChange: SimpleChange) {
    this.handleInputChange(simpleChange.currentValue);
    this.getSelfAsFormItem()?.patchValue(simpleChange.currentValue);
    const initialized = simpleChange.previousValue === undefined;
    if (!initialized) this.markAsDirty();
    this.pendingDisplayChanged.emit(simpleChange.currentValue);
  }

}
