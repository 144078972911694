<app-loading *ngIf="isLoading$ | async" [options]="loadingOpts$ | async"> </app-loading>

<div class="app-body mt-0 mb-0">
  <!-- Nav Bar -->
  <app-sidebar-navigation
    *rxLet="sidebarVisible$ as visible"
    [hidden]="!visible"
    [class.sidebar-show]="visible"
    [class.sidebar-hide]="!visible"
    class="sidebar">
  </app-sidebar-navigation>
  <!-- Main content -->
  <main class="main">
    <app-page-banner></app-page-banner>
    <main class="main-content">
      <router-outlet></router-outlet>
      <app-banner-events></app-banner-events>
    </main>
  </main>
</div>
