import { Injectable } from '@angular/core';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { map } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { VariantInventory } from '../../../../../models/product/dto/variant-inventory';
import { BsError } from '../../../../../models/shared/bs-error';
import { ToastService } from '../../../../../services/toast-service';
import { ProductDomainModel } from '../../../../../domainModels/product-domain-model';

@Injectable()
export class MakeVariantActiveModalViewModel extends BaseModalViewModel {

  constructor(
    private userDomainModel: UserDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel,
    private productDomainModel: ProductDomainModel,
    private activeModal: NgbActiveModal,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  public isAdmin$ = this.userDomainModel.isCompanyAdmin$;
  public companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public currentLocationId$ = this.locationDomainModel.locationId$;
  public currentLocationName$ = this.locationDomainModel.locationName$;

  public canMakeActiveForAllLocations$ = combineLatest([
    this.isAdmin$,
    this.companyDomainModel.company$
  ]).pipe(
    map(([isAdmin, company]) => isAdmin && company?.locations?.length > 1)
  );

  public singleLocationMakeActiveButtonText$ = combineLatest([
    this.canMakeActiveForAllLocations$,
    this.currentLocationName$
  ]).pipe(
    map(([canMakeActiveForAllLocations, currentLocationName]) => {
      return canMakeActiveForAllLocations ? currentLocationName : 'Make Active';
    })
  );

  private _inventories = new BehaviorSubject<VariantInventory[]>([]);
  public inventories$ = this._inventories as Observable<VariantInventory[]>;
  connectToInventories = (inventories: VariantInventory[]) => this._inventories.next(inventories);

  public makeActiveModalTitle$ = this.canMakeActiveForAllLocations$.pipe(
    map(canMakeActiveForAllLocations => {
      return canMakeActiveForAllLocations
        ? 'Update active state at all locations?'
        : 'Update product active state?';
    })
  );

  public makeActiveModalCopy$ = combineLatest([
    this.canMakeActiveForAllLocations$,
    this.companyConfig$,
  ]).pipe(
    map(([canMakeActiveForAllLocations, cc]) => {
      return canMakeActiveForAllLocations
        ? 'Would you like to update the discontinued product into an active state '
          + 'at all location, or only your location? By applying this to all locations, everyone will now see this '
          + 'product in their active products.'
        : 'By marking this product as active, it will appear in the list of active products in your account. If the '
          + `product does not come in stock within the next ${cc?.discontinuedProductThreshold} days, it will get `
          + 'marked as discontinued again.';
    })
  );

  makeVariantActiveForThisLocation() {
    combineLatest([
      this.inventories$,
      this.currentLocationId$
    ]).once(([inventories, locationId]) => {
      const locationInventory = inventories?.filter(inventory => inventory?.locationId === locationId);
      this.makeVariantActive(locationInventory);
    });
  }

  makeVariantActiveForAllLocations() {
    this.inventories$.once(inventories => this.makeVariantActive(inventories));
  }

  makeVariantActive(inventories: VariantInventory[]) {
    const lm = 'Setting Variant As Active';
    this._loadingOpts.addRequest(lm);
    this.productDomainModel.updateVariantInventories(inventories).once({
      next: _ => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Variant set to active successfully.', 'Activation Successful');
        this.activeModal.close(true);
      },
      error: (err: BsError) => {
        this.toastService.publishError(err);
        throwError(() => err);
      }
    });
  }

}
