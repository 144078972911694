import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationOptions } from '../models/shared/stylesheet/confirmation-options';
import { from, Observable } from 'rxjs';
import { ConfirmationModalComponent } from '../modules/confirmation/confirmation-modal/confirmation-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector } from '@angular/core';

export class ModalConfirmation {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    confirmationOpts: ConfirmationOptions,
    onConfirmation?: (confirmed: boolean) => void,
    returnObservable?: boolean
  ): void | Observable<boolean> {
    const modalRef = ngbModal.open(ConfirmationModalComponent, ModalUtils.confirmationModalOptions(injector));
    const compInstance = modalRef.componentInstance as ConfirmationModalComponent;
    compInstance.setConfirmationOptions(confirmationOpts);
    if (returnObservable) return from(modalRef.result);
    modalRef.result.then((cont) => onConfirmation?.(cont)).catch(() => {});
  }

}
