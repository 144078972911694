import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, defer, interval, Observable, of, throwError } from 'rxjs';
import { LocationConfiguration } from '../models/company/dto/location-configuration';
import { CompanyAPI } from '../api/company-api';
import { DistinctUtils } from '../utils/distinct-utils';
import { AutomationAPI } from '../api/automation-api';
import { Location } from '../models/company/dto/location';
import { CompanyDomainModel } from './company-domain-model';
import { RefetchConfigUtils } from '../utils/refetch-config-utils';
import { UserDomainModel } from './user-domain-model';
import { exists } from '../functions/exists';
import { iiif } from '../utils/observable.extensions';
import { ProviderUtils } from '../utils/provider-utils';
import { BsError } from '../models/shared/bs-error';

// Provided by Logged In Scope
@Injectable()
export class LocationDomainModel extends BaseDomainModel {

  constructor(
    private userDomainModel: UserDomainModel,
    private automationAPI: AutomationAPI,
    private companyAPI: CompanyAPI,
    private companyDomainModel: CompanyDomainModel,
  ) {
    super();
  }

  private lastLocationKey = 'lastLocation';
  private company$ = this.companyDomainModel.company$;

  private _location = new BehaviorSubject<Location|null>(this.getInitialLocation());
  public location$ = combineLatest([
    this.company$,
    this.userDomainModel.user$
  ]).pipe(
    switchMap(([company, user]) => {
      const hasLocation$ = this._location.pipe(
        map(location => {
          return exists(location) && exists(company)
            ? company?.id === location?.companyId
            : exists(location);
        })
      );
      const autoSelect$ = defer(() => {
        const locId = user?.defaultLocationId;
        const defaultLocation = company?.locations?.find(loc => loc.id === locId) || company?.locations?.firstOrNull();
        return of(defaultLocation);
      });
      return iiif(hasLocation$, this._location, autoSelect$);
    }),
    distinctUntilChanged(),
    tap(location => window?.localStorage?.setItem(this.lastLocationKey, JSON.stringify(location))),
    shareReplay({ bufferSize: 1, refCount: true }),
    takeUntil(this.onDestroy)
  );

  public locationId$ = this.location$.pipe(map(loc => loc?.id), distinctUntilChanged());
  public allLocations$ = this.company$.pipe(map(company => company?.locations));
  public locationName$ = this.location$.pipe(map(loc => loc?.name));
  public countryCode$ = this.location$.pipe(map(loc => loc?.countryCode));

  private currentlyFetchingLocationConfig = false;
  private _locationConfig = new BehaviorSubject<LocationConfiguration>(null);
  public locationConfig$ = this._locationConfig.pipe(
    DistinctUtils.distinctUntilChanged,
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public priceFormat$ = this.locationConfig$.pipe(
    map(lc => lc?.priceFormat),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public secondaryPriceSyncedToPricingGroup$ = combineLatest([
    this.companyDomainModel.inventoryProvider$,
    this.locationConfig$,
  ]).pipe(
    map(([ip, locConfig]) => {
      // If the POS supports pricing groups, and the secondary price is mapped to a specific Pricing group id
      return ProviderUtils.supportsPricingGroups(ip) && locConfig?.secondaryPriceGroupId !== '';
    })
  );

  private fetchLocationConfigForLocationId = this.locationId$.pipe(
    distinctUntilChanged(),
    filter(locationId => exists(locationId)),
    tap(() => this._locationConfig.next(null))
  ).subscribeWhileAlive({
    owner: this,
    next: () => this.fetchLocationConfig()
  });

  private refetchLocationConfigOnInterval = combineLatest([
    this.locationId$,
    interval(RefetchConfigUtils.REFETCH_CONFIG_TIME)
  ]).stopWhenInactive().pipe(
    filter(([locationId]) => !!locationId)
  ).subscribeWhileAlive({
    owner: this,
    next: () => this.fetchLocationConfig()
  });

  private getInitialLocation(): Location|null {
    const Deserialize = window?.injector?.Deserialize;
    const lastLocationKey = window?.localStorage?.getItem(this.lastLocationKey);
    if (lastLocationKey !== 'undefined' && lastLocationKey !== 'null' && exists(lastLocationKey)) {
      return Deserialize?.instanceOf(Location, JSON.parse(lastLocationKey));
    }
    return null;
  }

  updateLocationConfiguration(config: LocationConfiguration): Observable<LocationConfiguration> {
    const isUpdatedValueEmpty = config.inventoryReceivingWindow.isEmpty();
    if (isUpdatedValueEmpty) {
      config.inventoryReceivingWindow = null;
    }
    return this.companyAPI.updateLocationConfiguration(config).pipe(
      tap(locationConfig => this._locationConfig.next(locationConfig)),
      catchError((err: BsError) => {
        if (err?.code === 409) this.fetchLocationConfig();
        return throwError(() => err);
      })
    );
  }

  public setCurrentLocation(l: Location): void {
    this._location.next(l);
  }

  public setLocationConfig(config: LocationConfiguration): void {
    this._locationConfig.next(config);
  }

  public updateLocation(location: Location): Observable<Location> {
    return this.companyAPI.updateCompanyLocation(location).pipe(
      tap(() => {
        this.companyDomainModel.loadCompany(true);
      })
    );
  }

  syncSmartDisplayAttributes(locationIds: string[], forceSync?: boolean): Observable<LocationConfiguration[]> {
    const locationsCommaDelimitedList = locationIds?.join(',');
    return this.automationAPI.syncSmartDisplayAttributes(locationsCommaDelimitedList, forceSync).pipe(
      tap((config) => config.map((lc) => this.setLocationConfig(lc)))
    );
  }

  fetchLocationConfig(): void {
    if (!this.currentlyFetchingLocationConfig) {
      this.currentlyFetchingLocationConfig = true;
      this.locationId$
        .pipe(switchMap(locationId => this.companyAPI.getLocationConfiguration(String(locationId))))
        .pipe(take(1))
        .subscribe(config => {
          this._locationConfig.next(config);
          this.currentlyFetchingLocationConfig = false;
        });
    }
  }

  override destroy() {
    super.destroy();
    window?.localStorage?.removeItem(this.lastLocationKey);
  }

}
