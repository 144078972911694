import { Component, Injector, Input, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { combineLatest, Observable } from 'rxjs';
import { DatatableProductFilter } from '../../../../../../../models/product/table/datatable-product-filter';
import { OrderableMenuAsset } from '../../../../../../../models/menu/shared/orderable-menu-asset';
import { SortUtils } from '../../../../../../../utils/sort-utils';
import { EditFeaturedProductMenuViewModel } from '../edit-featured-product-menu-view-model';
import { EditFeaturedProductsViewModel } from './edit-featured-products-view-model';
import { ModalAddProducts } from '../../../../../../../modals/modal-add-products';
import { ModalReorderProductMenuAssets } from '../../../../../../../modals/modal-reorder-product-menu-assets';
import { Menu } from '../../../../../../../models/menu/dto/menu';

@Component({
  selector: 'app-edit-featured-products',
  templateUrl: './edit-featured-products.component.html',
  styleUrls: ['./edit-featured-products.component.scss'],
  providers: [EditFeaturedProductsViewModel]
})
export class EditFeaturedProductsComponent extends BaseComponent {

  @Input() sharedVM: EditFeaturedProductMenuViewModel;
  @Input() mergeKey: string;
  @Input() isTemplatedMenu: boolean;
  @Input() formPristine: boolean = true;
  @Input() formHasErrors: boolean = true;

  constructor(
    public viewModel: EditFeaturedProductsViewModel,
    private ngZone: NgZone,
    private ngbModal: NgbModal,
    private injector: Injector,
  ) {
    super();
  }

  override setupViews() {
    this.viewModel.init(this.sharedVM);
  }

  public addFeatureProductClicked() {
    const contOp = (variantIds: string[]): Observable<boolean> => this.sharedVM?.addNewFeaturedVariants(variantIds);
    const resultOp = (customFilter: DatatableProductFilter) => {};
    const open = (menu: Menu) => ModalAddProducts.open(
      this.ngZone,
      this.ngbModal,
      this.injector,
      menu,
      null,
      contOp,
      resultOp
    );
    this.viewModel?.sharedViewModel.menu$.once(open);
  }

  reorderProductsClicked() {
    combineLatest([
      this.viewModel.sharedViewModel.menu$,
      this.viewModel.sharedViewModel.orderedMedia$
    ]).once(([menu, orderedMedia]) => {
      let order: OrderableMenuAsset[] = orderedMedia;
      order = order.sort(SortUtils.menuAssets);
      const reorderOperation = (ordered: OrderableMenuAsset[]): Observable<boolean> => {
        return this.viewModel.sharedViewModel.updateMenuAssetOrder(ordered);
      };
      const options = this.viewModel.sharedViewModel.getReorderOptions(menu);
      ModalReorderProductMenuAssets.open(this.ngZone, this.ngbModal, this.injector, options, order, reorderOperation);
    });
  }

}
