import { DisplayableItemPreviewViewModel, REFRESH_MENU_PREVIEW_THRESHOLD_MINUTES } from '../displayable-item-preview/displayable-item-preview-view-model';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { DropDownMenuItem, DropDownMenuSection } from '../../../../../../models/shared/stylesheet/drop-down-menu-section';
import { Size } from '../../../../../../models/shared/size';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { inject, Injectable, Injector, NgZone } from '@angular/core';
import { Display } from '../../../../../../models/display/dto/display';
import { debounceTime, map, pairwise } from 'rxjs/operators';
import { DisplayableAction } from '../../../../../../models/menu/enum/menu-action.enum';
import { ConfirmationOptions } from '../../../../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../../../../modals/modal-confirmation';
import { DisplayDomainModel } from '../../../../../../domainModels/display-domain-model';
import { BsError } from '../../../../../../models/shared/bs-error';
import { ModalDisplayLiveView } from '../../../../../../modals/modal-display-live-view';
import { Router } from '@angular/router';
import { Orientation } from '../../../../../../models/utils/dto/orientation-type';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { TemplateCollection } from '../../../../../../models/template/dto/template-collection';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { MenuPreview } from '../../../../../../models/menu/shared/menu-preview';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';
import { PreviewOf } from '../../../../../../models/enum/shared/preview-of';
import { PreviewService } from '../../../../../../services/preview-service';
import { DateUtils } from '../../../../../../utils/date-utils';
import { exists } from '../../../../../../functions/exists';
import { DeviceDomainModel } from '../../../../../../domainModels/device-domain-model';

@Injectable()
export class DisplayPreviewViewModel extends DisplayableItemPreviewViewModel {

  constructor(
    private deviceDomainModel: DeviceDomainModel,
    private displayDomainModel: DisplayDomainModel,
    private router: Router,
    protected menuPreviewService: MenuPreviewService,
    menuDomainModel: MenuDomainModel,
    locationDomainModel: LocationDomainModel,
    toastService: ToastService,
    navigationService: NavigationService,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(
      menuDomainModel,
      locationDomainModel,
      toastService,
      navigationService,
      ngZone,
      ngbModal,
      injector
    );
    this.listenForSlideChange();
  }

  protected override _displayableItem: BehaviorSubject<Display>;
  public override displayableItem$: Observable<Display>;

  public useLandscapeAspectRatio$ = this.displayableItem$.pipe(
    map((d: Display) => d?.displaySize?.orientation === Orientation.Landscape)
  );
  public usePortraitAspectRatio$ = this.displayableItem$.pipe(
    map((d: Display) => {
      const orientation = d?.displaySize?.orientation;
      return (orientation === Orientation.Portrait || orientation === Orientation.ReversePortrait);
    })
  );

  public override showLiveViewButton$ = of(true);

  public dropDownMenuSections$ = this.displayableItem$.pipe(
    map(display => {
      const dropDownMenuSections = [];
      const sectionItems = [];
      const checkIds = (tcs: TemplateCollection[]) => tcs?.flatMap(tc => tc?.requiredDisplayIds)?.contains(display?.id);
      const deletable = !checkIds(display?.templateCollections);
      sectionItems.push(new DropDownMenuItem('Copy Link Url', DisplayableAction.CopyURL));
      sectionItems.push(new DropDownMenuItem('Edit', DisplayableAction.Edit));
      sectionItems.push(new DropDownMenuItem('Live View', DisplayableAction.LiveView));
      if (deletable) {
        sectionItems.push(
          new DropDownMenuItem('Delete Display', DisplayableAction.Delete, new Set<string>().add('red-text'))
        );
      }
      const section = new DropDownMenuSection(null, sectionItems);
      dropDownMenuSections.push(section);
      return dropDownMenuSections;
    })
  );

  public hasDevices$ = combineLatest([
    this.deviceDomainModel.locationDevices$,
    this.displayableItem$,
  ]).pipe(
    map(([devices, display]) => {
      return !!devices?.find(device => device?.displayId === display?.id) || false;
    })
  );

  private previewRefreshMap = new Map<string, boolean>();

  protected listenForPreviewChanges(): void {
    combineLatest([
      this.displayableItem$,
      inject(MenuPreviewService).previews$,
      this.locationId$
    ]).pipe(debounceTime(100)).subscribeWhileAlive({
      owner: this,
      next: ([display, menuPreviews, locationId]) => {
        const contentIds = display?.displayableItemPreviewContentIds(locationId);
        const keys = contentIds?.map(id => PreviewService.getPreviewKey(id, locationId, PreviewOf.MenuImage));
        const previews = keys?.map(key => menuPreviews?.get(key))?.filterNulls();
        this._itemPreviews.next(previews ?? null);
      }
    });
  }

  /**
   * Previews are lazy loaded, so we need to listen for when the slide changes,
   * and then fetch the preview if it hasn't been loaded.
   */
  protected listenForSlideChange(): void {
    this.activeSlideIndex$.pipe(
      pairwise()
    ).subscribeWhileAlive({
      owner: this,
      next: ([prevIndex, currIndex]) => {
        if (prevIndex !== currIndex) {
          this.fetchItemPreviewAtIndexIfDoesntExist(currIndex);
        }
      }
    });
  }

  /**
   * The parent class calls this.fetchItemPreview() within its constructor.
   * Which means this override will not have context to any additional properties injected into the child class
   * when it's being called.
   *
   * IE. fetchItemPreview() won't have access to templateCollectionDeletionService and templateDomainModel.
   * If you try and access those properties from within here, you'll get a null reference error.
   * I can get around this by using Angular's inject statement, so that I can get direct access to the
   * TemplateDomainModel when this method is being called in this weird context.
   *
   * Lazy loading is used for display previews, so that is why we only fetch the first contentId.
   */
  protected override fetchItemPreviews(): void {
    combineLatest([
      this.displayableItem$.distinctUniquelyIdentifiable(),
      this.locationId$.notNull(),
      inject(TemplateDomainModel).menuTemplates$.notNull().distinctUniquelyIdentifiableArray(),
    ]).subscribeWhileAlive({
      owner: this,
      next: ([display, locId, menuTemplates]) => {
        combineLatest([
          this.previewLoadingBundle$,
          this.refreshLoadingBundle$
        ]).once(([previewLoadingBundle, refreshLoadingBundle]) => {
          const contentIds = display?.displayableItemPreviewContentIds(locId);
          if (contentIds?.length) {
            this.initializePreviewLoadingOptions(previewLoadingBundle, contentIds);
            this.initializeRefreshPreviewLoadingOptions(refreshLoadingBundle, contentIds);
            const contentId = contentIds?.firstOrNull();
            const menu = display?.getOrderedContent()?.find(m => m?.id === contentId);
            this.loadMenuPreview(display, menu, locId);
          } else {
            this.loadPlaceholder(display);
          }
        });
      }
    });
  }

  private loadPlaceholder(display: Display) {
    const placeholder = display?.displaySize?.isLandscape()
      ? 'assets/img/display-placeholder/display-empty-L.jpg'
      : 'assets/img/display-placeholder/display-empty-P.jpg';
    this._previewPlaceholderSrc.next(placeholder);
  }

  protected fetchItemPreviewAtIndexIfDoesntExist(index: number): void {
    combineLatest([
      this.displayableItem$,
      this.locationId$,
      this.previewLoadingBundle$,
      this.refreshLoadingBundle$,
      this.itemPreviews$
    ]).once(([display, locId, previewLoadingBundle, refreshLoadingBundle, previews]) => {
      const contentId: string | string[] = display?.displayableItemPreviewContentIds(locId)?.[index];
      if (exists(contentId)) {
        this.initializePreviewLoadingOptions(previewLoadingBundle, [contentId]);
        this.initializeRefreshPreviewLoadingOptions(refreshLoadingBundle, [contentId]);
        const preview = previews?.[index];
        if (!preview) {
          const menu = display?.getOrderedContent()?.find(m => m?.id === contentId);
          this.loadMenuPreview(display, menu, locId);
        }
      }
    });
  }

  protected loadMenuPreview(
    display: Display,
    menu: Menu,
    locId: number
  ) {
    combineLatest([
      this._itemPreviews,
      this.previewLoadingBundle$
    ]).once(([currPreviews, loadingMap]) => {
      const id = menu?.id;
      const key = PreviewService.getPreviewKey(id, locId, PreviewOf.MenuImage);
      const returnLastSaved = !this.menuPreviewService.isPreviewInProgress(key);
      const lm = 'Getting Preview';
      const loadingOpts = loadingMap?.get(id);
      if (!loadingOpts?.containsRequest(lm) && !this.previewRefreshMap.get(id)) {
        this.previewRefreshMap.set(id, true);
        loadingOpts?.addRequest(lm);
        const cacheDelay = returnLastSaved ? 500 : 12000;
        const getMenuPreview = this.menuPreviewService.getMenuPreview;
        getMenuPreview(menu, returnLastSaved, cacheDelay, false, false, true).subscribe({
          next: (menuTemplatePreview) => {
            if (menuTemplatePreview) {
              this.setItemPreviews(display, currPreviews, menuTemplatePreview);
              // Add delay to account for saving of new preview
              this.previewRefreshMap.set(menuTemplatePreview.id, false);
              loadingOpts?.removeRequest(lm);
              const timestamp = menuTemplatePreview.preview.timestamp;
              const refresh = !DateUtils.unixAfterMinutesAgo(timestamp, REFRESH_MENU_PREVIEW_THRESHOLD_MINUTES);
              if (refresh) this.refreshItemPreviewInBackground(menu);
            }
          },
          error: (err: BsError) => {
            this.previewRefreshMap.set(menu.id, false);
            loadingOpts?.removeRequest(lm);
            this.toastService.publishError(err);
            throwError(() => err);
          }
        });
      }
    });
  }

  private setItemPreviews(
    display: Display,
    currPreviews: MenuPreview[],
    newPreview: MenuPreview
  ): void {
    const idsInOrder = display?.getOrderedContentIds();
    const updatedPreviews = currPreviews?.shallowCopy() ?? [];
    const currentIndex = updatedPreviews?.findIndex(p => p?.preview?.id === newPreview?.preview?.id);
    if (currentIndex > -1) updatedPreviews.splice(currentIndex, 1);
    updatedPreviews.push(newPreview);
    updatedPreviews?.sort((a, b) => {
      const aIndex = idsInOrder?.indexOf(a?.preview?.id);
      const bIndex = idsInOrder?.indexOf(b?.preview?.id);
      return aIndex - bIndex;
    });
    this._itemPreviews.next(updatedPreviews);
  }

  protected deleteItem(item: Display): void {
    const lm = 'Deleting Display';
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      this.displayDomainModel.deleteDisplay(item).subscribe((_) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Successfully deleted display.', 'Display deleted');
      }, (error: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      });
    }
  }

  protected loadItemPreviews(d: Display, locId: number) {
    // Function needs to exist to conform to interface
  }

  protected refreshItemPreviewInBackground(menu: Menu) {
    combineLatest([
      this.displayableItem$,
      this._itemPreviews,
      this.refreshLoadingBundle$
    ]).once(([display, currPreviews, refreshLoadingBundle]) => {
      const id = menu?.id;
      const lm = 'Refreshing Preview';
      const loadingOpts = refreshLoadingBundle?.get(id);
      if (!loadingOpts?.containsRequest(lm)) {
        this.previewRefreshMap.set(id, true);
        loadingOpts?.addRequest(lm);
        const imageDelay = 12000;
        this.menuPreviewService.getMenuPreview(menu, false, imageDelay, undefined, false, true).subscribe({
          next: (menuPreview) => {
            this.setItemPreviews(display, currPreviews, menuPreview);
            this.previewRefreshMap.set(id, false);
            loadingOpts?.removeRequest(lm);
            },
          error: (err: BsError) => {
            this.previewRefreshMap.set(id, false);
            loadingOpts?.removeRequest(lm);
            throwError(() => err);
          }
        });
      }
    });
  }

  private promptForDeleteAttachedDisplay() {
    this.displayableItem$.once(display => {
      const opts = new ConfirmationOptions();
      opts.title = 'Uh Oh.';
      opts.bodyText = `It looks like you’re trying to delete Display '${display?.name}' `
        + `while it's still attached to a device. `
        + `To delete this display, delete the device setup (Settings > General > Devices), `
        + `or attach a different display to the associated device.`;
      opts.cancelText = 'Cancel';
      opts.showContinue = false;
      ModalConfirmation.open(this.ngbModal, this.injector, opts);
    });
  }

  private promptForDeleteDetachedDisplay() {
    this.displayableItem$.once(display => {
      const opts = new ConfirmationOptions();
      opts.title = 'Are you sure?';
      opts.bodyText = `You are about to delete the display '${display?.name}'. `
        + `This action is permanent and can not be undone. Deleting this display will mean `
        + `that any TVs that are set to this url will stop working.`;
      opts.continueText = 'Delete Display';
      opts.cancelText = 'Cancel';
      const deleteDisplay = (cont: boolean) => { if (cont) this.deleteItem(display); };
      ModalConfirmation.open(this.ngbModal, this.injector, opts, deleteDisplay);
    });
  }

  openDeleteItemModal() {
    this.hasDevices$.once(hasDevices => {
      hasDevices ? this.promptForDeleteAttachedDisplay() : this.promptForDeleteDetachedDisplay();
    });
  }

  openDuplicateItemModal() {
  }

  openEditItem(): void {
    this.displayableItem$.once(display => {
      this.router.navigate([`/displays/${display?.id}`]).then(() => {
      });
    });
  }

  openLiveViewModal(sizeOverride?: Size) {
    combineLatest([
      this.displayableItem$,
      this.locationDomainModel.locationId$
    ]).once(([display, locationId]) => {
      ModalDisplayLiveView.open(this.ngZone, this.ngbModal, this.injector, display, locationId);
    });
  }

  public copyItemURL() {
    this.displayableItem$.once(display => {
      navigator.clipboard.writeText(display?.url).then((_) => {
        this.toastService.publishInfoMessage('Link copied to clipboard', 'Link Copied!');
      });
    });
  }

}
