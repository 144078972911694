import { Injectable, Injector, NgZone } from '@angular/core';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { WebMenusContainerViewModel } from '../web-menus-container/web-menus-container-view-model';
import { DisplayableItemsViewModel } from '../../../../../shared/components/displayable-content/displayable-item-list/displayable-items-view-model';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayableType } from '../../../../../../models/enum/shared/displayableType.enum';

@Injectable()
export class WebProductMenusViewModel extends DisplayableItemsViewModel {

  constructor(
    protected menuDomainModel: MenuDomainModel,
    // webMenusContainerViewModel provided by Logged In Scope - need to get access to which tag is selected,
    // only way to pass this data to child is via a shared model, because child component is being created
    // by tab bar component (inflated in non conventional way), which means I can't pass in inputs
    protected webMenusContainerViewModel: WebMenusContainerViewModel,
    navigationService: NavigationService,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
  ) {
    super(webMenusContainerViewModel, navigationService, ngZone, ngbModal, injector);
    this.setupFilter();
  }

  protected defaultEmptyStateTitle = 'No Web Product Menus';

  protected itemsToFilter$ = this.menuDomainModel.currentLocationWebMenus$;

  protected getEmptyStateTitleCopy(menuTypeFilter: string, menuFormatFilter: DisplayableType): string {
    return this.genericEmptyStateTitleCopyForMenu(
      menuTypeFilter,
      menuFormatFilter,
      'web product menus',
      'templated web product menus'
    );
  }

  public createWebMenu(): void {
    this.createMenu(MenuType.WebMenu);
  }

}
