import { EditSectionFormViewModel } from '../../../../../edit-menu-section/edit-menu-section-form/edit-section-form/edit-section-form-view-model';
import { Injectable } from '@angular/core';
import { LocationDomainModel } from '../../../../../../../../domainModels/location-domain-model';
import { map } from 'rxjs/operators';
import { SectionSortProductInfo } from '../../../../../../../../models/enum/dto/section-sort-product-info';
import { SortUtils } from '../../../../../../../../utils/sort-utils';
import type { SectionSortType } from '../../../../../../../../models/utils/dto/section-sort-type';
import type { SectionSortOption } from '../../../../../../../../models/enum/dto/section-sort-option';
import type { LocationConfiguration } from '../../../../../../../../models/company/dto/location-configuration';

@Injectable()
export class EditShelfTalkerBrandsSectionFormViewModel extends EditSectionFormViewModel {

  constructor(
    locationDomainModel: LocationDomainModel
  ) {
    super(locationDomainModel);
  }

  protected override zeroStockNoteForSmartFilters = ''
    + 'Note: This shelf talker card is using smart filters. '
    + 'Therefore, out of stock products are automatically omitted.';
  protected override zeroStockNote = 'Note: Products with a quantity of "0" will not appear on this shelf talker card.';

  private validSortOptions = [
    SectionSortProductInfo.BrandAsc,
    SectionSortProductInfo.BrandDesc,
    SectionSortProductInfo.PriceAsc,
    SectionSortProductInfo.PriceDesc
  ];

  public shelfTalkerBrandsPrimarySortingTypes$ = this.primarySortingTypes$.pipe(
    map((options: SectionSortType[]) => {
      return options.filter(option => this.validSortOptions.includes(option.value as SectionSortProductInfo));
    })
  );

  public shelfTalkerBrandsSecondarySortOptions$ = this.secondarySortingTypes$.pipe(
    map((options: SectionSortType[]) => {
      return options.filter(option => this.validSortOptions.includes(option.value as SectionSortProductInfo));
    })
  );

  /**
   * Shelf talker brand cards have limited sort options, so override the default secondary sort and lock it to price.
   */
  protected override getDefaultSecondarySort(locationConfig: LocationConfiguration): SectionSortOption {
    return SectionSortProductInfo.PriceAsc;
  }

  protected override replaceSecondarySort(
    primarySortId: string,
    defaultSecondarySort: SectionSortOption
  ): SectionSortOption {
    const defaultSecondarySortId = SortUtils.sharedSortId(defaultSecondarySort);
    const brandSortId = SortUtils.sharedSortId(SectionSortProductInfo.BrandAsc);
    switch (true) {
      case primarySortId !== defaultSecondarySortId:
        return defaultSecondarySort;
      case primarySortId !== brandSortId:
        return SectionSortProductInfo.BrandAsc;
      default:
        return SectionSortProductInfo.PriceAsc;
    }
  }

}
