import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LocationViewModel } from './location-view-model';
import { BaseComponent } from '../../../../models/base/base-component';
import { CurrentLocationNavItemViewModel } from '../../../dashboard/components/sidebar-nav/nav-item/current-location-nav-item/current-location-nav-item-view-model';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  providers: [LocationViewModel, CurrentLocationNavItemViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationComponent extends BaseComponent implements OnChanges {

  @Input() customText: string = null;

  constructor(
    public viewModel: LocationViewModel,
    public currentLocationNavItemViewModel: CurrentLocationNavItemViewModel,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customText) this.viewModel.connectToCustomText(this.customText);
  }

}
