import { Injectable } from '@angular/core';
import { EditCardStackMenuViewModel } from '../../../edit-card-stack-menu/edit-card-stack-menu-view-model';
import { of } from 'rxjs';

@Injectable()
export class EditShelfTalkerBrandsMenuBodyViewModel extends EditCardStackMenuViewModel {

  public menuTypes$ = window.types.menuTypes$;
  public override mergeKey$ = of('editShelfTalkerMenu');

}
