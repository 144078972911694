import { Injectable, Injector } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { CompanyDomainModel } from '../../../../../../../../domainModels/company-domain-model';
import { MenuDomainModel } from '../../../../../../../../domainModels/menu-domain-model';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { MenuPreviewJobStatus } from '../../../../../../../../models/utils/dto/menu-preview-job-status-type';
import { DateUtils } from '../../../../../../../../utils/date-utils';
import { ToastService } from '../../../../../../../../services/toast-service';
import { ConfirmationOptions } from '../../../../../../../../models/shared/stylesheet/confirmation-options';
import { ModalConfirmation } from '../../../../../../../../modals/modal-confirmation';
import { ModalViewBulkPrintJob } from '../../../../../../../../modals/modal-view-bulk-print-job';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BulkPrintJobsDataTableViewModel } from '../bulk-print-jobs-data-table-view-model';
import { ProductDomainModel } from '../../../../../../../../domainModels/product-domain-model';
import { BulkJobSource } from '../../../../../../../../models/utils/dto/bulk-job-source-type';
import { ModalViewStackPrintJob } from '../../../../../../../../modals/modal-view-stack-print-job';
import { ModalCreateStackPrintJob } from '../../../../../../../../modals/modal-create-stack-print-job';
import { BulkPrintJobDomainModel } from '../../../../../../../../domainModels/bulk-print-job-domain-model';
import { iiif } from '../../../../../../../../utils/observable.extensions';
import { exists } from '../../../../../../../../functions/exists';
import { BsError } from '../../../../../../../../models/shared/bs-error';
import { SmartFiltersDomainModel } from '../../../../../../../../domainModels/smart-filters-domain-model';

@Injectable()
export class BulkPrintJobItemViewModel extends BaseViewModel {

  constructor(
    private bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private container: BulkPrintJobsDataTableViewModel,
    private injector: Injector,
    private menuDomainModel: MenuDomainModel,
    private ngbModal: NgbModal,
    private productDomainModel: ProductDomainModel,
    private smartFiltersDomainModel: SmartFiltersDomainModel,
    private toastService: ToastService
  ) {
    super();
  }

  public readonly templateMode$ = this.bulkPrintJobDomainModel.templateMode$;
  public readonly activeMenuId$ = this.bulkPrintJobDomainModel.activeMenuId$;
  public readonly companyEmployees$ = this.companyDomainModel.employees$;
  public readonly currentLocationMenus$ = this.menuDomainModel.currentLocationMenus$;

  private readonly _rowData = new BehaviorSubject<BulkPrintJob|null>(null);
  public readonly rowData$ = this._rowData as Observable<BulkPrintJob|null>;
  connectToRowData = (rowData: BulkPrintJob|null) => this._rowData.next(rowData);

  private readonly _retrying = new BehaviorSubject<boolean>(false);
  public readonly retrying$ = this._retrying as Observable<boolean>;
  connectToRetrying = (retrying: boolean) => this._retrying.next(retrying);

  public readonly showLoadingSpinner$ = this.rowData$.pipe(
    map(job => {
      const statusToDisplay = MenuPreviewJobStatus[job?.jobStatus];
      const queued = statusToDisplay === MenuPreviewJobStatus.MenuPreviewJobStatus_Queued;
      const processing = statusToDisplay === MenuPreviewJobStatus.MenuPreviewJobStatus_Processing;
      const success = statusToDisplay === MenuPreviewJobStatus.MenuPreviewJobStatus_Success;
      const successButNoUrl = success && !job?.downloadUrl;
      return queued || processing || successButNoUrl;
    })
  );

  public readonly editPrintStackMode$ = combineLatest([
    this.container.editPrintCardMode$,
    this.container.editPrintLabelMode$
  ]).pipe(
    map(([cardMode, labelMode]) => cardMode || labelMode),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly jobStatus$ = this.rowData$.pipe(
    map(job => job?.getStatusToDisplay()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly statusIcon$ = this.jobStatus$.pipe(
    map(status => this.generateStatusIconUrl(status)),
    map(url => url?.includes('clock') ? null : url),
  );

  public readonly menuTooltip$: Observable<string[]> = combineLatest([
    this.rowData$,
    this.currentLocationMenus$
  ]).pipe(
    map(([job, locationMenus]) => {
      const menusOnJob = locationMenus
        ?.filter(m => job?.menuIds?.includes(m?.id))
        ?.map(appliedMenus => appliedMenus?.name)
        || [];
      if (job?.deletedMenuNames?.length > 0) {
        job?.deletedMenuNames?.forEach(name => menusOnJob?.push(name));
      }
      return menusOnJob?.length > 5
        ? [...menusOnJob.take<string>(5), `+${menusOnJob?.length - 5} more`]
        : menusOnJob;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly cardTooltip$: Observable<string[]> = combineLatest([
    this.rowData$,
    this.activeMenuId$,
    this.productDomainModel.currentLocationVariants$
  ]).pipe(
    map(([job, activeMenuId, variants]) => {
      const cardStackPrintConfig = job?.getCardStackPrintConfigFor(activeMenuId);
      const variantIds = cardStackPrintConfig?.variantIds;
      const variantNames: string[] = variants
        ?.filter(v => variantIds?.includes(v.id))
        ?.map(v => v.getDisplayName()) || [];
      return variantIds?.length > 5
        ? [...variantNames.take<string>(5), `+${variantIds?.length - 5} more`]
        : variantNames;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly menuOrCardTooltip$ = iiif(
    this.editPrintStackMode$,
    this.cardTooltip$,
    this.menuTooltip$
  );

  public readonly recipientTooltip$: Observable<string[]> = combineLatest([
    this.rowData$,
    this.companyEmployees$
  ]).pipe(
    map(([job, employees]) => {
      const employeesOnJob = employees?.filter(e => job?.recipientIds?.indexOf(e.userId) !== -1)
        ?.map(appliedEmployees => appliedEmployees?.fullName);
      if (employeesOnJob.length === 0) {
        employeesOnJob.push('No recipients.');
      }
      return employeesOnJob;
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );
  public readonly sharingRecipients$: Observable<string> = combineLatest([
    this.rowData$,
    this.companyEmployees$
  ]).pipe(
    map(([job, employees]) => {
      const employeesOnJob = employees?.filter(e => job?.recipientIds?.indexOf(e.userId) !== -1);
      return employeesOnJob?.length.toString();
    }),
  );

  public readonly jobStatusToDisplay$ = this.rowData$.pipe(
    map((j) => {
      switch (MenuPreviewJobStatus[j?.jobStatus]) {
        case MenuPreviewJobStatus.MenuPreviewJobStatus_Success:
          // If the job is complete, but has yet to receive a downloadUrl, keep it as 'Processing'
          const displayStatus = !!j?.downloadUrl
            ? MenuPreviewJobStatus[j?.jobStatus]
            : MenuPreviewJobStatus.MenuPreviewJobStatus_Processing;
          return displayStatus as MenuPreviewJobStatus;
        case MenuPreviewJobStatus.MenuPreviewJobStatus_Sent:
          return MenuPreviewJobStatus.MenuPreviewJobStatus_Success;
        default:
          return MenuPreviewJobStatus[j?.jobStatus] as MenuPreviewJobStatus;
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly showReprintButton$ = combineLatest([this.editPrintStackMode$, this.jobStatusToDisplay$]).pipe(
    map(([printStackMode, status]) => {
      return printStackMode && status === MenuPreviewJobStatus.MenuPreviewJobStatus_Success;
    })
  );

  public readonly dateCompletedToDisplay$ = combineLatest([
    this.rowData$,
    this.jobStatusToDisplay$,
  ]).pipe(
    map(([job, status]) => {
      if (status === MenuPreviewJobStatus.MenuPreviewJobStatus_Success && job?.dateCompleted !== 0) {
        // Only return the 'Date Completed' time once the status to display is set to Success
        return DateUtils.formatUnixToDateTime(job?.dateCompleted);
      }
      return null;
    })
  );

  public readonly nCardsPrinted$ = combineLatest([this.rowData$, this.activeMenuId$]).pipe(
    map(([job, activeMenuId]) => {
      const cardStackPrintConfig = job?.getCardStackPrintConfigFor(activeMenuId);
      const variantIds = cardStackPrintConfig?.variantIds;
      return variantIds?.length;
    })
  );

  public readonly jobType$ = combineLatest([
    this.rowData$,
    this.activeMenuId$,
    this.editPrintStackMode$,
  ]).pipe(
    map(([job, activeMenuId, editPrintStackMode]) => {
      if (editPrintStackMode) {
        // Within edit print card, the job type options are Smart / Manual (see CardStackPrintType)
        const cardStackPrintConfig = job?.getCardStackPrintConfigFor(activeMenuId);
        return cardStackPrintConfig?.printType;
      } else {
        // Within bulk print table the job type options are Card / Menu (see BulkPrintJobType)
        return job?.getBulkPrintJobType();
      }
    })
  );

  public readonly disableDownloadButton$ = combineLatest([this.rowData$, this.activeMenuId$]).pipe(
    map(([job, activeMenuId]) => {
      if (exists(activeMenuId)) {
        const cardStackPrintConfig = job?.getCardStackPrintConfigFor(activeMenuId);
        const isSmartPrint = cardStackPrintConfig?.printType?.toLowerCase()?.includes('smart');
        const noVariants = cardStackPrintConfig?.variantIds?.length === 0;
        return isSmartPrint && noVariants;
      } else {
        const printConfigMap = job?.getStackPrintConfigMap();
        const firstKey = printConfigMap?.keys()?.next()?.value;
        const isSmartPrint = printConfigMap?.get(firstKey)?.printType?.toLowerCase()?.includes('smart');
        const noVariants = [...(printConfigMap?.values() || [])]?.every(config => !config?.variantIds?.length);
        return isSmartPrint && noVariants;
      }
    })
  );

  public readonly downloadTooltip$ = combineLatest([
    this.disableDownloadButton$,
    this.jobType$
  ]).pipe(
    map(([disabled, jobType]) => {
      const type = jobType?.split('_')?.pop()?.toLowerCase();
      return disabled
        ? `No new ${type}s for download`
        : 'Download completed job';
    })
  );

  public readonly showDownloadButton$ = this.rowData$.pipe(
    map(job => {
      const sentStatus = MenuPreviewJobStatus[job?.jobStatus] === MenuPreviewJobStatus.MenuPreviewJobStatus_Sent;
      const successStatus = MenuPreviewJobStatus[job?.jobStatus] === MenuPreviewJobStatus.MenuPreviewJobStatus_Success;
      const successStatusWithUrl = successStatus && !!job?.downloadUrl;
      return sentStatus || successStatusWithUrl;
    })
  );

  generateStatusIconUrl(status: MenuPreviewJobStatus) {
    switch (status) {
      case MenuPreviewJobStatus.MenuPreviewJobStatus_Processing:
        return '/assets/icons/dark/solid/clock.svg';
      case MenuPreviewJobStatus.MenuPreviewJobStatus_Queued:
        return '/assets/icons/dark/solid/clock.svg';
      case MenuPreviewJobStatus.MenuPreviewJobStatus_Cancelled:
        return '/assets/icons/red/outline/x-circle.svg';
      case MenuPreviewJobStatus.MenuPreviewJobStatus_Failed:
        return '/assets/icons/red/solid/exclamation-circle.svg';
      case MenuPreviewJobStatus.MenuPreviewJobStatus_Sent:
        return '/assets/icons/green/solid/check-circle.svg';
      case MenuPreviewJobStatus.MenuPreviewJobStatus_Success:
        return '/assets/icons/green/solid/check-circle.svg';
    }
    return null;
  }

  retryJob() {
    this.rowData$.once(job => {
      const lmCreatePrintJob = 'Creating Bulk Print Job';
      const lmSyncSmartFilters = 'Syncing Smart Filters';
      this._loadingOpts.addRequest(lmCreatePrintJob);
      this._loadingOpts.addRequest(lmSyncSmartFilters);
      this.connectToRetrying(true);
      this.bulkPrintJobDomainModel.bulkPrintJobContainsStackedContentAtMenuLevel(job).pipe(
        switchMap(containsStackedContent => {
          const async = !containsStackedContent;
          return this.smartFiltersDomainModel.syncSmartFiltersBeforePrintJob(job?.menuIds, async).pipe(
            tap(() => this._loadingOpts.removeRequest(lmSyncSmartFilters))
          );
        }),
        switchMap(() => this.bulkPrintJobDomainModel.retryBulkPrintJob(job))
      ).subscribe({
        complete: () => {
          this.toastService.publishSuccessMessage('', 'Job recreated');
          this._loadingOpts.removeRequest(lmCreatePrintJob);
          this._loadingOpts.removeRequest(lmSyncSmartFilters);
          this.connectToRetrying(false);
        },
        error: (err: BsError) => {
          this.toastService.publishError(err);
          this._loadingOpts.removeRequest(lmCreatePrintJob);
          this._loadingOpts.removeRequest(lmSyncSmartFilters);
          this.connectToRetrying(false);
        }
      });
    });
  }

  downloadJob() {
    this.rowData$.once(job => window.open(job?.downloadUrl));
  }

  cancelJob() {
    this.rowData$.once(job => {
      const opts = new ConfirmationOptions();
      opts.title = 'Cancel Job';
      opts.bodyText = `Are you sure you want to cancel the following job: '${job?.name}'?`;
      opts.cancelText = 'Do Not Cancel';
      opts.continueText = 'Cancel';
      const confirmed = (cont: boolean) => {
        if (cont) {
          this.bulkPrintJobDomainModel.cancelBulkPrintJob(job).subscribe({
            next: canceledJob => {
              if (MenuPreviewJobStatus[canceledJob.jobStatus] !== MenuPreviewJobStatus.MenuPreviewJobStatus_Cancelled) {
                this.toastService.publishWarningMessage('', 'Your job was unable to be cancelled');
              } else {
                this.bulkPrintJobDomainModel.updateActiveBulkPrintJob(canceledJob);
                this.toastService.publishSuccessMessage('', 'Job cancelled');
              }
            }
          });
        }
      };
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmed);
    });
  }

  viewJob() {
    combineLatest([
      this.bulkPrintJobDomainModel.templateMode$,
      this.editPrintStackMode$,
      this.rowData$
    ]).once(([templateMode, printStackMode, job]) => {
      const openStackPrintJob = () => {
        ModalViewStackPrintJob.open(this.ngbModal, this.injector, job?.whatTypeOfStackIsThis(), templateMode, job);
      };
      if (printStackMode) {
        openStackPrintJob();
      } else {
        job?.jobSource === BulkJobSource.BulkJobSource_BulkPrint
          ? ModalViewBulkPrintJob.open(this.ngbModal, this.injector, job)
          : openStackPrintJob();
      }
    });
  }

  public reprintJob(): void {
    combineLatest([
      this.bulkPrintJobDomainModel.templateMode$,
      this.rowData$
    ]).once(([templateMode, job]) => {
      ModalCreateStackPrintJob.open(this.ngbModal, this.injector, job?.whatTypeOfStackIsThis(), templateMode, job);
    });
  }

}
