import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { EditVariantContainer } from '../../edit-variant-container';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { DisplayAttribute } from '../../../../../../../models/display/dto/display-attribute';
import { DistinctUtils } from '../../../../../../../utils/distinct-utils';
import { TerpeneUnitOfMeasure } from '../../../../../../../models/utils/dto/terpene-unit-of-measure-type';

@Injectable()
export class EditVariantTerpenesViewModel extends BaseViewModel {

  constructor(
    public container: EditVariantContainer
  ) {
    super();
  }

  public terpeneUnitOfMeasureOptions$ = window.types.terpeneUnitOfMeasure$;

  public enabledTerpenes$ = this.container.enabledTerpenes$;
  public enabledTerpeneNames$ = this.container.enabledTerpenesNames$;
  public hasEnabledTerpenes$ = this.container.hasEnabledTerpenes$;

  private _selectedTUOM = new BehaviorSubject<TerpeneUnitOfMeasure>(TerpeneUnitOfMeasure.UNKNOWN);
  public selectedTUOM$ = this._selectedTUOM.pipe(distinctUntilChanged());

  private _updatedCompanyDA = new BehaviorSubject<DisplayAttribute>(null);
  public updatedCompanyDA$ = this._updatedCompanyDA.pipe(distinctUntilChanged());

  private _updatedLocationDA = new BehaviorSubject<DisplayAttribute>(null);
  public updatedLocationDA$ = this._updatedLocationDA.pipe(distinctUntilChanged());

  private distinctById = distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable);
  private initial = combineLatest([
    this.container.variant$.pipe(this.distinctById),
    this.container.variantLocationDisplayAttribute$.pipe(this.distinctById),
    this.container.variantCompanyDisplayAttribute$.pipe(this.distinctById)
  ]).subscribeWhileAlive({
    owner: this,
    next: ([variant, locationDA, companyDA]) => {
      this._selectedTUOM.next(variant?.terpeneUnitOfMeasure || TerpeneUnitOfMeasure.UNKNOWN);
      this._updatedCompanyDA.next(companyDA);
      this._updatedLocationDA.next(locationDA);
    }
  });

  public TUOMTooltip$ = this.container.syncTerpeneFromPOS$.pipe(
    map(isSync => {
      if (isSync) {
        return 'Editing the Terpene Unit of Measure is disabled when Sync Terpene from POS is enabled. '
          + 'This value will automatically be set based on the Default Terpene Unit of Measure. '
          + '(See Settings > Product > General)';
      } else {
        return 'The units of measure for terpenes such as Limoene of  within the product.';
      }
    })
  );

  public disableTerpeneInputs$: Observable<boolean> = combineLatest([
    this.container.showTerpenePOSSyncBanner$,
    this.selectedTUOM$
  ]).pipe(
    map(([showPOSSyncBanner, selectedTUOM]) => {
      return showPOSSyncBanner
        || selectedTUOM === TerpeneUnitOfMeasure.NA
        || selectedTUOM === TerpeneUnitOfMeasure.UNKNOWN;
    })
  );

  public tuomSelectionChanged = (tuom: TerpeneUnitOfMeasure) => {
    this._selectedTUOM.next(tuom);
  };

  public companyDAUpdated(value: string, accessor: string) {
    this.updatedCompanyDA$.once(companyDA => {
      const companyDACopy = Object.assign(new DisplayAttribute(), companyDA);
      companyDACopy[accessor] = value;
      this._updatedCompanyDA.next(companyDACopy);
    });
  }

  public locationDAUpdated(value: string, accessor: string) {
    this.updatedLocationDA$.once(locationDA => {
      const locationDACopy = Object.assign(new DisplayAttribute(), locationDA);
      locationDACopy[accessor] = value;
      this._updatedLocationDA.next(locationDACopy);
    });
  }

  public companyDAForTerpenePresenceUpdated(
    value: string,
    accessor: string,
    minAccessor: string,
    maxAccessor: string
  ) {
    this.updatedCompanyDA$.once(companyDA => {
      const companyDACopy = Object.assign(new DisplayAttribute(), companyDA);
      companyDACopy[accessor] = value;
      companyDACopy[minAccessor] = value;
      companyDACopy[maxAccessor] = value;
      this._updatedCompanyDA.next(companyDACopy);
    });
  }

  public locationDAForTerpenePresenceUpdated(
    value: string,
    accessor: string,
    minAccessor: string,
    maxAccessor: string
  ) {
    this.updatedLocationDA$.once(locationDA => {
      const locationDACopy = Object.assign(new DisplayAttribute(), locationDA);
      locationDACopy[accessor] = value;
      locationDACopy[minAccessor] = value;
      locationDACopy[maxAccessor] = value;
      this._updatedLocationDA.next(locationDACopy);
    });
  }

}
