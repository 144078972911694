import { Observable } from 'rxjs';
import { EventEmitter, Injector, NgZone } from '@angular/core';
import { Size } from '../models/shared/size';
import { Menu } from '../models/menu/dto/menu';
import { LoadingOptions } from '../models/shared/loading-options';
import { Orientation } from '../models/utils/dto/orientation-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPrintMenuLiveView } from '../modals/modal-print-menu-live-view';
import { ModalMenuLiveView } from '../modals/modal-menu-live-view';

export class LiveViewUtils {

  static allowLiveView(loadingOpts: LoadingOptions, menu: Menu) {
    const hasOrientation = menu?.displaySize?.orientation !== Orientation.NA;
    const validConfig = !loadingOpts?.isLoading && !!menu?.displaySize && hasOrientation;
    const validSizeForType = menu?.validSizeForType();
    return validConfig && validSizeForType;
  }

  static openLiveView(
    allPristine: boolean,
    formsAutoSubmitted$: Observable<any[]>,
    submitForms: EventEmitter<boolean>,
    openLiveViewModal: () => void
  ) {
    // can ony get into here if there are no errors. Save if there are changes to form, then open live view.
    if (allPristine) {
      openLiveViewModal();
    } else {
      formsAutoSubmitted$.once(_ => openLiveViewModal());
      submitForms.emit(true);
    }
  }

  static openLiveViewModal(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    locationId: number,
    menu: Menu,
    sizeOverride?: Size
  ) {
    switch (true) {
      case menu?.isPrintMenuOrPrintReportMenu():
        ModalPrintMenuLiveView.open(ngZone, ngbModal, injector, menu);
        break;
      default:
        ModalMenuLiveView.open(ngZone, ngbModal, injector, locationId, menu, sizeOverride);
    }
  }

}
