import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PrintCardLiveViewViewModel } from './print-card-live-view-view-model';
import { Menu } from '../../../../models/menu/dto/menu';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { CardLiveViewComponent } from '../../shared/card-live-view.component';

/**
 * The iFrame makes scaling complicated. It doesn't scale content how you'd expect.
 * That is why the scale value gets sent to the parent container.
 */
@Component({
  selector: 'app-print-card-live-view',
  templateUrl: './print-card-live-view.component.html',
  styleUrls: ['./print-card-live-view.component.scss'],
  providers: [PrintCardLiveViewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintCardLiveViewComponent extends CardLiveViewComponent {

  constructor(
    viewModel: PrintCardLiveViewViewModel
  ) {
    super(viewModel);
  }

  @Input() @ViewModelConnector('connectToPrintCardMenu') printCardMenu: Menu;
  @Input() @ViewModelConnector('connectToVariantIds') variantIds: string[];
  @Input() @ViewModelConnector('connectToMetadata') metadata: any;

}
