import { Component } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductTableColumnConfig } from '../../../../models/menu/dto/product-table-column-config';
import { CustomVisibleColumnsModalViewModel } from './custom-visible-columns-modal-view-model';

@Component({
  selector: 'app-custom-visible-column-selection-modal',
  templateUrl: './custom-visible-columns-modal.component.html',
  styleUrls: ['./custom-visible-columns-modal.component.scss'],
  providers: [CustomVisibleColumnsModalViewModel]
})
export class CustomVisibleColumnsModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: CustomVisibleColumnsModalViewModel,
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  setCustomProductTableColumnConfig(customProductTableColumnConfig: ProductTableColumnConfig) {
    const customProductTableColumnConfigCopy = window.injector.Deserialize.instanceOf(
      ProductTableColumnConfig,
      customProductTableColumnConfig
    );
    this.viewModel.setCustomProductTableColumnConfig(customProductTableColumnConfigCopy);
  }

  saveCustomProductTableColumnConfig(): void {
    this.viewModel.customProductTableColumnConfig$.once(customProductTableColumnConfig => {
      this.activeModal.close(customProductTableColumnConfig);
    });
  }

}
