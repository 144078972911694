import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddEditSmartFilterModalOpenedFrom } from '../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { HydratedSmartFilter } from '../models/automation/hydrated-smart-filter';
import { AddEditSmartFilterModalComponent } from '../views/settings/components/settings-automation/smart-filters/manage-smart-filters/add-edit-smart-filter-modal/add-edit-smart-filter-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector, NgZone } from '@angular/core';

export class ModalEditSmartFilter {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    openedFrom: AddEditSmartFilterModalOpenedFrom,
    smartFilter: HydratedSmartFilter,
    onClose?: (req: HydratedSmartFilter) => void
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(
        AddEditSmartFilterModalComponent,
        ModalUtils.addEditSmartFiltersModalOptions(injector)
      );
      const compInstance = modalRef.componentInstance as AddEditSmartFilterModalComponent;
      compInstance.viewModel.connectToIsEditing(true);
      compInstance.viewModel.connectToOpenedFrom(openedFrom);
      compInstance.viewModel.connectToExistingSmartFilter(
        window?.injector?.Deserialize?.instanceOf(HydratedSmartFilter, smartFilter)
      );
      modalRef.result
        .then((editedFilter) => {
          if (!!editedFilter && !!onClose) {
            onClose(editedFilter);
          }
        })
        .catch(() => {});
    });
  }

}
