import { DateUtils } from './date-utils';
import { ProviderUtils } from './provider-utils';
import type { VariantPricing } from '../models/product/dto/variant-pricing';
import { PriceFormat } from '../models/enum/dto/price-format';
import { InventoryProvider } from '../models/enum/dto/inventory-provider';

export class PriceUtils {

  static roundPrice(p: number): number {
    return Math.round(p * 100) / 100;
  }

  static formatPrice(p: number): string {
    return p ? `$${p.toFixed(2)}` : '';
  }

  static formatDecimalIntoPercentage(p: number): string {
    const percentage = p * 100;
    return `${(Math.round(percentage * 10) / 10)}%`;
  }

  static formatSalePrice(vp: VariantPricing, priceFormat: PriceFormat, inventoryProvider: InventoryProvider): string {
    const salePrice = PriceUtils.formatPrice(vp?.getSalePriceOrNull(priceFormat));
    const supportsExplicitSaleStartEndTime = ProviderUtils.supportsExplicitSaleStartEndTime(inventoryProvider);
    if (!!salePrice && supportsExplicitSaleStartEndTime && vp?.overrideStartDate > 0 && vp?.overrideStopDate > 0) {
      // Format with start and end date
      const sd = DateUtils.formatUnixToShorthandDate(vp?.overrideStartDate);
      const ed = DateUtils.formatUnixToShorthandDate(vp?.overrideStopDate);
      if (sd === ed) {
        return `${salePrice} (${sd})`;
      } else {
        return `${salePrice} (${sd} - ${ed})`;
      }
    }
    return salePrice || null;
  }

  /**
   * Rounds a price to the nearest 5 cents
   * ------------ 100th value -----------------------
   * |0|  1  2  3  4  |5|  6  7  8  9  |0|
   *    ←-----||-----→   ←-----||-----→
   *                           || Round 10th value up
   * ------------------------------------------------
   * Examples
   * (5.62 -> 5.6), (5.68 -> 5.7), (3.22 -> 3.2), (9.99 -> 10)
   * (29.98 -> 30), (124.444 -> 124.45)
   */
  static roundToNearest5CentsOrNull(price: number): number | null {
    if (!price) return null;
    return Math.round(price * 20) / 20;
  }

}
