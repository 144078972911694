import { ChangeDetectionStrategy, Component, Injector, NgZone, ViewChild } from '@angular/core';
import { EditSpotlightMenuViewModel } from './edit-spotlight-menu-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../../../../../services/toast-service';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { EditSpotlightMenuCombinedLoadingOptsViewModel } from './edit-spotlight-menu-combined-loading-opts-view-model';
import { EditSpotlightSectionViewModel } from './edit-spotlight-section-view-model';
import { EditStaticSectionMenuComponent } from '../shared/edit-static-section-menu/edit-static-section-menu.component';

@Component({
  selector: 'app-edit-spotlight-menu',
  templateUrl: './edit-spotlight-menu.component.html',
  styleUrls: [
    './edit-spotlight-menu.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [
    EditSpotlightMenuViewModel,
    EditSpotlightSectionViewModel,
    EditSpotlightMenuCombinedLoadingOptsViewModel
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSpotlightMenuComponent extends EditStaticSectionMenuComponent {

  @ViewChild(ReactiveFormMergeGroupsComponent) override formCapture: ReactiveFormMergeGroupsComponent;

  constructor(
    public override viewModel: EditSpotlightMenuViewModel,
    public override sectionViewModel: EditSpotlightSectionViewModel,
    public override sharedLoadingState: EditSpotlightMenuCombinedLoadingOptsViewModel,
    activatedRoute: ActivatedRoute,
    router: Router,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    sanitizer: DomSanitizer,
    toastService: ToastService,
    activeRoute: ActivatedRoute,
  ) {
    super(
      viewModel,
      sectionViewModel,
      sharedLoadingState,
      activatedRoute,
      router,
      ngZone,
      ngbModal,
      injector,
      sanitizer,
      toastService,
      activeRoute
    );
  }

}
