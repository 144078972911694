import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DisplayableItem } from './displayable-item-preview/displayable-item';

@Injectable()
export class DisplayableItemContainerViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _displayableItems = new BehaviorSubject<DisplayableItem[]>(null);
  public displayableItems$ = this._displayableItems as Observable<DisplayableItem[]>;
  public connectToDisplayableItems = (di: DisplayableItem[]) => this._displayableItems.next(di);

  trackDisplayableItem(index: number, item: DisplayableItem): string {
    return item?.displayableItemId();
  }

}
