import { DisplayableType } from '../../../../../../models/enum/shared/displayableType.enum';
import { Injectable, Injector, NgZone } from '@angular/core';
import { DigitalTemplatesContainerViewModel } from '../digital-templates-container/digital-templates-container-view-model';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { TemplateCollectionDomainModel } from '../../../../../../domainModels/template-collection-domain-model';
import { DisplayableItemFilterByActive } from '../../../../../../models/enum/shared/displayable-item-filter-by.active';
import { PublishableDisplayableItemsViewModel } from '../../../../../shared/components/displayable-content/displayable-item-list/publishable-displayable-items-view-model';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateCollection } from '../../../../../../models/template/dto/template-collection';
import { ModalCreateTemplateCollection } from '../../../../../../modals/modal-create-template-collection';

@Injectable()
export class DigitalTemplateCollectionsViewModel extends PublishableDisplayableItemsViewModel {

  constructor(
    protected templatesDomainModel: TemplateDomainModel,
    protected templateCollectionsDomainModel: TemplateCollectionDomainModel,
    // digitalTemplatesContainerViewModel provided by the Logged In Scope - need to get access to which tag is selected,
    // only way to pass this data to child is via a shared model, because child component is being created
    // by tab bar component (inflated in non conventional way), which means I can't pass in inputs
    protected digitalTemplatesContainerViewModel: DigitalTemplatesContainerViewModel,
    navigationService: NavigationService,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
  ) {
    super(digitalTemplatesContainerViewModel, navigationService, ngZone, ngbModal, injector);
    this.setupFilter();
  }

  protected defaultEmptyStateTitle = 'No Template Collections';

  protected override defaultEmptyStateDescription = 'Create a template collection by using the button above.';

  protected itemsToFilter$ = this.templateCollectionsDomainModel.templateCollections$;

  protected override setEmptyState(
    searchText: string,
    tag: string,
    filterDisplayableItemBy: DisplayableItemFilterByActive,
    menuFormatFilter: DisplayableType,
    menuTypeFilter: string,
    hasInactiveItems: boolean,
    hasTemplatedItems: boolean,
    hasItemsToFilter: boolean
  ) {
    const title = this.getTemplateCollectionEmptyStateTitleCopy(filterDisplayableItemBy);
    this.updateEmptyStateHelper(
      [searchText, tag, filterDisplayableItemBy, menuFormatFilter, menuTypeFilter],
      {
        noSearchTextNoTag: () => {
          const description = this.getTemplateCollectionEmptyStateDescriptionCopy(false, false);
          this.updateEmptyStateInfo(hasItemsToFilter, title, description);
        },
        noSearchTextWithTag: () => {
          const description = this.getTemplateCollectionEmptyStateDescriptionCopy(true, false);
          const withThatTag = ` with the tag \"${tag.toUpperCase()}\".`;
          this.updateEmptyStateInfo(hasItemsToFilter, title + withThatTag, description);
        },
        searchTextNoTag: () => {
          const description = this.getTemplateCollectionEmptyStateDescriptionCopy(false, true);
          const couldNotBeFound = ` that include \"${searchText}\".`;
          this.updateEmptyStateInfo(hasItemsToFilter, title + couldNotBeFound, description);
        },
        searchTextWithTag: () => {
          const description = this.getTemplateCollectionEmptyStateDescriptionCopy(true, true);
          const withThatTag = ` with the tag \"${tag.toUpperCase()}\".`;
          const withThatText = ` that include \"${searchText}\"`;
          this.updateEmptyStateInfo(hasItemsToFilter, title + withThatText + withThatTag, description);
        }
      }
    );
  }

  protected getTemplateCollectionEmptyStateTitleCopy(filterDisplayableItemBy: DisplayableItemFilterByActive): string {
    switch (filterDisplayableItemBy) {
      case DisplayableItemFilterByActive.Active:
        return 'Searching for published template collections';
      case DisplayableItemFilterByActive.Inactive:
        return 'Searching for draft template collections';
      default:
        return 'Searching for template collections';
    }
  }

  protected getTemplateCollectionEmptyStateDescriptionCopy(
    hasTag: boolean,
    searchText: boolean
  ): string {
    let description: string;
    switch (true) {
      case searchText && hasTag:
        description = 'Try a different search query or tag to find the template collection that you\'re looking for.';
        break;
      case !searchText && hasTag:
        description = 'Try a different tag to find the template collection that you\'re looking for.';
        break;
      case searchText:
        description = 'Try a different search query to find the template collection that you\'re looking for.';
        break;
      default:
        description = 'Try to filter by a different template status.';
        break;
    }
    return description;
  }

  /**
   * Template collections do not use menuTypeFilter, so implement custom getTemplateCollectionEmptyStateTitleCopy
   * method instead of using the default getEmptyStateTitleCopy method.
   */
  protected getEmptyStateTitleCopy(_menuTypeFilter: string): string {
    return '';
  }

  public openCreateTemplateCollectionModal(): void {
    const onClose = (newCollection: TemplateCollection) => {
      if (newCollection) {
        this.navigationService.navigateToTemplateCollection(newCollection);
      }
    };
    ModalCreateTemplateCollection.open(this.ngZone, this.ngbModal, this.injector, onClose);
  }

}
