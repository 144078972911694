import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable, Injector, NgZone } from '@angular/core';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { map, shareReplay } from 'rxjs/operators';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { SmartFilterGrouping } from '../../../../models/automation/smart-filter-grouping';
import { AddEditSmartFilterModalOpenedFrom } from '../../../../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { ModalSelectSmartFilter } from '../../../../modals/modal-select-smart-filter';
import { ModalCreateSmartFilter } from '../../../../modals/modal-create-smart-filter';
import { exists } from '../../../../functions/exists';

// Provided by Logged In Scope
@Injectable()
export class SmartFilterRowViewModel extends BaseViewModel {

  constructor(
    protected smartFiltersDomainModel: SmartFiltersDomainModel,
    protected activeModal: NgbActiveModal,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected productDomainModel: ProductDomainModel
  ) {
    super();
    this._loadingOpts.next(LoadingOptions.defaultWhiteBackground());
    this.curatedSmartFilterGroups$.subscribeWhileAlive({
      owner: this,
      next: (groups) => {
        if (!groups && !this._loadingOpts.containsRequest('')) this._loadingOpts.addRequest('');
        if (exists(groups)) this._loadingOpts.removeRequest('');
      }
    });
  }

  private _hideOutOfStockProducts = new BehaviorSubject<boolean>(true);
  public hideOutOfStockProducts$ = this._hideOutOfStockProducts.asObservable();

  public curatedSmartFilterGroups$ = this.smartFiltersDomainModel.curatedGroupedSmartFilters$.pipe(
    // Only including category cards that do not contain advanced filters
    map(selectableSmartFilters => {
      return selectableSmartFilters?.filter(smartFilterGrouping => {
        return smartFilterGrouping.getGroupedFilters().some(filters => !filters.advancedFilter);
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public allSmartFilters$ = this.smartFiltersDomainModel.allSmartFilters$;
  private _appliedSmartFilters = new BehaviorSubject<HydratedSmartFilter[]>([]);
  public appliedSmartFilterIds$ = this._appliedSmartFilters.pipe(map(filters => filters?.map(filter => filter.id)));
  public appliedSmartFilters$ = combineLatest([
    this.appliedSmartFilterIds$,
    this.allSmartFilters$
  ]).pipe(
    map(([appliedSmartFilterIds, allSmartFilters]) => {
      return allSmartFilters?.filter(smartFilter => appliedSmartFilterIds?.includes(smartFilter.id));
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public allProductsGrouping = new SmartFilterGrouping('All Products');

  public openAccordion: boolean = false;

  private listenToLengthOfAppliedFilters = this.appliedSmartFilters$.subscribe(appliedSmartFilters => {
    if (appliedSmartFilters?.length === 0) {
      this.openAccordion = false;
    }
  });

  private listenToAppliedSmartFilterProducts = combineLatest([
    this.hideOutOfStockProducts$,
    this.appliedSmartFilters$,
    this.productDomainModel.currentLocationProducts$,
    this.productDomainModel.currentLocationVariants$
  ]).subscribe(([hideOutOfStock, appliedFilters, products, variants]) => {
    products?.forEach(product => product?.resetVariantsThatMeetSmartFilterSearch());
    if (!appliedFilters?.length || !products || !variants) {
      this.productDomainModel.setSmartFilterDataTableProducts(null, null);
      return;
    }
    if (appliedFilters?.some(sf => !sf?.appliedVariantIds)) {
      this.productDomainModel.setSmartFilterDataTableProducts(appliedFilters?.map(it => it?.id), null);
      return;
    }
    let variantIds = appliedFilters?.flatMap(s => s.appliedVariantIds);
    if (!hideOutOfStock) {
      const outOfStockVariants = appliedFilters?.flatMap(s => s.outOfStockVariantIds);
      variantIds = variantIds?.concat(outOfStockVariants);
    }
    variantIds = variantIds?.unique();
    const productIds = variants?.filter(v => variantIds?.includes(v?.id))?.map(v => v?.productId)?.unique();
    const smartFilterProducts = products?.filter(p => productIds?.includes(p.id));
    smartFilterProducts?.forEach(product => {
      // Top of the variant filter pipeline, so we use product.variants to initialize with.
      product.variantsThatMeetSmartFilterSearch = product?.variants?.filter(v => variantIds?.includes(v?.id)) ?? [];
    });
    this.productDomainModel.setSmartFilterDataTableProducts(appliedFilters?.map(it => it?.id), smartFilterProducts);
  });

  openSelectSmartFilterModal() {
    this.appliedSmartFilters$.pipe(map(filters => filters?.map(sf => sf.id))).once(smartFilterIds => {
      ModalSelectSmartFilter.open(
        this.ngZone,
        this.ngbModal,
        this.injector,
        smartFilterIds,
        this.applySmartFiltersFromModal,
        this.createSmartFilter
      );
    });
  }

  applySmartFilterCategory(smartFilters: HydratedSmartFilter[], keepExisting: boolean = false) {
    this.appliedSmartFilters$.once(existingFilters => {
      const existingIds = existingFilters?.map(sf => sf.id);
      const newIds = smartFilters?.map(sf => sf.id);
      const newValue = existingFilters?.concat(smartFilters) || smartFilters;
      if (existingIds?.equals(newIds)) {
        this._appliedSmartFilters.next([]);
      } else {
        if (keepExisting) {
          this._appliedSmartFilters.next([...new Set(newValue)]);
        } else {
          this._appliedSmartFilters.next([...new Set(smartFilters)]);
        }
      }
    });
  }

  applySmartFiltersFromModal = (ids: string[]) => {
    this.allSmartFilters$.once(allSmartFilters => {
      const smartFiltersFromId = exists(ids) && ids?.length > 0
        ? allSmartFilters?.filter(asf => ids?.indexOf(asf.id) !== -1)
        : [];
      this.applySmartFilterCategory(smartFiltersFromId, true);
      this.openAccordion = true;
    });
  };

  createSmartFilter = () => {
    ModalCreateSmartFilter.open(
      this.ngZone,
      this.ngbModal,
      this.injector,
      AddEditSmartFilterModalOpenedFrom.Products,
      this.createSmartFilterHelper
    );
  };

  createSmartFilterHelper = (smartFilter?: HydratedSmartFilter) => {
    const smartFilterId = smartFilter?.id;
    if (!!smartFilterId) this.applySmartFiltersFromModal([smartFilterId]);
  };

  removeSmartFilter(id: string) {
    const existing = this._appliedSmartFilters.getValue();
    const i = existing.findIndex(sf => sf.id === id);
    if (i > -1) {
      existing.splice(i, 1);
    }
    this._appliedSmartFilters.next(existing);
  }

  clearAllSmartFilters() {
    this._appliedSmartFilters.next([]);
  }

  toggleAccordion() {
    this.openAccordion = !this.openAccordion;
  }

  setHideOutOfStockProducts(hidden: boolean) {
    this._hideOutOfStockProducts.next(hidden);
  }

  trackBySelectableSmartFilterId(index: number, smartFilterGrouping: SmartFilterGrouping) {
    return smartFilterGrouping.getId();
  }

  trackBySmartFilterId(index: number, smartFilter: HydratedSmartFilter) {
    return smartFilter.id;
  }

}
