import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditMenuWithMenuAndSectionCombinedTogetherViewModel } from '../edit-menu-and-section-combined-together-menu/edit-menu-with-menu-and-section-combined-together-view-model';
import { CombinedLoadingOptsViewModel } from '../../../../../shared/components/auto-save/combined-loading-opts-view-model';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({template: ''})
export abstract class EditMenuWithMenuAndSectionCombinedTogetherFooterComponent extends BaseComponent {

  protected constructor(
    public viewModel: EditMenuWithMenuAndSectionCombinedTogetherViewModel,
    public sharedLoadingState: CombinedLoadingOptsViewModel
  ) {
    super();
  }

  @Input() menu: Menu;
  @Input() canDeleteTemplateMenu: boolean = true;
  @Input() formDisableSubmit: boolean = true;
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Output() submitForms = new EventEmitter<boolean>(true);

}
