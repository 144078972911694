<div class="pt-24px">
  <lib-reactive-form-text
    #menuName
    [inputName]="'menuName'"
    [dispersedKey]="dispersedKey"
    [label]="'Menu Name (Internal)' | replaceMenuWithTemplate : (viewModel.templateMode$ | push)"
    [placeholder]="'Enter an internal name for the menu.' | replaceMenuWithTemplate : (viewModel.templateMode$ | push)"
    [bindingProperty]="'name'"
    [maxLength]="128"
    [required]="true">
  </lib-reactive-form-text>

  <div class="tag-container" *rxIf="!creatingFromExisting; strategy: 'immediate'">
    <lib-reactive-form-pill-drop-down
      [bindingProperty]="'tags'"
      [inputName]="'tags'"
      [label]="'Tags'"
      [placeholder]="'Select tags to sort your menus by.'"
      [inlineLabel]="false"
      [addToAtLeastNItemsFilledInPool]="true"
      [dispersedKey]="dispersedKey"
      [clearable]="true"
      [mergeDelimitedItemsThatAreTheSameWithDifferentCasing]="{
        on: true,
        showMergedOptionInListWith: 'MostCapitalized',
        whichAnswerIsUsedAsValue: 'LeastCapitalized'
      }"
      [maxCharactersInSearch]="128"
      [limitNumberOfSelectedItemsTo]="50"
      [userCanAddNewDelimitedItems]="true"
      [submitAsDelimitedString]="false"
      [delimiter]="','"
      [delimitedDropDownOptions]="viewModel.tagsDelimited$ | push">
    </lib-reactive-form-pill-drop-down>
  </div>

  <hr />
  <app-card-stack-size-picker
    *rxIf="viewModel.containsStackedContent$; strategy: 'immediate'"
    [menuType]="viewModel.productMenuType$ | push"
    [cardStackSizesToShow]="viewModel.stackSizesSupportedByThemes$ | push"
    (selectedStackSize)="viewModel.stackSizeSelected($event)">
  </app-card-stack-size-picker>
  <!--  Theme Previews  -->
  <app-theme-preview
    *rxIf="!creatingFromExisting; strategy: 'immediate'"
    [companyId]="viewModel.companyId$ | push"
    [menuType]="viewModel.productMenuType$ | push"
    [selectedCardStackSize]="viewModel.selectedStackSize$ | push"
    [themes]="viewModel.themeSelectionOptions$ | push"
    (orientationSelected)="viewModel.orientationSelected($event)"
    (themeSelected)="viewModel.themeSelected($event)">
  </app-theme-preview>
</div>
