import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';
import { StringUtils } from '../../../utils/string-utils';

@Pipe({
  name: 'getVariantTopTerpene'
})
export class GetVariantTopTerpenePipe implements PipeTransform {

  transform(variant: Variant | null, enabledTerpenes: string[] = []): string | null {
    const enabledTerpenesPascalCased = enabledTerpenes?.map(t => StringUtils.toPascalCase(t));
    return variant?.getVariantTopTerpene(enabledTerpenesPascalCased);
  }

}
