import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../../../../../models/product/dto/variant';
import { Section } from '../../../../../../../models/menu/dto/section';

@Pipe({
  name: 'stackAppliedProductListItemTitle'
})
export class StackAppliedProductListItemTitlePipe implements PipeTransform {

  transform(value: Section | Variant): string | null {
    if (value instanceof Section) return value?.getSectionTitle();
    if (value instanceof Variant) return value?.getVariantTitle();
    return 'Could Not Find';
  }

}
