import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { ModalUtils } from '../utils/modal-utils';
import { PublishCollectionComponent } from '../views/collection/components/modals/publish-collection/publish-collection.component';
import { TemplateCollection } from '../models/template/dto/template-collection';

export class ModalPublishCollection {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    collection: TemplateCollection,
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(PublishCollectionComponent, ModalUtils.defaultModalOptions(injector));
      const compInstance = modalRef.componentInstance as PublishCollectionComponent;
      compInstance.viewModel.connectToCollection(collection);
    });
  }

}
