import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { BulkPrintJobType } from '../../../../../models/automation/enum/bulk-print-job-type';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { DistinctUtils } from '../../../../../utils/distinct-utils';

@Injectable()
export class ChooseBulkPrintJobTypeViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private menuDomainModel: MenuDomainModel
  ) {
    super();
  }

  public readonly themes$ = this.menuDomainModel.menuThemes$;
  public readonly companyId$ = this.companyDomainModel.companyId$;

  public bulkPrintJobTypeOptions$ = combineLatest([
    this.themes$,
    this.companyId$
  ]).pipe(
    map(([themes, companyId]) => {
      return [
        {
          type: BulkPrintJobType.BulkPrintJobType_Menu,
          title: 'Print Menus',
          subtitle: 'Select existing print menus'
        },
        {
          type: BulkPrintJobType.BulkPrintJobType_Card,
          title: 'Print Cards',
          subtitle: 'Select existing print card stacks'
        },
        ...themes?.some(theme => theme?.isUsableShelfTalker(companyId))
          ? [{
              type: BulkPrintJobType.BulkPrintJobType_ShelfTalker,
              title: 'Shelf Talkers',
              subtitle: 'Select shelf talker stacks'
            }]
          : [],
        {
          type: BulkPrintJobType.BulkPrintJobType_Label,
          title: 'Print Labels',
          subtitle: 'Select existing print label stacks'
        }
      ];
    }),
    distinctUntilChanged(DistinctUtils.distinctJSON)
  );

}
