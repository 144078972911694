import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartFilterSmallSyncButtonComponent } from './components/smart-filter-small-sync-button/smart-filter-small-sync-button.component';
import { MatRippleModule } from '@angular/material/core';
import { SmartFilterLargeSyncButtonComponent } from './components/smart-filter-large-sync-button/smart-filter-large-sync-button.component';
import { SmartFilterAddedToSectionComponent } from './components/smart-filter-added-to-section/smart-filter-added-to-section.component';
import { SmartFilterSelectionModalComponent } from './modal/smart-filter-selection-modal/smart-filter-selection-modal.component';
import { SharedModule } from '../shared/shared.module';
import { DuplicateSmartFilterModalComponent } from './modal/duplicate-smart-filter-modal/duplicate-smart-filter-modal.component';
import { SmartFilterSelectionComponent } from './components/smart-filter-selection/smart-filter-selection.component';
import { SmartFilterSelectionGroupComponent } from './components/smart-filter-selection-group/smart-filter-selection-group.component';
import { IsSmartFilterGroupingPipe } from './pipes/is-smart-filter-group.pipe';
import { IsSmartFilterPipe } from './pipes/is-smart-filter.pipe';
import { CastAsHydratedSmartFilterPipe } from './pipes/cast-as-hydrated-smart-filter.pipe';
import { IsHydratedSmartFilterPipe } from './pipes/is-hydrated-smart-filter.pipe';
import { CastAsSmartFilterGroupingPipe } from './pipes/cast-as-smart-filter-grouping.pipe';
import { SmartFilterGroupSelectionComponent } from './components/smart-filter-selection/smart-filter-group-selection/smart-filter-group-selection.component';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { BasicAddEditSmartFilterFormComponent } from './forms/add-edit-smart-filter-form/basic-add-edit-smart-filter-form/basic-add-edit-smart-filter-form.component';
import { AdvancedAddEditSmartFilterFormComponent } from './forms/add-edit-smart-filter-form/advanced-add-edit-smart-filter-form/advanced-add-edit-smart-filter-form.component';
import { BudsenseLottieModule } from '../lottie/budsense-lottie.module';
import { SmartFilterCardRowComponent } from './components/smart-filter-card-row/smart-filter-card-row.component';
import { SmartFilterCategoryCardComponent } from './components/smart-filter-card-row/smart-filter-category-card/smart-filter-category-card.component';
import { SmartFilterCardComponent } from './components/smart-filter-card-row/smart-filter-card/smart-filter-card.component';
import { SmartFiltersRowMiscButtonComponent } from './components/smart-filter-card-row/add-more-smart-filters-row-button/smart-filters-row-misc-button.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SmartFiltersSectionComponent } from './components/smart-filters-section/smart-filters-section.component';
import { SmartFilterLineItemComponent } from './components/smart-filter-line-item/smart-filter-line-item.component';
import { ProductLabelsModule } from '../../modules/product-labels/product-labels.module';
import { SmartFilterAppliedOnPillsComponent } from './components/smart-filter-applied-on-pills/smart-filter-applied-on-pills.component';
import { ReactiveButtonsModule } from '@mobilefirstdev/reactive-buttons';
import { IsSectionSyncingSmartFiltersPipe } from './pipes/is-section-syncing-smart-filters.pipe';
import { LoadingShimmerModule } from '@mobilefirstdev/loading-shimmer';
import { RxIf } from '@rx-angular/template/if';
import { RxPush } from '@rx-angular/template/push';
import { RxFor } from '@rx-angular/template/for';
import { CannabinoidAddEditSmartFilterFormComponent } from './forms/add-edit-smart-filter-form/cannbinoid-add-edit-smart-filter-form/cannabinoid-add-edit-smart-filter-form.component';
import { ProductAddEditSmartFilterFormComponent } from './forms/add-edit-smart-filter-form/product-add-edit-smart-filter-form/product-add-edit-smart-filter-form.component';
import { TerpeneAddEditSmartFilterFormComponent } from './forms/add-edit-smart-filter-form/terpene-add-edit-smart-filter-form/terpene-add-edit-smart-filter-form.component';
import { GetSelectionNamePipe } from './components/smart-filter-card-row/smart-filter-category-card/get-selection-name.pipe';
import { GetGroupingImagePipe } from './components/smart-filter-card-row/smart-filter-category-card/get-grouping-image.pipe';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { CannabinoidMinMaxFormComponent } from './forms/add-edit-smart-filter-form/cannbinoid-add-edit-smart-filter-form/components/cannabinoid-min-max-form/cannabinoid-min-max-form.component';
import { TerpeneMinMaxFormComponent } from './forms/add-edit-smart-filter-form/terpene-add-edit-smart-filter-form/components/terpene-min-max-form/terpene-min-max-form.component';

@NgModule({
  declarations: [
    SmartFilterSmallSyncButtonComponent,
    SmartFilterLargeSyncButtonComponent,
    SmartFilterAddedToSectionComponent,
    SmartFilterSelectionModalComponent,
    DuplicateSmartFilterModalComponent,
    SmartFilterSelectionComponent,
    SmartFilterSelectionGroupComponent,
    IsSmartFilterGroupingPipe,
    IsSmartFilterPipe,
    CastAsHydratedSmartFilterPipe,
    IsHydratedSmartFilterPipe,
    CastAsSmartFilterGroupingPipe,
    SmartFilterGroupSelectionComponent,
    SmartFilterCardRowComponent,
    SmartFilterCategoryCardComponent,
    SmartFilterCardComponent,
    SmartFiltersRowMiscButtonComponent,
    BasicAddEditSmartFilterFormComponent,
    AdvancedAddEditSmartFilterFormComponent,
    ProductAddEditSmartFilterFormComponent,
    CannabinoidAddEditSmartFilterFormComponent,
    CannabinoidMinMaxFormComponent,
    TerpeneAddEditSmartFilterFormComponent,
    TerpeneMinMaxFormComponent,
    SmartFiltersSectionComponent,
    SmartFilterLineItemComponent,
    SmartFilterAppliedOnPillsComponent,
    IsSectionSyncingSmartFiltersPipe,
    GetSelectionNamePipe,
    GetGroupingImagePipe
  ],
  exports: [
    SmartFilterSmallSyncButtonComponent,
    SmartFilterLargeSyncButtonComponent,
    SmartFilterAddedToSectionComponent,
    IsSmartFilterGroupingPipe,
    IsSmartFilterPipe,
    CastAsHydratedSmartFilterPipe,
    IsHydratedSmartFilterPipe,
    CastAsSmartFilterGroupingPipe,
    SmartFilterCardRowComponent,
    BasicAddEditSmartFilterFormComponent,
    AdvancedAddEditSmartFilterFormComponent,
    ProductAddEditSmartFilterFormComponent,
    CannabinoidAddEditSmartFilterFormComponent,
    TerpeneAddEditSmartFilterFormComponent,
    SmartFilterSelectionComponent,
    SmartFiltersSectionComponent,
    SmartFilterLineItemComponent,
    SmartFilterAppliedOnPillsComponent,
    IsSectionSyncingSmartFiltersPipe
  ],
  imports: [
    CommonModule,
    MatRippleModule,
    FlexLayoutModule,
    SharedModule,
    ReactiveFormModule,
    BudsenseLottieModule,
    LoadingShimmerModule,
    NgbTooltipModule,
    ProductLabelsModule,
    ReactiveButtonsModule,
    RxIf,
    RxPush,
    RxFor,
  ]
})
export class SmartFiltersModule { }
