import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Variant } from '../../../../../../models/product/dto/variant';
import { MenuStyle } from '../../../../../../models/menu/dto/menu-style';
import { Theme } from '../../../../../../models/menu/dto/theme';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { Product } from 'src/app/models/product/dto/product';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { MenuOptions } from '../../../../../../models/menu/dto/menu-options';
import { Observable } from 'rxjs';
import { MenuSectionVariantGroupingViewModel } from './menu-section-variant-grouping-view-model';
import { Menu } from '../../../../../../models/menu/dto/menu';

@Component({
  selector: 'app-menu-section-variant-grouping',
  templateUrl: './menu-section-variant-grouping.component.html',
  styleUrls: ['./menu-section-variant-grouping.component.scss'],
  providers: [MenuSectionVariantGroupingViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSectionVariantGroupingComponent extends BaseComponent implements OnChanges {

  @Input() menu: Menu;
  @Input() menuOptions: MenuOptions;
  @Input() section: HydratedSection;
  @Input() product: Product;
  @Input() sortedVariantIds: string[];
  @Input() variants: Variant[];
  @Input() sectionStyles: MenuStyle[];
  @Input() theme: Theme;
  @Input() invalidFormState: boolean = false;
  @Input() autoSaving: boolean;
  @Input() removingVariants: boolean = false;
  @Input() templateMode: boolean = false;
  @Input() isTemplatedSection: boolean = false;
  @Input() allowBadgeOverride: boolean = true;
  @Input() allowLabelOverride: boolean = true;
  @Input() allowStyleOverride: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() saveSection: (background: boolean, badgeMap?: Map<string, string[]>) => void;
  @Input() removeVariantFromSection: (v: Variant) => Observable<string[]>;
  @Input() includedOnMenu: boolean = true;

  constructor(
    public viewModel: MenuSectionVariantGroupingViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.product) this.viewModel.connectToProduct(this.product);
    if (changes.variants) this.viewModel.connectToVariants(this.variants);
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
    if (changes.section) this.viewModel.connectToSection(this.section);
    if (changes.themes) this.viewModel.connectToTheme(this.theme);
    if (changes.sortedVariantIds) this.viewModel.connectToSortedVariantIds(this.sortedVariantIds);
  }

}
