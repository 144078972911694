import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { FormValidatorInterface } from '@mobilefirstdev/reactive-form/lib/validators/interfaces/form-validator-interface';
import { exists } from '../../../functions/exists';

export function validStringNumberInputBounds(
  customErrorKey: string,
  lowerBound: number,
  upperBound: number
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value as number;
    if (!value) return null;
    if (exists(value) && exists(lowerBound) && value < lowerBound) {
      return { [customErrorKey]: `Value must be greater than or equal to ${lowerBound}.` };
    }
    if (exists(value) && exists(upperBound) && value > upperBound) {
      return { [customErrorKey]: `Value must be less than or equal to ${upperBound}.` };
    }
    return null;
  };
}

@Directive({
  selector: '[appStringNumberInputBoundsValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: StringNumberInputBoundsValidatorDirective, multi: true }]
})
export class StringNumberInputBoundsValidatorDirective implements FormValidatorInterface {

  @Input() customErrorKey: string = 'input';
  @Input() lowerBound: number = null;
  @Input() upperBound: number = null;

  constructor() { }

  errorKey(): string {
    return this.customErrorKey;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    return validStringNumberInputBounds(this.customErrorKey, this.lowerBound, this.upperBound)(control);
  }

}
