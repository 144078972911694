import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Product } from '../../../../../../../models/product/dto/product';
import { Variant } from '../../../../../../../models/product/dto/variant';
import { HydratedSection } from '../../../../../../../models/menu/dto/hydrated-section';
import { HydratedVariantBadge } from '../../../../../../../models/product/dto/hydrated-variant-badge';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { VariantGroupingHeaderViewModel } from './variant-grouping-header-view-model';

@Component({
  selector: 'app-variant-grouping-header',
  templateUrl: './variant-grouping-header.component.html',
  styleUrls: ['./variant-grouping-header.component.scss'],
  providers: [VariantGroupingHeaderViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariantGroupingHeaderComponent extends BaseComponent implements OnChanges {

  @Input() autoSaving: boolean;
  @Input() removingVariants: boolean = false;
  @Input() menu: Menu;
  @Input() section: HydratedSection;
  @Input() product: Product;
  @Input() variants: Variant[];
  @Input() badges: HydratedVariantBadge[];
  @Input() includedOnMenu: boolean;
  @Output() showLiveView = new EventEmitter<Variant[]>(true);

  constructor(
    public viewModel: VariantGroupingHeaderViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.menu) this.viewModel.connectToMenu(this.menu);
    if (!!changes.section) this.viewModel.connectToSection(this.section);
    if (!!changes.product) this.viewModel.connectToProduct(this.product);
    if (!!changes.variants) this.viewModel.connectToVariants(this.variants);
  }

}
