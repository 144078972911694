import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuAdditionalOptionsComponent } from '../../../../../forms/menu-additional-options/menu-additional-options.component';
import { EditShelfTalkerBrandsMenuViewModel } from './edit-shelf-talker-brands-menu-additional-otpions-view-model';

@Component({
  selector: 'app-edit-shelf-talker-brands-menu-additional-options',
  templateUrl: './edit-shelf-talker-brands-menu-additional-options.component.html',
  styleUrls: ['./edit-shelf-talker-brands-menu-additional-options.component.scss'],
  providers: [EditShelfTalkerBrandsMenuViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShelfTalkerBrandsMenuAdditionalOptionsComponent extends MenuAdditionalOptionsComponent {

  constructor(viewModel: EditShelfTalkerBrandsMenuViewModel) {
    super(viewModel);
  }

}
