<lib-reactive-form-group [mergeKey]="mergeKey" [bindTo]="bindTo" (hasErrors$)="setHasError($event)">
  <lib-reactive-form-column-layout [nColumns]="2">
    <div [style.display]="'flex'" [style.gap.rem]="0.5" [style.align-items]="'center'">
      <lib-reactive-form-drop-down
        #sectionType
        [style.width]="'-webkit-fill-available'"
        [inputName]="'menuTheme'"
        [label]="'Card Theme'"
        [placeholder]="'Choose card theme'"
        [inlineLabel]="false"
        [disabled]="true"
        [required]="true"
        [dropdowns]="viewModel.themes$ | async"
        [bindingProperty]="'theme'">
      </lib-reactive-form-drop-down>

      <lib-button-neutral (buttonClicked)="openThemeDetails()"> Details </lib-button-neutral>
    </div>

    <lib-reactive-form-pill-drop-down
      [bindingProperty]="'tags'"
      [inputName]="'tags'"
      [label]="'Tags'"
      [placeholder]="'Select tags for this menu.'"
      [disabled]="isTemplatedMenu"
      [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
      [inlineLabel]="false"
      [addToAtLeastNItemsFilledInPool]="true"
      [clearable]="true"
      [mergeDelimitedItemsThatAreTheSameWithDifferentCasing]="{
        on: true,
        showMergedOptionInListWith: 'MostCapitalized',
        whichAnswerIsUsedAsValue: 'LeastCapitalized'
      }"
      [maxCharactersInSearch]="128"
      [limitNumberOfSelectedItemsTo]="50"
      [userCanAddNewDelimitedItems]="true"
      [submitAsDelimitedString]="false"
      [delimiter]="','"
      [delimitedDropDownOptions]="viewModel.tagsDelimited$ | push">
    </lib-reactive-form-pill-drop-down>

    <lib-reactive-form-drop-down
      [inputName]="'menuPreviewSize'"
      [label]="'Menu Printout Size'"
      [placeholder]="'Select the size of this shelf talker menu.'"
      [inlineLabel]="false"
      [disabled]="isTemplatedMenu || (viewModel.shelfTalkerPaperSizes$ | push)?.length === 1"
      [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
      [required]="true"
      [tooltip]="viewModel.previewSizeToolTip$ | async"
      [dropdowns]="viewModel.shelfTalkerPaperSizes$ | async"
      [bindingProperty]="'displaySize.name'">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>
</lib-reactive-form-group>
