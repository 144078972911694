<div class="auth-flow-wrapper">
  <app-loading *ngIf="isLoading$ | async" [options]="loadingOpts$ | async"> </app-loading>

  <div class="auth-flow-container">
    <div class="auth-flow-title">
      {{ viewModel.getAuthFlowTitle() }}
    </div>

    <lib-reactive-form-group #form [bindTo]="req$ | async" (formSubmitted$)="formSubmitted($event)">
      <lib-reactive-form-email
        [inputName]="'email'"
        [placeholder]="'Enter your email'"
        [label]="'Email'"
        [bindingProperty]="'email'"
        [required]="true">
      </lib-reactive-form-email>

      <lib-reactive-form-string-number
        [inputName]="'code'"
        [integersOnly]="true"
        [required]="true"
        [label]="'Password Reset Code'"
        [customValueParser]="null"
        [placeholder]="'Enter the password reset code sent to your email.'"
        [bindingProperty]="'code'">
      </lib-reactive-form-string-number>

      <lib-reactive-form-password
        [inputName]="'password'"
        [required]="true"
        [creatingOrUpdatingPassword]="true"
        [confirmPassword]="true"
        [label]="'New Password'"
        [placeholder]="'Enter a new password.'"
        [bindingProperty]="'password'">
      </lib-reactive-form-password>

      <lib-reactive-form-password
        [inputName]="'confirmPassword'"
        [required]="true"
        [creatingOrUpdatingPassword]="true"
        [confirmPassword]="true"
        [label]="'Retype your new password.'"
        [placeholder]="'Confirm your password.'">
      </lib-reactive-form-password>
    </lib-reactive-form-group>

    <div class="flex-container flex-space-between">
      <lib-button-primary [disabled]="form.disableSubmit$ | async" (buttonClicked)="form.submitForm()">
        Reset Password
      </lib-button-primary>
      <lib-button-text (buttonClicked)="viewModel.clearUserAndGetBackToSignIn()"> Return to Sign In </lib-button-text>
    </div>
  </div>
</div>
