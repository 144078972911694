import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class BulkPrintJobsDataTableViewModel extends BaseViewModel {

  private _editPrintCardMode = new BehaviorSubject<boolean>(false);
  public editPrintCardMode$ = this._editPrintCardMode.pipe(distinctUntilChanged());
  connectToEditPrintCardMode = (mode: boolean) => this._editPrintCardMode.next(mode);

  private _editPrintLabelMode = new BehaviorSubject<boolean>(false);
  public editPrintLabelMode$ = this._editPrintLabelMode.pipe(distinctUntilChanged());
  connectToEditPrintLabelMode = (mode: boolean) => this._editPrintLabelMode.next(mode);

  private _editPrintShelfTalkerMode = new BehaviorSubject<boolean>(false);
  public editPrintShelfTalkerMode$ = this._editPrintShelfTalkerMode.pipe(distinctUntilChanged());
  connectToEditPrintShelfTalkerMode = (mode: boolean) => this._editPrintShelfTalkerMode.next(mode);

}
