import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, NgZone, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ReactiveFormDropDownComponent } from '@mobilefirstdev/reactive-form';
import { MenuDetailsFormViewModel } from './menu-details-form-view-model';
import { MenuAdditionalOptionsFormComponent } from '../menu-additional-options-form';
import { Orientation } from '../../../../../../../models/utils/dto/orientation-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalThemeDetails } from '../../../../../../../modals/modal-theme-details';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { DefaultPrintCardPaperSize } from '../../../../../../../models/utils/dto/default-print-card-paper-size-type';
import { DefaultDigitalSize } from '../../../../../../../models/utils/dto/default-digital-size-type';
import { DefaultPrintSize } from '../../../../../../../models/utils/dto/default-print-size-type';
import { PrintCardPaperSizeSubtype } from '../../../../../../../models/enum/dto/print-card-paper-size-subtype';
import { DefaultPrintLabelPaperSize } from '../../../../../../../models/utils/dto/default-print-label-paper-size-type';

@Component({
  selector: 'app-menu-details-form',
  templateUrl: './menu-details-form.component.html',
  styleUrls: ['./menu-details-form.component.scss'],
  providers: [MenuDetailsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuDetailsFormComponent extends MenuAdditionalOptionsFormComponent implements OnChanges {

  @Input() @ViewModelConnector('connectToTemplateMode') templateMode: boolean = false;

  @Output() height = new EventEmitter<number>(true);
  @Output() width = new EventEmitter<number>(true);
  @Output() orientationChanged = new EventEmitter<Orientation>(true);
  @Output() printCardPaperSizeChanged = new EventEmitter<DefaultPrintCardPaperSize>(true);

  @ViewChild('sectionOrientation') protected sectionOrientation: ReactiveFormDropDownComponent;

  constructor(
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    public viewModel: MenuDetailsFormViewModel
  ) {
    super();
  }

  override setupViews() {
    this.viewModel.setMenu(this.menu);
  }

  heightChanged(h: number) {
    this.height.emit(h);
  }

  widthChanged(w: number) {
    this.width.emit(w);
  }

  displaySizeChanged(displaySize: PrintCardPaperSizeSubtype | DefaultDigitalSize | DefaultPrintSize): void {
    if (this.isDefaultPrintCardPaperSize(displaySize)) {
      this.printCardPaperSizeChanged.emit(displaySize);
      this.viewModel.setSelectedPrintoutSize(displaySize);
    }
    if (this.isDefaultPrintLabelPaperSize(displaySize)) {
      this.viewModel.setSelectedPrintoutSize(displaySize);
    }
  }

  private isDefaultPrintCardPaperSize(value: any): value is DefaultPrintCardPaperSize {
    return Object.values(DefaultPrintCardPaperSize).includes(value);
  }

  private isDefaultPrintLabelPaperSize(value: any): value is DefaultPrintLabelPaperSize {
    return Object.values(DefaultPrintLabelPaperSize).includes(value);
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.menu) this.viewModel.setMenu(this.menu);
  }

  openThemeDetails() {
    ModalThemeDetails.open(
      this.ngZone,
      this.ngbModal,
      this.injector,
      this.menu.hydratedTheme,
      this.menu.type,
      this.sectionOrientation?.getMyValue()
    );
  }

}
