<lib-reactive-form-group [mergeKey]="mergeKey" [bindTo]="bindTo" (hasErrors$)="setHasError($event)">
  <lib-reactive-form-column-layout [nColumns]="2">
    <div [style.display]="'flex'" [style.gap.rem]="0.5" [style.align-items]="'center'">
      <lib-reactive-form-drop-down
        #sectionType
        [style.width]="'-webkit-fill-available'"
        [inputName]="'menuTheme'"
        [label]="'Menu Theme' | replaceMenuWithCardOrLabel : (menu | isPrintCardMenu) : (menu | isPrintLabelMenu)"
        [placeholder]="
          'Choose menu theme' | replaceMenuWithCardOrLabel : (menu | isPrintCardMenu) : (menu | isPrintLabelMenu)
        "
        [inlineLabel]="false"
        [disabled]="true"
        [required]="true"
        [dropdowns]="viewModel.themes$ | async"
        [bindingProperty]="'theme'">
      </lib-reactive-form-drop-down>

      <lib-button-neutral (buttonClicked)="openThemeDetails()"> Details </lib-button-neutral>
    </div>

    <div [style.display]="'flex'" [style.align-items]="'center'">
      <lib-reactive-form-drop-down
        fxFlex
        [hidden]="viewModel.creatingNewTag$ | async"
        [bindOnSubmit]="!(viewModel.creatingNewTag$ | async)"
        [inputName]="'tags'"
        [label]="'Tag'"
        [clearable]="true"
        [placeholder]="'Select a tag for this menu.'"
        [inlineLabel]="false"
        [disabled]="(viewModel.creatingNewTag$ | async) || isTemplatedMenu"
        [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
        [required]="false"
        [dropdowns]="viewModel.tags$ | async"
        [bindingProperty]="'tag'">
      </lib-reactive-form-drop-down>

      <lib-reactive-form-text
        fxFlex
        [hidden]="!(viewModel.creatingNewTag$ | async)"
        [bindOnSubmit]="viewModel.creatingNewTag$ | async"
        [inputName]="'newTag'"
        [label]="'New Tag'"
        [placeholder]="'Enter tag name.'"
        [inlineLabel]="false"
        [required]="false"
        [minLength]="1"
        [maxLength]="128"
        [disabled]="!(viewModel.creatingNewTag$ | async)"
        [bindingProperty]="'tag'">
      </lib-reactive-form-text>

      <lib-button-primary
        [style.margin-left.rem]="0.5"
        [disabled]="isTemplatedMenu"
        [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
        (buttonClicked)="viewModel.toggleNewTagInput()">
        {{ (viewModel.creatingNewTag$ | async) ? 'Existing' : 'New' }}
      </lib-button-primary>
    </div>

    <lib-reactive-form-drop-down
      *ngIf="!(menu | isWebMenu)"
      [inputName]="'menuPreviewSize'"
      [label]="viewModel.setLabelForMenuSize$ | async"
      [placeholder]="viewModel.setPlaceholderForMenuSize$ | async"
      [inlineLabel]="false"
      [disabled]="isTemplatedMenu || (viewModel.displaySizes$ | push)?.length === 1"
      [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
      [required]="true"
      [tooltip]="viewModel.previewSizeToolTip$ | async"
      [dropdowns]="viewModel.displaySizes$ | async"
      [bindingProperty]="'displaySize.name'"
      (valueChanged)="displaySizeChanged($event)">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      #sectionType
      [inputName]="'menuType'"
      [label]="'Menu Type'"
      [placeholder]="'Choose menu type'"
      [inlineLabel]="false"
      [disabled]="true"
      [required]="true"
      [dropdowns]="viewModel.menuTypes$ | async"
      [bindingProperty]="'type'">
    </lib-reactive-form-drop-down>

    <ng-container *ngIf="viewModel.showScaleType$ | async">
      <lib-reactive-form-drop-down
        [inputName]="'scaleType'"
        [label]="'Scale Type'"
        [placeholder]="'Choose Scale Type'"
        [inlineLabel]="false"
        [required]="true"
        [disabled]="isTemplatedMenu"
        [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
        [dropdowns]="viewModel.scaleTypes$ | async"
        [bindingProperty]="'options.scale'">
      </lib-reactive-form-drop-down>
    </ng-container>

    <lib-reactive-form-drop-down
      #sectionOrientation
      *ngIf="!(menu | isWebMenu) && !(menu | containsStackedContent)"
      [inputName]="'orientation'"
      [label]="'Orientation'"
      [placeholder]="'Select the orientation of your display'"
      [inlineLabel]="false"
      [disabled]="isTemplatedMenu"
      [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
      [required]="true"
      [tooltip]="'Portrait is a 90º clockwise rotation. Reverse portrait is a 90º counterclockwise rotation.'"
      [dropdowns]="viewModel.orientations$ | async"
      [bindingProperty]="'displaySize.orientation'"
      (valueChanged)="viewModel.setOrientation($event); orientationChanged.emit($event)">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      #numberOfMenuColumns
      *ngIf="(menu | isProductMenu) && (viewModel.selectableNumberOfMenuColumns$ | async)?.length > 0"
      [inputName]="'numberOfMenuColumns'"
      [label]="'Number of Menu Columns'"
      [inlineLabel]="false"
      [required]="true"
      [disabled]="(viewModel.disableNumberOfMenuColumns$ | async) || isTemplatedMenu"
      [disabledTooltip]="isTemplatedMenu | templatedMenuDisabledFormControlTooltip"
      [tooltip]="viewModel.numberOfMenuColumnsTooltip$ | async"
      [dropdowns]="viewModel.selectableNumberOfMenuColumns$ | async"
      [bindingProperty]="'columnCount'"
      [programmaticallyChangeValue]="viewModel.autoUpdateColumnCount$ | async"
      (valueChanged)="viewModel.setNumberOfMenuColumns($event)">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-number
      #height
      *ngIf="menu | isWebMenu"
      [inputName]="'height'"
      [label]="'Web Embed Height'"
      [placeholder]="'Select the height in pixels.'"
      [inlineLabel]="false"
      [integersOnly]="true"
      [required]="true"
      [minValue]="1"
      [maxValue]="10000"
      [disabled]="false"
      [bindingProperty]="'displaySize.height'"
      [tooltip]="'This is the height for the embedded iframe on your website.'"
      (valueChanged)="heightChanged($event)">
    </lib-reactive-form-number>

    <lib-reactive-form-number
      #height
      *ngIf="menu | isWebMenu"
      [inputName]="'width'"
      [label]="'Web Embed Width'"
      [placeholder]="'Select the width in pixels.'"
      [inlineLabel]="false"
      [integersOnly]="true"
      [required]="true"
      [minValue]="1"
      [maxValue]="5000"
      [disabled]="false"
      [bindingProperty]="'displaySize.width'"
      [tooltip]="'This is the width for the embedded iframe on your website.'"
      (valueChanged)="widthChanged($event)">
    </lib-reactive-form-number>
  </lib-reactive-form-column-layout>
  <app-shopping-info-banner *rxIf="viewModel.showULineLink$ | push" [message]="viewModel.ulineLinkMessage$ | push">
  </app-shopping-info-banner>
</lib-reactive-form-group>
