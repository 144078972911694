export enum ProductType {
  Accessories = 'Accessories',
  Beverage = 'Beverage',
  Concentrate = 'Concentrate',
  Edible = 'Edible',
  Flower = 'Flower',
  Oil = 'Oil',
  Other = 'Other',
  Seed = 'Seed',
  Vape = 'Vape',
  Wellness = 'Wellness'
}
