import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DigitalMarketingMenusViewModel } from '../digital-marketing-menus-view-model';
import { DisplayableItemListComponent } from '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component';

@Component({
  selector: 'app-digital-marketing-menu-list',
  templateUrl: '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.html',
  styleUrls: [
    '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.scss',
    './digital-marketing-menu-list.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DigitalMarketingMenuListComponent extends DisplayableItemListComponent {

  constructor(
    public digitalMarketingMenusViewModel: DigitalMarketingMenusViewModel // Provided by parent component
  ) {
    super(digitalMarketingMenusViewModel);
  }

}
