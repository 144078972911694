/*
 * Note: Not all themeIds are included below - only those that have an explicit reference in the code base.
 * For a complete list of all themeIds, see the display app where they must be referenced for deserialization
 * https://github.com/mobilefirstdev/budsense-client-display/blob/dev/src/app/models/enum/dto/theme.enum.ts
 */

export type ThemeId = ProductTheme | PrintTheme | PrintCardTheme | MarketingTheme | SpotlightTheme;

export enum ProductTheme {
  LuxLeaf = 'ed69992b-c6ee-4def-9ae9-ee704b4971e8'
}

export enum PrintTheme {
  Plantlife = '7b0ceab2-7963-403d-81b7-e908547f46ca',
  PlantlifeNonSmokable = 'd6bfeeaf-80d9-4b0b-bfe5-df674382eabf',
  DoubleDutch = '73032d65-f24a-4e2e-86da-e9b37fef763a',
  ComeToBaskTriFold = 'fa0b1d4a-e4e9-4b59-96f4-e0b956207868',
}

export enum PrintCardTheme {
  DougAr = '933b3209-9799-4d9f-947d-ada393ce20e6',
  FikaEdibles = 'ee4674fd-4b74-45a8-ade8-78e1c03a32c6',
  FireAndFlower = '252634b5-39a7-44c6-8923-682c9937d912',
  FireAndFlowerBrandShelfTalker = '6b6f4882-b701-4a8f-a20e-1961fbce00fb',
  FireAndFlowerDefault = 'f22f5012-3c7c-4d19-a35a-71633d68f958',
  FireAndFlowerSale = '4686bde3-3f6b-4b36-9f86-46cc1426cbb5',
  FireAndFlowerMember = 'c2c6fa53-5003-45e9-92df-d68048aae246',
  Pennywise = 'b1ff7497-5a9c-4817-9eeb-5a7b696a9e87',
  Stiiizy = 'd8237f65-adfc-4f11-b12c-0706e2980008',
}

export enum MarketingTheme {
  MarketingPlaylist = 'bff0954e-9092-4ab5-a5c9-d27afd3c755b',
  SmartPlaylist = '36197fc0-c4d0-11ed-afa1-0242ac120002',
  MarketingProductFeature = 'a9ecd951-0db2-4cdf-a7bc-7832c0f52da0',
  Combo = '38978015-c814-46b5-a9f3-40358ec6e864',
  FeaturedCategory = 'ed40262e-721d-11ec-90d6-0242ac120003',
  SurfsideUrlPlaylist = 'cdf767d6-0177-4437-8b94-64907c630d1c'
}

export enum SpotlightTheme {
  Top = 'd90a6b3c-e01f-11eb-ba80-0242ac130004',
}
