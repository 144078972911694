<div class="mt-16px mb-16px f16px bs-bold">{{ title }}</div>

<app-bulk-card-stack-print-job-applied-sections-accordion
  *rxFor="let data of viewModel.cardStackPrintConfigs$ | push | keyvalue"
  [cardStackMenu]="
    data?.key | menuFromId : viewModel.locationMenus$ | constructCardStackMenuIfOriginalDeleted : data?.value | push
  "
  [itemCount]="data?.value?.variantIds?.length">
  <!-- variantIds are reused for sectionIds -->
  <ng-container *rxIf="data?.value?.variantIds?.length; else noAppliedSections">
    <app-stack-applied-section-list-item
      *rxFor="let sectionId of data?.value?.variantIds; let last = last"
      [itemCountMap]="data?.value?.variantCardCountMap"
      [item]="sectionId | variantOrSectionFromId : viewModel.locationVariants$ : viewModel.sections$ | push"
      [last]="last">
    </app-stack-applied-section-list-item>
  </ng-container>
  <ng-template #noAppliedSections>
    <div class="d-flex f12px pb-16px bs-medium">
      <div>{{ viewModel?.noSectionsPlaceholder$ | push }}</div>
    </div>
  </ng-template>
</app-bulk-card-stack-print-job-applied-sections-accordion>
