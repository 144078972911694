import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { SectionTypeDefinition } from '../../../../../../../models/utils/dto/section-type-definition';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SelectableItem } from '../../../../../../../models/shared/selectable-item';
import { map, startWith } from 'rxjs/operators';
import { Theme } from '../../../../../../../models/menu/dto/theme';
import { SectionType } from '../../../../../../../models/enum/dto/section-type';

@Injectable()
export class NewMenuSectionFormViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _isDuplicating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isDuplicating$ = this._isDuplicating as Observable<boolean>;
  connectToIsDuplicating = (isDuplicating: boolean) => this._isDuplicating.next(isDuplicating);

  private _menuTheme = new BehaviorSubject<Theme>(null);
  public menuTheme$ = this._menuTheme as Observable<Theme>;
  connectToMenuTheme = (menuTheme: Theme) => this._menuTheme.next(menuTheme);

  private _sectionTypes: BehaviorSubject<SectionTypeDefinition[]> = new BehaviorSubject<SectionTypeDefinition[]>(null);
  public sectionTypes$ = this._sectionTypes as Observable<SectionTypeDefinition[]>;
  connectToSectionTypes = (sectionTypes: SectionTypeDefinition[]) => this._sectionTypes.next(sectionTypes);

  private _columnOptions: BehaviorSubject<SelectableItem[]> = new BehaviorSubject<SelectableItem[]>(null);
  public columnOptions$ = this._columnOptions as Observable<SelectableItem[]>;
  connectToColumnOptions = (columnOptions: SelectableItem[]) => this._columnOptions.next(columnOptions);
  public columnOptionsLength$ = this.columnOptions$?.pipe(map(options => options.length));

  private _hideTitle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hideTitle$ = this._hideTitle as Observable<boolean>;

  private _selectedSectionType: BehaviorSubject<SectionType> = new BehaviorSubject<SectionType>(null);
  public selectedSectionType$ = this._selectedSectionType as Observable<SectionType>;
  connectToSelectedSectionType = (selectedSectionType: SectionType) => this.setSectionType(selectedSectionType);
  public hideSectionType$ = this.selectedSectionType$.pipe(
    map(sectionType => sectionType === SectionType.CategoryCard || sectionType === SectionType.ProductGroup)
  );
  public disableSectionType$ = combineLatest([
    this.isDuplicating$,
    this.hideSectionType$
  ]).pipe(
    map(([isDuplicating, hideSectionType]) => isDuplicating || hideSectionType)
  );

  public hideColumnOptions$ = combineLatest([
    this.selectedSectionType$,
    this.menuTheme$,
  ]).pipe(
    map(([sectionType, theme]) => {
      const isProductSection = sectionType === SectionType.Product;
      const themeSupportsColumnOptions = theme?.supportsDynamicColumns();
      //     Hide the default column option input if section or theme do not support column options
      return !isProductSection || !themeSupportsColumnOptions;
    }),
    startWith(true)
  );

  public hideAndDisableColumnOptionsDropdown$ = combineLatest([
    this.isDuplicating$,
    this.hideColumnOptions$,
    this.columnOptionsLength$
  ]).pipe(
    map(([isDuplicating, hideColumnOptions, columnOptionsLength]) => {
      return isDuplicating || hideColumnOptions || columnOptionsLength === 0;
    })
  );

  public setSectionType(sectionType: SectionType): void {
    this._selectedSectionType.next(sectionType);
    if (sectionType === SectionType.PageBreak) {
      this._hideTitle.next(true);
    } else {
      this._hideTitle.next(false);
    }
  }

}
