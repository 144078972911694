import { EventEmitter } from '@angular/core';

export class LabelGroupItem {

  public itemType: LabelItemType = LabelItemType.Text;
  public id: string;
  public label: string;
  public value: string;
  public subValue: string;
  public color: string;
  public backgroundColor: string;
  public tooltipText: string;
  public inlineLabel: boolean = false;
  public inlineFlexEnd: boolean = false;
  public boldText: boolean = false;
  public hideLabel: boolean = false;
  public equalInlineLabel: boolean = false;
  public actionButtonText: string;
  public actionButtonClass: string;
  public iconSrc: string;
  public actionEmitter: EventEmitter<any> = new EventEmitter<any>();
  public customWidthPercentage: string;
  public data: any;

}

export enum LabelItemType {
  Text = 'text',
  SmallText = 'smalltext',
  Divider = 'divider',
  Title = 'title',
  Subtitle = 'subtitle',
  ButtonListItem = 'buttonlistitem',
  IconListItem = 'iconlistitem',
  Banner = 'banner',
  Spacer = 'spacer'
}
