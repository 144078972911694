export enum SectionColumnConfigSecondaryPricingData {
  SecondaryPrice = 'SecondaryPrice',
  PricePerUOM = 'PricePerUOM',
  OriginalPrice = 'OriginalPrice',
  SaleOriginalPrice = 'SaleOriginalPrice',
  OriginalAndSalePrice = 'OriginalAndSalePrice',
  TaxesInPrice = 'TaxesInPrice',
  TaxesInRoundedPrice = 'TaxesInRoundedPrice',
  PreTaxPrice = 'PreTaxPrice',
}
