import { Deserializable } from '../../../protocols/deserializable';
import { WebSocketMessageType } from '../../web-socket-message-type';
import { WebSocketMessageContext } from '../../contexts/web-socket-message-context';
import { PolymorphicDeserializationKey } from '../../../enum/shared/polymorphic-deserialization-key.enum';

export class WebSocketReceiveMessage implements Deserializable {

  /**
   * The context in which the WebSocket connection was established (companyId, locationId, etc.).
   * Within this app, this is always the locationId.
   */
  entityId: number;

  /**
   * The timestamp that the message was sent.
   */
  timestamp: number;

  /**
   * The type of message that was sent.
   * This can be an Event, Command, DataUpdate, Heartbeat, Error, Notification, or Response.
   * This allows us to have a granularity of control over how the message is handled.
   */
  messageType: WebSocketMessageType;

  /**
   * This can be a wide range of enums. It is used to give context to what the message is about, and how it should
   * be handled by the client.
   */
  messageContext: WebSocketMessageContext;

  /**
   * This is a message that is intended for the user to see. This is used for toasts.
   */
  userMessage: string;

  /**
   * This is the payload of the message. It can be anything, and is intended to be used by the client to
   * determine what to do with the message. If it's a specific Object, then this class should be extended
   * and the payload should be overridden with the specific object.
   */
  payload: any;

  /**
   * This lets you type: windows?.injector?.Deserialize.instanceOf(WebSocketMessage, message), and you get a properly
   * typed object back (could be an extended class of WebSocketMessage) without knowing the type of message beforehand.
   * See DeserializeWebSocketMessageHelper to add new WebSocketMessage types.
   */
  getPolymorphicDeserializationKey(): PolymorphicDeserializationKey {
    return PolymorphicDeserializationKey.WebSocketMessage;
  }

  onDeserialize() {}

}
