import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { Menu } from '../models/menu/dto/menu';
import { ModalUtils } from '../utils/modal-utils';
import { PrintLabelLiveViewModalComponent } from '../views/live-view/print-labels/print-label-live-view-modal/print-label-live-view-modal.component';

export class ModalPrintLabelLiveView {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    printCardMenu: Menu,
    sortedVariantIds: string[],
    productId: string,
    variantIds?: string[]
  ) {
    ngZone.run(() => {
      const modalRef = ngbModal.open(
        PrintLabelLiveViewModalComponent,
        ModalUtils.printLabelLiveViewOptions(injector, printCardMenu?.displaySize?.orientation)
      );
      const ref = (modalRef.componentInstance as PrintLabelLiveViewModalComponent);
      ref.viewModel.connectToPrintCardMenu(printCardMenu);
      ref.viewModel.connectToSortedVariantIds(sortedVariantIds);
      ref.viewModel.connectToSelectedProductId(productId);
      ref.viewModel.connectToSelectedVariantIds(variantIds);
    });
  }

}
