import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-cannabinoid-preview-bottom',
  templateUrl: './cannabinoid-preview-bottom.component.html',
  styleUrls: ['./cannabinoid-preview-bottom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CannabinoidPreviewBottomComponent {

}
