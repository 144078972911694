import { ScalableLiveViewModalViewModel } from '../../shared/scalable-live-view-modal-view-model';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Menu } from '../../../../models/menu/dto/menu';
import { delay, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { exists } from '../../../../functions/exists';
import { iiif } from '../../../../utils/observable.extensions';
import { Injectable } from '@angular/core';
import type { Section } from '../../../../models/menu/dto/section';

@Injectable()
export class PrintShelfTalkerLiveViewModalViewModel extends ScalableLiveViewModalViewModel {

  constructor(
    protected companyDomainModel: CompanyDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected productDomainModel: ProductDomainModel
  ) {
    super();
  }

  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;

  private readonly _shelfTalkerMenu = new BehaviorSubject<Menu|null>(null);
  public readonly shelfTalkerMenu$ = this._shelfTalkerMenu as Observable<Menu|null>;
  connectToShelfTalkerMenu = (shelfTalkerMenu: Menu|null) => this._shelfTalkerMenu.next(shelfTalkerMenu);

  private readonly _searchTextAndHits = new BehaviorSubject<[string, any[]]>([null, []]);
  public readonly searchTextAndHits$ = this._searchTextAndHits as Observable<[string, any[]]>;
  connectToSearchTextAndHits = (x: [string, any[]]) => this._searchTextAndHits.next(x || [null, []]);

  public readonly title$ = this.shelfTalkerMenu$.pipe(map(menu => menu?.name));

  private _iFrameLoaded = new BehaviorSubject<boolean>(false);
  public readonly iFrameLoaded$ = this._iFrameLoaded.pipe(delay(1000), startWith(false), distinctUntilChanged());
  connectToIFrameLoaded = (x: boolean) => this._iFrameLoaded.next(x);

  public sections$ = this.shelfTalkerMenu$.pipe(
    map(menu => menu?.getSectionsBasedOnMenuType()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly visibleSections$ = combineLatest([
    this.searchTextAndHits$,
    this.sections$
  ]).pipe(
    map(([[searchText, hits], sections]) => {
      const hitIds = hits?.map(hit => hit?.id);
      return (searchText?.length >= 2)
        ? sections?.filter(section => hitIds?.includes(section?.id))
        : sections;
    })
  );

  private readonly _selectedSectionId = new BehaviorSubject<string|null>(null);
  connectToSelectedSectionId = (selected: string|null) => this._selectedSectionId.next(selected);

  private readonly hasSelectedSectionId$ = this._selectedSectionId.pipe(
    map(sectionId => exists(sectionId))
  );

  private readonly firstSectionId$ = this.sections$.pipe(
    map(sections => sections?.firstOrNull()?.id)
  );

  public readonly selectedSectionId$ = iiif(
    this.hasSelectedSectionId$,
    this._selectedSectionId,
    this.firstSectionId$
  ).pipe(
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly noVisibleSections$ = this.visibleSections$.pipe(
    map(sections => !sections?.length)
  );

  public sectionSelected$(item: Section): Observable<boolean> {
    return this.selectedSectionId$.pipe(
      map(selectedSectionId => selectedSectionId === item?.id)
    );
  }

  trackById(index: number, item: Section): string {
    return item?.id;
  }

  /* ******************************* Searching **************************** */

  public readonly searchPlaceholder$ = of(`Search cards by name`);
  public readonly searchableItems$ = this.sections$;
  public readonly noVisibleItems$ = this.noVisibleSections$;
  public readonly emptyText$ = of('No shelf talker card found');

  public itemSelected$(item: Section): Observable<boolean> {
    return this.sectionSelected$(item);
  }

  itemClicked(item: Section): void {
    this.connectToSelectedSectionId(item?.id);
  }

}
