import { Component, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReactiveFormDropDownComponent } from '@mobilefirstdev/reactive-form';
import { BasicAddEditSmartFilterFormViewModel } from './basic-add-edit-smart-filter-form-view-model';
import { BaseSmartFilterFormComponent } from '../base-smart-filter-form';

@Component({
  selector: 'app-basic-add-edit-smart-filter-form',
  templateUrl: './basic-add-edit-smart-filter-form.component.html',
  styleUrls: ['./basic-add-edit-smart-filter-form.component.scss'],
  providers: [BasicAddEditSmartFilterFormViewModel]
})
export class BasicAddEditSmartFilterFormComponent extends BaseSmartFilterFormComponent implements OnChanges {

  @ViewChild('secondarySort') secondarySortInput: ReactiveFormDropDownComponent;
  @ViewChild('primarySort') primarySortInput: ReactiveFormDropDownComponent;

  private selectedPrimarySortOption = new BehaviorSubject<string>(null);

  private listenToPrimarySortDropDown = this.selectedPrimarySortOption.subscribeWhileAlive({
    owner: this,
    next: op => {
      if (!op) {
        this.secondarySortInput?.clear();
      }
    }
  });

  private listenToItemCount = this.viewModel.itemCount$.subscribeWhileAlive({
    owner: this,
    next: ic => {
      if (!ic) {
        this.primarySortInput?.clear();
        this.secondarySortInput?.clear();
      }
    }
  });

  constructor(
    public viewModel: BasicAddEditSmartFilterFormViewModel
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bindTo) {
      this.viewModel.connectToSmartFilter(this.bindTo);
      this.primarySortDropdownChanged(this.bindTo.primarySort);
    }
    if (changes.isEditing) this.viewModel.connectToIsEditing(this.isEditing);
    if (changes.isViewOnly) this.viewModel.connectToIsViewOnly(this.isViewOnly);
    if (changes.modalOpenedFrom) this.viewModel.connectToModalOpenedFrom(this.modalOpenedFrom);
  }

  public primarySortDropdownChanged(sortOption: string) {
    this.selectedPrimarySortOption.next(sortOption);
    this.viewModel.primarySortDropdownChanged(sortOption);
  }

}
