<lib-reactive-form-group
  [bindTo]="sectionColumnConfig"
  [mergeKey]="formMergeKey"
  [ngClass]="{ 'cursor-not-allowed': !enabled }">
  <mat-accordion multi [displayMode]="'flat'" class="column-style-options-accordion">
    <mat-expansion-panel
      class="mat-elevation-z0 column-style-options-container"
      [ngClass]="{ disabled: !enabled }"
      [ngStyle]="{ padding: cannabinoidOrTerpene ? '0 1rem' : 0 }"
      [(expanded)]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title class="bs-bold f12px"> Column Style Options </mat-panel-title>
      </mat-expansion-panel-header>
      <lib-reactive-form-column-layout nColumns="2" class="column-config-form">
        <lib-reactive-form-number
          [inputName]="columnOptionName + 'ColumnOpacity'"
          [label]="'Column Opacity (%)'"
          [placeholder]="'100'"
          [bindingProperty]="'columnOpacity'"
          [integersOnly]="true"
          [minValue]="1"
          [maxValue]="100"
          [customValueParser]="parseIntAsOpacityPercentage"
          [tooltip]="'Change this value to modify the opacity of the column'">
        </lib-reactive-form-number>
        <lib-reactive-form-drop-down
          [inputName]="columnOptionName + 'TextStyle'"
          [label]="'Text Style'"
          [bindingProperty]="'fontStyle'"
          [dropdowns]="defaultFontStyleOptions$ | push"
          [tooltip]="'Change the style of the text within the column'"
          [hidden]="hideTextOptions">
        </lib-reactive-form-drop-down>
        <lib-reactive-form-color-square
          [presetColors]="columnOptionsViewModel.colorPalette$ | push"
          [inputName]="columnOptionName + 'ColumnColor'"
          [label]="'Column Color'"
          [inlineLabel]="false"
          [bindingProperty]="'columnColor'"
          [tooltip]="'Change this value to modify the color of the column'">
        </lib-reactive-form-color-square>
        <lib-reactive-form-color-square
          [presetColors]="columnOptionsViewModel.colorPalette$ | push"
          [inputName]="columnOptionName + 'TextColor'"
          [label]="'Text Color'"
          [inlineLabel]="false"
          [bindingProperty]="'fontColor'"
          [tooltip]="'Change this value to modify the color of the text within the column'"
          [hidden]="hideTextColor">
        </lib-reactive-form-color-square>
      </lib-reactive-form-column-layout>
    </mat-expansion-panel>
  </mat-accordion>
</lib-reactive-form-group>
