import { ApiClient } from './api-client';
import { defer, Observable, of, throwError, timer } from 'rxjs';
import { Endpoints } from './endpoints';
import { GenerateUploadUrlRequest } from '../models/image/requests/generate-upload-url-request';
import { SignedUploadUrl } from '../models/shared/signed-upload-url';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { catchError, concatMap, delayWhen, map, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';
import * as buffer from 'buffer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MediaUtils } from '../utils/media-utils';
import { AssetUrl } from '../models/image/dto/asset-url';
import { Asset } from '../models/image/dto/asset';
import { MenuPreview } from '../models/menu/shared/menu-preview';
import { Menu } from '../models/menu/dto/menu';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { exists } from '../functions/exists';

@Injectable({ providedIn: 'root' })
export class ImageAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
    private http: HttpClient,
  ) {
  }

  // Variables

  public serviceName = 'Image';

  // Image

  public DeleteAsset(id, md5: string): Observable<string> {
    const url = Endpoints.DeleteAsset(id, md5);
    return this.apiClient.deleteStr(url, null).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'DeleteAsset', err));
        return throwError(err);
      })
    );
  }

  public GenerateUploadUrl(req: GenerateUploadUrlRequest): Observable<SignedUploadUrl> {
    const url = Endpoints.GenerateUploadUrl();
    return this.apiClient.postObj(SignedUploadUrl, url, req).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GenerateUploadUrl', err));
        return throwError(err);
      })
    );
  }

  /**
   * previewOnly: This flag is used to return the last known preview image for the respective content.
   *              This does not consider any ongoing preview jobs, and simply grabs the last preview.
   *              This should be used when we want to immediately show content while we fetch the updated content in
   *              the background
   * forceUpdate: This flag is used to ignore any existing content and any existing (including ongoing) preview jobs.
   *              When set to true, this will always kick start a new preview job, even if the menu or content in
   *              question has not been updated since the last preview was generated.
   */
  public GetMenuPreview(
    locationId: number,
    menu: Menu,
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean
  ): Observable<any> {
    const url = Endpoints.GetMenuPreview(locationId, menu?.id, returnLastSaved, forceUpdate, previewOnly);
    const notForcedUrl = Endpoints.GetMenuPreview(locationId, menu?.id, returnLastSaved, false, previewOnly);
    return this.fetchPreviewWithRetryLogic(previewOnly, url, notForcedUrl, menu?.id, menu?.name);
  }

  /**
   * previewOnly: This flag is used to return the last known preview image for the respective content.
   *              This does not consider any ongoing preview jobs, and simply grabs the last preview.
   *              This should be used when we want to immediately show content while we fetch the updated content in
   *              the background
   * forceUpdate: This flag is used to ignore any existing content and any existing (including ongoing) preview jobs.
   *              When set to true, this will always kick start a new preview job, even if the menu or content in
   *              question has not been updated since the last preview was generated.
   */
  public GetMenuTemplatePreview(
    locationId: number,
    template: MenuTemplate,
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean,
  ): Observable<MenuPreview> {
    const url = Endpoints.GetMenuTemplatePreview(locationId, template?.id, returnLastSaved, forceUpdate, previewOnly);
    const notForcedUrl = Endpoints.GetMenuTemplatePreview(
      locationId,
      template?.id,
      returnLastSaved,
      false,
      previewOnly
    );
    return this.fetchPreviewWithRetryLogic(previewOnly, url, notForcedUrl, template?.id, template?.name);
  }

  /**
   * previewOnly: This flag is used to return the last known preview image for the respective content.
   *              This does not consider any ongoing preview jobs, and simply grabs the last preview.
   *              This should be used when we want to immediately show content while we fetch the updated content in
   *              the background
   * forceUpdate: This flag is used to ignore any existing content and any existing (including ongoing) preview jobs.
   *              When set to true, this will always kick start a new preview job, even if the menu or content in
   *              question has not been updated since the last preview was generated.
   */
  public GetPrintCardPreview = (
    locationId: number,
    menu: Menu,
    variantIds: string[],
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean
  ): Observable<any> => {
    const url = Endpoints.GetCardStackPreview(
      locationId,
      menu?.id,
      variantIds,
      returnLastSaved,
      forceUpdate,
      previewOnly
    );
    const notForcedUrl = Endpoints.GetCardStackPreview(
      locationId,
      menu?.id,
      variantIds,
      returnLastSaved,
      false,
      previewOnly
    );
    const id = `${menu?.id}-${variantIds}`;
    const name = `${menu?.name}-${variantIds}`;
    return this.fetchPreviewWithRetryLogic(previewOnly, url, notForcedUrl, id, name).pipe(
      tap(preview => {
        if (exists(preview)) preview.itemIds = variantIds;
      })
    );
  };

  /**
   * previewOnly: This flag is used to return the last known preview image for the respective content.
   *              This does not consider any ongoing preview jobs, and simply grabs the last preview.
   *              This should be used when we want to immediately show content while we fetch the updated content in
   *              the background
   * forceUpdate: This flag is used to ignore any existing content and any existing (including ongoing) preview jobs.
   *              When set to true, this will always kick start a new preview job, even if the menu or content in
   *              question has not been updated since the last preview was generated.
   */
  public GetPrintCardTemplatePreview = (
    locationId: number,
    template: MenuTemplate,
    variantIds: string[],
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean
  ): Observable<any> => {
    const url = Endpoints.GetCardStackTemplatePreview(
      locationId,
      template?.id,
      variantIds,
      returnLastSaved,
      forceUpdate,
      previewOnly
    );
    const notForcedUrl = Endpoints.GetCardStackTemplatePreview(
      locationId,
      template?.id,
      variantIds,
      returnLastSaved,
      false,
      previewOnly
    );
    const id = `${template?.id}-${variantIds}`;
    const name = `${template?.name}-${variantIds}`;
    return this.fetchPreviewWithRetryLogic(previewOnly, url, notForcedUrl, id, name).pipe(
      tap(preview => {
        if (exists(preview)) preview.itemIds = variantIds;
      })
    );
  };

  /**
   * previewOnly: This flag is used to return the last known preview image for the respective content.
   *              This does not consider any ongoing preview jobs, and simply grabs the last preview.
   *              This should be used when we want to immediately show content while we fetch the updated content in
   *              the background
   * forceUpdate: This flag is used to ignore any existing content and any existing (including ongoing) preview jobs.
   *              When set to true, this will always kick start a new preview job, even if the menu or content in
   *              question has not been updated since the last preview was generated.
   */
  public GetShelfTalkerCardPreview = (
    locationId: number,
    menu: Menu,
    sectionId: string,
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean
  ): Observable<any> => {
    const url = Endpoints.GetShelfTalkerPreview(
      locationId,
      menu?.id,
      sectionId,
      returnLastSaved,
      forceUpdate,
      previewOnly
    );
    const notForcedUrl = Endpoints.GetShelfTalkerPreview(
      locationId,
      menu?.id,
      sectionId,
      returnLastSaved,
      false,
      previewOnly
    );
    const id = `${menu?.id}-${sectionId}`;
    const name = `${menu?.name}-${sectionId}`;
    return this.fetchPreviewWithRetryLogic(previewOnly, url, notForcedUrl, id, name).pipe(
      tap(preview => {
        if (exists(preview)) preview.itemIds = [sectionId];
      })
    );
  };

  /**
   * previewOnly: This flag is used to return the last known preview image for the respective content.
   *              This does not consider any ongoing preview jobs, and simply grabs the last preview.
   *              This should be used when we want to immediately show content while we fetch the updated content in
   *              the background
   * forceUpdate: This flag is used to ignore any existing content and any existing (including ongoing) preview jobs.
   *              When set to true, this will always kick start a new preview job, even if the menu or content in
   *              question has not been updated since the last preview was generated.
   */
  public GetShelfTalkerCardTemplatePreview = (
    locationId: number,
    template: MenuTemplate,
    sectionId: string,
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean
  ): Observable<any> => {
    const url = Endpoints.GetShelfTalkerCardTemplatePreview(
      locationId,
      template?.id,
      sectionId,
      returnLastSaved,
      forceUpdate,
      previewOnly
    );
    const notForcedUrl = Endpoints.GetShelfTalkerCardTemplatePreview(
      locationId,
      template?.id,
      sectionId,
      returnLastSaved,
      false,
      previewOnly
    );
    const id = `${template?.id}-${sectionId}`;
    const name = `${template?.name}-${sectionId}`;
    return this.fetchPreviewWithRetryLogic(previewOnly, url, notForcedUrl, id, name).pipe(
      tap(preview => {
        if (exists(preview)) preview.itemIds = [sectionId];
      })
    );
  };

  public GetPrintPDF(locationId: number, forceUpdate: boolean, menuId?: string, templateId?: string): Observable<any> {
    if (!menuId && !templateId) return of(null);
    const url = Endpoints.GetPrintPDF(locationId, forceUpdate, menuId, templateId);
    const notForcedUrl = Endpoints.GetPrintPDF(locationId, false, menuId, templateId);
    return this.fetchPreviewWithRetryLogic(false, url, notForcedUrl);
  }

  protected fetchPreviewWithRetryLogic = (
    previewOnly: boolean,
    url: string,
    notForcedUrl: string,
    id?: string,
    name?: string
  ): Observable<any> => {
    let retryCount = 0;
    // Want to return untyped obj so deserializeToInstance is ignored until after delay
    // Defer says: don't create my observable until I am subscribed to. Once subscribed, use the observable
    // factory to create my source. Everytime I'm subscribed to, redo the above. Retry logic always resubscribes
    // to the source observable, therefore, defer allows me to have a conditional source observable.
    return defer(() => this.apiClient.getUntypedObj(retryCount > 0 ? notForcedUrl : url)).pipe(
      retryWhen(errors$ => {
        return errors$.pipe(
          concatMap((err) => {
            if (err.status === 503) {
              retryCount++;
              return of(err.status);
            } else {
              if (err.status === 404 && !!id && !!name) {
                err.error.message = err?.error?.message?.replace(id, name);
              }
              const e = new BsError(err, this.serviceName);
              this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetPreview', e));
              return throwError(e);
            }
          }),
          delayWhen(_ => timer(previewOnly ? 30000 : 20000)),
          take(10)
        );
      })
    );
  };

  public PutImageUploadUrl(url: string, file: string, fileName: string): Observable<any> {
    const adjustedfileName = fileName.replace(' ', '').toLowerCase();
    const type = MediaUtils.getMediaType(adjustedfileName.split('.').pop());
    const newFileContents = MediaUtils.stripFileContents(file);

    const buff = buffer.Buffer.from(newFileContents, 'base64');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', type);
    headers = headers.append('Content-Encoding', 'base64');

    const blob = new Blob([new Uint8Array(buff)]);
    return this.http.put<any>(url, blob, {headers}).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'PutImageUploadUrl', err));
        return throwError(err);
      })
    );
  }

  public GetAsset(id, md5Hash: string): Observable<Asset> {
    const url = Endpoints.GetAsset(id, md5Hash);
    return this.apiClient.getObj<Asset>(Asset, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetAsset', err));
        return throwError(err);
      })
    );
  }

  public GetBlobFromUrl(assetUrl: AssetUrl): Observable<Blob | null | undefined> {
    if (!assetUrl.url || assetUrl.urlExpired()) {
      return this.GetAsset(assetUrl.assetId, assetUrl.md5Hash).pipe(
        map(fetchedAsset => fetchedAsset.urls?.find(url => url.size === assetUrl.size)),
        map(updatedAssetUrl => assetUrl.updateDataFrom(updatedAssetUrl)),
        switchMap(updatedAssetUrl => (!!updatedAssetUrl?.url ? this.fetchBlobFromApi(updatedAssetUrl.url) : of<Blob>()))
      );
    } else {
      return this.fetchBlobFromApi(assetUrl.url);
    }
  }

  private fetchBlobFromApi(url: string): Observable<Blob | null | undefined> {
    return this.apiClient.getBlob<Blob>(url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetBlobFromUrl', err));
        return throwError(err);
      })
    );
  }

}
