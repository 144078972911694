import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { AllCardStackPrintJobsModalComponent } from '../views/menu/components/edit-menu/edit-card-stack-menu/modals/all-card-stack-print-jobs-modal/all-card-stack-print-jobs-modal.component';
import { ModalUtils } from '../utils/modal-utils';

export class ModalAllCardStackPrintJobs {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    templateMode: boolean
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(
        AllCardStackPrintJobsModalComponent,
        ModalUtils.allCardStackPrintJobsOptions(injector)
      );
      const compInstance = modalRef.componentInstance as AllCardStackPrintJobsModalComponent;
      compInstance.viewModel.connectToTemplateMode(templateMode);
    });
  }

}
