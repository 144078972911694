<app-edit-shelf-talker-brands-section-details
  [templateMode]="templateMode"
  [mergeKey]="dispersedAndMergeKey"
  [formPristine]="formPristine">
</app-edit-shelf-talker-brands-section-details>
<app-edit-shelf-talker-brands-asset-section
  *ngIf="viewModel.showSectionImageAsset$ | async"
  [hideLoading]="viewModel.hideSectionAssetLoading$ | async"
  [loadingOpts]="viewModel.sectionAssetLoadingOpts$ | async"
  [sectionAsset]="viewModel.sectionMedia$ | async"
  [templateMode]="templateMode"
  [formPristine]="formPristine"
  [section]="viewModel.section$ | async"
  [isTemplatedSection]="viewModel.isTemplatedSection$ | async"
  [sectionAssetPreview]="viewModel.sectionMediaPreview$ | async"
  [allowVideo]="false"
  [parentHandler]="this"
  (changeSectionAssetClicked)="viewModel.handleChangeSectionAsset()"
  (deleteSectionAssetClicked)="viewModel.handleDeleteSectionAsset()"
  (refreshSectionAssetClicked)="viewModel.handleMenuSectionAssetRefresh($event)">
</app-edit-shelf-talker-brands-asset-section>
<app-menu-section-smart-filters
  [templateMode]="templateMode"
  [menu]="viewModel.menu$ | push"
  [mergeKey]="dispersedAndMergeKey"
  [section]="viewModel.section$ | push"
  [autoSaving]="viewModel.autoSaving$ | push"
  [formPristine]="formPristine"
  [isTemplatedSection]="viewModel.isTemplatedSection$ | push">
</app-menu-section-smart-filters>
<app-edit-shelf-talker-brands-menu-products
  [templateMode]="templateMode"
  [formHasErrors]="formHasErrors"
  [formPristine]="formPristine">
</app-edit-shelf-talker-brands-menu-products>
