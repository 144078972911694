import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { LabelDomainModel } from '../../../domainModels/label-domain-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, fromEvent, of, Subscription } from 'rxjs';
import { Menu } from '../../../models/menu/dto/menu';
import { delay, distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { exists } from '../../../functions/exists';
import { environment } from '../../../../environments/environment';
import { StringifyUtils } from '../../../utils/stringify-utils';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { LoadingSpinnerSize } from '../../../models/enum/shared/loading-spinner-size.enum';

@Injectable()
export class PrintMenuLiveViewViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private sanitizer: DomSanitizer
  ) {
    super();
    this.receivePageInfoFromIFrame();
    this.loadingSpinner();
  }

  private sendDataSub: Subscription|null = null;

  protected override readonly _loadingOpts = new BehaviorSubject(this.getLoadingOpts());
  private readonly borderInPx$ = of(0);
  public readonly companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  public readonly labels$ = this.labelDomainModel.allLabels$;
  public readonly iFrameName$ = of(Date.now()?.toString(10));
  public readonly url$ = of(this.generateLiveViewURL());

  private readonly _printMenu = new BehaviorSubject<Menu|null>(null);
  public readonly printMenu$ = this._printMenu.deepCopy().pipe(
    map(printMenu => printMenu?.adjustDataForPrintLiveView()),
  );
  connectToPrintMenu = (printMenu: Menu|null) => this._printMenu.next(printMenu);

  private readonly _menuHeight = new BehaviorSubject<number|null>(0);
  public readonly menuHeight$ = combineLatest([
    this.borderInPx$,
    this._menuHeight
  ]).pipe(
    filter(([_, menuHeight]) => exists(menuHeight)),
    map(([borderInPx, menuHeight]) => menuHeight + (borderInPx * 2)),
    distinctUntilChanged()
  );
  connectToMenuHeight = (menuHeight: number|null) => this._menuHeight.next(menuHeight);

  public readonly iFrameIsVisible$ = this.menuHeight$.pipe(map(height => height > 0), delay(250));

  private readonly _menuWidth = new BehaviorSubject<number|null>(0);
  public readonly menuWidth$ = combineLatest([
    this.borderInPx$,
    this._menuWidth
  ]).pipe(
    filter(([_, menuWidth]) => exists(menuWidth)),
    map(([borderInPx, menuWidth]) => menuWidth + (borderInPx * 2)),
    distinctUntilChanged()
  );
  connectToMenuWidth = (menuWidth: number|null) => this._menuWidth.next(menuWidth);

  private readonly _nPages = new BehaviorSubject<number>(0);
  public readonly nPages$ = this._nPages.pipe(distinctUntilChanged());
  connectToNPages = (nPages: number) => this._nPages.next(nPages);

  public readonly boxShadow$ = this.menuHeight$.pipe(
    map((height) => exists(height) ? '1px 1px 15px black' : null),
  );

  private readonly _parentContainerWidth = new BehaviorSubject<number|null>(null);
  public readonly parentContainerWidth$ = this._parentContainerWidth.pipe(distinctUntilChanged());
  connectToParentContainerWidth = (width: number|null) => this._parentContainerWidth.next(width);

  private readonly _parentContainerHeight = new BehaviorSubject<number|null>(null);
  public readonly parentContainerHeight$ = this._parentContainerHeight.pipe(distinctUntilChanged());
  connectToParentContainerHeight = (height: number|null) => this._parentContainerHeight.next(height);

  private readonly _userSetScale = new BehaviorSubject<number|null>(null);
  public readonly userSetScale$ = this._userSetScale.pipe(distinctUntilChanged());
  connectToUserSetScale = (scale: number|null) => this._userSetScale.next(scale);

  public readonly scaleToParentView$ = combineLatest([
    this.menuHeight$,
    this.menuWidth$,
    this.parentContainerHeight$,
    this.parentContainerWidth$
  ]).pipe(
    map(([menuHeight, menuWidth, parentContainerHeight, parentContainerWidth]) => {
      const heightRatio = (parentContainerHeight && menuHeight) ? (parentContainerHeight / menuHeight) : 1;
      const widthRatio = (parentContainerWidth && menuWidth) ? (parentContainerWidth / menuWidth) : 1;
      return Math.min(heightRatio, widthRatio, 1);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly scale$ = combineLatest([
    this.userSetScale$,
    this.scaleToParentView$
  ]).pipe(
    map(([userSetScale, scaleToParentView]) => userSetScale || scaleToParentView)
  );

  private getLoadingOpts(): LoadingOptions {
    const opts = LoadingOptions.defaultWhiteBackground();
    opts.spinnerSize = LoadingSpinnerSize.Large;
    return opts;
  }

  private generateLiveViewURL(): SafeResourceUrl {
    const cacheBuster = '?cacheBuster=' + Date.now() + Math.floor(Math.random() * 1000000);
    const env = (environment.production && environment.environmentName !== 'rc')
      ? ''
      : `${environment.environmentName}.`;
    const url = `https://${env}display.mybudsense.com/#/print/live-view/${cacheBuster}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public sendDataToIFrame(iFrame: HTMLIFrameElement): void {
    this.sendDataSub?.unsubscribe();
    this.sendDataSub = combineLatest([
      this.locationConfig$.notNull(),
      this.companyConfig$.notNull(),
      this.labels$.notNull(),
      this.printMenu$.notNull(),
    ]).subscribeWhileAlive({
      owner: this,
      next: ([locationConfig, companyConfig, labels, printMenu]) => {
        const msg = {
          type: 'menu',
          locationConfig: JSON.parse(JSON.stringify(locationConfig, StringifyUtils.displayAppReplacer)),
          companyConfig: JSON.parse(JSON.stringify(companyConfig, StringifyUtils.displayAppReplacer)),
          labels: JSON.parse(JSON.stringify(labels, StringifyUtils.displayAppReplacer)),
          printMenu: JSON.parse(JSON.stringify(printMenu, StringifyUtils.displayAppReplacer)),
        };
        iFrame?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  public sendPageSelectionToIFrame(iFrame: HTMLIFrameElement, pageIndex: number): void {
    const msg = {
      type: 'page',
      pageIndex
    };
    iFrame?.contentWindow?.postMessage(msg, '*');
  }

  /**
   * All messages coming from window get intercepted here. This means messages that the dashboard app sends
   * to the display app will be received here as well. Therefore, we must check if the specific message contains
   * relevant data, or else we can end up in an infinite loop.
   */
  private receivePageInfoFromIFrame(): void {
    fromEvent(window, 'message').pipe(
      filter((event: MessageEvent) => {
        const origin = event?.origin?.toLowerCase();
        const hasData = event?.data?.type === 'page';
        return hasData && (origin?.includes('localhost') || origin?.includes('mybudsense.com'));
      }),
      map((event: MessageEvent) => event?.data)
    ).subscribeWhileAlive({
      owner: this,
      next: (msg: any) => {
        if (msg?.menuHeight) this.connectToMenuHeight(msg?.menuHeight);
        if (msg?.menuWidth) this.connectToMenuWidth(msg?.menuWidth);
        if (msg?.nPages) this.connectToNPages(msg?.nPages);
      }
    });
  }

  private loadingSpinner(): void {
    combineLatest([
      this.menuHeight$,
      this.nPages$
    ]).subscribeWhileAlive({
      owner: this,
      next: ([menuHeight, nPages]) => {
        if (menuHeight > 0 && !this._loadingOpts.containsRequest('')) this._loadingOpts.addRequest('');
        if (menuHeight > 0 && nPages > 0) this._loadingOpts.removeRequest('');
      }
    });
  }

}
