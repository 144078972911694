import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DropDownMenuSection } from '../../../../models/shared/stylesheet/drop-down-menu-section';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({
  selector: 'app-drop-down-menu',
  templateUrl: './drop-down-menu.component.html',
  styleUrls: ['./drop-down-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropDownMenuComponent extends BaseComponent {

  @Input() iconSrc: string; // ex - 'assets/icons/light/outline/home.svg'
  @Input() buttonClass: string;
  @Input() buttonText: string;
  @Input() sections: DropDownMenuSection[];
  @Input() openLeft: boolean = false;
  @Input() useDivInsteadOfButton: boolean = false;
  @Input() disableOptionId: string;
  @Input() dropdownLeftMargin: string;
  @Input() disableBoxShadow: boolean = false;
  @Input() dropdownTopMargin: string;
  @Input() disabledSectionNavOptionIds: string[];
  @Output() output = new EventEmitter<any>();

  constructor() {
    super();
  }

  public ddId = Math.random().toString(24);
  public bId = Math.random().toString(24);

  clicked(i: any) {
    this.output.emit(i);
  }

}
