import type { Menu } from '../models/menu/dto/menu';
import { UniquelyIdentifiable } from '../models/protocols/uniquely-identifiable';
import { distinctUntilChanged } from 'rxjs/operators';
import type { Label } from '../models/shared/label';
import { Asset } from '../models/image/dto/asset';
import { BulkPrintJob } from '../models/automation/bulk-print-job';

export class DistinctUtils {

  public static distinctUniquelyIdentifiable = <T extends UniquelyIdentifiable>(prev: T, curr: T) => {
    return prev?.getUniqueIdentifier() === curr?.getUniqueIdentifier();
  };
  public static distinctUniquelyIdentifiableArray = <T extends UniquelyIdentifiable>(prev: T[], curr: T[]) => {
    const prevLength = prev?.length || 0;
    const currLength = curr?.length || 0;
    if (prevLength !== currLength) return false;
    const prevId = prev?.map(m => m?.getUniqueIdentifier())?.sort()?.join(',');
    const currId = curr?.map(m => m?.getUniqueIdentifier())?.sort()?.join(',');
    return prevId === currId;
  };
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public static distinctUntilChanged = distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable);
  public static distinctStringLists = (prev: string[], curr: string[]) => prev?.join(',') === curr?.join(',');
  // TODO - KFFT - this seems like a recipe for a bug, eventually come back and fix this.
  // Note: the compared lists are sorted in place, which means that the input data will be altered by this function.
  public static distinctSortedStrings = (prev: string[], curr: string[]) => {
    return prev?.sort().join(',') === curr?.sort().join(',');
  };
  public static distinctJSON = <T>(prev: T, curr: T) => JSON.stringify(prev) === JSON.stringify(curr);
  public static distinctUnsortedStrings = (prev: string[], curr: string[]) => prev?.join(',') === curr?.join(',');
  public static distinctByMenuId = (prev: Menu, curr: Menu) => prev?.id === curr?.id;
  public static distinctByMenuIds = (prev: Menu[], curr: Menu[]) => {
    const first = prev?.map(m => m?.id)?.filterNulls()?.sort().join(',');
    const second = curr?.map(m => m?.id)?.filterNulls()?.sort().join(',');
    return first === second;
  };
  public static distinctByLabelLastModified = (prev: Label, curr: Label) => prev?.lastModified === curr?.lastModified;
  public static distinctAssetByMD5Hash = (prev: Asset, curr: Asset) => prev?.md5Hash === curr?.md5Hash;
  public static distinctBulkPrintJobsById = (prev: BulkPrintJob[], curr: BulkPrintJob[]) => {
    const prevIds = prev?.map(j => j?.id)?.filterNulls()?.sort().join(',');
    const currIds = curr?.map(j => j?.id)?.filterNulls()?.sort().join(',');
    return prevIds === currIds;
  };
  public static distinctByUserIdAndAccessToken = (
    prev: { userId: string, session: { accessToken: string } },
    curr: { userId: string, session: { accessToken: string } }
  ) => {
    return prev?.userId === curr?.userId
        && prev?.session?.accessToken === curr?.session?.accessToken;
  };

}
