<lib-reactive-form-group>
  <div fxLayout="column" fxLayoutGap="0.5rem">
    <!-- Top Row -->
    <div fxLayout="row" fxLayoutAlign=" end" fxLayoutGap="2rem">
      <!-- Left Column 50% -->
      <div fxFlex fxLayout="row" fxLayoutGap="1rem">
        <!-- text field with clear button overlay on right side-->
        <div fxFlex class="search-container">
          <lib-reactive-form-text
            #searchBar
            class="search-products"
            [style.display]="'block'"
            [inputName]="'searchText'"
            [label]="'Search'"
            [placeholder]="'Search to filter results'"
            [inlineLabel]="false"
            [required]="false"
            [disabled]="false"
            [programmaticallyChangeValue]="viewModel.programmaticallySetSearchText$ | push"
            (valueChanged)="viewModel.setSearchText($event)">
          </lib-reactive-form-text>
          <button
            *rxIf="(viewModel.searchText$ | push)?.length > 0"
            (click)="searchBar?.getSelfAsFormItem()?.setValue('')"
            type="button"
            class="clear-search">
            CLEAR
          </button>
        </div>
      </div>
      <!-- Right Column 50% -->
      <div class="filter-options">
        <div class="filter-options-layout">
          <app-segmented-control
            *rxIf="viewModel.displayAllRecMedControl$"
            class="segmented-control"
            [options]="viewModel.usePurposeOptions$ | push"
            (optionSelected)="viewModel.setUsePurpose($event)">
          </app-segmented-control>

          <lib-reactive-form-switch
            class="out-of-stock-switch"
            [style.display]="'block'"
            [inputName]="'datatableCheckBox'"
            [selectedValue]="true"
            [notSelectedValue]="false"
            [labelOnRight]="true"
            [programmaticallyChangeValue]="
              hideRecentlyUpdatedProductsMode
                ? (viewModel.hideRecentlyUpdatedProducts$ | push)
                : (viewModel.hideOutOfStockProducts$ | push)
            "
            (valueChanged)="
              hideRecentlyUpdatedProductsMode
                ? viewModel.setHideRecentlyUpdatedProducts($event)
                : viewModel.setHideOutOfStockProducts($event)
            ">
            {{ hideRecentlyUpdatedProductsMode ? 'Hide recently updated' : 'Hide out of stock products' }}
          </lib-reactive-form-switch>
          <app-custom-visible-columns
            [currentProductColumnConfigsIdValue]="viewModel.currentProductTableColumnConfigsIdValue$ | push"
            (productTableColumnConfigSelected)="viewModel.setCurrentProductTableColumnConfigsIdValue($event)">
          </app-custom-visible-columns>
        </div>
      </div>
    </div>
    <!-- Bottom Row - Columns spaced equally -->
    <lib-reactive-form-row [style.display]="'block'" [gap]="'2rem'">
      <lib-reactive-form-drop-down
        #filterByProductType
        [inputName]="'filterByProductType'"
        [label]="'Filter by product type'"
        [placeholder]="'Choose a product type'"
        [inlineLabel]="false"
        [disabled]="viewModel.productTypeDisabled$ | push"
        [required]="false"
        [clearable]="viewModel.filterByProductTypeClearable$ | push"
        [dropdowns]="viewModel.selectableProductTypes$ | push"
        [programmaticallyChangeValue]="viewModel.filterByProductType$ | push"
        (valueChanged)="
          filterByVariantType.clear(); filterByStrainType?.clear(); viewModel.setFilterByProductType($event)
        ">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-drop-down
        #filterByVariantType
        [inputName]="'filterByVariantType'"
        [label]="'Filter by variant type'"
        [placeholder]="'Choose a variant type'"
        [inlineLabel]="false"
        [disabled]="viewModel.variantTypeDisabled$ | push"
        [required]="false"
        [clearable]="viewModel.filterByVariantTypeClearable$ | push"
        [dropdowns]="viewModel.selectableVariantTypes$ | push"
        [programmaticallyChangeValue]="viewModel.filterByVariantType$ | push"
        (valueChanged)="filterByStrainType.clear(); viewModel.setFilterByVariantType($event)">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-drop-down
        #filterByStrainType
        [inputName]="'filterByStrainType'"
        [label]="'Filter by strain type'"
        [placeholder]="'Choose a strain type'"
        [inlineLabel]="false"
        [disabled]="viewModel.strainTypeDisabled$ | push"
        [required]="false"
        [clearable]="viewModel.filterByStrainTypeClearable$ | push"
        [dropdowns]="viewModel.selectableStrainTypes$ | push"
        [programmaticallyChangeValue]="viewModel.filterByStrainType$ | push"
        (valueChanged)="viewModel.setFilterByStrainType($event)">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-drop-down
        [inputName]="'numberOfResults'"
        [label]="'Number of Results'"
        [placeholder]="'Number of Results'"
        [inlineLabel]="false"
        [disabled]="false"
        [required]="false"
        [dropdowns]="viewModel.nProductsPerPageOptions$ | push"
        [programmaticallyChangeValue]="viewModel.numberOfProductsPerPage$ | push"
        (valueChanged)="viewModel.setNumberOfProductsPerPage($event); resetPageNumber.emit()">
      </lib-reactive-form-drop-down>
    </lib-reactive-form-row>
  </div>
  <hr *ngIf="includeBottomBorder" class="no-margin pt-4px" />
</lib-reactive-form-group>
