import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { MenuDomainModel } from '../../../../../../../../domainModels/menu-domain-model';
import { map, shareReplay, tap } from 'rxjs/operators';
import { iiif } from '../../../../../../../../utils/observable.extensions';
import { TemplateDomainModel } from '../../../../../../../../domainModels/template-domain-model';
import { Section } from 'src/app/models/menu/dto/section';
import { HasChildIds } from '../../../../../../../../models/protocols/has-child-ids';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';

@Injectable()
export class StackPrintJobSectionsFormViewModel {

  constructor(
    private templateDomainModel: TemplateDomainModel,
    private menuDomainModel: MenuDomainModel
  ) {
  }

  private _mergeKey = new BehaviorSubject<string>('');
  public mergeKey$ = this._mergeKey as Observable<string>;
  connectToMergeKey = (mergeKey: string) =>   this._mergeKey.next(mergeKey);

  private readonly _templateMode = new BehaviorSubject<boolean>(false);
  public readonly templateMode$ = this._templateMode as Observable<boolean>;
  connectToTemplateMode = (templateMode: boolean) => this._templateMode.next(templateMode);

  private _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => this._job.next(job);

  private readonly _searchText = new BehaviorSubject<string>('');
  public readonly searchText$ = this._searchText as Observable<string>;
  connectToSearchText = (searchText: string) => this._searchText.next(searchText);

  private _sectionIdsToBeAdded = new BehaviorSubject<string[]>([]);
  public sectionIdsToBeAdded$ = this._sectionIdsToBeAdded as Observable<string[]>;
  connectToSectionIdsToBeAdded = (sectionIds: string[]) => this._sectionIdsToBeAdded.next(sectionIds);

  private readonly _viewOnly = new BehaviorSubject<boolean | null>(null);
  public readonly viewOnly$ = this._viewOnly as Observable<boolean | null>;
  connectToViewOnly = (viewOnly: boolean) => this._viewOnly.next(viewOnly);

  private menu$ = iiif(
    this.templateMode$,
    this.templateDomainModel.activeMenuTemplate$,
    this.menuDomainModel.activeHydratedMenu$
  );

  private menuId$ = this.menu$.pipe(map(cadStack => cadStack?.id));
  private sections$ = this.menu$.pipe(map(cardStack => cardStack?.getSectionsBasedOnMenuType()));

  public sectionStackPrintConfig$ = combineLatest([
    this.job$,
    this.menuId$
  ]).pipe(
    map(([job, menuId]) => job?.cardStackPrintConfigMap?.get(menuId)),
    tap(cardStackPrintConfig => {
      if (cardStackPrintConfig) {
        // a bit confusing, but variantIds property is reused to also store sectionIds
        this.connectToSectionIdsToBeAdded(cardStackPrintConfig?.variantIds);
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly searchedSections$: Observable<Section[]> = combineLatest([
    this.searchText$,
    this.sections$
  ]).pipe(
    map(([searchText, sections]) => {
      let searchedSections = sections;
      if (searchText?.length >= 2) {
        searchedSections = searchedSections?.filter(section => {
          return this.search(section?.title, searchText);
        });
      }
      return searchedSections;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly emptySearch$ = combineLatest([this.searchText$, this.searchedSections$]).pipe(
    map(([searchText, hits]) => searchText?.length >= 2 && !hits?.length)
  );

  public readonly noSectionsForSearchPlaceholder$ = this.searchText$.pipe(
    map(searchText => `"${searchText}" does not exist`),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly searchedSectionsInListAsSelections$ = this.searchedSections$.pipe(
    map(sections => {
      return new class implements HasChildIds {

        getId = ():string => '';
        getChildIds = ():string[] => sections?.map(s => s?.id);

      };
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private search = (haystack: string, needle: string) => {
    return haystack?.toLowerCase()?.trim()?.includes(needle?.toLowerCase()?.trim());
  };

  public bulkAddSelection(sectionIds: string[]): void {
    this.sectionIdsToBeAdded$.once(prevIds => {
      const updatedIds = prevIds?.concat(sectionIds)?.unique() ?? sectionIds;
      this.connectToSectionIdsToBeAdded(updatedIds);
    });
  }

  public bulkRemoveSelection(sectionIds: string[]): void {
    this.sectionIdsToBeAdded$.once(prevIds => {
      const updatedIds = prevIds?.filter(prevId => !sectionIds.includes(prevId)) ?? [];
      this.connectToSectionIdsToBeAdded(updatedIds);
    });
  }

  public addSectionClicked(sectionId: string): void {
    this.sectionIdsToBeAdded$.once(prevIds => {
      const updatedIds = prevIds?.concat(sectionId)?.unique() ?? [sectionId];
      this.connectToSectionIdsToBeAdded(updatedIds);
    });
  }

  public removeSectionClicked(sectionId: string): void {
    this.sectionIdsToBeAdded$.once(prevIds => {
      const updatedIds = prevIds?.filter(prevId => prevId !== sectionId) ?? [];
      this.connectToSectionIdsToBeAdded(updatedIds);
    });
  }

  public containerSectionClicked(sectionId: string): void {
    this.sectionIdsToBeAdded$.once( sectionIds => {
      sectionIds.includes(sectionId) ? this.removeSectionClicked(sectionId) : this.addSectionClicked(sectionId);
    });
  }

}
