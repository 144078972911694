import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { StackSmartBulkPrintPreviousJobsFormViewModel } from './stack-smart-bulk-print-previous-jobs-form-view-model';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { BulkPrintJob } from '../../../../../../../models/automation/bulk-print-job';
import { StackType } from '../../../../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';
import { combineLatest } from 'rxjs';
import { exists } from '../../../../../../../functions/exists';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-stack-smart-bulk-print-previous-jobs-form',
  templateUrl: './stack-smart-bulk-print-previous-jobs-form.component.html',
  styleUrls: ['./stack-smart-bulk-print-previous-jobs-form.component.scss'],
  providers: [StackSmartBulkPrintPreviousJobsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackSmartBulkPrintPreviousJobsFormComponent extends BaseComponent {

  constructor(
    public viewModel: StackSmartBulkPrintPreviousJobsFormViewModel
  ) {
    super();
    this.initializeIncludeChangesSince();
  }

  @Input() @ViewModelConnector('connectToStackType') stackType: StackType;
  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() @ViewModelConnector('connectToSelectedCardStacks') selectedCardStacks: Menu[];
  @Input() @ViewModelConnector('connectToViewOnly') viewOnly: boolean;
  @Output() includeChangesSinceChanged = new EventEmitter<Map<string, number>>();

  override setupViews() {}
  override setupBindings() {}

  public handleDropdownValueChange(id: string, prevJobDate: number): void {
    if (!Number.isFinite(prevJobDate)) return;
    this.includeChangesSinceChanged.emit(new Map([[id, prevJobDate]]));
  }

  private initializeIncludeChangesSince(): void {
    combineLatest([
      this.viewModel.selectedCardStacksWithPreviousJobs$.notNull().pipe(filter(stacks => stacks?.length > 0)),
      this.viewModel.job$.notNull(),
      this.viewModel.successfulLocationBulkPrintJobs$.notNull()
    ]).pipe(
      take(1)
    ).subscribeWhileAlive({
      owner: this,
      next: ([stacks, currJob, successfulJobs]) => {
        stacks?.forEach(stack => {
          const currentJobIncludeChangesSince = currJob?.cardStackPrintConfigMap?.get(stack?.id)?.includeChangesSince;
          if (exists(currentJobIncludeChangesSince)) {
            const previousJobDate = currJob?.cardStackPrintConfigMap?.get(stack?.id)?.includeChangesSince;
            const previousJob = successfulJobs?.find(job => job?.dateCreated === previousJobDate);
            return previousJob?.id;
          }
          const mostRecentJob = successfulJobs?.filter(job => job?.menuIncludedInPrintJob(stack?.id))?.firstOrNull();
          this.includeChangesSinceChanged.emit(new Map([[stack?.id, mostRecentJob?.dateCreated || 0]]));
        });
      }
    });
  }

}
