import { Variant } from '../models/product/dto/variant';
import { SortUtils } from './sort-utils';
import { PriceFormat } from '../models/enum/dto/price-format';

export class PrintCardMenuUtils {

  /**
   * NOTE: code copied from Display app
   *
   * Recursive function that evenly distributes grid variants across multiple print cards based on maxVariantsPerCard.
   * It also groups sizes that are nearest to each other.
   * Example: maxOf3 per card | 1g, 2g, 5g, 10g | [1g, 2g], [5g, 10g]
   * Example: maxOf4 per card | 1g, 2g, 5g, 10g, 14g | [1g, 2g, 5g], [10g, 14g]
   * Example: maxOf5 per card | 1g, 2g, 5g, 10g, 14g, 28g | [1g, 2g, 5g], [10g, 14g, 28g]
   * Example: maxVariantsPerCard = 3
   *   4 variants -> [2, 2]
   *   5 variants -> [3, 2]
   *   6 variants -> [3, 3]
   *   7 variants -> [3, 2, 2]
   *   8 variants -> [3, 3, 2]
   *   9 variants -> [3, 3, 3]
   *   10 variants -> [3, 3, 2, 2]
   *   11 variants -> [3, 3, 3, 2]
   *   12 variants -> [3, 3, 3, 3]
   * Example: maxVariantsPerCard = 4
   *   5 variants -> [3, 2]
   *   6 variants -> [3, 3]
   *   7 variants -> [4, 3]
   *   8 variants -> [4, 4]
   *   9 variants -> [3, 3, 3]
   *   10 variants -> [4, 3, 3]
   *   11 variants -> [4, 4, 3]
   *   12 variants -> [4, 4, 4]
   *   13 variants -> [4, 3, 3, 3]
   *   14 variants -> [4, 4, 3, 3]
   *   15 variants -> [4, 4, 4, 3]
   *   16 variants -> [4, 4, 4, 4]
   * Example: maxVariantsPerCard = 5
   *   6 variants -> [3, 3]
   *   7 variants -> [4, 3]
   *   8 variants -> [4, 4]
   *   9 variants -> [5, 4]
   *   10 variants -> [5, 5]
   *   11 variants -> [4, 4, 3],
   *   12 variants -> [4, 4, 4],
   *   13 variants -> [5, 4, 4],
   *   14 variants -> [5, 5, 4],
   *   15 variants -> [5, 5, 5],
   *   16 variants -> [4, 4, 4, 4],
   *   17 variants -> [5, 4, 4, 4],
   *   18 variants -> [5, 5, 4, 4],
   *   19 variants -> [5, 5, 5, 4],
   *   20 variants -> [5, 5, 5, 5],
   */
  public static spreadLargePrintCardGridAcrossMultipleCards(
    variantsToDisplay: Variant[],
    maxVariantsPerCard: number,
  ): Variant[][] {
    variantsToDisplay?.sort(SortUtils.sortVariantsByUnitSizeAscElsePackagedQuantityAsc);
    const chunkedVariants = variantsToDisplay?.chunkedList(maxVariantsPerCard);
    let tooSmall: Variant[][];
    /** Take from left neighbor if it has more variants than the current chunk */
    const takeFromLeftNeighbor = (chunks: Variant[][], chunk: Variant[]): void => {
      const chunkIndex = chunks?.indexOf(chunk);
      if (!chunkIndex || !chunk?.length) return;
      const leftNeighbor = chunks[chunkIndex - 1];
      if (leftNeighbor?.length > chunk?.length) {
        takeFromLeftNeighbor(chunks, leftNeighbor);
        const variant = leftNeighbor?.pop();
        chunk.unshift(variant);
      }
    };
    const fillTooSmallPile = () => {
      tooSmall = chunkedVariants?.filter(chunk => {
        const largestListSize = Math.max(...(chunkedVariants?.map(c => c?.length ?? 0) || []), 0);
        return (largestListSize - chunk?.length ?? 0) > 1;
      });
    };
    fillTooSmallPile();
    while (tooSmall?.length > 0) {
      takeFromLeftNeighbor(chunkedVariants, tooSmall?.last());
      fillTooSmallPile();
    }
    return chunkedVariants;
  }

  public static spreadByPrice(
    variantsToDisplay: Variant[],
    themeId: string,
    locId: number,
    companyId: number | null,
    priceFormat: PriceFormat,
    hideSale: boolean,
    pricingTierGridName: string = null,
  ): Variant[][] {
    const chunkedByPrice = new Map<number, Variant[]>();
    variantsToDisplay?.forEach(variant => {
      const price = variant?.getVisiblePrice(locId, companyId, priceFormat, hideSale, pricingTierGridName);
      if (!chunkedByPrice.has(price)) {
        chunkedByPrice.set(price, []);
      }
      chunkedByPrice.get(price)?.push(variant);
    });
    return [...(chunkedByPrice.values() || [])];
  }

}
