import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Theme } from '../../../../models/menu/dto/theme';
import { BaseComponent } from '../../../../models/base/base-component';
import { Orientation } from '../../../../models/utils/dto/orientation-type';
import { MenuType } from '../../../../models/utils/dto/menu-type-definition';
import { DefaultPrintStackSize } from '../../../../models/enum/dto/default-print-stack-size';
import { ThemePreviewViewModel } from './theme-preview-view-model';

@Component({
  selector: 'app-theme-preview',
  templateUrl: './theme-preview.component.html',
  styleUrls: ['./theme-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ThemePreviewViewModel],
})
export class ThemePreviewComponent extends BaseComponent implements OnInit, OnChanges {

  constructor(
    public viewModel: ThemePreviewViewModel
  ) {
    super();
  }

  @Input() companyId: number;
  @Input() menuType: MenuType = MenuType.DisplayMenu;
  @Input() selectedCardStackSize: DefaultPrintStackSize;
  @Input() themes: Theme[] = [];
  @Output() orientationSelected = new EventEmitter<Orientation>();
  @Output() themeSelected = new EventEmitter<Theme>();

  override setupViews() {
    this.viewModel.selectedOrientationControl$.notNull().subscribeWhileAlive({
      owner: this,
      next: (option) => this.orientationSelected.emit(option?.value)
    });
    this.viewModel.themeSelected$.subscribeWhileAlive({
      owner: this,
      next: (theme) => this.themeSelected.emit(theme)
    });
    this.viewModel.connectToMenuType(this.menuType);
    this.viewModel.connectToSelectedCardStackSize(this.selectedCardStackSize);
    this.viewModel.connectToThemes(this.themes);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.menuType) this.viewModel.connectToMenuType(this.menuType);
    if (changes?.selectedCardStackSize) this.viewModel.connectToSelectedCardStackSize(this.selectedCardStackSize);
    if (changes?.themes) this.viewModel.connectToThemes(this.themes);
  }

}
