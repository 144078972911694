import { ChangeDetectionStrategy, Component, Injector, Input, NgZone, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { EditCardStackAssetSectionViewModel } from './edit-card-stack-asset-section-view-model';
import { MenuSectionBackgroundComponent } from '../../shared/menu-section-background/menu-section-background.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';
import { ReactiveFormDropDownComponent, ReactiveFormNumberComponent } from '@mobilefirstdev/reactive-form';
import { ImageAlignment } from '../../../../../../models/enum/shared/image-alignment';
import { SectionTemplate } from '../../../../../../models/template/dto/section-template';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';

@Component({
  selector: 'app-edit-card-stack-asset-section',
  templateUrl: './edit-card-stack-asset-section.component.html',
  styleUrls: ['./edit-card-stack-asset-section.component.scss'],
  providers: [EditCardStackAssetSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCardStackAssetSectionComponent extends MenuSectionBackgroundComponent implements OnChanges {

  constructor(
    override viewModel: EditCardStackAssetSectionViewModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    menuPreviewService: MenuPreviewService
  ) {
    super(viewModel, ngZone, ngbModal, injector, menuPreviewService);
  }

  @ViewChild('imageAlignment') imageAlignmentDropdown: ReactiveFormDropDownComponent;
  @ViewChild('imageWidth') imageWidth: ReactiveFormNumberComponent;
  @Input() mergeKey = 'editSection';
  @Input() section: SectionTemplate|HydratedSection;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.section) this.viewModel.connectToSection(this.section);
  }

  override setupBindings() {
    super.setupBindings();
    this.viewModel.imageWidthDisabled$.subscribeWhileAlive({
      owner: this,
      next: imageWidthDisabled => {
        if (imageWidthDisabled) this.imageWidth?.getSelfAsFormItem()?.setValue(100);
      }
    });
    this.viewModel.imageAlignmentDisabled$.subscribeWhileAlive({
      owner: this,
      next: imageAlignmentDisabled => {
        if (imageAlignmentDisabled) this.imageAlignmentDropdown?.getSelfAsFormItem()?.setValue(ImageAlignment.Center);
      }
    });
  }

}
