import { Injectable } from '@angular/core';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { MenuType, MenuTypeDefinition } from '../../../../../models/utils/dto/menu-type-definition';
import { ProductMenuType } from '../../../../../models/enum/dto/product-menu-type.enum';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { Menu } from '../../../../../models/menu/dto/menu';
import { Orientation } from '../../../../../models/utils/dto/orientation-type';
import { Tag } from '../../../../../models/menu/dto/tag';
import { DefaultPrintSize, DefaultPrintSizeType } from '../../../../../models/utils/dto/default-print-size-type';
import { Size } from '../../../../../models/shared/size';
import { DefaultDigitalSize } from '../../../../../models/utils/dto/default-digital-size-type';
import { ClientTypeUtils } from '../../../../../utils/client-type-utils';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Theme } from '../../../../../models/menu/dto/theme';
import { ToastService } from '../../../../../services/toast-service';
import { MenuCreationType } from '../../../../../models/enum/shared/menu-creation-type.enum';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { SingleSelectionItem } from '../../../../../models/shared/stylesheet/single-selection-item';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { BsError } from '../../../../../models/shared/bs-error';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';
import { SyncSmartFilterService } from '../../../../../services/smart-filter-sync.service';
import { MenuSubtype } from '../../../../../models/enum/dto/menu-subtype';
import { DefaultPrintCardPaperSize, DefaultPrintCardPaperSizeType } from '../../../../../models/utils/dto/default-print-card-paper-size-type';
import { SizeUnit } from '../../../../../models/utils/dto/size-unit-type';
import { ThemeUtils } from '../../../../../utils/theme-utils';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { DefaultPrintLabelPaperSize } from '../../../../../models/utils/dto/default-print-label-paper-size-type';
import { DefaultPrintStackSize } from '../../../../../models/enum/dto/default-print-stack-size';
import { MenuCreationFlowType } from '../../../../../models/utils/dto/menu-creation-flow-type';
import { exists } from '../../../../../functions/exists';
import { SortUtils } from '../../../../../utils/sort-utils';

export enum MenuCreationStep {
  HowToCreate,
  ImportFromTemplate,
  PickMenuSubType,
  PickMenuTheme
}

@Injectable()
export class NewMenuModalViewModel extends BaseModalViewModel {

  constructor(
    private menuDomainModel: MenuDomainModel,
    private templateDomainModel: TemplateDomainModel,
    private locationDomainModel: LocationDomainModel,
    private syncSmartFilterService: SyncSmartFilterService,
    private userDomainModel: UserDomainModel,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
    this.setPositionBasedOnTemplateAndMenuType();
  }

  static applyDefaultDisplaySize(
    m: Menu,
    printSizes: DefaultPrintSizeType[],
    cardSizes: DefaultPrintCardPaperSizeType[]
  ) {
    // Must set name, unit and orientation in order for backend to autocomplete display size
    if (!m.displaySize) {
      m.displaySize = new Size();
      switch (m.type) {
        case MenuType.DisplayMenu:
        case MenuType.MarketingMenu:
          m.displaySize.name = DefaultDigitalSize.Digital1080p;
          m.displaySize.unit = ClientTypeUtils.SizeUnit.Digital;
          break;
        case MenuType.WebMenu:
          m.displaySize.height = 1280;
          m.displaySize.width = 800;
          m.displaySize.unit = ClientTypeUtils.SizeUnit.Digital;
          break;
        case MenuType.PrintMenu:
        case MenuType.PrintReportMenu:
          const printConfig = m?.hydratedTheme?.printConfig;
          const paperSizes = printConfig?.paperSizes;
          const displaySizes = printSizes?.filter(s => paperSizes?.includes(s.value));
          const includesLetterPaper = displaySizes?.map(s => s.value)?.contains(DefaultPrintSize.PaperLetter);
          const includesPortraitOrientation = printConfig?.orientations?.contains(Orientation.Portrait);
          if (includesLetterPaper && includesPortraitOrientation) {
            m.displaySize.name = DefaultPrintSize.PaperLetter;
            m.displaySize.orientation = Orientation.Portrait;
            m.displaySize.unit = ClientTypeUtils.SizeUnit.Imperial;
          } else {
            m.displaySize.orientation = m.hydratedTheme?.printConfig?.orientations[0] as Orientation;
            m.displaySize.name = displaySizes?.[0]?.value;
            m.displaySize.unit = DefaultPrintSizeType.getUnitTypeFromSize(displaySizes?.[0]);
          }
          break;
        case MenuType.PrintCardMenu:
        case MenuType.PrintShelfTalkerMenu:
          const cardPaperSizes = cardSizes
            ?.filter(cs => m?.hydratedTheme?.printConfig?.paperSizes?.includes(cs.value))
            ?.map(cs => cs?.value);
          const cardPaperSizesContainsLetter = cardPaperSizes
            ?.includes(DefaultPrintCardPaperSize.DefaultSize_Letter_CustomCut);
          m.displaySize.name = cardPaperSizesContainsLetter
            ? DefaultPrintCardPaperSize.DefaultSize_Letter_CustomCut
            : cardPaperSizes?.[0];
          m.displaySize.orientation = m.hydratedTheme?.printConfig?.orientations[0] as Orientation;
          m.displaySize.unit = SizeUnit.Imperial;
          break;
        case MenuType.PrintLabelMenu:
          m.displaySize.name = DefaultPrintLabelPaperSize.DefaultSize_LetterLabel_Uline;
          m.displaySize.orientation = m.hydratedTheme?.printConfig?.orientations[0] as Orientation;
          m.displaySize.unit = SizeUnit.Imperial;
          break;
      }
    }
  }

  private _newMenu = new BehaviorSubject<Menu>(Menu.newProductMenu());
  public newMenu$ = this._newMenu as Observable<Menu>;

  private _position = new BehaviorSubject<MenuCreationStep>(MenuCreationStep.HowToCreate);
  public position$ = this._position as Observable<MenuCreationStep>;

  private _menuSubTypes = new BehaviorSubject<MenuSubtype[]>([ProductMenuType.ProductMenu]);
  public menuSubTypes$ = this._menuSubTypes as Observable<MenuSubtype[]>;

  // use setMenuType instead of directly changing with _menuType.next
  private _menuType = new BehaviorSubject<MenuType>(MenuType.DisplayMenu);
  public menuType$ = this._menuType as Observable<MenuType>;

  private _selectedTemplate = new BehaviorSubject<MenuTemplate>(null);
  public selectedTemplate$ = this._selectedTemplate as Observable<MenuTemplate>;

  public menuTemplates$ = this.menuType$.pipe(
    switchMap(menuType => {
      switch (menuType) {
        case MenuType.DisplayMenu:
          return this.templateDomainModel.displayMenuTemplates$;
        case MenuType.MarketingMenu:
          return this.templateDomainModel.marketingMenuTemplates$;
        case MenuType.PrintMenu:
          return this.templateDomainModel.printMenuTemplates$;
        case MenuType.PrintCardMenu:
        case MenuType.PrintShelfTalkerMenu: {
          return combineLatest([
            this.templateDomainModel.printCardTemplates$,
            this.templateDomainModel.printShelfTalkerTemplates$
          ]).pipe(
            map(([cardTemplates, shelfTalkerTemplates]) => {
              if (!cardTemplates || !shelfTalkerTemplates) return null;
              return [...cardTemplates, ...shelfTalkerTemplates]?.sort(SortUtils.menusByNameAsc);
            })
          );
        }
        case MenuType.PrintLabelMenu:
          return this.templateDomainModel.printLabelTemplates$;
        case MenuType.PrintReportMenu:
            return this.templateDomainModel.printReportMenuTemplates$;
        case MenuType.WebMenu:
          return this.templateDomainModel.webMenuTemplates$;
        default:
          return this.templateDomainModel.menuTemplates$;
      }
    })
  );
  public companySupportsTemplates$ = this.userDomainModel.canUseTemplates$;
  public locationId$ = this.locationDomainModel.locationId$;

  private listenToMenuType = combineLatest([
    this.menuType$,
    this.newMenu$.distinctUniquelyIdentifiable()
  ]).subscribeWhileAlive({
    owner: this,
    next: ([type, newMenu]) => {
      newMenu.type = type;
      this._newMenu.next(newMenu);
    }
  });

  // Form
  private _selectedTheme = new BehaviorSubject<Theme>(null);
  public selectedTheme$ = this._selectedTheme as Observable<Theme>;

  private _selectedStackSize = new BehaviorSubject<DefaultPrintStackSize>(null);
  public selectedStackSize$ = this._selectedStackSize as Observable<DefaultPrintStackSize>;
  connectToSelectedStackSize = (css: DefaultPrintStackSize) => this._selectedStackSize.next(css);

  private _selectedOrientation = new BehaviorSubject<Orientation>(null);
  public selectedOrientation$ = this._selectedOrientation as Observable<Orientation>;
  connectToSelectedOrientation = (o: Orientation) => this._selectedOrientation.next(o);

  public _formIsValid = new BehaviorSubject<boolean>(false);
  public formIsValid$ = this._formIsValid as Observable<boolean>;
  connectToFormIsValid = (valid: boolean) => this._formIsValid.next(valid);

  public hasStackSizeSelectedIfNeeded$ = combineLatest([
    this.menuType$,
    this.selectedStackSize$
  ]).pipe(
    map(([menuType, stackSizeSelected]) => {
      return MenuTypeDefinition.containsStackedContent(menuType) ? !!stackSizeSelected : true;
    })
  );

  public canSubmitForm$ = combineLatest([
    this.formIsValid$,
    this.selectedTheme$,
    this.selectedTemplate$,
    this.hasStackSizeSelectedIfNeeded$
  ]).pipe(
    map(([isValid, selectedTheme, selectedTemplate, hasCardStackSizeSelectedIfNeeded]) => {
      return (isValid && !!selectedTheme && hasCardStackSizeSelectedIfNeeded) || !!selectedTemplate;
    })
  );

  public dispersedKey$ = of('createMenu');

  public tags$: Observable<Tag[]> = combineLatest([
    this.menuDomainModel.existingTags$.notNull(),
    this.templateDomainModel.existingMenuTemplateTags$.notNull()
  ]).pipe(
    map(([menuTags, templateTags]) => [...(menuTags || []), ...(templateTags || [])].uniqueByProperty('value')),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public modalTitle$ = combineLatest([
    this.menuType$,
    this.menuSubTypes$
  ]).pipe(
    map(([type, subTypes]) => {
      switch (type) {
        case MenuType.PrintMenu:
          return 'Create Print Menu';
        case MenuType.MarketingMenu:
          return 'Create Marketing Menu';
        case MenuType.PrintCardMenu:
        case MenuType.PrintShelfTalkerMenu:
          return 'Create Print Card Stack';
        case MenuType.PrintLabelMenu:
          return 'Create Print Label Menu';
        case MenuType.PrintReportMenu:
            return 'Create Report';
        case MenuType.DisplayMenu: {
          switch (subTypes?.firstOrNull()) {
            case ProductMenuType.SpotlightMenu:
              return 'Create Spotlight Menu';
            default:
              return 'Create Product Menu';
          }
        }
        default: return 'Create Product Menu';
      }
    })
  );

  public canImportFromTemplate$ = combineLatest([
    this.menuTemplates$,
    this.companySupportsTemplates$
  ]).pipe(
    map(([menuTemplates, companySupportsTemplates]) => {
      const atLeastOnePublishedTemplate = menuTemplates?.some(mt => mt?.isPublished());
      return companySupportsTemplates && atLeastOnePublishedTemplate;
    })
  );

  public isHowToCreateStep$ = this.position$.pipe(
    map(position => position === MenuCreationStep.HowToCreate)
  );

  public isImportFromTemplateStep$ = this.position$.pipe(
    map(position => position === MenuCreationStep.ImportFromTemplate)
  );

  public isPickMenuSubTypeStep$ = this.position$.pipe(
    map(position => position === MenuCreationStep.PickMenuSubType)
  );

  public isPickMenuThemeStep$ = this.position$.pipe(
    map(position => position === MenuCreationStep.PickMenuTheme)
  );

  public fixedModalHeight$ = this.position$.pipe(
    map(position => {
      switch (position) {
        case MenuCreationStep.ImportFromTemplate:
          return '40rem';
        default:
          return null;
      }
    })
  );

  public hideGoBack$ = combineLatest([
    this.position$,
    this.menuType$,
    this.canImportFromTemplate$
  ]).pipe(
    map(([position, menuType, canImportFromTemplate]) => {
      const isHowToCreateStep = position === MenuCreationStep.HowToCreate;
      let isFirstStep = false;
      if (!canImportFromTemplate) {
        if (menuType === MenuType.PrintMenu || MenuTypeDefinition.containsStackedContent(menuType)) {
          isFirstStep = position === MenuCreationStep.PickMenuTheme;
        } else {
          isFirstStep = position === MenuCreationStep.PickMenuSubType;
        }
      }
      return isHowToCreateStep || isFirstStep;
    })
  );

  public selectableTemplates$ = combineLatest([
    this.menuTemplates$,
    this.locationId$
  ]).pipe(
    map(([menuTemplates, locationId]) => {
      const selectableItems = [];
      const tooltip = 'This template is already imported at this location';
      menuTemplates?.forEach(t => {
        if (t?.isPublished()) {
          const item = new SingleSelectionItem(t?.name, null, t);
          if (t?.activeLocationIds?.includes(locationId)) {
            item.disabled = true;
            item.tooltip = tooltip;
          }
          selectableItems?.push(item);
        }
      });
      return selectableItems;
    })
  );

  setPositionBasedOnTemplateAndMenuType() {
    combineLatest([
      this.canImportFromTemplate$,
      this.menuType$,
    ]).once(([canImportFromTemplate, menuType]) => {
      if (canImportFromTemplate) {
        // Only prompt 'how to create' step if templates of the selected menu type exist
        this.setPosition(MenuCreationStep.HowToCreate);
      } else {
        // Fallback logic checks if menu type should prompt for subtype or go straight to theme
        const menuTypeDefinition = window?.types?.initTypeDefinition(MenuTypeDefinition, menuType);
        if (menuTypeDefinition?.requiresMenuSubtype()) {
          this.setPosition(MenuCreationStep.PickMenuSubType);
        } else {
          this.setPosition(MenuCreationStep.PickMenuTheme);
        }
      }
    });
  }

  setPosition(n: number) {
    this._position.next(n);
  }

  /**
   * Print card and shelf talkers both use one another as subtypes in this flow
   * key: "PRINT_CARD_MENU"
   * value: [{ "CREATE_PRINT_CARD_MENU" => Array(1), "CREATE_PRINT_SHELF_TALKER_MENU" => Array(1) }]
   * key: "PRINT_SHELF_TALKER_MENU"
   * value: [{ "CREATE_PRINT_CARD_MENU" => Array(1), "CREATE_PRINT_SHELF_TALKER_MENU" => Array(1) }]
   * Therefore, we say that it has multiple subtypes
   */
  private noCreationSubTypes(menuType: MenuType): boolean {
    return menuType === MenuType.PrintMenu
        || menuType === MenuType.PrintLabelMenu
        || menuType === MenuType.PrintReportMenu;
  }

  goBack() {
    combineLatest([
      this.position$,
      this.menuType$
    ]).once(([position, menuType]) => {
      switch (position) {
        case MenuCreationStep.ImportFromTemplate:
          this.setPosition(MenuCreationStep.HowToCreate);
          this._selectedTemplate.next(null);
          break;
        case MenuCreationStep.PickMenuSubType:
          this.setPosition(MenuCreationStep.HowToCreate);
          break;
        case MenuCreationStep.PickMenuTheme:
          if (this.noCreationSubTypes(menuType)) {
            this.setPosition(MenuCreationStep.HowToCreate);
          } else {
            this.setPosition(MenuCreationStep.PickMenuSubType);
          }
          break;
      }
    });
  }

  setMenuCreationType(creationType: MenuCreationType) {
    this.menuType$.once(type => {
      switch (creationType) {
        case MenuCreationType.FromTemplate:
          this.setPosition(MenuCreationStep.ImportFromTemplate);
          break;
        case MenuCreationType.New:
          switch (true) {
            case this.noCreationSubTypes(type):
              this.setPosition(MenuCreationStep.PickMenuTheme);
              break;
            default:
              this.setPosition(MenuCreationStep.PickMenuSubType);
          }
          break;
      }
    });
  }

  setTemplate(selectedItem: SingleSelectionItem) {
    const menuTemplate: MenuTemplate = selectedItem.value;
    this._selectedTemplate.next(menuTemplate);
  }

  setMenuType(type: MenuType, initialize: boolean = true) {
    this._menuType.next(type);
    if (initialize) this.setPositionBasedOnTemplateAndMenuType();
    // Set base subtype based on menu type to handle scenarios where no intermediary picker exists
    const menuType = window?.types?.initTypeDefinition(MenuTypeDefinition, type);
    this._menuSubTypes.next(menuType.getMenuSubTypes());
  }

  setMenuSubTypes(types: MenuSubtype[]) {
    this._menuSubTypes.next(types);
    this.setPosition(MenuCreationStep.PickMenuTheme);
  }

  public selectedMenuType = <T extends MenuCreationFlowType>(type: T): void => {
    const mapping: { [key in MenuCreationFlowType]?: MenuType } = {
      [MenuCreationFlowType.PrintCardMenu]: MenuType.PrintCardMenu,
      [MenuCreationFlowType.PrintShelfTalkerMenu]: MenuType.PrintShelfTalkerMenu,
    };
    // This is specific to shelf talkers and print cards, because we use the same flow to create them, but they
    // have separate menu types.
    const updateMenuType = mapping[type] ?? null;
    if (exists(updateMenuType)) {
      this.setMenuType(updateMenuType, false);
    }
  };

  public paperSizeTypes$ = combineLatest([
    window.types.printSizeTypes$,
    window.types.printCardPaperSizeTypes$
  ]);

  createNewMenu() {
    const lm = 'Creating Menu';
    combineLatest([
      this.newMenu$,
      this.selectedTemplate$,
      this.locationId$,
      this.selectedOrientation$,
      this.selectedStackSize$,
      this.paperSizeTypes$
    ]).pipe(
      take(1),
      switchMap(([
        menu,
        template,
        locationId,
        orientation,
        selectedStackSize,
        [printSizeTypes, printCardPaperSizeTypes],
      ]) => {
        this._loadingOpts.addRequest(lm);
        menu.locationId = locationId;
        NewMenuModalViewModel.applyDefaultDisplaySize(menu, printSizeTypes, printCardPaperSizeTypes);
        if (menu?.containsStackedContent()) {
          menu.metadata.printCardSize = selectedStackSize;
        }
        if (menu.type !== MenuType.PrintMenu && menu.type !== MenuType.PrintCardMenu) {
          menu.displaySize.orientation = orientation;
        }
        if (menu?.isMarketingMenu()) {
          menu.metadata.cardType = ThemeUtils.getStartingCardType(menu?.theme);
        }

        if (!!template) {
          menu.templateId = template?.id;
        }
        return this.menuDomainModel.createNewMenu(menu);
      }),
      take(1)
    ).subscribe({
      next: (nm) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('New menu created successfully.', 'Menu Created');
        this.templateDomainModel.loadCompanyMenuTemplates();
        if (!!nm?.templateId && nm?.displayableItemHasSmartFilters()) {
          this.syncAllSections(nm);
        }
        this.dismissModalSubject.next(nm);
      },
      error: (error: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(() => error);
      }
    });

  }

  syncAllSections(nm: Menu) {
    const sectionsToSync = nm?.sections
      ?.filter(s => s?.hasSmartFilters())
      ?.map(s => this.syncSmartFilterService.syncSectionSmartFilters(nm, s));
    forkJoin(sectionsToSync)?.subscribe({
      error: (err: BsError) => {
        this.toastService.publishBannerFailed(err?.message);
        throwError(() => err);
      }
    });
  }

  connectToThemeSelected(t: Theme) {
    this.newMenu$.once(newMenu => {
      newMenu.theme = t?.id;
      newMenu.hydratedTheme.printConfig = t?.printConfig;
      this._newMenu.next(newMenu);
    });
    this._selectedTheme.next(t);
  }

}
