import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { EditVariantContainer } from '../../edit-variant-container';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DateUtils } from '../../../../../../../utils/date-utils';
import { Location } from '../../../../../../../models/company/dto/location';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { VariantInventory } from '../../../../../../../models/product/dto/variant-inventory';
import { LoadingOptions } from '../../../../../../../models/shared/loading-options';
import { ProviderUtils } from '../../../../../../../utils/provider-utils';
import { MenuLabel } from '../../../../../../../models/enum/dto/menu-label';

type LocationInventory = { location: Location; locationInventory: VariantInventory };
export type LocationVariantInventory = {
  location: Location;
  inventoryForVariant: number,
  decoratedInventoryForVariant: string
};

@Injectable()
export class EditVariantAvailabilityViewModel extends BaseViewModel {

  constructor(
    public container: EditVariantContainer,
  ) {
    super();
    this._loadingOpts.addRequest(this.loadingMessage);
  }

  protected override _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.defaultWhiteBackground());
  private loadingMessage = 'Loading Availability';
  private totalInventoryAtOtherLocations$ = new BehaviorSubject<number>(0);

  public hasMultipleLocations$ = this.container.companyLocations$.pipe(map(allLocations => allLocations.length > 1));
  public locationName$ = this.container.currentLocation$.pipe(map(currentLocation => currentLocation.name));
  public quantityInStock$ = this.container.variant$.pipe(map(variant => variant.getQuantityInStock()));
  public quantityInStockToDisplay$ = combineLatest([
    this.container.inventoryProvider$,
    this.quantityInStock$
  ]).pipe(
    map(([inventoryProvider, quantityInStock]) => {
      return ProviderUtils.applyVariantInventoryDecorator(inventoryProvider, quantityInStock);
    })
  );

  public lastRestock$ = this.container.variant$.pipe(
    map(variant => {
      if (variant?.inventory?.lastRestockedTimeInSeconds() > 0) {
        return DateUtils.formatUnixToDate(variant.inventory?.lastRestockedTimeInSeconds());
      } else {
        return '--';
      }
    })
  );
  public lastThresholdRestock$ = this.container.variant$.pipe(
    map(variant => {
      if (variant?.inventory?.lastThresholdRestock > 0) {
        return DateUtils.formatUnixToDate(variant.inventory?.lastThresholdRestock);
      } else {
        return '--';
      }
    })
  );

  public locationRestockLabelQuantityThreshold$ = this.container.locationSystemLabels$.pipe(
    map(labels => labels.find(l => l.id === MenuLabel.Restocked)?.numericThreshold || 0),
  );

  public showThresholdRestock$ = combineLatest([
    this.locationRestockLabelQuantityThreshold$,
  ]).pipe(
    map(([threshold]) => threshold > 0),
  );

  public locationsGroupedByProvince$ = this.container.companyLocations$.pipe(
    map(companyLocations => this.groupLocationsByProvince(companyLocations))
  );

  private inventories$ = this.container.inventories$;

  private inventoryForLocations$: Observable<LocationInventory[]> = combineLatest([
    this.inventories$,
    this.container.companyLocations$
  ]).pipe(
    map(([inventories, companyLocations]) => {
      return companyLocations.map(location => this.getInventoryForLocation(location, inventories));
    })
  );

  public inventoryForVariantByLocation$: Observable<Map<string, LocationVariantInventory[]>> =
    combineLatest([
      this.locationsGroupedByProvince$,
      this.inventoryForLocations$,
      this.container.variant$,
      this.container.currentLocation$,
      this.container.inventoryProvider$
    ]).pipe(
      map(([
        locationsGroupedByProvince,
        inventoryForLocations,
        variant,
        currentLocation,
        inventoryProvider
      ]) => {
        const variantInventoryForSortedLocationsMap = new Map<string, LocationVariantInventory[]>();
        let totalInvAtOtherLocations = 0;
        locationsGroupedByProvince.forEach((locations, province) => {
          const locationsWithVariantInventory = [];
          locations.filter(loc => loc.id !== currentLocation.id).forEach(location => {
            const locInventory = inventoryForLocations.find(ifl => ifl.location.id === location.id);
            const locationInventory = locInventory.locationInventory;
            const varInventory: LocationVariantInventory = {
              location,
              inventoryForVariant: locationInventory?.quantityInStock,
              decoratedInventoryForVariant: ProviderUtils.applyVariantInventoryDecorator(
                inventoryProvider,
                locationInventory?.quantityInStock
              )
            };
            locationsWithVariantInventory.push(varInventory);
          });
          variantInventoryForSortedLocationsMap.set(province, locationsWithVariantInventory);
          variantInventoryForSortedLocationsMap.forEach((provinceAndInventory) => {
            provinceAndInventory.forEach(pai => {
              totalInvAtOtherLocations += pai.inventoryForVariant || 0;
            });
          });
        });
        this.totalInventoryAtOtherLocations$.next(totalInvAtOtherLocations);
        this._loadingOpts.removeRequest(this.loadingMessage);
        return variantInventoryForSortedLocationsMap;
      }),
    );

  public locationPercentageDifference$: Observable<number> = combineLatest([
    this.inventoryForVariantByLocation$,
    this.quantityInStock$
  ]).pipe(
    map(([inventoryForVariantByLocation, quantityInStock]) => {
      let inStockTotal = 0;
      let totalLocationsWithStock = 0;
      inventoryForVariantByLocation.forEach((locationAndInventory) => {
        locationAndInventory.forEach(lai => {
          if (lai.inventoryForVariant && lai.inventoryForVariant !== 0) {
            inStockTotal += lai.inventoryForVariant;
            totalLocationsWithStock += 1;
          }
        });
      });
      if (!totalLocationsWithStock || totalLocationsWithStock === 0) return 100;
      const avg = inStockTotal / totalLocationsWithStock;
      return Math.round((quantityInStock - avg) / avg * 100);
    })
  );

  public shouldShowInventoryCountPercentage$ = combineLatest([
    this.hasMultipleLocations$.pipe(distinctUntilChanged()),
    this.quantityInStock$.pipe(distinctUntilChanged()),
    this.totalInventoryAtOtherLocations$.pipe(distinctUntilChanged())
  ]).pipe(
    map(([hasMultipleLocations, quantity, stockAtOtherLocations]) => {
      return hasMultipleLocations && quantity > 0 && stockAtOtherLocations > 0;
    })
  );

  private groupLocationsByProvince(locations: Location[]): Map<string, Location[]> {
    const groupedLocationResults: Map<string, Location[]> = new Map<string, Location[]>();
    locations.forEach(location => {
      const existingGroup = groupedLocationResults.get(location.state);
      if (!!existingGroup) {
        existingGroup.push(location);
      } else {
        groupedLocationResults.set(location.state, [location]);
      }
    });
    return groupedLocationResults;
  }

  private getInventoryForLocation(
    location: Location,
    inventories: VariantInventory[]
  ): LocationInventory {
    const locationInventory = inventories.find(i => i.locationId === location.id);
    return {location, locationInventory};
  }

  public formatInventoryForDisplay(locationVariantInventory: LocationVariantInventory): string {
    // If no inventory object exists at other location or value is not finite, return N/A
    const notAvailable = locationVariantInventory?.inventoryForVariant === null
      || locationVariantInventory?.inventoryForVariant === undefined
      || !Number.isFinite(locationVariantInventory?.inventoryForVariant);
    if (notAvailable) return 'N/A';
    // If inventory value exists, return the decorated string (this accounts for upper ended limits ie: 30+)
    if (locationVariantInventory.inventoryForVariant) return locationVariantInventory.decoratedInventoryForVariant;
    return '0';
  }

}
