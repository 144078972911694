import { Injectable } from '@angular/core';
import { BulkPrintJobDomainModel } from '../../../../../../../domainModels/bulk-print-job-domain-model';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BulkPrintJob } from '../../../../../../../models/automation/bulk-print-job';
import { StackType } from '../../../../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';

@Injectable()
export class StackSmartBulkPrintPreviousJobsFormViewModel {

  constructor(private bulkPrintJobDomainModel: BulkPrintJobDomainModel) {
  }

  public successfulLocationBulkPrintJobs$ = this.bulkPrintJobDomainModel.currentSuccessfulLocationBulkPrintJobs$;

  private _stackType = new BehaviorSubject<string>(null);
  public readonly stackType$ = this._stackType.pipe(distinctUntilChanged());
  connectToStackType = (type: StackType) => this._stackType.next(type);

  private _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => this._job.next(job);

  private _selectedCardStacks = new BehaviorSubject<Menu[]>([]);
  public selectedCardStacks$ = this._selectedCardStacks as Observable<Menu[]>;
  connectToSelectedCardStacks = (cardStacks: Menu[]) => this._selectedCardStacks.next(cardStacks);

  private _viewOnly = new BehaviorSubject<boolean>(false);
  public viewOnly$ = this._viewOnly as Observable<boolean>;
  connectToViewOnly = (viewOnly: boolean) => this._viewOnly.next(viewOnly);

  public selectedCardStacksWithPreviousJobs$ = combineLatest([
    this.selectedCardStacks$,
    this.successfulLocationBulkPrintJobs$
  ]).pipe(
    map(([cardStacks, jobs]) => {
      return cardStacks?.filter(stack => jobs?.some(job => job?.menuIncludedInPrintJob(stack?.id)));
    })
  );

  public selectedCardStacksWithoutPreviousJobs$ = combineLatest([
    this.selectedCardStacks$,
    this.successfulLocationBulkPrintJobs$,
  ]).pipe(
    map(([cardStacks, jobs]) => {
      return cardStacks?.filter(stack => !jobs?.some(job => job?.cardStackPrintConfigMap?.has(stack?.id)));
    })
  );

  public cardStacksWithAllCardsPrinted$: Observable<Menu[]> = combineLatest([
    this.selectedCardStacks$,
    this.job$
  ]).pipe(
    map(([cardStacks, currentJob]) => {
      const noIncludeChangesSince = (stackId: string) => {
        return currentJob?.cardStackPrintConfigMap?.get(stackId)?.includeChangesSince === 0;
      };
      return cardStacks?.filter((stack) => noIncludeChangesSince(stack?.id));
    })
  );

  public allStackCardsPrintedList$: Observable<Menu[]> = this.viewOnly$.pipe(
    switchMap(viewOnly => {
      return viewOnly ? this.cardStacksWithAllCardsPrinted$ : this.selectedCardStacksWithoutPreviousJobs$;
    })
  );

  public allStackCardsPrintedDescription$ = combineLatest([
    this.viewOnly$,
    this.stackType$
  ]).pipe(
    map(([viewOnly, stackType]) => {
      return `The following ${stackType} stacks ${viewOnly ? 'had' : 'have'} no previous print jobs within the last `
        + `${BulkPrintJobDomainModel.BULK_PRINT_JOB_CLEANUP_DAYS} days. All cards ${viewOnly ? 'were' : 'will be'} `
        + `printed.`;
    })
  );

}
