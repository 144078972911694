import { Injectable, Injector, NgZone } from '@angular/core';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { PublishableDisplayableItemsViewModel } from '../../../../../shared/components/displayable-content/displayable-item-list/publishable-displayable-items-view-model';
import { PrintTemplatesContainerViewModel } from '../print-templates-container/print-templates-container-view-model';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortUtils } from '../../../../../../utils/sort-utils';

@Injectable()
export class PrintCardTemplatesViewModel extends PublishableDisplayableItemsViewModel {

  constructor(
    protected templatesDomainModel: TemplateDomainModel,
    // digitalTemplatesContainerViewModel provided by the Logged In Scope - need to get access to which tag is selected,
    // only way to pass this data to child is via a shared model, because child component is being created
    // by tab bar component (inflated in non conventional way), which means I can't pass in inputs
    protected printTemplatesContainerViewModel: PrintTemplatesContainerViewModel,
    navigationService: NavigationService,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
  ) {
    super(printTemplatesContainerViewModel, navigationService, ngZone, ngbModal, injector);
    this.setupFilter();
  }

  protected defaultEmptyStateTitle = 'No Print Card Templates';

  protected override defaultEmptyStateDescription = 'Create a print card template by using the button above.';

  protected itemsToFilter$ = combineLatest([
    this.templatesDomainModel.printCardTemplates$,
    this.templatesDomainModel.printShelfTalkerTemplates$
  ]).pipe(
    map(([cardStacks, shelfTalkerStacks]) => {
      if (!cardStacks || !shelfTalkerStacks) return null;
      return [...cardStacks, ...shelfTalkerStacks]?.sort(SortUtils.menusByNameAsc);
    })
  );

  protected getEmptyStateTitleCopy(menuTypeFilter: string): string {
    return this.genericEmptyStateTitleCopyForTemplates(menuTypeFilter, 'print card templates');
  }

  public openCreatePrintCardTemplateModal() {
    this.openCreateTemplateModal(MenuType.PrintCardMenu);
  }

}
