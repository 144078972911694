import { Injectable } from '@angular/core';
import { CompanyAPI } from '../api/company-api';
import { BsError } from '../models/shared/bs-error';
import { ToastService } from '../services/toast-service';
import { Company } from '../models/company/dto/company';
import { CacheService } from '../services/cache-service';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { BudsenseFile } from '../models/shared/budsense-file';
import { BehaviorSubject, combineLatest, EMPTY, interval, Observable, throwError } from 'rxjs';
import { GenerateUploadUrlRequest } from '../models/image/requests/generate-upload-url-request';
import { UploadFilePath } from '../models/enum/dto/upload-file.path';
import { catchError, delay, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ImageAPI } from '../api/image-api';
import { LogoTypeEnum } from '../models/company/enum/logo-type.enum';
import { InventoryProviderConfiguration } from '../models/company/dto/inventory-provider-configuration';
import { CompanyConfiguration } from '../models/company/dto/company-configuration';
import { HydratedUser } from '../models/account/dto/hydrated-user';
import { AccountAPI } from '../api/account-api';
import { EditEmployee } from '../models/company/shared/edit-employee';
import { User } from '../models/account/dto/user';
import { CreateUserRequest } from '../models/account/requests/create-user-request';
import { ProductAPI } from '../api/product-api';
import { Asset } from '../models/image/dto/asset';
import { StringUtils } from '../utils/string-utils';
import { environment } from '../../environments/environment';
import { DistinctUtils } from '../utils/distinct-utils';
import { ProviderUtils } from '../utils/provider-utils';
import { CannabinoidDisplayType } from '../models/utils/dto/cannabinoid-display-type-definition';
import { InventoryRoom } from '../models/company/dto/inventory-room';
import { UserDomainModel } from './user-domain-model';
import { RefetchConfigUtils } from '../utils/refetch-config-utils';
import { exists } from '../functions/exists';
import { TerpeneDisplayType } from '../models/utils/dto/terpene-display-type-definition';
import { InventoryProvider } from '../models/enum/dto/inventory-provider';
import { ProductTableColumnConfig } from '../models/menu/dto/product-table-column-config';
import { ProductTableColumnConfigsIdValue } from '../models/utils/dto/product-table-column-configs-type';

// Provided by Logged In Scope
@Injectable()
export class CompanyDomainModel extends BaseDomainModel {

  constructor(
    private productAPI: ProductAPI,
    private companyAPI: CompanyAPI,
    private accountAPI: AccountAPI,
    private toastService: ToastService,
    private cacheService: CacheService,
    private imageAPI: ImageAPI,
    private userDomainModel: UserDomainModel,
  ) {
    super();
    this.setupBindings();
  }

  private userId$ = this.userDomainModel.userId$;

  private _company = new BehaviorSubject<Company | null>(null);
  public company$ = this._company as Observable<Company | null>;

  private setCompanyConfig = (config: CompanyConfiguration) => {
    this.company$.once(company => {
      const companyCopy = window?.injector?.Deserialize?.instanceOf(Company, company);
      companyCopy.configuration = config;
      this._company.next(companyCopy);
    });
  };

  public companyId$ = this.company$.pipe(
    map(company => company?.id),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public companyName$ = this.company$.pipe(map(company => company?.name), distinctUntilChanged());

  // Company Config
  private _inventoryProviderConfigs = new BehaviorSubject<InventoryProviderConfiguration[]>(null);
  public inventoryProviderConfigs$ = this._inventoryProviderConfigs as Observable<InventoryProviderConfiguration[]>;
  public currentInventoryProviderConfig$ = this.inventoryProviderConfigs$.firstNotNull().pipe(
    map(configs => configs.firstOrNull()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public inventoryProvider$: Observable<InventoryProvider> = this.inventoryProviderConfigs$.pipe(
    map(configs => ProviderUtils.getInventoryProviderFromProviderConfigurations(configs)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private pluck = <T>(mapTo: (value: InventoryProvider) => T): Observable<T> => {
    return this.inventoryProvider$.pipe(
      map(mapTo),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };

  public posSupportsPOSSync$ = this.pluck(ProviderUtils.supportsPOSSync);
  public posSupportsLotInfo$ = this.pluck(ProviderUtils.supportsPOSLotInfo);
  public posSupportsLocationSpecificSync$ = this.pluck(ProviderUtils.supportsLocationSpecificPOSSyncing);
  public posSupportsSecondaryCannabinoids$ = this.pluck(ProviderUtils.supportsPresentSecondaryCannabinoids);
  public posSupportsTotalActiveCannabinoids$ = this.pluck(ProviderUtils.supportsTotalActiveCannabinoids);
  public posSupportsPresentTerpenes$ = this.pluck(ProviderUtils.supportsPresentTerpenes);
  public posSupportsIndividualTerpeneValues$ = this.pluck(ProviderUtils.supportsIndividualTerpeneValues);
  public posSupportsTotalTerpene$ = this.pluck(ProviderUtils.supportsTotalTerpene);
  public posSupportsTopTerpene$ = this.pluck(ProviderUtils.supportsTopTerpene);
  public posSupportsTaxCreationWithinBudSense$ = this.pluck(ProviderUtils.supportsTaxCreationWithinBudSense);

  private _employees: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);
  public employees$ = this._employees.pipe(
    map(employees => {
      // Ignore hello BudSense emails in production
      if (environment.production) {
        const backdoorEmployeeRegexp = RegExp(/(hello)(.*)(@mybudsense.com)/);
        return employees?.filter(em => !backdoorEmployeeRegexp.test(em.email));
      } else {
        return employees;
      }
    })
  );

  public employeesMinusLoggedInUser$ = this.employees$.pipe(
    withLatestFrom(this.userId$),
    map(([employees, userId]) => employees?.filter(employee => employee.userId !== userId))
  );

  public companyConfiguration$ = this.company$.pipe(
    map(company => company?.configuration),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly companyConfigDefaultProductTableColumnConfig$ = this.companyConfiguration$.pipe(
    map(companyConfig => companyConfig?.defaultProductTableColumConfigs?.firstOrNull()),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly cannabinoidDisplayType$ = this.companyConfiguration$.pipe(
    map(companyConfiguration => companyConfiguration?.cannabinoidDisplayType),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public colorPalette$ = this.companyConfiguration$.pipe(
    map(configuration => configuration?.colorPalette ?? []),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public syncPOSCannabinoid$ = this.companyConfiguration$.pipe(
    map(c => !!c?.syncPOSCannabinoid),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public syncPOSTerpene$ = this.companyConfiguration$.pipe(
    map(c => !!c?.syncPOSTerpene),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public rangeCannabinoidsAtCompanyLevel$ = this.companyConfiguration$.pipe(
    map(companyConfiguration => companyConfiguration?.cannabinoidDisplayType === CannabinoidDisplayType.Range),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public rangeTerpenesAtCompanyLevel$ = this.companyConfiguration$.pipe(
    map(companyConfiguration => companyConfiguration?.terpeneDisplayType === TerpeneDisplayType.Range),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _customProductTableColumnConfig = new BehaviorSubject<ProductTableColumnConfig>(
    new ProductTableColumnConfig()
  );
  public customProductTableColumnConfig$ = this._customProductTableColumnConfig as Observable<ProductTableColumnConfig>;
  setCustomProductTableColumnConfig = (customProductTableColumnConfig: ProductTableColumnConfig) => {
    this._customProductTableColumnConfig.next(customProductTableColumnConfig);
  };

  private productTableColumnConfigs$ = window.types?.productTableColumnConfigs$;
  private productTableColumnConfigOptions$ = combineLatest([
    this.companyConfigDefaultProductTableColumnConfig$,
    this.productTableColumnConfigs$,
    this.customProductTableColumnConfig$,
  ]).pipe(
    map(([companyDefaultPTCC, productTableColumnConfigs, customPTCC]) => {
      const productTableColumnConfigMap = new Map<ProductTableColumnConfigsIdValue, ProductTableColumnConfig>(
        productTableColumnConfigs?.map(productTableColumnConfig => {
          return [productTableColumnConfig.value, productTableColumnConfig.metadata];
        })
      );
      productTableColumnConfigMap.set(ProductTableColumnConfigsIdValue.CompanyDefault, companyDefaultPTCC);
      productTableColumnConfigMap.set(ProductTableColumnConfigsIdValue.Custom, customPTCC);
      return productTableColumnConfigMap;
    })
  );

  private _currentProductTableColumnConfigsIdValue =
    new BehaviorSubject<ProductTableColumnConfigsIdValue>(ProductTableColumnConfigsIdValue.CompanyDefault);
  public currentProductTableColumnConfigsIdValue$ =
    this._currentProductTableColumnConfigsIdValue as Observable<ProductTableColumnConfigsIdValue>;
  setCurrentProductTableColumnConfigsIdValue = (idValue: ProductTableColumnConfigsIdValue) => {
    this._currentProductTableColumnConfigsIdValue.next(idValue);
  };

  public currentProductTableColumnConfig$ = combineLatest([
    this.productTableColumnConfigOptions$,
    this.currentProductTableColumnConfigsIdValue$
  ]).pipe(
    map(([ptccOptions, idValue]) => {
      return ptccOptions?.get(idValue) || null;
    })
  );

  public readonly productTableColumnConfigKeys$ = this.currentProductTableColumnConfig$.pipe(
    map(config => {
      return  Object.keys(config)?.filter(config => config !== 'image');
    })
  );

  public productPropertiesFromConfig$ = this.companyConfiguration$.pipe(
    map(companyConfiguration => {
      // Creates a CompanyConfig with just these listed properties
      return [
        'defaultCannabisUnitOfMeasure',
        'cannabinoidDisplayType',
        'syncPOSCannabinoid',
        'syncPOSTerpene',
        'saleLabelFormat',
        'labelStyle'
      ].reduce((pluckedObj, key) => {
        pluckedObj[key] = companyConfiguration?.[key];
        return pluckedObj;
      }, new CompanyConfiguration());
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)
  );

  public companySupportsLocationLabels$ = this.company$.pipe(
    map(c => c?.features?.supportsLocationLabels),
    distinctUntilChanged()
  );

  private fetchConfig = interval(RefetchConfigUtils.REFETCH_CONFIG_TIME)
    .stopWhenInactive()
    .subscribeWhileAlive({
      owner: this,
      next: () => this.fetchCompanyConfig()
    });

  setupBindings() {
    // Bind to session to load the company object
    const companySub = this.userDomainModel.user$.pipe(
      filter(user => user?.session?.validSession()),
      withLatestFrom(this.inventoryProviderConfigs$, this.company$)
    ).subscribe(([user, ipcs, prevCompany]) => {
      if (exists(user) && prevCompany?.id?.toString() !== user?.companyId?.toString()) {
        // get company and locations for user
        this.loadCompany();
      }
      if (ipcs === null) {
        this.loadInventoryProvider();
      }
    });
    this.pushSub(companySub);
  }

  loadCompany(force: boolean = false): void {
    // Check cache for Company
    this.companyId$.pipe(
      take(1),
      switchMap(companyId => {
        const key = Company.buildCacheKey(companyId?.toString());
        const cached = this.cacheService.getCachedObject<Company>(Company, key);
        if (!!cached && !force) {
          this._company.next(cached);
          return EMPTY;
        } else {
          return this.companyAPI.GetCompany();
        }
      })
    ).subscribe({
      next: (company) => {
        this._company.next(company);
        this.cacheService.cacheObject<Company>(company.cacheKey(), company);
      },
      error: (error: BsError) => {
        this.toastService.publishError(error);
        throwError(() => error);
      }
    });
  }

  loadInventoryProvider() {
    return this.fetchInventoryProvider().subscribe({
      error: (error) => this.toastService.publishError(error)
    });
  }

  deleteCompanyPicture(type: LogoTypeEnum, locationId: number = 0): Observable<Company> {
    return this.company$.pipe(
      take(1),
      switchMap((company) => {
        const loc = company?.locations?.find((l) => l.id === locationId);
        let existingPicture: Asset;
        if (type === LogoTypeEnum.Default) {
          existingPicture = locationId > 0 ? loc?.logo : company?.logo;
        } else {
          existingPicture = locationId > 0 ? loc?.altLogo : company?.altLogo;
        }
        if (!existingPicture) return EMPTY;

        this.cacheService.removeCachedAsset(existingPicture);
        return this.imageAPI.DeleteAsset(existingPicture.id, existingPicture.md5Hash).pipe(
          delay(2000),
          switchMap((_) => {
            return this.fetchAndSetCompanyFromApi();
          })
        );
      })
    );
  }

  private fetchAndSetCompanyFromApi(): Observable<Company> {
    return this.companyAPI.GetCompany().pipe(
      tap(company => {
        this._company.next(company);
        this.cacheService.cacheObject<Company>(company.cacheKey(), company);
      })
    );
  }

  updateCompanyConfiguration(
    config: CompanyConfiguration,
    currentLocationId: number
  ): Observable<CompanyConfiguration> {
    return this.company$.pipe(
      take(1),
      switchMap((company) => {
        // The API needs current location id to refresh previews for labelStyle changes. If not provided, is ignored
        return this.companyAPI.UpdateCompanyConfiguration(config, currentLocationId).pipe(
          tap((companyConfig) => this.setCompanyConfig(companyConfig))
        );
      })
    );
  }

  updateCompanyColorPalette(colors: string[], currentLocationId: number): Observable<CompanyConfiguration> {
    return this.companyConfiguration$.pipe(
      take(1),
      switchMap((companyConfig) => {
        const updatedConfig = window?.injector?.Deserialize?.instanceOf(CompanyConfiguration, companyConfig);
        updatedConfig.colorPalette = colors;
        return this.updateCompanyConfiguration(updatedConfig, currentLocationId);
      })
    );
  }

  uploadCompanyPicture(
    f: BudsenseFile,
    type: UploadFilePath = UploadFilePath.CompanyLogoPath,
    locationId?: number
  ): Observable<Company> {
    return combineLatest([this.companyId$, this.userId$]).pipe(
      take(1),
      switchMap(([companyId, userId]) => {
        // Generate the upload request
        const req = new GenerateUploadUrlRequest();
        req.fileName = StringUtils.normalizeCharacters(f.name);
        req.mediaClass = type;
        req.mediaType = f.getMediaType();
        req.metadata = new Map<string, string>();
        req.metadata.set('CompanyId', companyId?.toString());
        req.metadata.set('UserId', userId);
        if (locationId && locationId > 0) {
          req.metadata.set('LocationId', locationId.toString());
        }
        return this.uploadCompanyPictureHelper(req, f);
      })
    );
  }

  private uploadCompanyPictureHelper(req: GenerateUploadUrlRequest, f: BudsenseFile): Observable<Company> {
    return this.imageAPI.GenerateUploadUrl(req).pipe(
      switchMap((signedUploadUrl) => {
        return this.imageAPI.PutImageUploadUrl(signedUploadUrl.url, f.url.toString(), req.fileName).pipe(
          // provide delay based on file size
          delay(f.getUploadDelay()),
          switchMap((_) => {
            return this.fetchAndSetCompanyFromApi();
          })
        );
      })
    );
  }

  fetchInventoryProvider(): Observable<InventoryProviderConfiguration[]> {
    return this.companyAPI.GetCompanyInventoryProviderConfigurations().pipe(
      tap((ipcs) => {
        const provider = ProviderUtils.getInventoryProviderFromProviderConfigurations(ipcs);
        this.userDomainModel.setInventoryProvider(provider);
        this._inventoryProviderConfigs.next(ipcs);
      })
    );
  }

  updateInventoryRooms(rooms: InventoryRoom[]): Observable<InventoryRoom[]> {
    return this.companyAPI.UpdateInventoryRooms(rooms).pipe(
      tap((updatedRooms) => this.replaceInventoryRooms(updatedRooms))
    );
  }

  private replaceInventoryRooms(updatedRooms: InventoryRoom[]): void {
    this.inventoryProviderConfigs$.once((ipcs) => {
      const ipcsCopy = window?.injector?.Deserialize?.arrayOf(InventoryProviderConfiguration, ipcs);
      ipcsCopy?.forEach((ipc) => {
        ipc.inventoryRooms = ipc.inventoryRooms?.map((room) => {
          const matchingRoom = updatedRooms.find((r) => r.id === room.id && r.locationId === room.locationId);
          return matchingRoom ? matchingRoom : room;
        });
      });
      this._inventoryProviderConfigs.next(ipcsCopy);
    });
  }

  syncDisplayNames(): Observable<CompanyConfiguration> {
    return this.productAPI.SyncDisplayNames().pipe(
      tap((config) => this.setCompanyConfig(config)),
      catchError(err => {
        this.fetchCompanyConfig();
        return throwError(() => err);
      })
    );
  }

  adminGetEmployees(): Observable<HydratedUser[]> {
    return this.accountAPI.AdminGetUsers().pipe(
      tap(employees => this._employees.next(employees))
    );
  }

  updateEmployeeInformation(e: User, updated: EditEmployee): Observable<User> {
    const shallowCopyEmployee = window?.injector?.Deserialize?.instanceOf(User, e);
    shallowCopyEmployee.firstName = updated.firstName;
    shallowCopyEmployee.lastName = updated.lastName;
    shallowCopyEmployee.email = updated.email;
    shallowCopyEmployee.companyRole = updated.companyRole;
    shallowCopyEmployee.isCompanyAdmin = updated.isCompanyAdmin;
    shallowCopyEmployee.isTemplateAdmin = updated.isTemplateAdmin;
    return this.updateEmployee(shallowCopyEmployee);
  }

  toggleEmployeeAdmin(e: User) {
    const shallowCopyEmployee = window?.injector?.Deserialize?.instanceOf(User, e);
    shallowCopyEmployee.isCompanyAdmin = !shallowCopyEmployee.isCompanyAdmin;
    return this.updateEmployee(shallowCopyEmployee);
  }

  toggleEmployeeTemplateAccess(e: User) {
    const shallowCopyEmployee = window?.injector?.Deserialize?.instanceOf(User, e);
    shallowCopyEmployee.isTemplateAdmin = !shallowCopyEmployee.isTemplateAdmin;
    return this.updateEmployee(shallowCopyEmployee);
  }

  resendUserInvite(e: User) {
    return this.accountAPI.ResendCode(e.email, '');
  }

  public createEmployee(req: CreateUserRequest): Observable<HydratedUser> {
    return this.accountAPI.AdminCreateUser(req).pipe(
      withLatestFrom(this.employees$),
      map(([u, employees]) => {
        employees.push(u);
        this._employees.next(employees);
        return u;
      })
    );
  }

  removeEmployee(e: User): Observable<string> {
    return this.accountAPI.AdminDeleteUser(e).pipe(
      withLatestFrom(this.employees$),
      map(([s, employees]) => {
        const i = employees.findIndex(find => find.userId === e.userId);
        if (i > -1) {
          employees.splice(i, 1);
        }
        this._employees.next(employees);
        return s;
      })
    );
  }

  private updateEmployee(user: User): Observable<User> {
    return this.accountAPI.AdminUpdateUser(user).pipe(
      withLatestFrom(this.employees$),
      map(([employee, allEmployees]) => {
        const i = allEmployees.findIndex(find => find.userId === user.userId);
        if (i > -1) {
          allEmployees[i] = employee;
        }
        this._employees.next(allEmployees);
        return employee;
      })
    );
  }

  public fetchCompanyConfig(): void {
    this.companyId$.pipe(
      take(1),
      switchMap(companyId => this.companyAPI.GetCompanyConfiguration(String(companyId)))
    ).once((cc) => this.setCompanyConfig(cc));
  }

}
