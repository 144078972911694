import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { switchMap } from 'rxjs/operators';
import { ColumnOptionsViewModel } from '../../../../../viewModels/column-options-view-model';
import { combineLatest } from 'rxjs';

@Injectable()
export class TerpeneColumnOptionsFormViewModel extends BaseViewModel {

  constructor(
    public container: ColumnOptionsViewModel
  ) {
    super();
  }

  public collectiveTerpeneColumnConfigsAndKeys$ = window.types.terpeneSectionColumnConfigKeys$.pipe(
    switchMap(keys => {
      return this.container.makeColumnOptionFormFromKeys$(keys?.filter(k => k?.isCollectiveTerpeneKey()));
    })
  );

  public individualTerpeneColumnConfigsAndKeys$ = combineLatest([
    this.container.enabledTerpenes$,
    window.types.terpeneSectionColumnConfigKeys$
  ]).pipe(
    switchMap(([enabledTerpenes, keys]) => {
      const filteredKeys = keys
        ?.filter(keyType => keyType?.isIndividualTerpeneKey())
        ?.filter(keyType => {
          const individualTerpeneValues = enabledTerpenes?.map(et => et?.getSelectionValue()?.stripWhiteSpace());
          const keyTypeValue = keyType?.getSelectionValue();
          return individualTerpeneValues?.includes(keyTypeValue);
        });

      return this.container.makeColumnOptionFormFromKeys$(filteredKeys);
    })
  );

}
