import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HydratedSection } from '../models/menu/dto/hydrated-section';
import { Theme } from '../models/menu/dto/theme';
import { SectionColumnConfig } from '../models/menu/dto/section-column-config';
import { ColumnOptionsModalComponent } from '../views/menu/components/modals/column-options-modal/column-options-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector, NgZone } from '@angular/core';

export class ModalSectionColumnOptions {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    section: HydratedSection,
    theme: Theme,
    onClose: (columnConfig: Map<string, SectionColumnConfig>) => void
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(ColumnOptionsModalComponent, ModalUtils.columnOptionsModalOptions(injector));
      const compInst = modalRef.componentInstance as ColumnOptionsModalComponent;
      compInst.setSection(section);
      compInst.setTheme(theme);
      modalRef.result
        .then((columnConfig) => !!columnConfig && onClose(columnConfig))
        .catch(() => {});
    });
  }

}
