import { EditTemplateMenuViewModel } from '../edit-template-menu-view-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { EditSpotlightMenuViewModel } from '../../../../menu/components/edit-menu/edit-spotlight-menu/edit-spotlight-menu-view-model';
import { Injectable, Injector, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { LocationChangedUtils } from '../../../../../utils/location-changed-utils';
import { EditSpotlightSectionViewModel } from '../../../../menu/components/edit-menu/edit-spotlight-menu/edit-spotlight-section-view-model';

@Injectable()
export class EditTemplateSpotlightMenuViewModel extends EditTemplateMenuViewModel {

  /*
   * Need editSpotlightMenuViewModel and editSpotlightSectionViewModel injected here so that it's
   * scoped to this template. This allows child components that require these view models
   * to share the same instance.
   */
  constructor(
    protected editSpotlightMenuViewModel: EditSpotlightMenuViewModel,
    protected editSpotlightSectionViewModel: EditSpotlightSectionViewModel,
    templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(editSpotlightMenuViewModel, templateDomainModel, locationDomainModel, ngZone, ngbModal, injector);
    editSpotlightSectionViewModel.connectToTemplateMode(true);
    editSpotlightSectionViewModel.connectToAllowAutoSaving(false);
  }

  public readonly sectionTemplateId$ = this.templateDomainModel.activeSectionTemplateId$;
  showLiveView = this.editSpotlightMenuViewModel.showLiveView;

  override saveTemplate = (background: boolean) => {
    this.editSpotlightSectionViewModel.saveStaticSectionMenu(background);
  };

  loadSectionTemplate = (locationId: number, menuTemplateId: string, sectionTemplateId: string,) => {
    this.editSpotlightSectionViewModel.loadSectionTemplate(locationId, menuTemplateId, sectionTemplateId);
  };

  /* *********************** Local Threads of Execution *********************** */

  private fetchSectionTemplateWhenLocationChanges = LocationChangedUtils
    .loadSectionTemplate(this, this.locId$, this.menuTemplateId$, this.sectionTemplateId$, this.loadSectionTemplate);

  /* ************************************************************************** */

  override destroy() {
    super.destroy();
    this.templateDomainModel.clearActiveSectionTemplate();
  }

}
