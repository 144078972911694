import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { EditVariantContainer, TerpeneData } from '../../edit-variant-container';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { DisplayAttribute } from '../../../../../../../models/display/dto/display-attribute';
import { DistinctUtils } from '../../../../../../../utils/distinct-utils';
import { TerpeneUnitOfMeasure } from '../../../../../../../models/utils/dto/terpene-unit-of-measure-type';
import { exists } from '../../../../../../../functions/exists';
import { StringUtils } from '../../../../../../../utils/string-utils';

@Injectable()
export class EditVariantTerpenesViewModel extends BaseViewModel {

  constructor(
    public container: EditVariantContainer
  ) {
    super();
  }

  public terpeneUnitOfMeasureOptions$ = window.types.terpeneUnitOfMeasure$;

  public enabledTerpenes$ = this.container.enabledTerpenes$;
  public enabledTerpenesNamesData$ = this.container.enabledTerpenesNamesData$;
  public enabledTerpeneNames$ = this.container.enabledTerpeneNames$;
  public posSupportedEnabledTerpeneNames$ = this.container.posSupportedEnabledTerpeneNames$;
  public otherEnabledTerpeneNames$ = this.container.otherEnabledTerpeneNames$;
  public hasEnabledTerpenes$ = this.container.hasEnabledTerpenes$;
  public posSupportsPresentTerpenes$ = this.container.posSupportsPresentTerpenes$;
  public posSupportsTotalTerpene$ = this.container.posSupportsTotalTerpene$;
  public posSupportsTopTerpene$ = this.container.posSupportsTopTerpene$;
  public variant$ = this.container.variant$;
  public readonly companyUsesCannabinoidRange$ = this.container.companyUsesCannabinoidRange$;
  public readonly companyUsesTerpeneRange$ = this.container.companyUsesTerpeneRange$;
  public readonly displayAttribute$ = this.container.displayAttribute$;
  public readonly posSupportedTerpenes$ = this.container.posSupportedTerpenes$;

  private _selectedTUOM = new BehaviorSubject<TerpeneUnitOfMeasure>(TerpeneUnitOfMeasure.UNKNOWN);
  public selectedTUOM$ = this._selectedTUOM.pipe(distinctUntilChanged());

  private _updatedCompanyDA = new BehaviorSubject<DisplayAttribute>(null);
  public updatedCompanyDA$ = this._updatedCompanyDA.pipe(distinctUntilChanged());

  private _updatedLocationDA = new BehaviorSubject<DisplayAttribute>(null);
  public updatedLocationDA$ = this._updatedLocationDA.pipe(distinctUntilChanged());

  private distinctById = distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable);
  private initial = combineLatest([
    this.container.variant$.pipe(this.distinctById),
    this.container.variantLocationDisplayAttribute$.pipe(this.distinctById),
    this.container.variantCompanyDisplayAttribute$.pipe(this.distinctById)
  ]).subscribeWhileAlive({
    owner: this,
    next: ([variant, locationDA, companyDA]) => {
      this._selectedTUOM.next(variant?.terpeneUnitOfMeasure || TerpeneUnitOfMeasure.UNKNOWN);
      this._updatedCompanyDA.next(companyDA);
      this._updatedLocationDA.next(locationDA);
    }
  });

  public hasPosSupportedTerpenes$ = this.posSupportedEnabledTerpeneNames$.pipe(
    map(terpenes => terpenes?.length > 0)
  );
  public hasOtherEnabledTerpenes$ = this.otherEnabledTerpeneNames$.pipe(
    map(terpenes => terpenes?.length > 0)
  );

  public TUOMTooltip$ = this.container.syncTerpenesFromPOS$.pipe(
    map(isSync => {
      if (isSync) {
        return 'Editing the Terpene Unit of Measure is disabled when Sync Terpene from POS is enabled. '
          + 'This value will automatically be set based on the Default Terpene Unit of Measure. '
          + '(See Settings > Product > General)';
      } else {
        return 'The units of measure for terpenes such as Limoene of  within the product.';
      }
    })
  );

  public readonly functionInputsForDisableTerpeneInput$ = combineLatest([
    this.container.showTerpenePOSSyncBanner$,
    this.selectedTUOM$
  ]);

  public disableTerpeneInputs$: Observable<boolean> = this.functionInputsForDisableTerpeneInput$.pipe(
    map(([showPOSSyncBanner, selectedTUOM]) => {
      return showPOSSyncBanner
          || selectedTUOM === TerpeneUnitOfMeasure.NA
          || selectedTUOM === TerpeneUnitOfMeasure.UNKNOWN;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public disableLocationPresentTerpenesInput$ = combineLatest([
    this.disableTerpeneInputs$,
    this.posSupportsPresentTerpenes$,
    this.enabledTerpenes$
  ]).pipe(
    map(([disableTerpeneInputs, posSupportsPresentTerpenes, enabledTerpenes]) => {
      const disableInputsAndSupportsPresentTerpene = disableTerpeneInputs && posSupportsPresentTerpenes;
      const hasNoEnabledTerpenes = enabledTerpenes?.length <= 0;
      return disableInputsAndSupportsPresentTerpene || hasNoEnabledTerpenes;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public disableCompanyPresentTerpenesInput$ = combineLatest([
    this.container.isCompanyAdmin$,
    this.disableTerpeneInputs$,
    this.posSupportsPresentTerpenes$,
    this.enabledTerpenes$
  ]).pipe(
    map(([isCompanyAdmin, disableTerpeneInputs, posSupportsPresentTerpenes, enabledTerpenes]) => {
      return !isCompanyAdmin || enabledTerpenes?.length <= 0;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly disableCompanyLevelPresentTerpenesInputTooltip$ = combineLatest([
    this.container.isCompanyAdmin$,
    this.enabledTerpenes$
  ]).pipe(
    map(([isCompanyAdmin, enabledTerpenes]) => {
      if (!isCompanyAdmin) {
        return 'Only company admins can edit company wide properties.';
      }
      if (enabledTerpenes?.length <= 0) {
        return 'No terpenes are enabled. To enable terpenes, go to Settings > Products > Cannabinoids & Terpenes.';
      }
      // Always allow for company level present terpenes to be set by admins
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly disableLocationLevelPresentTerpenesInputTooltip$ = combineLatest([
    this.disableTerpeneInputs$,
    this.posSupportsPresentTerpenes$,
    this.container.inventoryProvider$,
    this.enabledTerpenes$
  ]).pipe(
    map(([disableInput, posSupportsPresentTerpenes, provider, enabledTerpenes]) => {
      if (enabledTerpenes?.length <= 0) {
        return 'No terpenes are enabled. To enable terpenes, go to Settings > Products > Cannabinoids & Terpenes.';
      }
      if (disableInput && posSupportsPresentTerpenes) {
        return `Sync Terpenes from POS is enabled. To set at a location level, manage present terpenes in ${provider}
        then perform a sync into BudSense.`;
      }
      return null;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public disableTopTerpeneInput$ = combineLatest([
    this.disableTerpeneInputs$,
    this.posSupportsTopTerpene$,
    this.enabledTerpenes$
  ]).pipe(
    map(([disableTerpeneInputs, posSupportsTopTerpene, enabledTerpenes]) => {
      const disableInputsAndSupportsTopTerpene = disableTerpeneInputs && posSupportsTopTerpene;
      const hasNoEnabledTerpenes = enabledTerpenes?.length <= 0;
      return disableInputsAndSupportsTopTerpene || hasNoEnabledTerpenes;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public disableTopTerpeneTooltip$ = this.enabledTerpenes$.pipe(
    map(enabledTerpenes => {
      if (enabledTerpenes?.length <= 0) {
        return 'No terpenes are enabled. To enable terpenes, go to Settings > Products > Cannabinoids & Terpenes.';
      }
      return null;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public companyPresentTerpenePillPlaceholders$ = combineLatest([
    this.container.variant$,
    this.companyUsesTerpeneRange$,
    this.updatedCompanyDA$,
    this.enabledTerpenesNamesData$
  ]).pipe(
    map(([variant, companyUsesTerpeneRange, companyDA, enabledTerpeneNames]) => {
      const ranged = variant?.useTerpeneRange || companyUsesTerpeneRange;
      return this.getPresentTerpenesFromDisplayAttribute(ranged, null, companyDA, enabledTerpeneNames);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public companyPresentTerpeneTextPlaceholder$ = combineLatest([
    this.updatedCompanyDA$,
    this.companyPresentTerpenePillPlaceholders$
  ]).pipe(
    map(([companyDA, presentTerpenePillPlaceholders]) => {
      const hasCompanySetPresentTerpenes = companyDA?.presentTerpenes?.length > 0;
      const hasPillPlaceholders = presentTerpenePillPlaceholders?.length > 0;
      switch (true) {
        case hasCompanySetPresentTerpenes:
          return 'Select present terpenes';
        case hasPillPlaceholders:
          return '(Auto-calculated)';
        default:
          return 'Enter override present terpenes';
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public locationPresentTerpenesFromDisplayAttributes$ = combineLatest([
    this.container.variant$,
    this.companyUsesTerpeneRange$,
    this.updatedLocationDA$,
    this.updatedCompanyDA$,
    this.enabledTerpenesNamesData$
  ]).pipe(
    map(([variant, companyUsesTerpeneRange, locationDA, companyDA, enabledTerpeneNames]) => {
      const ranged = variant?.useTerpeneRange || companyUsesTerpeneRange;
      return this.getPresentTerpenesFromDisplayAttribute(
        ranged,
        locationDA,
        companyDA,
        enabledTerpeneNames
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public locationPresentTerpenePillPlaceholders$ = combineLatest([
    this.updatedCompanyDA$,
    this.enabledTerpenesNamesData$,
    this.locationPresentTerpenesFromDisplayAttributes$,
    this.companyPresentTerpenePillPlaceholders$
  ]).pipe(
    map(([companyDA, enabledTerpenes, locationPresentTerpenesFromDA, companyPresentTerpenePlaceholders]) => {
      const hasCompanySetPresentTerpenes = companyDA?.presentTerpenes?.length > 0;
      const hasCompanyAutoCalculatedPlaceholders = companyPresentTerpenePlaceholders?.length > 0;
      const companySetTerpenes = companyDA?.presentTerpenes?.shallowCopy();
      switch (true) {
        case hasCompanySetPresentTerpenes:
          return companySetTerpenes;
        case hasCompanyAutoCalculatedPlaceholders:
        case locationPresentTerpenesFromDA?.length > 0:
          return locationPresentTerpenesFromDA;
        default:
          return null;
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public locationPresentTerpeneTextPlaceholder$ = combineLatest([
    this.updatedLocationDA$,
    this.updatedCompanyDA$,
    this.locationPresentTerpenePillPlaceholders$
  ]).pipe(
    map(([locationDA, companyDA, locationPresentTerpenePillPlaceholders]) => {
      const hasLocationSetPresentTerpenes = locationDA?.presentTerpenes?.length > 0;
      const hasCompanySetPresentSecondaryCannabinoids = companyDA?.presentTerpenes?.length > 0;
      const hasLocationPillPlaceholders = locationPresentTerpenePillPlaceholders?.length > 0;
      switch (true) {
        case hasLocationSetPresentTerpenes:
          return 'Select present terpenes';
        case hasLocationPillPlaceholders && hasCompanySetPresentSecondaryCannabinoids:
          return '(Company Default)';
        case hasLocationPillPlaceholders:
          return '(Auto-calculated)';
        default:
          return 'Select present terpenes';
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public companyTopTerpenePillPlaceholder$ = combineLatest([
    this.variant$,
    this.enabledTerpeneNames$,
    this.updatedCompanyDA$
  ]).pipe(
    map(([variant, enabledTerpenes, companyDisplayAttributes]) => {
      const topTerpene = variant?.getAutoCalculatedTopTerpene(enabledTerpenes, [companyDisplayAttributes]);
      return exists(topTerpene) ? [topTerpene] : null;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public companyTopTerpeneTextPlaceholder$: Observable<string | null> = combineLatest([
    this.companyTopTerpenePillPlaceholder$,
    this.updatedCompanyDA$
  ]).pipe(
    map(([topTerpene, companyDisplayAttributes]) => {
      if (exists(companyDisplayAttributes.topTerpene)) {
        return '(Company Default)';
      }
      if (exists(topTerpene?.firstOrNull())) {
        return '(Auto-calculated)';
      }
      return 'Select top terpene to override';
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public locationTopTerpenePillPlaceholder$ = combineLatest([
    this.variant$,
    this.enabledTerpeneNames$,
    this.updatedLocationDA$,
    this.updatedCompanyDA$,
  ]).pipe(
    map(([variant, enabledTerpenes, locationDisplayAttribute, companyDisplayAttribute]) => {
      const locationTopTerpeneExplicitlySet = exists(locationDisplayAttribute?.topTerpene);
      const companyTopTerpeneExplicitlySet = exists(companyDisplayAttribute?.topTerpene);
      const topTerpene = variant.getAutoCalculatedTopTerpene(
        enabledTerpenes,
        [locationDisplayAttribute, companyDisplayAttribute]
      );
      switch (true) {
        case locationTopTerpeneExplicitlySet:
          return [locationDisplayAttribute?.topTerpene];
        case companyTopTerpeneExplicitlySet:
          return [companyDisplayAttribute?.topTerpene];
        case exists(topTerpene):
          return [topTerpene];
        default:
          return null;
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public locationTopTerpeneTextPlaceholder$: Observable<string | null> = combineLatest([
    this.variant$,
    this.enabledTerpeneNames$,
    this.updatedLocationDA$,
    this.updatedCompanyDA$,
  ]).pipe(
    map(([variant, enabledTerpenes, locationDisplayAttribute, companyDisplayAttribute]) => {
      const locationTopTerpeneExplicitlySet = exists(locationDisplayAttribute?.topTerpene);
      const companyTopTerpeneExplicitlySet = exists(companyDisplayAttribute?.topTerpene);
      const topTerpene = variant.getAutoCalculatedTopTerpene(
        enabledTerpenes,
        [locationDisplayAttribute, companyDisplayAttribute]
      );
      switch (true) {
        case locationTopTerpeneExplicitlySet:
          return '(Location Default)';
        case companyTopTerpeneExplicitlySet:
          return '(Company Default)';
        case exists(topTerpene):
          return '(Auto-calculated)';
        default:
          return 'Select top terpene to override';
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly terpenePillBackgroundColors$ = combineLatest([
    this.enabledTerpeneNames$,
    window.types.terpenes$
  ]).pipe(
    map(([enabledTerpenes, terpenes]) => {
      const terpeneNames = terpenes?.map(c => c?.getSelectionTitle());
      const buildPillBackgroundColorObject = (pillBackgroundColors: any, terpeneName: string) => {
        pillBackgroundColors[terpeneName] = enabledTerpenes?.includes(terpeneName) ? '#222222' : '#FFD74B';
        return pillBackgroundColors;
      };
      return terpeneNames.reduce(buildPillBackgroundColorObject, {});
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly terpenePillTextColors$ =  combineLatest([
    this.enabledTerpeneNames$,
    window.types.terpenes$
  ]).pipe(
    map(([enabledTerpenes, terpenes]) => {
      const terpeneNames = terpenes?.map(c => c?.getSelectionTitle());
      const buildPillTextColorObject = (pillTextColors: any, terpeneName: string) => {
        pillTextColors[terpeneName] = enabledTerpenes?.includes(terpeneName) ? '#FFFFFF' : '#222222';
        return pillTextColors;
      };
      return terpeneNames.reduce(buildPillTextColorObject, {});
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly terpenePillTooltip$ = combineLatest([
    this.enabledTerpeneNames$,
    window.types.terpenes$
  ]).pipe(
    map(([enabledTerpenes, terpenes]) => {
      const terpeneNames = terpenes?.map(c => c?.getSelectionTitle());
      const buildTooltipObject = (pillTooltips: any, terpeneName: string) => {
        pillTooltips[terpeneName] = enabledTerpenes?.includes(terpeneName)
          ? ''
          : `${terpeneName} is disabled in company terpenes. This will not appear on menus.`;
        return pillTooltips;
      };
      return terpeneNames.reduce(buildTooltipObject, {});
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public terpenesWithPOSValueNotEnabledInBudsense$: Observable<string[]> = combineLatest([
    this.posSupportedTerpenes$,
    this.displayAttribute$,
    this.enabledTerpeneNames$,
    this.selectedTUOM$
  ]).pipe(
    map(([posSupportedTerpenes, displayAttribute, enabledTerpenes, selectedTUOM]) => {
      const terpenesWithPOSValueNotEnabledInBudsense: string[] = [];
      posSupportedTerpenes.forEach(terpene => {
        const value = displayAttribute[StringUtils.toCamelCase(terpene)];
        if (value && !enabledTerpenes.includes(terpene)) {
          terpenesWithPOSValueNotEnabledInBudsense.push(`${terpene} - ${value}${selectedTUOM}`);
        }
      });
      return terpenesWithPOSValueNotEnabledInBudsense;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public tuomSelectionChanged = (tuom: TerpeneUnitOfMeasure) => {
    this._selectedTUOM.next(tuom);
  };

  public companyDAUpdated(value: string, accessor: string) {
    this.updatedCompanyDA$.once(companyDA => {
      const companyDACopy = Object.assign(new DisplayAttribute(), companyDA);
      companyDACopy[accessor] = value;
      this._updatedCompanyDA.next(companyDACopy);
    });
  }

  public locationDAUpdated(value: string, accessor: string) {
    this.updatedLocationDA$.once(locationDA => {
      const locationDACopy = Object.assign(new DisplayAttribute(), locationDA);
      locationDACopy[accessor] = value;
      this._updatedLocationDA.next(locationDACopy);
    });
  }

  public companyTopTerpeneUpdated(value: string[]) {
    this.companyDAUpdated(value?.firstOrNull(), 'topTerpene');
  }

  public locationTopTerpeneUpdated(value: string[]) {
    this.locationDAUpdated(value?.firstOrNull(), 'topTerpene');
  }

  public companyDAForTerpenePresenceUpdated(
    value: string,
    accessor: string,
    minAccessor: string,
    maxAccessor: string
  ) {
    this.updatedCompanyDA$.once(companyDA => {
      const companyDACopy = Object.assign(new DisplayAttribute(), companyDA);
      companyDACopy[accessor] = value;
      companyDACopy[minAccessor] = value;
      companyDACopy[maxAccessor] = value;
      this._updatedCompanyDA.next(companyDACopy);
    });
  }

  public locationDAForTerpenePresenceUpdated(
    value: string,
    accessor: string,
    minAccessor: string,
    maxAccessor: string
  ) {
    this.updatedLocationDA$.once(locationDA => {
      const locationDACopy = Object.assign(new DisplayAttribute(), locationDA);
      locationDACopy[accessor] = value;
      locationDACopy[minAccessor] = value;
      locationDACopy[maxAccessor] = value;
      this._updatedLocationDA.next(locationDACopy);
    });
  }

  getPresentTerpenesFromDisplayAttribute(
    useRange: boolean,
    locationDA: DisplayAttribute,
    companyDA: DisplayAttribute,
    enabledTerpenes: TerpeneData[]
  ): string[] {
    return enabledTerpenes
      ?.filter(terpeneHelper => {
        return useRange
          ? this.hasPresentRangedTerpeneOnDisplayAttribute(terpeneHelper, locationDA, companyDA)
          : this.hasPresentNonRangedTerpeneOnDisplayAttribute(terpeneHelper, locationDA, companyDA);
      })
      ?.map(terpeneHelper => terpeneHelper?.terpeneName);
  }

  private hasPresentRangedTerpeneOnDisplayAttribute(
    terpeneHelper: TerpeneData,
    locationDA: DisplayAttribute,
    companyDA: DisplayAttribute,
  ): boolean {
    const minAccessor = `min${terpeneHelper?.pascalCased}`;
    const maxAccessor = `max${terpeneHelper?.pascalCased}`;
    const hasLocationValue = exists(locationDA?.[minAccessor]) || exists(locationDA?.[maxAccessor]);
    if (hasLocationValue) {
      return this.hasNumericValueGreaterThanZero(locationDA?.[minAccessor])
          || this.hasNumericValueGreaterThanZero(locationDA?.[maxAccessor]);
    } else {
      return this.hasNumericValueGreaterThanZero(companyDA?.[minAccessor])
          || this.hasNumericValueGreaterThanZero(companyDA?.[maxAccessor]);
    }
  }

  private hasPresentNonRangedTerpeneOnDisplayAttribute(
    terpeneHelper: TerpeneData,
    locationDA: DisplayAttribute,
    companyDA: DisplayAttribute,
  ): boolean {
    const accessor = terpeneHelper?.camelCased;
    return exists(locationDA?.[accessor])
      ? this.hasNumericValueGreaterThanZero(locationDA?.[accessor])
      : this.hasNumericValueGreaterThanZero(companyDA?.[accessor]);
  }

  private hasNumericValueGreaterThanZero(terpeneVal: string): boolean {
    return exists(terpeneVal) && parseFloat(terpeneVal) > 0;
  }

  trackByTerpeneName(index: number, terpeneData: TerpeneData): string {
    return terpeneData?.terpeneName;
  }

}
