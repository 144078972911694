<div class="badge-picker-container">
  <mat-accordion multi [displayMode]="'flat'">
    <div class="sticky-header">
      <div *ngIf="maximumNumberOfBadges" class="f16px bs-bold">{{ titleText$ | async }}</div>
      <app-search-with-look-ahead
        [style.display]="'block'"
        [style.padding-top.rem]="0.5"
        [placeHolder]="'Search to filter results'"
        [lookAheadItems]="viewModel.allBadges$ | async"
        [searchTheseProperties]="['name']"
        [disablePopper]="true"
        [nLookAheadItems]="10000"
        (searchTextAndLookAheadHits)="viewModel.connectToSearchBadges($event)">
      </app-search-with-look-ahead>
    </div>
    <!-- Loading -->
    <ng-container *rxIf="(viewModel.searchedBadgeSectionList$ | async) === null">
      <mat-expansion-panel #panel class="mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="badge-section-title">Your Badges</mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="!asListItems" fxLayout="row wrap" [style.gap.rem]="1">
          <lib-loading-shimmer
            *ngFor="let number of [0, 1, 2, 3, 4, 6, 7]"
            [width]="'2.5rem'"
            [height]="'2.5rem'"
            [shape]="'rect'"
            [borderRadius]="'0.625rem'">
          </lib-loading-shimmer>
        </div>
        <div *ngIf="asListItems" fxLayout="column">
          <ng-container *ngFor="let number of [0, 1, 2]">
            <div class="list-loading">
              <lib-loading-shimmer [width]="'3rem'" [height]="'3rem'" [shape]="'rect'" [borderRadius]="'0.625rem'">
              </lib-loading-shimmer>
            </div>
            <hr class="no-margin" />
          </ng-container>
        </div>
      </mat-expansion-panel>
      <hr class="mt-16px" />
    </ng-container>
    <!-- Badges -->
    <ng-container
      *rxFor="
        let badgeSection of viewModel.searchedBadgeSectionList$ | async;
        index as i;
        trackBy: viewModel.badgeSectionTrackBy
      ">
      <mat-expansion-panel
        #panel
        class="mat-elevation-z0"
        [expanded]="
          badgeSection.badges?.length > 0 ||
          ((viewModel.searchText$ | async)?.length > 0 && badgeSection.subSectionBadges?.size > 0)
        ">
        <mat-expansion-panel-header>
          <mat-panel-title class="badge-section-title">
            {{ badgeSection.title | titlecase }}
            ({{ badgeSection | countBadges }})
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="!asListItems" fxLayout="row wrap" [style.gap.rem]="1">
          <app-badge-within-picker
            *rxFor="let badge of badgeSection.badges; trackBy: viewModel.badgeTrackBy"
            [addedBadgeIds]="addedBadgeIds"
            [asListItems]="asListItems"
            [badge]="badge"
            [maximumNumberOfBadges]="maximumNumberOfBadges"
            [preselectedBadgeIds]="preselectedBadgeIds"
            (selected)="selected.emit($event)">
          </app-badge-within-picker>
          <div class="badge-subsection">
            <ng-container *rxFor="let subSection of badgeSection.subSectionBadges | keyvalue">
              <mat-panel-title *ngIf="subSection.value.length" class="badge-subsection-title">
                {{ subSection.key | titlecase }}
              </mat-panel-title>
              <div fxLayout="row wrap" fxLayoutGap="1rem">
                <app-badge-within-picker
                  *rxFor="let badge of subSection.value"
                  [addedBadgeIds]="addedBadgeIds"
                  [asListItems]="asListItems"
                  [badge]="badge"
                  [maximumNumberOfBadges]="maximumNumberOfBadges"
                  [preselectedBadgeIds]="preselectedBadgeIds"
                  (selected)="selected.emit($event)">
                </app-badge-within-picker>
              </div>
            </ng-container>
          </div>
        </div>
        <div *ngIf="asListItems" fxLayout="column">
          <app-variant-badge
            *rxFor="let badge of badgeSection.badges; let last = last"
            appThrottleClick
            matRipple
            class="badge-as-list-variant"
            [addedBadgeIds]="addedBadgeIds"
            [includeTopDivider]="false"
            [includeBottomDivider]="!last"
            (throttleClick)="selected.emit(badge)"
            [showPlusSign]="true"
            [badge]="badge">
          </app-variant-badge>
          <ng-container *rxFor="let subSection of badgeSection.subSectionBadges | keyvalue">
            <mat-panel-title class="badge-subsection-title">
              {{ subSection.key | titlecase }}
            </mat-panel-title>
            <app-variant-badge
              *rxFor="let badge of subSection.value; let last = last"
              appThrottleClick
              matRipple
              class="badge-as-list-variant"
              [addedBadgeIds]="addedBadgeIds"
              [includeTopDivider]="false"
              [includeBottomDivider]="last"
              (throttleClick)="selected.emit(badge)"
              [showPlusSign]="true"
              [badge]="badge">
            </app-variant-badge>
          </ng-container>
        </div>
      </mat-expansion-panel>
      <hr class="no-margin" />
    </ng-container>
    <!-- Empty state -->
    <ng-container *ngIf="(viewModel.searchedBadgeSectionList$ | async)?.length === 0">
      <p class="mt-16px">No results for {{ viewModel.searchText$ | async }}</p>
    </ng-container>
  </mat-accordion>
</div>
