import { Injectable } from '@angular/core';
import { StackPrintType } from '../../../../../../models/automation/enum/card-stack-print-type.enum';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BulkPrintJob } from '../../../../../../models/automation/bulk-print-job';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { HasChildIds } from '../../../../../../models/protocols/has-child-ids';
import { SearchUtils } from '../../../../../../utils/search-utils';
import { CardStackPrintConfig } from '../../../../../../models/automation/card-stack-print-config';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { StringUtils } from '../../../../../../utils/string-utils';
import { SortUtils } from '../../../../../../utils/sort-utils';
import { iiif } from '../../../../../../utils/observable.extensions';
import { SmartPrintUtils } from '../../../../../../utils/smart-print-utils';

@Injectable()
export class StackManualBulkPrintJobViewModel extends BaseViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    private menuDomainModel: MenuDomainModel,
    private productDomainModel: ProductDomainModel
  ) {
    super();
  }

  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  public readonly currentLocationStackedMenus$ = this.menuDomainModel.currentLocationStackedMenus$;

  private readonly _stackPrintType = new BehaviorSubject<StackPrintType>(null);
  public readonly stackPrintType$ = this._stackPrintType as Observable<StackPrintType>;
  connectToStackPrintType = (stackPrintType: StackPrintType) => this._stackPrintType.next(stackPrintType);

  public readonly isCardStack$ = this.stackPrintType$.pipe(
    map(stackPrintType => SmartPrintUtils.isCardJob(stackPrintType))
  );

  public readonly isShelfTalkerStack$ = this.stackPrintType$.pipe(
    map(stackPrintType => SmartPrintUtils.isShelfTalkerJob(stackPrintType))
  );

  public readonly isCardOrShelfTalkerStack$ = combineLatest([
    this.isCardStack$,
    this.isShelfTalkerStack$
  ]).pipe(
    map(([isCard, isShelf]) => isCard || isShelf)
  );

  public readonly isLabelStack$ = this.stackPrintType$.pipe(
    map(stackPrintType => SmartPrintUtils.isLabelJob(stackPrintType))
  );

  private readonly _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public readonly job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => this._job.next(job);

  private readonly _mergeKey = new BehaviorSubject<string | null>(null);
  public readonly mergeKey$ = this._mergeKey as Observable<string | null>;
  connectToMergeKey = (mergeKey: string) => this._mergeKey.next(mergeKey);

  private readonly _placeholder = new BehaviorSubject<string | null>(null);
  public readonly placeholder$ = this._placeholder as Observable<string | null>;
  connectToPlaceholder = (placeholder: string) => this._placeholder.next(placeholder);

  private readonly _selectedStackMenuIds = new BehaviorSubject<string[] | null>(null);
  public readonly selectedStackMenuIds$ = this._selectedStackMenuIds as Observable<string[] | null>;
  connectToSelectedStackMenuIds = (menuIds: string[]) => this._selectedStackMenuIds.next(menuIds);

  private readonly _viewOnly = new BehaviorSubject<boolean | null>(null);
  public readonly viewOnly$ = this._viewOnly as Observable<boolean | null>;
  connectToViewOnly = (viewOnly: boolean) => this._viewOnly.next(viewOnly);

  public readonly selectedStackMenus$ = combineLatest([
    this.currentLocationStackedMenus$,
    this.selectedStackMenuIds$
  ]).pipe(
    map(([menus, selectedIds]) => menus?.filter(m => selectedIds?.includes(m?.id))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly _selectedStackMenuId = new BehaviorSubject<string | null>(null);
  public readonly selectedStackMenuId$ = combineLatest([
    this.selectedStackMenus$,
    this._selectedStackMenuId,
  ]).pipe(
    map(([menus, selectedId]) => selectedId || menus?.firstOrNull()?.id),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToSelectedStackMenuId = (menuId: string) => this._selectedStackMenuId.next(menuId);

  public readonly selectedStackMenu$ = combineLatest([
    this.currentLocationStackedMenus$,
    this.selectedStackMenuId$
  ]).pipe(
    map(([menus, selectedId]) => menus?.find(m => selectedId === m?.id)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly isShelfTalkerCardStack$ = this.selectedStackMenu$.pipe(
    map(menu => menu?.isShelfTalkerMenu())
  );

  public readonly allSectionsWithinSelectedShelfTalker$ = this.selectedStackMenu$.pipe(
    map(menu => menu?.getSectionsBasedOnMenuType())
  );

  public readonly allVariantsWithinSelectedStack$ =  combineLatest([
    this.locationConfig$,
    this.selectedStackMenu$,
    this.productDomainModel.currentLocationVariants$
  ]).pipe(
    map(([locationConfig, menu, currentLocationVariants]) => {
      const stack = menu?.getSectionsBasedOnMenuType()?.firstOrNull() as HydratedSection;
      const variantIds = stack?.enabledVariantIds || [];
      const products = stack?.products;
      const priceFormat = locationConfig?.priceFormat;
      const variants = variantIds
        ?.map(id => currentLocationVariants?.find(v => v?.id === id))
        ?.filterNulls();
      const scopedVariants = stack?.getScopedVisibleVariants(products, variants, menu, priceFormat, false);
      return SortUtils.sortVariantsBySectionSortOptions(scopedVariants, stack);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly allItemsWithinSelectedStack$ = iiif(
    this.isShelfTalkerCardStack$,
    this.allSectionsWithinSelectedShelfTalker$,
    this.allVariantsWithinSelectedStack$
  );

  public readonly selectedShelfTalkerSearchableSections$ = this.allSectionsWithinSelectedShelfTalker$.pipe(
    map(sections => sections?.map(s => SearchUtils.getSimpleSearchableSection(s))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly selectedStackSearchableVariants$ = this.allVariantsWithinSelectedStack$.pipe(
    map(variants => variants?.map(v => SearchUtils.getSimpleSearchableVariant(v))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly searchThrough$ = iiif(
    this.isShelfTalkerCardStack$,
    this.selectedShelfTalkerSearchableSections$,
    this.selectedStackSearchableVariants$
  );

  private readonly _searchTextAndHits = new BehaviorSubject<[string, any[]]>([null, []]);
  public readonly searchTextAndHits$ = this._searchTextAndHits as Observable<[string, any[]]>;
  connectToSearchTextAndHits = (x: [string, any[]]) => this._searchTextAndHits.next(x || [null, []]);

  public readonly visibleSectionsInList$ = combineLatest([
    this.allSectionsWithinSelectedShelfTalker$,
    this.searchTextAndHits$
  ]).pipe(
    map(([sections, [searchText, hits]]) => {
      return (searchText?.length >= 2)
        ? hits?.map(hit => sections?.find(s => s?.id === hit?.id))?.filterNulls()
        : sections;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly visibleVariantsInList$: Observable<any[] | null> = combineLatest([
    this.allVariantsWithinSelectedStack$,
    this.searchTextAndHits$
  ]).pipe(
    map(([variants, [searchText, hits]]) => {
      return (searchText?.length >= 2)
        ? hits?.map(hit => variants?.find(v => v?.id === hit?.id))?.filterNulls()
        : variants;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly visibleVariantIdsInList$ = this.visibleVariantsInList$.pipe(
    map(variants => variants?.map(v => v?.id)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly visibleSectionsInListAsSelection$ = this.visibleSectionsInList$.pipe(
    map(sections => {
      return new class implements HasChildIds {

        getId = (): string => 'not relevant';
        getChildIds = (): string[] => sections?.map(s => s?.id);

      };
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly hasVisibleSections$ = this.visibleSectionsInList$.pipe(
    map(sections => sections?.length > 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly visibleVariantsInListAsSelection$ = this.visibleVariantsInList$.pipe(
    map(variants => {
      return new class implements HasChildIds {

        getId = (): string => 'not relevant';
        getChildIds = (): string[] => variants?.map(v => v?.id);

      };
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly hasVisibleVariants$ = this.visibleVariantsInList$.pipe(
    map(variants => variants?.length > 0),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly emptySearchText$ = this.isShelfTalkerCardStack$.pipe(
    map(isShelfTalker => isShelfTalker ? 'No cards found' : 'No variants found'),
  );

  /* **************************** Selected Variants **************************** */

  private readonly _selectedItemsMap = new BehaviorSubject<Map<string, string[] | null>>(new Map());
  public readonly selectedItemsMap$ = this._selectedItemsMap as Observable<Map<string, string[] | null>>;
  public readonly stackSelectedItemIds$ = combineLatest([
    this.selectedItemsMap$,
    this.selectedStackMenuId$,
  ]).pipe(
    map(([selectedVariantIds, menuId]) => selectedVariantIds?.get(menuId) || []),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToSelectedItemIds = (menuId: string, itemIds: string[]) => {
    this.selectedItemsMap$.once(selectedVariantMap => {
      const updatedSelections = selectedVariantMap?.shallowCopy() || new Map();
      !itemIds?.length
        ? updatedSelections.delete(menuId)
        : updatedSelections.set(menuId, itemIds);
      this._selectedItemsMap.next(updatedSelections);
    });
  };

  bulkAddSelections = (itemIds: string[]) => {
    combineLatest([
      this.selectedStackMenuId$,
      this.selectedItemsMap$
    ]).once(([menuId, selectedItemMap]) => {
      const updatedSelections = selectedItemMap?.get(menuId)?.shallowCopy() || [];
      itemIds?.forEach(itemId => {
        if (!updatedSelections?.includes(itemId)) updatedSelections.push(itemId);
      });
      this.connectToSelectedItemIds(menuId, updatedSelections);
    });
  };

  bulkRemoveSelections = (itemIds: string[]) => {
    combineLatest([
      this.selectedStackMenuId$,
      this.selectedItemsMap$
    ]).once(([menuId, selectedItemMap]) => {
      const updatedSelections = selectedItemMap?.get(menuId)?.shallowCopy() || [];
      itemIds?.forEach(itemId => {
        const index = updatedSelections?.indexOf(itemId);
        if (index > -1) updatedSelections.splice(index, 1);
      });
      this.connectToSelectedItemIds(menuId, updatedSelections);
    });
  };

  bulkAddOrRemoveSelections = (itemIds: string[]) => {
    combineLatest([
      this.selectedStackMenuId$,
      this.selectedItemsMap$
    ]).once(([menuId, selectedItemMap]) => {
      const updatedSelections = selectedItemMap?.get(menuId)?.shallowCopy() || [];
      itemIds?.forEach(itemId => {
        const index = updatedSelections?.indexOf(itemId);
        if (index > -1) {
          updatedSelections.splice(index, 1);
        } else {
          updatedSelections.push(itemId);
        }
      });
      this.connectToSelectedItemIds(menuId, updatedSelections);
    });
  };

  public readonly stackNSelectedSectionIds$ = combineLatest([
    this.stackSelectedItemIds$,
    this.visibleSectionsInList$
  ]).pipe(
    map(([selectedSectionIds, visibleSections]) => {
      return selectedSectionIds?.filter(id => visibleSections?.find(s => s?.id === id))?.length || 0;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly stackNSelectedVariantIds$ = combineLatest([
    this.stackSelectedItemIds$,
    this.visibleVariantsInList$
  ]).pipe(
    map(([selectedVariantIds, visibleVariants]) => {
      return selectedVariantIds?.filter(id => visibleVariants?.find(v => v?.id === id))?.length || 0;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly entireJobSelectedVariantIds$ = this.selectedItemsMap$.pipe(
    map(selectedMap => [...(selectedMap?.values() || [])].reduce((acc, val) => acc.concat(val || []), [])),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly validSelectedMenuIds$ = this.selectedItemsMap$.pipe(
    map(selectedMap => [...(selectedMap?.keys() || [])].filter(id => selectedMap?.get(id)?.length > 0)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /* **************************** Selected Variant Counts **************************** */

  /**
   * A map of menuStackId to a map of variantId to count.
   */
  private _eachMenusVariantLabelCountMap = new BehaviorSubject<Map<string, Map<string, number>>>(new Map());
  public eachMenusVariantLabelCountMap$ = this._eachMenusVariantLabelCountMap.pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToEachMenusVariantLabelCountMap = (x: Map<string, Map<string, number>>) => {
    this._eachMenusVariantLabelCountMap.next(x);
  };

  public readonly selectedMenuVariantLabelCountMap$ = combineLatest([
    this.selectedStackMenuId$,
    this.eachMenusVariantLabelCountMap$,
  ]).pipe(
    map(([menuStackId, eachMenusVariantLabelCountMap]) => {
      const mapping = eachMenusVariantLabelCountMap?.get(menuStackId);
      this.connectToSelectedItemIds(menuStackId, [...mapping?.keys() || []]?.unique());
      return mapping;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public updateVariantCountMap = ([variantId, count]: [string, number]): void => {
    combineLatest([
      this.selectedStackMenuId$,
      this.eachMenusVariantLabelCountMap$
    ]).once(([menuId, eachMenusVariantMap]) => {
      const updatedEachMenusVariantLabelCountMap = eachMenusVariantMap?.shallowCopy();
      const updatedMenuVariantMap = updatedEachMenusVariantLabelCountMap
        ?.get(menuId)
        ?.shallowCopy() || new Map<string, number>();
      !count
        ? updatedMenuVariantMap.delete(variantId)
        : updatedMenuVariantMap.set(variantId, count);
      updatedEachMenusVariantLabelCountMap.set(menuId, updatedMenuVariantMap);
      this.connectToEachMenusVariantLabelCountMap(updatedEachMenusVariantLabelCountMap);
    });
  };

  public bulkAddVariantsToVariantLabelCountMap = (variantIds: string[]): void => {
    combineLatest([
      this.selectedStackMenuId$,
      this.eachMenusVariantLabelCountMap$
    ]).once(([menuId, eachMenusVariantMap]) => {
      const updatedEachMenusVariantLabelCountMap = eachMenusVariantMap?.shallowCopy();
      const updatedMenuVariantMap = updatedEachMenusVariantLabelCountMap
        ?.get(menuId)
        ?.shallowCopy() || new Map<string, number>();
      variantIds.forEach(id => {
        if (!updatedMenuVariantMap.get(id)) updatedMenuVariantMap.set(id, 1);
      });
      updatedEachMenusVariantLabelCountMap.set(menuId, updatedMenuVariantMap);
      this.connectToEachMenusVariantLabelCountMap(updatedEachMenusVariantLabelCountMap);
    });
  };

  public bulkRemoveVariantsFromVariantLabelCountMap = (variantIds: string[]): void => {
    combineLatest([
      this.selectedStackMenuId$,
      this.eachMenusVariantLabelCountMap$
    ]).once(([menuId, eachMenusVariantMap]) => {
      const updatedEachMenusVariantLabelCountMap = eachMenusVariantMap?.shallowCopy();
      const updatedMenuVariantMap = updatedEachMenusVariantLabelCountMap
        ?.get(menuId)
        ?.shallowCopy() || new Map<string, number>();
      variantIds.forEach(id => updatedMenuVariantMap.delete(id));
      updatedEachMenusVariantLabelCountMap.set(menuId, updatedMenuVariantMap);
      this.connectToEachMenusVariantLabelCountMap(updatedEachMenusVariantLabelCountMap);
    });
  };

  /* ******************************* Build Data ******************************* */

  public readonly stackPrintConfigMap$ = combineLatest([
    this.stackPrintType$,
    this.selectedItemsMap$,
    this.eachMenusVariantLabelCountMap$
  ]).pipe(
    map(([type, selectedVariantMap, eachMenusVariantLabelCountMap]) => {
      const stackPrintConfigMap = new Map<string, CardStackPrintConfig>();
      selectedVariantMap?.forEach((variantIds, menuId) => {
        const printConfig = new CardStackPrintConfig();
        printConfig.printType = type;
        printConfig.variantIds = variantIds;
        printConfig.variantCardCountMap = eachMenusVariantLabelCountMap?.get(menuId);
        stackPrintConfigMap.set(menuId, printConfig);
      });
      return stackPrintConfigMap;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly typeOfStack$ = this.stackPrintType$.pipe(
    map(stackPrintType => StringUtils.splitPascalCase(stackPrintType)?.firstOrNull()),
  );

}
