import { BaseViewModel } from '../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { Label } from '../../../models/shared/label';
import { LabelDomainModel } from '../../../domainModels/label-domain-model';
import { MenuLabel } from '../../../models/enum/dto/menu-label';
import { SortUtils } from '../../../utils/sort-utils';

@Injectable()
export class LabelPickerViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private locationDomainModel: LocationDomainModel,
    private labelDomainModel: LabelDomainModel
  ) {
    super();
  }

  public readonly companyId$ = this.companyDomainModel.companyId$;

  private _isCompany = new BehaviorSubject<boolean>(null);
  public isCompany$ = this._isCompany as Observable<boolean>;
  public connectToIsCompany = (isCompany: boolean) => this._isCompany.next(isCompany);

  public readonly locationCustomLabels$: Observable<Label[]> = this.labelDomainModel.locationCustomLabels$;
  private readonly locationSystemLabels$: Observable<Label[]> = this.labelDomainModel.locationSystemLabels$;
  public readonly companyCustomLabels$: Observable<Label[]> = this.labelDomainModel.companyCustomLabels$;
  private readonly companySystemLabels$: Observable<Label[]> = this.labelDomainModel.companySystemLabels$;

  public readonly removeFromExistingOnSyncData$ = combineLatest([this.companyCustomLabels$, this.companyId$]);

  public readonly labelPool$: Observable<Label[]> = combineLatest([
    this.isCompany$,
    this.locationCustomLabels$,
    this.locationSystemLabels$,
    this.companyCustomLabels$,
    this.companySystemLabels$,
  ]).pipe(
    map(([isCompany, locationCustomLabels, locationSystemLabels, companyCustomLabels, companySystemLabels]) => {
      const systemLabelPool: Label[] = isCompany ? companySystemLabels : locationSystemLabels;
      let customLabelPool: Label[];
      if (isCompany) {
        // We want to show location equivalents if they are available
        customLabelPool = companyCustomLabels?.map(companyCustomLabel => {
          const locationCustomLabel = locationCustomLabels?.find(l => l.id === companyCustomLabel.id);
          return locationCustomLabel || companyCustomLabel;
        });
      } else {
        customLabelPool = locationCustomLabels?.shallowCopy();
      }
      const featuredLabel = systemLabelPool?.find(l => l?.id === MenuLabel.Featured);
      if (!customLabelPool?.includes(featuredLabel) && !!featuredLabel) {
        customLabelPool.push(featuredLabel);
      }
      return customLabelPool?.sort(SortUtils.sortLabelsForLabelPicker);
    })
  );

  public posLabelPool$: Observable<Label[]> = this.labelDomainModel.companyPOSLabels$;

  public showPOSLabels$ = combineLatest([
    this.isCompany$,
    this.posLabelPool$,
  ]).pipe(
    map(([isCompany, posLabelPool]) => {
      return isCompany && posLabelPool?.length > 0;
    })
  );

  public configurations$ = combineLatest([
    this.locationDomainModel.locationConfig$,
    this.companyDomainModel.companyConfiguration$
  ]);

}
