<div class="item-container">
  <!-- We want loading container to take shape of display preview card -->
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"></app-loading>

  <!-- Image preview -->
  <div
    class="item-preview-container preview"
    [ngClass]="{
      'item-landscape-preview': viewModel.useLandscapeAspectRatio$ | async,
      'item-portrait-preview': viewModel.usePortraitAspectRatio$ | async
    }">
    <swiper-container
      [hidden]="!(viewModel?.hasContentIds$ | async)"
      (click)="viewModel.openEditItem()"
      class="swiper-container"
      #swiper
      initial-slide="0"
      slides-per-view="1"
      loop="false">
      <ng-container *ngFor="let contentId of viewModel.contentIds$ | async; trackBy: viewModel.trackByContentId">
        <swiper-slide lazy="true">
          <!--  Top Right corner loading spinner for refresh  -->
          <ng-container
            *ngIf="viewModel.refreshLoadingBundle$ | displayItemGetLoadingFromBundle : contentId as refreshOptions$">
            <div
              [hidden]="!(refreshOptions$ | async)?.isLoading"
              [ngbTooltip]="'Refreshing Preview'"
              [container]="'body'"
              class="refreshing-preview-container"
              placement="right">
              <app-loading *ngIf="(refreshOptions$ | async)?.isLoading" [options]="refreshOptions$ | async">
              </app-loading>
            </div>
          </ng-container>
          <div class="swiper-image-content">
            <ng-container
              *ngIf="viewModel.previewLoadingBundle$ | displayItemGetLoadingFromBundle : contentId as loadingOptions$">
              <app-loading
                *ngIf="(loadingOptions$ | async)?.isLoading"
                [options]="loadingOptions$ | async"></app-loading>
            </ng-container>
            <app-asset
              *ngIf="viewModel.itemPreviews$ | loadPreview : contentId | async as previewAsset"
              class="item-preview-img"
              [styleOverrides]="viewModel.imgStyleOverrides$ | push"
              [borderRadius]="'0.625rem'"
              [scaleFit]="imageFitContain"
              [asset]="previewAsset"
              [size]="ClientTypes.AssetSize.Large">
            </app-asset>
          </div>
        </swiper-slide>
      </ng-container>
      <swiper-slide lazy="true" *ngIf="viewModel?.showSeeMoreCard$ | push">
        <div class="see-more-swiper-card">
          {{ viewModel?.seeMoreCardsText$ | push }}
        </div>
      </swiper-slide>
    </swiper-container>
    <img
      *ngIf="!(viewModel.hasContentIds$ | async)"
      class="empty-preview"
      (click)="viewModel.openEditItem()"
      [src]="viewModel.previewPlaceholderSrc$ | async" />
    <div
      class="carousel-button-container"
      (click)="viewModel.openEditItem()"
      *ngIf="viewModel.showCarouselButtons$ | async">
      <div (click)="$event.stopPropagation()">
        <app-carousel-button
          [disabled]="viewModel.firstSlide$ | async"
          [icon]="'assets/icons/light/outline/chevron-left.svg'"
          (clicked)="previousSlide()">
        </app-carousel-button>
      </div>
      <div (click)="$event.stopPropagation()">
        <app-carousel-button
          [disabled]="viewModel.lastSlide$ | async"
          [icon]="'assets/icons/light/outline/chevron-right.svg'"
          (clicked)="nextSlide()">
        </app-carousel-button>
      </div>
    </div>
    <div class="options-button-container">
      <app-drop-down-menu
        (output)="viewModel.handleDropdownSelection($event)"
        [buttonClass]="'options-button-color rounded-icon-button'"
        [dropdownTopMargin]="'1.2rem'"
        [dropdownLeftMargin]="'0.4rem'"
        [iconSrc]="'assets/icons/dark/outline/dots-horizontal.svg'"
        [openLeft]="true"
        [sections]="viewModel.dropDownMenuSections$ | async">
      </app-drop-down-menu>
    </div>
    <div
      *ngIf="viewModel.showLiveViewButton$ | async"
      (click)="$event.stopPropagation()"
      [ngbTooltip]="'Live View'"
      [triggers]="'hover'"
      class="options-button-container me-5">
      <button (click)="viewModel.openLiveViewModal()" class="bs-button options-button-color rounded-icon-button">
        <img [src]="'assets/icons/dark/outline/eye.svg'" alt="" class="icon-button-icon" />
      </button>
    </div>
    <div class="badge-container">
      <!--      Badge Container uses row-reverse flex direction to right align, so elements are in reverse order-->
      <app-active *ngIf="viewModel.showActiveBadge$ | async" [displayableItem]="displayableItem"></app-active>
      <app-template-deployed-count-indicator
        *ngIf="viewModel.showDeployedCountIndicator$ | async"
        [displayableItem]="displayableItem">
      </app-template-deployed-count-indicator>
      <app-smart-menu-indicator
        *ngIf="viewModel.itemContainsSmartFilters$ | async"
        [tooltipText]="viewModel.smartFilterIndicatorTooltip$ | async">
      </app-smart-menu-indicator>
      <app-template-collection-indicator
        *ngIf="viewModel.templateCollectionIndicatorTooltip$ | async"
        [collectionNames]="viewModel.templateCollectionIndicatorTooltip$ | async">
      </app-template-collection-indicator>
    </div>
  </div>
  <div class="item-info-container">
    <div class="item-info-name-container">
      <img *ngIf="viewModel.showTemplateIcon$ | async" class="me-2" src="assets/icons/dark/solid/template.svg" alt="" />
      <div
        class="item-info-name text-truncate d-block"
        #container
        [ngbTooltip]="viewModel.itemName$ | async"
        triggers="hover"
        openDelay="300"
        [disableTooltip]="!(container.offsetWidth < container.scrollWidth)">
        {{ viewModel.itemName$ | async }}
      </div>
      <div class="item-time-container">
        <img
          [hidden]="!(viewModel.previewTime$ | async)"
          [ngbTooltip]="viewModel.previewTime$ | async"
          [container]="'body'"
          [placement]="'left'"
          alt=""
          class="preview-date-icon"
          placement="top"
          src="/assets/icons/dark/outline/calendar.svg" />
      </div>
    </div>
    <div *ngIf="(viewModel.itemSubItems$ | async)?.length > 0">
      <div class="menu-info-subheading ellipsis-nowrap-text">
        {{ viewModel.itemSubItemTitle$ | async }}
      </div>
      <ng-container *ngFor="let subItem of viewModel.itemSubItems$ | async; trackBy: viewModel.trackBySubItemId">
        <div fxLayout="row" fxLayoutAlign=" center" [style.gap.rem]="0.3">
          <img *ngIf="subItem.iconSrc" [src]="subItem.iconSrc" [style.height.rem]="1" [style.width.rem]="1" />
          <div class="item-info-menus ellipsis-nowrap-text">
            {{ subItem.name }}
          </div>
        </div>
      </ng-container>
    </div>
    <div
      *ngIf="(displayableItem | isDisplay) && !(viewModel.itemSubItems$ | async)?.length"
      class="menu-info-subheading">
      There is nothing assigned to this display.
    </div>
  </div>
</div>
