import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseModalComponent } from '../../../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EditPrintStackStyleViewModel } from './edit-print-stack-style-view-model';

@Component({
  selector: 'app-edit-print-stack-style',
  templateUrl: './edit-print-stack-style.component.html',
  providers: [EditPrintStackStyleViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditPrintStackStyleComponent extends BaseModalComponent {

  constructor(activeModal: NgbActiveModal, public viewModel: EditPrintStackStyleViewModel) {
    super(activeModal);
  }

  parseNumberOpacityToString = (val: number) => (val?.toString());

}
