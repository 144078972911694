import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Menu } from '../models/menu/dto/menu';
import { Section } from '../models/menu/dto/section';
import { Observable } from 'rxjs';
import { DatatableProductFilter } from '../models/product/table/datatable-product-filter';
import { ProductPickerModalComponent } from '../views/product/components/modals/product-picker-modal/product-picker-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { ProductPickerModalOptions } from '../models/product/shared/product-picker-modal-options';
import { Injector, NgZone } from '@angular/core';
import { MarketingMenuType } from '../models/enum/dto/marketing-menu-type.enum';
import { exists } from '../functions/exists';

export class ModalAddProducts {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    menu: Menu,
    section: Section,
    continueOperation: (variantIds: string[]) => Observable<boolean>,
    resultOperation: (customFilter: DatatableProductFilter) => void,
  ) {
    ngZone.run(() => {
      const modalRef = ngbModal.open(ProductPickerModalComponent, ModalUtils.productPickerModalOptions(injector));
      const compInstance = modalRef.componentInstance as ProductPickerModalComponent;
      compInstance.viewModel.initAsIgnoreVariants.next(false);
      compInstance.options = ProductPickerModalOptions.defaultProductPickerModalOptions();
      if (menu?.isSpotlightMenu()) compInstance.setupModalForSpotlightMenu(menu, section);
      if (exists(menu)) compInstance.setupModalForFeaturedProductMenu(menu);
      if (exists(section)) compInstance.setupModalForSection(section);
      const hideOutOfStockProducts = menu?.getSubType() !== MarketingMenuType.SmartPlaylist;
      compInstance.setupModalForHideOutOfStockProducts(hideOutOfStockProducts);
      compInstance.continueOperation = continueOperation;
      modalRef.result.then((customFilter: DatatableProductFilter) => resultOperation(customFilter)).catch(() => {});
    });
  }

}
