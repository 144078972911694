import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { ColumnOptionsViewModel } from '../../../../../viewModels/column-options-view-model';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SectionColumnConfigKeyType } from '../../../../../../../models/utils/dto/section-column-config-key-type';
import { SectionColumnConfigDefaultState } from '../../../../../../../models/enum/dto/section-column-config-default-state';
import { SectionColumnConfigProductInfoKey } from '../../../../../../../models/enum/dto/section-column-config-key';

@Injectable()
export class SingleColumnOptionsViewModel extends BaseViewModel {

  constructor(
    public container: ColumnOptionsViewModel
  ) {
    super();
  }

  public showDisabledInGridModeMessage$ = combineLatest([
    this.container.layoutType$,
    this.container.columnSectionKey$
  ]).pipe(
    map(([layoutType, columnSectionKey]) => {
      return ColumnOptionsViewModel.disabledForGridLayout(columnSectionKey, layoutType)
        || (columnSectionKey === SectionColumnConfigProductInfoKey.Price && layoutType?.isGrid());
    })
  );

  public disabledInGridModeMessage$ = combineLatest([
    this.container.layoutType$,
    this.container.columnSectionKey$,
    this.container.columnSectionName$,
    this.container.gridColumnCount$
  ]).pipe(
    map(([layoutType, columnSectionKey, columnName, gridColumnCount]) => {
      if (SectionColumnConfigKeyType.disabledForGridLayout(columnSectionKey, layoutType)) {
        return `The ${columnName} column is disabled when the section is in Grid Mode.`;
      }
      return `This section has Grid Mode enabled, and there are ${gridColumnCount} `
        + 'active grid columns. Each grid column will be the width that is defined below.';
    })
  );

  public formColumnConfig$ = combineLatest([
    this.container.columnConfigMap$,
    this.container.layoutType$,
    this.container.columnSectionKey$
  ]).pipe(
    map(([columnConfigMap, layoutType, columnSectionKey]) => {
      const columnConfig = columnConfigMap?.get(columnSectionKey);
      if (ColumnOptionsViewModel.disabledForGridLayout(columnSectionKey, layoutType)) {
        columnConfig.defaultState = SectionColumnConfigDefaultState.Disabled;
      } else if (layoutType?.isGrid() && columnSectionKey === SectionColumnConfigProductInfoKey.Price) {
        columnConfig.defaultState = SectionColumnConfigDefaultState.On;
      }
      if (!!columnConfig?.columnOpacity && columnConfig?.columnOpacity <= 1) {
        columnConfig.columnOpacity = Math.round(columnConfig.columnOpacity * 100);
      }
      return columnConfig;
    })
  );

  public themeDefaults$ = combineLatest([
    this.container.themeColumnConfig$,
    this.container.columnSectionKey$
  ]).pipe(
    map(([themeColumnConfig, columnSectionKey]) => themeColumnConfig?.get(columnSectionKey))
  );

}
