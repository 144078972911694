<app-print-job-name-form
  [job]="job"
  [label]="'Stack Print Name'"
  [mergeKey]="mergeKey"
  [placeholder]="placeholder"
  [viewOnly]="viewOnly">
</app-print-job-name-form>

<app-print-job-employee-form [job]="job" [mergeKey]="mergeKey" [viewOnly]="viewOnly"></app-print-job-employee-form>

<app-stack-smart-print-options-form
  [stackType]="stackType"
  [job]="job"
  [mergeKey]="mergeKey"
  [templateMode]="templateMode"
  [viewOnly]="viewOnly">
</app-stack-smart-print-options-form>

<ng-container *rxIf="viewOnly">
  <ng-container [ngSwitch]="stackType">
    <app-single-stack-print-job-applied-sections *ngSwitchCase="'shelf talker'" [job]="job" />
    <app-single-stack-print-job-applied-products *ngSwitchDefault [job]="job" />
  </ng-container>
</ng-container>
