import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditTemplateMenuHeaderComponent } from '../../edit-template-menu-header.component';
import { EditTemplateShelfTalkerBrandsMenuViewModel } from '../edit-template-shelf-talker-brands-menu/edit-template-shelf-talker-brands-menu/edit-template-shelf-talker-brands-menu-view-model';

@Component({
  selector: 'app-edit-template-shelf-talker-menu-header',
  templateUrl: './edit-template-shelf-talker-menu-header.component.html',
  styleUrls: [
    './edit-template-shelf-talker-menu-header.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateShelfTalkerMenuHeaderComponent extends EditTemplateMenuHeaderComponent {

  constructor(
    public override viewModel: EditTemplateShelfTalkerBrandsMenuViewModel
  ) {
    super(viewModel);
  }

}
