import { AutoSaveViewModel } from '../../../shared/components/auto-save/auto-save-view-model';
import { TemplateDomainModel } from '../../../../domainModels/template-domain-model';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { MenuType } from '../../../../models/utils/dto/menu-type-definition';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { Orientation } from '../../../../models/utils/dto/orientation-type';
import { EditMenuViewModel } from '../../../menu/viewModels/edit-menu-view-model';
import { ModalPublishTemplate } from '../../../../modals/modal-publish-template';
import { Injector, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuTemplate } from '../../../../models/template/dto/menu-template';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';
import { Size } from '../../../../models/shared/size';
import { LocationChangedUtils } from '../../../../utils/location-changed-utils';
import { ModalMenuTemplateRequiredLocationsChangedSaveConfirmation } from '../../../../modals/modal-menu-template-required-locations-changed-save-confirmation';
import { DistinctUtils } from '../../../../utils/distinct-utils';
import { DateUtils } from '../../../../utils/date-utils';

export abstract class EditTemplateMenuViewModel extends AutoSaveViewModel {

  constructor(
    protected editMenuViewModel: EditMenuViewModel,
    protected templateDomainModel: TemplateDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector
  ) {
    super();
    this.connectToAllowAutoSaving(false);
    editMenuViewModel.connectToTemplateMode(true);
    editMenuViewModel.connectToAllowAutoSaving(false);
    editMenuViewModel.connectToAddRequest('Loading Menu Template');
  }

  public readonly locId$ = this.locationDomainModel.locationId$;
  public readonly menuTemplateId$ = this.templateDomainModel.activeMenuTemplateId$;
  public readonly activeTemplate$ = this.templateDomainModel.activeMenuTemplate$;
  public readonly templateName$ = this.activeTemplate$.pipe(map(template => template?.name));
  public readonly templateStatus$ = this.activeTemplate$.pipe(map(template => template?.status));
  public readonly canDeleteTemplateMenu$ = this.editMenuViewModel.canDeleteTemplateMenu$;

  private readonly _formAutoSubmitted = new Subject<any[]>();
  public readonly formAutoSubmitted$ = this._formAutoSubmitted as Observable<any[]>;
  connectToFormAutoSubmitted = (formAutoSubmitted: any[]) => this._formAutoSubmitted.next(formAutoSubmitted);

  private readonly _changedRequiredTemplateIds = new BehaviorSubject<number[]>(undefined);
  public readonly changedRequiredTemplateIds$ = this._changedRequiredTemplateIds as Observable<number[]>;
  connectToChangedRequiredTemplateIds = (ids: number[]) => this._changedRequiredTemplateIds.next(ids);

  public readonly crumbs$ = this.activeTemplate$.pipe(
    map(menu => {
      return [
        menu?.getViewAllBreadcrumb(false),
        menu?.getEditBreadcrumb(true)
      ];
    })
  );

  public readonly showPublishButton$ = this.editMenuViewModel.menu$.pipe(
    map(menu => menu instanceof MenuTemplate && !menu?.isPublished())
  );

  public readonly isPrintTemplate$ = this.activeTemplate$.pipe(
    map(m => m?.type === MenuType.PrintMenu),
    distinctUntilChanged()
  );

  // Auto Save Loading
  protected override readonly _autoSaveLoadingOpts = new BehaviorSubject<LoadingOptions>(
    LoadingOptions.defaultInButton()
  );
  public override readonly autoSaveLoadingOpts$ = combineLatest([
    this._autoSaveLoadingOpts,
    this.editMenuViewModel.autoSaveLoadingOpts$,
  ]).pipe(
    map(([templateOpts, productOpts]) => templateOpts.combineWith(productOpts))
  );
  public override readonly autoSaving$ = this.autoSaveLoadingOpts$.pipe(map(it => it.isLoading));

  public allowLiveView$ = combineLatest([
    this.autoSaveLoadingOpts$,
    this.activeTemplate$
  ]).pipe(
    map(([loadingOpts, menu]) => {
      const hasOrientation = menu?.displaySize?.orientation !== Orientation.NA;
      const validConfig = !loadingOpts?.isLoading && !!menu?.displaySize && hasOrientation;
      const validSizeForType = menu?.validSizeForType();
      return validConfig && validSizeForType;
    }),
    distinctUntilChanged()
  );

  private loadMenuTemplate = () => this.editMenuViewModel.loadHydratedMenuTemplate();

  /* *********************** Local Threads of Execution *********************** */

  private fetchMenuTemplateWhenLocationChanges = LocationChangedUtils
    .loadMenuTemplate(this, this.locId$, this.menuTemplateId$, this.loadMenuTemplate);

  /* **************************** Publish Template **************************** */

  promptToPublishTemplate = (): void => {
    if (this.unsavedChanges) {
      this.triggerAutoFormSubmission.next();
    }
    ModalPublishTemplate.open(
      this.ngZone,
      this.ngbModal,
      this.injector,
      this.activeTemplate$,
      this.editMenuViewModel.autoSaveLoadingOpts$
    );
  };

  /* ************************************************************************** */

  public readonly triggerFormSubmission = new Subject<boolean>();

  saveIntercept = (background: boolean) => {
    combineLatest([
      this.activeTemplate$,
      this.changedRequiredTemplateIds$
    ]).once(([template, changedRequiredTemplateIds]) => {
      const saveFormGroup = (bg: boolean = false) => {
        this.triggerFormSubmission.next(bg);
        this.connectToLastAutoSaveTimestamp(DateUtils.currentTimestamp());
      };
      const published = template?.isPublished();
      const requiredLocationsNotUpdated = !changedRequiredTemplateIds;
      const originalIdsAsStrings = template?.requiredLocationIds?.map(id => id.toString(10)) ?? [];
      const updatedIdsAsStrings = changedRequiredTemplateIds?.map(id => id.toString(10)) ?? [];
      const noRequiredLocationChanges = DistinctUtils.distinctSortedStrings(originalIdsAsStrings, updatedIdsAsStrings);
      if (!published || requiredLocationsNotUpdated || noRequiredLocationChanges) {
        saveFormGroup(background);
        return;
      }
      ModalMenuTemplateRequiredLocationsChangedSaveConfirmation.open(
        this.ngZone,
        this.ngbModal,
        this.injector,
        template,
        changedRequiredTemplateIds,
        saveFormGroup
      );
    });
  };

  saveTemplate = (background: boolean) => {
    this.editMenuViewModel.saveMenu(background);
  };

  openTemplateLiveView = (sizeOverride?: Size) => {
    this.editMenuViewModel.openLiveViewModal(sizeOverride);
  };

  deleteTemplate = (): void => {
    this.editMenuViewModel.deleteMenu();
  };

  openDuplicateTemplateModal = (): void => {
    this.editMenuViewModel.openDuplicateMenuModal();
  };

  override destroy() {
    super.destroy();
    this.templateDomainModel.clearActiveMenuTemplate();
  }

}
