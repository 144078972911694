<div class="modal-padding">
  <div class="terpene-title">
    <span class="text"> Primary Terpene Info </span>
  </div>
  <app-terpene-min-max-form
    [smartFilter]="viewModel.smartFilter$ | push"
    [terpene]="viewModel.totalTerpeneInfo$ | push"
    [dispersedKey]="dispersedKey"
    [isViewOnly]="viewModel.isViewOnly$ | push"></app-terpene-min-max-form>

  <lib-reactive-form-drop-down
    [inputName]="'topTerpene'"
    [label]="'Top Terpene'"
    [placeholder]="'Select a top terpene'"
    [inlineLabel]="false"
    [required]="false"
    [disabled]="viewModel.isViewOnly$ | push"
    [tooltip]="'The smart filter will include products with a top terpene set here.'"
    [dropdowns]="viewModel.terpeneDropdowns$ | push"
    [clearable]="true"
    [addToAtLeastNItemsFilledInPool]="true"
    [bindingProperty]="'topTerpene'"
    [dispersedKey]="dispersedKey"></lib-reactive-form-drop-down>

  <lib-reactive-form-pill-drop-down
    [inputName]="'presentTerpenes'"
    [label]="'Present Terpenes'"
    [emptyPlaceholder]="'Enter present terpenes'"
    [placeholder]="'Enter present terpenes'"
    [dropdowns]="viewModel.terpeneDropdowns$ | push"
    [addToAtLeastNItemsFilledInPool]="true"
    [delimitedPillBackgroundColors]="viewModel.terpenePillBackgroundColors$ | push"
    [delimitedPillTooltips]="viewModel.terpenePillTooltip$ | push"
    [bindingProperty]="'presentTerpenes'"
    [dispersedKey]="dispersedKey"></lib-reactive-form-pill-drop-down>

  <div class="terpene-title">
    <span class="text"> Individual Terpenes </span>
  </div>
  <ng-container *rxFor="let terpene of viewModel.terpenes$ | push">
    <app-terpene-min-max-form
      [smartFilter]="viewModel.smartFilter$ | push"
      [terpene]="terpene"
      [dispersedKey]="dispersedKey"
      [isViewOnly]="viewModel.isViewOnly$ | push"></app-terpene-min-max-form>
  </ng-container>
</div>
