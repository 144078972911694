<div class="mt-4">
  <app-column-general-options-form
    [formColumnConfig]="viewModel.formColumnConfig$ | push"
    [columnSectionKey]="viewModel.columnSectionKey$ | push"
    [formMergeKey]="formMergeKey"
    [inputDisabled]="viewModel.inputDisabled$ | push"
    [sectionLayoutType]="viewModel.sectionLayoutType$ | push"
    [themeDefaults]="viewModel.themeDefaults$ | push"
    [managingDefault]="viewModel.managingDefault$ | push"
    (defaultStateChanged)="viewModel.connectToChangedDefaultState($event)"
    (changes)="viewModel.connectToChanges($event)">
  </app-column-general-options-form>

  <app-column-style-options-form
    [sectionColumnConfig]="viewModel?.formColumnConfig$ | push"
    [formMergeKey]="formMergeKey"
    [columnOptionName]="viewModel?.columnSectionKey$ | push"
    [enabled]="!(viewModel?.inputDisabled$ | push)"
    [cannabinoidOrTerpene]="cannabinoidOrTerpene"
    [hideTextColor]="viewModel?.hideTextOptions$ | push"
    [hideTextOptions]="viewModel?.hideTextOptions$ | push">
  </app-column-style-options-form>
</div>
