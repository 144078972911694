import { Injectable } from '@angular/core';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { AnimatorService } from '../../../../../../services/animator/animator.service';
import { ActivatedRoute } from '@angular/router';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { MenusContentContainerViewModel } from '../../../../../shared/components/displayable-content/displayable-content-container/menus-content-container-view-model';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';

// Provided by Logged In Scope
@Injectable()
export class DigitalMenusContainerViewModel extends MenusContentContainerViewModel {

  constructor(
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    templateDomainModel: TemplateDomainModel,
    userDomainModel: UserDomainModel,
    animatorService: AnimatorService,
    activeRoute: ActivatedRoute,
  ) {
    super(
      locationDomainModel,
      menuDomainModel,
      templateDomainModel,
      userDomainModel,
      animatorService,
      activeRoute
    );
  }

  getMenuTypesFromSelectedTab(selectedTab: number): MenuType[] {
    switch (selectedTab) {
      case 0: return [MenuType.DisplayMenu];
      case 1: return [MenuType.MarketingMenu];
    }
  }

}
