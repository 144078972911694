import 'src/app/utils/observable.extensions'; // just in case auth interceptor loads before app component
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { debounceTime, filter, map, retry, switchMap, take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { exists } from '../../functions/exists';
import { Session } from '../../models/account/dto/session';
import { AuthInterceptorService } from './auth-interceptor.service';

/**
 * Do NOT directly inject UserDomainModel into the interceptor, because it will create circular injection errors.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    // decouple user domain model from auth interceptor using intermediary service
    private authInterceptorService: AuthInterceptorService
  ) {
  }

  private ignoreAuthTokenFor = [
    'budboard-image',
    '/sign-in',
    '/sign-in-new-password',
    '/forgot-password-code',
    '/resend-code',
    '/reset-forgotten-password',
    '/refresh-session',
    '/sign-out',
  ];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.ignoreAuthTokenFor.map(r => request.url.includes(r)).filter(v => v).length > 0) {
      return this.createHeaders('', request.headers).pipe(
        take(1),
        map((headers) => request.clone({ headers })),
        switchMap(req => next.handle(req))
      );
    }
    return this.waitAndAddValidAuthenticationToken(request).pipe(
      take(1),
      switchMap(req => next.handle(req)),
      retry(this.error403RetryLogic()),
      takeUntil(this.authInterceptorService.destroySession$)
    );
  }

  /**
   * 403 errors mean that we tried to make an API call with an invalid token. Catch these specific errors with retry()
   * and wait for a valid token before making the request again.
   */
  private error403RetryLogic(): { delay: (error: any) => any } {
    return {
      delay: (error: any) => {
        if (error?.status === 403) {
          this.authInterceptorService.received403SoUpdateSessionTokenToRefreshing();
          return this.authInterceptorService.validSessionToken$.pipe(
            debounceTime(1000),
            filter(it => exists(it) && (it !== Session.REFRESHING_AUTH_TOKEN_FROM_403)),
            take(1),
          );
        }
        return throwError(() => error);
      }
    };
  }

  waitAndAddValidAuthenticationToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    return this.authInterceptorService.validSessionToken$.pipe(
      filter(it => exists(it) && (it !== Session.REFRESHING_AUTH_TOKEN_FROM_403)),
      take(1),
      switchMap((token) => this.createHeaders(token, request.headers)),
      take(1),
      map((headers) => request.clone({ headers })),
      takeUntil(this.authInterceptorService.destroySession$)
    );
  }

  createHeaders(token: string, headers: HttpHeaders): Observable<HttpHeaders> {
    return this.authInterceptorService.user$.pipe(
      take(1),
      map((user) => {
        const append = (n: string, val: string|string[]) => {
          if (exists(val)) headers = headers.append(n, val);
        };
        // noinspection FallThroughInSwitchStatementJS - we want to check all cases
        switch (true) {
          case !headers.get('Content-Type'): append('Content-Type', 'application/json');
          case !headers.get('ClientSecret'): append('ClientSecret', environment?.cognitoClientSecret);
          case !headers.get('CompanyId'):    append('CompanyId', user?.companyId?.toString());
          case !headers.get('Accept'):       append('Accept', 'application/json');
          case !headers.get('UserId'):       append('UserId', user?.userId);
          case !headers.get('Token'):        append('Token', token);
        }
        return headers;
      })
    );
  }

}

