import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, NgZone, Output } from '@angular/core';
import { EditShelfTalkerMenuComponent } from '../edit-shelf-talker-menu.component';
import { EditProductMenuViewModel } from '../../../../viewModels/edit-product-menu-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../../../../../../services/toast-service';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-shelf-talker-brands-menu',
  templateUrl: './edit-shelf-talker-brands-menu.component.html',
  styleUrls: ['./edit-shelf-talker-brands-menu.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'],
  providers: [EditProductMenuViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShelfTalkerBrandsMenuComponent extends EditShelfTalkerMenuComponent {

  constructor(
    public override viewModel: EditProductMenuViewModel,
    protected override router: Router,
    protected override ngZone: NgZone,
    protected override ngbModal: NgbModal,
    protected override injector: Injector,
    protected override sanitizer: DomSanitizer,
    protected override toastService: ToastService,
    protected override activatedRoute: ActivatedRoute
  ) {
    super(
      viewModel,
      router,
      ngZone,
      ngbModal,
      injector,
      sanitizer,
      toastService,
      activatedRoute
    );
  }

  @Input() templateMode: boolean = false;
  @Input() mergeKey: string;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Output() submitForms = new EventEmitter<boolean>(true);

}
