import { ScalableLiveViewModalViewModel } from '../../shared/scalable-live-view-modal-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Menu } from '../../../../models/menu/dto/menu';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class PrintMenuLiveViewModalViewModel extends ScalableLiveViewModalViewModel {

  override itemSelected$(item: any): Observable<boolean> {
    return of(true);
  }

  private readonly _printMenu = new BehaviorSubject<Menu|null>(null);
  public readonly printMenu$ = this._printMenu as Observable<Menu|null>;
  connectToPrintMenu = (printCardMenu: Menu|null) => this._printMenu.next(printCardMenu);

  private readonly _nPages = new BehaviorSubject<number>(0);
  public readonly nPages$ = this._nPages.pipe(distinctUntilChanged());
  connectToNPages = (nPages: number) => this._nPages.next(nPages);

  public readonly title$ = this.printMenu$.pipe(map(menu => menu?.name));
  public readonly oneOrNoPages$ = this.nPages$.pipe(map(nPages => nPages <= 1));

}
