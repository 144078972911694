/**
 * inventoryProvider$ gets set to Dutchie if it's DuchieMed or DouchieRec
 */
export enum InventoryProvider {
  // POS Integrated
  Hint = 'Select an inventory provider',
  Blaze = 'Blaze',
  Cova = 'Cova',
  Dutchie = 'Dutchie',
  DutchieMed = 'DutchieMed',
  DutchieRec = 'DutchieRec',
  FlowHub = 'FlowHub',
  Greenline = 'Greenline',
  GrowFlow = 'GrowFlow',
  IndicaOnline = 'IndicaOnline',
  TechPOS = 'TechPOS',
  TendyPOS = 'TendyPOS',
  Treez = 'Treez',
  // Non-integrated
  BudSense = 'BudSense'
}
