import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavItemViewModel } from '../nav-item-view-model';
import { NavItemComponent } from '../nav-item.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help-nav-item',
  templateUrl: './help-nav-item.component.html',
  styleUrls: [
    './help-nav-item.component.scss',
    '../nav-item.component.scss'
  ],
  providers: [NavItemViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpNavItemComponent extends NavItemComponent {

  constructor(
    public override viewModel: NavItemViewModel,
    router: Router,
  ) {
    super(viewModel, router);
  }

  override navItemSelected() {
    // open knowledge base in new tab
    window.open(this.navItem.routerPath, '_blank');
  }

}
