import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../../../../../models/product/dto/variant';

@Pipe({
  name: 'stackAppliedProductListItemIsVariant'
})
export class StackAppliedProductListItemIsVariantPipe implements PipeTransform {

  transform(value: any): Variant | null {
    return (value instanceof Variant) ? value : null;
  }

}
