import { Deserializable } from '../../protocols/deserializable';

export class SignInRequest implements Deserializable {

  constructor(
    public email: string = '',
    public password: string = ''
  ) { }

  public rememberMe: boolean = true;
  onDeserialize = ()  => {};

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-authenticator/blob/dev/models/SignInRequest.go
  onSerialize(): SignInRequest {
    const dto = new SignInRequest();
    delete dto.rememberMe;
    dto.email = this.email;
    dto.password = this.password;
    return dto;
  }

}
