import { AfterViewInit, Component, Injector, Input, NgZone, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { ComponentCanDeactivate } from '../../../../models/protocols/component-can-deactivate';
import { FormGroupComponent } from '../../../shared/components/form-group/form-group.component';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditMenuSectionViewModel } from '../../viewModels/edit-menu-section-view-model';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { ModalPromptForSectionDelete } from '../../../../modals/modal-prompt-for-section-delete';

@Component({
  selector: 'app-edit-menu-section',
  templateUrl: './edit-menu-section.component.html',
  styleUrls: [
    './edit-menu-section.component.scss',
    '../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditMenuSectionViewModel]
})
export class EditMenuSectionComponent extends BaseComponent implements AfterViewInit, ComponentCanDeactivate {

  constructor(
    public viewModel: EditMenuSectionViewModel,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  // View References
  @ViewChild('sectionForm') deactivatableForm: FormGroupComponent;
  @ViewChild(ReactiveFormMergeGroupsComponent) formCapture: ReactiveFormMergeGroupsComponent;
  @Input() dispersedAndMergeKey = 'editSection';
  public canvas = document.createElement('canvas'); // used to generate color image

  // Modals
  public dismissModalSubject: Subject<any> = new Subject<any>();

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (!this.viewModel.deactivatableForms.includes(this.deactivatableForm) && this.deactivatableForm) {
      this.viewModel.deactivatableForms.push(this.deactivatableForm);
    }
  }

  override setupBindings() {
    // trigger form auto submit
    this.viewModel.triggerAutoFormSubmission
      .subscribeWhileAlive({
        owner: this,
        next: _ => this.formCapture?.submitForms(true)
      });
  }

  canDeactivate(): boolean | Promise<any> {
    return this.viewModel.canDeactivate();
  }

  deleteSection() {
    this.viewModel.section$.once(section => {
      const confirmation = (cont: boolean) => { if (cont) this.viewModel.deleteSection(); };
      ModalPromptForSectionDelete.open(this.ngZone, this.ngbModal, this.injector, section, confirmation);
    });
  }

}
