import { DisplayableContentContainerViewModel } from './displayable-content-container-view-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { AnimatorService } from '../../../../../services/animator/animator.service';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { TemplateCollectionDomainModel } from '../../../../../domainModels/template-collection-domain-model';
import { combineLatest, defer, Observable, of } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { DisplayableItemFilterByActive } from '../../../../../models/enum/shared/displayable-item-filter-by.active';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';
import type { MenuTemplate } from '../../../../../models/template/dto/menu-template';

@Injectable()
export abstract class TemplatesContentContainerViewModel extends DisplayableContentContainerViewModel {

  protected constructor(
    protected templateCollectionDomainModel: TemplateCollectionDomainModel,
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    templateDomainModel: TemplateDomainModel,
    userDomainModel: UserDomainModel,
    animatorService: AnimatorService,
    activeRoute: ActivatedRoute,
  ) {
    super(
      locationDomainModel,
      menuDomainModel,
      templateDomainModel,
      userDomainModel,
      animatorService,
      activeRoute
    );
  }

  abstract getMenuTypeFromSelectedTab(selectedTab: number): MenuType[];

  override itemsToFilter$: Observable<MenuTemplate[]>;

  public override inactiveStateFilterHint$ = of('Choose a Template Status');
  public override inactiveStateFilterLabel$ = of('Filter by Template Status');

  public readonly locationText$ = of('Previewing templates at ');

  public readonly tags$ = this.selectedTab$.pipe(
    switchMap(tabNumber => this.templateDomainModel.getMenuTemplateTagsFor(this.getMenuTypeFromSelectedTab(tabNumber))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public override showMenuFormatDropdown$ = of(false);

  public override selectFilterByInactiveState$ = combineLatest([
    this.hasInactiveItems$,
    this.hasActiveItems$,
    this.hasItems$,
    this.userSelectedInactiveStateFilter$
  ]).pipe(
    debounceTime(0),
    withLatestFrom(this.isInitialInactiveStateSelection$),
    map(([[hasInactiveItems, hasActiveItems, hasItems, selectedOption], isInitialSelection]) => {
      switch (true) {
        case !hasItems:
          return DisplayableItemFilterByActive.All;
        case !hasInactiveItems:
          return DisplayableItemFilterByActive.Active;
        case !hasActiveItems && hasInactiveItems:
          return DisplayableItemFilterByActive.Inactive;
        case !isInitialSelection:
          return selectedOption;
        default:
          return DisplayableItemFilterByActive.All;
      }
    })
  );

  protected itemsToFilterThemeIds$ = defer(() => this.itemsToFilter$).pipe(
    map(items => items?.map(item => item.theme)?.filterNulls())
  );

  public menuTypeItems$ = combineLatest([
    this.itemsToFilterThemeIds$,
    this.menuDomainModel.menuSubTypeAssociatedToThemeId$
  ]).pipe(
    map(([currentTabThemeIds, menuTypeMapping]) => {
      const templateThemeIds = (currentTabThemeIds?.length > 0) ? new Set(currentTabThemeIds) : [];
      return [...templateThemeIds].map(themeId => menuTypeMapping.get(themeId)).unique();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
