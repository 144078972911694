import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Variant } from '../models/product/dto/variant';
import { EditVariantModalComponent } from '../views/product/components/modals/edit-variant-modal/edit-variant-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector, NgZone } from '@angular/core';

export class ModalEditVariant {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    variant: Variant
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(EditVariantModalComponent, ModalUtils.editVariantModalOptions(injector));
      const compInstance = modalRef.componentInstance as EditVariantModalComponent;
      if (!!variant) compInstance.setVariant(variant);
    });
  }

}
