import { Injector, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateViewStackPrintJobComponent, type StackType } from '../views/menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';
import { ModalUtils } from '../utils/modal-utils';
import { BulkPrintJob } from '../models/automation/bulk-print-job';
import { BulkJobSource } from '../models/utils/dto/bulk-job-source-type';

export class ModalCreateStackPrintJob {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    stackType: StackType,
    templateMode: boolean,
    job?: BulkPrintJob
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(CreateViewStackPrintJobComponent, ModalUtils.defaultModalOptions(injector));
      const compInstance = modalRef.componentInstance as CreateViewStackPrintJobComponent;
      compInstance.viewModel.connectToStackType(stackType);
      compInstance.viewModel.connectToTemplateMode(templateMode);
      if (job) {
        const constructJobCopy = () => {
          const copy = window?.injector?.Deserialize?.instanceOf(BulkPrintJob, job);
          copy.id = null;
          copy.name = '';
          copy.jobStatus = null;
          copy.jobSource = BulkJobSource.BulkJobSource_EditCardStack;
          return copy;
        };
        const jobCopy = constructJobCopy();
        compInstance.viewModel.connectToReprintMode(true);
        compInstance.viewModel.connectToBulkPrintJob(jobCopy);
      }
    });
  }

}
