// noinspection JSUnusedLocalSymbols

import { BaseDomainModel } from '../models/base/base-domain-model';
import { Injectable } from '@angular/core';
import { MenuAPI } from '../api/menu-api';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, shareReplay, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { CacheService } from '../services/cache-service';
import { BsError } from '../models/shared/bs-error';
import { Menu } from '../models/menu/dto/menu';
import { ToastService } from '../services/toast-service';
import { Theme } from '../models/menu/dto/theme';
import { BudsenseFile } from '../models/shared/budsense-file';
import { UploadFilePath } from '../models/enum/dto/upload-file.path';
import { GenerateUploadUrlRequest } from '../models/image/requests/generate-upload-url-request';
import { ImageAPI } from '../api/image-api';
import { MenuAssets } from '../models/menu/shared/menu-assets';
import { Asset } from '../models/image/dto/asset';
import { Section } from '../models/menu/dto/section';
import { HydratedSection } from '../models/menu/dto/hydrated-section';
import { ProductDomainModel } from './product-domain-model';
import { MenuStyle } from '../models/menu/dto/menu-style';
import { DuplicateMenuRequest } from '../models/menu/shared/duplicate-menu-request';
import { DisplayDomainModel } from './display-domain-model';
import { DistinctUtils } from '../utils/distinct-utils';
import { AssetSize } from '../models/enum/dto/asset-size.enum';
import { StringUtils } from '../utils/string-utils';
import { HydratedVariantFeature } from '../models/product/dto/hydrated-variant-feature';
import { SmartFiltersDomainModel } from './smart-filters-domain-model';
import { DuplicateMenuSectionRequest } from '../models/menu/shared/duplicate-menu-section-request';
import { NewMenuSectionRequest } from '../models/menu/dto/new-menu-section-request';
import { MediaUtils } from '../utils/media-utils';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { LocationDomainModel } from './location-domain-model';
import { DomainTunnelFromMenuToTemplateService } from '../services/domain-tunnel-from-menu-to-template.service';
import { ChangesRequiredForPreviewService } from '../services/changes-required-for-preview.service';
import { SortUtils } from '../utils/sort-utils';
import { exists } from '../functions/exists';
import { TemplateStatus } from '../models/template/enum/template-status.enum';
import { CompanyDomainModel } from './company-domain-model';
import { UserDomainModel } from './user-domain-model';
import { LocationChangedUtils } from '../utils/location-changed-utils';
import { SectionType } from '../models/enum/dto/section-type';
import type { Tag } from '../models/menu/dto/tag';
import type { MenuType } from '../models/utils/dto/menu-type-definition';
import type { MenuSubtype } from '../models/enum/dto/menu-subtype';

type PollingAssets = Observable<[boolean, MenuAssets, BudsenseFile[]]>;

// Provided by Logged In Scope
@Injectable()
export class MenuDomainModel extends BaseDomainModel {

  constructor(
    private userDomainModel: UserDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private productDomainModel: ProductDomainModel,
    private smartFiltersDomainModel: SmartFiltersDomainModel,
    private menuAPI: MenuAPI,
    private imageAPI: ImageAPI,
    private cacheService: CacheService,
    private toastService: ToastService,
    private displayDomainModel: DisplayDomainModel,
    private locationDomainModel: LocationDomainModel,
    private domainTunnelFromMenuToTemplateService: DomainTunnelFromMenuToTemplateService,
    private changesRequiredForPreviewService: ChangesRequiredForPreviewService
  ) {
    super();
    this.setupBindings();
  }

  private locationId$ = this.locationDomainModel.locationId$;
  private companyId$ = this.companyDomainModel.companyId$;
  public applyProductDetailsToHydratedSection = this.productDomainModel.applyProductDetailsToHydratedSection;

  // Active Menu Data
  private _hydratedMenus = new BehaviorSubject<Map<string, Menu>>(new Map());
  public hydratedMenus$ = this._hydratedMenus as Observable<Map<string, Menu>>;

  private _activeHydratedMenuId = new BehaviorSubject<string>(null);
  public activeHydratedMenuId$ = this._activeHydratedMenuId.pipe(distinctUntilChanged());
  public activeHydratedMenu$ = combineLatest([
    this.activeHydratedMenuId$,
    this.hydratedMenus$
  ]).pipe(map(([menuId, menus]) => (!!menuId ? menus?.get(menuId) : null)))
    .deepCopy()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private _hydratedSections = new BehaviorSubject<Map<string, HydratedSection>>(new Map());
  public hydratedSections$ = this._hydratedSections.pipe(distinctUntilChanged());

  private _activeHydratedSectionId = new BehaviorSubject<string>(null);
  public activeHydratedSectionId$ = this._activeHydratedSectionId.pipe(distinctUntilChanged());
  public activeHydratedSection$ = combineLatest([
    this.activeHydratedSectionId$,
    this.hydratedSections$,
  ]).pipe(switchMap(([id, sections]) => this.applyProductDetailsToHydratedSection(sections?.get(id) ?? null)))
    .deepCopy()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private _loadingMenus: BehaviorSubject<boolean> = new BehaviorSubject<boolean | null>(null);
  public loadingMenus$ = this._loadingMenus as Observable<boolean | null>;

  public _menusLocationId: BehaviorSubject<number> = new BehaviorSubject<number | null>(null);
  public menusLocationId$ = this._menusLocationId as Observable<number | null>;

  public duplicatedMenuIdsWithAsset: Map<string, string[]> = new Map<string, string[]>();

  private _menuDuplicatingToNewLocation = new BehaviorSubject<boolean>(false);
  public menuDuplicatingToNewLocation$ = this._menuDuplicatingToNewLocation as Observable<boolean>;
  connectToMenuDuplicatingToNewLocation = (d: boolean) => this._menuDuplicatingToNewLocation.next(d);

  // Themes
  private _fetchingThemes = new BehaviorSubject<boolean>(false);
  public fetchingThemes$ = this._fetchingThemes as Observable<boolean>;

  private _menuThemes: BehaviorSubject<Theme[]> = new BehaviorSubject<Theme[]>(null);
  public menuThemes$ = this._menuThemes as Observable<Theme[]>;

  public readonly menuSubTypeAssociatedToThemeId$ = this.menuThemes$.pipe(
    map(themes => {
      const subTypes = new Map<string, MenuSubtype>();
      themes?.forEach(theme => subTypes.set(theme?.id, theme?.menuSubType));
      return subTypes;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private readonly _currentLocationMenus = new BehaviorSubject<Menu[]>(null);
  public currentLocationMenus$ = combineLatest([
    this._currentLocationMenus,
    this.menuThemes$
  ]).pipe(
    map(([currentLocationMenus, themes]) => {
      currentLocationMenus?.forEach(menu => menu.hydratedTheme = themes?.find(theme => theme?.id === menu.theme));
      return currentLocationMenus;
    }),
  );

  private listenForLocationChange = LocationChangedUtils.onLocationChange(this, this.locationId$, () => {
    this._currentLocationMenus.next(null);
  });

  public currentLocationDisplayMenus$ = this.currentLocationMenus$.pipe(
    map(menus => {
      const isDisplayMenu = (menu: Menu): boolean => menu?.isDisplayMenu();
      return menus?.filter(isDisplayMenu);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public currentLocationPrintMenus$ = this.currentLocationMenus$.pipe(
    map(menus => {
      const isPrint = (menu: Menu): boolean => menu?.isPrintMenu();
      return menus?.filter(isPrint)?.sort(SortUtils.menusByNameAsc);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public currentLocationPrintReportMenus$ = this.currentLocationMenus$.pipe(
    map(menus => {
      const isPrintReport = (menu: Menu): boolean => menu?.isPrintReportMenu();
      return menus?.filter(isPrintReport)?.sort(SortUtils.menusByNameAsc);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public currentLocationPrintMenusAndPrintReportMenus$ = this.currentLocationMenus$.pipe(
    map(menus => {
      const isPrintOrPrintReport = (menu: Menu): boolean => menu?.isPrintMenuOrPrintReportMenu();
      return menus?.filter(isPrintOrPrintReport)?.sort(SortUtils.menusByNameAsc);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public currentLocationPrintCards$ = this.currentLocationMenus$.pipe(
    map(menus => {
      const isPrintCard = (menu: Menu): boolean => menu?.isPrintCardMenu();
      return menus?.filter(isPrintCard)?.sort(SortUtils.menusByNameAsc);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public currentLocationShelfTalkerMenus$ = this.currentLocationMenus$.pipe(
    map(menu => {
      const isShelfTalker = (menu: Menu): boolean => menu?.isShelfTalkerMenu();
      return menu?.filter(isShelfTalker)?.sort(SortUtils.menusByNameAsc);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public currentLocationPrintLabels$ = this.currentLocationMenus$.pipe(
    map(menus => {
      const isPrintLabel = (menu: Menu): boolean => menu?.isPrintLabelMenu();
      return menus?.filter(isPrintLabel)?.sort(SortUtils.menusByNameAsc);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public currentLocationStackedMenus$ = this.currentLocationMenus$.pipe(
    map(menus => {
      const containsStackedContent = (menu: Menu): boolean => menu?.containsStackedContent();
      return menus?.filter(containsStackedContent)?.sort(SortUtils.menusByNameAsc);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public currentLocationMarketingMenus$ = this.currentLocationMenus$.pipe(
    map(menus => {
      const isMarketing = (menu: Menu): boolean => menu?.isMarketingMenu();
      return menus?.filter(isMarketing);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public currentLocationWebMenus$ = this.currentLocationMenus$.pipe(
    map(menus => {
      const isWebMenu = (menu: Menu): boolean => menu?.isWebMenu();
      return menus?.filter(isWebMenu);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public existingTags$ = this.currentLocationMenus$.pipe(
    map(menus => Menu.getUniqueTags(menus)),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
  );

  public getMenuTagsFor(menuTypes: MenuType[]): Observable<Tag[]> {
    return this.currentLocationMenus$.pipe(
      map(menus => menus?.filter(menu => menuTypes?.includes(menu?.type) ?? false)),
      map(menus => Menu.getUniqueTags(menus)),
      distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    );
  }

  public readonly currentLocationDigitalMenus$ = this.currentLocationMenus$.pipe(
    map(menus => {
      return menus
        ?.filter(m => m?.isDisplayMenu() || m?.isMarketingMenu())
        ?.sort((a, b) => a.name.localeCompare(b.name));
    }),
  );

  // Updates Active Menu Styling
  private updateMenuStyle = new BehaviorSubject<[string, MenuStyle[]]>([null, null]);
  private updateHydratedMenuStylesMechanism = combineLatest([
    this.hydratedMenus$,
    this.updateMenuStyle,
  ]).pipe(takeUntil(this.onDestroy))
    .subscribe(([menus, [menuId, styles]]) => {
      const isMenuStyle = styles?.some(style => !style?.isTemplateStyle);
      if (!!menuId && isMenuStyle) {
        const menu = menus?.get(menuId);
        if (!!menu && menu.id === menuId) {
          menu.styling = styles;
        }
        this.updateMenuStyle.next([null, null]);
        this.updateHydratedMenuMap(menu);
      }
    });

  // Remove Active Menu Styling
  private removeMenuStyle = new BehaviorSubject<[string, MenuStyle[]]>([null, null]);
  private removeHydratedMenuStylesMechanism = combineLatest([
    this.hydratedMenus$,
    this.removeMenuStyle
  ]).pipe(
    debounceTime(100),
    map(([menus, [menuId, styles]]) => {
      const menu = menus?.get(menuId);
      const isMenuStyle = styles?.some(style => !style?.isTemplateStyle);
      if (!!menu && !!menuId && (menu.id === menuId) && isMenuStyle) {
        styles.forEach((deleteStyle) => {
          const deleteIndex = menu.styling.findIndex(s => s.id === deleteStyle.id);
          if (deleteIndex > -1) {
            menu.styling.splice(deleteIndex, 1);
          }
        });
      }
      return menu;
    }),
    takeUntil(this.onDestroy)
  ).subscribe(menu => {
    if (!!menu) {
      this.removeMenuStyle.next([null, null]);
      this.updateHydratedMenuMap(menu);
    }
  });

  public colorPalette$ = this.companyDomainModel.colorPalette$;

  setupBindings() {
    // Location Menu Updates
    this.locationId$.pipe(
      distinctUntilChanged(),
      switchMap(locationId => this.currentLocationMenus$.pipe(
        withLatestFrom(this.companyDomainModel.company$, this.userDomainModel.user$),
        tap(([menus, company, user]) => {
          const validSession = user?.session?.validSession() && exists(company);
          const fetchedMenuLocId = menus?.firstOrNull()?.locationId;
          const needsRefresh = !menus || !locationId || locationId !== fetchedMenuLocId;
          if (validSession && needsRefresh) {
            this.loadLocationMenus();
          }
        })
      ))
    ).subscribeWhileAlive({ owner: this });

    // theme updates
    combineLatest([
      this.userDomainModel.userSession$,
      this.menuThemes$,
      this.companyDomainModel.company$
    ]).pipe(
      filter(([session, themes, company]) => session?.validSession() && !themes?.length && exists(company))
    ).subscribeWhileAlive({
      owner: this,
      next: () => this.getThemes()
    });

    // Bind to active section
    combineLatest([
      this.activeHydratedMenu$.pipe(distinctUntilChanged(DistinctUtils.distinctByMenuId)),
      this.activeHydratedSection$.notNull()
    ]).pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe(([menu, section]) => {
        // Update the hydrated menu if it contains this section
        if (menu) {
          const updatedSection = menu.sections?.find(s => s.id === section.id);
          const replacementIndex = menu.sections?.indexOf(updatedSection);
          if (replacementIndex > -1) {
            menu.sections[replacementIndex] = section;
          }
        }
      });
  }

  getThemes(): void {
    this.fetchingThemes$.pipe(
      take(1),
      switchMap(fetching => {
        if (!fetching) {
          this._fetchingThemes.next(true);
          return this.menuAPI.GetMenuThemes();
        } else {
          return EMPTY;
        }
      })
    ).subscribe({
      next: (themes) => {
        this._fetchingThemes.next(false);
        this._menuThemes.next(themes);
      },
      error: (error: BsError) => {
        this._fetchingThemes.next(false);
        this.toastService.publishError(error);
        throwError(() => error);
      }
    });
  }

  public loadLocationMenus(): void {
    combineLatest([this.locationId$, this.loadingMenus$, this.menusLocationId$]).pipe(
      take(1),
      switchMap(([locId, loading, menusLocationId]) => {
        if (!loading || menusLocationId !== locId) {
          this._loadingMenus.next(true);
          this._menusLocationId.next(locId);
          return this.menuAPI.GetCompanyBasicMenus(locId);
        } else {
          return EMPTY;
        }
      })
    ).subscribe({
      next: (menus) => {
        this._currentLocationMenus.next(menus);
        this._loadingMenus.next(false);
      },
      error: (error: BsError) => {
        this._loadingMenus.next(false);
        this.toastService.publishError(error);
        throwError(() => error);
      }
    });
  }

  public deleteMenu(m: Menu): Observable<Menu> {
    return this.menuAPI.DeleteMenu(m).pipe(
      switchMap(() => this._currentLocationMenus),
      take(1),
      tap(currentLocationMenus => {
        if (m?.isTemplatedMenu()) this.domainTunnelFromMenuToTemplateService.templatedMenuDeleted(m);
        this.replaceMenus([m], true);
      }),
      map(() => m)
    );
  }

  public duplicateMenu(req: DuplicateMenuRequest): Observable<Menu> {
    return this.menuAPI.DuplicateMenu(req).pipe(
      withLatestFrom(this.companyId$, this.menusLocationId$),
      map(([newMenu, companyId, menusLocationId]) => {
        if (newMenu.locationId === menusLocationId) {
          this.replaceMenus([newMenu]);
        } else {
          // Clear cache for location so on navigation it pulls all new menus
          const cacheKey = Menu.buildArrayCacheKey(companyId, newMenu.locationId);
          this.cacheService.removeCachedObject(cacheKey);
        }
        return newMenu;
      })
    );
  }

  // Menus

  public createNewMenu(m: Menu): Observable<Menu> {
    return this.companyId$.pipe(
      take(1),
      switchMap(companyId => {
        m.companyId = companyId;
        m.tags = m.tags?.map(x => x?.toLowerCase());
        return this.menuAPI.CreateMenu(m);
      }),
      tap(newMenu => this.replaceMenus([newMenu])),
    );
  }

  public getThemeById(id: string): Observable<Theme> {
    return this.menuThemes$.pipe(
      map(themes => themes?.find(t => t?.id === id))
    );
  }

  public getTemplatedMenuSortedVariantIdsAfterDelay(
    menu: Menu,
    locationMenus: Menu[],
    delayMs: number
  ): void {
    // If published template requires sortedVariantIds, we need to fetch the location templated menus to
    // get updated sorted variant ids. Wait the delay time to allow for background SF sync to process
    const fetch = menu instanceof MenuTemplate
      && menu?.requiresSortedVariantIds()
      && menu?.status === TemplateStatus.Published;
    if (fetch) {
      const locationMenuToFetch = locationMenus?.find(m => m?.templateId === menu?.id);
      if (!!locationMenuToFetch) {
        setTimeout(() => {
          this.getHydratedMenu(locationMenuToFetch.id).once((hydratedMenu) => {
            this.replaceMenus([hydratedMenu]);
          });
        }, delayMs);
      }
    }
  }

  public getHydratedMenu(menuId: string): Observable<Menu> {
    return combineLatest([this.companyId$, this.locationId$]).pipe(
      take(1),
      switchMap(([companyId, locationId]) => {
        // Check cache for Hydrated Menu
        const cacheKey = Menu.buildCacheKey(companyId, menuId);
        const cachedMenu = this.cacheService.getCachedObject<Menu>(Menu, cacheKey) ?? new Menu();
        let topOfPipe$ = this.menuAPI.GetMenu(locationId, menuId);
        topOfPipe$ = topOfPipe$.pipe(startWith(cachedMenu));
        topOfPipe$ = topOfPipe$.pipe(
          pairwise(),
          map(([prev, curr], index) => {
            const notEqual = prev.getUniqueIdentifier() !== curr.getUniqueIdentifier();
            if (index === 0 || notEqual) {
              this.updateHydratedMenuMap(curr);
              return curr;
            }
            return prev;
          })
        );
        return topOfPipe$;
      })
    );
  }

  public getMenuAssets(isTemplate: boolean, id: string): Observable<MenuAssets> {
    return this.menuAPI.GetMenuAssets(isTemplate, id).pipe(
      map((assets) => new MenuAssets(id, assets))
    );
  }

  /**
   * @returns [assetsLoaded, menuAssets, loadedFiles]
   */
  public pollAssetsForNewFiles(menu: Menu, files: BudsenseFile[]): PollingAssets {
    return this.menuAPI.GetMenuAssets(menu instanceof MenuTemplate, menu?.id).pipe(
      map((assets) => {
        const searchFileNames = files.map(f => f.name);
        const menuAssetFileNames = assets.map(a => a.fileName);
        const difference = searchFileNames.filter(x => !menuAssetFileNames.includes(x));
        const menuAssets = new MenuAssets(menu?.id, assets);
        if (difference.length === 0) {
          return [true, menuAssets, files];
        } else if (difference.length < files.length) {
          // some files have been loaded
          const loadedFiles = files.filter(f => menuAssetFileNames.find(fn => fn === f.name));
          return [false, menuAssets, loadedFiles];
        } else {
          // no files have been loaded
          return [false, null, []];
        }
      })
    );
  }

  private replaceMenus(menus: Menu[], removeItems: boolean = false): void {
    this.currentLocationMenus$.once((existingMenus) => {
      const updatedExistingMenus = this.replaceMenusOperation(existingMenus, menus, removeItems, true);
      this._currentLocationMenus.next(updatedExistingMenus);
      this.displayDomainModel.updateMenusAttachedToDisplay(menus, removeItems);
    });
  }

  /**
   * Updated this to not change the original existing array.
   * Will return a shallow copy of the existing array with any changes.
   */
  private replaceMenusOperation(
    existingMenus: Menu[],
    newMenus: Menu[],
    removeItems: boolean = false,
    pushNewMenu: boolean = false
  ): Menu[] {
    const updatedMenus = existingMenus?.shallowCopy() || [];
    newMenus.forEach((newMenu) => {
      const oldMenu = updatedMenus?.find(d => d.id === newMenu.id);
      const replacementIndex = updatedMenus.indexOf(oldMenu);
      if (removeItems) {
        if (replacementIndex > -1) updatedMenus.splice(replacementIndex, 1);
      } else if (replacementIndex > -1) {
        updatedMenus[replacementIndex] = newMenu;
      } else if (pushNewMenu) {
        updatedMenus.push(newMenu);
      }
    });
    return updatedMenus;
  }

  public updateMenuVariantFeature(currentMenu: Menu, vf: HydratedVariantFeature): Observable<Menu> {
    if (vf?.hasChanges(currentMenu.variantFeature)) {
      currentMenu.variantFeature = vf;
      return this.saveMenu(currentMenu);
    } else {
      return of(currentMenu);
    }
  }

  public saveMenu(m: Menu): Observable<Menu> {
    return this.menuAPI.UpdateMenu(m).pipe(
      withLatestFrom(this.companyId$),
      map(([updatedMenu, companyId]) => {
        this.updateHydratedMenuMap(updatedMenu);
        this.replaceMenus([updatedMenu]);
        // Write updated hydrated menu to cache
        const cacheKey = Menu.buildCacheKey(companyId, updatedMenu.id);
        this.cacheService.cacheObject<Menu>(cacheKey, updatedMenu);
        return updatedMenu;
      })
    );
  }

  uploadMarketingMenuAsset(file: BudsenseFile, menu: Menu): Observable<any> {
    return this.companyId$.pipe(
      take(1),
      switchMap(companyId => {
        const mt = file?.isVideo() ? UploadFilePath.MarketingVideoPath : UploadFilePath.MarketingImagePath;
        const metadata = new Map<string, string>();
        metadata.set('ConfigurationId', menu?.id);
        metadata.set('CompanyId', companyId?.toString());
        return this.uploadMenuAsset(file, menu, metadata, mt);
      })
    );
  }

  uploadMenuBackgroundAsset(file: BudsenseFile, menu: Menu): Observable<any> {
    return this.companyId$.pipe(
      take(1),
      switchMap(companyId => {
        const metadata = new Map<string, string>();
        metadata.set('ConfigurationId', menu?.id);
        metadata.set('CompanyId', companyId?.toString());
        return this.uploadMenuAsset(file, menu, metadata, UploadFilePath.ConfigurationBackgroundImagePath);
      })
    );
  }

  uploadSectionAsset(
    file: BudsenseFile,
    menu: Menu,
    section: Section,
    isSecondaryImage: boolean = false
  ): Observable<any> {
    return this.companyId$.pipe(
      take(1),
      switchMap(companyId => {
        const metadata = new Map<string, string>();
        metadata.set('SectionId', section?.id);
        metadata.set('CompanyId', companyId?.toString());
        const uploadFilePath =
          isSecondaryImage ? UploadFilePath.SectionSecondaryImagePath : UploadFilePath.SectionImagePath;
        return this.uploadMenuAsset(file, menu, metadata, uploadFilePath);
      })
    );
  }

  uploadMenuAsset(
    file: BudsenseFile,
    menu: Menu,
    metadata: Map<string, string>,
    mediaClass: UploadFilePath
  ): Observable<any> {
    // Generate the upload request
    const req = new GenerateUploadUrlRequest();
    req.fileName = StringUtils.normalizeCharacters(file?.name);
    req.mediaClass = mediaClass;
    req.mediaType = file?.getMediaType();
    req.metadata = metadata;
    this.changesRequiredForPreviewService.removePreviewForAllLocations(menu);
    return this.uploadMenuAssetHelper(req, file);
  }

  private uploadMenuAssetHelper(req: GenerateUploadUrlRequest, f: BudsenseFile): Observable<any> {
    return this.imageAPI.GenerateUploadUrl(req).pipe(
      switchMap((signedUploadUrl) => {
        return this.imageAPI.PutImageUploadUrl(signedUploadUrl.url, f.url.toString(), req.fileName);
      })
    );
  }

  deleteAsset(asset: Asset, menu: Menu | MenuTemplate | null = null): Observable<any> {
    if (asset) {
      if (!!menu) {
        this.changesRequiredForPreviewService.removePreviewForAllLocations(menu);
      }
      return this.imageAPI.DeleteAsset(asset.id, asset.md5Hash);
    } else {
      return of(true);
    }
  }

  // Menu

  public selectActiveHydratedMenu(menuId: string): void {
    this._activeHydratedMenuId.next(menuId);
  }

  public deselectActiveHydratedMenu(): void {
    this._activeHydratedMenuId.next(null);
  }

  public stripMenuBackgroundAndUpdateHydratedMenuMap(menuId: string): void {
    this.hydratedMenus$.once(menus => {
      const menu = menus.get(menuId);
      if (!!menu) {
        menu.backgroundImage = null;
        this.updateHydratedMenuMap(menu);
      }
    });
  }

  private addNewSectionToHydratedMenuMap(menuId: string, section: Section | null | undefined): void {
    this.hydratedMenus$.once(menuMap => {
      const menu = menuMap.get(menuId);
      if (exists(menu) && exists(section)) {
        menu.sections = menu.sections || [];
        menu.sections.push(section);
        menu.sections.sort(SortUtils.menuSectionsByPriorityAsc);
        this.updateHydratedMenuMap(menu);
      }
    });
  }

  private updateHydratedMenuMap(m: Menu): void {
    this.hydratedMenus$.once(menus => {
      if (m) {
        menus.set(m.id, window?.injector?.Deserialize?.instanceOf(Menu, m));
        this.updateCachedMenu(m);
        this._hydratedMenus.next(menus);
      }
    });
  }

  // Menu Section

  public selectActiveHydratedSection(sectionId: string): void {
    this._activeHydratedSectionId.next(sectionId);
  }

  public deselectActiveHydratedSection(): void {
    this._activeHydratedSectionId.next(null);
  }

  private updateHydratedSectionMap(s: HydratedSection): void {
    if (s) {
      this.hydratedSections$.once(sectionMap => {
        const updatedSectionMap = sectionMap?.shallowCopy() ?? new Map<string, HydratedSection>();
        updatedSectionMap.set(s.id, window?.injector?.Deserialize?.instanceOf(HydratedSection, s));
        this._hydratedSections.next(updatedSectionMap);
      });
    }
  }

  public getHydratedSection(menuId: string, sectionId: string): Observable<HydratedSection> {
    return this.menuAPI.GetMenuSection(menuId, sectionId).pipe(
      // Apply display attributes and inventory to section products
      switchMap((section) => this.applyProductDetailsToHydratedSection(section)),
      tap(section => this.updateHydratedSectionMap(section)),
      take(1),
    );
  }

  public updateMenuSection(section: Section): Observable<HydratedSection> {
    return this.menuAPI.UpdateMenuSection(section).pipe(
      switchMap(updatedSection => this.applyProductDetailsToHydratedSection(updatedSection)),
      tap(updatedSection => this.updateHydratedSectionMap(updatedSection)),
      tap(updatedSection => this.replaceMenuSections(updatedSection.configurationId, [updatedSection])),
      take(1),
    );
  }

  public duplicateMenuSection(req: DuplicateMenuSectionRequest): Observable<HydratedSection> {
    return this.locationId$.pipe(
      take(1),
      switchMap(locationId => {
        req.locationId = locationId;
        return this.menuAPI.DuplicateMenuSection(req).pipe(
          switchMap(newSection => this.applyProductDetailsToHydratedSection(newSection)),
          tap(newSection => this.updateHydratedSectionMap(newSection)),
          tap(newSection => this.replaceMenuSections(newSection.configurationId, [newSection])),
          take(1),
        );
      })
    );
  }

  public updateCachedMenu(m: Menu): void {
    this.companyId$.once(companyId => {
      const cacheKey = Menu.buildCacheKey(companyId, m.id);
      this.cacheService.cacheObject<Menu>(cacheKey, m);
    });
  }

  public updateMenuSectionsPriority(menu: Menu, sections: Section[]): Observable<Section[]> {
    return this.menuAPI.UpdateSectionPriorities(sections).pipe(
      tap(updatedSections => this.replaceMenuSectionPriority(menu?.id, updatedSections)),
      tap(_ => {
        if (menu?.isSmartPlaylistMenu()) {
          this.changesRequiredForPreviewService.removePreviewForAllLocations(menu);
        }
      }),
      map(_ => sections),
      take(1),
    );
  }

  private replaceMenuSectionPriority(menuId: string, sections: Section[]): void {
    combineLatest([this.currentLocationMenus$, this.hydratedMenus$]).once(([currentLocMenus, hydratedMenus]) => {
      const sessionMenu = currentLocMenus?.find(m => m?.id === menuId);
      const hydratedMenu = hydratedMenus?.get(menuId);
      sections?.forEach((newSection) => {
        if (sessionMenu) this.replaceMenuSectionPriorityHelper(sessionMenu, newSection);
        if (hydratedMenu) this.replaceMenuSectionPriorityHelper(hydratedMenu, newSection);
      });
      if (sessionMenu) this.replaceMenus([sessionMenu]);
      if (hydratedMenu) this.updateHydratedMenuMap(hydratedMenu);
    });
  }

  private replaceMenuSectionPriorityHelper(menu: Menu, newSection: Section): void {
    menu?.sections?.find(s => s.id === newSection.id)?.setOrderableValue(newSection?.getOrderValue());
  }

  private replaceMenuSections(menuId: string, sections: Section[], removeItems: boolean = false): void {
    combineLatest([this.currentLocationMenus$, this.hydratedMenus$]).once(([currentLocMenus, hydratedMenus]) => {
      // Iterate through updated variants to find and replace in currentLocationProducts
      const sessionMenu = currentLocMenus?.find(m => m?.id === menuId);
      const hydratedMenu = hydratedMenus?.get(menuId);
      sections.forEach((newSection) => {
        if (sessionMenu) {
          this.replaceMenuSectionHelper(sessionMenu, newSection, removeItems);
        }
        if (hydratedMenu) {
          this.replaceMenuSectionHelper(hydratedMenu, newSection, removeItems);
        }
      });
      if (sessionMenu) {
        this.replaceMenus([sessionMenu]);
      }
      if (hydratedMenu) {
        this.updateHydratedMenuMap(hydratedMenu);
      }
    });
  }

  private replaceMenuSectionHelper(menu: Menu, newSection: Section, removeItems: boolean): void {
    const oldSection = menu?.sections?.find(s => s.id === newSection.id);
    const replacementIndex = menu?.sections?.indexOf(oldSection);
    if (removeItems) {
      if (replacementIndex > -1) menu.sections.splice(replacementIndex, 1);
    } else if (replacementIndex > -1) {
      menu.sections[replacementIndex] = newSection;
    } else {
      menu.sections.push(newSection);
    }
  }

  public createMenuSection(menuId: string, section: NewMenuSectionRequest): Observable<Section> {
    return this.menuAPI.AddMenuSection(section).pipe(
      map((menu) => {
        this.replaceMenus([menu]);
        this.updateCachedMenu(menu);
        const sections = section?.sectionType === SectionType.PageBreak
          ? menu?.sections?.filter(s => s.sectionType === SectionType.PageBreak)
          : menu?.sections?.filter(s => s.title === section.title);
        const newSection = sections?.sort(SortUtils.menuSectionsByDateCreatedDesc)?.firstOrNull();
        this.addNewSectionToHydratedMenuMap(menuId, newSection);
        return newSection;
      })
    );
  }

  public deleteMenuSection(section: Section, menu: Menu): Observable<Menu> {
    return this.menuAPI.DeleteMenuSection(section).pipe(
      tap(_ => {
        if (menu?.isSmartPlaylistMenu()) {
          this.changesRequiredForPreviewService.removePreviewForAllLocations(menu);
        }
      }),
      map((updatedMenu) => {
        this.replaceMenus([updatedMenu]);
        this.updateCachedMenu(updatedMenu);
        this.updateHydratedMenuMap(updatedMenu);
        return updatedMenu;
      })
    );
  }

  // Menu Styles

  public deleteMenuStyles(menuId: string, styles: MenuStyle[]): Observable<string> {
    return this.menuAPI.DeleteMenuStyles(styles).pipe(
      tap(_ => this.removeHydratedMenuStyles(menuId, styles))
    );
  }

  public updateMenuStyles(styles: MenuStyle[]): Observable<Map<string, MenuStyle[]>> {
    return this.menuAPI.UpdateMenuStyles(styles).pipe(
      tap((menuStyleMap) => {
        for (const [menuId, updatedStyles] of menuStyleMap.entries()) {
          this.updateHydratedMenuStyles(menuId, updatedStyles);
        }
      })
    );
  }

  public updateHydratedMenuStyles(menuId: string, styles: MenuStyle[]): void {
    if (menuId) {
      this.updateMenuStyle.next([menuId, styles]);
    }
  }

  public removeHydratedMenuStyles(menuId: string, styles: MenuStyle[]): void {
    if (menuId) {
      this.removeMenuStyle.next([menuId, styles]);
    }
  }

  public refreshImage(img: Asset): Observable<Asset> {
    return this.imageAPI.GetAsset(img.id, img.md5Hash);
  }

  // Smart Sync

  syncSectionSmartFilters(menu: Menu, section: Section): Observable<HydratedSection> {
    return this.smartFiltersDomainModel.syncSectionSmartFilters(menu, section).pipe(
      map(s => menu.updateSection(s) as HydratedSection),
      tap(s => this.updateHydratedSectionMap(s)),
      tap(_ => this.updateHydratedMenuMap(menu))
    );
  }

  public newAssetIsUnique(s: HydratedSection, f: BudsenseFile, isSecondaryImage: boolean): Observable<boolean> {
    const existingImage = isSecondaryImage ? s.image : s.secondaryImage;
    if (!!existingImage) {
      const existingImageUrl = existingImage.getAssetUrl(AssetSize.Original);
      return this.imageAPI.GetBlobFromUrl(existingImageUrl).pipe(
        switchMap((existingImageBlob) => {
          return combineLatest([
            f.getSHA256Hash(),
            MediaUtils.getSHA256FromBlob(existingImageBlob)
          ]).pipe(
            take(1),
            map(([newHash, existingHash]) => {
              return newHash !== existingHash;
            })
          );
        }),
        take(1)
      );
    } else {
      return of(true);
    }
  }

  // Templates

  public removeMenusInheritedFromTemplate(templateId: string): void {
    this.currentLocationMenus$.once(menus => {
      const menusCreatedFromTemplate = menus?.filter(m => m.templateId === templateId);
      if (menusCreatedFromTemplate?.length) this.replaceMenus(menusCreatedFromTemplate, true);
    });
  }

  public updateTemplatesAttachedToTemplatedMenus(updateTemplates: MenuTemplate[], removeItems: boolean = false): void {
    this.currentLocationMenus$.once(curLocMenus => {
      const updatedCurrentLocationMenus = curLocMenus?.shallowCopy() ?? [];
      const isTemplatedMenu = (menu: Menu): boolean => menu?.isTemplatedMenu();
      const templatedMenus = updatedCurrentLocationMenus?.filter(isTemplatedMenu);
      updateTemplates?.forEach(updatedTemplate => {
        const menusToUpdate = templatedMenus?.filter(m => m?.templateId === updatedTemplate?.id);
        menusToUpdate?.forEach(m => m.template = updatedTemplate);
      });
      this._currentLocationMenus.next(updatedCurrentLocationMenus);
    });
  }

}
