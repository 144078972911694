import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { AnimatorService } from '../../../../../../services/animator/animator.service';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { map } from 'rxjs/operators';
import { MenusContentContainerViewModel } from '../../../../../shared/components/displayable-content/displayable-content-container/menus-content-container-view-model';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';

// Provided by Logged In Scope
@Injectable()
export class PrintMenusContainerViewModel extends MenusContentContainerViewModel {

  constructor(
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    userDomainModel: UserDomainModel,
    templateDomainModel: TemplateDomainModel,
    animatorService: AnimatorService,
    activeRoute: ActivatedRoute,
  ) {
    super(
      locationDomainModel,
      menuDomainModel,
      templateDomainModel,
      userDomainModel,
      animatorService,
      activeRoute
    );
  }

  public bulkPrintJobTabSelected$ = combineLatest([
    this.selectedTab$,
    this.canUsePrintCards$,
  ]).pipe(map(([selected, canUsePrintCards]) => selected === (canUsePrintCards ? 4 : 3)));

  getMenuTypesFromSelectedTab(selectedTab: number): MenuType[] {
    switch (selectedTab) {
      case 0: return [MenuType.PrintMenu];
      case 1: return [MenuType.PrintReportMenu];
      case 2: return [MenuType.PrintCardMenu, MenuType.PrintShelfTalkerMenu];
      case 3: return [MenuType.PrintLabelMenu];
    }
  }

}
