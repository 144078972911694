import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PrintCardLiveViewModalViewModel } from '../print-cards/print-card-live-view-modal/print-card-live-view-modal-view-model';
import { PrintShelfTalkerLiveViewModalViewModel } from '../shelf-talkers/print-shelf-talker-live-view-modal/print-shelf-talker-live-view-modal-view-model';

@Pipe({
  name: 'isArrowButtonSelected'
})
export class IsArrowButtonSelectedPipe implements PipeTransform {

  transform(
    value: PrintCardLiveViewModalViewModel | PrintShelfTalkerLiveViewModalViewModel,
    item: any
  ): Observable<boolean> {
    return value?.itemSelected$(item) || of(false);
  }

}
