import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { EditVariantTerpenesViewModel } from '../edit-variant-terpenes-view-model';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { exists } from '../../../../../../../../functions/exists';
import { TerpeneUnitOfMeasure } from '../../../../../../../../models/utils/dto/terpene-unit-of-measure-type';

interface TerpenePreviewInfo {
  terpene: string;
  title: string;
  value: string;
  source: string;
}

@Injectable()
export class TerpenesPreviewViewModel extends BaseViewModel {

  constructor(
    private editVariantTerpenesViewModel: EditVariantTerpenesViewModel
  ) {
    super();
  }

  public variant$ = this.editVariantTerpenesViewModel.container.variant$;
  public companyDA$ = this.editVariantTerpenesViewModel.updatedCompanyDA$;
  public locationDA$ = this.editVariantTerpenesViewModel.updatedLocationDA$;
  public useRange$ = this.editVariantTerpenesViewModel.container.useTerpeneRange$;
  public terpeneUnitOfMeasure$ = this.editVariantTerpenesViewModel.selectedTUOM$;
  public enabledIndividualTerpeneNames$ = this.editVariantTerpenesViewModel.enabledTerpeneNames$;
  public hasEnabledTerpenes$ = this.editVariantTerpenesViewModel.hasEnabledTerpenes$;

  public totalTerpenePreviewInfo$ = this.useRange$.pipe(
    switchMap(useRange => {
      return this.createTerpenePreviewsFromList$([{
        terpeneName: 'Total Terpenes',
        camelCased: 'totalTerpene',
        pascalCased: 'TotalTerpene'
      }], useRange, false);
    })
  );

  public topTerpenePreviewInfo$ = this.createTerpenePreviewsFromList$([{
    terpeneName: 'Top Terpene',
    camelCased: 'topTerpene',
    pascalCased: 'TopTerpene'
  }], false, false);

  public individualTerpenesPreviewInfo$ = combineLatest([
    this.enabledIndividualTerpeneNames$,
    this.useRange$,
  ]).pipe(
    switchMap(([individualTerpeneNames, useRange]) => {
      return this.createTerpenePreviewsFromList$(individualTerpeneNames, useRange);
    })
  );

  private createTerpenePreviewsFromList$(
    terpenes: {terpeneName: string, pascalCased: string, camelCased: string}[],
    useRange: boolean,
    includeTUOM: boolean = true
  ): Observable<TerpenePreviewInfo[]> {
    if (useRange) {
      const rangePreviewObservables = terpenes?.flatMap(terpene => [
        this.createTerpenePreviewInfo$(
          'min' + terpene?.pascalCased,
          'Min ' + terpene?.terpeneName,
          includeTUOM
        ),
        this.createTerpenePreviewInfo$(
          'max' + terpene?.pascalCased,
          'Max ' + terpene?.terpeneName,
          includeTUOM
        ),
      ]);
      return combineLatest(rangePreviewObservables);
    } else {
      const previewObservables = terpenes.map(terpene => {
        return this.createTerpenePreviewInfo$(
          terpene?.camelCased,
          terpene?.terpeneName,
          includeTUOM
        );
      });
      return combineLatest(previewObservables);
    }
  }

  private createTerpenePreviewInfo$(
    terpene: string,
    title: string,
    includeTUOM: boolean = true
  ): Observable<TerpenePreviewInfo> {
    return combineLatest([
      this.companyDA$,
      this.locationDA$,
      this.terpeneUnitOfMeasure$
    ]).pipe(
      map(([ companyDA, locationDA, tuom]) => {
        const companyDATerpene = companyDA?.[terpene];
        const locationDATerpene = locationDA?.[terpene];
        let val: string;
        let source: string;
        if (exists(locationDATerpene)) {
          val = locationDATerpene;
          source = '(Location)';
        } else if (exists(companyDATerpene)) {
          val = companyDATerpene;
          source = '(Company)';
        }
        const infoUOM = includeTUOM ? tuom : '';
        const info = (exists(val) && tuom !== TerpeneUnitOfMeasure.NA && tuom !== TerpeneUnitOfMeasure.UNKNOWN)
            ? val + infoUOM
            : '--';
        if (info === '--') source = '';
        return {
          terpene: terpene,
          title: title,
          value: info,
          source: source,
        };
      })
    );
  }

  private createPresentTerpenePreviewInfo$(
    terpene: string,
    title: string,
  ): Observable<TerpenePreviewInfo> {
    return combineLatest([
      this.companyDA$,
      this.locationDA$,
    ]).pipe(
      map(([companyDA, locationDA]) => {
        const companyDATerpene = companyDA?.[terpene] ?? '0';
        const locationDATerpene = locationDA?.[terpene] ?? '0';
        let val: string;
        if (exists(locationDATerpene) && locationDATerpene !== '0') {
          val = locationDATerpene;
        } else if (exists(companyDATerpene) && companyDATerpene !== '0') {
          val = companyDATerpene;
        }
        return {
          terpene: terpene,
          title: exists(val) ? title : '--',
          value: null,
          source: null,
        };
      })
    );
  }

}
