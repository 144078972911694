import { TypeDefinition } from './type-definition';

export enum DefaultShelfTalkerPaperSize {
  DefaultSize_LetterPerforated_Uline = 'DefaultSize_LetterPerforated_Uline',
  DefaultSize_Letter_CustomCut = 'DefaultSize_Letter_CustomCut'
}

export class DefaultShelfTalkerPaperSizeType extends TypeDefinition {

    override value: DefaultShelfTalkerPaperSize;

}
