import { Injectable, Injector, NgZone } from '@angular/core';
import { EditTemplateMenuViewModel } from '../edit-template-menu-view-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationChangedUtils } from '../../../../../utils/location-changed-utils';
import { EditCardStackMenuViewModel } from '../../../../menu/components/edit-menu/edit-card-stack-menu/edit-card-stack-menu-view-model';
import { EditCardStackSectionViewModel } from '../../../../menu/components/edit-menu/edit-card-stack-menu/edit-card-stack-section-view-model';

@Injectable()
export class EditTemplateCardStackMenuViewModel extends EditTemplateMenuViewModel {

  /*
   * Need editCardStackMenuViewModel and editCardStackSectionViewModel injected here so that it's
   * scoped to this template. This allows child components that require these view models
   * to share the same instance.
   */
  constructor(
    protected editCardStackMenuViewModel: EditCardStackMenuViewModel,
    protected editCardStackSectionViewModel: EditCardStackSectionViewModel,
    templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(editCardStackMenuViewModel, templateDomainModel, locationDomainModel, ngZone, ngbModal, injector);
    editCardStackSectionViewModel.connectToTemplateMode(true);
    editCardStackSectionViewModel.connectToAllowAutoSaving(false);
  }

  public readonly sectionTemplateId$ = this.templateDomainModel.activeSectionTemplateId$;
  connectToSortedVariantIds = this.editCardStackMenuViewModel.connectToSortedVariantIds;
  showLiveView = this.editCardStackMenuViewModel.showLiveView;

  override saveTemplate = (background: boolean) => {
    this.editCardStackSectionViewModel.saveStaticSectionMenu(background);
  };

  loadSectionTemplate = (locationId: number, menuTemplateId: string, sectionTemplateId: string,) => {
    this.editCardStackSectionViewModel.loadSectionTemplate(locationId, menuTemplateId, sectionTemplateId);
  };

  /* *********************** Local Threads of Execution *********************** */

  private fetchSectionTemplateWhenLocationChanges = LocationChangedUtils
    .loadSectionTemplate(this, this.locId$, this.menuTemplateId$, this.sectionTemplateId$, this.loadSectionTemplate);

  /* ************************************************************************** */

  override destroy() {
    super.destroy();
    this.templateDomainModel.clearActiveSectionTemplate();
  }

}
