import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { ColumnOptionsViewModel } from '../../../../../viewModels/column-options-view-model';
import { startWith, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Injectable()
export class CannabinoidsColumnOptionsFormViewModel extends BaseViewModel {

  constructor(
    public container: ColumnOptionsViewModel
  ) {
    super();
  }

  public primaryCannabinoidColumnConfigsAndKeys$ = window.types.cannabinoidSectionColumnConfigKeys$.pipe(
    switchMap(keys => {
      return this.container.makeColumnOptionFormFromKeys$(keys?.filter(keyType => keyType?.isPrimaryCannabinoidKey()));
    }),
    startWith([])
  );

  public secondaryCannabinoidColumnConfigsAndKeys$ = combineLatest([
    this.container.enabledSecondaryCannabinoids$,
    window.types.cannabinoidSectionColumnConfigKeys$
  ]).pipe(
    switchMap(([secondaryCannabinoids, keys]) => {
      const filteredKeys = keys
        ?.filter(keyType => keyType?.isSecondaryCannabinoidKey())
        ?.filter(keyType => {
          const secondaryCannabinoidValues = secondaryCannabinoids?.map(sc => sc?.getSelectionValue());
          const keyTypeValue = keyType?.getSelectionValue();
          return secondaryCannabinoidValues?.includes(keyTypeValue);
        });
      return this.container.makeColumnOptionFormFromKeys$(filteredKeys);
    }),
    startWith([])
  );

}
