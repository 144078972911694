<div class="inline-picker-container" [ngClass]="{ disabled: disabled }">
  <span [style.display]="'contents'" [style.pointer-events]="disabled ? 'none' : ''">
    <div *ngIf="(badges$ | async)?.length === 0 || !(badges$ | async)" class="f12px bs-medium">{{ addText }}</div>
    <div
      *ngIf="(badges$ | async)?.length > 0 && !!(badges$ | async)"
      class="badge-list"
      [class.flex-wrap-content]="wrapBadges">
      <ng-container *ngFor="let badge of badges$ | async">
        <div
          class="badge-img me-2"
          [class.added-by-smart-filter]="badge | inlineBadgePickerCheckIfBadgeIsSmart : DA$ | async"
          [ngbTooltip]="(badge | inlineBadgePickerCheckIfBadgeIsSmart : DA$ | async) ? smartBadgeTooltipText : null"
          [placement]="'right'"
          [container]="'body'">
          <app-asset [asset]="badge?.image" [scaleFit]="true"> </app-asset>
          <app-remove-button
            *ngIf="!(badge | inlineBadgePickerCheckIfBadgeIsSmart : DA$ | async)"
            appThrottleClick
            class="remove-button"
            [width]="'1rem'"
            [height]="'1rem'"
            [throttleInMilliSec]="1000"
            (throttleClick)="updateDisplayAttribute(badge)">
          </app-remove-button>
          <app-badge-added-by-smart-filter-indicator *ngIf="badge | inlineBadgePickerCheckIfBadgeIsSmart : DA$ | async">
            Smart Badge Indicator
          </app-badge-added-by-smart-filter-indicator>
        </div>
      </ng-container>
    </div>

    <app-inline-picker-action-button
      *ngIf="(badges$ | async)?.length < maxBadgesAllowed"
      [disabled]="disabled"
      [canEdit]="!!(badges$ | async) && (badges$ | async)?.length > 0"
      [popper]="popperContent"
      [popperShowOnStart]="false"
      [popperTrigger]="ClientTypes.NgxPopperjsTriggers.click"
      [popperModifiers]="popperModifier"
      [popperHideOnClickOutside]="true"
      [popperHideOnScroll]="false"
      [popperPreventOverflow]="false"
      [popperPositionFixed]="true"
      [popperApplyClass]="'badge-picker-popper'"
      [popperStyles]="popperStyles"
      [popperPlacement]="popperPlacement"
      (click)="setPopperState()">
    </app-inline-picker-action-button>
    <popper-content #popperContent [style.position]="'fixed'" [style.z-index]="1050">
      <app-badge-picker
        *ngIf="(started$ | async) && (contentAliveWhileClosed || (popperState$ | async))"
        class="badge-picker"
        [popperOpen]="popperContent.state"
        [preselectedBadgeIds]="badgeIds$ | async"
        [maximumNumberOfBadges]="maxBadgesAllowed"
        [removeSmartBadgesFromPicker]="removeSmartBadgesFromPicker"
        (selected)="updateDisplayAttribute($event)">
      </app-badge-picker>
    </popper-content>
  </span>
</div>
