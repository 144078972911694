<div
  matRipple
  class="selection-container"
  [style.cursor]="(viewModel.viewOnly$ | push) ? 'not-allowed' : 'pointer'"
  (click)="viewModel.bulkAddOrRemoveSelections([section?.id])">
  <div class="title-and-size bs-medium">
    <span class="f12px">{{ section?.title }}</span>
  </div>
  <app-selectable
    [selectedIds]="viewModel.stackSelectedItemIds$ | push"
    [selection]="section"
    (add)="viewModel.bulkAddSelections([$event])"
    (remove)="viewModel.bulkRemoveSelections([$event])">
  </app-selectable>
</div>
