import { HydratedSection } from '../../menu/dto/hydrated-section';
import { SectionType } from '../../enum/dto/section-type';

export class SectionTemplate extends HydratedSection {

  public allowAddProducts: boolean;
  public allowBadgeOverride: boolean;
  public allowLabelOverride: boolean;
  public allowStyleOverride: boolean;

  override onDeserialize() {
    super.onDeserialize();
    if (!this.allowAddProducts) this.allowAddProducts = false;
    if (!this.allowBadgeOverride) this.allowBadgeOverride = false;
    if (!this.allowLabelOverride) this.allowLabelOverride = false;
    if (!this.allowStyleOverride) this.allowStyleOverride = false;
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/TemplateSectionDTO.go
  override onSerialize(): any {
    const dto = Object.assign(new SectionTemplate(), super.onSerialize());
    dto.allowAddProducts = this.allowAddProducts;
    dto.allowBadgeOverride = this.allowBadgeOverride;
    dto.allowLabelOverride = this.allowLabelOverride;
    dto.allowStyleOverride = this.allowStyleOverride;
    return dto;
  }

  override getEditSectionName(): string {
    switch (this.sectionType) {
      case SectionType.NewProducts:
      case SectionType.Product:
      case SectionType.RestockedProducts:   return 'Edit Template - Product Section';
      case SectionType.Spotlight:           return 'Edit Template - Spotlight Section';
      case SectionType.Title:               return 'Edit Template - Title Section';
      case SectionType.Media:               return 'Edit Template - Media Section';
      case SectionType.PageBreak:           return 'Edit Template - Page Break Section';
      case SectionType.CategoryCard:        return 'Edit Template - Category Card';
      case SectionType.ProductGroup:        return 'Edit Template - Product Grouping';
      case SectionType.ShelfTalker:         return 'Edit Template - Shelf Talker';
    }
    return '';
  }

  override getUniqueIdentifier(): string {
    return `
      -${super.getUniqueIdentifier()}
      -${this.allowAddProducts}
      -${this.allowBadgeOverride}
      -${this.allowLabelOverride}
      -${this.allowStyleOverride}
    `;
  }

}
