import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, NgZone, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { SmartFilterLineItemViewModel } from './smart-filter-line-item-view-model';
import { AddEditSmartFilterModalOpenedFrom } from '../../../../models/automation/enum/add-edit-smart-filter-modal-opened-from';
import { take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalEditSmartFilter } from '../../../../modals/modal-edit-smart-filter';

@Component({
  selector: 'app-smart-filter-line-item',
  templateUrl: './smart-filter-line-item.component.html',
  styleUrls: ['./smart-filter-line-item.component.scss'],
  providers: [SmartFilterLineItemViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterLineItemComponent extends BaseComponent {

  @Input() smartFilterId: string;
  @Output() smartFilterRemoved = new EventEmitter();

  constructor(
    public viewModel: SmartFilterLineItemViewModel,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  override setupViews(): void {
    this.viewModel.setSmartFilterId(this.smartFilterId);
  }

  removeSmartFilter(): void {
    this.smartFilterRemoved.emit(this.smartFilterId);
  }

  editSmartFilter(): void {
    this.viewModel.smartFilter$.pipe(take(1)).subscribe((smartFilter) => {
      ModalEditSmartFilter.open(
        this.ngZone,
        this.ngbModal,
        this.injector,
        AddEditSmartFilterModalOpenedFrom.Settings,
        smartFilter
      );
    });
  }

}
