import { Pipe, PipeTransform } from '@angular/core';
import { SectionLayout, SectionLayoutType } from '../../../models/utils/dto/section-layout-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'flowerGramsToOunces'
})
export class FlowerGramsToOuncesPipe implements PipeTransform {

  transform(value: string, layoutType$:  Observable<SectionLayoutType>): Observable<string> {
    return layoutType$.pipe(
      map(layoutType => {
        if (layoutType.value === SectionLayout.ClassicFlowerGrid) {
          return this.convertGramsToOunces(value);
        }
        return value;
      })
    );
  }

  private convertGramsToOunces(value: string): string {
    switch (value) {
      case '3.5 g': return '1/8 oz';
      case '7 g':   return '1/4 oz';
      case '14 g':  return '1/2 oz';
      case '28 g':  return '1 oz';
    }
    return value;
  }

}
