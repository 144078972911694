import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { BulkPrintJobType } from '../../../../../models/automation/enum/bulk-print-job-type';
import { ChooseBulkPrintJobTypeViewModel } from './choose-bulk-print-job-type-view-model';
import { BaseComponent } from '../../../../../models/base/base-component';

@Component({
  selector: 'app-choose-bulk-print-job-type',
  templateUrl: './choose-bulk-print-job-type.component.html',
  providers: [ChooseBulkPrintJobTypeViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseBulkPrintJobTypeComponent extends BaseComponent {

  constructor(
    public viewModel: ChooseBulkPrintJobTypeViewModel
  ) {
    super();
  }

  @Output() bulkPrintJobTypeSelected = new EventEmitter<BulkPrintJobType>();

}
