import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { ModalUtils } from '../utils/modal-utils';
import { Location } from '../models/company/dto/location';
import { TemplateViewLocationsComponent } from '../views/template/components/shared/template-view-locations/template-view-locations.component';
import { MenuTemplate } from '../models/template/dto/menu-template';

export class ModalTemplateViewProvinceLocations {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    template: MenuTemplate,
    requiredLocationIds: number[],
    province: string,
    locations: Location[]
  ) {
    ngZone.run(() => {
      const modalRef = ngbModal.open(
        TemplateViewLocationsComponent,
        ModalUtils.templateViewLocationsModalOptions(injector)
      );
      const compInst = modalRef.componentInstance as TemplateViewLocationsComponent;
      compInst.viewModel.connectToTemplate(template);
      compInst.viewModel.connectToRequiredLocationIds(requiredLocationIds);
      compInst.viewModel.connectToProvince(province);
      compInst.viewModel.connectToLocations(locations);
    });
  }

}
