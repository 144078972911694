import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ProductsTableComponent } from '../../all-products-data-table/products-table/products-table.component';
import { Product } from '../../../../models/product/dto/product';
import { HorizontalScrollingReactiveTableComponent } from '../../../shared/components/horizontal-scrolling-reactive-table/horizontal-scrolling-reactive-table.component';

@Component({
  selector: 'app-product-picker-table',
  templateUrl: './product-picker-table.component.html',
  styleUrls: ['./product-picker-table.component.scss'],
  providers: [
    {
      provide: ProductsTableComponent,
      useExisting: forwardRef(() => ProductPickerTableComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductPickerTableComponent extends ProductsTableComponent {

  @ViewChild('productPickerTable') reactiveTable: HorizontalScrollingReactiveTableComponent;
  @Input() override nItemsToDisplay: number;
  @Input() override resetPageOnNewData: boolean = false;
  @Input() override resetPageOnSortingChange: boolean = true;
  @Input() filterHeight: number = 0;
  @Input() productSelectionOnly: boolean = false;
  @Output() dataBeingDisplayed = new EventEmitter<Product[]>(true);

  override setupBindings() {
    super.setupBindings();
    this.reactiveTable?.dataToDisplay$?.subscribeWhileAlive({
      owner: this,
      next: data => this.dataBeingDisplayed.emit(data)
    });
  }

}
