import { Component } from '@angular/core';
import { MakeVariantActiveModalViewModel } from './make-variant-active-modal-view-model';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VariantInventory } from '../../../../../models/product/dto/variant-inventory';

@Component({
  selector: 'app-make-active-modal',
  templateUrl: './make-variant-active-modal.component.html',
  styleUrls: ['./make-variant-active-modal.component.scss'],
  providers: [MakeVariantActiveModalViewModel]
})
export class MakeVariantActiveModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: MakeVariantActiveModalViewModel,
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  setInventories(inventories: VariantInventory[]) {
    this.viewModel.connectToInventories(inventories);
  }

}
