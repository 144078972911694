import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { NumberUtils } from '../../../utils/number-utils';
import { RangeSliderOptions } from '../../../models/shared/stylesheet/range-slider-options';
import { BaseViewModel } from '../../../models/base/base-view-model';

@Injectable()
export abstract class ScalableLiveViewModalViewModel extends BaseViewModel {

  abstract itemSelected$(item: any): Observable<boolean>;

  /* ******************************* Scaling ******************************* */

  private readonly _smallestScaleValue = new BehaviorSubject<number | null>(1);
  public readonly smallestScaleValue$ = this._smallestScaleValue as Observable<number|null>;
  connectToSmallestScaleValue = (smallestScaleValue: number|null) => this._smallestScaleValue.next(smallestScaleValue);

  private readonly _userSetScale = new BehaviorSubject<number|null>(null);
  public readonly userSetScale$ = this._userSetScale as Observable<number|null>;
  connectToUserSetScale = (userSetScale: number|null) => this._userSetScale.next(userSetScale);

  private readonly _cardHeight = new BehaviorSubject<number|null>(null);
  public readonly cardHeight$ = this._cardHeight as Observable<number|null>;
  connectToCardHeight = (cardWidth: number|null) => this._cardHeight.next(cardWidth);

  private readonly _cardWidth = new BehaviorSubject<number|null>(null);
  public readonly cardWidth$ = this._cardWidth as Observable<number|null>;
  connectToCardWidth = (cardWidth: number|null) => this._cardWidth.next(cardWidth);

  public readonly userHasSetScale$ = this.userSetScale$.pipe(map(scale => Number.isFinite(scale)));

  private readonly _resetScale = new Subject<void>();
  public readonly resetScale$ = this._resetScale as Observable<void>;
  resetScale = () => {
    this.connectToUserSetScale(null);
    this._resetScale.next();
  };

  public readonly scaleRangeOptions$ = this.resetScale$.pipe(
    startWith(null),
    switchMap(() => {
      return this.smallestScaleValue$.pipe(
        distinctUntilChanged(),
        map(smallestScaleValue => {
          const smallest = NumberUtils.roundToTwoDecimalPlaces(smallestScaleValue || 1);
          const opts = RangeSliderOptions.default(smallest, 2, 0.01);
          opts.value = smallest;
          opts.label = 'Scale:';
          return opts;
        })
      );
    })
  );

  public readonly verticalCenteringOffset$ = this.cardHeight$.pipe(
    map(cardHeight => `calc(50% - ${(cardHeight ?? 0) / 2}px)`)
  );

  public readonly horizontalCenteringOffset$ = combineLatest([
    this.cardWidth$,
    this.smallestScaleValue$
  ]).pipe(
    map(([cardWidth, parentScaling]) => {
      const width = (cardWidth ?? 0) * (parentScaling ?? 1);
      return `calc(50% - ${width / 2}px)`;
    })
  );

  /* *************************** Local Threads of Execution *************************** */

  private readonly resetUserSetScale = fromEvent(window, 'resize')
    .pipe(debounceTime(100))
    .subscribeWhileAlive({
      owner: this,
      next: () => this.connectToUserSetScale(null)
    });

}
