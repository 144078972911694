<div class="mt-16px f16px bs-bold">Applied Products</div>

<ng-container *rxIf="(viewModel.variants$ | push)?.length; else emptyState">
  <app-stack-applied-product-list-item
    *rxFor="let variant of viewModel.variants$ | push; trackBy: 'id'"
    [itemCountMap]="viewModel.variantCountMap$ | push"
    [item]="variant"
    [stackType]="viewModel.stackType$ | push">
  </app-stack-applied-product-list-item>
</ng-container>

<ng-template #emptyState>
  <ng-container *rxIf="viewModel.isSearching$; else noVariants">
    <div class="d-flex justify-content-center mt-16px f14px bs-medium">
      <div>{{ viewModel.noVariantsForSearchPlaceholder$ | push }}</div>
    </div>
  </ng-container>
  <ng-template #noVariants>
    <div class="d-flex justify-content-center mt-16px f14px bs-medium">
      <div>{{ viewModel.noVariantsPlaceholder$ | push }}</div>
    </div>
  </ng-template>
</ng-template>
