import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DefaultPrintCardSizeType } from '../../../../models/utils/dto/default-print-card-size-type';
import { DefaultPrintLabelSizeType } from '../../../../models/utils/dto/default-print-label-size-type';
import { DefaultShelfTalkerSizeType } from '../../../../models/utils/dto/default-shelf-talker-size-type';

@Component({
  selector: 'app-card-stack-size-selection',
  templateUrl: './card-stack-size-selection.component.html',
  styleUrls: ['./card-stack-size-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardStackSizeSelectionComponent {

  @Input() cardSize: DefaultPrintCardSizeType | DefaultShelfTalkerSizeType | DefaultPrintLabelSizeType;
  @Input() disabled: boolean = false;
  @Input() displayOnly: boolean = false;
  @Input() selected: boolean = false;

}
