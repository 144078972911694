import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuPreviewComponent } from '../menu-preview/menu-preview.component';
import { ShelfTalkerPreviewViewModel } from './shelf-talker-preview-view-model';

@Component({
  selector: 'app-shelf-talker-preview',
  templateUrl: '../displayable-item-preview/displayable-item-preview.component.html',
  styleUrls: [
    '../displayable-item-preview/displayable-item-preview.component.scss',
    './shelf-talker-preview.component.scss'
  ],
  providers: [ShelfTalkerPreviewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShelfTalkerPreviewComponent extends MenuPreviewComponent {

  constructor(
    public override viewModel: ShelfTalkerPreviewViewModel
) {
    super(viewModel);
  }

}
