import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EditMenuWithMenuAndSectionCombinedTogetherViewModel } from '../shared/edit-menu-and-section-combined-together-menu/edit-menu-with-menu-and-section-combined-together-view-model';

@Injectable()
export class EditSpotlightMenuViewModel extends EditMenuWithMenuAndSectionCombinedTogetherViewModel {

  public override showWillNotAppearOnMenuSection$ = new BehaviorSubject<boolean>(true);

}
