import { BehaviorSubject, defer } from 'rxjs';
import { LoadingOptions } from '../shared/loading-options';
import { map, startWith } from 'rxjs/operators';
import { FoundationBaseViewModel } from '@mobilefirstdev/base-angular';
import { Injectable } from '@angular/core';

@Injectable()
export class BaseViewModel extends FoundationBaseViewModel {

  protected _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.default());
  loadingOpts$ = defer(() => this._loadingOpts);
  isLoading$ = defer(() => this.loadingOpts$).pipe(map(it => it?.isLoading), startWith(false));

}
