import { Injectable, Injector, NgZone } from '@angular/core';
import { EditCardStackPrintSectionViewModel } from '../../edit-card-stack-menu/edit-card-stack-print-section/edit-card-stack-print-section-view-model';
import { BulkPrintJobDomainModel } from '../../../../../../domainModels/bulk-print-job-domain-model';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { EditLabelStackMenuViewModel } from '../edit-label-stack-menu-view-model';

@Injectable()
export class EditLabelStackPrintSectionViewModel extends EditCardStackPrintSectionViewModel {

  constructor(
    protected override bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    protected editLabelStackMenuViewModel: EditLabelStackMenuViewModel,
    protected override injector: Injector,
    protected override menuDomainModel: MenuDomainModel,
    protected override ngZone: NgZone,
    protected override ngbModal: NgbModal,
    protected override templateDomainModel: TemplateDomainModel
  ) {
    super(
      bulkPrintJobDomainModel,
      editLabelStackMenuViewModel,
      injector,
      menuDomainModel,
      ngZone,
      ngbModal,
      templateDomainModel
    );
  }

}
