import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';

@Pipe({
  name: 'nShelfTalkersSelected'
})
export class NShelfTalkersSelectedPipe implements PipeTransform {

  transform(menu: Menu, nSelected: number): string {
    const nCards =  menu?.getSectionsBasedOnMenuType()?.length ?? 0;
    let nCardsString = '';
    if (nSelected > 0) nCardsString += `${nSelected} of `;
    nCardsString += `${nCards} card`;
    return nCardsString
      .pluralizer()
      .addRule({ isPlural: nCards > 1, listConnection: null, useApostrophe: false, word: 'card' })
      .pluralize();
  }

}
