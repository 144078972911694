import { Injectable, Injector, NgZone } from '@angular/core';
import { Breadcrumb } from '../../../../models/shared/stylesheet/breadcrumb';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, delay, map, pairwise, take } from 'rxjs/operators';
import { TemplateCollectionDomainModel } from '../../../../domainModels/template-collection-domain-model';
import { ModalPublishCollection } from '../../../../modals/modal-publish-collection';
import { EditDisplayViewModel } from '../../../display/viewModels/edit-display-view-model';
import { AutoSaveViewModel } from '../../../shared/components/auto-save/auto-save-view-model';
import { DisplayDomainModel } from '../../../../domainModels/display-domain-model';
import { ToastService } from '../../../../services/toast-service';
import { TemplateCollection } from '../../../../models/template/dto/template-collection';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { NavigationService } from '../../../../services/navigation.service';
import { TemplateStatus } from '../../../../models/template/enum/template-status.enum';
import { PendingDisplay } from '../../../../models/template/dto/pending-display';
import { ModalCollectionSaveConfirmation } from '../../../../modals/modal-collection-save-confirmation';
import { DistinctUtils } from '../../../../utils/distinct-utils';
import { ModalPromptForCollectionDelete } from '../../../../modals/modal-prompt-for-collection-delete';
import { BsError } from '../../../../models/shared/bs-error';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';

@Injectable()
export class EditCollectionViewModel extends AutoSaveViewModel {

  constructor(
    private editDisplayViewModel: EditDisplayViewModel,
    private userDomainModel: UserDomainModel,
    private displayDomainModel: DisplayDomainModel,
    private templateCollectionDomainModel: TemplateCollectionDomainModel,
    private navigationService: NavigationService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private injector: Injector,
    private ngZone: NgZone,
    private ngbModal: NgbModal
  ) {
    super();
    this.leaveIfCollectionNotFound();
    this.editDisplayViewModel.connectToCollectionMode(true);
    this.connectToAllowAutoSaving(false);
    this.getCompanyDisplays();
    this.resetPendingDisplayChangesWhenCollectionChanges();
  }

  resetPendingDisplayChangesWhenCollectionChanges() {
    this.collection$.pipe(pairwise()).subscribeWhileAlive({
      owner: this,
      next: ([prev, curr]) => {
        if (!DistinctUtils.distinctUniquelyIdentifiable(prev, curr)) {
          this._updatedPendingDisplay.next(null);
          this._updatedRequiredDisplayIds.next(null);
        }
      }
    });
  }

  private leaveIfCollectionNotFound() {
    combineLatest([
      this.userDomainModel.validSession$,
      this.editDisplayViewModel.displayId$,
      this.collections$,
    ]).pipe(debounceTime(1))
      .subscribeWhileAlive({
        owner: this,
        next: ([validSession, displayId, collections]) => {
          const validDisplayId = !!displayId;
          const validLocationDisplays = !!collections;
          const invalidDisplay = !(validDisplayId && this.editDisplayViewModel.displayExists(collections, displayId));
          if (invalidDisplay && validSession && validLocationDisplays) {
            this.unsavedChanges = false;
            this.navigationService.collections();
          }
        }
      });
  }

  public collection$: Observable<TemplateCollection> = this.editDisplayViewModel.display$.pipe(
    map((display) => ((display instanceof TemplateCollection) ? display : null)),
  );
  public collections$ = this.templateCollectionDomainModel.templateCollections$;

  public collectionIsEmpty$ = this.collection$.pipe(map((c) => !c?.templates?.length));

  private _updatedPendingDisplay = new BehaviorSubject<PendingDisplay>(null);
  public updatedPendingDisplay$ = this._updatedPendingDisplay as Observable<PendingDisplay>;
  connectToUpdatedPendingDisplay = (pd: PendingDisplay) => this._updatedPendingDisplay.next(pd);

  public pendingDisplay$ = combineLatest([this.collection$, this.updatedPendingDisplay$]).pipe(
    map(([collection, updatedPendingDisplay]) => {
      return !!updatedPendingDisplay ? updatedPendingDisplay : collection?.pendingDisplay;
    })
  );

  private _updatedRequiredDisplayIds = new BehaviorSubject<string[]>(null);
  public updatedRequiredDisplayIds$ = this._updatedRequiredDisplayIds as Observable<string[]>;
  connectToUpdatedRequiredDisplayIds = (ids: string[]) => this._updatedRequiredDisplayIds.next(ids);

  public breadcrumbs$ = this.collection$.pipe(
    map((collection) => {
      const breadcrumbs = [];
      const dispBc = new Breadcrumb(
        'Digital Templates',
        'templates/digital',
        'templateCollections'
      );
      breadcrumbs.push(dispBc);
      const editDispBc = new Breadcrumb(
        'Edit Template Collection',
        `templates/digital/${collection?.id}`,
      );
      editDispBc.active = true;
      breadcrumbs.push(editDispBc);
      return breadcrumbs;
    })
  );

  public readonly everyTemplateHasBeenPublished$ = this.collection$.pipe(
    map(collection => collection?.templates?.every(template => template?.isPublished()))
  );

  public readonly publishButtonDisabledText$ = this.everyTemplateHasBeenPublished$.pipe(
    map(everyTemplateHasBeenPublished => {
      if (!everyTemplateHasBeenPublished) {
        return 'All templates must be published before publishing the collection';
      }
    })
  );

  public getCompanyDisplays(): void {
    const lm = 'Loading Displays';
    this._loadingOpts.addRequest(lm);
    this.displayDomainModel.getCompanyDisplays().pipe(
      take(1)
    ).subscribe({
      complete: () => {
        this._loadingOpts.removeRequest(lm);
      },
      error: (err) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(err);
      }
    });
  }

  public openPublishCollectionModal(): void {
    this.collection$.once((c) => {
      ModalPublishCollection.open(this.ngZone, this.ngbModal, this.injector, c);
    });
  }

  public showLiveView(): void {
    this.editDisplayViewModel.showLiveView();
  }

  public promptForDeleteCollection(): void {
    this.collection$.once(collection => {
      const confirmation = (cont: boolean) => { if (cont) { this.deleteCollection(); } };
      ModalPromptForCollectionDelete.open(this.ngZone, this.ngbModal, this.injector, collection, confirmation);
    });
  }

  public deleteCollection() {
    this.collection$.once((collection) => {
      const lm = 'Deleting Template Collection';
      if (!this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.addRequest(lm);
        this.templateCollectionDomainModel.deleteTemplateCollection(collection).pipe(delay(250)).subscribe({
          complete: () => {
            this.unsavedChanges = false;
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishSuccessMessage('Template Collection deleted successfully', 'Collection Deleted');
            this.navigationService.collections();
          },
          error: (error: BsError) => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishError(error);
            throwError(error);
          }
        });
      }
    });
  }

  override destroy(): void {
    super.destroy();
    this.templateCollectionDomainModel.clearActiveCollection();
  }

  saveChanges(formCapture: ReactiveFormMergeGroupsComponent): void {
    combineLatest([
      this.collection$,
      this.updatedRequiredDisplayIds$,
      this.updatedPendingDisplay$,
      this.pendingDisplay$
    ]).once(([
      collection,
      updatedRequiredDisplayIds,
      updatedPendingDisplay,
      pendingDisplay
    ]) => {
      const confirm = this.shouldOpenConfirmationModal(collection, updatedRequiredDisplayIds, updatedPendingDisplay);
      if (!confirm) {
        formCapture.submitForms(false);
        return;
      }
      const onConfirmation = (result) => {
        if (result) formCapture.submitForms(false);
      };
      ModalCollectionSaveConfirmation.open(
        this.ngZone,
        this.ngbModal,
        this.injector,
        collection?.requiredDisplayIds,
        updatedRequiredDisplayIds,
        pendingDisplay,
        onConfirmation
      );
    });
  }

  private shouldOpenConfirmationModal(
    collection: TemplateCollection,
    updatedIds: string[],
    updatedPendingDisplay: PendingDisplay
  ): boolean {
    if (collection?.status === TemplateStatus.Draft) return false;

    const originalIds = collection?.requiredDisplayIds;
    const idsUpdated = updatedIds?.length > 0;
    const requiredDisplaysStartedEmptyThenDisplaysAddedThenFinishedEmpty = !!updatedIds && !idsUpdated && !originalIds;
    const originalPendingDisplay = collection?.pendingDisplay;
    const same = DistinctUtils.distinctUniquelyIdentifiable;
    const pendingDisplayChanged = !same(updatedPendingDisplay, originalPendingDisplay);
    const updatedAndOriginalRequiredDisplayIdsAreTheSame = () => updatedIds?.sort()?.equals(originalIds?.sort());
    const complexConfirmationRequired = !!updatedIds
       && !requiredDisplaysStartedEmptyThenDisplaysAddedThenFinishedEmpty
       && !updatedAndOriginalRequiredDisplayIdsAreTheSame();
    return pendingDisplayChanged || complexConfirmationRequired;
  }

}
