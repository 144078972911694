import { Injectable } from '@angular/core';
import { EditSectionFormViewModel } from '../../../edit-menu-section/edit-menu-section-form/edit-section-form/edit-section-form-view-model';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { SectionLayout, SectionLayoutType } from '../../../../../../models/utils/dto/section-layout-type';
import { ThemeUtils } from '../../../../../../utils/theme-utils';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { Menu } from '../../../../../../models/menu/dto/menu';

@Injectable()
export class CardStackSectionFormViewModel extends EditSectionFormViewModel {

  public layoutTypeDisabledTooltip$ = this.section$.pipe(
    map(section => section?.isTemplatedSection() ? null : 'This card size does not support grid layout.')
  );

  public override disableGridColumnCheckboxes$ = combineLatest([this.bindGridCheckboxesOnSubmit$, this.section$]).pipe(
    map(([bindGridCheckboxesOnSubmit, section]) => !bindGridCheckboxesOnSubmit || section?.autoUpdateGridColumns)
  );

  public override disablePricingTierGridColumnCheckboxes$ = combineLatest([
    this.bindPricingTierGridCheckboxesOnSubmit$,
    this.section$
  ]).pipe(
    map(([bindPricingTierGridCheckboxesOnSubmit, section]) => {
      return !bindPricingTierGridCheckboxesOnSubmit || section?.autoUpdateGridColumns;
    })
  );

  public override sectionBannerWarningMessage$ = combineLatest([
    this.layoutType$,
    this.menu$,
    this.gridColumnsArray$
  ]).pipe(
    map(([layoutType, menu, selectedColumns]) => {
      if (layoutType?.value !== SectionLayout.Grid) return null;
      const cardSize = menu?.metadata?.printCardSize;
      const gridCountMap = menu?.hydratedTheme?.printConfig?.gridCountMap;
      const maxColumnCount = gridCountMap?.get(cardSize);
      const selectedColumnCount = selectedColumns?.length;
      if (selectedColumnCount === 0) return `There are no Grid Columns selected on this menu.`;
      if (selectedColumnCount <= maxColumnCount) return null;
      return `This theme supports ${maxColumnCount} variants per card when grid mode is enabled.  Any products that `
        + `contain more than ${maxColumnCount} child variants will appear on multiple cards, sorted by Unit Size Asc.`;
    })
  );

  protected override layoutTypeFilter(menu: Menu, section: HydratedSection, layoutType: SectionLayoutType): boolean {
    switch (layoutType?.value) {
      case SectionLayout.ChildVariantList:
        return ThemeUtils.themeIdsThatSupportChildVariantList().contains(menu?.theme);
      case SectionLayout.ClassicFlowerGrid:
        return ThemeUtils.themeIdsThatSupportClassicFlowerGrid().contains(menu?.theme);
      case SectionLayout.Grid:
        return menu?.cardStackSupportsGridColumns();
      case SectionLayout.PricingTierGrid:
        return false;
      default:
        return true;
    }
  }

  protected override layoutTypeSelectionConversion(
    menu: Menu,
    section: HydratedSection,
    layoutType: SectionLayoutType
  ): SectionLayoutType {
    switch (layoutType.value) {
      case SectionLayout.List:
        return ThemeUtils.getSelectionOptionForCardStackLayoutList(layoutType);
      case SectionLayout.ChildVariantList:
        return ThemeUtils.getSelectionOptionForChildVariantList(menu?.theme, layoutType);
      default:
        return layoutType;
    }
  }

}
