import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../../models/product/dto/product';

@Pipe({
  name: 'getProductTACWithUnits'
})
export class GetProductTacWithUnitsPipe implements PipeTransform {

  transform(product: Product | null): string | null {
    return product?.getTACWithUnits();
  }

}
