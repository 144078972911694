import { Deserializable } from '../../protocols/deserializable';
import { AssetUrl } from './asset-url';
import { MediaType } from '../../enum/dto/media-type.enum';
import { AssetSize } from '../../enum/dto/asset-size.enum';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { MediaUtils } from '../../../utils/media-utils';
import { CachePolicy } from '../../enum/shared/cachable-image-policy.enum';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AssetProperties } from '../../protocols/asset-properties';

export class Asset implements Deserializable, Cachable, UniquelyIdentifiable, AssetProperties {

  public id: string;
  public md5Hash: string;
  public urls: AssetUrl[];
  public timestamp: number;
  public fileName: string;
  public mediaType: MediaType;
  public cachedTime: number;
  // Not From API
  // always emits url to largest fetched asset
  public sizePriorityUrl$: Observable<string|SafeResourceUrl>;

  static buildCacheKey(id: string, hash: string): string {
    return `Image-${id}-${hash}`;
  }

  public isEmpty() {
    return this.id === '' || this.md5Hash === '';
  }

  public onDeserialize() {
    const filteredUrls = this.urls?.filter(u => {
      // No other size exist for videos so filer them out
      return MediaUtils.isVideo(this.mediaType) ? u.size === AssetSize.Original : true;
    });
    this.urls = window?.injector?.Deserialize?.arrayOf(AssetUrl, filteredUrls);
    // Set data in child objects from parent object
    this.urls?.forEach(url => {
      url.name = this.fileName;
      url.assetId = this.id;
      url.md5Hash = this.md5Hash;
      url.mediaType = this.mediaType;
    });
    // Create size priority url observable that always emits url to largest fetched asset
    this.sizePriorityUrl$ = combineLatest(
      this.urls?.map(url => url.srcUrl.pipe(startWith([url.size, ''] as [AssetSize, Asset|SafeResourceUrl]))) || []
    ).pipe(
      map((listData) => {
        const [, ogUrl] = listData?.find(([size, _]) => size === AssetSize.Original) || [undefined, ''];
        const [, largeUrl] = listData?.find(([size, _]) => size === AssetSize.Large) || [undefined, ''];
        const [, mediumUrl] = listData?.find(([size, _]) => size === AssetSize.Medium) || [undefined, ''];
        const [, smallUrl] = listData?.find(([size, _]) => size === AssetSize.Small) || [undefined, ''];
        const [, thumbUrl] = listData?.find(([size, _]) => size === AssetSize.Thumb) || [undefined, ''];
        if (!!ogUrl && !this.isPDF(AssetSize.Original)) { return ogUrl; }
        if (largeUrl) { return largeUrl; }
        if (mediumUrl) { return mediumUrl; }
        if (smallUrl) { return smallUrl; }
        if (thumbUrl) { return thumbUrl; }
        return '';
      })
    );
    // Always get thumbnails
    if (this.isImage() || this.isPDFImage(AssetSize.Thumb)) {
      this.getAsset(CachePolicy.Service, AssetSize.Thumb, DateUtils.unixOneHour());
    }
  }

  getAsset(cachePolicy: CachePolicy, size: AssetSize, cacheForNSeconds: number) {
    this.getAssetUrl(size)?.loadAssetIntoSrcUrlSubject(cachePolicy, cacheForNSeconds);
  }

  public isValid(): boolean {
    // Use this to handle cases where assets are deleted, leaving a broken Image object behind (ie: {urls: null})
    return !!this && !!this.id && !!this.md5Hash;
  }

  public isImage(): boolean {
    return this.mediaType.match(/image\/*/) !== null;
  }

  public isVideo(): boolean {
    return this.mediaType.match(/video\/*/) !== null;
  }

  public isPDFImage(size: AssetSize): boolean {
    return this.mediaType.match(/pdf\/*/) !== null && size !== AssetSize.Original;
  }

  public isPDF(size: AssetSize): boolean {
    return this.mediaType.match(/pdf\/*/) !== null && size === AssetSize.Original;
  }

  public getAssetUrl(size: AssetSize): AssetUrl|null|undefined {
    return this.urls?.find(u => u.size === size);
  }

  public overrideRetryCount(n: number): void {
    this.urls?.forEach(url => url.retryOverride = n);
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(): string {
    return Asset.buildCacheKey(this.id, this.md5Hash);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  getUniqueIdentifier(): string {
    return `${this.id}-${this.md5Hash}`;
  }

}
