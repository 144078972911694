import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuAdditionalOptionsFormComponent } from '../../../../../forms/menu-additional-options/additional-option-forms/menu-additional-options-form';

@Component({
  selector: 'app-edit-shelf-talker-brands-menu-hide-show-options-form',
  templateUrl: './edit-shelf-talker-brands-menu-hide-show-options-form.component.html',
  styleUrls: ['./edit-shelf-talker-brands-menu-hide-show-options-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShelfTalkerBrandsMenuHideShowOptionsFormComponent extends MenuAdditionalOptionsFormComponent {

  @Input() templateMode = false;
  @Input() hideSaleDisabled: boolean;

}
