import { Component } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { PageBannerViewModel } from './page-banner-view-model';

@Component({
  selector: 'app-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss'],
  providers: [PageBannerViewModel]
})
export class PageBannerComponent extends BaseComponent {

  constructor(
    public viewModel: PageBannerViewModel
  ) {
    super();
  }

}
