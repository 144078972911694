import { UserDomainModel } from '../../../../domainModels/user-domain-model';
import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { DefaultNavSubItem } from './nav-sub-item/models/default-nav-sub-item';
import { LocationsSubNavItem } from './nav-sub-item/models/locations-sub-nav-item';
import { IncompleteNavSubItem } from './nav-sub-item/models/incomplete-nav-sub-item';
import { NavItem } from './nav-item/models/nav-item';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { DistinctUtils } from '../../../../utils/distinct-utils';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { User } from '../../../../models/account/dto/user';
import { DefaultNavItem } from './nav-item/models/default-nav-item';
import { NavItemId } from '../../../../models/enum/shared/nav-item-id.enum';
import { ProductsNavItem } from './nav-item/models/products-nav-item';
import { ProfileNavItem } from './nav-item/models/profile-nav-item';
import { LocationNavItem } from './nav-item/models/location-nav-item';
import { SignOutNavItem } from './nav-item/models/sign-out-nav-item';
import { Event, NavigationEnd, Router } from '@angular/router';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { HelpNavItem } from './nav-item/models/help-nav-item';
import { ProviderUtils } from '../../../../utils/provider-utils';
import { WebSocketService } from '../../../../services/web-socket.service';
import { ConnectionStatus } from '../../../../models/web-sockets/enums/connection-status';

@Injectable()
export class SidebarNavViewModel extends BaseViewModel {

  constructor(
    public userDomainModel: UserDomainModel,
    public companyDomainModel: CompanyDomainModel,
    private router: Router,
    private webSocketService: WebSocketService
  ) {
    super();
    this.bindUrlParams();
  }

  protected override _loadingOpts = new BehaviorSubject(this.getLoadingOptions());
  private readonly user$ = this.userDomainModel.user$;

  public readonly webSocketConnectionColor$ = this.webSocketService.socketConnectionStatus$.pipe(
    map(connectionStatus => {
      switch (connectionStatus) {
        case ConnectionStatus.Connected:    return '#63d58f';
        case ConnectionStatus.Connecting:   return '#ffd74b';
        case ConnectionStatus.Disconnected: return '#fa5555';
      }
    })
  );

  protected _currentNavUrl = new BehaviorSubject<string>('');
  public currentNavUrl$ = this._currentNavUrl.pipe(distinctUntilChanged());

  private readonly allowIntegrationAccessForNonAdmins$ = this.companyDomainModel.inventoryProvider$.pipe(
    map(ip => ProviderUtils.supportsLocationSpecificPOSSyncing(ip))
  );
  private readonly canUseTemplates$ = this.userDomainModel.canUseTemplates$;
  // @ts-ignore
  public readonly versionString$ = of(require('package.json')?.version);
  public readonly navItems$: Observable<NavItem[]> = combineLatest([
    this.user$,
    this.allowIntegrationAccessForNonAdmins$,
    this.canUseTemplates$,
  ]).pipe(
    map(([user, allowNonAdminIntegrationAccess, supportsTemplates]) => {
      return this.getNavItems(user, allowNonAdminIntegrationAccess, supportsTemplates);
    }),
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
  );

  private bindUrlParams() {
    this._currentNavUrl.next(this.router.url);
    this.router.events.pipe(
      filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
    ).subscribeWhileAlive({
      owner: this,
      next: (evt) => this.urlChanged(evt)
    });
  }

  private getLoadingOptions(): LoadingOptions {
    const loadingOpts = LoadingOptions.default();
    loadingOpts.fullscreen = true;
    loadingOpts.zIndex = 100;
    return loadingOpts;
  }

  private getNavItems(
    user: User,
    allowNonAdminIntegrationsAccess: boolean,
    companySupportsTemplates: boolean
  ): NavItem[] {
    const home = this.getHomeNavItem();
    const products = this.getProductsNavItem();
    const templates = this.getTemplatesNavItem();
    const menus = this.getMenusNavItem();
    const displays = this.getDisplaysNavItem();
    const settings = this.getSettingsNavItem(user?.isCompanyAdmin);
    const help = this.getHelpNavItem();
    const profile = this.getProfileNavItem();
    const currentLocation = this.getCurrentLocationNavItem();
    const signOut = this.getSignOutNavItem();
    const addSettingsToSidebar = user?.isCompanyAdmin || allowNonAdminIntegrationsAccess;
    const navItems = [
      home,
      products,
      ...(user?.isTemplateAdmin && companySupportsTemplates ? [templates] : []),
      menus,
      displays,
      ...(addSettingsToSidebar ? [settings] : []),
      help,
      profile,
      currentLocation,
      signOut
    ];
    return navItems;
  }

  private getHomeNavItem(): NavItem {
    const nonFilledIcon = 'assets/icons/light/outline/home.svg';
    const filledIcon = 'assets/icons/light/solid/home.svg';
    return new DefaultNavItem(NavItemId.Home, 'Home', [], '/home', nonFilledIcon, filledIcon);
  }

  private getProductsNavItem(): ProductsNavItem {
    const products = new DefaultNavSubItem('All Products', 'all-products');
    const incomplete = new IncompleteNavSubItem('Incomplete', 'incomplete');
    const orderReceiving = new DefaultNavSubItem('Order Receiving', 'order-receiving');
    const subItems = [products, incomplete, orderReceiving];
    const nonFilled = 'assets/icons/light/outline/view-grid.svg';
    const filled = 'assets/icons/light/solid/view-grid.svg';
    return new ProductsNavItem(NavItemId.Products, 'Products', subItems, '/products', nonFilled, filled);
  }

  private getTemplatesNavItem(): NavItem {
    const digital = new DefaultNavSubItem('Digital', 'digital');
    const print = new DefaultNavSubItem('Print', 'print');
    const web = new DefaultNavSubItem('Web', 'web');
    const subItems = [digital, print, web];
    const nonFilled = 'assets/icons/light/outline/template.svg';
    const filled = 'assets/icons/light/solid/template.svg';
    return new DefaultNavItem(NavItemId.Templates, 'Templates', subItems, '/templates', nonFilled, filled);
  }

  private getMenusNavItem(): NavItem {
    const digital = new DefaultNavSubItem('Digital', 'digital');
    const print = new DefaultNavSubItem('Print', 'print');
    const web = new DefaultNavSubItem('Web', 'web');
    const subItems = [digital, print, web];
    const nonFilled = 'assets/icons/light/outline/document-text.svg';
    const filled = 'assets/icons/light/solid/document-text.svg';
    return new DefaultNavItem(NavItemId.Menus, 'Menus', subItems, '/menus', nonFilled, filled);
  }

  private getDisplaysNavItem(): NavItem {
    const nonFilled = 'assets/icons/light/outline/device-tablet.svg';
    const filled = 'assets/icons/light/solid/device-tablet.svg';
    return new DefaultNavItem(NavItemId.Displays, 'Displays', [], '/displays', nonFilled, filled);
  }

  private getSettingsNavItem(isAdmin: boolean): NavItem {
    const nonFilled = 'assets/icons/light/outline/cog.svg';
    const filled = 'assets/icons/light/solid/cog.svg';
    const general = new DefaultNavSubItem('General', 'general');
    const locations = new LocationsSubNavItem('Locations', 'locations');
    const customization = new DefaultNavSubItem('Customization', 'customization');
    const menus = new DefaultNavSubItem('Menus', 'menus');
    const products = new DefaultNavSubItem('Products', 'products');
    const automation = new DefaultNavSubItem('Automation', 'automation');
    const subItems = isAdmin ? [general, locations, customization, menus, products, automation] : [general];
    return new DefaultNavItem(NavItemId.Settings, 'Settings', subItems, '/settings', nonFilled, filled);
  }

  private getHelpNavItem(): HelpNavItem {
    const nonFilled = 'assets/icons/light/outline/question-mark-circle.svg';
    const filled = 'assets/icons/light/solid/question-mark-circle.svg';
    return new HelpNavItem(NavItemId.Help, 'Help', [], 'https://support.mybudsense.com', nonFilled, filled);
  }

  private getProfileNavItem(): ProfileNavItem {
    const nonFilled = 'assets/icons/light/outline/user.svg';
    const filled = 'assets/icons/light/solid/user.svg';
    return new ProfileNavItem(NavItemId.Profile, 'Profile', [], '/account', nonFilled, filled);
  }

  private getCurrentLocationNavItem(): LocationNavItem {
    const nonFilled = 'assets/icons/light/outline/status-online.svg';
    const filled = 'assets/icons/light/solid/status-online.svg';
    const path = 'locations-picker-modals';
    return new LocationNavItem(NavItemId.CurrentLocation, 'Current Location', [], path, nonFilled, filled);
  }

  private getSignOutNavItem(): SignOutNavItem {
    const nonFilled = 'assets/icons/light/outline/logout.svg';
    const filled = 'assets/icons/light/solid/logout.svg';
    return new SignOutNavItem(NavItemId.SignOut, 'Sign Out', [], '/auth/sign-out', nonFilled, filled);
  }

  navigateToRoot() {
    this.router.navigate(['']).then(() => {});
  }

  private urlChanged(nav: NavigationEnd) {
    this._currentNavUrl.next(nav.url);
  }

}
