import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveTableComponent } from '@mobilefirstdev/reactive-table';

@Component({
  selector: 'app-horizontal-scrolling-reactive-table',
  templateUrl: './horizontal-scrolling-reactive-table.component.html',
  styleUrls: ['./horizontal-scrolling-reactive-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalScrollingReactiveTableComponent extends ReactiveTableComponent {

  @Input() makeTableElementVerticallyScrollable: boolean = false;
  @Input() addExtraSpaceToBottomOfTableInRem: number;

}
