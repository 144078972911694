<tr (click)="viewModel.viewJob()" class="table-item clickable">
  <td class="name">
    {{ rowData?.name | dateTimeStringIfUnixStringElseOriginal | doubleDashIfEmpty }}
  </td>
  <td class="misc-text">
    <div class="flex-container flex-row flex-no-wrap flex-align-center">
      {{ viewModel.sharingRecipients$ | push }}
      <button [ngbTooltip]="recipientTooltip" class="inline-tooltip" placement="right" triggers="hover">
        <img [src]="'assets/icons/dark/outline/bookmark.svg'" alt="" />
      </button>
    </div>
  </td>
  <td *ngIf="!(viewModel.editPrintStackMode$ | push)" class="misc-text">
    <div class="flex-container flex-row flex-no-wrap flex-align-center">
      <span *ngIf="!(viewModel.showLoadingSpinner$ | push)">
        {{ (rowData?.menuIds?.length + rowData?.deletedMenuNames?.length)?.toString() | doubleDashIfEmpty }}
      </span>
      <span *ngIf="viewModel.showLoadingSpinner$ | push">
        {{ rowData?.completedMenuIds?.length?.toString() + '/' + rowData?.menuIds?.length?.toString() }}
      </span>
      <button
        [hidden]="!(viewModel.menuTooltip$ | push)?.length"
        [ngbTooltip]="menuOrCardTooltip"
        class="inline-tooltip"
        container="body"
        placement="right"
        triggers="hover">
        <img [src]="'assets/icons/dark/outline/bookmark.svg'" alt="" />
      </button>
    </div>
  </td>
  <td *ngIf="viewModel.editPrintStackMode$ | push" class="misc-text">
    <div class="flex-container flex-row flex-no-wrap flex-align-center">
      <span *ngIf="!(viewModel.showLoadingSpinner$ | push)">{{ viewModel.nCardsPrinted$ | push }}</span>
      <span *ngIf="viewModel.showLoadingSpinner$ | push">--</span>
      <button
        *ngIf="!(viewModel.showLoadingSpinner$ | push)"
        [hidden]="!(viewModel.cardTooltip$ | push)?.length"
        [ngbTooltip]="menuOrCardTooltip"
        class="inline-tooltip"
        container="body"
        placement="right"
        triggers="hover">
        <img [src]="'assets/icons/dark/outline/bookmark.svg'" alt="" />
      </button>
    </div>
  </td>
  <td class="misc-text">
    {{ viewModel.jobType$ | push | printJobTypeString | doubleDashIfEmpty }}
  </td>
  <td *ngIf="!(viewModel.editPrintStackMode$ | push)" class="misc-text">
    {{ rowData?.dateStarted === 0 ? '--' : DateUtils.formatUnixToDateTime(rowData?.dateStarted) }}
  </td>
  <td class="misc-text">{{ viewModel.dateCompletedToDisplay$ | push | doubleDashIfEmpty }}</td>
  <td class="misc-text">
    <div class="flex-container flex-row flex-no-wrap flex-align-center" [style.gap.rem]="0.15">
      <app-loading-clock *rxIf="viewModel.showLoadingSpinner$"></app-loading-clock>
      <img *rxIf="viewModel.statusIcon$ as icon" [src]="icon" alt="status-icon" />
      <div
        [class.failed-status]="
          (viewModel.jobStatusToDisplay$ | push) === MenuPreviewJobStatus.MenuPreviewJobStatus_Failed
        ">
        {{ viewModel.jobStatusToDisplay$ | push | doubleDashIfEmpty }}
      </div>
    </div>
  </td>
  <td [style.min-width.rem]="2">
    <div class="flex-container flex-row flex-wrap-content justify-content-end" [style.gap.rem]="0.25">
      <app-products-table-action-button
        *ngIf="MenuPreviewJobStatus[rowData?.jobStatus] === MenuPreviewJobStatus.MenuPreviewJobStatus_Queued"
        (clicked)="viewModel.cancelJob()"
        [tooltip]="'Cancel job'"
        [iconUrl]="'assets/icons/dark/outline/trash.svg'">
      </app-products-table-action-button>
      <app-products-table-action-button
        *ngIf="MenuPreviewJobStatus[rowData?.jobStatus] === MenuPreviewJobStatus.MenuPreviewJobStatus_Failed"
        (clicked)="viewModel.retryJob()"
        [tooltip]="'Retry job'"
        [iconUrl]="'assets/icons/dark/outline/refresh.svg'"
        [disabled]="viewModel.retrying$ | push"
        [spinCCW]="viewModel.retrying$ | push">
      </app-products-table-action-button>
      <app-products-table-action-button
        *ngIf="viewModel.showDownloadButton$ | push"
        (clicked)="viewModel.downloadJob()"
        [disabled]="viewModel.disableDownloadButton$ | push"
        [tooltip]="viewModel.downloadTooltip$ | push"
        [iconUrl]="'assets/icons/dark/outline/download.svg'">
      </app-products-table-action-button>
      <app-products-table-action-button
        *rxIf="!(viewModel.editPrintStackMode$ | push)"
        (clicked)="viewModel.viewJob()"
        [tooltip]="'View job'"
        [iconUrl]="'assets/icons/dark/outline/eye.svg'">
      </app-products-table-action-button>
      <app-products-table-action-button
        *ngIf="viewModel.showReprintButton$ | push"
        (clicked)="viewModel.reprintJob()"
        [tooltip]="'Reprint job'"
        [iconUrl]="'assets/icons/dark/outline/refresh.svg'">
      </app-products-table-action-button>
    </div>
  </td>
</tr>
<ng-template #menuOrCardTooltip>
  <div *ngFor="let item of viewModel.menuOrCardTooltip$ | push">
    {{ item }}
  </div>
</ng-template>
<ng-template #recipientTooltip>
  <div *ngFor="let recipient of viewModel.recipientTooltip$ | push">
    {{ recipient }}
  </div>
</ng-template>
