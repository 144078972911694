import { Injectable, Injector, NgZone } from '@angular/core';
import { EditCardStackPrintSectionViewModel } from '../../../../edit-card-stack-menu/edit-card-stack-print-section/edit-card-stack-print-section-view-model';
import { BulkPrintJobDomainModel } from '../../../../../../../../domainModels/bulk-print-job-domain-model';
import { MenuDomainModel } from '../../../../../../../../domainModels/menu-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateDomainModel } from '../../../../../../../../domainModels/template-domain-model';
import { EditShelfTalkerBrandsMenuBodyViewModel } from '../edit-shelf-talker-brands-menu-body-view-model';

@Injectable()
export class EditShelfTalkerBrandsMenuPrintSectionViewModel extends EditCardStackPrintSectionViewModel {

  constructor(
    protected override bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    protected editShelfTalkerBrandsMenuBodyViewModel: EditShelfTalkerBrandsMenuBodyViewModel,
    protected override injector: Injector,
    protected override menuDomainModel: MenuDomainModel,
    protected override ngZone: NgZone,
    protected override ngbModal: NgbModal,
    protected override templateDomainModel: TemplateDomainModel
  ) {
    super(
      bulkPrintJobDomainModel,
      editShelfTalkerBrandsMenuBodyViewModel,
      injector,
      menuDomainModel,
      ngZone,
      ngbModal,
      templateDomainModel
    );
  }

}
