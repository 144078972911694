import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { BulkPrintJob } from '../models/automation/bulk-print-job';
import { CreateViewStackPrintJobComponent, type StackType } from '../views/menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';
import { ModalUtils } from '../utils/modal-utils';

export class ModalViewStackPrintJob {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    stackType: StackType,
    templateMode: boolean,
    bulkPrintJob: BulkPrintJob,
  ): void {
    ngZone.run(() => {
      const menuId = bulkPrintJob?.getTargetedMenuId();
      const printType = bulkPrintJob.cardStackPrintConfigMap?.get(menuId)?.printType;
      const modalRef = ngbModal.open(CreateViewStackPrintJobComponent, ModalUtils.defaultModalOptions(injector));
      const compInstance = modalRef.componentInstance as CreateViewStackPrintJobComponent;
      compInstance.viewModel.connectToStackType(stackType);
      compInstance.viewModel.connectToTemplateMode(templateMode);
      compInstance.viewModel.connectToViewOnly(true);
      compInstance.viewModel.connectToSelectedCardStackPrintType(printType);
      const jobCopy = window?.injector?.Deserialize?.instanceOf(BulkPrintJob, bulkPrintJob);
      compInstance.viewModel.connectToBulkPrintJob(jobCopy);
    });
  }

}
