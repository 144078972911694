<div class="row">
  <!-- Order matters here -->
  <!-- Menu must be below template, because template is also an instance of menu -->
  <div
    *ngFor="let item of viewModel.displayableItems$ | async; trackBy: viewModel.trackDisplayableItem"
    class="col-12 col-sm-12 col-md-6 col-xl-4">
    <ng-container *ngIf="item | isCollection as collection; else elseIfLabelStackTemplate">
      <app-collection-preview [displayableItem]="collection"> </app-collection-preview>
    </ng-container>
    <ng-template #elseIfLabelStackTemplate>
      <app-label-stack-template-preview
        *ngIf="item | isLabelStackMenuTemplate as labelStackMenuTemplate; else elseIfCardStackTemplate"
        [displayableItem]="labelStackMenuTemplate"
        [imageFitContain]="true">
      </app-label-stack-template-preview>
    </ng-template>
    <ng-template #elseIfCardStackTemplate>
      <app-card-stack-template-preview
        *ngIf="item | isCardStackMenuTemplate as cardStackMenuTemplate; else elseIfShelfTalkerMenuTemplate"
        [displayableItem]="cardStackMenuTemplate"
        [imageFitContain]="true">
      </app-card-stack-template-preview>
    </ng-template>
    <ng-template #elseIfShelfTalkerMenuTemplate>
      <app-shelf-talker-template-preview
        *ngIf="item | isShelfTalkerMenuTemplate as shelfTalkerMenuTemplate; else elseIfCardStack"
        [displayableItem]="shelfTalkerMenuTemplate"
        [imageFitContain]="true">
      </app-shelf-talker-template-preview>
    </ng-template>
    <ng-template #elseIfCardStack>
      <app-card-stack-preview
        *ngIf="item | isCardStackMenu as cardStackMenu; else elseifLabelMenu"
        [displayableItem]="cardStackMenu"
        [imageFitContain]="true">
      </app-card-stack-preview>
    </ng-template>
    <ng-template #elseifLabelMenu>
      <app-label-stack-preview
        *ngIf="item | isLabelStackMenu as labelStackMenu; else elseIfShelfTalkerMenu"
        [displayableItem]="labelStackMenu"
        [imageFitContain]="true">
      </app-label-stack-preview>
    </ng-template>
    <ng-template #elseIfShelfTalkerMenu>
      <app-shelf-talker-preview
        *ngIf="item | isShelfTalkerMenu as shelfTalkerMenu; else elseifTemplate"
        [displayableItem]="shelfTalkerMenu"
        [imageFitContain]="true">
      </app-shelf-talker-preview>
    </ng-template>
    <ng-template #elseifTemplate>
      <app-template-preview *ngIf="item | isTemplate as template; else elseifMenu" [displayableItem]="template">
      </app-template-preview>
    </ng-template>
    <ng-template #elseifMenu>
      <app-menu-preview *ngIf="item | isMenu as menu" [displayableItem]="menu"> </app-menu-preview>
    </ng-template>
  </div>
</div>
