import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModel } from '../base-smart-filter-form-view-model';
import { map } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../domainModels/cannabinoids-and-terpenes-domain-model';
import { combineLatest, Observable, of } from 'rxjs';
import { exists } from '../../../../../functions/exists';
import { StringUtils } from '../../../../../utils/string-utils';

export interface TerpeneInfo {
  key: any;
  text: string;
}

@Injectable()
export class TerpeneAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModel {

  constructor(
    public companyDomainModel: CompanyDomainModel,
    public cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel
  ) {
    super();
  }

  public readonly enabledTerpenes$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpenes$;
  public readonly enabledTerpeneNames$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpeneNames$;
  public readonly hasEnabledTerpenes$ = this.enabledTerpeneNames$.pipe(map(terpenes => terpenes.length > 0));

  public totalTerpeneInfo$ = of({
    key: 'TotalTerpene',
    text: 'Total Terpene'
  });

  public readonly terpenes$: Observable<TerpeneInfo[]> = this.enabledTerpenes$.pipe(
    map(terpeneTypes => terpeneTypes?.map(t => {
      const typeText = t?.getSelectionValue();
      const typeKey = typeText.toString()?.stripWhiteSpace();
      return {
        key: typeKey,
        text: typeText,
      };
    })),
  );

  public readonly terpenePillBackgroundColors$ = combineLatest([
    this.enabledTerpeneNames$,
    window.types.terpenes$
  ]).pipe(
    map(([enabledTerpenes, terpenes]) => {
      const terpeneNames = terpenes?.map(c => c?.getSelectionTitle());
      const buildPillBackgroundColorObject = (pillBackgroundColors: any, terpeneName: string) => {
        pillBackgroundColors[terpeneName] = enabledTerpenes?.includes(terpeneName) ? '#222222' : '#FFD74B';
        return pillBackgroundColors;
      };
      return terpeneNames.reduce(buildPillBackgroundColorObject, {});
    })
  );

  public readonly terpenePillTextColors$ =  combineLatest([
    this.enabledTerpeneNames$,
    window.types.terpenes$
  ]).pipe(
    map(([enabledTerpenes, terpenes]) => {
      const terpeneNames = terpenes?.map(c => c?.getSelectionTitle());
      const buildPillTextColorObject = (pillTextColors: any, terpeneName: string) => {
        pillTextColors[terpeneName] = enabledTerpenes?.includes(terpeneName) ? '#FFFFFF' : '#222222';
        return pillTextColors;
      };
      return terpeneNames.reduce(buildPillTextColorObject, {});
    })
  );

  public readonly terpenePillTooltip$ = combineLatest([
    this.enabledTerpeneNames$,
    window.types.terpenes$
  ]).pipe(
    map(([enabledTerpenes, terpenes]) => {
      const terpeneNames = terpenes?.map(c => c?.getSelectionTitle());
      const buildTooltipObject = (pillTooltips: any, terpeneName: string) => {
        pillTooltips[terpeneName] = enabledTerpenes?.includes(terpeneName)
          ? ''
          : `${terpeneName} is disabled in company terpenes. This will not be considered on smart filter matches.`;
        return pillTooltips;
      };
      return terpeneNames.reduce(buildTooltipObject, {});
    })
  );

  public readonly topTerpeneDisabledBackgroundColors$ = combineLatest([
    this.smartFilter$,
    this.enabledTerpenes$
  ]).pipe(
    map(([smartFilter, enabledTerpenes]) => {
      const top = smartFilter?.topTerpene;
      return exists(top) && !enabledTerpenes?.map(it => it?.getSelectionValue())?.includes(top)
        ? { [top]: '#FFD74B' }
        : {};
    })
  );

  public readonly topTerpeneDisabledTextColors$ = combineLatest([
    this.smartFilter$,
    this.enabledTerpenes$
  ]).pipe(
    map(([smartFilter, enabledTerpenes]) => {
      const top = smartFilter?.topTerpene;
      return exists(top) && !enabledTerpenes?.map(it => it?.getSelectionValue())?.includes(top)
        ? { [top]: '#222222' }
        : {};
    })
  );

  public readonly topTerpeneDisabledTooltips$  = combineLatest([
    this.smartFilter$,
    this.enabledTerpenes$
  ]).pipe(
    map(([smartFilter, enabledTerpenes]) => {
      const top = StringUtils.splitPascalCasePreserveAcronyms(StringUtils.toPascalCase(smartFilter?.topTerpene));
      return exists(top) && !enabledTerpenes?.map(it => it?.getSelectionValue())?.includes(top)
        ? { [top]: `${top} is disabled in company terpenes. This will not be considered on smart filter matches.` }
        : {};
    })
  );

}
