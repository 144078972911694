import { DefaultStackedSizeType } from './default-stacked-size-type';
import { DefaultPrintCardSize } from '../../enum/dto/default-print-card-size';

export class DefaultPrintCardSizeType extends DefaultStackedSizeType {

  override value: DefaultPrintCardSize;

  public getImageSrcFromSize(): string {
    switch (this.value) {
      case DefaultPrintCardSize.DefaultSize_AddressCard:  return 'assets/img/preview/Address-Card.svg';
      case DefaultPrintCardSize.DefaultSize_Custom2x2:    return 'assets/img/preview/F&F-2x2.svg';
      case DefaultPrintCardSize.DefaultSize_BusinessCard: return 'assets/img/preview/Business-Card.svg';
      case DefaultPrintCardSize.DefaultSize_IndexCard:    return 'assets/img/preview/Index-Card.svg';
      case DefaultPrintCardSize.DefaultSize_PostCard:     return 'assets/img/preview/Post-Card.svg';
      case DefaultPrintCardSize.DefaultSize_Custom5x5:    return 'assets/img/preview/Custom-5x5-Card.svg';
    }
  }

  public getNameFromSize(): string {
    switch (this.value) {
      case DefaultPrintCardSize.DefaultSize_AddressCard:  return 'Address Card';
      case DefaultPrintCardSize.DefaultSize_Custom2x2:    return 'Custom Size';
      case DefaultPrintCardSize.DefaultSize_BusinessCard: return 'Business Card';
      case DefaultPrintCardSize.DefaultSize_IndexCard:    return 'Index Card';
      case DefaultPrintCardSize.DefaultSize_PostCard:     return 'Post Card';
      default:                                            return 'Custom Size';
    }
  }

  public getDimensionString(): string {
    switch (this.value) {
      case DefaultPrintCardSize.DefaultSize_AddressCard:  return '1” x 3”';
      case DefaultPrintCardSize.DefaultSize_Custom2x2:    return '2” x 2”';
      case DefaultPrintCardSize.DefaultSize_BusinessCard: return '2” x 3”';
      case DefaultPrintCardSize.DefaultSize_IndexCard:    return '3” x 5”';
      case DefaultPrintCardSize.DefaultSize_PostCard:     return '4” x 6”';
      case DefaultPrintCardSize.DefaultSize_Custom5x5:    return '5” x 5”';
    }

  }

}
