<div class="mt-5">
  <ng-container *rxFor="let terpene of viewModel.collectiveTerpeneColumnConfigsAndKeys$ | push">
    <mat-accordion multi [displayMode]="'flat'" class="terpene-accordion">
      <mat-expansion-panel class="mat-elevation-z0 terpene-container">
        <mat-expansion-panel-header class="terpene-accordion-header">
          <mat-panel-title class="bs-bold f14px">
            {{ terpene?.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-column-options-form
          [formColumnConfig]="terpene?.config"
          [columnSectionKey]="terpene?.key"
          [formMergeKey]="viewModel.container.mergeKey"
          [sectionLayoutType]="viewModel.container.layoutType$ | push"
          [themeDefaults]="terpene?.themeDefaults"
          [managingDefault]="viewModel.container.managingDefault$ | push"
          [cannabinoidOrTerpene]="true"
          (columnChanges)="viewModel.container.connectToColumnChanges(terpene?.key, $event)"></app-column-options-form>
      </mat-expansion-panel>
      <hr class="mt-none" />
    </mat-accordion>
  </ng-container>

  <div class="terpene-title" [style.margin-top.rem]="2">
    <span class="text"> Individual Terpenes </span>
  </div>
  <ng-container *rxFor="let terpene of viewModel.individualTerpeneColumnConfigsAndKeys$ | push">
    <mat-accordion multi [displayMode]="'flat'" class="terpene-accordion">
      <mat-expansion-panel class="mat-elevation-z0 terpene-container">
        <mat-expansion-panel-header class="terpene-accordion-header">
          <mat-panel-title class="bs-bold f14px">
            {{ terpene?.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-column-options-form
          [formColumnConfig]="terpene?.config"
          [columnSectionKey]="terpene?.key"
          [formMergeKey]="viewModel.container.mergeKey"
          [sectionLayoutType]="viewModel.container.layoutType$ | push"
          [themeDefaults]="terpene?.themeDefaults"
          [managingDefault]="viewModel.container.managingDefault$ | push"
          [cannabinoidOrTerpene]="true"
          (columnChanges)="viewModel.container.connectToColumnChanges(terpene?.key, $event)"></app-column-options-form>
      </mat-expansion-panel>
      <hr class="mt-none" />
    </mat-accordion>
  </ng-container>
</div>
