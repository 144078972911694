import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { EditShelfTalkerBrandsMenuViewModel } from '../edit-shelf-talker-brands-menu-view-model';

@Component({
  selector: 'app-edit-shelf-talker-brands-menu-body',
  templateUrl: './edit-shelf-talker-brands-menu-body.component.html',
  styleUrls: ['./edit-shelf-talker-brands-menu-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShelfTalkerBrandsMenuBodyComponent extends BaseComponent {

  constructor(
    public viewModel: EditShelfTalkerBrandsMenuViewModel // provided by parent component
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() mergeKey: string;
  @Input() unsavedChanges: boolean = false;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Input() isTemplatedMenu: boolean = false;
  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Output() submitForms = new EventEmitter<boolean>(true);

}
