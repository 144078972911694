import { WebSocketReceiveMessage } from './web-socket-receive-message';
import { SyncDataJob } from '../../../settings/sync-data-job';

export class WebSocketSyncJobUpdate extends WebSocketReceiveMessage {

  override payload: SyncDataJob;

  override onDeserialize() {
    super.onDeserialize();
    this.payload = window?.injector?.Deserialize.instanceOf(SyncDataJob, this.payload);
  }

}
