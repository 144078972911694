import { BehaviorSubject, combineLatest, iif, merge, Observable, of } from 'rxjs';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { debounceTime, delay, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { MenuDomainModel } from '../../../../../../../domainModels/menu-domain-model';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { MarketingMenuType } from '../../../../../../../models/enum/dto/marketing-menu-type.enum';
import { Orientation } from '../../../../../../../models/utils/dto/orientation-type';
import { MenuType } from '../../../../../../../models/utils/dto/menu-type-definition';
import { TemplateDomainModel } from '../../../../../../../domainModels/template-domain-model';
import { DefaultPrintCardSizeType } from '../../../../../../../models/utils/dto/default-print-card-size-type';
import { DefaultPrintLabelSizeType } from '../../../../../../../models/utils/dto/default-print-label-size-type';
import { DefaultPrintCardPaperSize } from '../../../../../../../models/utils/dto/default-print-card-paper-size-type';
import { DefaultPrintLabelPaperSize } from '../../../../../../../models/utils/dto/default-print-label-paper-size-type';
import { exists } from '../../../../../../../functions/exists';
import { DefaultPrintCardSize } from '../../../../../../../models/enum/dto/default-print-card-size';
import { DefaultPrintLabelSize } from '../../../../../../../models/enum/dto/default-print-label-size';

@Injectable()
export class MenuDetailsFormViewModel extends BaseViewModel {

  constructor(
    private menuDomainModel: MenuDomainModel,
    private templateDomainModel: TemplateDomainModel
  ) {
    super();
    // Auto change menu columns if in discrepancy state
    combineLatest([
      this.menu$,
      this.columnConfig$.notNull(),
      this.numberOfMenuColumns$,
      this.orientation$.notNull()
    ]).pipe(debounceTime(100))
      .subscribeWhileAlive({
        owner: this,
        next: ([menu, columnConfig, nCol, orientation]) => {
          if (nCol === null || nCol === undefined) {
            this._autoUpdateColumnCount.next(menu?.columnCount);
          } else {
            if (orientation === Orientation.Landscape) {
              if (!columnConfig?.supportsNColumnsInLandscape(nCol)) {
                (menu?.type === MenuType.WebMenu)
                  ? this._autoUpdateColumnCount.next(1)
                  : this._autoUpdateColumnCount.next(columnConfig?.defaultMenuColumnCountLandscape);
              }
            } else if (orientation === Orientation.Portrait || orientation === Orientation.ReversePortrait) {
              if (!columnConfig?.supportsNColumnsInPortrait(nCol)) {
                (menu?.type === MenuType.WebMenu)
                  ? this._autoUpdateColumnCount.next(1)
                  : this._autoUpdateColumnCount.next(columnConfig?.defaultMenuColumnCountPortrait);
              }
            }
          }
        }
      });
  }

  protected _templateMode = new BehaviorSubject<boolean>(false);
  public templateMode$ = this._templateMode as Observable<boolean>;
  public connectToTemplateMode = (tm: boolean) => this._templateMode.next(tm);

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu as Observable<Menu>;
  public themes$ = this.menuDomainModel.menuThemes$;
  public menuTags$ = this.menuDomainModel.existingTags$;
  public templateTags$ = this.templateDomainModel.existingMenuTemplateTags$;
  public menuAndTemplateTags$ = combineLatest([
    this.menuTags$.notNull(),
    this.templateTags$.notNull()
  ]).pipe(
    map(([menuTags, templateTags]) => [...(menuTags || []), ...(templateTags || [])].uniqueByProperty('value')),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public tagsDelimited$ = this.templateMode$.pipe(
    switchMap(templateMode => iif(() => templateMode, this.templateTags$, this.menuAndTemplateTags$)),
    map(x => x?.map(x => x.title)?.filterFalsies()?.join(','))
  );
  public columnConfig$ = this.menu$.pipe(map(menu => menu?.hydratedTheme?.menuColumnCountConfig));
  public displaySizes$ = combineLatest([
    this.menu$,
    window.types.printSizeTypes$,
    window.types.printCardPaperSizeTypes$,
    window.types.digitalSizeTypes$,
    window.types.printLabelPaperSizeTypes$
  ]).pipe(
    map(([menu, printSizes, printCardPaperSizes, digitalSizes, printLabelPaperSizes]) => {
      const themePaperSizes = menu?.hydratedTheme?.printConfig?.paperSizes;
      switch (menu?.type) {
        case MenuType.PrintMenu:
        case MenuType.PrintReportMenu:
          return printSizes?.filter(s => themePaperSizes?.includes(s.value));
        case MenuType.PrintCardMenu:
          return printCardPaperSizes?.filter(s => themePaperSizes?.includes(s.value));
        case MenuType.PrintLabelMenu:
          return printLabelPaperSizes?.filter(s => themePaperSizes?.includes(s.value));
        default:
          return digitalSizes;
      }
    })
  );

  public printCardSize$ = this.menu$.pipe(
    map(menu => menu?.metadata?.printCardSize),
    distinctUntilChanged()
  );

  public linkToPaperSale$ = this.printCardSize$.pipe(
    map(printCardSize => {
      switch (printCardSize) {
        case DefaultPrintCardSize.DefaultSize_AddressCard:
          // eslint-disable-next-line max-len
          return 'https://www.uline.ca/Product/Detail/S-7698W/Vinyl-Envelopes-Job-Ticket-Holders/Insert-Cards-1-x-3-White';
        case DefaultPrintCardSize.DefaultSize_BusinessCard:
          // eslint-disable-next-line max-len
          return 'https://www.uline.ca/Product/Detail/S-6742W/Vinyl-Envelopes-Job-Ticket-Holders/Insert-Cards-2-x-3-White';
        case DefaultPrintCardSize.DefaultSize_IndexCard:
          return 'https://www.uline.ca/Product/ProductDetailRootItem?modelnumber=S-6743';
        case DefaultPrintCardSize.DefaultSize_PostCard:
          return 'https://www.uline.ca/Product/ProductDetailRootItem?modelnumber=S-6745';
        case DefaultPrintLabelSize.DefaultSize_CustomLabel2x4:
          return 'https://www.uline.ca/Product/Detail/S-3845/Laser-Labels/Uline-Laser-Labels-White-4-x-2';
        default:
          return '';
      }
    })
  );

  private _selectedPrintoutSize = new BehaviorSubject<string>(null);
  public selectedPrintoutSize$ = this._selectedPrintoutSize as Observable<string>;
  setSelectedPrintoutSize = (printoutSize: string) => {
    this._selectedPrintoutSize.next(printoutSize);
  };

  public showULineLink$ = combineLatest([
    this.printCardSize$,
    this.selectedPrintoutSize$
  ]).pipe(
    map(([printCardSize, printoutSize]) => {
      const paperForSale = printCardSize !== DefaultPrintCardSize.DefaultSize_Custom2x2
        && printCardSize !== DefaultPrintCardSize.DefaultSize_Custom5x5;
      const printoutIsUline = printoutSize === DefaultPrintCardPaperSize.DefaultSize_LetterPerforated_Uline
        || printoutSize === DefaultPrintLabelPaperSize.DefaultSize_LetterLabel_Uline;
      return paperForSale && printoutIsUline;
    })
  );

  public ulineNameText$ = combineLatest([
    this.printCardSize$,
    window.types.printCardSizeTypes$,
    window.types.printLabelSizeTypes$
  ]).pipe(
    map(([printCardSize, cardSizeTypes, labelSizeTypes]) => {
      let sizeType: any = cardSizeTypes.find(cst => cst?.value === printCardSize);
      if (!exists(sizeType)) {
        sizeType = labelSizeTypes.find(lst => lst?.value === printCardSize);
      }
      if (sizeType instanceof DefaultPrintCardSizeType) {
        return `${sizeType?.getNameFromSize()} (${sizeType.getDimensionString()})`;
      } else if (sizeType instanceof DefaultPrintLabelSizeType) {
        return `(${sizeType?.getDimensionString()})`;
      }
      return '';
    }),
    map(name => name?.toLowerCase())
  );

  public ulineLinkMessage$ = combineLatest([
    this.selectedPrintoutSize$,
    this.linkToPaperSale$,
    this.ulineNameText$
  ]).pipe(
    map(([printoutSize, linkToUlinePaper, ulineNameText]) => {
      if (printoutSize === DefaultPrintCardPaperSize.DefaultSize_LetterPerforated_Uline) {
        return `Uline perforated ${ulineNameText} paper can be purchased `
          + `<a href=\"${linkToUlinePaper}\" target=\"_blank\">at the following link<\a>`;
      }
      if (printoutSize === DefaultPrintLabelPaperSize.DefaultSize_LetterLabel_Uline) {
        return `Uline laser labels ${ulineNameText} can be purchased `
          + `<a href=\"${linkToUlinePaper}\" target=\"_blank\">at the following link</a>`;
      }
      return '';
    })
  );

  public showScaleType$ = this.menu$.pipe(
    map(m => {
      const playlist = m?.hydratedTheme?.menuSubType === MarketingMenuType.Playlist;
      const featuredProduct = m?.hydratedTheme?.menuSubType === MarketingMenuType.Featured;
      return playlist || featuredProduct;
    })
  );

  private _numberOfMenuColumns = new BehaviorSubject<number>(null);
  public numberOfMenuColumns$ = this._numberOfMenuColumns as Observable<number>;

  public _autoUpdateColumnCount = new BehaviorSubject<number>(undefined);
  public autoUpdateColumnCount$ = this._autoUpdateColumnCount.pipe(
    switchMap(n => of(undefined).pipe(delay(100), startWith(n)))
  );

  private _orientation = new BehaviorSubject<Orientation>(null);
  public orientation$ = this._orientation as Observable<Orientation>;

  private _creatingNewTag = new BehaviorSubject<boolean>(false);
  public creatingNewTag$ = this._creatingNewTag as Observable<boolean>;

  // Drop downs
  public menuTypes$ = window.types.menuTypes$;
  public orientations$ = combineLatest([
    this.menu$,
    window.types.orientations$
  ]).pipe(
    map(([
      menu,
      orientations
    ]) => {
      const print = menu?.isPrintMenuOrPrintReportMenu();
      return print
        ? orientations.filter(o => menu?.hydratedTheme?.printConfig?.orientations.includes(o.value))
        : orientations;
    })
  );

  public scaleTypes$ = window.types.optionScales$;

  public selectableNumberOfMenuColumns$ = combineLatest([this.menu$, this.orientation$]).pipe(
    map(([menu, orientation]) => {
      if (orientation === Orientation.Landscape) {
        return menu?.hydratedTheme?.getLandscapeColumnOptionsAsSelectable();
      } else {
        return menu?.hydratedTheme?.getPortraitColumnOptionsAsSelectable();
      }
    })
  );
  public readonly disableNumberOfMenuColumns$ = combineLatest([
    this.selectableNumberOfMenuColumns$,
    this.menu$
  ]).pipe(
    map(([selectableNumberOfMenuColumns, menu]) => {
      return (selectableNumberOfMenuColumns?.length <= 1) || menu?.isWebMenu();
    })
  );
  public numberOfMenuColumnsTooltip$ = combineLatest([
    merge(this.numberOfMenuColumns$, this._autoUpdateColumnCount).pipe(filter(n => n >= 1)),
    this.orientation$,
    this.selectableNumberOfMenuColumns$,
    this.menu$,
  ]).pipe(
    map(([nColumns, orientation, selectableNumbers, menu]) => {
      if (menu?.type === MenuType.WebMenu) {
        return 'Web Menus only support 1 column';
      } else if (selectableNumbers?.length === 1) {
        return `This menu only supports ${nColumns} column${nColumns > 1 ? 's' : ''} in ${orientation}`;
      } else {
        return 'Select the number of columns for this menu';
      }
    })
  );

  public previewSizeToolTip$ = this.menu$.pipe(
    map(m => {
      switch (m?.type) {
        case MenuType.PrintMenu:
        case MenuType.PrintReportMenu:
          return 'The size of paper the menu PDF will be generated on.';
        case MenuType.PrintCardMenu:
          return 'The size of paper the print cards will be generated on.';
        case MenuType.PrintLabelMenu:
          return 'The size of paper the print labels will be generated on.';
        default:
          return 'A menu will always respect the size of the display it is assigned to. '
               + 'This menu size is only used for generating menu previews.';
      }
    })
  );

  public setLabelForMenuSize$ = this.menu$.pipe(
    map(m => {
      switch (m?.type) {
        case MenuType.PrintMenu:
        case MenuType.PrintReportMenu:
          return 'Print Menu Size';
        case MenuType.PrintCardMenu:
        case MenuType.PrintLabelMenu:
          return 'Menu Printout Size';
        default:
          return 'Menu Preview Size';
      }
    })
  );

  public setPlaceholderForMenuSize$ = this.menu$.pipe(
    map(m => {
      switch (m?.type) {
        case MenuType.PrintMenu:
        case MenuType.PrintCardMenu:
        case MenuType.PrintLabelMenu:
          return 'Select the size of this print menu';
        default:
          return 'Select the size of this menu preview';
      }
    })
  );

  public toggleNewTagInput(): void {
    this.creatingNewTag$.pipe(
      take(1)
    ).subscribe(t => this._creatingNewTag.next(!t));
  }

  // Set data from component
  setNumberOfMenuColumns = (n: number) => this._numberOfMenuColumns.next(n);
  setOrientation = (orientation: Orientation) => this._orientation.next(orientation);
  setMenu(menu: Menu): void {
    this._menu.next(menu);
    this.setNumberOfMenuColumns(menu?.columnCount);
    this.setOrientation(menu?.displaySize?.orientation);
    this.setSelectedPrintoutSize(menu?.displaySize?.name);
  }

}
