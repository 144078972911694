import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { MenuDomainModel } from '../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../services/toast-service';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';
import { PrintJobViewModel } from './print-job-view-model';
import { BulkPrintJobDomainModel } from '../../../../domainModels/bulk-print-job-domain-model';
import { TemplateDomainModel } from '../../../../domainModels/template-domain-model';
import { BulkPrintJobType } from '../../../../models/automation/enum/bulk-print-job-type';
import { BulkPrintJob } from '../../../../models/automation/bulk-print-job';
import { StackPrintType } from '../../../../models/automation/enum/card-stack-print-type.enum';
import { StringUtils } from '../../../../utils/string-utils';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';
import type { StackType } from '../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';

export enum BulkPrintJobStep {
  SelectJobType = 0,
  SelectStackPrintType = 1,
  SelectStacks = 2,
  StackBulkPrintJob = 3,
  PrintMenuBulkPrintJob = 4,
}

@Injectable()
export class CreateViewBulkPrintJobViewModel extends PrintJobViewModel {

  constructor(
    ngbModal: NgbModal,
    router: Router,
    userDomainModel: UserDomainModel,
    activeModal: NgbActiveModal,
    bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    companyDomainModel: CompanyDomainModel,
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    smartFiltersDomainModel: SmartFiltersDomainModel,
    templateDomainModel: TemplateDomainModel,
    toastService: ToastService,
  ) {
    super(
      ngbModal,
      router,
      userDomainModel,
      activeModal,
      bulkPrintJobDomainModel,
      companyDomainModel,
      locationDomainModel,
      menuDomainModel,
      smartFiltersDomainModel,
      templateDomainModel,
      toastService,
    );
  }

  public mergeKey$ = of('editBulkPrintJob');

  private _step = new BehaviorSubject<BulkPrintJobStep>(undefined);
  connectToStep(step: BulkPrintJobStep) { this._step?.next(step); }
  public step$: Observable<BulkPrintJobStep> = combineLatest([
    this._step,
    this.userDomainModel.canUsePrintCards$,
  ]).pipe(
    map(([step, canUsePrintCards]) => {
      if (step === undefined) {
        return canUsePrintCards
          ? BulkPrintJobStep.SelectJobType
          : BulkPrintJobStep.PrintMenuBulkPrintJob;
      }
      return step;
    })
  );

  private _selectedBulkPrintJobType = new BehaviorSubject<BulkPrintJobType | null>(null);
  public selectedBulkPrintJobType$ = this._selectedBulkPrintJobType.pipe(distinctUntilChanged());
  connectToSelectedBulkPrintJobType(jobType: BulkPrintJobType) { this._selectedBulkPrintJobType?.next(jobType); }

  public type$ = this.selectedBulkPrintJobType$.pipe(
    map(t => t?.replace('BulkPrintJobType_', '')?.toLowerCase()),
    filter((t): t is StackType => t === 'card' || t === 'shelf talker' || t === 'label')
  );

  public selectedBulkPrintJobTypeIsStack$ = this.selectedBulkPrintJobType$.pipe(
    map(t => {
      return t === BulkPrintJobType.BulkPrintJobType_Card
          || t === BulkPrintJobType.BulkPrintJobType_ShelfTalker
          || t === BulkPrintJobType.BulkPrintJobType_Label;
    })
  );

  private _selectedStackPrintType = new BehaviorSubject<StackPrintType | null>(null);
  public selectedStackPrintType$ = this._selectedStackPrintType as BehaviorSubject<StackPrintType | null>;
  connectToSelectedStackPrintType(pt: StackPrintType) { this._selectedStackPrintType?.next(pt); }

  override connectToBulkPrintJob(bulkPrintJob: BulkPrintJob) {
    super.connectToBulkPrintJob(bulkPrintJob);
    this.connectToSelectedStackPrintType(bulkPrintJob?.getStackPrintType());
    this.connectToSelectedBulkPrintJobType(bulkPrintJob?.getBulkPrintJobType());
    const nextStep = bulkPrintJob?.getBulkPrintJobType() === BulkPrintJobType.BulkPrintJobType_Menu
      ? BulkPrintJobStep.PrintMenuBulkPrintJob
      : BulkPrintJobStep.StackBulkPrintJob;
    this.connectToStep(nextStep);
  }

  public stepIsSelectJobType$ = this.step$.pipe(map(s => s === BulkPrintJobStep.SelectJobType));
  public stepIsPrintMenuBulkPrintJob$ = this.step$.pipe(map(s => s === BulkPrintJobStep.PrintMenuBulkPrintJob));
  public stepIsSelectStackPrintType$ = this.step$.pipe(map(s => s === BulkPrintJobStep.SelectStackPrintType));

  public title$ = combineLatest([
    this.step$,
    this.viewOnly$,
    this.selectedStackPrintType$,
    this.type$.pipe(map(t => StringUtils.capitalizeFirstLetterOfEachWord(t)))
  ]).pipe(
    map(([step, viewOnly, cardStackPrintType, type]) => {
      switch (step) {
        case BulkPrintJobStep.SelectStacks:
          return `Select ${type} Stacks`;
        case BulkPrintJobStep.StackBulkPrintJob:
          const prefix = viewOnly ? 'View ' : '';
          const text = cardStackPrintType?.toLowerCase()?.includes('smart')
            ? 'Smart Print'
            : 'Manual Print';
          const suffix = viewOnly ? ' Job' : '';
          return `${prefix}${text}${suffix}`;
        default:
          return viewOnly ? 'View Bulk Print Job' : 'New Bulk Print Job';
      }
    })
  );

  public showPrimaryButton$ = this.step$.pipe(
    map(step => !(step === BulkPrintJobStep.SelectJobType || step === BulkPrintJobStep.SelectStackPrintType))
  );

  public primaryButtonText$ = combineLatest([this.step$, this.viewOnly$]).pipe(
    map(([step, viewOnly]) => {
      if (viewOnly) return 'Done';
      switch (step) {
        case BulkPrintJobStep.SelectStacks:
          return 'Next';
        default:
          return 'Print Job';
      }
    })
  );

  public showGoBackButton$ = combineLatest([
    this.stepIsSelectJobType$,
    this.stepIsPrintMenuBulkPrintJob$,
    this.viewOnly$,
    this.userDomainModel.canUsePrintCards$,
  ]).pipe(
    map(([
      isSelectJobType,
      isPrintMenuBulkPrintJob,
      viewOnly,
      canUsePrintCards
    ]) => {
      const bulkPrintStepForNonPrintCardUser = isPrintMenuBulkPrintJob && !canUsePrintCards;
      return !isSelectJobType && !viewOnly && !bulkPrintStepForNonPrintCardUser;
    })
  );

  public handleBulkPrintJobTypeSelected(jobType: BulkPrintJobType): void {
    this.connectToSelectedBulkPrintJobType(jobType);
    const nextStep = jobType === BulkPrintJobType.BulkPrintJobType_Menu
      ? BulkPrintJobStep.PrintMenuBulkPrintJob
      : BulkPrintJobStep.SelectStackPrintType;
    this.connectToStep(nextStep);
  }

  public handleStackPrintTypeSelected(printType: StackPrintType): void {
    this.connectToSelectedStackPrintType(printType);
    this.connectToStep(BulkPrintJobStep.SelectStacks);
  }

  public handlePrimaryButtonClicked(printJobForm: ReactiveFormMergeGroupsComponent): void {
    combineLatest([this.step$, this.viewOnly$, this.selectedStackPrintType$]).once(([
      step,
      viewOnly,
      cardStackPrintType
    ]) => {
      if (viewOnly) {
        this.activeModal.close();
        return;
      }
      if (step === BulkPrintJobStep.SelectStacks) {
        if (cardStackPrintType?.toLowerCase()?.includes('manual')) {
          this.activeModal.update({size: 'xl'});
        }
        this.connectToStep(BulkPrintJobStep.StackBulkPrintJob);
        return;
      }
      printJobForm.submitForms();
    });
  }

  public handleGoBack(): void {
    combineLatest([this.step$, this.selectedStackPrintType$]).once(([
      step,
      cardStackPrintType
    ]) => {
      switch (step) {
        case BulkPrintJobStep.PrintMenuBulkPrintJob:
          this.connectToStep(BulkPrintJobStep.SelectJobType);
          break;
        case BulkPrintJobStep.StackBulkPrintJob:
          if (cardStackPrintType?.toLowerCase()?.includes('manual')) {
            this.activeModal.update({size: 'md'});
          }
          this.connectToStep(BulkPrintJobStep.SelectStacks);
          break;
        default:
          this.connectToStep(--step);
      }
    });
  }

}
