import { Injectable } from '@angular/core';
import { MenuDetailsSectionViewModel } from '../../shared/menu-section-details/menu-details-section-view-model';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CardStackDetailsSectionViewModel extends MenuDetailsSectionViewModel {

  public printCardSize$ = combineLatest([
    this.menu$,
    window.types.printCardSizeTypes$,
  ]).pipe(
    map(([menu, printCardSizeTypes]) => {
      const printCardSize = menu?.metadata?.printCardSize;
      return printCardSizeTypes?.find(cardSize => cardSize?.value === printCardSize);
    })
  );

}
