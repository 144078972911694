import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss']
})
export class InfoBannerComponent {

  @Input() includeToggleSwitch: boolean = false;
  @Input() toggleState: boolean;
  @Output() buttonClicked = new EventEmitter();

}
