import { map } from 'rxjs/operators';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { combineLatest, timer } from 'rxjs';

@Injectable()
export class PageBannerViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  public company$ = this.companyDomainModel.company$;

  public companyMessages$ = this.company$.pipe(
    map(company => company?.messages)
  );

  public sanitizedPageBannerMessage$ = combineLatest([
    this.companyMessages$,
    timer(0, 30 * 60 * 1000)
  ]).pipe(
    map(([messages]) => messages?.getHighestPriorityMessage()),
    map(bannerMessage => this.sanitizer.sanitize(SecurityContext.HTML, bannerMessage?.message))
  );

}
