import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnOptionsViewModel } from '../../../viewModels/column-options-view-model';
import { HydratedSection } from '../../../../../models/menu/dto/hydrated-section';
import { TabBarItem } from '../../../../../models/shared/stylesheet/tab-bar-item';
import { map, take } from 'rxjs/operators';
import { TabBarComponent } from '../../../../shared/components/tab-bar/tab-bar.component';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { combineLatest, of } from 'rxjs';
import { ResizeObserver } from '@juggle/resize-observer';
import { TerpeneColumnOptionsFormComponent } from './components/terpene-column-options-form/terpene-column-options-form.component';
import { SingleColumnOptionsFormComponent } from './components/single-column-options-form/single-column-options-form.component';
import { CannabinoidColumnOptionsFormComponent } from './components/cannabinoid-column-options-form/cannabinoid-column-options-form.component';
import { Theme } from '../../../../../models/menu/dto/theme';
import { DefaultSectionColumnConfig } from '../../../../../models/menu/dto/default-section-column-config';
import { SectionColumnConfigKey, SectionColumnConfigProductInfoKey } from '../../../../../models/enum/dto/section-column-config-key';
import { SectionColumnConfigDefaultState } from '../../../../../models/enum/dto/section-column-config-default-state';
import { SectionType } from '../../../../../models/enum/dto/section-type';

@Component({
  selector: 'app-column-options-modal',
  templateUrl: './column-options-modal.component.html',
  styleUrls: ['./column-options-modal.component.scss'],
  providers: [ColumnOptionsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnOptionsModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: ColumnOptionsViewModel,
    public activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

  @ViewChild('contentContainer') tabContainer: ElementRef<HTMLDivElement>;
  @ViewChild('tabController') tabController: TabBarComponent;
  @ViewChild('columnOptionsFormGroup') columnOptionsFormGroup: ReactiveFormMergeGroupsComponent;

  public canChangeTabsErrorMsg$ = of('Please correct the invalid data before changing tabs.');

  private tabContainerRO: ResizeObserver;

  public tabs$ = combineLatest([
    this.viewModel.section$,
    this.viewModel.themeColumnConfig$,
    this.viewModel.sectionColumnConfigKeys$,
    this.viewModel.tabNames$
  ]).pipe(
    map(([section, themeColumnConfig, keys, tabNames]) => {
      const restockedSectionAssetColDisabled = (key: SectionColumnConfigKey): boolean => {
        const restockedSection = section?.sectionType === SectionType.RestockedProducts;
        return restockedSection && key === SectionColumnConfigProductInfoKey.Asset;
      };
      const isDisabled = (key: SectionColumnConfigKey): boolean => {
        const configDisabled = themeColumnConfig?.get(key)?.defaultState === SectionColumnConfigDefaultState.Disabled;
        return configDisabled || restockedSectionAssetColDisabled(key);
      };

      const getDisabledTabTooltip = (key: SectionColumnConfigKey): string => {
        return restockedSectionAssetColDisabled(key)
          ? `${key}s are disabled for restocked product sections.`
          : `${key}s are disabled for this theme.`;
      };

      const tabPosition = new Map<string, number>();
      tabNames?.forEach((tabName, i) => tabPosition.set(tabName, i));

      const tabDisabled = new Map<SectionColumnConfigKey, boolean>();
      keys.forEach((key) => tabDisabled.set(key.getSelectionValue(), isDisabled(key.getSelectionValue())));

      const tabs = [];
      let selectedIndexFound: boolean = false;
      let currentTabId = 0;
      const checkForInitialSelectedIndex = (key: SectionColumnConfigKey): void => {
        if (!selectedIndexFound && !tabDisabled.get(key)) {
          currentTabId = tabPosition.get(key);
          selectedIndexFound = true;
        }
      };
      tabNames?.forEach(tabName => {
        switch (tabName) {
          case 'Cannabinoids':
            tabs.push(new TabBarItem(CannabinoidColumnOptionsFormComponent, tabName, '', false));
            break;
          case 'Terpenes':
            tabs.push(new TabBarItem(TerpeneColumnOptionsFormComponent, tabName, '', false));
            break;
          default:
            const disable = tabDisabled.get(tabName as SectionColumnConfigKey);
            const tooltip = getDisabledTabTooltip(tabName as SectionColumnConfigKey);
            tabs.push(new TabBarItem(SingleColumnOptionsFormComponent, tabName, '', false, disable, null, tooltip));
            break;
        }
        checkForInitialSelectedIndex((tabName as SectionColumnConfigKey));
      });
      TabBarItem.setSelectedTab(tabs, currentTabId);
      return tabs;
    })
  );

  override setupBindings(): void {
    this.observeTabContainer();
  }

  setSection(section: HydratedSection) {
    this.viewModel.connectToSection(section);
  }

  setTheme(theme: Theme) {
    this.viewModel.connectToTheme(theme);
  }

  setDefaultColumnConfig(defaultColumnConfig: DefaultSectionColumnConfig) {
    this.viewModel.connectToManagingDefault(true);
    this.viewModel.connectToDefaultSectionConfig(defaultColumnConfig);
  }

  setPreviousTab(index: number) {
    this.viewModel.connectToPreviouslySelectedTabIndex(index);
  }

  setCurrentTab(index: number) {
    this.viewModel.connectToCurrentlySelectedTabIndex(index);
  }

  checkForChanges() {
    combineLatest([this.viewModel?.unsavedChangesInTab$, this.viewModel?.formIsValid$]).pipe(
      take(1),
      map(([unsavedChanges, formIsValid]) => {
        if (unsavedChanges && formIsValid) {
          this.columnOptionsFormGroup.submitForms(true);
        }
      })
    ).subscribe();
  }

  private observeTabContainer(): void {
    this.tabContainerRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.calculateBottomButtonContainerPosition(entry.contentRect.height);
      }
    });
    this.tabContainerRO.observe(this.tabContainer.nativeElement);
  }

  private calculateBottomButtonContainerPosition(contentHeight: number): void {
    const dialogRect = document.getElementsByClassName('modal-dialog').item(0)?.getBoundingClientRect();
    const headerRect = document.getElementsByClassName('sticky-header-container').item(0)?.getBoundingClientRect();
    const footerRect = document.getElementsByClassName('sticky-footer-container').item(0)?.getBoundingClientRect();
    const heightUntilScrollableContent = (dialogRect?.height - headerRect?.height) - footerRect?.height;
    const position = contentHeight > heightUntilScrollableContent ? 'sticky' : 'absolute';
    this.viewModel.connectToBottomButtonPosition(position);
  }

}
