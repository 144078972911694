import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { VariantProperty } from '../../enum/dto/variant-property.enum';

export class DisplayNameCustomParserConfig implements Deserializable, UniquelyIdentifiable {

  constructor() {
    this.customParserString = '';
    this.exampleString = '';
    this.expectedComponents = 1;
    this.customParserMapping = new Map<number, VariantProperty>();
    this.removeBrandName = false;
    this.appendPrefixTerms = new Map<string, string>();
    this.useBudBot = false;
    this.inStockOnly = false;
  }

  public customParserString: string;
  public exampleString: string;
  public expectedComponents: number;
  public customParserMapping: Map<number, VariantProperty>;
  public removeBrandName: boolean;
  public appendPrefixTerms: Map<string, string>;
  public useBudBot: boolean;
  public inStockOnly: boolean;

  onDeserialize(): void {
    const Deserialize = window?.injector?.Deserialize;
    this.customParserMapping = Deserialize.genericNumberKeyMap(this.customParserMapping)
      ?? new Map<number, VariantProperty>();
    this.appendPrefixTerms = Deserialize.genericMap<string, string>(this.appendPrefixTerms)
      ?? new Map<string, string>();
  }

  getUniqueIdentifier(...opts: any): string {
    const customParserMappingIds: string[] = [];
    this.customParserMapping?.forEach((val, key) => customParserMappingIds.push(`${key}-${val}`));
    const customParserMappingId = customParserMappingIds?.sort()?.join(',') ?? '';
    const appendPrefixTermsIds: string[] = [];
    this.appendPrefixTerms?.forEach((val, key) => appendPrefixTermsIds.push(`${key}-${val}`));
    const appendPrefixTermsId = appendPrefixTermsIds?.sort()?.join(',') ?? '';
    return `
      -${this.customParserString}
      -${this.exampleString}
      -${this.expectedComponents}
      -${customParserMappingId}
      -${this.removeBrandName}
      -${appendPrefixTermsId}
      -${this.useBudBot}
      -${this.inStockOnly}
    `;
  }

}
