import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { Menu } from '../../../models/menu/dto/menu';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { exists } from '../../../functions/exists';
import { StringifyUtils } from '../../../utils/stringify-utils';
import { LabelDomainModel } from '../../../domainModels/label-domain-model';
import { MenuType } from '../../../models/utils/dto/menu-type-definition';

@Injectable()
export class PrintCardLiveViewViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private sanitizer: DomSanitizer
  ) {
    super();
    this.receiveCardDimensionsFromIFrame();
  }

  private sendCompanyConfigSub: Subscription|null = null;
  private sendLocationConfigSub: Subscription|null = null;
  private sendPrintCardMenuSub: Subscription|null = null;
  private sendVariantIdsSub: Subscription|null = null;
  private sendLabelsSub: Subscription|null = null;
  private sendMetadataSub: Subscription|null = null;

  private readonly borderInPx$ = of(0);
  public readonly companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  public readonly labels$ = this.labelDomainModel.allLabels$;
  public readonly iFrameName$ = of(Date.now()?.toString(10));

  private _startTalking = new Subject<void>();
  public startTalking$ = this._startTalking as Observable<void>;

  private readonly _printCardMenu = new BehaviorSubject<Menu|null>(null);
  public readonly printCardMenu$ = this._printCardMenu as Observable<Menu|null>;
  connectToPrintCardMenu = (printCardMenu: Menu|null) => this._printCardMenu.next(printCardMenu);

  public readonly url$ = this.printCardMenu$.pipe(
    map(menu => {
      return this.generateLiveViewURL(menu?.type);
    })
  );

  private readonly _variantIds = new BehaviorSubject<string[]|null>(null);
  public readonly variantIds$ = this._variantIds as Observable<string[]|null>;
  connectToVariantIds = (variantIds: string[]|null) => this._variantIds.next(variantIds);

  private readonly _cardHeight = new BehaviorSubject<number|null>(0);
  public readonly cardHeight$ = combineLatest([
    this.borderInPx$,
    this._cardHeight
  ]).pipe(
    filter(([_, cardHeight]) => exists(cardHeight)),
    map(([borderInPx, cardHeight]) => cardHeight + (borderInPx * 2)),
    distinctUntilChanged()
  );
  connectToCardHeight = (cardHeight: number|null) => this._cardHeight.next(cardHeight);

  private readonly _cardWidth = new BehaviorSubject<number|null>(0);
  public readonly cardWidth$ = combineLatest([
    this.borderInPx$,
    this._cardWidth
  ]).pipe(
    filter(([_, cardWidth]) => exists(cardWidth)),
    map(([borderInPx, cardWidth]) => cardWidth + (borderInPx * 2)),
    distinctUntilChanged()
  );
  connectToCardWidth = (cardWidth: number|null) => this._cardWidth.next(cardWidth);

  public readonly boxShadow$ = this.cardHeight$.pipe(
    map((height) => exists(height) ? '1px 1px 6px black' : null),
  );

  private readonly _parentContainerWidth = new BehaviorSubject<number|null>(null);
  public readonly parentContainerWidth$ = this._parentContainerWidth.pipe(distinctUntilChanged());
  connectToParentContainerWidth = (width: number|null) => this._parentContainerWidth.next(width);

  private readonly _parentContainerHeight = new BehaviorSubject<number|null>(null);
  public readonly parentContainerHeight$ = this._parentContainerHeight.pipe(distinctUntilChanged());
  connectToParentContainerHeight = (height: number|null) => this._parentContainerHeight.next(height);

  private readonly _userSetScale = new BehaviorSubject<number|null>(null);
  public readonly userSetScale$ = this._userSetScale.pipe(distinctUntilChanged());
  connectToUserSetScale = (scale: number|null) => this._userSetScale.next(scale);

  private readonly _metadata = new BehaviorSubject<any|null>(null);
  public readonly metadata$ = this._metadata as Observable<any|null>;
  connectToMetadata = (metadata: any|null) => this._metadata.next(metadata);

  public readonly scaleToParentView$ = combineLatest([
    this.cardHeight$,
    this.cardWidth$,
    this.parentContainerHeight$,
    this.parentContainerWidth$
  ]).pipe(
    map(([cardHeight, cardWidth, parentContainerHeight, parentContainerWidth]) => {
      const heightRatio = (parentContainerHeight && cardHeight) ? (parentContainerHeight / cardHeight) : 1;
      const widthRatio = (parentContainerWidth && cardWidth) ? (parentContainerWidth / cardWidth) : 1;
      return Math.min(heightRatio, widthRatio, 1);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly scale$ = combineLatest([
    this.userSetScale$,
    this.scaleToParentView$
  ]).pipe(
    map(([userSetScale, scaleToParentView]) => userSetScale || scaleToParentView)
  );

  private generateLiveViewURL(menuType: MenuType): SafeResourceUrl {
    const cacheBuster = '?cacheBuster=' + Date.now() + Math.floor(Math.random() * 1000000);
    const env = (environment.production && environment.environmentName !== 'rc')
      ? ''
      : `${environment.environmentName}.`;
    const menuTypeUrlText = menuType === MenuType.PrintLabelMenu ? 'print-label' : 'print-card';
    const url = `https://${env}display.mybudsense.com/#/${menuTypeUrlText}/live-view/${cacheBuster}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public sendDataToIFrame(iFrame: HTMLIFrameElement): void {
    this.sendCompanyConfig(iFrame);
    this.sendLocationConfig(iFrame);
    this.sendPrintCardMenu(iFrame);
    this.sendVariantIds(iFrame);
    this.sendLabels(iFrame);
    this.sendMetadata(iFrame);
  }

  private sendCompanyConfig(iFrame: HTMLIFrameElement): void {
    this.sendCompanyConfigSub?.unsubscribe();
    this.sendCompanyConfigSub = this.companyConfig$.subscribeWhileAlive({
      owner: this,
      next: (companyConfig) => {
        const msg = { companyConfig: JSON.parse(JSON.stringify(companyConfig, StringifyUtils.displayAppReplacer)) };
        iFrame?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  private sendLocationConfig(iFrame: HTMLIFrameElement): void {
    this.sendLocationConfigSub?.unsubscribe();
    this.sendLocationConfigSub = this.locationConfig$.subscribeWhileAlive({
      owner: this,
      next: (locationConfig) => {
        const msg = { locationConfig: JSON.parse(JSON.stringify(locationConfig, StringifyUtils.displayAppReplacer)) };
        iFrame?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  private sendPrintCardMenu(iFrame: HTMLIFrameElement): void {
    this.sendPrintCardMenuSub?.unsubscribe();
    this.sendPrintCardMenuSub = this.printCardMenu$.subscribeWhileAlive({
      owner: this,
      next: (printCardMenu) => {
        const msg = { printCardMenu: JSON.parse(JSON.stringify(printCardMenu, StringifyUtils.displayAppReplacer)) };
        iFrame?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  private sendVariantIds(iFrame: HTMLIFrameElement): void {
    this.sendVariantIdsSub?.unsubscribe();
    this.sendVariantIdsSub = this.variantIds$.subscribeWhileAlive({
      owner: this,
      next: (variantIds) => {
        const msg = { variantIds };
        iFrame?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  private sendLabels(iFrame: HTMLIFrameElement): void {
    this.sendLabelsSub?.unsubscribe();
    this.sendLabelsSub = this.labels$.subscribeWhileAlive({
      owner: this,
      next: (labels) => {
        const msg = { labels: JSON.parse(JSON.stringify(labels, StringifyUtils.displayAppReplacer)) };
        iFrame?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  private sendMetadata(iFrame: HTMLIFrameElement): void {
    this.sendMetadataSub?.unsubscribe();
    this.sendMetadataSub = this.metadata$.subscribeWhileAlive({
      owner: this,
      next: (metadata) => {
        const msg = { metadata: JSON.parse(JSON.stringify(metadata, StringifyUtils.displayAppReplacer)) };
        iFrame?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  /**
   * All messages coming from window get intercepted here. This means messages that the dashboard app sends
   * to the display app will be received here as well. Therefore, we must check if the specific message contains
   * relevant data, or else we can end up in an infinite loop.
   */
  private receiveCardDimensionsFromIFrame(): void {
    fromEvent(window, 'message').pipe(
      filter((event: MessageEvent) => {
        const origin = event?.origin?.toLowerCase();
        const hasData = exists(event?.data);
        return hasData && (origin?.includes('localhost') || origin?.includes('mybudsense.com'));
      }),
      map((event: MessageEvent) => event?.data)
    ).subscribeWhileAlive({
      owner: this,
      next: (msg: any) => {
        if (Object.keys(msg)?.includes('cardHeight') && msg?.cardHeight === undefined) this._startTalking.next();
        if (msg?.cardHeight) this.connectToCardHeight(msg?.cardHeight);
        if (msg?.cardWidth) this.connectToCardWidth(msg?.cardWidth);
      }
    });
  }

}
