import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { CollectionRoutingModule } from './collection-routing.module';
import { EditCollectionComponent } from './components/edit-collection/edit-collection.component';
import { TemplateModule } from '../template/template.module';
import { ReactiveFormModule } from '@mobilefirstdev/reactive-form';
import { DisplayModule } from '../display/display.module';
import { EditCollectionHeaderComponent } from './components/edit-collection/edit-collection-header/edit-collection-header.component';
import { EditCollectionDisplaysComponent } from './components/edit-collection/edit-collection-displays/edit-collection-displays.component';
import { EditCollectionDetailsComponent } from './components/edit-collection/edit-collection-details/edit-collection-details.component';
import { ReactiveButtonsModule } from '@mobilefirstdev/reactive-buttons';
import { EditCollectionLocationListItemComponent } from './components/edit-collection/edit-collection-displays/edit-collection-location-list-item/edit-collection-location-list-item.component';
import { CollectionDisplaysModalContainerComponent } from './components/modals/collection-displays-modal-container/collection-displays-modal-container.component';
import { RequiredDisplayEditMethodComponent } from './components/modals/required-display-edit-method/required-display-edit-method.component';
import { EditCollectionEditRequiredDisplaysComponent } from './components/modals/edit-collection-edit-required-displays/edit-collection-edit-required-displays.component';
import { EditCollectionCreateNewDisplayComponent } from './components/modals/edit-collection-create-new-display/edit-collection-create-new-display.component';
import { SelectLocationsForNewDisplayComponent } from './components/modals/select-locations-for-new-display/select-locations-for-new-display.component';
import { ViewCollectionDisplaysComponent } from './components/modals/view-collection-displays/view-collection-displays.component';
import { DisplayMarkedAsRequiredPipe } from './pipes/display-marked-as-required.pipe';
import { DisplayTitlePipe } from './pipes/display-title.pipe';
import { LocationDisplayListItemComponent } from './shared/location-list-item/location-display-list-item.component';
import { GroupedProvinceLocationDisplayListItemsComponent } from './shared/grouped-province-location-display-list-items/grouped-province-location-display-list-items.component';
import { PublishCollectionComponent } from './components/modals/publish-collection/publish-collection.component';
import { SingleProvinceLocationDisplayListItemsComponent } from './shared/single-province-location-display-list-items/single-province-location-display-list-items.component';
import { LocationDisplaySelectionGroupComponent } from './shared/collection-display-selection-group/location-display-selection-group.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { DisplaySelectionItemComponent } from './shared/display-selection-item/display-selection-item.component';
import { GetDisplaysAsSelectionPipe } from './pipes/get-displays-as-selection.pipe';
import { GetDisplayGroupSelectedCountPipe } from './pipes/get-display-group-selected-count.pipe';
import { GetDisplaysForProvincePipe } from './pipes/get-displays-for-province.pipe';
import { CollectionDisplaysModalHeaderComponent } from './components/modals/collection-displays-modal-header/collection-displays-modal-header.component';
import { GetCollectionNameFromIdPipe } from './pipes/get-collection-name-from-id.pipe';
import { NumberOfNewDisplaysStringPipe } from './pipes/number-of-new-displays-string.pipe';
import { EditCollectionPendingDisplayFormItemComponent } from './components/edit-collection/edit-collection-displays/edit-collection-pending-display-form-item/edit-collection-pending-display-form-item.component';
import { CollectionChangesStringPipe } from './pipes/collection-changes-string.pipe';
import { EditCollectionSaveConfirmationComponent } from './components/modals/edit-collection-save-confirmation/edit-collection-save-confirmation.component';
import { DisplayChangesStringPipe } from './pipes/display-changes-string.pipe';
import { StickyAutoPositionDirective } from '@mobilefirstdev/sticky-auto-position';
import { RxPush } from '@rx-angular/template/push';

@NgModule({
  declarations: [
    EditCollectionComponent,
    EditCollectionHeaderComponent,
    EditCollectionDisplaysComponent,
    EditCollectionDetailsComponent,
    EditCollectionLocationListItemComponent,
    CollectionDisplaysModalContainerComponent,
    RequiredDisplayEditMethodComponent,
    EditCollectionEditRequiredDisplaysComponent,
    EditCollectionCreateNewDisplayComponent,
    SelectLocationsForNewDisplayComponent,
    ViewCollectionDisplaysComponent,
    DisplayMarkedAsRequiredPipe,
    DisplayTitlePipe,
    LocationDisplayListItemComponent,
    GroupedProvinceLocationDisplayListItemsComponent,
    PublishCollectionComponent,
    SingleProvinceLocationDisplayListItemsComponent,
    LocationDisplaySelectionGroupComponent,
    DisplaySelectionItemComponent,
    GetDisplaysAsSelectionPipe,
    GetDisplayGroupSelectedCountPipe,
    GetDisplaysForProvincePipe,
    CollectionDisplaysModalHeaderComponent,
    GetCollectionNameFromIdPipe,
    NumberOfNewDisplaysStringPipe,
    EditCollectionPendingDisplayFormItemComponent,
    CollectionChangesStringPipe,
    EditCollectionSaveConfirmationComponent,
    DisplayChangesStringPipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    CollectionRoutingModule,
    TemplateModule,
    ReactiveFormModule,
    DisplayModule,
    ReactiveButtonsModule,
    NgbModule,
    FlexLayoutModule,
    StickyAutoPositionDirective,
    RxPush
  ]
})
export class CollectionModule {
}
