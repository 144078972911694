import { Injectable } from '@angular/core';
import type { Size } from '../../../../../../models/shared/size';
import { of } from 'rxjs';
import { ModalShelfTalkerLiveView } from '../../../../../../modals/modal-shelf-talker-live-view';
import { EditProductMenuViewModel } from '../../../../viewModels/edit-product-menu-view-model';

@Injectable()
export class EditShelfTalkerBrandsMenuViewModel extends EditProductMenuViewModel {

  public menuTypes$ = window.types.menuTypes$;
  public mergeKey$ = of('editShelfTalkerMenu');

  public override openLiveViewModal(sizeOverride?: Size) {
    this.menu$.once(menu => ModalShelfTalkerLiveView.open(this.ngZone, this.ngbModal, this.injector, menu));
  }

}
