import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { ComputeLabelInterface } from './compute-label-interface';
import { Injectable } from '@angular/core';
import { Label } from '../../../models/shared/label';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { Variant } from '../../../models/product/dto/variant';
import { SaleSystemLabel } from '../../../models/shared/labels/sale-system-label';
import { ColorUtils } from '../../../utils/color-utils';
import { LabelUtils } from '../utils/label-utils';
import { SystemLabel } from '../../../models/shared/labels/system-label';
import { LowStockSystemLabel } from '../../../models/shared/labels/low-stock-system-label';
import { RestockSystemLabel } from '../../../models/shared/labels/restock-system-label';
import { NewSystemLabel } from '../../../models/shared/labels/new-system-label';
import { DisplayLabelInterface } from './display-label-interface';
import { SaleLabelFormat } from '../../../models/utils/dto/sale-label-format-type';
import { DisplaySaleLabelInterface } from './display-sale-label-interface';
import { LabelDomainModel } from '../../../domainModels/label-domain-model';
import { exists } from '../../../functions/exists';
import { Menu } from '../../../models/menu/dto/menu';
import { Section } from '../../../models/menu/dto/section';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { PriceFormat } from '../../../models/enum/dto/price-format';
import { SortUtils } from '../../../utils/sort-utils';

/**
 * There are TWO pools of LABEL objects: Location and Company.
 * This allows us to:
 * 1) Allow locations to have their labels update and managed by the company.
 * 2) Allow locations to unlink from the company and manage their labels independently.
 *
 * When a location is linked to a company, then the label object at the company
 * layer is used as a "Template" to send updates to all location level labels that
 * are a copy of the company level label "Template". This means if an update happens
 * at the company level, then the company level label will find all location level
 * labels that have the same ID as itself, and update them to match itself.
 *
 * If a user disconnects a label from being managed by the company, then the label
 * will not receive updates from the label in the company label pool. This location
 * label will be a "standalone" label that is managed by the location, and will not
 * receive updates from changes at the company level.
 *
 * - user applied = custom (labelOverride) or featured (variantFeature)
 * - system applied = sale, low stock, restock, new
 * - can have multiple labels in each pool when dealing with multiple variants
 * - highest priority pool for user defined labels is used for label calculation
 * - DA stands for display attribute data model.
 * - Display attributes can exist at a company and location level.
 --------------------------------------------------------------------------------
 |                  User Applied Labels                    |   System Applied   |
 -------------------------------------------------------------------------------|
 |  Highest Priority                                       |                    |
 |        ↑             Override Labels (Green Label) (purp|    System Label    |
 |        |                 ** Sorted by Hierarchy **      |                    |
 |        |            ------------------------------------|    (Sale Label) (re|
 |        |                                                |                    |
 |  Middle Priority  Location DA Labels (Yellow Label) (blu|   **  Sorted   **  |
 |        |                 ** Sorted by Hierarchy **      |   **    by     **  |
 |        |            ------------------------------------|   ** Hierarchy **  |
 |        |                                                |                    |
 |  Lowest Priority  Company DA Labels (Violet Label) (oran|                    |
 |        |                 ** Sorted by Hierarchy **      |                    |
 |____________________________ ↓ __________________________|________ ↓ _________|
 |                  User Applied Label Output              |   System Output    |
 |                             ↓                           |         ↓          |
 |                        Green Label                      |         |          |
 | - does the user applied label exist as a Location Label |         |          |
 |   data object?                                          |         |          |
 |   Yes. Then send Green Label into the next step         |         |          |
 |   No. Then the location has explicitly removed this     |         |          |
 |       label from their location and don't want to see   |     Sale Label     |
 |       it, so stop the green label from making it to the |         |          |
 |       next step                                         |         |          |
 |                             ↓                           |         |          |
 |     greenInLocationLabelPool ? Green Label : null       |         |          |
 | --------------------------- ↓ ----------------------------------- ↓ ---------|
 |  1) User applied: Green Label, System applied: Sale Label                    |
 |  2) Apply label hierarchy to green label and sale label                      |
 |  3) The label that is displayed is the one with the higher                   |
 |     priority in the label hierarchy                                          |
 --------------------------------------------------------------------------------
 */

@Injectable()
export class LabelViewModel extends BaseViewModel {

  constructor(
    protected labelDomainModel: LabelDomainModel,
    protected productDomainModel: ProductDomainModel
  ) {
    super();
  }

  private _computeLabelInterface = new BehaviorSubject<ComputeLabelInterface>(null);
  public readonly computeLabelInterface$ = this._computeLabelInterface as Observable<ComputeLabelInterface>;
  connectToComputeLabelInterface = (i: ComputeLabelInterface) => this._computeLabelInterface.next(i);

  private _displayLabelInterface = new BehaviorSubject<DisplayLabelInterface>(null);
  public readonly displayLabelInterface$ = this._displayLabelInterface as Observable<DisplayLabelInterface>;
  connectToDisplayLabelInterface = (i: DisplayLabelInterface) => this._displayLabelInterface.next(i);

  private _displaySaleLabelInterface = new BehaviorSubject<DisplaySaleLabelInterface>(null);
  public readonly displaySaleLabelInterface$ = this._displaySaleLabelInterface as Observable<DisplaySaleLabelInterface>;
  connectToDisplaySaleLabelInterface = (i: DisplaySaleLabelInterface) => {
    this._displaySaleLabelInterface.next(i);
  };

  public readonly interfaces$ = combineLatest([
    this.displayLabelInterface$,
    this.computeLabelInterface$,
    this.displaySaleLabelInterface$
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly computationInterface$ = combineLatest([
    this.computeLabelInterface$,
    this.displaySaleLabelInterface$
  ]).pipe(
    map(([computeLabelInterface, displaySaleLabelInterface]) => computeLabelInterface || displaySaleLabelInterface),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public displayableInterface$ = combineLatest([
    this.displayLabelInterface$,
    this.displaySaleLabelInterface$
  ]).pipe(
    map(([displayLabelInterface, displaySaleLabelInterface]) => displayLabelInterface || displaySaleLabelInterface),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /* ************************************ Private Internal Computation ************************************ */

  private readonly locationConfig$ = this.interfaces$.pipe(
    map(([displayLabelInterface, computeLabelInterface, displaySaleLabelInterface]) => {
      return displayLabelInterface?.getLocationConfigForLabelComponent()
          || computeLabelInterface?.getLocationConfigForLabelComponent()
          || displaySaleLabelInterface?.getLocationConfigForLabelComponent();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly locationId$ = this.locationConfig$.pipe(
    map(locationConfig => locationConfig?.locationId),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly priceFormat$ = this.locationConfig$.pipe(
    map(locationConfig => locationConfig?.priceFormat),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly companyConfig$ = this.interfaces$.pipe(
    map(([displayLabelInterface, computeLabelInterface, displaySaleLabelInterface]) => {
      return displayLabelInterface?.getCompanyConfigForLabelComponent()
          || computeLabelInterface?.getCompanyConfigForLabelComponent()
          || displaySaleLabelInterface?.getCompanyConfigForLabelComponent();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly companyId$ = this.companyConfig$.pipe(
    map(companyConfig => companyConfig?.companyId),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly menu$ = this.computationInterface$.pipe(
    map(componentInterface => componentInterface?.getMenuForLabelComponent()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly section$ = this.computationInterface$.pipe(
    map(componentInterface => componentInterface?.getSectionForLabelComponent()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly variants$ = this.computationInterface$.pipe(
    map(componentInterface => componentInterface?.getVariantsForLabelComponent()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly products$ = combineLatest([
    this.productDomainModel.currentLocationProducts$,
    this.variants$
  ]).pipe(
    map(([products, variants]) => {
      return products?.filter(product => variants?.some(variant => variant?.productId === product?.id));
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly labels$ = this.labelDomainModel.allLabels$;
  private readonly posLabels$ = this.labelDomainModel.companyPOSLabels$;

  /**
   * Location level label objects contain "copies" of company level label objects.
   * Company level label objects are purely used as a "template" to send mass updates
   * out to all locations, by transferring the company level label object data to a
   * copy of that label within the location level.
   *
   * This allows for a company to directly manage labels across locations, and also
   * allows for locations to disconnect from the company and manage their own labels.
   *
   * The latter is important for companies that own different branded stores, and therefore
   * don't want to share labels across locations.
   *
   * POS Labels only exist at a company level, but can be manually or automatically assigned to company
   * display attributes. We must reference them since we do not allow for location overrides/modification
   *
   */
  private readonly locationLabelsContainingCompanyLevelCopies$ = combineLatest([
    this.labels$,
    this.posLabels$,
    this.locationId$
  ]).pipe(
    map(([labels, posLabelsForLocation, locationId]) => {
      const locationLabels = labels?.filter(label => label?.locationId === locationId);
      return locationLabels?.concat(posLabelsForLocation);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  private readonly systemLabels$ = this.labelDomainModel.systemLabels$;
  private readonly lowStockSystemLabel$ = this.labelDomainModel.lowStockSystemLabel$;
  private readonly restockSystemLabel$ = this.labelDomainModel.restockSystemLabel$;
  private readonly saleSystemLabel$ = combineLatest([
    this.displayableInterface$,
    this.labelDomainModel.saleSystemLabel$
  ]).pipe(
    map(([displayInterface, saleSystemLabel]) => {
      const chosenLabelToDisplay = displayInterface
        ?.getLabelsForLabelComponent()
        ?.sort(SortUtils.sortLabelsByPriority)
        ?.firstOrNull();
      return chosenLabelToDisplay instanceof SaleSystemLabel ? chosenLabelToDisplay : saleSystemLabel;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  private readonly newSystemLabel$ = this.labelDomainModel.newSystemLabel$;

  /**
   * locationLabelsContainingCompanyLevelCopies$ is used on purpose here
   * read comment: Clarification between location and company label objects
   */
  private readonly sortedOverrideLabelIds$ = combineLatest([
    this.menu$,
    this.section$,
    this.variants$,
    this.locationLabelsContainingCompanyLevelCopies$
  ]).pipe(
    map(([menu, section, variants, locationLabels]) => {
      return LabelUtils.getSortedOverrideLabelIds(menu, section, variants, locationLabels);
    })
  );

  private readonly sortedLocationLabelIds$ = combineLatest([
    this.variants$,
    this.locationLabelsContainingCompanyLevelCopies$
  ]).pipe(
    map(([variants, locationLabels]) => LabelUtils.getSortedLocationLabelIds(variants, locationLabels))
  );

  /**
   * Clarification between location and company label objects
   *
   * 1) Why is this the location labels object pool being piped into here and not the company labels
   *    object pool you ask?
   *
   *    Because structurally, labels are complicated and hard to grasp.
   *    The location label pool of objects have direct copies of data from the company label object pool.
   *    THEREFORE, when you filter label objects by object.locationId === storeLocationId, you are actually
   *    getting label data for the store, and copies of company level label objects stored in at a location level.
   *    So this pool is both the location and company data pool!
   *
   * 2) Then what is the company label object pool used for (object.locationId === companyId)?
   *
   *    The company label object pool is essentially a tool used to send mass updates to the location
   *    label object pool. Meaning, if I change a company label styling, then the company label object
   *    is used as a "template" or "duplicator" to update any location level copies of that label, but
   *    only if the location level label is "isCompanyManaged" or "isCompanyLinked", THEREFORE, company
   *    label objects are useless OUTSIDE the context of: SETTINGS > CUSTOMIZATION > EDIT LABELS > COMPANY LABELS.
   *
   *    TL:DR - The company label object pool is used to update the location label object pool.
   *            Only look at the location object pool for displaying labels.
   */
  private readonly sortedCompanyLabelIds$ = combineLatest([
    this.variants$,
    this.locationLabelsContainingCompanyLevelCopies$
  ]).pipe(
    map(([variants, locationLabels]) => LabelUtils.getSortedCompanyLabelIds(variants, locationLabels)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly priorityBasedLabelIdPool$ = combineLatest([
    this.sortedOverrideLabelIds$,
    this.sortedLocationLabelIds$,
    this.sortedCompanyLabelIds$
  ]).pipe(
    map(([overrideLabelIds, locationLabelIds, companyLabelIds]) => {
      return LabelUtils.priorityBasedLabelPool(overrideLabelIds, locationLabelIds, companyLabelIds);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly sortedPriorityBasedLabelPool$ = combineLatest([
    this.priorityBasedLabelIdPool$,
    this.locationLabelsContainingCompanyLevelCopies$
  ]).pipe(
    map(([labelIdPool, labels]) => LabelUtils.sortedPriorityBasedLabelPool(labelIdPool, labels)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly labelToCompareWithSystemLabel$ = this.sortedPriorityBasedLabelPool$.pipe(
    map(LabelUtils.labelToCompareWithSystemLabel),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private systemLabelCalculationData = <T extends SystemLabel>(
    systemLabel$: Observable<T>
  ): Observable<[T, Menu,  Section, number, number, PriceFormat]> => {
    return combineLatest([
      systemLabel$,
      this.menu$,
      this.section$,
      this.locationId$,
      this.companyId$,
      this.priceFormat$
    ]).pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };

  private variantLowStockSystemLabelOrNull(variant: Variant): Observable<LowStockSystemLabel | null> {
    return this.systemLabelCalculationData(this.lowStockSystemLabel$).pipe(
      map(([lowStockLabel, menu, section]) => {
        if (menu?.menuOptions?.hideInventoryLabels) return null;
        if (section?.metadata?.hideLowStockLabels === 'true') return null;
        return LabelUtils.variantLowStockSystemLabelOrNull(variant, lowStockLabel);
      })
    );
  }

  private variantRestockSystemLabelOrNull(variant: Variant): Observable<RestockSystemLabel | null> {
    return this.systemLabelCalculationData(this.restockSystemLabel$).pipe(
      map(([restockLabel, menu, section]) => {
        if (menu?.menuOptions?.hideInventoryLabels) return null;
        if (section?.metadata?.hideRestockedLabels === 'true') return null;
        return LabelUtils.variantRestockSystemLabelOrNull(variant, restockLabel);
      })
    );
  }

  private variantSaleLabelOrNull(variant: Variant): Observable<SaleSystemLabel | null> {
    return this.systemLabelCalculationData(this.saleSystemLabel$).pipe(
      map(([saleLabel, menu, _, locationId, companyId, priceStream]) => {
        return LabelUtils.variantSaleLabelOrNull(menu, variant, saleLabel, locationId, companyId, priceStream);
      })
    );
  }

  private variantNewLabelOrNull(variant: Variant): Observable<NewSystemLabel | null> {
    return this.systemLabelCalculationData(this.newSystemLabel$).pipe(
      map(([newLabel, _, section]) => {
        if (section?.metadata?.hideNewLabels === 'true') return null;
        return LabelUtils.variantNewLabelOrNull(variant, newLabel);
      })
    );
  }

  private readonly systemLabelPool$ = this.variants$.pipe(
    switchMap(variants => {
      const parallelVariantLabelPipelines$: Observable<SystemLabel[]>[] = variants?.map(variant => {
        return combineLatest([
          this.variantLowStockSystemLabelOrNull(variant),
          this.variantRestockSystemLabelOrNull(variant),
          this.variantSaleLabelOrNull(variant),
          this.variantNewLabelOrNull(variant),
        ]).pipe(
          map(systemLabels => systemLabels?.filterNulls())
        );
      });
      // Unique list of system labels provided from all variants within this line item.
      const getUniqueListOfLabelsForVariants$ = combineLatest(parallelVariantLabelPipelines$).pipe(
        map((parallelVariantSystemLabels: SystemLabel[][]) => parallelVariantSystemLabels.flatten<SystemLabel[]>()),
        map((systemLabels: SystemLabel[]) => systemLabels?.uniqueByProperty('id'))
      );
      const hasParallelPipes = parallelVariantLabelPipelines$?.length > 0;
      return hasParallelPipes ? getUniqueListOfLabelsForVariants$ : of([] as SystemLabel[]);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly sortedSystemLabelPool$ = this.systemLabelPool$.pipe(
    map(LabelUtils.sortedSystemLabelPool),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly systemLabelToCompareWithCustomLabel$ = this.sortedSystemLabelPool$.pipe(
    map(LabelUtils.systemLabelToCompareWithCustomLabel),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /* ************************************ Primary Label ************************************ */

  public readonly saleLabelFormat$ = combineLatest([
    this.displayableInterface$,
    combineLatest([
      this.menu$,
      this.section$,
      this.products$,
      this.variants$,
      this.saleSystemLabel$,
    ]),
    combineLatest([
      this.locationConfig$,
      this.companyConfig$,
      this.priceFormat$
    ])
  ]).pipe(
    map(([
      displayInterface,
      [menu, section, products, variants, saleLabel],
      [locationConfig, companyConfig, priceStream]
    ]) => {
      const displayLabelInterfaceSaleLabelFormat = displayInterface?.overrideSaleLabelFormatIfDefined();
      if (!!displayLabelInterfaceSaleLabelFormat) return displayLabelInterfaceSaleLabelFormat;
      const hasMultipleVariantsPerLineItem = section?.hasMultipleVariantsPerLineItem();
      const sectionLevel = section?.saleLabelFormat;
      const locationLevel = locationConfig?.saleLabelFormat;
      const companyLevel = companyConfig?.saleLabelFormat;
      const saleFormat = sectionLevel || locationLevel || companyLevel || SaleLabelFormat.SALE;
      const locationId = locationConfig?.locationId;
      const companyId = companyConfig?.companyId;
      let uniqueSharedLabel = false;
      if (hasMultipleVariantsPerLineItem) {
        let gridVariants = exists(section)
          ? section?.getScopedVisibleVariantsForGridMode(
              products,
              variants,
              menu,
              priceStream,
              menu?.isProductMenuWithSectionLevelOverflow()
            )
            ?.flatten<Variant[]>()
          : variants;
        if (!section?.showZeroStockItems) gridVariants = gridVariants?.filter(variant => variant?.inStock());
        const saleLabels = gridVariants?.map(variant => {
          const saleLabelText = saleLabel?.getSaleText(variant, saleFormat, locationId, companyId, priceStream);
          return displayInterface?.overrideLabelTextIfDefined(saleLabelText) ?? saleLabelText;
        });
        uniqueSharedLabel = exists(saleLabels?.uniqueInstance());
      }
      return (hasMultipleVariantsPerLineItem && !uniqueSharedLabel) ? SaleLabelFormat.SALE : saleFormat;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly label$ = this.interfaces$.pipe(
    switchMap(([displayLabelInterface, computeLabelInterface, displaySaleLabelInterface]) => {
      if (!!displayLabelInterface) return of(displayLabelInterface?.getLabelsForLabelComponent());
      if (!!displaySaleLabelInterface) return of(displaySaleLabelInterface?.getLabelsForLabelComponent());
      if (!!computeLabelInterface) {
        return combineLatest([
          this.menu$,
          this.labelToCompareWithSystemLabel$,
          this.systemLabelToCompareWithCustomLabel$,
        ]).pipe(
          map(([menu, customLabelKey, systemLabelKey]) => {
            if (menu?.menuOptions?.hideLabel) return null;
            return [customLabelKey, systemLabelKey]?.sort(SortUtils.sortLabelsByPriority)?.filterNulls();
          })
        );
      }
      return of([] as Label[]);
    }),
    map(labels => labels?.sort(SortUtils.sortLabelsByPriority)?.firstOrNull()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly isCompanyOrphan$ = combineLatest([
    this.label$,
    this.locationLabelsContainingCompanyLevelCopies$
  ]).pipe(
    map(([label, locationLabelsContainingCompanyLevelCopies]) => {
      return LabelUtils.isCompanyOrphan(label, locationLabelsContainingCompanyLevelCopies);
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly originalLabelText$ = combineLatest([
    this.displayableInterface$,
    this.label$,
  ]).pipe(
    map(([displayInterface, label]) => {
      const labelText = displayInterface?.overrideLabelTextIfDefined(label?.text) ?? label?.text ?? '';
      if (!!displayInterface) {
        const labels = displayInterface?.getLabelsForLabelComponent();
        if (labels?.length > 1) {
          return `${labelText} (+${labels.length - 1})`;
        }
      }
      return labelText;
    })
  );

  private systemSaleLabelText$ = combineLatest([
    this.variants$.pipe(map(variants => variants?.firstOrNull())),
    this.saleSystemLabel$,
    this.saleLabelFormat$,
    this.locationId$,
    this.companyId$,
    this.priceFormat$,
    this.displayableInterface$
  ]).pipe(
    map(([variant, saleLabel, saleLabelFormat, locationId, companyId, priceStream, displayableInterface]) => {
      const saleLabelText = saleLabel?.getSaleText(variant, saleLabelFormat, locationId, companyId, priceStream);
      return displayableInterface?.overrideLabelTextIfDefined(saleLabelText) ?? saleLabelText;
    }),
    distinctUntilChanged()
  );

  public readonly labelText$ = this.label$.pipe(
    switchMap((label) => {
      return iif(() => label instanceof SaleSystemLabel, this.systemSaleLabelText$, this.originalLabelText$);
    }),
    distinctUntilChanged()
  );

  public readonly labelBackgroundColor$ = this.label$.pipe(
    map(label => label?.color ?? ColorUtils.LABEL_DEFAULT_TEXT_DARK_COLOR)
  );

  public readonly labelTextColor$ = combineLatest([
    this.label$,
    this.labelBackgroundColor$
  ]).pipe(
    map(([labelKey, labelBackgroundColor]) => {
      const offWhite = ColorUtils.LABEL_DEFAULT_TEXT_LIGHT_COLOR;
      const black = ColorUtils.LABEL_DEFAULT_TEXT_DARK_COLOR;
      const labelTextColor = labelKey?.textColor ?? offWhite;
      switch (true) {
        case !!labelTextColor:
          return labelTextColor;
        case !!labelBackgroundColor:
          return ColorUtils.isDarkColor(labelBackgroundColor) ? offWhite : black;
        default:
          return offWhite;
      }
    })
  );

  /**
   * This will only fire true if showAsDisabledIfAddedBySmartFilter is set to true.
   */
  public readonly labelAddedBySmartFilter$ = combineLatest([
    this.displayableInterface$,
    this.label$
  ]).pipe(
    map(([displayableInterface, label]) => {
      const show = displayableInterface?.showAsDisabledIfAddedBySmartFilter();
      const addedBySmartFilter = displayableInterface?.getSmartFilterLabelId() === label?.id;
      return show && addedBySmartFilter;
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly labelOpacity$ = combineLatest([
    this.displayLabelInterface$,
    this.labelAddedBySmartFilter$
  ]).pipe(
    map(([displayLabelInterface, labelAddedBySmartFilter]) => {
      const disabled = displayLabelInterface?.getDisabledForLabelComponent();
      return (disabled || labelAddedBySmartFilter) ? '0.5' : '1';
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly labelTooltip$ = combineLatest([
    this.displayableInterface$,
    this.labelAddedBySmartFilter$,
    this.isCompanyOrphan$
  ]).pipe(
    map(([displayableInterface, addedBySmartFilter, isCompanyOrphan]) => {
      const label = displayableInterface?.getLabelsForLabelComponent()?.firstOrNull();
      if (isCompanyOrphan) {
        return addedBySmartFilter
          ? `${label?.text?.toUpperCase()} is a company label applied using smart labels. Smart labels cannot be `
            + `removed. This label is not imported at your location, so it will not appear on menus.`
          : `${label?.text?.toUpperCase()} is a company label, but it\'s not imported at your location, so it will `
            + `not appear on menus.`;
      } else if (addedBySmartFilter) {
        return 'Smart labels cannot be removed.';
      } else {
        return displayableInterface?.getFallbackTooltipForLabelComponent();
      }
    })
  );

  public readonly removable$ = combineLatest([
    this.displayableInterface$,
    this.computationInterface$,
    this.labelAddedBySmartFilter$
  ]).pipe(
    map(([displayableInterface, computationInterface, disableRemoveBecauseAddedBySmartFilter]) => {
      const displayableInterfaceClearable = displayableInterface?.getClearableForLabelComponent();
      const computationInterfaceClearable = computationInterface?.getClearableForLabelComponent();
      const clearable = displayableInterfaceClearable || computationInterfaceClearable || false;
      return clearable && !disableRemoveBecauseAddedBySmartFilter;
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /* ************************************ Secondary Label ************************************ */

  /**
   * locationLabelsContainingCompanyLevelCopies$ is used on purpose here
   * read comment: Clarification between location and company label objects
   */
  private sortedAssociatedOverrideLabels$ = combineLatest([
    this.sortedOverrideLabelIds$,
    this.locationLabelsContainingCompanyLevelCopies$
  ]).pipe(
    map(([sortedOverrideLabelIds, locationLabels]) => {
      return sortedOverrideLabelIds
        ?.map(labelId => locationLabels?.find(label => label?.id === labelId))
        ?.filterNulls();
    })
  );

  private sortedAssociatedLocationLabels$ = combineLatest([
    this.sortedLocationLabelIds$,
    this.locationLabelsContainingCompanyLevelCopies$
  ]).pipe(
    map(([sortedLocationLabelIds, locationLabels]) => {
      return sortedLocationLabelIds
        ?.map(labelId => locationLabels?.find(label => label?.id === labelId))
        ?.filterNulls();
    })
  );

  /**
   * locationLabelsContainingCompanyLevelCopies$ is used on purpose here
   * read comment: Clarification between location and company label objects
   */
  private sortedAssociatedCompanyLabels$ = combineLatest([
    this.sortedCompanyLabelIds$,
    this.locationLabelsContainingCompanyLevelCopies$
  ]).pipe(
    map(([sortedCompanyLabelIds, locationLabels]) => {
      return sortedCompanyLabelIds
        ?.map(labelId => locationLabels?.find(label => label?.id === labelId))
        ?.filterNulls();
    })
  );

  private allUserAppliedLabels$ = combineLatest([
    this.sortedAssociatedOverrideLabels$,
    this.sortedAssociatedLocationLabels$,
    this.sortedAssociatedCompanyLabels$
  ]);

  public secondaryLabels$ = combineLatest([
    this.label$,
    this.allUserAppliedLabels$,
    this.sortedSystemLabelPool$,
    this.companyConfig$
  ]).pipe(
    map(([
      primaryLabel,
      [overrideLabels, locationDALocationLabels, companyDALocationLabels],
      systemLabels,
      companyConfig
    ]) => {
      const visibleLabelIsOverrideLabel = overrideLabels?.includes(primaryLabel);
      const primaryIsSystemLabel = primaryLabel instanceof SystemLabel;
      const override = overrideLabels?.filter(l => l?.id !== primaryLabel?.id);
      const location = locationDALocationLabels?.filter(l => l?.id !== primaryLabel?.id);
      const company = companyDALocationLabels?.filter(l => l?.id !== primaryLabel?.id);
      const system = systemLabels?.filter(l => l?.id !== primaryLabel?.id);
      const priorityBasedLabelPool = LabelUtils.priorityBasedLabelPool(override, location, company);
      const nextInLineUserLabel = priorityBasedLabelPool?.firstOrNull();
      const nextInLineSystemLabel = system?.firstOrNull();

      if (visibleLabelIsOverrideLabel) {
        // This ONLY happens within a MENU context and if you have a label override
        // Currently, this is the only case where we will return multiple labels so the user can see which
        // user applied label and system applied label they are overriding
        return [nextInLineUserLabel, nextInLineSystemLabel]?.sort(SortUtils.sortLabelsByPriority)?.filterNulls();
      } else {
        if (primaryIsSystemLabel) {
          // Primary label is a system applied label and the secondary label can only be a user applied label
          return [nextInLineUserLabel]?.filterNulls();
        } else if (!primaryIsSystemLabel && !!nextInLineSystemLabel) {
          // Primary label is user applied and secondary label should be a system label if available
          return [nextInLineSystemLabel];
        } else {
          return [];
        }
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
