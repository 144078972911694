export enum WebSocketConnectionState {
  // The connection is established and active.
  Connected = 'WebsocketConnection_Connected',
  // The connection is established but unresponsive, indicating potential issues.
  Hanging = 'WebsocketConnection_Hanging',
  // The connection has been lost or closed.
  Disconnected = 'WebsocketConnection_Disconnected',
  // The connection has been intentionally removed or terminated.
  Removed = 'WebsocketConnection_Removed'
}
