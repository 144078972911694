<div class="info-banner" fxLayout="row">
  <img
    class="info-icon"
    fxFlexAlign="center"
    [src]="'assets/icons/light/solid/information-circle.svg'"
    alt="information-circle.svg" />
  <div class="info-text" fxFlex><ng-content></ng-content></div>
</div>
<app-toggle-switch
  class="labels-order"
  *ngIf="includeToggleSwitch"
  [label]="'Show Label Order'"
  [state]="toggleState"
  (toggled)="buttonClicked.emit()">
</app-toggle-switch>
