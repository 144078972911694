import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MenuAdditionalOptionsFormComponent } from '../menu-additional-options-form';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { ThemeUtils } from '../../../../../../../utils/theme-utils';

@Component({
  selector: 'app-menu-hide-show-options-form',
  templateUrl: './menu-hide-show-options-form.component.html',
  styleUrls: ['./menu-hide-show-options-form.component.scss']
})
export class MenuHideShowOptionsFormComponent extends MenuAdditionalOptionsFormComponent implements OnChanges {

  @Input() templateMode = false;
  @Input() hasAltLogo: boolean;
  @Input() hideSaleDisabled: boolean;
  @Input() showAlternativeLogoDisabled: boolean;

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu as Observable<Menu>;

  private _hideDescription = new BehaviorSubject<boolean>(false);
  public hideDescription$ = this._hideDescription as Observable<boolean>;
  setHideDescription = (hideDescription: boolean) => this._hideDescription.next(hideDescription);
  public showFullDescriptionDisabledTooltip$ = this.hideDescription$.pipe(
    map(hideDescription => hideDescription ? 'Description is hidden so this option is invalid.' : '')
  );

  private _showFullDescription = new BehaviorSubject<boolean>(false);
  public showFullDescription$ = this._showFullDescription as Observable<boolean>;
  setShowFullDescription = (showFullDescription: boolean) => this._showFullDescription.next(showFullDescription);

  public programmaticallyChangedShowFullDescription$ = combineLatest([
    this.hideDescription$,
    this.showFullDescription$
  ]).pipe(
    map(([hideDescription, showFullDescription]) => hideDescription ? false : showFullDescription),
    distinctUntilChanged()
  );

  private setDescriptionOptionsFromMenu = this.menu$.subscribeWhileAlive({
    owner: this,
    next: menu => {
      this._hideDescription.next(menu?.menuOptions?.hideDescription);
      this._showFullDescription.next(menu?.menuOptions?.showFullDescription);
    }
  });

  public menuThemeSupportsCannabinoids$ = this.menu$.pipe(
    map(menu => ThemeUtils.themeSupportsCannabinoids(menu?.theme))
  );

  public hideCannabinoidsDecimalDisabledTooltip$ = this.menuThemeSupportsCannabinoids$.pipe(
    map(menuThemeSupportsCannabinoids => {
      return !menuThemeSupportsCannabinoids ? 'This theme does not support cannabinoids' : '';
    })
  );

  constructor() {
    super();
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.menu) {
      this._menu.next(this.menu);
    }
  }

}
