import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Variant } from '../models/product/dto/variant';
import { SelectVariantsModalComponent } from '../views/shared/components/select-variants-modal/select-variants-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector, NgZone } from '@angular/core';

export class ModalSelectVariants {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    variants: Variant[],
    disabledVariantIds: string[],
    preselectedVariants: Variant[],
    onClose: (selectedVariants: Variant[]) => void
  ) {
    ngZone.run(() => {
      const modalRef = ngbModal.open(
        SelectVariantsModalComponent,
        ModalUtils.selectVariantsModalOptions(injector)
      );
      const compInst = modalRef.componentInstance as SelectVariantsModalComponent;
      compInst.setDisabledVariantIds(disabledVariantIds);
      compInst.setVariants(variants, preselectedVariants);
      modalRef.result
        .then((selectedVariants) => selectedVariants?.length > 0 && onClose(selectedVariants))
        .catch(() => {});
    });
  }

}
