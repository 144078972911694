import { Injectable, Injector, NgZone } from '@angular/core';
import { EditTemplateMenuViewModel } from '../edit-template-menu-view-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditProductMenuViewModel } from '../../../../menu/viewModels/edit-product-menu-view-model';

@Injectable()
export abstract class EditTemplateShelfTalkerMenuViewModel extends EditTemplateMenuViewModel {

  protected constructor(
    protected override editMenuViewModel: EditProductMenuViewModel,
    protected override templateDomainModel: TemplateDomainModel,
    protected override locationDomainModel: LocationDomainModel,
    protected override ngZone: NgZone,
    protected override ngbModal: NgbModal,
    protected override injector: Injector
  ) {
    super(editMenuViewModel, templateDomainModel, locationDomainModel, ngZone, ngbModal, injector);
  }

}
