import { Injectable } from '@angular/core';
import { CombinedLoadingOptsViewModel } from '../../../../shared/components/auto-save/combined-loading-opts-view-model';
import { EditProductMenuViewModel } from '../../../../menu/viewModels/edit-product-menu-view-model';
import { EditTemplateShelfTalkerMenuViewModel } from './edit-template-shelf-talker-menu-view-modal';

@Injectable()
export class EditTemplateShelfTalkerMenuCombinedLoadingOptsViewModel extends CombinedLoadingOptsViewModel {

  constructor(
    protected editTemplateShelfTalkerMenuViewModel: EditTemplateShelfTalkerMenuViewModel,
    protected editProductMenuViewModel: EditProductMenuViewModel
  ) {
    super([editTemplateShelfTalkerMenuViewModel, editProductMenuViewModel]);
  }

}
