import { StringUtils } from '../../../utils/string-utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import type { Variant } from '../dto/variant';
import type { Product } from '../dto/product';
import { VariantGroupProperties } from '../../protocols/variant-group-properties';
import { BackgroundSortable } from '../../protocols/background-sortable';
import { StrainClassification } from '../../enum/dto/strain-classification';
import { PriceFormat } from '../../enum/dto/price-format';
import { SystemLabel } from '../../shared/labels/system-label';
import { PrimaryCannabinoid } from '../../enum/shared/primary-cannabinoid.enum';
import { SecondaryCannabinoid } from '../../enum/dto/secondary-cannabinoid';
import { Terpene } from '../../enum/dto/terpene';

export class VariantGroup implements UniquelyIdentifiable, VariantGroupProperties, BackgroundSortable {

  constructor(
    public product: Product,
    public variants: Variant[],
    public includedOnMenu: boolean
  ) {
  }

  /**
   * Not returned from API. Properties used for Sorting
   * Not to be used unless setSortingProperties is called prior
   */
  public groupBrand: string;
  public groupClassification: StrainClassification;
  public groupManufacturer: string;
  public minPackageQuantity: number;
  public maxPackageQuantity: number;
  public minPrice: number;
  public maxPrice: number;
  public minPriceWithSale: number; // Ignore the price format and set hideSale = false
  public maxPriceWithSale: number; // Ignore the price format and set hideSale = false
  public minPricePerUOM: number;
  public maxPricePerUOM: number;
  public minOriginalPrice: number;
  public maxOriginalPrice: number;
  public minSaleOriginalPrice: number;
  public maxSaleOriginalPrice: number;
  public minTaxesInPrice: number;
  public maxTaxesInPrice: number;
  public minTaxesInRoundedPrice: number;
  public maxTaxesInRoundedPrice: number;
  public minPreTaxPrice: number;
  public maxPreTaxPrice: number;
  public minSecondaryPrice: number;
  public maxSecondaryPrice: number;
  public productType: string;
  public maxVariantStock: number;
  public groupTitle: string;
  public minUnitSize: number;
  public maxUnitSize: number;
  public variantType: string;
  public topTerpene: string;
  public minTotalTerpene: number;
  public maxTotalTerpene: number;

  private returnVariantInfo(): boolean {
    return this.variants?.length === 1;
  }

  public getMaxVariantStock(): number {
    const quantityInStock = this.variants?.map(v => v?.inventory?.quantityInStock)?.filterNulls() || [];
    return Math.max(...quantityInStock, 0);
  }

  public getGroupTitle(): string {
    return this.returnVariantInfo() ? this.variants?.firstOrNull()?.getVariantTitle() : this.product?.getProductTitle();
  }

  public getGroupBrand(): string {
    return StringUtils.getStringMode(this.variants?.filter(v => !!v?.brand)?.map(v => v.brand) || []);
  }

  public getGroupManufacturer(): string {
    return StringUtils.getStringMode(this.variants?.filter(v => !!v?.manufacturer)?.map(v => v.manufacturer) || []);
  }

  public getGroupClassification(): StrainClassification | null {
    return this.returnVariantInfo()
      ? this.variants?.firstOrNull()?.classification
      : this.product?.getStrainClassification();
  }

  public getGroupProductType(): string {
    return this.variants?.length > 0 ? this.variants?.firstOrNull()?.productType : null;
  }

  public getGroupVariantType(): string {
    return StringUtils.getStringMode(this.variants?.filter(v => !!v?.variantType)?.map(v => v.variantType) || []);
  }

  /**
   * Returns the minimum price of the variants in the group.
   * Can return Infinity if prices are not specified.
   */
  public getMinGroupPrice(
    locationId: number,
    companyId,
    priceFormat: PriceFormat,
    hideSale: boolean
  ): number {
    const price = this.variants
      ?.map(v => v.getVisiblePrice(locationId, companyId, priceFormat, hideSale))
      ?.filterNulls() || [];
    return Math.min(...price);
  }

  /**
   * Returns the minimum secondary price of the variants in the group.
   * Can return Infinity if secondary prices are not specified.
   */
  public getMinGroupSecondaryPrice(locationId: number, companyId): number {
    const secondaryPrice = this.variants
      ?.map(v => v.getSecondaryPrice(locationId, companyId))
      ?.filterNulls() || [];
    return Math.min(...secondaryPrice);
  }

  /**
   * Returns the minimum price per unit of measure of the variants in the group.
   * Can return Infinity if group prices per unit of measure are not specified.
   */
  public getMinGroupPricePerUOM(
    locationId: number,
    companyId,
    priceFormat: PriceFormat,
    hideSale: boolean
  ): number {
    const pricePerUOM = this.variants?.map(v => v.getPricePerUOM(locationId, companyId, priceFormat, hideSale)) || [];
    return Math.min(...pricePerUOM);
  }

  public getMaxGroupPrice(
    locationId: number,
    companyId,
    priceFormat: PriceFormat,
    hideSale: boolean
  ): number {
    const price = this.variants?.map(v => v.getVisiblePrice(locationId, companyId, priceFormat, hideSale)) || [];
    return Math.max(...price, 0);
  }

  public getMaxGroupSecondaryPrice(locationId: number, companyId): number {
    const secondaryPrice = this.variants?.map(v => v.getSecondaryPrice(locationId, companyId)) || [];
    return Math.max(...secondaryPrice, 0);
  }

  public getMaxGroupPricePerUOM(
    locationId: number,
    companyId,
    priceFormat: PriceFormat,
    hideSale: boolean
  ): number {
    const pricePerUOM = this.variants?.map(v => v.getPricePerUOM(locationId, companyId, priceFormat, hideSale)) || [];
    return Math.max(...pricePerUOM, 0);
  }

  /**
   * Returns the minimum price of the variants in the group without any discounts applied.
   * Can return Infinity if prices are not specified.
   */
  public getMinOriginalPrice(
    locationId: number,
    companyId,
    priceFormat: PriceFormat
  ): number {
    const originalPrice = this.variants?.map(v => v.getPriceWithoutDiscounts(locationId, companyId, priceFormat)) || [];
    return Math.min(...originalPrice);
  }

  public getMaxOriginalPrice(
    locationId: number,
    companyId,
    priceFormat: PriceFormat
  ): number {
    const originalPrice = this.variants?.map(v => v.getPriceWithoutDiscounts(locationId, companyId, priceFormat)) || [];
    return Math.max(...originalPrice, 0);
  }

  /**
   * Returns the minimum sale price of the variants in the group.
   * Can return Infinity if sale prices are not specified.
   */
  public getMinSaleOriginalPrice(
    locationId: number,
    companyId,
    priceFormat: PriceFormat,
  ): number {
    const saleOriginalPrice = this.variants
      ?.map(v => v?.getSaleOriginalPriceOrNull(locationId, companyId, priceFormat))
      ?.filterNulls() || [];
    return Math.min(...saleOriginalPrice);
  }

  public getMaxSaleOriginalPrice(
    locationId: number,
    companyId,
    priceFormat: PriceFormat,
  ): number {
    const saleOriginalPrice = this.variants
      ?.map(v => v?.getSaleOriginalPriceOrNull(locationId, companyId, priceFormat))
      ?.filterNulls() || [];
    return Math.max(...saleOriginalPrice, 0);
  }

  /**
   * Returns the minimum average price of the variants in the group with the taxes applied.
   * Can return Infinity if prices are not specified.
   */
  public getMinTaxesInPrice(
    locationId: number,
    companyId
  ): number {
    const taxesInPrice = this.variants?.map(v => v.getTaxesInPrice(locationId, companyId)) || [];
    return Math.min(...taxesInPrice);
  }

  public getMaxTaxesInPrice(
    locationId: number,
    companyId
  ): number {
    const taxesInPrice = this.variants?.map(v => v.getTaxesInPrice(locationId, companyId)) || [];
    return Math.max(...taxesInPrice, 0);
  }

  /**
   * Returns the minimum average price of the variants in the group with the taxes applied and rounded.
   * Can return Infinity if prices are not specified.
   */
  public getMinTaxesInRoundedPrice(
    locationId: number,
    companyId
  ): number {
    const taxesInPrice = this.variants?.map(v => v.getTaxesInRoundedPrice(locationId, companyId)) || [];
    return Math.min(...taxesInPrice);
  }

  public getMaxTaxesInRoundedPrice(
    locationId: number,
    companyId
  ): number {
    const taxesInPrice = this.variants?.map(v => v.getTaxesInRoundedPrice(locationId, companyId)) || [];
    return Math.max(...taxesInPrice, 0);
  }

  /**
   * Returns the minimum pre-tax price of the variants in the group.
   * Can return Infinity if prices are not specified.
   */
  public getMinPreTaxPrice(
    locationId: number,
    companyId
  ): number {
    const preTaxPrice = this.variants?.map(v => v.getPreTaxPrice(locationId, companyId)) || [];
    return Math.min(...preTaxPrice);
  }

  public getMaxPreTaxPrice(
    locationId: number,
    companyId
  ): number {
    const preTaxPrice = this.variants?.map(v => v.getPreTaxPrice(locationId, companyId)) || [];
    return Math.max(...preTaxPrice, 0);
  }

  /**
   * Returns the minimum numeric cannabinoids of the variants in the group.
   * Can return Infinity if cannabinoids is not specified.
   */
  // TODO - KFFT - does this need access to companyConfig?.cannabinoidDisplayType?
  public getMinNumericCannabinoid(cannabinoid: string): number {
    const cannabinoids = this.variants?.map(v => {
      return v.useCannabinoidRange
        ? v.getNumericMinCannabinoidOrTerpene(cannabinoid)
        : v.getNumericCannabinoidOrTerpene(cannabinoid);
    }) || [];
    return Math.min(...cannabinoids);
  }

  // TODO - KFFT - does this need access to companyConfig?.cannabinoidDisplayType?
  public getMaxNumericCannabinoid(cannabinoid: string): number {
    const cannabinoids = this.variants?.map(v => {
      return v.useCannabinoidRange
        ? v.getNumericMaxCannabinoidOrTerpene(cannabinoid)
        : v.getNumericCannabinoidOrTerpene(cannabinoid);
    }) || [];
    return Math.min(...cannabinoids);
  }

  /**
   * Returns the minimum numeric TAC of the variants in the group.
   * Returned Auto-calculated TAC if not available in DA.
   */
  // TODO - KFFT - does this need access to companyConfig?.cannabinoidDisplayType?
  public getMaxNumericTac(): number {
    const tac = this.variants?.map(v => v.useCannabinoidRange ? v.activeMaxTAC : v.activeAvgTAC)?.filterNulls() || [];
    return !tac?.length ? null : Math.min(...tac);
  }
  // TODO - KFFT - does this need access to companyConfig?.cannabinoidDisplayType?
  public getMinNumericTac(): number {
    const tac = this.variants?.map(v => v.useCannabinoidRange ? v.activeMinTAC : v.activeAvgTAC)?.filterNulls() || [];
    return !tac?.length ? null : Math.min(...tac);
  }

  // TODO - KFFT - does this need access to companyConfig?.terpeneDisplayType?
  public getMaxNumericTotalTerpene(): number {
    const totalTerpene = this.variants?.map(v => {
      return v.useTerpeneRange
        ? v.activeMaxTotalTerpene
        : v.activeAvgTotalTerpene;
    }) || [];
    return !totalTerpene?.length ? null : Math.min(...totalTerpene);
  }
  // TODO - KFFT - does this need access to companyConfig?.terpeneDisplayType?
  public getMinNumericTotalTerpene(): number {
    const tac = this.variants?.map(v => {
      return v.useTerpeneRange
        ? v.activeMinTotalTerpene
        : v.activeAvgTotalTerpene;
    }) || [];
    return !tac?.length ? null : Math.min(...tac);
  }

  /**
   * Returns the minimum numeric terpenes of the variants in the group.
   * Can return Infinity if terpenes is not specified.
   */
  // TODO - KFFT - does this need access to companyConfig?.terpeneDisplayType?
  public getMinNumericTerpene(terpeneCamelCased :string): number {
    const terpenes = this.variants?.map(v => {
      return v.useTerpeneRange
        ? v.getNumericMinCannabinoidOrTerpene(terpeneCamelCased)
        : v.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    }) || [];
    return !terpenes?.length ? null : Math.min(...terpenes);
  }
  // TODO - KFFT - does this need access to companyConfig?.terpeneDisplayType?
  public getMaxNumericTerpene(terpeneCamelCased: string): number {
    const terpenes = this.variants?.map(v => {
      return v.useTerpeneRange
        ? v.getNumericMaxCannabinoidOrTerpene(terpeneCamelCased)
        : v.getNumericCannabinoidOrTerpene(terpeneCamelCased);
    }) || [];
    return !terpenes?.length ? null : Math.min(...terpenes);
  }

  // TODO - KFFT - this probably needs to be mode instead of firstOrNull
  public getVariantGroupTopTerpene(enabledTerpenesPascalCased: string[]): string {
    return this.variants?.map(v => v?.getVariantTopTerpene(enabledTerpenesPascalCased)).firstOrNull();
  }

  public getMinPackageQuantity(): number {
    const packageQuantity = this.variants?.map(v => v?.packagedQuantity) || [];
    const min = Math.min(...packageQuantity);
    return Number.isFinite(min) ? min : 0;
  }

  public getMaxPackageQuantity(): number {
    const packageQuantity = this.variants?.map(v => v?.packagedQuantity) || [];
    return Math.max(...packageQuantity, 0);
  }

  /**
   * Returns the minimum unit size of the variants in the group.
   * Can return Infinity if unit sizes are not specified.
   */
  public getMinNumericUnitSize(): number {
    const size = this.variants?.map(v => v?.unitSize) || [];
    return Math.min(...size);
  }

  public getMaxNumericUnitSize(): number {
    const size = this.variants?.map(v => v?.unitSize) || [];
    return Math.max(...size, 0);
  }

  getUniqueIdentifier(): string {
    return `
      -${this.product?.getUniqueIdentifier()}
      -${this.variants?.map(v => v.getUniqueIdentifier())?.sort()?.join('-')}
    `;
  }

  setSortingProperties(
    locationId?: number,
    companyId?: number,
    priceFormat?: PriceFormat,
    saleLabels?: SystemLabel[],
    hideSale?: boolean,
    enabledCannabinoids?: string[],
    enabledTerpenes?: string[]
  ): void {
    this.groupBrand = this.getGroupBrand();
    this.groupClassification = this.getGroupClassification();
    this.groupManufacturer = this.getGroupManufacturer();
    this.minPackageQuantity = this.getMinPackageQuantity();
    this.maxPackageQuantity = this.getMaxPackageQuantity();
    this.minPrice = this.getMinGroupPrice(locationId, companyId, priceFormat, hideSale);
    this.maxPrice = this.getMaxGroupPrice(locationId, companyId, priceFormat, hideSale);
    this.minPriceWithSale = this.getMinGroupPrice(locationId, companyId, priceFormat, false);
    this.maxPriceWithSale = this.getMaxGroupPrice(locationId, companyId, priceFormat, false);
    this.minPricePerUOM = this.getMinGroupPricePerUOM(locationId, companyId, priceFormat, hideSale);
    this.maxPricePerUOM = this.getMaxGroupPricePerUOM(locationId, companyId, priceFormat, hideSale);
    this.minOriginalPrice = this.getMinOriginalPrice(locationId, companyId, priceFormat);
    this.maxOriginalPrice = this.getMaxOriginalPrice(locationId, companyId, priceFormat);
    this.minSaleOriginalPrice = this.getMinSaleOriginalPrice(locationId, companyId, priceFormat);
    this.maxSaleOriginalPrice = this.getMaxSaleOriginalPrice(locationId, companyId, priceFormat);
    this.minTaxesInPrice = this.getMinTaxesInPrice(locationId, companyId);
    this.maxTaxesInPrice = this.getMaxTaxesInPrice(locationId, companyId);
    this.minTaxesInRoundedPrice = this.getMinTaxesInRoundedPrice(locationId, companyId);
    this.maxTaxesInRoundedPrice = this.getMaxTaxesInRoundedPrice(locationId, companyId);
    this.minPreTaxPrice = this.getMinPreTaxPrice(locationId, companyId);
    this.maxPreTaxPrice = this.getMaxPreTaxPrice(locationId, companyId);
    this.minSecondaryPrice = this.getMinGroupSecondaryPrice(locationId, companyId);
    this.maxSecondaryPrice = this.getMaxGroupSecondaryPrice(locationId, companyId);
    this.productType = this.getGroupProductType();
    this.maxVariantStock = this.getMaxVariantStock();
    this.groupTitle = this.getGroupTitle();
    this.minUnitSize = this.getMinNumericUnitSize();
    this.maxUnitSize = this.getMaxNumericUnitSize();
    this.variantType = this.getGroupVariantType();
    // cannabinoids and terpenes
    Object.values(PrimaryCannabinoid)?.forEach(cannabinoid => {
      if (cannabinoid === PrimaryCannabinoid.TAC) {
        this[`min${PrimaryCannabinoid.TAC}`] = this.getMinNumericTac() || null;
        this[`max${PrimaryCannabinoid.TAC}`] = this.getMaxNumericTac() || null;
      } else {
        this[`min${cannabinoid}`] = this.getMinNumericCannabinoid(cannabinoid) || null;
        this[`max${cannabinoid}`] = this.getMaxNumericCannabinoid(cannabinoid) || null;
      }
    });
    Object.values(SecondaryCannabinoid)?.forEach(secondaryCannabinoid => {
      this[`min${secondaryCannabinoid}`] = this.getMinNumericCannabinoid(secondaryCannabinoid) || null;
      this[`max${secondaryCannabinoid}`] = this.getMaxNumericCannabinoid(secondaryCannabinoid) || null;
    });
    Object.values(Terpene)?.forEach(terpene => {
      const pascalCaseTerpene = StringUtils.toPascalCase(terpene);
      const camelCased = StringUtils.toCamelCase(terpene);
      this[`min${pascalCaseTerpene}`] = this.getMinNumericTerpene(camelCased) || null;
      this[`max${pascalCaseTerpene}`] = this.getMaxNumericTerpene(camelCased) || null;
    });
    const enabledTerpenesPascalCased = enabledTerpenes?.map(t => StringUtils.toPascalCase(t));
    this.topTerpene = this.getVariantGroupTopTerpene(enabledTerpenesPascalCased);
    this.minTotalTerpene = this.getMinNumericTotalTerpene();
    this.maxTotalTerpene = this.getMaxNumericTotalTerpene();
  }

}
