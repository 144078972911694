import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { NumberUtils } from '../../../utils/number-utils';
import { UnitOfMeasure } from '../../utils/dto/unit-of-measure-type';
import { VariantPricingTierProperties } from '../../protocols/variant-pricing-tier-properties';

export const PRICING_TIER_UPPER_LIMIT = 9999;

export class VariantPricingTier implements Deserializable, UniquelyIdentifiable, VariantPricingTierProperties {

  public name: string;
  public startWeight: number;   // Lower bounded weight for pricing tier
  public startQuantity: number; // Quantity of BaseVariant (StartWeight/VariantDTO.UnitSize)
  public endWeight: number;     // Upper bounded weight for pricing tier
  public endQuantity: number;   // Quantity of BaseVariant (StartWeight/VariantDTO.UnitSize)
  public price: number;         // Indicates price per unit/uom
  public isDiscountable: boolean;

  onDeserialize() {
  }

  /**
   * This functionality directly matches what is implemented on the API.
   * The selected values are validated on the API, so it is important that
   * this is only changed in conjunction with the API
   */
  getPricingTierGridColumnName(useWeight: boolean, uom: UnitOfMeasure): string {
    if (useWeight && (this.startWeight > 0 || this.endWeight > 0)) {
      // Return column name using Weight
      if (this.endWeight === PRICING_TIER_UPPER_LIMIT) {
        return `${NumberUtils.formatToSigFigDecimals(this.startWeight)}${uom}+`;
      } else {
        const startWeight = NumberUtils.formatToSigFigDecimals(this.startWeight);
        const endWeight = NumberUtils.formatToSigFigDecimals(this.endWeight);
        return `${startWeight}-${endWeight}${uom}`;
      }
    } else {
      // Default to using quantity
      if (this.endQuantity === PRICING_TIER_UPPER_LIMIT) {
        return `${NumberUtils.formatToSigFigDecimals(this.startQuantity)}+`;
      } else {
        const startQty = NumberUtils.formatToSigFigDecimals(this.startQuantity);
        const endQty = NumberUtils.formatToSigFigDecimals(this.endQuantity);
        return `${startQty}-${endQty}`;
      }
    }
  }

  /**
   * For Classic Flower Grid we are supporting 1g, 1/8, 1/4, 1/2, and 1oz flower sizes.
   * Still we need to send to backend the weight in grams to pass the validation.
   * Used Pipe to transform the grams to oz in the UI.
   */
  getFlowerGridOunceColumnName(useWeight: boolean): string | null {
    switch (useWeight) {
      case this.startWeight >= 0.85 && this.endWeight <= 1.35:
        return '1 g'; // 1 g
      case this.startWeight >= 3.45 && this.endWeight <= 3.85:
        return '3.5 g'; // 1/8 oz
      case this.startWeight >= 7 && this.endWeight <= 7.5:
        return '7 g'; // 1/4 oz
      case this.startWeight >= 14 && this.endWeight <= 14.5:
        return '14 g'; // 1/2 oz
      case this.startWeight >= 28 && this.endWeight <= 28.5:
        return '28 g'; // 1 oz
      default:
        return null;
    }
  }

  getUniqueIdentifier(): string {
    return `${this.name}
      -${this.startWeight}
      -${this.startQuantity}
      -${this.endWeight}
      -${this.endQuantity}
      -${this.price}
      -${this.isDiscountable}`;
  }

}
