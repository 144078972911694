import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbandonFormComponent } from '../views/shared/modals/abandon-form/abandon-form.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector, NgZone } from '@angular/core';

export class ModalAbandonForm {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    onClose: (abandon: boolean) => void
  ) {
    ngZone.run(() => {
      const modalRef = ngbModal.open(AbandonFormComponent, ModalUtils.defaultModalOptions(injector));
      return modalRef.result.then(onClose).catch((_) => onClose(false));
    });
  }

}
