import { Injectable, Injector, NgZone } from '@angular/core';
import { DigitalTemplatesContainerViewModel } from '../digital-templates-container/digital-templates-container-view-model';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { PublishableDisplayableItemsViewModel } from '../../../../../shared/components/displayable-content/displayable-item-list/publishable-displayable-items-view-model';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';

@Injectable()
export class DigitalMarketingTemplatesViewModel extends PublishableDisplayableItemsViewModel {

  constructor(
    protected templatesDomainModel: TemplateDomainModel,
    // digitalTemplatesContainerViewModel provided by the Logged In Scope - need to get access to which tag is selected,
    // only way to pass this data to child is via a shared model, because child component is being created
    // by tab bar component (inflated in non conventional way), which means I can't pass in inputs
    protected digitalTemplatesContainerViewModel: DigitalTemplatesContainerViewModel,
    navigationService: NavigationService,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
  ) {
    super(digitalTemplatesContainerViewModel, navigationService, ngZone, ngbModal, injector);
    this.setupFilter();
  }

  protected defaultEmptyStateTitle = 'No Digital Marketing Templates';

  protected itemsToFilter$ = this.templatesDomainModel.marketingMenuTemplates$;

  protected getEmptyStateTitleCopy(menuTypeFilter: string): string {
    return this.genericEmptyStateTitleCopyForTemplates(menuTypeFilter, 'digital marketing templates');
  }

  public openCreateMarketingTemplateModal(): void {
    this.openCreateTemplateModal(MenuType.MarketingMenu);
  }

}
