import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Section } from '../../../../../../../models/menu/dto/section';
import { Variant } from '../../../../../../../models/product/dto/variant';
import type { StackType } from '../../../../../../menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';

@Component({
  selector: 'app-stack-applied-section-list-item',
  templateUrl: './stack-applied-section-list-item.component.html',
  styleUrls: ['./stack-applied-section-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackAppliedSectionListItemComponent {

  @Input() itemCountMap: Map<string, number>; // <sectionId, nCopies>
  @Input() item: Section | Variant;
  @Input() stackType: StackType;
  @Input() last: boolean;

}
