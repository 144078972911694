import { inject, Injectable, Injector, NgZone } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayableItemPreviewViewModel, REFRESH_MENU_PREVIEW_THRESHOLD_MINUTES } from '../displayable-item-preview/displayable-item-preview-view-model';
import { DropDownMenuItem, DropDownMenuSection } from '../../../../../../models/shared/stylesheet/drop-down-menu-section';
import { Size } from '../../../../../../models/shared/size';
import { TemplateCollection } from '../../../../../../models/template/dto/template-collection';
import { debounceTime, delay, map, pairwise } from 'rxjs/operators';
import { DisplayableAction } from '../../../../../../models/menu/enum/menu-action.enum';
import { ModalDisplayLiveView } from '../../../../../../modals/modal-display-live-view';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { DateUtils } from '../../../../../../utils/date-utils';
import { BsError } from '../../../../../../models/shared/bs-error';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { MenuPreview } from '../../../../../../models/menu/shared/menu-preview';
import { TemplateCollectionDomainModel } from '../../../../../../domainModels/template-collection-domain-model';
import { ModalPromptForCollectionDelete } from '../../../../../../modals/modal-prompt-for-collection-delete';
import { PreviewOf } from '../../../../../../models/enum/shared/preview-of';
import { PreviewService } from '../../../../../../services/preview-service';
import { exists } from '../../../../../../functions/exists';

@Injectable()
export class CollectionPreviewViewModel extends DisplayableItemPreviewViewModel {

  constructor(
    private collectionDomainModel: TemplateCollectionDomainModel,
    private templateDomainModel: TemplateDomainModel,
    protected menuPreviewService: MenuPreviewService,
    menuDomainModel: MenuDomainModel,
    locationDomainModel: LocationDomainModel,
    toastService: ToastService,
    navigationService: NavigationService,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(
      menuDomainModel,
      locationDomainModel,
      toastService,
      navigationService,
      ngZone,
      ngbModal,
      injector
    );
    this.listenForSlideChange();
  }

  protected override _displayableItem: BehaviorSubject<TemplateCollection>;
  public override displayableItem$: Observable<TemplateCollection>;

  public useLandscapeAspectRatio$: Observable<boolean> = of(true);
  public usePortraitAspectRatio$: Observable<boolean> = of(false);

  dropDownMenuSections$: Observable<DropDownMenuSection[]> = this.displayableItem$.pipe(
      map(templateCollection => {
        const dropDownMenuSections = [];
        const sectionItems = [];
        sectionItems.push(new DropDownMenuItem('Edit', DisplayableAction.Edit));
        sectionItems.push(new DropDownMenuItem('Live View', DisplayableAction.LiveView));
        sectionItems.push(new DropDownMenuItem(
          'Delete Collection',
          DisplayableAction.Delete,
          new Set<string>().add('red-text')
        ));
        const section = new DropDownMenuSection(null, sectionItems);
        dropDownMenuSections.push(section);
        return dropDownMenuSections;
      })
  );

  private previewRefreshMap = new Map<string, boolean>();

  protected listenForPreviewChanges(): void {
    combineLatest([
      this.displayableItem$,
      inject(MenuPreviewService).previews$,
      this.locationId$
    ]).pipe(debounceTime(100)).subscribeWhileAlive({
      owner: this,
      next: ([templateCollection, menuPreviews, locationId]) => {
        const contentIds = templateCollection?.displayableItemPreviewContentIds(locationId);
        const keys = contentIds?.map(id => PreviewService.getPreviewKey(id, locationId, PreviewOf.MenuImage));
        const previews = keys?.map(key => menuPreviews?.get(key))?.filterNulls();
        this._itemPreviews.next(previews ?? null);
      }
    });
  }

  /**
   * Previews are lazy loaded, so we need to listen for when the slide changes,
   * and then fetch the preview if it hasn't been loaded.
   */
  protected listenForSlideChange(): void {
    this.activeSlideIndex$.pipe(
      pairwise()
    ).subscribeWhileAlive({
      owner: this,
      next: ([prevIndex, currIndex]) => {
        if (prevIndex !== currIndex) {
          this.fetchItemPreviewAtIndexIfDoesntExist(currIndex);
        }
      }
    });
  }

  /**
   * The parent class calls this.fetchItemPreview() within its constructor.
   * Which means this override will not have context to any additional properties injected into the child class
   * when it's being called.
   *
   * IE. fetchItemPreview() won't have access to templateCollectionDeletionService and templateDomainModel.
   * If you try and access those properties from within here, you'll get a null reference error.
   * I can get around this by using Angular's inject statement, so that I can get direct access to the
   * TemplateDomainModel when this method is being called in this weird context.
   *
   * Lazy loading is used for collection previews, so that is why we only fetch the first contentId.
   */
  protected override fetchItemPreviews(): void {
    combineLatest([
      this.displayableItem$.distinctUniquelyIdentifiable(),
      this.locationId$.notNull(),
      inject(TemplateDomainModel).menuTemplates$.notNull().distinctUniquelyIdentifiableArray(),
    ]).subscribeWhileAlive({
      owner: this,
      next: ([templateCollection, locId, menuTemplates]) => {
        combineLatest([
          this.previewLoadingBundle$,
          this.refreshLoadingBundle$
        ]).once(([previewLoadingBundle, refreshLoadingBundle]) => {
          const contentIds = templateCollection?.displayableItemPreviewContentIds(locId);
          if (contentIds?.length) {
            this.initializePreviewLoadingOptions(previewLoadingBundle, contentIds);
            this.initializeRefreshPreviewLoadingOptions(refreshLoadingBundle, contentIds);
            const contentId = contentIds?.firstOrNull();
            const menuTemplate = templateCollection?.templates?.find(t => t?.id === contentId);
            this.loadMenuPreview(templateCollection, menuTemplate, locId, menuTemplates);
          } else {
            const placeholder = templateCollection?.displaySize?.isLandscape()
              ? 'assets/img/collection-placeholder/collection-empty-L.jpg'
              : 'assets/img/collection-placeholder/collection-empty-P.jpg';
            this._previewPlaceholderSrc.next(placeholder);
          }
        });
      }
    });
  }

  protected fetchItemPreviewAtIndexIfDoesntExist(index: number): void {
    combineLatest([
      this.displayableItem$,
      this.locationId$,
      this.templateDomainModel.menuTemplates$,
      this.previewLoadingBundle$,
      this.refreshLoadingBundle$,
      this.itemPreviews$
    ]).once(([templateCollection, locId, menuTemplates, previewLoadingBundle, refreshLoadingBundle, previews]) => {
      const contentId: string | string[] = templateCollection?.displayableItemPreviewContentIds(locId)?.[index];
      if (exists(contentId)) {
        this.initializePreviewLoadingOptions(previewLoadingBundle, [contentId]);
        this.initializeRefreshPreviewLoadingOptions(refreshLoadingBundle, [contentId]);
        const preview = previews?.[index];
        if (!preview) {
          const menuTemplate = templateCollection?.templates?.find(t => t?.id === contentId);
          this.loadMenuPreview(templateCollection, menuTemplate, locId, menuTemplates);
        }
      }
    });
  }

  protected deleteItem(collection: TemplateCollection): void {
    const lm = 'Deleting Template Collection';
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      this.collectionDomainModel.deleteTemplateCollection(collection).pipe(delay(250)).subscribe({
        complete: () => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishSuccessMessage('Template Collection deleted successfully', 'Collection Deleted');
        },
        error: (error: BsError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishError(error);
          throwError(error);
        }
      });
    }
  }

  protected loadItemPreviews(item: MenuTemplate, locId: number) {
    // Function needs to exist to conform to interface
  }

  protected loadMenuPreview(
    templateCollection: TemplateCollection,
    menuTemplate: MenuTemplate,
    locId: number,
    existingTemplates: MenuTemplate[]
  ) {
    combineLatest([this._itemPreviews, this.previewLoadingBundle$]).once(([currPreviews, loadingMap]) => {
      const id = menuTemplate?.id;
      const key = PreviewService.getPreviewKey(id, locId, PreviewOf.MenuImage);
      const returnLastSaved = !this.menuPreviewService.isPreviewInProgress(key);
      const lm = 'Getting Preview';
      const loadingOpts = loadingMap?.get(id);
      if (!loadingOpts?.containsRequest(lm) && !this.previewRefreshMap.get(id)) {
        this.previewRefreshMap.set(id, true);
        loadingOpts?.addRequest(lm);
        const cacheDelay = returnLastSaved ? 500 : 12000;
        const getMenuPreview = this.menuPreviewService.getMenuPreview;
        getMenuPreview(menuTemplate, returnLastSaved, cacheDelay, false, false, true).subscribe({
          next: (menuTemplatePreview) => {
            if (menuTemplatePreview) {
              this.setItemPreviews(templateCollection, currPreviews, menuTemplatePreview);
              // Add delay to account for saving of new preview
              this.previewRefreshMap.set(menuTemplatePreview.id, false);
              loadingOpts?.removeRequest(lm);
              const timestamp = menuTemplatePreview.preview.timestamp;
              const refresh = !DateUtils.unixAfterMinutesAgo(timestamp, REFRESH_MENU_PREVIEW_THRESHOLD_MINUTES);
              if (refresh) this.refreshItemPreviewInBackground(menuTemplate);
            }
          },
          error: (err: BsError) => {
            this.previewRefreshMap.set(menuTemplate.id, false);
            loadingOpts?.removeRequest(lm);
            this.toastService.publishError(err);
            throwError(() => err);
          }
        });
      }
    });
  }

  protected refreshItemPreviewInBackground(menuTemplate: MenuTemplate) {
    combineLatest([
      this.displayableItem$,
      this.templateDomainModel.menuTemplates$,
      this._itemPreviews,
      this.refreshLoadingBundle$
    ]).once(([templateCollection, templates, currPreviews, refreshLoadingBundle]) => {
      const id = menuTemplate?.id;
      const lm = 'Refreshing Preview';
      const loadingOpts = refreshLoadingBundle?.get(id);
      if (!loadingOpts?.containsRequest(lm) && !this.previewRefreshMap.get(id)) {
        this.previewRefreshMap.set(id, true);
        loadingOpts?.addRequest(lm);
        const imageDelay = 12000;
        const getMenuPreview = this.menuPreviewService.getMenuPreview;
        getMenuPreview(menuTemplate, false, imageDelay, undefined, false, true).subscribe({
          next: (menuTemplatePreview) => {
            this.setItemPreviews(templateCollection, currPreviews, menuTemplatePreview);
            this.previewRefreshMap.set(id, false);
            loadingOpts?.removeRequest(lm);
          },
          error: (err: BsError) => {
            this.previewRefreshMap.set(id, false);
            loadingOpts?.removeRequest(lm);
            throwError(() => err);
          }
        });
      }
    });
  }

  private setItemPreviews(
    templateCollection: TemplateCollection,
    currPreviews: MenuPreview[],
    newPreview: MenuPreview
  ): void {
    const idsInOrder = templateCollection?.getMenuIdsInOrder();
    const updatedPreviews = currPreviews?.shallowCopy() ?? [];
    const currentIndex = updatedPreviews?.findIndex(p => p?.preview?.id === newPreview?.preview?.id);
    if (currentIndex > -1) updatedPreviews.splice(currentIndex, 1);
    updatedPreviews.push(newPreview);
    updatedPreviews?.sort((a, b) => {
      const aIndex = idsInOrder?.indexOf(a?.preview?.id);
      const bIndex = idsInOrder?.indexOf(b?.preview?.id);
      return aIndex - bIndex;
    });
    this._itemPreviews.next(updatedPreviews);
  }

  openDeleteItemModal() {
    this.displayableItem$.once(c => {
      const confirmation = (cont) => {
        if (cont) {
          this.deleteItem(c);
        }
      };
      ModalPromptForCollectionDelete.open(this.ngZone, this.ngbModal, this.injector, c, confirmation);
    });
  }

  openDuplicateItemModal() {
  }

  openEditItem(): void {
    this.displayableItem$.once(collection => {
      this.navigationService.navigateToTemplateCollection(collection);
    });
  }

  openLiveViewModal(sizeOverride?: Size) {
    combineLatest([
      this.displayableItem$,
      this.locationDomainModel.locationId$
    ]).once(([collection, locationId]) => {
      ModalDisplayLiveView.open(this.ngZone, this.ngbModal, this.injector, collection, locationId);
    });
  }

  copyItemURL() {
  }

}
