import { map } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import type { Menu } from '../models/menu/dto/menu';
import type { StackType } from '../views/menu/components/edit-menu/edit-card-stack-menu/modals/create-view-stack-print-job/create-view-stack-print-job.component';

export class SmartPrintUtils {

  static isCardJob(type: string) {
    return type?.toLowerCase()?.includes('card');
  }

  static isLabelJob(type: string) {
    return type?.toLowerCase()?.includes('label');
  }

  static isShelfTalkerJob(type: string) {
    return type?.toLowerCase()?.includes('shelf');
  }

  static showIncludeOutOfStockProductsCheckbox$(
    stackType$: Observable<StackType>,
    menus$: Observable<Menu[]>
  ) {
    return combineLatest([
      stackType$,
      menus$
    ]).pipe(
      map(([printType, menus]) => {
        const type = printType?.toLowerCase();
        const isShelfTalker = SmartPrintUtils.isShelfTalkerJob(type);
        const cardOrLabelStack = !isShelfTalker;
        // fail to on if the menus don't exist, like how it currently works for cards and labels
        // this is because I don't know how to pipe the menus in during the bulk print flow yet
        const cardOrLabelStackWithSmartFilters = cardOrLabelStack && (menus?.length > 0)
          ? menus?.some(menu => menu?.getSectionsBasedOnMenuType()?.firstOrNull()?.smartFilterIds?.length > 0)
          : false;
        return !isShelfTalker && !cardOrLabelStackWithSmartFilters;
      })
    );
  }

  static showNewProductsCheckbox$(stackType$: Observable<StackType>) {
    return of(true);
  }

  static showInventoryRestockedCheckbox$(stackType$: Observable<StackType>) {
    return of(true);
  }

  static showPriceChangeCheckbox$(stackType$: Observable<StackType>) {
    return stackType$.pipe(
      map(printType => !SmartPrintUtils.isLabelJob(printType))
    );
  }

  static showProductInfoChangeCheckbox$(stackType$: Observable<StackType>) {
    return of(true);
  }

  static showCannabinoidChangeCheckbox$(stackType$: Observable<StackType>) {
    return stackType$.pipe(
      map(printType => {
        const compareWith = printType?.toLowerCase();
        return !SmartPrintUtils.isLabelJob(compareWith)
            && !SmartPrintUtils.isShelfTalkerJob(compareWith);
      })
    );
  }

  static showCustomizationChangeCheckbox$(stackType$: Observable<StackType>) {
    return stackType$.pipe(
      map(printType => {
        const compareWith = printType?.toLowerCase();
        return !SmartPrintUtils.isLabelJob(compareWith)
            && !SmartPrintUtils.isShelfTalkerJob(compareWith);
      })
    );
  }

  static showMenuChangeCheckbox$(stackType$: Observable<StackType>) {
    return stackType$.pipe(
      map(printType => {
        const compareWith = printType?.toLowerCase();
        return !SmartPrintUtils.isLabelJob(compareWith)
            && !SmartPrintUtils.isShelfTalkerJob(compareWith);
      })
    );
  }

}
