import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuDetailsSectionComponent } from '../../shared/menu-section-details/menu-details-section.component';
import { ShelfTalkerDetailsSectionViewModel } from './shelf-talker-details-section-view-model';

@Component({
  selector: 'app-shelf-talker-details-section',
  templateUrl: './shelf-talker-details-section.component.html',
  styleUrls: ['../../../../../shared/styles/edit-page-styles.scss'],
  providers: [ShelfTalkerDetailsSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShelfTalkerDetailsSectionComponent extends MenuDetailsSectionComponent {

  constructor(override viewModel: ShelfTalkerDetailsSectionViewModel) {
    super(viewModel);
  }

}
