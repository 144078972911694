export class Checkbox {

  public label: string;
  public checked: boolean;
  public disabled: boolean;

  constructor(label: string, checked = false, disabled = false) {
    this.label = label;
    this.checked = checked;
    this.disabled = disabled;
  }

}
