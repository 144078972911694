import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, OnDestroy, SimpleChanges, ViewRef } from '@angular/core';
import { ReactiveTableHeaderBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { Product } from 'src/app/models/product/dto/product';
import { AllProductsDataTableViewModel } from '../../all-products-data-table-view-model';
import { SortUtils } from '../../../../../utils/sort-utils';
import { combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PriceFormat } from '../../../../../models/enum/dto/price-format';
import { TableBadgeUtils } from '../../../utils/table-badge-utils';
import { StringUtils } from '../../../../../utils/string-utils';
import { ClientTypeUtils } from '../../../../../utils/client-type-utils';

@Component({
  selector: 'app-all-products-table-header',
  templateUrl: './all-products-table-header.component.html',
  styleUrls: ['./all-products-table-header.component.scss'],
  providers: [
    {
      provide: ReactiveTableHeaderBluePrintComponent,
      useExisting: forwardRef(() => AllProductsTableHeaderComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllProductsTableHeaderComponent extends ReactiveTableHeaderBluePrintComponent implements OnDestroy {

  private locationId: number;
  private priceFormat: PriceFormat;

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    public viewModel: AllProductsDataTableViewModel, // global provided by root
  ) {
    super(viewRef);
    this.viewModel.locationId$.pipe(takeUntil(this.onDestroy)).subscribe(locationId => this.locationId = locationId);
    this.viewModel.priceFormat$.pipe(takeUntil(this.onDestroy)).subscribe(stream => this.priceFormat = stream);
  }

  public cannabinoidAndTerpeneAscSortFuncMap$ = combineLatest([
    this.viewModel.enabledCannabinoidNames$,
    this.viewModel.enabledTerpeneNames$,
  ]).pipe(
    map(([enabledCannabinoids, enabledTerpenes]) => {
      const sortFuncMap = new Map<string, (a: any, b: any) => number>();
      sortFuncMap.set('Total Terpene', (a: Product, b: Product) => this.cannabinoidOrTerpeneAsc(a, b, 'totalTerpene'));
      enabledCannabinoids?.forEach(cannabinoid => {
        sortFuncMap.set(cannabinoid, (a: Product, b: Product) => this.cannabinoidOrTerpeneAsc(a, b, cannabinoid));
      });
      enabledTerpenes?.forEach(terpene => {
        sortFuncMap?.set(
          terpene,
          (a: Product, b: Product) => this.cannabinoidOrTerpeneAsc(a, b, StringUtils.camelize(terpene))
        );
      });
      return sortFuncMap;
    })
  );

  public cannabinoidAndTerpeneDescSortFuncMap$ = combineLatest([
    this.viewModel.enabledCannabinoidNames$,
    this.viewModel.enabledTerpeneNames$,
  ]).pipe(
    map(([enabledCannabinoids, enabledTerpenes]) => {
      const sortFuncMap = new Map<string, (a: any, b: any) => number>();
      sortFuncMap.set('Total Terpene', (a: Product, b: Product) => this.cannabinoidOrTerpeneDesc(a, b, 'totalTerpene'));
      enabledCannabinoids?.forEach(cannabinoid => {
        sortFuncMap.set(cannabinoid, (a: Product, b: Product) => this.cannabinoidOrTerpeneDesc(a, b, cannabinoid));
      });
      enabledTerpenes?.forEach(terpene => {
        sortFuncMap?.set(
          terpene,
          (a: Product, b: Product) => this.cannabinoidOrTerpeneDesc(a, b, StringUtils.camelize(terpene))
        );
      });
      return sortFuncMap;
    })
  );

  public minCannabinoidAndTerpeneAscSortFuncMap$ = combineLatest([
    this.viewModel.enabledCannabinoidNames$,
    this.viewModel.enabledTerpeneNames$,
  ]).pipe(
    map(([enabledCannabinoids, enabledTerpenes]) => {
      const sortFuncMap = new Map<string, (a: any, b: any) => number>();
      sortFuncMap.set(
        'Total Terpene',
        (a: Product, b: Product) => this.minCannabinoidOrTerpeneAsc(a, b, 'TotalTerpene')
      );
      enabledCannabinoids?.forEach(cannabinoid => {
        sortFuncMap.set(
          cannabinoid,
          (a: Product, b: Product) => this.minCannabinoidOrTerpeneAsc(a, b, cannabinoid)
        );
      });
      enabledTerpenes?.forEach(terpene => {
        const terpenePascalCased = StringUtils.toPascalCase(terpene);
        sortFuncMap?.set(
          terpenePascalCased,
          (a: Product, b: Product) => this.minCannabinoidOrTerpeneAsc(a, b, terpenePascalCased)
        );
      });
      return sortFuncMap;
    })
  );
  public minCannabinoidAndTerpeneDescSortFuncMap$ = combineLatest([
    this.viewModel.enabledCannabinoidNames$,
    this.viewModel.enabledTerpeneNames$,
  ]).pipe(
    map(([enabledCannabinoids, enabledTerpenes]) => {
      const sortFuncMap = new Map<string, (a: any, b: any) => number>();
      sortFuncMap.set(
        'Total Terpene',
        (a: Product, b: Product) => this.minCannabinoidOrTerpeneDesc(a, b, 'TotalTerpene')
      );
      enabledCannabinoids?.forEach(cannabinoid => {
        sortFuncMap.set(
          cannabinoid,
          (a: Product, b: Product) => this.minCannabinoidOrTerpeneDesc(a, b, cannabinoid)
        );
      });
      enabledTerpenes?.forEach(terpene => {
        const terpenePascalCased = StringUtils.toPascalCase(terpene);
        sortFuncMap?.set(
          terpenePascalCased,
          (a: Product, b: Product) => this.minCannabinoidOrTerpeneDesc(a, b, terpenePascalCased)
        );
      });
      return sortFuncMap;
    })
  );

  public maxCannabinoidAndTerpeneAscSortFuncMap$ = combineLatest([
    this.viewModel.enabledCannabinoidNames$,
    this.viewModel.enabledTerpeneNames$,
  ]).pipe(
    map(([enabledCannabinoids, enabledTerpenes]) => {
      const sortFuncMap = new Map<string, (a: any, b: any) => number>();
      sortFuncMap.set(
        'Total Terpene',
        (a: Product, b: Product) => this.maxCannabinoidOrTerpeneAsc(a, b, 'TotalTerpene')
      );
      enabledCannabinoids?.forEach(cannabinoid => {
        sortFuncMap.set(
          cannabinoid,
          (a: Product, b: Product) => this.maxCannabinoidOrTerpeneAsc(a, b, cannabinoid)
        );
      });
      enabledTerpenes?.forEach(terpene => {
        const terpenePascalCased = StringUtils.toPascalCase(terpene);
        sortFuncMap?.set(
          terpenePascalCased,
          (a: Product, b: Product) => this.maxCannabinoidOrTerpeneAsc(a, b, terpenePascalCased)
        );
      });
      return sortFuncMap;
    })
  );

  public maxCannabinoidAndTerpeneDescSortFuncMap$ = combineLatest([
    this.viewModel.enabledCannabinoidNames$,
    this.viewModel.enabledTerpeneNames$,
  ]).pipe(
    map(([enabledCannabinoids, enabledTerpenes]) => {
      const sortFuncMap = new Map<string, (a: any, b: any) => number>();
      sortFuncMap.set(
        'Total Terpene',
        (a: Product, b: Product) => this.maxCannabinoidOrTerpeneDesc(a, b, 'TotalTerpene')
      );
      enabledCannabinoids?.forEach(cannabinoid => {
        sortFuncMap.set(
          cannabinoid,
          (a: Product, b: Product) => this.maxCannabinoidOrTerpeneDesc(a, b, cannabinoid)
        );
      });
      enabledTerpenes?.forEach(terpene => {
        const terpenePascalCased = StringUtils.toPascalCase(terpene);
        sortFuncMap?.set(
          terpenePascalCased,
          (a: Product, b: Product) => this.maxCannabinoidOrTerpeneDesc(a, b, terpenePascalCased)
        );
      });
      return sortFuncMap;
    })
  );

  // Name Sort
  public nameAsc = (a: Product, b: Product) => SortUtils.numericStringAsc(a?.getProductTitle(), b?.getProductTitle());
  public nameDesc = (a: Product, b: Product) => SortUtils.numericStringDesc(a?.getProductTitle(), b?.getProductTitle());

  // Price Sort
  public priceAsc = (a: Product, b: Product) => {
    const asc = (c, d) => SortUtils.variantsPriceAsc([c, d]);
    a?.variants?.sort(asc);
    a?.variantsFilteredByTable?.sort(asc);
    b?.variants?.sort(asc);
    b?.variantsFilteredByTable?.sort(asc);
    return SortUtils.numberAscNullsLast(
      a?.getLowestPrice(this.locationId, null, this.priceFormat, false),
      b?.getLowestPrice(this.locationId, null, this.priceFormat, false)
    );
  };
  public priceDesc = (a: Product, b: Product) => {
    const desc = (c, d) => SortUtils.variantsPriceDesc([c, d]);
    a?.variants?.sort(desc);
    a?.variantsFilteredByTable?.sort(desc);
    b?.variants?.sort(desc);
    b?.variantsFilteredByTable?.sort(desc);
    return SortUtils.numberDescNullsLast(
      a?.getLowestPrice(this.locationId, null, this.priceFormat, false),
      b?.getLowestPrice(this.locationId, null, this.priceFormat, false)
    );
  };

  // Secondary Price Sort
  public secondaryPriceAsc = (a: Product, b: Product) => {
    const locationId = this.locationId;
    const companyId = a?.companyId;
    const asc = (c, d) => SortUtils.variantsSecondaryPriceAsc([c, d], null);
    a?.variants?.sort(asc);
    a?.variantsFilteredByTable?.sort(asc);
    b?.variants?.sort(asc);
    b?.variantsFilteredByTable?.sort(asc);
    return SortUtils.numberAscNullsLast(
      a?.getLowestPrice(this.locationId, null, this.priceFormat, false),
      b?.getLowestPrice(this.locationId, null, this.priceFormat, false)
    );
  };
  public secondaryPriceDesc = (a: Product, b: Product) => {
    const locationId = this.locationId;
    const companyId = a?.companyId;
    const desc = (c, d) => SortUtils.variantsSecondaryPriceDesc([c, d], null);
    a?.variants?.sort(desc);
    a?.variantsFilteredByTable?.sort(desc);
    b?.variants?.sort(desc);
    b?.variantsFilteredByTable?.sort(desc);
    return SortUtils.numberDescNullsLast(
      a?.getLowestPrice(this.locationId, null, this.priceFormat, false),
      b?.getLowestPrice(this.locationId, null, this.priceFormat, false)
    );
  };

  // Brand Sort
  public brandAsc = (a: Product, b: Product) => SortUtils.numericStringAsc(a?.getBrand(), b?.getBrand());
  public brandDesc = (a: Product, b: Product) => SortUtils.numericStringDesc(a?.getBrand(), b?.getBrand());

  // Type Sort
  public typeAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getProductTypeString(), b?.getProductTypeString());
  };
  public typeDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getProductTypeString(), b?.getProductTypeString());
  };

  // Strain Sort
  public strainAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getStrainClassification(), b?.getStrainClassification());
  };
  public strainDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getStrainClassification(), b?.getStrainClassification());
  };

  // Quantity Sort
  public qtyAsc = (a: Product, b: Product) => {
    a?.variants?.sort(SortUtils.variantsStockAsc);
    a?.variantsFilteredByTable?.sort(SortUtils.variantsStockAsc);
    b?.variants?.sort(SortUtils.variantsStockAsc);
    b?.variantsFilteredByTable?.sort(SortUtils.variantsStockAsc);
    return SortUtils.numericStringAsc(a?.getQuantityInStockString(), b?.getQuantityInStockString());
  };
  public qtyDesc = (a: Product, b: Product) => {
    a?.variants?.sort(SortUtils.variantsStockDesc);
    a?.variantsFilteredByTable?.sort(SortUtils.variantsStockDesc);
    b?.variants?.sort(SortUtils.variantsStockDesc);
    b?.variantsFilteredByTable?.sort(SortUtils.variantsStockDesc);
    return SortUtils.numericStringDesc(a?.getQuantityInStockString(), b?.getQuantityInStockString());
  };

  // Top Terpene
  public topTerpeneAsc = (a: Product, b: Product) => {
    a?.variants?.sort(SortUtils.variantsTopTerpeneAsc);
    a?.variantsFilteredByTable?.sort(SortUtils.variantsTopTerpeneAsc);
    b?.variants?.sort(SortUtils.variantsTopTerpeneAsc);
    b?.variantsFilteredByTable?.sort(SortUtils.variantsTopTerpeneAsc);
    return SortUtils.numericStringAsc(a?.getTopTerpeneString(), b?.getTopTerpeneString());
  };
  public topTerpeneDesc = (a: Product, b: Product) => {
    a?.variants?.sort(SortUtils.variantsTopTerpeneDesc);
    a?.variantsFilteredByTable?.sort(SortUtils.variantsTopTerpeneDesc);
    b?.variants?.sort(SortUtils.variantsTopTerpeneDesc);
    b?.variantsFilteredByTable?.sort(SortUtils.variantsTopTerpeneDesc);
    return SortUtils.numericStringDesc(a?.getTopTerpeneString(), b?.getTopTerpeneString());
  };

  // Cannabinoid and Terpene Sort
  public cannabinoidOrTerpeneAsc = (a: Product, b: Product, cannabinoidOrTerpeneCamelCased: string) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsCannabinoidAsc(aVar, bVar, cannabinoidOrTerpeneCamelCased));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => {
      return SortUtils.variantsCannabinoidAsc(aVar, bVar, cannabinoidOrTerpeneCamelCased);
    });
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsCannabinoidAsc(aVar, bVar, cannabinoidOrTerpeneCamelCased));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => {
      return SortUtils.variantsCannabinoidAsc(aVar, bVar, cannabinoidOrTerpeneCamelCased);
    });
    const aCannabinoidOrTerpene = a?.variants?.map(v => v?.useCannabinoidRange
      ? v?.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
      : v?.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)) || [];
    const bCannabinoidOrTerpene = b?.variants?.map(v => v?.useCannabinoidRange
      ? v?.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
      : v?.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)) || [];
    const aMinCannabinoidOrTerpene = Math.min(...aCannabinoidOrTerpene);
    const bMinCannabinoidOrTerpene = Math.min(...bCannabinoidOrTerpene);
    return SortUtils.numberAscNullsLast(aMinCannabinoidOrTerpene, bMinCannabinoidOrTerpene);
  };
  public cannabinoidOrTerpeneDesc = (a: Product, b: Product, cannabinoidOrTerpeneCamelCased: string) => {
    a?.variants?.sort((aVar, bVar) => SortUtils.variantsCannabinoidDesc(aVar, bVar, cannabinoidOrTerpeneCamelCased));
    a?.variantsFilteredByTable?.sort((aVar, bVar) => {
      return SortUtils.variantsCannabinoidDesc(aVar, bVar, cannabinoidOrTerpeneCamelCased);
    });
    b?.variants?.sort((aVar, bVar) => SortUtils.variantsCannabinoidDesc(aVar, bVar, cannabinoidOrTerpeneCamelCased));
    b?.variantsFilteredByTable?.sort((aVar, bVar) => {
      return SortUtils.variantsCannabinoidDesc(aVar, bVar, cannabinoidOrTerpeneCamelCased);
    });
    const aCannabinoidOrTerpene = a?.variants?.map(v => v?.useCannabinoidRange
      ? v?.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
      : v?.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)) || [];
    const bCannabinoidOrTerpene = b?.variants?.map(v => v?.useCannabinoidRange
      ? v?.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
      : v?.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)) || [];
    const aMaxCannabinoidOrTerpene = Math.max(...aCannabinoidOrTerpene, 0);
    const bMaxCannabinoidOrTerpene = Math.max(...bCannabinoidOrTerpene, 0);
    return SortUtils.numberDescNullsLast(aMaxCannabinoidOrTerpene, bMaxCannabinoidOrTerpene);
  };

  // Min Cannabinoid Or Terpene Sort
  public minCannabinoidOrTerpeneAsc = (a: Product, b: Product, cannabinoidOrTerpenePascalCased: string) => {
    a?.variants?.sort((aVar, bVar) => {
      return SortUtils.variantsMinCannabinoidOrTerpeneAsc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    a?.variantsFilteredByTable?.sort((aVar, bVar) => {
      return SortUtils.variantsMinCannabinoidOrTerpeneAsc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    b?.variants?.sort((aVar, bVar) => {
      return SortUtils.variantsMinCannabinoidOrTerpeneAsc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    b?.variantsFilteredByTable?.sort((aVar, bVar) => {
      return SortUtils.variantsMinCannabinoidOrTerpeneAsc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    const aCannabinoidOrTerpene = a?.variants?.map(v => {
      return v?.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpenePascalCased);
    }) || [];
    const bCannabinoidOrTerpene = b?.variants?.map(v => {
      return v?.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpenePascalCased);
    }) || [];
    const aMinCannabinoidOrTerpene = Math.min(...aCannabinoidOrTerpene);
    const bMinCannabinoidOrTerpene = Math.min(...bCannabinoidOrTerpene);
    return SortUtils.numberAscNullsLast(aMinCannabinoidOrTerpene, bMinCannabinoidOrTerpene);
  };
  public minCannabinoidOrTerpeneDesc = (a: Product, b: Product, cannabinoidOrTerpenePascalCased: string) => {
    a?.variants?.sort((aVar, bVar) => {
      return SortUtils.variantsMinCannabinoidOrTerpeneDesc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    a?.variantsFilteredByTable?.sort((aVar, bVar) => {
      return SortUtils.variantsMinCannabinoidOrTerpeneDesc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    b?.variants?.sort((aVar, bVar) => {
      return SortUtils.variantsMinCannabinoidOrTerpeneDesc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    b?.variantsFilteredByTable?.sort((aVar, bVar) => {
      return SortUtils.variantsMinCannabinoidOrTerpeneDesc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    const aCannabinoidOrTerpene = a?.variants?.map(v => {
      return v?.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpenePascalCased);
    }) || [];
    const bCannabinoidOrTerpene = b?.variants?.map(v => {
      return v?.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpenePascalCased);
    }) || [];
    const aMinCannabinoidOrTerpene = Math.min(...aCannabinoidOrTerpene);
    const bMinCannabinoidOrTerpene = Math.min(...bCannabinoidOrTerpene);
    return SortUtils.numberDescNullsLast(aMinCannabinoidOrTerpene, bMinCannabinoidOrTerpene);
  };

  // Max Cannabinoid and Terpene Sort
  public maxCannabinoidOrTerpeneAsc = (a: Product, b: Product, cannabinoidOrTerpenePascalCased: string) => {
    a?.variants?.sort((aVar, bVar) => {
      return SortUtils.variantsMaxCannabinoidOrTerpeneAsc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    a?.variantsFilteredByTable?.sort((aVar, bVar) => {
      return SortUtils.variantsMaxCannabinoidOrTerpeneAsc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    b?.variants?.sort((aVar, bVar) => {
      return SortUtils.variantsMaxCannabinoidOrTerpeneAsc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    b?.variantsFilteredByTable?.sort((aVar, bVar) => {
      return SortUtils.variantsMaxCannabinoidOrTerpeneAsc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    const aCannabinoidOrTerpene = a?.variants?.map(v => {
      return v?.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpenePascalCased);
    }) || [];
    const bCannabinoidOrTerpene = b?.variants?.map(v => {
      return v?.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpenePascalCased);
    }) || [];
    const aMaxCannabinoidOrTerpene = Math.max(...aCannabinoidOrTerpene, 0);
    const bMaxCannabinoidOrTerpene = Math.max(...bCannabinoidOrTerpene, 0);
    return SortUtils.numberAscNullsLast(aMaxCannabinoidOrTerpene, bMaxCannabinoidOrTerpene);
  };
  public maxCannabinoidOrTerpeneDesc = (a: Product, b: Product, cannabinoidOrTerpenePascalCased: string) => {
    a?.variants?.sort((aVar, bVar) => {
      return SortUtils.variantsMaxCannabinoidOrTerpeneDesc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    a?.variantsFilteredByTable?.sort((aVar, bVar) => {
      return SortUtils.variantsMaxCannabinoidOrTerpeneDesc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    b?.variants?.sort((aVar, bVar) => {
      return SortUtils.variantsMaxCannabinoidOrTerpeneDesc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    b?.variantsFilteredByTable?.sort((aVar, bVar) => {
      return SortUtils.variantsMaxCannabinoidOrTerpeneDesc(aVar, bVar, cannabinoidOrTerpenePascalCased);
    });
    const aCannabinoidOrTerpene = a?.variants?.map(v => {
      return v?.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpenePascalCased);
    }) || [];
    const bCannabinoidOrTerpene = b?.variants?.map(v => {
      return v?.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpenePascalCased);
    }) || [];
    const aMaxCannabinoidOrTerpene = Math.max(...aCannabinoidOrTerpene, 0);
    const bMaxCannabinoidOrTerpene = Math.max(...bCannabinoidOrTerpene, 0);
    return SortUtils.numberDescNullsLast(aMaxCannabinoidOrTerpene, bMaxCannabinoidOrTerpene);
  };

  // Label Sort
  public labelAsc = (a: Product, b: Product) => SortUtils.numericStringAsc(
    a?.computedLabelTextForProductSearch,
    b?.computedLabelTextForProductSearch
  );
  public labelDesc = (a: Product, b: Product) => SortUtils.numericStringDesc(
    a?.computedLabelTextForProductSearch,
    b?.computedLabelTextForProductSearch
  );

  // Badge Sort
  public badgeAsc = (a: Product, b: Product) => SortUtils.sortBadgesByNameAscending(
    TableBadgeUtils.getAllBadgesForVariants(a?.variantsFilteredByTable)?.firstOrNull(),
    TableBadgeUtils.getAllBadgesForVariants(b?.variantsFilteredByTable)?.firstOrNull(),
  );
  public badgeDesc = (a: Product, b: Product) => SortUtils.sortBadgesByNameDescending(
    TableBadgeUtils.getAllBadgesForVariants(a?.variantsFilteredByTable)?.firstOrNull(),
    TableBadgeUtils.getAllBadgesForVariants(b?.variantsFilteredByTable)?.firstOrNull(),
  );

  // Override Group Sort
  public overrideGroupAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.overrideGroupName, b?.overrideGroupName);
  };
  public overrideGroupDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.overrideGroupName, b?.overrideGroupName);
  };

  initializeFromBluePrint(bluePrint: ReactiveTableHeaderBluePrintComponent): void {
  }

  changesFromBluePrint(changes: SimpleChanges): void {
  }

  sendOutputsToBluePrint(bluePrint: ReactiveTableHeaderBluePrintComponent): void {
  }

  protected readonly ClientTypeUtils = ClientTypeUtils;

}
