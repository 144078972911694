import { Component, Input } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateTemplateContainer } from './create-template-container';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';

@Component({
  selector: 'app-create-template-modal',
  templateUrl: './create-template-modal.component.html',
  styleUrls: ['./create-template-modal.component.scss'],
  providers: [CreateTemplateContainer]
})
export class CreateTemplateModalComponent extends BaseModalComponent {

  constructor(
    protected activeModal: NgbActiveModal,
    public container: CreateTemplateContainer
  ) {
    super(activeModal);
  }

  @Input() stickyCollectionKey: string = 'create-template-modal';

  private listenToClose = this.container.dismissModalSubject.subscribeWhileAlive({
    owner: this,
    next: template => this.cancel(template)
  });

  override cancel(menuTemplate?: MenuTemplate) {
    this.activeModal.close(menuTemplate);
  }

}
