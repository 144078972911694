import { TypeDefinition } from './type-definition';
import { SectionLayoutType } from './section-layout-type';
import { SectionColumnConfigCollectiveTerpeneKey, SectionColumnConfigIndividualTerpeneKey, SectionColumnConfigKey, SectionColumnConfigPrimaryCannabinoidKey, SectionColumnConfigProductInfoKey, SectionColumnConfigSecondaryCannabinoidKey } from '../../enum/dto/section-column-config-key';

export class SectionColumnConfigKeyType extends TypeDefinition {

  override value: SectionColumnConfigKey;

  public static isKey(key: string|SectionColumnConfigKey): boolean {
    const keys: SectionColumnConfigKey[] = [
      ...Object.values(SectionColumnConfigProductInfoKey),
      ...Object.values(SectionColumnConfigPrimaryCannabinoidKey),
      ...Object.values(SectionColumnConfigSecondaryCannabinoidKey),
      ...Object.values(SectionColumnConfigCollectiveTerpeneKey),
      ...Object.values(SectionColumnConfigIndividualTerpeneKey)
    ];
    return keys.includes(key as SectionColumnConfigKey);
  }

  static getAllKeys(): SectionColumnConfigKey[] {
    return [
      ...Object.values(SectionColumnConfigProductInfoKey),
      ...Object.values(SectionColumnConfigPrimaryCannabinoidKey),
      ...Object.values(SectionColumnConfigSecondaryCannabinoidKey),
      ...Object.values(SectionColumnConfigCollectiveTerpeneKey),
      ...Object.values(SectionColumnConfigIndividualTerpeneKey)
    ];
  }

  static getSortOrder(): SectionColumnConfigKey[] {
    return [
      SectionColumnConfigProductInfoKey.Asset,
      SectionColumnConfigProductInfoKey.Badges,
      SectionColumnConfigProductInfoKey.Brand,
      SectionColumnConfigProductInfoKey.StrainType,
      SectionColumnConfigProductInfoKey.Quantity,
      SectionColumnConfigProductInfoKey.Size,
      SectionColumnConfigProductInfoKey.QuantityAndSize,
      SectionColumnConfigProductInfoKey.Stock,
      ...Object.values(SectionColumnConfigPrimaryCannabinoidKey),
      ...Object.values(SectionColumnConfigSecondaryCannabinoidKey),
      ...Object.values(SectionColumnConfigCollectiveTerpeneKey),
      ...Object.values(SectionColumnConfigIndividualTerpeneKey),
      SectionColumnConfigProductInfoKey.Price,
      SectionColumnConfigProductInfoKey.SecondaryPrice,
    ];
  }

  public isStockKey(): boolean {
    return this.value === SectionColumnConfigProductInfoKey.Stock;
  }

  public isCannabinoidKey(): boolean {
    return this.isPrimaryCannabinoidKey() || this.isSecondaryCannabinoidKey();
  }

  public isPrimaryCannabinoidKey(): boolean {
    return Object.values(SectionColumnConfigPrimaryCannabinoidKey).includes(this.value as any);
  }

  public isSecondaryCannabinoidKey(): boolean {
    return Object.values(SectionColumnConfigSecondaryCannabinoidKey).includes(this.value as any);
  }

  public isTerpeneKey(): boolean {
    return this.isCollectiveTerpeneKey() || this.isIndividualTerpeneKey();
  }

  public isCollectiveTerpeneKey(): boolean {
    return Object.values(SectionColumnConfigCollectiveTerpeneKey).includes(
      this.value as SectionColumnConfigCollectiveTerpeneKey
    );
  }

  public isIndividualTerpeneKey(): boolean {
    return Object.values(SectionColumnConfigIndividualTerpeneKey).includes(this.value);
  }

  public static disabledForGridLayout(key: string, layoutType: SectionLayoutType): boolean {
    const colsDisabledForGridLayout = [
      SectionColumnConfigProductInfoKey.Quantity,
      SectionColumnConfigProductInfoKey.QuantityAndSize,
      SectionColumnConfigProductInfoKey.Size,
      SectionColumnConfigProductInfoKey.SecondaryPrice,
    ];
    const disableForGridLayout = colsDisabledForGridLayout.includes(key as SectionColumnConfigProductInfoKey);
    return layoutType?.isAnyGrid() && disableForGridLayout;
  }

}
