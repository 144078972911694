<div class="preview-section">
  <div *rxIf="terpene" class="preview-terpenes-info-container">
    <div
      *rxIf="viewModel.allTerpenePreviewInfo$ | push | getTerpenePreviewInfoFor : terpene as terpenePreviewInfo"
      class="preview-terpene">
      <div class="preview-terpene-headers">{{ terpenePreviewInfo?.title }}</div>
      <div class="preview-terpene-info">{{ terpenePreviewInfo?.value }}</div>
      <div class="terpene-source">{{ terpenePreviewInfo?.source }}</div>
    </div>
  </div>
</div>
