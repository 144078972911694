import { exists } from '../functions/exists';

// Don't use this unless you know exactly how it works. If used wrong, can cause weird problems, since the cache lives
// with the life cycle of the object. I need to update this to live with the life cycle of edits, meaning if any
// data changes on the object, then I need to clear the cached method data.

/**
 * A decorator that caches the result of a function based on its input arguments.
 * If the function is called again with the same arguments, it returns the cached result instead of re-running
 * the function.
 *
 * This helps improve performance for expensive function calls with repeated inputs.
 *
 * @param target - The prototype of the class where the method is defined.
 * @param propertyKey - The name of the method being decorated.
 * @param descriptor - The descriptor of the method, containing metadata about the method.
 *
 * @returns The updated method descriptor with caching behavior.
 */
export function memoize(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;
  // Replace the original method with a new function that adds caching behavior
  descriptor.value = function(...args: any[]) {
    if (!this._memoize) this._memoize = new Map();
    const cacheKey = `${propertyKey}_${args?.map((arg) => argKey(arg))?.join('_')}`;
    if (this._memoize.has(cacheKey)) return this._memoize.get(cacheKey);
    const result = originalMethod.apply(this, args);
    this._memoize.set(cacheKey, result);
    return result;
  };
  // Return the updated method descriptor with the memoization behavior
  return descriptor;
}

function argKey(arg: any): string {
  switch (true) {
    case !arg:
      return '-';
    case exists(arg?.id):
      return arg.id;
    case Array.isArray(arg):
      return arg?.map((a) => argKey(a))?.join('_');
    default:
      return JSON.stringify(arg);
  }
}
