import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-terpene-preview-bottom',
  templateUrl: './terpene-preview-bottom.component.html',
  styleUrls: ['./terpene-preview-bottom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerpenePreviewBottomComponent {

}
