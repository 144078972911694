import { Injectable, Injector, NgZone } from '@angular/core';
import { EditCardStackPrintSectionViewModel } from '../../../../edit-card-stack-menu/edit-card-stack-print-section/edit-card-stack-print-section-view-model';
import { BulkPrintJobDomainModel } from '../../../../../../../../domainModels/bulk-print-job-domain-model';
import { MenuDomainModel } from '../../../../../../../../domainModels/menu-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateDomainModel } from '../../../../../../../../domainModels/template-domain-model';
import { EditShelfTalkerBrandsMenuViewModel } from '../../edit-shelf-talker-brands-menu-view-model';

@Injectable()
export class EditShelfTalkerBrandsMenuPrintSectionViewModel extends EditCardStackPrintSectionViewModel {

  constructor(
    protected editShelfTalkerBrandsMenuViewModel: EditShelfTalkerBrandsMenuViewModel, // provided by parent component
    bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    injector: Injector,
    menuDomainModel: MenuDomainModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    templateDomainModel: TemplateDomainModel
  ) {
    super(
      editShelfTalkerBrandsMenuViewModel,
      bulkPrintJobDomainModel,
      injector,
      menuDomainModel,
      ngZone,
      ngbModal,
      templateDomainModel
    );
  }

}
