import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Variant } from '../../../../../../../../models/product/dto/variant';
import { debounceTime, map } from 'rxjs/operators';
import { LocationDomainModel } from '../../../../../../../../domainModels/location-domain-model';
import { CompanyDomainModel } from '../../../../../../../../domainModels/company-domain-model';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../../../../domainModels/cannabinoids-and-terpenes-domain-model';
import { exists } from '../../../../../../../../functions/exists';
import { SortUtils } from '../../../../../../../../utils/sort-utils';
import { Move } from '../../../../../../../../worker/worker-sorter';
import { StringUtils } from '../../../../../../../../utils/string-utils';
import { PrimaryCannabinoid } from '../../../../../../../../models/enum/shared/primary-cannabinoid.enum';

interface VariantPreviewColumn {
  key: string;
  headerTitle: string;
  columnValues: Map<string, string>;
  columnTooltips?: Map<string, string>;
  ascFunc: (a: Variant, b: Variant) => number;
  descFunc: (a: Variant, b: Variant) => number;
}

@Injectable()
export class SmartFilterVariantsTableViewModel extends BaseViewModel {

  constructor(
    public locationDomainModel: LocationDomainModel,
    public companyDomainModel: CompanyDomainModel,
    public cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel
  ) {
    super();
  }

  private _searchString = new BehaviorSubject<string>('');
  public searchString$ = this._searchString as Observable<string>;
  connectToSearchString = (searchString: string) => this._searchString.next(searchString);

  private _variants = new BehaviorSubject<Variant[]>(null);
  public variants$ = this._variants as Observable<Variant[]>;
  connectToVariants = (variants: Variant[]) => this._variants.next(variants);

  private _ignoredVariantIds = new BehaviorSubject<string[]>([]);
  public ignoredVariantIds$ = this._ignoredVariantIds as Observable<string[]>;
  connectToIgnoredVariants = (ignoredVariantIds: string[]) => this._ignoredVariantIds.next(ignoredVariantIds);

  private _isViewOnly = new BehaviorSubject<boolean>(false);
  public isViewOnly$ = this._isViewOnly as Observable<boolean>;
  connectToIsViewOnly = (isViewOnly: boolean) => {
    this._isViewOnly.next(isViewOnly);
  };

  private _viewOnlyTooltip = new BehaviorSubject<string>(null);
  public viewOnlyTooltip$ = this._viewOnlyTooltip as Observable<string>;
  connectToViewOnlyTooltip = (viewOnlyTooltip: string) => this._viewOnlyTooltip.next(viewOnlyTooltip);

  private _variantVisibilityToggled = new BehaviorSubject<boolean>(false);
  public variantVisibilityToggled$ = this._variantVisibilityToggled as Observable<boolean>;

  private _searchedVariants = new BehaviorSubject<Variant[]>(null);
  public searchedVariants$ = this._searchedVariants.pipe(debounceTime(1));
  connectToSearchedVariants = (searchedVariants: Variant[]) => this._searchedVariants.next(searchedVariants);

  public dispersedKey$ = of('smart-filter-variants');

  public usesRange$ = this.companyDomainModel.rangeCannabinoidsAtCompanyLevel$;

  public locationConfig$ = this.locationDomainModel.locationConfig$;
  public locationId$ = this.locationDomainModel.locationId$;
  public locationIgnoredVariantIds$ = this.locationConfig$.pipe(
    map(locationConfig => locationConfig?.smartFilterIgnoredVariants)
  );
  public companyId$ = this.companyDomainModel.companyId$;
  public companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public enabledCannabinoids$ = this.cannabinoidsAndTerpenesDomainModel.enabledCannabinoidNames$;
  public enabledSecondaryCannabinoidNames$ = this.cannabinoidsAndTerpenesDomainModel.enabledSecondaryCannabinoidNames$;
  public enabledTerpenes$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpeneNames$;
  public priceFormat$ = this.locationDomainModel.priceFormat$;

  public columns$ = combineLatest([
    this.usesRange$,
    this.enabledCannabinoids$,
    this.enabledTerpenes$,
    this.variants$,
  ]).pipe(
    map(([usesRange, enabledCannabinoids, enabledTerpenes, variants]) => {
      const columns: VariantPreviewColumn[] = [];
      enabledCannabinoids?.forEach(cannabinoid => {
        const isTAC = cannabinoid === PrimaryCannabinoid.TAC;
        if (usesRange) {
          columns.push(
            isTAC
              ? this.getMinTACColumn(cannabinoid, variants)
              : this.getMinCannabinoidColumn(cannabinoid, variants)
          );
          columns.push(
            isTAC
              ? this.getMaxTACColumn(cannabinoid, variants)
              : this.getMaxCannabinoidColumn(cannabinoid, variants)
          );
        } else {
          columns.push(
            isTAC
              ? this.getTACColumn(cannabinoid, variants)
              : this.getCannabinoidColumn(cannabinoid, variants)
          );
        }
      });
      columns.push(this.getTotalTerpeneColumn(variants));
      enabledTerpenes?.forEach(terpene => {
        if (usesRange) {
          columns.push(this.getMinTerpeneColumn(terpene, variants));
          columns.push(this.getMaxTerpeneColumn(terpene, variants));
        } else {
          columns.push(this.getTerpeneColumn(terpene, variants));
        }
      });
      return columns;
    })
  );

  public titleAsc = SortUtils.variantsTitleAsc;
  public titleDesc = SortUtils.variantsTitleDesc;

  public priceAsc$ = combineLatest([this.locationId$, this.companyId$, this.priceFormat$]).pipe(
    map(([locationId, companyId, priceFormat]) => {
      return (a: Variant, b: Variant) => SortUtils.variantsPriceAscNullsLast([a, b]);
    })
  );

  public priceDesc$ = combineLatest([this.locationId$, this.companyId$, this.priceFormat$]).pipe(
    map(([locationId, companyId, priceFormat]) => {
      return (a: Variant, b: Variant) => SortUtils.variantsPriceDescNullsLast([a, b]);
    })
  );

  public visibleAsc$ = this.ignoredVariantIds$.pipe(
    map(ignoredVariantIds => {
      return (a: Variant, b: Variant) => {
        const aIgnored = ignoredVariantIds?.includes(a?.id);
        const bIgnored = ignoredVariantIds?.includes(b?.id);
        if (aIgnored && !bIgnored) return Move.ALeft;
        if (!aIgnored && bIgnored) return Move.BLeft;
        return Move.Nothing;
      };
    })
  );

  public visibleDesc$ = this.ignoredVariantIds$.pipe(
    map(ignoredVariantIds => {
      return (a: Variant, b: Variant) => {
        const aIgnored = ignoredVariantIds?.includes(a?.id);
        const bIgnored = ignoredVariantIds?.includes(b?.id);
        if (aIgnored && !bIgnored) return Move.BLeft;
        if (!aIgnored && bIgnored) return Move.ALeft;
        return Move.Nothing;
      };
    })
  );

  addVariantIdToSmartFilterIgnoredList(id: string) {
    this.ignoredVariantIds$.once(ignoredVariantIds => {
      const ignoredVariantIdsCopy = ignoredVariantIds?.shallowCopy();
      ignoredVariantIdsCopy?.push(id);
      this.connectToIgnoredVariants(ignoredVariantIdsCopy?.unique());
      this._variantVisibilityToggled.next(true);
    });
  }

  removeVariantIdFromSmartFilterIgnoredList(id: string) {
    this.ignoredVariantIds$.once(ignoredVariantIds => {
      const ignoredVariantIdsCopy = ignoredVariantIds?.shallowCopy()?.unique();
      const variantIdIndex = ignoredVariantIdsCopy?.findIndex(variantId => variantId === id);
      if (variantIdIndex >= 0) {
        ignoredVariantIdsCopy?.splice(variantIdIndex, 1);
        this.connectToIgnoredVariants(ignoredVariantIdsCopy);
        this._variantVisibilityToggled.next(true);
      }
    });
  }

  trackColumnByKey(index: number, column: VariantPreviewColumn) {
    return column?.key;
  }

  private makeCannabinoidTooltip(v: Variant, cannabinoid: string): string {
    const val = v?.displayAttributes?.[cannabinoid];
    if (exists(val)) {
      return exists(v?.[cannabinoid]) && v?.hasCannabisUnitOfMeasure()
        ? (v?.[cannabinoid] + v?.cannabisUnitOfMeasure)
        : '-';
    }
    return null;
  }

  private makeTerpeneTooltip(v: Variant, terpene: string): string {
    const terpKey = StringUtils.lowercaseFirstCharacter(StringUtils.removeWhiteSpace(terpene));
    const val = v?.displayAttributes?.[terpKey];
    if (exists(val)) {
      return exists(v?.[terpKey]) && v?.hasTerpeneUnitOfMeasure()
        ? (v?.[terpKey] + v?.terpeneUnitOfMeasure)
        : '-';
    }
    return null;
  }

  private makeMinCannabinoidTooltip(v: Variant, cannabinoid: string): string {
    const accessor = 'min' + StringUtils.capitalize(cannabinoid);
    const val = v?.displayAttributes?.[accessor];
    if (exists(val)) {
      return exists(v?.[accessor]) && v?.hasCannabisUnitOfMeasure()
        ? (v?.[accessor] + v?.cannabisUnitOfMeasure)
        : '-';
    }
    return null;
  }

  private  makeMinTerpeneTooltip(v: Variant, terpene: string): string {
    const accessor = 'min' + StringUtils.capitalize(StringUtils.removeWhiteSpace(terpene));
    const val = v?.displayAttributes?.[accessor];
    if (exists(val)) {
      return exists(v?.[accessor]) && v?.hasTerpeneUnitOfMeasure()
        ? (v?.[accessor] + v?.terpeneUnitOfMeasure)
        : '-';
    }
    return null;
  }

  private makeMaxCannabinoidTooltip(v: Variant, cannabinoid: string): string {
    const accessor = 'max' + StringUtils.capitalize(cannabinoid);
    const val = v?.displayAttributes?.[accessor];
    if (exists(val)) {
      return exists(v?.[accessor]) && v?.hasCannabisUnitOfMeasure()
        ? (v?.[accessor] + v?.cannabisUnitOfMeasure)
        : '-';
    }
    return null;
  }

  private makeMaxTerpeneTooltip(v: Variant, terpene: string): string {
    const accessor = 'max' + StringUtils.capitalize(StringUtils.removeWhiteSpace(terpene));
    const val = v?.displayAttributes?.[accessor];
    if (exists(val)) {
      return exists(v?.[accessor]) && v?.hasTerpeneUnitOfMeasure()
        ? (v?.[accessor] + v?.terpeneUnitOfMeasure)
        : '-';
    }
    return null;
  }

  private getMinTACColumn(canna: string, variants: Variant[]): VariantPreviewColumn {
    const values = variants?.map(v => [v?.id, v?.activeMinTAC?.toString()] as [string, string]);
    return {
      key: 'min' + canna,
      headerTitle: 'Min ' + canna,
      columnValues: new Map<string, string>(values),
      ascFunc: (a: Variant, b: Variant) => SortUtils.variantsMinCannabinoidOrTerpeneAscNullsLast(a, b, canna),
      descFunc: (a: Variant, b: Variant) => SortUtils.variantsMinCannabinoidOrTerpeneDescNullsLast(a, b, canna)
    };
  }

  private getMaxTACColumn(canna: string, variants: Variant[]): VariantPreviewColumn {
    const values = variants?.map(v => [v?.id, v?.activeMaxTAC?.toString()] as [string, string]);
    return {
      key: 'max' + canna,
      headerTitle: 'Max ' + canna,
      columnValues: new Map<string, string>(values),
      ascFunc: (a: Variant, b: Variant) => SortUtils.variantsMaxCannabinoidOrTerpeneAscNullsLast(a, b, canna),
      descFunc: (a: Variant, b: Variant) => SortUtils.variantsMaxCannabinoidOrTerpeneDescNullsLast(a, b, canna)
    };
  }

  private getTACColumn(canna: string, variants: Variant[]): VariantPreviewColumn {
    const values = variants?.map(v => [v?.id, v?.activeAvgTAC?.toString()] as [string, string]);
    return {
      key: canna,
      headerTitle: canna,
      columnValues: new Map<string, string>(values),
      ascFunc: (a: Variant, b: Variant) => SortUtils.variantsCannabinoidAsc(a, b, canna),
      descFunc: (a: Variant, b: Variant) => SortUtils.variantsCannabinoidDesc(a, b, canna)
    };
  }

  private getCannabinoidColumn(canna: string, variants: Variant[]): VariantPreviewColumn {
    const values = variants?.map(v => [v?.id, v?.getCannabinoidWithUnits(canna)] as [string, string]);
    const tooltips = variants?.map(v => [v?.id, this.makeCannabinoidTooltip(v, canna)] as [string, string]);
    return {
      key: canna,
      headerTitle: canna,
      columnValues: new Map<string, string>(values),
      columnTooltips: new Map<string, string>(tooltips),
      ascFunc: (a: Variant, b: Variant) => SortUtils.variantsCannabinoidAsc(a, b, canna),
      descFunc: (a: Variant, b: Variant) => SortUtils.variantsCannabinoidDesc(a, b, canna)
    };
  }

  private getMinCannabinoidColumn(canna: string, variants: Variant[]): VariantPreviewColumn {
    const values = variants?.map(v => [v?.id, v?.getMinCannabinoidWithUnits(canna)] as [string, string]);
    const tooltips = variants?.map(v => [v?.id, this.makeMinCannabinoidTooltip(v, canna)] as [string, string]);
    return {
      key: 'min' + canna,
      headerTitle: 'Min ' + canna,
      columnValues: new Map<string, string>(values),
      columnTooltips: new Map<string, string>(tooltips),
      ascFunc: (a: Variant, b: Variant) => SortUtils.variantsMinCannabinoidOrTerpeneAscNullsLast(a, b, canna),
      descFunc: (a: Variant, b: Variant) => SortUtils.variantsMinCannabinoidOrTerpeneDescNullsLast(a, b, canna)
    };
  }

  private getMaxCannabinoidColumn(canna: string, variants: Variant[]): VariantPreviewColumn {
    const values = variants?.map(v => [v?.id, v?.getMaxCannabinoidWithUnits(canna)] as [string, string]);
    const tooltips = variants?.map(v => [v?.id, this.makeMaxCannabinoidTooltip(v, canna)] as [string, string]);
    return {
      key: 'max' + canna,
      headerTitle: 'Max ' + canna,
      columnValues: new Map<string, string>(values),
      columnTooltips: new Map<string, string>(tooltips),
      ascFunc: (a: Variant, b: Variant) => SortUtils.variantsMaxCannabinoidOrTerpeneAscNullsLast(a, b, canna),
      descFunc: (a: Variant, b: Variant) => SortUtils.variantsMaxCannabinoidOrTerpeneDescNullsLast(a, b, canna)
    };
  }

  private getTotalTerpeneColumn(variants: Variant[]): VariantPreviewColumn {
    return {
      key: 'totalTerpenes',
      headerTitle: 'Total Terpenes',
      columnValues: new Map<string, string>(variants?.map(v => [v?.id, v?.getTotalTerpenes()])),
      columnTooltips: new Map<string, string>(),
      ascFunc: (a: Variant, b: Variant) => SortUtils.variantTotalTerpenesAsc(a, b),
      descFunc: (a: Variant, b: Variant) => SortUtils.variantTotalTerpenesDesc(a, b),
    };
  }

  private getTerpeneColumn(terp: string, vars: Variant[]): VariantPreviewColumn {
    const terpenePascalCased = StringUtils.camelize(terp);
    const values = vars?.map(v => [v?.id, v?.getTerpeneWithUnits(terpenePascalCased)] as [string, string]);
    const tooltips = vars?.map(v => [v?.id, this.makeTerpeneTooltip(v, terpenePascalCased)] as [string, string]);
    return {
      key: StringUtils.lowercaseFirstCharacter(terpenePascalCased),
      headerTitle: terp,
      columnValues: new Map<string, string>(values),
      columnTooltips: new Map<string, string>(tooltips),
      ascFunc: (a: Variant, b: Variant) => SortUtils.variantsTerpeneAsc(a, b, terpenePascalCased),
      descFunc: (a: Variant, b: Variant) => SortUtils.variantsTerpeneDesc(a, b, terpenePascalCased)
    };
  }

  private getMinTerpeneColumn(terp: string, vars: Variant[]): VariantPreviewColumn {
    const terpKey = StringUtils.toPascalCase(terp);
    const values = vars?.map(v => [v?.id, v?.getMinTerpeneWithUnits(terpKey)] as [string, string]);
    const tooltips = vars?.map(v => [v?.id, this.makeMinTerpeneTooltip(v, terpKey)] as [string, string]);
    return {
      key: 'min' + terpKey,
      headerTitle: 'Min ' + terp,
      columnValues: new Map<string, string>(values),
      columnTooltips: new Map<string, string>(tooltips),
      ascFunc: (a: Variant, b: Variant) => SortUtils.variantsMinCannabinoidOrTerpeneAscNullsLast(a, b, terpKey),
      descFunc: (a: Variant, b: Variant) => SortUtils.variantsMinCannabinoidOrTerpeneDescNullsLast(a, b, terpKey)
    };
  }

  private getMaxTerpeneColumn(terp: string, vars: Variant[]): VariantPreviewColumn {
    const terpKey = StringUtils.toPascalCase(terp);
    const values = vars?.map(v => [v?.id, v?.getMaxTerpeneWithUnits(terpKey)] as [string, string]);
    const tooltips = vars?.map(v => [v?.id, this.makeMaxTerpeneTooltip(v, terpKey)] as [string, string]);
    return {
      key: 'max' + terpKey,
      headerTitle: 'Max ' + terp,
      columnValues: new Map<string, string>(values),
      columnTooltips: new Map<string, string>(tooltips),
      ascFunc: (a: Variant, b: Variant) => SortUtils.variantsMaxCannabinoidOrTerpeneAscNullsLast(a, b, terpKey),
      descFunc: (a: Variant, b: Variant) => SortUtils.variantsMaxCannabinoidOrTerpeneDescNullsLast(a, b, terpKey)
    };
  }

}
