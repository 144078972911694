import { Component, forwardRef, Input } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { ReactiveTableSortByBluePrintComponent } from '@mobilefirstdev/reactive-table';

@Component({
  selector: 'app-table-asc-desc-sort',
  templateUrl: './table-asc-desc-sort.component.html',
  styleUrls: ['./table-asc-desc-sort.component.scss'],
  providers: [
    {
      provide: ReactiveTableSortByBluePrintComponent,
      useExisting: forwardRef(() => TableAscDescSortComponent)
    }
  ]
})
export class TableAscDescSortComponent extends ReactiveTableSortByBluePrintComponent {

  @Input() ascSort: (a: any, b: any) => number;
  @Input() ascSubSort: { property: string, sortBy: (a: any, b: any) => number };
  @Input() descSort: (a: any, b: any) => number;
  @Input() descSubSort: { property: string, sortBy: (a: any, b: any) => number };

  public isAscendingSort$ = this.sortingBy$.pipe(map(sortingBy => sortingBy === this.ascSort));
  public isDescendingSort$ = this.sortingBy$.pipe(map(sortingBy => sortingBy === this.descSort));

  clicked(): void {
    combineLatest([this.isAscendingSort$, this.isDescendingSort$]).pipe(
      take(1)
    ).subscribe(([isAscendingSort, isDescendingSort]) => {
      if (isAscendingSort) {
        this._sortBy.next(this.descSort);
        this._subSortBy.next(this.descSubSort);
      } else if (isDescendingSort) {
        this._sortBy.next(undefined);
        this._subSortBy.next(undefined);
      } else {
        this._sortBy.next(this.ascSort);
        this._subSortBy.next(this.ascSubSort);
      }
    });
  }

}
