import { Injectable } from '@angular/core';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { FilterProductsFormViewModel } from '../../shared/components/filter-products-form/filter-products-form-view-model';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Product } from '../../../models/product/dto/product';
import { Variant } from '../../../models/product/dto/variant';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { PaginationUtils } from '../../../utils/pagination-utils';
import { CannabinoidsAndTerpenesDomainModel } from '../../../domainModels/cannabinoids-and-terpenes-domain-model';

// Provided by Logged In Scope
@Injectable()
export class AllProductsDataTableViewModel extends FilterProductsFormViewModel {

  constructor(
    productDomainModel: ProductDomainModel,
    companyDomainModel: CompanyDomainModel,
    locationDomainModel: LocationDomainModel,
    cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel
  ) {
    super(companyDomainModel, locationDomainModel, productDomainModel, cannabinoidsAndTerpenesDomainModel);
  }

  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;
  public readonly companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public override readonly inventoryProvider$ = this.companyDomainModel.inventoryProvider$;

  public classificationMap$ = window?.types?.strainTypes$.pipe(
    map(types => new Map(types?.map(t => [t?.value, t]) || [])),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected override _numberOfProductsPerPage = new BehaviorSubject<number>(25);
  public override nProductsPerPageOptions$ = new BehaviorSubject(
    PaginationUtils.constructResultsPerPageOptions([10, 25, 50, 100])
  );

  /**
   * Need to override at "All Products Table" level so that the table uses the variants from the selected
   * smart filter instead of the variants off the product.
   */
  protected override getVariantsFilteredByMedAndRec(p: Product, filterByUse: (v: Variant) => boolean): Variant[] {
    const isSearchingProductsUsingSmartFilters = !!p?.variantsThatMeetSmartFilterSearch?.length;
    if (isSearchingProductsUsingSmartFilters) {
      return filterByUse
        ? p?.variantsThatMeetSmartFilterSearch?.filter(filterByUse)
        : p?.variantsThatMeetSmartFilterSearch;
    } else {
      return super.getVariantsFilteredByMedAndRec(p, filterByUse);
    }
  }

  trackByName = (index: number, name: string): string => name;
  trackByColumnKey = (index: number, key: string): string => key;
  trackByVariantId = (index: number, variant: Variant): string => variant?.id;

}
