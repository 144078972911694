import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import type { Variant } from '../../../../../models/product/dto/variant';
import type { Section } from '../../../../../models/menu/dto/section';

@Pipe({
  name: 'variantOrSectionFromId'
})
export class VariantOrSectionFromIdPipe implements PipeTransform {

  transform(
    id: string,
    variants$: Observable<Variant[]>,
    sections$: Observable<Section[]>
  ): Observable<Variant | Section> {
    return variants$?.pipe(
      switchMap(variants => {
        const variant = variants?.find(v => v?.id === id);
        if (variant) {
          return of(variant);
        } else {
          return sections$?.pipe(
            map(sections => sections?.find(s => s?.id === id))
          );
        }
      })
    );
  }

}
