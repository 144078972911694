<div class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">{{ viewModel.title$ | push | replaceMenuWithTemplate : templateMode }}</p>
    <p class="bs-edit-section-description">
      {{ viewModel.description$ | push | replaceMenuWithTemplate : templateMode }}
    </p>
    <div [style.max-width.rem]="9.25">
      <app-card-stack-size-selection
        [cardSize]="viewModel.printCardSize$ | push"
        [displayOnly]="true"
        [selected]="true">
      </app-card-stack-size-selection>
    </div>
  </div>
  <div class="col-sm-8 col-md-8 col-lg-8">
    <ng-content></ng-content>
  </div>
</div>

<hr class="mt-16px" />
