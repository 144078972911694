import { ChangeDetectionStrategy, Component, Injector, NgZone } from '@angular/core';
import { ComponentCanDeactivate } from '../../../../../models/protocols/component-can-deactivate';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeMediaOptions } from '../../../../../models/shared/stylesheet/change-media-options';
import { EditProductMenuViewModel } from '../../../viewModels/edit-product-menu-view-model';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../../../../../services/toast-service';
import { AutoSaveComponent } from '../../../../shared/components/auto-save/auto-save.component';

@Component({
  selector: 'app-edit-product-menu',
  templateUrl: './edit-product-menu.component.html',
  styleUrls: [
    './edit-product-menu.component.scss',
    '../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditProductMenuViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProductMenuComponent extends AutoSaveComponent implements ComponentCanDeactivate {

  constructor(
    public override viewModel: EditProductMenuViewModel,
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected sanitizer: DomSanitizer,
    protected toastService: ToastService,
    protected activeRoute: ActivatedRoute,
  ) {
    super(viewModel);
  }

  //  Background Image
  static getChangeBackgroundImageOptions(): ChangeMediaOptions {
    const opts = new ChangeMediaOptions();
    opts.modalTitle = 'Change Background Asset';
    opts.loadingMess = 'Uploading Background Asset';
    opts.successTitle = 'Upload Successful';
    opts.successMess = 'Uploaded new background asset.';
    opts.failureTitle = 'Upload Failed';
    opts.failureMess = 'Upload new background asset failed.';
    return opts;
  }

}
