import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { StackPrintJobAppliedProductsViewModel } from './stack-print-job-applied-products-view-model';
import { CardStackPrintConfig } from '../../../../../../models/automation/card-stack-print-config';

@Injectable()
export class BulkCardStackPrintJobAppliedProductsViewModel extends StackPrintJobAppliedProductsViewModel {

  public locationMenus$ = this.menuDomainModel.currentLocationMenus$;

  public cardStackPrintConfigs$ = this.job$.pipe(
    map(job => job?.getStackPrintConfigMap() || new Map<string, CardStackPrintConfig>())
  );

}
