<app-loading *rxIf="viewModel.isLoading$" [options]="viewModel.loadingOpts$ | push"> </app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="'editShelfTalkerMenu'"
  (formsAutoSubmitted$)="viewModel.saveMenu(true)"
  (formsSubmitted$)="viewModel.saveMenu(false)"
  (disableSubmit$)="viewModel.setAutoSaveTimer($event)"
  (unsavedChanges$)="viewModel.setUnsavedChanges($event)">
</lib-reactive-form-merge-groups>

<app-edit-menu-container>
  <app-edit-menu-header
    [menu]="viewModel.menu$ | push"
    [crumbs]="viewModel.breadcrumbs$ | push"
    [allowLiveView]="viewModel.allowLiveView$ | push"
    [autoSaveLoadingOpts]="viewModel.autoSaveLoadingOpts$ | push"
    [disableActiveToggle]="viewModel.disableActiveToggleForMenu$ | push"
    [headerText]="'Edit Shelf Talker Stack'"
    [headerSubtext]="'Guide your customers to an informed purchasing decision with shelf talkers.'"
    [lastSavedTimeText]="viewModel.lastAutoSavedTimeText$ | push"
    [formPristine]="formCapture.allPristine$ | push"
    [formHasErrors]="formCapture.hasErrors$ | push"
    [formDisableSubmit]="formCapture.disableSubmit$ | push"
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | push"
    [mergeKey]="'editShelfTalkerMenu'"
    [showLiveView]="viewModel.showLiveView"
    [toggleActiveState]="viewModel.toggleActiveState"
    [userIsTemplateAdmin]="viewModel.userIsTemplateAdmin$ | push"
    [formErrorMessages]="formCapture.errorMessages$ | push"
    (editTemplateClicked)="viewModel.navigateToEditTemplate($event)"
    (submitForms)="formCapture.submitForms($event)">
  </app-edit-menu-header>

  <app-edit-shelf-talker-brands-menu-body
    [mergeKey]="'editShelfTalkerMenu'"
    [isTemplatedMenu]="viewModel.isTemplatedMenu$ | push"
    [formHasErrors]="formCapture.hasErrors$ | push"
    [formPristine]="formCapture.allPristine$ | push"
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | push"
    (submitForms)="formCapture.submitForms($event)">
  </app-edit-shelf-talker-brands-menu-body>

  <app-edit-product-menu-footer
    [canDeleteTemplateMenu]="viewModel.canDeleteTemplateMenu$ | push"
    [formAutoSubmitted]="formCapture.formsAutoSubmitted$ | push"
    [formDisableSubmit]="formCapture.disableSubmit$ | push"
    [formErrorMessages]="formCapture.errorMessages$ | push"
    [formHasErrors]="formCapture.hasErrors$ | push"
    [formPristine]="formCapture.allPristine$ | push"
    (submitForms)="formCapture.submitForms($event)">
    Edit Shelf Talker Menu Footer
  </app-edit-product-menu-footer>
</app-edit-menu-container>
