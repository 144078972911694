import { LiveViewViewModel } from './live-view-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';
import { LabelDomainModel } from '../../../domainModels/label-domain-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { DomSanitizer } from '@angular/platform-browser';
import { exists } from '../../../functions/exists';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { DisplayAttributesDomainModel } from '../../../domainModels/display-attributes-domain-model';

@Injectable()
export abstract class CardLiveViewViewModel extends LiveViewViewModel {

  constructor(
    companyDomainModel: CompanyDomainModel,
    displayAttributesDomainModel: DisplayAttributesDomainModel,
    labelDomainModel: LabelDomainModel,
    locationDomainModel: LocationDomainModel,
    sanitizer: DomSanitizer
  ) {
    super(companyDomainModel, displayAttributesDomainModel, labelDomainModel, locationDomainModel, sanitizer);
    this.receiveCardDimensionsFromIFrame();
  }

  protected readonly borderInPx$ = of(0);

  protected readonly _cardHeight = new BehaviorSubject<number|null>(0);
  public readonly cardHeight$ = combineLatest([
    this.borderInPx$,
    this._cardHeight
  ]).pipe(
    filter(([_, cardHeight]) => exists(cardHeight)),
    map(([borderInPx, cardHeight]) => cardHeight + (borderInPx * 2)),
    distinctUntilChanged()
  );
  connectToCardHeight = (cardHeight: number|null) => this._cardHeight.next(cardHeight);

  protected readonly _cardWidth = new BehaviorSubject<number|null>(0);
  public readonly cardWidth$ = combineLatest([
    this.borderInPx$,
    this._cardWidth
  ]).pipe(
    filter(([_, cardWidth]) => exists(cardWidth)),
    map(([borderInPx, cardWidth]) => cardWidth + (borderInPx * 2)),
    distinctUntilChanged()
  );
  connectToCardWidth = (cardWidth: number|null) => this._cardWidth.next(cardWidth);

  public readonly scaleToParentView$ = combineLatest([
    this.cardHeight$,
    this.cardWidth$,
    this.parentContainerHeight$,
    this.parentContainerWidth$
  ]).pipe(
    map(([cardHeight, cardWidth, parentContainerHeight, parentContainerWidth]) => {
      const heightRatio = (parentContainerHeight && cardHeight) ? (parentContainerHeight / cardHeight) : 1;
      const widthRatio = (parentContainerWidth && cardWidth) ? (parentContainerWidth / cardWidth) : 1;
      return Math.min(heightRatio, widthRatio, 1);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly boxShadow$ = this.cardHeight$.pipe(
    map((height) => exists(height) ? '1px 1px 6px black' : null),
  );

  public readonly assetLoadingOpts$ = of(this.assetLoadingOpts());

  private readonly _loadingVariantAssets = new BehaviorSubject<boolean>(true);
  public readonly loadingVariantAssets$ = combineLatest([
    this.cardHeight$,
    this.cardWidth$,
    this._loadingVariantAssets
  ]).pipe(
     map(([cardHeight, cardWidth, loading]) => cardHeight > 0 && cardWidth > 0 ? loading : false)
  );
  connectToLoadingAssetMetadata = (loading: boolean) => this._loadingVariantAssets.next(loading);

  protected _startTalking = new Subject<void>();
  public startTalking$ = this._startTalking as Observable<void>;

  /**
   * All messages coming from window get intercepted here. This means messages that the dashboard app sends
   * to the display app will be received here as well. Therefore, we must check if the specific message contains
   * relevant data, or else we can end up in an infinite loop.
   *
   * An initial handshake happens from the display app where we receive the card dimensions as undefined. This lets us
   * know that the display app has loaded and is ready to receive messages from the dashboard app. When this signal is
   * received, we toggle startTalking$ to begin transmitting data.
   */
  protected receiveCardDimensionsFromIFrame(): void {
    fromEvent(window, 'message').pipe(
      filter((event: MessageEvent) => {
        const origin = event?.origin?.toLowerCase();
        const hasData = exists(event?.data);
        return hasData && (origin?.includes('localhost') || origin?.includes('mybudsense.com'));
      }),
      map((event: MessageEvent) => event?.data)
    ).subscribeWhileAlive({
      owner: this,
      next: (msg: any) => {
        if (('cardHeight' in msg) && (msg?.cardHeight === undefined)) this._startTalking.next();
        if (msg?.cardHeight) this.connectToCardHeight(msg?.cardHeight);
        if (msg?.cardWidth) this.connectToCardWidth(msg?.cardWidth);
        if ('loadingAssetMetadata' in msg) this.connectToLoadingAssetMetadata(msg?.loadingAssetMetadata);
      }
    });
  }

  assetLoadingOpts(): LoadingOptions {
    const opts = LoadingOptions.defaultWhiteBackground();
    opts.addRequest(' ');
    return opts;
  }

}
