import { Injectable } from '@angular/core';
import { EditTemplateShelfTalkerMenuCombinedLoadingOptsViewModel } from '../../edit-template-shelf-talker-menu-combined-loading-opts-view-model';
import { EditTemplateShelfTalkerBrandsMenuViewModel } from './edit-template-shelf-talker-brands-menu-view-model';
import { EditProductMenuViewModel } from '../../../../../../menu/viewModels/edit-product-menu-view-model';

@Injectable()
export class EditTemplateShelfTalkerBrandsMenuCombinedLoadingOptsViewModel
  extends EditTemplateShelfTalkerMenuCombinedLoadingOptsViewModel {

  constructor(
    protected editTemplateShelfTalkerBrandsMenuViewModel: EditTemplateShelfTalkerBrandsMenuViewModel,
    protected override editProductMenuViewModel: EditProductMenuViewModel
  ) {
    super(editTemplateShelfTalkerBrandsMenuViewModel, editProductMenuViewModel);
  }

}
