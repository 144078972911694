<app-menu-additional-options-accordion
  [accordionTitle]="'Additional Menu Information' | replaceMenuWithTemplate : templateMode"
  [showUpperHr]="true"
  [showBottomHr]="!(viewModel.isPlaylist$ | push)"
  [showErrorIndicator]="detailsForm.hasError$ | push">
  <app-edit-shelf-talker-brands-menu-details-form
    #detailsForm
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | push"
    [isTemplatedMenu]="viewModel.isTemplatedMenu$ | push"
    [bindTo]="viewModel.menu$ | push">
  </app-edit-shelf-talker-brands-menu-details-form>
</app-menu-additional-options-accordion>

<app-menu-additional-options-accordion
  *rxIf="viewModel.showPrintOptions$ | push"
  [accordionTitle]="'Print Options'"
  [showBottomHr]="!(viewModel.isPrintLabelMenu$ | push)"
  [showErrorIndicator]="printOptionsForm.hasError$ | push">
  <app-menu-print-options-form
    #printOptionsForm
    [templateMode]="templateMode"
    [isTemplatedMenu]="viewModel?.isTemplatedMenu$ | push"
    [singlePageMenuSwitchDisabled]="viewModel?.singlePageMenuSwitchDisabled$ | push"
    [needsToBeSinglePage]="viewModel.needsToBeSinglePage$ | push"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | push"
    [bindTo]="viewModel.menu$ | push">
  </app-menu-print-options-form>
</app-menu-additional-options-accordion>

<app-menu-additional-options-accordion
  [accordionTitle]="'Hide/Show Options'"
  [showBottomHr]="!(viewModel.containsStackedContent$ | push)"
  [showErrorIndicator]="showHideOptionsForm.hasError$ | push">
  <app-edit-shelf-talker-brands-menu-hide-show-options-form
    #showHideOptionsForm
    [templateMode]="templateMode"
    [mergeKey]="mergeKey"
    [menu]="viewModel.menu$ | push"
    [isTemplatedMenu]="viewModel.isTemplatedMenu$ | push"
    [hideSaleDisabled]="viewModel.hideSaleDisabled$ | push"
    [bindTo]="viewModel.menu$ | push">
  </app-edit-shelf-talker-brands-menu-hide-show-options-form>
</app-menu-additional-options-accordion>
