import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TerpenesFormViewModel } from './terpenes-form-view-model';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { ParserUtils } from '../../../../../../../../utils/parser-utils';

@Component({
  selector: 'app-terpenes-form',
  templateUrl: './terpenes-form.component.html',
  styleUrls: [
    '../edit-variant-terpenes.component.scss',
    './terpenes-form.component.scss'
  ],
  providers: [TerpenesFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerpenesFormComponent extends BaseComponent {

  constructor(
    public viewModel: TerpenesFormViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToTerpene') terpene: string;
  @Input() @ViewModelConnector('connectToTerpenePascalCased') terpenePascalCased: string;
  @Input() @ViewModelConnector('connectToTerpeneCamelCased') terpeneCamelCased: string;
  @Input() @ViewModelConnector('connectToPluralizeLabel') pluralizeLabel: boolean = false;
  @Input() mergeKey: string;

  parseNumberAsString(val: string) {
    return ParserUtils.parseFloatAsString(val);
  }

}
