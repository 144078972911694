export enum SectionSortSecondaryCannabinoids {
  CBDAAsc = 'CBDA_ASC',
  CBDADesc = 'CBDA_DESC',
  CBGAsc = 'CBG_ASC',
  CBGDesc = 'CBG_DESC',
  CBGAAsc = 'CBGA_ASC',
  CBGADesc = 'CBGA_DESC',
  CBLAsc = 'CBL_ASC',
  CBLDesc = 'CBL_DESC',
  CBLAAsc = 'CBLA_ASC',
  CBLADesc = 'CBLA_DESC',
  CBNAsc = 'CBN_ASC',
  CBNDesc = 'CBN_DESC',
  CBNAAsc = 'CBNA_ASC',
  CBNADesc = 'CBNA_DESC',
  CBTAsc = 'CBT_ASC',
  CBTDesc = 'CBT_DESC',
  CBCAsc = 'CBC_ASC',
  CBCDesc = 'CBC_DESC',
  CBCAAsc = 'CBCA_ASC',
  CBCADesc = 'CBCA_DESC',
  CBCVAsc = 'CBCV_ASC',
  CBCVDesc = 'CBCV_DESC',
  CBDVAsc = 'CBDV_ASC',
  CBDVDesc = 'CBDV_DESC',
  THC8Asc = 'THC8_ASC',
  THC8Desc = 'THC8_DESC',
  THC9Asc = 'THC9_ASC',
  THC9Desc = 'THC9_DESC',
  THCAAsc = 'THCA_ASC',
  THCADesc = 'THCA_DESC',
  THCVAsc = 'THCV_ASC',
  THCVDesc = 'THCV_DESC',
  TACAsc = 'TAC_ASC',
  TACDesc = 'TAC_DESC',
}
