import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { Menu } from '../../../../../models/menu/dto/menu';
import { HydratedSection } from '../../../../../models/menu/dto/hydrated-section';
import { CompanyConfiguration } from '../../../../../models/company/dto/company-configuration';
import { LocationConfiguration } from '../../../../../models/company/dto/location-configuration';
import type { SectionSortOption } from '../../../../../models/enum/dto/section-sort-option';

@Component({
  selector: 'app-edit-menu-section-form',
  templateUrl: './edit-menu-section-form.component.html',
  styleUrls: ['./edit-menu-section-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuSectionFormComponent extends BaseComponent {

  @Input() mergeKey: string;
  @Input() bindTo: any;
  @Input() menu: Menu;
  @Input() section: HydratedSection;
  @Input() isTemplatedSection: boolean = false;
  @Input() companyConfig: CompanyConfiguration;
  @Input() locationConfig: LocationConfiguration;
  @Output() showZeroStockChanged = new EventEmitter<boolean>(true);
  @Output() formAutoSubmitted: EventEmitter<any[]> = new EventEmitter<any[]>(true);
  @Output() disableSubmit: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Output() primarySortChanged = new EventEmitter<SectionSortOption>(true);
  @Output() secondarySortChanged = new EventEmitter<SectionSortOption>(true);

}
