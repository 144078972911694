import { BaseModalViewModel } from '../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take, takeUntil, tap } from 'rxjs/operators';
import { HydratedSmartFilter } from '../../../../models/automation/hydrated-smart-filter';
import { SmartDataType } from '../../../../models/enum/shared/smart-data-type.enum';
import { SmartFilterGrouping } from '../../../../models/automation/smart-filter-grouping';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';
import { SelectableSmartFilter } from '../../../../models/automation/protocols/selectable-smart-filter';

export enum SmartFilterSelectionTab {
  Custom,
  Default,
  Advanced
}

export const MAX_SF_LIMIT_ON_SMART_DA = 10;

@Injectable()
export class SmartFilterSelectionModalViewModel extends BaseModalViewModel {

  constructor(
    private userDomainModel: UserDomainModel,
    public smartFilterDomainModel: SmartFiltersDomainModel,
    private activeModal: NgbActiveModal,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  private _forSmartData = new BehaviorSubject<SmartDataType>(null);
  public forSmartData$ = this._forSmartData as Observable<SmartDataType>;

  private _forTemplateSection = new BehaviorSubject<boolean>(false);
  public forTemplateSection$ = this._forTemplateSection as Observable<boolean>;

  private _tabSelected = new BehaviorSubject<SmartFilterSelectionTab>(SmartFilterSelectionTab.Custom);
  public selectedTabIndex$ = this._tabSelected.asObservable();

  public maxSFCountOnSmartDA = MAX_SF_LIMIT_ON_SMART_DA;

  // already added smart filter ids (these will be in a disabled state)
  private _previouslyAddedSmartFilterIds = new BehaviorSubject<string[]>([]);
  public previouslyAddedSmartFilterIds$ = this._previouslyAddedSmartFilterIds.asObservable();

  // searching for smart filters
  public searchableSmartFilters$ = combineLatest([
    this.selectedTabIndex$,
    this.smartFilterDomainModel.clientLocationSmartFilters$,
    this.smartFilterDomainModel.curatedDefaultSmartFilters$,
    this.smartFilterDomainModel.curatedAdvancedSmartFilters$,
  ]).pipe(
    tap(() => this._loadingSmartFilters.next(true)),
    map(([selectedTab, locationFilters, curatedDefaultFilters, curatedAdvancedFilters]) => {
      switch (selectedTab) {
        case SmartFilterSelectionTab.Custom:
          return locationFilters;
        case SmartFilterSelectionTab.Default:
          return curatedDefaultFilters;
        case SmartFilterSelectionTab.Advanced:
          return curatedAdvancedFilters;
      }
    }),
    tap(smartFilters => this._loadingSmartFilters.next(!smartFilters))
  );
  private _searchString = new BehaviorSubject<string>('');
  public searchString$ = this._searchString.asObservable();
  private _searchHits = new BehaviorSubject<HydratedSmartFilter[]>([]);
  public searchHits$ = this._searchHits;

  // smart filter output pipes for which smart filter to display
  private _loadingSmartFilters = new BehaviorSubject<boolean>(true);
  public loadingSmartFilters$ = this._loadingSmartFilters.pipe(distinctUntilChanged());
  public groupedSearchableSmartFilters$ = combineLatest([
    this.selectedTabIndex$,
    this.smartFilterDomainModel.clientLocationGroupedSmartFilters$,
    this.smartFilterDomainModel.curatedDefaultGroupedSmartFilters$,
    this.smartFilterDomainModel.curatedAdvancedGroupedSmartFilters$,
  ]).pipe(
    map(([selectedTab, locationGroupedFilters, curatedDefaultGroupedFilters, curatedAdvancedGroupedFilters]) => {
      switch (selectedTab) {
        case SmartFilterSelectionTab.Custom:
          return locationGroupedFilters;
        case SmartFilterSelectionTab.Default:
          return curatedDefaultGroupedFilters;
        case SmartFilterSelectionTab.Advanced:
          return curatedAdvancedGroupedFilters;
      }
    })
  );

  public groupedHits$ = combineLatest([
    this.searchString$,
    this.searchHits$,
    this.groupedSearchableSmartFilters$,
    this.forSmartData$,
    this.forTemplateSection$
  ]).pipe(
    debounceTime(100),
    map(([searched, hits, groups, forSmartData, forTemplateSection]) => {
      const selectableSmartFilters = !!searched
        ? this.filterGroupsWithHits(groups, hits)
        : groups;
      return forSmartData || forTemplateSection
        ? selectableSmartFilters.filter((sf) => {
          return sf instanceof SmartFilterGrouping
            || (sf instanceof HydratedSmartFilter && sf.isCuratedSmartFilter)
            || (sf instanceof HydratedSmartFilter && sf.isCompanySmartFilter);
        })
        : selectableSmartFilters;
    }),
  );

  public showNoResults$ = combineLatest([
    this.loadingSmartFilters$,
    this.selectedTabIndex$,
    this.groupedSearchableSmartFilters$,
    this.groupedHits$
  ]).pipe(
    map(([loading, selectedTab, smartFilters, hits]) => {
      const noHits = hits?.length < 1;
      const hasSmartFilters = smartFilters?.length > 0;
      if (selectedTab === SmartFilterSelectionTab.Custom) {
        return hasSmartFilters ? (!loading && noHits) : false;
      } else {
        return !loading && noHits;
      }
    })
  );

  public showNoCustomFilters$ = combineLatest([
    this.loadingSmartFilters$,
    this.selectedTabIndex$,
    this.groupedSearchableSmartFilters$
  ]).pipe(
    map(([loading, selectedTab, filters]) => {
      return !loading && selectedTab === SmartFilterSelectionTab.Custom && (filters?.length === 0);
    })
  );

  // selected smart filters
  private _selectedSmartFilterIds = new BehaviorSubject<string[]>([]);
  public selectedSmartFilterIds$ = this._selectedSmartFilterIds.asObservable();

  // add to selection
  private _addSmartFilterId = new Subject<string>();
  private listenToAddAnId = this._addSmartFilterId.pipe(
    map(id => this._selectedSmartFilterIds.getValue()?.concat([id])?.unique()),
    takeUntil(this.onDestroy)
  ).subscribe(this._selectedSmartFilterIds);

  // remove from selection
  private _removeSmartFilterId = new Subject<string>();
  private listenToRemoveAnId = this._removeSmartFilterId.pipe(
    map(id => this._selectedSmartFilterIds.getValue()?.filter(currId => currId !== id)),
    takeUntil(this.onDestroy)
  ).subscribe(this._selectedSmartFilterIds);

  public displaySmartFilterLimitBanner$ = combineLatest([
    this.selectedSmartFilterIds$,
    this._previouslyAddedSmartFilterIds,
    this.forSmartData$
  ]).pipe(
    map(([selectedIds, previouslyAddedIds, forSmartData]) => {
      const nSelectedIds = selectedIds?.length + previouslyAddedIds?.length;
      return nSelectedIds >= MAX_SF_LIMIT_ON_SMART_DA && forSmartData;
    })
  );

  public allowSmartFilterCreation$ = this.userDomainModel.isCompanyAdmin$;

  public smartFilterCreationTooltip$ = this.allowSmartFilterCreation$.pipe(
    map(allow => {
      return allow ? null : 'Only company admins can create smart filters';
    })
  );

  public disableAddButton$ = combineLatest([
    this.selectedSmartFilterIds$,
    this.previouslyAddedSmartFilterIds$,
    this.forSmartData$
  ]).pipe(
    map(([selectedIds, previouslyAddedIds, forSmartBadges]) => {
      const nSelectedIds = selectedIds?.length + previouslyAddedIds?.length;
      return forSmartBadges && nSelectedIds > MAX_SF_LIMIT_ON_SMART_DA;
    })
  );

  tabSelected = (newIndex: number) => this._tabSelected.next(newIndex);
  searchText = (s: string) => this._searchString.next(s);
  searchHits = (hits: HydratedSmartFilter[]) => this._searchHits.next(hits);
  previouslyAddedSmartFilterIds = (ids: string[]) => this._previouslyAddedSmartFilterIds.next(ids);
  clearSelections = () => this._selectedSmartFilterIds.next([]);
  addSelectedId = (id: string) => this._addSmartFilterId.next(id);
  removeSelectedId = (id: string) => this._removeSmartFilterId.next(id);
  addSelectedIds = (ids: string[]) => ids?.forEach(id => this.addSelectedId(id));
  removeSelectedIds = (ids: string[]) => ids?.forEach(id => this.removeSelectedId(id));
  openCreateSmartFilterModal = () => this.activeModal.dismiss('open_create');
  spitOutNewlySelectedIds = () => this.selectedSmartFilterIds$.pipe(take(1)).subscribe(this.dismissModalSubject);
  forSmartData = (smartDataType: SmartDataType) => this._forSmartData.next(smartDataType);
  forTemplateSection = (forTemplateSection: boolean) => this._forTemplateSection.next(forTemplateSection);

  private filterGroupsWithHits(groups: SelectableSmartFilter[], hits: HydratedSmartFilter[]): SelectableSmartFilter[] {
    const selectableSmartFilters = groups?.filter(group => group.some(hits));
    return selectableSmartFilters?.map(ssf => {
      if (ssf instanceof SmartFilterGrouping) {
        const newSelectableSmartFilter = new SmartFilterGrouping(ssf?.category);
        newSelectableSmartFilter.grouping = ssf.grouping.filter(grouping => grouping.some(hits)).shallowCopy();
        return newSelectableSmartFilter;
      }
      return ssf;
    });
  }

}
