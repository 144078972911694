import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { StackManualBulkPrintJobViewModel } from '../../stack-manual-bulk-print-job-view-model';

@Component({
  selector: 'app-card-stack-manual-bulk-print-job-select-all-sections',
  templateUrl: './card-stack-manual-bulk-print-job-select-all-sections.component.html',
  styleUrls: ['./card-stack-manual-bulk-print-job-select-all-sections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardStackManualBulkPrintJobSelectAllSectionsComponent extends BaseComponent {

  constructor(
    public viewModel: StackManualBulkPrintJobViewModel
  ) {
    super();
  }

  @Input() label: string = 'Select all shelf talkers';

}
