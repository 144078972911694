<lib-reactive-form-group
  #form
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedSection | templatedMenuDisabledFormControlTooltip"
  [customErrorMessageMap]="viewModel.customErrorMessageMap$ | push"
  (formAutoSubmitted$)="formAutoSubmitted.emit($event)"
  (disableSubmit$)="disableSubmit.emit($event)">
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-text
      [inputName]="'title'"
      [label]="'Section Title'"
      [placeholder]="'Enter a title for this section.'"
      [inlineLabel]="false"
      [required]="true"
      [minLength]="1"
      [maxLength]="128"
      [disabled]="isTemplatedSection"
      [bindingProperty]="'title'">
    </lib-reactive-form-text>

    <lib-reactive-form-drop-down
      #sectionType
      [inputName]="'sectionType'"
      [label]="'Section Type'"
      [placeholder]="'Select the type of section to create'"
      [inlineLabel]="false"
      [disabled]="true"
      [required]="true"
      [disabledTooltip]="''"
      [dropdowns]="viewModel.sectionTypes$ | push"
      [bindingProperty]="'sectionType'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      [inputName]="'sorting'"
      [label]="'Primary Sort'"
      [placeholder]="'Select how variants should be sorted.'"
      [inlineLabel]="false"
      [required]="true"
      [dropdowns]="viewModel.shelfTalkerBrandsPrimarySortingTypes$ | push"
      [bindingProperty]="'sorting'"
      [reinitializeWhenListChanges]="false"
      [disabled]="isTemplatedSection"
      (valueChanged)="viewModel.connectToSelectedPrimarySortOption($event)">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      [inputName]="'secondarySorting'"
      [label]="'Secondary Sort'"
      [disabled]="(viewModel.secondarySortDisabled$ | push) || isTemplatedSection"
      [placeholder]="viewModel.secondarySortPlaceholder$ | push"
      [tooltip]="viewModel.secondarySortTooltip$ | push"
      [inlineLabel]="false"
      [required]="false"
      [dropdowns]="viewModel.shelfTalkerBrandsSecondarySortOptions$ | push"
      [bindingProperty]="'secondarySorting'"
      [reinitializeWhenListChanges]="false"
      [programmaticallyChangeValue]="viewModel.programmaticallyChangeSecondarySort$ | push"
      (valueChanged)="viewModel.connectToSelectedSecondarySortOption($event)">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>
  <lib-reactive-form-row>
    <lib-reactive-form-checkbox
      #showZeroStock
      [inputName]="'showOutOfStockProducts'"
      [disabled]="(viewModel.sectionHasSmartFilters$ | async) || isTemplatedSection"
      [selectedValue]="true"
      [notSelectedValue]="false"
      [emitInitializationValuesAsValueChanged]="true"
      (valueChanged)="viewModel.connectToShowZeroStock($event)"
      [bindingProperty]="'showZeroStockItems'">
      Show Out of Stock Products
    </lib-reactive-form-checkbox>
  </lib-reactive-form-row>
  <lib-reactive-form-row *rxIf="viewModel.showZeroStockNote$">
    <app-note-text [fontSize]="'0.875rem'" [margin]="'1rem 0 0 0'">
      {{ viewModel.zeroStockNote$ | push }}
    </app-note-text>
  </lib-reactive-form-row>
</lib-reactive-form-group>
