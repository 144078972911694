import { ChangeDetectionStrategy, Component, QueryList, ViewChildren } from '@angular/core';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintCardLiveViewModalViewModel } from './print-card-live-view-modal-view-model';
import { ArrowButtonComponent } from '../../../shared/components/arrow-button/arrow-button.component';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { exists } from '../../../../functions/exists';
import { iiif } from '../../../../utils/observable.extensions';

@Component({
  selector: 'app-print-card-live-view-modal',
  templateUrl: './print-card-live-view-modal.component.html',
  styleUrls: ['./print-card-live-view-modal.component.scss'],
  providers: [PrintCardLiveViewModalViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintCardLiveViewModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: PrintCardLiveViewModalViewModel,
    activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  @ViewChildren(ArrowButtonComponent) arrowButtons: QueryList<ArrowButtonComponent>;
  private readonly _arrowButtons = new BehaviorSubject<ArrowButtonComponent[]|null>(null);

  override setupBindings() {
    this._arrowButtons.next(this.arrowButtons?.toArray());
    this.arrowButtons.changes.subscribeWhileAlive({
      owner: this,
      next: (arrowButtons) => this._arrowButtons.next(arrowButtons)
    });
    const vm = this.viewModel;
    combineLatest([
      this._arrowButtons.pipe(filter(arrowButtons => exists(arrowButtons?.length))),
      iiif(vm.shouldGroupProductVariantsTogether$, vm.selectedProductId$, vm.selectedVariantIds$).pipe(
        filter(id => exists(id))
      ),
    ]).once(([arrowButtons, selectedId]) => {
      const selectedIds = Array.isArray(selectedId) ? selectedId : [selectedId];
      const selectedArrowButton = arrowButtons?.find(arrowButton => selectedIds?.includes(arrowButton?.id));
      selectedArrowButton?.elementRef?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }

  override close(returnValue?: any) {
    this.activeModel?.close(returnValue);
  }

}
