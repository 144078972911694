import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading-dots',
  templateUrl: './loading-dots.component.html',
  styleUrls: ['./loading-dots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingDotsComponent {

  public options: AnimationOptions = { path: '/assets/lottie/loading.json' };

  constructor() { }

}
