import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditMenuSectionSectionDetailsComponent } from '../../../../../edit-menu-section/edit-menu-section-section-details/edit-menu-section-section-details.component';
import { EditShelfTalkerBrandsSectionViewModel } from '../edit-shelf-talker-brands-section-view-model';

@Component({
  selector: 'app-edit-shelf-talker-brands-section-details',
  templateUrl: './edit-shelf-talker-brands-section-details.component.html',
  styleUrls: ['./edit-shelf-talker-brands-section-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShelfTalkerBrandsSectionDetailsComponent extends EditMenuSectionSectionDetailsComponent {

  constructor(
    public override viewModel: EditShelfTalkerBrandsSectionViewModel // provided by parent component
  ) {
    super(viewModel);
  }

}
