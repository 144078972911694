import { Deserializable } from '../protocols/deserializable';
import { SyncJobStatus } from '../utils/dto/sync-job-status-type';
import { SyncJobResults } from './sync-job-results';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { StringUtils } from '../../utils/string-utils';
import { SyncJobSyncedBy } from '../automation/enum/sync-job-synced-by.enum';
import { ProviderUtils } from '../../utils/provider-utils';
import { SyncType } from '../enum/dto/sync-type';
import { InventoryProvider } from '../enum/dto/inventory-provider';
import { SortUtils } from '../../utils/sort-utils';

export class SyncDataJob implements Deserializable, UniquelyIdentifiable {

  public companyId: number = null;
  public id: string = '';
  public locationIds: number[] = [];
  public name: string = '';
  public syncTypes: SyncType[] = [];
  public dateCreated: number = null;
  public dateStarted: number = null;
  public dateCompleted: number = null;
  public jobStatus: SyncJobStatus;
  public syncedBy: SyncJobSyncedBy;
  public results: SyncJobResults;
  public errorMessage: string;

  constructor(companyId?: number, syncTypes?: SyncType[], locationIds?: number[]) {
    this.companyId = companyId;
    this.syncTypes = syncTypes
      ?.unique(true)
      ?.sort(SortUtils.sortSyncTypes);
    this.locationIds = locationIds?.length ? locationIds : null;
  }

  onDeserialize() {
    this.syncTypes = Array.from(this.syncTypes)
      ?.unique(true)
      ?.sort(SortUtils.sortSyncTypes) || [];
    this.results = window?.injector?.Deserialize.instanceOf(SyncJobResults, this.results);
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/SyncJobDTO.go
  onSerialize() {
    const dto = Object.create(SyncDataJob.prototype);
    dto.companyId = this.companyId;
    dto.id = this.id;
    dto.locationIds = this.locationIds;
    dto.name = this.name;
    dto.syncTypes = this.syncTypes;
    dto.jobStatus = this.jobStatus;
    dto.syncedBy = this.syncedBy;
    dto.results = this.results;
    dto.errorMessage = this.errorMessage;
    return dto;
  }

  getHumanReadableStatus(): string {
    return StringUtils.splitPascalCasePreserveAcronyms(this.jobStatus?.replace('SyncJobStatus_', ''));
  }

  getSyncTypesTooltip(): string {
    return this.syncTypes
      ?.map(syncType => StringUtils.splitPascalCasePreserveAcronyms(syncType?.replace('SyncType_', '')))
      ?.join('\n');
  }

  autoAddPromotionsToSyncTypesIfNeeded(inventoryProvider: InventoryProvider): void {
    const supportsPromos = ProviderUtils.supportsPromotions(inventoryProvider);
    const syncedPricing = this.syncTypes?.includes(SyncType.Pricing);
    const syncedPromos = this.syncTypes?.includes(SyncType.Promotions);
    if (supportsPromos && syncedPricing && !syncedPromos) {
      this.syncTypes?.push(SyncType.Promotions);
    }
  }

  getUniqueIdentifier(): string {
    const syncTypesId = this.syncTypes?.sort(SortUtils.sortSyncTypes)?.join(',');
    const locationIds = this.locationIds?.sort()?.join(',');
    return `
      -${this.companyId}
      -${this.id}
      -${this.name}
      -${syncTypesId}
      -${locationIds}
      -${this.dateCreated}
      -${this.dateStarted}
      -${this.dateCompleted}
      -${this.jobStatus}
      -${this.syncedBy}
      -${this.results?.getUniqueIdentifier()}
      -${this.errorMessage}
    `;
  }

}
