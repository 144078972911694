<app-print-job-name-form
  [job]="job"
  [label]="'Stack Print Name'"
  [mergeKey]="mergeKey"
  [placeholder]="placeholder"
  [viewOnly]="viewOnly">
</app-print-job-name-form>

<app-print-job-employee-form [job]="job" [mergeKey]="mergeKey" [viewOnly]="viewOnly"></app-print-job-employee-form>

<lib-reactive-search-bar
  #searchBar
  class="search-bar"
  [style.top]="0"
  [style.position]="'sticky'"
  [style.z-index]="2"
  [searchTheseProperties]="['name']"
  [placeHolder]="'Search by name'">
</lib-reactive-search-bar>

<ng-container *rxIf="viewOnly; else editingTemplate">
  <ng-container [ngSwitch]="stackType">
    <ng-container *ngSwitchCase="'shelf talker'">
      <app-single-stack-print-job-applied-sections
        [job]="job"
        [stackType]="stackType"
        [templateMode]="templateMode"
        [searchText]="searchBar.searchText | push">
      </app-single-stack-print-job-applied-sections>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <app-single-stack-print-job-applied-products
        [job]="job"
        [stackType]="stackType"
        [searchText]="searchBar.searchText | push">
      </app-single-stack-print-job-applied-products>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #editingTemplate>
  <ng-container [ngSwitch]="stackType">
    <ng-container *ngSwitchCase="'shelf talker'">
      <app-stack-print-job-sections-form
        [job]="job"
        [mergeKey]="mergeKey"
        [templateMode]="templateMode"
        [viewOnly]="viewOnly"
        [searchText]="searchBar.searchText | push">
      </app-stack-print-job-sections-form>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <app-stack-print-job-products-form
        [job]="job"
        [mergeKey]="mergeKey"
        [templateMode]="templateMode"
        [viewOnly]="viewOnly"
        [searchText]="searchBar.searchText | push"
        [stackType]="stackType">
      </app-stack-print-job-products-form>
    </ng-container>
  </ng-container>
</ng-template>
