import { Injectable, Injector, NgZone } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { distinctUntilChanged, filter, map, pairwise, shareReplay, startWith } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ProductDomainModel } from '../../../../../domainModels/product-domain-model';
import { exists } from '../../../../../functions/exists';
import { ModalCreateOverrideProductGrouping } from '../../../../../modals/modal-create-override-product-grouping';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { OverrideProductGroup } from '../../../../../models/product/dto/override-product-group';

type SidePanelState = { initializeAsClosed: boolean, visible: boolean };

@Injectable()
export class ProductGroupingsViewModel extends BaseViewModel {

  constructor(
    private productDomainModel: ProductDomainModel,
    private ngZone: NgZone,
    private ngbModal: NgbModal,
    private injector: Injector,
  ) {
    super();
    this.connectToLoadingOpts();
    this.selectFirstProductGrouping();
  }

  protected override _loadingOpts = new BehaviorSubject<LoadingOptions>(LoadingOptions.defaultWhiteBackground());
  public readonly locationOverrideProductGroups$ = this.productDomainModel.currentLocationOverrideProductGroups$;

  private readonly _tabContentOffsetRem = new BehaviorSubject<number>(10.125);
  public readonly tabContentOffsetRem$ = this._tabContentOffsetRem as Observable<number>;

  private readonly _selectedProductGroupId = new BehaviorSubject<string|null>(null);
  public readonly selectedProductGroup$ = combineLatest([
    this._selectedProductGroupId,
    this.locationOverrideProductGroups$
  ]).pipe(
    map(([selectedId, productGroups]) => productGroups?.find(group => group?.id === selectedId) || null),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToSelectedProductGroupId = (productGroupId: string|null) => {
    this._selectedProductGroupId.next(productGroupId);
    this.connectToSidePanelShouldOpen(exists(productGroupId));
  };

  private readonly _sidePanelShouldOpen = new BehaviorSubject(false);
  public readonly sidePanelShouldOpen$ = this._sidePanelShouldOpen as Observable<boolean>;
  connectToSidePanelShouldOpen = (shouldOpen: boolean) => this._sidePanelShouldOpen.next(shouldOpen);

  public readonly sidePanelState$: Observable<SidePanelState> = combineLatest([
    this.selectedProductGroup$,
    this.sidePanelShouldOpen$
  ]).pipe(
    map(([selectedProductGroup, shouldOpen]) => exists(selectedProductGroup) && shouldOpen),
    startWith(false),
    pairwise(),
    map(([prevVisible, currVisible]) => {
      return {
        initializeAsClosed: !prevVisible && !currVisible,
        visible: currVisible
      };
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly sidePanelIsOpen$ = this.sidePanelState$.pipe(map(state => state.visible));

  private connectToLoadingOpts(): void {
    this.productDomainModel.currentLocationOverrideProductGroups$.subscribeWhileAlive({
      owner: this,
      next: (groups) => {
        const msg = `Loading Product Groupings`;
        !groups && !this._loadingOpts.containsRequest(msg)
          ? this._loadingOpts.addRequest(msg)
          : this._loadingOpts.removeRequest(msg);
      }
    });
  }

  private selectFirstProductGrouping(): void {
    this.locationOverrideProductGroups$.pipe(
      startWith(null),
      pairwise(),
      map(([prev, curr]) => {
        const prevId = prev?.firstOrNull()?.id;
        const currId = curr?.firstOrNull()?.id;
        return !prevId && exists(currId) ? currId : null;
      }),
      filter(exists),
    ).subscribeWhileAlive({
      owner: this,
      next: (id) => this.connectToSelectedProductGroupId(id)
    });
  }

  closeRightPanel(): void {
    this.connectToSidePanelShouldOpen(false);
  }

  createProductGrouping(): void {
    const created = (group: OverrideProductGroup) => exists(group) && this.connectToSelectedProductGroupId(group?.id);
    ModalCreateOverrideProductGrouping.open(this.ngZone, this.ngbModal, this.injector, created);
  }

}
