import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { HydratedVariantBadge } from '../models/product/dto/hydrated-variant-badge';
import { SortUtils } from '../utils/sort-utils';
import { catchError, debounceTime, delay, map, switchMap, take, tap } from 'rxjs/operators';
import { VariantBadge } from '../models/product/dto/variant-badge';
import { BudsenseFile } from '../models/shared/budsense-file';
import { GenerateUploadUrlRequest } from '../models/image/requests/generate-upload-url-request';
import { StringUtils } from '../utils/string-utils';
import { UploadFilePath } from '../models/enum/dto/upload-file.path';
import { ProductAPI } from '../api/product-api';
import { ImageAPI } from '../api/image-api';
import { ToastService } from '../services/toast-service';
import { LocationDomainModel } from './location-domain-model';
import { DisplayAttributesDomainModel } from './display-attributes-domain-model';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { CuratedVariantBadgeSection } from '../models/product/dto/curated-variant-badge-section';
import { BsError } from '../models/shared/bs-error';
import { ClonedVariantBadge } from '../models/product/dto/clone-variant-badge';
import { UserDomainModel } from './user-domain-model';

// Provided by Logged In Scope
@Injectable()
export class BadgeDomainModel extends BaseDomainModel {

  constructor(
    private imageAPI: ImageAPI,
    private productAPI: ProductAPI,
    private displayAttributeDomainModel: DisplayAttributesDomainModel,
    private locationDomainModel: LocationDomainModel,
    private userDomainModel: UserDomainModel,
    private toastService: ToastService,
  ) {
    super();
    this.setupBindings();
  }

  // Badges
  private theirBadgesTitle = 'Your Badges';
  private badges: BehaviorSubject<HydratedVariantBadge[]> = new BehaviorSubject<HydratedVariantBadge[]>(null);
  public badges$ = this.badges.pipe(map(badges => badges?.sort(SortUtils.sortBadgesByNameAscending)));
  private _curatedBadges: BehaviorSubject<HydratedVariantBadge[]> = new BehaviorSubject<HydratedVariantBadge[]>(null);
  public curatedBadges$ = this._curatedBadges as Observable<HydratedVariantBadge[]>;
  public curatedBadgeSections$ = this.curatedBadges$.pipe(
    map(curated => this.getBadgeSections(curated)),
    map(badgeSections => badgeSections?.sort(SortUtils.sortCuratedBadgeSections))
  );
  public theirsAndCuratedBadgeSections$ = combineLatest([this.badges, this.curatedBadges$]).pipe(
    map(([theirs, curated]) => {
      if (!theirs && !curated) return null;
      return this.getBadgeSections([...(theirs ?? []), ...(curated ?? [])]);
    }),
    map(badgeSections => badgeSections?.sort(SortUtils.sortTheirsAndCuratedBadgeSections))
  );
  public allBadges$ = combineLatest([
    this.badges,
    this.curatedBadges$
  ]).pipe(
    map(([theirs, curated]) => [...(theirs ?? []), ...(curated ?? [])])
  );

  public createNewBadgeFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  static getUploadBadgeReq(badge: VariantBadge, f: BudsenseFile): GenerateUploadUrlRequest {
    const uploadReq = new GenerateUploadUrlRequest();
    uploadReq.fileName = StringUtils.normalizeCharacters(f.name);
    uploadReq.mediaClass = UploadFilePath.VariantBadgePath;
    uploadReq.mediaType = f.getMediaType();
    uploadReq.metadata = new Map<string, string>();
    uploadReq.metadata.set('CompanyId', badge.companyId.toString());
    uploadReq.metadata.set('Id', badge.id);
    return uploadReq;
  }

  setupBindings() {
    // Bind to Variant Badges
    const badgesSub = combineLatest([
      this.badges$,
      this.userDomainModel.userSession$
    ]).pipe(
      debounceTime(1000),
      switchMap(([badges, session]) => {
        return (!badges && session?.validSession()) ? this.getBadges() : of(badges);
      }),
    ).subscribe();
    this.pushSub(badgesSub);

    // Listen to curated badges
    const subCuratedBadges = combineLatest([
      this.curatedBadges$,
      this.userDomainModel.userSession$
    ]).pipe(
      debounceTime(1000),
      switchMap(([badges, session]) => {
        return (!badges && session?.validSession()) ? this.getCuratedBadges() : of(badges);
      }),
    ).subscribe();
    this.pushSub(subCuratedBadges);
  }

  public getBadges(): Observable<HydratedVariantBadge[]> {
    return this.productAPI.GetVariantBadges().pipe(tap(badges => this.badges.next(badges)));
  }

  public getCuratedBadges(): Observable<HydratedVariantBadge[]> {
    return this.productAPI.GetCuratedBadges().pipe(tap(badges => this._curatedBadges.next(badges)));
  }

  public uploadBadge(req: VariantBadge, f: BudsenseFile): Observable<HydratedVariantBadge> {
    return this.productAPI.WriteVariantBadge(req).pipe(
      switchMap((badge) => {
        const uploadReq = BadgeDomainModel.getUploadBadgeReq(badge, f);
        return this.uploadBadgeHelper(badge, uploadReq, f, true);
      })
    );
  }

  public cloneBadge(req: ClonedVariantBadge) {
    return this.productAPI.WriteVariantBadge(req).pipe(
      map((badge) => {
        const hydratedBadge = window?.injector?.Deserialize.instanceOf(HydratedVariantBadge, badge);
        this.replaceBadge(hydratedBadge);
        return hydratedBadge;
      }),
    );
  }

  public getBadge(id: string): Observable<HydratedVariantBadge> {
    return this.productAPI.GetVariantBadge(id);
  }

  public updateBadge(b: VariantBadge, newImg?: BudsenseFile): Observable<HydratedVariantBadge> {
    return this.productAPI.UpdateVariantBadge(b).pipe(
      switchMap((badge) => {
        if (newImg) {
          const req = BadgeDomainModel.getUploadBadgeReq(badge, newImg);
          return this.uploadBadgeHelper(badge, req, newImg, false);
        }
        this.replaceBadge(badge);
        return of(badge);
      })
    );
  }

  public deleteBadge(b: VariantBadge): Observable<VariantBadge> {
    return this.productAPI.DeleteVariantBadge(b).pipe(
      delay(500),
      map(deleteBadge => {
        const badges = this.badges.getValue();
        const i = badges.findIndex(findB => findB.id === b.id);
        if (i > -1) {
          badges.splice(i, 1);
        }
        this.badges.next(badges);
        return deleteBadge;
      }),
      tap(() => {
        this.displayAttributeDomainModel.loadLocationDisplayAttributes();
        this.displayAttributeDomainModel.loadCompanyDisplayAttributes();
      }),
      catchError(e => {
        this.badges.next(this.badges.getValue());
        return throwError(e);
      })
    );
  }

  private replaceBadge(badge: HydratedVariantBadge) {
    const currentBadges = this.badges.getValue();
    const i = currentBadges.findIndex(cb => cb.id === badge.id);
    if (i > -1) {
      currentBadges.splice(i, 1);
    }
    currentBadges.push(badge);
    this.badges.next(currentBadges);
  }

  private uploadBadgeHelper(
    badge: VariantBadge,
    req: GenerateUploadUrlRequest,
    f: BudsenseFile,
    isCreate: boolean
  ): Observable<HydratedVariantBadge> {
    return this.imageAPI.GenerateUploadUrl(req).pipe(
      switchMap((signedUploadUrl) => {
        return this.imageAPI.PutImageUploadUrl(signedUploadUrl.url, f.url.toString(), req.fileName).pipe(
          // provide delay based on file size
          delay(f.getUploadDelay()),
          switchMap((_) => {
            return this.getBadge(badge.id);
          }),
          tap(updatedBadge => this.updateSmartDisplayAttributesIfNeeded(updatedBadge, isCreate)),
          map(mBadge => {
            this.replaceBadge(mBadge);
            return mBadge;
          })
        );
      })
    );
  }

  private getBadgeSections(badges: HydratedVariantBadge[]): CuratedVariantBadgeSection[] {
    badges?.forEach(b => b.category = b.category || this.theirBadgesTitle);
    const sectionTitles = badges?.map(b => b.category).unique();
    return sectionTitles?.map(title => {
      const s = new CuratedVariantBadgeSection();
      s.title = title;
      const sectionBadges = badges?.filter(b => title === b.category).sort(SortUtils.sortBadgesByNameAscending);
      s.badges = sectionBadges?.filter(b => b.subCategory === '');
      const subSectionTitles = sectionBadges?.map(b => b.subCategory).unique();
      subSectionTitles.forEach(subSectionTitle => {
        if (subSectionTitle) {
          const subSectionBadges = sectionBadges?.filter(b => b.subCategory === subSectionTitle);
          s.subSectionBadges.set(subSectionTitle, subSectionBadges);
        }
      });
      return s;
    });
  }

  private forceSmartBadgeSync(): void {
    this.locationDomainModel.locationId$.pipe(
      take(1),
      switchMap(locationId => this.locationDomainModel.syncSmartDisplayAttributes([locationId.toString()], true)),
    ).subscribe({
      error: (err: BsError) => this.toastService.publishError(err),
      complete: () => this.toastService.publishSuccessMessage(`Smart Badges Synced`, `Success`)
    });
  }

  public updateSmartDisplayAttributesIfNeeded(
    updatedBadge: HydratedVariantBadge,
    newBadge: boolean
  ) {
    this.badges$.pipe(take(1)).subscribe(badges => {
      const originalBadge = badges.find(b => b?.id === updatedBadge?.id) ?? updatedBadge;
      const smartFiltersChanged = this.badgeSmartFiltersHaveChanged(
        originalBadge?.smartFilterIds,
        updatedBadge?.smartFilterIds
      );
      const removeFromExistingOnSyncChanged =
        originalBadge?.removeExistingOnSync !== updatedBadge?.removeExistingOnSync;
      const netNewLabelWithSFProperties = newBadge && updatedBadge?.smartFilterIds.length > 0;
      const shouldSync = smartFiltersChanged || removeFromExistingOnSyncChanged || netNewLabelWithSFProperties;
      if (shouldSync) this.forceSmartBadgeSync();
    });
  }

  private badgeSmartFiltersHaveChanged(a: string[], b: string[]) {
    return a?.sort()?.toString() !== b?.sort()?.toString();
  }

}
