import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModel } from '../base-smart-filter-form-view-model';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../domainModels/cannabinoids-and-terpenes-domain-model';
import { PrimaryCannabinoid } from '../../../../../models/enum/shared/primary-cannabinoid.enum';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnumUtils } from '../../../../../utils/enum-utils';

@Injectable()
export class CannabinoidAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModel {

  constructor(
    public cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel
  ) {
    super();
  }

  public primaryCannabinoids$ = of(EnumUtils.getPrimaryCannabinoids());
  public primaryCannabinoidsWithoutTAC$ = this.primaryCannabinoids$.pipe(
    map(cannabinoids => cannabinoids?.filter(c => c !== PrimaryCannabinoid.TAC)),
  );
  public enabledSecondaryCannabinoids$ = this.cannabinoidsAndTerpenesDomainModel.enabledSecondaryCannabinoidNames$;
  public hasEnabledCannabinoids$ = this.enabledSecondaryCannabinoids$.pipe(
    map(cannabinoids => cannabinoids.length > 0)
  );

  public cannabinoidDropdowns$ = this.cannabinoidsAndTerpenesDomainModel.enabledCannabinoidsWithoutTAC$;

  public cannabinoidPillBackgroundColors$ = combineLatest([
    this.primaryCannabinoidsWithoutTAC$,
    this.enabledSecondaryCannabinoids$,
    window.types.secondaryCannabinoids$
  ]).pipe(
    map(([primaryCannabinoids, enabledCannabinoids, cannabinoids]) => {
      const cannabinoidNames = [
        ...(primaryCannabinoids?.filter(pc => pc !== PrimaryCannabinoid.TAC) || []),
        ...(cannabinoids?.map(c => c?.getSelectionTitle()) || [])
      ];
      const buildColorObject = (pillBackgroundColors: any, cannabinoidName: string) => {
        const cannabinoidIsEnabled = primaryCannabinoids.includes(cannabinoidName as PrimaryCannabinoid)
          || enabledCannabinoids?.includes(cannabinoidName);
        pillBackgroundColors[cannabinoidName] = cannabinoidIsEnabled ? '#222222' : '#FFD74B';
        return pillBackgroundColors;
      };
      return cannabinoidNames.reduce(buildColorObject, {});
    })
  );

  public cannabinoidPillTooltip$ = combineLatest([
    this.primaryCannabinoidsWithoutTAC$,
    this.enabledSecondaryCannabinoids$,
    window.types.secondaryCannabinoids$
  ]).pipe(
    map(([primaryCannabinoids, enabledCannabinoids, cannabinoids]) => {
      const cannabinoidNames = [
        ...(primaryCannabinoids || []),
        ...(cannabinoids?.map(c => c?.getSelectionTitle()) || [])
      ];
      const buildTooltipObject = (pillTooltips: any, cannabinoidName: string) => {
        const cannabinoidIsEnabled = primaryCannabinoids.includes(cannabinoidName as PrimaryCannabinoid)
          || enabledCannabinoids?.includes(cannabinoidName);
        pillTooltips[cannabinoidName] = cannabinoidIsEnabled
          ? ''
          : `${cannabinoidName} is disabled in company cannabinoids. `
            + 'This will not be considered on smart filter matches.';
        return pillTooltips;
      };
      return cannabinoidNames.reduce(buildTooltipObject, {});
    })
  );

}
