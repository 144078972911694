import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, OnDestroy, SimpleChanges, ViewRef } from '@angular/core';
import { ReactiveTableHeaderBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { AllProductsDataTableViewModel } from '../../all-products-data-table-view-model';
import { SortUtils } from '../../../../../utils/sort-utils';
import { combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PriceFormat } from '../../../../../models/enum/dto/price-format';
import { TableBadgeUtils } from '../../../utils/table-badge-utils';
import { StringUtils } from '../../../../../utils/string-utils';
import { ClientTypeUtils } from '../../../../../utils/client-type-utils';
import { PrimaryCannabinoid } from '../../../../../models/enum/shared/primary-cannabinoid.enum';
import type { Product } from 'src/app/models/product/dto/product';
import type { Variant } from '../../../../../models/product/dto/variant';

export type SortFunctions = {
  productSort: (a: Product, b: Product) => number,
  variantSort?: { property: string, sortBy: (a: Variant, b: Variant) => number }
}

@Component({
  selector: 'app-all-products-table-header',
  templateUrl: './all-products-table-header.component.html',
  styleUrls: ['./all-products-table-header.component.scss'],
  providers: [
    {
      provide: ReactiveTableHeaderBluePrintComponent,
      useExisting: forwardRef(() => AllProductsTableHeaderComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllProductsTableHeaderComponent extends ReactiveTableHeaderBluePrintComponent implements OnDestroy {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    public viewModel: AllProductsDataTableViewModel, // global provided by root
  ) {
    super(viewRef);
    this.viewModel.locationId$.pipe(takeUntil(this.onDestroy)).subscribe(locationId => this.locationId = locationId);
    this.viewModel.companyId$.pipe(takeUntil(this.onDestroy)).subscribe(companyId => this.companyId = companyId);
    this.viewModel.priceFormat$.pipe(takeUntil(this.onDestroy)).subscribe(stream => this.priceFormat = stream);
  }

  private locationId: number;
  private companyId: number;
  private priceFormat: PriceFormat;
  private variantProperty = 'variantsFilteredByTable';

  protected readonly ClientTypeUtils = ClientTypeUtils;
  protected readonly PrimaryCannabinoid = PrimaryCannabinoid;

  public cannabinoidAndTerpeneAscSortFuncMap$ = combineLatest([
    this.viewModel.enabledCannabinoidNames$,
    this.viewModel.enabledTerpeneNames$,
  ]).pipe(
    map(([enabledCannabinoids, enabledTerpenes]) => {
      const sortFuncMap = new Map<string, SortFunctions>();
      sortFuncMap.set(
        'Total Terpene',
        {
          productSort: (a: Product, b: Product) => this.productCannabinoidOrTerpeneAsc(a, b, 'totalTerpene')
          // TODO - KFFT - add variantSort when total terpene is added
        }
      );
      enabledCannabinoids?.forEach(cannabinoid => {
        sortFuncMap.set(
          cannabinoid,
          {
            productSort: (a: Product, b: Product) => this.productCannabinoidOrTerpeneAsc(a, b, cannabinoid),
            variantSort: {
              property: this.variantProperty,
              sortBy: (a: Variant, b: Variant) => SortUtils.variantsCannabinoidAsc(a, b, cannabinoid)
            }
          }
        );
      });
      enabledTerpenes?.forEach(terpene => {
        const terpeneCamelCased = StringUtils.toCamelCase(terpene);
        sortFuncMap?.set(
          terpene,
          {
            productSort: (a: Product, b: Product) => this.productCannabinoidOrTerpeneAsc(a, b, terpeneCamelCased),
            variantSort: {
              property: this.variantProperty,
              sortBy: (a: Variant, b: Variant) => SortUtils.variantsTerpeneAsc(a, b, terpeneCamelCased)
            }
          }
        );
      });
      return sortFuncMap;
    })
  );

  public cannabinoidAndTerpeneDescSortFuncMap$ = combineLatest([
    this.viewModel.enabledCannabinoidNames$,
    this.viewModel.enabledTerpeneNames$,
  ]).pipe(
    map(([enabledCannabinoids, enabledTerpenes]) => {
      const sortFuncMap = new Map<string, SortFunctions>();
      sortFuncMap.set(
        'Total Terpene',
        {
          productSort: (a: Product, b: Product) => this.productCannabinoidOrTerpeneDesc(a, b, 'totalTerpene')
          // TODO - KFFT - add variantSort when total terpene is added
        }
      );
      enabledCannabinoids?.forEach(cannabinoid => {
        sortFuncMap.set(
          cannabinoid,
          {
            productSort: (a: Product, b: Product) => this.productCannabinoidOrTerpeneDesc(a, b, cannabinoid),
            variantSort: {
              property: this.variantProperty,
              sortBy: (a: Variant, b: Variant) => SortUtils.variantsCannabinoidDesc(a, b, cannabinoid)
            }
          }
        );
      });
      enabledTerpenes?.forEach(terpene => {
        const terpeneCamelCased = StringUtils.toCamelCase(terpene);
        sortFuncMap?.set(
          terpene,
          {
            productSort: (a: Product, b: Product) => this.productCannabinoidOrTerpeneDesc(a, b, terpeneCamelCased),
            variantSort: {
              property: this.variantProperty,
              sortBy: (a: Variant, b: Variant) => SortUtils.variantsTerpeneDesc(a, b, terpeneCamelCased)
            }
          }
        );
      });
      return sortFuncMap;
    })
  );

  public minCannabinoidAndTerpeneAscSortFuncMap$ = combineLatest([
    this.viewModel.enabledCannabinoidNames$,
    this.viewModel.enabledTerpeneNames$,
  ]).pipe(
    map(([enabledCannabinoids, enabledTerpenes]) => {
      const sortFuncMap = new Map<string, SortFunctions>();
      sortFuncMap.set(
        'Total Terpene',
        {
          productSort: (a: Product, b: Product) => this.productMinCannabinoidOrTerpeneAsc(a, b, 'totalTerpene'),
          // TODO - KFFT - add variantSort when total terpene is added
        }
      );
      enabledCannabinoids?.forEach(cannabinoid => {
        sortFuncMap.set(
          cannabinoid,
          {
            productSort: (a: Product, b: Product) => this.productMinCannabinoidOrTerpeneAsc(a, b, cannabinoid),
            variantSort: {
              property: this.variantProperty,
              sortBy: (a: Variant, b: Variant) => SortUtils.variantsMinCannabinoidOrTerpeneAsc(a, b, cannabinoid)
            }
          }
        );
      });
      enabledTerpenes?.forEach(terpene => {
        const terpeneCamelCased = StringUtils.toCamelCase(terpene);
        const terpenePascalCased = StringUtils.toPascalCase(terpene);
        sortFuncMap?.set(
          terpenePascalCased,
          {
            productSort: (a: Product, b: Product) => this.productMinCannabinoidOrTerpeneAsc(a, b, terpeneCamelCased),
            variantSort: {
              property: this.variantProperty,
              sortBy: (a: Variant, b: Variant) => {
                return SortUtils.variantsMinCannabinoidOrTerpeneAsc(a, b, terpenePascalCased);
              }
            }
          }
        );
      });
      return sortFuncMap;
    })
  );

  public minCannabinoidAndTerpeneDescSortFuncMap$ = combineLatest([
    this.viewModel.enabledCannabinoidNames$,
    this.viewModel.enabledTerpeneNames$,
  ]).pipe(
    map(([enabledCannabinoids, enabledTerpenes]) => {
      const sortFuncMap = new Map<string, SortFunctions>();
      sortFuncMap.set(
        'Total Terpene',
        {
          productSort: (a: Product, b: Product) => this.productMinCannabinoidOrTerpeneDesc(a, b, 'totalTerpene'),
          // TODO - KFFT - add variantSort when total terpene is added
        }
      );
      enabledCannabinoids?.forEach(cannabinoid => {
        sortFuncMap.set(
          cannabinoid,
          {
            productSort: (a: Product, b: Product) => this.productMinCannabinoidOrTerpeneDesc(a, b, cannabinoid),
            variantSort: {
              property: this.variantProperty,
              sortBy: (a: Variant, b: Variant) => SortUtils.variantsMinCannabinoidOrTerpeneDesc(a, b, cannabinoid)
            }
          }
        );
      });
      enabledTerpenes?.forEach(terpene => {
        const terpeneCamelCased = StringUtils.toCamelCase(terpene);
        const terpenePascalCased = StringUtils.toPascalCase(terpene);
        sortFuncMap?.set(
          terpenePascalCased,
          {
            productSort: (a: Product, b: Product) => this.productMinCannabinoidOrTerpeneDesc(a, b, terpeneCamelCased),
            variantSort: {
              property: this.variantProperty,
              sortBy: (a: Variant, b: Variant) => {
                return SortUtils.variantsMinCannabinoidOrTerpeneDesc(a, b, terpenePascalCased);
              }
            }
          }
        );
      });
      return sortFuncMap;
    })
  );

  public maxCannabinoidAndTerpeneAscSortFuncMap$ = combineLatest([
    this.viewModel.enabledCannabinoidNames$,
    this.viewModel.enabledTerpeneNames$,
  ]).pipe(
    map(([enabledCannabinoids, enabledTerpenes]) => {
      const sortFuncMap = new Map<string, SortFunctions>();
      sortFuncMap.set(
        'Total Terpene',
        {
          productSort: (a: Product, b: Product) => this.productMaxCannabinoidOrTerpeneAsc(a, b, 'totalTerpene'),
          // TODO - KFFT - add variantSort when total terpene is added
        }
      );
      enabledCannabinoids?.forEach(cannabinoid => {
        sortFuncMap.set(
          cannabinoid,
          {
            productSort: (a: Product, b: Product) => this.productMaxCannabinoidOrTerpeneAsc(a, b, cannabinoid),
            variantSort: {
              property: this.variantProperty,
              sortBy: (a: Variant, b: Variant) => SortUtils.variantsMaxCannabinoidOrTerpeneAsc(a, b, cannabinoid)
            }
          }
        );
      });
      enabledTerpenes?.forEach(terpene => {
        const terpeneCamelCased = StringUtils.toCamelCase(terpene);
        const terpenePascalCased = StringUtils.toPascalCase(terpene);
        sortFuncMap?.set(
          terpenePascalCased,
          {
            productSort: (a: Product, b: Product) => this.productMaxCannabinoidOrTerpeneAsc(a, b, terpeneCamelCased),
            variantSort: {
              property: this.variantProperty,
              sortBy: (a: Variant, b: Variant) => SortUtils.variantsMaxCannabinoidOrTerpeneAsc(a, b, terpenePascalCased)
            }
          }
        );
      });
      return sortFuncMap;
    })
  );

  public maxCannabinoidAndTerpeneDescSortFuncMap$ = combineLatest([
    this.viewModel.enabledCannabinoidNames$,
    this.viewModel.enabledTerpeneNames$,
  ]).pipe(
    map(([enabledCannabinoids, enabledTerpenes]) => {
      const sortFuncMap = new Map<string, SortFunctions>();
      sortFuncMap.set(
        'Total Terpene',
        {
          productSort: (a: Product, b: Product) => this.productMaxCannabinoidOrTerpeneDesc(a, b, 'totalTerpene'),
          // TODO - KFFT - add variantSort when total terpene is added
        }
      );
      enabledCannabinoids?.forEach(cannabinoid => {
        sortFuncMap.set(
          cannabinoid,
          {
            productSort: (a: Product, b: Product) => this.productMaxCannabinoidOrTerpeneDesc(a, b, cannabinoid),
            variantSort: {
              property: this.variantProperty,
              sortBy: (a: Variant, b: Variant) => SortUtils.variantsMaxCannabinoidOrTerpeneDesc(a, b, cannabinoid)
            }
          }
        );
      });
      enabledTerpenes?.forEach(terpene => {
        const terpeneCamelCased = StringUtils.toCamelCase(terpene);
        const terpenePascalCased = StringUtils.toPascalCase(terpene);
        sortFuncMap?.set(
          terpenePascalCased,
          {
            productSort: (a: Product, b: Product) => this.productMaxCannabinoidOrTerpeneDesc(a, b, terpeneCamelCased),
            variantSort: {
              property: this.variantProperty,
              sortBy: (a: Variant, b: Variant) => {
                return SortUtils.variantsMaxCannabinoidOrTerpeneDesc(a, b, terpenePascalCased);
              }
            }
          }
        );
      });
      return sortFuncMap;
    })
  );

  // Name Sort
  public productNameAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getProductTitle(), b?.getProductTitle());
  };
  public productNameDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getProductTitle(), b?.getProductTitle());
  };
  public variantNameAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.numericStringAsc(a?.getDisplayName(), b?.getDisplayName())
  };
  public variantNameDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.numericStringDesc(a?.getDisplayName(), b?.getDisplayName())
  };

  // Price Sort
  public productPriceAsc = (a: Product, b: Product) => {
    return SortUtils.numberAscNullsLast(
      a?.getLowestPrice(this.locationId, this.companyId, this.priceFormat, false),
      b?.getLowestPrice(this.locationId, this.companyId, this.priceFormat, false)
    );
  };
  public productPriceDesc = (a: Product, b: Product) => {
    return SortUtils.numberDescNullsLast(
      a?.getLowestPrice(this.locationId, this.companyId, this.priceFormat, false),
      b?.getLowestPrice(this.locationId, this.companyId, this.priceFormat, false)
    );
  };
  public variantPriceAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsPriceAsc([a, b])
  };
  public variantPriceDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsPriceDesc([a, b])
  };

  // Secondary Price Sort
  public productSecondaryPriceAsc = (a: Product, b: Product) => {
    return SortUtils.numberAscNullsLast(
      a?.getLowestSecondaryPrice(this.locationId, this.companyId),
      b?.getLowestSecondaryPrice(this.locationId, this.companyId)
    );
  };
  public productSecondaryPriceDesc = (a: Product, b: Product) => {
    return SortUtils.numberDescNullsLast(
      a?.getLowestSecondaryPrice(this.locationId, this.companyId),
      b?.getLowestSecondaryPrice(this.locationId, this.companyId)
    );
  };
  public variantSecondaryPriceAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsSecondaryPriceAsc([a, b], null)
  };
  public variantSecondaryPriceDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsSecondaryPriceDesc([a, b], null)
  };

  // Brand Sort
  public productBrandAsc = (a: Product, b: Product) => SortUtils.numericStringAsc(a?.getBrand(), b?.getBrand());
  public productBrandDesc = (a: Product, b: Product) => SortUtils.numericStringDesc(a?.getBrand(), b?.getBrand());
  public variantBrandAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsBrandAsc(a, b)
  };
  public variantBrandDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsBrandDesc(a, b)
  };

  // Type Sort
  public productTypeAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getProductTypeString(), b?.getProductTypeString());
  };
  public productTypeDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getProductTypeString(), b?.getProductTypeString());
  };
  public variantTypeAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsVariantTypeAsc(a, b)
  };
  public variantTypeDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsVariantTypeDesc(a, b)
  };

  // Strain Sort
  public productStrainAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getStrainClassification(), b?.getStrainClassification());
  };
  public productStrainDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getStrainClassification(), b?.getStrainClassification());
  };
  public variantStrainAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsStrainClassificationAsc(a, b)
  };
  public variantStrainDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsStrainClassificationDesc(a, b)
  };

  // Quantity Sort
  public productQtyAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.getQuantityInStockString(), b?.getQuantityInStockString());
  };
  public productQtyDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.getQuantityInStockString(), b?.getQuantityInStockString());
  };
  public variantQtyAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsStockAsc(a, b)
  };
  public variantQtyDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsStockDesc(a, b)
  };

  // Top Terpene
  public productTopTerpeneAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.activeTopTerpene, b?.activeTopTerpene);
  };
  public productTopTerpeneDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.activeTopTerpene, b?.activeTopTerpene);
  };
  public variantTopTerpeneAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsTopTerpeneAsc(a, b)
  };
  public variantTopTerpeneDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsTopTerpeneDesc(a, b)
  };

  // TAC Sort
  public productTacAsc = (a: Product, b: Product) => {
    const aTAC = a?.variantsFilteredByTable
      ?.map(v => v?.useCannabinoidRange ? v?.activeMinTAC : v?.activeAvgTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTAC = b?.variantsFilteredByTable
      ?.map(v => v?.useCannabinoidRange ? v?.activeMinTAC : v?.activeAvgTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMinCannabinoid = !aTAC?.length ? null : Math.min(...aTAC);
    const bMinCannabinoid = !bTAC?.length ? null : Math.min(...bTAC);
    return SortUtils.numberAscNullsLast(aMinCannabinoid, bMinCannabinoid);
  };
  public productTacDesc = (a: Product, b: Product,) => {
    const aTAC = a?.variantsFilteredByTable
      ?.map(v => v?.useCannabinoidRange ? v?.activeMaxTAC : v?.activeAvgTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTAC = b?.variantsFilteredByTable
      ?.map(v => v?.useCannabinoidRange ? v?.activeMaxTAC : v?.activeAvgTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMaxCannabinoid = !aTAC?.length ? null : Math.max(...aTAC);
    const bMaxCannabinoid = !bTAC?.length ? null : Math.max(...bTAC);
    return SortUtils.numberDescNullsLast(aMaxCannabinoid, bMaxCannabinoid);
  };
  public variantTacAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsTacAsc(a, b)
  };
  public variantTacDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsTacDesc(a, b)
  };

  // Max TAC Sort
  public productMaxTacAsc = (a: Product, b: Product) => {
    const aTAC = a?.variantsFilteredByTable
      ?.map(v => v?.activeMaxTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTAC = b?.variantsFilteredByTable
      ?.map(v => v?.activeMaxTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMinCannabinoid = !aTAC?.length ? null : Math.max(...aTAC);
    const bMinCannabinoid = !bTAC?.length ? null : Math.max(...bTAC);
    return SortUtils.numberAscNullsLast(aMinCannabinoid, bMinCannabinoid);
  };
  public productMaxTacDesc = (a: Product, b: Product) => {
    const aTAC = a?.variantsFilteredByTable
      ?.map(v => v?.activeMaxTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTAC = b?.variantsFilteredByTable
      ?.map(v => v?.activeMaxTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMaxCannabinoid = !aTAC?.length ? null : Math.max(...aTAC);
    const bMaxCannabinoid = !bTAC?.length ? null : Math.max(...bTAC);
    return SortUtils.numberDescNullsLast(aMaxCannabinoid, bMaxCannabinoid);
  };
  public variantMaxTacAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsMaxTacAsc(a, b)
  };
  public variantMaxTacDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsMaxTacDesc(a, b)
  };

  // Min TAC Sort
  public productMinTacAsc = (a: Product, b: Product) => {
    const aTAC = a?.variantsFilteredByTable
      ?.map(v => v?.activeMinTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTAC = b?.variantsFilteredByTable
      ?.map(v => v?.activeMinTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMinCannabinoid = !aTAC?.length ? null : Math.min(...aTAC);
    const bMinCannabinoid = !bTAC?.length ? null : Math.min(...bTAC);
    return SortUtils.numberAscNullsLast(aMinCannabinoid, bMinCannabinoid);
  };
  public productMinTacDesc = (a: Product, b: Product,) => {
    const aTAC = a?.variantsFilteredByTable
      ?.map(v => v?.activeMinTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bTAC = b?.variantsFilteredByTable
      ?.map(v => v?.activeMinTAC)
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMaxCannabinoid = !aTAC?.length ? null : Math.min(...aTAC);
    const bMaxCannabinoid = !bTAC?.length ? null : Math.min(...bTAC);
    return SortUtils.numberDescNullsLast(aMaxCannabinoid, bMaxCannabinoid);
  };
  public variantMinTacAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsMinTacAsc(a, b)
  };
  public variantMinTacDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.variantsMinTacDesc(a, b)
  };

  // Cannabinoid and Terpene Sort
  public productCannabinoidOrTerpeneAsc = (
    a: Product,
    b: Product,
    cannabinoidOrTerpeneCamelCased: string
  ) => {
    const aCannabinoidOrTerpene = a?.variantsFilteredByTable
      ?.map(v => {
        return v?.useCannabinoidRange
          ? v?.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
          : v?.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased);
      })
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bCannabinoidOrTerpene = b?.variantsFilteredByTable
      ?.map(v => {
        return v?.useCannabinoidRange
          ? v?.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
          : v?.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased);
      })
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMinCannabinoidOrTerpene = !aCannabinoidOrTerpene?.length ? null : Math.min(...aCannabinoidOrTerpene);
    const bMinCannabinoidOrTerpene = !bCannabinoidOrTerpene?.length ? null : Math.min(...bCannabinoidOrTerpene);
    return SortUtils.numberAscNullsLast(aMinCannabinoidOrTerpene, bMinCannabinoidOrTerpene);
  };
  public productCannabinoidOrTerpeneDesc = (
    a: Product,
    b: Product,
    cannabinoidOrTerpeneCamelCased: string
  ) => {
    const aCannabinoidOrTerpene = a?.variantsFilteredByTable
      ?.map(v => {
        return v?.useCannabinoidRange
          ? v?.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
          : v?.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased);
      })
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const bCannabinoidOrTerpene = b?.variantsFilteredByTable
      ?.map(v => {
        return v?.useCannabinoidRange
          ? v?.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
          : v?.getNumericCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased);
      })
      ?.filter(value => Number.isFinite(value) && value >= 0) || [];
    const aMaxCannabinoidOrTerpene = !aCannabinoidOrTerpene?.length ? null : Math.max(...aCannabinoidOrTerpene);
    const bMaxCannabinoidOrTerpene = !bCannabinoidOrTerpene?.length ? null : Math.max(...bCannabinoidOrTerpene);
    return SortUtils.numberDescNullsLast(aMaxCannabinoidOrTerpene, bMaxCannabinoidOrTerpene);
  };

  // Min Cannabinoid Or Terpene Sort
  public productMinCannabinoidOrTerpeneAsc = (
    a: Product,
    b: Product,
    cannabinoidOrTerpeneCamelCased: string
  ) => {
    const getFilteredMinValues = (product: Product) => {
      const filteredValues = product?.variantsFilteredByTable
        ?.map(v => v?.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased))
        ?.filter(value => Number.isFinite(value) && value >= 0);
      return !filteredValues?.length ? null : Math.min(...filteredValues);
    };
    const aMinCannabinoidOrTerpene = getFilteredMinValues(a);
    const bMinCannabinoidOrTerpene = getFilteredMinValues(b);
    return SortUtils.numberAscNullsLast(aMinCannabinoidOrTerpene, bMinCannabinoidOrTerpene);
  };
  public productMinCannabinoidOrTerpeneDesc = (
    a: Product,
    b: Product,
    cannabinoidOrTerpeneCamelCased: string
  ) => {
    const getFilteredMinValues = (product: Product) => {
      const filteredValues = product?.variantsFilteredByTable
        ?.map(v => v?.getNumericMinCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased))
        ?.filter(value => Number.isFinite(value) && value >= 0);
      return !filteredValues?.length ? null : Math.min(...filteredValues);
    };
    const aMinCannabinoidOrTerpene = getFilteredMinValues(a);
    const bMinCannabinoidOrTerpene = getFilteredMinValues(b);
    return SortUtils.numberDescNullsLast(aMinCannabinoidOrTerpene, bMinCannabinoidOrTerpene);
  };

  // Max Cannabinoid and Terpene Sort
  public productMaxCannabinoidOrTerpeneAsc = (
    a: Product,
    b: Product,
    cannabinoidOrTerpeneCamelCased: string
  ) => {
    const getFilteredMaxValues = (product: Product) => {
      const filteredValues = product?.variantsFilteredByTable
        ?.map(v => v?.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased))
        ?.filter(value => Number.isFinite(value) && value >= 0);
      return !filteredValues?.length ? null : Math.max(...filteredValues);
    };
    const aMaxCannabinoidOrTerpene = getFilteredMaxValues(a);
    const bMaxCannabinoidOrTerpene = getFilteredMaxValues(b);
    return SortUtils.numberAscNullsLast(aMaxCannabinoidOrTerpene, bMaxCannabinoidOrTerpene);
  };
  public productMaxCannabinoidOrTerpeneDesc = (
    a: Product,
    b: Product,
    cannabinoidOrTerpeneCamelCased: string
  ) => {
    const getFilteredMaxValues = (product: Product) => {
      const filteredValues = product?.variantsFilteredByTable
        ?.map(v => v?.getNumericMaxCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased))
        ?.filter(value => Number.isFinite(value) && value >= 0);
      return !filteredValues?.length ? null : Math.max(...filteredValues);
    };
    const aMaxCannabinoidOrTerpene = getFilteredMaxValues(a);
    const bMaxCannabinoidOrTerpene = getFilteredMaxValues(b);
    return SortUtils.numberDescNullsLast(aMaxCannabinoidOrTerpene, bMaxCannabinoidOrTerpene);
  };

  // Label Sort
  public productLabelAsc = (a: Product, b: Product) => SortUtils.numericStringAsc(
    a?.computedLabelTextForProductSearch,
    b?.computedLabelTextForProductSearch
  );
  public productLabelDesc = (a: Product, b: Product) => SortUtils.numericStringDesc(
    a?.computedLabelTextForProductSearch,
    b?.computedLabelTextForProductSearch
  );
  public variantLabelAsc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.numericStringAsc(
      a?.computedLabelForProductTable?.text,
      b?.computedLabelForProductTable?.text
    )
  };
  public variantLabelDesc = {
    property: this.variantProperty,
    sortBy: (a: Variant, b: Variant) => SortUtils.numericStringDesc(
      a?.computedLabelForProductTable?.text,
      b?.computedLabelForProductTable?.text
    )
  };

  // Badge Sort
  public productBadgeAsc = (a: Product, b: Product) => SortUtils.sortBadgesByNameAscending(
    TableBadgeUtils.getAllBadgesForVariants(a?.variantsFilteredByTable)?.firstOrNull(),
    TableBadgeUtils.getAllBadgesForVariants(b?.variantsFilteredByTable)?.firstOrNull(),
  );
  public productBadgeDesc = (a: Product, b: Product) => SortUtils.sortBadgesByNameDescending(
    TableBadgeUtils.getAllBadgesForVariants(a?.variantsFilteredByTable)?.firstOrNull(),
    TableBadgeUtils.getAllBadgesForVariants(b?.variantsFilteredByTable)?.firstOrNull(),
  );

  // Override Group Sort
  public overrideGroupAsc = (a: Product, b: Product) => {
    return SortUtils.numericStringAsc(a?.overrideGroupName, b?.overrideGroupName);
  };
  public overrideGroupDesc = (a: Product, b: Product) => {
    return SortUtils.numericStringDesc(a?.overrideGroupName, b?.overrideGroupName);
  };

  initializeFromBluePrint(bluePrint: ReactiveTableHeaderBluePrintComponent): void {
  }

  changesFromBluePrint(changes: SimpleChanges): void {
  }

  sendOutputsToBluePrint(bluePrint: ReactiveTableHeaderBluePrintComponent): void {
  }

}
