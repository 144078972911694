<app-loading *ngIf="viewModel?.loadingOpts?.isLoading" [options]="viewModel?.loadingOpts"> </app-loading>

<div
  #modalBody
  [style.height.px]="modalBodyHeight$ | async"
  [style.max-height.px]="modalBodyHeight$ | async"
  class="modal-body pb-0 flex-container flex-column relative">
  <div #header>
    <div class="modal-header no-bottom-border">
      <div class="modal-title">
        {{ title }}
      </div>
      <div [hidden]="!subtitle" class="modal-subtitle-container">
        <div class="modal-subtitle inline-subtitle">
          {{ subtitle?.[0] }} <span> {{ subtitle?.[1] }} </span>
        </div>
      </div>
    </div>
  </div>

  <!--  Data Table  -->
  <app-product-picker-data-table
    #productPickerDataTableComponent
    [products]="viewModel.products$ | async"
    [headerHeight]="headerHeight$ | async"
    [flattenAndThenRemoveProductGroupings]="viewModel.flattenAndThenRemoveProductGroups$ | async"
    [hideLabelAndBadgeColumn]="viewModel.hideLabelAndBadgeColumn$ | async"
    [showCurrentOverrideGroupColumn]="viewModel.showCurrentOverrideGroupColumn$ | async"
    [productSelectionOnly]="viewModel.productSelectionOnly$ | async"
    (loadingVariants)="loadingVariants($event)"
    (clearExternalFilters)="productPickerDataTableComponent.clearSearchAndFilters()">
  </app-product-picker-data-table>

  <div
    #bottomButtons
    [style.position]="bottomButtonPosition$ | async"
    class="sticky-footer-container modal-footer-flex-end product-picker-footer">
    <button (click)="cancel()" [ngClass]="options.cancelButtonClass" class="bs-button">
      {{ options.cancelText }}
    </button>
    <button
      appThrottleClick
      (throttleClick)="addProducts()"
      [disabled]="!(productPickerDataTableComponent.idsToBeAdded$ | async)?.length"
      [class.disabled]="!(productPickerDataTableComponent.idsToBeAdded$ | async)?.length"
      class="bs-button add-product-button {{ options.confirmButtonClass }}">
      {{ options.confirmText }}
    </button>
  </div>
</div>
