import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { Menu } from '../models/menu/dto/menu';
import { ModalUtils } from '../utils/modal-utils';
import { PrintMenuLiveViewModalComponent } from '../views/live-view/print-menus/print-menu-live-view-modal/print-menu-live-view-modal.component';

export class ModalPrintMenuLiveView {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    printMenu: Menu
  ) {
    ngZone.run(() => {
      const modalRef = ngbModal.open(
        PrintMenuLiveViewModalComponent,
        ModalUtils.printMenuLiveViewOptions(injector, printMenu?.displaySize?.orientation)
      );
      const ref = (modalRef.componentInstance as PrintMenuLiveViewModalComponent);
      ref.viewModel.connectToPrintMenu(printMenu);
    });
  }

}
