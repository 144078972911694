import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { DuplicateMenuModalComponent } from '../views/menu/components/modals/duplicate-menu-modal/duplicate-menu-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Menu } from '../models/menu/dto/menu';

export class ModalDuplicateMenu {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    menu: Menu,
    navigateToDuplicatedMenu: boolean = true
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(DuplicateMenuModalComponent, ModalUtils.duplicateMenuModalOptions(injector));
      const compInst = modalRef.componentInstance as DuplicateMenuModalComponent;
      compInst.connectToMenu(menu);
      compInst.connectToNavigateToDuplicatedMenu(navigateToDuplicatedMenu);
    });
  }

}
