import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DisplayableItemListComponent } from '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component';
import { PrintLabelsViewModel } from '../print-labels-view-model';

@Component({
  selector: 'app-print-labels-list',
  templateUrl: '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.html',
  styleUrls: [
    '../../../../../../shared/components/displayable-content/displayable-item-list/displayable-item-list.component.scss',
    './print-labels-list.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintLabelsListComponent extends DisplayableItemListComponent {

  constructor(
    printLabelsViewModel: PrintLabelsViewModel
  ) {
    super(printLabelsViewModel);
  }

}
