// noinspection JSUnusedLocalSymbols
import { EventEmitter, Injectable } from '@angular/core';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Tag } from '../../../../../../models/menu/dto/tag';
import { Theme } from '../../../../../../models/menu/dto/theme';
import { ProductMenuType } from '../../../../../../models/enum/dto/product-menu-type.enum';
import { Orientation } from '../../../../../../models/utils/dto/orientation-type';
import { MenuType, MenuTypeDefinition } from '../../../../../../models/utils/dto/menu-type-definition';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { MenuSubtype } from '../../../../../../models/enum/dto/menu-subtype';
import { CompanyDomainModel } from '../../../../../../domainModels/company-domain-model';
import { iiif } from '../../../../../../utils/observable.extensions';
import { DefaultPrintStackSize } from '../../../../../../models/enum/dto/default-print-stack-size';
import { SortUtils } from '../../../../../../utils/sort-utils';

@Injectable()
export class ThemePickerViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private menuDomainModel: MenuDomainModel,
  ) {
    super();
  }

  public companyId$ = this.companyDomainModel.companyId$;

  private _orientation = new BehaviorSubject<Orientation>(Orientation.Portrait);
  public orientation$ = this._orientation as Observable<Orientation>;

  private _tags = new BehaviorSubject<Tag[]>(null);
  public tagsDelimited$: Observable<string> = this._tags.pipe(
    map(x => x?.map(x => x.title)?.filterFalsies()?.join(','))
  );

  private _productMenuType = new BehaviorSubject<MenuType>(MenuType.DisplayMenu);
  public productMenuType$ = this._productMenuType as Observable<MenuType>;

  private _productMenuSubTypes = new BehaviorSubject<MenuSubtype[]>([ProductMenuType.ProductMenu]);
  public productMenuSubTypes$ = this._productMenuSubTypes as Observable<MenuSubtype[]>;

  private _templateMode = new BehaviorSubject<boolean>(false);
  public templateMode$ = this._templateMode as Observable<boolean>;

  public themesFilteredByPrivateAndMenuType$ = combineLatest([
    this.companyId$,
    this.menuDomainModel.menuThemes$,
    this.productMenuType$,
    this.productMenuSubTypes$
  ]).pipe(
    map(([companyId, themes, type, productMenuTypes]) => {
      return themes
        ?.filter(theme => {
          const privateTheme = theme?.isPrivate();
          const privateAndHasAccess = privateTheme && theme?.companyHasAccess(companyId);
          return !privateTheme || privateAndHasAccess;
        })
        ?.filter(theme => theme.supportedMenuTypes.includes(type))
        ?.filter(theme => productMenuTypes?.includes(theme?.menuSubType)) ?? [];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public stackSizesSupportedByThemes$ = combineLatest([
    this.companyId$,
    this.themesFilteredByPrivateAndMenuType$
  ]).pipe(
    map(([companyId, themes]) => {
      const stackSizes = themes
        ?.flatMap(theme => theme?.printConfig?.printCardSizes as DefaultPrintStackSize[])
        ?.unique();
      const privateSizes = themes
        ?.filter(theme => theme?.isPrivate() && theme?.companyHasAccess(companyId))
        ?.flatMap(theme => theme?.printConfig?.printCardSizes as DefaultPrintStackSize[])
        ?.unique();
      return stackSizes?.sort((a, b) => SortUtils.sortPrintCardSizes(a, b, privateSizes));
    }),
  );

  public readonly containsStackedContent$ = this.productMenuType$.pipe(
    map(menuType => MenuTypeDefinition.containsStackedContent(menuType))
  );

  // Form
  public themeSelected$ = new BehaviorSubject<Theme>(null);
  public showNewTagInput$ = new BehaviorSubject<boolean>(false);
  public newTagClicked = new EventEmitter<any>();
  public useExistingTagClicked = new EventEmitter<any>();

  private _selectedStackSize = new BehaviorSubject<DefaultPrintStackSize>(null);
  public selectedStackSize$ = this._selectedStackSize as Observable<DefaultPrintStackSize>;

  public themesSupportedBySelectedStackSize$ = combineLatest([
    this.themesFilteredByPrivateAndMenuType$,
    this.selectedStackSize$,
  ]).pipe(
    map(([themes, cardStackSize]) => {
      return themes?.filter(t => t?.printConfig?.printCardSizes?.includes(cardStackSize));
    })
  );

  public themeSelectionOptions$ = iiif(
    this.containsStackedContent$,
    this.themesSupportedBySelectedStackSize$,
    this.themesFilteredByPrivateAndMenuType$
  );

  connectToProductMenuType(type: MenuType) {
    this._productMenuType.next(type);
  }

  connectToProductMenuSubTypes(type: MenuSubtype[]) {
    this._productMenuSubTypes.next(type);
  }

  connectToTags(tags: Tag[]) {
    this._tags.next(tags);
  }

  connectToTemplateMode(templateMode: boolean) {
    this._templateMode.next(templateMode);
  }

  stackSizeSelected(csSize: DefaultPrintStackSize) {
    if (csSize) {
      this._selectedStackSize.next(csSize);
    }
  }

  themeSelected(t: Theme) {
    if (t) {
      this.themeSelected$.next(t);
    }
  }

  orientationSelected(o: Orientation) {
    if (o) {
      this._orientation.next(o);
    }
  }

  toggleShowNewTagInput(show: boolean) {
    this.showNewTagInput$.next(show);
  }

}
