import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { EditVariantPricingViewModel } from './edit-variant-pricing-view-model';

@Component({
  selector: 'app-edit-variant-pricing',
  templateUrl: './edit-variant-pricing.component.html',
  styleUrls: ['./edit-variant-pricing.component.scss'],
  providers: [EditVariantPricingViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditVariantPricingComponent extends BaseComponent implements Tabbable {

  constructor(
    public viewModel: EditVariantPricingViewModel,
  ) {
    super();
  }

  tabActivated: BehaviorSubject<boolean>;

}
