<app-loading *rxIf="viewModel.isLoading$ | push" [options]="viewModel.loadingOpts$ | push"> </app-loading>

<div #modalBody class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">{{ viewModel.makeActiveModalTitle$ | push }}</div>
    </div>
  </div>

  <div class="sticky-body-container">
    {{ viewModel.makeActiveModalCopy$ | push }}
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <lib-button-neutral class="mr-8px" (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
    <lib-button-neutral
      *rxIf="viewModel.canMakeActiveForAllLocations$ | push"
      class="mx-2"
      (buttonClicked)="viewModel.makeVariantActiveForAllLocations()">
      All Locations
    </lib-button-neutral>
    <lib-button-primary class="ml-8px" (buttonClicked)="viewModel.makeVariantActiveForThisLocation()">
      {{ viewModel.singleLocationMakeActiveButtonText$ | push }}
    </lib-button-primary>
  </div>
</div>
