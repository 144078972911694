<!-- Primary Header -->
<app-title-and-crumbs [headerText]="'Edit Template - Shelf Talker Brands Stack'" [crumbs]="crumbs">
  <lib-button-primary
    *ngIf="viewModel.showPublishButton$ | async"
    [disabled]="(viewModel.autoSaving$ | async) || formHasErrors || unsavedChanges"
    [disabledTooltip]="
      (formErrorMessages | getFormErrorDisabledTooltip) || (unsavedChanges | getSaveTemplateBeforePublishingTooltip)
    "
    (buttonClicked)="viewModel.promptToPublishTemplate()">
    Publish Template
  </lib-button-primary>
</app-title-and-crumbs>

<!-- Secondary Header -->
<div class="template-secondary-header">
  <div class="left-side-of-header">
    <app-template-name>{{ viewModel.templateName$ | async }}</app-template-name>
    <app-template-status [templateStatus]="viewModel.templateStatus$ | async"></app-template-status>
    <app-template-info-blurb>
      Create consistent templates that can be deployed to select or all locations.
    </app-template-info-blurb>
  </div>
  <div class="right-side-of-header">
    <div fxLayout="row" fxLayoutAlign="end start" [style.gap.rem]="1">
      <lib-button-neutral
        [iconSrc]="'assets/icons/dark/outline/eye.svg'"
        [disabled]="!(viewModel.allowLiveView$ | async) || formHasErrors || !formPristine"
        [disabledTooltip]="(formErrorMessages | getFormErrorDisabledTooltip) || (formPristine | saveChangesTooltip)"
        (buttonClicked)="viewModel.showLiveView(formPristine, viewModel.formAutoSubmitted$, submitForms)">
        Live View
      </lib-button-neutral>
      <lib-button-primary
        [infoText]="viewModel.lastAutoSavedTimeText$ | async"
        [disabled]="(viewModel.autoSaving$ | async) || formDisableSubmit"
        [disabledTooltip]="formErrorMessages | getFormErrorDisabledTooltip"
        [loading]="viewModel.autoSaving$ | async"
        (buttonClicked)="submitForms.emit(false)">
        Save Changes
      </lib-button-primary>
    </div>
  </div>
</div>
