<lib-reactive-form-group [bindTo]="viewModel.formColumnConfig$ | push : 'immediate'" [mergeKey]="formMergeKey">
  <lib-reactive-form-column-layout nColumns="2">
    <lib-reactive-form-drop-down
      #columnDefaultState
      [inputName]="'DefaultState' | randomString"
      [label]="'Column State'"
      [placeholder]="viewModel.defaultStatePlaceholder$ | push : 'immediate'"
      [bindingProperty]="'defaultState'"
      [dropdowns]="viewModel.sectionDefaultStateOptionsWithUnknown$ | push : 'immediate'"
      [tooltip]="viewModel.tooltip$ | push : 'immediate'"
      [disabled]="viewModel.defaultStateInputDisabled$ | push : 'immediate'"
      [programmaticallyChangeValue]="viewModel.columnConfigDefaultState$ | push : 'immediate'"
      (valueChanged)="changeToDefaultState($event)">
    </lib-reactive-form-drop-down>

    <div [hidden]="viewModel.hideColumnName$ | push : 'immediate'">
      <lib-reactive-form-text
        #columnName
        [inputName]="'ColumnName' | randomString"
        [label]="'Column Name'"
        [placeholder]="viewModel.columnNamePlaceHolder$ | push : 'immediate'"
        [bindingProperty]="'columnName'"
        [disabled]="viewModel.inputDisabled$ | push : 'immediate'">
      </lib-reactive-form-text>
    </div>

    <lib-reactive-form-drop-down
      #columnDataValue
      *rxIf="viewModel.showColumnDataOptions$ | push : 'immediate'"
      [inputName]="'DataValue' | randomString"
      [label]="viewModel.columnDataOptionsLabel$ | push : 'immediate'"
      [placeholder]="viewModel.useValuePlaceholder$ | push : 'immediate'"
      [bindingProperty]="'dataValue'"
      [dropdowns]="viewModel.columnDataOptionsDropdowns$ | push : 'immediate'"
      [disabled]="viewModel.inputDisabled$ | push : 'immediate'"
      [tooltipModalTitle]="viewModel.columnDataOptionsToolTipModalTitle$ | push : 'immediate'"
      [tooltipModalInfoItems]="viewModel.columnDataOptionsToolTipModalItems$ | push : 'immediate'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-number
      #columnWidth
      [inputName]="'ColumnWidth' | randomString"
      [label]="'Column Width'"
      [placeholder]="viewModel.columnWidthPlaceholder$ | push : 'immediate'"
      [bindingProperty]="'columnWidth'"
      [integersOnly]="true"
      [minValue]="viewModel.minWidth$ | push : 'immediate'"
      [maxValue]="viewModel.maxWidth$ | push : 'immediate'"
      [tooltip]="'Will be validated by the lower and upper bounds set on the theme config'"
      [disabled]="viewModel.inputDisabled$ | push : 'immediate'"
      [required]="viewModel.widthRequired$ | push : 'immediate'"
      (valueChanged)="changeToColumnWidth($event)">
    </lib-reactive-form-number>
  </lib-reactive-form-column-layout>
</lib-reactive-form-group>
