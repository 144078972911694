import { WebSocketSendRequestDataMessage } from './web-socket-send-request-data-message';
import { exists } from '../../../../functions/exists';

/**
 * Can fetch all the displays for a location, or a specific display.
 *
 * Get all displays for a location:
 * {
 *     "action": "DATA_REQUEST",
 *     "entityId": 420420,
 *     "payload": {
 *         "companyId": 170479,
 *         "locationId": 420420,
 *         "requestId": "asd123",
 *         "userId": "user-id-here",
 *         "dataType": "WebsocketDataRequestType_GetLocationDisplays",
 *         "metadata": {
 *
 *         }
 *     }
 * }
 *
 * Get a specific display:
 * {
 *     "action": "DATA_REQUEST",
 *     "entityId": 420420,
 *     "payload": {
 *         "companyId": 170479,
 *         "locationId": 420420,
 *         "requestId": "asd123",
 *         "userId": "user-id-here",
 *         "dataType": "WebsocketDataRequestType_GetDisplay",
 *         "metadata": {
 *             "DisplayId": "display-id-here",
 *             "IgnoreLastSession": "false",
 *             "LastModified": "0",
 *             "Source": "Display"
 *         }
 *     }
 * }
 */
export class WebSocketRequestDisplays extends WebSocketSendRequestDataMessage {

  constructor(
    userId: string,
    companyId: number,
    locationId: number,
    displayId?: string,
    ignoreLastSession?: boolean,
    lastModified?: number
  ) {
    super(locationId);
    this.payload.companyId = companyId;
    this.payload.locationId = locationId;
    this.payload.userId = userId;
    this.payload.dataType = 'WebsocketDataRequestType_GetLocationDisplays';
    if (exists(displayId)) {
      const IgnoreLastSession = ignoreLastSession !== undefined && ignoreLastSession !== null
        ? ignoreLastSession.toString()
        : 'false';
      const LastModified = lastModified !== undefined && lastModified !== null
        ? lastModified.toString()
        : '0';
      this.payload.dataType = 'WebsocketDataRequestType_GetDisplay';
      this.payload.metadata = {
        DisplayId: displayId,
        IgnoreLastSession,
        LastModified,
        Source: 'Dashboard'
      };
    }
  }

}
