import { Injector, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditPrintStackStyleComponent } from '../views/menu/components/edit-menu/edit-card-stack-menu/modals/edit-print-card-style/edit-print-stack-style.component';
import { ModalUtils } from '../utils/modal-utils';
import { Menu } from '../models/menu/dto/menu';

export class ModalEditPrintCardStyle {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    stackMenu: Menu
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(EditPrintStackStyleComponent, ModalUtils.defaultModalOptions(injector));
      const compInstance = modalRef.componentInstance as EditPrintStackStyleComponent;
      compInstance.viewModel.connectTStackMenu(stackMenu);
    });
  }

}
