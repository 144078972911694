import { FilterProductsFormViewModel } from '../../../../shared/components/filter-products-form/filter-products-form-view-model';
import { Injectable } from '@angular/core';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { Product } from '../../../../../models/product/dto/product';
import { Variant } from '../../../../../models/product/dto/variant';
import { ProductDomainModel } from '../../../../../domainModels/product-domain-model';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../domainModels/cannabinoids-and-terpenes-domain-model';

// Provided by Logged In Scope
@Injectable()
export class OrderReceivingFilterFormViewModel extends FilterProductsFormViewModel {

  constructor(
    companyDomainModel: CompanyDomainModel,
    locationDomainModel: LocationDomainModel,
    productsDomainModel: ProductDomainModel,
    cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel
  ) {
    super(companyDomainModel, locationDomainModel, productsDomainModel, cannabinoidsAndTerpenesDomainModel);
  }

  /**
   * Splice the pipe to use variantsFilteredByLastNDays instead of variants, because variantsFilteredByLastNDays
   * is the chain link above variantsFilteredByMedAndRec in the order receiving pipeline.
   * Order receiving pipeline: variants -> variantsFilteredByLastNDays -> variantsFilteredByMedAndRec -> etc
   */
  protected override getVariantsFilteredByMedAndRec(p: Product, filterByUse: (v: Variant) => boolean): Variant[] {
    return filterByUse ? p?.variantsFilteredByLastNDays?.filter(filterByUse) : p?.variantsFilteredByLastNDays;
  }

}
