<app-loading *ngIf="viewModel.isLoading$ | push" [options]="viewModel.loadingOpts$ | push"> </app-loading>

<lib-reactive-form-merge-groups
  #formCapture
  [mergeKey]="'smart-filter-form'"
  [triggerManualObjectHydration]="viewModel.hydrateObject$ | push"
  [atLeastNItemsFilledIn]="1"
  (canSubmit$)="viewModel.connectToCanSubmit($event); viewModel.regenerateSmartFilter(formCapture.allPristine$)"
  (hasErrors$)="viewModel.connectToHasErrors($event)"
  (formsSubmitted$)="viewModel.saveChanges()"
  (manualHydrationResult$)="viewModel.setFormObject($event)">
</lib-reactive-form-merge-groups>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{ viewModel.modalTitle$ | push }}
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <div *ngIf="viewModel.isViewOnly$ | push" class="mt-3">
      <app-message-banner [message]="viewModel?.viewOnlyBanner$ | push"> </app-message-banner>
    </div>
    <lib-reactive-form-group
      [bindTo]="
        (viewModel.isEditing$ | push) ? (viewModel.existingSmartFilter$ | push) : (viewModel?.smartFilterReq$ | push)
      "
      [dispersedKey]="'smart-filter-dispersed'"
      [mergeKey]="'smart-filter-form'">
      <div>
        <app-basic-add-edit-smart-filter-form
          [bindTo]="
            (viewModel.isEditing$ | push)
              ? (viewModel.existingSmartFilter$ | push)
              : (viewModel?.smartFilterReq$ | push)
          "
          [dispersedKey]="'smart-filter-dispersed'"
          [isEditing]="viewModel.isEditing$ | push"
          [isViewOnly]="viewModel.isViewOnly$ | push"
          [modalOpenedFrom]="viewModel.openedFrom$ | push">
        </app-basic-add-edit-smart-filter-form>

        <mat-accordion multi [displayMode]="'flat'">
          <mat-expansion-panel #panel class="mat-elevation-z0" [expanded]="viewModel.openProductAccordion$ | push">
            <mat-expansion-panel-header class="no-background">
              <mat-panel-title class="accordion-title"> Product Filters </mat-panel-title>
            </mat-expansion-panel-header>
            <app-product-add-edit-smart-filter-form
              [bindTo]="
                (viewModel.isEditing$ | push)
                  ? (viewModel.existingSmartFilter$ | push)
                  : (viewModel?.smartFilterReq$ | push)
              "
              [isEditing]="viewModel.isEditing$ | push"
              [dispersedKey]="'smart-filter-dispersed'"
              [isViewOnly]="viewModel.isViewOnly$ | push"
              [modalOpenedFrom]="viewModel.openedFrom$ | push">
            </app-product-add-edit-smart-filter-form>
          </mat-expansion-panel>
        </mat-accordion>
        <hr />

        <mat-accordion multi [displayMode]="'flat'">
          <mat-expansion-panel #panel class="mat-elevation-z0" [expanded]="viewModel.openCannabinoidAccordion$ | push">
            <mat-expansion-panel-header class="no-background">
              <mat-panel-title class="accordion-title"> Cannabinoid Filters </mat-panel-title>
            </mat-expansion-panel-header>
            <app-cannabinoid-add-edit-smart-filter-form
              [bindTo]="
                (viewModel.isEditing$ | push)
                  ? (viewModel.existingSmartFilter$ | push)
                  : (viewModel?.smartFilterReq$ | push)
              "
              [isEditing]="viewModel.isEditing$ | push"
              [dispersedKey]="'smart-filter-dispersed'"
              [isViewOnly]="viewModel.isViewOnly$ | push"
              [modalOpenedFrom]="viewModel.openedFrom$ | push">
            </app-cannabinoid-add-edit-smart-filter-form>
          </mat-expansion-panel>
        </mat-accordion>
        <hr />

        <mat-accordion multi [displayMode]="'flat'">
          <mat-expansion-panel #panel class="mat-elevation-z0" [expanded]="viewModel.openTerpeneAccordion$ | push">
            <mat-expansion-panel-header class="no-background">
              <mat-panel-title class="accordion-title"> Terpene Filters </mat-panel-title>
            </mat-expansion-panel-header>
            <app-terpene-add-edit-smart-filter-form
              [bindTo]="
                (viewModel.isEditing$ | push)
                  ? (viewModel.existingSmartFilter$ | push)
                  : (viewModel?.smartFilterReq$ | push)
              "
              [isEditing]="viewModel.isEditing$ | push"
              [dispersedKey]="'smart-filter-dispersed'"
              [isViewOnly]="viewModel.isViewOnly$ | push"
              [modalOpenedFrom]="viewModel.openedFrom$ | push">
            </app-terpene-add-edit-smart-filter-form>
          </mat-expansion-panel>
        </mat-accordion>
        <hr />

        <mat-accordion multi [displayMode]="'flat'">
          <mat-expansion-panel #panel class="mat-elevation-z0" [expanded]="viewModel.openAdvancedAccordion$ | push">
            <mat-expansion-panel-header class="no-background">
              <mat-panel-title class="accordion-title"> Advanced Filters </mat-panel-title>
            </mat-expansion-panel-header>
            <app-advanced-add-edit-smart-filter-form
              [bindTo]="
                (viewModel.isEditing$ | push)
                  ? (viewModel.existingSmartFilter$ | push)
                  : (viewModel?.smartFilterReq$ | push)
              "
              [isEditing]="viewModel.isEditing$ | push"
              [dispersedKey]="'smart-filter-dispersed'"
              [isViewOnly]="viewModel.isViewOnly$ | push"
              [modalOpenedFrom]="viewModel.openedFrom$ | push">
            </app-advanced-add-edit-smart-filter-form>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <hr />
    </lib-reactive-form-group>

    <div class="accordion-title">Products</div>
    <div *ngIf="(viewModel.smartFilterVariants$ | push)?.length === 0" class="empty-product-state mt-48px mb-48px">
      <ng-container *ngIf="viewModel.isSmartFilterEmpty$ | push; else empty">
        Select at least 1 smart filter criteria to see results.
      </ng-container>
      <ng-template #empty>This smart filter doesn't match any in stock products.</ng-template>
    </div>
    <div [hidden]="viewModel.hideSmartFilterVariantTable$ | async">
      <app-smart-filter-variants-table
        [variants]="viewModel.smartFilterVariants$ | push"
        [ignoredVariantIds]="viewModel.ignoredVariantIds$ | push"
        [isViewOnly]="viewModel.isViewOnly$ | push"
        [viewOnlyTooltip]="viewModel.viewOnlyTooltip$ | push"
        (ignoredListUpdated)="viewModel.connectToIgnoredVariantIds($event)"
        (variantVisibilityToggled)="viewModel.connectToVariantVisibilityToggled($event)">
      </app-smart-filter-variants-table>
    </div>
  </div>

  <div
    class="sticky-footer-container modal-footer-flex-end"
    [ngClass]="{ 'no-right-padding': !(viewModel.isViewOnly$ | async) }">
    <div *ngIf="viewModel.showDeleteSmartFilterCTA$ | push" class="edit-button-container">
      <lib-button-text class="mx-4" (buttonClicked)="viewModel.showDeleteModal()" [textColor]="'#FA5555'">
        Delete Smart Filter
      </lib-button-text>
    </div>

    <lib-button-neutral (buttonClicked)="cancel()">
      {{ (viewModel.isViewOnly$ | async) ? 'Close' : 'Cancel' }}
    </lib-button-neutral>

    <lib-button-primary
      *ngIf="!(viewModel.isViewOnly$ | push)"
      class="mx-4"
      (buttonClicked)="formCapture.submitForms()"
      [disabled]="viewModel.disableSubmitButton$ | push">
      {{ viewModel.ctaText$ | push }}
    </lib-button-primary>
  </div>
</div>
