import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HydratedSmartFilter } from '../../../../../../../models/automation/hydrated-smart-filter';
import { PrimaryCannabinoid } from '../../../../../../../models/enum/shared/primary-cannabinoid.enum';
import { SecondaryCannabinoid } from '../../../../../../../models/enum/dto/secondary-cannabinoid';

@Injectable()
export class CannabinoidMinMaxFormViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _smartFilter = new BehaviorSubject<HydratedSmartFilter>(null);
  public smartFilter$ = this._smartFilter as Observable<HydratedSmartFilter>;
  connectToSmartFilter = (smartFilter: HydratedSmartFilter) => {
    this._smartFilter.next(smartFilter);
  };

  private _cannabinoid = new BehaviorSubject<PrimaryCannabinoid|SecondaryCannabinoid>(null);
  public cannabinoid$ = this._cannabinoid as Observable<PrimaryCannabinoid|SecondaryCannabinoid>;
  connectToCannabinoid = (cannabinoid: PrimaryCannabinoid|SecondaryCannabinoid) => {
    this._cannabinoid.next(cannabinoid);
  };

  private _minCannabinoidValue = new BehaviorSubject<number>(undefined);
  public minCannabinoidValue$ = this._minCannabinoidValue as Observable<number>;
  connectToMinCannabinoidValue = (minCannabinoidValue: number) => {
    this._minCannabinoidValue.next(minCannabinoidValue);
  };

  private _maxCannabinoidValue = new BehaviorSubject<number>(undefined);
  public maxCannabinoidValue$ = this._maxCannabinoidValue as Observable<number>;
  connectToMaxCannabinoidValue = (minCannabinoidValue: number) => {
    this._maxCannabinoidValue.next(minCannabinoidValue);
  };

  public minCannabinoidMaxValue$ = this.maxCannabinoidValue$.pipe(
    map(maxCannabinoid => maxCannabinoid || null)
  );
  public maxCannabinoidMinValue$ = this.minCannabinoidValue$.pipe(
    map(minCannabinoid => minCannabinoid || 0.01)
  );

}
