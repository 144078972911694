import { Injectable, Injector, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { TemplateDomainModel } from '../../../../../../../domainModels/template-domain-model';
import { EditTemplateShelfTalkerMenuViewModel } from '../../edit-template-shelf-talker-menu-view-modal';
import { EditShelfTalkerBrandsMenuViewModel } from '../../../../../../menu/components/edit-menu/edit-shelf-talker-menu/edit-shelf-talker-brands-menu/edit-shelf-talker-brands-menu-view-model';

@Injectable()
export class EditTemplateShelfTalkerBrandsMenuViewModel extends EditTemplateShelfTalkerMenuViewModel {

  constructor(
    public override editMenuViewModel: EditShelfTalkerBrandsMenuViewModel,
    templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(editMenuViewModel, templateDomainModel, locationDomainModel, ngZone, ngbModal, injector);
  }

  showLiveView = this.editMenuViewModel.showLiveView;

}
