import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { EditVariantCustomizationViewModel } from './edit-variant-customization-view-model';

@Component({
  selector: 'app-edit-variant-customization',
  templateUrl: './edit-variant-customization.component.html',
  styleUrls: ['./edit-variant-customization.component.scss'],
  providers: [EditVariantCustomizationViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditVariantCustomizationComponent extends BaseComponent implements Tabbable, AfterViewInit {

  constructor(
    public viewModel: EditVariantCustomizationViewModel,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  @ViewChild('labelPickerParent') labelPickerParent: ElementRef;

  tabActivated: BehaviorSubject<boolean>;

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    // used to detect any changes in the labelPickerParent height and remove ChangedAfterChecked Errors
    this.cdRef.detectChanges();
  }

}
