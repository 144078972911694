import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuSectionColorsViewModel } from './menu-section-colors-view-model';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { Section } from '../../../../../../models/menu/dto/section';

@Component({
  selector: 'app-menu-section-colors',
  templateUrl: './menu-section-colors.component.html',
  styleUrls: ['./menu-section-colors.component.scss'],
  providers: [MenuSectionColorsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSectionColorsComponent extends BaseComponent {

  constructor(
    public viewModel: MenuSectionColorsViewModel,
  ) {
    super();
  }

  @Input() templateMode: boolean = false;
  @Input() mergeKey: string;
  @Input() section: Section;
  @Input() isTemplatedSection: boolean = false;
  @Input() sectionSupportsTitleSectionBackgroundColor: boolean;
  @Input() sectionSupportsHeaderBackgroundColor: boolean;
  @Input() sectionSupportsHeaderTextColor: boolean;
  @Input() sectionSupportsProductContainerBackgroundColor: boolean;
  @Input() sectionSupportsBodyTextColor: boolean;

}
