import { Pipe, PipeTransform } from '@angular/core';
import { TypeDefinition } from '../../../models/utils/dto/type-definition';

@Pipe({
  name: 'typeDefinitionBasicMethod'
})
export class TypeDefinitionBasicMethodPipe implements PipeTransform {

  transform(
    typeDefinition: TypeDefinition,
    methodKey: string,
    argument?: any
  ): any {
    return typeDefinition?.[methodKey]?.(argument);
  }

}
