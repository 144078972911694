import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { StringUtils } from '../../utils/string-utils';
import { PrintCardProperty } from '../../models/enum/shared/print-card-property';
import { exists } from '../../functions/exists';
import { Variant } from '../../models/product/dto/variant';
import type { LocationConfiguration } from '../../models/company/dto/location-configuration';
import type { Menu } from '../../models/menu/dto/menu';

@Pipe({
  name: 'getMissingPropertiesForVariant'
})
export class GetMissingPropertiesForVariantPipe implements PipeTransform {

  transform(
    variant: any,
    menu$: Observable<Menu>,
    locationConfig$: Observable<LocationConfiguration>,
    visibleProperties$: Observable<string[]>
  ): Observable<string> {
    if (variant instanceof Variant) {
      return combineLatest([
        menu$,
        locationConfig$,
        visibleProperties$
      ]).pipe(
        debounceTime(1),
        map(([menu, locationConfig, visibleProperties]) => {
          const missingProperties = visibleProperties
            ?.filter(prop => this.isValidVisibleProperty(variant, prop))
            ?.map(prop => this.mapToActualPascalCasedProperty(prop))
            ?.filter(exists)
            ?.flatMap(properties => Array.isArray(properties) ? properties : [properties])
            ?.filter(prop => this.propertyIsMissing(menu, locationConfig, variant, prop));
          return missingProperties?.length > 0
            ? `Missing Product Details: ${this.readableMissingProperties(missingProperties).join(', ')}`
            : null;
        })
      );
    }
    return of(null);
  }

  /**
   * I don't know how to handle description, richTextDescription, and shortDescription, so ignore it
   */
  protected isValidVisibleProperty(variant: Variant, pascalCasedProp: string): boolean {
    // ignore product properties
    switch (pascalCasedProp) {
      case PrintCardProperty.Badges:
      case PrintCardProperty.CompanyLogo:
      case PrintCardProperty.Description:
      case PrintCardProperty.Label:
        return false;
    }
    // ignore cannabinoids
    switch (pascalCasedProp) {
      case PrintCardProperty.THC:
      case PrintCardProperty.CBD:
      case PrintCardProperty.TAC:
      case PrintCardProperty.EnabledSecondaryCannabinoids:
        return false;
    }
    // ignore terpenes
    switch (pascalCasedProp) {
      case PrintCardProperty.TotalTerpene:
      case PrintCardProperty.TopTerpene:
      case PrintCardProperty.PresentTerpenes:
      case PrintCardProperty.EnabledTerpenes:
        return false;
    }
    // ignore certain cannabinoid properties if variant is non-cannabinoid
    if (variant?.isNonCannabinoid()) {
      switch (pascalCasedProp) {
        case PrintCardProperty.UnitSize:
        case PrintCardProperty.PackageQuantity:
        case PrintCardProperty.StrainType:
        case PrintCardProperty.Strain:
          return false;
      }
    }
    return true;
  }

  protected mapToActualPascalCasedProperty(pascalCasedProp: string): string | string[] | null {
    switch (true) {
      case !pascalCasedProp:
        return null;
      case pascalCasedProp === PrintCardProperty.StrainType:
        return 'Classification';
      case pascalCasedProp === PrintCardProperty.SKU:
        return 'CatalogSKU';
      case pascalCasedProp === PrintCardProperty.ProductTypeVariantType:
        return ['ProductType', 'VariantType'];
      default:
        return pascalCasedProp;
    }
  }

  protected propertyIsMissing(
    menu: Menu,
    locationConfig: LocationConfiguration,
    variant: Variant,
    pascalCasedProperty: string
  ): boolean {
    const value = this.getPropertyValue(menu, locationConfig, variant, pascalCasedProperty);
    switch (true) {
      case typeof value === 'number':
        return value <= 0;
      case typeof value === 'boolean':
        return false;
      default:
        return !value;
    }
  }

  protected getPropertyValue(
    menu: Menu,
    locationConfig: LocationConfiguration,
    variant: Variant,
    pascalCasedProperty: string
  ): any {
    switch (true) {
      case pascalCasedProperty === PrintCardProperty.Name:
        return variant?.getDisplayName();
      case pascalCasedProperty === PrintCardProperty.Price:
        const locId = locationConfig?.locationId;
        const compId = locationConfig?.companyId;
        const priceFormat = locationConfig?.priceFormat;
        const hideSale = menu?.menuOptions?.hideSale;
        return variant?.getVisiblePrice(locId, compId, priceFormat, hideSale);
      case pascalCasedProperty === PrintCardProperty.SecondaryPrice:
        return variant?.getSecondaryPrice(locationConfig?.locationId, locationConfig?.companyId);
      default:
        return variant?.[this.getPropertyAsAccessor(pascalCasedProperty)];
    }
  }

  protected getPropertyAsAccessor(pascalCasedProperty: string): any {
    switch (true) {
      case /^[A-Z0-9]+$/.test(pascalCasedProperty):
        return pascalCasedProperty;
      default:
        return StringUtils.lowercaseFirstCharacter(pascalCasedProperty);
    }
  }

  /**
   * ignore PrintCardProperty.ProductTypeVariantType and leave as two separate properties
   */
  protected mapBackToOriginalPascalCasedProperty(pascalCasedProp: string): string {
    switch (true) {
      case !pascalCasedProp:
        return null;
      case pascalCasedProp === 'Classification':
        return PrintCardProperty.StrainType;
      case pascalCasedProp === 'CatalogSKU':
        return PrintCardProperty.SKU;
      default:
        return pascalCasedProp;
    }
  }

  /**
   * Words are returned capitalized and separated by spaces.
   */
  protected readableMissingProperties(missingProperties: string[]): string[] {
    return missingProperties?.map(prop => {
      const propertyBackToOriginal = this.mapBackToOriginalPascalCasedProperty(prop);
      return StringUtils.splitPascalCasePreserveAcronyms(propertyBackToOriginal);
    });
  }

}
