import { Injectable, Injector, NgZone } from '@angular/core';
import { CardStackPreviewViewModel } from '../card-stack-preview/card-stack-preview-view-model';
import { BulkPrintJobDomainModel } from '../../../../../../domainModels/bulk-print-job-domain-model';
import { PrintCardPreviewService } from '../../../../../../services/print-card-preview.service';
import { TemplateCollectionDomainModel } from '../../../../../../domainModels/template-collection-domain-model';
import { MenuDisplayableSubItemService } from '../displayable-item-preview/menu-displayable-sub-item.service';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class LabelStackPreviewViewModel extends CardStackPreviewViewModel {

  constructor(
    protected override bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    protected override printCardPreviewService: PrintCardPreviewService,
    templateCollectionDomainModel: TemplateCollectionDomainModel,
    menuSubItemService: MenuDisplayableSubItemService,
    menuPreviewService: MenuPreviewService,
    menuDomainModel: MenuDomainModel,
    locationDomainModel: LocationDomainModel,
    toastService: ToastService,
    navigationService: NavigationService,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(
      bulkPrintJobDomainModel,
      printCardPreviewService,
      templateCollectionDomainModel,
      menuSubItemService,
      menuPreviewService,
      menuDomainModel,
      locationDomainModel,
      toastService,
      navigationService,
      ngZone,
      ngbModal,
      injector
    );
  }

}
