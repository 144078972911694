import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';

@Pipe({
  name: 'getShelfTalkerSectionSelectionErrorTooltip'
})
export class GetShelfTalkerSectionSelectionErrorTooltipPipe implements PipeTransform {

  transform(menu: Menu, nSelected: number): string {
    const n = menu?.getSectionsBasedOnMenuType()?.length ?? 0;
    switch (true) {
      case n === 0:
        return 'Shelf talkers without cards will be ignored';
      case nSelected === 0:
        return 'Shelf talkers without selected cards will be ignored';
      default:
        return null;
    }
  }

}
