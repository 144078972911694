import { environment } from '../../environments/environment';
import { Location } from '../models/company/dto/location';
import { ProductMix } from '../models/utils/dto/product-mix-type';

export class Endpoints {

  // Session

  static encodeParam(s: string): string {
    return encodeURIComponent(s);
  }

  static SignIn(): string {
    return environment.accountServiceUrl + `/sign-in`;
  }

  static SignInNewPassword(): string {
    return environment.accountServiceUrl + `/sign-in-new-password`;
  }

  static SignOut(): string {
    return environment.accountServiceUrl + `/sign-out`;
  }

  static RefreshSession(): string {
    return environment.accountServiceUrl + `/refresh-session`;
  }

  // Admin Account

  static AdminCreateUser(): string {
    return environment.accountServiceUrl + `/admin-create-user`;
  }

  static AdminDeleteUser(): string {
    return environment.accountServiceUrl + `/admin-delete-user`;
  }

  static AdminGetUsers(): string {
    return environment.accountServiceUrl + `/admin-get-users`;
  }

  static AdminUpdateUser(): string {
    return environment.accountServiceUrl + `/admin-update-user`;
  }

  // User Account

  static ChangePassword(): string {
    return environment.accountServiceUrl + `/change-password`;
  }

  static ConfirmCode(): string {
    return environment.accountServiceUrl + `/confirm-code`;
  }

  static ForgotPasswordCode(email: string): string {
    return environment.accountServiceUrl + `/forgot-password-code?Email=${this.encodeParam(email)}`;
  }

  static ResendCode(email, token: string): string {
    return environment.accountServiceUrl + `/resend-code?Email=${this.encodeParam(email)}&Token=${token}`;
  }

  static ResetForgottenPassword(): string {
    return environment.accountServiceUrl + `/reset-forgotten-password`;
  }

  static UpdateUser(): string {
    return environment.accountServiceUrl + `/update-user`;
  }

  static GetUser(): string {
    return environment.accountServiceUrl + `/get-user`;
  }

  static SendCompanyReferral(): string {
    return environment.accountServiceUrl + `/send-company-referral`;
  }

  // Automation

  static GetMenuSchedules(location: Location): string {
    return environment.automationServiceUrl + `/get-menu-schedules?LocationId=${location?.id}`;
  }

  static UpdateMenuSchedule(): string {
    return environment.automationServiceUrl + `/update-menu-schedule`;
  }

  static createSmartFilter(): string {
    return environment.automationServiceUrl + `/create-smart-filter`;
  }

  static updateSmartFilter(): string {
    return environment.automationServiceUrl + `/update-smart-filter`;
  }

  static deleteSmartFilter(): string {
    return environment.automationServiceUrl + `/delete-smart-filter`;
  }

  static getSmartFilters(locationId: string, ids?: string[]): string {
    const cacheBuster = Math.random().toString(12);
    const idString = (ids ? `&Ids=${ids.join(',')}&CacheBuster=${cacheBuster}` : `&cacheBuster=${cacheBuster}`);
    return environment.automationServiceUrl + `/get-smart-filters?LocationId=${locationId}` + idString;
  }

  static getCuratedSmartFilters(locationId: string, ids?: string[]): string {
    const idString = (ids ? `&Ids=${ids.join(',')}` : '');
    return environment.automationServiceUrl + `/get-curated-smart-filters?LocationId=${locationId}` + idString;
  }

  static getHydratedSmartFilter(locationId: string, filterId: string): string {
    return environment.automationServiceUrl + `/get-hydrated-smart-filter?LocationId=${locationId}&Id=${filterId}`;
  }

  static syncLocationSmartFilters(locationId: string, forceSync?: boolean): string {
    let url = environment.automationServiceUrl + `/sync-location-smart-filters?LocationId=${locationId}`;
    if (forceSync) url += `&ForceSync=${forceSync}`;
    return url;
  }

  static syncSmartFiltersBeforePrintJob(
    locationId: string,
    menuIds: string[],
    async: boolean
  ): string {
    return environment.automationServiceUrl
      + `/sync-location-smart-filters?LocationId=${locationId}`
      + `&MenuIds=${menuIds.join(',')}`
      + `&Async=${async}`;
  }

  static syncSectionSmartFilter(locationId: string, filterId: string): string {
    return environment.automationServiceUrl + `/sync-section-smart-filters?MenuId=${locationId}&SectionId=${filterId}`;
  }

  static syncSmartDisplayAttributes(locationIds: string, forceSync?: boolean): string {
    let url = environment.automationServiceUrl + `/sync-smart-display-attributes?LocationIds=${locationIds}`;
    if (forceSync) url += `&ForceSync=${forceSync}`;
    return url;
  }

  static createBulkPrintJob(): string {
    return environment.automationServiceUrl + `/create-bulk-print-job`;
  }

  static cancelBulkPrintJob(): string {
    return environment.automationServiceUrl + `/cancel-bulk-print-job`;
  }

  static getBulkPrintJobs(locationId: string, ids?: string[]): string {
    return environment.automationServiceUrl + `/get-bulk-print-jobs?`
      + `LocationId=${locationId}` + (ids ? `&Ids=${ids.join(',')}` : '');
  }

  static getMenuBulkPrintJobs(menuId?: string, locationId?: string): string {
    return environment.automationServiceUrl + `/get-menu-bulk-print-jobs?`
      + (menuId ? `MenuId=${menuId}` : '')
      + (locationId ? `&LocationId=${locationId}` : '');
  }

  static getMenuTemplateBulkPrintJobs(templateId?: string, locationId?: string): string {
    return environment.automationServiceUrl + `/get-menu-bulk-print-jobs?`
      + (templateId ? `TemplateId=${templateId}` : '')
      + (locationId ? `&LocationId=${locationId}` : '');
  }

  // Menu

  static AddMenuSection(): string {
    return environment.menuServiceUrl + `/add-configuration-section`;
  }

  static GetMenuSection(menuId, sectionId: string): string {
    return environment.menuServiceUrl + `/get-configuration-section?MenuId=${menuId}&SectionId=${sectionId}`;
  }

  static CreateMenu(): string {
    return environment.menuServiceUrl + `/create-configuration`;
  }

  static DeleteMenu(): string {
    return environment.menuServiceUrl + `/delete-configuration`;
  }

  static DuplicateMenu(): string {
    return environment.menuServiceUrl + `/duplicate-configuration`;
  }

  static DeleteMenuSection(): string {
    return environment.menuServiceUrl + `/delete-configuration-section`;
  }

  static DeleteMenuStyles(): string {
    return environment.menuServiceUrl + `/delete-configuration-styles`;
  }

  static GetCompanyBasicMenus(locationId: number): string {
    const cacheBuster = Math.random().toString(12);
    const params = `?LocationId=${locationId}&CacheBuster=${cacheBuster}`;
    return environment.menuServiceUrl + `/get-company-configurations${params}`;
  }

  static GetMenu(locationId: number, menuId: string): string {
    const params = `?LocationId=${locationId}&MenuId=${menuId}`;
    return environment.menuServiceUrl + `/get-configuration${params}`;
  }

  static GetMenuAssets(isTemplate: boolean, id: string): string {
    const paramName = isTemplate ? 'TemplateId' : 'MenuId';
    return environment.menuServiceUrl + `/get-configuration-assets?${paramName}=${id}`;
  }

  static GetMenuThemes(): string {
    return environment.menuServiceUrl + `/get-company-themes`;
  }

  static UpdateMenu(): string {
    return environment.menuServiceUrl + `/update-configuration`;
  }

  static UpdateSectionPriorities(): string {
    return environment.menuServiceUrl + `/update-section-priorities`;
  }

  static UpdateMenuSection(): string {
    return environment.menuServiceUrl + `/update-configuration-section`;
  }

  static UpdateMenuStyles(): string {
    return environment.menuServiceUrl + `/update-configuration-styles`;
  }

  static DuplicateMenuSection(): string {
    return environment.menuServiceUrl + `/duplicate-configuration-section`;
  }

  // SectionBlueprint, Template and Template Collection

  static GetSectionBlueprints(ids?: string): string {
    if (ids) {
      return environment.templateServiceUrl + `/get-section-blueprints?Ids=${ids}&IncludeCurated=true`;
    }
    return environment.templateServiceUrl + `/get-section-blueprints?IncludeCurated=true`;
  }

  static GetSectionBlueprintCategories(): string {
    return environment.templateServiceUrl + '/get-section-blueprint-categories?IncludeCurated=true';
  }

  static GetMenuTemplateForLocation(locationId: number, menuTemplateId: string): string {
    return environment.templateServiceUrl
      + `/get-template`
      + `?LocationId=${locationId}`
      + `&TemplateId=${menuTemplateId}`;
  }

  static CreateSectionTemplate(): string {
    return environment.templateServiceUrl + `/create-template-section`;
  }

  static GetSectionTemplate(
    locationId: number,
    menuTemplateId: string,
    sectionTemplateId: string
  ): string {
    return environment.templateServiceUrl
      + `/get-template-section`
      + `?LocationId=${locationId}`
      + `&TemplateId=${menuTemplateId}`
      + `&TemplateSectionId=${sectionTemplateId}`;
  }

  static CreateMenuTemplate(): string {
    return environment.templateServiceUrl + `/create-template`;
  }

  static UpdateMenuTemplate(): string {
    return environment.templateServiceUrl + `/update-template`;
  }

  static UpdateMenuSectionTemplate(): string {
    return environment.templateServiceUrl + `/update-template-section`;
  }

  static DeleteMenuSectionTemplate(): string {
    return environment.templateServiceUrl + `/delete-template-section`;
  }

  static UpdateMenuSectionTemplatePriorities(): string {
    return environment.templateServiceUrl + `/update-template-section-priorities`;
  }

  static GetCompanyMenuTemplates(locationId: number): string {
    return environment.templateServiceUrl + `/get-company-templates?LocationId=${locationId}`;
  }

  static DuplicateMenuTemplate(): string {
    return environment.templateServiceUrl + `/duplicate-template`;
  }

  static DuplicateSectionTemplate(): string {
    return environment.templateServiceUrl + `/duplicate-template-section`;
  }

  static DeleteMenuTemplate(): string {
    return environment.templateServiceUrl + `/delete-template`;
  }

  static GetCompanyMenuTemplateCollections(): string {
    return environment.templateServiceUrl + `/get-company-template-collections`;
  }

  static CreateTemplateCollection(): string {
    return environment.templateServiceUrl + `/create-template-collection`;
  }

  static GetTemplateCollection(locationId: number, templateId: string ): string {
    return environment.templateServiceUrl + `/get-template-collection?LocationId=${locationId}&Id=${templateId}`;
  }

  static UpdateTemplateCollection(): string {
    return environment.templateServiceUrl + `/update-template-collection`;
  }

  static DeleteTemplateCollection(): string {
    return environment.templateServiceUrl + `/delete-template-collection`;
  }

  // Display Attributes

  static WriteDisplayAttributes(menuId: string = '', isBulkEdit: boolean = false): string {
    return environment.menuServiceUrl + `/write-display-attributes?MenuId=${menuId}&IsBulkEdit=${isBulkEdit}`;
  }

  static GetDisplayAttributes(locationId: number): string {
    return environment.menuServiceUrl + `/get-display-attributes?LocationId=${locationId}`;
  }

  static DeleteDisplayAttributes(ids: string[]): string {
    const idStrings = ids.join(',');
    return environment.menuServiceUrl + `/delete-display-attributes?DisplayAttributeIds=${idStrings}`;
  }

  static GetLabels(locationId: number): string {
    return environment.mainServiceUrl + `/get-labels?LocationId=${locationId}`;
  }

  static DeleteLabel(): string {
    return environment.mainServiceUrl + `/delete-label`;
  }

  static CreateLabel(): string {
    return environment.mainServiceUrl + `/create-labels`;
  }

  static UpdateLabels(): string {
    return environment.mainServiceUrl + `/update-labels`;
  }

  // Company

  static CreateCompany(): string {
    return environment.mainServiceUrl + `/create-company`;
  }

  static GetCompany(): string {
    return environment.mainServiceUrl + `/get-company`;
  }

  static GetCompanyConfiguration(companyId: string): string {
    return environment.mainServiceUrl + `/get-company-configuration?CompanyId=${companyId}`;
  }

  static UpdateCompanyConfiguration(locationId: number): string {
    return environment.mainServiceUrl + `/update-company-configuration?LocationId=${locationId}`;
  }

  static CompanyInventoryProviderConfigurations(): string {
    return environment.mainServiceUrl + `/company-inventory-provider-configurations`;
  }

  static WriteCompanyInventoryProvider(): string {
    return environment.mainServiceUrl + `/inventory-provider-configuration`;
  }

  static UpdateInventoryRooms(): string {
    return environment.mainServiceUrl + `/update-inventory-rooms`;
  }

  static GetLocationConfigurations(locationId: string): string {
    return environment.mainServiceUrl + `/get-location-configuration?LocationId=${locationId}`;
  }

  static UpdateLocationConfigurations(): string {
    return environment.mainServiceUrl + `/update-location-configuration`;
  }

  static UpdateLocation(): string {
    return environment.mainServiceUrl + `/update-location`;
  }

  // Display

  static WriteDisplay(): string {
    return environment.mainServiceUrl + `/add-location-display`;
  }

  static DeleteDisplay(): string {
    return environment.mainServiceUrl + `/delete-display`;
  }

  static UpdateDisplay(): string {
    return environment.mainServiceUrl + `/update-display`;
  }

  static GetDisplay(displayId: string, ignoreLastSession: boolean, lastModified?: number): string {
    let params = `?DisplayId=${displayId}&IgnoreLastSession=${ignoreLastSession}`;
    if (lastModified > 0) params += `&LastModified=${lastModified}`;
    return environment.mainServiceUrl + `/get-display${params}`;
  }

  static GetLocationDisplays(locationId: number): string {
    const cacheBuster = Math.random().toString(12);
    const params = `?LocationId=${locationId}&CacheBuster=${cacheBuster}`;
    return environment.mainServiceUrl + `/get-location-displays${params}`;
  }

  static GetCompanyDisplays(): string {
    return environment.mainServiceUrl + `/get-company-displays`;
  }

  static UpdateDisplayPriorities(): string {
    return environment.mainServiceUrl + `/update-display-priorities`;
  }

  // Image

  static DeleteAsset(id, md5Hash: string): string {
    return environment.imageServiceUrl + `/delete-asset?Id=${id}&MD5Hash=${md5Hash}`;
  }

  static GenerateUploadUrl(): string {
    return environment.imageServiceUrl + `/generate-upload-url`;
  }

  static GetPreview(
    locationId: number,
    displayId,
    menuId: string,
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly: boolean = false,
    templateId: string = '',
    templateCollectionId: string = ''
  ): string {
    const params = `?LocationId=${locationId}`
      + `&DisplayId=${displayId}`
      + `&MenuId=${menuId}`
      + `&ReturnLastSaved=${returnLastSaved}`
      + `&PreviewOnly=${previewOnly}`
      + `&ForceUpdate=${forceUpdate}`
      + `&TemplateId=${templateId}`
      + `&TemplateCollectionId=${templateCollectionId}`;
    return environment.imageServiceUrl + `/get-preview${params}`;
  }

  static GetMenuPreview(
    locationId: number,
    menuId: string,
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean,
  ): string {
    let params = `?LocationId=${locationId}`
      + `&MenuId=${menuId}`
      + `&ReturnLastSaved=${returnLastSaved}`
      + `&ForceUpdate=${forceUpdate}`;
    if (previewOnly) params += `&PreviewOnly=${previewOnly}`;
    return environment.imageServiceUrl + `/get-preview${params}`;
  }

  static GetCardStackPreview(
    locationId: number,
    menuId: string,
    variantIds: string[],
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean,
  ): string {
    let params = `?LocationId=${locationId}`
      + `&MenuId=${menuId}`
      + `&VariantIds=${variantIds?.sort().join(',')}`
      + `&ReturnLastSaved=${returnLastSaved}`
      + `&ForceUpdate=${forceUpdate}`;
    if (previewOnly) params += `&PreviewOnly=${previewOnly}`;
    return environment.imageServiceUrl + `/get-preview${params}`;
  }

  static GetShelfTalkerPreview(
    locationId: number,
    menuId: string,
    sectionId: string,
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean,
  ): string {
    let params = `?LocationId=${locationId}`
      + `&MenuId=${menuId}`
      + `&SectionId=${sectionId}`
      + `&ReturnLastSaved=${returnLastSaved}`
      + `&ForceUpdate=${forceUpdate}`;
    if (previewOnly) params += `&PreviewOnly=${previewOnly}`;
    return environment.imageServiceUrl + `/get-preview${params}`;
  }

  static GetMenuTemplatePreview(
    locationId: number,
    templateId: string,
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean
  ): string {
    let params = `?LocationId=${locationId}`
      + `&TemplateId=${templateId}`
      + `&ReturnLastSaved=${returnLastSaved}`
      + `&ForceUpdate=${forceUpdate}`;
    if (previewOnly) params += `&PreviewOnly=${previewOnly}`;
    return environment.imageServiceUrl + `/get-preview${params}`;
  }

  static GetCardStackTemplatePreview(
    locationId: number,
    menuId: string,
    variantIds: string[],
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean,
  ): string {
    let params = `?LocationId=${locationId}`
      + `&TemplateId=${menuId}`
      + `&VariantIds=${variantIds?.sort().join(',')}`
      + `&ReturnLastSaved=${returnLastSaved}`
      + `&ForceUpdate=${forceUpdate}`;
    if (previewOnly) params += `&PreviewOnly=${previewOnly}`;
    return environment.imageServiceUrl + `/get-preview${params}`;
  }

  static GetShelfTalkerCardTemplatePreview(
    locationId: number,
    menuId: string,
    sectionId: string,
    returnLastSaved: boolean,
    forceUpdate: boolean,
    previewOnly?: boolean,
  ): string {
    let params = `?LocationId=${locationId}`
      + `&TemplateId=${menuId}`
      + `&SectionId=${sectionId}`
      + `&ReturnLastSaved=${returnLastSaved}`
      + `&ForceUpdate=${forceUpdate}`;
    if (previewOnly) params += `&PreviewOnly=${previewOnly}`;
    return environment.imageServiceUrl + `/get-preview${params}`;
  }

  static GetPrintPDF(locationId: number, forced: boolean, menuId?: string, templateId?: string): string {
    let params = `?LocationId=${locationId}`
      + `&ReturnLastSaved=${false}`
      + `&PreviewOnly=${false}`
      + `&ForceUpdate=${forced}`;
    if (menuId) params += `&MenuId=${menuId}`;
    if (templateId) params += `&TemplateId=${templateId}`;
    return environment.imageServiceUrl + `/get-preview${params}`;
  }

  static GetAsset(id, md5Hash: string): string {
    return environment.imageServiceUrl + `/get-asset?Id=${id}&MD5Hash=${md5Hash}`;
  }

  // Product

  static WriteVariantBadge(): string {
    return environment.productServiceUrl + `/create-variant-badge`;
  }

  static DeleteVariantBadge(): string {
    return environment.productServiceUrl + `/delete-variant-badge`;
  }

  static UpdateVariantBadge(): string {
    return environment.productServiceUrl + `/update-variant-badge`;
  }

  static GetCuratedBadges(): string {
    return environment.productServiceUrl + `/get-curated-badges`;
  }

  static GetVariantBadges(id: string = ''): string {
    return environment.productServiceUrl + `/get-variant-badges?Id=${id}`;
  }

  static GetCompanyProducts(locationId: number, productMix = ProductMix.All, productIds: string[] = []): string {
    const cacheBuster = Math.random().toString(12);
    let params = `?LocationId=${locationId}&ProductMix=${productMix}&CacheBuster=${cacheBuster}`;
    if (productIds?.length > 0) {
      params += `&ProductIds=${productIds?.join(',')}`;
    }
    return environment.productServiceUrl + `/get-company-products${params}`;
  }

  static GetLocationInventory(locationId: number, forceSync: boolean): string {
    return environment.productServiceUrl + `/get-location-inventory?LocationId=${locationId}&ForceSync=${forceSync}`;
  }

  static GetAllLocationInventory(variantId: string): string {
    return environment.productServiceUrl + `/get-all-location-inventory?VariantId=${variantId}`;
  }

  static GetLocationPromotions(locationId: number): string {
    return environment.productServiceUrl + `/get-location-promotions?LocationId=${locationId}`;
  }

  static GetUniversalVariants(ids: string[]): string {
    const variantIds = ids.join(',');
    return environment.productServiceUrl + `/get-universal-variants?VariantIds=${variantIds}`;
  }

  static UpdateVariants(): string {
    return environment.productServiceUrl + `/update-variants`;
  }

  static UpdateVariantPricing(): string {
    return environment.productServiceUrl + `/update-variant-pricing`;
  }

  static UpdateVariantInventory(markAsActive: boolean): string {
    return environment.productServiceUrl + `/update-variant-inventory?MarkAsActive=${markAsActive}`;
  }

  // Syncing

  static SyncDisplayNames(): string {
    return environment.productServiceUrl + `/sync-display-names`;
  }

  static SyncFullProductInfo(): string {
    return environment.productServiceUrl + `/sync-full-product-info`;
  }

  static GetSyncJobs(ids: string[]): string {
    const jobIds = ids?.join(',') ?? '';
    return environment.productServiceUrl + `/get-sync-jobs?Ids=${jobIds}`;
  }

  static CreateSyncJob(): string {
    return environment.productServiceUrl + `/create-sync-job`;
  }

  // Product Groups

  static GetOverrideProductGroups(ids?: string[]): string {
    const cacheBuster = Math.random().toString(12);
    let params = `?CacheBuster=${cacheBuster}`;
    if (ids?.length) params += `&Ids=${ids.join(',')}`;
    return environment.productServiceUrl + `/get-override-product-groups${params}`;
  }

  static CreateOverrideProductGroup(): string {
    return environment.productServiceUrl + `/create-override-product-group`;
  }

  static UpdateOverrideProductGroup(): string {
    return environment.productServiceUrl + `/update-override-product-group`;
  }

  static DeleteOverrideProductGroup(): string {
    return environment.productServiceUrl + `/delete-override-product-group`;
  }

  // Taxes

  static CreateTaxRate(): string {
    return environment.productServiceUrl + `/create-tax-rate`;
  }

  static UpdateTaxRate(): string {
    return environment.productServiceUrl + `/update-tax-rate`;
  }

  static DeleteTaxRate(): string {
    return environment.productServiceUrl + `/delete-tax-rate`;
  }

  static GetTaxRates(locationId: number): string {
    return environment.productServiceUrl + `/get-tax-rates?LocationId=${locationId}`;
  }

  static GetTaxGroups(locationId: number): string {
    return environment.productServiceUrl + `/get-tax-groups?LocationId=${locationId}`;
  }

  static CreateTaxGroup(): string {
    return environment.productServiceUrl + `/create-tax-group`;
  }

  static DeleteTaxGroup(): string {
    return environment.productServiceUrl + `/delete-tax-group`;
  }

  static UpdateTaxGroup(): string {
    return environment.productServiceUrl + `/update-tax-group`;
  }

  // Device

  static GetCompanyDevices(locationId: number, hydrated: boolean): string {
    return environment.deviceServiceUrl + `/get-company-devices?LocationId=${locationId}&Hydrated=${hydrated}`;
  }

  static GetDevice(deviceId: string, hydrated: boolean): string {
    return environment.deviceServiceUrl + `/get-device?DeviceId=${deviceId}&Hydrated=${hydrated}`;
  }

  static ResetDevice(): string {
    return environment.deviceServiceUrl + `/reset-device`;
  }

  static SetupDevice(): string {
    return environment.deviceServiceUrl + `/setup-device`;
  }

  static UpdateDevice(): string {
    return environment.deviceServiceUrl + `/update-device`;
  }

  // Utils

  static GetTypes(countryCode: string): string {
    return environment.mainServiceUrl + `/get-types?Country=${countryCode}`;
  }

}

