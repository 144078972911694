<div class="mt-5">
  <div class="cannabinoid-title">
    <span class="text"> Primary Cannabinoids </span>
  </div>
  <ng-container *rxFor="let cannabinoid of viewModel.primaryCannabinoidColumnConfigsAndKeys$ | push">
    <mat-accordion multi [displayMode]="'flat'" class="cannabinoid-accordion">
      <mat-expansion-panel class="mat-elevation-z0 cannabinoid-container">
        <mat-expansion-panel-header class="cannabinoid-accordion-header">
          <mat-panel-title class="bs-bold f14px">
            {{ cannabinoid?.name }}
            <div
              class="bs-medium f12px default-state-label"
              [ngStyle]="
                cannabinoid?.key
                  | getCurrentlySelectedDefaultStateLabelStyling : (viewModel.currentDefaultStateMap$ | push)
              ">
              {{ cannabinoid?.key | getCurrentlySelectedDefaultState : (viewModel.currentDefaultStateMap$ | push) }}
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-column-options-form
          [formColumnConfig]="cannabinoid?.config"
          [columnSectionKey]="cannabinoid?.key"
          [formMergeKey]="viewModel.container.mergeKey"
          [sectionLayoutType]="viewModel.container.layoutType$ | push"
          [themeDefaults]="cannabinoid?.themeDefaults"
          [managingDefault]="viewModel.container.managingDefault$ | push"
          [cannabinoidOrTerpene]="true"
          (columnChanges)="viewModel.container.connectToColumnChanges(cannabinoid?.key, $event)">
        </app-column-options-form>
      </mat-expansion-panel>
      <hr class="mt-none" />
    </mat-accordion>
  </ng-container>
  <div class="cannabinoid-title-wrapper">
    <div class="cannabinoid-title" [style.margin-top.rem]="0.5">
      <span class="text"> Secondary Cannabinoids </span>
    </div>
  </div>
  <ng-container *rxFor="let cannabinoid of viewModel.secondaryCannabinoidColumnConfigsAndKeys$ | push">
    <mat-accordion multi [displayMode]="'flat'" class="cannabinoid-accordion">
      <mat-expansion-panel class="mat-elevation-z0 cannabinoid-container">
        <mat-expansion-panel-header class="cannabinoid-accordion-header">
          <mat-panel-title class="bs-bold f14px">
            {{ cannabinoid?.name }}
            <div
              class="bs-medium f12px default-state-label"
              [ngStyle]="
                cannabinoid?.key
                  | getCurrentlySelectedDefaultStateLabelStyling : (viewModel.currentDefaultStateMap$ | push)
              ">
              {{ cannabinoid?.key | getCurrentlySelectedDefaultState : (viewModel.currentDefaultStateMap$ | push) }}
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-column-options-form
          [formColumnConfig]="cannabinoid?.config"
          [columnSectionKey]="cannabinoid?.key"
          [formMergeKey]="viewModel.container.mergeKey"
          [sectionLayoutType]="viewModel.container.layoutType$ | push"
          [themeDefaults]="cannabinoid?.themeDefaults"
          [managingDefault]="viewModel.container.managingDefault$ | push"
          [cannabinoidOrTerpene]="true"
          (columnChanges)="viewModel.container.connectToColumnChanges(cannabinoid?.key, $event)">
        </app-column-options-form>
      </mat-expansion-panel>
      <hr class="mt-none" />
    </mat-accordion>
  </ng-container>
</div>
