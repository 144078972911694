import { Deserializable } from '../../protocols/deserializable';
import { BannerMessage } from './banner-message';

export class CustomerMessage implements Deserializable {

  customerMessages: BannerMessage[];
  systemMessages: BannerMessage[];

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.customerMessages = Deserialize?.arrayOf(BannerMessage, this.customerMessages);
    this.systemMessages = Deserialize?.arrayOf(BannerMessage, this.systemMessages);
  }

  getHighestPriorityMessage(): BannerMessage | null {
    return this.customerMessages?.filter(msg => msg.isWithinTimeWindow()).firstOrNull()
      || this.systemMessages?.filter(msg => msg.isWithinTimeWindow()).firstOrNull()
      || null;
  }

}
