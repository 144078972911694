import { EventEmitter, Injectable } from '@angular/core';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { Card } from '../../../models/shared/stylesheet/card';
import { Variant } from '../../../models/product/dto/variant';
import { Checkbox } from '../../../models/shared/stylesheet/checkbox';
import { CardStyle } from '../../../models/shared/stylesheet/card-style.enum';
import { CardTableOptions } from '../../../models/shared/stylesheet/card-table-options';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { DistinctUtils } from '../../../utils/distinct-utils';
import { NumberUtils } from '../../../utils/number-utils';
import { CannabinoidDisplayType } from '../../../models/utils/dto/cannabinoid-display-type-definition';
import { Product } from '../../../models/product/dto/product';
import { CompanyDomainModel } from '../../../domainModels/company-domain-model';

// Provided by Logged In Scope
@Injectable()
export class AllProductsViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private productDomainModel: ProductDomainModel,
  ) {
    super();
    this.setupCardTable();
  }

  public useRange$ = this.companyDomainModel.companyConfiguration$.notNull().pipe(
    map(config => config?.cannabinoidDisplayType === CannabinoidDisplayType.Range)
  );

  // Products table
  public allProductsTableData$ = combineLatest([
    this.productDomainModel.selectedSmartFilterIds$,
    this.productDomainModel.currentLocationActiveProducts$,
    this.productDomainModel.smartFilterDataTableProducts$
  ]).pipe(
    debounceTime(100),
    map(([appliedSmartFilterIds, activeProducts, smartFilterProds]) => {
      return appliedSmartFilterIds?.length > 0 ? smartFilterProds : activeProducts;
    }),
    startWith<Product[], Product[]>(null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  // Incomplete Products card table
  private _hideOutOfStockProducts = new BehaviorSubject<boolean>(true);
  public hideOutOfStockProducts$ = this._hideOutOfStockProducts.pipe(distinctUntilChanged());
  public cardTableOptions: CardTableOptions = new CardTableOptions();
  public updateIncompleteProductTableData: EventEmitter<Card[]> = new EventEmitter<Card[]>();

  // Incomplete variants
  public incompleteVariantCards$ = combineLatest([
    this.productDomainModel.inStockIncompleteVariants$,
    this.productDomainModel.allIncompleteVariants$,
    this.hideOutOfStockProducts$,
  ]).pipe(
    map(([inStockIncomplete, allIncomplete, hideOutOfStock]) => {
      const variants = hideOutOfStock ? inStockIncomplete : allIncomplete;
      return variants.map((variant) => {
        // Add incomplete variant cards
        const card = new Card(
          variant.productName,
          variant.name,
        );
        card.id = variant.id;
        const pluralText = variant.incompletePropertyCount(undefined) === 1 ? '' : 's';
        card.subtext = `${variant.incompletePropertyCount(undefined)} Missing Field${pluralText}`;
        card.cardStyle = CardStyle.IncompleteProduct;
        card.data = variant;
        return card;
      });
    }),
    startWith([])
  );
  private listenToIncompleteVariantCards = this.incompleteVariantCards$.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)
  ).subscribeWhileAlive({
    owner: this,
    next: incompleteVariantCards => {
      this.updateIncompleteProductTableData.next(incompleteVariantCards);
    }
  });
  public incompleteVariantCount$ = this.incompleteVariantCards$.pipe(map(incomplete => incomplete?.length ?? 0));
  public incompleteVariantPreviewCards$: Observable<Card[]> = this.incompleteVariantCards$.pipe(
    startWith([]),
    map(incomplete => incomplete?.take(10)),
  );

  // Company Cannabinoid Display
  setHideOutOfStock(hideOutOfStock: boolean) {
    this._hideOutOfStockProducts.next(hideOutOfStock);
    this.cardTableOptions.checkBox.checked = hideOutOfStock;
  }

  public setupCardTable() {
    this.cardTableOptions.searchLabelText = 'Search';
    this.cardTableOptions.searchPlaceholderText = 'Search for incomplete products by name';
    this.cardTableOptions.checkBox = new Checkbox('Hide out of stock products', true, false);
    this.setHideOutOfStock(this.cardTableOptions.checkBox.checked);
    this.cardTableOptions.checkBoxFilter = (c: Card): boolean => {
      if (c.data instanceof Variant) {
        return NumberUtils.floatFirstGreaterThanSecond(c.data.inventory.quantityInStock, 0);
      }
      return true;
    };
  }

  public getIncompleteVariantFromId(id: string): Observable<Variant> {
    return this.productDomainModel.allIncompleteVariants$.pipe(
      map(incompleteVariants => {
        const match = incompleteVariants.find(v => v.id === id);
        return match ?? null;
      })
    );
  }

  public getSiblingIncompleteVariants(incv: Variant): Observable<Variant[]> {
    if (!incv.productId) {
      return of([incv]);
    }
    return this.productDomainModel.allIncompleteVariants$.pipe(
      map(incompleteVariants => incompleteVariants?.filter(iv => iv.productId === incv.productId) ?? [])
    );
  }

}
