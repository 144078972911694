import { Component, Injector, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../../../../../services/toast-service';
import { EditProductMenuViewModel } from '../../../viewModels/edit-product-menu-view-model';
import { ComponentCanDeactivate } from '../../../../../models/protocols/component-can-deactivate';
import { AutoSaveComponent } from '../../../../shared/components/auto-save/auto-save.component';

@Component({
  selector: 'app-edit-shelf-talker-menu',
  template: ''
})
export abstract class EditShelfTalkerMenuComponent extends AutoSaveComponent implements ComponentCanDeactivate {

  protected constructor(
    public override viewModel: EditProductMenuViewModel,
    protected router: Router,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    protected sanitizer: DomSanitizer,
    protected toastService: ToastService,
    protected activatedRoute: ActivatedRoute
  ) {
    super(viewModel);
  }

}
