import { Pipe, PipeTransform } from '@angular/core';
import { BulkPrintJob } from '../../../../../../../models/automation/bulk-print-job';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take } from 'rxjs/operators';
import { exists } from '../../../../../../../functions/exists';

@Pipe({
  name: 'preSelectedPreviousJobId'
})
export class PreSelectedPreviousJobIdPipe implements PipeTransform {

  transform(
    allPrintJobs$: Observable<BulkPrintJob[]>,
    currentJob$: Observable<BulkPrintJob>,
    menuId: string,
  ): Observable<string> {
    return combineLatest([allPrintJobs$, currentJob$]).pipe(
      map(([allJobs, currentJob]) => {
        const currentJobIncludeChangesSince = currentJob?.cardStackPrintConfigMap?.get(menuId)?.includeChangesSince;
        if (exists(currentJobIncludeChangesSince)) {
          const previousjobDate = currentJob?.cardStackPrintConfigMap?.get(menuId)?.includeChangesSince;
          const previousJob = allJobs?.find(job => job?.dateCreated === previousjobDate);
          return previousJob?.id;
        }
        const mostRecentJob = allJobs
          ?.filter(job => job?.menuIncludedInPrintJob(menuId))
          ?.firstOrNull();
        return mostRecentJob?.id;
      }),
      filter(it => exists(it)),
      distinctUntilChanged(),
      take(1), // don't update in real time, only update once on initial load
      startWith(undefined)
    );
  }

}
