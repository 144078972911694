<div class="row edit-page-details">
  <div class="col-sm-4 col-md-4 col-lg-4">
    <p class="bs-edit-section-title">Background Asset</p>
    <p class="bs-edit-section-description">The uploaded asset will be used as the cards background.</p>
  </div>

  <div class="col-sm-8 col-md-8 col-lg-8 menu-background-image-container position-relative">
    <app-loading *ngIf="!hideLoading" [options]="loadingOpts"> </app-loading>
    <!-- Asset Preview -->
    <app-asset-preview
      (primaryButtonPressed)="deleteSectionAssetClicked.emit()"
      (secondaryButtonPressed)="changeSectionAssetClicked.emit()"
      (refreshButtonPressed)="refreshSectionAssetClicked.emit($event)"
      [templateMode]="templateMode"
      [asset]="sectionAsset"
      [hidden]="!sectionAsset"
      [formPristine]="formPristine"
      [options]="sectionAssetPreview"
      [isDisabledByTemplates]="isTemplatedSection">
    </app-asset-preview>

    <!-- Upload Asset -->
    <div [hidden]="!!sectionAsset" class="section-upload-area">
      <app-upload-asset
        #sectionHeaderImage
        [allowImage]="allowImage"
        [allowVideo]="allowVideo"
        [displayList]="false"
        [formPristine]="formPristine"
        [isDisabledByTemplates]="isTemplatedSection"
        [maxAssets]="1"
        [parentHandler]="parentHandler">
      </app-upload-asset>
    </div>
    <div [hidden]="!sectionAsset">
      <lib-reactive-form-group
        [mergeKey]="'editSection'"
        [bindTo]="viewModel.section$ | push"
        [globalDisabledTooltip]="isTemplatedSection | templatedMenuDisabledFormControlTooltip">
        <lib-reactive-form-column-layout nColumns="3">
          <lib-reactive-form-drop-down
            [inputName]="'objectFit'"
            [label]="'Image Fit'"
            [placeholder]="'Select Image Fit'"
            [inlineLabel]="false"
            [disabled]="isTemplatedSection"
            [required]="true"
            [tooltip]="'Determines if the image should cover or fit within the background container.'"
            [bindingProperty]="'metadata.objectFit'"
            [dropdowns]="viewModel.objectFitTypes$ | push"
            (valueChanged)="viewModel.connectToImageObjectFit($event)">
          </lib-reactive-form-drop-down>
          <lib-reactive-form-number
            #imageWidth
            [inputName]="'width'"
            [label]="'Image Width'"
            [placeholder]="'Enter image width (%)'"
            [inlineLabel]="false"
            [integersOnly]="true"
            [required]="true"
            [minValue]="1"
            [maxValue]="100"
            [customValueParser]="ClientTypes.ParserUtils.parseAsString"
            [disabled]="(viewModel.imageWidthDisabled$ | push) || isTemplatedSection"
            [tooltip]="'Determines what percentage the asset width expands within the background container.'"
            [disabledTooltip]="'Image width is disabled when image fit equals cover.'"
            [bindingProperty]="'metadata.width'"
            (valueChanged)="viewModel.connectToImageWidth($event)">
          </lib-reactive-form-number>
          <lib-reactive-form-drop-down
            #imageAlignment
            [inputName]="'imageAlignment'"
            [label]="'Image Alignment'"
            [placeholder]="'Select Image Alignment'"
            [inlineLabel]="false"
            [required]="true"
            [disabled]="(viewModel.imageAlignmentDisabled$ | push) || isTemplatedSection"
            [disabledTooltip]="viewModel.imageAlignmentDisabledTooltip$ | push"
            [tooltip]="'Determines where the image should be horizontally aligned.'"
            [bindingProperty]="'metadata.imageAlignment'"
            [dropdowns]="viewModel.imageAlignments$ | push">
          </lib-reactive-form-drop-down>
        </lib-reactive-form-column-layout>
      </lib-reactive-form-group>
    </div>
  </div>
</div>
<hr class="mt-24px" />
