import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SingleStackPrintJobAppliedSectionsViewModel } from './single-stack-print-job-applied-sections-view-model';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { BulkPrintJob } from '../../../../../../../../models/automation/bulk-print-job';
import type { StackType } from '../create-view-stack-print-job.component';

@Component({
  selector: 'app-single-stack-print-job-applied-sections',
  templateUrl: './single-stack-print-job-applied-sections.component.html',
  providers: [SingleStackPrintJobAppliedSectionsViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleStackPrintJobAppliedSectionsComponent {

  constructor(public viewModel: SingleStackPrintJobAppliedSectionsViewModel) {
  }

  @Input() @ViewModelConnector('connectToJob') job: BulkPrintJob;
  @Input() @ViewModelConnector('connectToSearchText') searchText: string;
  @Input() @ViewModelConnector('connectToStackType') stackType: StackType;
  @Input() @ViewModelConnector('connectToTemplateMode') templateMode: boolean;

}
