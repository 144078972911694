export enum SectionType {
  CategoryCard = 'SectionType_CategoryCard',
  CardStack = 'SectionType_CardStack',
  Media = 'SectionType_Image',
  PageBreak = 'SectionType_PageBreak',
  Product = 'SectionType_Product',
  ProductGroup = 'SectionType_ProductGroup',
  NewProducts = 'SectionType_PrintReportNewProducts',
  RestockedProducts = 'SectionType_PrintReportRestockedProducts',
  Spotlight = 'SectionType_Spotlight',
  Title = 'SectionType_Title',
  LabelStack = 'SectionType_LabelStack',
}
