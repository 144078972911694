import { TypeDefinition } from './type-definition';
import { InventoryProvider } from '../../enum/dto/inventory-provider';

export class InventoryProviderType extends TypeDefinition {

  override value: InventoryProvider;

  override getSelectionValue(): InventoryProvider {
    return this.value;
  }

}
