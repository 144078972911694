import { Injectable, Injector } from '@angular/core';
import { UrlTree } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AbandonFormComponent } from '../../views/shared/modals/abandon-form/abandon-form.component';
import { ComponentCanDeactivate } from '../../models/protocols/component-can-deactivate';
import { ModalUtils } from '../../utils/modal-utils';
import { firstValueFrom, Observable } from 'rxjs';

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false,
};

type Deactivation = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

@Injectable({ providedIn: 'root' })
export class CanDeactivateGuard {

  constructor(
    private ngbModal: NgbModal,
    private injector: Injector,
  ) {
  }

  private deactivateModel: NgbModalRef = null;

  canDeactivate(component: ComponentCanDeactivate): Deactivation {
    let deactivation = component.canDeactivate();
    const handleDeactivation = (deactivate: boolean) => {
      if (deactivate) return true;
      return !this.deactivateModel ? this.openAbandonModal() : false;
    };
    if (deactivation instanceof Observable) deactivation = firstValueFrom(deactivation);
    return deactivation instanceof Promise ? deactivation.then(handleDeactivation) : handleDeactivation(deactivation);
  }

  private openAbandonModal(): Promise<boolean> {
    this.deactivateModel = this.ngbModal.open(AbandonFormComponent, ModalUtils.defaultModalOptions(this.injector));
    return this.deactivateModel.result.then(result => {
      this.deactivateModel = null;
      return result;
    }).catch((_) => {
      this.deactivateModel = null;
      return false;
    }) as Promise<boolean>;
  }

}
