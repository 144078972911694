import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditMenuWithMenuAndSectionCombinedTogetherFooterComponent } from '../../shared/edit-menu-and-section-combined-together-footer/edit-menu-with-menu-and-section-combined-together-footer.component';
import { EditSpotlightMenuViewModel } from '../edit-spotlight-menu-view-model';
import { EditSpotlightMenuCombinedLoadingOptsViewModel } from '../edit-spotlight-menu-combined-loading-opts-view-model';

@Component({
  selector: 'app-edit-spotlight-menu-footer',
  templateUrl: '../../shared/edit-menu-and-section-combined-together-footer/edit-menu-with-menu-and-section-combined-together-footer.component.html',
  styleUrls: ['../../../../../shared/styles/edit-page-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSpotlightMenuFooterComponent extends EditMenuWithMenuAndSectionCombinedTogetherFooterComponent {

  constructor(
    public override viewModel: EditSpotlightMenuViewModel,
    public override sharedLoadingState: EditSpotlightMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel, sharedLoadingState);
  }

}
