import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MenuDetailsSectionViewModel } from '../../shared/menu-section-details/menu-details-section-view-model';

@Injectable()
export class ShelfTalkerDetailsSectionViewModel extends MenuDetailsSectionViewModel {

  public override title$ = of('Stack Details');
  public override description$ = of('View and edit basic information about this stack.');

  public shelfTalkerCardSize$ = combineLatest([
    this.menu$,
    window.types.printShelfTalkerSizeTypes$
  ]).pipe(
    map(([menu, printShelfTalkerSizeTypes]) => {
      const printCardSize = menu?.metadata?.printCardSize;
      return printShelfTalkerSizeTypes?.find(cardSize => cardSize?.value === printCardSize);
    })
  );

}
