<div class="modal-padding">
  <div class="cannabinoid-title">
    <span class="text"> Primary Cannabinoids </span>
  </div>

  <ng-container *rxFor="let cannabinoid of viewModel.primaryCannabinoids$ | push">
    <app-cannabinoid-min-max-form
      [smartFilter]="viewModel.smartFilter$ | push"
      [cannabinoid]="cannabinoid"
      [dispersedKey]="dispersedKey"
      [isViewOnly]="viewModel.isViewOnly$ | push"></app-cannabinoid-min-max-form>
  </ng-container>
  <lib-reactive-form-pill-drop-down
    [inputName]="'presentSecondaryCannabinoids'"
    [label]="'Present Secondary Cannabinoids'"
    [emptyPlaceholder]="'Enter present secondary cannabinoids'"
    [placeholder]="'Enter present secondary cannabinoids'"
    [dropdowns]="viewModel.secondaryCannabinoidDropdowns$ | push"
    [addToAtLeastNItemsFilledInPool]="true"
    [delimitedPillBackgroundColors]="viewModel.secondaryCannabinoidPillBackgroundColors$ | push"
    [delimitedPillTooltips]="viewModel.secondaryCannabinoidPillTooltip$ | push"
    [bindingProperty]="'presentSecondaryCannabinoids'"
    [dispersedKey]="dispersedKey"></lib-reactive-form-pill-drop-down>

  <div class="cannabinoid-title" [style.margin-top.rem]="2">
    <span class="text"> Secondary Cannabinoids </span>
  </div>
  <ng-container *rxFor="let cannabinoid of viewModel.enabledSecondaryCannabinoids$ | push">
    <app-cannabinoid-min-max-form
      [smartFilter]="viewModel.smartFilter$ | push"
      [cannabinoid]="cannabinoid"
      [dispersedKey]="dispersedKey"
      [isViewOnly]="viewModel.isViewOnly$ | push"></app-cannabinoid-min-max-form>
  </ng-container>
</div>
