import { DisplayableItemsViewModel } from './displayable-items-view-model';
import { DropDownItem } from '../../../../../models/shared/stylesheet/drop-down-item';
import { DisplayableItemFilterByActive } from '../../../../../models/enum/shared/displayable-item-filter-by.active';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';
import { ModalCreateTemplate } from '../../../../../modals/modal-create-template';
import { DisplayableType } from '../../../../../models/enum/shared/displayableType.enum';

export abstract class PublishableDisplayableItemsViewModel extends DisplayableItemsViewModel {

  protected override defaultEmptyStateDescription = 'Create a template by using the button above.';

  protected override setEmptyState(
    searchText: string,
    tag: string,
    filterDisplayableItemBy: DisplayableItemFilterByActive,
    menuFormatFilter: DisplayableType,
    menuTypeFilter: string,
    hasInactiveItems: boolean,
    hasTemplatedItems: boolean,
    hasItemsToFilter: boolean
  ) {
    const title = this.getEmptyStateTitleCopy(menuTypeFilter);
    this.updateEmptyStateHelper(
      [searchText, tag, filterDisplayableItemBy, menuFormatFilter, menuTypeFilter],
      {
        noSearchTextNoTag: () => {
          const description = this.getEmptyStateDescriptionCopy(hasInactiveItems, hasTemplatedItems, false, false);
          this.updateEmptyStateInfo(hasItemsToFilter, title, description);
        },
        noSearchTextWithTag: () => {
          const description = this.getEmptyStateDescriptionCopy(hasInactiveItems, hasTemplatedItems, true, false);
          const withThatTag = ` with the tag \"${tag.toUpperCase()}\".`;
          this.updateEmptyStateInfo(hasItemsToFilter, title + withThatTag, description);
        },
        searchTextNoTag: () => {
          const description = this.getEmptyStateDescriptionCopy(hasInactiveItems, hasTemplatedItems, false, true);
          const couldNotBeFound = ` that include \"${searchText}\".`;
          this.updateEmptyStateInfo(hasItemsToFilter, title + couldNotBeFound, description);
        },
        searchTextWithTag: () => {
          const description = this.getEmptyStateDescriptionCopy(hasInactiveItems, hasTemplatedItems, true, true);
          const withThatTag = ` with the tag \"${tag.toUpperCase()}\".`;
          const withThatText = ` that include \"${searchText}\"`;
          this.updateEmptyStateInfo(hasItemsToFilter, title + withThatText + withThatTag, description);
        }
      }
    );
  }

  protected override generateInactiveStateFilterOptions() {
    const dropdownOptions: DropDownItem[] = [];
    const all = new DropDownItem('All', DisplayableItemFilterByActive.All);
    const active = new DropDownItem('Published', DisplayableItemFilterByActive.Active);
    const inactive = new DropDownItem('Draft', DisplayableItemFilterByActive.Inactive);
    dropdownOptions.push(all, active, inactive);
    this.containerViewModel.connectToInactiveStateFilterOptions(dropdownOptions);
  }

  public openCreateTemplateModal(mt: MenuType = MenuType.DisplayMenu): void {
    const onClose = (template: MenuTemplate) => {
      if (template) {
        this.navigationService.navigateToEditMenuOrTemplate(template);
      }
    };
    ModalCreateTemplate.open(this.ngZone, this.ngbModal, this.injector, mt, onClose);
  }

  protected override getEmptyStateDescriptionCopy(
      hasInactiveItems: boolean,
      hasTemplatedItems: boolean,
      hasTag: boolean,
      searchText: boolean
    ): string {
      let description: string;
      switch (true) {
        case searchText && !hasTag:
          description = 'Try a different search query to find the template that you\'re looking for.';
          break;
        case searchText && hasTag:
          description = 'Try a different search query or tag to find the template that you\'re looking for.';
          break;
        case hasTag && hasTemplatedItems:
          description = 'Try to filter by a different menu type or menu format.';
          break;
        case hasTag && !hasTemplatedItems:
          description = 'Try to filter by a different menu type or template status.';
          break;
        case !hasTemplatedItems:
        case hasInactiveItems:
          description = 'Try to filter by a different template status';
          break;
        default:
          description = 'Try to filter by a different menu format';
          break;
      }
      return description;
    }

}
