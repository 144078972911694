import { StringifyUtils } from '../../../../utils/stringify-utils';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Menu } from '../../../../models/menu/dto/menu';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MenuType } from '../../../../models/utils/dto/menu-type-definition';
import { CardLiveViewViewModel } from '../../shared/card-live-view-view-model';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class PrintShelfTalkerLiveViewViewModel extends CardLiveViewViewModel {

  private sendSectionIdSub: Subscription|null = null;
  private sendShelfTalkerMenuSub: Subscription|null = null;

  private readonly _shelfTalkerMenu = new BehaviorSubject<Menu|null>(null);
  public readonly shelfTalkerMenu$ = combineLatest([
    this._shelfTalkerMenu.deepCopy(),
    this.locationDisplayAttributes$,
    this.companyDisplayAttributes$
  ]).pipe(
    map(([shelfTalkerMenu, locationDisplayAttributes, companyDisplayAttributes]) => {
      return shelfTalkerMenu?.adjustDataForLiveView(locationDisplayAttributes, companyDisplayAttributes);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  connectToShelfTalkerMenu = (shelfTalkerMenu: Menu|null) => this._shelfTalkerMenu.next(shelfTalkerMenu);

  private readonly _sectionId = new BehaviorSubject<string|null>(null);
  public readonly sectionId$ = this._sectionId.pipe(distinctUntilChanged());
  connectToSectionId = (sectionId: string|null) => this._sectionId.next(sectionId);

  protected override generateLiveViewURL(menuType: MenuType): SafeResourceUrl {
    const cacheBuster = '?cacheBuster=' + Date.now() + Math.floor(Math.random() * 1000000);
    const env = (environment.production && environment.environmentName !== 'rc')
      ? ''
      : `${environment.environmentName}.`;
    const url = `https://${env}display.mybudsense.com/#/print-shelf-talker/live-view/${cacheBuster}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public readonly url$ = this.shelfTalkerMenu$.pipe(
    map(menu => this.generateLiveViewURL(menu?.type))
  );

  private sendSectionId(iFrame: HTMLIFrameElement): void {
    this.sendSectionIdSub?.unsubscribe();
    this.sendSectionIdSub = this.sectionId$.subscribeWhileAlive({
      owner: this,
      next: (sectionId) => {
        const msg = { sectionId };
        iFrame?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  private sendShelfTalkerMenu(iFrame: HTMLIFrameElement): void {
    this.sendShelfTalkerMenuSub?.unsubscribe();
    this.sendShelfTalkerMenuSub = this.shelfTalkerMenu$.subscribeWhileAlive({
      owner: this,
      next: (shelfTalkerMenu) => {
        const msg = { shelfTalkerMenu: JSON.parse(JSON.stringify(shelfTalkerMenu, StringifyUtils.displayAppReplacer)) };
        iFrame?.contentWindow?.postMessage(msg, '*');
      }
    });
  }

  public override sendDataToIFrame(iFrame: HTMLIFrameElement): void {
    super.sendDataToIFrame(iFrame);
    this.sendSectionId(iFrame);
    this.sendShelfTalkerMenu(iFrame);
  }

}
