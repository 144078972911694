import { TypeDefinition } from './type-definition';
import { ProductTableColumnConfig } from '../../menu/dto/product-table-column-config';

export enum ProductTableColumnConfigsIdValue {
  Default = 'Default',
  Cannabinoids = 'Cannabinoids',
  Terpenes = 'Terpenes',
  Custom = 'Custom'
}

export class ProductTableColumnConfigsType extends TypeDefinition {

  public override value: ProductTableColumnConfigsIdValue;
  public override metadata: ProductTableColumnConfig;

  override onDeserialize() {
    super.onDeserialize();
    this.metadata = window.injector.Deserialize.instanceOf(ProductTableColumnConfig, this.metadata);
  }

}
