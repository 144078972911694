<app-horizontal-scrolling-reactive-table
  #productPickerTable
  [makeTableElementVerticallyScrollable]="true"
  [addExtraSpaceToBottomOfTableInRem]="9"
  [data]="products"
  [nItemsToDisplay]="nItemsToDisplay"
  [nPagesInsidePaginationIndicator]="7"
  [resetPageOnNewData]="resetPageOnNewData"
  [resetPageOnSortingChange]="resetPageOnSortingChange">
  <app-product-picker-table-header></app-product-picker-table-header>
  <app-product-picker-table-item></app-product-picker-table-item>
  <app-product-picker-table-empty-state></app-product-picker-table-empty-state>
  <app-table-loading></app-table-loading>
  <lib-reactive-table-paginator></lib-reactive-table-paginator>
</app-horizontal-scrolling-reactive-table>
