import { DisplayMenuSectionViewModel } from '../../display-menu-section/display-menu-section-view-model';
import { Injectable, Injector, NgZone } from '@angular/core';
import { EditDisplayViewModel } from '../../../../viewModels/edit-display-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationService } from '../../../../../../services/navigation.service';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';
import { CompanyDomainModel } from '../../../../../../domainModels/company-domain-model';

@Injectable()
export class DisplayMenuSectionTemplateCollectionViewModel extends DisplayMenuSectionViewModel {

  constructor(
    editDisplayViewModel: EditDisplayViewModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    navigationService: NavigationService,
    menuDomainModel: MenuDomainModel,
    templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    userDomainModel: UserDomainModel,
    companyDomainModel: CompanyDomainModel
  ) {
    super(
      editDisplayViewModel,
      ngZone,
      ngbModal,
      injector,
      navigationService,
      menuDomainModel,
      templateDomainModel,
      locationDomainModel,
      userDomainModel,
      companyDomainModel
    );
    this.connectToWithinCollectionAssignedToDisplay(true);
  }

}
