import { Injectable, Injector, NgZone } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { TemplateCollection } from '../../../../../models/template/dto/template-collection';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayDomainModel } from '../../../../../domainModels/display-domain-model';
import { Display, LocationDisplayGrouping } from '../../../../../models/display/dto/display';
import { ModalCollectionViewProvinceLocations } from '../../../../../modals/modal-collection-view-province-locations';
import { ModalCollectionDisplays } from '../../../../../modals/modal-collection-displays';
import { RequiredDisplaysEditStep } from '../../modals/collection-displays-modal-container/collection-displays-modal-container-view-model';
import { PendingDisplay } from '../../../../../models/template/dto/pending-display';
import { TemplateStatus } from '../../../../../models/template/enum/template-status.enum';

@Injectable()
export class EditCollectionDisplaysViewModel extends BaseViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    private displayDomainModel: DisplayDomainModel,
    private ngZone: NgZone,
    private ngbModal: NgbModal,
    private injector: Injector
  ) {
    super();
  }

  get requiredDisplaysEditStep(): typeof RequiredDisplaysEditStep {
    return RequiredDisplaysEditStep;
  }

  public allLocations$ = this.locationDomainModel.allLocations$;
  public allDisplays$ = this.displayDomainModel.allCompanyDisplays$;

  private _collection = new BehaviorSubject<TemplateCollection>(null);
  public collection$ = this._collection as Observable<TemplateCollection>;
  public connectToCollection = (collection: TemplateCollection) =>  {
    this._collection.next(collection);
    this._pendingDisplay.next(collection?.pendingDisplay);
    this._changeRequiredDisplayIds.next(null);
  };

  public collectionIsPublished$ = this.collection$.pipe(map(c => c?.status === TemplateStatus.Published));

  /*
   * We need to use undefined as the initial state, or else I can't tell within
   * edit-collection-pending-display-form-item whether the pending display initialized or changed.
   * If the pending displays previous value was undefined, then I know it was initialized.
   */
  private _pendingDisplay = new BehaviorSubject<PendingDisplay>(undefined);
  public pendingDisplay$ = this._pendingDisplay as Observable<PendingDisplay>;

  public originalRequiredDisplayIds$ = this.collection$.pipe(map(c => c?.requiredDisplayIds));

  private _changeRequiredDisplayIds = new BehaviorSubject<string[]>(null);
  public changeRequiredDisplayIds$ = this._changeRequiredDisplayIds.pipe(distinctUntilChanged());
  public changeRequiredDisplayIds = (updatedRequiredDisplayIds: string[]) => {
    this._changeRequiredDisplayIds.next(updatedRequiredDisplayIds);
  };

  public requiredDisplayIds$ = combineLatest([
    this.changeRequiredDisplayIds$,
    this.originalRequiredDisplayIds$
  ]).pipe(
    map(([updated, original]) => (!!updated ? updated : original))
  );

  public activeDisplaysGroupedByLocationAndProvince$ = combineLatest([
    this.collection$,
    this.pendingDisplay$,
    this.requiredDisplayIds$,
    this.allDisplays$,
    this.allLocations$
  ]).pipe(
    map(([collection, pd, requiredDisplayIds, allDisplays, allLocations]) => {
      const activeOrRequiredDisplayIds = [
        ...(collection?.activeDisplayIds || []),
        ...(requiredDisplayIds || [])
      ].unique();
      const activeOrRequiredDisplays = Display.getDisplaysFromIds(activeOrRequiredDisplayIds, allDisplays);
      const pendingDisplays = Display.createDisplaysFromPendingDisplay(pd);
      const combinedDisplays = activeOrRequiredDisplays?.concat(pendingDisplays).unique();
      return Display.groupDisplaysByLocationAndProvince(combinedDisplays, allLocations);
    }),
    shareReplay({ bufferSize: 1, refCount: true})
  );

  public hasRequiredOrActiveDisplayIds$ = combineLatest([
    this.collection$,
    this.pendingDisplay$,
    this.requiredDisplayIds$
  ]).pipe(
    map(([col, pd, requiredDisplayIds]) => {
      return !!(requiredDisplayIds?.length || pd?.locationIds?.length || col?.activeDisplayIds?.length);
    })
  );

  public newDisplaysButtonText$: Observable<string> = this.pendingDisplay$.pipe(
    map(pd => (!!pd ? 'Edit New Displays' : 'Create New Displays'))
  );

  public provinceClicked(clickEvent: {[key: string]: LocationDisplayGrouping[]}): void {
    this.requiredDisplayIds$.once((requiredDisplayIds) => {
      const [province, locations] = Object.entries(clickEvent)?.firstOrNull() || [null, null];
      ModalCollectionViewProvinceLocations.open(
        this.ngZone,
        this.ngbModal,
        this.injector,
        province,
        locations,
        requiredDisplayIds
      );
    });
  }

  public editCollectionDisplays(step: RequiredDisplaysEditStep) {
    combineLatest([this.requiredDisplayIds$, this.pendingDisplay$, this.collection$]).once(([
      requiredDisplayIds,
      pendingDisplay,
      collection
    ]) => {
      const onSave = (result: any) => {
        if (result instanceof PendingDisplay) this._pendingDisplay.next(result);
        if (result instanceof Array) this.changeRequiredDisplayIds(result);
      };
      ModalCollectionDisplays.open(
        this.ngZone,
        this.ngbModal,
        this.injector,
        step,
        onSave,
        collection,
        requiredDisplayIds,
        pendingDisplay,
      );
    });
  }

}
