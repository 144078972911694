import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { ModalUtils } from '../utils/modal-utils';
import { ViewCollectionDisplaysComponent } from '../views/collection/components/modals/view-collection-displays/view-collection-displays.component';
import { LocationDisplayGrouping } from '../models/display/dto/display';

export class ModalCollectionViewProvinceLocations {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    province: string,
    locations: LocationDisplayGrouping[],
    requiredDisplayIds: string[]
  ) {
    ngZone.run(() => {
      const modalRef = ngbModal.open(
        ViewCollectionDisplaysComponent,
        ModalUtils.templateCollectionViewLocationsModalOptions(injector)
      );
      const compInst = modalRef.componentInstance as ViewCollectionDisplaysComponent;
      compInst.viewModel.connectToProvince(province);
      compInst.viewModel.connectToLocationDisplayGroupings(locations);
      compInst.viewModel.connectToRequiredDisplayIds(requiredDisplayIds);
    });
  }

}
