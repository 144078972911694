import { EditTemplateMenuViewModel } from '../edit-template-menu-view-model';
import { EditMenuViewModel } from '../../../../menu/viewModels/edit-menu-view-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable, Injector, NgZone } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export abstract class EditTemplateMarketingMenuViewModel extends EditTemplateMenuViewModel {

  protected constructor(
    protected override editMenuViewModel: EditMenuViewModel,
    templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(editMenuViewModel, templateDomainModel, locationDomainModel, ngZone, ngbModal, injector);
  }

  public override readonly crumbs$ = this.activeTemplate$.pipe(
    map(menu => {
      return [
        menu?.getViewAllBreadcrumb(false),
        menu?.getEditBreadcrumb(true)
      ];
    })
  );

  showLiveView = this.editMenuViewModel.showLiveView;

}
