import { SmartFilter } from './smart-filter';
import { Deserializable } from '../protocols/deserializable';
import { SelectableSmartFilter } from './protocols/selectable-smart-filter';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import type { Asset } from '../image/dto/asset';
import { SmartFilterCategory } from './smart-filter-category';
import { Pagable } from '../protocols/pagable';
import { HydratedSmartFilterProperties } from '../protocols/hydrated-smart-filter-properties';

export class HydratedSmartFilter extends SmartFilter
  implements Deserializable, SelectableSmartFilter, UniquelyIdentifiable, Pagable, HydratedSmartFilterProperties {

  public category: SmartFilterCategory;
  public appliedTemplateIds: string[];
  public appliedMenuIds: string[];
  public appliedLabelIds: string[];
  public appliedBadgeIds: string[];

  /**
   * These are hydrated by the main products pipeline, and can be used all through the app.
   */
  public appliedVariantIds: string[];    // not hydrated from API - done on client with web-worker
  public outOfStockVariantIds: string[]; // not hydrated from API - done on client with web-worker

  /**
   * These are meant for the add/edit smart filter modal, and are hydrated by the add/edit smart filter modal pipeline.
   * This is so that if someone is making changes to a smart filter, and doesn't save, then we don't affect the
   * source of truth (appliedVariantIds and outOfStockVariantIds) for what the smart filter is actually applied to.
   */
  public appliedVariantIdsForAddEditSmartFilter: string[];   // not hydrated from API - done on client with web-worker
  public outOfStockVariantIdsForEditedSmartFilter: string[]; // not hydrated from API - done on client with web-worker

  override onDeserialize() {
    super.onDeserialize();
    this.category = window?.injector?.Deserialize?.instanceOf(SmartFilterCategory, this.category);
  }

  /**
   * This gets calculated inside the smart filter variant matcher worker in the context of the all product table.
   */
  setSmartFilterVariantMatches(
    inStockVariantIds: string[] | null,
    outOfStockVariantIds: string[] | null
  ): this {
    this.appliedVariantIds = inStockVariantIds;
    this.outOfStockVariantIds = outOfStockVariantIds;
    return this;
  }

  /**
   * This gets calculated inside the smart filter variant matcher worker in the context of
   * the add/edit smart filter modal.
   */
  setSmartFilterVariantMatchesForEditedSmartFilter(
    inStockVariantIds: string[] | null,
    outOfStockVariantIds: string[] | null
  ): this {
    this.appliedVariantIdsForAddEditSmartFilter = inStockVariantIds;
    this.outOfStockVariantIdsForEditedSmartFilter = outOfStockVariantIds;
    return this;
  }

  getStandardizedCategoryName(): string {
    return this.category?.name?.trim()?.toLowerCase();
  }

  getSelectionName(): string {
    return this?.name;
  }

  getVariantHits(): number {
    return this?.appliedVariantIds?.length ?? 0;
  }

  getId(): string {
    return this.id;
  }

  some(filters: HydratedSmartFilter[]): boolean {
    const filterIds = filters?.map(filter => filter?.id);
    return filterIds?.some(id => id === this?.id);
  }

  /**
   * The same logic is copied over to the smart filter variant matcher worker, so if you make a change in here,
   * update the logic in smart-filter-variant-matcher.worker.ts as well.
   */
  isEmpty(): boolean {
    const filterOutTheseKeys = [
      'locationId', 'id', 'ignoredVariantIds', 'name',
      'appliedTemplateIds', 'appliedMenuIds', 'appliedLabelIds', 'appliedBadgeIds',
      'appliedVariantIds', 'outOfStockVariantIds',
      'appliedVariantIdsForAddEditSmartFilter', 'outOfStockVariantIdsForEditedSmartFilter',
      'dateCreated', 'lastUpdated', 'lastModified', 'advancedFilter', 'hidden', 'itemCount',
      'isCompanySmartFilter', 'isCuratedSmartFilter'
    ];
    return Object.entries(this)
      .filter(([key]) => !filterOutTheseKeys.includes(key))
      .every(([key, value]) => {
        switch (true) {
          case key === 'usePurpose':      return !value || value === 'All';
          case typeof value === 'number': return !Number.isFinite(value);
          case value instanceof Array:    return !value?.length;
          case value instanceof Map:      return !value?.size;
          default:                        return !value;
        }
      });
  }

  getNumOfFilters(): number {
    return null;
  }

  getGroupingImage(): Asset {
    return null;
  }

  getGroupedFilters(): HydratedSmartFilter[] {
    return [this];
  }

  getStartKey(): string {
    return this.id;
  }

  override getUniqueIdentifier(): string {
    return super.getUniqueIdentifier().concat(`
      -${this.category?.getUniqueIdentifier()}
      -${this.appliedMenuIds?.sort()?.join(',')}
      -${this.appliedVariantIds?.sort()?.join(',')}
      -${this.appliedLabelIds?.sort()?.join(',')}
      -${this.appliedBadgeIds?.sort()?.join(',')}
    `);
  }

}
