import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Menu } from '../../../../../models/menu/dto/menu';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { Card } from '../../../../../models/shared/stylesheet/card';
import { CardStyle } from '../../../../../models/shared/stylesheet/card-style.enum';
import { DefaultPrintStackSize } from '../../../../../models/enum/dto/default-print-stack-size';
import { TypeDefinition } from '../../../../../models/utils/dto/type-definition';
import { DistinctUtils } from '../../../../../utils/distinct-utils';

@Injectable()
export class StackSizePickerViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu as Observable<Menu>;
  connectToMenu = (menu: Menu) => this._menu.next(menu);

  /**
   * Order is determined by data being passed in.
   */
  private _cardStackSizesToShow = new BehaviorSubject<DefaultPrintStackSize[]>(null);
  public cardStackSizesToShow$ = this._cardStackSizesToShow.pipe(
    distinctUntilChanged(DistinctUtils.distinctStringLists)
  );
  connectToCardStackSizesToShow = (cardStackSizesToShow: DefaultPrintStackSize[]) => {
    this._cardStackSizesToShow.next(cardStackSizesToShow);
  };

  private _selectedSize = new BehaviorSubject<DefaultPrintStackSize>(null);
  public selectedSize$ = this._selectedSize.pipe(distinctUntilChanged());
  connectToSelectedSize = (selectedSize: DefaultPrintStackSize) => this._selectedSize.next(selectedSize);

  public sizeSelectionCards$ = combineLatest([
    this.menu$,
    this.cardStackSizesToShow$,
    this.selectedSize$,
    window.types.printCardSizeTypes$,
    window.types.printLabelSizeTypes$,
    window.types.printShelfTalkerSizeTypes$,
  ]).pipe(
    map(([
      menu,
      stackSizesToShow,
      selectedSize,
      printCardSizeTypes,
      printLabelSizeTypes,
      printShelfTalkerSizeTypes
    ]) => {
      const getTypeDef = <T extends TypeDefinition>(s: string, p: T[]) => p?.find(t => t?.getSelectionValue() === s);
      const cardSizes = stackSizesToShow?.map(size => getTypeDef(size, printCardSizeTypes))?.filterNulls();
      const labelSize = stackSizesToShow?.map(size => getTypeDef(size, printLabelSizeTypes))?.filterNulls();
      const shelfTalkerSizes = stackSizesToShow
        ?.map(size => getTypeDef(size, printShelfTalkerSizeTypes))
        ?.filterNulls();
      const getFirstNonEmptyArray = (...arrays: any[][]) => arrays.find(arr => arr.length > 0) || [];
      const cardOrLabelSizes = getFirstNonEmptyArray(labelSize, cardSizes, shelfTalkerSizes);
      const firstCardSize = cardOrLabelSizes?.firstOrNull()?.getSelectionValue();
      const selectedCard = !!selectedSize ? selectedSize : firstCardSize;
      if (!menu && !selectedSize) this.connectToSelectedSize(firstCardSize);
      const cardSizePickerCards: Card[] = [];
      cardOrLabelSizes.forEach(cardSize => {
        const cardSizeCard = new Card(cardSize.getNameFromSize(), cardSize.getDimensionString());
        cardSizeCard.id = cardSize.getUniqueIdentifier();
        cardSizeCard.data = cardSize;
        cardSizeCard.selected = selectedCard === cardSize?.getSelectionValue();
        cardSizeCard.cardStyle = CardStyle.PrintCardSizeCarouselImage;
        cardSizePickerCards.push(cardSizeCard);
      });
      return cardSizePickerCards;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
