import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Deserializable } from '../../protocols/deserializable';

export class CompanyFeatures implements Deserializable, UniquelyIdentifiable {

  public companyId: number;
  public supportsCustomDisplayNameParser: boolean;
  public supportsLocationLabels: boolean;
  public supportsTemplates: boolean;
  public supportsPrintCards: boolean;

  onDeserialize() {
  }

  getUniqueIdentifier(): string {
    return `
      ${this.companyId}
      ${this.supportsCustomDisplayNameParser}
      ${this.supportsLocationLabels}
      ${this.supportsTemplates}
      ${this.supportsPrintCards}
    `;
  }

}
