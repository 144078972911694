/* eslint-disable dot-notation */
import { Deserializable } from '../../protocols/deserializable';
import { HydratedVariantBadge } from '../../product/dto/hydrated-variant-badge';
import { SortUtils } from '../../../utils/sort-utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { LotInfo } from './lot-info';
import { Pagable } from '../../protocols/pagable';
import * as moment from 'moment';
import { MenuStyleObject } from '../../utils/dto/menu-style-object-type';
import type { Variant } from '../../product/dto/variant';
import type { Product } from '../../product/dto/product';
import { StringUtils } from '../../../utils/string-utils';
import { DisplayAttributeProperties } from '../../product/dto/display-attribute-properties';
import { Terpene } from '../../enum/dto/terpene';
import { Cannabinoid } from '../../enum/dto/cannabinoid';
import { EnumUtils } from '../../../utils/enum-utils';

export class DisplayAttribute implements Deserializable, Pagable, UniquelyIdentifiable, DisplayAttributeProperties {

  constructor(companyId?: number, locationId?: number, objectId?: string, objectType?: MenuStyleObject) {
    this.companyId = companyId;
    this.locationId = locationId;
    this.objectId = objectId;
    this.objectType = objectType;
    this.topTerpene = '';
    this.totalTerpene = '';
    this.minTotalTerpene = '';
    this.maxTotalTerpene = '';
    this.displayName = '';
    this.defaultLabel = null;
    this.badgeIds = null;
    this.initializeCannabinoidsAndTerpeneProperties();
  }

  public companyId: number;
  public id: string;
  public locationId: number;
  public objectId: string; // VariantId or ProductId
  public objectType: MenuStyleObject;
  public displayName: string;
  public badgeIds: string[];
  public badges: HydratedVariantBadge[];
  public defaultLabel: string;
  public lotInfo: LotInfo;
  public lastModified: number;
  public lastBulkModified: number;
  public smartBadgeIds: string[];
  public smartLabelId: string;
  public presentCannabinoids: Cannabinoid[];
  public topTerpene: string;
  // Total Terpene
  public totalTerpene: string;
  public minTotalTerpene: string;
  public maxTotalTerpene: string;
  public presentTerpenes: Terpene[];
  // Inherited
  inheritedDisplayAttribute: DisplayAttribute;

  // Cannabinoid and Terpene Properties are added via initializeCannabinoidsAndTerpeneProperties()
  // These properties are of type string.
  // cannabinoid example properties: THC, minTHC, maxTHC, CBLA, minCBLA, maxCBLA, TAC, minTAC, maxTAC, etc.
  // terpene example properties: bisabolol, minBisabolol, maxBisabolol, etc.
  // NOTE: terpene enums are capitalized, but terpene properties are camelCase.

  /**
   * Adds [cannabinoid], [minCannabinoid], and [maxCannabinoid] properties to the DisplayAttribute object.
   * Adds [terpene], [minTerpene], and [maxTerpene] properties to the DisplayAttribute object.
   * NOTE: terpene enums are capitalized, but terpene properties are camelCase.
   */
  protected initializeCannabinoidsAndTerpeneProperties(): void {
    EnumUtils.getPrimaryCannabinoids()?.forEach(cannabinoid => {
      this[cannabinoid] = this[cannabinoid] || '';
      this[`min${cannabinoid}`] = this[`min${cannabinoid}`] ?? null;
      this[`max${cannabinoid}`] = this[`max${cannabinoid}`] ?? null;
    });
    this.presentCannabinoids = Array.from(this.presentCannabinoids || []);
    EnumUtils.getSecondaryCannabinoids()?.forEach(secondaryCannabinoid => {
      this[secondaryCannabinoid] = this[secondaryCannabinoid] || '';
      this[`min${secondaryCannabinoid}`] = this[`min${secondaryCannabinoid}`] ?? null;
      this[`max${secondaryCannabinoid}`] = this[`max${secondaryCannabinoid}`] ?? null;
    });
    this.presentTerpenes = Array.from(this.presentTerpenes || []);
    EnumUtils.getTerpenes()?.forEach(terpene => {
      const camelCaseTerpene = StringUtils.toCamelCase(terpene);
      const pascalCaseTerpene = StringUtils.toPascalCase(terpene);
      this[camelCaseTerpene] = this[camelCaseTerpene] || null;
      this[`min${pascalCaseTerpene}`] = this[`min${pascalCaseTerpene}`] ?? null;
      this[`max${pascalCaseTerpene}`] = this[`max${pascalCaseTerpene}`] ?? null;
    });
  }

  static removeDisplayAttributeFrom(item: Product | Variant, toRemove: DisplayAttribute): void {
    if (item?.id === toRemove?.objectId) {
      const currentDisplayAttribute = item?.displayAttributes;
      switch (true) {
        case toRemove?.isLocationDA(): {
          item.displayAttributes = toRemove?.inheritedDisplayAttribute || null;
          break;
        }
        case toRemove?.isCompanyDA() && currentDisplayAttribute?.isLocationDA(): {
          item.displayAttributes.inheritedDisplayAttribute = null;
          break;
        }
        case toRemove?.isCompanyDA() && currentDisplayAttribute?.isCompanyDA(): {
          item.displayAttributes = null;
          break;
        }
      }
    }
  }

  static updateDisplayAttributeWithin(item: Product | Variant, toUpdate: DisplayAttribute): void {
    if (item?.id === toUpdate?.objectId) {
      const currentDisplayAttribute = item?.displayAttributes;
      switch (true) {
        case toUpdate?.isLocationDA(): {
          const inherited = item?.displayAttributes?.inheritedDisplayAttribute;
          item.displayAttributes = toUpdate;
          item.displayAttributes.inheritedDisplayAttribute = inherited;
          break;
        }
        case toUpdate?.isCompanyDA() && currentDisplayAttribute?.isLocationDA(): {
          item.displayAttributes.inheritedDisplayAttribute = toUpdate;
          break;
        }
        case toUpdate?.isCompanyDA() && currentDisplayAttribute?.isCompanyDA(): {
          item.displayAttributes = toUpdate;
          break;
        }
      }
    }
  }

  public getStartKey(): string {
    return this.id;
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    const inherited = this.inheritedDisplayAttribute;
    this.inheritedDisplayAttribute = Deserialize?.instanceOf(DisplayAttribute, inherited);
    this.lotInfo = Deserialize?.instanceOf(LotInfo, this.lotInfo);
    this.badgeIds = Array.from(this.badgeIds || []);
    // We currently sort badges by name alphabetically in the display app, so we want to match that here
    this.badges = Deserialize?.arrayOf(HydratedVariantBadge, this.badges)?.sort(SortUtils.sortBadgesByNameAscending);
    this.initializeCannabinoidsAndTerpeneProperties();
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/DisplayAttributeDTO.go
  public onSerialize() {
    const dto = Object.create(DisplayAttribute.prototype);
    dto.companyId = this.companyId;
    dto.id = this.id;
    dto.locationId = this.locationId;
    dto.objectId = this.objectId;
    dto.objectType = this.objectType;
    dto.displayName = this.displayName;
    dto.badgeIds = this.badgeIds;
    dto.defaultLabel = this.defaultLabel;
    dto.lotInfo = this.lotInfo;
    dto.smartBadgeIds = this.smartBadgeIds;
    dto.smartLabelId = this.smartLabelId;
    EnumUtils.getPrimaryCannabinoids()?.forEach(cannabinoid => {
      dto[cannabinoid] = this[cannabinoid];
      dto[`min${cannabinoid}`] = this[`min${cannabinoid}`];
      dto[`max${cannabinoid}`] = this[`max${cannabinoid}`];
    });
    EnumUtils.getSecondaryCannabinoids()?.forEach(secondaryCannabinoid => {
      dto[secondaryCannabinoid] = this[secondaryCannabinoid];
      dto[`min${secondaryCannabinoid}`] = this[`min${secondaryCannabinoid}`];
      dto[`max${secondaryCannabinoid}`] = this[`max${secondaryCannabinoid}`];
    });
    dto.presentCannabinoids = this.presentCannabinoids;
    dto.presentTerpenes = this.presentTerpenes;
    dto.totalTerpene = this.totalTerpene;
    dto.minTotalTerpene = this.minTotalTerpene;
    dto.maxTotalTerpene = this.maxTotalTerpene;
    dto.topTerpene = this.topTerpene;
    EnumUtils.getTerpenes()?.forEach(terpene => {
      const camelCaseTerpene = StringUtils.toCamelCase(terpene);
      const pascalCaseTerpene = StringUtils.toPascalCase(terpene);
      dto[camelCaseTerpene] = this[camelCaseTerpene];
      dto[`min${pascalCaseTerpene}`] = this[`min${pascalCaseTerpene}`];
      dto[`max${pascalCaseTerpene}`] = this[`max${pascalCaseTerpene}`];
    });
    return dto;
  }

  public getDisplayName(): string {
    return (this.displayName) ? this.displayName : this.inheritedDisplayAttribute?.displayName || null;
  }

  public getCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased: string): string {
    const accessor = cannabinoidOrTerpeneCamelCased;
    return this[accessor] || this.inheritedDisplayAttribute?.[accessor] || null;
  }

  public getMinCannabinoidOrTerpene(cannabinoidOrTerpenePascalCased: string): string {
    const accessor = `min${cannabinoidOrTerpenePascalCased}`;
    return this[accessor] || this.inheritedDisplayAttribute?.[accessor] || null;
  }

  public getMaxCannabinoidOrTerpene(cannabinoidOrTerpenePascalCased: string): string {
    const accessor = `max${cannabinoidOrTerpenePascalCased}`;
    return this[accessor] || this.inheritedDisplayAttribute?.[accessor] || null;
  }

  public setInheritedDisplayAttribute(da: DisplayAttribute) {
    this.inheritedDisplayAttribute = da;
  }

  getNumberOfInheritedBadges() {
    return this.badgeIds?.length || this?.inheritedDisplayAttribute?.getNumberOfInheritedBadges() || 0;
  }

  public getBadges(): HydratedVariantBadge[] {
    const displayAttributeBadges = this.badges ?? [];
    const inheritedDisplayAttributeBadges = this.inheritedDisplayAttribute?.badges ?? [];
    if (displayAttributeBadges.length > 0) {
      return displayAttributeBadges;
    } else if (inheritedDisplayAttributeBadges.length > 0) {
      return inheritedDisplayAttributeBadges;
    } else {
      return [];
    }
  }

  setLabel(key: string) {
    this.defaultLabel = key;
  }

  setInheritedDisplayAttributeLabel(key: string) {
    this.inheritedDisplayAttribute.setLabel(key);
  }

  segmentedId(): string {
    return `${this.id}-${this.companyId}${this.locationId}`;
  }

  isCompanyDA(): boolean {
    return this.companyId === this.locationId;
  }

  isLocationDA(): boolean {
    return this.companyId !== this.locationId;
  }

  getSimpleKey(): string {
    return `${this.objectId}-${this.objectType}-${this.locationId}-${this.companyId}`;
  }

  recentlyBulkUpdated(timeWindowInSeconds: number): boolean {
    // .unix() - outputs the number of seconds since the Unix Epoch
    return this.lastBulkModified >= moment().subtract(timeWindowInSeconds, 'seconds').unix();
  }

  isForProduct(): boolean {
    return this.objectType === MenuStyleObject.Product;
  }

  isForVariant(): boolean {
    return this.objectType === MenuStyleObject.Variant;
  }

  getUniqueIdentifier(): string {
    let uniqueId = `
      -${this.companyId}
      -${this.id}
      -${this.locationId}
      -${this.objectId}
      -${this.objectType}
      -${this.displayName}
      -${this.lastModified}
      -${this.lastBulkModified}
      -${this.badgeIds?.sort()?.join(',')}
      -${this.badges?.sort(SortUtils.sortBadges)?.map(b => b?.getUniqueIdentifier())?.join(',')}
      -${this.inheritedDisplayAttribute?.getUniqueIdentifier()}
      -${this.defaultLabel}
      -${this.presentCannabinoids?.sort()?.join(',')}
      -${this.presentTerpenes?.sort()?.join(',')}
      -${this.totalTerpene}
      -${this.minTotalTerpene}
      -${this.maxTotalTerpene}
      -${this.topTerpene}
    `;
    EnumUtils.getPrimaryCannabinoids()?.forEach(cannabinoid => {
      uniqueId += `-${this[cannabinoid]}`;
      uniqueId += `-${this[`min${cannabinoid}`]}`;
      uniqueId += `-${this[`max${cannabinoid}`]}`;
    });
    EnumUtils.getSecondaryCannabinoids()?.forEach(secondaryCannabinoid => {
      uniqueId += `-${this[secondaryCannabinoid]}`;
      uniqueId += `-${this[`min${secondaryCannabinoid}`]}`;
      uniqueId += `-${this[`max${secondaryCannabinoid}`]}`;
    });
    EnumUtils.getTerpenes()?.forEach(terpene => {
      const camelCaseTerpene = StringUtils.toCamelCase(terpene);
      const pascalCaseTerpene = StringUtils.toPascalCase(terpene);
      uniqueId += `-${this[camelCaseTerpene]}`;
      uniqueId += `-${this[`min${pascalCaseTerpene}`]}`;
      uniqueId += `-${this[`max${pascalCaseTerpene}`]}`;
    });
    return uniqueId;
  }

}

