import { Asset } from '../../image/dto/asset';
import { Orderable } from '../../protocols/orderable';
import { OrderableMenuAssetProperties } from '../../protocols/orderable-menu-asset-properties';

export class OrderableMenuAsset implements Orderable, OrderableMenuAssetProperties {

  public asset: Asset;
  public priority: number = 99999;
  public id: string;
  public titleOverride: string;

  constructor(id?: string, asset?: Asset, priority?: number) {
    if (id) {
      this.id = id;
    }
    if (asset && asset.isValid()) {
      this.asset = asset;
    }
    if (priority !== null && priority >= 0 && priority !== undefined) {
      this.priority = priority;
    }
  }

  onDeserialize() {
    this.asset = window?.injector?.Deserialize?.instanceOf(Asset, this.asset);
  }

  getOrderValue(): number {
    return this.priority;
  }

  getOrderableTitle(): string {
    return this.titleOverride || this.asset?.fileName;
  }

  getOrderableUniqueId(): string {
    return this.asset?.md5Hash || this.id;
  }

  setOrderableValue(val: number) {
    this.priority = val;
  }

}
