import { Pipe, PipeTransform } from '@angular/core';
import type { SortFunctions } from '../all-products-data-table/products-table/all-products-table-header/all-products-table-header.component';
import type { Product } from '../../../models/product/dto/product';

@Pipe({
  name: 'getProductSortingFunctionForCannabinoidOrTerpene'
})
export class GetProductSortingFunctionForCannabinoidOrTerpenePipe implements PipeTransform {

  transform(
    cannabinoidOrTerpene: string,
    sortFuncMap: Map<string, SortFunctions>
  ): ((a: Product, b: Product) => number) {
    return sortFuncMap?.get(cannabinoidOrTerpene)?.productSort;
  }

}
