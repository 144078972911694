export class EnumUtils {

  static createEnum<T extends string | number>(values: T[]): { [K in T]: K } {
    return values.reduce((prev, curr) => {
      prev[curr] = curr;
      return prev;
    }, {} as { [K in T]: K});
  }

}
