import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { SidebarSubItemListViewModel } from './sidebar-sub-item-list-view-model';
import { NavItem } from '../nav-item/models/nav-item';

@Component({
  selector: 'app-sidebar-sub-item-list',
  templateUrl: './sidebar-sub-item-list.component.html',
  styleUrls: ['./sidebar-sub-item-list.component.scss'],
  providers: [SidebarSubItemListViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarSubItemListComponent extends BaseComponent implements OnChanges {

  @Input() navItem: NavItem;
  @Input() currentNavUrl: string;
  @ViewChild('subList') subList: ElementRef;

  constructor(
    public viewModel: SidebarSubItemListViewModel,
  ) {
    super();
  }

  override setupViews() {
    this.viewModel.connectToNavItem(this.navItem);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.navItem) this.viewModel.connectToNavItem(this.navItem);
    if (changes.currentNavUrl) this.viewModel.connectToCurrentNavUrl(this.currentNavUrl);
  }

  public getListScrollHeight(): number {
    return this?.subList?.nativeElement?.scrollHeight;
  }

}
