import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../models/base/base-view-model';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { ProductTableColumnConfig } from '../../../../models/menu/dto/product-table-column-config';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductTableColumnConfigsIdValue } from '../../../../models/utils/dto/product-table-column-configs-type';

@Injectable()
export class CustomVisibleColumnsPickerViewModel extends BaseViewModel {

  constructor(
    private companyDomainModel: CompanyDomainModel
  ) {
    super();
  }

  public productTableColumnConfigs$ = window.types.productTableColumnConfigs$;
  public customProductTableColumnConfig$ = this.companyDomainModel.customProductTableColumnConfig$;

  private _selectedProductTableColumnConfigsIdValue = new BehaviorSubject<ProductTableColumnConfigsIdValue>(null);
  public selectedProductTableColumnConfigsIdValue$ =
    this._selectedProductTableColumnConfigsIdValue as Observable<string>;
  connectToSelectedProductTableColumnConfigsIdValue = (idValue: ProductTableColumnConfigsIdValue) => {
    this._selectedProductTableColumnConfigsIdValue.next(idValue);
  };

  public updateCustomProductTableColumnConfig(customProdudctTableColumnConfig: ProductTableColumnConfig) {
    this.companyDomainModel.setCustomProductTableColumnConfig(customProdudctTableColumnConfig);
  }

}
