<div class="modal-padding">
  <lib-reactive-form-drop-down
    [inputName]="'topTerpene'"
    [label]="'Top Terpene'"
    [placeholder]="'Select a top terpene'"
    [inlineLabel]="false"
    [required]="false"
    [disabled]="viewModel.isViewOnly$ | push"
    [tooltip]="'The smart filter will include products with a top terpene set here.'"
    [dropdowns]="viewModel.terpeneDropdowns$ | push"
    [clearable]="true"
    [addToAtLeastNItemsFilledInPool]="true"
    [bindingProperty]="'topTerpene'"
    [dispersedKey]="dispersedKey"></lib-reactive-form-drop-down>

  <lib-reactive-form-column-layout>
    <lib-reactive-form-number
      [inputName]="'minTotalTerpene'"
      [label]="'Minimum Total Terpene'"
      [placeholder]="'Minimum total terpene'"
      [inlineLabel]="false"
      [required]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [integersOnly]="false"
      [step]="0.01"
      [tooltip]="
        'The smart filter will include products with total terpene values greater than or equal to this value.'
      "
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'minTotalTerpene'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-number>

    <lib-reactive-form-number
      [inputName]="'maxTotalTerpene'"
      [label]="'Maximum Total Terpene'"
      [placeholder]="'Maximum total terpene'"
      [inlineLabel]="false"
      [required]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [integersOnly]="false"
      [step]="0.01"
      [tooltip]="'The smart filter will include products with total terpene values less than or equal to this value.'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'maxTotalTerpene'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-number>
  </lib-reactive-form-column-layout>

  <ng-container *rxFor="let terpene of viewModel.terpenes$ | push">
    <lib-reactive-form-column-layout nColumns="2">
      <lib-reactive-form-number
        [inputName]="'min' + terpene.key"
        [label]="'Minimum ' + terpene.text"
        [placeholder]="'Minimum ' + terpene.text + ' content of products'"
        [inlineLabel]="false"
        [required]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [integersOnly]="false"
        [step]="0.01"
        [tooltip]="
          'The smart filter will include products with ' + terpene.text + ' values greater than or equal to this value.'
        "
        [addToAtLeastNItemsFilledInPool]="true"
        [bindingProperty]="'min' + terpene.key"
        [dispersedKey]="dispersedKey">
      </lib-reactive-form-number>

      <lib-reactive-form-number
        [inputName]="'max' + terpene.key"
        [label]="'Maximum ' + terpene.text"
        [placeholder]="'Maximum ' + terpene.text + ' content of products'"
        [inlineLabel]="false"
        [required]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [integersOnly]="false"
        [step]="0.01"
        [tooltip]="
          'The smart filter will include products with ' + terpene.text + ' values less than or equal to this value.'
        "
        [addToAtLeastNItemsFilledInPool]="true"
        [bindingProperty]="'max' + terpene.key"
        [dispersedKey]="dispersedKey">
      </lib-reactive-form-number>
    </lib-reactive-form-column-layout>
  </ng-container>
</div>
