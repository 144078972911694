import type { WebSocketPayloadAction } from '../../actions/websocket-payload-action';
import { Deserializable } from '../../../protocols/deserializable';
import { exists } from '../../../../functions/exists';

export abstract class WebSocketSendMessage implements Deserializable {

  /**
   * LocationId or companyId, in our case within the client dashboard, it's always the locationId.
   */
  public entityId: number;

  /**
   * What action the backend should take with this message.
   */
  public action: WebSocketPayloadAction;

  /**
   * The payload to send to the backend.
   */
  public payload: any;

  onDeserialize() {}

  /**
   * If the payload implements onSerialize, then return an object that serializes the payload.
   */
  onSerialize(): any {
    return exists(this.payload?.onSerialize)
      ? this.getWithSerializedPayload()
      : this;
  }

  private getWithSerializedPayload(): any {
    return {
      entityId: this.entityId,
      action: this.action,
      payload: this.onSerialize()
    };
  }

}
