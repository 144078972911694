import { EventEmitter, Injectable, Injector, NgZone } from '@angular/core';
import { Menu } from '../../../models/menu/dto/menu';
import { FormInputItem } from '../../../models/shared/stylesheet/form-input-item';
import { BsError } from '../../../models/shared/bs-error';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { BudsenseFile } from '../../../models/shared/budsense-file';
import { LoadingSpinnerSize } from '../../../models/enum/shared/loading-spinner-size.enum';
import { MenuAssets } from '../../../models/menu/shared/menu-assets';
import { FormGroupComponent } from '../../shared/components/form-group/form-group.component';
import { BehaviorSubject, combineLatest, forkJoin, iif, interval, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, map, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { OrderableMenuAsset } from '../../../models/menu/shared/orderable-menu-asset';
import { Size } from '../../../models/shared/size';
import { DEFAULT_ROTATION_INTERVAL } from '../../../models/shared/display-options';
import { ASSET_RETRY_COUNT, ASSET_RETRY_DELAY } from '../../../utils/media-utils';
import { Asset } from '../../../models/image/dto/asset';
import { MenuMediaComponent } from '../components/shared/menu-media/menu-media.component';
import { MarketingTheme } from '../../../models/enum/dto/theme.enum';
import { ReorderOptions } from '../../../models/shared/stylesheet/reorder-options';
import { StringUtils } from '../../../utils/string-utils';
import { SortUtils } from '../../../utils/sort-utils';
import { DistinctUtils } from '../../../utils/distinct-utils';
import { Variant } from '../../../models/product/dto/variant';
import { PrioritySortableVariant } from '../../../models/product/shared/priority-sortable-variant';
import { Orientation } from '../../../models/utils/dto/orientation-type';
import { ModalMenuLiveView } from '../../../modals/modal-menu-live-view';
import { EditMenuViewModel } from './edit-menu-view-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { MenuDomainModel } from '../../../domainModels/menu-domain-model';
import { ProductDomainModel } from '../../../domainModels/product-domain-model';
import { TemplateDomainModel } from '../../../domainModels/template-domain-model';
import { NavigationService } from '../../../services/navigation.service';
import { ToastService } from '../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuTemplate } from '../../../models/template/dto/menu-template';
import { MenuPreviewService } from '../../../services/menu-preview.service';
import type { SectionMediaComponent } from '../components/shared/section-media/section-media.component';
import { PrintPDFService } from '../../../services/print-pdf.service';
import { UserDomainModel } from '../../../domainModels/user-domain-model';
import { DisplayDomainModel } from '../../../domainModels/display-domain-model';
import { TemplateCollectionDomainModel } from '../../../domainModels/template-collection-domain-model';
import { BulkPrintJobDomainModel } from '../../../domainModels/bulk-print-job-domain-model';

@Injectable()
export class EditMarketingMenuViewModel extends EditMenuViewModel {

  constructor(
    bulkPrintJobDomainModel: BulkPrintJobDomainModel,
    displayDomainModel: DisplayDomainModel,
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    productDomainModel: ProductDomainModel,
    templateDomainModel: TemplateDomainModel,
    templateCollectionDomainModel: TemplateCollectionDomainModel,
    userDomainModel: UserDomainModel,
    menuPreviewService: MenuPreviewService,
    printPDFService: PrintPDFService,
    navigationService: NavigationService,
    toastService: ToastService,
    router: Router,
    ngZone: NgZone,
    ngbModal: NgbModal,
    sanitizer: DomSanitizer,
    activatedRoute: ActivatedRoute,
    injector: Injector
  ) {
    super(
      bulkPrintJobDomainModel,
      displayDomainModel,
      locationDomainModel,
      menuDomainModel,
      productDomainModel,
      templateDomainModel,
      templateCollectionDomainModel,
      userDomainModel,
      menuPreviewService,
      printPDFService,
      navigationService,
      toastService,
      router,
      ngZone,
      ngbModal,
      sanitizer,
      activatedRoute,
      injector
    );
    this.subToOrderedMedia();
    this.subToUploadQueue();
  }

  public fileUploadLoadingOpts = new BehaviorSubject<LoadingOptions>(this.getFileUploadLoadingOptions());
  public breadcrumbs$ = this.menu$.pipe(
    map(menu => [
      menu?.getViewAllBreadcrumb(false),
      menu?.getEditBreadcrumb(true),
    ])
  );

  private _orderedMedia = new BehaviorSubject<OrderableMenuAsset[]>(null);
  public orderedMedia$ = this._orderedMedia as Observable<OrderableMenuAsset[]>;
  connectToOrderedMedia = (media: OrderableMenuAsset[]) => this._orderedMedia.next(media);

  // Orientation
  private _selectedOrientation = new BehaviorSubject<Orientation>(null);
  public selectedOrientation$ = this._selectedOrientation.pipe(distinctUntilChanged());
  public connectToSelectedOrientation = (o: Orientation) => this._selectedOrientation.next(o);
  private listenToMenu = this.menu$.subscribeWhileAlive({
    owner: this,
    next: menu => {
      if (!!menu?.displaySize?.orientation) {
        this.connectToSelectedOrientation(menu.displaySize.orientation);
      }
    }
  });
  public override isTemplatedMenu$ = this.menu$.pipe(map(menu => menu?.isTemplatedMenu()));
  public override menuName$ = this.menu$.pipe(debounceTime(100), map(menu => menu?.name));
  public override menuWarningMessage$ = this.menu$.pipe(map(it => it?.getMenuWarningMessage()), startWith(''));
  public override menuActive$ = this.menu$.pipe(debounceTime(100), map(menu => menu?.active));
  public theme$ = this.menu$.pipe(map(menu => menu?.hydratedTheme));
  public override initialDisplaySize$ = this.menu$
    .pipe(map(menu => menu?.displaySize))
    .deepCopy()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  // Data Transport Layer
  private _media = new BehaviorSubject<MenuAssets>(null);
  public media$ = this._media.asObservable();
  // Deleting Media
  public deleteAssetQueue = new BehaviorSubject<Asset[]>(null);
  public deleteFeatVariantIdQueue = new BehaviorSubject<string[]>(null);
  // Uploading Media
  public uploadedAssetQueue = new BehaviorSubject<BudsenseFile[]>(null);
  protected displayOptions$ = this.menu$.pipe(
    map(menu => menu?.options),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public orderableMediaSubject$ = combineLatest([
    this.displayOptions$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)),
    this._media.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)),
  ]).pipe(debounceTime(100));
  protected pollingForAssets = new BehaviorSubject<boolean>(false);
  protected pollingMechanism$ = combineLatest([
    this.uploadedAssetQueue,
    this.pollingForAssets,
  ]).pipe(debounceTime(10));

  // Title
  public title$ = this.menu$.notNull().pipe(map(m => m?.getEditMarketingMenuTitleFromSubtype()));
  public subtitle$ = this.menu$.notNull().pipe(map(m => m?.getMarketingMenuDescriptionFromSubtype()));

  // Media
  public featuredVariantIds$ = this.menu$.pipe(map(m => m?.variantFeature?.variantIds));
  public lengthOfFeaturedVariantIds$ = this.featuredVariantIds$.pipe(map(v => v?.length));
  public featuredVariants$ = combineLatest([
    this.featuredVariantIds$,
    this.productDomainModel.currentLocationVariants$
  ]).pipe(
    map(([variantIds, locationVariants]) => {
      const featuredVariants: Variant[] = [];
      variantIds?.forEach(id => {
        const Deserialize = window?.injector?.Deserialize;
        const variant = Deserialize?.instanceOf(Variant, locationVariants.find(v => v?.id === id));
        featuredVariants.push(variant);
      });
      return featuredVariants.filterNulls();
    })
  );
  public sortedFeaturedVariants$ = combineLatest([
    this.featuredVariants$.notNull(),
    this.menu$
  ]).pipe(
    debounceTime(10),
    map(([variants, menu]) => {
      const variantsToBeSorted: PrioritySortableVariant[] = [];
      variants?.forEach(v => {
        const variantPriority = menu?.options?.rotationOrder?.get(v?.id) || 0;
        variantsToBeSorted.push(new PrioritySortableVariant(variantPriority, v));
      });
      return variantsToBeSorted.sort((a, b) => SortUtils.variantsRotationPriorityAsc(a, b)).map(s => s.variant);
    })
  );

  public tempUploadedMedia: OrderableMenuAsset[] = [];
  public allowDelete$ = combineLatest([
    this.uploadedAssetQueue.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)),
    this.deleteAssetQueue.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray)),
    this.deleteFeatVariantIdQueue.pipe(distinctUntilChanged()),
    this.pollingForAssets,
  ]).pipe(
    map(([uploadAssetQueue, deleteAssetQueue, deleteFVQueue, polling]) => {
      // only allow delete once upload polling is complete
      return (!uploadAssetQueue || uploadAssetQueue.length === 0)
          && (!deleteAssetQueue || deleteAssetQueue.length === 0)
          && (!deleteFVQueue || deleteFVQueue.length === 0)
          && !polling;
    })
  );

  // Forms
  public canSubmitForm = new BehaviorSubject<boolean>(false);
  public showNewTagInput: boolean = false;
  public newTagClicked: EventEmitter<any> = new EventEmitter<any>();
  public useExistingTagClicked: EventEmitter<any> = new EventEmitter<any>();

  // Forms
  public override triggerAutoFormSubmission = new Subject<void>();
  public override unsavedChanges: boolean = false;

  // Disable save button
  public disableSaveButton$ = combineLatest([
    this.autoSaving$,
    this.canSubmitForm
  ]).pipe(
    map(([isSaving, canSubmitForm]) => isSaving || !canSubmitForm)
  );

  // Ordered variant Ids
  public orderedVariantIds$ = combineLatest([
    this.featuredVariantIds$.notNull(),
    this.menu$,
    this.media$.notNull(),
  ]).pipe(
    debounceTime(1),
    map(([ids, menu, _]) => {
      const sortedFeaturedVariantAssets: OrderableMenuAsset[] = [];
      ids.forEach((id) => {
        // Create an OrderableMenuAsset object for each id
        const variantPriority = menu?.options?.rotationOrder?.get(id) || 0;
        const menuAsset = new OrderableMenuAsset(id, null, variantPriority);
        sortedFeaturedVariantAssets.push(menuAsset);
      });
      return sortedFeaturedVariantAssets.sort(SortUtils.menuAssets).map(item => item.id);
    }),
    startWith([]),
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings),
    shareReplay({bufferSize: 1, refCount: true}),
    takeUntil(this.onDestroy)
  );
  private nVariantsLoaded: number = 0;
  private _loadingVariantsOverTime = new BehaviorSubject<boolean>(false);
  public loadingVariantsOverTime$ = this._loadingVariantsOverTime.pipe(distinctUntilChanged());
  public orderedVariantsOverTime$: Observable<Variant[]> = this.sortedFeaturedVariants$.notNull().pipe(
    tap(() => this._loadingVariantsOverTime.next(true)),
    switchMap((items) => {
      return this._loadingVariantsOverTime.pipe(
        switchMap((loadingOverTime) => {
          const loadOverTime$: Observable<Variant[]> = interval(10).pipe(
            map((_) => {
              if (this.nVariantsLoaded >= items?.length) {
                this._loadingVariantsOverTime.next(false);
              }
              this.nVariantsLoaded += 1;
              return items?.take(this.nVariantsLoaded);
            })
          );
          return iif(() => loadingOverTime, loadOverTime$, of(items));
        })
      );
    })
  );
  public hasVariants$ = this.orderedVariantIds$.pipe(map(ids => ids?.length > 0));
  public canReorder$ = this.orderedVariantIds$.pipe(map(ids => ids?.length > 1));

  // Child Forms
  public menuAssetItems: Map<string, FormInputItem[]> = new Map<string, FormInputItem[]>();
  public menuAssetComponents: MenuMediaComponent[] = [];
  public menuAssetForms: FormGroupComponent[] = [];
  public sectionAssetComponents: SectionMediaComponent[] = [];

  /* *************************** Local Threads of Execution *************************** */

  private loadTemplateAssets = this.fireIfTemplateMode$
    .pipe(switchMap(_ => this.activeTemplateId$))
    .subscribeWhileAlive({
      owner: this,
      next: templateId => {
        if (!!templateId) this.getMenuAssets(true, templateId);
      }
    });

  private loadMenuAssets = this.fireIfMenuMode$
    .pipe(switchMap(_ => this.activeHydratedMenuId$))
    .subscribeWhileAlive({
      owner: this,
      next: menuId => {
        if (!!menuId) this.getMenuAssets(false, menuId);
      }
    });

  /* ********************************************************************************** */

  protected getFileUploadLoadingOptions(): LoadingOptions {
    // Setup File Upload loading opts
    const opts = LoadingOptions.default();
    opts.backgroundColor = '#FFF';
    opts.spinnerSize = LoadingSpinnerSize.Default;
    return opts;
  }

  protected getThemesThatReplaceImagesInMap(): string[] {
    return Object.values(MarketingTheme)?.filter(themeId => themeId !== MarketingTheme.MarketingPlaylist);
  }

  public onBackgroundDeleted() {
    this.menu$.once(menu => this.menuDomainModel.stripMenuBackgroundAndUpdateHydratedMenuMap(menu?.id));
  }

  public trackMediaBy(index, item: OrderableMenuAsset) {
    return item.getOrderableUniqueId();
  }

  public override saveMenu(background: boolean = true) {
    combineLatest([
      this.menu$,
      this.orderedMedia$,
      this.initialDisplaySize$
    ]).once(([menu, orderedMedia, initialDisplaySize]) => {
      const loadingOpts = (background) ? this._autoSaveLoadingOpts : this._loadingOpts;
      const lm = StringUtils.replaceMenuWithTemplate('Saving Menu', menu instanceof MenuTemplate);
      // If Size changes, regenerate preview in background
      const forceRefreshPreview = !initialDisplaySize || initialDisplaySize?.hasChanges(menu.displaySize);
      this.addOptionsToMenu(menu, orderedMedia);
      if (!loadingOpts.containsRequest(lm)) {
        loadingOpts.addRequest(lm);
        const saveData$ = menu instanceof MenuTemplate
          ? this.templateDomainModel.saveMenuTemplate(menu)
          : this.menuDomainModel.saveMenu(menu);
        saveData$.subscribe({
          complete: () => {
            loadingOpts.removeRequest(lm);
            if (!background) this.toastService.publishSuccessMessage('Successful.', 'Saved Changes');
            this.setLastAutoSavedTimestampToNow();
            this.destroyAllTimerSubs();
            if (forceRefreshPreview) this.forceRefreshPreview();
          },
          error: (error: BsError) => {
            loadingOpts.removeRequest(lm);
            this.toastService.publishError(error);
            throwError(error);
          }
        });
      }
    });
  }

  public uploadFiles(files: BudsenseFile[]): Observable<any> {
    return this.menu$.pipe(
      take(1),
      switchMap(menu => {
        const fileUploadTxs = files.map(f => this.menuDomainModel.uploadMarketingMenuAsset(f, menu));
        return forkJoin(fileUploadTxs).pipe(
          map(_ => {
            this.addAssetsToUploadQueue(files);
            return true;
          })
        );
      })
    );
  }

  public override backgroundSaveMenuWithCompletion(
    getIntervalsFromMenuAssetComponents: boolean = true
  ): Observable<Menu> {
    return combineLatest([this.menu$, this.orderedMedia$, this.initialDisplaySize$]).pipe(
      take(1),
      switchMap(([menu, orderedMedia, initialDisplaySize]) => {
        const lm = StringUtils.replaceMenuWithTemplate('Saving Menu', menu instanceof MenuTemplate);
        const forceRefreshPreview = !initialDisplaySize || initialDisplaySize?.hasChanges(menu?.displaySize);
        if (!this._autoSaveLoadingOpts.containsRequest(lm)) {
          this._autoSaveLoadingOpts.addRequest(lm);
          if (getIntervalsFromMenuAssetComponents) {
            this.addOptionsToMenu(menu, orderedMedia);
          }
          const saveData$ = menu instanceof MenuTemplate
            ? this.templateDomainModel.saveMenuTemplate(menu)
            : this.menuDomainModel.saveMenu(menu);
          return saveData$.pipe(
            tap(_ => {
              this._autoSaveLoadingOpts.removeRequest(lm);
              if (forceRefreshPreview) this.forceRefreshPreview();
              this.setLastAutoSavedTimestampToNow();
              this.destroyAllTimerSubs();
            }),
            catchError((error: BsError) => {
              this._autoSaveLoadingOpts.removeRequest(lm);
              this.toastService.publishError(error);
              return throwError(error);
            })
          );
        }
      })
    );
  }

  public uploadFile(f: BudsenseFile): Observable<any> {
    return this.menu$.pipe(
      take(1),
      switchMap(menu => {
        return this.menuDomainModel
          .uploadMarketingMenuAsset(f, menu)
          .pipe(tap(_ => this.addAssetsToUploadQueue([f])));
      })
    );
  }

  public updateMenuAssetOrder(assets: OrderableMenuAsset[]): Observable<boolean> {
    return this.menu$.pipe(
      take(1),
      switchMap(menu => {
        // update order for menus
        const menuCopy = window?.injector?.Deserialize?.instanceOf(Menu, menu);
        menuCopy?.options?.rotationOrder?.clear();
        assets.forEach(it => menuCopy?.options?.rotationOrder?.set(it.id, it.priority));
        this.addOptionsToMenu(menuCopy, assets);
        const lm = StringUtils.replaceMenuWithTemplate('Saving Menu', menuCopy instanceof MenuTemplate);
        if (!this._autoSaveLoadingOpts.containsRequest(lm)) {
          const saveData$ = menuCopy instanceof MenuTemplate
            ? this.templateDomainModel.saveMenuTemplate(menuCopy)
            : this.menuDomainModel.saveMenu(menuCopy);
          return saveData$.pipe(delay(250), map(_ => true));
        } else {
          return of(false);
        }
      })
    );
  }

  public getNewlyUploadedAssets(files: BudsenseFile[], remainingRetries: number) {
    this.menu$.pipe(
      take(1),
      switchMap(menu => {
        return this.menuDomainModel.pollAssetsForNewFiles(menu, files).pipe(
          map(([pollingComplete, menuAssets, loadedFiles]) => {
            return [menu, pollingComplete, menuAssets, loadedFiles] as [Menu, boolean, MenuAssets, BudsenseFile[]];
          })
        );
      })
    ).subscribe(([menu, pollingComplete, menuAssets, loadedFiles]) => {
      if (pollingComplete) {
        this.removeAssetFromUploadQueue(loadedFiles);
        this._media.next(menuAssets);
        // Slight delay so the assets can be removed from queue first
        setTimeout(() => this.pollingForAssets.next(false), 250);
        // Set uploaded asset as enabled
        loadedFiles.forEach((loadedFile) => {
          const variantId = menu?.variantFeature?.getKeyFromAssetName(loadedFile.name);
          const assetComponent = this.menuAssetComponents.find(c => c.getMediaIdentifier() === variantId);
          if (!!variantId && !!assetComponent) {
            assetComponent.viewModel.req.interval = assetComponent.viewModel.req.interval > 0
              ? assetComponent.viewModel.req.interval
              : DEFAULT_ROTATION_INTERVAL;
            assetComponent.viewModel.req.loop = 1;
            assetComponent.viewModel.validateForm.next(true);
          }
        });
        setTimeout(() => this.saveMenu(true));
      } else {
        if (menuAssets) {
          // some files are loaded
          this.removeAssetFromUploadQueue(loadedFiles);
          this._media.next(menuAssets);
          this.saveMenu(true);
        }
        if (remainingRetries > 0) {
          const getUploadedAssets = () => {
            // Add delay before firing off recursive request
            const remainingFiles = files.filter(f => !loadedFiles.find(lf => lf.name === f.name));
            this.getNewlyUploadedAssets(remainingFiles, remainingRetries - 1);
          };
          setTimeout(getUploadedAssets, ASSET_RETRY_DELAY * 1000);
        } else {
          this.pollingForAssets.next(false);
        }
      }
    });
  }

  protected getMenuOrTemplateAfterSectionDeleted(menuId: string) {
    combineLatest([this.locationId$, this.menu$]).once(([locationId, menu]) => {
      const lm = StringUtils.replaceMenuWithTemplate('Loading Menu', menu instanceof MenuTemplate);
      if (!this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.addRequest(lm);
        const getData$ = menu instanceof MenuTemplate
          ? this.templateDomainModel.getHydratedMenuTemplate(locationId, menuId)
          : this.menuDomainModel.getHydratedMenu(menuId);
        getData$.subscribe({
          next: (m) => this._loadingOpts.removeRequest(lm),
          error: (error: BsError) => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishError(error);
            throwError(error);
          }
        });
      }
    });
  }

  protected getMenuAssets(isTemplate: boolean, id: string) {
    const lm = StringUtils.replaceMenuWithTemplate('Loading Menu Assets', isTemplate);
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      this.menuDomainModel.getMenuAssets(isTemplate, id).subscribe({
        next: (menuAsset) => {
          this._loadingOpts.removeRequest(lm);
          this._media.next(menuAsset);
        },
        error: (error: BsError) => {
          this._loadingOpts.removeRequest(lm);
          this.toastService.publishError(error);
          throwError(error);
        }
      });
    }
  }

  public pollForNewlyUploadedMedia(files: BudsenseFile[]) {
    if (files && files.length > 0) {
      this.pollingForAssets.next(true);
      this.getNewlyUploadedAssets(files, ASSET_RETRY_COUNT);
    }
  }

  public deleteFeaturedProduct(vId: string): Observable<any> {
    return combineLatest([this.menu$, this.orderedMedia$]).pipe(
      take(1),
      switchMap(([menu, orderedMedia]) => {
        const fileNameToDelete = menu?.variantFeature?.assetNameMap?.get(vId);
        let variantsUsingFile: string[] = [];
        if (fileNameToDelete) {
          const assetNames = menu?.variantFeature?.assetNameMap?.values();
          variantsUsingFile = Array.from(assetNames)?.filter(fn => fn === fileNameToDelete);
        }
        const removeFeatProdAndReturn = (shadowedVId: string): Observable<any> => {
          menu.removeFeaturedProduct(shadowedVId);
          return this.backgroundSaveMenuWithCompletion();
        };
        if (variantsUsingFile.length === 1) {
          // Only delete the asset if its only used by one feature variant
          const f = orderedMedia.find(om => om.asset?.fileName === fileNameToDelete);
          if (f?.asset) {
            return this.menuDomainModel.deleteAsset(f.asset).pipe(
              switchMap(_ => {
                this.removeAsset(f.asset);
                return removeFeatProdAndReturn(vId);
              })
            );
          }
        }
        return removeFeatProdAndReturn(vId);
      })
    );
  }

  public addAssetToRemoveQueue(img: Asset) {
    const imgToAdd = window?.injector?.Deserialize?.instanceOf(Asset, img);
    const deleteQueue = this.deleteAssetQueue.getValue() || [];
    deleteQueue.push(imgToAdd);
    this.deleteAssetQueue.next(deleteQueue);
  }

  public removeAssetFromRemoveQueue(img: Asset) {
    const deleteQueue = (this.deleteAssetQueue.getValue() || []).shallowCopy();
    const removeIndex = deleteQueue.findIndex(imgs => imgs.id === img.id);
    if (removeIndex > -1) {
      deleteQueue.splice(removeIndex, 1);
    }
    this.deleteAssetQueue.next(deleteQueue);
  }

  public addFeatureVariantToRemoveQueue(id: string) {
    const deleteQueue = this.deleteFeatVariantIdQueue.getValue() || [];
    deleteQueue.push(id);
    this.deleteFeatVariantIdQueue.next(deleteQueue.unique(true));
  }

  public removeFeatureVariantFromRemoveQueue(id: string) {
    const deleteQueue = this.deleteFeatVariantIdQueue.getValue() || [];
    const removeIndex = deleteQueue.indexOf(id);
    if (removeIndex > -1) {
      deleteQueue.splice(removeIndex, 1);
    }
    this.deleteFeatVariantIdQueue.next(deleteQueue.unique(true));
  }

  public forceRefreshPreview() {
    this.menu$.pipe(
      take(1),
      switchMap(menu => this.menuPreviewService.getMenuPreview(menu, true, 12000))
    ).subscribe();
  }

  public removeAsset(f: Asset) {
    combineLatest([this.menu$, this.orderedMedia$]).once(([menu, orderedMedia]) => {
      menu.removeAssetOptions(f.fileName);
      this.updateMediaAfterRemoval(menu, orderedMedia, f);
    });
  }

  // On Destroy
  override destroy() {
    super.destroy();
    this.clearUpdatedMenu();
    this.menuAssetComponents = [];
    this.menuAssetForms = [];
  }

  public addOptionsToMenu(menu: Menu, orderedMedia: OrderableMenuAsset[]) {
    this.applyTimeIntervalToMenu(menu, orderedMedia);
  }

  public applyTimeIntervalToMenu(menu: Menu, orderedMedia: OrderableMenuAsset[]) {
    menu.options.rotationInterval = menu.options.rotationInterval ?? new Map<string, number>();
    const durations: [string, number][] = this.menuAssetComponents
      ?.map(component => component?.getInterval())
      ?.filter(([id, duration]) => Number.isFinite(duration));
    orderedMedia?.forEach(m => {
      const [id, rotationDuration] = durations?.find(([identifier]) => identifier === m?.id) || [null, 0];
      if (!!id || (id === null && !menu.options.rotationInterval.has(m?.id))) {
        menu.options.rotationInterval.set(id, rotationDuration);
      }
    });
  }

  public replaceAsset(
    orderedMedia: OrderableMenuAsset[],
    existingFile: Asset,
    newFile: BudsenseFile,
    vId: string,
    useVariantId,
    skipDelete: boolean
  ): Observable<any> {
    const existingFileCopy = window?.injector?.Deserialize?.instanceOf(Asset, existingFile);
    // Delete the existing asset
    const deleteAsset$ = skipDelete
      ? of(true)
      : this.menuDomainModel.deleteAsset(existingFile);
    return deleteAsset$.pipe(
      switchMap(() => {
        // Upload new asset
        return this.uploadFile(newFile).pipe(
          switchMap(() => {
            // Replace asset options and save menu
            this.replaceOrderedMediaInfo(orderedMedia, existingFileCopy, newFile, vId, useVariantId);
            return this.replaceMediaOptions(existingFileCopy, newFile, vId, useVariantId);
          })
        );
      })
    );
  }

  protected subToUploadQueue() {
    this.pollingMechanism$.subscribeWhileAlive({
      owner: this,
      next: ([queue, polling]) => {
        if (queue && queue.length > 0 && !polling) {
          this.pollForNewlyUploadedMedia(queue);
        }
      }
    });
  }

  protected addAssetsToUploadQueue(files: BudsenseFile[]) {
    const assets = this.uploadedAssetQueue.getValue() || [];
    files.forEach(f => {
      if (f.isVideo()) {
        f.replaceWithWebm();
      }
      if (assets.findIndex(it => it.name === f.name) < 0) {
        assets.push(f);
      }
    });
    this.uploadedAssetQueue.next(assets);
  }

  protected removeAssetFromUploadQueue(files: BudsenseFile[]) {
    const assets = this.uploadedAssetQueue.getValue() || [];
    files.forEach(f => {
      const i = assets.findIndex(it => it.name === f.name);
      if (i > -1) {
        assets.splice(i, 1);
      }
    });
    this.uploadedAssetQueue.next(assets);
  }

  protected updateMediaAfterRemoval(menu: Menu, orderedMedia: OrderableMenuAsset[], f: Asset) {
    const i = orderedMedia?.findIndex(find => find?.asset?.md5Hash === f?.md5Hash);
    if (i > -1) {
      orderedMedia?.splice(i, 1);
      const updatedMedia = new MenuAssets(menu.id, orderedMedia?.map(it => it?.asset));
      this._media.next(updatedMedia);
    }
  }

  protected clearUpdatedMenu() {
    this.menu$.once(menu => {
      menu instanceof MenuTemplate
        ? this.templateDomainModel.clearActiveMenuTemplate()
        : this.menuDomainModel.deselectActiveHydratedMenu();
    });
  }

  public getReorderOptions(menu: Menu): ReorderOptions {
    const opts = new ReorderOptions();
    const marketingLoop = menu.theme === MarketingTheme.MarketingPlaylist;
    opts.title = marketingLoop ? 'Reorder Media' : 'Reorder Featured Variants';
    opts.subTitle = marketingLoop ? 'Media' : 'Featured Variants';
    opts.bodyText = 'Drag and drop items into the desired order.';
    opts.confirmText = 'Confirm Order';
    opts.loadingMess = 'Reordering Items';
    opts.successTitle = 'Successful Reorder';
    opts.successMess = 'Items reordered successfully.';
    opts.failureTitle = 'Reorder Error';
    opts.failureMess = 'Error updating item order.';
    return opts;
  }

  protected replaceOrderedMediaInfo(
    orderedMedia: OrderableMenuAsset[],
    existingFile: Asset,
    newFile: BudsenseFile,
    vId: string,
    useVariantId: boolean
  ) {
    const mediaToUpdate = orderedMedia?.find(om => {
      return useVariantId ? (om.id === vId) : (om.asset.fileName === existingFile.fileName);
    });
    mediaToUpdate.id = useVariantId ? vId : newFile.name;
    if (mediaToUpdate.asset) {
      mediaToUpdate.asset.fileName = StringUtils.normalizeCharacters(newFile.name);
      mediaToUpdate.asset.mediaType = newFile.getMediaType();
    }
  }

  protected replaceMediaOptions(
    existingFile: Asset,
    newFile: BudsenseFile,
    vId: string,
    useVariantId: boolean
  ): Observable<Menu> {
    return this.menu$.pipe(
      take(1),
      switchMap(menu => {
        const existingLookupVal = useVariantId ? vId : existingFile.fileName;
        const newLookupVal = useVariantId ? vId : newFile.name;
        const existingOrder = menu.options.rotationOrder.get(existingLookupVal)
          ? menu.options.rotationOrder.get(existingLookupVal)
          : 99;
        const existingInterval = menu.options.rotationInterval.get(existingLookupVal) ?? 0;
        if (existingLookupVal !== newLookupVal) {
          menu.removeAssetOptions(existingLookupVal);
          menu.options.rotationOrder.set(newLookupVal, existingOrder);
          menu.options.rotationInterval.set(newLookupVal, existingInterval);
        }
        if (existingInterval === 0) {
          // When uploading or replacing media, we want to enable the new media
          menu.options.rotationInterval.set(newLookupVal, DEFAULT_ROTATION_INTERVAL);
        }
        // set the assetNameMap
        const themesThatSetAssetMap = this.getThemesThatReplaceImagesInMap();
        if (useVariantId && themesThatSetAssetMap.contains(menu.theme)) {
          menu.variantFeature.assetNameMap = menu.variantFeature.assetNameMap || new Map<string, string>();
          menu.variantFeature.assetNameMap.set(vId, newFile.name);
        }
        return this.backgroundSaveMenuWithCompletion(false);
      })
    );
  }

  protected setDefaultOptionsForVariants(menu: Menu, variantIds: string[]): Observable<Menu> {
    // Set the next rotation order values for the new files
    let nextPriority = menu.getNextRotationOrderPriority();
    variantIds.forEach((vId) => {
      menu.options.rotationOrder.set(vId, nextPriority);
      menu.options.rotationInterval.set(vId, 0);
      nextPriority++;
    });
    return this.backgroundSaveMenuWithCompletion();
  }

  public addNewFeaturedVariants(variantIds: string[]): Observable<boolean> {
    return this.menu$.pipe(
      take(1),
      switchMap(menu => {
        const newIds = menu.getFeaturedVariantIds().concat(variantIds).unique();
        menu.setFeaturedVariantIds(newIds);
        return this.setDefaultOptionsForVariants(menu, variantIds).pipe(map(_ => true));
      })
    );
  }

  protected subToOrderedMedia() {
    combineLatest([
      this.featuredVariantIds$.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctSortedStrings)),
      this.menu$.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)),
      this._media.notNull().pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)),
      this.productDomainModel.currentLocationVariants$,
    ]).pipe(debounceTime(1), takeUntil(this.onDestroy)).subscribe(([ids, menu, assets, variants]) => {
      const sortedFeaturedVariantAssets: OrderableMenuAsset[] = [];
      ids.forEach((id) => {
        // Create an OrderableMenuAsset object for each id
        const variantAssetName = menu?.variantFeature?.assetNameMap?.get(id);
        const asset = assets?.assets?.filterNulls()?.find(a => a.fileName === variantAssetName);
        const variantPriority = menu?.options?.rotationOrder?.get(id) || 0;
        const menuAsset = new OrderableMenuAsset(id, asset, variantPriority);
        const variant = variants?.find(v => v.id === id);
        menuAsset.titleOverride = variant ? `${variant.getDisplayName()} (${variant.getSize()})` : asset?.fileName;
        sortedFeaturedVariantAssets.push(menuAsset);
      });
      const orderedMedia = sortedFeaturedVariantAssets.sort(SortUtils.menuAssets);
      this.connectToOrderedMedia(orderedMedia);
    });
  }

  public override openLiveViewModal(sizeOverride?: Size) {
    combineLatest([this.locationId$, this.menu$]).once(([locationId, menu]) => {
      ModalMenuLiveView.open(this.ngZone, this.ngbModal, this.injector, locationId, menu, sizeOverride);
    });
  }

}
