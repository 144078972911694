import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { CustomVisibleColumnsModalComponent } from '../views/shared/modals/custom-visible-columns-modal/custom-visible-columns-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { ProductTableColumnConfig } from '../models/menu/dto/product-table-column-config';
import { exists } from '../functions/exists';

export class ModalCustomVisibleColumns {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    customProductTableTableColumnConfig: ProductTableColumnConfig,
    onClose: (customColumnConfig: ProductTableColumnConfig) => void
  ): void {
    const modalRef = ngbModal.open(
      CustomVisibleColumnsModalComponent,
      ModalUtils.customVisibleColumnsModalOptions(injector)
    );
    const compInst = modalRef.componentInstance as CustomVisibleColumnsModalComponent;
    compInst.setCustomProductTableColumnConfig(customProductTableTableColumnConfig);
    modalRef.result.then((updatedCustomColConfig: ProductTableColumnConfig) => {
      if (exists(updatedCustomColConfig)) {
        onClose(updatedCustomColConfig);
      }
    }).catch(() => {});
  }

}
