<div
  class="list-item-container"
  matRipple
  [class.selected]="stackMenu | isSelectedId : selectedId"
  (click)="idSelected.emit(stackMenu?.id)">
  <img
    class="icon"
    [src]="
      !!stackMenu?.templateId ? ClientTypes.MenuIconSrc.TemplatedMenu : ClientTypes.MenuSectionTypeIcon.CategoryCard
    "
    alt="icon" />
  <div class="menu-name">{{ stackMenu?.name }}</div>
  <ng-container *ngIf="stackMenu | isShelfTalkerMenu as menu; else usesVariants">
    <img
      *ngIf="menu | getShelfTalkerSectionSelectionErrorTooltip : nSelectedItems as errorTooltip"
      class="problem-icon"
      [ngbTooltip]="errorTooltip"
      [container]="'body'"
      [src]="'assets/icons/red/outline/exclamation-circle.svg'"
      alt="" />
    <div class="n-items">{{ stackMenu | nShelfTalkersSelected : nSelectedItems }}</div>
  </ng-container>
  <ng-template #usesVariants>
    <img
      *ngIf="stackMenu | getStackVariantErrorTooltip : nSelectedItems | push as errorTooltip"
      class="problem-icon"
      [ngbTooltip]="errorTooltip"
      [container]="'body'"
      [src]="'assets/icons/red/outline/exclamation-circle.svg'"
      alt="" />
    <div class="n-items">{{ stackMenu | firstSectionNVariants : locationConfig : nSelectedItems | push }}</div>
  </ng-template>
</div>
