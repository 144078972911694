<app-info-banner *rxIf="viewModel.selectedSortOptionIsStrainClassification$" class="mb-16px">
  {{ viewModel.strainClassificationSortExplanation$ | push }}
</app-info-banner>

<lib-reactive-form-group
  #form
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedSection | templatedMenuDisabledFormControlTooltip"
  [customErrorMessageMap]="viewModel.customErrorMessageMap$ | push"
  (formAutoSubmitted$)="formAutoSubmitted.emit($event)"
  (disableSubmit$)="disableSubmit.emit($event)">
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-text
      [inputName]="'title'"
      [label]="'Section Title'"
      [placeholder]="'Enter a title for this section.'"
      [inlineLabel]="false"
      [required]="true"
      [minLength]="1"
      [maxLength]="128"
      [disabled]="isTemplatedSection"
      [bindingProperty]="'title'">
    </lib-reactive-form-text>

    <lib-reactive-form-drop-down
      #sectionType
      [inputName]="'sectionType'"
      [label]="'Section Type'"
      [placeholder]="'Select the type of section to create'"
      [inlineLabel]="false"
      [disabled]="true"
      [required]="true"
      [disabledTooltip]="''"
      [dropdowns]="viewModel.sectionTypes$ | push"
      [bindingProperty]="'sectionType'">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-drop-down
      [inputName]="'sorting'"
      [label]="'Primary Sort'"
      [placeholder]="'Select how variants should be sorted.'"
      [inlineLabel]="false"
      [required]="true"
      [dropdowns]="viewModel.primarySortingTypes$ | push"
      [bindingProperty]="'sorting'"
      [reinitializeWhenListChanges]="false"
      [disabled]="isTemplatedSection"
      (valueChanged)="viewModel.connectToSelectedPrimarySortOption($event)">
    </lib-reactive-form-drop-down>

    <!-- not required, because old menu sections will not have a secondary set -->
    <lib-reactive-form-drop-down
      [inputName]="'secondarySorting'"
      [label]="'Secondary Sort'"
      [disabled]="(viewModel.secondarySortDisabled$ | push) || isTemplatedSection"
      [placeholder]="viewModel.secondarySortPlaceholder$ | push"
      [tooltip]="viewModel.secondarySortTooltip$ | push"
      [inlineLabel]="false"
      [required]="false"
      [dropdowns]="viewModel.secondarySortingTypes$ | push"
      [bindingProperty]="'secondarySorting'"
      [reinitializeWhenListChanges]="false"
      [programmaticallyChangeValue]="viewModel.programmaticallyChangeSecondarySort$ | push"
      (valueChanged)="viewModel.connectToSelectedSecondarySortOption($event)">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>

  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-drop-down
      *rxIf="!(viewModel.isPrintReportMenu$ | push)"
      [inputName]="'layoutType'"
      [label]="'Layout'"
      [placeholder]="'Select how the section products should be laid out'"
      [inlineLabel]="false"
      [required]="true"
      [disabled]="(viewModel.disableLayoutTypeSelection$ | push) || isTemplatedSection"
      [dropdowns]="viewModel.layoutTypes$ | push"
      [bindingProperty]="'layoutType'"
      [emitInitializationValuesAsValueChanged]="true"
      (valueChanged)="viewModel.connectToSectionLayoutType($event)">
    </lib-reactive-form-drop-down>

    <lib-reactive-form-string-number
      *rxIf="viewModel.menu$ | push | MenuSupportsProductRowCount"
      [inputName]="'productRowCount'"
      [label]="'Product Row Count'"
      [placeholder]="'Show all products'"
      [inlineLabel]="false"
      [required]="false"
      [integersOnly]="true"
      [forceMinValueZeroOrLower]="0"
      [forceMaxValue]="1000"
      [customValidators]="viewModel.productRowCountValidator$ | push"
      [maxCharacterCount]="6"
      [tooltip]="'This value limits the number of products that will appear in this section. Enter 0 to show all'"
      [bindingProperty]="'rowCount'"
      [disabled]="isTemplatedSection"
      (valueChanged)="viewModel.connectToRowCount($event)">
    </lib-reactive-form-string-number>

    <lib-reactive-form-drop-down
      [inputName]="'saleLabelFormat'"
      [bindingProperty]="'saleLabelFormat'"
      [label]="'Sale Label Format'"
      [required]="false"
      [placeholder]="viewModel.saleFormatPlaceholder$ | push"
      [clearable]="true"
      [tooltip]="'The sale label format can be overridden at the section level.'"
      [dropdowns]="viewModel.saleFormatDropdowns$ | push"
      [disabled]="isTemplatedSection"></lib-reactive-form-drop-down>

    <lib-reactive-form-number
      *rxIf="viewModel.menu$ | push | isPlantlifeMenu"
      [inputName]="'autoBalanceThreshold'"
      [label]="'Auto-Balance Threshold'"
      [placeholder]="'Magic threshold number for auto balancing'"
      [tooltip]="
        'If the difference between the largest and second largest column exceeds this number, then auto balance.'
      "
      [integersOnly]="true"
      [required]="false"
      [maxValue]="100"
      [inlineLabel]="false"
      [disabled]="isTemplatedSection"
      [customValueParser]="viewModel.parseAsString"
      [bindingProperty]="'metadata.autoBalanceThreshold'">
    </lib-reactive-form-number>
  </lib-reactive-form-column-layout>

  <lib-reactive-form-row *rxIf="viewModel.showGridColumnsOptions$">
    <lib-reactive-form-checkboxes
      [customValueParser]="viewModel.parseSectionGridColumns"
      (valueChanged)="viewModel.connectToGridColumns($event)"
      [disabled]="
        (viewModel.autoUpdateGridColumns$ | push) ||
        (viewModel.disableGridColumnCheckboxes$ | push) ||
        isTemplatedSection
      "
      [disabledTooltip]="viewModel.selectAllGridColumnsDisabledTooltip$ | push"
      [disabledTooltipDelayMs]="75"
      [showErrorMessagesOnInitialization]="true"
      [bindingProperty]="'metadata.gridColumnNames'"
      [programmaticallyChangeValue]="viewModel.programmaticallyChangeGridOptionCheckboxes$ | push"
      [requireMinimumSelection]="1"
      [maximumSelections]="viewModel.maximumGridColumnSelections$ | push"
      [inputName]="'gridColumnNames'"
      [label]="'Grid Columns'"
      [checkAllText]="'Select All Grid Columns'"
      [inlineOptions]="true">
      <lib-reactive-form-checkbox
        *rxFor="let colName of viewModel.sectionLayoutGridColumns$"
        [inputName]="colName | replaceDotWithDash"
        [disabled]="
          (viewModel.section$ | push)?.autoUpdateGridColumns ||
          isTemplatedSection ||
          (colName
            | disableGridColumnCheckbox : viewModel.gridColumnsArray$ : viewModel.maximumGridColumnsReached$
            | push)
        "
        [disabledTooltip]="
          (viewModel.disabledCheckboxTooltip$ | push) ||
          (colName
            | disableGridColumnCheckbox : viewModel.gridColumnsArray$ : viewModel.maximumGridColumnsReached$
            | disableGridColumnCheckboxTooltip
            | push)
        "
        [disabledTooltipDelayMs]="75"
        [selectedValue]="colName"
        [notSelectedValue]="null">
        {{ colName | flowerGramsToOunces : viewModel.layoutType$ | push }}
      </lib-reactive-form-checkbox>
    </lib-reactive-form-checkboxes>
  </lib-reactive-form-row>
  <lib-reactive-form-row>
    <lib-reactive-form-checkbox
      #showZeroStock
      [inputName]="'showOutOfStockProducts'"
      [disabled]="(viewModel.sectionHasSmartFilters$ | push) || isTemplatedSection"
      [selectedValue]="true"
      [notSelectedValue]="false"
      [emitInitializationValuesAsValueChanged]="true"
      (valueChanged)="viewModel.connectToShowZeroStock($event)"
      [bindingProperty]="'showZeroStockItems'">
      Show Out of Stock Products
    </lib-reactive-form-checkbox>
  </lib-reactive-form-row>

  <lib-reactive-form-row *rxIf="viewModel.showZeroStockNote$">
    <app-note-text [fontSize]="'0.875rem'" [margin]="'1rem 0 0 0'">
      {{ viewModel.zeroStockNote$ | push }}
    </app-note-text>
  </lib-reactive-form-row>
</lib-reactive-form-group>
<app-edit-menu-warning-banner
  [warningMessage]="viewModel.sectionBannerWarningMessage$ | push"
  [tooltipMessage]="viewModel.sectionWarningMessageToolTip$ | push">
</app-edit-menu-warning-banner>
