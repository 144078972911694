<app-arrow-button
  *ngFor="let menuType of viewModel.menuTypes$ | async"
  (clicked)="viewModel.menuTypeSelected(menuType?.value)"
  [paddingLeft]="'0'"
  [paddingRight]="'0'"
  [title]="menuType?.name"
  [titleFontSize]="titleFontSize"
  [subtitle]="menuType?.description"
  [subtitleFontSize]="subtitleFontSize">
</app-arrow-button>
