import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { EditVariantContainer, TerpeneData } from '../../edit-variant-container';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { DisplayAttribute } from '../../../../../../../models/display/dto/display-attribute';
import { DistinctUtils } from '../../../../../../../utils/distinct-utils';
import { TerpeneUnitOfMeasure } from '../../../../../../../models/utils/dto/terpene-unit-of-measure-type';
import { exists } from '../../../../../../../functions/exists';

@Injectable()
export class EditVariantTerpenesViewModel extends BaseViewModel {

  constructor(
    public container: EditVariantContainer
  ) {
    super();
  }

  public terpeneUnitOfMeasureOptions$ = window.types.terpeneUnitOfMeasure$;

  public enabledTerpenes$ = this.container.enabledTerpenes$;
  public enabledTerpenesNamesData$ = this.container.enabledTerpenesNamesData$;
  public enabledTerpeneNames$ = this.container.enabledTerpeneNames$;
  public posSupportedEnabledTerpeneNames$ = this.container.posSupportedEnabledTerpeneNames$;
  public otherEnabledTerpeneNames$ = this.container.otherEnabledTerpeneNames$;
  public hasEnabledTerpenes$ = this.container.hasEnabledTerpenes$;
  public posSupportsPresentTerpenes$ = this.container.posSupportsPresentTerpenes$;
  public posSupportsTotalTerpene$ = this.container.posSupportsTotalTerpene$;
  public posSupportsTopTerpene$ = this.container.posSupportsTopTerpene$;
  public variant$ = this.container.variant$;
  public readonly companyUsesCannabinoidRange$ = this.container.companyUsesCannabinoidRange$;
  public readonly companyUsesTerpeneRange$ = this.container.companyUsesTerpeneRange$;

  private _selectedTUOM = new BehaviorSubject<TerpeneUnitOfMeasure>(TerpeneUnitOfMeasure.UNKNOWN);
  public selectedTUOM$ = this._selectedTUOM.pipe(distinctUntilChanged());

  private _updatedCompanyDA = new BehaviorSubject<DisplayAttribute>(null);
  public updatedCompanyDA$ = this._updatedCompanyDA.pipe(distinctUntilChanged());

  private _updatedLocationDA = new BehaviorSubject<DisplayAttribute>(null);
  public updatedLocationDA$ = this._updatedLocationDA.pipe(distinctUntilChanged());

  private distinctById = distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable);
  private initial = combineLatest([
    this.container.variant$.pipe(this.distinctById),
    this.container.variantLocationDisplayAttribute$.pipe(this.distinctById),
    this.container.variantCompanyDisplayAttribute$.pipe(this.distinctById)
  ]).subscribeWhileAlive({
    owner: this,
    next: ([variant, locationDA, companyDA]) => {
      this._selectedTUOM.next(variant?.terpeneUnitOfMeasure || TerpeneUnitOfMeasure.UNKNOWN);
      this._updatedCompanyDA.next(companyDA);
      this._updatedLocationDA.next(locationDA);
    }
  });

  public hasPosSupportedTerpenes$ = this.posSupportedEnabledTerpeneNames$.pipe(
    map(terpenes => terpenes?.length > 0)
  );
  public hasOtherEnabledTerpenes$ = this.otherEnabledTerpeneNames$.pipe(
    map(terpenes => terpenes?.length > 0)
  );

  public TUOMTooltip$ = this.container.syncTerpenesFromPOS$.pipe(
    map(isSync => {
      if (isSync) {
        return 'Editing the Terpene Unit of Measure is disabled when Sync Terpene from POS is enabled. '
          + 'This value will automatically be set based on the Default Terpene Unit of Measure. '
          + '(See Settings > Product > General)';
      } else {
        return 'The units of measure for terpenes such as Limoene of  within the product.';
      }
    })
  );

  public disableTerpeneInputs$: Observable<boolean> = combineLatest([
    this.container.showTerpenePOSSyncBanner$,
    this.selectedTUOM$
  ]).pipe(
    map(([showPOSSyncBanner, selectedTUOM]) => {
      return showPOSSyncBanner
        || selectedTUOM === TerpeneUnitOfMeasure.NA
        || selectedTUOM === TerpeneUnitOfMeasure.UNKNOWN;
    })
  );

  public disablePresentTerpenesInput$ = combineLatest([
    this.disableTerpeneInputs$,
    this.posSupportsPresentTerpenes$
  ]).pipe(
    map(([disableTerpeneInputs, posSupportsPresentTerpenes]) => {
      return disableTerpeneInputs && posSupportsPresentTerpenes;
    })
  );

  public disableTopTerpeneInput$ = combineLatest([
    this.disableTerpeneInputs$,
    this.posSupportsTopTerpene$
  ]).pipe(
    map(([disableTerpeneInputs, posSupportsTopTerpene]) => {
      return disableTerpeneInputs && posSupportsTopTerpene;
    })
  );

  public companyPresentTerpenePillPlaceholders$ = combineLatest([
    this.container.variant$,
    this.companyUsesTerpeneRange$,
    this.updatedCompanyDA$,
    this.enabledTerpenesNamesData$
  ]).pipe(
    map(([variant, companyUsesTerpeneRange, companyDA, enabledTerpeneNames]) => {
      const ranged = variant?.useTerpeneRange || companyUsesTerpeneRange;
      return this.getNonInheritedPresentTerpenesFromDisplayAttribute(ranged, companyDA, enabledTerpeneNames);
    })
  );

  public companyPresentTerpeneTextPlaceholder$ = combineLatest([
    this.updatedCompanyDA$,
    this.companyPresentTerpenePillPlaceholders$
  ]).pipe(
    map(([companyDA, presentTerpenePillPlaceholders]) => {
      const hasCompanySetPresentTerpenes = companyDA?.presentTerpenes?.length > 0;
      const hasPillPlaceholders = presentTerpenePillPlaceholders?.length > 0;
      switch (true) {
        case hasCompanySetPresentTerpenes:
          return '(Manual Override)';
        case hasPillPlaceholders:
          return '(Auto-calculated)';
        default:
          return 'Enter override present terpenes';
      }
    })
  );

  public locationPresentTerpenesFromDisplayAttributes$ = combineLatest([
    this.container.variant$,
    this.companyUsesTerpeneRange$,
    this.updatedLocationDA$,
    this.enabledTerpenesNamesData$
  ]).pipe(
    map(([variant, companyUsesTerpeneRange, locationDA, enabledTerpeneNames]) => {
      const ranged = variant?.useTerpeneRange || companyUsesTerpeneRange;
      return this.getNonInheritedPresentTerpenesFromDisplayAttribute(ranged, locationDA, enabledTerpeneNames);
    })
  );

  public locationPresentTerpenePillPlaceholders$ = combineLatest([
    this.updatedCompanyDA$,
    this.enabledTerpenesNamesData$,
    this.locationPresentTerpenesFromDisplayAttributes$,
    this.companyPresentTerpenePillPlaceholders$
  ]).pipe(
    map(([companyDA, enabledTerpenes, locationPresentTerpenesFromDA, companyPresentTerpenePlaceholders]) => {
      const hasCompanySetPresentTerpenes = companyDA?.presentTerpenes?.length > 0;
      const hasCompanyAutoCalculatedPlaceholders = companyPresentTerpenePlaceholders?.length > 0;
      const companySetTerpenes = companyDA?.presentTerpenes?.map(terpene => {
        const selection = enabledTerpenes?.find(t => t?.terpeneName === terpene);
        return selection?.terpeneName;
      });
      switch (true) {
        case hasCompanySetPresentTerpenes:
          return companySetTerpenes;
        case hasCompanyAutoCalculatedPlaceholders:
        case locationPresentTerpenesFromDA?.length > 0:
          return locationPresentTerpenesFromDA;
        default:
          return null;
      }
    })
  );

  public locationPresentTerpeneTextPlaceholder$ = combineLatest([
    this.updatedLocationDA$,
    this.updatedCompanyDA$,
    this.locationPresentTerpenePillPlaceholders$
  ]).pipe(
    map(([locationDA, companyDA, locationPresentTerpenePillPlaceholders]) => {
      const hasLocationSetPresentTerpenes = locationDA?.presentTerpenes?.length > 0;
      const hasCompanySetPresentSecondaryCannabinoids = companyDA?.presentTerpenes?.length > 0;
      const hasLocationPillPlaceholders = locationPresentTerpenePillPlaceholders?.length > 0;
      switch (true) {
        case hasLocationSetPresentTerpenes:
          return '(Manual Override)';
        case hasLocationPillPlaceholders && hasCompanySetPresentSecondaryCannabinoids:
          return '(Company Default)';
        case hasLocationPillPlaceholders:
          return '(Auto-calculated)';
        default:
          return 'Select preset secondary cannabinoids';
      }
    })
  );

  public companyTopTerpenePlaceholder$: Observable<string|null> = combineLatest([
    this.variant$,
    this.enabledTerpenesNamesData$,
    this.updatedCompanyDA$
  ]).pipe(
    map(([variant, enabledTerpenes, companyDisplayAttributes]) => {
      const topTerpene = variant.getAutoCalculatedTopTerpene(enabledTerpenes, null, [companyDisplayAttributes]);
      if (exists(companyDisplayAttributes.topTerpene)) {
        return `${companyDisplayAttributes.topTerpene} (Company Default)`;
      }
      if (exists(topTerpene)) {
        return `${topTerpene} (Auto-calculated)`;
      }
      return 'Select top terpene to override';
    })
  );

  public locationTopTerpenePlaceholder$: Observable<string|null> = combineLatest([
    this.variant$,
    this.enabledTerpenesNamesData$,
    this.updatedLocationDA$,
    this.updatedCompanyDA$
  ]).pipe(
    map(([variant, enabledTerpenes, locationDisplayAttribute, companyDisplayAttribute]) => {
      const locationTopTerpeneExplicitlySet = exists(locationDisplayAttribute?.topTerpene);
      const companyTopTerpeneExplicitlySet = exists(companyDisplayAttribute?.topTerpene);
      const topTerpene = variant.getAutoCalculatedTopTerpene(enabledTerpenes, null, [locationDisplayAttribute]);
      switch (true) {
        case locationTopTerpeneExplicitlySet:
          return `${locationDisplayAttribute.topTerpene} (Location Default)`;
        case companyTopTerpeneExplicitlySet:
          return `${companyDisplayAttribute.topTerpene} (Company Default)`;
        case exists(topTerpene):
          return `${topTerpene} (Auto-calculated)`;
        default:
          return 'Select top terpene to override';
      }
    })
  );

  public tuomSelectionChanged = (tuom: TerpeneUnitOfMeasure) => {
    this._selectedTUOM.next(tuom);
  };

  public companyDAUpdated(value: string, accessor: string) {
    this.updatedCompanyDA$.once(companyDA => {
      const companyDACopy = Object.assign(new DisplayAttribute(), companyDA);
      companyDACopy[accessor] = value;
      this._updatedCompanyDA.next(companyDACopy);
    });
  }

  public locationDAUpdated(value: string, accessor: string) {
    this.updatedLocationDA$.once(locationDA => {
      const locationDACopy = Object.assign(new DisplayAttribute(), locationDA);
      locationDACopy[accessor] = value;
      this._updatedLocationDA.next(locationDACopy);
    });
  }

  public companyDAForTerpenePresenceUpdated(
    value: string,
    accessor: string,
    minAccessor: string,
    maxAccessor: string
  ) {
    this.updatedCompanyDA$.once(companyDA => {
      const companyDACopy = Object.assign(new DisplayAttribute(), companyDA);
      companyDACopy[accessor] = value;
      companyDACopy[minAccessor] = value;
      companyDACopy[maxAccessor] = value;
      this._updatedCompanyDA.next(companyDACopy);
    });
  }

  public locationDAForTerpenePresenceUpdated(
    value: string,
    accessor: string,
    minAccessor: string,
    maxAccessor: string
  ) {
    this.updatedLocationDA$.once(locationDA => {
      const locationDACopy = Object.assign(new DisplayAttribute(), locationDA);
      locationDACopy[accessor] = value;
      locationDACopy[minAccessor] = value;
      locationDACopy[maxAccessor] = value;
      this._updatedLocationDA.next(locationDACopy);
    });
  }

  /**
   * Only look at the display attribute passed in, and NOT it's inherited values.
   * Calling da?.getCannabinoidOrTerpene, da?.getMinCannabinoidOrTerpene, and da?.getMaxCannabinoidOrTerpene in here
   * is wrong, because it will return an inherited value from the companyDA (which we don't want).
   */
  getNonInheritedPresentTerpenesFromDisplayAttribute(
    useRange: boolean,
    da: DisplayAttribute,
    enabledTerpenes: TerpeneData[]
  ): string[] {
    return enabledTerpenes?.filter(terpeneHelper => {
      if (useRange) {
        return this.hasNumericValueGreaterThanZero(da?.[`min${terpeneHelper?.pascalCased}`])
            || this.hasNumericValueGreaterThanZero(da?.[`max${terpeneHelper?.pascalCased}`]);
      } else {
        return this.hasNumericValueGreaterThanZero(da?.[terpeneHelper?.camelCased]);
      }
    })?.map(terpeneHelper => terpeneHelper?.terpeneName);
  }

  private hasNumericValueGreaterThanZero(terpeneVal: string): boolean {
    return exists(terpeneVal) && parseFloat(terpeneVal) > 0;
  }

}
