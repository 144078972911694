import { ChangeDetectorRef, Component, forwardRef, Inject, ViewRef } from '@angular/core';
import { AllProductsTableHeaderComponent } from '../../../all-products-data-table/products-table/all-products-table-header/all-products-table-header.component';
import { ProductPickerDataTableViewModel } from '../../product-picker-data-table-view-model';
import { ReactiveTableHeaderBluePrintComponent } from '@mobilefirstdev/reactive-table';

@Component({
  selector: 'app-product-picker-table-header',
  templateUrl: './product-picker-table-header.component.html',
  styleUrls: ['./product-picker-table-header.component.scss'],
  providers: [
    {
      provide: ReactiveTableHeaderBluePrintComponent,
      useExisting: forwardRef(() => ProductPickerTableHeaderComponent)
    }
  ]
})
export class ProductPickerTableHeaderComponent extends AllProductsTableHeaderComponent {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    public override viewModel: ProductPickerDataTableViewModel, // global provided by root
  ) {
    super(viewRef, viewModel);
  }

}
