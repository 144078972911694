import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { SecondaryCannabinoid } from '../../enum/dto/secondary-cannabinoid';
import { Terpene } from '../../enum/dto/terpene';

export class EnabledCompounds implements Deserializable, UniquelyIdentifiable {

  public enabledCannabinoids: SecondaryCannabinoid[];
  public enabledTerpenes: Terpene[];

  onDeserialize(): void {
    this.enabledCannabinoids = Array.from(this.enabledCannabinoids || []);
    this.enabledTerpenes = Array.from(this.enabledTerpenes || []);
  }

  getUniqueIdentifier(): string {
    return `${this.enabledCannabinoids.join(',')}-${this.enabledTerpenes.join(',')}`;
  }

}
