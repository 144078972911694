import { TypeCheckUtils } from './type-check-utils';

export {};
declare global {
  interface Array<T> {
    contains(v: T): boolean;
    unique(ignoreEmpty?: boolean): Array<T>;
    uniqueInstance(ignoreEmpty?: boolean): T;
    uniqueByProperty(property: string, ignoreNull?: boolean): Array<T>;
    uniqueInstanceByProperty(property: string, ignoreNull?: boolean): T;
    filterNulls(): Array<T>;
    filterFalsies(): Array<T>;
    equals(arr: T[]): boolean;
    last(): T;
    toStringArray(): Array<string>;
    intersection(arr: T[]): T[];
    flatMap<V>(x: (y: T) => V, depth?: number): V;
    flatten<V>(depth?: number): V;
    shallowCopy(): T[];
    deepCopy(): Array<T>;
    firstOrNull(): T;
    take<T>(n: number): T[];
    remove(item: T): T[];
    chunkedList(chunkSize: number): T[][];
    mode(): T;
  }
}

Array.prototype.contains = function(v) {
  for (let i = 0; i < this.length; i++) {
    if (this[i] === v) {
      return true;
    }
  }
  return false;
};

Array.prototype.unique = function(ignoreEmpty: boolean = true) {
  const arr = [];
  for (let i = 0; i < this.length; i++) {
    if (!arr.contains(this[i])) {
      if (ignoreEmpty) {
        if (this[i] !== null && this[i] !== undefined && this[i] !== '') {
          arr.push(this[i]);
        }
      } else {
        arr.push(this[i]);
      }
    }
  }
  return arr;
};

Array.prototype.uniqueInstance = function(ignoreEmpty: boolean = false) {
  const uniqueVals = this.unique(ignoreEmpty);
  if (uniqueVals.length === 1) {
    return uniqueVals[0];
  } else {
    return null;
  }
};

Array.prototype.uniqueByProperty = function(property: string, ignoreNull: boolean = true) {
  const uniqueProps = [];
  const arr = [];
  for (const item of this) {
    if (!!item && item.hasOwnProperty(property)) {
      const uniqueProp = item[property];
      if (!uniqueProps.contains(uniqueProp)) {
        if (ignoreNull) {
          if (!!item) {
            uniqueProps.push(uniqueProp);
            arr.push(item);
          }
        } else {
          uniqueProps.push(uniqueProp);
          arr.push(item);
        }
      }
    }
  }
  return arr;
};

Array.prototype.uniqueInstanceByProperty = function(property: string, ignoreNull: boolean = true) {
  return this.uniqueByProperty(property, ignoreNull).firstOrNull();
};

Array.prototype.filterNulls = function() {
  return this.filter(v => v !== null && v !== undefined);
};

Array.prototype.filterFalsies = function() {
  return this.filter(v => !!v);
};

Array.prototype.equals = function(array) {
  if (!array) {
    return false;
  }
  if (this.length !== array.length) {
    return false;
  }
  for (let i = 0, l = this.length; i < l; i++) {
    if (this[i] instanceof Array && array[i] instanceof Array) {
      if (!this[i].equals(array[i])) {
        return false;
      }
    } else if (this[i] !== array[i]) {
      return false;
    }
  }
  return true;
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, 'equals', {enumerable: false});

Array.prototype.last = function() {
  if (this.length === 0) {
    return null;
  } else {
    return this[this.length - 1];
  }
};

Array.prototype.toStringArray = function() {
  return this.map(v => v?.toString());
};

Array.prototype.intersection = function <T>(compare: T[]): T[] {
  if (!compare) {
    return [];
  }
  const res = [];
  const {length: len1} = this;
  const {length: len2} = compare;
  const smaller = (len1 < len2 ? this : compare).slice();
  const bigger = (len1 >= len2 ? this : compare).slice();
  for (let i = 0; i < smaller.length; i++) {
    if (bigger.indexOf(smaller[i]) !== -1) {
      res.push(smaller[i]);
      bigger.splice(bigger.indexOf(smaller[i]), 1, undefined);
    }
  }
  return res;
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, 'intersection', {enumerable: false});

/**
 * Use to return Array.prototype.concat.apply([], this.map(lambda));
 * - Array.prototype.concat.apply([], ...) spreads elements manually. O(n²) in worst case
 * - Each concat call results in copying array elements into a new array, leading to repeated memory allocations.
 * - flat() is specifically designed for this purpose and handles data more efficiently. O(n) (optimized internally)
 * - flat() iterates through the array once and directly inserts values, avoiding unnecessary memory copying.
 */
Array.prototype.flatMap = function(lambda, depth?: number) {
  return this.map(lambda).flat(depth);
};

Array.prototype.flatten = function<V>(depth?: number): V {
  return this.flatMap(it => it, depth);
};

Array.prototype.shallowCopy = function<T>(): T[] {
  return [...this];
};

Array.prototype.deepCopy = function() {
  const copy = [];
  this.forEach(val => {
    const isPrimitive = TypeCheckUtils.isPrimitive(val);
    if (isPrimitive) {
      copy.push(JSON.parse(JSON.stringify(val)));
    } else if (val instanceof Array) {
      copy.push(val.deepCopy());
    } else {
      copy.push(window?.injector?.Deserialize?.instanceOf(val.constructor, val));
    }
  });
  return copy;
};

Array.prototype.firstOrNull = function() {
  return this.length > 0 ? this[0] : null;
};

Array.prototype.take = function<T>(n: number): T[] {
  return this.slice(0, n);
};

Array.prototype.remove = function<T>(item: T): T[] {
  const index = this.indexOf(item);
  if (index !== -1) {
    this.splice(index, 1);
  }
  return this;
};

/**
 * An extension function that returns a 2D array of the original array paginated into sub-arrays up to length n.
 * If the last page does not have n elements, it will be shorter than n.
 * This extension method does not mutate the original array.
 *
 * @return A 2D paginated array of the original.
 */
Array.prototype.chunkedList = function(chunkSize: number): any[][] {
  const res = [];
  if (this.length < 1) return [];
  for (let i = 0; i < this.length; i += chunkSize) {
    res.push(this.slice(i, i + chunkSize));
  }
  return res;
};

/**
 * An extension function that returns the mode of the array.
 * The mode is the value that appears most frequently in the array.
 */
Array.prototype.mode = function<T>(): T | null {
  const frequency = new Map();
  let modeVal = null;
  let maxFreq = 0;
  for (const val of this) {
    const count = (frequency.get(val) || 0) + 1;
    frequency.set(val, count);
    if (count > maxFreq) {
      maxFreq = count;
      modeVal = val;
    }
  }
  return modeVal;
};
