import { PrimaryCannabinoid } from '../models/enum/shared/primary-cannabinoid.enum';
import { SecondaryCannabinoid } from '../models/enum/dto/secondary-cannabinoid';
import { Terpene } from '../models/enum/dto/terpene';
import { cacheResult } from '../decorators/cache-result';

export class EnumUtils {

  static createEnum<T extends string | number>(values: T[]): { [K in T]: K } {
    return values.reduce((prev, curr) => {
      prev[curr] = curr;
      return prev;
    }, {} as { [K in T]: K});
  }

  @cacheResult
  static getPrimaryCannabinoids(): PrimaryCannabinoid[] {
    return Object.values(PrimaryCannabinoid);
  }

  @cacheResult
  static getSecondaryCannabinoids(): SecondaryCannabinoid[] {
    return Object.values(SecondaryCannabinoid);
  }

  @cacheResult
  static getTerpenes(): Terpene[] {
    return Object.values(Terpene);
  }

}
