<div
  matRipple
  class="selection-container"
  [style.cursor]="viewOnly ? 'not-allowed' : 'pointer'"
  (click)="containerSectionClicked.emit(section.id)">
  <div class="title-and-size bs-medium">
    <span class="f12px">{{ section?.title }}</span>
  </div>
  <app-selectable
    [selectedIds]="selectedIds"
    [selection]="section"
    (add)="addSectionClicked.emit($event)"
    (remove)="removeSectionClicked.emit($event)">
  </app-selectable>
</div>
