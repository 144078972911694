import { DisplayableItemPreviewViewModel, REFRESH_MENU_PREVIEW_THRESHOLD_MINUTES } from '../displayable-item-preview/displayable-item-preview-view-model';
import { inject, Injectable, Injector, NgZone } from '@angular/core';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { ToastService } from '../../../../../../services/toast-service';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { BsError } from '../../../../../../models/shared/bs-error';
import { debounceTime, delay, map, switchMap } from 'rxjs/operators';
import { DropDownMenuItem, DropDownMenuSection } from '../../../../../../models/shared/stylesheet/drop-down-menu-section';
import { DisplayableAction } from '../../../../../../models/menu/enum/menu-action.enum';
import { Size } from '../../../../../../models/shared/size';
import { ModalConfirmation } from '../../../../../../modals/modal-confirmation';
import { ConfirmationOptions } from '../../../../../../models/shared/stylesheet/confirmation-options';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { ModalMenuLiveView } from '../../../../../../modals/modal-menu-live-view';
import { ModalDuplicateMenu } from '../../../../../../modals/modal-duplicate-menu';
import { DateUtils } from '../../../../../../utils/date-utils';
import { MenuDisplayableSubItemService } from '../displayable-item-preview/menu-displayable-sub-item.service';
import { PreviewService } from '../../../../../../services/preview-service';
import { PreviewOf } from '../../../../../../models/enum/shared/preview-of';
import { ModalPrintMenuLiveView } from '../../../../../../modals/modal-print-menu-live-view';

@Injectable()
export class TemplatePreviewViewModel extends DisplayableItemPreviewViewModel {

  constructor(
    protected templateDomainModel: TemplateDomainModel,
    protected menuSubItemService: MenuDisplayableSubItemService,
    protected menuPreviewService: MenuPreviewService,
    menuDomainModel: MenuDomainModel,
    locationDomainModel: LocationDomainModel,
    toastService: ToastService,
    navigationService: NavigationService,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
  ) {
    super(
      menuDomainModel,
      locationDomainModel,
      toastService,
      navigationService,
      ngZone,
      ngbModal,
      injector
    );
  }

  protected override _displayableItem: BehaviorSubject<MenuTemplate>;
  public override displayableItem$: Observable<MenuTemplate>;

  public override itemSubItems$ = this.displayableItem$.pipe(
    switchMap(item => this.menuSubItemService.displayableSubItemList(item))
  );

  public useLandscapeAspectRatio$: Observable<boolean> = of(true);
  public usePortraitAspectRatio$: Observable<boolean> = of(false);

  public dropDownMenuSections$ = this.displayableItem$.pipe(
    map(template => {
      const dropDownMenuSections = [];
      const sectionItems = [];
      sectionItems.push(new DropDownMenuItem('Edit', DisplayableAction.Edit));
      if (!template?.isPrintMenuOrPrintReportMenu()) {
        sectionItems.push(new DropDownMenuItem('Live View', DisplayableAction.LiveView));
      }
      sectionItems.push(new DropDownMenuItem('Duplicate', DisplayableAction.Duplicate));
      sectionItems.push(
        new DropDownMenuItem('Delete Template', DisplayableAction.Delete, new Set<string>().add('red-text'))
      );
      const section = new DropDownMenuSection(null, sectionItems);
      dropDownMenuSections.push(section);
      return dropDownMenuSections;
    })
  );

  protected loadItemPreviews(menuTemplate: MenuTemplate, locId: number) {
    combineLatest([
      this.templateDomainModel.menuTemplates$,
      this.locationId$,
      this.previewLoadingBundle$
    ]).once(([templates, locationId, previewLoadingBundle]) => {
      const id = menuTemplate?.id;
      const key = PreviewService.getPreviewKey(menuTemplate?.id, locationId, PreviewOf.MenuImage);
      const returnLastSaved = !this.menuPreviewService.isPreviewInProgress(key);
      const lm = 'Getting Preview';
      const loadingOpts = previewLoadingBundle?.get(id);
      if (!loadingOpts?.containsRequest(lm) && !this.isRefreshing) {
        this.isRefreshing = true;
        loadingOpts?.addRequest(lm);
        const cacheImageDelay = returnLastSaved ? 500 : 12000;
        const getMenuPreview = this.menuPreviewService.getMenuPreview;
        getMenuPreview(menuTemplate, returnLastSaved, cacheImageDelay, undefined, false, true).subscribe({
          next: menuTemplatePreview => {
            if (menuTemplatePreview) {
              this._itemPreviews.next([menuTemplatePreview]);
              // Add delay to account for saving of new preview
              this.isRefreshing = false;
              loadingOpts?.removeRequest(lm);
              const timestamp = menuTemplatePreview?.preview?.timestamp;
              const refresh = !DateUtils.unixAfterMinutesAgo(timestamp, REFRESH_MENU_PREVIEW_THRESHOLD_MINUTES);
              if (refresh) this.refreshItemPreviewInBackground(menuTemplate);
            }
          },
          error: (err: BsError) => {
            this.isRefreshing = false;
            loadingOpts?.removeRequest(lm);
            this.toastService.publishError(err);
            throwError(() => err);
          }
        });
      }
    });
  }

  protected listenForPreviewChanges(): void {
    combineLatest([
      this.displayableItem$,
      inject(MenuPreviewService).previews$,
      this.locationId$
    ]).pipe(debounceTime(100)).subscribeWhileAlive({
      owner: this,
      next: ([menuTemplate, menuPreviews, locationId]) => {
        const contentIds = menuTemplate?.displayableItemPreviewContentIds(locationId);
        const keys = contentIds?.map(ids => {
          const key = ids instanceof Array ? ids?.firstOrNull() : ids;
          return PreviewService.getPreviewKey(key, locationId, PreviewOf.MenuImage);
        });
        const previews = keys?.map(key => menuPreviews?.get(key))?.filterNulls();
        this._itemPreviews.next(previews ?? null);
      }
    });
  }

  protected refreshItemPreviewInBackground(t: MenuTemplate) {
    combineLatest([
      this.templateDomainModel.menuTemplates$,
      this.refreshLoadingBundle$
    ]).once(([templates, refreshLoadingBundle]) => {
      const lm = 'Refreshing Preview';
      const refreshLoadingOpts = refreshLoadingBundle?.get(t?.id);
      if (!refreshLoadingOpts?.containsRequest(lm) && !this.isRefreshing) {
        this.isRefreshing = true;
        refreshLoadingOpts?.addRequest(lm);
        const imageDelay = 12000;
        const getMenuPreview = this.menuPreviewService.getMenuPreview;
        getMenuPreview(t, false, imageDelay, undefined, false, true).subscribe({
          next: (tp) => {
            this._itemPreviews.next([tp]);
            this.isRefreshing = false;
            refreshLoadingOpts?.removeRequest(lm);
          },
          error: (err: BsError) => {
            this.isRefreshing = false;
            refreshLoadingOpts?.removeRequest(lm);
            throwError(() => err);
          }
        });
      }
    });
  }

  public openEditItem(): void {
    this.displayableItem$.once(t => {
      this.navigationService.navigateToEditMenuOrTemplate(t);
    });
  }

  public openDeleteItemModal() {
    this.displayableItem$.once(t => {
      const opts = new ConfirmationOptions();
      opts.title = 'Delete Template';
      opts.bodyText = `Are you sure you want to delete the following template: \'${t?.name}\''? `
        + `The templated menu will be deleted from all locations it is used at, and removed from any `
        + `displays or template collections. This action cannot be undone.`;
      opts.cancelText = 'Cancel';
      opts.continueText = 'Delete';
      const confirmation = (cont) => {
        if (cont) {
          this.deleteItem(t);
        }
      };
      ModalConfirmation.open(this.ngbModal, this.injector, opts, confirmation);
    });
  }

  protected deleteItem(template: MenuTemplate) {
    const lm = 'Deleting Template';
    if (!this._loadingOpts.containsRequest(lm)) {
      this._loadingOpts.addRequest(lm);
      this.templateDomainModel.deleteMenuTemplate(template).pipe(delay(250)).subscribe((_) => {
        // Add small delay so updated templates can propagate
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Template deleted successfully.', 'Template Deleted');
      }, (error: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
        throwError(error);
      });
    }
  }

  public openLiveViewModal(sizeOverride?: Size) {
    combineLatest([this.locationId$, this.displayableItem$]).once(([locationId, template]) => {
      switch (true) {
        case template?.isPrintMenuOrPrintReportMenu():
          ModalPrintMenuLiveView.open(this.ngZone, this.ngbModal, this.injector, template);
          break;
        default:
          ModalMenuLiveView.open(this.ngZone, this.ngbModal, this.injector, locationId, template, sizeOverride);
      }
    });
  }

  public copyItemURL() {
  }

  openDuplicateItemModal() {
    this.displayableItem$.once(m => ModalDuplicateMenu.open(this.ngZone, this.ngbModal, this.injector, m));
  }

}
