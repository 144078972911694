<hr *ngIf="showTopLine" />
<div class="smart-filter-card-deck mt-4">
  <div class="parent-row">
    <div class="gradient-row">
      <div class="category-row">
        <!--      All Products Card-->
        <app-smart-filter-category-card
          class="ms-2"
          [smartFilterGrouping]="viewModel.allProductsGrouping"
          (cardClicked)="viewModel.clearAllSmartFilters()">
        </app-smart-filter-category-card>
        <!--      Curated Category Cards-->
        <app-smart-filter-category-card
          *ngFor="
            let sfg of viewModel.curatedSmartFilterGroups$ | async;
            trackBy: viewModel.trackBySelectableSmartFilterId
          "
          [smartFilterGrouping]="sfg"
          (cardClicked)="cardClicked.emit($event); viewModel.applySmartFilterCategory($event)">
        </app-smart-filter-category-card>
        <ng-container *ngIf="viewModel.isLoading$ | async" [style.gap]="'1rem'">
          <lib-loading-shimmer
            *ngFor="let number of [0, 1, 2, 3, 4, 6, 7]"
            [width]="'12rem'"
            [height]="'3.5rem'"
            [shape]="'rect'"
            [borderRadius]="'0.625rem'">
          </lib-loading-shimmer>
        </ng-container>
      </div>
    </div>
    <mat-accordion class="ms-2" multi [displayMode]="'flat'">
      <mat-expansion-panel
        #panel
        class="mat-elevation-z0"
        [expanded]="viewModel.openAccordion"
        (click)="viewModel.toggleAccordion()">
        <mat-expansion-panel-header class="no-background"> </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <mat-accordion multi [displayMode]="'flat'">
    <mat-expansion-panel #panel class="mat-elevation-z0" [expanded]="viewModel.openAccordion">
      <div class="ms-1">
        <div class="title">Applied Smart Filters</div>
        <div *ngIf="(viewModel.appliedSmartFilters$ | async)?.length === 0" class="mt-2">
          If you select a Smart Filter category card from above, the individually applied Smart Filters will appear
          here.
        </div>
      </div>
      <div class="applied-filter-row ms-2" *ngIf="(viewModel.appliedSmartFilters$ | async)?.length > 0">
        <app-smart-filter-card
          *ngFor="let sf of viewModel.appliedSmartFilters$ | async; trackBy: viewModel.trackBySmartFilterId"
          [smartFilter]="sf"
          (removeClicked)="viewModel.removeSmartFilter($event)">
        </app-smart-filter-card>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<hr />
