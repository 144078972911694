import { Component, Injector, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../../../../services/toast-service';
import { ActivatedRoute, Router } from '@angular/router';
import { EditFeaturedProductMenuViewModel } from './edit-featured-product-menu-view-model';
import { EditMarketingMenuComponent } from '../edit-marketing-menu.component';

@Component({
  selector: 'app-edit-featured-product-menu',
  templateUrl: './edit-featured-product-menu.component.html',
  styleUrls: [
    './edit-featured-product-menu.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  providers: [EditFeaturedProductMenuViewModel]
})
export class EditFeaturedProductMenuComponent extends EditMarketingMenuComponent {

  constructor(
    public override viewModel: EditFeaturedProductMenuViewModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    toastService: ToastService,
    router: Router,
    activatedRoute: ActivatedRoute,
  ) {
    super(viewModel, ngZone, ngbModal, injector, toastService, router, activatedRoute);
  }

}
