import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { Menu } from '../../../../models/menu/dto/menu';
import { PrintShelfTalkerLiveViewViewModel } from './print-shelf-talker-live-view-view-model';
import { CardLiveViewComponent } from '../../shared/card-live-view.component';

/**
 * The iFrame makes scaling complicated. It doesn't scale content how you'd expect.
 * That is why the scale value gets sent to the parent container.
 */
@Component({
  selector: 'app-print-shelf-talker-live-view',
  templateUrl: './print-shelf-talker-live-view.component.html',
  styleUrls: ['./print-shelf-talker-live-view.component.scss'],
  providers: [PrintShelfTalkerLiveViewViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintShelfTalkerLiveViewComponent extends CardLiveViewComponent {

  constructor(
    viewModel: PrintShelfTalkerLiveViewViewModel
  ) {
    super(viewModel);
  }

  @Input() @ViewModelConnector('connectToShelfTalkerMenu') shelfTalkerMenu: Menu;
  @Input() @ViewModelConnector('connectToSectionId') sectionId: string;

}
