<div class="card-stack-select-all">
  <div class="f12px bs-medium">Select all sections</div>
  <div [hidden]="!(groupSelectionComponent.nChildrenSelected$ | push)" class="checked-count bs-bold">
    ({{ groupSelectionComponent.nChildrenSelected$ | push }})
  </div>
  <app-group-selection
    #groupSelectionComponent
    [selectedIds]="sectionIds"
    [selection]="selection"
    (bulkAdd)="bulkAddSelection.emit($event)"
    (bulkRemove)="bulkRemoveSelection.emit($event)">
  </app-group-selection>
</div>
