import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { PosProductCategoryGroupProperties } from '../../protocols/pos-product-category-group-properties';

export class PosProductCategoryGroup
  implements Deserializable, UniquelyIdentifiable, PosProductCategoryGroupProperties {

  public id: string;
  public name: string;
  public categoryIds: string[];

  onDeserialize() {
    this.categoryIds = Array.from(this.categoryIds || []);
  }

  getUniqueIdentifier(): string {
    return `
      -${this.id}
      -${this.name}
      -${this.categoryIds.sort()}
    `;
  }

}

