import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { LotInfoBannerViewModel } from './lot-info-banner-view-model';
import type { DisplayAttribute } from '../../../../../models/display/dto/display-attribute';
import type { EditVariantModalTabId } from '../../../../../models/enum/shared/edit-variant-modal-tab-id.enum';
import type { InventoryProvider } from '../../../../../models/enum/dto/inventory-provider';

@Component({
  selector: 'app-lot-info-banner',
  templateUrl: './lot-info-banner.component.html',
  styleUrls: ['./lot-info-banner.component.scss'],
  providers: [LotInfoBannerViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotInfoBannerComponent extends BaseComponent {

  constructor(public viewModel: LotInfoBannerViewModel) {
    super();
  }

  @Input() @ViewModelConnector('connectToInventoryProvider') inventoryProvider: InventoryProvider;
  @Input() @ViewModelConnector('connectToDisplayAttribute') displayAttribute: DisplayAttribute;
  @Input() @ViewModelConnector('connectToSelectedTabId') selectedTabId: EditVariantModalTabId;

}
