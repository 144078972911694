<app-loading *ngIf="viewModel.isLoading$ | push" [options]="viewModel?.loadingOpts$ | push"> </app-loading>

<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title">
        {{
          'Duplicate Menu'
            | replaceMenuWithCardStack
              : (viewModel.isCardStack$ | push)
              : (viewModel.isLabelStack$ | push)
              : (viewModel.isShelfTalker$ | push)
            | replaceMenuWithTemplate : (viewModel.isTemplate$ | push)
        }}
      </div>
      <div class="modal-subtitle-container">
        <div class="modal-subtitle inline-subtitle">
          {{ viewModel.menuName$ | push }}
        </div>
      </div>
    </div>
  </div>

  <div #modalBody class="sticky-body-container mt-16px">
    <!-- Modal Body -->

    <app-message-banner
      *ngIf="viewModel.isTemplatedMenu$ | push"
      [style.margin-bottom]="'1rem'"
      [message]="viewModel.isTemplatedMenuBannerText$ | push">
    </app-message-banner>

    <lib-reactive-form-group
      #duplicateMenuFormGroup
      [bindTo]="viewModel.req$ | push"
      (formSubmitted$)="duplicateMenu()">
      <lib-reactive-form-text
        [inputName]="'menuName'"
        [label]="'Menu Name (Internal)' | replaceMenuWithTemplate : (viewModel.isTemplate$ | push)"
        [placeholder]="
          'Enter an internal name for the menu.' | replaceMenuWithTemplate : (viewModel.isTemplate$ | push)
        "
        [maxLength]="128"
        [bindingProperty]="'name'"
        [required]="true"></lib-reactive-form-text>

      <lib-reactive-form-drop-down
        [inputName]="'menuTheme'"
        [label]="'Menu Theme' | replaceMenuWithTemplate : (viewModel.isTemplate$ | push)"
        [placeholder]="'Choose menu theme' | replaceMenuWithTemplate : (viewModel.isTemplate$ | push)"
        [bindingProperty]="'theme'"
        [required]="true"
        [dropdowns]="viewModel.themes$ | push"
        [disabled]="true"></lib-reactive-form-drop-down>

      <lib-reactive-form-drop-down
        *ngIf="!(viewModel.isTemplate$ | push)"
        [inputName]="'locationId'"
        [label]="'Location'"
        [placeholder]="'Select a location'"
        [bindingProperty]="'locationId'"
        [required]="true"
        [dropdowns]="viewModel.allLocations$ | push"></lib-reactive-form-drop-down>
    </lib-reactive-form-group>

    <div *ngIf="viewModel.containsStackedContent$ | push" class="mb-2">
      <hr />
      <app-card-stack-size-picker
        [menu]="viewModel.menu$ | push"
        [menuType]="viewModel.menuType$ | push"
        [cardStackSizesToShow]="viewModel.menuThemeSupportedCardSizes$ | push"
        (selectedStackSize)="cardStackSizeSelected($event)">
      </app-card-stack-size-picker>
    </div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end" style="padding-top: 0; gap: 1rem">
    <lib-button-neutral (buttonClicked)="cancel()"> Cancel </lib-button-neutral>
    <lib-button-primary
      (buttonClicked)="duplicateMenuFormGroup.submitForm()"
      [disabled]="duplicateMenuFormGroup.hasErrors$ | push">
      {{
        'Duplicate Menu'
          | replaceMenuWithStack
            : (viewModel.isCardStack$ | push)
            : (viewModel.isLabelStack$ | push)
            : (viewModel.isShelfTalker$ | push)
          | replaceMenuWithTemplate : (viewModel.isTemplate$ | push)
      }}
    </lib-button-primary>
  </div>
</div>
