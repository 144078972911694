import { Component } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { LoadingOptions } from '../../../../models/shared/loading-options';
import { DashboardViewModel } from './dashboard-view-model';
import { TypeService } from '../../../../services/type-service';
import { apisGloballyScopedToLoginContext, domainModelsGloballyScopedToLoginContext, servicesGloballyScopedToLoginContext, viewModelsGloballyScopedToLoginContext } from '../../../../di/logged-in-scope';
import { SmartFiltersDomainModel } from '../../../../domainModels/smart-filters-domain-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDomainModel } from '../../../../domainModels/user-domain-model';
import { SyncDomainModel } from '../../../../domainModels/sync-domain-model';
import { exists } from '../../../../functions/exists';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    ...apisGloballyScopedToLoginContext,
    ...domainModelsGloballyScopedToLoginContext,
    ...viewModelsGloballyScopedToLoginContext,
    ...servicesGloballyScopedToLoginContext,
    DashboardViewModel
  ]
})
export class DashboardComponent extends BaseComponent {

  /**
   * The order of the injected objects in the constructor matters. It is the order in which they are initialized.
   *
   * Do not remove unused objects from this constructor. They are used to initialize the objects when the user logs in.
   * Do not remove: typeService, smartFiltersDomainModel, syncDomainModel.
   */
  constructor(
    private typeService: TypeService,
    private smartFiltersDomainModel: SmartFiltersDomainModel,
    private syncDomainModel: SyncDomainModel, // uses web sockets, so init on login
    private userDomainModel: UserDomainModel,
    private ngbModal: NgbModal,
    public viewModel: DashboardViewModel
  ) {
    super();
  }

  public _loadingOpts = new BehaviorSubject(LoadingOptions.default(false, true));
  public loadingOpts$ = this._loadingOpts as Observable<LoadingOptions>;
  public isLoading$ = this.loadingOpts$.pipe(map(opts => opts.isLoading));

  public readonly sidebarVisible$ = this.userDomainModel.user$.pipe(
    map(user => {
      return exists(user)
        ? (user?.accountConfirmed || user?.session?.validSession())
        : false;
    })
  );

  override setupViews() {
    this.setupLoadingOpts();
  }

  setupLoadingOpts() {
    this.loadingOpts$.once(opts => {
      opts.zIndex = 100;
      this._loadingOpts.next(opts);
    });
  }

  /**
   * Dismiss all open models when logged in context dies.
   */
  override destroy() {
    super.destroy();
    this.ngbModal.hasOpenModals() && this.ngbModal.dismissAll();
  }

}
