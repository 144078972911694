import { Pipe, PipeTransform } from '@angular/core';
import { DefaultPrintCardSizeType } from '../../../../models/utils/dto/default-print-card-size-type';
import { DefaultPrintLabelSizeType } from '../../../../models/utils/dto/default-print-label-size-type';
import { DefaultPrintCardSize } from '../../../../models/enum/dto/default-print-card-size';

@Pipe({
  name: 'cardSizeImageWidthInRem'
})
export class CardSizeImageWidthInRemPipe implements PipeTransform {

  transform(cardSize: DefaultPrintCardSizeType|DefaultPrintLabelSizeType): number {
    switch (cardSize?.value) {
      case DefaultPrintCardSize.DefaultSize_AddressCard:
      case DefaultPrintCardSize.DefaultSize_BusinessCard:
        return 6.25;
      case DefaultPrintCardSize.DefaultSize_IndexCard:
        return 7.25;
      default:
        return 8.25;
    }
  }

}
