import { DisplayableItemsViewModel } from './displayable-items-view-model';
import { DropDownItem } from '../../../../../models/shared/stylesheet/drop-down-item';
import { DisplayableItemFilterByActive } from '../../../../../models/enum/shared/displayable-item-filter-by.active';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';
import { ModalCreateTemplate } from '../../../../../modals/modal-create-template';

export abstract class PublishableDisplayableItemsViewModel extends DisplayableItemsViewModel {

  protected override generateInactiveStateFilterOptions() {
    const dropdownOptions: DropDownItem[] = [];
    const all = new DropDownItem('All', DisplayableItemFilterByActive.All);
    const active = new DropDownItem('Published', DisplayableItemFilterByActive.Active);
    const inactive = new DropDownItem('Draft', DisplayableItemFilterByActive.Inactive);
    dropdownOptions.push(all, active, inactive);
    this.containerViewModel.connectToInactiveStateFilterOptions(dropdownOptions);
  }

  public openCreateTemplateModal(mt: MenuType = MenuType.DisplayMenu): void {
    const onClose = (template: MenuTemplate) => {
      if (template) {
        this.navigationService.navigateToEditMenuOrTemplate(template);
      }
    };
    ModalCreateTemplate.open(this.ngZone, this.ngbModal, this.injector, mt, onClose);
  }

}
