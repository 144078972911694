import { Pipe, PipeTransform } from '@angular/core';
import { BulkPrintJob } from '../../../../../../../models/automation/bulk-print-job';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'menuPrintJobs'
})
export class MenuPrintJobsPipe implements PipeTransform {

  transform(allPrintJobs$: Observable<BulkPrintJob[]>, menuId: string): Observable<BulkPrintJob[]> {
    return allPrintJobs$.pipe(
      map(jobs => jobs?.filter(job => job?.menuIncludedInPrintJob(menuId)))
    );
  }

}
