import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditCardStackPrintSectionViewModel } from './edit-card-stack-print-section-view-model';
import { BulkPrintJobPollingService } from '../../../menus/print/services/bulk-print-job-polling.service';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { BulkJobSource } from '../../../../../../models/utils/dto/bulk-job-source-type';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';

@Component({
  selector: 'app-edit-card-stack-print-section',
  templateUrl: './edit-card-stack-print-section.component.html',
  providers: [EditCardStackPrintSectionViewModel, BulkPrintJobPollingService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCardStackPrintSectionComponent extends BaseComponent {

  constructor(
    public viewModel: EditCardStackPrintSectionViewModel,
    protected bulkPrintJobPollingService: BulkPrintJobPollingService
  ) {
    super();
    this.bulkPrintJobPollingService.startPolling(BulkJobSource.BulkJobSource_EditCardStack);
  }

  @Input() @ViewModelConnector('connectToTemplateMode') templateMode: boolean;
  @Input() @ViewModelConnector('connectToUnsavedChanges') unsavedChanges: boolean;
  @Input() sectionDescription: string = 'View and manage the print jobs for your card stack.';
  @Input() editPrintCardMode: boolean = true;
  @Input() editPrintLabelMode: boolean = false;
  @Input() editPrintShelfTalkerMode: boolean = false;

}
