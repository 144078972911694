import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, iif, of } from 'rxjs';
import { delay, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { SortUtils } from '../../../utils/sort-utils';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { SectionSortOption } from '../../../models/enum/dto/section-sort-option';
import { SectionSortType } from '../../../models/utils/dto/section-sort-type';
import { SectionSortProductInfo } from '../../../models/enum/dto/section-sort-product-info';

@Injectable()
export class SectionSortingViewModel extends BaseViewModel {

  constructor(
    public locationDomainModel: LocationDomainModel
  ) {
    super();
  }

  private _selectedPrimarySortOption = new BehaviorSubject<SectionSortOption>(null);
  public selectedPrimarySortOption$ = this._selectedPrimarySortOption.pipe(distinctUntilChanged());
  connectToSelectedPrimarySortOption = (sort: SectionSortOption) => this._selectedPrimarySortOption.next(sort);

  private _selectedSecondarySortOption = new BehaviorSubject<SectionSortOption>(null);
  public selectedSecondarySortOption$ = this._selectedSecondarySortOption.pipe(distinctUntilChanged());
  connectToSelectedSecondarySortOption = (sort: SectionSortOption) => this._selectedSecondarySortOption.next(sort);

  public primarySortingTypes$ = window.types.groupedSectionSortOptions$;

  /**
   * Technically the user should never be able to get into this state, but just in case primary sort isn't set
   * we'll disable the secondary sort until they select a primary sort
   */
  public secondarySortDisabled$ = this.selectedPrimarySortOption$.pipe(map(sort => !sort));

  public secondarySortPlaceholder$ = this.secondarySortDisabled$.pipe(
    map(secondaryDisabled => {
      const defaultsTo = window.types.initTypeDefinition(SectionSortType, SortUtils.DEFAULT_SECTION_TERTIARY_SORT);
      return secondaryDisabled
        ? 'Pick a primary sort first'
        : `${defaultsTo?.name} (Default)`;
    })
  );

  public secondarySortTooltip$ = of(
    'Secondary sort will be used as a tie breaker if multiple products have the same value of primary sort criteria'
  );

  /**
   * Secondary sort filters out primary sort values
   */
  public secondarySortingTypes$ = combineLatest([
    window.types.groupedSectionSortOptions$,
    this.selectedPrimarySortOption$,
  ]).pipe(
    map(([sortTypes, selectedPrimarySort]) => {
      const primarySortString = SortUtils.sharedSortId(selectedPrimarySort);
      return !!selectedPrimarySort
        ? sortTypes?.filter(t => !t?.getSelectionValue()?.includes(primarySortString))
        : sortTypes;
    })
  );

  public programmaticallyChangeSecondarySort$ = combineLatest([
    this.selectedPrimarySortOption$,
    this.selectedSecondarySortOption$,
    this.locationDomainModel.locationConfig$
  ]).pipe(
    switchMap(([selectedPrimarySort, selectedSecondarySort, locationConfig]) => {
      const defaultSecondary = locationConfig?.secondarySorting;
      const primaryId = SortUtils.sharedSortId(selectedPrimarySort);
      const secondarySortId = SortUtils.sharedSortId(selectedSecondarySort);
      const same = primaryId === secondarySortId;
      const change$ = of(undefined).pipe(delay(100), startWith(this.replaceSecondarySort(primaryId, defaultSecondary)));
      return iif(() => same, change$, of(undefined));
    }),
    distinctUntilChanged()
  );

  public selectedSortOptionIsStrainClassification$ = combineLatest([
    this.selectedPrimarySortOption$,
    this.selectedSecondarySortOption$
  ]).pipe(
    map(([first, second]) => {
      const classificationSortId = SortUtils.sharedSortId(SectionSortProductInfo.ClassificationAsc);
      return first?.includes(classificationSortId) || second?.includes(classificationSortId);
    })
  );

  public selectedSortOptions$ = combineLatest([
    this.selectedPrimarySortOption$,
    this.selectedSecondarySortOption$
  ]);

  private replaceSecondarySort(
    primarySortId: string,
    locationDefaultSort: SectionSortOption
  ): SectionSortOption {
    const locationDefaultId = SortUtils.sharedSortId(locationDefaultSort);
    const brandSortId = SortUtils.sharedSortId(SectionSortProductInfo.BrandAsc);
    const titleSortId = SortUtils.sharedSortId(SectionSortProductInfo.TitleAsc);
    switch (true) {
      case primarySortId !== locationDefaultId:
        return locationDefaultSort;
      case primarySortId !== titleSortId:
        return SectionSortProductInfo.TitleAsc;
      case primarySortId !== brandSortId:
        return SectionSortProductInfo.BrandAsc;
      default:
        return SectionSortProductInfo.TitleAsc;
    }
  }

}
