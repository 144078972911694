<div class="theme-preview-container">
  <!-- Title and orientation selection -->
  <div class="theme-preview-header">
    <div class="theme-preview-title float-left">Theme</div>
    <div
      *ngIf="menuType !== ClientTypes.MenuType.PrintCardMenu && menuType !== ClientTypes.MenuType.PrintLabelMenu"
      class="theme-orientation float-right">
      <app-segmented-control
        [hidden]="viewModel.hideOrientationSegmentedControl$ | async"
        [options]="viewModel.orientationControlOptions$ | async"
        (optionSelected)="viewModel.orientationSegmentedControlOptionSelected($event)">
      </app-segmented-control>
    </div>
  </div>
  <!-- Themes Preview Cards -->
  <app-card-deck
    [cards]="viewModel.themeCards$ | async"
    [horizontalScroller]="true"
    [rowGap]="'0'"
    [columnGap]="'0'"
    [maxHeightRem]="viewModel.maxHeightRem$ | async"
    [scrollToCardId]="viewModel.scrollToCardId$ | async"
    (cardButtonPressed)="viewModel.openCardDetails($event)"
    (cardPressed)="viewModel.themeCardSelected($event)">
  </app-card-deck>
</div>
