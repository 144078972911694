import { ChangeDetectorRef, Component, forwardRef, Inject, Injector, ViewChild, ViewRef } from '@angular/core';
import { AllProductsTableItemComponent } from '../../../all-products-data-table/products-table/all-products-table-item/all-products-table-item.component';
import { ProductPickerDataTableViewModel } from '../../product-picker-data-table-view-model';
import { ReactiveTableRowBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { SelectableComponent } from '../../../../shared/components/group-selection/selectable/selectable.component';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DisplayLabelInterface } from '../../../../../modules/product-labels/label/display-label-interface';
import { LabelUtils } from '../../../../../modules/product-labels/utils/label-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HasChildIds } from '../../../../../models/protocols/has-child-ids';
import { iiif } from '../../../../../utils/observable.extensions';
import { PrimaryCannabinoid } from '../../../../../models/enum/shared/primary-cannabinoid.enum';

@Component({
  selector: 'app-product-picker-table-item',
  templateUrl: './product-picker-table-item.component.html',
  styleUrls: ['./product-picker-table-item.component.scss'],
  providers: [
    {
      provide: ReactiveTableRowBluePrintComponent,
      useExisting: forwardRef(() => ProductPickerTableItemComponent)
    }
  ]
})
export class ProductPickerTableItemComponent extends AllProductsTableItemComponent {

  @ViewChild('selectVariant') selectVariant: SelectableComponent;

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    changeDetector: ChangeDetectorRef,
    ngbModal: NgbModal,
    injector: Injector,
    public override viewModel: ProductPickerDataTableViewModel // global - provided by root
  ) {
    super(viewRef, changeDetector, ngbModal, injector, viewModel);
  }

  private readonly selectProduct$ = this.rowData$.pipe(
    map(rowData => {
      return new class implements HasChildIds {

        getId = (): string => 'no relevant';
        getChildIds = (): string[] => [rowData?.id];

      }();
    })
  );

  private readonly selectVariants$ = this.rowData$;

  public readonly selection$ = iiif(
    this.viewModel.productSelectionOnly$,
    this.selectProduct$,
    this.selectVariants$
  );

  public override readonly dataForLabelInterface$ = combineLatest([
    this.rowData$,
    this.viewModel.locationConfig$,
    this.viewModel.companyConfig$
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public override readonly productLabelInterface$: Observable<DisplayLabelInterface> = this.dataForLabelInterface$.pipe(
    map(([product, locationConfig, companyConfig]) => {
      return LabelUtils.getDisplayLabelInterfaceForProductInProductTable(product, locationConfig, companyConfig);
    })
  );

  variantContainerClicked(): void {
    this.viewModel.productSelectionOnly$.once(productSelectionOnly => {
      productSelectionOnly && this.selectVariant?.containerClicked();
    });
  }

  protected override readonly PrimaryCannabinoid = PrimaryCannabinoid;

}
