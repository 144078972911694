// noinspection JSUnusedLocalSymbols

import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { EditVariantContainer } from '../../edit-variant-container';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../../../utils/distinct-utils';
import { CannabisUnitOfMeasure } from '../../../../../../../models/utils/dto/cannabis-unit-of-measure-type';
import { DisplayAttribute } from '../../../../../../../models/display/dto/display-attribute';
import { PrimaryCannabinoid } from '../../../../../../../models/enum/shared/primary-cannabinoid.enum';
import { ProviderUtils } from '../../../../../../../utils/provider-utils';
import { exists } from '../../../../../../../functions/exists';
import { Variant } from 'src/app/models/product/dto/variant';
import { EnumUtils } from '../../../../../../../utils/enum-utils';

@Injectable()
export class EditVariantCannabinoidsViewModel extends BaseViewModel {

  constructor(
    public container: EditVariantContainer
  ) {
    super();
  }

  public cannabisUnitOfMeasureOptions$ = window.types.cannabisUnitOfMeasures$;

  public enabledCannabinoids$ = this.container.enabledCannabinoids$;
  public enabledCannabinoidNames$ = this.container.enabledCannabinoidNames$;
  public enabledSecondaryCannabinoidNames$ = this.container.enabledSecondaryCannabinoidNames$;
  public enabledCannabinoidNamesWithoutTAC$ = this.container.enabledCannabinoidsNamesWithoutTAC$;
  public hasEnabledSecondaryCannabinoids$ = this.container.hasEnabledSecondaryCannabinoids$;
  public posSupportedSecondaryCannabinoidNames$ = this.container.posSupportedSecondaryCannabinoidNames$;
  public hasPosSupportedSecondaryCannabinoids$ = this.container.hasPosSupportedSecondaryCannabinoids$;
  public otherEnabledSecondaryCannabinoids$ = this.container.otherEnabledSecondaryCannabinoids$;
  public hasOtherEnabledSecondaryCannabinoids$ = this.container.hasOtherEnabledSecondaryCannabinoids$;
  public readonly companyUsesCannabinoidRange$ = this.container.companyUsesCannabinoidRange$;
  public readonly displayAttribute$ = this.container.displayAttribute$;
  public readonly posSupportedSecondaryCannabinoids$ = this.container.posSupportedSecondaryCannabinoids$;

  private _selectedCUOM = new BehaviorSubject<CannabisUnitOfMeasure>(CannabisUnitOfMeasure.UNKNOWN);
  public selectedCUOM$ = this._selectedCUOM.pipe(distinctUntilChanged());

  private _updatedCompanyDA = new BehaviorSubject<DisplayAttribute>(null);
  public updatedCompanyDA$ = this._updatedCompanyDA.pipe(distinctUntilChanged());

  private _updatedLocationDA = new BehaviorSubject<DisplayAttribute>(null);
  public updatedLocationDA$ = this._updatedLocationDA.pipe(distinctUntilChanged());

  public primaryCannabinoids$ = of(EnumUtils.getPrimaryCannabinoids());
  public showCannabinoidPOSSyncBanner$ = this.container.showCannabinoidPOSSyncBanner$;

  public readonly primaryCannabinoidsWithoutTAC$: Observable<PrimaryCannabinoid[]> = of(
    EnumUtils.getPrimaryCannabinoids()?.filter(it => it !== PrimaryCannabinoid.TAC)
  );

  private distinctById = distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable);
  private initial = combineLatest([
    this.container.variant$.pipe(this.distinctById),
    this.container.variantLocationDisplayAttribute$.pipe(this.distinctById),
    this.container.variantCompanyDisplayAttribute$.pipe(this.distinctById)
  ]).subscribeWhileAlive({
    owner: this,
    next: ([variant, locationDA, companyDA]) => {
      this._selectedCUOM.next(variant?.cannabisUnitOfMeasure || CannabisUnitOfMeasure.UNKNOWN);
      this._updatedCompanyDA.next(companyDA);
      this._updatedLocationDA.next(locationDA);
    }
  });

  public readonly functionInputsForDisableCannabinoid$ = combineLatest([
    this.showCannabinoidPOSSyncBanner$,
    this.selectedCUOM$
  ]);

  public disableCannabisUnitOrMeasure$ = combineLatest([
    this.container.syncCannabinoidsFromPOS$,
    this.container.variant$,
  ]).pipe(
    map(([syncCannabinoidsFromPOS, variant]) => syncCannabinoidsFromPOS && exists(variant?.cannabisUnitOfMeasure)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public disableCannabinoidInput$: Observable<boolean> = this.functionInputsForDisableCannabinoid$.pipe(
    map(([showPOSSyncBanner, selectedCUOM]) => {
      return showPOSSyncBanner || selectedCUOM === CannabisUnitOfMeasure.NA;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public disablePresentCannabinoidInput$ = combineLatest([
    this.disableCannabinoidInput$,
    this.container.posSupportsPresentSecondaryCannabinoids$
  ]).pipe(
    map(([disableCannabinoidInputs, posSupportsPresentSecondaryCannabinoids]) => {
      // this signal is here for future "Present Secondary Cannabinoids" disabled logic (if needed)
      return false;
    }),
    startWith(false),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public disableTAC$: Observable<boolean> = combineLatest([
    this.showCannabinoidPOSSyncBanner$,
    this.selectedCUOM$,
    this.container.inventoryProvider$
  ]).pipe(
    map(([showPOSSyncBanner, selectedCUOM, inventoryProvider]) => {
      return (showPOSSyncBanner && ProviderUtils.supportsTotalActiveCannabinoids(inventoryProvider))
          || selectedCUOM === CannabisUnitOfMeasure.NA;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public CUOMTooltip$ = this.container.syncCannabinoidsFromPOS$.pipe(
    map(isSync => {
      if (isSync) {
        return 'Editing the Cannabis Unit of Measure is disabled when Sync THC/CBD from POS is enabled. '
          + 'This value will automatically be set based on the Default Cannabis Unit of Measure. '
          + '(See Settings > Product > General)';
      } else {
        return 'The units of measure for cannabinoids such as THC and CBD within the product.';
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public companyPresentCannabinoidPillPlaceholders$ = combineLatest([
    this.container.variant$,
    this.companyUsesCannabinoidRange$,
    this.updatedCompanyDA$,
    this.enabledCannabinoidNamesWithoutTAC$
  ]).pipe(
    map(([variant, companyUsesCannabinoidRange, companyDA, enabledCannabinoidNames]) => {
      const ranged = variant?.useCannabinoidRange || companyUsesCannabinoidRange;
      return this.getPresentCannabinoids(
        variant,
        ranged,
        null,
        companyDA,
        enabledCannabinoidNames
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public companyPresentCannabinoidTextPlaceholder$: Observable<string> = combineLatest([
    this.updatedCompanyDA$,
    this.companyPresentCannabinoidPillPlaceholders$
  ]).pipe(
    map(([companyDA, presentSecondaryCannabinoidPillPlaceholders]) => {
      const hasCompanySetPresentCannabinoids = companyDA?.presentCannabinoids?.length > 0;
      const hasPillPlaceholders = presentSecondaryCannabinoidPillPlaceholders?.length > 0;
      switch (true) {
        case hasCompanySetPresentCannabinoids:
          return 'Select present secondary cannabinoids';
        case hasPillPlaceholders:
          return '(Auto-calculated)';
        default:
          return 'Select present secondary cannabinoids';
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public locationPresentCannabinoidsFromDisplayAttributes$ = combineLatest([
    this.container.variant$,
    this.companyUsesCannabinoidRange$,
    this.updatedLocationDA$,
    this.updatedCompanyDA$,
    this.enabledCannabinoidNamesWithoutTAC$
  ]).pipe(
    map(([variant, companyUsesCannabinoidRange, locationDA, companyDA, enabledCannabinoidNames]) => {
      const ranged = variant?.useCannabinoidRange || companyUsesCannabinoidRange;
      return this.getPresentCannabinoids(
        variant,
        ranged,
        locationDA,
        companyDA,
        enabledCannabinoidNames
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public locationPresentCannabinoidPillPlaceholders$ = combineLatest([
    this.updatedCompanyDA$,
    this.enabledCannabinoidNamesWithoutTAC$,
    this.locationPresentCannabinoidsFromDisplayAttributes$,
    this.companyPresentCannabinoidPillPlaceholders$
  ]).pipe(
    map(([
      companyDA,
      enabledSecondaryCannabinoids,
      locationPresentCannabinoidsFromDA,
      companyPresentCannabinoidsFromDA
    ]) => {
      const hasCompanySetPresentCannabinoids = companyDA?.presentCannabinoids?.length > 0;
      const hasCompanyAutoCalculatedPlaceholders = companyPresentCannabinoidsFromDA?.length > 0;
      const companySetCannabinoids = companyDA?.presentCannabinoids?.shallowCopy();
      switch (true) {
        case hasCompanySetPresentCannabinoids:
          return companySetCannabinoids;
        case hasCompanyAutoCalculatedPlaceholders:
        case locationPresentCannabinoidsFromDA?.length > 0:
          return locationPresentCannabinoidsFromDA?.filter(presentCannabinoid => {
            return presentCannabinoid !== PrimaryCannabinoid.TAC;
          });
        default:
          return null;
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public locationPresentCannabinoidTextPlaceholder$: Observable<string> = combineLatest([
    this.updatedLocationDA$,
    this.updatedCompanyDA$,
    this.locationPresentCannabinoidPillPlaceholders$
  ]).pipe(
    map(([locationDA, companyDA, locationPresentSecondaryCannabinoidPillPlaceholders]) => {
      const hasLocationSetPresentSecondaryCannabinoids = locationDA?.presentCannabinoids?.length > 0;
      const hasCompanySetPresentSecondaryCannabinoids = companyDA?.presentCannabinoids?.length > 0;
      const hasLocationPillPlaceholders = locationPresentSecondaryCannabinoidPillPlaceholders?.length > 0;
      switch (true) {
        case hasLocationSetPresentSecondaryCannabinoids:
          return 'Select present secondary cannabinoids';
        case hasLocationPillPlaceholders && hasCompanySetPresentSecondaryCannabinoids:
          return '(Company Default)';
        case hasLocationPillPlaceholders:
          return '(Auto-calculated)';
        default:
          return 'Select present secondary cannabinoids';
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public cannabinoidsWithPOSValueNotEnabledInBudsense$: Observable<string[]> = combineLatest([
    this.posSupportedSecondaryCannabinoids$,
    this.displayAttribute$,
    this.enabledSecondaryCannabinoidNames$,
    this.selectedCUOM$,
    this.container.variant$,
    this.companyUsesCannabinoidRange$,
  ]).pipe(
    map(([
      posSupportedSecondaryCannabinoids,
      displayAttribute,
      enabledSecondaryCannabinoid,
      selectedCUOM,
      variant,
      companyUsesCannabinoidRange
    ]) => {
      const ranged = variant?.useCannabinoidRange || companyUsesCannabinoidRange;
      const cannabinoidsWithPOSValueNotEnabledInBudsense: string[] = [];
      posSupportedSecondaryCannabinoids.forEach(cannabinoid => {
        const value = displayAttribute[cannabinoid];
        if (ranged) {
          const minValue = displayAttribute[`min${cannabinoid}`];
          const maxValue = displayAttribute[`max${cannabinoid}`];

          if (minValue === maxValue && value && !enabledSecondaryCannabinoid.includes(cannabinoid)) {
            cannabinoidsWithPOSValueNotEnabledInBudsense.push(`${cannabinoid} - ${value}${selectedCUOM}`);
          } else if (minValue !== maxValue && !enabledSecondaryCannabinoid.includes(cannabinoid)) {
            cannabinoidsWithPOSValueNotEnabledInBudsense.push(
              `${cannabinoid} - (${minValue}${selectedCUOM} - ${maxValue}${selectedCUOM})`
            );
          }
        } else {
          if (value && !enabledSecondaryCannabinoid.includes(cannabinoid)) {
            cannabinoidsWithPOSValueNotEnabledInBudsense.push(`${cannabinoid} - ${value}${selectedCUOM}`);
          }
        }
      });
      return cannabinoidsWithPOSValueNotEnabledInBudsense;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public cuomSelectionChanged = (cuom: CannabisUnitOfMeasure) => {
    this._selectedCUOM.next(cuom);
  };

  public companyDAUpdated(value: string, accessor: string) {
    this.updatedCompanyDA$.once(companyDA => {
      const companyDACopy = Object.assign(new DisplayAttribute(), companyDA);
      companyDACopy[accessor] = value;
      this._updatedCompanyDA.next(companyDACopy);
    });
  }

  public locationDAUpdated(value: string, accessor: string) {
    this.updatedLocationDA$.once(locationDA => {
      const locationDACopy = Object.assign(new DisplayAttribute(), locationDA);
      locationDACopy[accessor] = value;
      this._updatedLocationDA.next(locationDACopy);
    });
  }

  getPresentCannabinoids(
    variant: Variant,
    useRange: boolean,
    locationDA: DisplayAttribute,
    companyDA: DisplayAttribute,
    enabledCannabinoids: string[]
  ): string[] {
    return enabledCannabinoids?.filter(cannabinoid => {
      return useRange
        ? this.hasPresentRangedCannabinoid(cannabinoid, variant, locationDA, companyDA)
        : this.hasPresentNonRangedCannabinoid(cannabinoid, variant, locationDA, companyDA);
    });
  }

  private hasPresentRangedCannabinoid(
    cannabinoid: string,
    variant: Variant,
    locationDA: DisplayAttribute,
    companyDA: DisplayAttribute
  ): boolean {
    if (exists(locationDA?.[`min${cannabinoid}`]) || exists(locationDA?.[`max${cannabinoid}`])) {
      return this.hasNumericValueGreaterThanZero(locationDA?.[`min${cannabinoid}`])
          || this.hasNumericValueGreaterThanZero(locationDA?.[`max${cannabinoid}`]);
    } else if (exists(companyDA?.[`min${cannabinoid}`]) || exists(companyDA?.[`max${cannabinoid}`])) {
      return this.hasNumericValueGreaterThanZero(companyDA?.[`min${cannabinoid}`])
          || this.hasNumericValueGreaterThanZero(companyDA?.[`max${cannabinoid}`]);
    } else {
      return this.hasNumericValueGreaterThanZero(variant?.[`min${cannabinoid}`])
          || this.hasNumericValueGreaterThanZero(variant?.[`max${cannabinoid}`]);
    }
  }

  private hasPresentNonRangedCannabinoid(
    cannabinoid: string,
    variant: Variant,
    locationDA: DisplayAttribute,
    companyDA: DisplayAttribute
  ): boolean {
    if (exists(locationDA?.[cannabinoid])) {
      return this.hasNumericValueGreaterThanZero(locationDA?.[cannabinoid]);
    } else if (exists(companyDA?.[cannabinoid])) {
      return this.hasNumericValueGreaterThanZero(companyDA?.[cannabinoid]);
    } else {
      return this.hasNumericValueGreaterThanZero(variant?.[cannabinoid]);
    }
  }

  private hasNumericValueGreaterThanZero(cannabinoidVal: string): boolean {
    return exists(cannabinoidVal) && parseFloat(cannabinoidVal) > 0;
  }

  trackByCannabinoid(index: number, cannabinoid: string): string {
    return cannabinoid;
  }

  trackBySecondaryCannabinoidName(index: number, secondaryCannabinoid: string): string {
    return secondaryCannabinoid;
  }

}
