import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { DefaultColumnOptionsPickerViewModel } from './default-column-options-picker-view-model';
import { ThemeSectionColumnConfig } from '../../../../models/menu/dto/theme-section-column-config';
import { DefaultSectionColumnConfig } from '../../../../models/menu/dto/default-section-column-config';
import { SectionColumnConfigKey } from '../../../../models/enum/dto/section-column-config-key';

@Component({
  selector: 'app-default-column-options-picker',
  templateUrl: 'default-column-options-picker.component.html',
  styleUrls: ['default-column-options-picker.component.scss'],
  providers: [DefaultColumnOptionsPickerViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultColumnOptionsPickerComponent extends BaseComponent {

  @Input() themeDefault: Map<SectionColumnConfigKey, ThemeSectionColumnConfig>;
  @Output() selected: EventEmitter<DefaultSectionColumnConfig> = new EventEmitter<DefaultSectionColumnConfig>();

  constructor(
    public viewModel: DefaultColumnOptionsPickerViewModel,
  ) {
    super();
  }

  emitThemeDefault() {
    const themeDefaultColumnConfig = new DefaultSectionColumnConfig();
    themeDefaultColumnConfig.id = '-1';
    themeDefaultColumnConfig.name = 'Theme Default';
    themeDefaultColumnConfig.columnConfig = this.themeDefault;
    this.selected.emit(themeDefaultColumnConfig);
  }

}
