import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewDisplayModalComponent } from '../views/display/components/modals/new-display-modal/new-display-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import type { AllDisplaysViewModel } from '../views/display/viewModels/all-displays-view-model';
import { Injector, NgZone } from '@angular/core';
import { Display } from '../models/display/dto/display';

export class ModalAddDisplay {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    allDisplaysViewModel: AllDisplaysViewModel,
    onClose: (display: Display) => void
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(NewDisplayModalComponent, ModalUtils.displayModalOptions(injector));
      const compInst = modalRef.componentInstance as NewDisplayModalComponent;
      compInst.viewModel = allDisplaysViewModel;
      modalRef.result.then(display => onClose(display)).catch();
    });
  }

}
