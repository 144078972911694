import { Injectable } from '@angular/core';
import { Variant } from '../../../../../models/product/dto/variant';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { HydratedSection } from '../../../../../models/menu/dto/hydrated-section';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { BsError } from '../../../../../models/shared/bs-error';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { ToastService } from '../../../../../services/toast-service';
import { Menu } from '../../../../../models/menu/dto/menu';
import { BehaviorSubject, combineLatest, iif, Observable, of, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { Section } from '../../../../../models/menu/dto/section';
import { HydratedVariantFeature } from '../../../../../models/product/dto/hydrated-variant-feature';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { LabelUtils } from '../../../../../modules/product-labels/utils/label-utils';
import { DisplayAttributesDomainModel } from '../../../../../domainModels/display-attributes-domain-model';
import { SystemLabel } from '../../../../../models/shared/labels/system-label';
import { Label } from '../../../../../models/shared/label';
import { FeaturedSystemLabel } from '../../../../../models/shared/labels/featured-system-label';
import { MenuTemplate } from 'src/app/models/template/dto/menu-template';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { SectionTemplate } from '../../../../../models/template/dto/section-template';
import { LabelDomainModel } from '../../../../../domainModels/label-domain-model';
import { MenuLabel } from '../../../../../models/enum/dto/menu-label';

@Injectable()
export class EditLabelViewModel extends BaseModalViewModel {

  constructor(
    router: Router,
    modalService: NgbModal,
    private companyDomainModel: CompanyDomainModel,
    private menuDomainModel: MenuDomainModel,
    private templateDomainModel: TemplateDomainModel,
    private toastService: ToastService,
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private displayAttributeDomainModel: DisplayAttributesDomainModel,
  ) {
    super(router, modalService);
  }

  private locationName$ = this.locationDomainModel.locationName$;
  private companyName$ = this.companyDomainModel.companyName$;
  private locationConfig$ = this.locationDomainModel.locationConfig$;
  public companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public locationCustomLabels$ = this.labelDomainModel.locationCustomLabels$;
  public companyPOSLabels$ = this.labelDomainModel.companyPOSLabels$;
  public allLabels$ = this.labelDomainModel.allLabels$;

  // Loading
  loadingOpts: LoadingOptions = LoadingOptions.default();

  // Input data
  protected _variant = new BehaviorSubject<Variant>(null);
  public variant$ = this._variant.asObservable();
  public variantDisplayName$ = this.variant$.pipe(
    map((v) => v.getDisplayName())
  );

  // Location Config
  public priceFormat$ = this.locationDomainModel.priceFormat$;

  // Menu data
  protected _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu.asObservable();
  public theme$ = this.menu$.pipe(map((menu) => menu?.hydratedTheme));
  public templateMode$ = this.menu$.pipe(map(menu => menu instanceof MenuTemplate));

  protected _section = new BehaviorSubject<HydratedSection>(null);
  public section$ = this._section.asObservable();

  // Static Label Subjects
  public featuredLabel$ = this.labelDomainModel.featuredLabel$;
  public locationLabels$ = this.labelDomainModel.allLocationLabels$;

  public activeSaleLabelFormat$ = combineLatest([
    this.companyConfig$,
    this.locationConfig$,
  ]).pipe(
    map(([companyConfig, locationConfig]) => {
      return locationConfig?.saleLabelFormat || companyConfig?.saleLabelFormat;
    })
  );

  public activeLabelStyle$ = combineLatest([
    this.companyConfig$,
    this.locationConfig$,
  ]).pipe(
    map(([companyConfig, locationConfig]) => {
      return locationConfig?.labelStyle || companyConfig?.labelStyle;
    })
  );

  // Dynamic Label Subjects
  private readonly _selectedOverrideLabelKey = new BehaviorSubject<string>(null);
  public readonly selectedOverrideLabelKey$ = this._selectedOverrideLabelKey as Observable<string>;
  public connectToSelectedOverrideLabelKey = (labelKey: string) => this._selectedOverrideLabelKey.next(labelKey);
  public selectedOverrideLabel$ = combineLatest([
    this.selectedOverrideLabelKey$,
    this.variant$,
    this.section$,
    this.menu$,
    this.locationCustomLabels$,
    this.featuredLabel$,
  ]).pipe(
    debounceTime(100),
    map(([selectedOverrideKey, variant, section, menu, customLabels, featuredLabel]) => {
      const allLabels = [...customLabels, featuredLabel];
      if (!!selectedOverrideKey) {
        // If user made explicit selection, return that
        return allLabels.find(l => l.id === selectedOverrideKey);
      } else {
        // Check if there is a selected override key
        const existingCustomOverride = section?.customLabelMap?.get(variant?.id);
        const existingFeatLabel = menu?.variantFeature?.variantIds?.contains(variant?.id);
        if (!!existingCustomOverride) {
          // POS labels should be treated the same as custom labels (though they are purely company-wide)
          return customLabels.find(l => l.id === existingCustomOverride);
        } else if (existingFeatLabel) {
          return featuredLabel;
        } else {
          return null;
        }
      }
    }),
    distinctUntilChanged((a: Label, b: Label) => a?.id === b?.id),
  );

  public existingOverrideLabel$ = combineLatest([
    this.variant$,
    this.section$,
    this.menu$,
    this.locationLabels$,
    this.featuredLabel$
  ]).pipe(map(([variant, section, menu, locationLabels, featuredLabel]) => {
    const existingOverride = section?.customLabelMap?.get(variant?.id);
    const isFeatured = menu?.variantFeature?.variantIds.contains(variant?.id);
    switch (true) {
      case !!existingOverride: return locationLabels?.find(l => l?.id === existingOverride);
      case isFeatured:         return featuredLabel;
    }
    return null;
  }));

  private _appliedSystemLabels = new BehaviorSubject<SystemLabel[]>([]);
  public appliedSystemLabels$ = this._appliedSystemLabels as Observable<SystemLabel[]>;
  public connectToAppliedSystemLabels = (systemLabels: SystemLabel[]) => this._appliedSystemLabels.next(systemLabels);
  public systemAppliedLabel$ = this.appliedSystemLabels$.pipe(map(systemLabels => systemLabels?.firstOrNull()));

  public existingSystemLabelText$ = combineLatest([
    this.systemAppliedLabel$,
    this.variant$,
    combineLatest([this.locationName$, this.companyName$]),
    combineLatest([this.locationConfig$, this.companyConfig$]),
    this.priceFormat$
  ]).pipe(
    map(([systemLabel, variant, names, configs, locationPriceStream]) => {
      return LabelUtils.getSystemLabelInfo(systemLabel, variant, names, configs, locationPriceStream);
    })
  );

  private _explicitLabelHierarchyVisibility = new BehaviorSubject<boolean>(undefined);
  public explicitLabelHierarchyVisibility$ = this._explicitLabelHierarchyVisibility as Observable<boolean>;
  public explicitlyShowOrHideLabel(): void {
    this.explicitLabelHierarchyVisibility$
      .pipe(
        switchMap(explicitLabelHierarchyVisibility => {
          if (explicitLabelHierarchyVisibility === undefined) {
            return this.showLabelHierarchy$.pipe(map(showLabelHierarchy => !showLabelHierarchy));
          } else {
            return of(!explicitLabelHierarchyVisibility);
          }
        }),
        take(1),
      )
      .subscribe(calculatedExplicitLabelHierarchyVisibility => {
        this._explicitLabelHierarchyVisibility.next(calculatedExplicitLabelHierarchyVisibility);
      });
  }

  public showLabelHierarchy$ = this.explicitLabelHierarchyVisibility$.pipe(
    switchMap(explicitLabelHierarchyVisibility => {
      const explicitToggle$ = of(explicitLabelHierarchyVisibility);
      const defaultFunctionality$ = combineLatest([
        this.variant$,
        this.systemAppliedLabel$,
        this.existingOverrideLabel$,
      ]).pipe(
        map(([variant, systemLabel, overrideLabel]) => {
          // We want to show hierarchy when either of the following exist
          // Case1 - DA + system applied label exist (no override)
          // Case2 - Override + system applied label exist
          const validSystemLabel = !!systemLabel;
          const validOverrideLabel = !!overrideLabel;
          const case1 = !!variant?.displayAttributes?.defaultLabel && validSystemLabel && !validOverrideLabel;
          const case2 = validSystemLabel && validOverrideLabel;
          return case1 || case2;
        })
      );
      return iif(() => explicitLabelHierarchyVisibility !== undefined, explicitToggle$, defaultFunctionality$);
    })
  );

  public newCustomLabelSelected$ = combineLatest([
    this.existingOverrideLabel$,
    this.selectedOverrideLabel$,
  ]).pipe(
    map(([existingOverrideLabel, selectedOverrideLabel]) => {
      return !!selectedOverrideLabel && existingOverrideLabel?.id !== selectedOverrideLabel?.id;
    })
  );

  public inheritedDALabelText$ = combineLatest([
    this.variant$,
    this.existingOverrideLabel$,
    this.systemAppliedLabel$,
    this.locationLabels$,
    this.companyPOSLabels$,
  ]).pipe(map(([variant, existingOverrideLabel, systemLabel, locationLabels, companyPOSLabels]) => {
    const overrideValid = !!existingOverrideLabel;
    const systemValid = !!systemLabel;
    const inheritedDAIsHidden = overrideValid && systemValid;
    const defaultDALabel = variant?.displayAttributes?.defaultLabel
      || variant?.displayAttributes?.inheritedDisplayAttribute?.defaultLabel;
    const hasDefaultDALabel = !!defaultDALabel;
    const changed = existingOverrideLabel?.id !== defaultDALabel;
    if (hasDefaultDALabel && changed && !inheritedDAIsHidden) {
      const labelPoolToCheck = locationLabels.concat(companyPOSLabels) || [];
      const inheritedLabel = labelPoolToCheck?.find(l => l?.id === defaultDALabel);
      if (!!existingOverrideLabel) {
        return `The label '${inheritedLabel?.text}' is inherited from the product's display attributes. `
          + `This is being overridden by the '${existingOverrideLabel?.text}' label.`;
      } else {
        return `The label '${inheritedLabel?.text}' is inherited from the product's display attributes.`;
      }
    } else {
      return null;
    }
  }));

  public computeLabelInterface$ = combineLatest([
    this.menu$,
    this.section$.deepCopy(),
    this.variant$,
    this.locationConfig$,
    this.companyConfig$,
    this.selectedOverrideLabel$,
  ]).pipe(
    map(([menu, section, variant, locConfig, compConfig, selectedOverride]) => {
      !!selectedOverride
        ? section?.customLabelMap?.set(variant?.id, selectedOverride?.id)
        : section?.customLabelMap?.delete(variant?.id);
      return LabelUtils.getComputeLabelInterfaceWithinMenuContext(menu, section, [variant], locConfig, compConfig);
    })
  );

  connectToSection(s: HydratedSection) {
    this._section.next(window?.injector?.Deserialize?.instanceOf(HydratedSection, s));
  }

  connectToMenu(m: Menu) {
    this._menu.next(window?.injector?.Deserialize?.instanceOf(Menu, m));
  }

  connectToVariant(v: Variant) {
    this._selectedOverrideLabelKey.next(null);
    this._variant.next(window?.injector?.Deserialize?.instanceOf(Variant, v));
  }

  removeLabels() {
    combineLatest([
      this.existingOverrideLabel$.notNull(),
      this.menu$,
      this.variant$,
      this.section$,
      this.templateMode$
    ]).pipe(
      take(1)
    ).subscribe(([existingOverrideLabel, menu, variant, section, templateMode]) => {
      if (existingOverrideLabel?.id === MenuLabel.Featured) {
        const lm = 'Updating Menu Labels';
        this.loadingOpts.addRequest(lm);
        const variantFeatures = this.removeVariantIdFromFeature(menu, variant);
        this.saveMenu(lm, variantFeatures, menu, templateMode);
      } else {
        section.customLabelMap.delete(variant.id);
        const lm = 'Updating Section Labels';
        this.loadingOpts.addRequest(lm);
        this.saveSection(lm, section, templateMode);
      }
    });
  }

  save() {
    combineLatest([
      this.selectedOverrideLabel$.notNull(),
      this.existingOverrideLabel$,
      this.section$,
      this.variant$,
      this.templateMode$
    ]).pipe(
      take(1)
    ).subscribe(([selectedOverrideLabel, existingOverrideLabel, section, variant, templateMode]) => {
      const existingOverrideIsFeatured = existingOverrideLabel instanceof FeaturedSystemLabel;
      const selectedOverrideIsFeatured = selectedOverrideLabel instanceof FeaturedSystemLabel;
      if (selectedOverrideIsFeatured) {
        // Add variant to featured variants. We know a variant is featured solely base on the fact that it is added
        // to the featured variants data model on the menu.
        this.clearCustomUpdateFeaturedVariant();
      } else if (existingOverrideIsFeatured && !selectedOverrideIsFeatured) {
        // remove variant from featured variants data model on the menu (featured label has been overwritten).
        this.clearFeaturedUpdateSection();
      } else {
        const lm = 'Updating Section Labels';
        if (!this.loadingOpts.containsRequest(lm)) {
          this.loadingOpts.addRequest(lm);
          section.customLabelMap.set(variant.id, selectedOverrideLabel?.id);
          this.saveSection(lm, section, templateMode);
        }
      }
    });
  }

  clearCustomUpdateFeaturedVariant() {
    const lm = 'Updating Menu Labels';
    combineLatest([
      this.menu$,
      this.variant$,
      this.section$,
      this.templateMode$,
    ]).pipe(
      take(1),
      switchMap(([menu, variant, section, templateMode]) => {
        if (menu) {
          const variantFeatures = this.addVariantIdToFeature(menu, variant);
          const updateMenuVariantFeature$ = templateMode
            ? this.templateDomainModel.updateMenuVariantFeature(menu as MenuTemplate, variantFeatures)
            : this.menuDomainModel.updateMenuVariantFeature(menu, variantFeatures);
          if (!this.loadingOpts.containsRequest(lm)) {
            this.loadingOpts.addRequest(lm);
            return updateMenuVariantFeature$.pipe(
              switchMap(_ => this.updateMenuSectionCustomLabels(section, variant))
            );
          }
        }
        return of(false);
      }),
      take(1),
    ).subscribe(_ => {
      this.loadingOpts.removeRequest(lm);
      this._selectedOverrideLabelKey.next(null);
      this.closeModal();
    }, (error: BsError) => {
      this.loadingOpts.removeRequest(lm);
      this.toastService.publishError(error);
      throwError(error);
    });
  }

  clearFeaturedUpdateSection() {
    const lm = 'Updating Section Labels';
    combineLatest([
      this.menu$,
      this.section$,
      this.variant$,
      this.selectedOverrideLabel$,
    ]).pipe(
      take(1),
      switchMap(([menu, section, variant, selectedOverrideLabel]) => {
        const variantFeatures = this.removeVariantIdFromFeature(menu, variant);
        if (!this.loadingOpts.containsRequest(lm)) {
          this.loadingOpts.addRequest(lm);
          section.customLabelMap.set(variant.id, selectedOverrideLabel?.id);
          return this.menuDomainModel.updateMenuVariantFeature(menu, variantFeatures).pipe(
            switchMap(_ => this.menuDomainModel.updateMenuSection(section))
          );
        }
        return of(false);
      }),
      take(1)
    ).subscribe(_ => {
      this.loadingOpts.removeRequest(lm);
      this._selectedOverrideLabelKey.next(null);
      this.closeModal();
    }, (error: BsError) => {
      this.loadingOpts.removeRequest(lm);
      this.toastService.publishError(error);
      throwError(error);
    });
  }

  removeVariantIdFromFeature(menu: Menu, variant: Variant): HydratedVariantFeature {
    const Deserialize = window?.injector?.Deserialize;
    const variantFeatures = Deserialize?.instanceOf(HydratedVariantFeature, menu.variantFeature);
    const existingFeatureIds = Array.from(menu?.variantFeature.variantIds ?? []);
    const removeIndex = existingFeatureIds.indexOf(variant.id);
    if (removeIndex > -1) {
      existingFeatureIds.splice(removeIndex, 1);
    }
    variantFeatures.variantIds = Array.from(new Set(existingFeatureIds)); // ensuring all IDs are unique
    return variantFeatures;
  }

  addVariantIdToFeature(menu: Menu, variant: Variant): HydratedVariantFeature {
    const Deserialize = window?.injector?.Deserialize;
    const variantFeatures = Deserialize?.instanceOf(HydratedVariantFeature, menu.variantFeature);
    const existingFeatureIds = Array.from(menu?.variantFeature.variantIds ?? []);
    existingFeatureIds.push(variant.id);
    if (variantFeatures) {
      variantFeatures.variantIds = Array.from(new Set(existingFeatureIds)); // ensuring all IDs are unique
    }
    return variantFeatures;
  }

  updateMenuSectionCustomLabels(
    section: HydratedSection,
    variant: Variant,
    templateMode: boolean = false
  ): Observable<HydratedSection> {
    if (section.customLabelMap.get(variant.id)) {
      section.customLabelMap.delete(variant.id);
      return templateMode
        ? this.templateDomainModel.updateMenuSectionTemplate(section as SectionTemplate)
        : this.menuDomainModel.updateMenuSection(section);
    } else {
      return of(section);
    }
  }

  saveSection(lm: string, s: Section, templateMode: boolean = false) {
    const updateSection$ = templateMode
      ? this.templateDomainModel.updateMenuSectionTemplate(s as SectionTemplate)
      : this.menuDomainModel.updateMenuSection(s);
    updateSection$.pipe(take(1)).subscribe(_ => {
      this.loadingOpts.removeRequest(lm);
      this._selectedOverrideLabelKey.next(null);
      this.closeModal();
    }, (error: BsError) => {
      this.loadingOpts.removeRequest(lm);
      this.toastService.publishError(error);
      throwError(error);
    });
  }

  saveMenu(lm: string, vf: HydratedVariantFeature, m: Menu, templateMode: boolean = false) {
    const updateMenuVariantFeature$ = templateMode
      ? this.templateDomainModel.updateMenuVariantFeature(m as MenuTemplate, vf)
      : this.menuDomainModel.updateMenuVariantFeature(m, vf);
    updateMenuVariantFeature$.pipe(take(1)).subscribe(_ => {
      this.loadingOpts.removeRequest(lm);
      this._selectedOverrideLabelKey.next(null);
      this.closeModal();
    }, (error: BsError) => {
      this.loadingOpts.removeRequest(lm);
      this.toastService.publishError(error);
      throwError(error);
    });
  }

}
