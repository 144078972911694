import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Card } from '../../../../models/shared/stylesheet/card';
import { CardStyle } from '../../../../models/shared/stylesheet/card-style.enum';
import { BaseComponent } from '../../../../models/base/base-component';
import { exists } from '../../../../functions/exists';

@Component({
  selector: 'app-card-deck',
  templateUrl: './card-deck.component.html',
  styleUrls: ['./card-deck.component.scss'],
})
export class CardDeckComponent extends BaseComponent implements AfterViewInit, OnChanges {

  @Input() cards: Card[] = [];
  @Input() horizontalScroller = true;
  @Input() maxHeightRem: number = 18;
  @Input() columnGap: string = '1rem';
  @Input() rowGap: string = '1rem';
  @Input() scrollToCardId: string;

  @Output() cardPressed: EventEmitter<Card> = new EventEmitter<Card>();
  @Output() cardButtonPressed: EventEmitter<Card> = new EventEmitter<Card>();

  @ViewChild('cardDeck') cardDeck: ElementRef<HTMLDivElement>;

  // View
  public cardStyles = CardStyle;

  constructor() {
    super();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.scrollToCard(this.scrollToCardId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scrollToCardId) this.scrollToCard(this.scrollToCardId);
  }

  scrollToCard(cardId: string) {
    if (exists(cardId)) {
      const parent = this.cardDeck?.nativeElement;
      const element = document?.getElementById(cardId);
      if (exists(parent) && exists(element)) {
        const parentRect = parent?.getBoundingClientRect();
        const elementRect = element?.getBoundingClientRect();
        if (this.horizontalScroller) {
          // accounts for padding
          const scrollLeft = parent.scrollLeft + (elementRect.left - parentRect.left);
          this.cardDeck?.nativeElement?.scrollTo({ left: scrollLeft, behavior: 'smooth' });
        } else {
          // accounts for padding
          const scrollTop = parent.scrollTop + (elementRect.top - parentRect.top);
          this.cardDeck?.nativeElement?.scrollTo({ top: scrollTop, behavior: 'smooth' });
        }
      }
    }
  }

  handleCardPress(card: Card) {
    this.cardPressed.emit(card);
  }

}
