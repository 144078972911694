<tr
  class="table-item"
  [class.expanded-parent]="expanded$ | push : 'userBlocking'"
  [class.odd]="(index + 1) % 2 !== 0"
  (click)="viewModel?.productClicked(rowData)">
  <ng-container *rxFor="let key of viewModel.productTableColumnKeys$ | push : 'userBlocking'">
    <ng-container
      [ngSwitch]="
        key
          | ProductTableColumnKeyToData
            : (viewModel.enabledCannabinoids$ | push : 'userBlocking')
            : (viewModel.enabledTerpenes$ | push : 'userBlocking')
      ">
      <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Name" class="fixed-column">
        <div fxLayout="row" fxLayoutAlign=" center" [style.gap.rem]="1">
          <div fxLayout="row" fxLayoutAlign=" center">
            <app-group-selection
              #groupSelectionComponent
              [selection]="selection$ | push : 'userBlocking'"
              [previouslyAddedIds]="viewModel.previouslyAddedIds$ | push : 'userBlocking'"
              [selectedIds]="viewModel.idsToBeAdded$ | push : 'userBlocking'"
              (bulkAdd)="viewModel.bulkAdd($event)"
              (bulkRemove)="viewModel.bulkRemove($event)">
            </app-group-selection>
            <div
              *ngIf="
                !(viewModel.productSelectionOnly$ | push : 'userBlocking') &&
                (groupSelectionComponent.nChildrenSelected$ | push : 'userBlocking') > 0
              "
              class="checked-count">
              ({{ groupSelectionComponent.nChildrenSelected$ | push : 'userBlocking' }})
            </div>
          </div>
          <div class="header-title">
            {{ rowData | getProductTitle | doubleDashIfEmpty }}
            <app-table-property-changed
              *rxIf="!!(rowData?.displayAttributes?.getDisplayName() | removeDashes)"
              [oldValue]="rowData?.name">
            </app-table-property-changed>
          </div>
        </div>
      </td>
      <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Brand">
        {{ brand$ | push : 'userBlocking' | doubleDashIfEmpty }}
      </td>
      <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.ProductType">
        {{ rowData?.getProductTypeString() | doubleDashIfEmpty }}
      </td>
      <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.StrainType">
        {{
          rowData?.getStrainClassification()
            | presentClassificationToUser : viewModel.classificationMap$
            | push : 'userBlocking'
            | doubleDashIfEmpty
        }}
      </td>
      <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Quantity">
        {{
          rowData
            | presentProductQuantityInStockToUser : viewModel.inventoryProvider$
            | push : 'userBlocking'
            | doubleDashIfEmpty
        }}
      </td>
      <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Price">
        {{
          priceNoDollarSigns$ | push : 'userBlocking' | toFixedDecimal : 2 | addDollarSignToPrice | doubleDashIfEmpty
        }}
      </td>
      <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.SecondaryPrice">
        {{
          secondaryPriceNoDollarSigns$
            | push : 'userBlocking'
            | toFixedDecimal : 2
            | addDollarSignToPrice
            | doubleDashIfEmpty
        }}
      </td>
      <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Badge">
        <app-table-badges [item]="rowData" [badges]="badges$ | push : 'userBlocking'"></app-table-badges>
      </td>
      <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Label">
        <app-label-inflator
          #productLabel
          [zoom]="labelZoom$ | push : 'userBlocking'"
          [displayLabelInterface]="productLabelInterface$ | push : 'userBlocking'">
        </app-label-inflator>
      </td>
      <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.TopTerpene">
        {{
          rowData | getProductTopTerpene : (viewModel.enabledTerpeneNames$ | push : 'userBlocking') | doubleDashIfEmpty
        }}
      </td>
      <ng-container *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Cannabinoid">
        <td *rxIf="viewModel.displayCannabinoidsInRanges$ | push : 'userBlocking'">
          <ng-container *ngIf="key === PrimaryCannabinoid.TAC; else restOfProductCannabinoids">
            {{ rowData | getProductMinTACWithUnits | push | doubleDashIfEmpty }}
          </ng-container>
          <ng-template #restOfProductCannabinoids>
            {{ rowData?.getMinCannabinoidWithUnits(key) | doubleDashIfEmpty }}
            <app-table-property-changed
              *rxIf="!!(rowData?.displayAttributes?.getMinCannabinoidOrTerpene(key) | removeDashes)"
              [oldValue]="rowData?.getMinCannabinoidWithoutDisplayAttributes(key)">
            </app-table-property-changed>
          </ng-template>
        </td>
        <td *rxIf="viewModel.displayCannabinoidsInRanges$ | push : 'userBlocking'">
          <ng-container *ngIf="key === PrimaryCannabinoid.TAC; else restOfProductCannabinoids">
            {{ rowData | getProductMaxTACWithUnits | push | doubleDashIfEmpty }}
          </ng-container>
          <ng-template #restOfProductCannabinoids>
            {{ rowData?.getMaxCannabinoidWithUnits(key) | doubleDashIfEmpty }}
            <app-table-property-changed
              *rxIf="!!(rowData?.displayAttributes?.getMaxCannabinoidOrTerpene(key) | removeDashes)"
              [oldValue]="rowData?.getMaxCannabinoidWithoutDisplayAttributes(key)">
            </app-table-property-changed>
          </ng-template>
        </td>
        <td *rxIf="!(viewModel.displayCannabinoidsInRanges$ | push : 'userBlocking')">
          <ng-container *ngIf="key === PrimaryCannabinoid.TAC; else restOfProductCannabinoids">
            {{ rowData | getProductTACWithUnits | push | doubleDashIfEmpty }}
          </ng-container>
          <ng-template #restOfProductCannabinoids>
            {{ rowData?.getCannabiniodWithUnits(key) | doubleDashIfEmpty }}
            <app-table-property-changed
              *rxIf="!!(rowData?.displayAttributes?.getCannabinoidOrTerpene(key) | removeDashes)"
              [oldValue]="rowData?.getCannabinoidWithoutDisplayAttributes(key)">
            </app-table-property-changed>
          </ng-template>
        </td>
      </ng-container>
      <ng-container *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Terpene">
        <td *rxIf="viewModel.displayTerpenesInRanges$ | push : 'userBlocking'">
          {{ rowData?.getMinTerpeneWithUnits(key) | doubleDashIfEmpty }}
        </td>
        <td *rxIf="viewModel.displayTerpenesInRanges$ | push : 'userBlocking'">
          {{ rowData?.getMaxTerpeneWithUnits(key) | doubleDashIfEmpty }}
        </td>

        <td *rxIf="!(viewModel.displayTerpenesInRanges$ | push : 'userBlocking')">
          {{ rowData?.getTerpeneWithUnits(key) | doubleDashIfEmpty }}
        </td>
      </ng-container>
    </ng-container>
  </ng-container>
</tr>

<ng-container *ngIf="expanded$ | push : 'userBlocking'">
  <tr
    *ngFor="let variant of rowData?.variantsFilteredByTable; let nestedRowIndex = index"
    class="nested-table-data-cell"
    [class.space-between-product-and-variants]="nestedRowIndex === 0"
    (click)="variantContainerClicked()">
    <ng-container *rxFor="let key of viewModel.productTableColumnKeys$ | push : 'userBlocking'">
      <ng-container
        [ngSwitch]="
          key
            | ProductTableColumnKeyToData
              : (viewModel.enabledCannabinoids$ | push : 'userBlocking')
              : (viewModel.enabledTerpenes$ | push : 'userBlocking')
        ">
        <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Name" class="fixed-column">
          <div fxLayout="row" fxLayoutAlign=" center">
            <div fxFlex fxLayout="row" fxLayoutAlign=" center" [style.gap.rem]="1">
              <app-selectable
                #selectVariant
                *ngIf="!(viewModel.productSelectionOnly$ | push : 'userBlocking')"
                [selection]="variant"
                [previouslyAddedIds]="viewModel.previouslyAddedVariantIds$ | push : 'userBlocking'"
                [selectedIds]="viewModel.variantIdsToBeAdded$ | push : 'userBlocking'"
                (add)="viewModel.addVariantId($event)"
                (remove)="viewModel.removeVariantId($event)">
              </app-selectable>
              <app-display-name
                [displayName]="variant?.getDisplayName()"
                [isMedical]="variant?.isMedical"></app-display-name>
              <app-table-property-changed
                *rxIf="!!(variant?.displayAttributes?.getDisplayName() | removeDashes)"
                [oldValue]="variant?.name">
              </app-table-property-changed>
            </div>
            <div class="variant-sizing">{{ variant | variantFormattedSizing }}</div>
          </div>
        </td>
        <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Brand">
          {{ variant?.brand | doubleDashIfEmpty }}
        </td>
        <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.ProductType">
          {{ variant?.variantType | doubleDashIfEmpty }}
        </td>
        <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.StrainType">
          {{
            variant?.classification
              | presentClassificationToUser : viewModel.classificationMap$
              | push : 'userBlocking'
              | doubleDashIfEmpty
          }}
        </td>
        <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Quantity">
          {{
            variant
              | presentVariantQuantityInStockToUser : viewModel.inventoryProvider$
              | push : 'userBlocking'
              | doubleDashIfEmpty
          }}
        </td>
        <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Price">
          <span
            [class.sale-price]="
              locationId$ | push : 'userBlocking' | isVariantOnSale : variant : (priceFormat$ | push : 'userBlocking')
            ">
            {{
              locationId$
                | push : 'userBlocking'
                | variantPrice : variant : (priceFormat$ | push : 'userBlocking') : false
                | toFixedDecimal : 2
                | addDollarSignToPrice
                | doubleDashIfEmpty
            }}
          </span>
          <span
            *rxIf="
              locationId$ | push : 'userBlocking' | isVariantOnSale : variant : (priceFormat$ | push : 'userBlocking')
            "
            class="original-price">
            {{
              locationId$
                | push : 'userBlocking'
                | variantOriginalPrice : variant : (priceFormat$ | push : 'userBlocking')
                | toFixedDecimal : 2
                | addDollarSignToPrice
                | doubleDashIfEmpty
            }}
          </span>
        </td>
        <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.SecondaryPrice">
          {{
            locationId$
              | push : 'userBlocking'
              | variantSecondaryPrice : (companyId$ | push : 'userBlocking') : variant
              | toFixedDecimal : 2
              | addDollarSignToPrice
              | doubleDashIfEmpty
          }}
        </td>
        <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Badge">
          <app-table-badges [item]="variant" [badges]="variant | variantBadges : rowData$ | push : 'userBlocking'">
          </app-table-badges>
        </td>
        <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Label">
          <app-label-inflator
            #variantLabel
            *rxIf="variant?.computedLabelForProductTable | isSaleSystemLabel; else isNotASaleLabel"
            [zoom]="labelZoom$ | push : 'userBlocking'"
            [displaySaleLabelInterface]="
              variant | variantSaleLabelInterface : dataForLabelInterface$ | push : 'userBlocking'
            ">
          </app-label-inflator>
          <ng-template #isNotASaleLabel>
            <app-label-inflator
              [zoom]="labelZoom$ | push : 'userBlocking'"
              [displayLabelInterface]="
                variant | variantLabelInterface : dataForLabelInterface$ | push : 'userBlocking'
              ">
            </app-label-inflator>
          </ng-template>
        </td>
        <td *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.TopTerpene">
          {{
            variant
              | getVariantTopTerpene : (viewModel.enabledTerpeneNames$ | push : 'userBlocking')
              | doubleDashIfEmpty
          }}
        </td>
        <ng-container *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Cannabinoid">
          <td *rxIf="viewModel.displayCannabinoidsInRanges$ | push : 'userBlocking'">
            <ng-container *ngIf="key === PrimaryCannabinoid.TAC; else restOfVariantCannabinoids">
              {{
                variant
                  | getVariantMinTACWithUnits : (viewModel.enabledSecondaryCannabinoidNames$ | push)
                  | doubleDashIfEmpty
              }}
            </ng-container>
            <ng-template #restOfVariantCannabinoids>
              {{ variant | getMinCannabinoidWithUnits : key | doubleDashIfEmpty }}
              <app-table-property-changed
                *rxIf="!!(variant?.displayAttributes?.getMinCannabinoidOrTerpene(key) | removeDashes)"
                [oldValue]="variant?.getMinCannabinoidWithoutDisplayAttributes(key)">
              </app-table-property-changed>
            </ng-template>
          </td>
          <td *rxIf="viewModel.displayCannabinoidsInRanges$ | push : 'userBlocking'">
            <ng-container *ngIf="key === PrimaryCannabinoid.TAC; else restOfVariantCannabinoids">
              {{
                variant
                  | getVariantMaxTACWithUnits : (viewModel.enabledSecondaryCannabinoidNames$ | push)
                  | doubleDashIfEmpty
              }}
            </ng-container>
            <ng-template #restOfVariantCannabinoids>
              {{ variant | getMaxCannabinoidWithUnits : key | doubleDashIfEmpty }}
              <app-table-property-changed
                *rxIf="!!(variant?.displayAttributes?.getMaxCannabinoidOrTerpene(key) | removeDashes)"
                [oldValue]="variant?.getMaxCannabinoidWithoutDisplayAttributes(key)">
              </app-table-property-changed>
            </ng-template>
          </td>
          <td *rxIf="!(viewModel.displayCannabinoidsInRanges$ | push : 'userBlocking')">
            <ng-container *ngIf="key === PrimaryCannabinoid.TAC; else restOfVariantCannabinoids">
              {{ variant | getTACWithUnits | doubleDashIfEmpty }}
            </ng-container>
            <ng-template #restOfVariantCannabinoids>
              {{ variant | getCannabinoidWithUnits : key | doubleDashIfEmpty }}
              <app-table-property-changed
                *rxIf="!!(variant?.displayAttributes?.getCannabinoidOrTerpene(key) | removeDashes)"
                [oldValue]="variant?.getCannabinoidWithoutDisplayAttributes(key)">
              </app-table-property-changed>
            </ng-template>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="ClientTypeUtils.ProductTableColumnId.Terpene">
          <td *rxIf="viewModel.displayTerpenesInRanges$ | push : 'userBlocking'">
            {{ variant | getMinTerpeneWithUnits : key | doubleDashIfEmpty }}
          </td>
          <td *rxIf="viewModel.displayTerpenesInRanges$ | push : 'userBlocking'">
            {{ variant | getMaxTerpeneWithUnits : key | doubleDashIfEmpty }}
          </td>
          <td *rxIf="!(viewModel.displayTerpenesInRanges$ | push : 'userBlocking')">
            {{ variant | getTerpeneWithUnits : key | doubleDashIfEmpty }}
          </td>
        </ng-container>
      </ng-container>
    </ng-container>
  </tr>
</ng-container>
