import { DefaultStackedSizeType } from './default-stacked-size-type';
import { DefaultPrintLabelSize } from '../../enum/dto/default-print-label-size';

export class DefaultPrintLabelSizeType extends DefaultStackedSizeType {

  override value: DefaultPrintLabelSize;

  public getImageSrcFromSize(): string {
    switch (this.value) {
      case DefaultPrintLabelSize.DefaultSize_CustomLabel2x4:
        return 'assets/img/preview/inventory-label-2x4.svg';
    }
  }

  public getNameFromSize(): string {
    switch (this.value) {
      case DefaultPrintLabelSize.DefaultSize_CustomLabel2x4: return 'Default Label';
    }
  }

  public getDimensionString(): string {
    switch (this.value) {
      case DefaultPrintLabelSize.DefaultSize_CustomLabel2x4: return '2” x 4"';
    }
  }

}
