<app-edit-menu-section-section-details
  [templateMode]="templateMode"
  [mergeKey]="dispersedAndMergeKey"
  [formPristine]="formPristine"
  [autoUpdateGridColumns]="autoUpdateGridColumns"
  (selectedSectionLayoutType)="onLayoutTypeSelected($event)">
  Section Details
</app-edit-menu-section-section-details>

<app-menu-section-location-permissions
  *ngIf="(viewModel.section$ | async | isSectionTemplate) && (viewModel.section$ | async | sectionCanContainProducts)"
  [mergeKey]="dispersedAndMergeKey"
  [sectionTemplate]="viewModel.section$ | async | castToSectionTemplate">
</app-menu-section-location-permissions>

<app-menu-section-colors
  *ngIf="viewModel.supportsSectionColors$ | async"
  [templateMode]="templateMode"
  [mergeKey]="dispersedAndMergeKey"
  [section]="viewModel.section$ | async"
  [isTemplatedSection]="viewModel.isTemplatedSection$ | async"
  [sectionSupportsTitleSectionBackgroundColor]="viewModel.sectionSupportsTitleSectionBackgroundColor$ | async"
  [sectionSupportsHeaderBackgroundColor]="viewModel.sectionSupportsHeaderBackgroundColor$ | async"
  [sectionSupportsHeaderTextColor]="viewModel.sectionSupportsHeaderTextColor$ | async"
  [sectionSupportsProductContainerBackgroundColor]="viewModel.sectionSupportsProductContainerBackgroundColor$ | async"
  [sectionSupportsBodyTextColor]="viewModel.sectionSupportsBodyTextColor$ | async">
</app-menu-section-colors>

<app-edit-menu-section-primary-asset
  [hidden]="viewModel.showPrimarySectionAssetSection$ | async"
  [primaryAssetTitle]="viewModel.sectionPrimaryAssetTitle$ | async"
  [primaryAssetDesc]="viewModel.sectionPrimaryAssetDesc$ | async"
  [hideLoading]="viewModel.hideSectionAssetLoading$ | async"
  [loadingOpts]="viewModel.sectionAssetLoadingOpts$ | async"
  [sectionAsset]="viewModel.sectionMedia$ | async"
  [templateMode]="templateMode"
  [allowVideo]="!isPrintMenu"
  [formPristine]="formPristine"
  [isTemplatedSection]="viewModel.isTemplatedSection$ | async"
  [sectionAssetPreview]="viewModel.sectionMediaPreview$ | async"
  [parentHandler]="this"
  (changeSectionAssetClicked)="viewModel.handleChangeSectionAsset()"
  (deleteSectionAssetClicked)="viewModel.handleDeleteSectionAsset()"
  (refreshSectionAssetClicked)="viewModel.handleMenuSectionAssetRefresh($event)">
</app-edit-menu-section-primary-asset>

<app-menu-section-smart-filters
  *ngIf="viewModel.isProductSection$ | async"
  [templateMode]="templateMode"
  [menu]="viewModel.menu$ | async"
  [section]="viewModel.section$ | async"
  [selectedSectionLayoutType]="selectedSectionLayoutType"
  [isTemplatedSection]="viewModel.isTemplatedSection$ | async"
  [autoSaving]="viewModel.autoSaving$ | async"
  [mergeKey]="dispersedAndMergeKey"
  [formPristine]="formPristine"
  (autoUpdateGridColumns)="onAutoUpdateGridColumns($event)">
</app-menu-section-smart-filters>

<app-edit-menu-section-products
  [templateMode]="templateMode"
  [formHasErrors]="formHasErrors"
  [formPristine]="formPristine">
</app-edit-menu-section-products>
