import { Component, EventEmitter, Injector, Input, NgZone, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Menu } from '../../../../../../../../../models/menu/dto/menu';
import { BaseComponent } from '../../../../../../../../../models/base/base-component';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { DropDownItem } from '../../../../../../../../../models/shared/stylesheet/drop-down-item';
import { map, switchMap, take } from 'rxjs/operators';
import { FeaturedCategoryMenuCardType } from '../../../../../../../../../models/utils/dto/featured-category-menu-card-type';
import { OverflowState, OverflowStateInfoType, OverflowStateType } from '../../../../../../../../../models/utils/dto/overflow-state-type';
import { DefaultCarouselDuration } from '../../../../../../../../../models/menu/dto/menu-metadata';
import '../../../../../../../../../utils/type-definition-array.extentions';
import { Orientation } from '../../../../../../../../../models/utils/dto/orientation-type';
import { CardType } from '../../../../../../../../../models/utils/dto/card-type-definition';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOverflowInformation } from '../../../../../../../../../modals/modal-overflow-information';

@Component({
  selector: 'app-featured-category-cards-details-form',
  templateUrl: './featured-category-cards-details-form.component.html',
  styleUrls: ['./featured-category-cards-details-form.component.scss']
})
export class FeaturedCategoryCardsDetailsFormComponent extends BaseComponent implements OnChanges {

  constructor(
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  @Input() menu: Menu;
  @Input() mergeKey: string;
  @Input() bindTo: Menu;
  @Input() orientation: Orientation;
  @Output() cardTypeChanged: EventEmitter<CardType> = new EventEmitter<CardType>();
  @Output() cardStartAtChanged = new EventEmitter<string>(true);

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu.asObservable();

  public isTemplatedMenu$ = this.menu$.pipe(map(menu => menu?.isTemplatedMenu()));

  private _selectedCardType = new BehaviorSubject<FeaturedCategoryMenuCardType>(null);
  public selectedCardTypeName$ = this._selectedCardType.pipe(
    switchMap(selectedCardValue => {
      return window.types.featuredCategoryMenuCardTypes$.pipe(
        map(cardTypes => cardTypes?.find(it => it.value === selectedCardValue.value)),
        map(selectedCardTypeObject => selectedCardTypeObject?.name)
      );
    })
  );
  public isCarouselCard$ = this._selectedCardType.pipe(
    map(c => c.isCarouselCard())
  );
  private _selectedOrientation = new BehaviorSubject<Orientation>(null);
  public _cardsToDisplay = new BehaviorSubject<number>(undefined);
  public cardsToDisplay$ = this._cardsToDisplay.pipe(map(num => num?.toString()));
  private _selectedOverflowState = new BehaviorSubject<OverflowStateType>(null);
  public selectedOverflowTypeValue$ = this._selectedOverflowState.pipe(map(state => state?.value));
  public selectedOverflowTypeName$ = this._selectedOverflowState.pipe(
    switchMap(selectedOverflowType => {
      return window.types.allMenuOverflowStateTypes$.pipe(
        map(cardTypes => cardTypes?.find(it => it.value === selectedOverflowType.value)),
        map(selectedCardTypeObject => selectedCardTypeObject?.name)
      );
    })
  );

  public hideCardNumber$ = new BehaviorSubject<boolean>(null);
  public carouselDuration$ = new BehaviorSubject<string>(undefined);

  public tooltipModalInfoItems$ = combineLatest([
    this.isCarouselCard$,
    window.types.featuredCategoryOverflowStateTypes$
  ]).pipe(
    map(([isC, types]) => {
      const overflowType = isC ? OverflowStateInfoType.FeatCatCarousel : OverflowStateInfoType.FeatCatNonCarousel;
      const filteredTypes = types?.filter(it => it.value !== OverflowState.STEADY_SCROLL);
      return isC ? filteredTypes.getInfoItems(overflowType) : types.getInfoItems(overflowType);
    })
  );

  public overflowStates$ = combineLatest([
    window.types.featuredCategoryOverflowStateTypes$,
    this._selectedCardType
  ]).pipe(
    map(([overflowStateTypes, selectedCardType]) => {
      if (selectedCardType.isCarouselCard()) {
        // Filter out SteadyScroll option for Carousel cards
        return overflowStateTypes?.filter(it => it.value !== OverflowState.STEADY_SCROLL);
      }
      return overflowStateTypes;
    })
  );

  public cardCountOptions$ = combineLatest([
    this._selectedCardType,
    this._selectedOrientation,
  ]).pipe(
    map(([cardType, orientation]) => {
      const cardCounts: DropDownItem[] = [];
      if (cardType.isProductDealCarousel()) {
        cardCounts.push(new DropDownItem('9', '9'));
        cardCounts.push(new DropDownItem('12', '12'));
      } else {
        cardCounts.push(new DropDownItem('2', '2'));
        if (orientation === Orientation.Landscape) cardCounts.push(new DropDownItem('3', '3'));
        cardCounts.push(new DropDownItem('4', '4'));
      }
      return cardCounts;
    })
  );

  public carouselInfoMessage$ = combineLatest([
    this.selectedOverflowTypeName$,
    this._menu,
    this.cardsToDisplay$,
    this.isCarouselCard$,
    this.selectedCardTypeName$
  ]).pipe(
    map(([
      selectedOverflowTypeName,
      menu,
      cardCount,
      isCarouselCard,
      selectedCardTypeName
    ]) => {
      let message = '';
      const noneOverflow = window.types.initTypeDefinition(OverflowStateType, OverflowState.NONE);
      if (menu?.sections?.length > parseInt(cardCount, 10)) {
        // There are more cards than allowed to appear at the same time
        if (isCarouselCard) {
          message += `The ${selectedCardTypeName} will only show ${cardCount} cards at a time. This menu currently
        has ${menu?.sections?.length} cards, so only the first ${cardCount} will appear. \n \n`;
          if (selectedOverflowTypeName === noneOverflow?.name) {
            message += `Overflow is disabled so only the first product on each card will appear. \n \n`;
          } else {
            message += `The products within each card will rotate using the '${selectedOverflowTypeName}' overflow
            option based on the carousel duration.\n \n`;
          }
        } else {
          message += `The ${selectedCardTypeName} will only show ${cardCount} cards at a time. All additional cards
           will appear based on the '${selectedOverflowTypeName}' overflow option and time interval that is defined
           on the menu's display duration.\n \n`;
        }
      } else {
        // All cards will appear at once
        if (isCarouselCard) {
          if (selectedOverflowTypeName === noneOverflow?.name) {
            message += `Overflow is disabled so only the first product will appear on each card.\n \n`;
          } else {
            message += `The products within each card will rotate using the '${selectedOverflowTypeName}' overflow
            option based on the carousel duration.\n \n`;
          }
        }
      }
      return message;
    })
  );

  public carouselDurationPlaceholder$ = this.isCarouselCard$.pipe(
    map(isCarouselCard => {
      if (isCarouselCard) {
        return 'Time for each product to be displayed on a card.';
      } else {
        return 'Disabled for non-carousel cards.';
      }
    })
  );

  parseNumberAsString = (val: string) => Number.parseInt(val, 10).toString();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) {
      this._selectedCardType.next(new FeaturedCategoryMenuCardType(this.menu?.metadata?.cardType, ''));
      this._selectedOverflowState.next(new OverflowStateType(this.menu?.overflowState, ''));
      this._cardsToDisplay.next(Number(this.menu?.metadata?.cardCount));

      const cardTypeDef = window.types.initTypeDefinition(FeaturedCategoryMenuCardType, this.menu?.metadata?.cardType);
      const isCarousel = cardTypeDef?.isCarouselCard();
      const durationExists = !!this.menu?.metadata?.carouselDuration && this.menu?.metadata?.carouselDuration !== '0';
      if (isCarousel && !durationExists) {
        this.menu.metadata.carouselDuration = `${DefaultCarouselDuration}`;
      } else if (!isCarousel && durationExists) {
        this.menu.metadata.carouselDuration = '';
      }
      this.carouselDuration$.next(this.menu?.metadata?.carouselDuration);

      this._menu.next(this.menu);
      if (!!this.menu?.metadata?.hideCardNumber) {
        this.hideCardNumber$.next(Boolean(JSON.parse(this.menu.metadata.hideCardNumber)));
      } else {
        this.hideCardNumber$.next(false);
      }
    }
    if (changes.orientation) this._selectedOrientation.next(this.orientation);
  }

  override setupViews() {
    this._menu.next(this.menu);
    this.setDefaultCarouselDuration(this.menu?.metadata?.cardType);
  }

  public cardTypeSelected(type: CardType) {
    this._selectedCardType.next(new FeaturedCategoryMenuCardType(type, ''));
    this.cardTypeChanged.emit(type);
    if (type === CardType.ProductDealCarousel) {
      this._cardsToDisplay.next(9);
      this.bindTo.metadata.cardCount = '9';
    } else {
      this._cardsToDisplay.next(2);
      this.bindTo.metadata.cardCount = '2';
    }
    this.setDefaultCarouselDuration(type);
    this.setDefaultOverflowState(type);
  }

  private setDefaultCarouselDuration(cardType: CardType) {
    const isCarousel = window.types.initTypeDefinition(FeaturedCategoryMenuCardType, cardType)?.isCarouselCard();
    const durationExists = !!this.bindTo?.metadata?.carouselDuration && this.bindTo?.metadata?.carouselDuration !== '0';
    if (isCarousel && !durationExists) {
      this.bindTo.metadata.carouselDuration = `${DefaultCarouselDuration}`;
    } else if (!isCarousel && durationExists) {
      this.bindTo.metadata.carouselDuration = '';
    }
    this.carouselDuration$.next(this.bindTo?.metadata?.carouselDuration);
  }

  private setDefaultOverflowState(cardType: CardType) {
    combineLatest([
      this._selectedOverflowState,
      this.overflowStates$,
    ]).pipe(take(1)).subscribe(([selectedOverflowState, overflowStates]) => {
      const isCarousel = window.types.initTypeDefinition(FeaturedCategoryMenuCardType, cardType)?.isCarouselCard();
      if (selectedOverflowState.value === OverflowState.STEADY_SCROLL && isCarousel) {
        this.bindTo.overflowState = OverflowState.NONE;
        this._selectedOverflowState.next(overflowStates?.find(s => s.value === OverflowState.NONE));
      }
    });
  }

  public hideCardNumberToggled(hide: string) {
    const hideNumber = Boolean(JSON.parse(hide));
    this.hideCardNumber$.next(hideNumber);
  }

  public cardsToDisplaySelected(numOfCards: number) {
    this._cardsToDisplay.next(numOfCards);
  }

  public overflowTypeSelected(state: OverflowState) {
    this._selectedOverflowState.next(new OverflowStateType(state, ''));
  }

  public openOverflowInfo() {
    this.isCarouselCard$.pipe(take(1)).subscribe( isCarouselCard => {
      if (isCarouselCard) {
        const type = OverflowStateInfoType.FeatCatCarousel;
        ModalOverflowInformation.open(this.ngZone, this.ngbModal, this.injector, type);
      } else {
        const type = OverflowStateInfoType.FeatCatNonCarousel;
        ModalOverflowInformation.open(this.ngZone, this.ngbModal, this.injector, type);
      }
    });
  }

}
