import { UniquelyIdentifiable } from '../../../../../../models/protocols/uniquely-identifiable';
import { DisplayableSubItem } from '../../../../../../models/shared/displayable-subitem';

export const DISPLAYABLE_ITEM_PREVIEW_COUNT =  5;

export interface DisplayableItem extends UniquelyIdentifiable {

  active: boolean;
  name: string;
  tag: string;

  displayableItemId(): string;
  displayableItemPreviewContentIds(locationId: number): (string | string[])[];
  displayableItemShouldShowSeeMoreCard(): boolean;
  displayableItemTotalCount(): number;
  displayableItemContainsStackedContent(): boolean;

  // Smart Filter Indication
  displayableItemHasSmartFilters(): boolean;
  displayableItemSmartFilterIndicatorTooltip(): string;

  // Active Logic
  displayableItemIsActive(): boolean;
  displayableItemActiveText(): string;
  displayableItemInactiveText(): string;
  displayableItemShowActiveBadge(): boolean;

  // Specific to Templates
  displayableItemShowDeployedCountIndicator(): boolean;
  displayableItemDeployedCountTooltipText(): string;
  displayableItemDeployedCount(): number;
  displayableItemDeployedCountIcon(): string;
  displayableItemIsTemplatedMenu(): boolean;

  // Sub Information
  displayableItemSubtitle(): string;
  displayableItemSubItemList?(): DisplayableSubItem[];

}
