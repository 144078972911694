import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector, NgZone } from '@angular/core';
import { MenuTemplate } from '../models/template/dto/menu-template';
import { ModalUtils } from '../utils/modal-utils';
import { MenuTemplateRequiredLocationsChangedSaveConfirmationComponent } from '../views/template/components/modals/menu-template-required-locations-changed-save-confirmation/menu-template-required-locations-changed-save-confirmation.component';

export class ModalMenuTemplateRequiredLocationsChangedSaveConfirmation {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    template: MenuTemplate,
    updatedRequiredLocationIds: number[],
    confirmed?: () => void
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(
        MenuTemplateRequiredLocationsChangedSaveConfirmationComponent,
        ModalUtils.menuTemplateSaveConfirmationOptions(injector)
      );
      const compInst = modalRef.componentInstance as MenuTemplateRequiredLocationsChangedSaveConfirmationComponent;
      compInst.viewModel.connectToTemplate(template);
      compInst.viewModel.connectToUpdatedRequiredLocationIds(updatedRequiredLocationIds);
      modalRef.result.then(confirm => { if (confirm) confirmed?.(); }).catch(() => {});
    });
  }

}
