import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injector } from '@angular/core';
import { HydratedSection } from '../models/menu/dto/hydrated-section';
import { ModalUtils } from '../utils/modal-utils';
import { NewMenuSectionModalComponent } from '../views/menu/components/modals/new-menu-section-modal/new-menu-section-modal.component';
import { Section } from '../models/menu/dto/section';
import { Menu } from '../models/menu/dto/menu';
import { SectionType } from '../models/enum/dto/section-type';

export class ModalAddSmartPlaylistProductGrouping {

  static open(
    ngbModal: NgbModal,
    injector: Injector,
    menu: Menu,
    existingSection?: HydratedSection,
    onClose?: (section: Section) => void
  ) {
    const modalRef = ngbModal.open(
      NewMenuSectionModalComponent,
      ModalUtils.newMenuSectionModalOptions(injector)
    );
    const modalInstance = modalRef.componentInstance as NewMenuSectionModalComponent;
    modalInstance.setMenuToAddSectionTo(menu);
    if (!!existingSection) {
      modalInstance.setExistingSectionToDuplicate(existingSection);
    } else {
      modalInstance.setSectionTypeToCreate(SectionType.ProductGroup);
    }
    modalRef.result.then((section) => onClose?.(section)).catch(() => {});
  }

}
