import { ChangeDetectionStrategy, Component, Injector, NgZone } from '@angular/core';
import { EditCardStackAssetSectionViewModel } from './edit-card-stack-asset-section-view-model';
import { MenuSectionBackgroundComponent } from '../../shared/menu-section-background/menu-section-background.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuPreviewService } from '../../../../../../services/menu-preview.service';

@Component({
  selector: 'app-edit-card-stack-asset-section',
  templateUrl: './edit-card-stack-asset-section.component.html',
  styleUrls: ['./edit-card-stack-asset-section.component.scss'],
  providers: [EditCardStackAssetSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCardStackAssetSectionComponent extends MenuSectionBackgroundComponent {

  constructor(
    override viewModel: EditCardStackAssetSectionViewModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    menuPreviewService: MenuPreviewService
  ) {
    super(viewModel, ngZone, ngbModal, injector, menuPreviewService);
  }

}
