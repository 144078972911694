import { EditTemplateMenuSectionViewModel } from '../../../../edit-template-menu-section/edit-template-menu-section-view-model';
import { TemplateDomainModel } from '../../../../../../../domainModels/template-domain-model';
import { EditFeaturedCategorySectionViewModel } from '../../../../../../menu/components/edit-menu/edit-marketing-menu/edit-featured-category-menu/edit-featured-category-section/edit-featured-category-section-view-model';
import { Injectable, Injector, NgZone } from '@angular/core';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class EditTemplateFeaturedCategorySectionViewModel extends EditTemplateMenuSectionViewModel {

  /*
   * Need EditFeaturedCategorySectionViewModel injected here so that it's scoped to this template.
   * This allows child components that require these view models to share the same instance.
   */
  constructor(
    public override editMenuSectionViewModel: EditFeaturedCategorySectionViewModel,
    templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    protected override ngZone: NgZone,
    protected override ngbModal: NgbModal,
    protected override injector: Injector
  ) {
    super(editMenuSectionViewModel, templateDomainModel, locationDomainModel, ngZone, ngbModal, injector);
  }

}
