import { Injectable, Injector, NgZone } from '@angular/core';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalEditSpecificDateTime } from '../../../../../../modals/modal-edit-specific-date-time';
import { CompactMenu } from '../../../../../../models/menu/dto/compact-menu';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { EditDisplayViewModel } from '../../../../viewModels/edit-display-view-model';
import { map, shareReplay } from 'rxjs/operators';
import { TemplateCollection } from '../../../../../../models/template/dto/template-collection';
import { Display } from '../../../../../../models/display/dto/display';

@Injectable()
export class DisplayMenuSectionBodyViewModel extends BaseViewModel {

  constructor(
    protected editDisplayViewModel: EditDisplayViewModel,
    private ngZone: NgZone,
    private ngbModal: NgbModal,
    private injector: Injector,
  ) {
    super();
  }

  public display$ = this.editDisplayViewModel.display$;

  protected _compactMenu = new BehaviorSubject<CompactMenu>(null);
  public compactMenu$ = this._compactMenu as Observable<CompactMenu>;
  connectToCompactMenu = (menu: CompactMenu) => this._compactMenu.next(menu);

  protected _menuIsPartOfCollection = new BehaviorSubject<boolean>(false);
  public menuIsPartOfACollection$ = this._menuIsPartOfCollection as Observable<boolean>;
  connectToMenuIsPartOfCollection = (menuIsPartOfCollection: boolean) => {
    this._menuIsPartOfCollection.next(menuIsPartOfCollection);
  };

  public menuHasSpecificDateTimesConfigured$ = combineLatest([
    this.display$,
    this.compactMenu$,
    this.menuIsPartOfACollection$
  ]).pipe(
    map(([display, menu, menuIsPartOfCollection]) => {
      if (menuIsPartOfCollection && display instanceof Display) {
        const templateCollection = display.templateCollections?.find(tc => tc?.templateIds?.includes(menu?.id));
        const collectionTimeDuration = templateCollection?.options?.overrides?.get(menu?.id);
        return collectionTimeDuration?.hasSchedule() || false;
      }
      const timeDuration = display?.options?.overrides?.get(menu?.id);
      return timeDuration?.hasSchedule() || false;
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  protected _bindTo = new BehaviorSubject<any>(null);
  public bindTo$ = this._bindTo as Observable<any>;
  connectToBindTo = (bindTo: any) => this._bindTo.next(bindTo);

  openEditSpecificDateTimeModal() {
    combineLatest([
      this.display$,
      this.compactMenu$,
      this.bindTo$,
    ]).once(([display, compactMenu, bindTo]) => {
      const saveFunc = display instanceof TemplateCollection
        ? this.editDisplayViewModel.updateTemplateCollectionAndReloadDisplays
        : this.editDisplayViewModel.updateDisplay;
      ModalEditSpecificDateTime.open(this.ngZone, this.ngbModal, this.injector, display, compactMenu, bindTo, saveFunc);
    });
  }

}
