import { Injectable, Injector, NgZone } from '@angular/core';
import { EditTemplateMarketingMenuViewModel } from '../edit-template-marketing-menu-view-model';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditUrlPlaylistMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-url-playlist-menu/edit-url-playlist-menu-view-model.service';
import { map } from 'rxjs/operators';
import { MarketingTheme } from '../../../../../../models/enum/dto/theme.enum';

@Injectable()
export class EditTemplateMarketingUrlPlaylistMenuViewModel extends EditTemplateMarketingMenuViewModel {

  constructor(
    protected override editMenuViewModel: EditUrlPlaylistMenuViewModel,
    templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(editMenuViewModel, templateDomainModel, locationDomainModel, ngZone, ngbModal, injector);
  }

  public readonly headerText$ = this.activeTemplate$.pipe(
    map(menu => {
      return menu?.theme === MarketingTheme.SurfsideUrlPlaylist
        ? 'Edit Template - Surfside Playlist Menu'
        : 'Edit Template - URL Playlist Menu';
    }),
  );

}
