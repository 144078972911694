import { WebSocketReceiveMessage } from './web-socket-receive-message';

export class WebSocketConnectionEstablished extends WebSocketReceiveMessage {

  override payload: {
    connectionId: string;
    connectionState: string;
  };

}
