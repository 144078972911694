import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../models/base/base-component';
import { ColorUtils } from '../../../../utils/color-utils';
import { PopperUtils } from '../../../../utils/popper-utils';

@Component({
  selector: 'app-products-table-action-button',
  templateUrl: './products-table-action-button.component.html',
  styleUrls: ['./products-table-action-button.component.scss']
})
export class ProductsTableActionButtonComponent extends BaseComponent {

  @Input() heightInRem;
  @Input() widthInRem;
  @Input() cursor;
  @Input() showNewLines = false;
  @Input() tooltip: string;
  @Input() iconUrl: string;
  @Input() disabled: boolean = false;
  @Input() spinCCW: boolean = false;
  @Output() clicked = new EventEmitter<void>(true);

  // Popper
  public popperModifier = [PopperUtils.flipModifier(['bottom', 'top', 'left'])];
  public popperStyles = { 'background-color': ColorUtils.BUDSENSE_BLACK_COLOR };

  constructor() {
    super();
  }

  public buttonClicked(): void {
    if (!this.disabled) this.clicked.emit();
  }

}
