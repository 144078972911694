import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../models/base/base-component';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { EditTemplateShelfTalkerBrandsMenuViewModel } from '../edit-template-shelf-talker-brands-menu/edit-template-shelf-talker-brands-menu/edit-template-shelf-talker-brands-menu-view-model';

@Component({
  selector: 'app-edit-template-shelf-talker-menu-footer',
  templateUrl: './edit-template-shelf-talker-menu-footer.component.html',
  styleUrls: [
    './edit-template-shelf-talker-menu-footer.component.scss',
    '../../../../../shared/styles/edit-page-styles.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateShelfTalkerMenuFooterComponent extends BaseComponent {

  constructor(
    public viewModel: EditTemplateShelfTalkerBrandsMenuViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToFormAutoSubmitted') formAutoSubmitted: any[];
  @Input() formErrorMessages: { [label:string]: string } | null | undefined;
  @Input() formDisableSubmit: boolean = true;
  @Input() formHasErrors: boolean = true;
  @Input() formPristine: boolean = true;
  @Output() submitForms = new EventEmitter<boolean>(true);

}
