import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BulkPrintJob } from '../../../../../../models/automation/bulk-print-job';
import { map, shareReplay } from 'rxjs/operators';
import { MenuPreviewJobStatus } from '../../../../../../models/utils/dto/menu-preview-job-status-type';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class StackPrintJobAppliedSectionsViewModel {

  constructor(
    protected menuDomainModel: MenuDomainModel,
    protected productDomainModel: ProductDomainModel,
  ) {
  }

  protected _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => this._job.next(job);

  protected readonly jobStatus$ = this.job$.pipe(
    map(job => job?.getStatusToDisplay()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly locationVariants$ = of([]);

  public readonly sections$ = this.menuDomainModel.currentLocationShelfTalkerMenus$.pipe(
    map(menus => menus?.flatMap(menu => menu?.getSectionsBasedOnMenuType()))
  );

  public noSectionsPlaceholder$ = this.jobStatus$.pipe(
    map(status => {
      const processing = status === MenuPreviewJobStatus.MenuPreviewJobStatus_Processing;
      const queued = status === MenuPreviewJobStatus.MenuPreviewJobStatus_Queued;
      return (processing || queued) ? 'Job is processing' : 'No cards applied';
    })
  );

}
