<app-loading *ngIf="viewModel.isLoading$ | async" [options]="viewModel.loadingOpts$ | async"> </app-loading>

<lib-reactive-form-merge-groups
  #newMenuFormGroups
  [mergeKey]="'create-menu'"
  (canSubmit$)="viewModel.connectToFormIsValid($event)"
  (formsSubmitted$)="viewModel.createNewMenu()">
</lib-reactive-form-merge-groups>

<lib-sticky-modal [height]="viewModel.fixedModalHeight$ | push : 'immediate'">
  <lib-sticky-header [stickyCollectionKey]="stickyCollectionKey" [stickyZ]="9" [stickyOrder]="0">
    <div class="modal-header">
      <div class="modal-title">
        {{ viewModel?.modalTitle$ | async }}
      </div>
    </div>
  </lib-sticky-header>
  <lib-sticky-body [position]="'relative'">
    <app-menu-creation-method
      *rxIf="viewModel.isHowToCreateStep$; strategy: 'immediate'"
      (selectedCreationType)="viewModel.setMenuCreationType($event)">
    </app-menu-creation-method>
    <app-menu-type-picker
      *rxIf="viewModel.isPickMenuSubTypeStep$; strategy: 'immediate'"
      [menuType]="viewModel.menuType$ | push"
      [paddingLeft]="'0'"
      [paddingRight]="'0'"
      (selectedSubTypes)="viewModel.setMenuSubTypes($event)"
      (selectedMenuType)="viewModel.selectedMenuType($event)">
    </app-menu-type-picker>
    <lib-reactive-form-group
      *rxIf="viewModel.isPickMenuThemeStep$; strategy: 'immediate'"
      [mergeKey]="'create-menu'"
      [dispersedKey]="viewModel.dispersedKey$ | async"
      [bindTo]="viewModel.newMenu$ | async">
      <app-theme-picker
        [menuType]="viewModel.menuType$ | async"
        [menuSubtypes]="viewModel.menuSubTypes$ | async"
        [tags]="viewModel.tags$ | async"
        [dispersedKey]="viewModel.dispersedKey$ | async"
        (themeSelected)="viewModel.connectToThemeSelected($event)"
        (stackSizeSelected)="viewModel.connectToSelectedStackSize($event)"
        (orientationSelected)="viewModel.connectToSelectedOrientation($event)">
      </app-theme-picker>
    </lib-reactive-form-group>
    <app-searchable-single-select
      [hidden]="!(viewModel.isImportFromTemplateStep$ | async)"
      [searchableItems]="viewModel.selectableTemplates$ | async"
      [noItemsBody]="'No Templates'"
      [noItemsTitle]="'Templates will appear here'"
      [stickyCollectionKey]="stickyCollectionKey"
      [stickyOrder]="1"
      [stickyZ]="9"
      (selectedItem)="viewModel.setTemplate($event)">
    </app-searchable-single-select>
  </lib-sticky-body>
  <lib-sticky-footer>
    <div class="flex-container flex-align-center">
      <div>
        <lib-button-text [hidden]="viewModel.hideGoBack$ | async" (buttonClicked)="viewModel.goBack()">
          Go Back
        </lib-button-text>
      </div>
      <div [style.margin-left]="'auto'">
        <lib-button-neutral [style.margin-right]="'0.5rem'" (buttonClicked)="cancel(null)"> Cancel </lib-button-neutral>
        <lib-button-primary
          *ngIf="!(viewModel.isHowToCreateStep$ | async) && !(viewModel.isPickMenuSubTypeStep$ | async)"
          [style.margin-left]="'0.5rem'"
          [disabled]="!(viewModel.canSubmitForm$ | async)"
          (buttonClicked)="saveClicked()">
          Save
        </lib-button-primary>
      </div>
    </div>
  </lib-sticky-footer>
</lib-sticky-modal>
