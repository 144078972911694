<app-stack-smart-bulk-print-config-form
  [configMap]="viewModel.stackPrintConfigMap$ | push"
  [job]="viewModel.job$ | push"
  [mergeKey]="viewModel.mergeKey$ | push">
</app-stack-smart-bulk-print-config-form>

<app-hidden-array-form-input
  [inputName]="'printMenuSelections'"
  [bindingProperty]="'menuIds'"
  [disabled]="viewModel.viewOnly$ | push"
  [required]="true"
  [dispersedKey]="viewModel.mergeKey$ | push"
  [inputArr]="viewModel.validSelectedMenuIds$ | push">
</app-hidden-array-form-input>

<div *rxIf="!(viewModel.viewOnly$ | push)" class="stack-manual-bulk-print-job-container">
  <app-stack-manual-bulk-print-job-left-pane></app-stack-manual-bulk-print-job-left-pane>
  <app-stack-manual-bulk-print-job-right-pane></app-stack-manual-bulk-print-job-right-pane>
</div>

<div *rxIf="viewModel.viewOnly$" class="view-bulk-print-job">
  <app-print-job-name-form
    [dispersedKey]="viewModel.mergeKey$ | push"
    [mergeKey]="viewModel.mergeKey$ | push"
    [job]="viewModel.job$ | push"
    [label]="'Stack Print Name'"
    [placeholder]="viewModel.placeholder$ | push"
    [viewOnly]="viewModel.viewOnly$ | push">
  </app-print-job-name-form>
  <hr class="mt-0 mb-24px" />
  <app-print-job-employee-form
    [job]="viewModel.job$ | push"
    [mergeKey]="viewModel.mergeKey$ | push"
    [viewOnly]="viewModel.viewOnly$ | push">
  </app-print-job-employee-form>
  <ng-container [ngSwitch]="viewModel.typeOfStack$ | push | stringIncludes : 'Shelf'">
    <app-bulk-card-stack-print-job-applied-sections
      *ngSwitchCase="true"
      [job]="viewModel.job$ | push"
      [title]="'Shelf Talkers'" />
    <app-bulk-card-stack-print-job-applied-products
      *ngSwitchDefault
      [job]="viewModel.job$ | push"
      [title]="'Products'" />
  </ng-container>
</div>
