import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PrintMenuLiveViewModalViewModel } from './print-menu-live-view-modal-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalComponent } from '../../../../models/base/base-modal.component';

@Component({
  selector: 'app-print-menu-live-view-modal',
  templateUrl: './print-menu-live-view-modal.component.html',
  styleUrls: ['./print-menu-live-view-modal.component.scss'],
  providers: [PrintMenuLiveViewModalViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintMenuLiveViewModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: PrintMenuLiveViewModalViewModel,
    activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  override close(returnValue?: any) {
    this.activeModel?.close(returnValue);
  }

}
