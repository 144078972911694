import { Injectable, Injector, NgZone } from '@angular/core';
import { DigitalMenusContainerViewModel } from '../digital-menus-container/digital-menus-container-view-model';
import { MenuDomainModel } from '../../../../../../domainModels/menu-domain-model';
import { DisplayableItemsViewModel } from '../../../../../shared/components/displayable-content/displayable-item-list/displayable-items-view-model';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';
import { NavigationService } from '../../../../../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayableType } from '../../../../../../models/enum/shared/displayableType.enum';

@Injectable()
export class DigitalMarketingMenusViewModel extends DisplayableItemsViewModel {

  constructor(
    protected menuDomainModel: MenuDomainModel,
    protected digitalProductMenuViewModel: DigitalMenusContainerViewModel,
    navigationService: NavigationService,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
  ) {
    super(digitalProductMenuViewModel, navigationService, ngZone, ngbModal, injector);
    this.setupFilter();
  }

  protected defaultEmptyStateTitle = 'No Digital Marketing Menus';

  protected itemsToFilter$ = this.menuDomainModel.currentLocationMarketingMenus$;

  protected getEmptyStateTitleCopy(menuTypeFilter: string, menuFormatFilter: DisplayableType): string {
    return this.genericEmptyStateTitleCopyForMenu(
      menuTypeFilter,
      menuFormatFilter,
      'digital marketing menus',
      'templated digital marketing menus'
    );
  }

  public createMarketingMenu(): void {
    this.createMenu(MenuType.MarketingMenu);
  }

}
