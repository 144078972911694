import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getVariantMaxTACWithUnits'
})
export class GetVariantMaxTacWithUnitsPipe implements PipeTransform {

  transform(variant: Variant | null, enabledCannabinoids: string[]): string | null {
    return variant?.getMaxTACWithUnits(enabledCannabinoids);
  }

}
