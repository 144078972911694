import { Deserializable } from '../../protocols/deserializable';
import { SessionChallenge } from './session-challenge';
import { DateUtils } from '../../../utils/date-utils';
import { exists } from '../../../functions/exists';

export class Session implements Deserializable {

  static readonly REFRESHING_AUTH_TOKEN_FROM_403 = 'REFRESHING_BECAUSE_OF_403';

  /**
   * accessToken is set to Session.REFRESHING_AUTH_TOKEN_FROM_403 while the current token is invalid and needs to
   * be refreshed. This prevents router guards from redirecting the user to the login page while the token is being
   * refreshed.
   */
  public accessToken: string;
  public expiresIn: number;
  public expiresAt: number;
  public idToken: string;
  public refreshToken: string;
  public tokenType: string;
  public challenge: SessionChallenge;

  public onDeserialize() {
    this.challenge = window?.injector?.Deserialize?.instanceOf(SessionChallenge, this.challenge);
  }

  public hasChallenge(): boolean {
    return exists(this.challenge);
  }

  public getChallengeAuthToken(): string {
    return this.challenge?.authSession;
  }

  public setAccessTokenToRefreshing(): void {
    this.accessToken = Session.REFRESHING_AUTH_TOKEN_FROM_403;
  }

  public hasValidSessionAndNotRefreshingBecauseOf403(): boolean {
    return exists(this.accessToken)
        && this.accessToken !== Session.REFRESHING_AUTH_TOKEN_FROM_403
        && this.expiresAt > DateUtils.currentTimestamp();
  }

  public refreshingBecauseOf403(): boolean {
    return this.accessToken === Session.REFRESHING_AUTH_TOKEN_FROM_403;
  }

  /**
   * Session is still valid if the token equals Session.REFRESHING_AUTH_TOKEN_FROM_403. This state means that the
   * session is in the middle of refreshing, and we aren't sure if the token will be refreshed successfully.
   * In this state, all API calls are intercepted and queued until the token is refreshed.
   */
  public validSession(): boolean {
    return exists(this.accessToken) && this.expiresAt > DateUtils.currentTimestamp();
  }

  /**
   * The accessToken can be set to Session.REFRESHING_AUTH_TOKEN_FROM_403, which means the access token is still valid,
   * but we are in the middle of refreshing it. This prevents router guards from redirecting the user to the login
   * page while the token is being refreshed.
   */
  public hasAccessToken(): boolean {
    return exists(this.accessToken);
  }

  public expiresInNMilliSecondsMinus(nSeconds: number): number {
    const expiresIn = (this.expiresAt - DateUtils.nowInUnixSeconds() - nSeconds) * 1000;
    if (expiresIn < 0) {
      return 0;
    }
    return expiresIn;
  }

}
