import { DuplicateMenuRequest } from '../../../models/menu/shared/duplicate-menu-request';
import { BsError } from '../../../models/shared/bs-error';
import { MenuDomainModel } from '../../../domainModels/menu-domain-model';
import { ToastService } from '../../../services/toast-service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { Menu } from '../../../models/menu/dto/menu';
import { BaseModalViewModel } from '../../../models/base/base-modal-view-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuType } from '../../../models/utils/dto/menu-type-definition';
import { MenuTemplate } from '../../../models/template/dto/menu-template';
import { TemplateDomainModel } from '../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../domainModels/location-domain-model';
import { StringUtils } from '../../../utils/string-utils';
import { NavigationService } from '../../../services/navigation.service';
import { DefaultPrintStackSize } from '../../../models/enum/dto/default-print-stack-size';

@Injectable()
export class DuplicateMenuViewModel extends BaseModalViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    private templateDomainModel: TemplateDomainModel,
    private menuDomainModel: MenuDomainModel,
    private navigationService: NavigationService,
    private toastService: ToastService,
    router: Router,
    ngbModal: NgbModal,
  ) {
    super(router, ngbModal);
  }

  private readonly locationId$ = this.locationDomainModel.locationId$;
  public readonly allLocations$ = this.locationDomainModel.allLocations$;
  public readonly themes$ = this.menuDomainModel.menuThemes$;

  private readonly _menu = new BehaviorSubject<Menu>(null);
  public readonly menu$ = this._menu as Observable<Menu>;
  connectToMenu = (menu: Menu) => this._menu.next(menu);

  public readonly menuType$ = this.menu$.pipe(map(menu => menu?.type));

  public menuName$ = this.menu$.pipe(map(menu => menu?.name));
  public menuThemeSupportedCardSizes$ = this.menu$.pipe(
    map(menu => menu?.hydratedTheme?.printConfig?.printCardSizes?.map(size => size as DefaultPrintStackSize))
  );
  public isTemplatedMenu$ = this.menu$.pipe(
    map(menu => menu?.isTemplatedMenu()),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public isTemplatedMenuBannerText$ = of('When a Templated Menu is duplicated, a point in time copy is made at'
    + ' the current location. The new menu will not be linked to the template anymore.');

  public isTemplate$ = this.menu$.pipe(
    map(menu => menu instanceof MenuTemplate),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public isCardStack$ = this.menu$.pipe(
    map(menu => menu?.isPrintCardMenu()),
    shareReplay({bufferSize: 1, refCount:true})
  );

  public isLabelStack$ = this.menu$.pipe(
    map(menu => menu?.isPrintLabelMenu()),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public readonly isShelfTalker$ = this.menu$.pipe(
    map(menu => menu?.isShelfTalkerMenu()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly containsStackedContent$ = this.menu$.pipe(
    map(menu => menu?.containsStackedContent()),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _selectedCardStackSize = new BehaviorSubject<DefaultPrintStackSize>(null);
  public selectedCardStackSize$ = this._selectedCardStackSize as Observable<DefaultPrintStackSize>;
  connectToSelectedCardStackSize = (selectedSize: DefaultPrintStackSize) => {
    this._selectedCardStackSize.next(selectedSize);
  };

  public req$ = combineLatest([this.locationId$, this.menu$]).pipe(
    map(([locationId, menu]) => new DuplicateMenuRequest(locationId, menu)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public navigateToDuplicatedMenu: boolean = true;

  public duplicateMenu() {
    combineLatest([
      this.locationId$,
      this.menu$,
      this.req$,
      this.selectedCardStackSize$
    ]).once(([locationId, duplicateFrom, req, selectedCardStack]) => {
      const isTemplate = duplicateFrom instanceof MenuTemplate;
      if (duplicateFrom?.containsStackedContent()) {
        req.printCardSize = selectedCardStack;
      }
      const lm = StringUtils.replaceMenuWithTemplate('Duplicating Menu', isTemplate);
      if (!this._loadingOpts.containsRequest(lm)) {
        this._loadingOpts.addRequest(lm);
        const duplicateMenu$ = isTemplate
          ? this.templateDomainModel.duplicateMenuTemplate(locationId, req)
          : this.menuDomainModel.duplicateMenu(req);
        duplicateMenu$.subscribe({
          next: (duplicatedMenu: Menu) => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishSuccessMessage(
              StringUtils.replaceMenuWithTemplate('Menu duplicated successfully', isTemplate),
              StringUtils.replaceMenuWithTemplate('Menu Duplicated', isTemplate)
            );
            this.processDuplicatedMenu(duplicateFrom, duplicatedMenu);
            this.dismissModalSubject.next(true);
          },
          error: (error: BsError) => {
            this._loadingOpts.removeRequest(lm);
            this.toastService.publishError(error);
            throwError(error);
          }
        });
      }
    });
  }

  private processDuplicatedMenu(duplicateFrom: Menu, duplicatedMenu: Menu) {
    this.checkIfShouldChangeCurrentLocation(duplicatedMenu).once((shouldChangeLocation) => {
      if (shouldChangeLocation) {
        this.menuDomainModel.connectToMenuDuplicatingToNewLocation(true);
        this.changeLocation(duplicatedMenu);
      }
      this.setInDuplicateMenuWithAssetMapIfNecessary(duplicateFrom, duplicatedMenu);
      this.navigateToNewMenuIfNecessary(duplicatedMenu);
      this.menuDomainModel.connectToMenuDuplicatingToNewLocation(false);
    });
  }

  private checkIfShouldChangeCurrentLocation(duplicatedMenu: Menu): Observable<boolean> {
    return this.locationId$.pipe(
      take(1),
      map((locationId) => {
        const isTemplate = duplicatedMenu instanceof MenuTemplate;
        const locationChanged = duplicatedMenu?.locationId !== locationId;
        return !isTemplate && locationChanged && this.navigateToDuplicatedMenu;
      })
    );
  }

  private changeLocation(duplicatedMenu: Menu): void {
    this.allLocations$.once((allLocations) => {
      const changeTo = allLocations?.find(l => l?.id === duplicatedMenu?.locationId);
      this.locationDomainModel.setCurrentLocation(changeTo);
      this.toastService.publishInfoMessage(`${changeTo?.name}`, 'Location Changed');
    });
  }

  private setInDuplicateMenuWithAssetMapIfNecessary(duplicatedFrom: Menu, duplicatedMenu: Menu) {
    if (!!duplicatedFrom?.backgroundImage && duplicatedFrom?.type !== MenuType.MarketingMenu) {
      const id = duplicatedMenu?.id;
      const fileName = duplicatedFrom?.backgroundImage?.fileName;
      duplicatedFrom instanceof MenuTemplate
        ? this.templateDomainModel.duplicatedMenuTemplatesWithAssets.set(id, [fileName])
        : this.menuDomainModel.duplicatedMenuIdsWithAsset.set(id, [fileName]);
    }
  }

  private navigateToNewMenuIfNecessary(duplicatedMenu: Menu) {
    if (this.navigateToDuplicatedMenu) {
      this.navigationService.navigateToEditMenuOrTemplate(duplicatedMenu);
    }
  }

}
