import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../../../models/menu/dto/menu';

@Pipe({
  name: 'isShelfTalkerMenu'
})
export class IsShelfTalkerMenuPipe implements PipeTransform {

  transform(value: any): Menu | null {
    return (value?.isShelfTalkerMenu?.() ?? false) ? value : null;
  }

}
