import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EditTemplateShelfTalkerMenuComponent } from '../../edit-template-shelf-talker-menu.component';
import { EditTemplateShelfTalkerBrandsMenuViewModel } from './edit-template-shelf-talker-brands-menu-view-model';
import { EditShelfTalkerBrandsMenuViewModel } from '../../../../../../menu/components/edit-menu/edit-shelf-talker-menu/edit-shelf-talker-brands-menu/edit-shelf-talker-brands-menu-view-model';
import { EditTemplateShelfTalkerBrandsMenuCombinedLoadingOptsViewModel } from './edit-template-shelf-talker-brands-menu-combined-loading-opts-view-model';
import { EditProductMenuViewModel } from '../../../../../../menu/viewModels/edit-product-menu-view-model';

@Component({
  selector: 'app-edit-template-shelf-talker-brands-menu',
  templateUrl: './edit-template-shelf-talker-brands-menu.component.html',
  styleUrls: ['./edit-template-shelf-talker-brands-menu.component.scss'],
  providers: [
    EditTemplateShelfTalkerBrandsMenuViewModel,
    EditShelfTalkerBrandsMenuViewModel,
    EditTemplateShelfTalkerBrandsMenuCombinedLoadingOptsViewModel,
    { provide: EditProductMenuViewModel, useExisting: EditShelfTalkerBrandsMenuViewModel }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateShelfTalkerBrandsMenuComponent extends EditTemplateShelfTalkerMenuComponent {

  constructor(
    public override viewModel: EditTemplateShelfTalkerBrandsMenuViewModel,
    public override combinedLoadingOptsViewModel: EditTemplateShelfTalkerBrandsMenuCombinedLoadingOptsViewModel
  ) {
    super(viewModel, combinedLoadingOptsViewModel);
  }

  @Input() mergeAndDispersedKey: string = 'editTemplateShelfTalkerBrandsMenu';

}
