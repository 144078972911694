import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HydratedSmartFilter } from '../../../../../../../models/automation/hydrated-smart-filter';
import { TerpeneInfo } from '../../terpene-add-edit-smart-filter-form-view-model';

@Injectable()
export class TerpeneMinMaxFormViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _smartFilter = new BehaviorSubject<HydratedSmartFilter>(null);
  public smartFilter$ = this._smartFilter as Observable<HydratedSmartFilter>;
  connectToSmartFilter = (smartFilter: HydratedSmartFilter) => {
    this._smartFilter.next(smartFilter);
  };

  private _terpene = new BehaviorSubject<TerpeneInfo>(null);
  public terpene$ = this._terpene as Observable<TerpeneInfo>;
  connectToTerpene = (terpene: TerpeneInfo) => {
    this._terpene.next(terpene);
  };

  private _minTerpeneValue = new BehaviorSubject<number>(undefined);
  public minTerpeneValue$ = this._minTerpeneValue as Observable<number>;
  connectToMinTerpeneValue = (minTerpeneValue: number) => {
    this._minTerpeneValue.next(minTerpeneValue);
  };

  private _maxTerpeneValue = new BehaviorSubject<number>(undefined);
  public maxTerpeneValue$ = this._maxTerpeneValue as Observable<number>;
  connectToMaxTerpeneValue = (minTerpeneValue: number) => {
    this._maxTerpeneValue.next(minTerpeneValue);
  };

  public minTerpeneMaxValue$ = this.maxTerpeneValue$.pipe(
    map(maxTerpene => maxTerpene || null)
  );
  public maxTerpeneMinValue$ = this.minTerpeneValue$.pipe(
    map(minTerpene => minTerpene || 0.01)
  );

}
