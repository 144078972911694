/* eslint-disable @typescript-eslint/member-ordering */
// noinspection JSUnusedLocalSymbols

import { BaseViewModel } from '../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, iif, merge, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { ProductDomainModel } from '../../../../domainModels/product-domain-model';
import { Injectable } from '@angular/core';
import { Variant } from '../../../../models/product/dto/variant';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { HasChildIds } from '../../../../models/protocols/has-child-ids';
import * as moment from 'moment';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../domainModels/cannabinoids-and-terpenes-domain-model';
import { CompanyDomainModel } from '../../../../domainModels/company-domain-model';
import { DisplayAttributesDomainModel } from '../../../../domainModels/display-attributes-domain-model';
import { LocationDomainModel } from '../../../../domainModels/location-domain-model';
import { LabelDomainModel } from '../../../../domainModels/label-domain-model';
import { DistinctUtils } from '../../../../utils/distinct-utils';
import { ScrollLinkService } from './scroll-link/scroll-link.service';
import { HydratedVariantBadge } from '../../../../models/product/dto/hydrated-variant-badge';
import { LabelUpdate } from '../../../../models/enum/dto/label-update.enum';
import { BadgeUpdate } from '../../../../models/enum/dto/badge-update.enum';
import { DisplayAttribute } from '../../../../models/display/dto/display-attribute';
import { PropertyLevel } from '../../../../models/enum/shared/property-level.enum';
import { ToastService } from '../../../../services/toast-service';
import { CustomizationProperty } from '../../../../models/enum/dto/customization-property.enum';
import { BsError } from '../../../../models/shared/bs-error';
import { LocationChangedUtils } from '../../../../utils/location-changed-utils';
import { iiif } from '../../../../utils/observable.extensions';
import { exists } from '../../../../functions/exists';
import { BadgeChangeMap, CannabinoidMinOrMax, ChangeMap, LastModifiedMap, PropertyLevelAbbreviation, TerpeneMinOrMax } from './order-receiving-types';
import { StringUtils } from '../../../../utils/string-utils';

// Provided by Logged In Scope
@Injectable()
export class OrderReceivingViewModel extends BaseViewModel {

  constructor(
    protected cannabinoidAndTerpsDomainModel: CannabinoidsAndTerpenesDomainModel,
    protected companyDomainModel: CompanyDomainModel,
    protected displayAttributeDomainModel: DisplayAttributesDomainModel,
    protected labelDomainModel: LabelDomainModel,
    protected locationDomainModel: LocationDomainModel,
    protected productDomainModel: ProductDomainModel,
    protected scrollLinkService: ScrollLinkService,
    protected toastService: ToastService,
  ) {
    super();
  }

  public readonly locationId$ = this.locationDomainModel.locationId$;
  public readonly products$ = this.productDomainModel.currentLocationProducts$;
  public readonly variants$ = this.productDomainModel.currentLocationVariants$;
  public readonly enabledCannabinoidNames$ = this.cannabinoidAndTerpsDomainModel.enabledCannabinoidNames$;
  public readonly enabledTerpeneNamesCamelCased$ = this.cannabinoidAndTerpsDomainModel.enabledTerpeneNamesCamelCased$;

  private _hasFilters = new BehaviorSubject<boolean>(false);
  public readonly hasFilters$ = this._hasFilters as Observable<boolean>;
  connectToHasFilters = (hasFilters: boolean) => this._hasFilters.next(hasFilters);

  private _inventorySinceXSecondsIntoThePast = new BehaviorSubject<number>(null);
  public readonly inventorySinceXSecondsIntoThePast$ = this._inventorySinceXSecondsIntoThePast as Observable<number>;
  connectToXSecondsIntoThePast = (seconds: number) => this._inventorySinceXSecondsIntoThePast.next(seconds);

  private _cannabinoidProperties = new BehaviorSubject<string[]>([]);
  public readonly cannabinoidProperties$ = this._cannabinoidProperties.pipe(
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings)
  );
  connectToCannabinoids = (cannabinoids: string[]) => this._cannabinoidProperties.next(cannabinoids);

  private _customizationProperties = new BehaviorSubject<string[]>([]);
  public readonly customizationProperties$ = this._customizationProperties.pipe(
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings)
  );
  connectToCustomization = (customizations: string[]) => this._customizationProperties.next(customizations);

  private _terpeneProperties = new BehaviorSubject<string[]>([]);
  public readonly terpeneProperties$ = this._terpeneProperties.pipe(
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings)
  );
  connectToTerpenes = (terpenes: string[]) => this._terpeneProperties.next(terpenes);

  private _hideRecentlyUpdated = new BehaviorSubject<boolean>(false);
  public readonly hideRecentlyUpdated$ = this._hideRecentlyUpdated as Observable<boolean>;
  connectToHideRecentlyUpdated = (hide: boolean) => this._hideRecentlyUpdated.next(hide);

  public inventoryProvider$ = this.companyDomainModel.inventoryProvider$;

  private _numberOfProductsPerPage = new BehaviorSubject<number>(10);
  public readonly numberOfProductsPerPage$ = this._numberOfProductsPerPage.pipe(
    debounceTime(100),
    distinctUntilChanged(),
    startWith(10)
  );
  connectToNumberOfProductsPerPage = (n: number) => this._numberOfProductsPerPage.next(n);

  private _searchText = new BehaviorSubject<string>('');
  public readonly searchText$ = this._searchText as Observable<string>;
  connectToSearchText = (searchText: string) => this._searchText.next(searchText);
  public readonly hasSearchText$ = this.searchText$.pipe(map(text => text?.length > 0));

  public readonly hasBulkEditPropertiesSelected$ = combineLatest([
    this.cannabinoidProperties$,
    this.terpeneProperties$,
    this.customizationProperties$
  ]).pipe(
    map(([cannabinoidFilters, terpeneFilters, customizationFilters]) => {
      return cannabinoidFilters?.length > 0 || terpeneFilters?.length > 0 || customizationFilters?.length > 0;
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly productsSinceXSecondsIntoThePast$ = combineLatest([
    this.inventorySinceXSecondsIntoThePast$,
    this.products$
  ]).pipe(
    map(([xSecondsIntoPast, products]) => {
      if (!products) return null; // null puts the table into a loading state
      const past = moment().subtract(xSecondsIntoPast, 'seconds').unix();
      products?.forEach(product => {
        product.variantsFilteredByLastNDays = product?.variants?.filter(v => v?.restockedWithin(past) || false);
      });
      return products?.filter(product => product.variantsFilteredByLastNDays?.length > 0);
    })
  );

  private _variantsFromFilterForm = new BehaviorSubject<Variant[]>(null);
  public readonly variantsFromFilterForm$ = this._variantsFromFilterForm as Observable<Variant[]>;
  connectToFilteredVariants = (variants: Variant[]) => this._variantsFromFilterForm.next(variants);

  public readonly sortedFilteredVariantsSinceXSecondsIntoThePast$ = combineLatest([
    this.variantsFromFilterForm$,
    this.hasBulkEditPropertiesSelected$
  ]).pipe(
    map(([variants, hasBulkEditPropertiesSelected]) => {
      if (hasBulkEditPropertiesSelected) {
        return variants;
      } else {
        return !!variants ? [] : null;
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
    debounceTime(100),
  ).deepCopyArray();

  private readonly rangeCannabinoidsAtCompanyLevel$ = this.companyDomainModel.rangeCannabinoidsAtCompanyLevel$;
  private readonly rangeTerpenesAtCompanyLevel$ = this.companyDomainModel.rangeTerpenesAtCompanyLevel$;

  private listenForLocationChange = LocationChangedUtils
    .onLocationChange(this, this.locationId$, (locationId: number) => this.clearSelectedVariants());

  private sortedVariantIdsSinceXSecondsIntoThePast$ = this.sortedFilteredVariantsSinceXSecondsIntoThePast$.pipe(
    map(variants => variants?.map(variant => variant?.id)),
    filter(variantIds => variantIds?.length > 0),
    distinctUntilChanged(DistinctUtils.distinctSortedStrings),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /* ******************************** Selection Checkbox Logic ******************************** */

  private _selectedVariantIds = new BehaviorSubject<string[]>([]);
  public readonly selectedVariantIds$ = this._selectedVariantIds.pipe(
    distinctUntilChanged(DistinctUtils.distinctSortedStrings),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public readonly selectedVariants$ = combineLatest([
    this.selectedVariantIds$,
    this.variants$
  ]).pipe(
    map(([selectedVariantIds, variants]) => variants?.filter(variant => selectedVariantIds?.includes(variant?.id))),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public readonly nVariantIdsAdded$ = this.selectedVariantIds$.pipe(
    map(variantIds => variantIds?.length),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  // clear group values when the number of selected variants goes to 0.
  private listenToNVariantsSelected = this.nVariantIdsAdded$
    .pipe(distinctUntilChanged())
    .subscribeWhileAlive({
      owner: this,
      next: n => {
        if (n === 0) this.scrollLinkService.resetScrollPosition();
      }
    });

  private _variantsBeingDisplayed = new BehaviorSubject<Variant[]>([]);
  public readonly variantsBeingDisplayed$ = this._variantsBeingDisplayed as Observable<Variant[]>;
  connectToVariantsBeingDisplayed = (variants: Variant[]) => this._variantsBeingDisplayed.next(variants);

  public readonly nestedVariantsSelectAllPlugin$ = this.variantsBeingDisplayed$.pipe(
    map(variants => {
      return new class implements HasChildIds {

        getId = (): string => 'not relevant';
        getChildIds = (): string[] => variants?.map(variant => variant?.getId()) ?? [];

      }();
    })
  );

  /* ************************** Individual Data Changes **************************** */

  private individualCannabinoidUpdate$ = (
    enabled$: Observable<boolean>,
    incomingUpdate$: Observable<[string, number]>,
    saveHere$: Observable<Map<string, BehaviorSubject<number>>>
  ): Observable<[string, number]> => {
    return iiif(
      enabled$,
      saveHere$.pipe(
        switchMap(individualChanges => {
          return incomingUpdate$.pipe(
            map(([vId, update]) => (Number.isNaN(update) ? [vId, null] : [vId, update]) as [string, number]),
            tap(([idToChange, update]) => individualChanges?.get(idToChange)?.next(update))
          );
        })
      ),
      of(null)
    ).pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };

  private getCannabinoidChangePipes$ = (
    enabled$: Observable<boolean>,
    changeMap: ChangeMap<number>,
    isRangePipe: boolean
  ): Observable<Map<string, BehaviorSubject<number>>> => {
    return iiif(
      enabled$,
      combineLatest([
        this.sortedVariantIdsSinceXSecondsIntoThePast$,
        this.variants$,
        this.rangeCannabinoidsAtCompanyLevel$
      ]).pipe(
        map(([variantIds, allVariants, companyLevelRangesEnabled]) => {
          variantIds?.forEach(variantId => {
            const variant = allVariants?.find(v => v?.id === variantId);
            const isRanged = companyLevelRangesEnabled || variant?.useCannabinoidRange;
            if ((isRangePipe && isRanged) || (!isRangePipe && !isRanged)) {
              if (!changeMap.has(variantId)) changeMap.set(variantId, new BehaviorSubject<number>(null));
            } else {
              changeMap?.get(variantId)?.next(null);
            }
          });
          return changeMap;
        })
      ),
      of(new Map<string, BehaviorSubject<number>>())
    ).pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };

  private getResetCannabinoidPipe$ = (
    resetSignal$: Observable<string>,
    changes$: Observable<Map<string, BehaviorSubject<number>>>
  ): Observable<any> => {
    return changes$.pipe(
      switchMap(changePipes => {
        return resetSignal$.pipe(
          filter(variantId => exists(changePipes?.get(variantId)?.value)),
          tap(variantId => changePipes?.get(variantId)?.next(null))
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };

  private readonly cannabinoidChangeAndUpdateSignals$ = this.enabledCannabinoidNames$.pipe(
    map(cannabinoids => {
      const cannabinoidChangeAndUpdateSignals = new Map();
      cannabinoids?.forEach(cannabinoid => {
        const signals = {
          name: cannabinoid,
          enabled$: this.cannabinoidAndTerpsDomainModel.isCannabinoidEnabled$(cannabinoid),
          locChanges: new Map<string, BehaviorSubject<number>>(),
          locMinChanges: new Map<string, BehaviorSubject<number>>(),
          locMaxChanges: new Map<string, BehaviorSubject<number>>(),
          compChanges: new Map<string, BehaviorSubject<number>>(),
          compMinChanges: new Map<string, BehaviorSubject<number>>(),
          compMaxChanges: new Map<string, BehaviorSubject<number>>(),
          locUpdate: new Subject<[string, number]>(),
          locMinUpdate: new Subject<[string, number]>(),
          locMaxUpdate: new Subject<[string, number]>(),
          compUpdate: new Subject<[string, number]>(),
          compMinUpdate: new Subject<[string, number]>(),
          compMaxUpdate: new Subject<[string, number]>(),
          compReset: new Subject<string>(),
          compResetMin: new Subject<string>(),
          compResetMax: new Subject<string>(),
        };
        cannabinoidChangeAndUpdateSignals.set(cannabinoid, signals);
      });
      return cannabinoidChangeAndUpdateSignals;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public getCannabinoidChangeSignalFor$(
    cannabinoid: string,
    level: PropertyLevelAbbreviation,
    minOrMax?: CannabinoidMinOrMax
  ): Observable<Map<string, BehaviorSubject<number>>> {
    return this.cannabinoidChangeAndUpdateSignals$.pipe(
      map(signals => {
        const accessor = exists(minOrMax) ? `${level}${minOrMax}Changes` : `${level}Changes`;
        return (signals?.get(cannabinoid)?.[accessor] as Map<string, BehaviorSubject<number>>) || null;
      })
    );
  }

  public getCannabinoidUpdateSignalFor$ = (
    cannabinoid: string,
    level: PropertyLevelAbbreviation,
    minOrMax?: CannabinoidMinOrMax
  ): Observable<Subject<[string, number]>> => {
    return this.cannabinoidChangeAndUpdateSignals$.pipe(
      map(signals => {
        const accessor = exists(minOrMax) ? `${level}${minOrMax}Update` : `${level}Update`;
        return (signals?.get(cannabinoid)?.[accessor] as Subject<[string, number]>) || null;
      })
    );
  };

  private readonly cannabinoidDataChangePipelines$ = this.cannabinoidChangeAndUpdateSignals$.pipe(
    filter(cannabinoids => cannabinoids?.size > 0),
    map(cannabinoids => {
      const updateSignals = new Map();
      cannabinoids?.forEach((s, cannabinoid) => {
        const changes = {
          locChanges$: this.getCannabinoidChangePipes$(s.enabled$, s.locChanges, false),
          locMinChanges$: this.getCannabinoidChangePipes$(s.enabled$, s.locMinChanges, true),
          locMaxChanges$: this.getCannabinoidChangePipes$(s.enabled$, s.locMaxChanges, true),
          compChanges$: this.getCannabinoidChangePipes$(s.enabled$, s.compChanges, false),
          compMinChanges$: this.getCannabinoidChangePipes$(s.enabled$, s.compMinChanges, true),
          compMaxChanges$: this.getCannabinoidChangePipes$(s.enabled$, s.compMaxChanges, true)
        };
        const updates = {
          locUpdate$: this.individualCannabinoidUpdate$(s.enabled$, s.locUpdate, changes.locChanges$),
          locMinUpdate$: this.individualCannabinoidUpdate$(s.enabled$, s.locMinUpdate, changes.locMinChanges$),
          locMaxUpdate$: this.individualCannabinoidUpdate$(s.enabled$, s.locMaxUpdate, changes.locMaxChanges$),
          compUpdate$: this.individualCannabinoidUpdate$(s.enabled$, s.compUpdate, changes.compChanges$),
          compMinUpdate$: this.individualCannabinoidUpdate$(s.enabled$, s.compMinUpdate, changes.compMinChanges$),
          compMaxUpdate$: this.individualCannabinoidUpdate$(s.enabled$, s.compMaxUpdate, changes.compMaxChanges$)
        };
        const signals = {
          name: cannabinoid,
          enabled$: s.enabled$,
          ...changes,
          ...updates
        };
        updateSignals.set(cannabinoid, signals);
      });
      return updateSignals;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public getCannabinoidDataChangePipelinesFor$ = (canna: string) => this.cannabinoidDataChangePipelines$.pipe(
    map(pipelines => pipelines?.get(canna))
  );

  private readonly startUpCannabinoidUpdatePipelines = this.cannabinoidDataChangePipelines$.pipe(
    switchMap(pipelines => {
      const update$ = [...(pipelines.values() || [])]?.map(s => {
        return merge(
          s?.locUpdate$ || of(null),
          s?.locMinUpdate$ || of(null),
          s?.locMaxUpdate$ || of(null),
          s?.compUpdate$ || of(null),
          s?.compMinUpdate$ || of(null),
          s?.compMaxUpdate$ || of(null)
        );
      });
      return merge(...update$);
    })
  ).subscribeWhileAlive({ owner: this });

  public clearAllCannabinoidChanges(variantId: string): void {
    this.cannabinoidChangeAndUpdateSignals$.once(bundle => {
      bundle?.forEach((signals, _) => {
        signals.locUpdate?.next([variantId, null]);
        signals.locMinUpdate?.next([variantId, null]);
        signals.locMaxUpdate?.next([variantId, null]);
        signals.compUpdate?.next([variantId, null]);
        signals.compMinUpdate?.next([variantId, null]);
        signals.compMaxUpdate?.next([variantId, null]);
      });
    });
  }

  public getIndividualCannabinoidChangePipeFor$ = (
    cannabinoid: string,
    level: PropertyLevelAbbreviation,
    minOrMax?: CannabinoidMinOrMax
  ): Observable<Map<string, BehaviorSubject<number>>> => {
    return this.getCannabinoidChangeSignalFor$(cannabinoid, level, minOrMax);
  };

  /* *** Terpenes *** */

  private individualTerpeneUpdate$ = (
    enabled$: Observable<boolean>,
    incomingUpdate$: Observable<[string, number]>,
    saveHere$: Observable<Map<string, BehaviorSubject<number>>>
  ): Observable<[string, number]> => {
    return iiif(
      enabled$,
      saveHere$.pipe(
        switchMap(individualChanges => {
          return incomingUpdate$.pipe(
            map(([vId, update]) => (Number.isNaN(update) ? [vId, null] : [vId, update]) as [string, number]),
            tap(([idToChange, update]) => individualChanges?.get(idToChange)?.next(update))
          );
        })
      ),
      of(null)
    ).pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };

  private getTerpeneChangePipes$ = (
    enabled$: Observable<boolean>,
    changeMap: ChangeMap<number>,
    isRangePipe: boolean
  ): Observable<Map<string, BehaviorSubject<number>>> => {
    return iiif(
      enabled$,
      combineLatest([
        this.sortedVariantIdsSinceXSecondsIntoThePast$,
        this.variants$,
        this.rangeTerpenesAtCompanyLevel$
      ]).pipe(
        map(([variantIds, allVariants, companyLevelRangesEnabled]) => {
          variantIds?.forEach(variantId => {
            const variant = allVariants?.find(v => v?.id === variantId);
            const isRanged = companyLevelRangesEnabled || variant?.useTerpeneRange;
            if ((isRangePipe && isRanged) || (!isRangePipe && !isRanged)) {
              if (!changeMap.has(variantId)) changeMap.set(variantId, new BehaviorSubject<number>(null));
            } else {
              changeMap?.get(variantId)?.next(null);
            }
          });
          return changeMap;
        })
      ),
      of(new Map<string, BehaviorSubject<number>>())
    ).pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };

  private getResetTerpenePipe$ = (
    resetSignal$: Observable<string>,
    changes$: Observable<Map<string, BehaviorSubject<number>>>
  ): Observable<any> => {
    return changes$.pipe(
      switchMap(changePipes => {
        return resetSignal$.pipe(
          filter(variantId => exists(changePipes?.get(variantId)?.value)),
          tap(variantId => changePipes?.get(variantId)?.next(null))
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };

  private readonly terpeneChangeAndUpdateSignals$ = this.enabledTerpeneNamesCamelCased$.pipe(
    map(terpenesCamelCased => {
      const terpeneChangeAndUpdateSignals = new Map();
      terpenesCamelCased?.forEach(terpeneCamelCased => {
        const signals = {
          name: terpeneCamelCased,
          enabled$: this.cannabinoidAndTerpsDomainModel.isTerpeneEnabled$(terpeneCamelCased),
          locChanges: new Map<string, BehaviorSubject<number>>(),
          locMinChanges: new Map<string, BehaviorSubject<number>>(),
          locMaxChanges: new Map<string, BehaviorSubject<number>>(),
          compChanges: new Map<string, BehaviorSubject<number>>(),
          compMinChanges: new Map<string, BehaviorSubject<number>>(),
          compMaxChanges: new Map<string, BehaviorSubject<number>>(),
          locUpdate: new Subject<[string, number]>(),
          locMinUpdate: new Subject<[string, number]>(),
          locMaxUpdate: new Subject<[string, number]>(),
          compUpdate: new Subject<[string, number]>(),
          compMinUpdate: new Subject<[string, number]>(),
          compMaxUpdate: new Subject<[string, number]>(),
          compReset: new Subject<string>(),
          compResetMin: new Subject<string>(),
          compResetMax: new Subject<string>(),
        };
        terpeneChangeAndUpdateSignals.set(terpeneCamelCased, signals);
      });
      return terpeneChangeAndUpdateSignals;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public getTerpeneChangeSignalFor$ = (
    terpeneCamelCased: string,
    level: PropertyLevelAbbreviation,
    minOrMax?: TerpeneMinOrMax
  ): Observable<Map<string, BehaviorSubject<number>>> => {
    return this.terpeneChangeAndUpdateSignals$.pipe(
      map(signals => {
        const accessor = exists(minOrMax) ? `${level}${minOrMax}Changes` : `${level}Changes`;
        return (signals?.get(terpeneCamelCased)?.[accessor] as Map<string, BehaviorSubject<number>>) || null;
      })
    );
  };

  public getTerpeneUpdateSignalFor$ = (
    terpeneCamelCased: string,
    level: PropertyLevelAbbreviation,
    minOrMax?: TerpeneMinOrMax
  ): Observable<Subject<[string, number]>> => {
    return this.terpeneChangeAndUpdateSignals$.pipe(
      map(signals => {
        const accessor = exists(minOrMax) ? `${level}${minOrMax}Update` : `${level}Update`;
        return (signals?.get(terpeneCamelCased)?.[accessor] as Subject<[string, number]>) || null;
      })
    );
  };

  private readonly terpeneDataChangePipelines$ = this.terpeneChangeAndUpdateSignals$.pipe(
    filter(signalBundle => signalBundle?.size > 0),
    map(signalBundle => {
      const updateSignals = new Map();
      signalBundle?.forEach((s, terpeneCamelCased) => {
        const changes = {
          locChanges$: this.getTerpeneChangePipes$(s.enabled$, s.locChanges, false),
          locMinChanges$: this.getTerpeneChangePipes$(s.enabled$, s.locMinChanges, true),
          locMaxChanges$: this.getTerpeneChangePipes$(s.enabled$, s.locMaxChanges, true),
          compChanges$: this.getTerpeneChangePipes$(s.enabled$, s.compChanges, false),
          compMinChanges$: this.getTerpeneChangePipes$(s.enabled$, s.compMinChanges, true),
          compMaxChanges$: this.getTerpeneChangePipes$(s.enabled$, s.compMaxChanges, true)
        };
        const updates = {
          locUpdate$: this.individualTerpeneUpdate$(s.enabled$, s.locUpdate, changes.locChanges$),
          locMinUpdate$: this.individualTerpeneUpdate$(s.enabled$, s.locMinUpdate, changes.locMinChanges$),
          locMaxUpdate$: this.individualTerpeneUpdate$(s.enabled$, s.locMaxUpdate, changes.locMaxChanges$),
          compUpdate$: this.individualTerpeneUpdate$(s.enabled$, s.compUpdate, changes.compChanges$),
          compMinUpdate$: this.individualTerpeneUpdate$(s.enabled$, s.compMinUpdate, changes.compMinChanges$),
          compMaxUpdate$: this.individualTerpeneUpdate$(s.enabled$, s.compMaxUpdate, changes.compMaxChanges$)
        };
        const signals = {
          name: terpeneCamelCased,
          enabled$: s.enabled$,
          ...changes,
          ...updates
        };
        updateSignals.set(terpeneCamelCased, signals);
      });
      return updateSignals;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public getTerpeneDataChangePipelinesFor$ = (terpeneNameCamelCased: string) => this.terpeneDataChangePipelines$.pipe(
    map(pipelines => pipelines?.get(terpeneNameCamelCased))
  );

  private readonly startUpTerpeneUpdatePipelines = this.terpeneDataChangePipelines$.pipe(
    switchMap(pipelines => {
      const update$ = [...(pipelines.values() || [])]?.map(s => {
        return merge(
          s?.locUpdate$ || of(null),
          s?.locMinUpdate$ || of(null),
          s?.locMaxUpdate$ || of(null),
          s?.compUpdate$ || of(null),
          s?.compMinUpdate$ || of(null),
          s?.compMaxUpdate$ || of(null)
        );
      });
      return merge(...update$);
    })
  ).subscribeWhileAlive({ owner: this });

  public clearAllTerpeneChanges(variantId: string): void {
    this.terpeneChangeAndUpdateSignals$.once(bundle => {
      bundle?.forEach((signals, _) => {
        signals.locUpdate?.next([variantId, null]);
        signals.locMinUpdate?.next([variantId, null]);
        signals.locMaxUpdate?.next([variantId, null]);
        signals.compUpdate?.next([variantId, null]);
        signals.compMinUpdate?.next([variantId, null]);
        signals.compMaxUpdate?.next([variantId, null]);
      });
    });
  }

  public getIndividualTerpeneChangePipeFor$ = (
    terpeneCamelCased: string,
    level: PropertyLevelAbbreviation,
    minOrMax?: TerpeneMinOrMax
  ): Observable<Map<string, BehaviorSubject<number>>> => {
    return this.getTerpeneChangeSignalFor$(terpeneCamelCased, level, minOrMax);
  };

  /* *** Badges *** */

  private initializeBadgeAndLabelChanges = <T>(
    variant: Variant,
    getChanges: () => T,
    variantModifiedTimeMap: LastModifiedMap,
    changeMap: ChangeMap<T>
  ): void => {
    const updateChangeMap = () => changeMap.set(variant?.id, new BehaviorSubject(getChanges?.()));
    const updateLastModifiedTime = () => variantModifiedTimeMap.set(variant?.id, variant?.getLastModifiedTimes());
    const checkForModifications = () => {
      const lastModifiedTimes = variantModifiedTimeMap.get(variant?.id);
      const lastModifiedTimeChanged = variant?.lastModifiedTimeChanged(lastModifiedTimes);
      if (lastModifiedTimeChanged) {
        updateLastModifiedTime();
        updateChangeMap();
      }
    };
    if (!changeMap.has(variant?.id)) updateChangeMap();
    !variantModifiedTimeMap.has(variant?.id) ? updateLastModifiedTime() : checkForModifications();
  };

  private individualBadgesUpdate$ = (
    incomingUpdate$: Observable<[string, HydratedVariantBadge, BadgeUpdate]>,
    saveHere$: Observable<Map<string, BehaviorSubject<HydratedVariantBadge[]>>>
  ): Observable<[string, HydratedVariantBadge, BadgeUpdate]> => {
    return saveHere$.pipe(
      switchMap(individualChanges => {
        return incomingUpdate$.pipe(
          tap(([idToChange, updatedBadge, updateType]) => {
            const changePipe = individualChanges?.get(idToChange);
            const badges = !!changePipe?.getValue() ? [...(changePipe?.getValue() || [])] : [];
            if (updateType === BadgeUpdate.Add) {
              badges.push(updatedBadge);
            } else if (updateType === BadgeUpdate.Remove) {
              const removeIndex = badges.findIndex(badge => badge.id === updatedBadge.id);
              if (removeIndex >= 0) badges.splice(removeIndex, 1);
            }
            changePipe?.next([...badges]?.uniqueByProperty('id'));
          })
        );
      })
    );
  };

  private getBadgeChangePipes$ = (
    changeMap: ChangeMap<HydratedVariantBadge[]>,
    initializeInMap: (variant: Variant, lastModifiedMap: LastModifiedMap, changeMap: BadgeChangeMap) => void,
  ): Observable<Map<string, BehaviorSubject<HydratedVariantBadge[]>>> => {
    const variantsLastModifiedTimeMap = new Map<string, number[]>();
    return this.sortedFilteredVariantsSinceXSecondsIntoThePast$.pipe(
      map(variants => {
        variants?.forEach(variant => initializeInMap(variant, variantsLastModifiedTimeMap, changeMap));
        return changeMap;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };

  private getResetBadgePipe$ = (
    resetSignal$: Observable<string>,
    variants$: Observable<Variant[]>,
    changes$: Observable<Map<string, BehaviorSubject<HydratedVariantBadge[]>>>,
    getOriginalBadges: (variant: Variant) => HydratedVariantBadge[]
  ): Observable<any> => {
    return changes$.pipe(
      switchMap(changePipes => variants$.pipe(
        switchMap(variants => resetSignal$.pipe(
          tap(variantId => {
            const variant = variants?.find(v => v?.id === variantId);
            const originalBadges = getOriginalBadges(variant);
            changePipes?.get(variantId)?.next(originalBadges);
          })
        ))
      )),
    );
  };

  /* *** Location Badges *** */

  private individualLocationBadgesChanges: Map<string, BehaviorSubject<HydratedVariantBadge[]>> = new Map();
  private initializeLocationBadgesInMap = (
    variant: Variant,
    modifiedTimeMap: LastModifiedMap,
    changeMap: ChangeMap<HydratedVariantBadge[]>
  ): void => {
    const getChanges = variant?.getLocationBadgesShallowCopy?.bind(variant);
    this.initializeBadgeAndLabelChanges(variant, getChanges, modifiedTimeMap, changeMap);
  };
  public individualLocationBadgeChanges$ = this.getBadgeChangePipes$(
    this.individualLocationBadgesChanges,
    this.initializeLocationBadgesInMap
  );
  private _individualLocationBadgesUpdate = new Subject<[string, HydratedVariantBadge, BadgeUpdate]>();
  connectToIndividualLocationBadges = (id: string, [value, updateType]: [HydratedVariantBadge, BadgeUpdate]) => {
    this._individualLocationBadgesUpdate.next([id, value, updateType]);
  };
  public readonly individualLocationBadgesUpdate$ =
    this._individualLocationBadgesUpdate as Observable<[string, HydratedVariantBadge, BadgeUpdate]>;
  private listenToLocationBadgesUpdate = this.individualBadgesUpdate$(
    this.individualLocationBadgesUpdate$,
    this.individualLocationBadgeChanges$
  ).subscribeWhileAlive({ owner: this });
  private _resetLocationBadges = new Subject<string>();
  private getOriginalLocationBadges = (variant: Variant) => variant?.getLocationBadgesShallowCopy();
  private listenForLocationBadgesResetSignal = this.getResetBadgePipe$(
    this._resetLocationBadges,
    this.sortedFilteredVariantsSinceXSecondsIntoThePast$,
    this.individualLocationBadgeChanges$,
    this.getOriginalLocationBadges
  ).subscribeWhileAlive({ owner: this });

  /* *** Company Badges *** */

  private individualCompanyBadgesChanges: Map<string, BehaviorSubject<HydratedVariantBadge[]>> = new Map();
  private initializeCompanyBadgesInMap = (
    variant: Variant,
    modifiedTimeMap: LastModifiedMap,
    changeMap: ChangeMap<HydratedVariantBadge[]>
  ): void => {
    const getChanges = variant?.getCompanyBadgesShallowCopy?.bind(variant);
    this.initializeBadgeAndLabelChanges(variant, getChanges, modifiedTimeMap, changeMap);
  };
  public individualCompanyBadgeChanges$ = this.getBadgeChangePipes$(
    this.individualCompanyBadgesChanges,
    this.initializeCompanyBadgesInMap
  );
  private _individualCompanyBadgesUpdate = new Subject<[string, HydratedVariantBadge, BadgeUpdate]>();
  connectToIndividualCompanyBadges = (id: string, [value, updateType]: [HydratedVariantBadge, BadgeUpdate]) => {
    this._individualCompanyBadgesUpdate.next([id, value, updateType]);
  };
  public readonly individualCompanyBadgesUpdate$ =
    this._individualCompanyBadgesUpdate as Observable<[string, HydratedVariantBadge, BadgeUpdate]>;
  private listenToCompanyBadgesUpdate = this.individualBadgesUpdate$(
    this.individualCompanyBadgesUpdate$,
    this.individualCompanyBadgeChanges$
  ).subscribeWhileAlive({ owner: this });
  private _resetCompanyBadges = new Subject<string>();
  private getOriginalCompanyBadges = (variant: Variant) => variant?.getCompanyBadgesShallowCopy();
  private listenForCompanyBadgesResetSignal = this.getResetBadgePipe$(
    this._resetCompanyBadges,
    this.sortedFilteredVariantsSinceXSecondsIntoThePast$,
    this.individualCompanyBadgeChanges$,
    this.getOriginalCompanyBadges
  ).subscribeWhileAlive({ owner: this });

  /* *** Labels *** */

  private individualLabelUpdate$ = (
    incomingUpdate$: Observable<[string, string, LabelUpdate]>,
    saveHere$: Observable<Map<string, BehaviorSubject<string>>>
  ): Observable<[string, string, LabelUpdate]> => {
    return saveHere$.pipe(
      switchMap(individualChanges => {
        return incomingUpdate$.pipe(
          tap(([idToChange, updatedLabel, updateType]) => {
            const changePipe = individualChanges?.get(idToChange);
            const currentLabel = changePipe?.getValue();
            if (updateType === LabelUpdate.Change) {
              changePipe?.next(updatedLabel);
            } else if (updateType === LabelUpdate.Remove && currentLabel === updatedLabel) {
              changePipe?.next(null);
            }
          })
        );
      })
    );
  };

  private getLabelChangePipes$ = (
    changeMap: ChangeMap<string>,
    initializeInMap: (variant: Variant, modifiedTimeMap: LastModifiedMap, changeMap: ChangeMap<string>) => void
  ): Observable<Map<string, BehaviorSubject<string>>> => {
    const variantsLastModifiedTimeMap = new Map<string, number[]>();
    return this.sortedFilteredVariantsSinceXSecondsIntoThePast$.pipe(
      map(variants => {
        variants?.forEach(variant => initializeInMap(variant, variantsLastModifiedTimeMap, changeMap));
        return changeMap;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  };

  private getResetLabelPipe$ = (
    resetSignal$: Observable<string>,
    variants$: Observable<Variant[]>,
    changes$: Observable<Map<string, BehaviorSubject<string>>>,
    getOriginalLabel: (variant: Variant) => string
  ): Observable<any> => {
    return changes$.pipe(
      switchMap(changePipes => variants$.pipe(
        switchMap(variants => resetSignal$.pipe(
          tap(variantId => {
            const variant = variants?.find(v => v?.id === variantId);
            const originalLabel = getOriginalLabel(variant);
            changePipes?.get(variantId)?.next(originalLabel);
          })
        ))
      )),
    );
  };

  /* *** Location Label *** */

  private allLabels$ = this.labelDomainModel.allLabels$;
  private individualLocationLabelChanges: Map<string, BehaviorSubject<string>> = new Map();
  private initializeLocationLabelInMap = (
    variant: Variant,
    modifiedTimeMap: LastModifiedMap,
    changeMap: ChangeMap<string>
  ) => {
    this.initializeBadgeAndLabelChanges(variant, variant?.getLocationLabel?.bind(variant), modifiedTimeMap, changeMap);
  };
  public individualLocationLabelChanges$ = this.getLabelChangePipes$(
    this.individualLocationLabelChanges,
    this.initializeLocationLabelInMap
  );
  private _individualLocationLabelUpdate = new Subject<[string, string, LabelUpdate]>();
  connectToIndividualLocationLabel = (id: string, [value, updateType]: [string, LabelUpdate]) => {
    this._individualLocationLabelUpdate.next([id, value, updateType]);
  };
  public readonly individualLocationLabelUpdate$ =
    this._individualLocationLabelUpdate as Observable<[string, string, LabelUpdate]>;
  private listenToLocationLabelUpdate = this.individualLabelUpdate$(
    this.individualLocationLabelUpdate$,
    this.individualLocationLabelChanges$
  ).subscribeWhileAlive({ owner: this });
  private _resetLocationLabel = new Subject<string>();
  private getOriginalLocationLabel = (variant: Variant): string => variant?.getLocationLabel();
  private listenForLocationLabelResetSignal = this.getResetLabelPipe$(
    this._resetLocationLabel,
    this.sortedFilteredVariantsSinceXSecondsIntoThePast$,
    this.individualLocationLabelChanges$,
    this.getOriginalLocationLabel
  ).subscribeWhileAlive({ owner: this });

  /* *** Company Label *** */

  private individualCompanyLabelChanges: Map<string, BehaviorSubject<string>> = new Map();
  private initializeCompanyLabelInMap = (
    variant: Variant,
    modifiedTimeMap: LastModifiedMap,
    changeMap: ChangeMap<string>
  ): void => {
    this.initializeBadgeAndLabelChanges(variant, variant?.getCompanyLabel?.bind(variant), modifiedTimeMap, changeMap);
  };
  public individualCompanyLabelChanges$ = this.getLabelChangePipes$(
    this.individualCompanyLabelChanges,
    this.initializeCompanyLabelInMap
  );
  private _individualCompanyLabelUpdate = new Subject<[string, string, LabelUpdate]>();
  connectToIndividualCompanyLabel = (id: string, [value, updateType]: [string, LabelUpdate]) => {
    this._individualCompanyLabelUpdate.next([id, value, updateType]);
  };
  public readonly individualCompanyLabelUpdate$ =
    this._individualCompanyLabelUpdate as Observable<[string, string, LabelUpdate]>;
  private listenToCompanyLabelUpdate = this.individualLabelUpdate$(
    this.individualCompanyLabelUpdate$,
    this.individualCompanyLabelChanges$
  ).subscribeWhileAlive({ owner: this });
  private _resetCompanyLabel = new Subject<string>();
  private getOriginalCompanyLabel = (variant: Variant): string => variant?.getCompanyLabel();
  private listenForCompanyLabelResetSignal = this.getResetLabelPipe$(
    this._resetCompanyLabel,
    this.sortedFilteredVariantsSinceXSecondsIntoThePast$,
    this.individualCompanyLabelChanges$,
    this.getOriginalCompanyLabel
  ).subscribeWhileAlive({ owner: this });

  /* ***************************** Group Data Changes ****************************** */

  private readonly changeDebounceTime = 1;
  private _focusedVariantId = new BehaviorSubject<string>(null);
  public readonly focusedVariantId$ = this._focusedVariantId as Observable<string>;
  connectToFocusedVariantId = (id: string) => this._focusedVariantId.next(id);

  public readonly disabledCannabinoidVariantIds$ = this.variantsFromFilterForm$.pipe(
    map(variants => variants?.filter(v => v?.isAccessory())?.map(v => v.id) || [])
  );

  public readonly disabledTerpeneVariantIds$ = this.disabledCannabinoidVariantIds$;

  /* *** Prevent Group Changes *** */

  private readonly _changingPages = new BehaviorSubject<number>(0);
  connectToPageChange = (pageNumber: number) => this._changingPages.next(pageNumber);
  public readonly changingPages$ = this._changingPages.pipe(distinctUntilChanged());

  private readonly _singleVariantReset = new Subject<string>();
  public readonly singleVariantReset$ = this._singleVariantReset as Observable<string>;
  connectToSingleVariantReset = (id: string) => this._singleVariantReset.next(id);

  public readonly preventGroupChange$ = merge(
    this.changingPages$,
    this.singleVariantReset$
  ).pipe(
    switchMap(() => timer(500).pipe(map(() => false), startWith(true))),
    startWith(true),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  /* *** Cannabinoid Group Changes *** */

  private listenForCannabinoidGroupChange$ = (
    groupChange$: Observable<number>,
    individualChanges$: Observable<Map<string, BehaviorSubject<number>>>,
    preventGroupChange$: Observable<boolean>,
  ): Observable<any> => {
    const sendOutGroupChange = (
      changePipes: Map<string, BehaviorSubject<number>>,
      focusedId: string,
      selectedIds: string[],
      disabledVariantIds: string[]
    ): Observable<any> => groupChange$.pipe(
      map(changedValue => (Number.isNaN(changedValue) ? null : changedValue)),
      tap(groupChange => {
        selectedIds?.forEach(variantId => {
          const disabled = disabledVariantIds?.includes(variantId);
          if (!disabled && (variantId !== focusedId) && (selectedIds?.includes(variantId))) {
            const changeInput = changePipes?.get(variantId);
            changeInput?.next(groupChange);
          }
        });
      })
    );
    const detectGroupChange = (
      [focusedId, selectedIds, disabledVariantIds]: [string, string[], string[]]
    ): Observable<BehaviorSubject<number>> => {
      const doNothing$ = of(null);
      const sendOutGroupChange$ = individualChanges$.pipe(
        switchMap(individualChanges => {
          return sendOutGroupChange(individualChanges, focusedId, selectedIds, disabledVariantIds);
        })
      );
      return preventGroupChange$.pipe(
        switchMap(preventGroupChange => iif(() => preventGroupChange, doNothing$, sendOutGroupChange$))
      );
    };
    return combineLatest([
      this.focusedVariantId$,
      this.selectedVariantIds$,
      this.disabledCannabinoidVariantIds$,
    ]).pipe(
      debounceTime(100),
      switchMap(detectGroupChange)
    );
  };

  private getCannabinoidGroupChangePipe$(changeSubject: Subject<number>): Observable<number> {
    return this.preventGroupChange$.pipe(
      switchMap(changingPages => changeSubject.pipe(filter(() => !changingPages))),
      debounceTime(this.changeDebounceTime),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  public readonly cannabinoidGroupChangeSignals$ = this.enabledCannabinoidNames$.pipe(
    map(cannabinoids => {
      const cannabinoidGroupChangeSignals = new Map();
      cannabinoids?.forEach(cannabinoid => {
        const signals = {
          name: cannabinoid,
          locChange: new Subject<number>(),
          locMinChange: new Subject<number>(),
          locMaxChange: new Subject<number>(),
          compChange: new Subject<number>(),
          compMinChange: new Subject<number>(),
          compMaxChange: new Subject<number>(),
        };
        cannabinoidGroupChangeSignals.set(cannabinoid, signals);
      });
      return cannabinoidGroupChangeSignals;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public getCannabinoidGroupChangeSignalFor$ = (
    canna: string,
    level: PropertyLevelAbbreviation,
    minOrMax?: CannabinoidMinOrMax
  ): Observable<Subject<number>> => {
    return this.cannabinoidGroupChangeSignals$.pipe(
      map(signals => {
        const accessor = exists(minOrMax) ? `${level}${minOrMax}Change` : `${level}Change`;
        return (signals?.get(canna)?.[accessor] as Subject<number>) || null;
      })
    );
  };

  public readonly cannabinoidGroupDataChangePipelines$ = this.cannabinoidGroupChangeSignals$.pipe(
    filter(signalBundles => signalBundles?.size > 0),
    map(signalBundles => {
      const updateSignals = new Map();
      signalBundles?.forEach((s, cannabinoid) => {
        const changes = {
          locChange$: this.getCannabinoidGroupChangePipe$(s.locChange),
          locMinChange$: this.getCannabinoidGroupChangePipe$(s.locMinChange),
          locMaxChange$: this.getCannabinoidGroupChangePipe$(s.locMaxChange),
          compChange$: this.getCannabinoidGroupChangePipe$(s.compChange),
          compMinChange$: this.getCannabinoidGroupChangePipe$(s.compMinChange),
          compMaxChange$: this.getCannabinoidGroupChangePipe$(s.compMaxChange)
        };
        const signals = {
          name: cannabinoid,
          ...changes
        };
        updateSignals.set(cannabinoid, signals);
      });
      return updateSignals;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  getCannabinoidGroupDataChangePipelinesFor$(canna: string): Observable<any> {
    return this.cannabinoidGroupDataChangePipelines$.pipe(
      map(pipelines => pipelines?.get(canna))
    );
  }

  private listenForCannabinoidGroupChanges = this.cannabinoidGroupDataChangePipelines$.pipe(
    switchMap(pipelines => {
      const update$ = [...(pipelines.values() || [])]?.map(s => {
        const listen = this.listenForCannabinoidGroupChange$;
        const changeFor$ = this.getIndividualCannabinoidChangePipeFor$;
        const loc$ = listen(s.locChange$, changeFor$(s.name, 'loc'), this.preventGroupChange$);
        const locMin$ = listen(s.locMinChange$, changeFor$(s.name, 'loc', 'Min'), this.preventGroupChange$);
        const locMax$ = listen(s.locMaxChange$, changeFor$(s.name, 'loc', 'Max'), this.preventGroupChange$);
        const comp$ = listen(s.compChange$, changeFor$(s.name, 'comp'), this.preventGroupChange$);
        const compMin$ = listen(s.compMinChange$, changeFor$(s.name, 'comp', 'Min'), this.preventGroupChange$);
        const compMax$ = listen(s.compMaxChange$, changeFor$(s.name, 'comp', 'Max'), this.preventGroupChange$);
        return merge(loc$, locMin$, locMax$, comp$, compMin$, compMax$);
      });
      return merge(...update$);
    })
  ).subscribeWhileAlive({ owner: this });

  public getGroupCannabinoidChangePipeFor$(
    cannabinoid: string,
    level: PropertyLevelAbbreviation,
    minOrMax?: CannabinoidMinOrMax
  ): Observable<Map<string, BehaviorSubject<number>>> {
    return this.getCannabinoidGroupDataChangePipelinesFor$(cannabinoid).pipe(
      map(signals => {
        const accessor = exists(minOrMax) ? `${level}${minOrMax}Changes` : `${level}Changes`;
        return (signals?.[accessor] as Map<string, BehaviorSubject<number>>) || null;
      })
    );
  }

  /* *** Terpene Group Changes *** */

  private listenForTerpeneGroupChange$ = (
    groupChange$: Observable<number>,
    individualChanges$: Observable<Map<string, BehaviorSubject<number>>>,
    preventGroupChange$: Observable<boolean>,
  ): Observable<any> => {
    const sendOutGroupChange = (
      changePipes: Map<string, BehaviorSubject<number>>,
      focusedId: string,
      selectedIds: string[],
      disabledVariantIds: string[]
    ): Observable<any> => groupChange$.pipe(
      map(changedValue => (Number.isNaN(changedValue) ? null : changedValue)),
      tap(groupChange => {
        selectedIds?.forEach(variantId => {
          const disabled = disabledVariantIds?.includes(variantId);
          if (!disabled && (variantId !== focusedId) && (selectedIds?.includes(variantId))) {
            const changeInput = changePipes?.get(variantId);
            changeInput?.next(groupChange);
          }
        });
      })
    );
    const detectGroupChange = (
      [focusedId, selectedIds, disabledVariantIds]: [string, string[], string[]]
    ): Observable<BehaviorSubject<number>> => {
      const doNothing$ = of(null);
      const sendOutGroupChange$ = individualChanges$.pipe(
        switchMap(individualChanges => {
          return sendOutGroupChange(individualChanges, focusedId, selectedIds, disabledVariantIds);
        })
      );
      return preventGroupChange$.pipe(
        switchMap(preventGroupChange => iif(() => preventGroupChange, doNothing$, sendOutGroupChange$))
      );
    };
    return combineLatest([
      this.focusedVariantId$,
      this.selectedVariantIds$,
      this.disabledTerpeneVariantIds$,
    ]).pipe(
      debounceTime(100),
      switchMap(detectGroupChange)
    );
  };

  private getTerpeneGroupChangePipe$(changeSubject: Subject<number>): Observable<number> {
    return this.preventGroupChange$.pipe(
      switchMap(changingPages => changeSubject.pipe(filter(() => !changingPages))),
      debounceTime(this.changeDebounceTime),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  public readonly terpeneGroupChangeSignals$ = this.enabledTerpeneNamesCamelCased$.pipe(
    map(terpenesCamelCased => {
      const terpeneGroupChangeSignals = new Map();
      terpenesCamelCased?.forEach(terpeneCamelCased => {
        const signals = {
          name: terpeneCamelCased,
          locChange: new Subject<number>(),
          locMinChange: new Subject<number>(),
          locMaxChange: new Subject<number>(),
          compChange: new Subject<number>(),
          compMinChange: new Subject<number>(),
          compMaxChange: new Subject<number>(),
        };
        terpeneGroupChangeSignals.set(terpeneCamelCased, signals);
      });
      return terpeneGroupChangeSignals;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public getTerpeneGroupChangeSignalFor$ = (
    terpeneCamelCased: string,
    level: PropertyLevelAbbreviation,
    minOrMax?: TerpeneMinOrMax
  ): Observable<Subject<number>> => {
    return this.terpeneGroupChangeSignals$.pipe(
      map(signals => {
        const accessor = exists(minOrMax) ? `${level}${minOrMax}Change` : `${level}Change`;
        return (signals?.get(terpeneCamelCased)?.[accessor] as Subject<number>) || null;
      })
    );
  };

  public readonly terpeneGroupChangePipelines$ = this.terpeneGroupChangeSignals$.pipe(
    filter(signalBundle => signalBundle?.size > 0),
    map(signalBundle => {
      const updateSignals = new Map();
      signalBundle?.forEach((s, terpeneCamelCased) => {
        const changes = {
          locChange$: this.getTerpeneGroupChangePipe$(s.locChange),
          locMinChange$: this.getTerpeneGroupChangePipe$(s.locMinChange),
          locMaxChange$: this.getTerpeneGroupChangePipe$(s.locMaxChange),
          compChange$: this.getTerpeneGroupChangePipe$(s.compChange),
          compMinChange$: this.getTerpeneGroupChangePipe$(s.compMinChange),
          compMaxChange$: this.getTerpeneGroupChangePipe$(s.compMaxChange)
        };
        const signals = {
          name: terpeneCamelCased,
          ...changes
        };
        updateSignals.set(terpeneCamelCased, signals);
      });
      return updateSignals;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public getTerpeneGroupDataChangePipeFor$(terpeneCamelCased: string): Observable<any> {
    return this.terpeneGroupChangePipelines$.pipe(
      map(pipelines => pipelines?.get(terpeneCamelCased))
    );
  }

  private listenForTerpeneGroupChanges = this.terpeneGroupChangePipelines$.pipe(
    switchMap(pipelines => {
      const update$ = [...(pipelines.values() || [])]?.map(s => {
        const listen = this.listenForTerpeneGroupChange$;
        const changeFor$ = this.getIndividualTerpeneChangePipeFor$;
        const loc$ = listen(s.locChange$, changeFor$(s.name, 'loc'), this.preventGroupChange$);
        const locMin$ = listen(s.locMinChange$, changeFor$(s.name, 'loc', 'Min'), this.preventGroupChange$);
        const locMax$ = listen(s.locMaxChange$, changeFor$(s.name, 'loc', 'Max'), this.preventGroupChange$);
        const comp$ = listen(s.compChange$, changeFor$(s.name, 'comp'), this.preventGroupChange$);
        const compMin$ = listen(s.compMinChange$, changeFor$(s.name, 'comp', 'Min'), this.preventGroupChange$);
        const compMax$ = listen(s.compMaxChange$, changeFor$(s.name, 'comp', 'Max'), this.preventGroupChange$);
        return merge(loc$, locMin$, locMax$, comp$, compMin$, compMax$);
      });
      return merge(...update$);
    })
  ).subscribeWhileAlive({ owner: this });

  public getGroupTerpeneChangePipeFor$(
    terpeneCamelCased: string,
    level: PropertyLevelAbbreviation,
    minOrMax?: TerpeneMinOrMax
  ): Observable<Map<string, BehaviorSubject<number>>> {
    return this.getTerpeneGroupDataChangePipeFor$(terpeneCamelCased).pipe(
      map(signals => {
        const accessor = exists(minOrMax) ? `${level}${minOrMax}Changes` : `${level}Changes`;
        return (signals?.[accessor] as Map<string, BehaviorSubject<number>>) || null;
      })
    );
  }

  /* *** Badge Group Changes *** */

  private listenForBadgesGroupChange = (
    groupChange$: Observable<[HydratedVariantBadge, BadgeUpdate]>,
    individualChanges$: Observable<Map<string, BehaviorSubject<HydratedVariantBadge[]>>>
  ): Subscription => {
    const sendOutGroupChange = (
      changePipes: Map<string, BehaviorSubject<HydratedVariantBadge[]>>,
      selectedVariants: Variant[]
    ): Observable<[HydratedVariantBadge, BadgeUpdate]> => groupChange$.pipe(
      tap(([badgeUpdate, updateType]) => {
        // don't allow group changes that would override a smart badge
        const currentVariantBadgeAllowedToUpdated = (variantId: string) => {
          const variant = selectedVariants?.find(v => v?.id === variantId);
          return !variant?.getLocationLevelSmartFilterBadgeIds()?.includes(badgeUpdate?.id);
        };
        selectedVariants?.forEach(selectedVariant => {
          if (currentVariantBadgeAllowedToUpdated(selectedVariant?.id)) {
            const changeInput = changePipes?.get(selectedVariant?.id);
            const updatedBadges = changeInput?.getValue() || [];
            if (updateType === BadgeUpdate.Add) {
              updatedBadges.push(badgeUpdate);
            } else if (updateType === BadgeUpdate.Remove) {
              const removeIndex = updatedBadges.findIndex(badge => badge.id === badgeUpdate.id);
              if (removeIndex >= 0) updatedBadges.splice(removeIndex, 1);
            }
            changeInput?.next([...updatedBadges]?.uniqueByProperty('id'));
          }
        });
      })
    );
    const detectGroupChange = (
      selectedVariants: Variant[]
    ): Observable<[HydratedVariantBadge, BadgeUpdate]> => individualChanges$.pipe(
      switchMap(individualChanges => {
        return sendOutGroupChange(individualChanges, selectedVariants);
      })
    );
    return this.selectedVariants$.pipe(
      debounceTime(100),
      switchMap(selectedVariants => detectGroupChange(selectedVariants))
    ).subscribeWhileAlive({ owner: this });
  };

  /* *** Location *** */

  private readonly _groupLocationBadgesChange = new Subject<[HydratedVariantBadge, BadgeUpdate]>();
  connectToGroupLocationBadges = ([value, updateType]: [HydratedVariantBadge, BadgeUpdate]) => {
    this._groupLocationBadgesChange.next([value, updateType]);
  };
  private listenToLocationBadgeGroupChange$ = this.listenForBadgesGroupChange(
    this._groupLocationBadgesChange,
    this.individualLocationBadgeChanges$
  );

  /* *** Company *** */

  private readonly _groupCompanyBadgesChange = new Subject<[HydratedVariantBadge, BadgeUpdate]>();
  connectToGroupCompanyBadges = ([value, updateType]: [HydratedVariantBadge, BadgeUpdate]) => {
    this._groupCompanyBadgesChange.next([value, updateType]);
  };
  private listenToCompanyBadgeGroupChange = this.listenForBadgesGroupChange(
    this._groupCompanyBadgesChange,
    this.individualCompanyBadgeChanges$
  );

  /* *** Label Group Changes *** */

  /* *** Location *** */

  private listenForLabelGroupChange = (
    groupChange$: Observable<[string, LabelUpdate]>,
    individualChanges$: Observable<Map<string, BehaviorSubject<string>>>
  ): Subscription => {
    const sendOutGroupChange = (
      changePipes: Map<string, BehaviorSubject<string>>,
      selectedVariants: Variant[]
    ): Observable<[string, LabelUpdate]> => groupChange$.pipe(
      tap(([updatedLabelId, updateType]) => {
        // don't allow group changes that would override a point of sale label or smart label
        const currentVariantLabelIsAllowedToBeUpdated = (variantId: string) => {
          const variant = selectedVariants?.find(v => v?.id === variantId);
          return !variant?.hasSmartLabel() && !variant?.hasPosLabel();
        };
        selectedVariants?.forEach(selectedVariant => {
          if (currentVariantLabelIsAllowedToBeUpdated(selectedVariant?.id)) {
            const changeInput = changePipes?.get(selectedVariant?.id);
            const currentChangedValue = changeInput?.getValue();
            if (updateType === LabelUpdate.Change) {
              changeInput?.next(updatedLabelId);
            } else if ((updateType === LabelUpdate.Remove) && (currentChangedValue === updatedLabelId)) {
              changeInput?.next(null);
            }
          }
        });
      })
    );
    const detectGroupChange = (
      selectedVariants: Variant[],
    ): Observable<[string, LabelUpdate]> => individualChanges$.pipe(
      switchMap(individualChanges => sendOutGroupChange(individualChanges, selectedVariants))
    );
    return this.selectedVariants$.pipe(
      debounceTime(100),
      switchMap(selectedVariants => detectGroupChange(selectedVariants))
    ).subscribeWhileAlive({ owner: this });
  };

  private readonly _groupLocationLabelChange = new Subject<[string, LabelUpdate]>();
  connectToGroupLocationLabel = (value: [string, LabelUpdate]) => this._groupLocationLabelChange.next(value);
  private listenToLocationLabelGroupChange = this.listenForLabelGroupChange(
    this._groupLocationLabelChange,
    this.individualLocationLabelChanges$
  );

  /* *** Company *** */

  private _groupCompanyLabelChange = new Subject<[string, LabelUpdate]>();
  connectToGroupCompanyLabel = (value: [string, LabelUpdate]) => this._groupCompanyLabelChange.next(value);
  private listenToCompanyLabelGroupChange = this.listenForLabelGroupChange(
    this._groupCompanyLabelChange,
    this.individualCompanyLabelChanges$
  );

  /* *************************** Selection **************************** */

  // add to selection
  private _addVariantIds = new Subject<string[]>();
  private listenToAddAnId = this._addVariantIds
    .pipe(map(id => this._selectedVariantIds.getValue()?.concat(id)?.unique()))
    .subscribeWhileAlive({
      owner: this,
      next: ids => this._selectedVariantIds.next(ids)
    });

  // remove from selection
  private _removeVariantIds = new Subject<string[]>();
  private listenToRemoveAnId = this._removeVariantIds
    .pipe(map(ids => this._selectedVariantIds.getValue()?.filter(currId => !ids?.includes(currId))))
    .subscribeWhileAlive({
      owner: this,
      next: ids => this._selectedVariantIds.next(ids)
    });

  // adding and removing variant ids
  addVariantId = (id: string) => this._addVariantIds.next([id]);
  removeVariantId = (id: string) => this._removeVariantIds.next([id]);
  addVariantIds = (ids: string[]) => this._addVariantIds.next(ids);
  removeVariantIds = (ids: string[]) => this._removeVariantIds.next(ids);

  /* *************************** Saving Changes **************************** */

  private _saveVariant = new Subject<string>();
  private saveVariant$ = this._saveVariant as Observable<string>;

  private bulkSavingQueue: string[] = [];
  private _bulkSavingQueue = new BehaviorSubject<string[]>([]);
  public bulkSavingQueue$ = this._bulkSavingQueue as Observable<string[]>;

  private _bulkSavingVariants = new BehaviorSubject<boolean>(false);
  public bulkSavingVariants$ = this._bulkSavingVariants as Observable<boolean>;

  private setBulkSavingQueue = (id: string) => {
    this.bulkSavingQueue = [...this.bulkSavingQueue, id]?.unique();
    this._bulkSavingQueue.next(this.bulkSavingQueue);
  };

  private startSavingChanges = () => {
    this._bulkSavingVariants.next(true);
  };

  private clearChangesAfterSave = (displayAttributes: DisplayAttribute[]) => {
    const locationDisplayAttributes = displayAttributes?.filter(attr => attr?.isLocationDA());
    const companyDisplayAttributes = displayAttributes?.filter(attr => attr?.isCompanyDA());
    this.bulkSavingQueue?.forEach(id => {
      const locationDA = locationDisplayAttributes?.find(attr => attr?.objectId === id);
      const companyDA = companyDisplayAttributes?.find(attr => attr?.objectId === id);
      this.clearCannabinoidChangesFor(id);
      this.clearTerpeneChangesFor(id);
      const locationBadges = locationDA?.badges || null;
      const companyBadges = companyDA?.badges || null;
      const locationLabel = locationDA?.defaultLabel || null;
      const companyLabel = companyDA?.defaultLabel || null;
      locationBadges?.forEach(badge => this._individualLocationBadgesUpdate.next([id, badge, BadgeUpdate.Add]));
      companyBadges?.forEach(badge => this._individualCompanyBadgesUpdate.next([id, badge, BadgeUpdate.Add]));
      this._individualLocationLabelUpdate.next([id, locationLabel, LabelUpdate.Change]);
      this._individualCompanyLabelUpdate.next([id, companyLabel, LabelUpdate.Change]);
    });
  };

  private clearSavedQueue = () => {
    this._bulkSavingVariants.next(false);
    this.bulkSavingQueue = [];
    this._bulkSavingQueue.next(this.bulkSavingQueue);
  };

  private clearSavedFromSelected = () => {
    const currentSelectedVariantIds = this._selectedVariantIds.getValue();
    const copy = !currentSelectedVariantIds ? [] : [...currentSelectedVariantIds];
    this.bulkSavingQueue?.forEach(id => copy?.remove(id));
    this._selectedVariantIds.next(copy);
  };

  private saveSuccessful = () => {
    this.clearSavedFromSelected();
    this.clearSavedQueue();
    this.toastService.publishSuccessMessage('Your changes have been saved.', 'Save Successful');
  };

  private errorWhileSaving = (err: BsError) => {
    this.clearSavedQueue();
    this.toastService.publishError(err);
  };

  private listenToBulkSaveVariant = this.saveVariant$
    .notNull()
    .pipe(
      tap(id => this.setBulkSavingQueue(id)),
      debounceTime(2500),
      tap(_ => this.startSavingChanges()),
      switchMap(ids => this.bulkSaveDisplayAttributes$(this.bulkSavingQueue)),
      tap(attrs => this.clearChangesAfterSave(attrs))
    )
    .subscribeWhileAlive({
      owner: this,
      next: this.saveSuccessful,
      error: this.errorWhileSaving
    });

  /* *************************** Internal methods ************************** */

  private clearGroupCannabinoidChanges(): void {
    this.cannabinoidGroupChangeSignals$.once(signalBundle => {
      signalBundle.forEach(signals => {
        signals?.locChange?.next(null);
        signals?.locMinChange?.next(null);
        signals?.locMaxChange?.next(null);
        signals?.compChange?.next(null);
        signals?.compMinChange?.next(null);
        signals?.compMaxChange?.next(null);
      });
    });
  }

  private clearCannabinoidChangesFor(variantId: string) {
    combineLatest([
      this.cannabinoidChangeAndUpdateSignals$,
      this.cannabinoidProperties$
    ]).once(([signalBundle, cannabinoidProperties]) => {
      this.connectToSingleVariantReset(variantId);
      signalBundle?.forEach(signals => {
        if (cannabinoidProperties?.some(property => property === `Location ${signals?.name}`)) {
          signals?.locChanges?.get(variantId)?.next(null);
          signals?.locMinChanges?.get(variantId)?.next(null);
          signals?.locMaxChanges?.get(variantId)?.next(null);
        }
        if (cannabinoidProperties?.some(property => property === `Company ${signals?.name}`)) {
          signals?.compChanges?.get(variantId)?.next(null);
          signals?.compMinChanges?.get(variantId)?.next(null);
          signals?.compMaxChanges?.get(variantId)?.next(null);
        }
      });
    });
  }

  private clearGroupTerpeneChanges(): void {
    this.terpeneGroupChangeSignals$.once(signalBundle => {
      signalBundle.forEach(signals => {
        signals?.locChange?.next(null);
        signals?.locMinChange?.next(null);
        signals?.locMaxChange?.next(null);
        signals?.compChange?.next(null);
        signals?.compMinChange?.next(null);
        signals?.compMaxChange?.next(null);
      });
    });
  }

  private clearTerpeneChangesFor(variantId: string) {
    combineLatest([
      this.terpeneChangeAndUpdateSignals$,
      this.terpeneProperties$
    ]).once(([signalBundle, terpeneProperties]) => {
      this.connectToSingleVariantReset(variantId);
      signalBundle?.forEach(signals => {
        if (terpeneProperties?.some(property => property === `Location ${signals?.name}`)) {
          signals?.locChanges?.get(variantId)?.next(null);
          signals?.locMinChanges?.get(variantId)?.next(null);
          signals?.locMaxChanges?.get(variantId)?.next(null);
        }
        if (terpeneProperties?.some(property => property === `Company ${signals?.name}`)) {
          signals?.compChanges?.get(variantId)?.next(null);
          signals?.compMinChanges?.get(variantId)?.next(null);
          signals?.compMaxChanges?.get(variantId)?.next(null);
        }
      });
    });
  }

  private resetCustomizationChangesFor(variantId: string) {
    this._resetLocationBadges.next(variantId);
    this._resetCompanyBadges.next(variantId);
    this._resetLocationLabel.next(variantId);
    this._resetCompanyLabel.next(variantId);
  }

  private readonly dontUpdateThisProperty = 'DO_NOT_UPDATE';

  private bulkSaveDisplayAttributes$(variantIds: string[]): Observable<DisplayAttribute[]> {
    return this.getUpdatedDisplayAttributesFor$(variantIds).pipe(
      switchMap(attributes => this.displayAttributeDomainModel.updateDisplayAttributes(attributes, '', true)),
      take(1),
    );
  }

  private getUpdatedDisplayAttributesFor$(variantIds: string[]): Observable<DisplayAttribute[]> {
    return combineLatest(variantIds?.map(variantId => this.getUpdatedDisplayAttributesForVariantId$(variantId))).pipe(
      map(displayAttributes => displayAttributes?.flatten<DisplayAttribute[]>()),
      take(1)
    );
  }

  private getUpdatedCannabinoidDisplayAttributeData$ = (
    variantId: string,
    displayAttribute$: Observable<DisplayAttribute>,
    cannabinoidProperties$: Observable<string[]>,
  ): Observable<DisplayAttribute> => {
    return combineLatest([
      cannabinoidProperties$,
      this.rangeCannabinoidsAtCompanyLevel$,
      displayAttribute$
    ]).pipe(
      take(1),
      switchMap(([cannaProps, useRanges, dispAttr]) => {
        return this.updateCannabinoidPropertiesForDisplayAttribute$(variantId, dispAttr, cannaProps, useRanges);
      }),
      take(1)
    );
  };

  private getUpdatedTerpeneDisplayAttributeData$ = (
    variantId: string,
    displayAttribute: DisplayAttribute,
    terpeneProperties$: Observable<string[]>,
  ): Observable<DisplayAttribute> => {
    return combineLatest([
      terpeneProperties$,
      this.rangeTerpenesAtCompanyLevel$
    ]).pipe(
      take(1),
      switchMap(([terpProps, useRanges]) => {
        return this.updateTerpenePropertiesForDisplayAttribute$(variantId, displayAttribute, terpProps, useRanges);
      }),
      take(1)
    );
  };

  private getUpdatedCustomizationDisplayAttributeData$ = (
    variantId: string,
    displayAttribute: DisplayAttribute,
    customizationProperties$: Observable<string[]>,
  ): Observable<DisplayAttribute> => {
    return customizationProperties$.pipe(
      take(1),
      switchMap(properties => {
        return this.updateCustomizationPropertiesForDisplayAttribute$(variantId, displayAttribute, properties);
      }),
      take(1)
    );
  };

  private getUpdatedDisplayAttributesForVariantId$(variantId: string): Observable<DisplayAttribute[]> {
    const locationDisplayAttribute$ = this.displayAttributeDomainModel.getSingleValueStreamForLocationDA(variantId);
    const companyDisplayAttribute$ = this.displayAttributeDomainModel.getSingleValueStreamForCompanyDA(variantId);
    const locationOnlyCannabinoidProperties$ = this.cannabinoidProperties$.pipe(
      map(props => props?.filter(prop => prop?.includes(PropertyLevel.Location)) || []),
      take(1)
    );
    const locationOnlyTerpeneProperties$ = this.terpeneProperties$.pipe(
      map(props => props?.filter(prop => prop?.includes(PropertyLevel.Location)) || []),
      take(1)
    );
    const locOnlyCustomizationProps$ = this.customizationProperties$.pipe(
      map(props => props?.filter(prop => prop?.includes(PropertyLevel.Location)) || []),
      take(1)
    );
    const companyOnlyCannabinoidProperties$ = this.cannabinoidProperties$?.pipe(
      map(props => props?.filter(prop => prop?.includes(PropertyLevel.Company)) || []),
      take(1)
    );
    const companyOnlyTerpeneProperties$ = this.terpeneProperties$?.pipe(
      map(props => props?.filter(prop => prop?.includes(PropertyLevel.Company)) || []),
      take(1)
    );
    const compOnlyCustomizationProps$ = this.customizationProperties$?.pipe(
      map(props => props?.filter(prop => prop?.includes(PropertyLevel.Company)) || []),
      take(1)
    );
    const updateLocationCannabinoidProps$ = this.getUpdatedCannabinoidDisplayAttributeData$(
      variantId,
      locationDisplayAttribute$,
      locationOnlyCannabinoidProperties$
    );
    const updateLocationTerpeneProps$ = (locDispAttr: DisplayAttribute) => {
      return this.getUpdatedTerpeneDisplayAttributeData$(variantId, locDispAttr, locationOnlyTerpeneProperties$);
    };
    const updateLocationCustomizationProps$ = (locDispAttr: DisplayAttribute) => {
      return this.getUpdatedCustomizationDisplayAttributeData$(variantId, locDispAttr, locOnlyCustomizationProps$);
    };
    const updateCompanyCannabinoidProps$ = this.getUpdatedCannabinoidDisplayAttributeData$(
      variantId,
      companyDisplayAttribute$,
      companyOnlyCannabinoidProperties$
    );
    const updateCompanyTerpeneProps$ = (compDispAttr: DisplayAttribute) => {
      return this.getUpdatedTerpeneDisplayAttributeData$(variantId, compDispAttr, companyOnlyTerpeneProperties$);
    };
    const updateCompanyCustomizationProps$ = (compDispAttr: DisplayAttribute) => {
      return this.getUpdatedCustomizationDisplayAttributeData$(variantId, compDispAttr, compOnlyCustomizationProps$);
    };
    const locationUpdates$ = updateLocationCannabinoidProps$.pipe(
      switchMap(updateLocationTerpeneProps$),
      switchMap(updateLocationCustomizationProps$),
      take(1)
    );
    const companyUpdates$ = updateCompanyCannabinoidProps$.pipe(
      switchMap(updateCompanyTerpeneProps$),
      switchMap(updateCompanyCustomizationProps$),
      take(1)
    );
    return combineLatest([
      locationUpdates$,
      companyUpdates$
    ]).pipe(
      take(1),
      map(attrs => attrs?.filterNulls())
    );
  }

  /* ******* Cannabinoid Properties ******* */

  /**
   * Update location and company in separate calls to this function.
   * As in, don't pass in location and company properties at the same time.
   * If you do this, then this function will not work.
   *
   * @param variantId
   * @param displayAttribute
   * @param cannabinoidProperties location or company properties, not both.
   * @param useRanges whether user uses ranges or not.
   * @private
   */
  private updateCannabinoidPropertiesForDisplayAttribute$(
    variantId: string,
    displayAttribute: DisplayAttribute,
    cannabinoidProperties: string[],
    useRanges: boolean
  ): Observable<DisplayAttribute> {
    return this.variants$.pipe(
      take(1),
      switchMap(variants => {
        const variant = variants?.find(v => v?.id === variantId);
        if (useRanges || variant?.useCannabinoidRange) {
          return this.updateRangedCannabinoidProperties$(variantId, displayAttribute, cannabinoidProperties);
        } else {
          return this.updateCannabinoidProperties$(variantId, displayAttribute, cannabinoidProperties);
        }
      })
    );
  }

  private getCannabinoidChangePipe$ = (
    variantId: string,
    changePipes$: Observable<Map<string, BehaviorSubject<number>>>
  ): Observable<string | null> => {
    return changePipes$.pipe(
      take(1),
      switchMap(changePipes => changePipes?.get(variantId)),
      map(change => (Number.isFinite(change) ? change.toString() : null)),
      take(1),
    );
  };

  /**
   * Update location and company in separate calls to this function.
   * As in, don't pass in location and company properties at the same time.
   * If you do this, then this function will not work.
   */
  private updateRangedCannabinoidProperties$(
    variantId: string,
    displayAttribute: DisplayAttribute,
    cannabinoidProperties: string[],
  ): Observable<DisplayAttribute> {
    return this.enabledCannabinoidNames$.pipe(
      take(1),
      switchMap(cannabinoids => {
        const getUpdatePipe$ = (c: string) => {
          const change$ = this.getCannabinoidChangePipe$;
          if (cannabinoidProperties?.contains(`Location ${c}`)) {
            const locationMin$ = change$(variantId, this.getIndividualCannabinoidChangePipeFor$(c, 'loc', 'Min'));
            const locationMax$ = change$(variantId, this.getIndividualCannabinoidChangePipeFor$(c, 'loc', 'Max'));
            return of(c).pipe(withLatestFrom(locationMin$, locationMax$));
          }
          if (cannabinoidProperties?.contains(`Company ${c}`)) {
            const companyMin$ = change$(variantId, this.getIndividualCannabinoidChangePipeFor$(c, 'comp', 'Min'));
            const companyMax$ = change$(variantId, this.getIndividualCannabinoidChangePipeFor$(c, 'comp', 'Max'));
            return of(c).pipe(withLatestFrom(companyMin$, companyMax$));
          }
          return of([c, this.dontUpdateThisProperty, this.dontUpdateThisProperty]);
        };
        return combineLatest(cannabinoids?.map(c => getUpdatePipe$(c)) || []);
      }),
      take(1),
      map(updates => {
        updates?.forEach(([cannabinoid, minVal, maxVal]) => {
          const shouldUpdate = (val: string) => (val !== this.dontUpdateThisProperty) && (val !== null);
          if (shouldUpdate(minVal)) displayAttribute[`min${cannabinoid}`] = minVal;
          if (shouldUpdate(maxVal)) displayAttribute[`max${cannabinoid}`] = maxVal;
        });
        return displayAttribute;
      })
    );
  }

  /**
   * Update location and company in separate calls to this function.
   * As in, don't pass in location and company properties at the same time.
   * If you do this, then this function will not work.
   */
  private updateCannabinoidProperties$(
    varId: string,
    displayAttribute: DisplayAttribute,
    cannabinoidProperties: string[],
  ): Observable<DisplayAttribute> {
    return this.enabledCannabinoidNames$.pipe(
      take(1),
      switchMap(cannabinoids => {
        const getUpdatePipe$ = (c: string) => {
          if (cannabinoidProperties?.contains(`Location ${c}`)) {
            const loc$ = this.getCannabinoidChangePipe$(varId, this.getIndividualCannabinoidChangePipeFor$(c, 'loc'));
            return of(c).pipe(withLatestFrom(loc$));
          }
          if (cannabinoidProperties?.contains(`Company ${c}`)) {
            const com$ = this.getCannabinoidChangePipe$(varId, this.getIndividualCannabinoidChangePipeFor$(c, 'comp'));
            return of(c).pipe(withLatestFrom(com$));
          }
          return of([c, this.dontUpdateThisProperty]);
        };
        return combineLatest(cannabinoids?.map(c => getUpdatePipe$(c)) || []);
      }),
      take(1),
      map(updates => {
        updates?.forEach(([cannabinoid, value]) => {
          if ((value !== this.dontUpdateThisProperty) && (value !== null)) displayAttribute[cannabinoid] = value;
        });
        return displayAttribute;
      })
    );
  }

  /* ******* Terpene Properties ******* */

  private updateTerpenePropertiesForDisplayAttribute$(
    variantId: string,
    displayAttribute: DisplayAttribute,
    terpeneProperties: string[],
    useRanges: boolean
  ): Observable<DisplayAttribute> {
    return this.variants$.pipe(
      take(1),
      switchMap(variants => {
        const variant = variants?.find(v => v?.id === variantId);
        if (useRanges || variant?.useTerpeneRange) {
          return this.updateRangedTerpeneProperties$(variantId, displayAttribute, terpeneProperties);
        } else {
          return this.updateTerpeneProperties$(variantId, displayAttribute, terpeneProperties);
        }
      })
    );
  }

  private getTerpeneChangePipe$ = (
    variantId: string,
    changePipes$: Observable<Map<string, BehaviorSubject<number>>>
  ): Observable<string | null> => {
    return changePipes$.pipe(
      take(1),
      switchMap(changePipes => changePipes?.get(variantId)),
      map(change => (Number.isFinite(change) ? change.toString() : null)),
      take(1),
    );
  };

  private updateRangedTerpeneProperties$(
    variantId: string,
    displayAttribute: DisplayAttribute,
    terpeneProperties: string[]
  ): Observable<DisplayAttribute> {
    return this.enabledTerpeneNamesCamelCased$.pipe(
      take(1),
      switchMap(terpenesCamelCased => {
        const getUpdatePipe$ = (t: string) => {
          const change$ = this.getTerpeneChangePipe$;
          if (terpeneProperties?.contains(`Location ${t}`)) {
            const locationMin$ = change$(variantId, this.getIndividualTerpeneChangePipeFor$(t, 'loc', 'Min'));
            const locationMax$ = change$(variantId, this.getIndividualTerpeneChangePipeFor$(t, 'loc', 'Max'));
            return of(t).pipe(withLatestFrom(locationMin$, locationMax$));
          }
          if (terpeneProperties?.contains(`Company ${t}`)) {
            const companyMin$ = change$(variantId, this.getIndividualTerpeneChangePipeFor$(t, 'comp', 'Min'));
            const companyMax$ = change$(variantId, this.getIndividualTerpeneChangePipeFor$(t, 'comp', 'Max'));
            return of(t).pipe(withLatestFrom(companyMin$, companyMax$));
          }
          return of([t, this.dontUpdateThisProperty, this.dontUpdateThisProperty]);
        };
        return combineLatest(terpenesCamelCased?.map(terpeneCamelCased => getUpdatePipe$(terpeneCamelCased)) || []);
      }),
      take(1),
      map(updates => {
        updates?.forEach(([terpeneCamelCased, minVal, maxVal]) => {
          const terpenePascalized = StringUtils.toPascalCase(terpeneCamelCased);
          const shouldUpdate = (val: string) => (val !== this.dontUpdateThisProperty) && (val !== null);
          if (shouldUpdate(minVal)) displayAttribute[`min${terpenePascalized}`] = minVal;
          if (shouldUpdate(maxVal)) displayAttribute[`max${terpenePascalized}`] = maxVal;
        });
        return displayAttribute;
      })
    );
  }

  private updateTerpeneProperties$(
    variantId: string,
    displayAttribute: DisplayAttribute,
    terpeneProperties: string[]
  ): Observable<DisplayAttribute> {
    return this.enabledTerpeneNamesCamelCased$.pipe(
      take(1),
      switchMap(terpenesCamelCased => {
        const getUpdatePipe$ = (terpenesCamelCased: string) => {
          const unwrapChange$ = this.getTerpeneChangePipe$;
          const getChangeFor$ = this.getIndividualTerpeneChangePipeFor$;
          if (terpeneProperties?.contains(`Location ${terpenesCamelCased}`)) {
            const loc$ = unwrapChange$(variantId, getChangeFor$(terpenesCamelCased, 'loc'));
            return of(terpenesCamelCased).pipe(withLatestFrom(loc$));
          }
          if (terpeneProperties?.contains(`Company ${terpenesCamelCased}`)) {
            const com$ = unwrapChange$(variantId, getChangeFor$(terpenesCamelCased, 'comp'));
            return of(terpenesCamelCased).pipe(withLatestFrom(com$));
          }
          return of([terpenesCamelCased, this.dontUpdateThisProperty]);
        };
        return combineLatest(terpenesCamelCased?.map(terpeneCamelCased => getUpdatePipe$(terpeneCamelCased)) || []);
      }),
      take(1),
      map(updates => {
        updates?.forEach(([terpenesCamelCased, value]) => {
          if ((value !== this.dontUpdateThisProperty) && (value !== null)) displayAttribute[terpenesCamelCased] = value;
        });
        return displayAttribute;
      })
    );
  }

  /* ******* Customization Properties ******* */

  /**
   * Update location and company in separate calls to this function.
   * As in, don't pass in location and company properties at the same time.
   * If you do this, then this function will not work.
   *
   * @param variantId
   * @param displayAttribute
   * @param customizationProperties
   * @private
   */
  private updateCustomizationPropertiesForDisplayAttribute$(
    variantId: string,
    displayAttribute: DisplayAttribute,
    customizationProperties: string[]
  ): Observable<DisplayAttribute> {
    return this.updateCustomizationProperties$(variantId, displayAttribute, customizationProperties);
  }

  private getBadgeChangePipe$ = (
    variantId: string,
    changePipes$: Observable<Map<string, BehaviorSubject<HydratedVariantBadge[]>>>
  ): Observable<string[] | null> => {
    return changePipes$.pipe(
      take(1),
      switchMap(changePipes => changePipes?.get(variantId)),
      map(badges => badges?.map(badge => badge.id)?.unique()),
      take(1),
    );
  };

  private getLabelChangePipe$ = (
    variantId: string,
    changePipes$: Observable<Map<string, BehaviorSubject<string>>>
  ): Observable<string | null> => {
    return changePipes$.pipe(
      take(1),
      switchMap(changePipes => changePipes?.get(variantId)),
      take(1),
    );
  };

  /**
   * Update location and company in separate calls to this function.
   * As in, don't pass in location and company properties at the same time.
   * If you do this, then this function will not work.
   *
   * @param variantId
   * @param displayAttribute
   * @param customizationProperties
   * @private
   */
  private updateCustomizationProperties$(
    variantId: string,
    displayAttribute: DisplayAttribute,
    customizationProperties: string[],
  ): Observable<DisplayAttribute> {
    let badgeIds$: Observable<string> | Observable<string[]> = of(this.dontUpdateThisProperty);
    let label$: Observable<string> = of(this.dontUpdateThisProperty);
    if (customizationProperties?.contains(CustomizationProperty.LocationBadges)) {
      badgeIds$ = this.getBadgeChangePipe$(variantId, this.individualLocationBadgeChanges$);
    }
    if (customizationProperties?.contains(CustomizationProperty.LocationLabel)) {
      label$ = this.getLabelChangePipe$(variantId, this.individualLocationLabelChanges$);
    }
    if (customizationProperties?.contains(CustomizationProperty.CompanyBadges)) {
      badgeIds$ = this.getBadgeChangePipe$(variantId, this.individualCompanyBadgeChanges$);
    }
    if (customizationProperties?.contains(CustomizationProperty.CompanyLabel)) {
      label$ = this.getLabelChangePipe$(variantId, this.individualCompanyLabelChanges$);
    }
    return combineLatest([badgeIds$, label$]).pipe(
      take(1),
      map(([badgeIds, label]) => {
        if (badgeIds !== this.dontUpdateThisProperty) displayAttribute.badgeIds = badgeIds as string[];
        if (label !== this.dontUpdateThisProperty) displayAttribute.defaultLabel = label as string;
        return displayAttribute;
      })
    );
  }

  /* ********************************** Public methods ********************************** */

  public clearSelectedVariants(): void {
    this._selectedVariantIds.next([]);
  }

  public resetVariantChanges(variantId: string): void {
    this.clearCannabinoidChangesFor(variantId);
    this.clearTerpeneChangesFor(variantId);
    this.resetCustomizationChangesFor(variantId);
  }

  public bulkSaveVariant = (variantId: string): void => this._saveVariant.next(variantId);

  public bulkSave(): void {
    this.selectedVariantIds$.pipe(take(1)).subscribe(variantIds => {
      variantIds.forEach(variantId => this.bulkSaveVariant(variantId));
    });
  }

}
