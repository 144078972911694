import * as uuid from 'uuid';
import { Product } from '../dto/product';
import type { Variant } from '../dto/variant';
import { exists } from '../../../functions/exists';

/**
 * Allows us to represent a grouping of variants with the same brand underneath a product umbrella.
 * This is useful because then we can override methods, and the product will automatically plug in to
 * existing pipelines.
 *
 * Example. In edit menu section, when searching products grouped by brand, the search functionality will automatically
 * search for the brand name, because of the override methods below.
 */
export class ProductThatRepresentsVariantsGroupedByBrand extends Product {

  constructor(variantsGroupedByBrand: Variant[]) {
    super();
    this.id = uuid.v4();
    this.variants = variantsGroupedByBrand;
  }

  // this lets us search by brand within edit menu section
  override getDisplayName(): string {
    return this.getBrandName();
  }

  override getProductTitle(): string {
    return this.getBrandName();
  }

  protected getBrandName(): string {
    const brandName = this.getBrand(false);
    return exists(brandName) ? brandName : '(No Brand Name)';
  }

}
