import { Injectable, Injector, NgZone } from '@angular/core';
import { EditTemplateMenuSectionViewModel } from '../../../../edit-template-menu-section/edit-template-menu-section-view-model';
import { TemplateDomainModel } from '../../../../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../../../../domainModels/location-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditShelfTalkerBrandsSectionViewModel } from '../../../../../../menu/components/edit-menu/edit-shelf-talker-menu/edit-shelf-talker-brands-menu/edit-shelf-talker-brands-section/edit-shelf-talker-brands-section-view-model';
import { combineLatest } from 'rxjs';
import { ModalShelfTalkerLiveView } from '../../../../../../../modals/modal-shelf-talker-live-view';
import { Size } from '../../../../../../../models/shared/size';

@Injectable()
export class EditTemplateShelfTalkerBrandsSectionViewModel extends EditTemplateMenuSectionViewModel {

  constructor(
    public override editMenuSectionViewModel: EditShelfTalkerBrandsSectionViewModel,
    templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(editMenuSectionViewModel, templateDomainModel, locationDomainModel, ngZone, ngbModal, injector);
  }

  public override openLiveViewModal(sizeOverride?: Size) {
    combineLatest([
      this.activeMenuTemplate$,
      this.activeSectionTemplate$
    ]).once(([menu, section]) => {
      ModalShelfTalkerLiveView.open(this.ngZone, this.ngbModal, this.injector, menu, section?.id);
    });
  }

}
