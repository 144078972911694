import { Deserializable } from '../../protocols/deserializable';
import { LookAheadItem } from '../../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class User implements Deserializable, LookAheadItem, UniquelyIdentifiable {

  public companyId: number;
  public userId: string;
  public dateCreated: number;
  public cognitoId: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public isCompanyAdmin: boolean;
  public hasProfilePicture: boolean;
  public emailConfirmed: boolean;
  public accountConfirmed: boolean;
  public lastSession: number;
  public defaultLocationId: number;
  public isTemplateAdmin: boolean;
  // Not return from api, calculated on deserialization
  public fullName: string;
  // private for look ahead list item
  public alreadyAddedToPrintSchedule: boolean = false;
  public companyRole: string;

  getUniqueIdentifier(): string {
    return `
      -${this.companyId}
      -${this.userId}
      -${this.cognitoId}
      -${this.email}
      -${this.firstName}
      -${this.lastName}
      -${this.isCompanyAdmin}
      -${this.hasProfilePicture}
      -${this.emailConfirmed}
      -${this.accountConfirmed}
      -${this.lastSession}
      -${this.defaultLocationId}
      -${this.isTemplateAdmin}
      -${this.companyRole}
    `;
  }

  public onDeserialize() {
    this.fullName = `${this?.firstName} ${this?.lastName}`.trim();
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/UserDTO.go
  public onSerialize() {
    const dto = Object.create(User.prototype);
    dto.companyId = this.companyId;
    dto.userId = this.userId;
    dto.dateCreated = this.dateCreated;
    dto.cognitoId = this.cognitoId;
    dto.email = this.email;
    dto.firstName = this.firstName;
    dto.lastName = this.lastName;
    dto.defaultLocationId = this.defaultLocationId;
    dto.isCompanyAdmin = this.isCompanyAdmin;
    dto.isTemplateAdmin = this.isTemplateAdmin;
    dto.hasProfilePicture = this.hasProfilePicture;
    dto.emailConfirmed = this.emailConfirmed;
    dto.accountConfirmed = this.accountConfirmed;
    dto.lastSession = this.lastSession;
    dto.companyRole = this.companyRole;
    return dto;
  }

  getFullName(): string {
    return `${this?.firstName} ${this?.lastName}`;
  }

  setFromOtherUser(u: User) {
    this.companyId = u.companyId;
    this.userId = u.userId;
    this.dateCreated = u.dateCreated;
    this.cognitoId = u.cognitoId;
    this.email = u.email;
    this.firstName = u.firstName;
    this.lastName = u.lastName;
    this.isCompanyAdmin = u.isCompanyAdmin;
    this.hasProfilePicture = u.hasProfilePicture;
    this.emailConfirmed = u.emailConfirmed;
    this.accountConfirmed = u.accountConfirmed;
    this.lastSession = u.lastSession;
    this.defaultLocationId = u.defaultLocationId;
    this.companyRole = u.companyRole;
  }

  // Look ahead item interface

  setAlreadyAddedToScheduledMenu(added: boolean) {
    this.alreadyAddedToPrintSchedule = added;
  }

  lookAheadDisabled(): boolean {
    return !this.emailConfirmed || this.alreadyAddedToPrintSchedule;
  }

  lookAheadDisabledMessage(): string {
    if (this.alreadyAddedToPrintSchedule) return 'Already added.';
    if (!this.emailConfirmed) return 'Email not verified.';
  }

}
