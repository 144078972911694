import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { Menu } from '../../../../../../../../models/menu/dto/menu';
import { LocationConfiguration } from '../../../../../../../../models/company/dto/location-configuration';

@Component({
  selector: 'app-stack-manual-bulk-print-job-menu-list-item',
  templateUrl: './stack-manual-bulk-print-job-menu-list-item.component.html',
  styleUrls: ['./stack-manual-bulk-print-job-menu-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StackManualBulkPrintJobMenuListItemComponent extends BaseComponent {

  constructor() {
    super();
  }

  @Input() selectedId: string;
  @Input() stackMenu: Menu;
  @Input() locationConfig: LocationConfiguration;
  @Input() nSelectedItems: number;
  @Output() idSelected = new EventEmitter<string>(true);

}
