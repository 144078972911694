<div matRipple class="select-all-container pt-28px pb-16px" (click)="groupSelection.containerClicked()">
  <lib-reactive-form-label [label]="label"></lib-reactive-form-label>
  <div class="select-all-button-container">
    <span class="menu-count">({{ viewModel.stackNSelectedSectionIds$ | push }})</span>
    <app-group-selection
      #groupSelection
      [selection]="viewModel.visibleSectionsInListAsSelection$ | push"
      [selectedIds]="viewModel.stackSelectedItemIds$ | push"
      (bulkAdd)="viewModel.bulkAddSelections($event)"
      (bulkRemove)="viewModel.bulkRemoveSelections($event)">
    </app-group-selection>
  </div>
</div>
