import { Pipe, PipeTransform } from '@angular/core';
import { SelectableSmartFilter } from '../../../../../models/automation/protocols/selectable-smart-filter';

@Pipe({
  name: 'getSelectionName'
})
export class GetSelectionNamePipe implements PipeTransform {

  transform(selectableSmartFilter: SelectableSmartFilter): string | null {
    return selectableSmartFilter?.getSelectionName();
  }

}
