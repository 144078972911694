import { NavItemViewModel } from '../nav-item-view-model';
import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ProductDomainModel } from '../../../../../../domainModels/product-domain-model';
import { map, switchMap, take } from 'rxjs/operators';
import { Location } from '../../../../../../models/company/dto/location';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';
import { ModalLocationPicker } from '../../../../../../modals/modal-location-picker';
import { EMPTY } from 'rxjs';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { exists } from '../../../../../../functions/exists';

@Injectable()
export class CurrentLocationNavItemViewModel extends NavItemViewModel {

  constructor(
    protected locationDomainModel: LocationDomainModel,
    protected productDomainModel: ProductDomainModel,
    protected userDomainModel: UserDomainModel,
    protected injector: Injector,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    router: Router,
  ) {
    super(router);
  }

  public location$ = this.locationDomainModel.location$.pipe(
    map(currentLocation => { return currentLocation ? currentLocation?.name : 'Location'; })
  );
  public override multiLineNavItem$ = this.location$.pipe(map(name => name?.length > 12));

  showLocationPickerModal() {
    const onClose = ([activeLocation, remember]: [Location, boolean]) => {
      if (exists(activeLocation)) {
        if (remember) this.updateUserDefaultLocation(activeLocation?.id);
        this.locationDomainModel.setCurrentLocation(activeLocation);
      }
    };
    ModalLocationPicker.open(this.ngZone, this.ngbModal, this.injector, onClose);
  }

  private updateUserDefaultLocation(locId: number): void {
    this.userDomainModel.user$.pipe(
      take(1),
      switchMap(user => {
        if (user?.defaultLocationId === locId) {
          return EMPTY;
        }
        user.defaultLocationId = locId;
        return this.userDomainModel.updateUser(user);
      })
    ).once();
  }

}
