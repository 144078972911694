import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../../../models/product/dto/product';

@Pipe({
  name: 'getProductMinTACWithUnits'
})
export class GetProductMinTacWithUnitsPipe implements PipeTransform {

  transform(product: Product | null): string | null {
    return product?.getMinTACWithUnits();
  }

}
