import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HasChildIds } from '../../../../../../../../../models/protocols/has-child-ids';

@Component({
  selector: 'app-card-stack-print-job-select-all-sections',
  templateUrl: './card-stack-print-job-select-all-sections.component.html',
  styleUrls: ['../stack-print-job-sections-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardStackPrintJobSelectAllSectionsComponent {

  @Input() sectionIds: string[];
  @Input() selection: HasChildIds;
  @Output() bulkAddSelection = new EventEmitter<string[]>();
  @Output() bulkRemoveSelection = new EventEmitter<string[]>();

}
