<div class="row edit-collection-section">
  <div class="col-4">
    <div class="section-title">Template Collection Details</div>
  </div>
  <div class="col-8">
    <app-message-banner [message]="viewModel.bannerMessage$ | async"></app-message-banner>
    <lib-reactive-form-group [mergeKey]="mergeKey" [bindTo]="bindTo">
      <div class="collection-details-form">
        <div>
          <lib-reactive-form-text
            fxFlex
            [inputName]="'collectionName'"
            [required]="true"
            [bindingProperty]="'name'"
            [placeholder]="'Enter the template collection name'"
            [maxLength]="128"
            [label]="'Template Collection Name'">
          </lib-reactive-form-text>
        </div>
        <lib-reactive-form-pill-drop-down
          [bindingProperty]="'tags'"
          [inputName]="'tags'"
          [label]="'Tags'"
          [placeholder]="'Select tags for this collection.'"
          [inlineLabel]="false"
          [addToAtLeastNItemsFilledInPool]="true"
          [clearable]="true"
          [mergeDelimitedItemsThatAreTheSameWithDifferentCasing]="{
            on: true,
            showMergedOptionInListWith: 'MostCapitalized',
            whichAnswerIsUsedAsValue: 'LeastCapitalized'
          }"
          [maxCharactersInSearch]="128"
          [limitNumberOfSelectedItemsTo]="50"
          [userCanAddNewDelimitedItems]="true"
          [submitAsDelimitedString]="false"
          [delimiter]="','"
          [delimitedDropDownOptions]="viewModel.tagsDelimited$ | push">
        </lib-reactive-form-pill-drop-down>
        <div>
          <lib-reactive-form-drop-down
            fxFlex
            [inputName]="'previewSize'"
            [required]="true"
            [dropdowns]="viewModel.displaySizeDropDowns$ | async"
            [bindingProperty]="'displaySize.name'"
            [label]="'Preview Size'">
          </lib-reactive-form-drop-down>
        </div>
        <div>
          <lib-reactive-form-drop-down
            fxFlex
            [inputName]="'previewOrientation'"
            [required]="true"
            [dropdowns]="viewModel.orientationDropDowns$ | async"
            [bindingProperty]="'displaySize.orientation'"
            [label]="'Preview Orientation'">
          </lib-reactive-form-drop-down>
        </div>
      </div>
    </lib-reactive-form-group>
  </div>
</div>
