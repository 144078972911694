import { Component } from '@angular/core';
import { EditTemplateMenuComponent } from '../edit-template-menu.component';
import { EditTemplateShelfTalkerMenuViewModel } from './edit-template-shelf-talker-menu-view-modal';
import { EditTemplateShelfTalkerMenuCombinedLoadingOptsViewModel } from './edit-template-shelf-talker-menu-combined-loading-opts-view-model';

@Component({
  selector: 'app-edit-template-shelf-talker-menu',
  template: ''
})
export abstract class EditTemplateShelfTalkerMenuComponent extends EditTemplateMenuComponent {

   protected constructor(
     public override viewModel: EditTemplateShelfTalkerMenuViewModel,
     public combinedLoadingOptsViewModel: EditTemplateShelfTalkerMenuCombinedLoadingOptsViewModel
    ) {
      super(viewModel);
    }

}
