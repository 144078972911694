import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EditShelfTalkerBrandsAssetSectionViewModel } from './edit-shelf-talker-brands-asset-section-view-model';
import { EditMenuSectionPrimaryAssetComponent } from '../../../../../edit-menu-section/edit-menu-section-primary-asset/edit-menu-section-primary-asset.component';

@Component({
  selector: 'app-edit-shelf-talker-brands-asset-section',
  templateUrl: './edit-shelf-talker-brands-asset-section.component.html',
  styleUrls: ['./edit-shelf-talker-brands-asset-section.component.scss'],
  providers: [EditShelfTalkerBrandsAssetSectionViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditShelfTalkerBrandsAssetSectionComponent extends EditMenuSectionPrimaryAssetComponent {

  constructor(
    public override viewModel: EditShelfTalkerBrandsAssetSectionViewModel
  ) {
    super(viewModel);
  }

}
