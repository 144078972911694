<div class="preview-section">
  <div *rxIf="cannabinoid" class="preview-cannabinoids-info-container">
    <div
      *rxIf="
        viewModel.allCannabinoidPreviewInfo$
          | push
          | getCannabinoidPreviewInfoFor : cannabinoid as cannabinoidPreviewInfo
      "
      class="preview-cannabinoid">
      <div class="preview-cannabinoid-headers">{{ cannabinoidPreviewInfo?.title }}</div>
      <div class="preview-cannabinoid-info">{{ cannabinoidPreviewInfo?.value }}</div>
      <div class="cannabinoid-source">{{ cannabinoidPreviewInfo?.source }}</div>
    </div>
  </div>
</div>
