import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Theme } from '../models/menu/dto/theme';
import { MenuType, MenuTypeDefinition } from '../models/utils/dto/menu-type-definition';
import { Orientation } from '../models/utils/dto/orientation-type';
import { ThemeDetailsModalComponent } from '../views/menu/components/modals/theme-details-modal/theme-details-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Injector, NgZone } from '@angular/core';

export class ModalThemeDetails {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    theme: Theme,
    menuType: MenuType,
    orientation: Orientation
  ) {
    ngZone.run(() => {
      const modalRef = ngbModal.open(ThemeDetailsModalComponent, ModalUtils.themeDetailsModalOptions(injector));
      const compInst = modalRef.componentInstance as ThemeDetailsModalComponent;
      compInst.setTheme(theme, menuType);
      if (orientation === Orientation.ReversePortrait) {
        orientation = Orientation.Portrait;
      }
      if (MenuTypeDefinition.containsStackedContent(menuType)) {
        orientation = Orientation.Landscape;
      }
      compInst.setOrientation(orientation);
    });
  }

}
