<app-info-banner *ngIf="viewModel.selectedSortOptionIsStrainClassification$ | async" class="mb-16px">
  {{ viewModel.strainClassificationSortExplanation$ | async }}
</app-info-banner>

<lib-reactive-form-group
  #form
  [mergeKey]="mergeKey"
  [bindTo]="bindTo"
  [globalDisabledTooltip]="isTemplatedSection | templatedMenuDisabledFormControlTooltip"
  (formAutoSubmitted$)="formAutoSubmitted.emit($event)"
  (disableSubmit$)="disableSubmit.emit($event)">
  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-text
      [inputName]="'title'"
      [label]="viewModel.sectionTitleLabel$ | async"
      [placeholder]="'Enter a title for this card.'"
      [inlineLabel]="false"
      [required]="true"
      [minLength]="1"
      [maxLength]="128"
      [disabled]="isTemplatedSection"
      [bindingProperty]="'title'">
    </lib-reactive-form-text>

    <lib-reactive-form-text
      [inputName]="'subTitle'"
      [label]="'Category Card Subtitle'"
      [placeholder]="viewModel.sectionSubtitlePlaceholder$ | async"
      [inlineLabel]="false"
      [required]="false"
      [minLength]="1"
      [maxLength]="128"
      [disabled]="isTemplatedSection"
      [bindingProperty]="'subTitle'">
    </lib-reactive-form-text>

    <lib-reactive-form-drop-down
      [inputName]="'sorting'"
      [label]="'Primary Sort'"
      [placeholder]="'Select how variants should be sorted.'"
      [inlineLabel]="false"
      [required]="true"
      [disabled]="isTemplatedSection"
      [dropdowns]="viewModel.primarySortingTypes$ | async"
      [bindingProperty]="'sorting'"
      [reinitializeWhenListChanges]="false"
      (valueChanged)="viewModel.connectToSelectedPrimarySortOption($event)">
    </lib-reactive-form-drop-down>

    <!-- not required, because old menu sections will not have a secondary set -->
    <lib-reactive-form-drop-down
      [inputName]="'secondarySorting'"
      [label]="'Secondary Sort'"
      [disabled]="(viewModel.secondarySortDisabled$ | async) || isTemplatedSection"
      [placeholder]="viewModel.secondarySortPlaceholder$ | async"
      [tooltip]="viewModel.secondarySortTooltip$ | async"
      [inlineLabel]="false"
      [required]="false"
      [dropdowns]="viewModel.secondarySortingTypes$ | async"
      [bindingProperty]="'secondarySorting'"
      [reinitializeWhenListChanges]="false"
      [programmaticallyChangeValue]="viewModel.programmaticallyChangeSecondarySort$ | async"
      (valueChanged)="viewModel.connectToSelectedSecondarySortOption($event)">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>

  <lib-reactive-form-column-layout [nColumns]="2">
    <lib-reactive-form-drop-down
      #sectionType
      [inputName]="'sectionType'"
      [label]="'Section Type'"
      [placeholder]="'Select the type of section to create'"
      [inlineLabel]="false"
      [disabled]="true"
      [required]="true"
      [disabledTooltip]="''"
      [dropdowns]="viewModel.sectionTypes$ | async"
      [bindingProperty]="'sectionType'">
    </lib-reactive-form-drop-down>
  </lib-reactive-form-column-layout>

  <lib-reactive-form-row>
    <lib-reactive-form-checkbox
      #showZeroStock
      [inputName]="'showOutOfStockProducts'"
      [disabled]="(viewModel.sectionHasSmartFilters$ | async) || isTemplatedSection"
      [selectedValue]="true"
      [notSelectedValue]="false"
      [bindingProperty]="'showZeroStockItems'"
      [emitInitializationValuesAsValueChanged]="true"
      (valueChanged)="viewModel.connectToShowZeroStock($event)">
      Show Out of Stock Products
    </lib-reactive-form-checkbox>

    <lib-reactive-form-switch
      [label]="'Hide Product Prices'"
      [tooltip]="'The price column will be hidden from the category card.'"
      [bindingProperty]="'section.metadata.hidePrices'"
      [inputName]="'hidePrices'"
      [inlineLabel]="true"
      [inlinePercentage]="50"
      [disabled]="isTemplatedSection"
      [selectedValue]="'true'"
      [notSelectedValue]="'false'">
    </lib-reactive-form-switch>
  </lib-reactive-form-row>

  <app-note-text *ngIf="viewModel.showZeroStockNote$ | async" [fontSize]="'0.875rem'" [margin]="'1rem 0 0 0'">
    {{ viewModel.zeroStockNote$ | async }}
  </app-note-text>
</lib-reactive-form-group>

<app-edit-menu-warning-banner
  [warningMessage]="viewModel.sectionBannerWarningMessage$ | async"
  [tooltipMessage]="viewModel.sectionWarningMessageToolTip$ | async">
</app-edit-menu-warning-banner>
