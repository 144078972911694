import { BaseComponent } from '../../../../../../models/base/base-component';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { CompanyConfiguration } from '../../../../../../models/company/dto/company-configuration';
import { LocationConfiguration } from '../../../../../../models/company/dto/location-configuration';
import { EditSectionFormViewModel } from './edit-section-form-view-model';
import type { SectionSortOption } from '../../../../../../models/enum/dto/section-sort-option';

@Component({template: ''})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class EditSectionForm extends BaseComponent implements OnChanges {

  protected constructor(public viewModel: EditSectionFormViewModel) {
    super();
  }

  @Input() mergeKey: string;
  @Input() bindTo: any;
  @Input() menu: Menu;
  @Input() section: HydratedSection;
  @Input() isTemplatedSection: boolean = false;
  @Input() companyConfig: CompanyConfiguration;
  @Input() locationConfig: LocationConfiguration;
  @Output() showZeroStockChanged = new EventEmitter<boolean>(true);
  @Output() formAutoSubmitted: EventEmitter<any[]> = new EventEmitter<any[]>(true);
  @Output() disableSubmit: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Output() primarySortChanged = new EventEmitter<SectionSortOption>(true);
  @Output() secondarySortChanged = new EventEmitter<SectionSortOption>(true);

  override setupBindings() {
    this.viewModel.showZeroStock$.subscribeWhileAlive({
      owner: this,
      next: showZeroStock => this.showZeroStockChanged.emit(showZeroStock)
    });
    this.viewModel.selectedPrimarySortOption$.subscribeWhileAlive({
      owner: this,
      next: sort => this.primarySortChanged.emit(sort)
    });
    this.viewModel.selectedSecondarySortOption$.subscribeWhileAlive({
      owner: this,
      next: sort => this.secondarySortChanged.emit(sort)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bindTo) this.viewModel.connectToBindTo(this.bindTo);
    if (changes.menu) this.viewModel.connectToMenu(this.menu);
    if (changes.section) this.viewModel.connectToSection(this.section);
    if (changes.companyConfig) this.viewModel.connectToCompanyConfig(this.companyConfig);
    if (changes.locationConfig) this.viewModel.connectToLocationConfig(this.locationConfig);
  }

}
