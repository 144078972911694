import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, NgZone, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { MenuDomainModel } from '../../../../../../../domainModels/menu-domain-model';
import { ReactiveFormDropDownComponent } from '@mobilefirstdev/reactive-form';
import { Menu } from '../../../../../../../models/menu/dto/menu';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalThemeDetails } from '../../../../../../../modals/modal-theme-details';

@Component({
  selector: 'app-spotlight-menu-details-form',
  templateUrl: './spotlight-menu-details-form.component.html',
  styleUrls: ['./spotlight-menu-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpotlightMenuDetailsFormComponent extends BaseComponent {

  @Input() menu: Menu;
  @Input() mergeKey: string;
  @Input() bindTo: any;
  @Output() height = new EventEmitter<number>(true);
  @Output() width = new EventEmitter<number>(true);

  @ViewChild('sectionOrientation') protected sectionOrientation: ReactiveFormDropDownComponent;

  public themes$ = this.menuDomainModel.menuThemes$;
  public menuTypes$ = window.types.menuTypes$;
  public displaySizes$ = window.types.digitalSizeTypes$;
  public orientations$ = window.types.orientations$;

  constructor(
    protected menuDomainModel: MenuDomainModel,
    protected ngZone: NgZone,
    protected ngbModal: NgbModal,
    protected injector: Injector,
  ) {
    super();
  }

  heightChanged(h: number) {
    this.height.emit(h);
  }

  widthChanged(w: number) {
    this.width.emit(w);
  }

  openThemeDetails() {
    ModalThemeDetails.open(
      this.ngZone,
      this.ngbModal,
      this.injector,
      this.bindTo.hydratedTheme,
      this.bindTo.type,
      this.sectionOrientation?.getMyValue()
    );
  }

}
