import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../models/base/base-component';
import { EditMenuSectionViewModel } from '../../../viewModels/edit-menu-section-view-model';
import { UploadImageInterface } from '../../../../shared/components/upload-asset/upload-image-interface';
import { BudsenseFile } from '../../../../../models/shared/budsense-file';
import { ASSET_RETRY_COUNT } from '../../../../../utils/media-utils';
import { UploadAssetComponent } from '../../../../shared/components/upload-asset/upload-asset.component';
import { SectionLayoutType } from '../../../../../models/utils/dto/section-layout-type';

@Component({
  selector: 'app-edit-menu-section-body',
  templateUrl: './edit-menu-section-body.component.html',
  styleUrls: ['./edit-menu-section-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMenuSectionBodyComponent extends BaseComponent implements UploadImageInterface {

  constructor(
    public viewModel: EditMenuSectionViewModel
  ) {
    super();
  }

  selectedSectionLayoutType: SectionLayoutType;
  autoUpdateGridColumns: boolean;

  @ViewChild('sectionHeaderImage') sectionHeaderImage: UploadAssetComponent;
  @Input() templateMode: boolean = false;
  @Input() isTemplatedSection: boolean = false;
  @Input() isPrintMenu: boolean = false;
  @Input() formHasErrors: boolean;
  @Input() formPristine: boolean = true;
  @Input() dispersedAndMergeKey = 'editSection';

  onLayoutTypeSelected(selectedSectionLayoutType: SectionLayoutType) {
    this.selectedSectionLayoutType = selectedSectionLayoutType;
  }

  onAutoUpdateGridColumns(value: boolean) {
    this.autoUpdateGridColumns = value;
  }

  public fileList(
    files: BudsenseFile[],
    _: number,
    isSecondaryImage: boolean = false
  ): void {
    if (files.length > 0) {
      this.sectionHeaderImage?.clear();
      const file = files?.firstOrNull();
      if (!file) return;
      this.viewModel.uploadSectionAsset(file, isSecondaryImage).subscribe((uploadedFileNames) => {
        const fileName = uploadedFileNames?.firstOrNull();
        if (!fileName) return;
        this.viewModel.getRefreshedSectionAsset(fileName, ASSET_RETRY_COUNT, isSecondaryImage);
      });
    }
  }

}
