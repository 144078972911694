import { Component, EventEmitter, Injector, Input, NgZone, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EditSectionWithSectionAndMenuCombinedTogetherViewModel } from '../edit-menu-and-section-combined-together-menu/edit-section-with-section-and-menu-combined-together-view-model';
import { EditMenuWithMenuAndSectionCombinedTogetherViewModel } from '../edit-menu-and-section-combined-together-menu/edit-menu-with-menu-and-section-combined-together-view-model';
import { EditMenuSectionProductsComponent } from '../../../edit-menu-section/edit-menu-section-products/edit-menu-section-products.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Components that combine edit menu and edit section should extend this class.
 */
@Component({template: ''})
export abstract class EditMenuWithMenuAndSectionCombinedTogetherProductsComponent
  extends EditMenuSectionProductsComponent
  implements OnChanges {

  protected constructor(
    public menuViewModel: EditMenuWithMenuAndSectionCombinedTogetherViewModel,
    public sectionViewModel: EditSectionWithSectionAndMenuCombinedTogetherViewModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(sectionViewModel, ngZone, ngbModal, injector);
  }

  @Input() combinedAutoSaving: boolean = false;
  @Output() sortedVariantIds = new EventEmitter<string[]>(true);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.templateMode) {
      this.menuViewModel.connectToTemplateMode(this.templateMode);
      this.sectionViewModel.connectToTemplateMode(this.templateMode);
    }
  }

}
