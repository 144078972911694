export enum MenuCreationFlowType {
  DigitalProductMenu = 'CREATE_DIGITAL_PRODUCT_MENU',
  DigitalSpotlightMenu = 'CREATE_DIGITAL_SPOTLIGHT_MENU',
  PrintProductMenu = 'CREATE_PRINT_PRODUCT_MENU',
  DigitalPlaylistMenu = 'CREATE_DIGITAL_LOOPING_CONTENT_MENU',
  DigitalFeaturedProductMenu = 'CREATE_DIGITAL_FEATURED_PRODUCT_MENU',
  DigitalFeaturedCategoryMenu = 'CREATE_DIGITAL_FEATURED_CATEGORY_MENU',
  WebProductMenu = 'CREATE_WEB_PRODUCT_MENU',
  WebSpotlightMenu = 'CREATE_WEB_SPOTLIGHT_MENU',
  PrintCardMenu = 'CREATE_PRINT_CARD_MENU',
  PrintShelfTalkerMenu = 'CREATE_SHELF_TALKER_MENU',
  PrintLabelMenu = 'CREATE_PRINT_LABEL_MENU'
}
