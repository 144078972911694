import { EditTemplateMarketingMenuViewModel } from '../edit-template-marketing-menu-view-model';
import { Injectable, Injector, NgZone } from '@angular/core';
import { TemplateDomainModel } from '../../../../../../domainModels/template-domain-model';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditPlaylistMenuViewModel } from '../../../../../menu/components/edit-menu/edit-marketing-menu/edit-playlist-menu/edit-playlist-menu-view-model';

@Injectable()
export class EditTemplateMarketingPlaylistMenuViewModel extends EditTemplateMarketingMenuViewModel {

  constructor(
    protected override editMenuViewModel: EditPlaylistMenuViewModel,
    templateDomainModel: TemplateDomainModel,
    locationDomainModel: LocationDomainModel,
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector
  ) {
    super(editMenuViewModel, templateDomainModel, locationDomainModel, ngZone, ngbModal, injector);
  }

}
