import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { EditMenuWithMenuAndSectionCombinedTogetherViewModel } from '../shared/edit-menu-and-section-combined-together-menu/edit-menu-with-menu-and-section-combined-together-view-model';
import { Size } from '../../../../../models/shared/size';
import { ModalPrintCardLiveView } from '../../../../../modals/modal-print-card-live-view';
import { MenuTemplate } from '../../../../../models/template/dto/menu-template';
import { SectionTemplate } from '../../../../../models/template/dto/section-template';
import { Section } from '../../../../../models/menu/dto/section';

@Injectable()
export class EditCardStackMenuViewModel extends EditMenuWithMenuAndSectionCombinedTogetherViewModel {

  public mergeKey$ = of('editCardStack');

  private readonly _sortedVariantIds = new BehaviorSubject<string[]|null>(null);
  public readonly sortedVariantIds$ = this._sortedVariantIds as Observable<string[]|null>;
  connectToSortedVariantIds = (sortedVariantIds: string[]|null) => this._sortedVariantIds.next(sortedVariantIds);

  /**
   * Must override to replace non-hydrated section on menu object with hydrated section.
   */
  public override openLiveViewModal(sizeOverride?: Size) {
    combineLatest([
      this.menu$,
      this.sectionStream$,
      this.sortedVariantIds$,
    ]).once(([menu, section, sortedVariantIds]) => {
      menu instanceof MenuTemplate && section instanceof SectionTemplate
        ? menu?.updateSectionTemplate(section, false)
        : menu?.updateSection(section as Section, false);
      ModalPrintCardLiveView.open(this.ngZone, this.ngbModal, this.injector, menu, sortedVariantIds, null);
    });
  }

}
