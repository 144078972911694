import { TypeDefinition } from './type-definition';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuSubtype } from '../../enum/dto/menu-subtype';
import { ProductMenuType } from '../../enum/dto/product-menu-type.enum';
import { MarketingMenuType } from '../../enum/dto/marketing-menu-type.enum';
import { PrintCardMenuType } from '../../enum/dto/print-card-menu-type.enum';
import { PrintReportMenuType } from '../../enum/dto/print-report-menu-type.enum';
import { PrintLabelMenuType } from '../../enum/dto/print-label-menu-type.enum';

export enum MenuType {
  // DisplayMenu encapsulates digital PRODUCT menus (this is an artifact of how we originally designed it)
  DisplayMenu = 'DISPLAY_MENU',
  WebMenu = 'WEB_MENU',
  PrintMenu = 'PRINT_MENU',
  MarketingMenu = 'MARKETING_MENU',
  PrintCardMenu = 'PRINT_CARD_MENU',
  PrintReportMenu = 'PRINT_REPORT_MENU',
  PrintLabelMenu = 'PRINT_LABEL_MENU',
  BulkPrint = 'BULK_PRINT',
  PrintShelfTalkerMenu = 'PRINT_SHELF_TALKER_MENU',
}

export class MenuTypeDefinition extends TypeDefinition {

  override value: MenuType;

  static containsStackedContent(menuType: MenuType): boolean {
    return menuType === MenuType.PrintCardMenu
        || menuType === MenuType.PrintShelfTalkerMenu
        || menuType === MenuType.PrintLabelMenu;
  }

  static getSingleWordDefiningMenuType(menuType: MenuType): string {
    switch (menuType) {
      case MenuType.DisplayMenu:     return 'display';
      case MenuType.WebMenu:         return 'web';
      case MenuType.PrintMenu:       return 'print';
      case MenuType.MarketingMenu:   return 'marketing';
      case MenuType.PrintCardMenu:   return 'card';
      case MenuType.PrintReportMenu: return 'report';
      case MenuType.PrintLabelMenu:  return 'label';
      case MenuType.BulkPrint:       return 'bulk';
      default:                       return '';
    }
  }

  static getPrintMenuTypeOptions(): Observable<MenuTypeDefinition[]> {
    return window.types.menuTypes$.pipe(
      map(menuTypes => MenuTypeDefinition.PrintMenuTypes(menuTypes))
    );
  }

  private static PrintMenuTypes(menuTypes: MenuTypeDefinition[]): MenuTypeDefinition[] {
    const printTypes = [
      MenuType.PrintMenu,
      MenuType.PrintCardMenu,
      MenuType.PrintLabelMenu,
      MenuType.PrintReportMenu,
    ];
    return menuTypes?.filter(mt => printTypes.includes(mt?.value));
  }

  public requiresMenuSubtype(): boolean {
    switch (this.value) {
      case MenuType.DisplayMenu:
      case MenuType.WebMenu:
      case MenuType.MarketingMenu:
      case MenuType.PrintCardMenu:
        return true;
      case MenuType.PrintMenu:
      case MenuType.PrintReportMenu:
      case MenuType.PrintLabelMenu:
        return false;
      default:
        return false;
    }
  }

  public getMenuSubTypes(): MenuSubtype[] {
    switch (this.value) {
      case MenuType.DisplayMenu:
      case MenuType.WebMenu:
      case MenuType.PrintMenu:
        return [
          ProductMenuType.ProductMenu,
          ProductMenuType.SpotlightMenu
        ];
      case MenuType.MarketingMenu:
        return [
          MarketingMenuType.Playlist,
          MarketingMenuType.SmartPlaylist,
          MarketingMenuType.Featured,
          MarketingMenuType.DriveThru,
          MarketingMenuType.Category
        ];
      case MenuType.PrintCardMenu:
        return [PrintCardMenuType.PrintCardMenu];
      case MenuType.PrintShelfTalkerMenu:
        return [PrintCardMenuType.PrintShelfTalkerMenuBrands];
      case MenuType.PrintLabelMenu:
        return [PrintLabelMenuType.PrintLabelMenu];
      case MenuType.PrintReportMenu:
        return [PrintReportMenuType.OrderReview];
      default:
        return [];
    }
  }

}
