import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NewMenuSectionContainer } from './new-menu-section-container';
import { SectionCreationType } from '../../../../../models/enum/shared/section-creation-type.enum';
import { Section } from '../../../../../models/menu/dto/section';
import { Theme } from '../../../../../models/menu/dto/theme';
import { NewMenuSectionRequest } from '../../../../../models/menu/dto/new-menu-section-request';
import { Menu } from '../../../../../models/menu/dto/menu';
import { SectionType } from '../../../../../models/enum/dto/section-type';

@Component({
  selector: 'app-new-menu-section-modal',
  templateUrl: './new-menu-section-modal.component.html',
  styleUrls: ['./new-menu-section-modal.component.scss'],
  providers: [NewMenuSectionContainer]
})
export class NewMenuSectionModalComponent extends BaseModalComponent implements AfterViewInit {

  constructor(
    public container: NewMenuSectionContainer,
    protected activeModal: NgbActiveModal,
  ) {
    super(activeModal);
  }

  // View References
  @ViewChild('modalBody') modalBody: HTMLDivElement;

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modalBody.scrollTop = this.modalBody.scrollHeight;
  }

  setSectionCreationType(type: SectionCreationType) {
    this.container.connectToCreationType(type);
  }

  setTheme(theme: Theme) {
    this.container.connectToMenuTheme(theme);
  }

  goBack() {
    this.container.newSectionType$.once(newSectionType => {
      this.container.connectToCreationType(null);
      this.container.connectToBlueprintSelected(null);
      if (!!newSectionType) this.setSectionTypeToCreate(newSectionType);
      else this.container.connectToNewSectionRequest(new NewMenuSectionRequest());
    });
  }

  addSection() {
    this.container.addSection();
  }

  setMenuToAddSectionTo(menu: Menu) {
    this.container.connectToMenu(menu);
  }

  setSectionTypeToCreate(sectionType: SectionType) {
    const newSection = new NewMenuSectionRequest(undefined, '', undefined, sectionType, undefined, undefined);
    this.container.connectToNewSectionRequest(newSection);
  }

  setExistingSectionToDuplicate(s: Section) {
    this.container.connectToCreationType(SectionCreationType.New);
    this.container.connectToIsDuplicating(true);
    const newSection = new NewMenuSectionRequest(
      undefined,
      `${s.title} (Copy)`,
      undefined,
      s.sectionType,
      undefined,
      undefined
    );
    this.container.connectToNewSectionRequest(newSection);
    //   We need the menuID of the section in order to duplicate, hence why we use both models
    const existingSectionToDuplicate = window?.injector?.Deserialize?.instanceOf(Section, s);
    this.container.connectToSectionToDuplicate(existingSectionToDuplicate);
  }

}
