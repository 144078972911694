<div class="modal-padding">
  <lib-reactive-form-column-layout>
    <lib-reactive-form-number
      [inputName]="'minTHC'"
      [label]="'Minimum THC'"
      [placeholder]="'Minimum THC content of products'"
      [inlineLabel]="false"
      [required]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [integersOnly]="false"
      [step]="0.01"
      [tooltip]="'The smart filter will include products with THC values greater than or equal to this value.'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'minTHC'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-number>

    <lib-reactive-form-number
      [inputName]="'maxTHC'"
      [label]="'Maximum THC'"
      [placeholder]="'Maximum THC content of products'"
      [inlineLabel]="false"
      [required]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [integersOnly]="false"
      [step]="0.01"
      [tooltip]="'The smart filter will include products with THC values less than or equal to this value.'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'maxTHC'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-number>

    <lib-reactive-form-number
      [inputName]="'minCBD'"
      [label]="'Minimum CBD'"
      [placeholder]="'Minimum CBD content of products'"
      [inlineLabel]="false"
      [required]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [integersOnly]="false"
      [step]="0.01"
      [tooltip]="'The smart filter will include products with CBD values greater than or equal to this value.'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'minCBD'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-number>

    <lib-reactive-form-number
      [inputName]="'maxCBD'"
      [label]="'Maximum CBD'"
      [placeholder]="'Maximum CBD content of products'"
      [inlineLabel]="false"
      [required]="false"
      [disabled]="viewModel.isViewOnly$ | push"
      [integersOnly]="false"
      [step]="0.01"
      [tooltip]="'The smart filter will include products with CBD values less than or equal to this value.'"
      [addToAtLeastNItemsFilledInPool]="true"
      [bindingProperty]="'maxCBD'"
      [dispersedKey]="dispersedKey">
    </lib-reactive-form-number>

    <ng-container *rxFor="let cannabinoid of viewModel.secondaryCannabinoids$ | push">
      <lib-reactive-form-number
        [inputName]="'min' + cannabinoid"
        [label]="'Minimum ' + cannabinoid"
        [placeholder]="'Minimum ' + cannabinoid + ' content of products'"
        [inlineLabel]="false"
        [required]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [integersOnly]="false"
        [step]="0.01"
        [tooltip]="
          'The smart filter will include products with ' + cannabinoid + ' values greater than or equal to this value.'
        "
        [addToAtLeastNItemsFilledInPool]="true"
        [bindingProperty]="'min' + cannabinoid"
        [dispersedKey]="dispersedKey">
      </lib-reactive-form-number>

      <lib-reactive-form-number
        [inputName]="'max' + cannabinoid"
        [label]="'Maximum ' + cannabinoid"
        [placeholder]="'Maximum ' + cannabinoid + ' content of products'"
        [inlineLabel]="false"
        [required]="false"
        [disabled]="viewModel.isViewOnly$ | push"
        [integersOnly]="false"
        [step]="0.01"
        [tooltip]="
          'The smart filter will include products with ' + cannabinoid + ' values less than or equal to this value.'
        "
        [addToAtLeastNItemsFilledInPool]="true"
        [bindingProperty]="'max' + cannabinoid"
        [dispersedKey]="dispersedKey">
      </lib-reactive-form-number>
    </ng-container>
  </lib-reactive-form-column-layout>
</div>
