import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../../models/base/base-view-model';
import { combineLatest, iif, Observable, switchMap } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { exists } from '../../../../../../../../functions/exists';
import { CannabisUnitOfMeasure } from '../../../../../../../../models/utils/dto/cannabis-unit-of-measure-type';
import { EditVariantCannabinoidsViewModel } from '../edit-variant-cannabinoids-view-model';
import { DisplayAttribute } from '../../../../../../../../models/display/dto/display-attribute';
import { TACProperty } from '../../../../../../../../models/enum/shared/tac-property-enum';

export interface CannabinoidPreviewInfo {
  cannabinoid: string;
  title: string;
  value: string;
  source: string;
}

@Injectable()
export class CannabinoidsPreviewViewModel extends BaseViewModel {

  constructor(
    private editVariantCannabinoidsViewModel: EditVariantCannabinoidsViewModel
  ) {
    super();
  }

  public variant$ = this.editVariantCannabinoidsViewModel.container.variant$;
  public companyDA$ = this.editVariantCannabinoidsViewModel.updatedCompanyDA$;
  public locationDA$ = this.editVariantCannabinoidsViewModel.updatedLocationDA$;
  public useRange$ = this.editVariantCannabinoidsViewModel.container.useCannabinoidRange$;
  public cannabinoidUnitOfMeasure$ = this.editVariantCannabinoidsViewModel.selectedCUOM$;
  public enabledSecondaryCannabinoidNames$ = this.editVariantCannabinoidsViewModel.enabledSecondaryCannabinoidNames$;
  public posSupportedSecondaryCannabinoids$ = this.editVariantCannabinoidsViewModel.posSupportedSecondaryCannabinoids$;
  public hasPosSupportedSecondaryCannabinoids$ =
    this.editVariantCannabinoidsViewModel.hasPosSupportedSecondaryCannabinoids$;
  public otherEnabledSecondaryCannabinoids$ = this.editVariantCannabinoidsViewModel.otherEnabledSecondaryCannabinoids$;
  public primaryCannabinoids$ = this.editVariantCannabinoidsViewModel.primaryCannabinoids$;
  public disableTACCalculation$ = this.editVariantCannabinoidsViewModel.disableTACCalculation$;

  public primaryCannabinoidPreviewInfo$ = combineLatest([
    this.primaryCannabinoids$,
    this.useRange$
  ]).pipe(
    switchMap(([primary, useRange]) => this.createCannabinoidPreviewsFromList$(primary, useRange))
  );

  public presentSecondaryCannabinoidPreviewInfo$: Observable<CannabinoidPreviewInfo> = combineLatest([
    this.companyDA$,
    this.locationDA$,
    this.editVariantCannabinoidsViewModel.companyPresentSecondaryCannabinoidPillPlaceholders$,
    this.editVariantCannabinoidsViewModel.locationPresentSecondaryCannabinoidsFromDisplayAttributes$,
  ]).pipe(
    map(([companyDA, locationDA, companyCannabinoidsFromDA, locationCannabinoidsFromDA]) => {
      const companyPresentSecondaryCannabinoids = companyDA?.presentSecondaryCannabinoids ?? [];
      const locationPresentSecondaryCannabinoids = locationDA?.presentSecondaryCannabinoids ?? [];
      let val: number = 0;
      let source: string;
      if (locationPresentSecondaryCannabinoids?.length > 0) {
        val = locationPresentSecondaryCannabinoids.length;
        source = '(Location)';
      } else if (companyPresentSecondaryCannabinoids?.length > 0) {
        val = companyPresentSecondaryCannabinoids?.length;
        source = '(Company)';
      } else {
        val = [...(locationCannabinoidsFromDA || []), ...(companyCannabinoidsFromDA || [])].unique().length;
        source = val > 0 ? '(Auto-calculated)' : '';
      }
      return {
        cannabinoid: 'presentSecondaryCannabinoids',
        title: 'Present Secondary Cannabinoids',
        value: (val || '--').toString(),
        source: source,
      };
    })
  );

  public posSupportedSecondaryCannabinoidPreviewInfo$ = combineLatest([
    this.posSupportedSecondaryCannabinoids$,
    this.useRange$
  ]).pipe(
    switchMap(([secondaryCannabinoids, useRange]) => {
      return this.createCannabinoidPreviewsFromList$(secondaryCannabinoids, useRange);
    }),
    startWith([])
  );

  public otherEnabledSecondaryCannabinoidPreviewInfo$ = combineLatest([
    this.otherEnabledSecondaryCannabinoids$,
    this.useRange$
  ]).pipe(
    switchMap(([secondaryCannabinoids, useRange]) => {
      return this.createCannabinoidPreviewsFromList$(secondaryCannabinoids, useRange);
    }),
    startWith([])
  );

  public readonly allCannabinoidPreviewInfo$ = combineLatest([
    this.primaryCannabinoidPreviewInfo$,
    this.presentSecondaryCannabinoidPreviewInfo$,
    this.posSupportedSecondaryCannabinoidPreviewInfo$,
    this.otherEnabledSecondaryCannabinoidPreviewInfo$,
  ]).pipe(
    map(([primary, presentSecondary, posSupportedSecondary, otherEnabledSecondary]) => {
      return [
        ...(primary ?? []),
        presentSecondary,
        ...(posSupportedSecondary ?? []),
        ...(otherEnabledSecondary ?? [])
      ]?.filterNulls();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private savePreviewPipelines = new Map<string, Observable<CannabinoidPreviewInfo>>();

  private createCannabinoidPreviewsFromList$(
    cannabinoids: string[],
    useRange: boolean
  ): Observable<CannabinoidPreviewInfo[]> {
    return iif(() => useRange, this.getRangedPreviewInfo$(cannabinoids), this.getIndividualPreviewInfo$(cannabinoids));
  }

  private getRangedPreviewInfo$(cannabinoids: string[]): Observable<CannabinoidPreviewInfo[]> {
    const rangePreviewObservables = cannabinoids?.flatMap(cannabinoid => {
      const minProperty = 'min' + cannabinoid;
      const maxProperty = 'max' + cannabinoid;
      if (!this.savePreviewPipelines.has(minProperty)) {
        const minTitle = 'Min ' + cannabinoid;
        this.savePreviewPipelines.set(minProperty, this.createCannabinoidPreviewInfo$(minProperty, minTitle));
      }
      if (!this.savePreviewPipelines.has(maxProperty)) {
        const maxTitle = 'Max ' + cannabinoid;
        this.savePreviewPipelines.set(maxProperty, this.createCannabinoidPreviewInfo$(maxProperty, maxTitle));
      }
      return [this.savePreviewPipelines.get(minProperty), this.savePreviewPipelines.get(maxProperty)];
    });
    return combineLatest(rangePreviewObservables);
  }

  private getIndividualPreviewInfo$(cannabinoids: string[]): Observable<CannabinoidPreviewInfo[]> {
    const previewObservables = cannabinoids.map(cannabinoid => {
      if (!this.savePreviewPipelines.has(cannabinoid)) {
        this.savePreviewPipelines.set(cannabinoid, this.createCannabinoidPreviewInfo$(cannabinoid, cannabinoid));
      }
      return this.savePreviewPipelines.get(cannabinoid);
    });
    return combineLatest(previewObservables);
  }

  private createCannabinoidPreviewInfo$(cannabinoid: string, title: string): Observable<CannabinoidPreviewInfo> {
    return combineLatest([
      this.variant$,
      this.companyDA$,
      this.locationDA$,
      this.cannabinoidUnitOfMeasure$,
      this.enabledSecondaryCannabinoidNames$,
      this.disableTACCalculation$,
    ]).pipe(
      map(([variant, companyDA, locationDA, cuom, enabledCannabinoids, disableTACCalculation]) => {
        const locationDACannabinoid = locationDA?.[cannabinoid];
        const companyDACannabinoid = companyDA?.[cannabinoid];
        const defaultCannabinoid = variant?.[cannabinoid];
        const isTACCannabinoid = Object.values(TACProperty).includes(cannabinoid as TACProperty);
        const canOverrideTAC = isTACCannabinoid ? this.allowTACOverride(companyDA, locationDA, cannabinoid) : false;
        const ifExistsUseThis = (x: any, useThisInstead?: any) => exists(x) ? (useThisInstead ?? x) : null;
        let val: string;
        let src: string;
        if (isTACCannabinoid && !disableTACCalculation && !canOverrideTAC) {
          const min = cannabinoid?.substring(0, 3) === 'min' ? 'min' : '';
          const max = cannabinoid?.substring(0, 3) === 'max' ? 'max' : '';
          const rangeAccessor = min || max || null;
          const totalTACPreview = variant
            ?.getAutoCalculatedTAC(enabledCannabinoids, rangeAccessor, [locationDA, companyDA])
            ?.toString();
          val = ifExistsUseThis(totalTACPreview);
          src = ifExistsUseThis(totalTACPreview, '(Auto-calculated)');
        }
        if (!val) {
          val = ifExistsUseThis(locationDACannabinoid)
             || ifExistsUseThis(companyDACannabinoid)
             || defaultCannabinoid;
          src = ifExistsUseThis(locationDACannabinoid, '(Location)')
             || ifExistsUseThis(companyDACannabinoid, '(Company)')
             || '(Variant Default)';
        }
        const info = (exists(val) && cuom !== CannabisUnitOfMeasure.NA && cuom !== CannabisUnitOfMeasure.UNKNOWN)
          ? val + cuom
          : '--';
        if (info === '--') src = '';

        return {
          cannabinoid: cannabinoid,
          title: title,
          value: info,
          source: src,
        };
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private allowTACOverride(
    companyDA: DisplayAttribute,
    locationDA: DisplayAttribute,
    cannabinoid: string
  ): boolean {
    const sources = [locationDA, companyDA];
    return sources.some(source => exists(source?.[cannabinoid]));
  }

}
