import { Injectable } from '@angular/core';
import { MenuAdditionalOptionsViewModel } from '../../../../../forms/menu-additional-options/menu-additional-options-view-model';
import { CompanyDomainModel } from '../../../../../../../../domainModels/company-domain-model';

@Injectable()
export class EditShelfTalkerBrandsMenuAdditionOptionsViewModel extends MenuAdditionalOptionsViewModel {

  constructor(companyDomainModel: CompanyDomainModel) {
    super(companyDomainModel);
  }

}
