import { Injectable } from '@angular/core';
import { DisplayableContentContainerViewModel } from './displayable-content-container-view-model';
import { LocationDomainModel } from '../../../../../domainModels/location-domain-model';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { TemplateDomainModel } from '../../../../../domainModels/template-domain-model';
import { AnimatorService } from '../../../../../services/animator/animator.service';
import { ActivatedRoute } from '@angular/router';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { combineLatest, defer, Observable, of } from 'rxjs';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { MenuType } from '../../../../../models/utils/dto/menu-type-definition';
import type { Menu } from '../../../../../models/menu/dto/menu';

@Injectable()
export abstract class MenusContentContainerViewModel extends DisplayableContentContainerViewModel {

  protected constructor(
    locationDomainModel: LocationDomainModel,
    menuDomainModel: MenuDomainModel,
    templateDomainModel: TemplateDomainModel,
    userDomainModel: UserDomainModel,
    animatorService: AnimatorService,
    activeRoute: ActivatedRoute
  ) {
    super(
      locationDomainModel,
      menuDomainModel,
      templateDomainModel,
      userDomainModel,
      animatorService,
      activeRoute
    );
  }

  abstract getMenuTypesFromSelectedTab(selectedTab: number): MenuType[];

  override itemsToFilter$: Observable<Menu[]>;

  public readonly locationText$ = of(null);

  public readonly menuTags$ = this.selectedTab$.pipe(
    switchMap(selectedTab => this.menuDomainModel.getMenuTagsFor(this.getMenuTypesFromSelectedTab(selectedTab))),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly templateTags$ = this.selectedTab$.pipe(
    switchMap(selectedTab => {
      return this.templateDomainModel.getMenuTemplateTagsFor(this.getMenuTypesFromSelectedTab(selectedTab));
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly tags$ = combineLatest([
    this.menuTags$.notNull(),
    this.templateTags$.notNull()
  ]).pipe(
    map(([menuTags, templateTags]) => [...(menuTags || []), ...(templateTags || [])].uniqueByProperty('value')),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public menuTypeItems$ = defer(() => this.itemsToFilter$).pipe(
    map(items => {
      const subTypes = items?.map(item => item.getSubType())?.filterNulls() || [];
      return [...new Set(subTypes)];
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
