import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { Tabbable } from '../../../../../../../models/protocols/tabbable';
import { BehaviorSubject } from 'rxjs';
import { TerpeneColumnOptionsFormViewModel } from './terpene-column-options-form-view-model';

@Component({
  selector: 'app-terpene-column-options-form',
  templateUrl: './terpene-column-options-form.component.html',
  styleUrls: ['./terpene-column-options-form.component.scss'],
  providers: [TerpeneColumnOptionsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerpeneColumnOptionsFormComponent extends BaseComponent implements Tabbable {

  constructor(
    public viewModel: TerpeneColumnOptionsFormViewModel
  ) {
    super();
  }

  tabActivated: BehaviorSubject<boolean>;

}
