export enum SectionSortProductInfo {
  BrandAsc = 'BRAND_ASC',
  BrandDesc = 'BRAND_DESC',
  CBDAsc = 'CBD_ASC',
  CBDDesc = 'CBD_DESC',
  ClassificationAsc = 'CLASSIFICATION_ASC',
  ClassificationDesc = 'CLASSIFICATION_DESC',
  ManufacturerAsc = 'MANUFACTURER_ASC',
  ManufacturerDesc = 'MANUFACTURER_DESC',
  PackagedQuantityAsc = 'PACKAGED_QUANTITY_ASC',
  PackagedQuantityDesc = 'PACKAGED_QUANTITY_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  ProductTypeAsc = 'PRODUCT_TYPE_ASC',
  ProductTypeDesc = 'PRODUCT_TYPE_DESC',
  SecondaryPriceAsc = 'SECONDARY_PRICE_ASC',
  SecondaryPriceDesc = 'SECONDARY_PRICE_DESC',
  StockAsc = 'STOCK_ASC',
  StockDesc = 'STOCK_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TACAsc = 'TAC_ASC',
  TACDesc = 'TAC_DESC',
  THCAsc = 'THC_ASC',
  THCDesc = 'THC_DESC',
  TopTerpeneAsc = 'TOP_TERPENE_ASC',
  TopTerpeneDesc = 'TOP_TERPENE_DESC',
  TotalTerpenesAsc = 'TOTAL_TERPENE_ASC',
  TotalTerpenesDesc = 'TOTAL_TERPENE_DESC',
  UnitSizeAsc = 'UNIT_SIZE_ASC',
  UnitSizeDesc = 'UNIT_SIZE_DESC',
  VariantTypeAsc = 'VARIANT_TYPE_ASC',
  VariantTypeDesc = 'VARIANT_TYPE_DESC',
}
