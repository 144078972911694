import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { UserDomainModel } from '../../../../../domainModels/user-domain-model';
import { ToastService } from '../../../../../services/toast-service';
import { BsError } from '../../../../../models/shared/bs-error';
import { BehaviorSubject } from 'rxjs';
import { LoadingOptions } from '../../../../../models/shared/loading-options';
import { Router } from '@angular/router';
import { NavigationService } from '../../../../../services/navigation.service';

@Injectable()
export class SignOutViewModel extends BaseViewModel {

  constructor(
    protected router: Router,
    protected userDomainModel: UserDomainModel,
    protected toastService: ToastService,
    protected navigationService: NavigationService
  ) {
    super();
  }

  public override _loadingOpts = new BehaviorSubject<LoadingOptions>(this.signOutLoadingOpts());

  private signOutLoadingOpts(): LoadingOptions {
    const opts = LoadingOptions.defaultWhiteBackground();
    opts.addRequest('Signing Out');
    return opts;
  }

  signOut() {
    return this.userDomainModel.signOut().subscribe({
      error: (error: BsError) => {
        this.toastService.publishError(error);
        this.navigationService.signIn();
      }
    });
  }

}
