import { Deserializable } from '../../protocols/deserializable';
import { UsePurpose } from '../../enum/dto/use-purpose';

export class InventoryRoom implements Deserializable {

  public locationId: number;
  public id: string;
  public companyId: number;
  public name: string;
  public disabled: boolean;
  public posDisabled: boolean;
  public usePurpose: UsePurpose;

  onDeserialize() {
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/InventoryRoomDTO.go
  onSerialize() {
    const dto = Object.create(InventoryRoom.prototype);
    dto.locationId = this.locationId;
    dto.id = this.id;
    dto.companyId = this.companyId;
    dto.name = this.name;
    dto.disabled = this.disabled;
    dto.posDisabled = this.posDisabled;
    dto.usePurpose = this.usePurpose;
    return dto;
  }

}
