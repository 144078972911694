import { BehaviorSubject, combineLatest, defer, Observable } from 'rxjs';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { SectionLayout, SectionLayoutType } from '../../../../../../models/utils/dto/section-layout-type';
import { CompanyConfiguration } from '../../../../../../models/company/dto/company-configuration';
import { LocationConfiguration } from '../../../../../../models/company/dto/location-configuration';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { ThemeUtils } from '../../../../../../utils/theme-utils';
import { DistinctUtils } from '../../../../../../utils/distinct-utils';
import { VariantType, VariantTypeDefinition } from '../../../../../../models/utils/dto/variant-type-definition';
import { Injectable } from '@angular/core';
import { SectionSortingViewModel } from '../../../../viewModels/section-sorting-view-model';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { SortUtils } from '../../../../../../utils/sort-utils';
import { NAME_COLUMN_DEFAULT_WIDTH } from '../../../../viewModels/column-options-view-model';
import { TypeDefinition } from '../../../../../../models/utils/dto/type-definition';
import { SectionColumnConfigDefaultState } from '../../../../../../models/enum/dto/section-column-config-default-state';
import { SectionSortProductInfo } from '../../../../../../models/enum/dto/section-sort-product-info';
import { SectionColumnConfigProductInfoKey } from '../../../../../../models/enum/dto/section-column-config-key';
import { SectionColumnConfigKeyType } from '../../../../../../models/utils/dto/section-column-config-key-type';

enum WarningMessageType {
  Banner,
  Tooltip
}

@Injectable()
export class EditSectionFormViewModel extends SectionSortingViewModel {

  constructor(
    locationDomainModel: LocationDomainModel
  ) {
    super(locationDomainModel);
  }

  protected _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu as Observable<Menu>;
  connectToMenu = (menu: Menu) => this._menu.next(menu);

  protected _companyConfig = new BehaviorSubject<CompanyConfiguration>(null);
  public companyConfig$ = this._companyConfig as Observable<CompanyConfiguration>;
  connectToCompanyConfig = (companyConfig: CompanyConfiguration) => this._companyConfig.next(companyConfig);

  protected _locationConfig = new BehaviorSubject<LocationConfiguration>(null);
  public locationConfig$ = this._locationConfig as Observable<LocationConfiguration>;
  connectToLocationConfig = (locationConfig: LocationConfiguration) => this._locationConfig.next(locationConfig);

  private _showZeroStock = new BehaviorSubject<boolean>(true);
  public showZeroStock$ = this._showZeroStock as Observable<boolean>;
  connectToShowZeroStock = (showZeroStock: boolean) => this._showZeroStock.next(showZeroStock);

  protected _layoutType = new BehaviorSubject<SectionLayoutType>(null);
  public layoutType$ = this._layoutType as Observable<SectionLayoutType>;
  connectToSectionLayoutType = (layoutType: SectionLayout | SectionLayoutType) => {
    layoutType instanceof SectionLayoutType
      ? this._layoutType.next(layoutType)
      : this._layoutType.next(!!layoutType ? window.types.initTypeDefinition(SectionLayoutType, layoutType) : null);
  };

  private _gridColumns = new BehaviorSubject<string>(null);
  protected gridColumns$ = this._gridColumns as Observable<string>;
  connectToGridColumns = (columnString: string) => this._gridColumns.next(columnString);
  public gridColumnsArray$ = this.gridColumns$.pipe(map(columns => columns ? columns.split(',') : []));

  private _pricingTierGridColumns = new BehaviorSubject<string>(null);
  protected pricingTierGridColumns$ = this._pricingTierGridColumns as Observable<string>;
  connectToPricingTierGridColumns = (columnString: string) => this._pricingTierGridColumns.next(columnString);

  private _section = new BehaviorSubject<HydratedSection>(null);
  public section$ = this._section as Observable<HydratedSection>;

  connectToSection(section: HydratedSection) {
    this._section.next(section);
    this.connectToShowZeroStock(section?.showZeroStockItems);
    this.connectToSectionLayoutType(section?.getLayoutType());
    this.connectToGridColumns(section?.getGridModeColumnNamesOrNull());
    this.connectToPricingTierGridColumns(section?.getPricingTierGridColumnNamesOrNull());
  }

  private _bindTo = new BehaviorSubject(null);
  public bindTo$ = this._bindTo as Observable<any>;
  connectToBindTo = (bindTo: any) => {
    this._bindTo.next(bindTo);
    this.connectToSelectedPrimarySortOption(bindTo?.sorting);
    this.connectToSelectedSecondarySortOption(bindTo?.secondarySorting);
  };

  public sectionTypes$ = this.menu$.pipe(switchMap(menu => window.types.getSectionTypes(menu)));

  public readonly layoutTypes$ = combineLatest([
    window.types.sectionLayoutTypes$,
    this.menu$,
    this.section$,
  ]).pipe(
    map(([layoutTypes, menu, section]) => {
      return layoutTypes
        ?.filter(layoutType => this.layoutTypeFilter(menu, section, layoutType))
        ?.map(layoutType => this.layoutTypeSelectionConversion(menu, section, layoutType));
    })
  );

  /**
   * Only show Pricing Tier Grid if a variant exists with a pricing tier, so no empty checkbox
   * options will ever be shown.
   */
  protected layoutTypeFilter(menu: Menu, section: HydratedSection, layoutType: SectionLayoutType): boolean {
    switch (layoutType?.value) {
      case SectionLayout.ChildVariantList:
        return ThemeUtils.themeIdsThatSupportChildVariantList().contains(menu?.theme);
      case SectionLayout.ClassicFlowerGrid:
        return ThemeUtils.themeIdsThatSupportClassicFlowerGrid().contains(menu?.theme);
      case SectionLayout.Grid:
        return !ThemeUtils.themeIdsWithGridDisabled().contains(menu?.theme);
      case SectionLayout.PricingTierGrid:
        return section?.hasPricingTier();
      default:
        return true;
    }
  }

  protected layoutTypeSelectionConversion(
    menu: Menu,
    section: HydratedSection,
    layoutType: SectionLayoutType
  ): SectionLayoutType {
    return layoutType.value === SectionLayout.ChildVariantList
      ? ThemeUtils.getSelectionOptionForChildVariantList(menu?.theme, layoutType)
      : layoutType;
  }

  public strainClassificationSortExplanation$ = this.selectedSortOptions$.pipe(
    map(sortOptions => {
      const explanation = 'Strain Type is sorted in the following order: ';
      switch (true) {
        case sortOptions?.includes(SectionSortProductInfo.ClassificationAsc):
          return explanation + 'Sativa - Hybrid - Blend - Indica - CBD - Other';
        case sortOptions?.includes(SectionSortProductInfo.ClassificationDesc):
          return explanation + 'CBD - Indica - Blend - Hybrid - Sativa - Other';
        default:
          return '';
      }
    })
  );

  public disableLayoutTypeSelection$ = defer(() => this.layoutTypes$).pipe(
    map(layoutTypes => layoutTypes?.length === 1),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public sectionHasSmartFilters$ = this.section$.pipe(map(s => s?.hasSmartFilters()));

  public columns$ = combineLatest([
    this.layoutType$,
    this.gridColumns$,
    this.pricingTierGridColumns$,
  ]).pipe(
    map(([layoutType, gridCols, ptGridCols]) => {
      if (layoutType?.isGrid()) return gridCols;
      if (layoutType?.isPricingTierGrid()) return ptGridCols;
      return null;
    })
  );

  /**
   * Grid Columns
   *
   * We are unable to just update the gridColumns that appear in the UI. Every time we change the underlying values
   * the *ngFor that lays out the checkboxes causes a re-render to happen. This re-lays out the entire form and
   * sets the layoutType to the value that is present on the Section data model - which will be unchanged until
   * the form binds and save API call is made
   */
  public clearGridBulkSelection$ = this.section$.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    map(section => (section?.layoutType === SectionLayout.Grid ? undefined : null))
  );

  public clearPricingTierGridBulkSelection$ = this.section$.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    map((section) => (section?.layoutType === SectionLayout.PricingTierGrid ? undefined : null))
  );

  public isGrid$ = this.layoutType$.pipe(map(type => type?.isGrid()));

  public sectionGridColumns$: Observable<string[]> = combineLatest([
    this.section$,
    this.menu$,
  ]).pipe(
    map(([section, menu]) => {
      const gridLayoutType = new SectionLayoutType(SectionLayout.Grid);
      const sectionColumns = section?.getGridColumns(gridLayoutType, menu?.locationId);
      return sectionColumns?.sort(SortUtils.compareNumerically);
    }),
    distinctUntilChanged(DistinctUtils.distinctUnsortedStrings)
  );

  public bindGridCheckboxesOnSubmit$ = combineLatest([
    this.sectionGridColumns$,
    this.layoutType$,
  ]).pipe(
    map(([gridColumnNames, layoutType]) => {
      return layoutType?.isGrid() ? (gridColumnNames?.length > 0) : false;
    }),
    distinctUntilChanged()
  );

  private priceColumnWidth$ = combineLatest([this.menu$, this.section$]).pipe(
    map(([menu, section]) => {
      const defaultWidth = menu
        ?.hydratedTheme
        ?.sectionColumnConfig
        ?.get(SectionColumnConfigProductInfoKey.Price)
        ?.columnWidth;
      const columnConfigWidth = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.Price)?.columnWidth;
      return columnConfigWidth || defaultWidth;
    })
  );

  public maximumGridColumnSelections$ = combineLatest([
    this.menu$,
    this.section$,
    this.layoutType$,
    this.priceColumnWidth$
  ]).pipe(
    map(([menu, section, layoutType, priceColumnWidth]) => {
      let nonGridColumnWidthTotal = NAME_COLUMN_DEFAULT_WIDTH;
      section?.columnConfig?.forEach((columnConfig, key) => {
        const themeDefaults = menu?.hydratedTheme?.sectionColumnConfig?.get(key);
        const columnState = columnConfig?.defaultState || themeDefaults?.defaultState;
        const nonDisplayedColumnStates = [
          SectionColumnConfigDefaultState.Disabled,
          SectionColumnConfigDefaultState.Off
        ];
        const isStockColumn  = key === SectionColumnConfigProductInfoKey.Stock;
        const stockColumnAndSectionNotReport = isStockColumn && !section?.isPrintReportMenuSection();
        if (nonDisplayedColumnStates.includes(columnState) || stockColumnAndSectionNotReport) return;
        let widthToBeAdded = SectionColumnConfigKeyType.disabledForGridLayout(key, layoutType)
          ? 0
          : columnConfig?.columnWidth || themeDefaults?.columnWidth;
        if (key === SectionColumnConfigProductInfoKey.Price && layoutType?.isAnyGrid()) widthToBeAdded = 0;
        nonGridColumnWidthTotal += widthToBeAdded;
      });
      const menuWidth = 100;
      const availableWidth = menuWidth - nonGridColumnWidthTotal;
      return Math.floor(availableWidth / priceColumnWidth);
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  public maximumGridColumnsReached$ = combineLatest([this.maximumGridColumnSelections$, this.gridColumnsArray$]).pipe(
    map(([maximumSelections, columnsSelected]) => {
      const nColumnsSelected = columnsSelected?.length;
      return nColumnsSelected >= maximumSelections;
    })
  );

  public allGridColumnsWouldExceedMaximumWidth$ = combineLatest([
    this.maximumGridColumnSelections$,
    this.sectionGridColumns$
  ]).pipe(
    map(([maxSelections, sectionGridColumns]) => {
      const nPossibleSelections = sectionGridColumns?.length;
      return nPossibleSelections > maxSelections;
    })
  );

  public selectAllGridColumnsDisabledTooltip$ = this.section$.pipe(
    map((section) => {
      if (section?.isTemplatedSection()) return null;
      return section?.autoUpdateGridColumns
        ? 'Selecting all grid columns is not allowed when auto-update is enabled.'
        : 'Selecting all grid columns would exceed the maximum width.';
    })
  );

  public disableGridColumnCheckboxes$ = combineLatest([
    this.bindGridCheckboxesOnSubmit$,
    this.section$,
    this.allGridColumnsWouldExceedMaximumWidth$
  ]).pipe(
    map(([bindGridCheckboxesOnSubmit, section, wouldExceedMaximum]) => {
      return !bindGridCheckboxesOnSubmit || section?.autoUpdateGridColumns || wouldExceedMaximum;
    })
  );

  public performGridColumnValidationWhenDisabled$ = combineLatest([this.section$, this.isGrid$]).pipe(
    map(([section, isGrid]) => {
      return isGrid && !section?.autoUpdateGridColumns;
    })
  );

  public isPricingTierGrid$ = this.layoutType$.pipe(map(type => type?.isPricingTierGrid()));

  public performPricingTierGridValidationWhenDisabled$ = combineLatest([this.section$, this.isPricingTierGrid$]).pipe(
    map(([section, isPricingTierGrid]) => {
      return isPricingTierGrid && !section?.autoUpdateGridColumns;
    })
  );

  public sectionPricingTierGridColumns$: Observable<string[]> = combineLatest([
    this.section$,
    this.menu$,
  ]).pipe(
    map(([section, menu]) => {
      const gridLayoutType = new SectionLayoutType(SectionLayout.PricingTierGrid);
      return section?.getGridColumns(gridLayoutType, menu?.locationId);
    }),
    distinctUntilChanged(DistinctUtils.distinctSortedStrings)
  );

  public bindPricingTierGridCheckboxesOnSubmit$ = combineLatest([
    this.sectionPricingTierGridColumns$,
    this.layoutType$,
  ]).pipe(
    map(([pricingTierGridColumnNames, layoutType]) => {
      return layoutType?.isPricingTierGrid() ? (pricingTierGridColumnNames?.length > 0) : false;
    }),
    distinctUntilChanged()
  );

  public disablePricingTierGridColumnCheckboxes$ = combineLatest([
    this.bindPricingTierGridCheckboxesOnSubmit$,
    this.section$,
    this.allGridColumnsWouldExceedMaximumWidth$
  ]).pipe(
    map(([bindPricingTierGridCheckboxesOnSubmit, section, wouldExceedMaximum]) => {
      return !bindPricingTierGridCheckboxesOnSubmit || section?.autoUpdateGridColumns || wouldExceedMaximum;
    })
  );

  private getNoColumnsSelectedWarningMessage(messageType: WarningMessageType): string {
    switch (messageType) {
      case WarningMessageType.Banner:
        return `There are no Grid Columns selected on this menu.`;
      case WarningMessageType.Tooltip:
        return `When a section layout type is set to Grid, at least one Grid Column must be selected.`;
    }
    return null;
  }

  private getTooManyColumnsWarningMessage(messageType: WarningMessageType, activeGridCols: string[]): string {
    switch (messageType) {
      case WarningMessageType.Banner:
        return `There are ${activeGridCols.length} Grid Columns selected on this menu.`;
      case WarningMessageType.Tooltip:
        return `Having more than 3 Grid Columns in a grid section might make the section `
          + `appear too busy and hard to read.`;
    }
    return null;
  }

  private getMultipleVariantTypesWarningMessage(
    messageType: WarningMessageType,
    sectionVariantTypes: VariantType[]
  ): string {
    switch (messageType) {
      case WarningMessageType.Banner:
        return `There are ${sectionVariantTypes?.length} Variant Types included on this menu.`;
      case WarningMessageType.Tooltip:
        return `Having multiple variant types in a grid section might make the column headers confusing.`;
    }
    return null;
  }

  public getWarningMessage(messageType: WarningMessageType): Observable<string> {
    return combineLatest([
      this.section$,
      this.layoutType$,
      this.columns$,
    ]).pipe(
      map(([section, type, columns]) => {
        // Grid Column Count error
        if (!!section) {
          const activeGridCols = !!columns ? columns?.split(',') : [];
          // Variant Type error
          const sectionVariants = section?.products
            ?.flatMap(p => p?.variants)
            ?.filter(v => section?.enabledVariantIds?.contains(v.id));
          switch (true) {
            case activeGridCols?.length === 0 && type?.isAnyGrid():
              return this.getNoColumnsSelectedWarningMessage(messageType);
            case activeGridCols?.length > 3: {
              return this.getTooManyColumnsWarningMessage(messageType, activeGridCols);
            }
            case sectionVariants?.length > 0: {
              const sectionVariantTypes = sectionVariants.map(v => v?.variantType).unique();
              const isOnlyFlowerVariantTypes = sectionVariantTypes?.every(
                vt => VariantTypeDefinition.isFlowerByGramType(vt)
              );
              const hasSectionVariantTypes = sectionVariantTypes?.length > 1;
              // Only show message about multiple Variant Types on menu
              // if Grid (Pricing Tier Grid will still be discrete line items)
              const isGridType = type?.isGrid();
              if (hasSectionVariantTypes && isGridType && !isOnlyFlowerVariantTypes) {
                return this.getMultipleVariantTypesWarningMessage(messageType, sectionVariantTypes);
              }
            }
          }
        }
        return null;
      })
    );
  }

  public sectionBannerWarningMessage$ = this.getWarningMessage(WarningMessageType.Banner);
  public sectionWarningMessageToolTip$ = this.getWarningMessage(WarningMessageType.Tooltip);

  public showZeroStockNote$ = this.showZeroStock$.pipe(map(showZeroStock => !showZeroStock));

  protected zeroStockNoteForSmartFilters = 'Note: This section is using Smart Filters. '
    + 'Therefore, out of stock products are automatically omitted.';
  protected zeroStockNote = 'Note: Products with a quantity of "0" will not appear when the menu is displayed.';

  public zeroStockNote$ = this.sectionHasSmartFilters$.pipe(
    map(hasSmartFilters => {
      return hasSmartFilters
        ? this.zeroStockNoteForSmartFilters
        : this.zeroStockNote;
    })
  );

  customErrorMessageMap$ = this.maximumGridColumnSelections$.pipe(
    map((maxSelections) => {
      const errorMap = new Map<string, string>();
      const errorMsg = `To prevent overflow, a maximum of ${maxSelections} `
        + `grid column${maxSelections === 1 ? '' : 's'} may be selected.`;
      return errorMap.set('maxCheckboxes', errorMsg);
    })
  );

  parseSectionGridColumns = (newVal: string | string[]) => {
    if (newVal instanceof Array) {
      return newVal.filterFalsies().sort().join(',');
    } else {
      return newVal?.split(',')?.sort()?.join(',') ?? '';
    }
  };

  public parseAsString = (value: number) => (!!value ? `${value}` : null);

  public saleFormatDropdowns$ = window?.types?.saleLabelFormats$;

  public saleFormatPlaceholder$ = combineLatest([
    this.companyConfig$,
    this.locationConfig$,
  ]).pipe(
    debounceTime(100),
    map(([compConfig, locConfig]) => {
      const fallbackFormat = !!locConfig?.saleLabelFormat ? locConfig?.saleLabelFormat : compConfig?.saleLabelFormat;
      const fallbackLocText = !!locConfig?.saleLabelFormat ? 'Location' : 'Company';
      const name = window?.types.initTypeDefinition(TypeDefinition, fallbackFormat)?.name;
      return `${name} (${fallbackLocText} Default)`;
    })
  );

}
