import { Theme } from '../models/menu/dto/theme';
import { MarketingTheme, PrintCardTheme, PrintTheme, ProductTheme, ThemeId } from '../models/enum/dto/theme.enum';
import { SectionColumnConfigDataValueType } from '../models/utils/dto/section-column-config-data-value-type';
import { CardType } from '../models/utils/dto/card-type-definition';
import { SectionLayoutType } from '../models/utils/dto/section-layout-type';
import { SectionColumnConfigSecondaryPricingData } from '../models/enum/dto/section-column-config-secondary-pricing-data';

export class ThemeUtils {

  static themeIdsWithGridDisabled(): string[] {
    return [
      PrintTheme.DoubleDutch,
      PrintTheme.PlantlifeNonSmokable,
      PrintTheme.Plantlife,
      PrintTheme.ComeToBaskTriFold
    ];
  }

  static themeIdsThatSupportChildVariantList(): string[] {
    return [
      PrintCardTheme.Stiiizy,
      PrintCardTheme.FikaEdibles
    ];
  }

  static themeIdsThatSupportClassicFlowerGrid(): string[] {
    return [];
  }

  static getSelectionOptionForCardStackLayoutList(original: SectionLayoutType): SectionLayoutType | null {
    const buildSelection = (name: string): SectionLayoutType => {
      return new class extends SectionLayoutType {

        override name = name;
        override value = original.value;
        override description = original.description;

      }();
    };
    return buildSelection('Single Variant Per Card');
  }

  static getSelectionOptionForLabelStackLayoutList(original: SectionLayoutType): SectionLayoutType | null {
    const buildSelection = (name: string): SectionLayoutType => {
      return new class extends SectionLayoutType {

        override name = name;
        override value = original.value;
        override description = original.description;

      }();
    };
    return buildSelection('Single Variant Per Label');
  }

  static getSelectionOptionForChildVariantList(
    themeId: string,
    original: SectionLayoutType
  ): SectionLayoutType | null {
    const buildSelection = (name: string): SectionLayoutType => {
      return new class extends SectionLayoutType {

        override name = name;
        override value = original.value;
        override description = original.description;

      }();
    };
    switch (themeId) {
      case PrintCardTheme.FikaEdibles:
        return buildSelection('Edibles Card');
      case PrintCardTheme.Stiiizy:
        return buildSelection('Multi Strain/Flavor Card');
      default:
        return original;
    }
  }

  static themeIdsWithClassificationTextColorDisabled(): string[] {
    return [PrintTheme.DoubleDutch];
  }

  static themeIdsWithMediaAndTitleSectionsDisabled(): string[] {
    return [PrintTheme.DoubleDutch, PrintTheme.PlantlifeNonSmokable, PrintTheme.Plantlife];
  }

  static themeIdsWithVerticallyStackedPrices(): string[] {
    return [ProductTheme.LuxLeaf];
  }

  static themeIdsWithNonStandardDimensions(): string[] {
    return [PrintCardTheme.DougAr, PrintCardTheme.Stiiizy];
  }

  static themeIdsWithHideSaleDisabled(): string[] {
    return [PrintCardTheme.FireAndFlowerDefault, PrintCardTheme.FireAndFlowerSale, PrintCardTheme.FireAndFlowerMember];
  }

  static themeIdsWithShowAlternativeLogoDisabled(): string[] {
    return [PrintCardTheme.Stiiizy];
  }

  static filterSecondaryPriceUseByForTheme(
    vals: SectionColumnConfigDataValueType[],
    themeId: string
  ): SectionColumnConfigDataValueType[] {
    const supportsOriginalAndSalePrice = ThemeUtils.themeIdsWithVerticallyStackedPrices().contains(themeId);
    return supportsOriginalAndSalePrice
      ? vals
      : vals.filter(v => v.value !== SectionColumnConfigSecondaryPricingData.OriginalAndSalePrice);
  }

  static isMarketingMenu(theme: Theme): boolean {
    return Object.values(MarketingTheme)?.map(it => it.toString())?.contains(theme?.id);
  }

  static getStartingCardType(themeId: ThemeId): CardType {
    switch (themeId) {
      case MarketingTheme.FeaturedCategory:
        return CardType.HeaderImage;
      case MarketingTheme.Combo:
        return CardType.Full;
    }
  }

}
