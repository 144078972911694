import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModel } from '../base-smart-filter-form-view-model';
import { map } from 'rxjs/operators';
import { CompanyDomainModel } from '../../../../../domainModels/company-domain-model';
import { CannabinoidsAndTerpenesDomainModel } from '../../../../../domainModels/cannabinoids-and-terpenes-domain-model';
import { combineLatest, Observable, of } from 'rxjs';

export interface TerpeneInfo {
  key: any;
  text: string;
}

@Injectable()
export class TerpeneAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModel {

  constructor(
    public companyDomainModel: CompanyDomainModel,
    public cannabinoidsAndTerpenesDomainModel: CannabinoidsAndTerpenesDomainModel
  ) {
    super();
  }

  public enabledTerpenes$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpeneNames$;

  public terpeneDropdowns$ = this.cannabinoidsAndTerpenesDomainModel.enabledTerpenes$;

  public totalTerpeneInfo$ = of({
    key: 'TotalTerpene',
    text: 'Total Terpene'
  });

  public terpenes$: Observable<TerpeneInfo[]> = this.terpeneDropdowns$.pipe(
    map(terpeneTypes => terpeneTypes?.map(t => {
      const typeText = t?.getSelectionValue();
      const typeKey = typeText.toString()?.stripWhiteSpace();
      return {
        key: typeKey,
        text: typeText,
      };
    })),
  );

  public terpenePillBackgroundColors$ = combineLatest([
    this.enabledTerpenes$,
    window.types.terpenes$
  ]).pipe(
    map(([
      enabledTerpenes,
      terpenes
    ]) => {
      const terpeneNames = terpenes?.map(c => c?.getSelectionTitle());
      return terpeneNames.reduce((pillBackgroundColors, terpeneName) => {
        pillBackgroundColors[terpeneName] = enabledTerpenes?.includes(terpeneName) ? '#222222' : '#FFD74B';
        return pillBackgroundColors;
      }, {});
    })
  );

  public terpenePillTooltip$ = combineLatest([
    this.enabledTerpenes$,
    window.types.terpenes$
  ]).pipe(
    map(([
      enabledTerpenes,
      terpenes
    ]) => {
      const terpeneNames = terpenes?.map(c => c?.getSelectionTitle());
      return terpeneNames.reduce((pillTooltips, terpeneName) => {
        const tooltip = enabledTerpenes?.includes(terpeneName)
          ? ''
          : `${terpeneName} is disabled in company cannabinoids. `
          + 'This will not be considered on smart filter matches.';
        return { ...pillTooltips, [terpeneName]: tooltip};
      }, {});
    })
  );

}
