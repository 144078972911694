import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditLabelModalComponent } from '../views/menu/components/edit-menu-section/edit-label-modal/edit-label-modal.component';
import { ModalUtils } from '../utils/modal-utils';
import { Variant } from '../models/product/dto/variant';
import { Menu } from '../models/menu/dto/menu';
import { HydratedSection } from '../models/menu/dto/hydrated-section';
import { Injector, NgZone } from '@angular/core';

export class ModalEditLabel {

  static open(
    ngZone: NgZone,
    ngbModal: NgbModal,
    injector: Injector,
    menu: Menu,
    section: HydratedSection,
    variant: Variant
  ): void {
    ngZone.run(() => {
      const modalRef = ngbModal.open(EditLabelModalComponent, ModalUtils.editLabelModalOptions(injector));
      const compInstance = modalRef.componentInstance as EditLabelModalComponent;
      if (variant) {
        compInstance.viewModel.connectToMenu(menu);
        compInstance.viewModel.connectToSection(section);
        compInstance.viewModel.connectToVariant(variant);
      }
    });
  }

}
