import { Injectable } from '@angular/core';
import { StackPrintType } from '../../../../../models/automation/enum/card-stack-print-type.enum';
import { BulkPrintJob } from '../../../../../models/automation/bulk-print-job';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BulkPrintJobStep } from '../create-view-bulk-print-job-view-model';
import { map } from 'rxjs/operators';
import { BulkPrintJobType } from '../../../../../models/automation/enum/bulk-print-job-type';

@Injectable()
export class StackBulkPrintJobViewModel {

  private _stackPrintType = new BehaviorSubject<StackPrintType | null>(null);
  public stackPrintType$ = this._stackPrintType as Observable<StackPrintType | null>;
  connectToStackPrintType = (pt: StackPrintType) => this._stackPrintType.next(pt);

  private _job = new BehaviorSubject<BulkPrintJob | null>(null);
  public job$ = this._job as Observable<BulkPrintJob | null>;
  connectToJob = (job: BulkPrintJob) => {
    this._selectedStackIds.next(job?.menuIds ?? []);
    this._job.next(job);
  };

  private _mergeKey = new BehaviorSubject<string>('');
  public mergeKey$ = this._mergeKey as Observable<string>;
  connectToMergeKey = (mergeKey: string) => this._mergeKey.next(mergeKey);

  private _placeholder = new BehaviorSubject<string>('');
  public placeholder$ = this._placeholder as Observable<string>;
  connectToPlaceholder = (placeholder: string) => this._placeholder.next(placeholder);

  private _selectedBulkPrintType = new BehaviorSubject<BulkPrintJobType | null>(null);
  public selectedBulkPrintType$ = this._selectedBulkPrintType as Observable<BulkPrintJobType | null>;
  connectToSelectedBulkPrintType = (bulkPrintType: BulkPrintJobType) => this._selectedBulkPrintType.next(bulkPrintType);

  private _selectedStackIds = new BehaviorSubject<string[]>([]);
  public selectedStackIds$ = this._selectedStackIds as Observable<string[]>;

  private _step = new BehaviorSubject<BulkPrintJobStep | null>(null);
  public step$ = this._step as Observable<BulkPrintJobStep | null>;
  connectToStep = (step: BulkPrintJobStep) => {
    if (step === BulkPrintJobStep.SelectStackPrintType) {
      this._selectedStackIds.next([]);
    }
    this._step.next(step);
  };

  private _viewOnly = new BehaviorSubject<boolean>(false);
  public viewOnly$ = this._viewOnly as Observable<boolean>;
  connectToViewOnly = (viewOnly: boolean) => this._viewOnly.next(viewOnly);

  public readonly selectedBulkPrintTypeIsStack$ = this.selectedBulkPrintType$.pipe(
    map(jt => {
      return jt === BulkPrintJobType.BulkPrintJobType_Card
          || jt === BulkPrintJobType.BulkPrintJobType_ShelfTalker
          || jt === BulkPrintJobType.BulkPrintJobType_Label;
    })
  );

  public selectedBulkPrintTypeIsCard$ = this.selectedBulkPrintType$.pipe(
    map(jt => jt === BulkPrintJobType.BulkPrintJobType_Card)
  );

  public stackPrintTypeIsSmart$ = this.stackPrintType$.pipe(
    map(pt => pt?.toLowerCase()?.includes('smart'))
  );

  public stackPrintTypeIsManual$ = this.stackPrintType$.pipe(
    map(pt => pt?.toLowerCase()?.includes('manual'))
  );

  public stepIsSelectStacks$ = this.step$.pipe(map(s => s === BulkPrintJobStep.SelectStacks));
  public stepIsStackBulkPrintJob$ = this.step$.pipe(map(s => s === BulkPrintJobStep.StackBulkPrintJob));

  public showStackSmartBulkPrintJob$ = combineLatest([
    this.stackPrintTypeIsSmart$,
    this.stepIsStackBulkPrintJob$
  ]).pipe(
    map(([isSmart, isStackBulkPrintJob]) => isSmart && isStackBulkPrintJob)
  );

  public showStackManualBulkPrintJob$ = combineLatest([
    this.stackPrintTypeIsManual$,
    this.stepIsStackBulkPrintJob$
  ]).pipe(
    map(([isManual, isStackBulkPrintJob]) => isManual && isStackBulkPrintJob)
  );

  public showSelectStacks$ = combineLatest([
    this.selectedBulkPrintTypeIsStack$,
    this.showStackManualBulkPrintJob$
  ]).pipe(
    map(([isStack, showStackManualBulkPrintJob]) => isStack && !showStackManualBulkPrintJob)
  );

  public bulkAddStackIdsToSelectedIds(ids: string[]): void {
    this.selectedStackIds$.once(prevSelectedIds => {
      const newIds = prevSelectedIds?.concat(ids)?.unique() ?? ids;
      this._selectedStackIds.next(newIds);
    });
  }

  public bulkRemoveStackIdsFromSelectedIds(ids: string[]): void {
    this.selectedStackIds$.once(prevSelectedIds => {
      const newIds = prevSelectedIds?.filter(prevId => !ids.includes(prevId)) ?? [];
      this._selectedStackIds.next(newIds);
    });
  }

  public addStackIdToSelectedIds(id: string): void {
    this.selectedStackIds$.once(prevSelectedIds => {
      const newIds = prevSelectedIds?.concat(id) ?? [id];
      this._selectedStackIds.next(newIds);
    });
  }

  public removeStackIdFromSelectedIds(id: string): void {
    this.selectedStackIds$.once(prevSelectedIds => {
      const newIds = prevSelectedIds?.filter(prevId => prevId !== id) ?? [];
      this._selectedStackIds.next(newIds);
    });
  }

}
