import { MenuDetailsFormViewModel } from '../../../../../forms/menu-additional-options/additional-option-forms/menu-details-form/menu-details-form-view-model';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuDomainModel } from '../../../../../../../../domainModels/menu-domain-model';
import { TemplateDomainModel } from '../../../../../../../../domainModels/template-domain-model';
import { Injectable } from '@angular/core';
import { DefaultShelfTalkerPaperSize } from '../../../../../../../../models/utils/dto/default-shelf-talker-paper-size-type';
import { DefaultShelfTalkerSize } from '../../../../../../../../models/enum/dto/default-shelf-talker-size';

@Injectable()
export class EditShelfTalkerBrandsMenuDetailsFormViewModel extends MenuDetailsFormViewModel {

  constructor(menuDomainModel: MenuDomainModel, templateDomainModel: TemplateDomainModel) {
    super(menuDomainModel, templateDomainModel);
  }

  public shelfTalkerPaperSizes$ = combineLatest([
    this.menu$,
    window.types.printShelfTalkerPaperSizeTypes$
  ]).pipe(
    map(([menu, shelfTalkerSizeTypes]) => {
      const themePaperSizes = menu?.hydratedTheme?.printConfig?.paperSizes;
      return shelfTalkerSizeTypes?.filter(paperSize => themePaperSizes?.includes(paperSize.value));
    })
  );

  public override showULineLink$ = combineLatest([
    this.printCardSize$,
    this.selectedPrintoutSize$
  ]).pipe(
    map(([printCardSize, printoutSize]) => {
      const custom = printCardSize === DefaultShelfTalkerSize.ShelfTalkerSize_Custom5x7;
      const uLinePaperSelected = printoutSize === DefaultShelfTalkerPaperSize.DefaultSize_LetterPerforated_Uline;
      return !custom && uLinePaperSelected;
    })
  );

}
