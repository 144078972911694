import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavSubItemComponent } from '../nav-sub-item.component';
import { Router } from '@angular/router';
import { LocationsSubNavItemViewModel } from './locations-sub-nav-item-view-model';

@Component({
  selector: 'app-locations-sub-nav-item',
  templateUrl: './locations-sub-nav-item.component.html',
  styleUrls: [
    './locations-sub-nav-item.component.scss',
    '../nav-sub-item.component.scss'
  ],
  providers: [LocationsSubNavItemViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationsSubNavItemComponent extends NavSubItemComponent {

  constructor(
    public override viewModel: LocationsSubNavItemViewModel,
    router: Router,
  ) {
    super(viewModel, router);
  }

}
