import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { PrimaryCannabinoid } from '../../../../../../../models/enum/shared/primary-cannabinoid.enum';
import { SecondaryCannabinoid } from '../../../../../../../models/enum/dto/secondary-cannabinoid';
import { CannabinoidMinMaxFormViewModel } from './cannabinoid-min-max-form-view-model';
import { HydratedSmartFilter } from '../../../../../../../models/automation/hydrated-smart-filter';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-cannabinoid-min-max-form',
  templateUrl: './cannabinoid-min-max-form.component.html',
  styleUrls: ['./cannabinoid-min-max-form.component.scss'],
  providers: [CannabinoidMinMaxFormViewModel]
})
export class CannabinoidMinMaxFormComponent extends BaseComponent {

  constructor(
    public viewModel: CannabinoidMinMaxFormViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToSmartFilter') smartFilter: HydratedSmartFilter;
  @Input() @ViewModelConnector('connectToCannabinoid') cannabinoid: PrimaryCannabinoid | SecondaryCannabinoid;
  @Input() dispersedKey: string;
  @Input() isViewOnly: boolean = false;

  override setupViews() {
    super.setupViews();
    combineLatest([
      this.viewModel.smartFilter$.notNull(),
      this.viewModel.cannabinoid$.notNull()
    ]).subscribeWhileAlive({
      owner: this,
      next: ([smartFiler, cannabinoid]) => {
        this.viewModel.connectToMinCannabinoidValue(smartFiler?.[`min${cannabinoid}`]);
        this.viewModel.connectToMaxCannabinoidValue(smartFiler?.[`max${cannabinoid}`]);
      }
    });
  }

}
