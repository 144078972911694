import { Pipe, PipeTransform } from '@angular/core';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getVariantMinTACWithUnits'
})
export class GetVariantMinTacWithUnitsPipe implements PipeTransform {

  transform(variant: Variant | null, enabledCannabinoids: string[]): string | null {
    return variant?.getMinTACWithUnits(enabledCannabinoids);
  }

}
