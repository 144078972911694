import { Injectable, Injector } from '@angular/core';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Product } from '../../../../../../models/product/dto/product';
import { DisplayAttributesDomainModel } from '../../../../../../domainModels/display-attributes-domain-model';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { LocationDomainModel } from '../../../../../../domainModels/location-domain-model';
import { Menu } from '../../../../../../models/menu/dto/menu';
import { Section } from '../../../../../../models/menu/dto/section';
import { CompanyDomainModel } from '../../../../../../domainModels/company-domain-model';
import { DistinctUtils } from '../../../../../../utils/distinct-utils';
import { HydratedSection } from '../../../../../../models/menu/dto/hydrated-section';
import { Variant } from '../../../../../../models/product/dto/variant';
import { HydratedVariantBadge } from '../../../../../../models/product/dto/hydrated-variant-badge';
import { Theme } from '../../../../../../models/menu/dto/theme';
import { ModalPrintCardLiveView } from '../../../../../../modals/modal-print-card-live-view';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuTemplate } from '../../../../../../models/template/dto/menu-template';
import { SectionTemplate } from '../../../../../../models/template/dto/section-template';
import { MenuType } from '../../../../../../models/utils/dto/menu-type-definition';
import { ModalPrintLabelLiveView } from '../../../../../../modals/modal-print-label-live-view';
import { PriceFormat } from '../../../../../../models/enum/dto/price-format';

@Injectable()
export class MenuSectionVariantGroupingViewModel extends BaseViewModel {

  constructor(
    private locationDomainModel: LocationDomainModel,
    private displayAttributeDomainModel: DisplayAttributesDomainModel,
    private companyDomainModel: CompanyDomainModel,
    private ngbModal: NgbModal,
    private injector: Injector
  ) {
    super();
  }

  public companyConfig$ = this.companyDomainModel.companyConfiguration$;
  public priceFormat$ = this.locationDomainModel.priceFormat$;

  private _product = new BehaviorSubject<Product>(null);
  public product$: Observable<Product> = this._product.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _variants = new BehaviorSubject<Variant[]>(null);
  public variants$: Observable<Variant[]> = this._variants.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiableArray),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private _menu = new BehaviorSubject<Menu>(null);
  public menu$ = this._menu.asObservable();

  private _section = new BehaviorSubject<HydratedSection>(null);
  public section$: Observable<HydratedSection> = this._section.pipe(
    distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable)
  );

  private _theme = new BehaviorSubject<Theme>(null);
  public theme$ = this._theme.asObservable();

  private readonly _sortedVariantIds = new BehaviorSubject<string[]>(null);
  public readonly sortedVariantIds$ = this._sortedVariantIds as Observable<string[]>;

  /* ************************************ Three main outputs ************************************ */

  public badges$ = combineLatest([
    this.variants$,
    this.menu$,
    this.section$,
    this.theme$,
    this.priceFormat$
  ]).pipe(
    map(([variants, menu, section, theme, priceStream]) => {
      return this.getAllVariantBadges(theme?.themeFeatures?.badgeCount ?? 0, variants, menu, section, priceStream);
    })
  );

  private getAllVariantBadges(
    limit: number = 0,
    variants: Variant[],
    menu: Menu,
    section: HydratedSection,
    priceFormat: PriceFormat
  ): HydratedVariantBadge[] {
    let selectedBadges: HydratedVariantBadge[] = [];
    let overrideBadges: HydratedVariantBadge[] = [];
    let daBadges: HydratedVariantBadge[] = [];
    let inheritedDABadges: HydratedVariantBadge[] = [];

    const scopedVisibleVariants = this.getScopedVisibleVariants(variants, menu, section, priceFormat);
    scopedVisibleVariants.forEach((v) => {
      const badges: HydratedVariantBadge[] = [];
      if (section?.variantBadgeMap && section?.variantBadgeMap.get(v.id)) {
        section.variantBadgeMap.get(v.id).forEach((badge) => {
          if (!badges.find(b => b.id === badge.id)) {
            badges.push(badge);
          }
        });
      }

      const displayAttributeBadges = v?.displayAttributes?.badges ? v.displayAttributes.badges : [];
      const inheritedDisplayAttributeBadges = v?.displayAttributes?.inheritedDisplayAttribute?.badges ?? [];
      if (section.getLayoutType()?.isGrid()) {
        if (badges.length > 0) {
          overrideBadges.push(...badges);
        }
        if (displayAttributeBadges.length > 0) {
          daBadges.push(...displayAttributeBadges);
        }
        if (inheritedDisplayAttributeBadges.length > 0) {
          inheritedDABadges.push(...inheritedDisplayAttributeBadges);
        }
      } else {
        if (badges.length > 0) {
          selectedBadges.push(...badges);
        } else if (displayAttributeBadges.length > 0) {
          selectedBadges.push(...displayAttributeBadges);
        } else if (inheritedDisplayAttributeBadges.length > 0) {
          selectedBadges.push(...inheritedDisplayAttributeBadges);
        }
      }
    });

    overrideBadges = overrideBadges.uniqueByProperty('id').sort((a, b) => a.name.localeCompare(b.name));
    daBadges = daBadges.uniqueByProperty('id').sort((a, b) => a.name.localeCompare(b.name));
    inheritedDABadges = inheritedDABadges.uniqueByProperty('id').sort((a, b) => a.name.localeCompare(b.name));

    if (overrideBadges.length > 0) {
      selectedBadges = overrideBadges;
    } else if (daBadges.length > 0) {
      selectedBadges = daBadges;
    } else if (inheritedDABadges.length > 0) {
      selectedBadges = inheritedDABadges;
    } else {
      selectedBadges = selectedBadges.uniqueByProperty('id').sort((a, b) => a.name.localeCompare(b.name));
    }

    if (limit > 0 && limit < selectedBadges.length) {
      return selectedBadges.slice(0, limit);
    } else if (limit < 0) { // Not supported
      return [];
    } else {
      return selectedBadges;
    }
  }

  private getScopedVisibleVariants(
    variants: Variant[],
    menu: Menu,
    section: Section,
    priceFormat: PriceFormat
  ): Variant[] {
    // Return scoped visible variants for purposes of badge/label calculation
    if (section.isInLineMode()) {
      return variants;
    } else {
      return variants?.filter(v => {
        const validGridColumnNames = v?.getGridNames(section?.getLayoutType(), menu?.locationId);
        const appliedGridColumnNames = section?.metadata?.gridColumnNames.split(',').map(s => s.trim());
        const isWithinGridScope = validGridColumnNames.intersection(appliedGridColumnNames).length > 0;
        let price: number;
        if (section.showZeroStockItems || (!section.showZeroStockItems && v.inventory.inStock())) {
          price = v?.getVisiblePrice(menu?.locationId, menu?.companyId, priceFormat, false);
        }
        return isWithinGridScope ? price > 0 : false;
      });
    }
  }

  showLiveView(variants: Variant[]): void {
    combineLatest([
      this.menu$,
      this.section$,
      this.sortedVariantIds$
    ]).once(([menu, section, sortedVariantIds]) => {
      const productId = variants?.firstOrNull()?.productId;
      const variantIds = variants?.map(v => v?.id)?.filterNulls();
      menu instanceof MenuTemplate && section instanceof SectionTemplate
        ? menu?.updateSectionTemplate(section, false)
        : menu?.updateSection(section as Section, false);
      const liveViewOpen = menu?.type === MenuType.PrintLabelMenu
        ? ModalPrintLabelLiveView.open
        : ModalPrintCardLiveView.open;
      liveViewOpen(this.ngbModal, this.injector, menu, sortedVariantIds, productId, variantIds);
    });
  }

  connectToProduct(p: Product) {
    this._product.next(p);
  }

  connectToVariants(v: Variant[]) {
    this._variants.next(v);
  }

  connectToMenu(m: Menu) {
    this._menu.next(m);
  }

  connectToSection(s: HydratedSection) {
    this._section.next(s);
  }

  connectToTheme(t: Theme) {
    this._theme.next(t);
  }

  connectToSortedVariantIds(ids: string[]) {
    this._sortedVariantIds.next(ids);
  }

}
