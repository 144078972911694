<div class="d-flex justify-content-between align-items-center mt-24px">
  <span class="bs-bold f16px">Cards</span>
</div>

<div class="f12px mt-16px mb-8px">Select the stack sections you want included in this print job.</div>

<lib-reactive-form-group
  [mergeKey]="viewModel.mergeKey$ | push"
  [dispersedKey]="(viewModel.mergeKey$ | push) + 'dispersed'"
  [bindTo]="viewModel.sectionStackPrintConfig$ | push">
  <app-hidden-array-form-input
    [disabled]="viewModel.viewOnly$ | push"
    [inputName]="'sectionSelections'"
    [required]="true"
    [bindingProperty]="'variantIds'"
    [inputArr]="viewModel.sectionIdsToBeAdded$ | push">
  </app-hidden-array-form-input>
  <app-card-stack-print-job-select-all-sections
    *rxIf="!(viewModel.emptySearch$ | push)"
    [sectionIds]="viewModel.sectionIdsToBeAdded$ | push"
    [selection]="viewModel.searchedSectionsInListAsSelections$ | push"
    (bulkAddSelection)="viewModel.bulkAddSelection($event)"
    (bulkRemoveSelection)="viewModel.bulkRemoveSelection($event)">
  </app-card-stack-print-job-select-all-sections>
  <app-card-stack-print-job-select-individual-section
    *rxFor="let section of viewModel.searchedSections$; trackBy: 'id'"
    [section]="section"
    [selectedIds]="viewModel.sectionIdsToBeAdded$ | push"
    [viewOnly]="viewModel.viewOnly$ | push"
    (containerSectionClicked)="viewModel.containerSectionClicked($event)"
    (addSectionClicked)="viewModel.addSectionClicked($event)"
    (removeSectionClicked)="viewModel.removeSectionClicked($event)">
  </app-card-stack-print-job-select-individual-section>
</lib-reactive-form-group>

<div *rxIf="viewModel.emptySearch$" class="d-flex justify-content-start mt-16px f14px bs-medium">
  <div>{{ viewModel.noSectionsForSearchPlaceholder$ | push }}</div>
</div>
