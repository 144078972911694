import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, Injector, Input, OnInit, ViewRef } from '@angular/core';
import { ReactiveTableRowBluePrintComponent } from '@mobilefirstdev/reactive-table';
import { SmartFilterVariantsTableViewModel } from '../../smart-filter-variants-table-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Variant } from '../../../../../../../../../../models/product/dto/variant';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { PriceUtils } from '../../../../../../../../../../utils/price-utils';
import { LabelUtils } from '../../../../../../../../../../modules/product-labels/utils/label-utils';
import { VariantIgnoredTypeEnum } from '../../../../../../../../../../models/automation/enum/variant-ignored-type.enum';
import { PrimaryCannabinoid } from '../../../../../../../../../../models/enum/shared/primary-cannabinoid.enum';

@Component({
  selector: 'app-smart-filter-variants-table-item',
  templateUrl: './smart-filter-variants-table-item.component.html',
  styleUrls: ['./smart-filter-variants-table-item.component.scss'],
  providers: [{
    provide: ReactiveTableRowBluePrintComponent,
    useExisting: forwardRef(() => SmartFilterVariantsTableItemComponent)
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartFilterVariantsTableItemComponent extends ReactiveTableRowBluePrintComponent implements OnInit {

  constructor(
    @Inject(ChangeDetectorRef) viewRef: ViewRef,
    changeDetector: ChangeDetectorRef,
    protected ngbModal: NgbModal,
    protected injector: Injector,
    public tableViewModel: SmartFilterVariantsTableViewModel
  ) {
    super(viewRef, changeDetector);
  }

  protected readonly length = length;

  @Input() override rowData: Variant;

  private _rowData = new BehaviorSubject<Variant|null>(this.rowData);
  public rowData$ = this._rowData as Observable<Variant|null>;

  public id$ = this.rowData$.pipe(map(variant => variant?.id));
  public cellClass$ = this.rowData$.pipe(
    map(variant => variant?.useCannabinoidRange ? 'range-cannabinoid-cell' : 'exact-cannabinoid-cell')
  );

  // ignore variant controls
  public ignoredBySmartFilter$ = combineLatest([
    this.rowData$,
    this.tableViewModel.ignoredVariantIds$
  ]).pipe(
    map(([rowData, smartFilterIgnoredVariantIds]) => smartFilterIgnoredVariantIds?.includes(rowData?.id)),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public ignoredByLocation$ = combineLatest([
    this.rowData$,
    this.tableViewModel.locationIgnoredVariantIds$
  ]).pipe(
    map(([rowData, locationIgnoredVariantIds]) => locationIgnoredVariantIds?.includes(rowData?.id)),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public isVisible$ = combineLatest([
    this.ignoredBySmartFilter$,
    this.ignoredByLocation$
  ]).pipe(
    map(([ignoredBySmartFilter, ignoredByLocation]) => !ignoredBySmartFilter && !ignoredByLocation),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  // Name column
  public isMedical$ = this.rowData$.pipe(
    map(rowData => rowData?.isMedical)
  );
  public variantName$ = this.rowData$.pipe(
    map(rowData => rowData?.getDisplayName())
  );
  public variantSubText$ = combineLatest([
    this.rowData$,
    this.ignoredBySmartFilter$,
    this.ignoredByLocation$
  ]).pipe(
    map(([rowData, ignoredBySmartFilter, ignoredByLocation ]) => {
      let ignoredMessageText = '';
      if (ignoredBySmartFilter) {
        ignoredMessageText = VariantIgnoredTypeEnum.SmartFilter;
      } else if (ignoredByLocation) {
        ignoredMessageText = VariantIgnoredTypeEnum.Location;
      }
      return `${rowData?.productType} - ${rowData?.variantType} - ${rowData?.classification} ${ignoredMessageText}`;
    })
  );
  public variantNameTooltip$ = this.rowData$.pipe(
    map(rowData => {
      const hasDisplayName = !!rowData?.displayAttributes?.displayName
        || !!rowData?.displayAttributes?.inheritedDisplayAttribute?.displayName;
      return hasDisplayName ? rowData?.name : null;
    })
  );
  public computeLabelInterface$ = combineLatest([
    this.rowData$,
    this.tableViewModel.locationConfig$,
    this.tableViewModel.companyConfig$
  ]).pipe(
    map(([rowData, locConfig, compConfig]) => {
      return LabelUtils.getComputeLabelInterfaceFromVariantOutsideOfMenuContext(rowData, locConfig, compConfig);
    })
  );

  // Price column
  public variantPrice$ = combineLatest([
    this.rowData$,
    this.tableViewModel.locationId$,
    this.tableViewModel.companyId$,
    this.tableViewModel.priceFormat$
  ]).pipe(
    map(([rowData, locationId, companyId, priceFormat]) => {
      return PriceUtils.formatPrice(rowData?.getVisiblePrice(locationId, companyId, priceFormat, false));
    })
  );
  public variantPriceTooltip$ =  combineLatest([
    this.rowData$,
    this.tableViewModel.locationId$,
    this.tableViewModel.companyId$,
    this.tableViewModel.priceFormat$
  ]).pipe(
    map(([rowData, locationId, companyId, priceFormat]) => {
          const locationSale = rowData?.locationPricing?.find(p => p?.locationId === locationId)?.isSaleActive();
          const companySale = rowData?.locationPricing?.find(p => p?.locationId === companyId)?.isSaleActive();
          if (locationSale || companySale) {
            return rowData?.getPriceWithoutDiscountsTooltip(locationId, companyId, priceFormat);
          } else {
            return null;
          }
    })
  );

  public variantToggleDisabled$ = combineLatest([
    this.ignoredByLocation$,
    this.tableViewModel.isViewOnly$,
  ]).pipe(
    map(([ignoredByLocation, isViewOnly]) => ignoredByLocation || isViewOnly)
  );

  public variantToggleDisabledTooltip$ = combineLatest([
    this.ignoredByLocation$,
    this.tableViewModel.isViewOnly$,
    this.tableViewModel.viewOnlyTooltip$
  ]).pipe(
    map(([ignoredByLocation, isViewOnly, viewOnlyTooltip]) => {
      if (ignoredByLocation) return 'This variant is ignored by this location';
      if (isViewOnly) return viewOnlyTooltip;
      return '';
    })
  );

  ngOnInit(): void {
    this._rowData.next(this.rowData);
  }

  rowDataChanged(newRowData: Variant): void {
    this._rowData.next(newRowData);
  }

  visibilityToggled() {
    combineLatest([
      this.rowData$,
      this.tableViewModel.ignoredVariantIds$
    ]).once(([rowData, ignoredVariantIds]) => {
      const id = rowData?.id;
      if (ignoredVariantIds?.includes(id)) {
        this.tableViewModel.removeVariantIdFromSmartFilterIgnoredList(id);
      } else {
        this.tableViewModel.addVariantIdToSmartFilterIgnoredList(id);
      }
    });
  }

  protected readonly PrimaryCannabinoid = PrimaryCannabinoid;

}
